import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components 
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar"; 
import Button from "@material-ui/core/Button"; 
import sectionpop from "./SectionPopup";
// core components 
import styles from "../assets/jss/components/headerStyle.js";
import GridContainer from "../elements/Grid/GridContainer";
import GridItem from "../elements/Grid/GridItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CustomDropdown from "../elements/CustomDropdown/CustomDropdown"; 
import stylesr from "../assets/jss/components/headerLinkstopStyle";
import PageVisibility from 'react-page-visibility';


// import logo from "../assets/img/logo.png";
// import vipico from "../assets/img/card-ico2.png";

import Slide from "@material-ui/core/Slide";
// import menusidebarDb from "../data/menusidebar";
// import menusignheadrDb from "../data/menusignheadr"; 
 
// import CustomTabs from "../elements/CustomTabs/CustomTabs";
// import notificationDb from "../data/notification"; 
import LanguageSelector from './LanguageSelector'
import usermenuDb from "../data/usermenu";
import menusidebartopDb from "../data/menusidebartop";

import jwt from 'jsonwebtoken'; 
import jwtDecode from 'jwt-decode';
import jwtService from '../../../services/jwtService'
import axios from 'axios';
import * as globalurl from '../../../global/Global.js'; 
 
var isAlreadyOpen = false;
export default function SectionHeaderLogin(props) {
  const [userName, setUserName] = React.useState("Menus");
  const [topMenus, setTopMenus] = React.useState(0);
  const [sideMenus, setSideMenus] = React.useState(0);
  const [myBalance, setMyBalance] = React.useState(false);
  
  // User has switched back to the tab
  const onFocus = (event) => {
   
    if(event === true){
      getBalanceNow();
    }
  };

  
  if(props.themeColors != null){
    stylesr.headbackground.backgroundColor = props.themeColors.bgColors.primary_bgcolor;
    stylesr.topheader.backgroundColor = props.themeColors.bgColors.primary_bgcolor;
    styles.toolbarafter["&::before"].backgroundColor = props.themeColors.bgColors.primary_bgcolor; 
    stylesr.dropdownLink.color = props.themeColors.bgColors.text_secondary_dark_color;
    stylesr.navLink.color = props.themeColors.bgColors.text_secondary_color;  
    styles.coinbalance.color = props.themeColors.bgColors.text_secondary_color
    styles.plusbalance.color = props.themeColors.bgColors.text_secondary_color 
    styles.menusignin.color = props.themeColors.bgColors.text_primary_in_color
    styles.menusignin["&:hover"].color = props.themeColors.bgColors.text_primary_in_color
    styles.menusignin["&:hover"].background = props.themeColors.bgColors.main_color
    styles.usernotibtn.background = props.themeColors.bgColors.primary_bgcolor
    styles.usernotibtn.color = props.themeColors.bgColors.text_primary_color 
    styles.usersigninanker["&:hover"].background = props.themeColors.bgColors.main_color_bg
    styles.notificationmsgbox["& h5"].color = props.themeColors.bgColors.text_secondary_color
    styles.notificationmsgbox["&:hover"].background = props.themeColors.bgColors.text_secondary_color
    styles.notificationmsgbox["&:hover h5"].color = props.themeColors.bgColors.text_primary_dark_color
    styles.rightsidebarbtn.background = props.themeColors.bgColors.main_color
    styles.rightsidebarbtn.color = props.themeColors.bgColors.text_primary_in_color
    
    styles.usersigninbtn.color = props.themeColors.bgColors.text_primary_color 
    styles.logoname.color = props.themeColors.bgColors.text_primary_color 
    styles.signinheadersidebar.background = props.themeColors.bgColors.primary_bgcolor
    styles.listheadsidebartop.background = props.themeColors.bgColors.secondary_dark_bgcolor
    styles.listheadsidebartop["& h6"].color = props.themeColors.bgColors.text_secondary_in_color
    styles.listheadsidebartop["& h5"].color = props.themeColors.bgColors.text_primary_color
    styles.listheadsidebaranker.color = props.themeColors.bgColors.text_primary_color
    styles.listheadsidebaranker["&:hover"].background = props.themeColors.bgColors.main_color_bg
    styles.balancerefresh.background = props.themeColors.bgColors.main_color
    styles.balancerefresh.color = props.themeColors.bgColors.text_primary_in_color
    styles.usersigninanker.color = props.themeColors.bgColors.text_primary_color; 
    styles.balanceamount.background = props.themeColors.bgColors.primary_bgcolor; 
    styles.balanceamount.color = props.themeColors.bgColors.text_primary_color;
    styles.balanceamount["&:hover"].color = props.themeColors.bgColors.text_primary_color;  
    styles.balanceamount.borderColor = props.themeColors.bgColors.text_secondary_color;
    styles.headsigninbg.background = props.themeColors.bgColors.main_color;  
    
    styles.logoname.fontFamily = props.themeColors.bgColors.logo_font;
    styles.menusignin.fontFamily = props.themeColors.bgColors.base_font; 
    styles.balanceamount.fontFamily = props.themeColors.bgColors.base_font; 
    styles.usersigninbtn.fontFamily = props.themeColors.bgColors.base_font; 
    styles.listheadsidebaranker.fontFamily = props.themeColors.bgColors.base_font; 
    styles.listheadsidebartop["& h5"].fontFamily = props.themeColors.bgColors.base_font;  
  }
  
  const pageName = "dashboard";
  var parentId = 0;
  if(window.localStorage.getItem("header_access_token")){
    const decodedData = jwtDecode(window.localStorage.getItem("header_access_token"));
    if(decodedData.TopMenu != null){
      decodedData.TopMenu.forEach((myMenuData) => {
        if(myMenuData.path === "dashboard"){
          parentId = myMenuData.id;
        }
      });
    }
  }
  async function getBalanceFromServer(){
    let pro = new Promise((resolve,reject) => {
        const request = axios.post(globalurl.GETBALANCE , {
            token: window.localStorage.getItem('jwt_access_token')
        });
        request.then((response) => {
            if(response.data != null){
                var data = {
                    "status":true,
                    "data":response.data
                }
                resolve(data);
            }else{
                resolve({"status":false, "message":'No record found'});
            }
        });
        request.catch((error) => {
            reject(error);
        });
    }); 
    const val = await pro;
    return val;
  }
  async function getBalanceNow(){
    if(window.localStorage.getItem('jwt_access_token') != null){
      var MyBalance = await getBalanceFromServer();
      if(MyBalance.status != null && MyBalance.status === true && MyBalance.data != null){
        if(MyBalance.data.balance != null){
          setMyBalance(MyBalance.data.balance);
        }else{
          setMyBalance(0);
        }
      }else{
        setMyBalance(0);
      }
    }
  }
  if(myBalance === false){
    setMyBalance("Loading...")
  // alert(JSON.stringify(jwtService.isAuthenticationLogin()))
    if (jwtService.isAuthenticationLogin() === true){
      getBalanceNow();
    }
  }
  var refreshBalance = function(){
    setMyBalance("Loading...")
    if (jwtService.isAuthenticationLogin() === true){
      getBalanceNow();
    }
  }
  setTimeout(function() { 
    if (jwtService.isAuthenticationLogin() === true){
      getBalanceNow();
    }
  }, 25000);

  async function getDataFromServer(menutype, myParent, myPageName){
    let pro = new Promise((resolve,reject) => {
        const request = axios.post(globalurl.GETMENUS , {
            token: window.localStorage.getItem('jwt_access_token'),
            menutypes: menutype,
            parent_id: myParent,
            pageslug: myPageName
        });
        request.then((response) => {
            if(response.data.data != null){
                var data = {
                    "status":true,
                    "data":response.data.data
                }
                resolve(data);
            }else{
                resolve({"status":false, "message":'No record found'});
            }
        });
        request.catch((error) => {
            reject(error);
        });
    }); 
    const val = await pro;
    return val;
  }

  function setHeaderData(data, access_token_name){
      var jwtConfig = {
          "secret"   : "324934dsfj786ykjhgiyyi4439wri",
          "expiresIn": "1 hours" // A numeric value is interpreted as a seconds count. If you use a string be sure you provide the time units (days, hours, etc)
      };
      const access_token = jwt.sign(data, jwtConfig.secret, {expiresIn: jwtConfig.expiresIn});
      window.localStorage.setItem(access_token_name, access_token);
  }

  var dataOfMenu = [];
  async function getDataNow(){
      dataOfMenu = await getDataFromServer(6, parentId, pageName);
      if(dataOfMenu.data != null){
          setHeaderData({TopMenu: dataOfMenu.data}, "dashboard_access_token");
          setTopMenus(dataOfMenu.data);
      }
  }
  if(topMenus === 0){
      let headerData = false; 
      if(window.localStorage.getItem("dashboard_access_token") != null){
          const decodedData = jwtDecode(window.localStorage.getItem("dashboard_access_token"));
          const currentTime = Date.now() / 1000;
          let tokenExpired = false;
          if ( decodedData.exp < currentTime ){
              tokenExpired = true;
          }
          if(tokenExpired === true && navigator != null && navigator.onLine != null && navigator.onLine !== true){
              tokenExpired = false;
          }
          if (tokenExpired === false){
              if(decodedData.TopMenu != null){
                  setTopMenus(decodedData.TopMenu);
                  headerData = true;
              }  
          }
      }
      if(headerData === false){
          getDataNow();
      }
  }else{
    if(Array.isArray(topMenus)){
      dataOfMenu = topMenus;
    }else{
      dataOfMenu[0] = topMenus;
    }
  }




  function setHeaderSidebarData(data, access_token_name){
    var jwtConfig = {
        "secret"   : "324934dsfj786ykjhgiyyi4439wri",
        "expiresIn": "1 hours" // A numeric value is interpreted as a seconds count. If you use a string be sure you provide the time units (days, hours, etc)
    };
    const access_token = jwt.sign(data, jwtConfig.secret, {expiresIn: jwtConfig.expiresIn});
    window.localStorage.setItem(access_token_name, access_token);
}

  var dataSidebar = [];
  async function getSidebarData(){
    dataSidebar = await getDataFromServer(6, 0, 0);
      if(dataSidebar.data != null){
          setHeaderSidebarData({TopMenu: dataSidebar.data}, "sidebar_access_token");
          setSideMenus(dataSidebar.data);
      }
  }
  if(sideMenus === 0){
      let headerData = false; 
      if(window.localStorage.getItem("sidebar_access_token") != null){
          const decodedData = jwtDecode(window.localStorage.getItem("sidebar_access_token"));
          const currentTime = Date.now() / 1000;
          let tokenExpired = false;
          if ( decodedData.exp < currentTime ){
              tokenExpired = true;
          }
          if(tokenExpired === true && navigator != null && navigator.onLine != null && navigator.onLine !== true){
              tokenExpired = false;
          }
          if (tokenExpired === false){
              if(decodedData.TopMenu != null){
                  setSideMenus(decodedData.TopMenu);
                  headerData = true;
              }  
          }
      }
      if(headerData === false){
        getSidebarData();
      }
  }else{
    dataSidebar = sideMenus;
  }



  const useStyles = makeStyles(styles);
  const useStylesr = makeStyles(stylesr);
  const classes = useStyles();
  const classesr = useStylesr();  
  const currentLocation = props.location.pathname.split("/");
  React.useEffect(() => {
    if (props.changeColorOnScroll && (currentLocation[1] === "" || currentLocation[1] === "about" || currentLocation[1] === "vipprograms" || currentLocation[1] === "faq" || currentLocation[1] === "contact")) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll && (currentLocation[1] === "" || currentLocation[1] === "about" || currentLocation[1] === "vipprograms" || currentLocation[1] === "faq" || currentLocation[1] === "contact")) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  }); 
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);

      document.body
          .getElementsByClassName("topheadercl")[0]
          .setAttribute("style", "display:none;");

      document.body
          .getElementsByClassName(classes.toolbarafter)[0]
          .classList.add("removeAfterEffect");

    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
      document.body
          .getElementsByClassName("topheadercl")[0]
          .setAttribute("style", "display:block;");

      document.body
          .getElementsByClassName(classes.toolbarafter)[0]
          .classList.remove("removeAfterEffect");
    }
  };


  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  Transition.displayName = "Transition";


  const { color,  fixed, absolute } = props;
  
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  }); 

 

  var checkToggle = 0;
  // function signinnotifi() {
  //   if(checkToggle === 0){
  //     checkToggle = 1;
  //     document.body
  //         .getElementsByClassName("signinnotificationbox")[0]
  //         .setAttribute("style", "display:block;");
  //   }else{
  //     checkToggle = 0;
  //     document.body
  //         .getElementsByClassName("signinnotificationbox")[0]
  //         .setAttribute("style", "display:none;");
  //   }
  // }
  function signinsidebar() {
    if(checkToggle === 0){
      checkToggle = 1;
      document.body
          .getElementsByClassName("signinsidebarbox")[0]
          .setAttribute("style", "display:block;");
    }else{
      checkToggle = 0;
      document.body
          .getElementsByClassName("signinsidebarbox")[0]
          .setAttribute("style", "display:none;");
    }
  }
  function parseJwt(token) {
      if (!token) { return; }
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
  }
  function getUserName(){
    if(window.localStorage.getItem('jwt_access_token') != null && userName === "Menus"){
        let userData = parseJwt(window.localStorage.getItem('jwt_access_token'));
        if(userData.username != null){
          setUserName(userData.username);
        }
    }
  }
  getUserName();
  /*function signinsidebarbox() {
    if(checkToggle === 0){
      checkToggle = 1;
      document.body
          .getElementsByClassName("signinsidebarbox")[0]
          .setAttribute("style", "display:none;");
    }else{
      checkToggle = 0;
      document.body
          .getElementsByClassName("signinsidebarbox")[0]
          .setAttribute("style", "display:none;");
    }
  }*/
  
  
  
  function getCurrentThemeInfo(){
    if(window.localStorage.getItem('jwt_theme_token') != null){
        let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
        return themeData;
    }
  }

 
  var icon = null; 
  var brandName = null;
  var themeInfo = getCurrentThemeInfo();
  function getCurrentLoginInfo(){
    if(window.localStorage.getItem('jwt_access_token') != null){
        let themeData = parseJwt(window.localStorage.getItem('jwt_access_token'));
        return themeData;
    }
  } 
  // var loginInfo = getCurrentLoginInfo();
  // if((sessionStorage.getItem('welcome_message') === null || sessionStorage.getItem('welcome_message') === undefined || sessionStorage.getItem('welcome_message') === false)  && jwtService.isAuthenticationLogin() === true && loginInfo && loginInfo.username != null && loginInfo.username !== undefined && themeInfo && themeInfo.brand_name != null && themeInfo.brand_name !== undefined){
  //   if(sectionpop && isAlreadyOpen === false){
  //     refreshBalance();
  //     isAlreadyOpen = true;
  //     sectionpop.openPopupWithButton("popup", "Hey " + (loginInfo.username), "Welcome to " + (themeInfo.brand_name).toUpperCase());
  //     sessionStorage.setItem('welcome_message', true);
  //   }
  // }
  // if(themeInfo != null && themeInfo.id != null){ 
  //     if(themeInfo.icon != null && themeInfo.icon.length > 0){
  //       icon = themeInfo.icon;
  //     } 
  //     if(themeInfo.brand_name != null && themeInfo.brand_name.length > 0){
  //       brandName = themeInfo.brand_name;
  //     } 
  // }



  
   
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1000);
  React.useEffect(() => {
    window.addEventListener("resize", () => {
        const ismobile = window.innerWidth < 1000;
        if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
}, [isMobile]);


  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", hhiddeheader);
    }
    return function cleanup() {
      if (props.changeColorOnScroll ) {
        window.removeEventListener("scroll", hhiddeheader);
      }
    };
  });  
 
  const hhiddeheader = () => {
    const {  changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
      .getElementsByClassName("fixheadermobilehide")[0]
      .setAttribute("style", "display:none;");  
      document.body
      .getElementsByClassName("fixheadermobile")[0]
      .setAttribute("style", "display:block ;");
    }else {
      document.body
      .getElementsByClassName("fixheadermobilehide")[0]
      .setAttribute("style", "display:block;"); 
      document.body
      .getElementsByClassName("fixheadermobile")[0]
      .setAttribute("style", "display:none ;"); 
    }
  } 
  // function showHeader(){
  //   document.body
  //   .getElementsByClassName("fixheadermobilehide")[0]
  //   .setAttribute("style", "display:block;"); 
  //   document.body
  //   .getElementsByClassName("fixheadermobile")[0]
  //   .setAttribute("style", "display:none;");
  //   document.body
  //   .getElementsByClassName("fixheadermobile1")[0]
  //   .setAttribute("style", "display:block;");
  // } 
  // function hideHeader(){
  //   document.body
  //   .getElementsByClassName("fixheadermobilehide")[0]
  //   .setAttribute("style", "display:none;"); 
  //   document.body
  //   .getElementsByClassName("fixheadermobile1")[0]
  //   .setAttribute("style", "display:none;");
  //   document.body
  //   .getElementsByClassName("fixheadermobile")[0]
  //   .setAttribute("style", "display:block;");
  // } 

  function createMarkup(text) { return {__html: text}; };
  
  return ( 
    <PageVisibility onChange={onFocus}>
    <div className={classes.headersignin } id="top">
    <div className="fixheadermobilehide">
    <AppBar className={appBarClasses +" "+ classes.headersigninfix }>
      <section className={classesr.topheader + " topheadercl"}>
        <GridContainer className={" toperheadercl"}>
          <GridItem xs={1} sm={1} md={4}> 

            {(() => {
              //   alert(JSON.stringify(themeInfo.icon))
                  if(themeInfo.icon !== "null" && (themeInfo.icon !== "undefined" && themeInfo.icon.length > 0)) { 
                          return (
                            <a href="/">
                              <img src={icon} height={"60px"} alt={""} className={classes.imglogo2} />
                              <i className={"fa fa-home  " +  classes.himeicon}></i> 
                            </a>
                  ) }else{
                    return (
                         <a href="/">
                           <h4 className={classes.logoname}>{brandName}</h4>
                           <i className={"fa fa-home  " +  classes.himeicon}></i> 
                         </a>
                      )
                    }
                    })()}
          </GridItem>
          <GridItem xs={11} sm={11} md={8}>
            <div className={classes.rightcontent2 + " rightcontenttop"}>

             
            
              <div className={classes.userdetaildiv}>

            

              {usermenuDb[sessionStorage.getItem('lang')].map((jsondata) => (
                <CustomDropdown
                    noLiPadding
                    key={jsondata.id}
                    themeColors={props.themeColors}
                    buttonText={[<i className={"fa fa-user  " + classes.userdetailicon} key={"ik1"}></i>, <p className={classes.userdetailname} key={"ik2"}>{userName}</p>]}
                    buttonProps={{
                      className: classes.navLink + " " + classes.usersigninbtn,
                      color: "transparent"
                    }}
                    dropdownList={jsondata.submenu.map((jsondata) => (
                      <a
                          href={jsondata.submenu_path}
                          rel="noopener noreferrer"
                          className={classes.usersigninanker}
                          key={jsondata.id}
                      >
                        <i className={jsondata.submenu_class}></i>&nbsp;  {jsondata.sub_menu_name}
                      </a> 
                    ))}
                />
              ))}
              </div>
              <div className={classes.contryflagdiv}>
              <LanguageSelector  themeColors={props.themeColors} {...props} />
                
              </div>
             
              <div className={classes.rightsidebardiv}>
                <Button  
                    onClick={signinsidebar}
                    className={classes.rightsidebarbtn}
                >
                  <i className={"fa fa-bars"}></i>
                </Button> 
                

                <div onClick={signinsidebar} className={classes.signinsidebarbox + " signinsidebarbox"}> 
                  <div className={classes.signinheadersidebar}>
                    <div
                        className={classes.listheadsidebartop}
                    >
                      <img src={icon} height={"60px"} alt={""} className={classes.listheadsidebarlogo} />
                      {menusidebartopDb[sessionStorage.getItem('lang')].map((jsondata) => (
                        <div key={jsondata.id}>
                        <h5>{userName}</h5> 
                        </div>
                      ))}
                    </div>
                    <div  className={"signinsidebarboxheight"} style={{overflow: 'auto',maxHeight: '70%'}}>
                      <List className={classes.listheadsidebar}> 
                      {dataSidebar.map((jsondata) => ( 
                            <ListItem className={classes.listheadsidebaritem} key={jsondata.id}>
                              <Button
                                  href={jsondata.path}  
                                  color="inherit"

                                  className={classes.listheadsidebaranker}
                              >
                                  
                                <span dangerouslySetInnerHTML={createMarkup(jsondata.menu_name)} />
                              </Button>
                            </ListItem>
                        ))}
                      </List>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           <div className={classes.balanceboxwrap + " swingimage balanceboxwrapcasino"}>
              <Button
                  href="/deposit"
                  color="inherit"

                  className={classes.balanceamount}
              >
                <i className={classes.coinbalance + "  fa fa-credit-card "} />
                {(isNaN(myBalance)) ? myBalance : parseFloat(myBalance).toFixed(2)}
                <i className={classes.plusbalance + "  fa fa-plus"} />
              </Button>
              <Button
                  href=""
                  color="inherit"
                  onClick={refreshBalance}
                  className={classes.balancerefresh}
              >
                <i className={"fa fa-retweet  "} />
              </Button>
            </div>

          </GridItem>
        </GridContainer>

      </section>
      <div className={classes.headsigninbg}>
        <List className={classes.listsign}>
          {(() => {
            if(Array.isArray(dataOfMenu)) {
              return dataOfMenu.map((jsondata) => (
              <ListItem className={classes.listItemsign} key={jsondata.id}>
                <Button
                    href={jsondata.path}
                    color="inherit"

                    className={classes.menusignin}
                >
                  <i className={jsondata.menu_icon}></i>&nbsp;  {jsondata.menu_name}
                </Button>
              </ListItem>
          )) }else{
          return (
              <ListItem className={classes.listItemsign} key={dataOfMenu.id}>
                <Button
                    href={dataOfMenu.path}
                    color="inherit"

                    className={classes.menusignin}
                >
                  <i className={dataOfMenu.menu_icon}></i>&nbsp;  {dataOfMenu.menu_name}
                </Button>
              </ListItem>
          )
        }
        })()
      }

        </List>
      </div>
    </AppBar>
    </div>

    <Button  
      href="#top"   
      color="transparent"
      className={`${isMobile ? "mobile-class fixheadermobile" : "non-mobile-class fixheadermobile"}`}
    > 
        <i className={"fa fa-arrow-up"}></i> Top
    </Button>  
  </div>   
  
    
  </PageVisibility>
  );
}

/*eslint-disable*/
import React, {useEffect, useRef, useState} from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import {IconButton, MenuItem} from "@material-ui/core"; 
import People from "@material-ui/icons/People";
import Email from "@material-ui/icons/Email"; 
// react components for routing our app without refresh
import { Link, withRouter } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// @material-ui/core components
import {Button, Divider, Typography, InputAdornment, Icon} from '@material-ui/core';
import CustomInput from "../elements/CustomInput/CustomInput.js";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { NumberTextfield } from "react-number-textfield";
// core components
import CustomDropdown from "../elements/CustomDropdown/CustomDropdown.js";
import CustomButton from "../elements/CustomButtons/Button.js";
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';


import Formsy from 'formsy-react';
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import menuDb from "../data/menu.js";
import logo from "../assets/img/logo.png";
import GridItem from "../elements/Grid/GridItem";
import GridContainer from "../elements/Grid/GridContainer";
import styles from "../assets/jss/components/headerLinksStyle.js";
import stylesr from "../assets/jss/components/headerLinkstopStyle";

import * as authActions from '../../../auth/casino/actions';
import * as Gurl from '../../../global/Global.js';
import {useDispatch, useSelector} from 'react-redux';
import {TextFieldFormsy, SelectFormsy} from '../../../@casino';
import secsignupboxDb from "../data/secsignupbox.js";
//import { useTranslation } from 'react-i18next'
const history = createBrowserHistory()



let test = "ok";
let classicModalForgot = null;
let setClassicModalForgot = null;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

const validEmailRegex = 
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

function SectionForgotBox(props) {

    const dispatch = useDispatch();
    const [isFormValid, setIsFormValid] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [classAdd, setClassAdd] = useState(false);
    const [countryCode, setCountryCode] = React.useState(0);
    const formRef = useRef(null);
    const forgot = useSelector(({auth}) => auth.forgot);
    const [isCaptcha, setIsCaptcha] = useState(false);
    var captchaReceived = false;
    let recaptchaInstance;
    const [recaptchaCode, setRecaptchaCode] = React.useState("NOVALUE");
    styles.signinbtn.backgroundImage = props.themeColors.bgColors.main_color;
    styles.signinbtn.color = props.themeColors.bgColors.text_primary_in_color;
    styles.modalHeader.background = props.themeColors.bgColors.primary_bgcolor;
    styles.modalBody.background = props.themeColors.bgColors.primary_bgcolor;
    styles.modalFooter.background = props.themeColors.bgColors.primary_bgcolor;
    styles.modalSubtitle.color = props.themeColors.bgColors.text_primary_color;
    styles.signupbixp.color = props.themeColors.bgColors.text_primary_color; 
 
    styles.signinmodalbtn.backgroundImage = props.themeColors.bgColors.main_color;
    styles.signinmodalbtn["&:hover"].backgroundImage = props.themeColors.bgColors.main_color;
    styles.signinmodalbtn.color = props.themeColors.bgColors.text_primary_in_color;

    styles.logoname.fontFamily = props.themeColors.bgColors.logo_font 
    styles.modalTitle.fontFamily = props.themeColors.bgColors.base_font 
    styles.modalSubtitle.fontFamily = props.themeColors.bgColors.base_font 
    styles.form["& label"].fontFamily = props.themeColors.bgColors.base_font 
    styles.form["& input"].fontFamily = props.themeColors.bgColors.base_font  
    styles.signinmodalbtn.fontFamily = props.themeColors.bgColors.base_font  
   // const { t } = useTranslation()
    let currentStyle = styles;
    const useStyles = makeStyles(styles);

    const classes = useStyles();

    if(isPopupOpen === "stop"){
        setClassicModalForgot(false);
        props.LoginBox.openPopup("login");
        setIsPopupOpen(false);
    }
   useEffect(() => {
        if ( forgot.success != null && forgot.success == false && forgot.error && (forgot.error.email || forgot.error.username || forgot.error.recaptcha ||  forgot.error.message) )
        {
            resetRecaptcha();
            if(forgot.error.email || forgot.error.username){
                delete forgot.error["message"];
                formRef.current.updateInputsWithError({
                    ...forgot.error
                });
            }else if(forgot.error.message != null){
                setMessage(forgot.error.message);   
                setClassAdd(classes.msg = " successmsg" );
                
            }
            disableButton();
            captchaReceived = false;
            setRecaptchaCode("NOVALUE");
            
        }else{
            if(forgot.success != null && forgot.success == true && forgot.data != null){
                if(recaptchaInstance){
                    recaptchaInstance.reset();
                }
                if(formRef){
                    formRef.current.reset();
                }
                setTimeout(function() { 
                    setClassicModalForgot(false);
                    setClassAdd(classes.msg = " successmsg" )
                    setMessage("");
                    props.LoginBox.openPopup("login");
                    setIsPopupOpen(false); 
                  }, 5000); 

                setClassAdd(classes.msg = " successmsg" )
                if(forgot.data.message){
                    setMessage(forgot.data.message);
                }
            }
            
        }
    }, [forgot, forgot.error]);

    const resetRecaptcha = () => {
        recaptchaInstance.reset();  
      };
      var verifyCallback = function (response) {
        setRecaptchaCode(response);
        captchaReceived = true;
        if(response && isCaptcha === true){
          enableButton();
        }else{
          setIsFormValid(false);
        }
      };
      var gcErrorCallback = function (issue){
        disableButton();
        captchaReceived = false;
        setRecaptchaCode("NOVALUE");
     };

    function disableButton()
    {
        setIsFormValid(false);
        document.body
        .getElementsByClassName("signupbtnnew")[0]
          .setAttribute("style", "opacity:0.5;");
    }

    function enableButton()
    {
        if((recaptchaCode !== "NOVALUE" && recaptchaCode.length > 0) || captchaReceived === true){
            setIsFormValid(true);
            document.body
            .getElementsByClassName("signupbtnnew")[0]
              .setAttribute("style", "opacity:1;");
          }else{
            setIsCaptcha(true);
          }

         
    }

    function handleSubmit(model)
    { 
        if(model.username == null && model.email == null && model.mobile == null){
            setClassAdd(classes.msg = " errormsg" );
            setMessage("Please fillup atleast one field");
        }else{
            setMessage("");
            model = {"recaptcha": recaptchaCode, ...model}
            dispatch(authActions.submitForgot(model,setIsPopupOpen,props));
        }
        /*
        dispatch(authActions.submitRegister(model,setIsPopupOpen,props));

        document.body
        .getElementsByClassName("message_register")[0]
        .innerHTML=" " */   
    }

    function getcountrydata(){
    //    alert("dsfdfsds");
        const request = axios.post(Gurl.WLCOUNTRY_DATA, JSON.stringify({
            token: window.localStorage.getItem('jwt_access_token')
        }),{header:{"Content-Type": "application/x-www-form-urlencoded"}});
    
        request.catch((error) => {
            setCountryCode("0");
        }); 
        request.then((response) => { 
        
       //     alert("hkjhh"+JSON.stringify(response));
            
            if(response.data.data != null){ 
                
                var myOutPut = (
                    response.data.data.map((jsondata) => (
                        <MenuItem value={jsondata.id}>{jsondata.title} ({jsondata.phonecode})</MenuItem> 
                    ))
                );
                setCountryCode(myOutPut);
                //alert(JSON.stringify(gameType));
            };
        });  
             
    }   
    //alert(countryCode);   
    if(countryCode == "0"){
        setCountryCode("Processing");
        getcountrydata();
    }

     function parseJwt(token) {
      if (!token) { return; }
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
  }
  function getCurrentThemeInfo(){
    if(window.localStorage.getItem('jwt_theme_token') != null){
        let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
        return themeData;
    }
  }

 
  var icon = null; 
  var brandName = null; 
  var themeInfo = getCurrentThemeInfo();
  
  if(themeInfo != null && themeInfo.id != null){ 
      if(themeInfo.icon != null && themeInfo.icon.length > 0){
        icon = themeInfo.icon;
      } 
      if(themeInfo.brand_name != null && themeInfo.brand_name.length > 0){
        brandName = themeInfo.brand_name;
      } 
  }
  

  [classicModalForgot, setClassicModalForgot] = React.useState(false);
  return (
    <div>
     {secsignupboxDb[sessionStorage.getItem('lang')].map((jsondata) => (   
        
      <div key={jsondata.id}>
    <Dialog
    classes={{
        root: classes.center,
        paper: classes.modal + " " + classes.responsivemodell
    }}
    open={classicModalForgot}
    TransitionComponent={Transition}
    keepMounted
    onClose={() => setClassicModalForgot(false)}
    aria-labelledby="classic-modal-slide-title"
    aria-describedby="classic-modal-slide-description"
>
    <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
    >
        <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setClassicModalForgot(false)}
        >
            <Close className={classes.modalClose} />
        </IconButton>
       
    </DialogTitle>
    <Formsy
                onValidSubmit={handleSubmit}
                onValid={enableButton}
                onInvalid={disableButton}
                ref={formRef}
                autoComplete={"off"}
                className={classes.form + " flex flex-col justify-center w-full " + classes.modalBody}
            >
                
    <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
    > 

            <div style={{textAlign:"center"}}>
                {(() => {
                // alert(JSON.stringify(themeInfo))
                    if(themeInfo.icon != null && (themeInfo.icon != undefined && themeInfo.icon != "undefined" && themeInfo.icon.length > 0)) { 
                            return ( 
                                <img src={icon} height={"40px"} alt={""} className={classes.modellogo} /> 
                    ) }else{
                    return ( 
                            <h4 className={classes.logoname}>{brandName}</h4>  
                        )
                }
                })()}
                <h4 className={classes.modalTitle}>{jsondata.text_fp_btn}    </h4>
            </div>
         
            <GridContainer style={{marginLeft:'0'}}>
                <GridItem xs={12}><div className={classes.msg + " message_register " + classAdd} id="message">{message}</div></GridItem>
                <GridItem xs={12} sm={12} md={12} style={{padding:'0'}}>
                    <div className={classes.formggroup}>
                     <TextFieldFormsy 
                        label={jsondata.name}
                        id="username"
                        name="username"
                        formControlProps={{
                            fullWidth: true
                        }}
                        validations={{
                            minLength: 4
                        }}
                        validationErrors={{
                            minLength: 'Min character length is 4'
                        }}
                        
                        variant="outlined"
                    /> 
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} style={{padding:'0'}}>
                    <div className={classes.normaltxt}> 
                    {jsondata.text_or}   
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} style={{padding:'0'}}>
                <div className={classes.formggroup}>
                    <TextFieldFormsy
                        label={jsondata.email}
                        id="email"
                        name="email"
                        formControlProps={{
                            fullWidth: true
                        }}
                        validations={{
                            isEmail: true
                        }}
                        validationErrors={{
                            isEmail: 'Please enter a valid email'
                        }}
                        
                        variant="outlined"
                    /> 
                    </div>
                </GridItem>
                
            </GridContainer>

           
            <ReCAPTCHA 
                   ref={e => recaptchaInstance = e}  
                   onChange={verifyCallback}
                   className={classes.receptadiv}
                   theme="dark"
                   onExpired={gcErrorCallback}
                   onErrored={gcErrorCallback}
                   sitekey={Gurl.reCaptchaKey}
                   name="recaptcha"  
                  />  



    </DialogContent>
    <DialogActions className={classes.modalFooter}>
        <Button color="primary" className={classes.signinmodalbtn +" signupbtnnew"} type="submit"  disabled={!isFormValid}>
        {jsondata.text_fp_btn}  
        </Button>
       
    </DialogActions>
    </Formsy>                  
</Dialog>
</div>
))}
</div>
  );
}

SectionForgotBox.propTypes = {
    openPopup: function(isState){
        if(isState === "forgot"){
            setClassicModalForgot(true);
          }  
          if(isState === false){
            setClassicModalForgot(false);
          } 
    }
};
SectionForgotBox.openPopup = function(isState){
    if(isState === "forgot"){
        setClassicModalForgot(true);
      }  
      if(isState === false){
        setClassicModalForgot(false);
      } 
}
export default SectionForgotBox;


const tabnotificationeditDb = {
    en: [
        { 
            'id': '2342424424',   
            'noti_name1': 'Internal Messages', 
            'noti_name2': 'Push Notifications',       
            'noti_name3': 'Phone Call ',      
            'noti_name4': 'Email ',      
            'noti_name5': 'SMS',       
            'noti_yes': 'Yes',         
            'noti_no': 'No',   
            'editnotification':'Edit Notifications',
            'submitbtn':'Submit',
            'cancelbtn':'Cancel',  
        },
    ],
    tm: [
        { 
            'id': '2342424424',    
            'noti_name1': 'உள் செய்திகள்',
            'noti_name2': 'அறிவிப்புகளை அழுத்து',
            'noti_name3': 'தொலைபேசி அழைப்பு',
            'noti_name4': 'மின்னஞ்சல்',
            'noti_name5': 'SMS',
            'noti_yes': 'ஆம்',
            'noti_no': 'இல்லை',
            'editnotification': 'அறிவிப்புகளைத் திருத்து',
            'submitbtn': 'சமர்ப்பி',
            'cancelbtn': 'ரத்துசெய்',
        },
    ],
    kn: [
        { 
            'id': '2342424424',    
            'noti_name1': 'ಆಂತರಿಕ ಸಂದೇಶಗಳು',
            'noti_name2': 'ಅಧಿಸೂಚನೆಗಳನ್ನು ತಳ್ಳಿರಿ',
            'noti_name3': 'ಫೋನ್ ಕರೆ',
            'noti_name4': 'ಇಮೇಲ್',
            'noti_name5': 'SMS',
            'noti_yes': 'ಹೌದು',
            'noti_no': 'ಇಲ್ಲ',
            'editnotification': 'ಅಧಿಸೂಚನೆಗಳನ್ನು ಸಂಪಾದಿಸಿ',
            'submitbtn': 'ಸಲ್ಲಿಸು',
            'cancelbtn': 'ರದ್ದುಮಾಡು',
        },
    ],
    ne: [
        { 
            'id': '2342424424',    
            'noti_name1': 'आन्तरिक सन्देशहरू',
            'noti_name2': 'पुश सूचनाहरू',
            'noti_name3': 'फोन कल',
            'noti_name4': 'ईमेल',
            'noti_name5': 'SMS',
            'noti_yes': 'हो',
            'noti_no': 'होइन',
            'editnotification': 'सूचनाहरू सम्पादन गर्नुहोस्',
            'submitbtn': 'बुझाउनुहोस्',
            'cancelbtn': 'रद्द गर्नुहोस्',
        },
    ],
    tl: [
        { 
            'id': '2342424424',   
            'noti_name1': 'అంతర్గత సందేశాలు', 
            'noti_name2': 'పుష్ నోటిఫికేషన్‌లు',       
            'noti_name3': 'ఫోన్ కాల్ ',      
            'noti_name4': 'ఇమెయిల్',      
            'noti_name5': 'SMS',       
            'noti_yes': 'అవును',         
            'noti_no': 'లేదు',   
            'editnotification':'నోటిఫికేషన్‌లను సవరించండి',
            'submitbtn':'సమర్పించండి',
            'cancelbtn':'రద్దు చేయండి',  
        },
    ],
    hi: [
        { 
            'id': '2342424424', 
            'noti_name1': 'आंतरिक संदेश',     
            'noti_name2': 'सूचनाएं भेजना',      
            'noti_name3': 'फ़ोन कॉल ',     
            'noti_name4': 'ईमेल',       
            'noti_name5': 'एसएमएस',       
            'noti_yes': 'हाँ',     
            'noti_no': 'नहीं',   
            'editnotification':'सूचनाएं संपादित करें',
            'submitbtn':'प्रस्तुत',
            'cancelbtn':'रद्द करना',   
        },
    ]
}
export default tabnotificationeditDb;



const accountsDb = {
    en: [
        {
            'id':'3923923894',
           'pageheading': 'My Bets', 
            'btn_one': 'All Bets', 
            'btn_two': 'Open',
            'btn_three':'Won',
            'btn_four': "Lost",
            'btn_five': "Returned",
            'btn_six': "Won Returned",
            'betid': "Bet Id",
            'bettype': "Bet Type",
            'period': 'Period',
            'start_date': 'Start Date',
            'end_date': 'End Date', 
            'showbtn':'Show',
        },
        
    ],
    tl: [
        {
            'id':'3923923894',
            'pageheading': 'నా బెట్స్', 
            'btn_one': 'అన్ని బెట్స్', 
            'btn_two': 'తెరవండి',
            'btn_three':'గెలిచింది',
            'btn_four': "కోల్పోయిన",
            'btn_five': "తిరిగి వచ్చింది",
            'btn_six': "గెలిచింది",
            'betid': "పందెం ఐడి",
            'bettype': "పందెం రకం",
            'period': 'కాలం',
            'start_date': 'ప్రారంబపు తేది',
            'end_date': 'చివరి తేది', 
            'showbtn':'చూపించు',
        },
        
    ],
    hi: [
        {
            'id':'3923923892',
             'pageheading': 'मेरे दांव', 
            'btn_one': 'सभी दांव', 
            'btn_two': 'खुला हुआ',
            'btn_three':'जीत लिया',
            'btn_four': "खो गया",
            'btn_five': "लौटाया हुआ",
            'btn_six': "जीत गए",
            'betid': "शर्त आईडी",
            'bettype': "शर्त प्रकार",
            'period': 'अवधि',
            'start_date': 'आरंभ करने की तिथि',
            'end_date': 'अंतिम तिथि',  
            'showbtn':'प्रदर्शन',   
        },
    ]
}
export default accountsDb;

import React, {useRef} from "react";
// @material-ui/core components
import 'react-accessible-accordion/dist/fancy-example.css';

import axios from 'axios';
import * as globalurl from '../../../global/Global.js'; 

import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";
import Formsy from 'formsy-react'; 
// import LoginHistoryList from "../sections/LoginHistoryList";
import StatementList from "../sections/SectionGameStatementList";

import styles from "../assets/jss/components/tabsStatementstyle.js";
import Button from "../elements/CustomButtons/Button"; 
import statementDb from "../data/statement.js"; 
// import ListItem from "@material-ui/core/ListItem";
// import List from "@material-ui/core/List";
import {TextFieldFormsy, SelectFormsy} from '../../../@casino';
// import { useTranslation } from 'react-i18next'
import {MenuItem} from '@material-ui/core'; 
import mybetDb from "../data/mybet.js";

const useStyles = makeStyles(styles);
  


export default function MyBets(props) {
    const paymentType = "8";
    const [betType, setBetType] = React.useState(false);
    const durationType = "NO";
    const [currentBetType, setCurrentBetType] = React.useState("0");
    const [currentTypeOfBet, setCurrentTypeOfBet] = React.useState(0);
    const [betId, setBetId] = React.useState("");
    const formRef = useRef(null); 
    if(props.themeColors != null){
        styles.messagebox.background = props.themeColors.bgColors.secondary_dark_bgcolor;
        styles.listItem["&:nth-child(even)"].background = props.themeColors.bgColors.primary_dark_bgcolor;
        styles.listItem.color = props.themeColors.bgColors.text_primary_color;
    
        styles.signinnerpagebox.background = props.themeColors.bgColors.primary_bgcolor;
        styles.signinnerpageboxin.background = props.themeColors.bgColors.secondary_dark_bgcolor;
        styles.depositbtn.backgroundImage = props.themeColors.bgColors.main_color;
        styles.depositbtn.color = props.themeColors.bgColors.text_primary_in_color;

        styles.pageheadinginner.color = props.themeColors.bgColors.text_primary_color;
        styles.selectboxx["& label"].color = props.themeColors.bgColors.text_primary_color;
        styles.formtodate["& label"].color = props.themeColors.bgColors.text_primary_color;
        styles.formtodate["& input"].color = props.themeColors.bgColors.text_primary_color; 

        styles.tablewrap["& th"].background = props.themeColors.bgColors.primary_bgcolor; 
        styles.tablewrap["& tr:nth-child(2n)"].background = props.themeColors.bgColors.primary_dark_bgcolor; 
        styles.tablewrap.color = props.themeColors.bgColors.text_primary_color;  

        styles.statementbox.background = props.themeColors.bgColors.secondary_dark_bgcolor;
         styles.btntabwarp["& .active"].background = props.themeColors.bgColors.main_color; 
         styles.btntabwarp["& .tabbtn"].background = props.themeColors.bgColors.primary_bgcolor;  
         styles.btntabwarp["& .tabbtn"].color = props.themeColors.bgColors.text_primary_color;  
         styles.btntabwarp["& .tabbtn"].borderColor = props.themeColors.bgColors.text_secondary_color; 
         styles.btntabwarp["& .active"].color = props.themeColors.bgColors.text_primary_in_color;  
         styles.tableheading.background = props.themeColors.bgColors.primary_bgcolor; 
        styles.tableheading.color = props.themeColors.bgColors.text_primary_color;
        
        styles.pageheadinginner.fontFamily = props.themeColors.bgColors.base_font;  
        styles.selectboxx["& label"].fontFamily = props.themeColors.bgColors.base_font;  
        styles.selectboxx["& input"].fontFamily = props.themeColors.bgColors.base_font;  
        styles.formtodate["& label"].fontFamily = props.themeColors.bgColors.base_font;  
        styles.formtodate["& input"].fontFamily = props.themeColors.bgColors.base_font;  
        styles.listItem["& p"].fontFamily = props.themeColors.bgColors.base_font; 
        styles.listItem.fontFamily = props.themeColors.bgColors.base_font;  
    }
    

    const dateNow = new Date(); // Creating a new date object with the current date and time
    const year = dateNow.getFullYear(); // Getting current year from the created Date object
    const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const month = // Setting current Month number from current Date object
        monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
        ? `0${monthWithOffset}`
        : monthWithOffset;
    const date =
        dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${dateNow.getUTCDate()}`
        : dateNow.getUTCDate();
    //dateNow.setDate(dateNow.getDate() - 7);
    var minDateNow = new Date(year, month - 6, date);
    const minyear = minDateNow.getFullYear(); // Getting current year from the created Date object
    const minmonthWithOffset = minDateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const minmonth = // Setting current Month number from current Date object
        minmonthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
        ? `0${minmonthWithOffset}`
        : minmonthWithOffset;
    const mindate =
        minDateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${minDateNow.getUTCDate()}`
        : minDateNow.getUTCDate();

    var oneDateNow = new Date();
    oneDateNow.setDate(oneDateNow.getDate() - 30);
    const oneyear = oneDateNow.getFullYear(); // Getting current year from the created Date object
    const onemonthWithOffset = oneDateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const onemonth = // Setting current Month number from current Date object
        onemonthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
        ? `0${onemonthWithOffset}`
        : onemonthWithOffset;
    const onedate =
    oneDateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${oneDateNow.getUTCDate()}`
        : oneDateNow.getUTCDate();

    var minSevenDateNow = new Date();
    minSevenDateNow.setDate(minSevenDateNow.getDate() - 7);
    const min7year = minSevenDateNow.getFullYear(); // Getting current year from the created Date object
    const min7monthWithOffset = minSevenDateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const min7month = // Setting current Month number from current Date object
        min7monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
        ? `0${min7monthWithOffset}`
        : min7monthWithOffset;
    const min7date =
    minSevenDateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${minSevenDateNow.getUTCDate()}`
        : minSevenDateNow.getUTCDate();
    
    const MaxDateAvailable = `${year}-${month}-${date}`;
    const MinDateAvailable = `${minyear}-${minmonth}-${mindate}`;
    const OneMonthDateAvailable = `${oneyear}-${onemonth}-${onedate}`;
    const SevenDateAvailable = `${min7year}-${min7month}-${min7date}`;

    const [dateConfig, setDateConfig] = React.useState({start: SevenDateAvailable, end: MaxDateAvailable});
    
    

    function selectDurationType(event){
        //alert(JSON.stringify(event.target.value))
        if(event.target != null && event.target.value === "1"){
        setDateConfig({start: SevenDateAvailable, end: MaxDateAvailable});
        } else if(event.target != null && event.target.value === "2"){
        setDateConfig({start: OneMonthDateAvailable, end: MaxDateAvailable});
        } else if(event.target != null && event.target.value === "3"){
        setDateConfig({start: MinDateAvailable, end: MaxDateAvailable});
        }
    }
    function changeStartDate(event){
        if(event.target != null && event.target.value > dateConfig.end){
            setDateConfig({start: dateConfig.end, end: dateConfig.end});
        }else if(event.target != null && event.target.value < MinDateAvailable){
            setDateConfig({start: MinDateAvailable, end: dateConfig.end});
        }else if(event.target != null && event.target.value > MaxDateAvailable){
            setDateConfig({start: MaxDateAvailable, end: dateConfig.end});
        }else{
            setDateConfig({start: event.target.value, end: dateConfig.end});
        }
    }
    function changeEndDate(event){
        if(event.target != null && event.target.value < dateConfig.start){
            setDateConfig({start: dateConfig.start, end: dateConfig.start});
        }else if(event.target != null && event.target.value < MinDateAvailable){
            setDateConfig({start: dateConfig.start, end: MinDateAvailable});
        }else if(event.target != null && event.target.value > MaxDateAvailable){
            setDateConfig({start: dateConfig.start, end: MaxDateAvailable});
        }else{
            setDateConfig({start: dateConfig.start, end: event.target.value});
        }
    }

    if(betType === false){
        setBetType("PROCESSING");
        const request = axios.post(globalurl.MYGAMES , {
            token: window.localStorage.getItem('jwt_access_token')
        });

        request.then((response) => {
            
            if(response.data.data != null){
                setBetType(response.data.data);
            }
        });
        request.catch((error) => {
            setBetType(false);
        });
    }

    // const { t } = useTranslation()
    const classes = useStyles();

    function handleSubmit(model) { 
        
    }

    // const selectPaymentType = function(event){
    //     if(event.target != null && event.target.value != null){
    //         setPaymentType(event.target.value);
    //     }
    // }
 
    return (
        <div id="nav-tabs" className={classes.signinnerpagebox} >
            

            <div id="nav-tabs" className={classes.signinnerpageboxin} > 
            {mybetDb[sessionStorage.getItem('lang')].map((jsondata) => (
                    <GridContainer  >
                        <GridItem xs={12} sm={12} md={12}>
                         
            <h4 className={classes.pageheadinginner}>{jsondata.pageheading}</h4>
                            <div className={classes.statementbox}>
                                <div className={classes.statementfilterbox}>
                                    <div className={classes.btntabwarp}>  
                                        <Button
                                            color="transparent"
                                            onClick={() => setCurrentTypeOfBet(0)}
                                           
                                            className={classes.tabbtn + " tabbtn " + ((currentTypeOfBet === 0) ? " active" : "")}
                                        >
                                       {jsondata.btn_one}
                                        </Button>
                                        <Button
                                            color="transparent"
                                            onClick={() => setCurrentTypeOfBet(1)}
                                            className={classes.tabbtn + " tabbtn" + ((currentTypeOfBet === 1) ? " active" : "")}
                                        >
                                        {jsondata.btn_two}
                                        </Button>
                                        <Button
                                            color="transparent"
                                            onClick={() => setCurrentTypeOfBet(2)}
                                            className={classes.tabbtn + " tabbtn" + ((currentTypeOfBet === 2) ? " active" : "")}
                                        >
                                        {jsondata.btn_three}
                                        </Button>
                                        <Button
                                            color="transparent"
                                            onClick={() => setCurrentTypeOfBet(3)}
                                            className={classes.tabbtn + " tabbtn" + ((currentTypeOfBet === 3) ? " active" : "")}
                                        >
                                        {jsondata.btn_four}
                                        </Button>
                                        <Button
                                            color="transparent"
                                            onClick={() => setCurrentTypeOfBet(4)}
                                            className={classes.tabbtn + " tabbtn" + ((currentTypeOfBet === 4) ? " active" : "")}
                                        >
                                       {jsondata.btn_five}
                                        </Button>
                                        <Button
                                            color="transparent"
                                            onClick={() => setCurrentTypeOfBet(5)}
                                            className={classes.tabbtn + " tabbtn" + ((currentTypeOfBet === 5) ? " active" : "")}
                                        >
                                        {jsondata.btn_six}
                                        </Button>
                                    </div>
                                    <Formsy   
                                        onValidSubmit={handleSubmit}
                                        ref={formRef}
                                        className={classes.form + " flex flex-col justify-center w-full"}
                                    > 
                                        <GridContainer>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <TextFieldFormsy 
                                                className={classes.formtodate + " MuiInputBase-input MuiOutlinedInput-input"}
                                                label={jsondata.betid}
                                                name="bet_id"
                                                formControlProps={{
                                                    fullWidth: true
                                                }} 
                                                onChange={(event) => setBetId(event.target.value)}
                                                placeholder="#"
                                                value=""
                                                variant="outlined" 
                                            />  
                                          
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <SelectFormsy 
                                                    name="bet_type" 
                                                    label={jsondata.bettype}
                                                    className={classes.selectboxx + " selectbox"}  
                                                    onChange={(event) => setCurrentBetType(event.target.value)}
                                                    value={((currentBetType === "0") ? 0 : currentBetType)}
                                                >   
                                                        <MenuItem  value="0">All</MenuItem> 
                                                        {(betType !== false && betType !== "PROCESSING" && betType !== null) ? betType.map((jsondata) => (   
                                                            <MenuItem key={jsondata.id} value={jsondata.game_id}>{jsondata.game_name}</MenuItem>
                                                        )): ""}    
                                                </SelectFormsy>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                            <SelectFormsy 
                                                    name="duration" 
                                                    label={jsondata.period}
                                                    className={classes.selectboxx + " selectbox"}
                                                    onChange={(event) => selectDurationType(event)}
                                                    value={((durationType === "NO") ? 1 : durationType)}
                                                >
                                                    {statementDb[sessionStorage.getItem('lang')][0].periodoptions.map((jsondata) => (   
                                                        <MenuItem key={jsondata.id} value={jsondata.id}>{jsondata.periodoption}</MenuItem>
                                                    ))}    
                                                </SelectFormsy>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                            <TextFieldFormsy 
                                                className={classes.formtodate + " MuiInputBase-input MuiOutlinedInput-input"}
                                                label={jsondata.start_date}
                                                name="start_date"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                type="date"
                                                defaultValue={dateConfig.start}
                                                onChange={(event) => {changeStartDate(event)}}
                                                value={dateConfig.start}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                InputProps={{inputProps: { min: MinDateAvailable, max: MaxDateAvailable} }}
                                                variant="outlined"
                                                required
                                            />  
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                            <TextFieldFormsy 
                                                className={classes.formtodate + " MuiInputBase-input MuiOutlinedInput-input"}
                                                label={jsondata.end_date}
                                                name="end_date"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                type="date"
                                                defaultValue={dateConfig.end}
                                                onChange={(event) => {changeEndDate(event)}}
                                                value={dateConfig.end}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                InputProps={{inputProps: { min: MinDateAvailable, max: MaxDateAvailable} }}
                                                variant="outlined"
                                                required
                                            /> 
                                            </GridItem>
                                        </GridContainer>
                                    </Formsy>
                                </div>
                            </div>


                            <div className={classes.tablewrap}>
                                <div className={classes.depositlistwarp}>
                                    <div className={classes.depositlistw} >   
    
                                        <StatementList statement={true} paymentType={paymentType} betType={currentBetType} typeOfBet={currentTypeOfBet} betId={betId}  durationType={durationType}  startDate={dateConfig.start} endDate={dateConfig.end}/>
                                    </div>
                                </div>
                            </div>
                    


                        </GridItem>
                    </GridContainer>
            ))}
                  
            </div>
        </div>

    );
}

import React from "react";

// nodejs library that concatenates classes

// core elements
import Header from "./elements/Header/Header.js";
import Footer from "./elements/Footer/Footer.js";

import axios from 'axios';
import * as globalurl from '../../global/Global.js'; 
import HeaderLinks from "./elements/Header/HeaderLinks.js";

import './webroot/App.css';
// import HomePage from "./pages/home.js";
import {Route } from "react-router-dom"; 
import sectionpop from "./sections/SectionPopup";
import ComponentLoader from "./pages/componentloader.js";
import CookieConsent from "react-cookie-consent";
import themeDb from "./data/theme.js";  
import "./assets/scss/material-kit-react.scss?v=1.8.0";
import jwt from 'jsonwebtoken'; 
import jwtDecode from 'jwt-decode';
import jwtService from '../../services/jwtService' 
import  "./i18n";
const dashboardRoutes = [];




 

export default function LandingPage(props) {

    const { ...rest} = props;
    const { ...myTheme} = themeDb; 
    const [topMenus, setTopMenus] = React.useState(0);
    const { ...sectionPopup} = sectionpop;
    const currentLocation = props.location.pathname.split("/");
    var dataOfMenu = [];

    
      
     

    document.oncontextmenu = function (e) {
        console.log(e.button);
        if (e.button === 2) {
            e.preventDefault();
            return false;
        }
    
    }
    // document.onkeydown = function(e) {
    //     if (e.ctrlKey && (e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 85 || e.keyCode === 117)) {//Alt+c, Alt+v will also be disabled sadly.
    //        // alert('not allowed');
    //     }
    //     return false;
    // };
    async function getDataNow(){
        dataOfMenu = await getDataFromServer();
        if(dataOfMenu.data != null){
            setHeaderData({TopMenu: dataOfMenu.data}, "header_access_token");
            setTopMenus(dataOfMenu.data);
        }
    }
    async function getDataFromServer(){
        let pro = new Promise((resolve,reject) => {
            const request = axios.post(globalurl.GETMENUS , {
                token: window.localStorage.getItem('jwt_access_token'),
                menutypes: 8
            });
            request.then((response) => {
                if(response.data.data != null){
                    var data = {
                        "status":true,
                        "data":response.data.data
                    }
                    resolve(data);
                }else{
                    resolve({"status":false, "message":'No record found'});
                }
            });
            request.catch((error) => {
                reject(error);
            });
        }); 
        const val = await pro;
        return val;
    }
    function setHeaderData(data, access_token_name){
        var jwtConfig = {
            "secret"   : "324934dsfj786ykjhgiyyi4439wri",
            "expiresIn": "1 hours" // A numeric value is interpreted as a seconds count. If you use a string be sure you provide the time units (days, hours, etc)
        };
        const access_token = jwt.sign(data, jwtConfig.secret, {expiresIn: jwtConfig.expiresIn});
        window.localStorage.setItem(access_token_name, access_token);
    }
    if(topMenus === 0){
        let headerData = false; 
        if(window.localStorage.getItem("header_access_token") != null){
            const decodedData = jwtDecode(window.localStorage.getItem("header_access_token"));
            const currentTime = Date.now() / 1000;
            let tokenExpired = false;
            if ( decodedData.exp < currentTime ){
                tokenExpired = true;
            }
            if(tokenExpired === true && navigator != null && navigator.onLine != null && navigator.onLine !== true){
                tokenExpired = false;
            }
            if (tokenExpired === false){
                if(decodedData.TopMenu != null){
                    setTopMenus(decodedData.TopMenu);
                    headerData = true;
                }  
            }
        }
        if(headerData === false){
            getDataNow();
        }
    }else{
        if(Array.isArray(topMenus)){
            dataOfMenu = topMenus;
          }else{
            dataOfMenu[0] = topMenus;
          }
    } 
            var colorSplit = "#000000";
            if(themeDb.bgColors.primary_bgcolor !== null){
                colorSplit = themeDb.bgColors.primary_bgcolor.split(" ");
            } 
    return (

        <div 
            key="dzfzxc" 
            className={"sporto " + ((currentLocation[1] !== null) ? currentLocation[1] : "")}
            ref={(el) => {
                if (el && colorSplit[1] !== null && colorSplit[1] !== "!important") {
                  el.style.setProperty('background-color', ((colorSplit[0] !== null) ? colorSplit[0] : "#000000"), '!important');
                }else if (el && colorSplit[1] !== null && colorSplit[1] !== "important") {
                    el.style.setProperty('background-color', ((colorSplit[0] !== null) ? colorSplit[0] : "#000000"), 'important');
                }else{
                   // alert(themeDb.bgColors.primary_bgcolor);
                   if(el){
                    el.style.setProperty('background-color', ((themeDb.bgColors.primary_bgcolor !== null) ? themeDb.bgColors.primary_bgcolor : "#000000"), '#000000');
                   }
                }
              }}
        >
            
          {/* <div className="cookiesbox">
        <CookieConsent 
            style={{
            margin: '0',
            fontFamily: 'Open Sans',
            fontSize:'14px',   
            }} 
            enableDeclineButton flipButtons  
            >
            Third Party Cookies Policy
            <p style={{ margin: '0', fontSize:'12px', fontFamily: 'Open Sans'}}>Our website uses cookies to make your browsing experience better. By using our site you agree to our use of cookies.</p>
        </CookieConsent>   
        </div>   
         */}
          
         
        
        <div >
            
            <Header
                color="transparent"
                routes={dashboardRoutes}
                brand="iSoft Gaming"
                rightLinks={<HeaderLinks themeColors={myTheme} {...rest}/>}
                fixed
                changeColorOnScroll={{
                    height: 30,
                    color: "rose"
                }}
                sectionPopup={sectionPopup}
                themeColors={myTheme} 
                {...rest}
                {...props}
            />
            {(() => {
                const currentLocation = props.location.pathname.split("/");
                if(jwtService.isAuthenticationLogin() === true){
                    return(
                        <div> 
                        <Route path={"/"+currentLocation[1]}>
                            <ComponentLoader themeColors={myTheme} sectionPopup={sectionPopup} {...props}/>
                        </Route>
                         
                        
                        </div>
                    )  
                }else{
                    if(currentLocation[1].length > 0){
                        return(
                            <div>
                            <Route path={"/"+currentLocation[1]}>
                                <ComponentLoader themeColors={myTheme} sectionPopup={sectionPopup} {...props}/>
                            </Route>
                
                            
                            </div>
                        )
                    }else{
                        return(
                            <div>
                            <Route path={"/"}>
                                <ComponentLoader themeColors={myTheme} sectionPopup={sectionPopup} {...props}/>
                            </Route>
                            
                            </div>
                        )
                    }
                    
                }
            })()}
             
            
            <Footer
            key="157"
            sectionPopup={sectionPopup}
                themeColors={myTheme}
                {...rest}/>
        </div>    
        <div style={{clear:"both"}}></div>
        </div>
    );
}
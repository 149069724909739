import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
 

import SectionGame from "../pages/sectiongame.js"; 
import Games from "../pages/games.js";
import RecentGames from "../pages/recentgames.js";

import styles from "../assets/jss/components/tabsDashboardGames.js";
 
 

export default function Dashboard(props) {

     
 
    const useStyles = makeStyles(styles);
    const classes = useStyles();
  //alert(Gurl.MYGAMES);
 
    return (
        <div id="nav-tabs" className={classes.signinnerpagebox} >

        <div id="nav-tabs" className={classes.signinnerpageboxin} > 
                     
        <div id="nav-tabs" className={classes.dashboardgames}>
        
        <SectionGame themeColors={props.themeColors} />

        <Games themeColors={props.themeColors} gameIds={"35, 36, 37, 38, 39, 40, 41, 42"} />

        <RecentGames  themeColors={props.themeColors} />
        
        </div>



        </div></div>
                    );
     
}
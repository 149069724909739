import React from 'react';
import {FormControl, FormControlLabel, FormHelperText} from '@material-ui/core';
import {withFormsy} from 'formsy-react';
import _ from '../../../@lodash';
import Switch from "react-switch";

function SwitchFormsy(props)
{
    const importedProps = _.pick(props, [
        'checkedIcon',
        'classes',
        'color',
        'disabled',
        'disableRipple',
        'icon',
        'id',
        'name',
        'indeterminate',
        'indeterminateIcon',
        'inputProps',
        'inputRef',
        'onChange',
        'variant',
        'value',
        'checked',
        'data'
    ]);

    // An error message is returned only if the component is invalid
    const errorMessage = props.errorMessage;
    function changeValue(event, c, d)
    {
        //alert(event + ":::" + c + ":::" + d)
        if(event === true){
            props.setValue(props.data);
        }else{
            props.setValue("");
        }
        if(props.onClick != null && event === true){ 
            props.onClick();
        }
    }
   
    return (
        <FormControl error={Boolean(errorMessage)} className={props.className}>
            <FormControlLabel
                control={
                    <Switch 
                        {...importedProps}
                        onChange={(e, c, d) => changeValue(e, c, d)}
                        checked={props.checked}
                        value={props.value}
                    />
                }
                label={props.label}
            />
            {Boolean(errorMessage) && (
                <FormHelperText>{errorMessage}</FormHelperText>
            )}
        </FormControl>
    );
}

export default React.memo(withFormsy(SwitchFormsy));

/* eslint-disable no-mixed-operators */

import React, {useEffect, useRef, useState} from "react";
 
// @material-ui/core components
// import {
//     Accordion,
//     AccordionItem,
//     AccordionItemHeading,
//     AccordionItemButton,
//     AccordionItemPanel,
// } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import axios from 'axios';
// import history from '../../../@history';
import * as globalurl from '../../../global/Global.js'; 
import * as authActions from '../../../auth/casino/actions';
import { makeStyles } from "@material-ui/core/styles";
import depositDb from "../data/deposit.js";
import withdrawpageDb from "../data/withdrawpage.js";

import ImgUpload from "./imgcompile.js";
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";
import CustomTabs from "../elements/CustomTabs/CustomTabs.js"; 
import designimg from "../assets/img/paypal.png";
// import designimg2 from "../assets/img/authorize.png";
import designimg3 from "../assets/img/paytm.png";
import designimg4 from "../assets/img/phonepay.png";
import designimg5 from "../assets/img/googlepay.png";
import styles from "../assets/jss/components/tabsDepositstyle.js";
import {useDispatch, useSelector} from 'react-redux';
import Button from "../elements/CustomButtons/Button";
import DepositStatementList from "../sections/SectionDepositList";
import { PayPalButton } from "react-paypal-button-v2";

// import paymentpic1 from "../assets/img/497110d.png";
// import paymentpic2 from "../assets/img/bcecb3f.png";
// import DialogTitle from "@material-ui/core/DialogTitle"; 
import {MenuItem} from "@material-ui/core"; 
// import Close from "@material-ui/icons/Close";
// import DialogContent from "@material-ui/core/DialogContent";
// import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
// import { useTranslation } from 'react-i18next'
import Formsy from 'formsy-react'; 
import {TextFieldFormsy, SelectFormsy} from '../../../@casino';
const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";
// function cc_format(value) {
//     var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
//     var matches = v.match(/\d{4,16}/g);
//     var match = matches && matches[0] || ''
//     var parts = []
//     for (var i=0, len=match.length; i<len; i+=4) {
//       parts.push(match.substring(i, i+4))
//     }
//     if (parts.length) {
//       return parts.join(' ')
//     } else {
//       return value
//     }
//   }
//   function cc_date(value) {
//     var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
//     var matches = v.match(/\d{2,4}/g);
//     var match = matches && matches[0] || ''
//     var parts = []
//     for (var i=0, len=match.length; i<len; i+=2) {
//       parts.push(match.substring(i, i+2))
//     }
//     if (parts.length) {
//       return parts.join('/')
//     } else {
//       return value
//     }
//   }
//   function cc_cvv(value) {
//     var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
//     var matches = v.match(/\d{3,3}/g);

//     var match = matches && matches[0] || ''
//     var parts = []
//     for (var i=0, len=match.length; i<len; i+=3) {
//       parts.push(match.substring(i, i+3))
//     }
//     if (parts.length) {
//       return parts.join('')
//     } else {
//       return value
//     }
//   } 
var gatewayAPI = false;
export default function Deposit(props) {
    const {...rest} = props;
    if(props.themeColors != null){
        styles.rupeemainbox.background = props.themeColors.bgColors.secondary_dark_bgcolor;
        styles.rupeemainbox["& h3"].color = props.themeColors.bgColors.text_secondary_color; 
        styles.dangerText.color = props.themeColors.bgColors.text_secondary_color;
        styles.tablerupee.color = props.themeColors.bgColors.text_secondary_dark_color;
        styles.tablerupee["& tr"].borderColor = props.themeColors.bgColors.primary_bgcolor;
        styles.tablerupee["& tr td"].borderColor = props.themeColors.bgColors.primary_bgcolor;
        styles.depositrupeeform.background = props.themeColors.bgColors.primary_bgcolor;
        styles.creditcardform.background = props.themeColors.bgColors.primary_bgcolor;
    
        styles.accordianbtn["& div"].background = props.themeColors.bgColors.primary_bgcolor;
        styles.accordianbtn["& div:hover"].background = props.themeColors.bgColors.primary_bgcolor;
        styles.alert.color = props.themeColors.bgColors.text_primary_dark_color;
        styles.alert.background = props.themeColors.bgColors.main_color_bg;
        styles.accordianiitm.background = props.themeColors.bgColors.primary_bgcolor;
        styles.accordianiitm.color = props.themeColors.bgColors.text_secondary_dark_color;
        styles.depositbtn.backgroundImage = props.themeColors.bgColors.main_color;
        styles.depositbtn.color = props.themeColors.bgColors.text_primary_in_color;
    
        styles.paymentbtn.backgroundColor = props.themeColors.bgColors.primary_bgcolor;
        styles.paymentbtn["&:hover"].background = props.themeColors.bgColors.main_color;
        styles.paymentbtn.color = props.themeColors.bgColors.text_primary_dark_color;
        styles.paymentbtnactive.background = props.themeColors.bgColors.main_color;
        styles.paymentbtnactive.color = props.themeColors.bgColors.text_primary_in_color;
    
        styles.qutionbtn["& i"].color = props.themeColors.bgColors.text_secondary_color;
        styles.rupeeguidebtn.backgroundImage = props.themeColors.bgColors.main_color;
        styles.rupeeguidebtn.color = props.themeColors.bgColors.text_primary_in_color;
        styles.modalHeader.background = props.themeColors.bgColors.primary_bgcolor;
        styles.modalBody.background = props.themeColors.bgColors.primary_bgcolor;
        styles.tablewarapbox.background = props.themeColors.bgColors.secondary_dark_bgcolor;
        styles.messagebox.background = props.themeColors.bgColors.secondary_dark_bgcolor;
        styles.listItem["&:nth-child(even)"].background = props.themeColors.bgColors.primary_dark_bgcolor;
        styles.listItem.color = props.themeColors.bgColors.text_primary_color;
        styles.selectboxx.color = props.themeColors.bgColors.text_primary_color;
        styles.depositform.color = props.themeColors.bgColors.text_primary_color;
        styles.depositform["& input"].color = props.themeColors.bgColors.text_primary_color;
        styles.selectboxx["& label"].color = props.themeColors.bgColors.text_primary_color;
        styles.formtodate["& label"].color = props.themeColors.bgColors.text_primary_color; 
        styles.formtodate["& input"].color = props.themeColors.bgColors.text_primary_color; 
        styles.signinnerpagebox.background = props.themeColors.bgColors.primary_bgcolor; 
        styles.signinnerpageboxin.background = props.themeColors.bgColors.primary_bgcolor; 

        styles.alert.fontFamily = props.themeColors.bgColors.base_font; 
        styles.depositform["& label"].fontFamily = props.themeColors.bgColors.base_font; 
        styles.depositform["& input"].fontFamily = props.themeColors.bgColors.base_font; 
        styles.depositbtn.fontFamily = props.themeColors.bgColors.base_font; 
        styles.formtodate["& input"].fontFamily = props.themeColors.bgColors.base_font; 
        styles.formtodate["& label"].fontFamily = props.themeColors.bgColors.base_font; 
        styles.selectboxx["& label"].fontFamily = props.themeColors.bgColors.base_font; 
        styles.listItem.fontFamily = props.themeColors.bgColors.base_font;    
        
    }
    
    // const util = require('util');
    const [currentCurrency, setCurrentCurrency] = useState("NO");
    const [gatewayAccess, setGatewayAccess] = useState(false);
    
    // const [paypalResponseText, setPaypalResponseText] = useState("");
    const [payPalKey, setPayPalKey] = useState("");
    //const [uploadLogoid, setUploadLogoid] = React.useState(0);
    const durationType = "NO";
    const dateNow = new Date(); // Creating a new date object with the current date and time
    const year = dateNow.getFullYear(); // Getting current year from the created Date object
    const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const month = // Setting current Month number from current Date object
        monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
        ? `0${monthWithOffset}`
        : monthWithOffset;
    const date =
        dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${dateNow.getUTCDate()}`
        : dateNow.getUTCDate();
    //dateNow.setDate(dateNow.getDate() - 7);
    var minDateNow = new Date(year, month - 6, date);
    const minyear = minDateNow.getFullYear(); // Getting current year from the created Date object
    const minmonthWithOffset = minDateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const minmonth = // Setting current Month number from current Date object
        minmonthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
        ? `0${minmonthWithOffset}`
        : minmonthWithOffset;
    const mindate =
        minDateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${minDateNow.getUTCDate()}`
        : minDateNow.getUTCDate();

    var oneDateNow = new Date();
    oneDateNow.setDate(oneDateNow.getDate() - 30);
    const oneyear = oneDateNow.getFullYear(); // Getting current year from the created Date object
    const onemonthWithOffset = oneDateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const onemonth = // Setting current Month number from current Date object
        onemonthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
        ? `0${onemonthWithOffset}`
        : onemonthWithOffset;
    const onedate =
    oneDateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${oneDateNow.getUTCDate()}`
        : oneDateNow.getUTCDate();

    var minSevenDateNow = new Date();
    minSevenDateNow.setDate(minSevenDateNow.getDate() - 7);
    const min7year = minSevenDateNow.getFullYear(); // Getting current year from the created Date object
    const min7monthWithOffset = minSevenDateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const min7month = // Setting current Month number from current Date object
        min7monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
        ? `0${min7monthWithOffset}`
        : min7monthWithOffset;
    const min7date =
    minSevenDateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${minSevenDateNow.getUTCDate()}`
        : minSevenDateNow.getUTCDate();
    
    const MaxDateAvailable = `${year}-${month}-${date}`;
    const MinDateAvailable = `${minyear}-${minmonth}-${mindate}`;
    const OneMonthDateAvailable = `${oneyear}-${onemonth}-${onedate}`;
    const SevenDateAvailable = `${min7year}-${min7month}-${min7date}`;

    const [dateConfig, setDateConfig] = React.useState({start: SevenDateAvailable, end: MaxDateAvailable});
    
    function selectDurationType(event){
        if(event.target != null && event.target.value === "1"){
        setDateConfig({start: SevenDateAvailable, end: MaxDateAvailable});
        } else if(event.target != null && event.target.value === "2"){
        setDateConfig({start: OneMonthDateAvailable, end: MaxDateAvailable});
        } else if(event.target != null && event.target.value === "3"){
        setDateConfig({start: MinDateAvailable, end: MaxDateAvailable});
        }
    }
    function changeStartDate(event){
        if(event.target != null && event.target.value > dateConfig.end){
            setDateConfig({start: dateConfig.end, end: dateConfig.end});
        }else if(event.target != null && event.target.value < MinDateAvailable){
            setDateConfig({start: MinDateAvailable, end: dateConfig.end});
        }else if(event.target != null && event.target.value > MaxDateAvailable){
            setDateConfig({start: MaxDateAvailable, end: dateConfig.end});
        }else{
            setDateConfig({start: event.target.value, end: dateConfig.end});
        }
    }
    function parseJwt(token) {
        if (!token) { return; }
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }
    function getCurrentCurrency(){
        if(window.localStorage.getItem('jwt_theme_token') != null){
            let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
            if(themeData.website_theme != null){
              if(themeData.currency_code.length != null){
                setCurrentCurrency(themeData.currency_code);
              }else{
                setCurrentCurrency("INR");
              }
            }else{
                setCurrentCurrency("INR");
            }
        }else{
            setCurrentCurrency("INR");
        }
    }
    if(currentCurrency === "NO"){
        getCurrentCurrency();
    }
    function changeEndDate(event){
        if(event.target != null && event.target.value < dateConfig.start){
            setDateConfig({start: dateConfig.start, end: dateConfig.start});
        }else if(event.target != null && event.target.value < MinDateAvailable){
            setDateConfig({start: dateConfig.start, end: MinDateAvailable});
        }else if(event.target != null && event.target.value > MaxDateAvailable){
            setDateConfig({start: dateConfig.start, end: MaxDateAvailable});
        }else{
            setDateConfig({start: dateConfig.start, end: event.target.value});
        }
    }
    
    // const { t } = useTranslation()
    const dispatch = useDispatch();
    const classes = useStyles();
    const [payBy, setPayBy] = React.useState(4);
    const [amount, setAmount] = React.useState("");
    const [comment, setComment] = React.useState("");
    const [payByType, setPayByType] = React.useState("1,2,3,4,5,6,7,8");
    
    const [pBankData, setPbankData] = React.useState([{status: false}]);
    const [bankData, setBankData] = React.useState([{status: false}]);
    const [pageRefresh, setPageRefresh] = useState(false);
    // const [classicModal, setClassicModal] = React.useState(false); 
    const [isFormValid, setIsFormValid] = useState(false);
    const [depositType, setDepositType] = React.useState(0);
    const userAuth = useSelector(({auth}) => auth.depositrequest);
    const userPaypalAuth = useSelector(({auth}) => auth.paypalrequest);
    const userEWalletAuth = useSelector(({auth}) => auth.ewalletdeposit);
    const formRef = useRef(null); 
    const formRefdeposit = useRef(null); 
    // const formRefcreditcard = useRef(null); 
    // const formRefwallet = useRef(null); 
    // const formRefangadiya = useRef(null); 

    const [isPhotoUploading, setIsPhotoUploading] = useState(false);
    const [attachedImage, setAttachedImage] = useState("");
    const [resetImage, setResetImage] = useState("NO");
    const [classAdd, setClassAdd] = useState("errormsg");
    async function setUploadLogoid(isPhoto, e){
        if(e != null){
            setIsPhotoUploading(true);
            uploadImage({icon: e});
        } 
    }
    
    
    async function uploadImage(data){
        const imageUpURL = globalurl.IMAGEUPLOAD; //process.env.MSN_WIFI; 
        
        axios.defaults.headers.post['Content-Type'] = "multipart/form-data";
        axios.defaults.headers.common = {};
        axios.defaults.headers.common.accept = 'application/json';
        
        const formData = new FormData();
        formData.append('icon',data.icon)
    
        axios.post(imageUpURL, formData,{headers:{"content-type": "multipart/form-data"}})
        .then(response => {
            if(response.data.status === "1") { 
                if(response.data.img != null){
                    setAttachedImage(response.data.img);
                }
            } else {
                var errorObj = {status:false,"message": response.data.message}; // This Message will be show at Password field
                
            }
            setIsPhotoUploading(false);
        })
        .catch((error) => {
            setIsPhotoUploading(false);
        });
    }
    
    function disableButton()
    {  
        setIsFormValid(false);
        document.body
        .getElementsByClassName("signupbtn")[0]
            .setAttribute("style", "opacity:0.5;");
    }
    function disableButtonPayPal()
    {
        setIsFormValid(false);
    }
    function enableButton()
    {
        setIsFormValid(true);
        document.body
        .getElementsByClassName("signupbtn")[0]
            .setAttribute("style", "opacity:1;");
        
    }
    function enableButtonPayPal()
    {
        setIsFormValid(true);
    }

    function handleSubmit(model){
        if(isPhotoUploading === false){
            dispatch(authActions.depositRequest(model, attachedImage));  
        }else{
            alert("Receipt uploading. please wait...");
        }
    }
    function handleSubmitAngadiya(model){
        dispatch(authActions.angadiyadeposit(model)); 
    }
    function handleSubmitEWallet(model){
        if(isPhotoUploading === false){
            dispatch(authActions.eWalletDepositRequest(model,attachedImage)); 
        }else{
            alert("Receipt uploading. please wait...");
        }
        
    }

    useEffect(() => {
        if ( userAuth.error && (userAuth.error.parent_banker_id || userAuth.error.sender_banker_id || userAuth.error.amount || userAuth.error.message) )
        {
            if(userAuth.error.message != null && document.body.getElementsByClassName("message")[0] != null){
                document.body
                .getElementsByClassName("message")[0] 
                .innerHTML=userAuth.error.message;
                setClassAdd(classes.msg = " errormsg" );
            }else{
                formRefdeposit.current.updateInputsWithError({
                    ...userAuth.error
                });
            }
        }else{
            if(userAuth.success != null && userAuth.success === true){
                //alert("Deposit request added successfully.")
                if(document.body.getElementsByClassName("message")[0] != null){
                        document.body
                        .getElementsByClassName("message")[0] 
                        .innerHTML="Deposit request added successfully."
                    setClassAdd(classes.msg = " successmsg" );
                }
                //window.location.reload();
                setDepositType(1);
                formRefdeposit.current.reset();
                setPageRefresh(true);
                if(attachedImage.length > 0){
                    setAttachedImage("");
                    setResetImage("YES");
                }
            }
        }
    }, [userAuth.error, userAuth.success, classes.msg, attachedImage.length]);
    
    useEffect(() => {
        if ( userPaypalAuth.error && (userPaypalAuth.error.amount || userPaypalAuth.error.message) )
        {
            if(userPaypalAuth.error.message != null){
                if(document.body.getElementsByClassName("message")[0]  != null){
                    document.body
                    .getElementsByClassName("message")[0] 
                    .innerHTML=userPaypalAuth.error.message;
                    setClassAdd(classes.msg = " errormsg" );
                }else{
                    alert(userPaypalAuth.error.message);
                }
                
            }else{
                formRefdeposit.current.updateInputsWithError({
                    ...userPaypalAuth.error
                });
            }
        }else{
            if(userPaypalAuth.success != null && userPaypalAuth.success === true){
                //alert("Deposit request added successfully.")
                if(document.body.getElementsByClassName("message")[0] != null){
                        document.body
                        .getElementsByClassName("message")[0] 
                        .innerHTML="Deposit request added successfully."
                    setClassAdd(classes.msg = " successmsg" );
                }
                
                //window.location.reload();
                formRefdeposit.current.reset();
                setPageRefresh(true);
                if(attachedImage.length > 0){
                    setAttachedImage("");
                    setResetImage("YES");
                }
            }
        }
    }, [userPaypalAuth.error, userPaypalAuth.success, classes.msg, attachedImage.length]);

    useEffect(() => {
        if ( userEWalletAuth.error && (userEWalletAuth.error.parent_banker_id || userEWalletAuth.error.sender_banker_id || userEWalletAuth.error.amount || userEWalletAuth.error.message) )
        {
            if(userEWalletAuth.error.message != null){
                if(document.body.getElementsByClassName("messagewallet")[0] != null){
                    document.body
                    .getElementsByClassName("messagewallet")[0] 
                    .innerHTML=userEWalletAuth.error.message;
                    setClassAdd(classes.msg = " errormsg" );
                }else{
                    alert(userEWalletAuth.error.message);
                }
            }else{
                formRefdeposit.current.updateInputsWithError({
                    ...userEWalletAuth.error
                });
            }
        }else{
            if(userEWalletAuth.success != null && userEWalletAuth.success === true){
                //alert("Deposit request added successfully.")
                if(document.body.getElementsByClassName("messagewallet")[0] != null){
                        document.body
                        .getElementsByClassName("messagewallet")[0] 
                        .innerHTML="Deposit request added successfully."
                    setClassAdd(classes.msg = " successmsg" );
                }
                
                //window.location.reload();
                setDepositType(1);
                formRefdeposit.current.reset();
                setPageRefresh(true);
                if(attachedImage.length > 0){
                    setAttachedImage("");
                    setResetImage("YES");
                }
            }
        }
    }, [userEWalletAuth.error, userEWalletAuth.success, classes.msg, attachedImage.length]);

    // const [cardNumber, setCardNumber] = React.useState(false);
    // const chagevale = (event) => {
    //     var cnumber=event.target.value;
    //     if(cnumber != null && cnumber.toString().length > 4){
    //         //cnumber = cnumber.substr(0,4) + " " + cnumber.substr(5, cnumber.toString().length - 4);
    //         cnumber = cc_format(cnumber.toString());
    //     }
        
    //     setCardNumber(cnumber);
    // }
    // const [cardDate, setCardDate] = React.useState(false); 
    // const chagedate = (event) => {
    //     var cDate=event.target.value;
    //     if(cDate != null && cDate.toString().length > 2){
    //         //cnumber = cnumber.substr(0,4) + " " + cnumber.substr(5, cnumber.toString().length - 4);
    //         cDate = cc_date(cDate.toString());
    //     }
    //     setCardDate(cDate);
    // }
    // const [cardCvv, setCardCvv] = React.useState(false);
    // const chagecvv = (event) => {
    //     var cCvv=event.target.value; 
    //     if(cCvv != null && cCvv.toString().length > 3){
    //         //cnumber = cnumber.substr(0,4) + " " + cnumber.substr(5, cnumber.toString().length - 4);
    //         cCvv = cc_cvv(cCvv.toString());
    //     }
    //     setCardCvv(cCvv);
    // }

    function selectplanHandler(event){
        if(event.target.value != null ){
        setDepositType(event.target.value);
        }   
    }

    function design(themeIndex){    
        document.body 
            .getElementsByClassName(classes.radiobtn1)[0]
            .classList.add("radiobtn");
        // document.body 
        //     .getElementsByClassName(classes.radiobtn2)[0]
        //     .classList.remove("radiobtn");  
    } 
    // function design2(themeIndex){    
    //     document.body 
    //         .getElementsByClassName(classes.radiobtn1)[0]
    //         .classList.remove("radiobtn");
    //     document.body 
    //         .getElementsByClassName(classes.radiobtn2)[0]
    //         .classList.add("radiobtn");  
    // }
    function design3(themeIndex){    
        document.body 
            .getElementsByClassName(classes.radiobtn3)[0]
            .classList.add("radiobtn");
        document.body 
            .getElementsByClassName(classes.radiobtn4)[0]
            .classList.remove("radiobtn"); 
        document.body 
            .getElementsByClassName(classes.radiobtn5)[0]
            .classList.remove("radiobtn");  
    } 
    function design4(themeIndex){    
        document.body 
            .getElementsByClassName(classes.radiobtn4)[0]
            .classList.add("radiobtn");
        document.body 
            .getElementsByClassName(classes.radiobtn3)[0]
            .classList.remove("radiobtn"); 
        document.body 
            .getElementsByClassName(classes.radiobtn5)[0]
            .classList.remove("radiobtn");  
    } 
    function design5(themeIndex){    
        document.body 
            .getElementsByClassName(classes.radiobtn5)[0]
            .classList.add("radiobtn");
        document.body 
            .getElementsByClassName(classes.radiobtn4)[0]
            .classList.remove("radiobtn"); 
        document.body 
            .getElementsByClassName(classes.radiobtn3)[0]
            .classList.remove("radiobtn");  
    } 
    function gatewayDetails(){
        if(gatewayAPI === false){
            gatewayAPI = true;
            const request = axios.post(globalurl.GETGATEWAYDETAILS , {
                token: window.localStorage.getItem('jwt_access_token')
            });
            
            request.then((response) => {
                gatewayAPI = false;
                if(response.data.data != null){
                    setGatewayAccess(response.data.data);
                }
            });
            request.catch((error) => {
                gatewayAPI = false;
                setGatewayAccess("ERROR")
            });
        }
        
    }
    if(gatewayAccess === false && gatewayAPI === false){
        gatewayDetails();
    }
    if(payPalKey === ""){
        if(Array.isArray(gatewayAccess)){
            gatewayAccess.map((getOneByone) => {
                if(getOneByone.GATEWAYCODE != null && getOneByone.GATEWAYCODE.toUpperCase() === "PAYPAL"){
                    let gatewayInfo = JSON.parse(getOneByone.GATEWAYINFO);
                    setPayPalKey(gatewayInfo[0].paypal_token);
                }
            });
       }else{
            if(gatewayAccess.GATEWAYCODE != null && gatewayAccess.GATEWAYCODE.toUpperCase() === "PAYPAL"){
                let gatewayInfo = JSON.parse(gatewayAccess.GATEWAYINFO);
                setPayPalKey(gatewayInfo[0].paypal_token);
            }
       }
    }
    function banklist(accType){
        const request = axios.post(((accType === "PARENT") ? globalurl.PARENTBANKACCOUNTS : globalurl.SELFBANKACCOUNTS) , {
            token: window.localStorage.getItem('jwt_access_token'),
            LSTART: 0,
            LEND: 50
        });
        
        request.then((response) => {
            if(response.data.data != null){
                var bankDb = [{
                    "status":true,
                    "data":response.data.data
                }]
                if(accType === "PARENT"){
                    setPbankData(bankDb);
                }else{
                    setBankData(bankDb);
                }
            }
        });
        request.catch((error) => {
            var bankDb = [{
                "status":false
            }]   
            if(accType === "PARENT"){
                setPbankData(bankDb);
            }else{
                setBankData(bankDb);
            }
        });
    }
    if(bankData[0].status != null && bankData[0].status === false){
        banklist("SELF");
    }
    if(pBankData[0].status != null && pBankData[0].status === false){
        banklist("PARENT");
    }
    const isObject = function(a) {
        return (!!a) && (a.constructor === Object);
      };
    return (
        <div>

    
<div id="nav-tabs" className={classes.signinnerpagebox} >


<div id="nav-tabs" className={classes.signinnerpageboxin + " " + classes.hidden} > 
{withdrawpageDb[sessionStorage.getItem('lang')].map((jsondata) => (
        <div key={jsondata.id}> <h3 className={classes.alert}>  {jsondata.top_text}</h3></div>
))}  
      <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
             
                    <CustomTabs
                        themeColors={props.themeColors}
                        plainTabs
                        border="round"
                        tabs={depositDb[sessionStorage.getItem('lang')].map((jsondata) => (
                        {
                            tabName: (<span>{jsondata.title}</span>),
                            tabContent: (
                                <div>
                                {(() =>  {
                                    if (jsondata.tagName === "tab1") {

                                        return (<div className={classes.rupeemainbox}>
                                            
                                            <div className={classes.addaccountform}>
                                                <Formsy
                                                        onValidSubmit={handleSubmit}
                                                        onValid={enableButton}
                                                        onInvalid={disableButton}
                                                        ref={formRefdeposit}
                                                        autoComplete={"off"}
                                                        className={classes.form + " flex flex-col justify-center w-full"}
                                                    >
                                                <div className={classes.depositform}>
                                                <GridContainer>
                                                    <GridItem xs={12}><div className={classes.msg + " message " + classAdd} id="message"></div></GridItem>
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <SelectFormsy 
                                                                className={classes.selectboxx + " selectbox"} 
                                                                label={jsondata.select_pay_by}
                                                                name="select_pay_by"  
                                                                variant="outlined"
                                                                value="1"
                                                                onChange={selectplanHandler}
                                                                required> 
                                                             <MenuItem value="1">Bank</MenuItem>
                                                             <MenuItem value="2">Cash</MenuItem>
                                                        </SelectFormsy>   
                                                    </GridItem>
                                                    <GridItem className={classes.btb} xs={12} sm={12} md={4}>  
                                                        <SelectFormsy 
                                                        className={classes.selectboxx + " selectbox"} 
                                                                label={jsondata.receiver_bank_account} 
                                                                name="parent_banker_id"  
                                                                variant="outlined"
                                                                required>
                                                                    {
                                                                        (() => {
                                                                            if(pBankData[0].status != null && pBankData[0].status === true){
                                                                                var mItems = pBankData.map((jsondata) => {
                                                                                    if(Array.isArray(jsondata.data)){
                                                                                        return (
                                                                                            jsondata.data.map((bodyData) => (
                                                                                                <MenuItem value={bodyData.id}>{bodyData.bank_name} (*****{bodyData.acc_number.substr(bodyData.acc_number.length-4, 4)})</MenuItem>
                                                                                            ))
                                                                                        )
                                                                                    
                                                                                    }else if(isObject(jsondata.data)){
                                                                                        return(
                                                                                            <MenuItem value={jsondata.data.id}>{jsondata.data.bank_name} (*****{jsondata.data.acc_number.substr(jsondata.data.acc_number.length-4, 4)})</MenuItem>
                                                                                        )
                                                                                    }
                                                                                });
                                                                                return mItems;
                                                                            }
                                                                        })()
                                                                    }
                                                        </SelectFormsy>  
                                                    </GridItem>
                                                    <GridItem className={classes.btb + " " +  ((depositType == 2 ) ? "displayno" : "displayin")} xs={12} sm={12} md={4}>
                                                    <SelectFormsy 
                                                        className={classes.selectboxx + " selectbox"} 
                                                                label={jsondata.sender_bank_account}
                                                                name="sender_banker_id"  
                                                                variant="outlined"
                                                                required={((depositType === 1) ? true : false)}>
                                                                    {
                                                                        (() => {
                                                                            if(bankData[0].status != null && bankData[0].status === true){
                                                                                var mItems = bankData.map((jsondata) => {
                                                                                    if(Array.isArray(jsondata.data)){
                                                                                        return (
                                                                                            jsondata.data.map((bodyData) => (
                                                                                                <MenuItem value={bodyData.id}>{bodyData.bank_name} (*****{bodyData.acc_number.substr(bodyData.acc_number.length-4, 4)})</MenuItem>
                                                                                            ))
                                                                                        )
                                                                                    
                                                                                    }else if(isObject(jsondata.data)){
                                                                                        return(
                                                                                            <MenuItem value={jsondata.data.id}>{jsondata.data.bank_name} (*****{jsondata.data.acc_number.substr(jsondata.data.acc_number.length-4, 4)})</MenuItem>
                                                                                        )
                                                                                    }
                                                                                });
                                                                                return mItems;
                                                                            }
                                                                        })()
                                                                    }
                                                        </SelectFormsy>    
                                                    </GridItem>
                                                    <GridItem className={classes.btb + " " +  ((depositType == 2 ) ? "displayno" : "displayin")} xs={12} sm={12} md={4}>
                                                        <SelectFormsy 
                                                                className={classes.selectboxx + " selectbox"} 
                                                                label={jsondata.payment_mode}
                                                                name="deposit_mode"  
                                                                variant="outlined"
                                                                value="1"
                                                                required> 
                                                             <MenuItem value="1">IMPS</MenuItem>
                                                             <MenuItem value="2">NEFT</MenuItem>
                                                             <MenuItem value="3">RTGS</MenuItem>
                                                        </SelectFormsy>   
                                                    </GridItem>
                                                    
                                                    <GridItem className={classes.btb} xs={12} sm={12} md={((depositType === 2 ) ? "4" : "4")}>
                                                            <TextFieldFormsy 
                                                                label={jsondata.trasationid}
                                                                name="transation_id"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                validations={{
                                                                    minLength: 6
                                                                }} 
                                                                validationErrors={{
                                                                    minLength: 'Min character length is 6'
                                                                }} 
                                                                variant="outlined"
                                                                required
                                                            />    
                                                    </GridItem>
                                                    <GridItem className={classes.btb} xs={12} sm={12} md={((depositType === 2 ) ? "4" : "4")}>
                                                            <TextFieldFormsy 
                                                                label={jsondata.comment}
                                                                name="comment"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                validations={{
                                                                    minLength: 6
                                                                }} 
                                                                validationErrors={{
                                                                    minLength: 'Min character length is 6'
                                                                }} 
                                                                variant="outlined"
                                                                required
                                                            />    
                                                    </GridItem>
                                                    <GridItem className={classes.btb} xs={12} sm={12} md={4}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.amount}
                                                            name="amount"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 3,
                                                                maxLength: 15,
                                                                isNumeric: true
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 3',
                                                                maxLength: 'Min character length is 15',
                                                                isNumeric:"No special character allowed only number"
                                                            }} 
                                                            variant="outlined"
                                                            required
                                                        />
                                                    </GridItem>
                                                    <GridItem className={classes.btb} xs={12} sm={12} md={4}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.confirm_amount}
                                                            name="confirm_amount"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 3,
                                                                maxLength: 15,
                                                                isNumeric: true,
                                                                equalsField: "amount"
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 3',
                                                                maxLength: 'Min character length is 15',
                                                                isNumeric:"No special character allowed only number",
                                                                equalsField: 'Confirm Amount must match with amount'
                                                            }} 
                                                            variant="outlined"
                                                            required
                                                        />
                                                    </GridItem>
                                                    
                                                    

                                                    <GridItem className={classes.btb} xs={12} sm={12} md={4}>
                                                        <label>{jsondata.receipt_upload}</label>
                                                        <ImgUpload 
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            name="logo"  
                                                            imagePreviewUrl = ""
                                                            onClick={setUploadLogoid} 
                                                            onReset={resetImage}
                                                            resetBack = {setResetImage}
                                                            themeColors={props.themeColors } 
                                                            {...rest}
                                                            required
                                                        />  
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                    <div className={classes.depositformbtni}> 
                                                    <Button 
                                                        type="submit"
                                                        color="transparent" 
                                                        className={classes.depositbtn +" signupbtn"}
                                                        disabled={!isFormValid}
                                                    >
                                                       {jsondata.depositbtns}
                                                    </Button></div>
                                                    </GridItem>
                                                    </GridContainer>
                                                </div>
                                                </Formsy>
                                                </div>

                                        </div>)

                                    }else if (jsondata.tagName === "tab2"){
                                        return (<div className={classes.rupeemainbox}>
                                                <div className={classes.addaccountform}>
                                                <Formsy
                                                        onValidSubmit={handleSubmitAngadiya}
                                                        onValid={enableButton}
                                                        onInvalid={disableButton}
                                                        ref={formRefdeposit}
                                                        autoComplete={"off"}
                                                        className={classes.form + " flex flex-col justify-center w-full"}
                                                    >
                                                <div className={classes.depositform}>
                                                <GridContainer> 
                                                <GridItem xs={12}><div className={classes.msg + " message " + classAdd} id="message"></div></GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextFieldFormsy 
                                                                label={jsondata.amount}
                                                                name="amount"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                validations={{
                                                                    minLength: 3,
                                                                    maxLength: 15,
                                                                    isNumeric: true
                                                                }} 
                                                                validationErrors={{
                                                                    minLength: 'Min character length is 3',
                                                                    maxLength: 'Min character length is 15',
                                                                    isNumeric:"No special character allowed only number"
                                                                }} 
                                                                variant="outlined"
                                                                required
                                                            />  
                                                    </GridItem> 
                                                    <GridItem className={classes.btb} xs={12} sm={12} md={6}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.confirm_amount}
                                                            name="confirm_amount"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 3,
                                                                maxLength: 15,
                                                                isNumeric: true,
                                                                equalsField: "amount"
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 3',
                                                                maxLength: 'Min character length is 15',
                                                                isNumeric:"No special character allowed only number",
                                                                equalsField: 'Confirm Amount must match with amount'
                                                            }} 
                                                            variant="outlined"
                                                            required
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                            <TextFieldFormsy 
                                                                label={jsondata.unique_code}
                                                                name="transation_id"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                validations={{
                                                                    minLength: 6
                                                                }} 
                                                                validationErrors={{
                                                                    minLength: 'Min character length is 6'
                                                                }} 
                                                                variant="outlined"
                                                                required
                                                            />    
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                            <TextFieldFormsy 
                                                                label={jsondata.comment}
                                                                name="comment"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                validations={{
                                                                    minLength: 6
                                                                }} 
                                                                validationErrors={{
                                                                    minLength: 'Min character length is 6'
                                                                }} 
                                                                variant="outlined"
                                                                required
                                                            />    
                                                    </GridItem>
                                                    
                                                    <GridItem xs={12} sm={12} md={12}>
                                                    <div className={classes.depositformbtni}> 
                                                    <Button 
                                                        type="submit"
                                                        color="transparent" 
                                                        className={classes.depositbtn +" signupbtn"}
                                                        disabled={!isFormValid}
                                                    >
                                                        {jsondata.deposit_btn}
                                                    </Button></div>
                                                    </GridItem>
                                                    </GridContainer>
                                                </div>
                                                </Formsy>
                                                </div>
                                        </div>)

                                    }else if (jsondata.tagName === "tab3"){
                                        return (<div className={classes.rupeemainbox}>
                                                <div className={classes.addaccountform}>
                                                <Formsy
                                                        onValidSubmit={handleSubmit}
                                                        onValid={enableButtonPayPal}
                                                        onInvalid={disableButtonPayPal}
                                                        ref={formRefdeposit}
                                                        autoComplete={"off"}
                                                        className={classes.form + " flex flex-col justify-center w-full"}
                                                    >
                                                <div className={classes.depositform}>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <div  className={classes.designbox}>
                                                                <div  onClick={() =>{design(1);}} className={classes.radiobtn1 + " radiobtn"}> <img src={designimg}  className={classes.designimg} alt="" /></div>
                                                        </div>  
                                                    </GridItem> 
                                                    
                                                    <GridItem xs={12}><div className={classes.msg + " message " + classAdd} id="message"></div></GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextFieldFormsy 
                                                                label={jsondata.amount}
                                                                name="amount"
                                                                onBlur={(e)=>setAmount(e.target.value)}
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                validations={{
                                                                    minLength: 3,
                                                                    maxLength: 15,
                                                                    isNumeric:true
                                                                }} 
                                                                validationErrors={{
                                                                    minLength: 'Min character length is 3',
                                                                    maxLength: 'Min character length is 15',
                                                                    isNumeric:"No special character allowed only number"
                                                                }} 
                                                                variant="outlined"
                                                                required
                                                            />  
                                                    </GridItem> 
                                                    <GridItem className={classes.btb} xs={12} sm={12} md={6}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.confirm_amount}
                                                            name="confirm_amount"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 3,
                                                                maxLength: 15,
                                                                isNumeric: true,
                                                                equalsField: "amount"
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 3',
                                                                maxLength: 'Min character length is 15',
                                                                isNumeric:"No special character allowed only number",
                                                                equalsField: 'Confirm Amount must match with amount'
                                                            }} 
                                                            variant="outlined"
                                                            required
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                            <TextFieldFormsy 
                                                                label={jsondata.comment}
                                                                name="comment"
                                                                onBlur={(e)=>setComment(e.target.value)}
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                validations={{
                                                                    minLength: 6
                                                                }} 
                                                                validationErrors={{
                                                                    minLength: 'Min character length is 6'
                                                                }} 
                                                                variant="outlined"
                                                                required
                                                            />    
                                                    </GridItem>
                                                    <br/><br/>
                                                    <GridItem xs={12} sm={12} md={3}></GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <div className={classes.depositformbtni}>
                                                        {(() => {
                                                                if(payPalKey !== ""){
                                                                return(
<PayPalButton
                                                                amount={amount}
                                                                // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                                                onSuccess={(details, data) => {
                                                                    if(props.sectionPopup != null){
                                                                        props.sectionPopup.openPopup("popup", "Success", "Please don't refresh till transaction proceed.");
                                                                    }else{
                                                                        alert("Please don't refresh till transaction proceed.");
                                                                    }
                                                                    //alert("Transaction completed by " + details.payer.name.given_name);
                                                                    // OPTIONAL: Call your server to save the transaction
                                                                    var payPalEmail = "";
                                                                    var payPalId = "";
                                                                    if(details != null && details.id != null && details.purchase_units != null && details.purchase_units[0].payments != null && details.purchase_units[0].payments.captures != null && details.purchase_units[0].payments.captures[0].id != null){
                                                                        payPalEmail = (details.payer != null && details.payer.email_address != null) ? details.payer.email_address : "NOIDAVAIL";
                                                                        payPalId = (details.payer != null && details.payer.payer_id != null) ? details.payer.payer_id : "NOIDAVAIL";
                                                                        dispatch(authActions.paypalRequest({amount: amount, comment: comment, paypal_email:payPalEmail,paypal_id:payPalId, transation_id: details.purchase_units[0].payments.captures[0].id, transaction_response: JSON.stringify(details)}));  
                                                                    }else{
                                                                        payPalEmail = (details.payer != null && details.payer.email_address != null) ? details.payer.email_address : "NOIDAVAIL";
                                                                        payPalId = (details.payer != null && details.payer.payer_id != null) ? details.payer.payer_id : "NOIDAVAIL";
                                                                        dispatch(authActions.paypalRequest({amount: amount, comment: comment, paypal_email:payPalEmail,paypal_id:payPalId, transation_id: 0, transaction_response: JSON.stringify(details)}));  
                                                                    }
                                                                    
                                                                }}
                                                                options={{
                                                                    clientId: payPalKey,
                                                                    currency:((currentCurrency === "NO") ? "INR" : currentCurrency)
                                                                }}
                                                                disabled={!isFormValid}
                                                            />
                                                                )
                                                            }
                                                        })()}
                                                        </div>
                                                    {/* <div className={classes.depositformbtni}>
                                                        <Button 
                                                            type="submit"
                                                            color="transparent" 
                                                            className={classes.depositbtn +" signupbtn"}
                                                            disabled={!isFormValid}
                                                        >
                                                        {"Deposit"}
                                                    </Button></div> */}
                                                    </GridItem>
                                                    </GridContainer>
                                                </div>
                                                </Formsy>
                                                </div>
                                        </div>)

                                    }else if (jsondata.tagName === "tab4"){
                                        return (<div className={classes.rupeemainbox}>
                                               <div className={classes.addaccountform}>
                                                <Formsy
                                                        onValidSubmit={handleSubmitEWallet}
                                                        onValid={enableButton}
                                                        onInvalid={disableButton}
                                                        ref={formRefdeposit}
                                                        autoComplete={"off"}
                                                        className={classes.form + " flex flex-col justify-center w-full"}
                                                    >
                                                <div className={classes.depositform}>
                                                <GridContainer>
                                                <GridItem xs={12}><div className={classes.msg + " messagewallet " + classAdd} id="messagewallet"></div></GridItem>
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <div  className={classes.designbox}>
                                                                <div  onClick={() =>{design3(3);setPayBy(4)}} className={classes.radiobtn3 + ((payBy === 4) ? " radiobtn" :  "")}> <img src={designimg3}  className={classes.designimg} alt="" /></div>
                                                        </div>  
                                                    </GridItem> 
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <div  className={classes.designbox}>
                                                                <div   onClick={() =>{design4(4);setPayBy(5)}} className={classes.radiobtn4 + ((payBy === 5) ? " radiobtn" :  "")}> <img src={designimg4}  className={classes.designimg} alt="" /></div>
                                                        </div>  
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <div  className={classes.designbox}>
                                                                <div   onClick={() =>{design5(5);setPayBy(6)}} className={classes.radiobtn5  + ((payBy === 6) ? " radiobtn" :  "")}> <img src={designimg5}  className={classes.designimg} alt="" /></div>
                                                        </div>  
                                                    </GridItem>  
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <TextFieldFormsy 
                                                                label={jsondata.transfered_phone_number}
                                                                name="parent_phone_number"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                validations={{
                                                                    minLength: 4,
                                                                    isNumeric:true
                                                                }} 
                                                                validationErrors={{
                                                                    minLength: 'Min character length is 4',
                                                                    isNumeric:"No special character allowed only number"
                                                                }} 
                                                                variant="outlined"
                                                                required
                                                            />  
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4}>
                                                            <TextFieldFormsy 
                                                                label={jsondata.sender_phone_number}
                                                                name="sender_phone_number"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                validations={{
                                                                    minLength: 6,
                                                                    isNumeric:true
                                                                }} 
                                                                validationErrors={{
                                                                    minLength: 'Min character length is 6',
                                                                    isNumeric:"No special character allowed only number"
                                                                }} 
                                                                variant="outlined"
                                                                required
                                                            />    
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4}>
                                                            <TextFieldFormsy 
                                                                label={jsondata.trasationid}
                                                                name="transaction_id"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                validations={{
                                                                    minLength: 4
                                                                }} 
                                                                validationErrors={{
                                                                    minLength: 'Min character length is 4'
                                                                }} 
                                                                variant="outlined"
                                                                required
                                                            />    
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4}>
                                                            <TextFieldFormsy 
                                                                label={jsondata.amount}
                                                                name="amount"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                validations={{
                                                                    minLength: 3,
                                                                    maxLength: 15,
                                                                    isNumeric:true
                                                                }} 
                                                                validationErrors={{
                                                                    minLength: 'Min character length is 3',
                                                                    maxLength: 'Min character length is 15',
                                                                    isNumeric:"No special character allowed only number"
                                                                }} 
                                                                variant="outlined"
                                                                required
                                                            />    
                                                    </GridItem>
                                                    <GridItem className={classes.btb} xs={12} sm={12} md={4}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.confirm_amount}
                                                            name="confirm_amount"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 3,
                                                                maxLength: 15,
                                                                isNumeric: true,
                                                                equalsField: "amount"
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 3',
                                                                maxLength: 'Min character length is 15',
                                                                isNumeric:"No special character allowed only number",
                                                                equalsField: 'Confirm Amount must match with amount'
                                                            }} 
                                                            variant="outlined"
                                                            required
                                                        />
                                                    </GridItem>
                                                    
                                                    <GridItem xs={12} sm={12} md={4}>
                                                            <TextFieldFormsy 
                                                                label={jsondata.comment}
                                                                name="comment"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                validations={{
                                                                    minLength: 6
                                                                }} 
                                                                validationErrors={{
                                                                    minLength: 'Min character length is 6'
                                                                }} 
                                                                variant="outlined"
                                                                required
                                                            />    
                                                    </GridItem>
                                                    <GridItem className={classes.btb} xs={12} sm={12} md={4}>
                                                        <label>{jsondata.receipt_upload}</label>
                                                        <ImgUpload 
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            name="logo"  
                                                            onClick={setUploadLogoid}
                                                            onReset={resetImage}
                                                            resetBack = {setResetImage}
                                                            themeColors={props.themeColors } 
                                                            required
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                    <div className={classes.depositformbtni}> 
                                                    <Button 
                                                        type="submit"
                                                        color="transparent" 
                                                        className={classes.depositbtn +" signupbtn"}
                                                        disabled={!isFormValid}
                                                    >
                                                        {jsondata.depositbtns}
                                                    </Button></div>
                                                    </GridItem>
                                                    </GridContainer>
                                                    <TextFieldFormsy 
                                                        name="pay_by"
                                                        type="hidden"
                                                        value={payBy}
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        variant="outlined"
                                                        required
                                                    />  
                                                </div>
                                                </Formsy>
                                                </div>

                                        </div>)
                                    }
                                })()}
                                    </div>

                            )
                        }

                        ))}
                    />
                    </GridItem>
                </GridContainer>


            <div className={classes.tablewarapbox} >           

                <Formsy   
                onValidSubmit={handleSubmit}
                ref={formRef}
                autoComplete={"off"}
                className={classes.form + " flex flex-col justify-center w-full"}
            > 
            {depositDb[sessionStorage.getItem('lang')][0].paybyoptions_one.map((jsondata) => (   
            <GridContainer key="a1">
                
                <GridItem xs={12} sm={12} md={3}>
                
                <SelectFormsy 
                    name="pay_by" 
                    label={jsondata.paying_type}
                    className={classes.selectboxx + " selectbox"} 
                    onChange={(event) => setPayByType(event.target.value)}
                    value={((payByType === "NO" && depositDb[sessionStorage.getItem('lang')][0].paybyoptions[0].id != null) ? depositDb[sessionStorage.getItem('lang')][0].paybyoptions[0].id : payByType)}
                >
                    {depositDb[sessionStorage.getItem('lang')][0].paybyoptions.map((jsondata) => (   
                        <MenuItem key={jsondata.id} value={jsondata.id}>{jsondata.name}</MenuItem>
                    ))}    
                </SelectFormsy> 
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                    <SelectFormsy 
                        name="duration" 
                        label={jsondata.period}
                        className={classes.selectboxx + " selectbox"}
                        onChange={(event) => selectDurationType(event)}
                        value={((durationType === "NO") ? 1 : durationType)}
                    >
                        {depositDb[sessionStorage.getItem('lang')][0].periodoptions.map((jsondata) => (   
                            <MenuItem key={jsondata.id} value={jsondata.id}>{jsondata.periodoption}</MenuItem>
                        ))}    
                    </SelectFormsy>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                <TextFieldFormsy 
                    className={classes.formtodate + " MuiInputBase-input MuiOutlinedInput-input"}
                    label={jsondata.start_date}
                    name="start_date"
                    formControlProps={{
                        fullWidth: true
                    }}
                    type="date"
                    defaultValue={dateConfig.start}
                    onChange={(event) => {changeStartDate(event)}}
                    value={dateConfig.start}
                    InputLabelProps={{
                        shrink: true
                    }}
                    InputProps={{inputProps: { min: MinDateAvailable, max: MaxDateAvailable} }}
                    variant="outlined"
                    required
                />  
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                <TextFieldFormsy 
                    className={classes.formtodate + " MuiInputBase-input MuiOutlinedInput-input"}
                    label={jsondata.end_date}
                    name="end_date"
                    formControlProps={{
                        fullWidth: true
                    }}
                    type="date"
                    defaultValue={dateConfig.end}
                    onChange={(event) => {changeEndDate(event)}}
                    value={dateConfig.end}
                    InputLabelProps={{
                        shrink: true
                    }}
                    InputProps={{inputProps: { min: MinDateAvailable, max: MaxDateAvailable} }}
                    variant="outlined"
                    required
                /> 
                </GridItem>
            </GridContainer>
            ))}
            </Formsy>
           
            <div className={classes.depositlistwarp}>
        <div className={classes.depositlistw}>
           <DepositStatementList themeColors={props.themeColors} statement={true} pageRefresh={pageRefresh} setPageRefresh={setPageRefresh} payByType={payByType} startDate={dateConfig.start} endDate={dateConfig.end} />

           </div> 
           </div> 
           </div> 

                </div>

            </div>
           
       </div>
    );
}
/*eslint-disable*/
import React, {useEffect, useRef, useState} from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton"; 
import People from "@material-ui/icons/People";
import Email from "@material-ui/icons/Email"; 
// react components for routing our app without refresh
import { Link, withRouter } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// @material-ui/core components
import {Button, Divider, Typography, InputAdornment, Icon} from '@material-ui/core';
import CustomInput from "../elements/CustomInput/CustomInput.js";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import * as globalurl from '../../../global/Global.js'; 
// core components
import axios from 'axios';
import Formsy from 'formsy-react';
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import menuDb from "../data/menu.js";
import logo from "../assets/img/logo.png";
import GridItem from "../elements/Grid/GridItem";
import GridContainer from "../elements/Grid/GridContainer";
import styles from "../assets/jss/components/headerLinksStyle.js";
import stylesr from "../assets/jss/components/headerLinkstopStyle"; 

import * as authActions from '../../../auth/casino/actions';
import {useDispatch, useSelector} from 'react-redux';
import {TextFieldFormsy, CheckboxFormsy} from '../../../@casino';
import secloginboxDb from "../data/secloginbox.js";
//import { useTranslation } from 'react-i18next'
const history = createBrowserHistory()
let classicModal = null;
let setClassicModal = null;


let test = "ok";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";
const validEmailRegex = 
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

function VerifyEmail(props) {

    const dispatch = useDispatch();
    const [isFormValid, setIsFormValid] = useState(false);
    const formRef = useRef(null);
    const [classAdd, setClassAdd] = useState(false);
    const [message, setMessage] = useState("");

    function verifyEmail(){
      const request = axios.post(globalurl.VERIFYEMAIL, {
        token: window.localStorage.getItem('jwt_access_token')
      });
      request.then((response) => {
          if (response.data.status != null && response.data.status === true )
          {
            setMessage("Our automated system sent verify link on your registerted email id if your account is exists.");
            setClassAdd(classes.msg = " successmsg" );
          }else{
            setMessage("Failed to verify with email.");
            setClassAdd(classes.msg = " errormsg" );
          }
      });
      request.catch((error) => {
          setMessage("Failed to verify with email.");
          setClassAdd(classes.msg = " errormsg" );
      });
    }

    function disableButton()
    {
        setIsFormValid(false);
        document.body
        .getElementsByClassName("signinbtn")[0]
          .setAttribute("style", "opacity:0.5;");
    }

    function enableButton()
    {
        setIsFormValid(true);
        document.body
        .getElementsByClassName("signinbtn")[0]
          .setAttribute("style", "opacity:1;");
    }

    function handleSubmit(model)
    {
        verifyEmail();
        setMessage("") 

    }
    const responseFb = (response) => {
      alert("RESONSE: " + JSON.stringify(response));
    }
    const componentClickFb = (response) => {
      
    }

    const responseGoogle = (response) => {
      //sessionStorage.setItem("googleOutput", JSON.stringify(response, getCircularReplacer()));
    }
    if(props.themeColors != null){
      styles.signinbtn.backgroundImage = props.themeColors.bgColors.submit_btns;
   //   styles.signinbtn.color = props.themeColors.bgColors.goldenbtntext;
      styles.modalHeader.background = props.themeColors.bgColors.blackbg;
      styles.modalBody.background = props.themeColors.bgColors.blackbg;
      styles.modalFooter.background = props.themeColors.bgColors.blackbg;
      styles.modalSubtitle.color = props.themeColors.bgColors.whitetextcolor;
      styles.signupbixp.color = props.themeColors.bgColors.whitetextcolor; 
   
      styles.signinmodalbtn.backgroundImage = props.themeColors.bgColors.submit_btns;
      styles.signinmodalbtn["&:hover"].backgroundImage = props.themeColors.bgColors.submit_btns;
      //styles.signinmodalbtn.color = props.themeColors.bgColors.goldenbtntext;
    }
    
  //  const { t } = useTranslation()
    let currentStyle = styles;
    const useStyles = makeStyles(styles);

    const classes = useStyles();
  [classicModal, setClassicModal] = React.useState(false);
  return ( <div>
    
      <div   >
    <Dialog
    classes={{
      root: classes.center,
      paper: classes.modal + " " + classes.responsivemodell
      
    }}
     
    open={classicModal}
    TransitionComponent={Transition}
    keepMounted
    onClose={() => setClassicModal(false)}
    aria-labelledby="classic-modal-slide-title"
    aria-describedby="classic-modal-slide-description"
>

  <DialogTitle
      id="classic-modal-slide-title"
      disableTypography
      className={classes.modalHeader + " " + classes.verifyheader}
  >
    <IconButton
        className={classes.modalCloseButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => setClassicModal(false)}
    >
      <Close className={classes.modalClose} />
    </IconButton>
      <img src={logo} height={"40px"} alt={""} className={classes.modellogo} />
    <h4 className={classes.modalTitle}> Verify Email Address   </h4> 
  </DialogTitle>
  <Formsy
        onValidSubmit={handleSubmit}
        onValid={enableButton}
        onInvalid={disableButton}
        ref={formRef}
        className={classes.form + " flex flex-col justify-center w-full " + classes.modalBody + " " + classes.verifybody}
    >
  <DialogContent
      id="classic-modal-slide-description"
      className={classes.modalBody + " " + classes.verifybody}
  >  
         <div className={classes.msg + " messageverify " + classAdd} id="messageverify">{message}</div>
         <div className={classes.formggroup}>
          <TextFieldFormsy
              label="Enter Email Address"
              id="username"
              name="username"
              formControlProps={{
                  fullWidth: true
              }}
              validations={{
                  minLength: 4
              }}
              validationErrors={{
                  minLength: 'Min character length is 4'
              }}
              value={props.emailId}
              variant="outlined"
              disabled={true}
              required
          /> 
          </div>
            
  </DialogContent>
  <DialogActions className={classes.modalFooter + " " + classes.verifyfooter}>
      
      <Button color="primary" className={classes.signinmodalbtn +" signinbtn"} type="submit" disabled={!isFormValid}>
           Verify Now
      </Button>
  </DialogActions>
  </Formsy> 
</Dialog>
</div>
 
</div>
  );
}
VerifyEmail.propTypes = {
  openPopup: function(isState){ 
      if(isState === "VerifyEmail"){
        setClassicModal(true);
      } 
    }
};

VerifyEmail.openPopup = function(isState){ 
  if(isState === "VerifyEmail"){
    setClassicModal(true);
  } 
};

export default VerifyEmail;
import React, {useEffect, useRef, useState} from "react";
 import Formsy from 'formsy-react';    
 
 
export default function Themecolor(props) { 
    
    function parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    };
     function getCurrentThemeInfo(){
        if(window.localStorage.getItem('jwt_theme_token') != null){
            let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
            return themeData;
        }else{
          return null;
        }
      }
      function extractHostname(url) {
            var hostname;
            //find & remove protocol (http, ftp, etc.) and get hostname
        
            if (url.indexOf("//") > -1) {
                hostname = url.split('/')[2];
            }
            else {
                hostname = url.split('/')[0];
            }
        
            //find & remove port number
            hostname = hostname.split(':')[0];
            //find & remove "?"
            hostname = hostname.split('?')[0];
        
            return hostname;
      }
      function extractRootDomain(url) {
        var domain = extractHostname(url),
            splitArr = domain.split('.'),
            arrLen = splitArr.length;
    
        //extracting the root domain here
        //if there is a subdomain 
        if (arrLen > 2) {
            domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1];
            //check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
            if (splitArr[arrLen - 2].length == 2 && splitArr[arrLen - 1].length == 2) {
                //this is using a ccTLD
                domain = splitArr[arrLen - 3] + '.' + domain;
            }
        }
        return domain;
    }
   
    
    var themeInfo = getCurrentThemeInfo();
    if(themeInfo != null){
      var themeIcon512 = "logo512.png";
      var themeIcon192 = "logo192.png";
      var website_title = "iSoft Gaming";
      if(themeInfo.icon != null && themeInfo.icon.length > 0 && themeInfo.icon != undefined && themeInfo.icon != "undefined"){
        var awsdomain = extractHostname(themeInfo.icon);
        themeIcon512 = themeInfo.icon.replace(awsdomain, "ik.imagekit.io/cdimage");
        themeIcon192 = themeInfo.icon.replace(awsdomain, "ik.imagekit.io/cdimage");
      }
      if(themeInfo.website_title != null && themeInfo.website_title.length > 0){
        website_title = themeInfo.website_title;
      }
        var stringCss = "";
      if(themeInfo.themecolors !== null && themeInfo.themecolors !== "" && themeInfo.themecolors !== undefined){
        var themeColorsParse = JSON.parse(themeInfo.themecolors);
        
        stringCss = ".gamelabel {color:"+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + "; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;}\n";
        stringCss += ".singlegame .mybtn2{  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000 !important") + "; background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d !important") + "; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".singlegame .mybtn2:hover{  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000 !important") + "; background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d !important") + ";} ";
        stringCss += ".singlegame::before{ background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "linear-gradient(135deg,#a1902e,#ffdb66 35%,#baa73e 40%,#fff8d4 44%,#6e5706 48%,#574300 52%,#fff6c7 56%,#ab8f00 76%,#ab8f00)") + ";} ";
        stringCss += ".singlegame .mybtn2:hover{  background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + "; color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + ";} ";
        stringCss += "::-webkit-scrollbar-thumb{ background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + ";} ";
        stringCss += "::-webkit-scrollbar-thumb:hover{ background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + ";} ";
        stringCss += ".gamesearching{ background: "+((themeColorsParse.secondary_dark_bgcolor !== null) ? themeColorsParse.secondary_dark_bgcolor : "#131212") + " !important; color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".gamebtnwarp button.active{ color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important; border-bottom-color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#ffb80c") + " !important;} ";
        stringCss += ".gamebtnwarp button{ color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".singlefeature-bottom .one{ box-shadow: "+((themeColorsParse.primary_shadow !== null) ? themeColorsParse.primary_shadow : "0px 0px 0px 15px rgba(255, 184, 12, 0.2), 0px 0px 0px 15px rgba(255, 184, 12, 0.2)") + " !important;} ";
        stringCss += ".singlefeature-bottom:hover .one{ box-shadow: "+((themeColorsParse.primary_light_shadow !== null) ? themeColorsParse.primary_light_shadow : "0px 0px 0px 15px rgba(255, 184, 12, 0.2), 0px 0px 0px 30px rgba(255, 184, 12, 0.2)") + " !important;} ";
        stringCss += "body{font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += "h2{font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".singlevision .visiontitle{font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".singlevision .visiontext{font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".singlewiner{ background: "+((themeColorsParse.secondary_dark_bgcolor !== null) ? themeColorsParse.secondary_dark_bgcolor : "#131212") + ";} ";
        stringCss += ".singlewiner .id{ background: "+((themeColorsParse.primary_bgcolor !== null) ? themeColorsParse.primary_bgcolor : "#000000") + "; color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + "; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".singlewiner:hover{ border-color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#ffb80c") + ";} ";
        stringCss += ".singlefeature-bottom{ background: "+((themeColorsParse.secondary_dark_bgcolor !== null) ? themeColorsParse.secondary_dark_bgcolor : "#131212") + ";} ";
        stringCss += ".singlefeature-bottom .titlenew{ color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".singlefeature-bottom .button.link{ color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".singlefeature-bottom .one{ border-color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#ffb80c") + " !important;} ";
        stringCss += ".casinoprime .playnowbtn{ background-image: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07)") + "; color: "+((themeColorsParse.text_primary_in_color !== null) ? themeColorsParse.text_primary_in_color : "#783205") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".singlewiner .name{  color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".singleawards .titleinner{  color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".singleawards .content p{  color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".singlewiner .date{  color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".singlewiner .leftbt{  color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#ffb80c") + " !important;} ";
        stringCss += ".titlefeaturelandscape{  color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".button-link{  color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".singleboxcobl .titlecobl a{  color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".singleboxcobl .textcobl{  color: "+((themeColorsParse.text_secondary_dark_color !== null) ? themeColorsParse.text_secondary_dark_color : "#b3b2b2") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".accordianbtn div{  background: "+((themeColorsParse.primary_inner_bgcolor !== null) ? themeColorsParse.primary_inner_bgcolor : "#1e1e1e") + " !important; color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".accordianiitm{  background: "+((themeColorsParse.secondary_bgcolor !== null) ? themeColorsParse.secondary_bgcolor : "#0f0f0f") + " !important;} ";
        stringCss += ".accordianp{  color: "+((themeColorsParse.text_secondary_dark_color !== null) ? themeColorsParse.text_secondary_dark_color : "#b3b2b2") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".selectbox .MuiSelect-select{  color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".singleteamarea .teamname{  color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".subtitle-left{ color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#efde1d") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".subtitle{ color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#efde1d") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".title{ color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".titledesc{ color: "+((themeColorsParse.text_secondary_dark_color !== null) ? themeColorsParse.text_secondary_dark_color : "#bdbdbd") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".title-left{ color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".titledesc-left{ color: "+((themeColorsParse.text_secondary_dark_color !== null) ? themeColorsParse.text_secondary_dark_color : "#bdbdbd") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".singlevision .iconone{  background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + "; box-shadow: "+((themeColorsParse.primary_light_shadow !== null) ? themeColorsParse.primary_light_shadow : "0px 0px 0px 15px rgba(255, 184, 12, 0.2), 0px 0px 0px 30px rgba(255, 184, 12, 0.2)") + " !important;} ";
        stringCss += ".singlefeaturelandscape{ border-color: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + " !important;} ";
        stringCss += ".casinoprime .iconone2{ border-color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#ffb80c") + "; background: "+((themeColorsParse.primary_bgcolor !== null) ? themeColorsParse.primary_bgcolor : "#000") + "; box-shadow: "+((themeColorsParse.primary_light_shadow !== null) ? themeColorsParse.primary_light_shadow : "0px 0px 0px 15px rgba(255, 184, 12, 0.2), 0px 0px 0px 30px rgba(255, 184, 12, 0.2)") + " !important;} ";
        stringCss += ".casinoprime .singlefeaturelandscape:hover .iconone2{ border-color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#ffb80c") + "; background: "+((themeColorsParse.primary_bgcolor !== null) ? themeColorsParse.primary_bgcolor : "#000") + "; box-shadow: "+((themeColorsParse.primary_light_shadow !== null) ? themeColorsParse.primary_light_shadow : "0px 0px 0px 15px rgba(255, 184, 12, 0.2), 0px 0px 0px 30px rgba(255, 184, 12, 0.2)") + " !important;} ";
        stringCss += ".casinoprime .singlefeaturelandscape{ border-color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#ffb80c") + " !important;} ";
        stringCss += ".iconone2{ border-color: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + "; background: "+((themeColorsParse.primary_bgcolor !== null) ? themeColorsParse.primary_bgcolor : "#000") + ";} ";
        stringCss += ".singleteamarea .designation{ color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#efde1d") + "; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".singleteamarea .teamimage { background: "+((themeColorsParse.secondary_dark_bgcolor !== null) ? themeColorsParse.secondary_dark_bgcolor : "#131212") + ";} ";
        stringCss += ".singleboxcobl{ background: "+((themeColorsParse.secondary_dark_bgcolor !== null) ? themeColorsParse.secondary_dark_bgcolor : "#131212") + ";} ";
        stringCss += ".singleawards{ background: "+((themeColorsParse.secondary_dark_bgcolor !== null) ? themeColorsParse.secondary_dark_bgcolor : "#131212") + "; } ";
        stringCss += ".singleawards:hover{ border-color: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + ";} ";
        stringCss += ".fixheadermobile1{ background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + " !important;} ";
        stringCss += ".fixheadermobile{ background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + " !important;} ";
        stringCss += ".fullScreenBtn{ background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + " !important;} ";
        stringCss += ".homeiconbtns{ background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + " !important;} ";
       
        stringCss += ".leftsidebar{ background: "+((themeColorsParse.secondary_bgcolor !== null) ? themeColorsParse.secondary_bgcolor : "#0d0d0d") + " !important; border-color: "+((themeColorsParse.border_color !== null, themeColorsParse.border_color !== undefined) ? themeColorsParse.border_color : "#cccccc") + " !important;} ";
        
        stringCss += ".ambiance .singlegame .mybtn2{  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#fdc600 ") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".ambiance .singlegame .mybtn2:hover{  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000 !important") + "; background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#fdc600 !important") + ";} ";
        stringCss += ".ambiance .singlevision .visiontitle{color: "+((themeColorsParse.text_primary_dark_color  !== null) ? themeColorsParse.text_primary_dark_color  : '#000000') + " !important;} ";
        stringCss += ".ambiance .playnowbtn{ background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "##fdc600") + "; color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".ambiance .button-link{ color: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#fdc600") + " !important;  font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".ambiance .button-link:hover{ color: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#fdc600") + " !important;  font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".ambiance .titlefeaturelandscape {  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".ambiance .gamelabel{color:"+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#fdc600") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;}\n";
        stringCss += ".ambiance .title{ color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".ambiance .titledesc{ color: "+((themeColorsParse.text_secondary_inner_color !== null) ? themeColorsParse.text_secondary_inner_color : "#6b6b6b") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".ambiance .titledesc-left{ color: "+((themeColorsParse.text_secondary_inner_color !== null) ? themeColorsParse.text_secondary_inner_color : "#6b6b6b") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".ambiance .title-left{ color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".ambiance .singlevision .iconone{  background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#ed2024") + "; box-shadow: "+((themeColorsParse.shadow_color !== null) ? themeColorsParse.shadow_color : "0px 0px 0px 10px rgba(253, 198, 0, 0.2), 0px 0px 0px 20px rgba(253, 198, 0, 0.2)") + " !important;} ";
        stringCss += ".ambiance .iconone2{ border-color: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#fdc600") + " !important;  box-shadow: "+((themeColorsParse.shadow_color !== null && themeColorsParse.shadow_color !== undefined) ? themeColorsParse.shadow_color : "0px 0px 0px 10px rgba(253, 198, 0, 0.2), 0px 0px 0px 20px rgba(253, 198, 0, 0.2)") + " !important;} ";
        stringCss += ".ambiance .singlefeaturelandscape:hover .icononetwo{  box-shadow: "+((themeColorsParse.shadow_color !== null) ? themeColorsParse.shadow_color : "0px 0px 0px 10px rgba(253, 198, 0, 0.2), 0px 0px 0px 20px rgba(253, 198, 0, 0.2)") + " !important;} ";
        stringCss += ".ambiance.home .textpage{   background: "+((themeColorsParse.secondary_white_bgcolor !== null) ? themeColorsParse.secondary_white_bgcolor : "#ffffff !important") + "; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        // stringCss += ".ambiance .singlegame .mybtn2{  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000 !important") + "; background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#ed2024 !important") + "; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        
        stringCss += ".brezza .textpage.home p a{color:"+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#ed2024 ") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;}\n";
        stringCss += ".brezza .gamebtnwarp .slick-slide button{ color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".brezza .gamebtnwarp .slick-slide button.active{ color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#ed2024") + " !important; border-bottom-color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#ffb80c") + " !important;} ";
        stringCss += ".brezza .playnowbtn{ background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#ed2024") + "; color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#fff") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".brezza .button-link{ color: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#ed2024") + ";  font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".brezza .button-link:hover{ color: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#ed2024") + ";  font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";       
        stringCss += ".brezza .textpage a{ color: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#ed2024") + ";  font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".brezza .singlevision .visiontitle{  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".brezza .titlefeaturelandscape {  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".brezza .singlevision .visiontext {  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".brezza .fixheadermobile1{  background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#ed2024") + " !important; color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important;} ";
        stringCss += ".brezza .fullScreenBtn{  background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#ed2024") + " !important; color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important;} ";
        stringCss += ".brezza .container .singlegame a{  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".brezza .singlegame a:hover{  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".brezza .gamelabel{color:"+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#ed2024") + "; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;}\n";
        stringCss += ".brezza .textpage p a{ color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#ed2024") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".brezza .title-left{ color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".brezza .textpage p a.playnowbtn{ color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".brezza .singlewiner {  background: "+((themeColorsParse.secondary_dark_bgcolor !== null) ? themeColorsParse.secondary_dark_bgcolor : "#131212") + "; } ";
        stringCss += ".brezza .singlevision .iconone{  background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#ed2024") + "; box-shadow: "+((themeColorsParse.shadow_color !== null) ? themeColorsParse.shadow_color : "0px 0px 0px 10px rgba(237, 32, 36, 0.2), 0px 0px 0px 20px rgba(237, 32, 36, 0.2)") + " !important;} ";
        stringCss += ".brezza .iconone2{ border-color: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#ed2024") + " !important;  box-shadow: "+((themeColorsParse.shadow_color !== null && themeColorsParse.shadow_color !== undefined) ? themeColorsParse.shadow_color : "0px 0px 0px 10px rgba(237, 32, 36, 0.2), 0px 0px 0px 20px rgba(237, 32, 36, 0.2)") + " !important;} ";
        stringCss += ".brezza .singleteamarea .designation{ color: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#ed2024") + " !important; } ";
        stringCss += ".brezza .singlefeaturelandscape:hover .icononetwo{  box-shadow: "+((themeColorsParse.shadow_color !== null) ? themeColorsParse.shadow_color : "0px 0px 0px 10px rgba(237, 32, 36, 0.2), 0px 0px 0px 20px rgba(237, 32, 36, 0.2)") + " !important;} ";
        stringCss += ".brezza .singleboxcobl{  background: "+((themeColorsParse.secondary_dark_bgcolor !== null) ? themeColorsParse.secondary_dark_bgcolor : "#131212") + "; } ";
        stringCss += ".brezza .singlefeaturelandscape{ border-color: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#ed2024") + " !important;} ";
        stringCss += ".brezza .singlegame .mybtn2{  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000 !important") + "; background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#ed2024 !important") + "; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".brezza .slidertext{   background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#ed2024 !important") + "; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".brezza .textpage h1.title{  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        
        stringCss += ".spikes .gamebtnwarp button.active{ color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#ff3341") + " !important; border-bottom-color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#ffb80c") + " !important;} ";
        stringCss += ".spikes .gamebtnwarp button{ border-color: "+((themeColorsParse.primary_bgcolor !== null) ? themeColorsParse.primary_bgcolor : "#ffffff") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".spikes .singlevision .visiontitle{color: "+((themeColorsParse.text_primary_color  !== null) ? themeColorsParse.text_primary_color  : '#fff') + " !important;} ";
        stringCss += ".spikes .sidearrowleft {  background: "+((themeColorsParse.primary_bgcolor !== null) ? themeColorsParse.primary_bgcolor : "#000000") + " !important; color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important; } ";
        stringCss += ".spikes .sidearrowright {  background: "+((themeColorsParse.primary_bgcolor !== null) ? themeColorsParse.primary_bgcolor : "#000000") + " !important; color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important; border-color: "+((themeColorsParse.border_color !== null) ? themeColorsParse.border_color : "#2d2d2d") + " !important; } ";
        stringCss += ".spikes .singleteamarea .teamname{  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#ffffff") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".spikes .textpage h2{  color: "+((themeColorsParse.text_primary_color !== null) ? themeColorsParse.text_primary_color : "#ffffff") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".spikes .playnowbtn{ background-image: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + "; background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#ff3341") + " !important; color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#ffffff") + " !important;} ";
        stringCss += ".spikes .singlevision .iconone{  box-shadow: "+((themeColorsParse.shadow_color !== null) ? themeColorsParse.shadow_color : "0px 0px 0px 10px rgba(255, 51, 65, 0.2), 0px 0px 0px 20px rgba(255, 51, 65, 0.2)") + " !important;} ";
        stringCss += ".spikes .iconone2{  border-color: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + " !important; box-shadow: "+((themeColorsParse.shadow_color !== null) ? themeColorsParse.shadow_color : "0px 0px 0px 10px rgba(255, 51, 65, 0.2), 0px 0px 0px 20px rgba(255, 51, 65, 0.2)") + " !important;} ";
        stringCss += ".spikegame .spikegamehover{  border-color: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + " !important; } ";
        stringCss += ".spikes .singlefeaturelandscape:hover .icononetwo{  box-shadow: "+((themeColorsParse.shadow_color !== null) ? themeColorsParse.shadow_color : "0px 0px 0px 10px rgba(255, 51, 65, 0.2), 0px 0px 0px 20px rgba(255, 51, 65, 0.2)") + " !important;} ";
        stringCss += ".spikes .gamewrap a{ color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#ff3341") + ";} ";
        stringCss += ".spikes .container a{ color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#ff3341") + ";} ";
        stringCss += ".spikes a:hover{ color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#ff3341") + ";} ";
        stringCss += ".spikess .selectbox .MuiSelect-icon{ color: "+((themeColorsParse.text_secondary_dark_color !== null) ? themeColorsParse.text_secondary_dark_color : "#ffffff") + " !important; } ";
        stringCss += ".spikess ::-webkit-calendar-picker-indicator{ filter: "+((themeColorsParse.filter !== null) ? themeColorsParse.filter : "none") + " !important; } ";
        stringCss += ".spikegame .singlegame .mybtn2{ background: rgba(0,0,0,0.8) !important;  color: #ffffff !important; } ";
        stringCss += ".spikes .gamelabel{color:#fff  !important; }\n";
        
        stringCss += ".sporto .singlegame::before{ background:"+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "linear-gradient(135deg,#a1902e,#ffdb66 35%,#baa73e 40%,#fff8d4 44%,#6e5706 48%,#574300 52%,#fff6c7 56%,#ab8f00 76%,#ab8f00)") + ";} ";
        stringCss += ".sporto .playnowbtn{ background-image: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + "; background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + " !important; color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important;} ";
        stringCss += ".sporto .gamelabel{color:"+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#ed2024") + "; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;}\n";
        stringCss += ".sporto .container a{ color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#efde1d") + ";} ";
        stringCss += ".sporto .singlevision .iconone{  box-shadow: "+((themeColorsParse.shadow_color !== null) ? themeColorsParse.shadow_color : "0px 0px 0px 10px rgba(255, 51, 65, 0.2), 0px 0px 0px 20px rgba(255, 51, 65, 0.2)") + " !important;} ";
        stringCss += ".sporto .iconone2{  border-color: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + " !important; box-shadow: "+((themeColorsParse.shadow_color !== null) ? themeColorsParse.shadow_color : "0px 0px 0px 10px rgba(255, 51, 65, 0.2), 0px 0px 0px 20px rgba(255, 51, 65, 0.2)") + " !important;} ";
        stringCss += ".sporto .singlefeaturelandscape:hover .iconone2{  box-shadow: "+((themeColorsParse.shadow_color !== null) ? themeColorsParse.shadow_color : "0px 0px 0px 10px rgba(255, 51, 65, 0.2), 0px 0px 0px 20px rgba(255, 51, 65, 0.2)") + " !important;} ";
        stringCss += ".sporto a:hover{ color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#efde1d") + ";} ";
        stringCss += ".sporto .gamewrap a{ color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#efde1d") + ";} ";
        stringCss += ".sporto .singlegame .mybtn2{  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000 !important") + "; background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#ed2024 ") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".sportogame .mybtn2{ background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + " !important;  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; } ";
        
        stringCss += ".freddy .singlegame::before{ background:"+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "linear-gradient(135deg,#a1902e,#ffdb66 35%,#baa73e 40%,#fff8d4 44%,#6e5706 48%,#574300 52%,#fff6c7 56%,#ab8f00 76%,#ab8f00)") + ";} ";
        stringCss += ".freddy .playnowbtn{ background-image: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + "; background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + " !important; color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important;} ";
        stringCss += ".freddy .gamelabel{color:"+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#ed2024") + "; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;}\n";
        stringCss += ".freddy .container a{ color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#efde1d") + ";} ";
        stringCss += ".freddy .singlevision .iconone{  box-shadow: "+((themeColorsParse.shadow_color !== null) ? themeColorsParse.shadow_color : "0px 0px 0px 10px rgba(255, 51, 65, 0.2), 0px 0px 0px 20px rgba(255, 51, 65, 0.2)") + " !important;} ";
        stringCss += ".freddy .iconone2{  border-color: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + " !important; box-shadow: "+((themeColorsParse.shadow_color !== null) ? themeColorsParse.shadow_color : "0px 0px 0px 10px rgba(255, 51, 65, 0.2), 0px 0px 0px 20px rgba(255, 51, 65, 0.2)") + " !important;} ";
        stringCss += ".freddy .singlefeaturelandscape:hover .iconone2{  box-shadow: "+((themeColorsParse.shadow_color !== null) ? themeColorsParse.shadow_color : "0px 0px 0px 10px rgba(255, 51, 65, 0.2), 0px 0px 0px 20px rgba(255, 51, 65, 0.2)") + " !important;} ";
        stringCss += ".freddy a:hover{ color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#efde1d") + ";} ";
        stringCss += ".freddy .gamewrap a{ color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#efde1d") + ";} ";
        stringCss += ".freddy .singlegame .mybtn2{  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000 !important") + "; background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#ed2024 ") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".freddy .title{ color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".freddy .titledesc{ color: "+((themeColorsParse.text_secondary_inner_color !== null) ? themeColorsParse.text_secondary_inner_color : "#6b6b6b") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".freddy .titledesc-left{ color: "+((themeColorsParse.text_secondary_inner_color !== null) ? themeColorsParse.text_secondary_inner_color : "#6b6b6b") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".freddy .title-left{ color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".freddy .singlevision .visiontitle{  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".freddy .titlefeaturelandscape {  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".freddy .singlevision .visiontext {  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
       
        stringCss += ".gloster .singlegame::before{ background:"+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "linear-gradient(135deg,#a1902e,#ffdb66 35%,#baa73e 40%,#fff8d4 44%,#6e5706 48%,#574300 52%,#fff6c7 56%,#ab8f00 76%,#ab8f00)") + ";} ";
        stringCss += ".gloster .playnowbtn{ background-image: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + "; background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + " !important; color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important;} ";
        stringCss += ".gloster .gamelabel{color:"+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#ed2024") + "; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;}\n";
        stringCss += ".gloster .container a{ color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#efde1d") + ";} ";
        stringCss += ".gloster .singlevision .iconone{  box-shadow: "+((themeColorsParse.shadow_color !== null) ? themeColorsParse.shadow_color : "0px 0px 0px 10px rgba(255, 51, 65, 0.2), 0px 0px 0px 20px rgba(255, 51, 65, 0.2)") + " !important;} ";
        stringCss += ".gloster .iconone2{  border-color: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#efde1d") + " !important; box-shadow: "+((themeColorsParse.shadow_color !== null) ? themeColorsParse.shadow_color : "0px 0px 0px 10px rgba(255, 51, 65, 0.2), 0px 0px 0px 20px rgba(255, 51, 65, 0.2)") + " !important;} ";
        stringCss += ".gloster .singlefeaturelandscape:hover .iconone2{  box-shadow: "+((themeColorsParse.shadow_color !== null) ? themeColorsParse.shadow_color : "0px 0px 0px 10px rgba(255, 51, 65, 0.2), 0px 0px 0px 20px rgba(255, 51, 65, 0.2)") + " !important;} ";
        stringCss += ".gloster a:hover{ color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#efde1d") + ";} ";
        stringCss += ".gloster .gamewrap a{ color: "+((themeColorsParse.text_secondary_color !== null) ? themeColorsParse.text_secondary_color : "#efde1d") + ";} ";
        stringCss += ".gloster .singlegame .mybtn2{  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000 !important") + "; background: "+((themeColorsParse.main_color !== null) ? themeColorsParse.main_color : "#ed2024 ") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".gloster .title{ color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".gloster .titledesc{ color: "+((themeColorsParse.text_secondary_inner_color !== null) ? themeColorsParse.text_secondary_inner_color : "#6b6b6b") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".gloster .titledesc-left{ color: "+((themeColorsParse.text_secondary_inner_color !== null) ? themeColorsParse.text_secondary_inner_color : "#6b6b6b") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".gloster .title-left{ color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".gloster .singlevision .visiontitle{  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".gloster .titlefeaturelandscape {  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        stringCss += ".gloster .singlevision .visiontext {  color: "+((themeColorsParse.text_primary_dark_color !== null) ? themeColorsParse.text_primary_dark_color : "#000000") + " !important; font-family: "+((themeColorsParse.base_font !== null) ? themeColorsParse.base_font : 'Ubuntu') + " !important;} ";
        
      }
         // stringCss += ".accordion img{ filter: "+((themeColorsParse.filter !== null) ? themeColorsParse.filter : "grayscale(100%)") + " !important;} ";
        const blobCss = new Blob([stringCss], {type: 'text/css'});
        const cssURL = URL.createObjectURL(blobCss);
        document.querySelector('#my-css-placeholder').setAttribute('href', cssURL);

        document.querySelector('#my-website-description').setAttribute('content', website_title);
        document.querySelector('title').textContent = website_title;
        document.querySelector('#apple-touch-icon').setAttribute('href', themeIcon192 + "?tr=w-192,h-192,cm-pad_resize");
        document.querySelector('#favicon-icon').setAttribute('href', themeIcon192 + "?tr=w-64,h-64,cm-pad_resize");
        
    }  
  


    return (
         <div >
       
         </div>
    );
}


const usermenuDb = {
    en: [ 
        {
            'id'    : '5725a680b3249760eb21de01',
            'submenu_dropdown_name'  : 'Username', 
            'submenu': [
                {
                    'id'    : '5725a680b3249760eb21de02',
                    'sub_menu_name'  : 'Deposit',
                    'submenu_path' : '/deposit',
                    'submenu_class' : 'fa fa-database',
                },
                {
                    'id'    : '5725a680b3249760eb21de03',
                    'sub_menu_name'  : 'Withdraw Request',
                    'submenu_path' : '/withdraw',
                    'submenu_class' : 'fa fa-leaf',
                },
                {
                    'id'    : '5725a680b3249760eb21de04',
                    'sub_menu_name'  : 'Account',
                    'submenu_path' : '/account',
                    'submenu_class' : 'fa fa-user',
                },
                {
                    'id'    : '5725a680b3249760eb21de05',
                    'sub_menu_name'  : 'Statement',
                    'submenu_path' : '/statement',
                    'submenu_class' : 'fa fa-list',
                }, 
                {
                    'id'    : '5725a680b3249760eb21de09',
                    'sub_menu_name'  : 'Login History',
                    'submenu_path' : '/loginhistory',
                    'submenu_class' : 'fa fa-user',
                },
                {
                    'id'    : '5725a680b3249760eb21de10',
                    'sub_menu_name'  : 'My Bonus',
                    'submenu_path' : '/mybonus',
                    'submenu_class' : 'fa fa-gift',
                },
                {
                    'id'    : '5725a680b3249760eb21de11',
                    'sub_menu_name'  : 'My Bets',
                    'submenu_path' : '/mybets',
                    'submenu_class' : 'fa fa-trophy',
                }, 
            ]
        },
          
    ],
    tl: [ 
        {
            'id'    : '5725a680b3249760eb21de01',
            'submenu_dropdown_name'  : 'వినియోగదారు పేరు', 
            'logoutbtn':'లాగ్ అవుట్',
            'submenu': [
                {
                    'id'    : '5725a680b3249760eb21de02',
                    'sub_menu_name'  : 'డిపాజిట్',
                    'submenu_path' : '/deposit', 
                    'submenu_class' : 'fa fa-database',
                },
                {
                    'id'    : '5725a680b3249760eb21de03',
                    'sub_menu_name'  : 'అభ్యర్థనను ఉపసంహరించుకోండి',
                    'submenu_path' : '/withdraw', 
                    'submenu_class' : 'fa fa-leaf',
                },
                {
                    'id'    : '5725a680b3249760eb21de04',
                    'sub_menu_name'  : 'ఖాతా',
                    'submenu_path' : '/account', 
                    'submenu_class' : 'fa fa-user',
                },
                {
                    'id'    : '5725a680b3249760eb21de05',
                    'sub_menu_name'  : 'ప్రకటన',
                    'submenu_path' : '/statement', 
                    'submenu_class' : 'fa fa-list',
                }, 
                {
                    'id'    : '5725a680b3249760eb21de09',
                    'sub_menu_name'  : 'లాగిన్ చరిత్ర',
                    'submenu_path' : '/loginhistory', 
                    'submenu_class' : 'fa fa-user',
                },
                {
                    'id'    : '5725a680b3249760eb21de10',
                    'sub_menu_name'  : 'నా బోనస్',
                    'submenu_path' : '/mybonus', 
                    'submenu_class' : 'fa fa-gift',
                },
               {
                    'id'    : '5725a680b3249760eb21de11',
                    'sub_menu_name'  : 'నా బెట్స్',
                    'submenu_path' : '/mybets',  
                    'submenu_class' : 'fa fa-trophy',
                },   
            ]
        },
          
    ],
    hi: [
        {
            'id'    : '5725a680b3249760eb21de01',
            'submenu_dropdown_name'  : 'उपयोगकर्ता नाम', 
            'submenu': [
                {
                    'id'    : '5725a680b3249760eb21de02',
                    'sub_menu_name'  : 'जमा',
                    'submenu_path' : '/deposit',
                    'submenu_class' : 'fa fa-database',
                },
                {
                    'id'    : '5725a680b3249760eb21de03',
                    'sub_menu_name'  : 'निकालना',
                    'submenu_path' : '/withdraw',
                    'submenu_class' : 'fa fa-leaf',
                },
                {
                    'id'    : '5725a680b3249760eb21de04',
                    'sub_menu_name'  : 'खाता',
                    'submenu_path' : '/account',
                    'submenu_class' : 'fa fa-user',
                },
                {
                    'id'    : '5725a680b3249760eb21de05',
                    'sub_menu_name'  : 'विवरण',
                    'submenu_path' : '/statement',
                    'submenu_class' : 'fa fa-list',
                }, 
                {
                    'id'    : '5725a680b3249760eb21de09',
                    'sub_menu_name'  : 'लॉगिन इतिहास',
                    'submenu_path' : '/loginhistory',
                    'submenu_class' : 'fa fa-user',
                },
                {
                    'id'    : '5725a680b3249760eb21de10',
                    'sub_menu_name'  : 'मेरा बोनस',
                    'submenu_path' : '/mybonus',
                    'submenu_class' : 'fa fa-gift',
                },
                {
                    'id'    : '5725a680b3249760eb21de11',
                    'sub_menu_name'  : 'मेरा बेट्स',
                    'submenu_path' : '/mybets',
                    'submenu_class' : 'fa fa-trophy',
                },  
            ]
        }  
    ],

};

export default usermenuDb;
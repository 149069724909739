import React, {useEffect, useRef, useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";


import styles from "../assets/jss/components/contact.js";
import Button from "../elements/CustomButtons/Button";

 
import classNames from "classnames"; 
import leftimgconatct from "../assets/img/contact-left.png";
import {useDispatch, useSelector} from 'react-redux';
import { MenuItem} from "@material-ui/core"; 
import * as authActions from '../../../auth/casino/actions';
import Formsy from 'formsy-react'; 
import {TextFieldFormsy, SelectFormsy, CheckboxFormsy} from '../../../@casino';
import seccontactDb from "../data/seccontact.js";
// import { useTranslation } from 'react-i18next' 
const useStyles = makeStyles(styles);

export default function SectionContact(props) {

    const [isFormValid, setIsFormValid] = useState(false);
    const formRef = useRef(null); 
    const [isPrivacy, setIsPrivacy] = useState(false); 
    
    // const [isPermission, setIsPermission] = useState(1);
    const userAuth = useSelector(({auth}) => auth.user); 
    const dispatch = useDispatch();

    useEffect(() => {
        if ( userAuth.error && (userAuth.error.parent_banker_id || userAuth.error.sender_banker_id || userAuth.error.amount || userAuth.error.message) )
        {
            if(userAuth.error.message != null){
                document.body
                .getElementsByClassName("message")[0] 
                .innerHTML=userAuth.error.message
            }else{
                formRef.current.updateInputsWithError({
                    ...userAuth.error
                });
            }
        }else{
            if(userAuth.success != null && userAuth.success === true){
                alert("Contact request sent successfully.")
                window.location.reload();
            }
        }
    }, [userAuth.error, userAuth.success]);

    function disableButton()
    {
        setIsFormValid(false);
        document.body
        .getElementsByClassName("signupbtn")[0]
          .setAttribute("style", "opacity:0.5;");
    }
    function enableButton()
    {
        setIsFormValid(true);
        document.body
        .getElementsByClassName("signupbtn")[0]
          .setAttribute("style", "opacity:1;");
    }
 
    function handleSubmit(model)
    {
           dispatch(authActions.contactRequest(model));  
    }


    styles.singlecontact.background = props.themeColors.bgColors.primary_bgcolor
    styles.subtitle.color = props.themeColors.bgColors.text_secondary_color
    styles.text.color = props.themeColors.bgColors.text_secondary_dark_color
    styles.contactbox.background = props.themeColors.bgColors.primary_bgcolor 
    styles.contactbox["& textarea"].color = props.themeColors.bgColors.text_primary_color 
    styles.contactbox["& input"].color = props.themeColors.bgColors.text_primary_color 
    styles.contactbox["& label"].color = props.themeColors.bgColors.text_secondary_dark_color
    styles.checkboxin["& p"].color = props.themeColors.bgColors.text_primary_color 
    styles.checkboxxlogin.color = props.themeColors.bgColors.text_secondary_color 
    
    styles.contacttitle.color = props.themeColors.bgColors.text_primary_color 
    styles.mybtn1.backgroundImage = props.themeColors.bgColors.main_color
    styles.mybtn1.background = props.themeColors.bgColors.main_color
    styles.mybtn1.color = props.themeColors.bgColors.text_primary_dark_color 

    styles.contacttitle.fontFamily = props.themeColors.bgColors.base_font; 
    styles.contactiinpu["& label"].fontFamily = props.themeColors.bgColors.base_font; 
    styles.contactiinpu["& input"].fontFamily = props.themeColors.bgColors.base_font; 
    styles.contactiinpu["& textarea"].fontFamily = props.themeColors.bgColors.base_font;    
    styles.checkbtitext.fontFamily = props.themeColors.bgColors.base_font; 
    styles.mybtn1.fontFamily = props.themeColors.bgColors.base_font; 
    // const { t } = useTranslation()
  const classes = useStyles();
  return (
      <div className={classNames(classes.singlecontact)}>
         
          <div className={classNames(classes.container)}> 

        <img 
            src={leftimgconatct} 
            alt="" 
            style={{    
                top: "-5px",
                left: "0px",
                position: "absolute",
                maxWidth: "520px",
                paddingTop: "80px",
            }}
         />

      <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
          <Formsy
                onValidSubmit={handleSubmit}
                onValid={enableButton}
                onInvalid={disableButton}
                ref={formRef}
                className={classes.form + " flex flex-col justify-center w-full"}
            >
              <div className={classes.contactformwrapper}>
              {/* {alert(JSON.stringify(seccontactDb.categories))} */}
              {seccontactDb.categories.map((jsondata) => (
                
                  <div className={classes.contactbox}>
                      
                      <h4 className={classes.contacttitle}>
                       Inquiry Form
                      </h4>
                      <GridContainer>
                      <GridItem md={12}><div className={classes.msg + " message errormsg "} id="message"></div></GridItem>
                             <GridItem xs={12} sm={12} md={12}>
                                <TextFieldFormsy 
                                    label={jsondata.name}
                                    id="name"
                                    name="name"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validations={{
                                        minLength: 2
                                    }} 
                                    className={classes.contactiinpu}
                                    validationErrors={{
                                        minLength: 'Min character length is 2'
                                    }} 
                                    variant="outlined"
                                    required
                                />  
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <TextFieldFormsy 
                                    label={jsondata.email}
                                    id="email"
                                    name="email"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validations={{
                                        minLength: 2
                                    }} 
                                    className={classes.contactiinpu}
                                    validationErrors={{
                                        minLength: 'Min character length is 2'
                                    }} 
                                    variant="outlined"
                                    required
                                />   
                             </GridItem>
                             <GridItem xs={12} sm={12} md={12}>  

                                  <SelectFormsy 
                                    name="type_of"
                                    className={classes.selectboxx + " selectbox"} 
                                    value="tom"
                                    label="Topic"
                                    variant="outlined"
                                    validations={{
                                        isNumeric: true
                                    }}
                                    validationErrors={{
                                        isNumeric: 'Please select topic'
                                    }} 
                                    required>
                                    {jsondata.submenus.map((jsondata) => (
                                      <MenuItem value={((jsondata.id > 0) ? jsondata.id : "tom")}>{jsondata.options}</MenuItem> 
                                    ))}
                                  </SelectFormsy>


                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={12}>
                                  <textarea value={jsondata.textarea} style={{marginTop:"10px"}} className={classes.contactiinpu}></textarea> 
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={12}>
                                  <div className={classes.checkboxin + " " + classes.checkboxxlogin + " checkboxeroormain"}>  
                                    <CheckboxFormsy  
                                        name="tnc" 
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        className={classes.checkboxx + " " + classes.checkboxxlogin + " checkboxeroor"}
                                        value={isPrivacy} 
                                        onClick={(isChecked) => {((isChecked === true) ? setIsPrivacy(true) : setIsPrivacy(false))}}
                                        validations={{
                                        equals: true,
                                        }}
                                        validationErrors={{
                                        equals: "Sorry! Please select T&C"
                                        }}
                                        required
                                        variant="outlined" 
                                    />
                                    <p>{jsondata.checkb} </p>
                                  </div> 
                                  </GridItem>
                               </GridContainer>
                                  <Button 
                                      color="transparent" 
                                      type="submit"
                                      className={classes.mybtn1 +" signupbtn"}
                                      disabled={!isFormValid}
                                  >
                                      {jsondata.submitbtn} 
                                  </Button> 
                       
                  </div>))}
              </div>
            </Formsy>
          </GridItem>
           
          
      </GridContainer>
      </div>
      </div>
  );
}

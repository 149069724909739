

const accountsDb = {
    en: [
        {
            'id':'3923923892',
            'titleicon':"fa fa-user",
            'title':"Profile",
            'tagName': 'tab1', 
            'userprofilediv': [
                {
                    'id':"name",
                    'display_name':"Full Name"
                },
                {
                    'id':"username",
                    'display_name':"User Name"
                },
                {
                    'id':"email",
                    'display_name':"Email Id",
                },
                {
                    'id':"mobile",
                    'display_name':"Mobile Number",
                },
                {
                    'id':"commission_type",
                    'display_name':"Commission Type",
                }
            ],
            'changepasswordbtn':"Change Password",
            'editprofilebtn':"Edit Profile",
            'oldpassword':"Old Password",
            'newpassword':"New Password",
            'confirmpassword':"Confirm Password",
            'updatepasswordbtn':" Update Password",
            'cancelbtn':"Cancel",
            'formname':"Full Name",
            'formusername':"Username",
            'formemail':"Email",
            'formphone':"Phone Number",
            'formgender':"Gender",
            'formmale':"Male",
            'formfemale':"Female",
            'formdob':"Date of Birth",
            'updateprofilebtn':"Update Profile",
        },
        {
            'id':'3923923894',
            'title':"Wallet",
            'titleicon':"fa fa-university",
            'tagName': 'tab2', 
        },
        {
            'id':'3923923893',
            'title':"My Bets",
            'titleicon':"fa fa-trophy",
            'tagName': 'tab3',
            'ptext': "To change your Bank Account, please contact Customer Service .",
            'acountname': "Account Name",
            'bankdiv': [
                {
                    'banknameoption':"Bank Name",
                },
                {
                    'banknameoption':"SBI",
                },
                {
                    'banknameoption':"ICICI",
                },
                {
                    'banknameoption':"Panjab National Bank",
                },
                {
                    'banknameoption':"HDFC",
                },
            ],
            'bankbranch': "Bank Branch",
            'accountnumber': "Account Number",
            'ifsccode': "IFSC Code",
            'whyifsc': "What is my IFSC Code?",
            'myaccountbtn': "Add Account", 
        },
        {
            'id':'3923923894',
            'title':"Bonuses",
            'titleicon':"fa fa-gift",
            'tagName': 'tab4', 
        }, 
        
    ],
    tm: [
        {
            'id':'3923923892',
            'titleicon':"fa fa-user",
            'title':"சுயவிவரம்",
            'tagName': 'tab1', 
            'userprofilediv': [
                {
                    'id':"name",
                    'display_name':"முழு பெயர்"
                },
                {
                    'id':"username",
                    'display_name':"பயனர் பெயர்"
                },
                {
                    'id':"email",
                    'display_name':"மின்னஞ்சல் முகவரி",
                },
                {
                    'id':"mobile",
                    'display_name':"கைபேசி எண்",
                },
                {
                    'id':"commission_type",
                    'display_name':"கமிஷன் வகை",
                }
            ],  
            'changepasswordbtn': "கடவுச்சொல்லை மாற்று",
            'editprofilebtn': "சுயவிவரத்தைத் திருத்து",
            'oldpassword': "பழைய கடவுச்சொல்",
            'newpassword': "புதிய கடவுச்சொல்",
            'confirmpassword': "கடவுச்சொல்லை உறுதிப்படுத்தவும்",
            'updatepasswordbtn': "கடவுச்சொல்லைப் புதுப்பிக்கவும்",
            'cancelbtn': "ரத்துசெய்",
            'formname': "முழுப்பெயர்",
            'formusername': "பயனர்பெயர்",
            'formemail': "மின்னஞ்சல்",
            'formphone': "தொலைபேசி எண்",
            'formgender': "பாலினம்",
            'formmale': "ஆண்",
            'formfemale': "பெண்",
            'formdob': "பிறந்த தேதி",
            'updateprofilebtn': "சுயவிவரத்தைப் புதுப்பிக்கவும்",
        },
        {
            'id':'3923923894',
            'title':"இருப்பு",
            'titleicon':"fa fa-university",
            'tagName': 'tab2', 
        },
        {
            'id':'3923923893',
            'title':"என் பெட்ஸ்",
            'titleicon':"fa fa-trophy",
            'tagName': 'tab3',
            'ptext': "உங்கள் வங்கி கணக்கை மாற்ற, வாடிக்கையாளர் சேவையைத் தொடர்பு கொள்ளவும்.",
            'acountname': "கணக்கின் பெயர்",
            'bankdiv': [
                {
                    'banknameoption':"வங்கி பெயர்",
                },
                {
                    'banknameoption':"எஸ்பிஐ",
                },
                {
                    'banknameoption':"ஐ.சி.ஐ.சி.ஐ.",
                },
                {
                    'banknameoption':"பஞ்சாப் நேஷனல் வங்கி",
                },
                {
                    'banknameoption':"HDFC",
                },
            ],
            'bankbranch': "வங்கிக்கிளை",
            'accountnumber': "கணக்கு எண்",
            'ifsccode': "IFSC குறியீடு",
            'whyifsc': "எனது IFSC குறியீடு என்ன?",
            'myaccountbtn': "கணக்கு சேர்க்க", 
        },
        {
            'id':'3923923894',
            'title':"போனஸ்",
            'titleicon':"fa fa-gift",
            'tagName': 'tab4', 
        }, 
        
    ],
    kn: [
        {
            'id':'3923923892',
            'titleicon':"fa fa-user",
            'title':"ಪ್ರೊಫೈಲ್",
            'tagName': 'tab1', 
            'userprofilediv': [
                {
                    'id':"name",
                    'display_name':"ಪೂರ್ಣ ಹೆಸರು"
                },
                {
                    'id':"username",
                    'display_name':"ಬಳಕೆದಾರ ಹೆಸರು"
                },
                {
                    'id':"email",
                    'display_name':"ಇಮೇಲ್ ಐಡಿ",
                },
                {
                    'id':"mobile",
                    'display_name':"ಮೊಬೈಲ್ ನಂಬರ",
                },
                {
                    'id':"commission_type",
                    'display_name':"ಆಯೋಗದ ಪ್ರಕಾರ",
                }
            ],  
            'changepasswordbtn': "ಪಾಸ್ವರ್ಡ್ ಬದಲಾಯಿಸಿ",
            'editprofilebtn': "ಪ್ರೊಫೈಲ್ ಸಂಪಾದಿಸಿ",
            'oldpassword': "ಹಳೆಯ ಪಾಸ್‌ವರ್ಡ್",
            'newpassword': "ಹೊಸ ಪಾಸ್‌ವರ್ಡ್",
            'confirmpassword': "ಪಾಸ್ವರ್ಡ್ ದೃ irm ೀಕರಿಸಿ",
            'updatepasswordbtn': "ಪಾಸ್ವರ್ಡ್ ನವೀಕರಿಸಿ",
            'cancelbtn': "ರದ್ದುಮಾಡು",
            'formname': "ಪೂರ್ಣ ಹೆಸರು",
            'formusername': "ಬಳಕೆದಾರಹೆಸರು",
            'formemail': "ಇಮೇಲ್",
            'formphone': "ಫೋನ್ ಸಂಖ್ಯೆ",
            'formgender': "ಲಿಂಗ",
            'formmale': "ಪುರುಷ",
            'formfemale': "ಸ್ತ್ರೀ",
            'formdob': "ಹುಟ್ಟಿದ ದಿನಾಂಕ",
            'updateprofilebtn': "ಪ್ರೊಫೈಲ್ ನವೀಕರಿಸಿ",
        },
        {
            'id':'3923923894',
            'title':"ಸಮತೋಲನ",
            'titleicon':"fa fa-university",
            'tagName': 'tab2', 
        },
        {
            'id':'3923923893',
            'title':"ನನ್ನ ಬೆಟ್ಸ್",
            'titleicon':"fa fa-trophy",
            'tagName': 'tab3',
            'ptext': "ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಖಾತೆಯನ್ನು ಬದಲಾಯಿಸಲು, ದಯವಿಟ್ಟು ಗ್ರಾಹಕ ಸೇವೆಯನ್ನು ಸಂಪರ್ಕಿಸಿ.",
            'acountname': "ಖಾತೆಯ ಹೆಸರು",
            'bankdiv': [
                {
                    'banknameoption':"ಬ್ಯಾಂಕ್ ಹೆಸರು",
                },
                {
                    'banknameoption':"ಎಸ್‌ಬಿಐ",
                },
                {
                    'banknameoption':"ಐಸಿಐಸಿಐ",
                },
                {
                    'banknameoption':"ಪಂಜಾಬ್ ನ್ಯಾಷನಲ್ ಬ್ಯಾಂಕ್",
                },
                {
                    'banknameoption':"HDFC",
                },
            ],
            'bankbranch': "ಬ್ಯಾಂಕ್ ಶಾಖೆ",
            'accountnumber': "ಖಾತೆ ಸಂಖ್ಯೆ",
            'ifsccode': "ಐಎಫ್‌ಎಸ್‌ಸಿ ಕೋಡ್",
            'whyifsc': "ನನ್ನ ಐಎಫ್‌ಎಸ್‌ಸಿ ಕೋಡ್ ಎಂದರೇನು?",
            'myaccountbtn': "ಖಾತೆಯನ್ನು ಸೇರಿಸು", 
        },
        {
            'id':'3923923894',
            'title':"ಬೋನಸ್ಗಳು",
            'titleicon':"fa fa-gift",
            'tagName': 'tab4', 
        }, 
        
    ],
    ne: [
        {
            'id':'3923923892',
            'titleicon':"fa fa-user",
            'title':"प्रोफाइल",
            'tagName': 'tab1', 
            'userprofilediv': [
                {
                    'id':"name",
                    'display_name':"पुरा नाम"
                },
                {
                    'id':"username",
                    'display_name':"प्रयोगकर्ता नाम"
                },
                {
                    'id':"email",
                    'display_name':"ईमेल आईडी",
                },
                {
                    'id':"mobile",
                    'display_name':"मोबाइल नम्बर",
                },
                {
                    'id':"commission_type",
                    'display_name':"मोबाइल नम्बर",
                }
            ],   
            'changepasswordbtn': "पासवर्ड परिवर्तन गर्नुहोस्",
            'editprofilebtn': "प्रोफाइल सम्पादन गर्नुहोस्",
            'oldpassword': "पुरानो पासवर्ड",
            'newpassword': "नयाँ पासवर्ड",
            'confirmpassword': "पासवर्ड पुष्टि गर्नुहोस्",
            'updatepasswordbtn': "अपडेट पासवर्ड",
            'cancelbtn': "रद्द गर्नुहोस्",
            'formname': "पूर्ण नाम",
            'formusername': "प्रयोगकर्तानाम",
            'formemail': "ईमेल",
            'formphone': "फोन नम्बर",
            'formgender': "लिंग",
            'formmale': "पुरुष",
            'formfemale': "महिला",
            'formdob': "जन्म मिति",
            'updateprofilebtn': "प्रोफाइल अपडेट गर्नुहोस्",
        },
        {
            'id':'3923923894',
            'title':"शेष",
            'titleicon':"fa fa-university",
            'tagName': 'tab2', 
        },
        {
            'id':'3923923893',
            'title':"मेरो शर्त",
            'titleicon':"fa fa-trophy",
            'tagName': 'tab3',
            'ptext': "तपाईंको बैंक खाता परिवर्तन गर्न, कृपया ग्राहक सेवालाई सम्पर्क गर्नुहोस्।",
            'acountname': "खाताको नाम",
            'bankdiv': [
                {
                    'banknameoption':"बैंक नाम",
                },
                {
                    'banknameoption':"एसबीआई",
                },
                {
                    'banknameoption':"आईसीआईसीआई",
                },
                {
                    'banknameoption':"पंजाब नेशनल बैंक",
                },
                {
                    'banknameoption':"HDFC",
                },
            ],  
            'bankbranch': "Bank शाखा",
            'accountnumber': "खाता नम्बर",
            'ifsccode': "IFSC Code",
            'whyifsc': "मेरो आईएफएससी कोड के हो?",
            'myaccountbtn': "खाता थप्नुहोस्",
        },
        {
            'id':'3923923894',
            'title':"बोनस",
            'titleicon':"fa fa-gift",
            'tagName': 'tab4', 
        }, 
        
    ],
    tl: [
        {
            'id':'3923923892',
            'titleicon':"fa fa-user",
            'title':"ప్రొఫైల్",
            'tagName': 'tab1', 
            'userprofilediv': [
                {
                    'id':"name",
                    'display_name':"పూర్తి పేరు"
                },
                {
                    'id':"username",
                    'display_name':"వినియోగదారు పేరు"
                },
                {
                    'id':"email",
                    'display_name':"ఇమెయిల్ ఐడి",
                },
                {
                    'id':"mobile",
                    'display_name':"మొబైల్ సంఖ్య",
                },
                {
                    'id':"commission_type",
                    'display_name':"కమిషన్ రకం",
                }
            ],
            'changepasswordbtn':"పాస్వర్డ్ మార్చండి",
            'editprofilebtn':"ప్రొఫైల్‌ను సవరించండి",
            'oldpassword':"పాత పాస్‌వర్డ్",
            'newpassword':"కొత్త పాస్వర్డ్",
            'confirmpassword':"పాస్వర్డ్ను నిర్ధారించండి",
            'updatepasswordbtn':" పాస్వర్డ్ను నవీకరించండి",
            'cancelbtn':"రద్దు చేయండి",
            'formname':"పూర్తి పేరు",
            'formusername':"వినియోగదారు పేరు",
            'formemail':"ఇమెయిల్",
            'formphone':"ఫోను నంబరు",
            'formgender':"లింగం",
            'formmale':"పురుషుడు",
            'formfemale':"స్త్రీ",
            'formdob':"పుట్టిన తేది",
            'updateprofilebtn':"ప్రొఫైల్‌ను నవీకరించండి",
        },
        {
            'id':'3923923894',
            'title':"సంతులనం",
            'titleicon':"fa fa-university",
            'tagName': 'tab2', 
        },
        {
            'id':'3923923893',
            'title':"నా బెట్స్",
            'titleicon':"fa fa-trophy",
            'tagName': 'tab3',
            'ptext': "మీ బ్యాంక్ ఖాతాను మార్చడానికి, దయచేసి కస్టమర్ సేవను సంప్రదించండి.",
            'acountname': "ఖాతా పేరు",
            'bankdiv': [
                {
                    'banknameoption':"బ్యాంక్ పేరు",
                },
                {
                    'banknameoption':"ఎస్బిఐ",
                },
                {
                    'banknameoption':"ఐసిఐసిఐ",
                },
                {
                    'banknameoption':"పంజాబ్ నేషనల్ బ్యాంక్",
                },
                {
                    'banknameoption':"HDFC",
                },
            ],
            'bankbranch': "బ్యాంకు శాఖ",
            'accountnumber': "ఖాతా సంఖ్య",
            'ifsccode': "IFSC కోడ్",
            'whyifsc': "నా IFSC కోడ్ ఏమిటి?",
            'myaccountbtn': "ఖాతా జోడించండి", 
        }, 
        {
            'id':'3923923894',
            'title':"బోనస్",
            'titleicon':"fa fa-gift",
            'tagName': 'tab4', 
        }, 
        
    ],
    hi: [
        {
            'id':'3923923892',
            'title':"प्रोफ़ाइल",
            'tagName': 'tab1', 
            'titleicon':"fa fa-user",
            'userprofilediv': [
                {
                    'id':"name",
                    'display_name':"पूरा नाम"
                },
                {
                    'id':"username",
                    'display_name':"उपयोगकर्ता नाम"
                },
                {
                    'id':"email",
                    'display_name':"ईमेल",
                },
                {
                    'id':"mobile",
                    'display_name':"मोबाइल",
                },
                {
                    'id':"gender",
                    'display_name':"लिंग",
                },
                {
                    'id':"date_of_birth",
                    'display_name':"जन्म की तारीख",
                },
                {
                    'id':"commission_type",
                    'display_name':"कमीशन का प्रकार",
                }
            ],
            'changepasswordbtn':"पासवर्ड बदलें",
            'editprofilebtn':"प्रोफ़ाइल संपादित करें",
            'oldpassword':"पुराना पासवर्ड",
            'newpassword':"नया पासवर्ड",
            'confirmpassword':"पासवर्ड की पुष्टि कीजिये",
            'updatepasswordbtn':"पासवर्ड अपडेट करें",
            'cancelbtn':"रद्द करना",
            'formname':"पूरा नाम",
            'formusername':"उपयोगकर्ता नाम",
            'formemail':"ईमेल",
            'formphone':"फ़ोन नंबर",
            'formgender':"लिंग",
            'formmale':"पुरुष",
            'formfemale':"महिला",
            'formdob':"जन्म की तारीख",
            'updateprofilebtn':"प्रोफ़ाइल अपडेट करें",
        },
        {
            'id':'3923923894',
            'title':"धनराशि",
            'titleicon':"fa fa-university",
            'tagName': 'tab2', 
        },
        {
            'id':'3923923893',
            'title':"मेरे दांव",
            'tagName': 'tab3',
            'titleicon':"fa fa-trophy",
            'ptext': "अपना बैंक खाता बदलने के लिए, कृपया ग्राहक सेवा से संपर्क करें।",
            'acountname': "खाते का नाम",
            'bankdiv': [
                {
                    'banknameoption':"बैंक का नाम",
                },
                {
                    'banknameoption':"भारतीय स्टेट बैंक",
                },
                {
                    'banknameoption':"आईसीआईसीआई",
                },
                {
                    'banknameoption':"पंजाब नेशनल बैंक",
                },
                {
                    'banknameoption':"एचडीफसी ",
                },
            ],
            'bankbranch': "बैंक शाखा",
            'accountnumber': "खाता संख्या",
            'ifsccode': "IFSC कोड",
            'whyifsc': "मेरा IFSC कोड क्या है?",
            'myaccountbtn': "खाता जोड़ो", 
        },
        {
            'id':'3923923894',
            'title':"बोनस",
            'titleicon':"fa fa-gift",
            'tagName': 'tab4', 
        },
        // {
        //     'id':'3923923894',
        //     'title':"आंकड़े",
        //     'titleicon':"fa fa-chart", 
        //     'tagName': 'tab5', 
        // },
    ]
}
export default accountsDb;

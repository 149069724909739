/*eslint-disable*/
import React, {useEffect, useRef, useState} from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import {IconButton, MenuItem} from "@material-ui/core"; 
import People from "@material-ui/icons/People";
import Email from "@material-ui/icons/Email"; 
// react components for routing our app without refresh
import { Link, withRouter } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// @material-ui/core components
import {Button, Divider, Typography, InputAdornment, Icon} from '@material-ui/core';
import CustomInput from "../elements/CustomInput/CustomInput.js";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { NumberTextfield } from "react-number-textfield";
// core components
import CustomDropdown from "../elements/CustomDropdown/CustomDropdown.js";
import CustomButton from "../elements/CustomButtons/Button.js";
import ReCAPTCHA from "react-google-recaptcha";
// @database
import { useIndexedDB } from 'react-indexed-db';
import queryString from 'query-string';
//import { DbService } from '../../../@database/DBService';
// @api calling
import axios from 'axios';

import Formsy from 'formsy-react';
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import menuDb from "../data/menu.js";
import logo from "../assets/img/logo.png";
import GridItem from "../elements/Grid/GridItem";
import GridContainer from "../elements/Grid/GridContainer";
import styles from "../assets/jss/components/headerLinksStyle.js";
//import stylesr from "../assets/jss/components/headerLinkstopStyle";

import * as authActions from '../../../auth/casino/actions';
import * as Gurl from '../../../global/Global.js';
import {useDispatch, useSelector} from 'react-redux';
import {TextFieldFormsy, SelectFormsy, CheckboxFormsy} from '../../../@casino';
import secsignupboxDb from "../data/secsignupbox.js";
//import { useTranslation } from 'react-i18next'
const history = createBrowserHistory()



let test = "ok";
let classicModal2 = null;
let setClassicModal2 = null;
let notSelectCountry = false;
let countryData = "0";
let setCountryData = null;

let currentCountryId = "99";
let setCurrentCountryId = null;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

const validEmailRegex = 
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
  var isExistCountryApiCall = false;
function SectionSignupbox(props) {
    var isTest = false;
    if(props != null && props.location != null && props.location.search != null){
        let params = queryString.parse(props.location.search);
        if(params != null && params.istest != null){
            isTest = true;
        }
    }
    const dispatch = useDispatch();
    const [isFormValid, setIsFormValid] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPrivacy, setIsPrivacy] = useState(false);
    const [classAdd, setClassAdd] = useState(false);
    const [countryCode, setCountryCode] = React.useState(0);
    const formRef = useRef(null);
    const register = useSelector(({auth}) => auth.register);
    const [mobileNumberMaxLen, setMobileNumberMaxLen] = React.useState(10);
    const [currentCountry, setCurrentCountry] = React.useState("India");
    [currentCountryId, setCurrentCountryId] = React.useState("99"); 
    [countryData, setCountryData] = React.useState("0");
    const [showPassword, setShowPassword] = useState(false);
    const { getAll, add, getByID, getByIndex, update } = useIndexedDB('Countries');
    const [isCaptcha, setIsCaptcha] = useState(false);
    const [userName, setUserName] = React.useState("");
    let recaptchaInstance = null;
    var captchaReceived = false; 
    
    const [recaptchaCode, setRecaptchaCode] = React.useState("NOVALUE");
    


    function countryWiseMobileValidate(event){
        if(countryData != "0" && countryData != null && event != null && event.target != null && event.target.value != null){
            countryData.map((jsonData) => { 
                if(jsonData.id === event.target.value){
                    setCurrentCountryId(jsonData.id);
                    setCurrentCountry(jsonData.title); 
                    setMobileNumberMaxLen(jsonData.mobile_number_length);
                }
            })
        }
    }  
    
    styles.signinbtn.backgroundImage = props.themeColors.bgColors.main_color;
    styles.signinbtn.color = props.themeColors.bgColors.text_primary_dark_color;
    styles.modalHeader.background = props.themeColors.bgColors.primary_dark_bgcolor;
    styles.modalBody.background = props.themeColors.bgColors.primary_dark_bgcolor;
    styles.modalFooter.background = props.themeColors.bgColors.primary_dark_bgcolor;
    styles.modalSubtitle.color = props.themeColors.bgColors.text_primary_color;
    styles.modalTitle.color = props.themeColors.bgColors.text_secondary_dark_color;
    
    
  styles.modalCloseButton.color = props.themeColors.bgColors.text_primary_color;
    styles.signupbixp.color = props.themeColors.bgColors.text_primary_color; 
    styles.form["& input"].color = props.themeColors.bgColors.text_primary_color; 
    styles.form["& label"].color = props.themeColors.bgColors.text_secondary_dark_color; 
    styles.selectboxx["& div div"].color = props.themeColors.bgColors.text_primary_color; 
    
    styles.signinmodalbtn.backgroundImage = props.themeColors.bgColors.main_color;
    styles.signinmodalbtn.background = props.themeColors.bgColors.main_color;
    styles.signinmodalbtn["&:hover"].background = props.themeColors.bgColors.main_color;
    styles.signinmodalbtn["&:hover"].backgroundImage = props.themeColors.bgColors.main_color;
    styles.signinmodalbtn.color = props.themeColors.bgColors.text_primary_dark_color;
    styles.tnctext.color = props.themeColors.bgColors.text_primary_color;
    styles.tnctext["& a"].color = props.themeColors.bgColors.text_secondary_color;
    styles.logoname.color = props.themeColors.bgColors.text_primary_color; 

    styles.logoname.fontFamily = props.themeColors.bgColors.logo_font; 
      styles.modalTitle.fontFamily = props.themeColors.bgColors.base_font; 
      styles.modalSubtitle.fontFamily = props.themeColors.bgColors.base_font;  
      styles.form["& label"].fontFamily = props.themeColors.bgColors.base_font;   
      styles.form["& input"].fontFamily = props.themeColors.bgColors.base_font; 
      styles.signupbix.fontFamily = props.themeColors.bgColors.base_font;
      styles.signinmodalbtn.fontFamily = props.themeColors.bgColors.base_font; 
      styles.tnctext.fontFamily = props.themeColors.bgColors.base_font; 
      

   // const { t } = useTranslation()
    let currentStyle = styles;
    const useStyles = makeStyles(styles);

    const classes = useStyles();

    if(isPopupOpen === "stop"){
        setClassicModal2(false);
        props.LoginBox.openPopup("login");
        setIsPopupOpen(false);
    }
   useEffect(() => {
        if ( register.success != null && register.success == false && register.error && (register.error.email || register.error.username || register.error.recaptcha || register.error.password || register.error.message || register.error.mobile) )
        {
            if(register.error.email || register.error.username || register.error.recaptcha || register.error.password || register.error.mobile){
                resetRecaptcha();
                if(register.error.username){
                    register.error.personal_mydata = register.error.username;
                    delete register.error["username"];
                }
                if(register.error.password){
                    register.error.user_password = register.error.password;
                    delete register.error["password"];
                }
                delete register.error["message"];
                //alert("JSONALERT" + JSON.stringify(register.error));
                formRef.current.updateInputsWithError({
                    ...register.error
                });
            }else if(register.error.message != null){
                resetRecaptcha();
                if(document.body.getElementsByClassName("message_register_one")[0] != null){
                    document.body.getElementsByClassName("message_register_one")[0].innerHTML=register.error.message;
                }
                setClassAdd(classes.msg = " errormsg" );
            }
            disableButton();
            captchaReceived = false;
            setRecaptchaCode("NOVALUE");
            
        }else{
            if(register.success != null && register.success == true && register.data != null && register.data.status != null && register.data.status > 0){
                if(formRef != null && formRef.current != null){
                    formRef.current.reset();
                }
                if(register.data.ask_otp_status === "1"){
                    
                    setTimeout(function() { 
                        if(document.body.getElementsByClassName("message_register_one")[0] != null){
                            document.body.getElementsByClassName("message_register_one")[0].innerHTML="";
                        }
                        setClassicModal2(false);
                        props.OtpBox.openPopup("otpbox", register.data.status);
                        setIsPopupOpen(false); 
                      }, 1000); 
                }else{
                    setTimeout(function() { 
                        if(document.body.getElementsByClassName("message_register_one")[0] != null){
                            document.body.getElementsByClassName("message_register_one")[0].innerHTML="";
                        }
                        setClassicModal2(false);
                        props.LoginBox.openPopup("login");
                        setIsPopupOpen(false); 
                      }, 5000); 
                }

                if(register.data.message != null){
                    setClassAdd(classes.msg = " successmsg" )
                    if(document.body.getElementsByClassName("message_register_one")[0] != null){
                        document.body.getElementsByClassName("message_register_one")[0].innerHTML=register.data.message;
                    }
                }
                  
            }
            
        }
    }, [register.error]);

    const resetRecaptcha = () => {
        if(recaptchaInstance != null){
            recaptchaInstance.reset();  
        }
        
      };
      var verifyCallback = function (response) {
        setRecaptchaCode(response);
        captchaReceived = true;
        if(response && isCaptcha === true){
          enableButton();
        }else{
          setIsFormValid(false);
        }
      };
      var gcErrorCallback = function (issue){
        disableButton();
        captchaReceived = false;
        setRecaptchaCode("NOVALUE");
     };

    function disableButton()
    {
        setIsFormValid(false);
        document.body
        .getElementsByClassName("signupbtnnew3")[0]
          .setAttribute("style", "opacity:0.5;");
    }

    function enableButton()
    {
        if((recaptchaCode !== "NOVALUE" && recaptchaCode.length > 0) || captchaReceived === true){
            setIsFormValid(true);
            document.body
            .getElementsByClassName("signupbtnnew3")[0]
              .setAttribute("style", "opacity:1;");
        }else{
        setIsCaptcha(true);
        }
         
    }

    function handleSubmit(model)
    { 
        disableButton();
        //model = {"recaptcha": recaptchaCode, ...model}
        model = {"recaptcha": recaptchaCode, "username": model.personal_mydata, "password": model.user_password, ...model}

        dispatch(authActions.submitRegister(model,setIsPopupOpen,props));

        document.body
        .getElementsByClassName("message_register_one")[0]
        .innerHTML=" " 
    }
    
    function getCurrentCountry(myCountryData){
        axios.get('https://ipapi.co/json/').then((response) => {
                let data = response.data;
                if(data != null && data.country_calling_code != null){
                    myCountryData.forEach((jsonData) => {
                        var phonecode = ("+" + jsonData.phonecode.toString()).toString();
                        if(phonecode === data.country_calling_code){
                            setCurrentCountry(jsonData.title);
                            setMobileNumberMaxLen(jsonData.mobile_number_length);
                            setCurrentCountryId(jsonData.id);
                        }
                    })
                }
            }).catch((error) => {
                console.log(error);
            });
    }
    function getcountrydata(){
    //    alert("dsfdfsds");
     if( isExistCountryApiCall === false){
         isExistCountryApiCall = true;
        const request = axios.post(Gurl.WLCOUNTRY_DATA, JSON.stringify({
            token: window.localStorage.getItem('jwt_access_token')
        }),{header:{"Content-Type": "application/x-www-form-urlencoded"}});
    
        request.catch((error) => {
            isExistCountryApiCall = false;
            setCountryCode("0");
        }); 
        request.then((response) => { 
            
       //     alert("hkjhh"+JSON.stringify(response));
            
            if(response.data.data != null){ 
                
                var myOutPut = (
                    response.data.data.map((jsondata) => (
                        <MenuItem value={jsondata.id} key={jsondata.id}>{jsondata.title} ({jsondata.phonecode})</MenuItem> 
                    ))
                );
                if(isTest === true){
                    //alert("OUTPUT:" + myOutPut)
                }
                setCountryCode(myOutPut);
                setCountryData(response.data.data);
                getCurrentCountry(response.data.data);
                response.data.data.map((oneByOneCountry) => {
                    if(oneByOneCountry != null && oneByOneCountry.id != null && oneByOneCountry.id > 0){
                        getByIndex('id', oneByOneCountry.id).then(personFromDB => {
                            if(personFromDB != null && personFromDB != undefined && JSON.stringify(oneByOneCountry) != JSON.stringify(personFromDB)){
                                update(oneByOneCountry).then(
                                    event => {
                                        //alert('ID Generated: ', JSON.stringify(event.target));
                                    },
                                    error => {
                                        //alert(error);
                                    }
                                );
                            }else{
                                if(JSON.stringify(oneByOneCountry) != JSON.stringify(personFromDB)){
                                    add(oneByOneCountry).then(
                                        event => {
                                            //alert('ID Generated: ', JSON.stringify(event.target));
                                        },
                                        error => {
                                            //alert(error);
                                        }
                                    );
                                }
                            }
                        });
                    }
                });
                isExistCountryApiCall = false;
            }else{
                isExistCountryApiCall = false;
                setCountryCode("0");
            }
        }); 
     }
             
    }   
    async function fetchLocalData(){
         
        const val = await getAll().then(personsFromDB => {
            alert("JSO:" + JSON.stringify(personsFromDB));
            return personsFromDB;
        });
        return val;
    }
    
    //alert(countryCode);   
    if(countryCode == "0" && isExistCountryApiCall === false){
        
        getAll().then(allData => {
            if(navigator != null && navigator.onLine != null && navigator.onLine !== true){
                if(allData === null || allData === undefined){
                    getcountrydata();
                }else{
                    var myOutPut = (
                        allData.map((jsondata) => (
                            <MenuItem value={jsondata.id} key={jsondata.id}>{jsondata.title} ({jsondata.phonecode})</MenuItem> 
                        ))
                    );
                    setCountryCode(myOutPut);
                }
            }else{
                getcountrydata();
            }
        });
    }
    

    function parseJwt(token) {
      if (!token) { return; }
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
  }
 function getCurrentThemeInfo(){
    if(window.localStorage.getItem('jwt_theme_token') != null){
        let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
        return themeData;
    }
  }

 
   var icon = null; 
   var brandName = null; 
  var themeInfo = getCurrentThemeInfo();
  
  if(themeInfo != null && themeInfo.id != null){ 
      if(themeInfo.icon != null && themeInfo.icon.length > 0){
        icon = themeInfo.icon;
      } 
      if(themeInfo.brand_name != null && themeInfo.brand_name.length > 0){
        brandName = themeInfo.brand_name;
      }  
  }
  // const brandComponent = "";
 




    

  [classicModal2, setClassicModal2] = React.useState(false);
  return (
    <div>
     {secsignupboxDb[sessionStorage.getItem('lang')].map((jsondata) => (   
        
      <div key={jsondata.id} className="sporto">
    <Dialog
    classes={{
        root: classes.center,
        paper: classes.modal + " sportot   " + classes.responsivemodell
    }}
    open={classicModal2}
    TransitionComponent={Transition}
    keepMounted
    onClose={() => setClassicModal2(false)}
    aria-labelledby="classic-modal-slide-title"
    aria-describedby="classic-modal-slide-description"
>
    <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
    >
        <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setClassicModal2(false)}
        >
            <Close className={classes.modalClose} />
        </IconButton>
        

        
         
    </DialogTitle>
    <Formsy
                onValidSubmit={handleSubmit}
                onValid={enableButton}
                onInvalid={disableButton}
                ref={formRef}
                autoComplete={"nope"}
                className={classes.form + " flex flex-col justify-center w-full " + classes.modalBody}
            >
                
    <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody + " signupmodallx"}
    > 

    <div style={{textAlign:"center", minHeight:"50px"}}>
        {(() => {
            // alert(JSON.stringify(themeInfo))
                if(themeInfo.icon != null && (themeInfo.icon != undefined && themeInfo.icon != "undefined" && themeInfo.icon.length > 0)) { 
                        return ( 
                            <img src={icon} height={"40px"} alt={""} style={{minHeight: "85px", paddingTop: "20px"}} className={classes.modellogo} />
                ) }else{
                return ( 
                        <h4 style={{minHeight: "85px", paddingTop: "20px"}} className={classes.logoname}>{brandName}</h4>  
                    )
            }
            })()}

            <h4 className={classes.modalTitle}>{jsondata.title} </h4>
    </div>
         
            <GridContainer style={{marginLeft:'0'}}>
<GridItem xs={12}><div className={classes.msg + " message_register_one " + classAdd} id="message"></div></GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <div className={classes.formggroup}>
                     <TextFieldFormsy 
                        label={jsondata.name}
                        id="personal_mydata"
                        value={userName}
                        onChange={(myText) => {if(myText.target != null && myText.target.value) { setUserName(myText.target.value.toLowerCase()) }}}
                        name="personal_mydata"
                        formControlProps={{
                            fullWidth: true
                        }}
                        validations={{
                            minLength: 5,
                            maxLength: 20,
                            noSpecialCase: function(value){
                                if(new RegExp(/^(?=[a-zA-Z0-9._]{0,50}$)(?!.*[_.]{2})[^_.].*[^_.].*$/).test(value.personal_mydata)){
                                    return true;
                                }else{
                                    return false;
                                }
                            },
                            noSpaceCase: function(value){
                                if(new RegExp(/^(?=.*[ ]).*$/).test(value.personal_mydata)){
                                    return false;
                                }else{
                                    return true;
                                }
                            },
                        }}
                        validationErrors={{
                            minLength: "Minimum 5 character length",
                            maxLength: "Maximum 20 character allowed",
                            noSpecialCase: "Special character not allowed",
                            noSpaceCase: "Space not allowed",
                        }}
                        autoComplete={"new-mydata"}
                        variant="outlined"
                        required
                    /> 
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                <div className={classes.formggroup}>
                    <TextFieldFormsy
                        label={jsondata.email}
                        id="email"
                        name="email"
                        formControlProps={{
                            fullWidth: true
                        }}
                        validations={{
                            isEmail: true
                        }}
                        validationErrors={{
                            isEmail: 'Please enter a valid email'
                        }}
                        
                        variant="outlined"
                        required
                    /> 
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}> 
                <div className={classes.formggroup}>
                    <TextFieldFormsy
                        formControlProps={{
                            fullWidth: true
                        }}
                        type="password"
                        name="user_password"
                        id="user_password"
                        label={jsondata.password}
                        validations={{
                            minLength: 6,
                            maxLength: 20,
                            upperCase: function(value){
                                if(new RegExp(/^(?=.*[A-Z]).*$/).test(value.user_password)){
                                    return true;
                                }else{
                                    return false;
                                }
                            },
                            // specialCase: function(value){
                            //     if(new RegExp(/^(?=.*[!@#$&*]).*$/).test(value.user_password)){
                            //         return true;    
                            //     }else{
                            //         return false;
                            //     }
                            // },
                            numberCase: function(value){
                                if(new RegExp(/^(?=.*[0-9]).*$/).test(value.user_password)){
                                    return true;
                                }else{
                                    return false;
                                }
                            },
                        }}
                        validationErrors={{
                            minLength: "Length should be minimum 6",
                            maxLength: "Length should not be greater 20",
                            upperCase: "Atleast one uppercase",
                            numberCase: "Atleast one numeric char",
                        }}
                        
                        variant="outlined"
                        required
                    />
                    </div>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}> 
                <div className={classes.formggroup}>
                    <TextFieldFormsy
                        formControlProps={{
                            fullWidth: true
                        }}
                        type="password"
                        id="confirm_password"
                        name="confirm_password"
                        label={jsondata.confirmpassword}
                        validations={{
                            equalsField: "user_password"
                        }}
                        validationErrors={{
                            equalsField: 'Please Re-enter same password'
                        }}
                        InputProps={{
                            className: 'pr-2',
                            type: showPassword ? 'text' : 'password',
                            endAdornment: (
                                <InputAdornment position="end" style={{width:'12px',margin:'0px'}}>
                                <IconButton onClick={() => setShowPassword(!showPassword)} style={{padding:'0px'}}> 
                                    <i className={showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} style={{fontSize: '18px',color: '#ccc'}}></i>
                                </IconButton>
                                </InputAdornment>
                            )
                        }}
                        variant="outlined"
                        required
                    />
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <div className={classes.formggroup}>
                        <div style={{display:"none"}}>{countryCode}</div>
                    {(() => {
                        if(countryCode !== "0" && countryCode !== "Processing" && countryCode !== 0){
                            return(
                                <SelectFormsy className={classes.selectboxx + " selectbox"}
                                        key={currentCountryId}
                                        name="country_id"
                                        label="Country" 
                                        value={currentCountryId}
                                        variant="outlined"
                                        onChange={(event) => countryWiseMobileValidate(event)}
                                        required
                                >
                                        {countryCode}
                                </SelectFormsy>
                            )
                        }else{
                            return (
                            <SelectFormsy className={classes.selectboxx + " selectbox"}
                                        key={currentCountryId}
                                        name="country_id"
                                        label="Country" 
                                        value={currentCountryId}
                                        variant="outlined"
                                        onChange={(event) => countryWiseMobileValidate(event)}
                                        required
                                >
                                        {countryCode}
                                </SelectFormsy>
                            )
                        }
                    })()}
                        
                    </div>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}> 
                <div className={classes.formggroup}> 
                    <TextFieldFormsy
                        label={jsondata.mobilen}
                        id="mobile"
                        name="mobile" 
                        type="number"
                        value=""
                        formControlProps={{
                            fullWidth: true
                        }}
                        
                        validations={"isLength:"+mobileNumberMaxLen}
                        validationErrors={{
                            isLength: "Mobile number length must be " + mobileNumberMaxLen + " digit for " + currentCountry, 
                        }}
                        
                        variant="outlined"
                        required
                    />
                    </div>
                </GridItem>
                
                <GridItem xs={12} sm={12} md={12}>
                    <ReCAPTCHA 
                        ref={e => recaptchaInstance = e}
                        onChange={verifyCallback}  
                        className={classes.signcaptcha}
                        theme="dark"
                        sitekey={Gurl.reCaptchaKey} 
                        onExpired={gcErrorCallback}
                        onErrored={gcErrorCallback}  
                        name="recaptcha"  
                        />
                    </GridItem>
                  
                <GridItem xs={12} sm={12} md={12}>  

                <div className={classes.tnctext} dangerouslySetInnerHTML={{__html: jsondata.aggreetnc.replace(jsondata.aggreetncs,"<a href='/terms-of-service' target='_blank'>"+jsondata.aggreetncs+"</a>" )}}></div>
 
                </GridItem>
            </GridContainer>

         



    </DialogContent>
    <DialogActions className={classes.modalFooter}>
        <Button color="primary" className={classes.signinmodalbtn +" signupbtnnew3"} type="submit"  disabled={!isFormValid}>
            {jsondata.sbmitbtn}
        </Button>
       
    </DialogActions>
    </Formsy>                  
</Dialog>
</div>
))}
</div>
  );
}

SectionSignupbox.propTypes = {
    openPopup: function(isState){
        if(isState === "signup" && setClassicModal2 != null){
            setClassicModal2(true);
          }  
          if(isState === false){
            setClassicModal2(false);
          } 
    }
};

SectionSignupbox.openPopup = function(isState){
    if(isState === "signup" && setClassicModal2 != null){
        setClassicModal2(true);
      }  
      if(isState === false){
        setClassicModal2(false);
      } 
};
export default SectionSignupbox;
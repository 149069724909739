import { container } from "../../casino-kit-react.js";
import Background5 from "../../img/verify.png";
import Background6 from "../../img/verifyed.png";
const tabsStyle = {
    signinnerpagebox:{
        background:"#000",
        padding:"0px 20px 0px",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            padding:"0px 0px 0px",
          },
    },
    tablebonus:{
        width:"100%",  
    },
    tablewrapresposnive:{
      overflow:"auto", 
    },
    historytablebox:{  
      "& table":{
        whiteSpace: "nowrap",
        width:"100%",
        fontFamily:"ubuntu",
        color:"#ccc",
        fontSize:"12px", 
      },
      "& table tr th":{
        padding:"10px",
        background:"#0f0f0f",
      },
      "& table tr td":{
        padding:"10px",
        background:"#2b2b2b",
      }
    },
    closeimg:{
        position:"absolute",
        right:"20px",
        top:"20px",
        fontSize:"30px",
        color:"#fff"  
    },
    approved:{
        color:"#03d413"
      },
    rejected:{
        color:"#e42323"
      }, 
    actionbtn:{
        background:"#efde1d",
        color:"#000",
        fontSize:"12px",
        padding: "4px 8px !important",
        marginRight: "5px",
      "&:hover":{
        background:"#efde1d",
        color:"#000",
      },  
      "& span":{
        color: "#000 !important",
        background: "none !important",
        margin: "0px !important",
        padding: "0 !important",
      },
      "& span i":{
        marginRight:"5px",
        color:"#000 !important"
      }
    },
    popupimg:{
      display:"none",
      background:"rgba(0,0,0,0.7)",
      width:"100%",
      height:"100%",
      position:"fixed",
      top: "0",
      left: "0",
      zIndex: "99",
      textAlign: "center",
      paddingTop: "10%",
      "& img":{
        maxWidth:"60%",
        MaxHeight:"80%"
      }
    },
    verify:{
        backgroundImage:"url(" + Background5 + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "100%",
          float:"right",
          width:"24px",
          height:"24px",
          position:"absolute",
          top:"10px",
          right:"10px",
    },
    verifyed:{
        backgroundImage:"url(" + Background6 + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "100%",
          position:"absolute",
          top:"10px",
          right:"10px",
          width:"24px",
          float:"right",
          height:"24px",
    },
    mobiactionbtn:{
      ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
        marginBottom: "5px",
      },
    },
    
    loginpageheadinginner:{
      ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
        padding: "0px !important", 
      },
    },
    moduleboxwrap:{
        padding:"10px 20px 30px",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
          padding: "0px 10px 0px",
        },
        "& div .MuiGrid-container":{
          ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            width:"-webkit-fill-available",

          },
         
        }
    }, 
    modulebox:{
        border:"1px solid #ffb80c",
        background:"#000",
        position:"relative",
        borderRadius:"10px",
        padding:"10px",
        textAlign: "center",
        "& img":{
            maxWidth:"100%",
            height:"160px",
        },
        "& h3":{
            marginTop: "10px",
            fontFamily:"ubuntu",
            fontSize:"18px",
            textAlign:"center",
            color:"#fff !important"
        },
    },
    btntabwarp:{
        marginBottom: "10px",
        overflow: "auto",
        display: "flex",
        "& .tabbtn":{ 
            borderRadius:"0px",
            margin:"0px",
            fontFamily:"ubuntu",
            padding:"10px 25px",
            fontSize:"14px",
            color:"#fff !important",
            borderRight : "1px solid #2b2b2b",
            borderBottom : "1px solid #2b2b2b",
        },
        "& .active":{
            borderRight : "1px solid #2b2b2b",
            borderBottom : "2px solid #2fe70c",
            color:"#fff !important"
        },
        "& .tabbtn:first-child":{
            borderRadius:"0px 0px 0px 0px"
        },
        "& .tabbtn:last-child":{
            borderRadius:"0px 0px 0px 0px"
        }
    },
    tablewrap:{
        overflow:"auto",
        marginBottom:"20px",
        width:"100%",
        "& th":{
            background:"#000",
            padding:"15px",
            fontFamily:"ubuntu",
            fontSize:"14px"
        },
        "& td":{
            padding:"15px",
            fontFamily:"ubuntu",
            fontSize:"14px"
        },
        "& td table th":{
            padding:"5px 10px", 
        },
        "& td table td":{
            padding:"5px 10px", 
        },
        "& a":{
            color:"#FFF"
        },
        "& tr:nth-child(2n)":{
            background: "#2d2d2d",
        },
        "& tr:nth-child(2n+1)":{
            background:"none"
        }
    }, 
    signinnerpageboxin:{
        background:"#131212",
        margin:"0px 0px 50px",
        borderRadius:"10px"
    },
    tableheading:{
        fontWeight:"bold",
        color: "#fff !important",
        background:"#000 !important"
    }, 
    section: {
        background: "#EEEEEE",
        padding: "70px 0"
    },
    pageheadinginner:{
        fontFamily: "ubuntu",
        color:"#fff",
        fontSize:"18px",
        padding:"10px 40px 0px 10px"
    },
    depositbtn:{
        color: "#000 !important",
        border: "0px",
        cursor: "pointer",
        padding: "4px 25px",
        float:"left",
        fontSize: "14px",
        boxShadow: "0px 0px 0px",
        transition: "all 0.3s ease-in",
        fontWeight: "600",
        borderRadius: "3px",
        lineHeight:"30px",
        textTransform: "capitalize",
        background: "#efde1d",
        margin: "10px 5px 0px",
        display: "block",
        "& span":{
            padding:"0px",
            color:"#000"
        },
        "&:hover":{
            background:"#efde1d",
            color:"#000",
            "& span":{
                color:"#000"
            },
        },
        "&:focus":{
          background:"#efde1d",
          color:"#000",
          "& span":{
              color:"#000"
          },
        }
    },
    messagebox:{
        background: "#252f5a",
        padding:"30px 0px",
        borderRadius:"10px",
    }, 
    list:{
      width:"100%",
      display:"block"
    },
    listItem:{
        width:"100%",
        paddingTop:"15px",
        paddingBottom:"15px",
        display:"block",
        color:"#fff",
        fontFamily:"ubuntu",
        fontSize:"18px",
        "& a":{
            margin:"0px",
        },
        "& p":{
            margin:"0px",
        },
        "&:nth-child(even)":{
            background:"#2d2d2d"
        },
        "&:nth-child(odd)":{
            background:"none"
        }
    },
    statementfilterbox:{
        fontSize:"18px",
        fontFamily: "ubuntu",
        textAlign: "left",
        color:"#fff",
        paddingBottom: "0px",
        "& select":{
            background:"none",
            border:"0px",
            width:"100%",
            marginTop:"10px",
            borderBottom:"1px solid #f1f1f1",
            height:"40px",
            color:"#fff",
        },
        "& select option":{
            color:"#000",
        },
        "& .selectbox":{
            marginBottom:"20px"
        }
    },
    selectboxx:{ 
        
        border:"0px",
        width:"100%",
        fontSize: "16px",
        fontFamily: "ubuntu",
        padding: "11px 0 11px",
        borderBottom:"1px solid #bdbdbd",
        color:"#fff",
        background:"none !important",
        marginBottom:"20px",
        "& input":{
            color:"#fff !important",
            fontSize:"8px",
            fontFamily:"ubuntu", 
            paddingLeft:"0px",
            paddingTop:"0px",
            height: "28px",
            marginTop:"24px",
            paddingBottom: "0px",
        },
        "& label":{
            color:"#fff !important", 
            fontFamily:"ubuntu", 
            marginTop:"-3px",
            fontSize:"14px",
            transform: "translate(0px, -3px) scale(1)",
        },
        "& div:hover fieldset":{
            borderColor:"#fff !important",
        },
        "& fieldset":{
            color:"#fff !important",
            border:"0px",
            fontFamily:"ubuntu",
            borderBottom:"1px solid #fff",
            borderColor:"#fff !important",
            borderRadius:"0px",
        },
        "& option":{
            color:"#000",
        },
        "& div div":{
            color: "#fff",
            fontSize: "14px !important",
            fontFamily: "ubuntu",
            padding:"0px",
        }
      },
      formtodate:{
        color:"#fff !important",
        fontFamily:"ubuntu",
        fontSize:"18px",
        paddingLeft: "0",
        marginBottom: "20px", 
        "& input":{
            color:"#fff !important",
            fontSize:"14px",
            fontFamily:"ubuntu", 
            paddingLeft:"0px",
            paddingTop:"0px",
            height: "30px",
            paddingBottom: "5px",
        },
        "& label":{
            color:"#fff !important", 
            fontFamily:"ubuntu", 
            fontSize: "14px",
            transform: "translate(0px, -2px) scale(1) !important",
        },
        "& div:hover fieldset":{
            borderColor:"#fff !important",
        },
        "& fieldset":{
            color:"#fff !important",
            border:"0px",
            fontFamily:"ubuntu",
            borderBottom:"1px solid #fff",
            borderColor:"#fff !important",
            borderRadius:"0px",
        },
    },
    depositlistwarp:{
        paddingBottom: "20px",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            overflow:"auto",  
            width:"100%",
          }, 
    },
    depositlistw:{
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"670px",
          }, 
    },
    statementboxmobi:{
      ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
        padding:"10px 0px 0px !important",
      }, 
    },
    statementbox:{
        background: "none",
        padding:"20px 10px 0px",
       // borderRadius:"10px",
        "& p":{
            color:"#a1aed4",
            fontSize:"20px",
            fontFamily: "ubuntu",
        },
        "& i":{
            color:"#a1aed4",
            fontSize:"88px",
        },
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            padding: "20px 10px 0px",
          }, 
    },

    container,
    textCenter: {
        textAlign: "center"
    },

    formgroupwidthlabel:{
        "& label":{
          fontFamily:"ubuntu",
          fontWeight:"normal",
          fontSize:"12px",
          color:"#ccc",
          paddingBottom:"10px",
          display:"block"   
        },
        "& select":{
          border:"1px solid #4f4f4f",
          fontFamily:"ubuntu",
          fontWeight:"normal",
          fontSize:"12px",
          color:"#ccc",
          padding:"10px",
          width:"100%",
          background:"none",
          display:"block"   
        },
        "& input":{
          border:"1px solid #4f4f4f",
          fontFamily:"ubuntu",
          fontWeight:"normal",
          fontSize:"12px",
          color:"#ccc",
          padding:"8px",
          width:"100%",
          background:"none",
          display:"block"   
        }
      },
      formbtn:{
        background:"#efde1d",
        color:"#000",
        textAlign:"center",
        fontFamily:"ubuntu",
        fontWeight:"bold",
        fontSize:"14px", 
        padding:"9px 20px",
        width:"100%",
        margin:"0",
      },
      formfilter:{
        margin:"0"
      },
      verifyaccounttable:{
        width:"100%",
        overflow:"auto",
        
        padding:"20px 0px 10px",
        "& table":{
          whiteSpace: "nowrap",
          width:"100%",
          border:"0",
          fontFamily:"ubuntu", 
          "& tr td span":{
            background:"#8f8f8f",
            color:"#fff",
            padding:"6px 9px",
            borderRadius:"60px",
            marginRight:"6px",
          },
          "& tr td a":{
            padding:"0px 6px", 
          },
          "& tr td a i":{
            color:"#bdbdbd", 
          },
          
        },
        "& tr th":{
          padding:"10px",
          fontSize:"14px",
          color:"#ccc"
        },
        "& tbody tr td":{
          padding:"10px",
          fontSize:"14px",
          color:"#ccc"
        },
        "& tbody tr":{ 
          background:"#0f0f0f", 
        },
        "& tbody tr:hover":{  
            background:"#404040", 
        }
      },
      
      verifyedcolor:{
        background:"none !important",
        padding:"0px !important",
        color:"#2fe70c !important"
      },
      verifycolor:{
        background:"none !important",
        padding:"0px !important",
        color:"#e81616 !important"
      },
      pending:{
        background:"none !important",
        padding:"0px !important",
        color:"#efde1d !important"
      },
      firstwrap:{
        padding: "10px",
        background: "#2b2b2b",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
          padding: "5px 1px 1px",
        },
        },
        firstbox:{
            background: "#1e1e1e",
        },
        secondwrap:{
            background:"#1e1e1e",
            padding:"10px",
            ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
              padding: "5px 1px 1px",
            },
          },

          tableresponsi:{
            color:"#fff !important",
            ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
              overflow: "auto",
            },
          },
          table:{
            boxShadow:" 0px 10px 40px 0px rgba(0, 0, 0, 0.4)",
            position: "relative",
            width: "100%",
            fontFamily: 'Open Sans',
            textAlign: "left",
            marginBottom: "1rem",
            color: "#212529",
            '& tr:hover': {
              background:"#192246",
            },
            '& td': {
              borderTop:"1px solid rgba(255, 255, 255, 0.09)",
              border:"0px",
              verticalAlign:"middle",
              padding:"20px 15px",
              fontWeight:"600",
              fontSize:"18px",
              textTransform:"uppercase",
              color:"#a1aed4",
            },
            '& th': {
              borderTop:"1px solid rgba(255, 255, 255, 0.09)",
              border:"0px",
              fontSize:"18px",
              fontWeight:"600",
              textTransform:"uppercase",
              borderBottom:"0px",
              padding:"20px 15px",
              background:"#0b122e",
              color:"#fff",
            },
            '& img': {
              width:"40px",
              height:"40px",
              borderRadius:"50%",
              verticalAlign:"middle",
              display:"inline-block"
            },
            '& td:nth-child(1) img': {
              marginRight:"5px",
            },
            '& td:nth-child(3) img': {
              marginRight:"5px",
            },
            '& td:nth-child(6) img': {
              marginRight:"5px",
            },
            '& td:nth-child(1)': {
              color:"#fff",
            },
            '& td:nth-child(3)': {
               color:"#045cf0",
            },
            '& td:nth-child(5)': {
              color:"#f0ad4e",
            },
            '& td:nth-child(6)': {
              color:"#f0ad4e",
            },
            '& td:last-child': {
              color:"#f0ad4e",
            },
          },
          mybetwrap:{
              paddingTop:"20px",
              ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
                padding: "0px 10px ",
              },
          },
          bonusbox:{
            padding:"10px"
          },
          norecordimg:{
            ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
              margin: "0px !important",
              height: "110px",
            },
          },
          withdrawlistmobiwrap:{
            ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
              overflow: "auto", 
            },
          },
          withdrawlistmobi:{
            // ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            //   width: "600px", 
            // },
          },
          balancehistory:{
            color:"#ccc",
            fontSize:"12px",
            fontFamily:"ubuntu",
            "& table":{
              width:"100%"
            },
            "& table td":{
              padding:"10px",
              "& table p":{
                textAlign:"center"
              }
            }
          },
          
          bonusbalancewrap:{
            fontFamily:"ubuntu",
            color:"#fff",
            opacity:"0.4",
            borderBottom:"5px solid #efde1d",
            "& h5":{
              margin:"0px",
              padding:"0px",
              fontSize:"18px",
              lineHeight:"20px",
              fontWeight:"bold",
            },
            "& p":{
              margin:"0px",
              fontSize:"12px",
              padding:"0px",
              textTransform:"uppercase",
              color:"#ccc",
            },
          },
          bonusmainbalance:{
            fontFamily:"ubuntu",
            color:"#fff",
            opacity:"0.4",
            textAlign:"right",
            "& h4":{
              fontSize:"24px",
              margin:"0px",
              fontWeight:"bold",
              lineHeight:"26px",
              padding:"0px",
            },
            "& p":{
              margin:"0px",
              fontSize:"12px",
              padding:"0px",
              color:"#ccc",
            },
          },
          activebonus:{ 
            textAlign:"right",
            "& h5":{
              color:"#efde1d",
              fontWeight:"bold",
            },
            "& p":{
              color:"#ccc",
            }
          },
          balancebonus:{
            color:"#ccc",
            marginTop:"20px",
            fontSize:"12px",
            fontFamily:"ubuntu", 
            width:"100%",
            "& td":{
              padding:"10px",
              background:"#2b2b2b",
              "& p":{
                textAlign:"left",
                fontSize:"12px"
              }
            },
            "& tr:nth-child(odd) td":{
              background: "#000",
            }
          },
          balancebonusinner:{
            width:"100%",
            "& tr th":{
              borderBottom:"1px solid #ccc"
            }, 
          },
          bonusbalancewrapactive:{
            opacity:"1"
          },
          bonusmainbalanceactive:{
            opacity:"1",
            overflow:"auto",
          },
          depositform:{
            ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
                width:"100%",
            },
            "& input":{
              background:"none",
              color:"#fff",
                width:"100%",
                fontSize: "14px", 
                borderBottom:"1px solid #bdbdbd",
                marginBottom:"10px",
            },
            "& label":{
                color:"#bdbdbd",
                fontSize:"14px",
                fontFamily:"ubuntu",
                transform: "translate(0px, 20px) scale(1)",
            },
            "& .MuiFormLabel-root.Mui-focused":{
                color:"#bdbdbd",
            },
            "& div":{
              width:"100%",
              ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
                marginLeft:"0px",
                marginRight:"0px",
                paddingLeft:"0px",
                paddingRight:"0px"
                },
            },
            "& fieldset":{
                color:"#fff", 
                border:"0px",
              },
            "& select":{
                background:"none",
                marginBottom:"20px",
                lineHeight:"40px",
                height:"40px",
                width:"100%",
                border:"0px",
                fontSize: "16px",
                borderBottom:"1px solid #a1aed4"
            },
            "& .selectbox":{
                background:"none"
            }
        }, 
        form:{
            margin:"0px"
        },
        clearboth:{
          clear:"both",
        }
};

export default tabsStyle;

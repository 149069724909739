import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar"; 
import Button from "@material-ui/core/Button"; 
import styles from "../assets/jss/components/headerStyle.js";
import GridContainer from "../elements/Grid/GridContainer";
import GridItem from "../elements/Grid/GridItem"; 
import CustomDropdown from "../elements/CustomDropdown/CustomDropdown"; 
import stylesr from "../assets/jss/components/headerLinkstopStyle";
import PageVisibility from 'react-page-visibility';
import SectionPopup from "./SectionPopup";
import sectionpop from "./SectionPopup";  
import menu from "../assets/img/menu.png";
import Slide from "@material-ui/core/Slide"; 
import usermenuDb from "../data/usermenu"; 
import jwt from 'jsonwebtoken'; 
import jwtDecode from 'jwt-decode';
import Balance from "../assets/img/coinbalace.png";
import jwtService from '../../../services/jwtService'
import axios from 'axios';
import * as globalurl from '../../../global/Global.js';  
import SectionLoginboxMobile from "./SectionLoginBoxMobile";
import SectionLoginbox from "./SectionLoginBox";
import SectionAllprofile from "./SectionAllprofile";
import SectionOtpbox from "./SectionOtpBox"; 
import SectionOtpboxMobile from "./SectionOtpBoxMobile"; 
import SectionSignupboxMobile from "./SectionSignupBoxMobile";
import SectionSignupbox from "./SectionSignupBox";
import SectionForgotbox from "./SectionForgotBox"; 
import 'react-accessible-accordion/dist/fancy-example.css';


var isExistApiCall = false;
 var isExistApiCalls = false;
var sideMenuEvent = 0;
var isBalanceLoop = false;
 
var isAlreadyOpen = false;
export default function SectionHeaderLogin(props) {
  const [userName, setUserName] = React.useState("Menus");
  const [topMenus, setTopMenus] = React.useState(0);
  const [sideMenus, setSideMenus] = React.useState(0); 
  const [myBalance, setMyBalance] = React.useState(false);
  const { ...sectionPopup} = sectionpop;
  

  if(props.themeColors != null){
    styles.balanceboxwrapmobile.background = props.themeColors.bgColors.secondary_dark_bgcolor; 
    
    styles.headersigninfix.borderColor = props.themeColors.bgColors.primary_inner_bgcolor; 
    styles.headersigninfix.background = props.themeColors.bgColors.secondary_bgcolor; 
    styles.balnceiconbox.background = props.themeColors.bgColors.primary_bgcolor
    styles.balnceiconbox["& img"].filter = props.themeColors.bgColors.filter
    styles.topheaderrow.background = props.themeColors.bgColors.secondary_dark_bgcolor;
    stylesr.headbackground.backgroundColor = props.themeColors.bgColors.primary_bgcolor;
     styles.navigationitem.color = props.themeColors.bgColors.text_primary_color;
    styles.toolbarafter["&::before"].backgroundColor = props.themeColors.bgColors.primary_bgcolor;
    styles.toolbarafter["&::after"].borderBottomColor = props.themeColors.bgColors.primary_bgcolor;
    stylesr.dropdownLink.color = props.themeColors.bgColors.text_secondary_dark_color;
    stylesr.navLink.color = props.themeColors.bgColors.text_secondary_color;  
    styles.coinbalance.color = props.themeColors.bgColors.main_color
    styles.plusbalance.color = props.themeColors.bgColors.text_secondary_color
    styles.balancerefresh.color = props.themeColors.bgColors.main_color
    styles.menusignin.color = props.themeColors.bgColors.text_primary_color
    styles.menusignin["&:hover"].backgroundColor = props.themeColors.bgColors.secondary_dark_bgcolor
    styles.usernotibtn.background = props.themeColors.bgColors.main_color
    styles.usernotibtn.color = props.themeColors.bgColors.text_primary_dark_color 
    styles.balanceamount.color = props.themeColors.bgColors.text_primary_color
    styles.balanceamount["&:hover"].color = props.themeColors.bgColors.text_secondary_color
    styles.usersigninanker.color = props.themeColors.bgColors.text_primary_color
    styles.usersigninanker["&:hover"].background = props.themeColors.bgColors.main_color
    styles.usersigninanker["&:hover"].backgroundColor = props.themeColors.bgColors.main_color
    styles.usersigninanker["&:hover"].color = props.themeColors.bgColors.text_primary_dark_color
    styles.notificationmsgbox["& h5"].color = props.themeColors.bgColors.text_secondary_color
    styles.notificationmsgbox["&:hover"].background = props.themeColors.bgColors.main_color
    styles.notificationmsgbox["&:hover h5"].color = props.themeColors.bgColors.text_primary_dark_color  
    styles.rightsidebarbtn["&:hover"].color = props.themeColors.bgColors.text_primary_color;
    styles.rightsidebarbtn.color = props.themeColors.bgColors.text_secondary_color
    styles.listItemsignimg.background = props.themeColors.bgColors.primary_bgcolor;
    styles.navigationwrap.borderColor = props.themeColors.bgColors.border_color;
    styles.mainboxsearch.background = props.themeColors.bgColors.primary_bgcolor;
    // styles.mainboxsearch["& input"].borderColor = props.themeColors.bgColors.primary_bgcolor;
    styles.signinbtn.color = props.themeColors.bgColors.text_primary_color; 
     styles.listItemsignimg["& img"].filter = props.themeColors.bgColors.filter
     styles.mobilemenu.filter = props.themeColors.bgColors.filter
     
    styles.listheadsidebartop.background = props.themeColors.bgColors.secondary_dark_bgcolor
    styles.listheadsidebartop["& h6"].color = props.themeColors.bgColors.text_secondary_dark_color
    styles.listheadsidebaranker.color = props.themeColors.bgColors.text_primary_color
    styles.listheadsidebaranker["&:hover"].background = props.themeColors.bgColors.main_color
    styles.listheadsidebaranker["&:hover"].color = props.themeColors.bgColors.text_primary_dark_color
    styles.usersigninbtn.color = props.themeColors.bgColors.text_primary_color
    styles.usersigninbtn.background = props.themeColors.bgColors.primary_bgcolor
    styles.logoutbtnt.color = props.themeColors.bgColors.text_primary_color
    styles.logoutbtnt.background = props.themeColors.bgColors.primary_bgcolor
    styles.logoname.color = props.themeColors.bgColors.text_primary_color
    

    stylesr.dropdownLink.fontFamily = props.themeColors.bgColors.base_font; 
    styles.logoname.fontFamily = props.themeColors.bgColors.logo_font; 
    styles.menusignin.fontFamily = props.themeColors.bgColors.base_font; 
    styles.signinbtn.fontFamily = props.themeColors.bgColors.base_font;  
    styles.listheadsidebaranker.fontFamily = props.themeColors.bgColors.base_font;   
    styles.usersigninanker.fontFamily = props.themeColors.bgColors.base_font;    
    styles.usersigninanker["& span"].fontFamily = props.themeColors.bgColors.base_font;
    styles.usersigninbtn.fontFamily = props.themeColors.bgColors.base_font; 
    styles.logoutbtnt.fontFamily = props.themeColors.bgColors.base_font;   
    styles.listheadsidebartop["& h5"].fontFamily = props.themeColors.bgColors.base_font;
    
    function myFunction(x) {
      if (x.matches) { // If media query matches 
        // styles.usersigninbtn.background = props.themeColors.bgColors.main_color
        // styles.headsigninbg.background = props.themeColors.bgColors.main_color
      } else {
      }
    } 
    var x = window.matchMedia("(max-width: 1000px)")
    myFunction(x) // Call listener function at run time
    x.addListener(myFunction)
  }
  
  // const pageName = "dashboard";
  var parentId = 0;
  if(window.localStorage.getItem("header_access_token")){
    const decodedData = jwtDecode(window.localStorage.getItem("header_access_token"));
    if(decodedData.TopMenu != null){
      decodedData.TopMenu.forEach((myMenuData) => {
        if(myMenuData.path === "dashboard"){
          parentId = myMenuData.id;
        }
      });
    }
  }
   

  async function getBalanceFromServer(){
    if(isExistApiCall === false){
      isExistApiCall = true;
      let pro = new Promise((resolve,reject) => { 
        const request = axios.post(globalurl.GETBALANCE , {
            token: window.localStorage.getItem('jwt_access_token')
        });
        request.then((response) => {
          isExistApiCall = false;
            if(response.data != null){
                var data = {
                    "status":true,
                    "data":response.data
                }
                resolve(data);
            }else{
                resolve({"status":false, "message":'No record found'});
            }
        });
        request.catch((error) => {
          isExistApiCall = false;
            reject(error);
        });
      }); 
      const val = await pro;
      return val;
    }
  }
  async function getBalanceNow(from){
    //alert(from);
    if(window.localStorage.getItem('jwt_access_token') != null){
      var MyGetBalance = await getBalanceFromServer();
      if(MyGetBalance != undefined && MyGetBalance != null && MyGetBalance.status != null && MyGetBalance.status === true && MyGetBalance.data != null){
        if(MyGetBalance.data.balance != null){
          setMyBalance(MyGetBalance.data.balance);
        }else{
          setMyBalance(0);
        }
      }else{
        if(MyGetBalance != null && MyGetBalance !== undefined && MyGetBalance.status != null && MyGetBalance.status === "INVALID_PARAMETER"){
          setTimeout(function() { 
              sectionpop.openPopup("popup", "Hello " + (loginInfo.username), " Your session expired. Please login again.");
              setTimeout(function() { 
                window.location.reload();
              }, 2000);
          }, 1000);
        }
        if(MyGetBalance !== undefined){
          setMyBalance(0);
        }
      }
    }
  }
  if(myBalance === false){
    setMyBalance("Loading...")
    if (jwtService.isAuthenticationLogin() === true){
      getBalanceNow(1);
    }
  }
  var refreshBalance = function(){
    setMyBalance("Loading...")
    if (jwtService.isAuthenticationLogin() === true){
      getBalanceNow(2);
    }
  }
  function getCurrentLoginInfo(){
    if(window.localStorage.getItem('jwt_access_token') != null){
        let themeData = parseJwt(window.localStorage.getItem('jwt_access_token'));
        return themeData;
    }
  } 
  var loginInfo = getCurrentLoginInfo();
  if((sessionStorage.getItem('welcome_message') === null || sessionStorage.getItem('welcome_message') === undefined || sessionStorage.getItem('welcome_message') === false)  && jwtService.isAuthenticationLogin() === true && loginInfo && loginInfo.username != null && loginInfo.username !== undefined && themeInfo && themeInfo.brand_name != null && themeInfo.brand_name !== undefined){
    if(sectionpop && isAlreadyOpen === false){
      refreshBalance();
      isAlreadyOpen = true;
      setTimeout(function() { 
          getUserName();
          //sectionpop.openPopupWithButton("popup", "Hey " + (loginInfo.username), "Welcome to " + (themeInfo.brand_name).toUpperCase());
          sessionStorage.setItem('welcome_message', true);
      }, 2000);
    }
  }else{
    setTimeout(function() { 
      getUserName();
    }, 1000);
  }
  
  if (jwtService.isAuthenticationLogin() === true && isBalanceLoop === false){
    isBalanceLoop = true;
    fetchBalanceinterval();
  }
  function fetchBalanceinterval(){
    getBalanceNow(3);
    setTimeout(function() { 
      if (jwtService.isAuthenticationLogin() === true){
        fetchBalanceinterval();
      }else{
          setTimeout(function() { 
              sectionpop.openPopup("popup", "Hello " + (loginInfo.username), " Your session expired. Please login again.");
              setTimeout(function() { 
                window.location.reload();
              }, 2000);
          }, 1000);
      }
    }, 20000);
  }
  async function getDataFromServer(menutype, myParent, myPageName){
    if(isExistApiCalls === false){
      isExistApiCalls = true;
    let pro = new Promise((resolve,reject) => {
       
        const request = axios.post(globalurl.GETMENUS , {
            token: window.localStorage.getItem('jwt_access_token'),
            menutypes: menutype,
            parent_id: myParent,
            pageslug: myPageName
        });
        request.then((response) => {
           isExistApiCalls = false;
            if(response.data.data != null) {
                var data = {
                    "status":true,
                    "data":response.data.data
                }
                resolve(data);
            }else{
                resolve({"status":false, "message":'No record found'});
            }
        });
        request.catch((error) => { 
           isExistApiCalls = false;
            reject(error);
        });
    
    }); 
    const val = await pro;
    return val;
    }
  }

  function setHeaderData(data, access_token_name){
      var jwtConfig = {
          "secret"   : "324934dsfj786ykjhgiyyi4439wri",
          "expiresIn": "1 hours" // A numeric value is interpreted as a seconds count. If you use a string be sure you provide the time units (days, hours, etc)
      };
      const access_token = jwt.sign(data, jwtConfig.secret, {expiresIn: jwtConfig.expiresIn});
      window.localStorage.setItem(access_token_name, access_token);
  }

  var dataOfMenu = [];
  async function getDataNow(){
   //  dataOfMenu = await getDataFromServer(6, parentId, pageName);    24-jun-2020
    if(parentId > 0){
      dataOfMenu = await getDataFromServer(8, parentId, "dashboard");
      
      if(dataOfMenu != null && dataOfMenu.data != null){
          setHeaderData({TopMenu: dataOfMenu.data}, "dashboard_access_token");
          setTopMenus(dataOfMenu.data);
          
        }
    }
  }
  if(topMenus === 0){
      let headerData = false; 
      if(window.localStorage.getItem("dashboard_access_token") != null){
          const decodedData = jwtDecode(window.localStorage.getItem("dashboard_access_token"));
          const currentTime = Date.now() / 1000;
          let tokenExpired = false;
          if ( decodedData.exp < currentTime ){
              tokenExpired = true;
          }
          if(tokenExpired === true && navigator != null && navigator.onLine != null && navigator.onLine !== true){
              tokenExpired = false;
          }
          if (tokenExpired === false){
              if(decodedData.TopMenu != null){
                  setTopMenus(decodedData.TopMenu);
                  headerData = true;
              }  
          }
      }
      if(headerData === false){
          getDataNow();
      }
  }else{
    if(Array.isArray(topMenus)){
      dataOfMenu = topMenus;
    }else{
      dataOfMenu[0] = topMenus;
    }
  } 
 
  function setHeaderSidebarData(data, access_token_name){
    var jwtConfig = {
        "secret"   : "324934dsfj786ykjhgiyyi4439wri",
        "expiresIn": "1 hours" // A numeric value is interpreted as a seconds count. If you use a string be sure you provide the time units (days, hours, etc)
    };
    const access_token = jwt.sign(data, jwtConfig.secret, {expiresIn: jwtConfig.expiresIn});
    window.localStorage.setItem(access_token_name, access_token);
  }
 
  var dataSidebar = [];
  async function getSidebarData(){
    dataSidebar = await getDataFromServer(6, 0, 0); 
  //  alert(JSON.stringify(dataSidebar.data));
      if(dataSidebar != null && dataSidebar.data !== null){
          setHeaderSidebarData({TopMenu: dataSidebar.data}, "sidebar_access_token");
          setSideMenus(dataSidebar.data);
      }
  }
  if(sideMenus === 0){
      let headerData = false; 
      if(window.localStorage.getItem("sidebar_access_token") != null){
          const decodedData = jwtDecode(window.localStorage.getItem("sidebar_access_token"));
          const currentTime = Date.now() / 1000;
          let tokenExpired = false;
          if ( decodedData.exp < currentTime ){
              tokenExpired = true;
          }
          if(tokenExpired === true && navigator != null && navigator.onLine != null && navigator.onLine !== true){
              tokenExpired = false;
          }
          if (tokenExpired === false){
              if(decodedData.TopMenu != null){
                  setSideMenus(decodedData.TopMenu);
                  headerData = true;
              }  
          }
      }
      if(headerData === false){
        getSidebarData();
      }
  }else{
    dataSidebar = sideMenus;
  } 

  const useStyles = makeStyles(styles);
  const useStylesr = makeStyles(stylesr);
  const classes = useStyles();
  const classesr = useStylesr();  
  const currentLocation = props.location.pathname.split("/");
  React.useEffect(() => {
    if (props.changeColorOnScroll && (currentLocation[1] === "" || currentLocation[1] === "about" || currentLocation[1] === "vipprograms" || currentLocation[1] === "faq" || currentLocation[1] === "contact")) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll && (currentLocation[1] === "" || currentLocation[1] === "about" || currentLocation[1] === "vipprograms" || currentLocation[1] === "faq" || currentLocation[1] === "contact")) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  }); 
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);  
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]); 
    }
  };


  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  Transition.displayName = "Transition"; 
  const { color,  fixed, absolute } = props;
  
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });  

  var checkToggle = 0;
     
  function parseJwt(token) {
      if (!token) { return; }
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
  }
  
  function getUserName(){
    if(window.localStorage.getItem('jwt_access_token') != null && userName === "Menus"){
        let userData = parseJwt(window.localStorage.getItem('jwt_access_token'));
        if(userData.username != null){
          setUserName(userData.username);
        }
    }
  }
  getUserName();
  
  function createMarkup(text) { return {__html: text}; };

  function getCurrentThemeInfo(){
    if(window.localStorage.getItem('jwt_theme_token') != null){
        let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
        return themeData;
    }
  }  
   var icon = null; 
  var brandName = null; 
  var registration_type ;
  var themeInfo = getCurrentThemeInfo(); 
  //alert(JSON.stringify(themeInfo))
  if(themeInfo != null && themeInfo.id != null){ 
      if(themeInfo.brand_name != null && themeInfo.brand_name.length > 0){
        brandName = themeInfo.brand_name;
      }
      if(themeInfo.icon != null && themeInfo.icon.length > 0){
        icon = themeInfo.icon;
      } 
      if(themeInfo.registration_type != null && themeInfo.registration_type > 0){ 
        registration_type = themeInfo.registration_type;
      } 
  } 
  var loginclass = ""; 
  var mobilmenuset = ""; 
  if(jwtService.isAuthenticationLogin() === true){   
    loginclass = 'topheaderrowddd'; 
    mobilmenuset = 'mobilmenu';       
  }else{          
    loginclass = 'topheaderrow';
    mobilmenuset = 'mobilmenuset';
  }   

  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1000);
  const [isMobilebal, setIsMobilebal] = React.useState(window.innerWidth < 1000);
    React.useEffect(() => {
      window.addEventListener("resize", () => {
          const ismobile = window.innerWidth < 1000;
          if (ismobile !== isMobile) setIsMobile(ismobile);
      }, false);
  }, [isMobile]);
  React.useEffect(() => {
    window.addEventListener("resize", () => {
        const ismobilebal = window.innerWidth < 1000;
        if (ismobilebal !== isMobilebal) setIsMobile(ismobilebal);
    }, false);
  }, [isMobilebal]); 

  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", hhiddeheader);
    }
    return function cleanup() {
      if (props.changeColorOnScroll ) {
        window.removeEventListener("scroll", hhiddeheader);
      }
    };
  });  
   
  const hhiddeheader = () => {
    const {  changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      if(document.body.getElementsByClassName("fixheadermobilehide")[0] !== undefined){
      document.body
      .getElementsByClassName("fixheadermobilehide")[0]
      .setAttribute("style", "display:none;");  
      }
      if(document.body.getElementsByClassName("fixheadermobile1")[0] !== undefined){
      document.body
      .getElementsByClassName("fixheadermobile1")[0]
      .setAttribute("style", "display:block ;"); 
      }
      if(document.body.getElementsByClassName("mobilebal-class")[0] !== undefined){
        document.body
        .getElementsByClassName("mobilebal-class")[0]  
        .setAttribute("style", "display:none ;"); 
      }
      
    }else {
      if(document.body.getElementsByClassName("fixheadermobilehide")[0] !== undefined){
        document.body
        .getElementsByClassName("fixheadermobilehide")[0]
        .setAttribute("style", "display:block;"); 
      } 
      if(document.body.getElementsByClassName("fixheadermobile1")[0] !== undefined){
        document.body
        .getElementsByClassName("fixheadermobile1")[0]
        .setAttribute("style", "display:none;");   
      }
      if(document.body.getElementsByClassName("mobilebal-class")[0] !== undefined){
        document.body
        .getElementsByClassName("mobilebal-class")[0]
        .setAttribute("style", "display:block ;"); 
      }
    }
  }  
  var checkSideToggle = 0;
  function mobilesidebar() {
    if(checkSideToggle === 0){
      checkSideToggle = 1; 
      document.body
        .getElementsByClassName("leftsidebarwrap")[0]
        .classList.add("shownavi"); 
      document.body
        .getElementsByClassName("leftsidebar")[0]
        .classList.add("shownaviin");   
    }else{
      checkSideToggle = 0; 
      document.body
        .getElementsByClassName("leftsidebarwrap")[0]
        .classList.remove("shownavi"); 
      document.body
        .getElementsByClassName("leftsidebar")[0]
        .classList.remove("shownaviin"); 
    }
    // if(document.body.getElementsByClassName("leftsidebarwrap")[0] != null){
    //   document.body
    //   .getElementsByClassName("leftsidebarwrap")[0]
    //   .classList.add("shownavi"); 
    // }
    
    // if(document.body.getElementsByClassName("leftsidebar")[0] != null){
    //   document.body
    //   .getElementsByClassName("leftsidebar")[0]
    //   .classList.add("shownaviin");  
    // }
    
  }
  function openSectionSignup(){
    setTimeout(function() { 
      if(registration_type === 1){
        SectionSignupboxMobile.openPopup("signup");  
      }else{ 
        SectionSignupbox.openPopup("signup");
      }
    }, 500)
 }
 
  
   var checkToggle = 0;
   function creditcardmob() {
     if(checkToggle === 0){
       checkToggle = 1; 
       document.body
       .getElementsByClassName("balanceheadtop")[0]
       .setAttribute("style", "display:block ;"); 
     }else{
       checkToggle = 0; 
       document.body
       .getElementsByClassName("balanceheadtop")[0]
       .setAttribute("style", "display:none ;"); 
     }
   }

  return ( 
    <PageVisibility  > 
      <div className={classes.headersignin + " topbarhead " + classes.headermobileonly} id="top">
        <div className="fixheadermobilehide">
          <AppBar className={appBarClasses +" "+ classes.headersigninfix }> 
              <section className={classesr.topheader + " topheadercl"}> 
                <GridContainer className={"bottomheaderbar"}> 
                  <GridItem xs={12} sm={12} md={12}>
                  <img src={menu} alt={""} onClick={mobilesidebar} className={classes.mobilemenu} />
                  {(() => {
                    // alert(JSON.stringify(themeInfo))
                      if(themeInfo.icon !== null && (themeInfo.icon !== undefined && themeInfo.icon !== "undefined" && themeInfo.icon.length > 0)) { 
                              return (
                                <a href="/">
                                  <img src={icon} height={"50px"} alt={""} className={classes.imglogo2 + " " + classes.logotophead} />
                                  <i className={"fa fa-home  " +  classes.himeicon}></i> 
                                </a>
                      ) }else{
                        return (
                            <a href="/">
                              <h4 className={classes.logoname}>{brandName}</h4>
                              <i className={"fa fa-home  " +  classes.himeicon}></i> 
                            </a>
                          )
                        }
                        })()} 
                    <div className={classes.rightcontent2 + " rightcontent2 " + classes.rightcontentheadtop}> 
                      
                    {(() =>  {
                          if(jwtService.isAuthenticationLogin() === true){
                            return (  
                              <div className={classes.userdetaildiv + " " + classes.userdetailmobhead}>  
                              {usermenuDb[sessionStorage.getItem('lang')].map((jsondata) => ( 
                              <CustomDropdown
                                      noLiPadding
                                      themeColors={props.themeColors}
                                      key={jsondata.id}
                                      buttonText={[<i className={classes.loginusericon + " fa fa-user usericonmobietab " + classes.userdetailicon} key={"ik1"}></i>,<i className={"fa fa-angle-down " + classes.dropdownarrow}></i>  ]}
                                      buttonProps={{
                                        className: classes.navLink + " " + classes.usersigninbtn + " " + classes.usermobinbtn,
                                        color: "transparent"
                                      }}
                                      dropdownList={jsondata.submenu.map((jsondata) => {
                                        if(jsondata.is_popup === null || jsondata.is_popup === undefined || jsondata.is_popup === true){
                                          return( 
                                              <Button   
                                                  onClick={() => {SectionAllprofile.openPopup("login",jsondata.tagname,jsondata.subtagname);}}
                                                  className={classes.usersigninanker} 
                                                  key={jsondata.id} 
                                              >
                                                <i className={jsondata.submenu_class}></i>&nbsp;  {jsondata.sub_menu_name}
                                              
                                              </Button> 
                                          );    
                                        } else{
                                          return(
                                          <Button   
                                              className={classes.usersigninanker} 
                                              href={jsondata.submenu_path}
                                              key={jsondata.id} 
                                          >
                                            <i className={jsondata.submenu_class}></i>&nbsp;  {jsondata.sub_menu_name}
                                          </Button> 
                                        )}
                                      })
                                      
                                      }
                                  />
                              ))}
                            </div> 
                              )
                            }else {
                              return(
                                <span></span>
                              )
                            }
                        })()} 

                         

                    <div className={classes.balanceheadtop + " balanceheadtop"}>  
                    {(() =>  {
                          if(jwtService.isAuthenticationLogin() === true){
                            return ( 
                              
                              <div className={classes.balanceboxi + " balanceboxi balanceboxmob"}>
                                
                                  <div className={classes.balancespike}> 
                                    <Button 
                                        onClick={() => {SectionAllprofile.openPopup("login");}}
                                        color="inherit" 
                                        className={classes.balanceamount}
                                    >
                                      <i className={classes.coinbalance + "  fa fa-credit-card "} />
                                      {(isNaN(myBalance)) ? myBalance : parseFloat(myBalance).toFixed(2)} 
                                    </Button> 
                                    <Button
                                        href=""
                                        color="inherit"
                                        onClick={refreshBalance}
                                        className={classes.balancerefresh}
                                    >
                                      <i className={"fa fa-retweet  "} />
                                    </Button></div>
                              </div> 
                              )
                            }else {
                              return(
                                <span></span>
                              )
                            }
                        })()} 
                      </div>
                    
                      {(() =>  {
                        if(jwtService.isAuthenticationLogin() === true){
                          return ( 
                          <span>
                            {usermenuDb[sessionStorage.getItem('lang')].map((jsondata) => (
                                  <Button
                                      // block
                                      className={classes.logoutbtnt}
                                      style={{marginTop:"4px"}}
                                      href="/logout"
                                  >
                                      <i className={"fa fa-power-off"}></i>
                                  </Button>   
                                    ))}
                          </span>
                          )
                      }else{

                        if(registration_type === 1){ 
                          return (
                            <div className={classes.logininlinebtn}> 
                            {usermenuDb[sessionStorage.getItem('lang')].map((jsondata) => (
                              <Button
                                  // block
                                  className={classes.signinbtn}
                                  onClick={() => {SectionLoginboxMobile.openPopup("login");}}
                              > 
                                {jsondata.loginsignupbtn}
                              </Button>
                              ))}  
                               
                                                            
                            </div>
                            ) 
                        }else{ 
                          return (
                            <div className={classes.logininlinebtn + " logininlinebtn"}> 
                            {usermenuDb[sessionStorage.getItem('lang')].map((jsondata) => (
                            <Button
                                  // block
                                  style={{marginTop: "-8px"}}
                                  className={classes.signinbtn  + " btnnx "}
                                  onClick={() => {SectionLoginbox.openPopup("login");}}
                              > 
                                {jsondata.loginbtn}
                              </Button>  
                              ))} 
                              {usermenuDb[sessionStorage.getItem('lang')].map((jsondata) => (
                              <Button
                                  // block
                                  style={{marginLeft:"1%", marginRight:"0%"}}
                                  className={classes.signinbtn + " btnnx " + classes.btnmobragister}
                                  onClick={openSectionSignup}
                              > 
                                {jsondata.ragisterbtn}
                              </Button>
                              ))}   
                              
                            </div>
                            
                           )
                            
                        }

                        
                        }
                      })()} 


                       
                    

                    </div>

                 
                  </GridItem>
                </GridContainer>
                <SectionPopup {...props} />
              </section>  
             
             <SectionAllprofile {...sectionPopup} {...props} />
              

          </AppBar>
        </div>
        
                 
      
      </div>   
    </PageVisibility>
  );
}

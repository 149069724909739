import React from "react"; 
import {makeStyles} from "@material-ui/core/styles";   
import 'react-accessible-accordion/dist/fancy-example.css'; 

import styles from "../assets/jss/components/tabsDepositstyle.js"; 
import Slide from "@material-ui/core/Slide";  

import Background4 from '../assets/img/uploaddw.png';
const useStyles = makeStyles(styles); 
const Transition = React.forwardRef(function Transition(props, ref) {
    
    return <Slide direction="down" ref={ref} {...props}  />;
    
}); 
Transition.displayName = "Transition";

const ImgUpload =({
  onChange,
  src,
  name,
})=>{
  const classes = useStyles(); 
  return(
    <label className={classes.custom_file_upload + " " + classes.fas}>
      <div className={classes.img_wrap + " " + classes.img_upload} >
        <img className={classes.custom_fileimg} alt="" src={src}/>
      </div>
      <input id="photo-upload" type="file" name={name} filetype="jpg, png, gif" className={classes.custom_fileu} onChange={onChange}/> 
    </label>
  );
}


class CardProfile extends  React.Component {
  constructor(props) {
    super(props);
    styles.custom_file_upload.background = props.themeColors.bgColors.secondary_dark_bgcolor;

    var imagePreviewUrl = (Background4);
    if(props.src !== undefined && props.src !== ""){
      imagePreviewUrl = this.props.src;
    }
     this.state = {
       file: '',
       imagePreviewUrl: imagePreviewUrl,
       name: props.name,
       status:'',
       active: 'edit'
    }
  }
  
  photoUpload (e) {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    const nameOfRef = e.target.name;
    reader.onloadend = () => {
        this.props.onChange(file, nameOfRef);
      this.setState({
        imagePreviewUrl: reader.result
      });
    }
    reader.readAsDataURL(file);
  }  
  
  render() {
    if(this.props.onReset != null && this.props.onReset === "YES"){
      this.setState({
        file: '',
        imagePreviewUrl: (Background4)
      });
      this.props.resetBack("NO");
    }
    const {imagePreviewUrl, name} = this.state;
    return (
      <div>
      { 
        <ImgUpload onChange={(e)=>this.photoUpload(e)} src={imagePreviewUrl} name={name} />
      }
      </div>
    )
  }
}

export default CardProfile;
/*eslint-disable*/
import React, {useEffect, useRef, useState} from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components 
import { makeStyles } from "@material-ui/core/styles";
 
import payget from "../../assets/img/payget.png";
import styles from "../../assets/jss/components/footerStyle.js"; 
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer"; 
import Button from "../CustomButtons/Button";  
const useStyles = makeStyles(styles);
import footertopDb from "../../data/footertop.js";
import { useTranslation } from 'react-i18next' 
import {useDispatch, useSelector} from 'react-redux';
import * as authActions from '../../../../auth/casino/actions';
import axios from 'axios';
import * as globalurl from '../../../../global/Global.js';  
import jwt from 'jsonwebtoken'; 
import jwtDecode from 'jwt-decode'; 

var isExistApiCall = false;
var isAPIRunning = false;

export default function Footer(props) {
  const currentLocation = props.location.pathname.replace("/","");
  if(props.themeColors != null){
    styles.footer.backgroundColor = props.themeColors.bgColors.secondary_color
    styles.titlefooter.color = props.themeColors.bgColors.text_primary_color
    styles.atag.color = props.themeColors.bgColors.text_primary_dark_color
    styles.atag["&:hover"].color = props.themeColors.bgColors.text_secondary_color
    styles.copybg.background = props.themeColors.bgColors.secondary_color
    styles.leftarea["& p"].color = props.themeColors.bgColors.text_secondary_dark_color 
    styles.copyrighttext.color = props.themeColors.bgColors.text_primary_dark_color
    styles.copyrightatag.color = props.themeColors.bgColors.gradient_border  
    styles.backtotop.borderColor = props.themeColors.bgColors.main_color
    styles.backtotop.color = props.themeColors.bgColors.text_secondary_color

    styles.backtotop["&:hover"].background = props.themeColors.bgColors.main_color
    styles.backtotop["&:hover"].color = props.themeColors.bgColors.text_primary_dark_color

    styles.titlefooter.fontFamily = props.themeColors.bgColors.base_font; 
    styles.atag.fontFamily = props.themeColors.bgColors.base_font; 
    styles.rightarea["& p"].fontFamily = props.themeColors.bgColors.base_font;  
    styles.copyrightatag.fontFamily = props.themeColors.bgColors.base_font;    
    styles.backtotop.fontFamily = props.themeColors.bgColors.base_font; 
 }

  const { t } = useTranslation()
  const classes = useStyles();
  const { whiteFont } = props;
  const [isFormValid, setIsFormValid] = useState(false);
  const [topMenus, setTopMenus] = React.useState(0);
  const [bottomMenus, setBottomMenus] = React.useState(0);
  const userAuth = useSelector(({auth}) => auth.user);
  const formRef = useRef(null); 
  const dispatch = useDispatch();

  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });

  useEffect(() => {
      if ( userAuth.error && (userAuth.error.parent_banker_id || userAuth.error.sender_banker_id || userAuth.error.amount || userAuth.error.message) )
      {
          if(userAuth.error.message != null){
              document.body
              .getElementsByClassName("message2")[0] 
              .innerHTML=userAuth.error.message
          }else{
              formRef.current.updateInputsWithError({
                  ...userAuth.error
              });
          }
      }else{
          if(userAuth.success != null && userAuth.success === true){
              alert("Your email added in newsletter subscription successfully.")
              window.location.reload();
          }
      }
  }, [userAuth.error]);

  


  async function getDataFromServer(menutype){
    let pro = new Promise((resolve,reject) => {
        isExistApiCall = true;
        const request = axios.post(globalurl.GETMENUS , {
            token: window.localStorage.getItem('jwt_access_token'),
            menutypes: menutype, 
        });
        request.then((response) => {
            if(response.data.data != null){
                 isExistApiCall = false;
                var data = {
                    "status":true,
                    "data":response.data.data
                }
                resolve(data);
            }else{
                resolve({"status":false, "message":'No record found'});
            }
        });
        request.catch((error) => {
             isExistApiCall = false;
            reject(error);
        });
    }); 
    const val = await pro;
    return val;
}
function setHeaderData(data, access_token_name){
    var jwtConfig = {
        "secret"   : "324934dsfj786ykjhgiyyi4439wri",
        "expiresIn": "1 hours" // A numeric value is interpreted as a seconds count. If you use a string be sure you provide the time units (days, hours, etc)
    };
    const access_token = jwt.sign(data, jwtConfig.secret, {expiresIn: jwtConfig.expiresIn});
    window.localStorage.setItem(access_token_name, access_token);
}

var dataOfMenu = [];
async function getDataNow(){
    dataOfMenu = await getDataFromServer(4);
    if(dataOfMenu.data != null){
        setHeaderData({TopMenu: dataOfMenu.data}, "footer_access_token");
        setTopMenus(dataOfMenu.data);
    }
}
if(topMenus === 0){
    let headerData = false; 
    if(window.localStorage.getItem("footer_access_token") != null){
        const decodedData = jwtDecode(window.localStorage.getItem("footer_access_token"));
        const currentTime = Date.now() / 1000;
        let tokenExpired = false;
        if ( decodedData.exp < currentTime ){
            tokenExpired = true;
        }
        if(tokenExpired === true && navigator != null && navigator.onLine != null && navigator.onLine !== true){
            tokenExpired = false;
        }
        if (tokenExpired === false){
            if(decodedData.TopMenu != null){
                setTopMenus(decodedData.TopMenu);
                headerData = true;
            }  
        }
    }
    if(headerData === false){
        getDataNow();
    }
}else{
  if(Array.isArray(topMenus)){
    dataOfMenu = topMenus;
  }else{
    dataOfMenu[0] = topMenus;
  }
}
  
function setHeaderData(data, access_token_name){
  var jwtConfig = {
      "secret"   : "324934dsfj786ykjhgiyyi4439wri",
      "expiresIn": "1 hours" // A numeric value is interpreted as a seconds count. If you use a string be sure you provide the time units (days, hours, etc)
  };
  const access_token = jwt.sign(data, jwtConfig.secret, {expiresIn: jwtConfig.expiresIn});
  window.localStorage.setItem(access_token_name, access_token);
}

var dataOfBottomMenu = [];
async function getDataNowF(){
    if(isAPIRunning === false){
    isAPIRunning = true;
  dataOfBottomMenu = await getDataFromServer(12);
  if(dataOfBottomMenu.data != null){
      setHeaderData({TopMenu: dataOfBottomMenu.data}, "footer_bottom_access_token");
      setBottomMenus(dataOfBottomMenu.data);
  }
   isAPIRunning = false;
  }
}
if(bottomMenus === 0){
  let headerData = false; 
  if(window.localStorage.getItem("footer_bottom_access_token") != null){
      const decodedData = jwtDecode(window.localStorage.getItem("footer_bottom_access_token"));
      const currentTime = Date.now() / 1000;
      if ( decodedData.exp < currentTime ){
          if(decodedData.TopMenu != null){
            setBottomMenus(decodedData.TopMenu);
              headerData = true;
          }  
      }
  }
  if(headerData === false){
    getDataNowF();
  }
}else{
  dataOfBottomMenu = bottomMenus;
}


function parseJwt(token) {
  if (!token) { return; }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}
function createMarkup(text) { return {__html: text}; };
function getCurrentThemeInfo(){
  if(window.localStorage.getItem('jwt_theme_token') != null){
      let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
      return themeData;
  }
}  

var copyrightText = null; 
var themeInfo = getCurrentThemeInfo(); 

if(themeInfo != null && themeInfo.id != null){
    if(themeInfo.copyright_text != null && themeInfo.copyright_text.length > 0){
      copyrightText = themeInfo.copyright_text;
    } 
} 
  
  return (


<div>

      {(() =>  {

        if (currentLocation === "domainnotadjust") {

          return (

              <div></div>


          )
        } else {
          
          return (
            
              <footer className={footerClasses}>

                
                <div className={classes.footertop}>
                <div className={classes.container} style={{textAlign:"center"}}>
                  
                     {dataOfMenu.map((jsondata) => (
                        <div className={classes.footerwidgetinfolinkwidget}>
                          {(() =>  {
                        
                        if (jsondata.submenu) {
                          let submenu = JSON.parse("["+jsondata.submenu+"]");
                        return (
                          
                            <ul className={classes.linklist}>
                              {submenu.map((jsondataIn) => (
                                  <li key={jsondataIn.id}>
                                    <Button
                                        color="transparent"
                                        href={"/" + jsondataIn.path}
                                        className={classes.atag}
                                    >
                                      {jsondataIn.menu_name}
                                    </Button>
                                  </li>
                              ))}
                            </ul>
                           
                           )

                          } 
                          })()}
                        </div>
                    ))}  
                    <div style={{clear:"both"}}></div>
                  <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.centerarea}>
                           <img src={payget} alt=""/> 
                        </div>
                      </GridItem>
                    </GridContainer>
                </div> 
                </div>

                {(() => { 
                  if(themeInfo.copyright_text !== null && (themeInfo.copyright_text !== undefined && themeInfo.copyright_text !== "undefined" && themeInfo.copyright_text.length > 0)) { 
                          return (  
                            <div className={classes.copybg}>
                              <div className={classes.container}>
                                <GridContainer>
                                  <GridItem xs={12} sm={12} md={12}>
                                      <div className={classes.rightarea}> 
                                          <p className={classes.copyrighttext}  >
                                          <span dangerouslySetInnerHTML={createMarkup(copyrightText)} />
                                          </p>  
                                      </div> 
                                  </GridItem>
                                </GridContainer>
                              </div>
                            </div>       
                  ) }else{
                    return (
                        <span style={{marginTop:"20px", display:"block"}}> 
                        </span>
                      )
                }
                })()}              

                
              </footer>
          )
        }
      })()}

</div>
 

  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};

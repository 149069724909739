/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import People from "@material-ui/icons/People";
import Email from "@material-ui/icons/Email";
import Icon from "@material-ui/core/Icon";
// react components for routing our app without refresh
import { Link, withRouter } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// @material-ui/core components

import CustomInput from "../../elements/CustomInput/CustomInput.js";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// core components
import CustomDropdown from "../CustomDropdown/CustomDropdown.js";
import Button from "../CustomButtons/Button.js";


import { Router } from "react-router";
import { createBrowserHistory } from "history";
import menuDb from "../../data/menu.js";
import logo from "../../assets/img/logo.png";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import styles from "../../assets/jss/components/headerLinksStyle.js";
import stylesr from "../../assets/jss/components/headerLinkstopStyle";

import axios from 'axios';
import * as globalurl from '../../../../global/Global.js'; 

import SectionLoginbox from "../../sections/SectionLoginBox";
import SectionOtpbox from "../../sections/SectionOtpBox"; 
import SectionSignupbox from "../../sections/SectionSignupBox";
import SectionForgotbox from "../../sections/SectionForgotBox";
//import { useTranslation } from 'react-i18next'
import LanguageSelector from '../../sections/LanguageSelector'
//import { useTranslation } from 'react-i18next'
import jwt from 'jsonwebtoken'; 
import jwtDecode from 'jwt-decode';
import jwtService from '../../../../services/jwtService'
const history = createBrowserHistory()



let test = "ok";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function HeaderLinks(props) {
 //   const { t } = useTranslation();
    const {...rest} = props;
    const [topMenus, setTopMenus] = React.useState(0);
    if(props.themeColors != null){
        styles.dropdownLink.background = props.themeColors.bgColors.blackbg;
        styles.dropdownLink.color = props.themeColors.bgColors.whitetextcolor;

        styles.signinbtn.backgroundImage = props.themeColors.bgColors.golden;
        styles.signinbtn.color = props.themeColors.bgColors.goldenbtntext; 
          
    }
    const currentLocation = props.location.pathname.split("/");
  //  const { t } = useTranslation()
    let currentStyle = styles;
    const useStyles = makeStyles(styles);

    const classes = useStyles();
 
    async function getDataFromServer(){
        let pro = new Promise((resolve,reject) => {
            const request = axios.post(globalurl.GETMENUS , {
                token: window.localStorage.getItem('jwt_access_token'),
                menutypes: 8
            });
            request.then((response) => {
                if(response.data.data != null){
                    var data = {
                        "status":true,
                        "data":response.data.data
                    }
                    resolve(data);
                }else{
                    resolve({"status":false, "message":'No record found'});
                }
            });
            request.catch((error) => {
                reject(error);
            });
        }); 
        const val = await pro;
        return val;
    }
    function setHeaderData(data, access_token_name){
        var jwtConfig = {
            "secret"   : "324934dsfj786ykjhgiyyi4439wri",
            "expiresIn": "1 hours" // A numeric value is interpreted as a seconds count. If you use a string be sure you provide the time units (days, hours, etc)
        };
        const access_token = jwt.sign(data, jwtConfig.secret, {expiresIn: jwtConfig.expiresIn});
        window.localStorage.setItem(access_token_name, access_token);
    }
    
    var dataOfMenu = [];
    
    async function getDataNow(){
        dataOfMenu = await getDataFromServer();
        if(dataOfMenu.data != null){
            setHeaderData({TopMenu: dataOfMenu.data}, "header_access_token");
            setTopMenus(dataOfMenu.data);
        }
    }
    if(topMenus === 0){
        let headerData = false; 
        if(window.localStorage.getItem("header_access_token") != null){
            const decodedData = jwtDecode(window.localStorage.getItem("header_access_token"));
            const currentTime = Date.now() / 1000;
            let tokenExpired = false;
            if ( decodedData.exp < currentTime ){
                tokenExpired = true;
            }
            if(tokenExpired === true && navigator != null && navigator.onLine != null && navigator.onLine !== true){
                tokenExpired = false;
            }
            if (tokenExpired === false){
                if(decodedData.TopMenu != null){
                    setTopMenus(decodedData.TopMenu);
                    headerData = true;
                }  
            }
        }
        if(headerData === false){
            getDataNow();
        }
    }else{
        if(Array.isArray(topMenus)){
            dataOfMenu = topMenus;
          }else{
            dataOfMenu[0] = topMenus;
          }
    }
 /* const [classicModal, setClassicModal] = React.useState(false);
  const [classicModal2, setClassicModal2] = React.useState(false);
  const [classicModal3, setClassicModal3] = React.useState(false);*/

  /*
  if data coming from language also
{menuDb[sessionStorage.getItem('lang')].map((jsondata) => (
  */
    return (
    <List className={classes.list}>
    {dataOfMenu.map((jsondata) => 
            (
            () => {
            if((jwtService.isAuthenticationLogin() == true && jsondata.is_login == "1") || jsondata.is_login == "0"){
                return(<ListItem className={classes.listItem} key={jsondata.id}>
                    {(() =>  {
                        
                        if (jsondata.submenu) {
                        let submenu = JSON.parse("["+jsondata.submenu+"]");
                        return (<div><CustomDropdown
                            noLiPadding
                            buttonText={jsondata.menu_name}
                            buttonProps={{
                                className: classes.navLink,
                                color: "transparent"
                            }}
                            dropdownList={submenu.map((jsondataIn) => (
                                <Button to={jsondataIn.submenu_path} href={jsondataIn.path} className={classes.dropdownLink} key={jsondataIn.id}>
                                    {jsondataIn.menu_name}
                                </Button>

                                        ))}
                        /></div>)

                    }else if (jsondata.path === "login" && jwtService.isAuthenticationLogin() != true) {

                        return (<div>

                                <Button
                                block
                                className={classes.signinbtn}
                                onClick={() => {SectionLoginbox.openPopup("login");}}
                            >
                                <i className={"fa fa-user"}></i>&nbsp;
                                {jsondata.menu_name}
                            </Button> 
                            
                            <SectionLoginbox 
                                SignupBox={SectionSignupbox}
                                ForgotBox={SectionForgotbox}
                                {...rest} />
                            <SectionForgotbox 
                                ForgotBox={SectionForgotbox}
                                LoginBox={SectionLoginbox}
                                {...rest} />
                            <SectionOtpbox
                                LoginBox={SectionLoginbox}
                            {...rest} />
                            <SectionSignupbox
                                OtpBox={SectionOtpbox}
                                LoginBox={SectionLoginbox}
                            {...rest} />  

                        </div>)

                    }else if (jsondata.path === "login" && jwtService.isAuthenticationLogin() == true) {

                        return (<div>

                                <Button
                                block
                                className={classes.signinbtn}
                                href="/logout"
                            >
                                <i className={"fa fa-user"}></i>&nbsp;
                                {"Logout"}
                            </Button> 
                        </div>)

                    }else{
                            return (<div><Button
                                href={jsondata.path}
                                color="transparent"
                                className={(() => {
                                    if (currentLocation[1] == jsondata.path) {
                                        return classes.navLink + " " + "nvilink navlinkactive"
                                    } else {
                                        return classes.navLink + " " + "nvilink"
                                    }
                                })()}
                            >
                                {jsondata.menu_name}
                                <div className={"mrhovereffect"}></div>
                            </Button></div>)
                        }
                    })()}
                </ListItem>)
                }
            }
            )()
            
        
        )}
    </List>
  );
}

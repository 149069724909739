export const DBConfig = {
name: 'CD_OFFLINE',
    version: 2,
    objectStoresMeta: [
      {
        store: 'Countries',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: [
          { name: 'id', keypath: 'id', options: { unique: true } },
          { name: 'title', keypath: 'title', options: { unique: false } },
          { name: 'phonecode', keypath: 'phonecode', options: { unique: false } },
          { name: 'flag', keypath: 'flag', options: { unique: false } },
          { name: 'mobile_number_length', keypath: 'mobile_number_length', options: { unique: false } }
        ]
      },
      {
        store: 'Games',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: [
          { name: 'id', keypath: 'id', options: { unique: true } },
          { name: 'game_id', keypath: 'game_id', options: { unique: false } },
          { name: 'game_name', keypath: 'game_name', options: { unique: false } },
          { name: 'game_icon', keypath: 'game_icon', options: { unique: false } }
        ]
      }
    ]
  };
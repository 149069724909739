import brezza from './brezza';
import casinodestination from './casinodestination';
import smdesign1 from './smdesign1';
import smdesign2 from './smdesign2';
import spike from './spike';
import sporto from './sporto';
import ambiance from './ambiance';
import freddy from './freddy';
import gloster from './gloster';
const CasinoLayouts = {
    brezza,
    casinodestination,
    smdesign1,
    smdesign2,
    spike,
    sporto,
    ambiance,
    freddy,
    gloster
};

export default CasinoLayouts;

//import React, {Component, useEffect, useRef, useState} from "react";
import React, {Component} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import 'react-accessible-accordion/dist/fancy-example.css';
import history from '../../../@history';
// import { makeStyles } from "@material-ui/core/styles";
import Loadgaming from "../assets/img/lodi.gif"; 

// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Radio from "@material-ui/core/Radio";

// import {IconButton, MenuItem} from "@material-ui/core"; 
// import GridContainer from "../elements/Grid/GridContainer.js";
// import GridItem from "../elements/Grid/GridItem.js";
// import CustomTabs from "../elements/CustomTabs/CustomTabs.js";
// import accountsDb from "../data/account.js";
import Iframe from 'react-iframe'
import HeaderFullScreenToggle from './fullframe.js' 
//import Fullscreen from "react-full-screen";

// const token = "kdkddjdj2929j38jv88fpwad83pakwdi3"; 

class Playnow extends Component {
    
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    // var gameUrl  = "";
    if(window.localStorage.getItem("game_access") != null){
      this.gameUrl = window.localStorage.getItem("game_access");
      //window.localStorage.removeItem("game_access")
       
       
    
    }else{
      history.push({
          pathname: '/casino'
      });
    }
  }
  
    
    
  
  componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
      
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  requestFullScreen(element) {
    // Supports most browsers and their versions.
    
  }
  
    render(){ 
        /*const refIframe = useRef(null); 
        function openFullscreen() {
            alert("YES");
            var elem = document.getElementById("myframe");
            alert("YES1" + elem);
            if (this.refIframe.myframe.requestFullscreen) {
                alert("1");
              this.refIframe.myframe.requestFullscreen();
            } else if (this.refIframe.myframe.mozRequestFullScreen) {
                alert("2");
              this.refIframe.myframe.mozRequestFullScreen();
            } else if (this.refIframe.myframe.webkitRequestFullscreen) {
                alert("3");
              this.refIframe.myframe.webkitRequestFullscreen();
            }
        }*/
        // if(this.props){
        //   
        // }
        setTimeout(function() {
          document.getElementById("showGameLoad").style.display = "none"
        }, 5000); 
        return (
            <div className={"newframwrap"} id="myframewrap">
             <HeaderFullScreenToggle />

        <div id="showGameLoad" style={{position: "absolute",width: "100%",height: "100%",zIndex: "99",background: "rgba(0,0,0,0.5)",display: "flex"}}><img style={{alignSelf: "center", width:"60px", margin:"0px auto"}} src={Loadgaming} alt=""/></div>
                 
            <Iframe className="myframe" 
                    url={this.gameUrl}
                    width="100%"
                    id="myframe"
                    name="myframe"
                    position="relative"
                    height={(this.state.height)}
                    allowfullscreen
                    />
                    
            </div>
        );
    }
    
  }
  
  export default Playnow;
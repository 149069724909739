/*eslint-disable*/
import React, {useEffect, useRef, useState} from "react";
import DeleteIcon from "@material-ui/icons/Delete"; 
import {IconButton, MenuItem} from "@material-ui/core"; 
import People from "@material-ui/icons/People";
import Email from "@material-ui/icons/Email"; 
// react components for routing our app without refresh 
import { Link, withRouter } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// @material-ui/core components
import {Button, Divider, Typography, InputAdornment, Icon} from '@material-ui/core';
import CustomInput from "../elements/CustomInput/CustomInput.js";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
//Global
import axios from 'axios';
import * as Gurl from '../../../global/Global.js';
// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// core components
import CustomDropdown from "../elements/CustomDropdown/CustomDropdown.js";
import CustomButton from "../elements/CustomButtons/Button.js";
//Third Party Authentication
import {FacebookLogin, GoogleLogin} from "../../../@casino"
// @database
///import { IndexedDb } from '../../../@database/IndexedDb';

import Formsy from 'formsy-react';
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import menuDb from "../data/menu.js";
import logo from "../assets/img/logo.png";
import GridItem from "../elements/Grid/GridItem";
import GridContainer from "../elements/Grid/GridContainer";
import styles from "../assets/jss/components/headerLinksStyle.js";
import stylesr from "../assets/jss/components/headerLinkstopStyle"; 
import { useIndexedDB } from 'react-indexed-db';
import * as authActions from '../../../auth/casino/actions';
import {useDispatch, useSelector} from 'react-redux'; 
import {TextFieldFormsy, SelectFormsy, CheckboxFormsy} from '../../../@casino';
import secloginboxDb from "../data/secloginbox.js";
//import { useTranslation } from 'react-i18next'
const history = createBrowserHistory()
let classicModal = null;
let setClassicModal = null;
let countryData = "0";
let setCountryData = null;

let currentCountryId = "99";
let setCurrentCountryId = null;

let test = "ok"; 

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";
const validEmailRegex = 
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
  var isExistCountryApiCall = false;
function SectionLoginboxMobile(props) { 

  
    const dispatch = useDispatch();
    const [isFormValid, setIsFormValid] = useState(false);
    const [isCaptcha, setIsCaptcha] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState("stop");
    const [mobileNumberMaxLen, setMobileNumberMaxLen] = React.useState(10);
    const [countryCode, setCountryCode] = React.useState(0);
    const [classAdd, setClassAdd] = useState(false);
    const [currentCountry, setCurrentCountry] = React.useState("India");
    [currentCountryId, setCurrentCountryId] = React.useState("99"); 
    [countryData, setCountryData] = React.useState("0");
    const { getAll, add, getByID, getByIndex, update } = useIndexedDB('Countries');

    const formRef = useRef(null);
    const loginmobile = useSelector(({auth}) => auth.loginmobile);
    const [showPassword, setShowPassword] = useState(false);
   [classicModal, setClassicModal] = React.useState(false);
   

    function countryWiseMobileValidate(event){
      if(countryData != "0" && countryData != null && event != null && event.target != null && event.target.value != null){
          countryData.map((jsonData) => { 
              if(jsonData.id === event.target.value){
                  setCurrentCountryId(jsonData.id);
                  setCurrentCountry(jsonData.title); 
                  setMobileNumberMaxLen(jsonData.mobile_number_length);
              }
          })
      }
  }  

  function getCurrentCountry(myCountryData){
    axios.get('https://ipapi.co/json/').then((response) => {
            let data = response.data;
            if(data != null && data.country_calling_code != null){
                myCountryData.forEach((jsonData) => {
                    var phonecode = ("+" + jsonData.phonecode.toString()).toString();
                    if(phonecode === data.country_calling_code){
                        setCurrentCountry(jsonData.title);
                        setMobileNumberMaxLen(jsonData.mobile_number_length);
                        setCurrentCountryId(jsonData.id);
                    }
                })
            }
        }).catch((error) => {
            console.log(error);
        });
}
function getcountrydata(){
 
 if( isExistCountryApiCall === false){
     isExistCountryApiCall = true;
    const request = axios.post(Gurl.WLCOUNTRY_DATA, JSON.stringify({
        token: window.localStorage.getItem('jwt_access_token')
    }),{header:{"Content-Type": "application/x-www-form-urlencoded"}});

    request.catch((error) => {
        isExistCountryApiCall = false;
        setCountryCode("0");
    }); 
    request.then((response) => { 
        
     
        if(response.data.data != null){ 
            
            var myOutPut = (
                response.data.data.map((jsondata) => (
                    <MenuItem value={jsondata.id} key={jsondata.id}><span>{jsondata.title}</span> +{jsondata.phonecode}</MenuItem> 
                ))
            );
            
            setCountryCode(myOutPut);
            setCountryData(response.data.data);
            getCurrentCountry(response.data.data);
            response.data.data.map((oneByOneCountry) => {
                if(oneByOneCountry != null && oneByOneCountry.id != null && oneByOneCountry.id > 0){
                    getByIndex('id', oneByOneCountry.id).then(personFromDB => {
                        if(personFromDB != null && personFromDB != undefined && JSON.stringify(oneByOneCountry) != JSON.stringify(personFromDB)){
                            update(oneByOneCountry).then(
                                event => {
                                 
                                  
                                },
                                error => {
                                   
                                }
                            );
                        }else{
                            if(JSON.stringify(oneByOneCountry) != JSON.stringify(personFromDB)){
                                add(oneByOneCountry).then(
                                    event => {
                                         
                                    },
                                    error => {
                                        
                                    }
                                );
                            }
                        }
                    });
                }
            });
            isExistCountryApiCall = false;
        }else{
            isExistCountryApiCall = false;
            setCountryCode("0");
        }
    }); 
 }
         
}   
async function fetchLocalData(){
     
    const val = await getAll().then(personsFromDB => {
    //    alert("JSO:" + JSON.stringify(personsFromDB));
        return personsFromDB;
    });
    return val;
}
 
if(countryCode == "0" && isExistCountryApiCall === false){
    
    getAll().then(allData => {
        if(navigator != null && navigator.onLine != null && navigator.onLine !== true){
            if(allData === null || allData === undefined){
                getcountrydata();
            }else{
                var myOutPut = (
                    allData.map((jsondata) => (
                        <MenuItem value={jsondata.id} key={jsondata.id}>{jsondata.title} ({jsondata.phonecode})</MenuItem> 
                    ))
                );
                setCountryCode(myOutPut);
            }
        }else{
            getcountrydata();
        }
    });
}

if(isPopupOpen === false){
   
  setClassicModal(false);
}    
 
useEffect(() => { 
  
  if ( loginmobile.success != null && loginmobile.success == false && loginmobile.error && (loginmobile.error.mobile || loginmobile.error.country_id || loginmobile.error.message) )
  {
    if(formRef != null && formRef.current != null){
      formRef.current.reset();
  }
      if(loginmobile.error.mobile || loginmobile.error.country_id ){
       
          if(loginmobile.error.mobile){
              loginmobile.error.mobile = loginmobile.error.mobile;
              delete loginmobile.error["mobile"];
          }
          if(loginmobile.error.country_id){
              loginmobile.error.country_id = loginmobile.error.country_id;
              delete loginmobile.error["country_id"];
          }
          delete loginmobile.error["message"];
          
          formRef.current.updateInputsWithError({
              ...loginmobile.error
          }); 
      }else if(loginmobile.error.message != null){
       
          if(document.body.getElementsByClassName("message_login_one")[0] != null){
              document.body.getElementsByClassName("message_login_one")[0].innerHTML=loginmobile.error.message;
          }
          setClassAdd(classes.msg = " errormsg" );
      }
      disableButton(); 
      
  }else{
      if(loginmobile.success != null && loginmobile.success == true && loginmobile.data != null && loginmobile.data.status != null && loginmobile.data.status > 0){
          if(formRef != null && formRef.current != null){
              formRef.current.reset();
          }
          if(loginmobile.data.LOGINOUTPUT !== null){
            let loginOutput = JSON.parse(loginmobile.data.LOGINOUTPUT);
            
            if(Number(loginOutput.status) > 0){
              
              setTimeout(function() { 
                  if(document.body.getElementsByClassName("message_login_one")[0] != null && loginOutput.message !== null){
                      document.body.getElementsByClassName("message_login_one")[0].innerHTML=loginOutput.message;
                      setClassAdd(classes.msg = " successmsg" );
                  }
                  setClassicModal(false);
                  if(props.OtpBox != null){
                    props.OtpBox.openPopup("otpbox", loginmobile.data.country_id, loginmobile.data.mobile);
                  }
                }, 1000); 
            }else{
              if(document.body.getElementsByClassName("message_login_one")[0] != null && loginOutput.message !== null){
                  document.body.getElementsByClassName("message_login_one")[0].innerHTML=loginOutput.message;
                 
                  setClassAdd(classes.msg = " errormsg" );
                }
            }
            
          }else{
              
          }

          if(loginmobile.data.message != null){
            setClassAdd(classes.msg = " successmsg" )
              if(document.body.getElementsByClassName("message_login_one")[0] != null){
                  document.body.getElementsByClassName("message_login_one")[0].innerHTML=loginmobile.data.message;
              }
              
          }
            
      }
      
  }
}, [loginmobile]);
 


  var verifyCallback = function (response) {
   
     
      if(response && isCaptcha === true){
        enableButton();
      }else{
        setIsFormValid(false);
      }
  };
  var gcErrorCallback = function (issue){
     disableButton();
  
   
  };

    function disableButton()
    {
        setIsFormValid(false);
        document.body
        .getElementsByClassName("signinbtn")[0]
          .setAttribute("style", "opacity:0.5;");
    }

    function enableButton()
    {
      
        setIsFormValid(true);
        document.body
        .getElementsByClassName("signinbtn")[0]
          .setAttribute("style", "opacity:1;");
      
    }

    function handleSubmit(model)
    {
         model = {"mobile": model.mobile, "country_id": model.country_id, ...model}

        dispatch(authActions.submitLoginNew(model,setIsPopupOpen,props));
        document.body
        .getElementsByClassName("message_login_one")[0]
        .innerHTML=" " 

    }
    const responseFb = (response) => {
     
    }
    const componentClickFb = (response) => {
      
    }

    const responseGoogle = (response) => {
      //sessionStorage.setItem("googleOutput", JSON.stringify(response, getCircularReplacer()));
    }
    if(props.themeColors != null){
            styles.signinbtn.backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinbtn.color = props.themeColors.bgColors.text_primary_dark_color;
      styles.modalHeaderlogin.background = props.themeColors.bgColors.primary_dark_bgcolor;
      styles.modalBodylogin.background = props.themeColors.bgColors.primary_dark_bgcolor;
      styles.modalFooterlogin.background = props.themeColors.bgColors.primary_dark_bgcolor; 
      styles.signupbix.color = props.themeColors.bgColors.text_primary_color; 
      styles.checkboxin["& p"].color = props.themeColors.bgColors.text_primary_color;
      styles.checkboxin["& span"].color = props.themeColors.bgColors.text_secondary_color;
      styles.checkboxin["& p a"].color = props.themeColors.bgColors.text_secondary_color;
      styles.modalSubtitle.color = props.themeColors.bgColors.text_primary_color;
      styles.modalCloseButton.color = props.themeColors.bgColors.text_primary_color;
      styles.modalTitle.color = props.themeColors.bgColors.text_secondary_dark_color; 
      styles.signupbixp.color = props.themeColors.bgColors.text_primary_color; 
      styles.form["& label"].color = props.themeColors.bgColors.text_secondary_dark_color;
      styles.form["& fieldset"].borderColor = props.themeColors.bgColors.secondary_dark_bgcolor;
      styles.form["& input"].color = props.themeColors.bgColors.text_primary_color;
      styles.signinmodalbtn.backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn.background = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn["&:hover"].backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn["&:hover"].background = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn.color = props.themeColors.bgColors.text_primary_dark_color;
      styles.logoname.color = props.themeColors.bgColors.text_primary_color;
 
      styles.logoname.fontFamily = props.themeColors.bgColors.logo_font; 
      styles.modalTitle.fontFamily = props.themeColors.bgColors.base_font; 
      styles.modalSubtitle.fontFamily = props.themeColors.bgColors.base_font;  
      styles.form["& label"].fontFamily = props.themeColors.bgColors.base_font;   
      styles.form["& input"].fontFamily = props.themeColors.bgColors.base_font; 
      styles.signupbix.fontFamily = props.themeColors.bgColors.base_font;
      styles.signinmodalbtn.fontFamily = props.themeColors.bgColors.base_font; 
    }
    
  //  const { t } = useTranslation()
    let currentStyle = styles;
    const useStyles = makeStyles(styles);

    const classes = useStyles();

  
  function parseJwt(token) {
      if (!token) { return; }
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
  }
   function getCurrentThemeInfo(){
    if(window.localStorage.getItem('jwt_theme_token') != null){
        let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
        return themeData;
    }
  }

 
   var icon = null; 
   var brandName = null;
  var themeInfo = getCurrentThemeInfo();
  
  if(themeInfo != null && themeInfo.id != null){ 
      if(themeInfo.icon != null && themeInfo.icon.length > 0){
        icon = themeInfo.icon;
      }
      if(themeInfo.brand_name != null && themeInfo.brand_name.length > 0){
        brandName = themeInfo.brand_name;
      }  
  }
 
 return ( <div>
   {secloginboxDb[sessionStorage.getItem('lang')].map((jsondata) => (   
      <div key={jsondata.id} className="sporto">
    <Dialog
    classes={{
      root: classes.center,
      paper: classes.modal + " sportot   " + classes.responsivemodelllogin
      
    }}
    key={jsondata.id}
    open={classicModal}
    TransitionComponent={Transition}
    keepMounted
    onClose={() => setClassicModal(false)}
    aria-labelledby="classic-modal-slide-title"
    aria-describedby="classic-modal-slide-description"
>

  <DialogTitle
      id="classic-modal-slide-title"
      disableTypography
      className={classes.modalHeaderlogin}
  >
    <IconButton
        className={classes.modalCloseButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => setClassicModal(false)}
    >
      <Close className={classes.modalClose} />
    </IconButton> 
  </DialogTitle>
  
  <Formsy
        onValidSubmit={handleSubmit}
        onValid={enableButton}
        onInvalid={disableButton}
        ref={formRef}
        autoComplete={"off"}
        className={classes.form + " flex flex-col justify-center w-full " + classes.modalBodylogin}
    >
  <DialogContent
      id="classic-modal-slide-description"
      className={classes.modalBodylogin}
  >  
      <div style={{textAlign:"center"}}>
        {(() => {
              
                if(themeInfo.icon != null && (themeInfo.icon != undefined && themeInfo.icon != "undefined" && themeInfo.icon.length > 0)) { 
                        return ( 
                            <img src={icon} height={"40px"} alt={""} className={classes.modellogo} /> 
                ) }else{
                  return ( 
                          <h4 className={classes.logoname}>{brandName}</h4>  
                    )
          }
          })()}

        <h4 className={classes.modalTitle}>{jsondata.title}   </h4>
        <h4 className={classes.modalSubtitle}>{jsondata.subtitle}  </h4>
      </div>

        <div className={classes.msg + " message_login_one " + classAdd} id="message"></div> 
        <div style={{paddingTop:"10px"}}>
          <GridContainer style={{marginLeft:'0'}}>
            <GridItem xs={3} style={{padding:'0'}}>
              <div>
                <div style={{display:"none"}}>{countryCode}</div>
                {(() => {
                    if(countryCode !== "0" && countryCode !== "Processing" && countryCode !== 0){
                        return(
                            <SelectFormsy className={classes.selectboxx + " selectbox cutcountry"}
                                    key={currentCountryId}
                                    name="country_id"
                                    label="" 
                                    value={currentCountryId}
                                    variant="outlined"
                                    onChange={(event) => countryWiseMobileValidate(event)}
                                    required
                            >
                                    {countryCode}
                            </SelectFormsy>
                        )
                    }else{
                        return (
                        <SelectFormsy className={classes.selectboxx + " selectbox cutcountry"}
                                    key={currentCountryId}
                                    name="country_id"
                                    label="" 
                                    value={currentCountryId}
                                    variant="outlined"
                                    onChange={(event) => countryWiseMobileValidate(event)}
                                    required
                            >
                                    {countryCode}
                            </SelectFormsy>
                        )
                    }
                })()} 
              </div>
            </GridItem>
            <GridItem xs={9} style={{padding:'0'}}> 
              <div> 
                      <TextFieldFormsy
                          label={jsondata.mobile}
                          id="mobile"
                          name="mobile" 
                          type="number"
                          value=""
                          formControlProps={{
                              fullWidth: true
                          }}
                          
                          validations={"isLength:"+mobileNumberMaxLen}
                          validationErrors={{
                              isLength: "Mobile number length must be " + mobileNumberMaxLen + " digit for " + currentCountry, 
                          }}
                          
                          variant="outlined"
                          required
                      />
                      </div>
            </GridItem>
          </GridContainer>
          </div>
                    
  </DialogContent>
  <DialogActions className={classes.modalFooterlogin}>
      
      <Button color="primary" className={classes.signinmodalbtn +" signinbtn"} type="submit" disabled={!isFormValid}>
           {jsondata.sbmitbtntwo}
      </Button>
      
      
      
  </DialogActions>
  </Formsy> 

</Dialog>
</div>
))}
</div>
  );
}
SectionLoginboxMobile.propTypes = {
  openPopup: function(isState){ 
      if(isState === "login" && setClassicModal != null){
        setClassicModal(true);
      } 
    }
};
SectionLoginboxMobile.openPopup =  function(isState){ 
 
  if(isState === "login" && setClassicModal != null){
    if(document.body.getElementsByClassName("message_login_one")[0] != null){
        document.body.getElementsByClassName("message_login_one")[0].innerHTML=""; 
      } 
    setClassicModal(true);

  } 

};

export default SectionLoginboxMobile;
import React  from "react";
import {makeStyles} from "@material-ui/core/styles";   
import 'react-accessible-accordion/dist/fancy-example.css';
import logo from "../assets/img/logo.png"; 
import styles from "../assets/jss/views/landingPage.js";  
import {Button} from '@material-ui/core';
const useStyles = makeStyles(styles);
 
 
export default function WlSuccess(props) { 
    const classes = useStyles();  

     
    return (
        <div className={classes.fullpagelogin + " " + classes.failpage}>

 

 
        <img className={classes.logoimg} alt="" style={{marginTop:"10px",marginBottom:"10px",}} src={logo}/>
        <div className={classes.loginwrap}> 
            <div style={{textAlign:'center'  }}>
            <i style={{fontSize:'50px', marginTop:"10px", color:'red',  }} className="fa fa-times"></i>
            <h4 style={{color:'red', }}>Failed</h4> 
            <table className={classes.tablebox}>
                <tr>
                    <td>
                        Transaction Id
                    </td>
                    <th>
                        #908000808
                    </th>
                </tr>
                <tr>
                    <td>
                        Amount
                    </td>
                    <th>
                        50,000
                    </th>
                </tr>
            </table>
            <p>Thank you, Your Mobile number has been verified. Your account is now active. Please use the link below to login to
your account.</p>
</div>
            <Button simple color="primary" className={classes.signinmodalbtn +" signinbtn"} type="submit" size="lg"   >
            Login to Your Account
                    </Button>
                    
        </div>
         </div>
    );
}
//import React, {Component, useEffect, useRef, useState} from "react";
import React, {Component} from "react";
// @material-ui/core components

import 'react-accessible-accordion/dist/fancy-example.css';
// import history from '../../../@history';
import * as globalurl from '../../../global/Global.js';
// import { makeStyles } from "@material-ui/core/styles";


// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Radio from "@material-ui/core/Radio";

// import {IconButton, MenuItem} from "@material-ui/core"; 
// import GridContainer from "../elements/Grid/GridContainer.js";
// import GridItem from "../elements/Grid/GridItem.js";
// import CustomTabs from "../elements/CustomTabs/CustomTabs.js";
// import accountsDb from "../data/account.js";
import Iframe from 'react-iframe'
//import Fullscreen from "react-full-screen";

// const token = "kdkddjdj2929j38jv88fpwad83pakwdi3"; 

class Sports extends Component {
    
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    // var gameUrl  = "";
    if(window.localStorage.getItem("sports_access") != null){
      this.gameUrl = globalurl.SPORTS_BOOK_BTI + "?operatorToken=" + window.localStorage.getItem("sports_access");
    }else{
      this.gameUrl = globalurl.SPORTS_BOOK_BTI + "?operatorToken=logout";
    }
  }
  
  componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
      
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  requestFullScreen(element) {
    // Supports most browsers and their versions.
    
  }
  
    render(){
        return (
            <Iframe url={this.gameUrl}
                    width="100%"
                    id="myId"
                    position="relative"
                    height={(this.state.height - 147)}
                    allowfullscreen
                    />
        );
    }
    
  }
  
  export default Sports;
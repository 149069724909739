import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next'
import CustomDropdown from "../elements/CustomDropdown/CustomDropdown"; 
import stylesr from "../assets/jss/components/headerLinkstopStyle";
import Button from "@material-ui/core/Button";
import en from "../assets/img/english.jpg";
import hi from "../assets/img/hindi.jpg"; 
import tl from "../assets/img/telugu.jpg"; 
import tm from "../assets/img/tamil.jpg";
import kn from "../assets/img/kannada.jpg"; 
import ne from "../assets/img/napali.jpg"; 

import flagDb from "../data/flag.js";

import i18new from "../i18n";

var checkOnce = 0; 
const LanguageSelector = (props) => {
  const { i18n } = useTranslation()
  const currentLocation = props.location.pathname.split("/");
  const changeLanguage = (event) => {
    if (currentLocation[1] === ""){
      sessionStorage.setItem('lang', event.target.value);
      i18n.changeLanguage(sessionStorage.getItem('lang')+'/'+sessionStorage.getItem('lang'));
      window.location.reload();
      
    }else{
      sessionStorage.setItem('lang', event.target.value);
      i18n.changeLanguage(sessionStorage.getItem('lang')+'/'+currentLocation[1]); 
      window.location.reload(); 
    }  
  }
  
  if(props.themeColors != null){
    
    //  styles.dropdown.backgroundColor = props.themeColors.bgColors.secondary_dark_bgcolor
    stylesr.usersigninanker.color = props.themeColors.bgColors.text_primary_color
    stylesr.usersigninanker["&:hover"].color = props.themeColors.bgColors.text_primary_dark_color 
    stylesr.usersigninanker["&:hover"].backgroundColor = props.themeColors.bgColors.main_color
    stylesr.usersigninanker.fontFamily = props.themeColors.bgColors.base_font;     
    
    }
  
  i18new.on('loaded', function(loaded) {
    if(checkOnce === 0){
      if (currentLocation[1] === ""){
        i18new.changeLanguage(sessionStorage.getItem('lang')+'/'+sessionStorage.getItem('lang')); 
      }else{
        i18new.changeLanguage(sessionStorage.getItem('lang')+'/'+currentLocation[1]); 
      }
      checkOnce = 1;
      
    }
  })

  if(checkOnce === 0){
    if (currentLocation[1] === ""){
      i18new.changeLanguage(sessionStorage.getItem('lang')+'/'+sessionStorage.getItem('lang')); 
    }else{
      i18new.changeLanguage(sessionStorage.getItem('lang')+'/'+currentLocation[1]); 
    }
    checkOnce = 1;
    
  }
  
  const useStylesr = makeStyles(stylesr); 
  const classesr = useStylesr(); 
  
  return (  
    <div  >
     
     {flagDb[sessionStorage.getItem('lang')].map((jsondata) => (
    <CustomDropdown
      noLiPadding
      themeColors={props.themeColors} 
    key={jsondata.id}
      buttonText={<img src={jsondata.flag_img}  alt={""} className={classesr.contryflag} />}
      buttonProps={{
        className: classesr.navLink + " " + classesr.userflagbtn,
        color: "transparent"
      }} 
      dropdownList={[
        <Button  onChange={changeLanguage} className={classesr.usersigninanker}>
          <img src={en} alt={""} className={classesr.contryflagt} />&nbsp; English  <input type="radio" value="en" name="language" className={classesr.radiobbn}  />
        </Button>, 
        <Button  onChange={changeLanguage} className={classesr.usersigninanker}>
          <img src={hi} alt={""} className={classesr.contryflagt} />&nbsp; Hindi <input type="radio" value="hi" name="language" className={classesr.radiobbn}  />
        </Button>,
        <Button  onChange={changeLanguage} className={classesr.usersigninanker}>
          <img src={tl} alt={""} className={classesr.contryflagt} />&nbsp; Telugu <input type="radio" value="tl" name="language" className={classesr.radiobbn}  />
        </Button>,
        <Button  onChange={changeLanguage} className={classesr.usersigninanker}>
          <img src={tm} alt={""} className={classesr.contryflagt} />&nbsp; Tamil <input type="radio" value="tm" name="language" className={classesr.radiobbn}  />
        </Button>,
        <Button  onChange={changeLanguage} className={classesr.usersigninanker}>
          <img src={kn} alt={""} className={classesr.contryflagt} />&nbsp; Kannada <input type="radio" value="kn" name="language" className={classesr.radiobbn}  />
        </Button>,
        <Button  onChange={changeLanguage} className={classesr.usersigninanker}>
          <img src={ne} alt={""} className={classesr.contryflagt} />&nbsp; Nepali <input type="radio" value="ne" name="language" className={classesr.radiobbn}  />
        </Button>
      ]}
  /> 
     ))}
</div>
  )
}

export default LanguageSelector;


const accountsDb = {
    en: [
        {
            'id':'3923923892',
            'titleicon':"fa fa-user",
            'title':"Profile",
            'tagName': 'tab1', 
            'userprofilediv': [
                {
                    'id':"name",
                    'display_name':"Full Name"
                },
                {
                    'id':"username",
                    'display_name':"User Name"
                },
                {
                    'id':"email",
                    'display_name':"Email Id",
                },
                {
                    'id':"mobile",
                    'display_name':"Mobile Number",
                },
                {
                    'id':"commission_type",
                    'display_name':"Commission Type",
                }
            ],
            'changepasswordbtn':"Change Password",
            'editprofilebtn':"Edit Profile",
            'oldpassword':"Old Password",
            'newpassword':"New Password",
            'confirmpassword':"Confirm Password",
            'updatepasswordbtn':" Update Password",
            'cancelbtn':"Cancel",
            'formname':"Full Name",
            'formusername':"Username",
            'formemail':"Email",
            'formphone':"Phone Number",
            'formgender':"Gender",
            'formmale':"Male",
            'formfemale':"Female",
            'formdob':"Date of Birth",
            'updateprofilebtn':"Update Profile",
        },
        {
            'id':'3923923894',
            'title':"Balance",
            'titleicon':"fa fa-university",
            'tagName': 'tab2', 
        },
        {
            'id':'3923923893',
            'title':"My Bets",
            'titleicon':"fa fa-trophy",
            'tagName': 'tab3',
            'ptext': "To change your Bank Account, please contact Customer Service .",
            'acountname': "Account Name",
            'bankdiv': [
                {
                    'banknameoption':"Bank Name",
                },
                {
                    'banknameoption':"SBI",
                },
                {
                    'banknameoption':"ICICI",
                },
                {
                    'banknameoption':"Panjab National Bank",
                },
                {
                    'banknameoption':"HDFC",
                },
            ],
            'bankbranch': "Bank Branch",
            'accountnumber': "Account Number",
            'ifsccode': "IFSC Code",
            'whyifsc': "What is my IFSC Code?",
            'myaccountbtn': "Add Account", 
        },
        {
            'id':'3923923894',
            'title':"Bonuses",
            'titleicon':"fa fa-gift",
            'tagName': 'tab4', 
        },
        // {
        //     'id':'3923923894',
        //     'title':"Statistics",
        //     'titleicon':"fa fa-chart",
        //     'tagName': 'tab5', 
        // },
        
    ],
    tl: [
        {
            'id':'3923923892',
            'titleicon':"fa fa-user",
            'title':"ప్రొఫైల్",
            'tagName': 'tab1', 
            'userprofilediv': [
                {
                    'id':"name",
                    'display_name':"పూర్తి పేరు"
                },
                {
                    'id':"username",
                    'display_name':"వినియోగదారు పేరు"
                },
                {
                    'id':"email",
                    'display_name':"ఇమెయిల్ ఐడి",
                },
                {
                    'id':"mobile",
                    'display_name':"మొబైల్ సంఖ్య",
                },
                {
                    'id':"commission_type",
                    'display_name':"కమిషన్ రకం",
                }
            ],
            'changepasswordbtn':"పాస్వర్డ్ మార్చండి",
            'editprofilebtn':"ప్రొఫైల్‌ను సవరించండి",
            'oldpassword':"పాత పాస్‌వర్డ్",
            'newpassword':"కొత్త పాస్వర్డ్",
            'confirmpassword':"పాస్వర్డ్ను నిర్ధారించండి",
            'updatepasswordbtn':" పాస్వర్డ్ను నవీకరించండి",
            'cancelbtn':"రద్దు చేయండి",
            'formname':"పూర్తి పేరు",
            'formusername':"వినియోగదారు పేరు",
            'formemail':"ఇమెయిల్",
            'formphone':"ఫోను నంబరు",
            'formgender':"లింగం",
            'formmale':"పురుషుడు",
            'formfemale':"స్త్రీ",
            'formdob':"పుట్టిన తేది",
            'updateprofilebtn':"ప్రొఫైల్‌ను నవీకరించండి",
        },
        {
            'id':'3923923894',
            'title':"సంతులనం",
            'titleicon':"fa fa-university",
            'tagName': 'tab2', 
        },
        {
            'id':'3923923893',
            'title':"నా బెట్స్",
            'titleicon':"fa fa-trophy",
            'tagName': 'tab3',
            'ptext': "మీ బ్యాంక్ ఖాతాను మార్చడానికి, దయచేసి కస్టమర్ సేవను సంప్రదించండి.",
            'acountname': "ఖాతా పేరు",
            'bankdiv': [
                {
                    'banknameoption':"బ్యాంక్ పేరు",
                },
                {
                    'banknameoption':"ఎస్బిఐ",
                },
                {
                    'banknameoption':"ఐసిఐసిఐ",
                },
                {
                    'banknameoption':"పంజాబ్ నేషనల్ బ్యాంక్",
                },
                {
                    'banknameoption':"HDFC",
                },
            ],
            'bankbranch': "బ్యాంకు శాఖ",
            'accountnumber': "ఖాతా సంఖ్య",
            'ifsccode': "IFSC కోడ్",
            'whyifsc': "నా IFSC కోడ్ ఏమిటి?",
            'myaccountbtn': "ఖాతా జోడించండి", 
        }, 
        {
            'id':'3923923894',
            'title':"బోనస్",
            'titleicon':"fa fa-gift",
            'tagName': 'tab4', 
        }, 
        
    ],
    hi: [
        {
            'id':'3923923892',
            'title':"प्रोफ़ाइल",
            'tagName': 'tab1', 
            'titleicon':"fa fa-user",
            'userprofilediv': [
                {
                    'id':"name",
                    'display_name':"पूरा नाम"
                },
                {
                    'id':"username",
                    'display_name':"उपयोगकर्ता नाम"
                },
                {
                    'id':"email",
                    'display_name':"ईमेल",
                },
                {
                    'id':"mobile",
                    'display_name':"मोबाइल",
                },
                {
                    'id':"gender",
                    'display_name':"लिंग",
                },
                {
                    'id':"date_of_birth",
                    'display_name':"जन्म की तारीख",
                },
                {
                    'id':"commission_type",
                    'display_name':"कमीशन का प्रकार",
                }
            ],
            'changepasswordbtn':"पासवर्ड बदलें",
            'editprofilebtn':"प्रोफ़ाइल संपादित करें",
            'oldpassword':"पुराना पासवर्ड",
            'newpassword':"नया पासवर्ड",
            'confirmpassword':"पासवर्ड की पुष्टि कीजिये",
            'updatepasswordbtn':"पासवर्ड अपडेट करें",
            'cancelbtn':"रद्द करना",
            'formname':"पूरा नाम",
            'formusername':"उपयोगकर्ता नाम",
            'formemail':"ईमेल",
            'formphone':"फ़ोन नंबर",
            'formgender':"लिंग",
            'formmale':"पुरुष",
            'formfemale':"महिला",
            'formdob':"जन्म की तारीख",
            'updateprofilebtn':"प्रोफ़ाइल अपडेट करें",
        },
        {
            'id':'3923923894',
            'title':"धनराशि",
            'titleicon':"fa fa-university",
            'tagName': 'tab2', 
        },
        {
            'id':'3923923893',
            'title':"मेरे दांव",
            'tagName': 'tab3',
            'titleicon':"fa fa-trophy",
            'ptext': "अपना बैंक खाता बदलने के लिए, कृपया ग्राहक सेवा से संपर्क करें।",
            'acountname': "खाते का नाम",
            'bankdiv': [
                {
                    'banknameoption':"बैंक का नाम",
                },
                {
                    'banknameoption':"भारतीय स्टेट बैंक",
                },
                {
                    'banknameoption':"आईसीआईसीआई",
                },
                {
                    'banknameoption':"पंजाब नेशनल बैंक",
                },
                {
                    'banknameoption':"एचडीफसी ",
                },
            ],
            'bankbranch': "बैंक शाखा",
            'accountnumber': "खाता संख्या",
            'ifsccode': "IFSC कोड",
            'whyifsc': "मेरा IFSC कोड क्या है?",
            'myaccountbtn': "खाता जोड़ो", 
        },
        {
            'id':'3923923894',
            'title':"बोनस",
            'titleicon':"fa fa-gift",
            'tagName': 'tab4', 
        },
        // {
        //     'id':'3923923894',
        //     'title':"आंकड़े",
        //     'titleicon':"fa fa-chart", 
        //     'tagName': 'tab5', 
        // },
    ]
}
export default accountsDb;

/*eslint-disable*/
import React, {useEffect, useRef, useState} from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton"; 
import People from "@material-ui/icons/People";
import Email from "@material-ui/icons/Email"; 
// react components for routing our app without refresh
import { Link, withRouter } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// @material-ui/core components
import {Button, Divider, Typography, InputAdornment, Icon} from '@material-ui/core';
import CustomInput from "../elements/CustomInput/CustomInput.js";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
import * as Gurl from '../../../global/Global.js';
import ReCAPTCHA from "react-google-recaptcha";
// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// core components
import CustomDropdown from "../elements/CustomDropdown/CustomDropdown.js";
import CustomButton from "../elements/CustomButtons/Button.js";
//Third Party Authentication
import {FacebookLogin, GoogleLogin} from "../../../@casino"

import Formsy from 'formsy-react';
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import menuDb from "../data/menu.js";
import logo from "../assets/img/logo.png";
import GridItem from "../elements/Grid/GridItem";
import GridContainer from "../elements/Grid/GridContainer";
import styles from "../assets/jss/components/headerLinksStyle.js";
import stylesr from "../assets/jss/components/headerLinkstopStyle"; 

import * as authActions from '../../../auth/casino/actions';
import {useDispatch, useSelector} from 'react-redux';
import {TextFieldFormsy, CheckboxFormsy} from '../../../@casino';
import secloginboxDb from "../data/secloginbox.js";
//import { useTranslation } from 'react-i18next'
const history = createBrowserHistory()
let classicModal = null;
let setClassicModal = null;


let test = "ok";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";
const validEmailRegex = 
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

function SectionLoginbox(props) {

    const dispatch = useDispatch();
    const [isFormValid, setIsFormValid] = useState(false);
    const [isCaptcha, setIsCaptcha] = useState(false);
    var captchaReceived = false;
    const formRef = useRef(null);
    const login = useSelector(({auth}) => auth.login);
    const [showPassword, setShowPassword] = useState(false);
    let recaptchaInstance;
    const [recaptchaCode, setRecaptchaCode] = React.useState("NOVALUE");
    useEffect(() => {
        if ( login.error && (login.error.username ||  login.error.password || login.error.recaptcha || login.error.message ) )
        {
          resetRecaptcha();
            if(login.error.message != null){
                document.body
                .getElementsByClassName("message")[0]
                .innerHTML=login.error.message 
                
            }
            else{
                formRef.current.updateInputsWithError({
                    ...login.error
                });
            }
            disableButton();
            captchaReceived = false;
            setRecaptchaCode("NOVALUE");
        } 
    }, [login.error]);

    const resetRecaptcha = () => {
      recaptchaInstance.reset();  
    };
    var verifyCallback = function (response) {
      setRecaptchaCode(response);
      captchaReceived = true;
      if(response && isCaptcha === true){
        enableButton();
      }else{
        setIsFormValid(false);
      }
    };
    var gcErrorCallback = function (issue){
      disableButton();
      captchaReceived = false;
      setRecaptchaCode("NOVALUE");
   };

    function disableButton()
    {
        setIsFormValid(false);
        document.body
        .getElementsByClassName("signinbtn")[0]
          .setAttribute("style", "opacity:0.5;");
    }

    function enableButton()
    {
      if((recaptchaCode !== "NOVALUE" && recaptchaCode.length > 0) || captchaReceived === true){
        setIsFormValid(true);
        document.body
        .getElementsByClassName("signinbtn")[0]
          .setAttribute("style", "opacity:1;");
      }else{
        setIsCaptcha(true);
      }
    }

    function handleSubmit(model)
    {
         model = {"recaptcha": recaptchaCode, ...model}
        dispatch(authActions.submitLogin(model,props));
        document.body
        .getElementsByClassName("message")[0]
        .innerHTML=" " 

    }
    const responseFb = (response) => {
      alert("RESONSE: " + JSON.stringify(response));
    }
    const componentClickFb = (response) => {
      
    }

    const responseGoogle = (response) => {
      //sessionStorage.setItem("googleOutput", JSON.stringify(response, getCircularReplacer()));
    }
    if(props.themeColors != null){
      
      styles.responsivemodell.backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinbtn.backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinbtn.color = props.themeColors.bgColors.text_primary_in_color;
      styles.modalHeader.background = props.themeColors.bgColors.primary_bgcolor;
      styles.modalBody.background = props.themeColors.bgColors.primary_bgcolor;
      styles.modalFooter.background = props.themeColors.bgColors.primary_bgcolor;
      styles.modalSubtitle.color = props.themeColors.bgColors.text_primary_color;
      styles.modalTitle.color = props.themeColors.bgColors.text_primary_color;
      styles.logoname.color = props.themeColors.bgColors.text_primary_color;
      styles.signupbixp.color = props.themeColors.bgColors.text_primary_color; 
      styles.signupbix.color = props.themeColors.bgColors.text_primary_color; 
      styles.form["& input"].color = props.themeColors.bgColors.text_primary_color; 
      
      styles.signinmodalbtn.backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn["&:hover"].backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn.color = props.themeColors.bgColors.text_primary_in_color;
      
      styles.logoname.fontFamily = props.themeColors.bgColors.logo_font 
      styles.modalTitle.fontFamily = props.themeColors.bgColors.base_font 
      styles.modalSubtitle.fontFamily = props.themeColors.bgColors.base_font 
      styles.form["& label"].fontFamily = props.themeColors.bgColors.base_font 
      styles.form["& input"].fontFamily = props.themeColors.bgColors.base_font 
      styles.signupbix.fontFamily = props.themeColors.bgColors.base_font 
      styles.modalFooter.fontFamily = props.themeColors.bgColors.base_font 
      styles.signupbix.fontFamily = props.themeColors.bgColors.base_font 
      styles.signinmodalbtn.fontFamily = props.themeColors.bgColors.base_font 

    }
    
  //  const { t } = useTranslation()
    let currentStyle = styles;
    const useStyles = makeStyles(styles);

    const classes = useStyles();
    
    
     function parseJwt(token) {
      if (!token) { return; }
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
  }
  function getCurrentThemeInfo(){
    if(window.localStorage.getItem('jwt_theme_token') != null){
        let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
        return themeData;
    }
  }

 
  var icon = null; 
  var brandName = null;
  var themeInfo = getCurrentThemeInfo();
  
  if(themeInfo != null && themeInfo.id != null){ 
      if(themeInfo.icon != null && themeInfo.icon.length > 0){
        icon = themeInfo.icon;
      } 
      if(themeInfo.brand_name != null && themeInfo.brand_name.length > 0){
        brandName = themeInfo.brand_name;
      }  
  }
  
  
  [classicModal, setClassicModal] = React.useState(false);
  return ( <div>
   {secloginboxDb[sessionStorage.getItem('lang')].map((jsondata) => (   
      <div key={jsondata.id}>
    <Dialog
    classes={{
      root: classes.center,
      paper: classes.modal + " " + classes.responsivemodell
      
    }}
    key={jsondata.id}
    open={classicModal}
    TransitionComponent={Transition}
    keepMounted
    onClose={() => setClassicModal(false)}
    aria-labelledby="classic-modal-slide-title"
    aria-describedby="classic-modal-slide-description"
>

  <DialogTitle
      id="classic-modal-slide-title"
      disableTypography
      className={classes.modalHeader}
  >
    <IconButton
        className={classes.modalCloseButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => setClassicModal(false)}
    >
      <Close className={classes.modalClose} />
    </IconButton>
       

     
  </DialogTitle>
  <Formsy
        onValidSubmit={handleSubmit}
        onValid={enableButton}
        onInvalid={disableButton}
        ref={formRef}
        autoComplete={"off"}
        className={classes.form + " flex flex-col justify-center w-full " + classes.modalBody}
    >
  <DialogContent
      id="classic-modal-slide-description"
      className={classes.modalBody}
  >  

      <div style={{textAlign:"center"}}>
          {(() => {
              // alert(JSON.stringify(themeInfo))
                if(themeInfo.icon != null && (themeInfo.icon != undefined && themeInfo.icon != "undefined" && themeInfo.icon.length > 0)) { 
                        return ( 
                            <img src={icon} height={"40px"} alt={""} className={classes.modellogo} /> 
                ) }else{
                  return ( 
                          <h4 className={classes.logoname}>{brandName}</h4>  
                    )
          }
          })()}

        <h4 className={classes.modalTitle}>{jsondata.title}   </h4>
        <h4 className={classes.modalSubtitle}>{jsondata.subtitle}  </h4>
      </div>

        <div className={classes.errormsg + " message"} id="message"></div> 
        <div className={classes.formggroup}>
        <GridContainer style={{marginLeft:'0'}}>
            <GridItem xs={12} style={{padding:'0'}}>
          <TextFieldFormsy
              label={jsondata.name}
              id="username"
              name="username"
              formControlProps={{
                  fullWidth: true
              }}
              validations={{
                  minLength: 4
              }}
              validationErrors={{
                  minLength: 'Min character length is 4'
              }}
              
              variant="outlined"
              required
          /> 
          </GridItem>
          </GridContainer>
          </div>
          <div className={classes.formggroup}>
          <GridContainer style={{marginLeft:'0'}}>
            <GridItem xs={12} style={{padding:'0'}}>
            <TextFieldFormsy
                formControlProps={{
                    fullWidth: true
                }}
                
                type="password"
                name="password"
                label={jsondata.password}
                validations={{
                    minLength: 4,
                    maxLength: 20
                }}
                validationErrors={{
                    minLength: 'Min character length is 4',
                    maxLength: "Max character length is 20"
                }}
                InputProps={{
                  className: 'pr-2',
                  type: showPassword ? 'text' : 'password',
                  endAdornment: (
                    <InputAdornment position="end" style={{width:'12px',margin:'0px'}}>
                      <IconButton onClick={() => setShowPassword(!showPassword)} style={{padding:'0px'}}> 
                        <i className={showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} style={{fontSize: '18px',color: '#ccc'}}></i>
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                variant="outlined"
                required
            />
            </GridItem>
            <GridItem xs={12} style={{padding:'0'}}>
              
            </GridItem>
          </GridContainer>
            </div>  
                 
                <ReCAPTCHA 
                   ref={e => recaptchaInstance = e}  
                   onChange={verifyCallback}
                   className={classes.receptadiv}
                   theme="dark"
                   onExpired={gcErrorCallback}
                   onErrored={gcErrorCallback}
                   sitekey={Gurl.reCaptchaKey}
                   name="recaptcha"  
                  />  
                       
                    <p className={classes.signupbixp + " " + classes.signupfor }  >
                  <IconButton
                      className={classes.signupbix}
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      onClick={() => {setClassicModal(false);props.ForgotBox.openPopup("forgot")}}
                  > {jsondata.text_fp} <i className="fas fa-angle-double-right"></i>
                  </IconButton>  
                  </p>
  </DialogContent>
  <DialogActions className={classes.modalFooter}>
      
      <Button color="primary" className={classes.signinmodalbtn +" signinbtn"} type="submit" disabled={!isFormValid}>
           {jsondata.sbmitbtn}
      </Button>
      <span className={"fbbtnn"}>
      {/* <FacebookLogin 
        FacebookAuthId = "655052758573398"
        componentClicked = {componentClickFb}
        responseFacebook = {responseFb}
      />
      </span>
      <span className={"googlebtnn"}> 
      <GoogleLogin
        googleClientId="183554071539-hncocf9j5m8em26qhp7g6r4quddibipk.apps.googleusercontent.com"
        buttonValue="Login with Google"
        googleOnResponse={responseGoogle}
        googleScope={'email','profile','openid'}
      /> */}
      </span>
      
      <p className={classes.signupbixp}>
       {jsondata.text_p} &nbsp;
      <IconButton
        className={classes.signupbix}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => {setClassicModal(false);props.SignupBox.openPopup("signup")}}
    > {jsondata.text_a} <i className="fas fa-angle-double-right"></i>
    </IconButton>
          
      </p>
  </DialogActions>
  </Formsy> 
</Dialog>
</div>
))}
</div>
  );
}
SectionLoginbox.propTypes = {
  openPopup: function(isState){ 
      if(isState === "login"){
        setClassicModal(true);
      } 
    }
};
SectionLoginbox.openPopup = function(isState){ 
    if(isState === "login"){
        setClassicModal(true);
    } 
};

export default SectionLoginbox;
// import * as UserActions from './user.actions';
//import * as Actions from '../../../src/store/actions';
import jwtService from '../../../services/jwtService';

export const EFUNDREQUEST_SUCCESS = 'EFUNDREQUEST_SUCCESS';
export const EFUNDREQUEST_ERROR = 'EFUNDREQUEST_ERROR';



export function eWalletDepositRequest({parent_phone_number, sender_phone_number, pay_by, amount, transaction_id, comment},receipt_by){
    return (dispatch) => {
        jwtService.depositEWalletRequest({
            token: window.localStorage.getItem('jwt_access_token'),
            parent_phone_number,
            sender_phone_number,
            pay_by,
            amount,
            transaction_id,
            receipt_by,
            comment
        })
        .then((user) => { 
            return dispatch({
                    type: EFUNDREQUEST_SUCCESS,
                    payload: user
                });
            }
        )
        .catch(error => { 
            return dispatch({
                type   : EFUNDREQUEST_ERROR,
                payload: error
            });
        });
    }
}
import React, {useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";  
import Formsy from 'formsy-react';    
import 'react-accessible-accordion/dist/fancy-example.css';
  
import isValidDomain from "is-valid-domain";
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";
import CustomTabs from "../elements/CustomTabs/CustomTabs.js";
import {MenuItem} from '@material-ui/core'; 
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import styles from "../assets/jss/components/tabsProfilesetup.js";
import designimg from "../assets/img/layout.png";
import paypalimg from "../assets/img/paypal.png";
import designimg2 from "../assets/img/497110d.png";
import Button from "../elements/CustomButtons/Button"; 
import Slide from "@material-ui/core/Slide"; 
// import icon11 from "../assets/img/icon11.png"; 
import ImgUpload from "./imgupload.js"; 
// import SwitchExample from "./swicher.js";
import {TextFieldFormsy, SelectFormsy, SwitchFormsy, CheckboxFormsy, FuseChipSelectFormsy} from '../../../@casino';
import * as authActions from '../../../auth/casino/actions';
import {useDispatch, useSelector} from 'react-redux';
import * as Gurl from "../../../global/Global.js"
import axios from 'axios';
import history from '../../../@history';
import jwt from 'jsonwebtoken'; 
import { PayPalButton } from "react-paypal-button-v2";
import jwtDecode from 'jwt-decode';

const useStyles = makeStyles(styles);
let SelectedModule = [];
let moduleDuration = [];
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

const validEmailRegex = 
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
var gatewayAPI = false;
export default function ProfileSetup(props) { 
    const classes = useStyles();  
    const parser = new DOMParser();
    const dispatch = useDispatch();
    const [isFormValid, setIsFormValid] = useState(false); 
    const [isFormValidPayment, setIsFormValidPayment] = useState(false); 
    //const [moduleDuration, setModuleDuration] = React.useState(false);
    const [isFormValidTwo, setIsFormValidTwo] = useState(false);
    const formRef = useRef(null); 
    const formRefstepfirst = useRef(null);
    const formRefstepSecond = useRef(null);
    const formRefstepThird = useRef(null);
    const formRefstepFour = useRef(null);
    const formRefstepFive = useRef(null);
    const formRefstepBank = useRef(null);
    const profilesetup = useSelector(({auth}) => auth.profilesetup);

    const [finalDomainName, setFinalDomainName] = React.useState("isoftgaming.com");
    
    var currentDomainSplit = window.location.hostname.split(".");
    if(currentDomainSplit.length > 2){
        //finalDomainName = currentDomainSplit[currentDomainSplit.length-2]+"."+currentDomainSplit[currentDomainSplit.length-1];
    }
    
    const [planType, setPlanType] = React.useState("NOVALUE");
    const [stepSubmit, setStepSubmit] = React.useState(0);
    const [pageLoaded, setPageLoaded] = React.useState(false);
    const [domainType, setDomainType] = React.useState(false);
    const [paymentTypeId, setPaymentTypeId] = React.useState(3);
    const [paypalGatewayId, setPaypalGatewayId] = React.useState(0);
    const [gatewayId, setGatewayId] = React.useState(0);
    const [userConfig, setUserConfig] = React.useState("NOVALUE");

    const [currentCurrency, setCurrentCurrency] = useState("NO");
    const [gatewayAccess, setGatewayAccess] = useState(false);
    const [paypalResponseText, setPaypalResponseText] = useState("");
    const [payPalKey, setPayPalKey] = useState("");
    
    const [sumOfSetupCost, setSumOfSetupCost] = React.useState("LOADING...");
    var [sumOfRecurringCost, setSumOfRecurringCost] = React.useState("LOADING...");
    var [sumOfTotalCost, setSumOfTotalCost] = React.useState("LOADING...");
    const [branchName, setBranchName] = React.useState("");
    const [bankName, setBankName] = React.useState("");
    const [classAdd, setClassAdd] = useState(false);
    var ifscCode = "";
    //let sumOfSetupCost = 0.00;
    //let sumOfRecurringCost = 0.00;
    //let sumOfTotalCost = 0.00;
    const [changeModuels, setChangeModuels] = React.useState(false);

    const [gameType, setGameType] = React.useState("NOVALUE");
    const [classicModal, setClassicModal] = React.useState(false);
    const [classicModal2, setClassicModal2] = React.useState(false);
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [planId, setPlanId] = React.useState(0);
    const [planName, setPlanName] = React.useState(0);
    const [themeId, setThemeId] = React.useState(0);
    const [typeGameid, setTypeGameid] = React.useState(0);
    const [subDomain, setSubDomain] = React.useState(0);  
    const [chooseDomain, setChooseDomain] = React.useState(0); 
     const [currencyType, setCurrencyType] = React.useState(0);
     const [currencyId, setCurrencyId] = React.useState(0);
     const [supportEmail, setSupportEmail] = React.useState(0);
     const [supportContactNumber, setSupportContactNumber] = React.useState(0);
    const [supportAddress, setSupportAddress] = React.useState(0); 
    const [isDefaultValue, setIsDefaultValue] = React.useState(false);
    var [userSettings, setUserSettings] = React.useState(false);
    var [userBankerDetails, setUserBankerDetails] = React.useState(false);
    var [userModules, setUserModules] = React.useState(false);
    const [isLoadCompleted, setIsLoadCompleted] = useState(false);

    const [themeTypes, setThemeTypes] = React.useState("NOVALUE");
    const [moduleLists, setModuleLists] = React.useState("NOVALUE");
   
    var [isError, setIsError] = React.useState(false);

    var [currentPosition, setCurrentPosition] = React.useState(0);

    const [settingConfig, setSettingConfig] = React.useState(false);
    const [isPhotoUploading, setIsPhotoUploading] = useState(false);
    const [attachedImage, setAttachedImage] = useState("");
    const [resetImage, setResetImage] = useState("NO");
    const [countryCode, setCountryCode] = React.useState("NOVALUE");
    const [currentCountryId, setCurrentCountryId] = React.useState("200"); 
    const [availableCurrencies, setAvailableCurrencies] = React.useState("200"); 
    
    let userData = false; 
    function parseJwt(token) {
        if (!token) { return; }
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        var userDatat = JSON.parse(window.atob(base64));
        return userDatat;
    }
    if(userData === false){
        userData = parseJwt(window.localStorage.getItem("access_token"));
        let xyzData = parseJwt(window.localStorage.getItem("jwt_theme_token"));
         
        userData = {...userData, ...xyzData};
    } 
    if(userData.Registration == null){
        history.push({
            pathname:'/wllogin'
        })
    }

    function getCurrentCountry(myCountryData, currencyDatas){
        axios.get('https://ipapi.co/json/').then((response) => {
                let data = response.data;
                myCountryData.forEach((jsonData) => {
                    var phonecode = ("+" + jsonData.phonecode.toString()).toString();
                    
                    if(phonecode === data.country_calling_code){
                        currencyDatas.map((currInfo) => {
                            if(currInfo != null && currInfo.Currency != null && currInfo.Currency.country_id === jsonData.id.toString()){
                                if(currencyId === 0){
                                    setCurrentCurrency(currInfo.Currency.code);
                                    setCurrencyId(currInfo.Currency.id);
                                }
                            }
                        });
                    }
                })
            }).catch((error) => {
                console.log(error);
            });
    }
    function getcountrydata(currencyDatas){
        const request = axios.post(Gurl.WLCOUNTRY_DATA, JSON.stringify({
            token: window.localStorage.getItem('jwt_access_token')
        }),{header:{"Content-Type": "application/x-www-form-urlencoded"}});
    
        request.catch((error) => {
            setCountryCode("NOVALUE");
        }); 

        request.then((response) => { 
            if(response.data.data != null){ 
                var myOutPut = (
                    response.data.data.map((jsondata) => (
                        <MenuItem value={jsondata.id}><img src={jsondata.flag} alt={""} width="25px" />&nbsp;{jsondata.title} - ({jsondata.phonecode})</MenuItem> 
                    ))
                );
                setCountryCode(myOutPut);
                getCurrentCountry(response.data.data, currencyDatas);
            };
        });  
    }      
    

    function getCurrentCurrency(){
        if(window.localStorage.getItem('jwt_theme_token') != null){
            let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
            if(themeData.website_theme != null){
              if(themeData.currency_code.length != null){
                setCurrentCurrency(themeData.currency_code);
              }else{
                setCurrentCurrency("INR");
              }
            }else{
                setCurrentCurrency("INR");
            }
        }else{
            setCurrentCurrency("INR");
        }
    }
    if(currentCurrency === "NO"){
        getCurrentCurrency();
    }
    function gatewayDetails(){
        if(gatewayAPI === false){
            gatewayAPI = true;
            const request = axios.post(Gurl.GETGATEWAYDETAILS , {
                token: window.localStorage.getItem('jwt_access_token')
            });
            
            request.then((response) => {
                gatewayAPI = false;
                if(response.data.data != null){
                    setGatewayAccess(response.data.data);
                }
            });
            request.catch((error) => {
                gatewayAPI = false;
                setGatewayAccess("ERROR")
            });
        }
    }
    if(gatewayAccess === false){
        gatewayDetails();
    }
    if(payPalKey === ""){
        if(Array.isArray(gatewayAccess)){
            gatewayAccess.map((getOneByone) => {
                if(getOneByone.GATEWAYCODE === "paypal"){
                    let gatewayInfo = JSON.parse(getOneByone.GATEWAYINFO);
                    setPayPalKey(gatewayInfo[0].paypal_token);
                    setPaypalGatewayId(getOneByone.user_gateway_id);
                }
            });
       }else{
            if(gatewayAccess.GATEWAYCODE === "paypal"){
                let gatewayInfo = JSON.parse(gatewayAccess.GATEWAYINFO);
                setPayPalKey(gatewayInfo[0].paypal_token);
                setPaypalGatewayId(gatewayAccess.user_gateway_id);
            }
       }
    }
    var isCheckObject = function(a) {
        return (!!a) && (a.constructor === Object);
    };
    if(isDefaultValue === false){ 
        if(userData.Registration != null){
            if(typeGameid === 0 && userData.Registration.website_type_id != 0){
                if(isCheckObject(gameType)){
                    var changeToArray = userData.Registration.website_type_id.split(",");
                    
                    let selectedGames = [];
                        gameType.map((jsondata) => {
                            if(changeToArray.indexOf(jsondata.id) != -1) {
                                var newdata = {
                                    value: jsondata.id,
                                    label: jsondata.type_name
                                };
                                selectedGames.push(newdata);
                            }
                        });
                       
                    setTypeGameid(selectedGames);
                }else{
                    setTypeGameid(userData.Registration.website_type_id);
                }
                
            }
            if(supportContactNumber === 0 && userData.Registration.support_contact_number != null && userData.Registration.support_contact_number.length > 0){
                setSupportContactNumber(userData.Registration.support_contact_number);
            }
            if(supportContactNumber === 0 && userData.Registration.mobile != null && userData.Registration.mobile.length > 0){
                setSupportContactNumber(userData.Registration.mobile);
            }
            if(planId === 0 && userData.Registration.membership_plan_id != null && userData.Registration.membership_plan_id != 0){
                setPlanId(userData.Registration.membership_plan_id);
            }
            if(planName === 0 && userData.MembershipPlan.plan_name != null && userData.MembershipPlan.plan_name.length > 0){
                setPlanName(userData.MembershipPlan.plan_name);
            }
            if(currencyId === 0 && userData.Registration.currency_id != null && userData.Registration.currency_id != 0){
                setCurrencyId(userData.Registration.currency_id);
            }
            if(supportEmail === 0 && userData.Registration.support_email != null && userData.Registration.support_email.length > 0){
                setSupportEmail(userData.Registration.support_email);
            }
            if(supportEmail === 0 && userData.Registration.email != null && userData.Registration.email.length > 0){
                setSupportEmail(userData.Registration.email);
            }
            if(supportAddress === 0 && userData.Registration.support_address != null && userData.Registration.support_address.length > 0){
                setSupportAddress(userData.Registration.support_address);
            }
            if(userBankerDetails === false && userData.Registration != null && userData.Registration.default_banker_data != null && userData.Registration.default_banker_data.length > 0){
                var bankerSettings = JSON.parse(userData.Registration.default_banker_data);
                setUserBankerDetails(bankerSettings);
            }
            if(attachedImage === "" && attachedImage.length == 0 && userData.Registration.logo != null && userData.Registration.logo.length > 0){
                setAttachedImage(userData.Registration.logo);
            }

            if(userData.Registration.domain_type != null && userData.Registration.domain_type.length > 0){
                setDomainType(userData.Registration.domain_type);
            }else{
                setDomainType("subdomain");
            }
            if(userData.Registration.domain != null && userData.Registration.domain.length > 0){
                if(userData.Registration.domain_type === "subdomain"){
                    setSubDomain(userData.Registration.domain.replace("."+finalDomainName,""));
                }else{
                    setChooseDomain(userData.Registration.domain);
                }
                if(stepSubmit < 1){
                    setStepSubmit(1);
                    setIsError(true);
                    isError = true;
                }
                //maindomain();
            }
            if(userSettings === false && userData.Registration.security_settings_data != null && userData.Registration.security_settings_data.length > 0){
                var settings = JSON.parse(userData.Registration.security_settings_data);
                setUserSettings(settings);
            }
            
            if(themeId === 0 && userData.Registration.cms_theme_id != null && userData.Registration.cms_theme_id > 0){
                setThemeId(userData.Registration.cms_theme_id);
            }

            if(userModules === false && userData.Registration.user_modules_data != null && userData.Registration.user_modules_data.length > 0){
                setUserModules(userData.Registration.user_modules_data);
            }

            

            stepchecker();
            setIsDefaultValue(true);
        }
    }

    async function setUploadLogoid(isPhoto, e){
        if(e != null){
            setIsPhotoUploading(true);
            uploadImage({icon: e});
        } 
    }
    async function uploadImage(data){
        const imageUpURL = Gurl.IMAGEUPLOAD; //process.env.MSN_WIFI; 
            
        axios.defaults.headers.post['Content-Type'] = "multipart/form-data";
        axios.defaults.headers.common = {};
        axios.defaults.headers.common.accept = 'application/json';
    
        const formData = new FormData();
        formData.append('icon',data.icon)
    
        axios.post(imageUpURL, formData,{headers:{"content-type": "multipart/form-data"}})
        .then(response => {
            if(response.data.status == "1") { 
                if(response.data.img != null){
                    setAttachedImage(response.data.img);
                }
            } else {
                var errorObj = {status:false,"message": response.data.message}; // This Message will be show at Password field
                
            }
            setIsPhotoUploading(false);
        })
        .catch((error) => {
            setIsPhotoUploading(false);
        });
    }
    function getuserconfig(){
        const request = axios.post(Gurl.WLUSER_CONFIG, JSON.stringify({
            token: window.localStorage.getItem('jwt_access_token')
        }),{header:{"Content-Type": "application/x-www-form-urlencoded"}});
   
        request.catch((error) => {
            setUserConfig("NOVALUE");
        }); 

        request.then((response) => { 
            if(response.data.data != null){ 
                var myConfig = response.data.data;
                setUserConfig(myConfig[0]);
                if(myConfig[0] != null && myConfig[0].main_domain != null && myConfig[0].main_domain.length > 0){
                    setFinalDomainName(myConfig[0].main_domain);
                }
            };
        });  
    }
    function plan(planIndex){
        if( document.body.getElementsByClassName(classes.planboxx)[0] != null){
            document.body 
                .getElementsByClassName(classes.planboxx)[0]
                .setAttribute("style", "display:none;");
            document.body 
                .getElementsByClassName(classes.domainboxx)[0]
                .setAttribute("style", "display:block;");
        }
        setPlanId(planIndex);  
    }
    function openBlankUrl(urlOfTheme){
        window.open(urlOfTheme, "_blank","fullscreen,scrollbars")
    }
    function planNameIndex(event){       
        setPlanName(event);
    }
    function planb(){   
        var selectedGames = "";
        if(typeGameid!=null && Array.isArray(typeGameid)){
            typeGameid.map((selval) => {
                if(selval.value != null && selval.value > 0){
                    if(selectedGames != ""){
                        selectedGames = selectedGames+","+selval.value;
                    }else{
                        selectedGames = selval.value;
                    }
                }
                
            });
        }
        getplansdata(selectedGames);
        document.body 
            .getElementsByClassName(classes.planboxx)[0]
            .setAttribute("style", "display:block;");
        document.body 
            .getElementsByClassName(classes.domainboxx)[0]
            .setAttribute("style", "display:none;");
    }
    function couponcodeBox(){   
        document.body 
            .getElementsByClassName('couponcode')[0]
            .setAttribute("style", "display:block;");
        document.body 
            .getElementsByClassName('paypalcode')[0]
            .setAttribute("style", "display:none;");
        document.body 
            .getElementsByClassName(classes.radiobtn8)[0]
            .classList.add("radiobtn");
        document.body 
            .getElementsByClassName(classes.radiobtn9)[0]
            .classList.remove("radiobtn");
        setPaymentTypeId(3);
        setGatewayId(0);
    }
    function paypalBox(){   
        document.body 
            .getElementsByClassName('couponcode')[0]
            .setAttribute("style", "display:none;");
        document.body 
            .getElementsByClassName('paypalcode')[0]
            .setAttribute("style", "display:block;");
        document.body 
            .getElementsByClassName(classes.radiobtn9)[0]
            .classList.add("radiobtn");
        document.body 
            .getElementsByClassName(classes.radiobtn8)[0]
            .classList.remove("radiobtn");
        setPaymentTypeId(2);
        setGatewayId(paypalGatewayId);
    }
    window.onload = function (){ 
        if(planId > 0 && currentPosition >= 0 && pageLoaded === false){
            setPageLoaded(true);
            stepchecker();
            if(currentPosition == 1){
                nextStep(1);
            }else if(currentPosition == 2){
                nextStep(2);
            }else if(currentPosition == 3){
                nextStep(3);
            }else if(currentPosition == 4){
                nextStep(4);
            }else{
                plan(planId);
            }
        }
    };
    if(pageLoaded === false){
        setTimeout(function() { 
            if(planId > 0 && currentPosition >= 0){
                setPageLoaded(true);
                stepchecker();
                if(currentPosition == 1){
                    nextStep(1);
                }else if(currentPosition == 2){
                    nextStep(2);
                }else if(currentPosition == 3){
                    nextStep(3);
                }else if(currentPosition == 4){
                    nextStep(4);
                }else{
                    plan(planId); 
                }
            }
        }, 1000);
    }
    function subdomain(){ 
        setDomainType("subdomain");
        document.body 
            .getElementsByClassName("subdomaincl")[0]
            .setAttribute("style", "display:inline-block;");
            
        document.body 
            .getElementsByClassName(classes.radiobtn)[0]
            .classList.add("radiobtn");
        document.body 
            .getElementsByClassName(classes.radiobtn2)[0]
            .classList.remove("radiobtn");   
        document.body 
            .getElementsByClassName(classes.domaintext)[0]
            .setAttribute("style", "display:inline-block;");
        document.body 
            .getElementsByClassName("domaincl")[0]
            .setAttribute("style", "display:none;"); 
    }
    function maindomain(){   
        setDomainType("domain"); 
        document.body 
            .getElementsByClassName("domaincl")[0]
            .setAttribute("style", "display:block;"); 
        document.body 
            .getElementsByClassName(classes.radiobtn2)[0]
            .classList.add("radiobtn");
        document.body 
            .getElementsByClassName(classes.radiobtn)[0]
            .classList.remove("radiobtn");
        document.body 
            .getElementsByClassName("subdomaincl")[0]
            .setAttribute("style", "display:none;");
        document.body 
            .getElementsByClassName(classes.domaintext)[0]
            .setAttribute("style", "display:none;");
    } 
    function design(themeIndex){    
        setThemeId(themeIndex);
    } 
    
    function payment(){    
        // document.body 
        //     .getElementsByClassName(classes.radiobtn6)[0]
        //     .classList.add("radiobtn");
        // document.body 
        //     .getElementsByClassName(classes.radiobtn7)[0]
        //     .classList.remove("radiobtn"); 
    } 
    function payment2(){    
        document.body 
            .getElementsByClassName(classes.radiobtn6)[0]
            .classList.remove("radiobtn");
        document.body 
            .getElementsByClassName(classes.radiobtn7)[0]
            .classList.add("radiobtn"); 
    } 
    function nextStep(tabIndex){
        if(currentPosition >= (tabIndex-1) && tabIndex !== 5){
            setSelectedTab(tabIndex);
            if(tabIndex == 0){
                if(planId > 0){
                    plan(planId)
                }
            }else if(tabIndex == 1){
                getSecuritySettings();
                currentPosition = 2;
                setCurrentPosition(2);
            }
        }
    }
      
    function setEmailIndex(event){
        if(event.target != null){
            setSupportEmail(event.target.value);  
            stepchecker();
        }
    }
  
    function setSupportNumberIndex(event){
        setSupportContactNumber(event.target.value);  
        stepchecker();
    }
    function domainvaluetext(event){
        if(event.target != null){
            setChooseDomain(event.target.value);
        }  
        stepchecker();
    }
    function setCurrencyIndex(event){
        if(event.target != null){
            setCurrencyId(event.target.value);
        }
        stepchecker();
    }
    function setAddressIndex(event){
        if(event.target != null){
            setSupportAddress(event.target.value);
        }
        stepchecker();
    } 
    function subdomainvaluetext(event){
        if(event.target.value != null){
            setSubDomain(event.target.value);  
        }
        stepchecker(); 
    }
   
    function stepchecker(){
        //uploadLogoid !== 0 && 
        if(planId >= 0  && isError === true && typeGameid !== 0 && supportAddress !==0 && supportContactNumber !== 0 && (isValidDomain(chooseDomain) === true || isValidDomain(subDomain + "." + finalDomainName) === true) && supportEmail !== 0 && currencyType !== 0){
            setCurrentPosition(1);
            currentPosition = 1;
        }else{
            setCurrentPosition(0);
            currentPosition = 0;
        }
        if(currentPosition > 0 && isError === true ){
            setCurrentPosition(1);
            currentPosition = 1;
        }
        if(currentPosition > 0 && userSettings != null && userSettings != false && isError === true ){
            setCurrentPosition(2);
            currentPosition = 2;
        }
        if(currentPosition > 1 && themeId > 0 && isError === true ){
            setCurrentPosition(3);
            currentPosition = 3;
        }
        if(currentPosition > 1 && userBankerDetails != null && userBankerDetails != false && isError === true ){
            setCurrentPosition(4);
            currentPosition = 4;
        }
    }
      
    
    
    function openKYCModule(kycModuleId){ 
        setClassicModal(kycModuleId);
    }

      

    useEffect(() => {
        if ( profilesetup.error && profilesetup.success != true && (profilesetup.error.username || profilesetup.error.password || profilesetup.error.website_type_id || profilesetup.error.membership_plan_id || profilesetup.error.type || profilesetup.error.domain || profilesetup.error.subdomain || profilesetup.error.currency_id || profilesetup.error.logo || profilesetup.error.support_email || profilesetup.error.support_contact_number || profilesetup.error.support_address || profilesetup.error.account_number || profilesetup.error.account_name || profilesetup.error.ifsc_code || profilesetup.error.bank_name || profilesetup.error.bank_branch || profilesetup.error.message) )
        {
            isError = false;
            if(document.body.getElementsByClassName("loadfixxer")[0] != null){
                document.body.getElementsByClassName("loadfixxer")[0]
                .setAttribute("style", "display:none;");
            }
            if(profilesetup.error.message != null){
                if(document.body.getElementsByClassName("message_login")[0] != null){
                    document.body
                    .getElementsByClassName("message_login")[0]
                    .innerHTML=profilesetup.error.message 
                }
                if(document.body.getElementsByClassName("message_login")[1] != null){
                    document.body
                    .getElementsByClassName("message_login")[1]
                    .innerHTML=profilesetup.error.message 
                }
                
                
            }else{
                formRefstepfirst.current.updateInputsWithError({
                    ...profilesetup.error
                });
            }
            disableButton();
        } else{
            if(profilesetup.success === true && profilesetup.data != null){
                //setClassicModal2(true)
                if(profilesetup.data.step != null && profilesetup.data.step == 4){
                    setClassicModal2(false);
                    window.localStorage.removeItem("settings_access_token");
                    window.localStorage.removeItem("access_token");
                    if(userData.Registration.domain_type === "subdomain"){
                        // history.push({
                        //     pathname:'http://' + subDomain + "." + finalDomainName
                        // })
                        window.open('https://' + subDomain + "." + finalDomainName+'/', "_self"); //to open new page
                    }else{
                        // history.push({
                        //     pathname:'http://' + chooseDomain
                        // })
                        window.open('https://' + chooseDomain+'/', "_self");
                    }  
                }else if(profilesetup.data.Registration != null && profilesetup.data.Registration.user_modules_data != null && profilesetup.data.Registration.user_modules_data.length > 0 && profilesetup.data.Registration.security_settings_data != null && profilesetup.data.Registration.security_settings_data.length > 0  && profilesetup.data.Registration.cms_theme_id != null && profilesetup.data.Registration.cms_theme_id > 0){
                    setUserModules(profilesetup.data.Registration.user_modules_data);
                    setThemeId(profilesetup.data.Registration.cms_theme_id);
                    var settings = JSON.parse(profilesetup.data.Registration.security_settings_data);
                    setUserSettings(settings);
                    isError = true;
                    stepchecker();
                    if(currentPosition >= 4){
                        nextStep(5);
                    }
                    setClassicModal2(true);
                }else if(profilesetup.data.Registration != null && profilesetup.data.Registration.security_settings_data != null && profilesetup.data.Registration.security_settings_data.length > 0  && profilesetup.data.Registration.cms_theme_id != null && profilesetup.data.Registration.cms_theme_id > 0 && profilesetup.data.Registration.default_banker_data != null && profilesetup.data.Registration.default_banker_data.length > 0){
                    setThemeId(profilesetup.data.Registration.cms_theme_id);
                    var settings = JSON.parse(profilesetup.data.Registration.security_settings_data);
                    setUserSettings(settings);
                    var bankerDetails = JSON.parse(profilesetup.data.Registration.default_banker_data);
                    setUserBankerDetails(bankerDetails);
                    isError = true;
                    stepchecker();
                    if(currentPosition >= 3){
                        nextStep(4);
                    }
                    setClassicModal2(false);
                }else if(profilesetup.data.Registration != null && profilesetup.data.Registration.security_settings_data != null && profilesetup.data.Registration.security_settings_data.length > 0  && profilesetup.data.Registration.cms_theme_id != null && profilesetup.data.Registration.cms_theme_id > 0){
                    setThemeId(profilesetup.data.Registration.cms_theme_id);
                    var settings = JSON.parse(profilesetup.data.Registration.security_settings_data);
                    setUserSettings(settings);
                    isError = true;
                    stepchecker();
                    if(currentPosition >= 2){
                        nextStep(3);
                    }
                }else if(profilesetup.data.Registration != null && profilesetup.data.Registration.security_settings_data != null && profilesetup.data.Registration.security_settings_data.length > 0){
                    var settings = JSON.parse(profilesetup.data.Registration.security_settings_data);
                    setUserSettings(settings);
                    isError = true;
                    stepchecker();
                    currentPosition = 2;
                    if(currentPosition >= 1){
                        nextStep(2);
                    }
                }else{
                    isError = true;
                    stepchecker();
                    if(currentPosition >= 1){
                        nextStep(1);
                    }
                }
                    
            }
        }
            
    }, [profilesetup]);
    function enableButtonPayment(){
        setIsFormValidPayment(true);
        document.body
        .getElementsByClassName("signupbtnpay")[0]
        .setAttribute("style", "opacity:1;");
    }
    function disableButtonPayment() {
        setIsFormValidPayment(false);
        document.body
        .getElementsByClassName("signupbtnpay")[0]
        .setAttribute("style", "opacity:0.5;");
    }
    function disableButton() {
        setIsFormValid(false);
        document.body
        .getElementsByClassName("signupbtn")[0]
        .setAttribute("style", "opacity:0.5;");
    }
    
    function enableButton() {
        setIsFormValid(true);
        document.body
        .getElementsByClassName("signupbtn")[0]
        .setAttribute("style", "opacity:1;");
    }
    if(userModules != false){
        userModules = userModules.split(",");
    }
    function handleSubmit(model) { 
        if(document.body.getElementsByClassName("message_login")[0] != null){
            document.body.getElementsByClassName("message_login")[0].innerHTML= "";
        }
        
        if(domainType === false){
            setDomainType("subdomain");
        }
        if(model.website_type_id != null){
            var selectedGames = "";
            if(model.website_type_id!=null){
                model.website_type_id.map((selval) => {
                    if(selval.value != null && selval.value > 0){
                        if(selectedGames != ""){
                            selectedGames = selectedGames+","+selval.value;
                        }else{
                            selectedGames = selval.value;
                        }
                    }
                    
                });
            }
            model.website_type_id = selectedGames;
        }
        /* if(model.membership_plan_id != null){
            if(document.getElementById("membership_plan_duration_id_" + model.membership_plan_id) != null){
                model.membership_plan_duration_id = document.getElementById("membership_plan_duration_id_" + model.membership_plan_id).value;
            }
        } */
        if(attachedImage.length > 0){
            model.logo = attachedImage;
        }
        
        document.body
                    .getElementsByClassName("message_login")[0]
                    .innerHTML= "";
        dispatch(authActions.submitProfilesetup(model,props,userData.Registration,finalDomainName));  
        //stepchecker();nextStep(1);
    }

    function ifscChange(event){
        
        if(event.target != null && event.target.value != null && event.target.value.length >= 11){
            ifscCode = event.target.value;
            fetchIFSCDetails();
        }else{
            ifscCode = event.target.value;
        }
        if(document.body.getElementsByClassName("message_login")[0] != null){
            document.body
            .getElementsByClassName("message_login")[0] 
            .innerHTML=""; 
        }
    }
    function fetchIFSCDetails(){
        if(ifscCode != null && ifscCode.length >= 11){
            if(document.body.getElementsByClassName("message_login")[0] != null){
                document.body
                .getElementsByClassName("message_login")[0]
                .innerHTML=""
            }
            const request = axios.post(Gurl.GETIFSCDETAILS , {
                token: window.localStorage.getItem('jwt_access_token'),
                ifsc_code: ifscCode
            });
            
            request.then((response) => {
                if(response.data.data != null){
                    var bankDetails = response.data.data;
                    if(bankDetails.branch != null){
                        setBranchName(bankDetails.branch);
                    }
                    if(bankDetails.bankname != null){
                        setBankName(bankDetails.bankname);
                    }
                }else{
                    if(document.body.getElementsByClassName("message_login")[0] != null){
                        document.body
                        .getElementsByClassName("message_login")[0] 
                        .innerHTML="Bank details not found as per IFSC. Please check IFSC."; 
                    }
                    setClassAdd(classes.msg = " errormsg" );
                }
            });
            request.catch((error) => {
                
            });
        }else{
            document.body
            .getElementsByClassName("message_login")[0] 
            .innerHTML="Please put correct IFSC Code."; 
            setClassAdd(classes.msg = " errormsg" );
        }
    }
    
    function handleSubmitTwo(model) { 
        //const util = require('util');
        //const myobj = util.inspect(model, {depth: null});
        if(document.body.getElementsByClassName("message_login")[0] != null){
            document.body.getElementsByClassName("message_login")[0].innerHTML= "";
        }
        var allData = Object.keys(model);
        var allValues = Object.values(model);
        var newModelData = {};
        
        for(var mx=0;mx < allData.length; mx++){
            var mykey = allData[mx];
            var myval = allValues[mx];
            if(myval != null && myval != undefined){
                myval = myval.toString().replace(/,,/g,',');
                myval = myval.replace(/,,/g,',');
                if(myval.charAt(0) === ","){
                    myval = myval.substr(1, myval.length);
                }
                if(myval.charAt(myval.length-1) === ","){
                    myval = myval.substr(0, myval.length-1);
                }
                var newVal = {};
                newVal[mykey] = myval;
                newModelData = {...newVal, ...newModelData}
            }
        }
        //alert(newModelData['main_data'])
        var finalModelData = {};
        var errorState = {state: false};
        if(newModelData != null){
            var keyData = Object.keys(newModelData);
            var valData = Object.values(newModelData);
            for(var my = 0;my < keyData.length; my++){
                if(keyData[my] === "main_data"){
                    var newVal = {};
                    newVal[keyData[my]] = valData[my];
                    finalModelData = {...newVal,...finalModelData}
                }else{
                    if(newModelData.main_data != null && newModelData.main_data.indexOf(keyData[my]) != -1 && valData[my] != null && valData[my].length > 0){
                        var newVal = {};
                        newVal[keyData[my]] = valData[my];
                        finalModelData = {...newVal,...finalModelData}
                    }else{
                        if(newModelData.main_data != null && newModelData.main_data.indexOf(keyData[my]) != -1 && (valData[my] === null || valData[my].length === 0)){
                            errorState = {state: true, code:keyData[my]};
                        }
                    }
                }
            }
        }
        
        if(errorState != null && errorState.state != null && errorState.state === false){
            document.body
                    .getElementsByClassName("message_login")[0]
                    .innerHTML= "";
            dispatch(authActions.submitProfileSettings(finalModelData,props,userData.Registration)); 
        }else{
            if(errorState != null && errorState.code != null && errorState.code > 0){
                document.body
                    .getElementsByClassName("message_login")[0]
                    .innerHTML= "Please check " + getFieldNameById(errorState.code);
            }
        }
        
        //dispatch(authActions.submitProfilesetup(model,props,userData.Registration));  
        //stepchecker();nextStep(1);
    }
    function handleSubmitBank(model){
        dispatch(authActions.submitBankSettings(model,props,userData.Registration)); 
    }
    function handleSubmitThree(model) { 
        if(themeId != false && themeId != null && themeId > 0){
            document.body
                    .getElementsByClassName("message_login")[0]
                    .innerHTML= "";
            dispatch(authActions.submitThemeSettings(model,props,userData.Registration)); 
        }else{
                document.body
                    .getElementsByClassName("message_login")[0]
                    .innerHTML= "Please select atleast one theme. ";
        }
        //dispatch(authActions.submitProfilesetup(model,props,userData.Registration));  
        //stepchecker();nextStep(1);
    }
    function handleSubmitPayment(model){
        if(document.body.getElementsByClassName("loadfixxer")[0] != null){
            document.body.getElementsByClassName("loadfixxer")[0]
            .setAttribute("style", "display:block;");
        }
        if(document.body.getElementsByClassName("message_login")[0] != null){
            document.body.getElementsByClassName("message_login")[0].innerHTML= "";
        }
        if(document.body.getElementsByClassName("message_login")[1] != null){
            document.body.getElementsByClassName("message_login")[1].innerHTML= "";
        }
        dispatch(authActions.submitPaymentSettings(model,props,userData.Registration)); 
        disableButtonPayment();

    }
    function handleSubmitFour(model){
        document.body
                    .getElementsByClassName("message_login")[0]
                    .innerHTML= "";
        if(document.body.getElementsByClassName("message_login")[1] != null){
                document.body
                .getElementsByClassName("message_login")[1]
                .innerHTML = "";
         }

        const util = require('util');
        const myobj = util.inspect(model, {depth: null});
        
        var allData = Object.keys(model);
        var allValues = Object.values(model);
        var newModelData = {};
        for(var mx=0;mx < allData.length; mx++){
            var mykey = allData[mx];
            var myval = allValues[mx];
            if(myval != null && myval != undefined){
                myval = myval.toString().replace(/,,/g,',');
                myval = myval.replace(/,,/g,',');
                if(myval.charAt(0) === ","){
                    myval = myval.substr(1, myval.length);
                }
                if(myval.charAt(myval.length-1) === ","){
                    myval = myval.substr(0, myval.length-1);
                }
                let modulesSelected = myval.split(",");
                var newStringFoModule = "";
                if(mykey === "main_data"){
                    for(var mdCh = 0; mdCh < modulesSelected.length; mdCh++){
                        if(modulesSelected[mdCh] != null && modulesSelected[mdCh] != ""){
                            if(document.getElementById("main_duration_data_" + modulesSelected[mdCh]) == undefined || document.getElementById("main_duration_data_" + modulesSelected[mdCh]).value == null){
                                if(newStringFoModule === ""){
                                    newStringFoModule = modulesSelected[mdCh] + ":0";
                                }else{
                                    newStringFoModule = newStringFoModule + "," + modulesSelected[mdCh] + ":0";
                                }
                            }else if(newStringFoModule === ""){
                                newStringFoModule = modulesSelected[mdCh] + ":" + document.getElementById("main_duration_data_" + modulesSelected[mdCh]).value;
                            }else{
                                newStringFoModule = newStringFoModule + "," + modulesSelected[mdCh] + ":" + document.getElementById("main_duration_data_" + modulesSelected[mdCh]).value;
                            }
                        }
                    }
                }
                var newVal = {};
                if(mykey === "main_data"){
                    newVal[mykey] = newStringFoModule;
                }else{
                    newVal[mykey] = myval;
                }
                
                newModelData = {...newVal, ...newModelData}
            }
        }
        //setClassicModal2(true)
        if(newModelData.main_data != null && newModelData.main_data.length >= 0){
            dispatch(authActions.submitModuleSettings(newModelData,props,userData.Registration)); 
        }else{
                document.body
                    .getElementsByClassName("message_login")[0]
                    .innerHTML= "Please select atleast one module. ";
        }
    }
    function getFieldNameById(postid){
        var nameOfField = "";
        if(settingConfig != null && Array.isArray(settingConfig)){
            settingConfig.map((setttingData) => {
                if(setttingData.Setting.id == postid){
                    nameOfField = setttingData.Setting.setting_name;
                }
            });
        }
        return nameOfField;
    }

    function getModuleLists(gametype){
        var myData = {
            token: window.localStorage.getItem('jwt_access_token'),
            user_id: userData.web_id
        };
        myData = Object.entries(myData)
            .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
            .join('&');


        const request = axios.post(Gurl.WLMODULE_LIST, myData,{header:{"Content-Type": "application/x-www-form-urlencoded"}});

        request.catch((error) => {
            setModuleLists("NOVALUE");
        });
        
        request.then((response) => { 
            if(response.data.data != null && response.data.status != null && response.data.status == 1){ 
                setModuleLists(response.data.data);
            }
        });
    }

    function getthemedata(gametype){
        var myData = {
            token: window.localStorage.getItem('jwt_access_token'),
            website_type_id: gametype
        };
        myData = Object.entries(myData)
            .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
            .join('&');


        const request = axios.post(Gurl.WLTHEME_LIST, myData,{header:{"Content-Type": "application/x-www-form-urlencoded"}});

        request.catch((error) => {
            setThemeTypes("NOVALUE");
        });
        
        request.then((response) => { 
            if(response.data.data != null && response.data.status != null && response.data.status == 1){ 
                setThemeTypes(response.data.data);
            }
        });
    }   
    function getplansdata(gametype){
        var myData = {
            token: window.localStorage.getItem('jwt_access_token'),
            website_type_id: gametype,
            user_id: userData.web_id
        };
        axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded";
        myData = Object.entries(myData)
            .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
            .join('&');


        const request = axios.post(Gurl.WLMEMBER_SHIP_PLAN, myData,{header:{"Content-Type": "application/x-www-form-urlencoded"}});

        request.catch((error) => {
            setPlanType("NOVALUE");
        });
        
        request.then((response) => { 
            
            if(response.data.data != null){ 
                var myOutPut = ( 
                    response.data.data.map((jsondata) => (                           
                        
                        <GridItem xs={12} sm={12} md={4} lg={4}>                              
                        <div className={classes.planbox}> 
                            <div onClick={() =>{plan(jsondata.MembershipPlan.id);stepchecker();planNameIndex(jsondata.MembershipPlan.plan_name);}}> 
                                {(() => {
                                    if(jsondata.MembershipPlan.id === planId){
                                        return (<div className={classes.planboxchecked + " planboxchecked"}></div>)
                                    }else{
                                        return (<div className={classes.planboxcheck + " planboxcheck"}></div>) 
                                    }
                                })()}
                                
                                <h4>{parser.parseFromString(`<!doctype html><body>${jsondata.MembershipPlan.plan_name}`, 'text/html').body.textContent} </h4>
                                <h5>Setup Cost : {(currentCurrency === "NO") ? "INR" : currentCurrency}  {jsondata.MembershipPlan.setup_cost}</h5>
                                <h5>Monthly Cost : {(currentCurrency === "NO") ? "INR" : currentCurrency} {jsondata.MembershipPlan.recurring_cost}</h5>
                                <div>{jsondata.MembershipPlan.details}</div>
                                <br/>
                            </div>
                            {/* <SelectFormsy className={classes.selectboxx + " selectbox"}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                type="text"
                                name={"membership_plan_duration_id_" + jsondata.MembershipPlan.id}
                                label="Plan Duration" 
                                value={((jsondata.MembershipPlanDuration != null && (Array.isArray(jsondata.MembershipPlanDuration))) ? ((userData.Registration.membership_plan_duration_id != null && jsondata.MembershipPlan.id == planId) ? userData.Registration.membership_plan_duration_id : jsondata.MembershipPlanDuration[0].id) : jsondata.MembershipPlanDuration[0].id)}
                                variant="outlined"
                                required
                            >
                                {(() => {
                                    if(jsondata.MembershipPlanDuration != null && Array.isArray(jsondata.MembershipPlanDuration)){
                                        var output = jsondata.MembershipPlanDuration.map((planData) => {
                                            if(userData.Registration.membership_plan_duration_id != null && planData.id === userData.Registration.membership_plan_duration_id){
                                                return (<MenuItem value={planData.id} selected >{planData.display_name}</MenuItem>)
                                            }else{ 
                                                return (<MenuItem value={planData.id} >{planData.display_name}</MenuItem>) 
                                            }
                                        })
                                        return output;
                                    }
                                })()}   
                                </SelectFormsy>
                             */}
                        </div>
                    </GridItem>
                        
                    ))
                );
                setPlanType(myOutPut);
            };
        });
    } 

    function planHandler(event){
        const util = require('util');
        const myobj = util.inspect(event, {depth: null});
        var selectedGames = "";
        if(event!=null){
            event.map((selval) => {
                if(selval.value != null && selval.value > 0){
                    if(selectedGames != ""){
                        selectedGames = selectedGames+","+selval.value;
                    }else{
                        selectedGames = selval.value;
                    }
                }
                
            });
        }
        
        if(event!=null && selectedGames.length > 0){
            setTypeGameid(event);  
            stepchecker();
            if(userConfig === "NOVALUE"){
                getuserconfig("Processing");
            }else{
                if(userConfig != null && userConfig.main_domain != null && userConfig.main_domain.length > 0){
                    setFinalDomainName(userConfig.main_domain);
                }
            }
            getplansdata(selectedGames);
        } 
    }
    function modulePriceHandler(event, position, ModuleRecurrngPrice, userModuleId){
        if(event != null && event.target.value!=null && event.target.value>0){
            if(document.getElementById("recurrning_cost_" + position) != null){
                for(var runPos=0;runPos < ModuleRecurrngPrice.length; runPos++){
                    let jsonOutput = ModuleRecurrngPrice[runPos];
                    if(jsonOutput.recurring_price_id != null && jsonOutput.recurring_price_id == event.target.value){
                        document.getElementById("recurrning_cost_" + position).innerHTML=jsonOutput.recurring_cost;
                        if(moduleDuration != false){
                            let newModuleList = false;
                            var isExist = false;
                            for(var exPos=0;exPos < moduleDuration.length; exPos++){
                                if(moduleDuration[exPos].module_id == userModuleId){
                                    if(newModuleList != false){
                                        newModuleList = [{module_id: userModuleId, position:event.target.value, price: jsonOutput.recurring_cost}, ...newModuleList]
                                    }else{
                                        newModuleList = [{module_id: userModuleId, position:event.target.value, price: jsonOutput.recurring_cost}]
                                    }
                                    isExist = true;
                                }else{
                                    if(newModuleList != false){
                                        newModuleList = [{module_id: moduleDuration[exPos].module_id, position:moduleDuration[exPos].position, price: moduleDuration[exPos].price}, ...newModuleList]
                                    }else{
                                        newModuleList = [{module_id: moduleDuration[exPos].module_id, position:moduleDuration[exPos].position, price: moduleDuration[exPos].price}]
                                    }
                                }
                            }
                            if(isExist == false){
                                if(newModuleList != false){
                                    newModuleList = [{module_id: userModuleId, position:event.target.value, price: jsonOutput.recurring_cost}, ...newModuleList]
                                }else{
                                    newModuleList = [{module_id: userModuleId, position:event.target.value, price: jsonOutput.recurring_cost}]
                                }
                            }
                            moduleDuration = newModuleList;
                            //setModuleDuration(newModuleList);
                            startTotalOfAmount("CLICK");
                        }else{
                            moduleDuration = [{module_id: userModuleId, position:event.target.value, price: jsonOutput.recurring_cost}];
                            //setModuleDuration([{module_id: userModuleId, position:event.target.value, price: jsonOutput.recurring_cost}])
                            startTotalOfAmount("CLICK");
                        }
                    }
                }
            }
        }else {
            alert("NO Data");
        }
    }

    if(planType === "NOVALUE" && userData != null && userData.Registration != null && currentPosition === 0){
        setPlanType("Processing");
        if(userConfig === "NOVALUE"){
            getuserconfig("Processing");
        }else{
            if(userConfig != null && userConfig.main_domain != null && userConfig.main_domain.length > 0){
                setFinalDomainName(userConfig.main_domain);
            }
        }
        getplansdata(userData.Registration.website_type_id); 
        
    } 
    if(themeTypes === "NOVALUE" && userData != null && userData.Registration != null && currentPosition === 2){
        setThemeTypes("Processing");
        getthemedata(userData.Registration.website_type_id); 
    } 
    if(moduleLists === "NOVALUE" && userData != null && userData.Registration != null && currentPosition === 3){
        setModuleLists("Processing");
        getModuleLists(userData.Registration.website_type_id); 
    } 
    var isObject = function(a) {
        return (!!a) && (a.constructor === Object);
    };
       
    function getcurrencydata(){
        const request = axios.post(Gurl.CURRENCIES, JSON.stringify({
            token: window.localStorage.getItem('jwt_access_token')
        }),{header:{"Content-Type": "application/x-www-form-urlencoded"}});
    
        request.catch((error) => {
            setCurrencyType("NOVALUE");
        }); 
        request.then((response) => { 
            if(response.data.data != null){ 
                var myOutPut = (
                    response.data.data.map((jsondata) => (
                        (() => {
                            if(userData != null && userData.Registration != null && jsondata.Currency.id === userData.Registration.currency_id){
                                return (<MenuItem value={jsondata.Currency.id} selected >{jsondata.Currency.code} ({jsondata.Currency.name})</MenuItem>)
                            }else{ 
                                return (<MenuItem value={jsondata.Currency.id} >{jsondata.Currency.code} ({jsondata.Currency.name})</MenuItem>) 
                            }
                        })()
                        
                    ))
                );
                setCurrencyType(myOutPut);
                if(countryCode === "NOVALUE"){
                    setCountryCode("Processing");
                    getcountrydata(response.data.data);
                }
            };
        });  
    }
    function getSecuritySettings(){
        var settingData = false;
        if(window.localStorage.getItem("settings_access_token") != null){
            const decodedData = jwtDecode(window.localStorage.getItem("settings_access_token"));
            const currentTime = Date.now() / 1000;
            let tokenExpired = false;
            if ( decodedData.exp < currentTime ){
                tokenExpired = true;
            }
            if(tokenExpired === true && navigator != null && navigator.onLine != null && navigator.onLine !== true){
                tokenExpired = false;
            }
            if (tokenExpired === false){
                if(decodedData != null && isObject(decodedData)){
                    settingData = true;
                    setSettingConfig(decodedData.data);
                }  
            }
        }
        if(settingData === false){
            settingData = true;
            var data = {token: "noneeded"};
            const datatwo = Object.entries(data)
            .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
            .join('&'); 
            const request = axios.post(Gurl.WLSECURITYSETTING, datatwo);
        
            request.catch((error) => {
                settingData = false;
            }); 
            request.then((response) => { 
                if(response.data.data != null){
                    
                    if(response.data != null && Array.isArray(response.data.data)){
                        setDataInStorage(response.data, "settings_access_token");
                        setSettingConfig(response.data.data);
                    }else{
                        settingData = false;
                    }
                }else{
                    settingData = false;
                }
            })
        }
    }
    function setDataInStorage(data, access_token_name){
        var jwtConfig = {
            "secret"   : "324934dsfj786ykjhgiyyi4439wri",
            "expiresIn": "2 hours" // A numeric value is interpreted as a seconds count. If you use a string be sure you provide the time units (days, hours, etc)
        };
        const access_token = jwt.sign(data, jwtConfig.secret, {expiresIn: jwtConfig.expiresIn});
        window.localStorage.setItem(access_token_name, access_token);
    }
    if(currencyType == "0"){
        setCurrencyType("Processing");
        getcurrencydata(); 
    }

    function getgamedata(){

        const request = axios.post(Gurl.WLGAME_TYPE, JSON.stringify({
            token: window.localStorage.getItem('jwt_access_token')
        }),{header:{"Content-Type": "application/x-www-form-urlencoded"}});
    
        request.catch((error) => {
            setGameType("NOVALUE");
        }); 
        request.then((response) => { 
            if(response.data.data != null){ 
                var myOutPut = (
                    response.data.data.map((jsondata) => (
                        {
                            value: jsondata.id,
                            label: jsondata.type_name
                        }
                        /*(() => {
                            if(userData != null && userData.Registration != null && jsondata.id === userData.Registration.website_type_id){
                                return (
                                    <MenuItem value={jsondata.id} selected >
                                        <CheckboxFormsy 
                                            id={"main_data["+jsondata.id+"]"} 
                                            name={"main_data["+jsondata.id+"]"} 
                                            themeColors={props.themeColors} 
                                            value={jsondata.id} 
                                            data={jsondata.id} 
                                            checked={true}
                                        />
                                        {jsondata.type_name}
                                    </MenuItem>
                                )
                            }else{
                                return (
                                    <MenuItem value={jsondata.id} >
                                        <CheckboxFormsy 
                                            id={"main_data["+jsondata.id+"]"} 
                                            name={"main_data["+jsondata.id+"]"} 
                                            themeColors={props.themeColors}
                                            data={jsondata.id} 
                                            checked={false}
                                        />
                                        {jsondata.type_name}
                                    </MenuItem>
                                ) 
                            }
                        })()*/
                        
                    ))
                );
                if(!isCheckObject(typeGameid) && !Array.isArray(typeGameid) && typeGameid != null && typeGameid.length > 0){
                    var changeToArray = typeGameid.split(",");
                    let finalSelected = [];
                        myOutPut.map((jsondata) => {
                            if(changeToArray.indexOf(jsondata.value) != -1) {
                                finalSelected.push(jsondata);
                            }
                        });
                    setTypeGameid(finalSelected);
                }
                if(typeGameid === 0){
                    let finalSelected = [];
                    var firstVal = 0;
                        myOutPut.map((jsondata) => {
                            if(firstVal === 0) {
                                finalSelected.push(jsondata);
                                firstVal++;
                            }
                        });
                    setTypeGameid(finalSelected);
                }
                setGameType(myOutPut);
            };
        });  
    }
    
    if(gameType === "NOVALUE"){
        setGameType("Processing");
        getgamedata();
    } 

    function setSelectedModules(isCheck,ModuleId,typeOf, curPos){
        if(typeOf === "CLICK"){
            setIsLoadCompleted(true);
        }
        if(isCheck != false){
            var index = SelectedModule.indexOf(ModuleId);
            if(index == -1){
                if(typeOf == "CLICK"){
                    SelectedModule.push(ModuleId);
                }else{
                    SelectedModule.push(ModuleId);
                }
            }
        }else{
            var index = SelectedModule.indexOf(ModuleId);
            if(index >= 0){
                SelectedModule.splice(index, 1);
            }
            
        }
        if(typeOf == "CLICK"){
            startTotalOfAmount(typeOf);
        }
    }
    function startTotalOfAmount(typeOf){
        var setupCost = 0;
        var recurringCost = 0;
        if(moduleLists != null && Array.isArray(moduleLists)){
            moduleLists.map((moduleData) => {
                if(SelectedModule.indexOf(moduleData.user_module_id) >= 0){
                    setupCost += parseFloat(moduleData.setup_cost);
                    var isRecur = false;
                    
                    for(var mdRun=0; mdRun < moduleDuration.length; mdRun++){
                        if(moduleDuration[mdRun].module_id == moduleData.user_module_id){
                            recurringCost += parseFloat(moduleDuration[mdRun].price);
                            isRecur = true;
                        }
                    }
                    if(isRecur == false && moduleData.ModuleRecurrngPrice.length > 0){
                        var selectedDuration = moduleData.ModuleRecurrngPrice[0].recurring_price_id;
                        if(moduleData != false && moduleData != null){
                            let selModuleSplit = userModules.toString().split(",");
                            for(var nTM=0;nTM<selModuleSplit.length;nTM++){
                                var onlyModuleSplit = selModuleSplit[nTM].split(":");
                                if(onlyModuleSplit[0] === moduleData.user_module_id){
                                    selectedDuration = onlyModuleSplit[1];
                                }
                            }
                        }
                        var selectedPrice = moduleData.ModuleRecurrngPrice[0].recurring_cost;
                        for(var runPos=0;runPos < moduleData.ModuleRecurrngPrice.length; runPos++){
                            let jsonOutput = moduleData.ModuleRecurrngPrice[runPos];
                            if(jsonOutput.recurring_price_id != null && jsonOutput.recurring_price_id == selectedDuration){
                                selectedPrice=jsonOutput.recurring_cost;
                            }
                        }
                        recurringCost += parseFloat(selectedPrice);
                    }
                }
            });
        }
        //alert("SETUP:" + setupCost + ":RECURRING:" + recurringCost + ":SELECTED:" + SelectedModule.toString());
        if(typeOf === "AUTO"){
            /*sumOfSetupCost = parseFloat(setupCost).toFixed(2);
            sumOfRecurringCost = parseFloat(recurringCost).toFixed(2);
            sumOfTotalCost = parseFloat(sumOfSetupCost) + parseFloat(sumOfRecurringCost);*/
        }else{
            setSumOfSetupCost(parseFloat(setupCost).toFixed(2));
            setSumOfRecurringCost(parseFloat(recurringCost).toFixed(2));
            setSumOfTotalCost(parseFloat(setupCost) + parseFloat(recurringCost));
        }
        
    }

    function settingChange(){
        setChangeModuels("CHANGE");
    }
    
    var clickedOnTabs = function(event){
        if(event === 0){
            setCurrentPosition(0);
            currentPosition = 0;
            setPlanType("NOVALUE")
            setCurrencyType(0);
            setGameType("NOVALUE");
            setIsDefaultValue(false);
            nextStep(0);
        }else if(event === 1){
            nextStep(1);
        }else if(event === 2){
            setThemeTypes("Processing");
            getthemedata(userData.Registration.website_type_id);
        }else if(event === 3){
            setModuleLists("Processing");
            getModuleLists(userData.Registration.website_type_id); 
        }
    }
    function openImg(themeIndex) {
        setThemeId(themeIndex);
        document.body 
            .getElementsByClassName("imgpopupbox" + themeIndex)[0]
            .setAttribute("style", "display:block;"); 
    }
    function openImgClose(themeIndex) {
        setThemeId(themeIndex);
        document.body 
            .getElementsByClassName("imgpopupbox" + themeIndex)[0]
            .setAttribute("style", "display:none;"); 
    }

    return (
         <div className={classes.profilesetup + " smdesigntwo"}>
             
           
             

             <div className={classes.container}>
             
              <div style={{textAlign:'center', fontSize:16, paddingBottom:10, color:'rgb(98, 98, 98)', fontFamily:'ubuntu'}}>Welcome <strong style={{fontWeight:'bold', color:'#000'}}>{(userData != null && userData.Registration != null) ? userData.Registration.name : ""}</strong>, Configure your Platform <strong style={{fontWeight:'bold', color:'#000'}}>{(userData != null && userData.Registration != null) ? userData.Registration.domain : ""}</strong></div>

             
             <div className={classes.formdix}>
            <GridContainer>
            <GridItem className={classes.formdixdd} xs={12} sm={12} md={12}>
              <CustomTabs
                plainTabs
                border="round"
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                currentPosition={currentPosition}
                clickEvent={clickedOnTabs}
                tabs={[
                  {
                    tabName: "1",
                    tabContent: (
                    <Formsy   
                         onValidSubmit={handleSubmit}
                        onValid={enableButton}
                        onInvalid={disableButton}
                        ref={formRefstepfirst}
                        autoComplete={"off"}
                        className={classes.form + " flex flex-col justify-center w-full"}
                    > 
                    <div className={classes.errormsg + " message_login"} id="message_login"></div>
                    <div className={classes.planboxx}>
                    
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <div className={classes.chipselct}> 
                                <FuseChipSelectFormsy className={classes.chipselctwrap}
                                textFieldProps={{
                                    label          : 'Games:',
                                    InputLabelProps: {
                                        shrink: true
                                    },
                                    variant        : 'standard'
                                }}
                                type="text"
                                name="website_type_id"
                                placeholder="Type of Game" 
                                value={typeGameid}
                                onChange={planHandler}
                                variant="outlined"
                                validations={{minLength: 1}}
                                validationErrors={{
                                    minLength: 'You need to select at least one'
                                }}
                                options={gameType}
                                isMulti
                                required
                            />
                                
                                </div>
                            </GridItem>
                        </GridContainer>
                        <h4 className={classes.headingtag}>Select Plan</h4>
                        <GridContainer>
                        {planType}
                        </GridContainer>       
                        
                    </div>
                    
                    <div className={classes.domainboxx}>
                        <h4 className={classes.headingtag}><a onClick={planb} href="#" alt=""> <i class="fa fa-arrow-left"></i></a> {parser.parseFromString(`<!doctype html><body>${planName}`, 'text/html').body.textContent} </h4>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>  
                            <div onClick={subdomain}  className={classes.radiobtn + ((domainType == "subdomain") ? " radiobtn" : " ")}> On Subdomain Parking (instant)</div>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12} style={{padding:'0'}}>
                                    <TextFieldFormsy
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        type="text"
                                        name="subdomain"
                                        label="Enter Subdomain"
                                        onChange={subdomainvaluetext}
                                        validations={(domainType == "subdomain") ? {
                                            minLength: 4, 
                                            isDomainCase: function(value){
                                                if(new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/).test(value.subdomain + ".isoftgaming.com")){
                                                    return true;
                                                }else{
                                                    return false;
                                                }
                                            },
                                        } : {}}
                                        className={((domainType == "subdomain") ? classes.domaininput : classes.maindomaininput) + " subdomaincl"}
                                        validationErrors={{
                                            minLength: 'Min character length is 4',  
                                            isDomainCase: "Domain validation"
                                        }} 
                                        value={(subDomain != 0) ? subDomain : ""}
                                        variant="outlined"
                                        behindContainer={<span className={classes.domaintext}>.{finalDomainName}</span>}
                                    />
                                 </GridItem> 
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}> 
                            <div onClick={maindomain} className={classes.radiobtn2 + ((domainType == "domain") ? " radiobtn" : " ")}> Add Your Own Domain</div>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12} style={{padding:'0'}}>
                                    <TextFieldFormsy
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        className={((domainType == "domain") ? classes.domaininput : classes.maindomaininput) + " domaincl"}
                                        type="text"   
                                        name="domain"
                                        id="domain"
                                        value={(chooseDomain != 0) ? chooseDomain : ""}
                                        label="Enter Domain"
                                        onChange={domainvaluetext} 
                                        validations={(domainType == "domain") ? {
                                            minLength: 6, 
                                            isDomainCase: function(value){
                                                if(new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/).test(value.domain)){
                                                    return true;
                                                }else{
                                                    return false;
                                                }
                                            },
                                        } : {}}
                                        validationErrors={{
                                            minLength: 'Min character length is 6',  
                                            isDomainCase: "Domain validation"
                                        }} 
                                        variant="outlined"
                                        
                                    />
                                </GridItem> 
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                        <SelectFormsy className={classes.selectboxx + " selectbox"}
                            formControlProps={{
                                fullWidth: true
                            }}
                            type="text"
                            name="currency_id"
                            label="Currency Type"
                            variant="outlined"
                            value={currencyId}
                            onChange={setCurrencyIndex} 
                            required
                        >
                            <MenuItem value={"0"}>Currency Type</MenuItem>
                            {currencyType}
                            </SelectFormsy>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextFieldFormsy
                                formControlProps={{
                                    fullWidth: true
                                }}
                                type="text"
                                name="support_email"
                                label="Support Email"
                                value={supportEmail}
                                onChange={setEmailIndex}
                                validations={{
                                    isEmail: true
                                }}
                                validationErrors={{
                                    isEmail: 'Please enter a valid email'
                                }}
                                variant="outlined"
                                required
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextFieldFormsy
                                formControlProps={{
                                    fullWidth: true
                                }}
                                type="number"
                                name="support_contact_number"
                                label="Support Number"
                                value={supportContactNumber}
                                onChange={setSupportNumberIndex}
                                validations={{
                                    minLength: 6, 
                                }}
                                validationErrors={{
                                    minLength: 'Min character length is 6',  
                                    
                                }} 
                                variant="outlined"
                                required
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextFieldFormsy
                                formControlProps={{
                                    fullWidth: true
                                }}
                                type="text"
                                name="support_address"
                                label="Address"
                                value={supportAddress}
                                onChange={setAddressIndex}
                                validations={{
                                    minLength: 6, 
                                }}
                                validationErrors={{
                                    minLength: 'Min character length is 6',  
                                    
                                }} 
                                variant="outlined"
                                required
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                             <label>Upload Company Logo</label>
                            <ImgUpload 
                                formControlProps={{
                                    fullWidth: true
                                }}
                                name="logo"
                                imagePreviewUrl={(attachedImage != null && attachedImage.length > 0) ? attachedImage: ""}
                                onClick={setUploadLogoid} 
                                onReset={resetImage}
                                resetBack = {setResetImage} 
                                themeColors={props.themeColors} 
                                required
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                                <Button simple color="primary" className={classes.signinmodalbtn +" signupbtn"} type="submit" size="lg" disabled={!isFormValid}>
                                    Next
                                </Button> 
                        </GridItem>
                    </GridContainer>
                    </div>
                    <TextFieldFormsy type="hidden" className={classes.hiddeninput +" hidden"} id="membership_plan_id" name="membership_plan_id" value={planId}></TextFieldFormsy>
                    <TextFieldFormsy type="hidden" className={classes.hiddeninput} id="domain_type" name="domain_type" value={domainType}></TextFieldFormsy>
                    <TextFieldFormsy type="hidden" className={classes.hiddeninput} id="final_sub_domain" name="final_sub_domain" value={finalDomainName}></TextFieldFormsy>
                    </Formsy>
                    )
                  },
                  {
                    tabName: "2",
                    tabContent: (
                    <Formsy   
                        onValidSubmit={handleSubmitTwo}
                        ref={formRefstepSecond}
                        autoComplete={"off"}
                        className={classes.form + " flex flex-col justify-center w-full"}
                    > 
                        <div className={classes.securitysetting}>
                        <h4 className={classes.headingtag}>Security Setting</h4>
                        <div className={classes.errormsg + " message_login"} id="message_login"></div>
                        <GridContainer>
                            {(() => {
                                if(settingConfig != null && Array.isArray(settingConfig)){
                                    var runArr = -1;
                                    var allConfig = settingConfig.map((setttingData) => {
                                                runArr = runArr + 1;
                                                var disabledOn = false;
                                                if(setttingData.Setting.is_editable != true) { 
                                                    disabledOn = true;
                                                }
                                                return (
                                                <GridItem xs={12} sm={12} md={4}>
                                                    <div className={classes.yesnofild}> 
                                                        <div className={classes.swither + " " + classes.checkbti} >
                                                            <CheckboxFormsy 
                                                                id={"main_data["+runArr+"]"} 
                                                                name={"main_data["+runArr+"]"} 
                                                                themeColors={props.themeColors} 
                                                                value={(((setttingData.Setting.default_status == 1 && userSettings === false) || ((JSON.parse(userSettings).main_data != null && JSON.parse(userSettings).main_data.indexOf(setttingData.Setting.id) >= 0))) ? setttingData.Setting.id : "")} 
                                                                data={setttingData.Setting.id} 
                                                                checked={(((setttingData.Setting.default_status == 1 && userSettings === false) || ((JSON.parse(userSettings).main_data != null && JSON.parse(userSettings).main_data.indexOf(setttingData.Setting.id) >= 0))) ? setttingData.Setting.id : "")}  
                                                                onClick={(isChecked) => {if(setttingData.Setting.data_json != null && setttingData.Setting.data_json.length > 0 && isChecked === true){openKYCModule(setttingData.Setting.id)}}} 
                                                                disabled={disabledOn} 
                                                            />
                                                        </div> 
                                                        <div className={classes.checkbtitext} >{setttingData.Setting.setting_name}</div>
                                                    </div>
                                                    {(() => {
                                                        if(setttingData.Setting.data_json != null && setttingData.Setting.data_json.length > 0){
                                                        return  (<Dialog
                                                                classes={{
                                                                    root: classes.center,
                                                                    paper: classes.modal
                                                                }}
                                                                open={((classicModal === setttingData.Setting.id) ? true : false)}
                                                                TransitionComponent={Transition}
                                                                keepMounted
                                                                onClose={() => setClassicModal(false)}
                                                                aria-labelledby="classic-modal-slide-title"
                                                                aria-describedby="classic-modal-slide-description"
                                                            >
                                                                <DialogTitle
                                                                    id="classic-modal-slide-title"
                                                                    disableTypography
                                                                    className={classes.modalHeader}
                                                                    
                                                                >
                                                                    <b>{setttingData.Setting.setting_name}</b>
                                                                    <IconButton
                                                                        className={classes.modalCloseButton}
                                                                        key="close"
                                                                        aria-label="Close"
                                                                        color="inherit"
                                                                        onClick={() => setClassicModal(false)}
                                                                    >
                                                                        <Close className={classes.modalClose} />
                                                                    </IconButton>
                                                                    
                                                            </DialogTitle>
                                                                <DialogContent
                                                                    id="classic-modal-slide-description"
                                                                    className={classes.modalBody}
                                                                > 
                                                                    {(() => {
                                                                        var subDataJson = setttingData.Setting.data_json.replace(/,,/g,',');
                                                                        var mapData = JSON.parse(subDataJson);
                                                                        var runIn = -1;
                                                                        var subConfig = mapData.map((innerData) => {
                                                                            var disabled = false;
                                                                            if(innerData.is_editable != "1") { 
                                                                                disabled = true;
                                                                            }
                                                                            runIn = runIn + 1;
                                                                            return (<div className={classes.yesnofild}> 
                                                                                <div className={classes.swither + " " + classes.checkbtii} >
                                                                                <CheckboxFormsy id={setttingData.Setting.id+"["+ runIn +"]"} name={setttingData.Setting.id+"["+ runIn +"]"}  value={(((userSettings == null || userSettings.length == 0 || JSON.parse(userSettings)[setttingData.Setting.id] == null)  && innerData.default_status == 1) || (userSettings != null && userSettings.length > 0 && (JSON.parse(userSettings)[setttingData.Setting.id].indexOf(innerData.id) >= 0)) ? innerData.id : "")} data={innerData.id}  themeColors={props.themeColors} checked={(((userSettings == null || userSettings.length == 0 || JSON.parse(userSettings)[setttingData.Setting.id] == null)  && innerData.default_status == 1) || (userSettings != null && userSettings.length > 0 && (JSON.parse(userSettings)[setttingData.Setting.id].indexOf(innerData.id) >= 0)) ? innerData.id : "")}  disabled={disabled} />
                                                                                </div> 
                                                                                <div className={classes.checkbtitextt} >{innerData.setting_name}</div><br/>
                                                                            </div>)
                                                                        });
                                                                        return (subConfig);
                                                                    })()}  
                                                                </DialogContent>
                                                            </Dialog>)
                                                        }
                                                    })()}
                                                </GridItem> 
                                            )
                                            
                                        });
                                    return (allConfig);
                                }else{
                                    return "";
                                }
                            })()}</GridContainer><GridContainer style={{paddingTop:10}}>
                            <GridItem xs={6} sm={2} md={2}>
                                <Button simple color="primary" style={{float:'left'}} className={classes.signinmodalbtn2 } type="button" onClick={()=>{setCurrentPosition(0);setSelectedTab(0); clickedOnTabs(0);}} size="lg" >
                                    Back
                                </Button> 
                            </GridItem>
                            <GridItem xs={6} sm={10} md={10}>
                                <Button simple color="primary" className={classes.signinmodalbtn +" signupbtn"} type="submit" size="lg">
                                    Next
                                </Button> 
                            </GridItem>
                        </GridContainer>
                    </div>
                    </Formsy>
                    )
                  },
                  {
                    tabName: "3",
                    tabContent: (
                        <Formsy   
                        onValidSubmit={handleSubmitThree}
                        ref={formRefstepThird}
                        autoComplete={"off"}
                        className={classes.form + " flex flex-col justify-center w-full"}
                    > 
                        <div className={classes.choosedesign}>
                        <h4 className={classes.headingtag}>Choose Website Layout</h4>
                        <div className={classes.errormsg + " message_login"} id="message_login"></div>
                        <GridContainer>
                            {(() => {
                                    if(themeTypes != null && Array.isArray(themeTypes)){
                                        var allThemes = themeTypes.map((themeData) => {
                                        //alert(JSON.stringify(themeData));
                                            return (
                                                <GridItem xs={12} sm={6} md={4} onClick={() =>{design(themeData.CmsTheme.id);stepchecker()}}>
                                                    <div  className={classes.planbox}>
                                                        <div onClick={() =>{design(themeData.CmsTheme.id);stepchecker()}} className={((themeData.CmsTheme.id === themeId) ? classes.planboxchecked + " planboxchecked" : classes.planboxcheck + " planboxcheck")}></div> <img src={themeData.CmsTheme.icon} width="300" height="180"  className={classes.designimg} alt="" />
                                                        <br/>
                                                        <Button simple color="primary" className={classes.signinmodalbtn + " " + classes.centerbtnb} type="button" size="lg" onClick={() =>{openImg(themeData.CmsTheme.id);stepchecker()}}  >
                                                            Preview
                                                        </Button>
                                                        <div className={classes.imgpopupbox + " imgpopupbox" + themeData.CmsTheme.id}>
                                                            <img src={themeData.CmsTheme.icon} height="100%" alt="" />
                                                            <Button 
                                                            simple 
                                                            color="primary"  
                                                            type="button" 
                                                            size="lg" 
                                                            className={classes.imgpopupboxclose}
                                                            onClick={() =>{openImgClose(themeData.CmsTheme.id);stepchecker()}}   >
                                                                <i className={"fa fa-times"}></i>
                                                            </Button>
                                                        </div>
                                                        <p style={{color:'#000', position:'absolute', textTransform: 'capitalize', zIndex:'999', top:'23px', right:'10px', background:'#d3ad31', padding:'3px 10px', borderRadius:'10px 0 0 10px'}}>{themeData.CmsTheme.theme_display_name}</p> 
                                                    </div>
                                                </GridItem>
                                            )
                                        })
                                        return allThemes;
                                    }
                                }
                            )()}
                            </GridContainer><GridContainer style={{paddingTop:10}}>
                            <GridItem xs={6} sm={2} md={2}>
                                <Button simple color="primary" style={{float:'left'}} className={classes.signinmodalbtn2 } type="button" onClick={()=>{setCurrentPosition(1);setSelectedTab(1); clickedOnTabs(1);}} size="lg" >
                                    Back
                                </Button> 
                            </GridItem>
                            <GridItem xs={6} sm={10} md={10}>
                            <TextFieldFormsy type="hidden" className={classes.hiddeninput +" hidden"} id="cms_theme_id" name="cms_theme_id" value={themeId}></TextFieldFormsy>
                                    <Button simple color="primary"  className={classes.signinmodalbtn +" signupbtn"} type="submit" size="lg" >
                                        Next
                                    </Button> 
                            </GridItem>
                        </GridContainer>
                        </div>
                        </Formsy>
                    )
                  },
                  {
                    tabName: "4",
                    tabContent: (
                        <Formsy   
                        onValidSubmit={handleSubmitBank}
                        ref={formRefstepBank}
                        autoComplete={"off"}
                        className={classes.form + " flex flex-col justify-center w-full"}
                    > 
                        <div className={classes.choosedesign}>
                        <h4 className={classes.headingtag}>Add Bank Account</h4>
                        <div className={classes.errormsg + " message_login"} id="message_login"></div>
                        <GridContainer> 
                                <GridItem xs={12} sm={6} md={6}>
                                    <TextFieldFormsy
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        type="text"
                                        name="account_name"
                                        label="Account Name"
                                        value={((userBankerDetails != null && userBankerDetails != false && userBankerDetails.account_name != null) ? userBankerDetails.account_name : "")}
                                        validations={{
                                            minLength: 6, 
                                        }}
                                        validationErrors={{
                                            minLength: 'Min character length is 6',  
                                            
                                        }} 
                                        variant="outlined"
                                        required 
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <TextFieldFormsy
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        type="text"
                                        name="ifsc_code"
                                        label="IFSC Code" 
                                        value={((userBankerDetails != null && userBankerDetails != false && userBankerDetails.ifsc_code != null) ? userBankerDetails.ifsc_code : "")}
                                        validations={{
                                            minLength: 6, 
                                        }}
                                        validationErrors={{
                                            minLength: 'Min character length is 6',  
                                            
                                        }} 
                                        onChange={ifscChange}
                                        variant="outlined"
                                        required
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <TextFieldFormsy
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        type="text"
                                        name="bank_name"
                                        label="Bank Name" 
                                        value={((userBankerDetails != null && userBankerDetails != false && userBankerDetails.bank_name != null) ? userBankerDetails.bank_name : bankName)}
                                        
                                        validations={{
                                            minLength: 3, 
                                        }}
                                        validationErrors={{
                                            minLength: 'Min character length is 3',  
                                            
                                        }} 
                                        variant="outlined"
                                        required
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <TextFieldFormsy
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        type="text"
                                        name="bank_branch"
                                        label="Bank Branch" 
                                        value={((userBankerDetails != null && userBankerDetails != false && userBankerDetails.bank_branch != null) ? userBankerDetails.bank_branch : branchName)}
                                        
                                        validations={{
                                            minLength: 3, 
                                        }}
                                        validationErrors={{
                                            minLength: 'Min character length is 3',  
                                            
                                        }} 
                                        variant="outlined"
                                        required
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <TextFieldFormsy
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        type="number"
                                        name="account_number"
                                        label="Account Number"
                                        value={((userBankerDetails != null && userBankerDetails != false && userBankerDetails.account_number != null) ? userBankerDetails.account_number : "")}
                                         
                                        validations={{
                                            minLength: 6, 
                                        }}
                                        validationErrors={{
                                            minLength: 'Min character length is 6',  
                                            
                                        }} 
                                        variant="outlined"
                                        required
                                    />
                                </GridItem>  
                            </GridContainer>
                            <GridContainer style={{paddingTop:10}}>
                            <GridItem xs={6} sm={2} md={2}>
                                <Button simple color="primary" style={{float:'left'}} className={classes.signinmodalbtn2 } type="button" onClick={()=>{setCurrentPosition(2);setSelectedTab(2); clickedOnTabs(2);}} size="lg" >
                                    Back
                                </Button> 
                            </GridItem>
                            <GridItem xs={6} sm={10} md={10}>
                            <TextFieldFormsy type="hidden" className={classes.hiddeninput +" hidden"} id="cms_theme_id" name="cms_theme_id" value={themeId}></TextFieldFormsy>
                                    <Button simple color="primary"  className={classes.signinmodalbtn +" signupbtn"} type="submit" size="lg" >
                                        Next
                                    </Button> 
                            </GridItem>
                        </GridContainer>
                        </div>
                        </Formsy>
                    )
                  },
                  {
                    tabName: "5",
                    tabContent: (
                        <Formsy   
                        onValidSubmit={handleSubmitFour}
                        ref={formRefstepFour}
                        autoComplete={"off"}
                        className={classes.form + " flex flex-col justify-center w-full"}
                    > 
                        <div className={classes.marketplace}>
                        <h4 className={classes.headingtag}>Invoice & Payments</h4>
                        
                         <div style={{fontSize:16, fontWeight:'bold', background: '#f1f1f1', padding: '20px',border: '1px solid #e8be35',borderRadius: '10px', margin: '10px 1.3% 0px', width: '97.4%'}}> 
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={3}> Plan Name : {(userData != null && userData.MembershipPlan != null) ? parser.parseFromString(`<!doctype html><body>${userData.MembershipPlan.plan_name}`, 'text/html').body.textContent : ""}
                                </GridItem> 
                                <GridItem xs={12} sm={6} md={3}> Setup Cost : {(userData != null && userData.MembershipPlan != null) ? userData.MembershipPlan.setup_cost : ""}  
                                </GridItem>
                                <GridItem xs={12} sm={6} md={3}> Monthly Cost : {(userData != null && userData.MembershipPlan != null) ? userData.MembershipPlan.recurring_cost : ""} 
                                </GridItem>
                                <GridItem xs={12} sm={6} md={3}>Plan Duration :  {/* {(userData != null && userData.MembershipPlanDuration != null) ? userData.MembershipPlanDuration.display_name : "Monthly"}  */}Monthly
                                </GridItem>
                            </GridContainer>
                        </div>
                        
                        <div style={{ margin: '10px 1.3% 10px', width: '97.4%'}} className={classes.errormsg + " message_login"} id="message_login"></div>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <div className={classes.tablefulywrap}>
                                <table className={classes.tablefuly}>
                                    <tr>
                                        <th style={{width:'25%'}}>Module Name</th>
                                        <th style={{width:'17%'}}>Setup Price ({(currentCurrency === "NO") ? "INR" : currentCurrency})</th>
                                        <th style={{width:'20%'}}>Duration</th>
                                        <th style={{width:'18%'}}>Price ({(currentCurrency === "NO") ? "INR" : currentCurrency})</th>
                                        <th className={classes.action} style={{width:'20%'}}>Action</th> 
                                    </tr>
                                {(() => {
                                        if(moduleLists != null && Array.isArray(moduleLists)){
                                            var runArr = -1;
                                            var disabledOn = false;
                                            
                                            var newthemeModules = "";
                                            var newDuration = "";
                                            if(userModules != false && userModules != null){
                                                let selModuleSplit = userModules.toString().split(",");
                                                for(var nTM=0;nTM<selModuleSplit.length;nTM++){
                                                    var onlyModuleSplit = selModuleSplit[nTM].split(":");
                                                    if(newthemeModules === ""){
                                                        newthemeModules = onlyModuleSplit[0];
                                                    }else{
                                                        newthemeModules = newthemeModules + "," + onlyModuleSplit[0];
                                                    }
                                                    if(newDuration === ""){
                                                        newDuration = onlyModuleSplit[1];
                                                    }else{
                                                        newDuration = newDuration + "," + onlyModuleSplit[1];
                                                    }
                                                }
                                            }
                                            
                                            var allModules = moduleLists.map((themeData) => {
                                                runArr = runArr + 1;
                                                if((userModules === false || userModules == null) || (newthemeModules.indexOf(themeData.user_module_id) >= 0)){
                                                    if(isLoadCompleted === false){
                                                        setSelectedModules(true,themeData.user_module_id,"AUTO", runArr);
                                                    }
                                                }
                                                const curPos = runArr;
                                                return (
                                                    <tr>
                                                        <td>{themeData.name}</td>
                                                        <td>{themeData.setup_cost}</td>
                                                        <td>
                                                            {(() => {
                                                                
                                                                if(themeData.ModuleRecurrngPrice != null && themeData.ModuleRecurrngPrice.length > 0){
                                                                    var selectedDuration = themeData.ModuleRecurrngPrice[0].recurring_price_id;
                                                                    if(userModules != false && userModules != null){
                                                                        let selModuleSplit = userModules.toString().split(",");
                                                                        for(var nTM=0;nTM<selModuleSplit.length;nTM++){
                                                                            var onlyModuleSplit = selModuleSplit[nTM].split(":");
                                                                            if(onlyModuleSplit[0] === themeData.user_module_id){
                                                                                selectedDuration = onlyModuleSplit[1];
                                                                            }
                                                                        }
                                                                    }
                                                                    return(
                                                                        
                                                                        <SelectFormsy  className={classes.selectboxx + " selectbox minwidthselect " + classes.seltbox}
                                                                            formControlProps={{
                                                                                fullWidth: true
                                                                            }}
                                                                            type="text"
                                                                            id={"main_duration_data_"+themeData.user_module_id+""} 
                                                                            name={"main_duration_data_"+themeData.user_module_id+""}
                                                                            label="Plan Duration"
                                                                            variant="outlined"
                                                                            value={selectedDuration}
                                                                            onChange={(event) => {modulePriceHandler(event, curPos, themeData.ModuleRecurrngPrice, themeData.user_module_id)}}
                                                                        >
                                                                            {(() => {
                                                                                var output = themeData.ModuleRecurrngPrice.map((PriceData) =>{
                                                                                    return (<MenuItem value={PriceData.recurring_price_id}>{PriceData.recurring_type_display}</MenuItem>)

                                                                                })
                                                                                return output;
                                                                            })()}
                                                                        </SelectFormsy>
                                                                        
                                                                    )
                                                                }else{
                                                                    return ("NOT APPLICABLE")
                                                                }
                                                            }
                                                            )()}
                                                            
                                                        </td>
                                                        <td>
                                                        {(() => {
                                                                if(themeData.ModuleRecurrngPrice != null && themeData.ModuleRecurrngPrice.length > 0){
                                                                    var selectedDuration = themeData.ModuleRecurrngPrice[0].recurring_price_id;
                                                                    if(userModules != false && userModules != null){
                                                                        let selModuleSplit = userModules.toString().split(",");
                                                                        for(var nTM=0;nTM<selModuleSplit.length;nTM++){
                                                                            var onlyModuleSplit = selModuleSplit[nTM].split(":");
                                                                            if(onlyModuleSplit[0] === themeData.user_module_id){
                                                                                selectedDuration = onlyModuleSplit[1];
                                                                            }
                                                                        }
                                                                    }
                                                                    var selectedPrice = themeData.ModuleRecurrngPrice[0].recurring_cost;
                                                                    for(var runPos=0;runPos < themeData.ModuleRecurrngPrice.length; runPos++){
                                                                        let jsonOutput = themeData.ModuleRecurrngPrice[runPos];
                                                                        if(jsonOutput.recurring_price_id != null && jsonOutput.recurring_price_id == selectedDuration){
                                                                            selectedPrice=jsonOutput.recurring_cost;
                                                                        }
                                                                    }
                                                                    return(
                                                                        <div id={"recurrning_cost_"+runArr}>{selectedPrice}</div>
                                                                    )
                                                                }else{
                                                                    return("NOT APPLICABLE")
                                                                }
                                                            }
                                                        )()}
                                                            </td>
                                                        <td><div className={classes.checkbtimarket} ><CheckboxFormsy id={"main_data["+runArr+"]"} name={"main_data["+runArr+"]"} themeColors={props.themeColors} value={(((userModules === false || userModules == null) || (newthemeModules.indexOf(themeData.user_module_id) >= 0)) ? themeData.user_module_id : "")} data={themeData.user_module_id}  defaultState={((themeData.default_status == null || themeData.default_status == 1) ? true : false)} onClick={(isCheck) => {setSelectedModules(isCheck,themeData.user_module_id, "CLICK", curPos)}} disabled={disabledOn} />
                                                        </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            if(sumOfSetupCost === "LOADING..."){
                                                startTotalOfAmount("ONCE");
                                            }
                                            
                                            return allModules;
                                        }
                                    }
                                )()}
                                    <tr>
                                        <td><span className={classes.bols}>TOTAL</span></td>
                                        <td><span className={classes.bols}>{(currentCurrency === "NO") ? "INR" : currentCurrency} {sumOfSetupCost}</span> </td>
                                        <td> </td>
                                        <td><span className={classes.bols}>{(currentCurrency === "NO") ? "INR" : currentCurrency} {sumOfRecurringCost}</span></td>
                                        <td></td>
                                    </tr>
                                    <tr className="subtotalprofile" style={{textAlign:"right", fontWeight:"bold"}}>
                                        <td colspan="4">
                                            <p style={{margin:"0px", padding:"5px 0px", fontSize:"18px"}}><span style={{ display: "inline-block", }}>Modules Cost :</span>   </p> 
                                            <p style={{margin:"0px", padding:"5px 0px", fontSize:"18px"}}><span style={{ display: "inline-block", }}>Setup Cost :</span>     </p> 
                                            <p style={{margin:"0px", padding:"5px 0px", fontSize:"18px"}}><span style={{ display: "inline-block",}}>Monthly Cost :</span>   </p> 
                                            {/* <p style={{margin:"0px", padding:"5px 0px", fontSize:"18px"}}><span style={{ display: "inline-block",}}>(Apply on monthly cost*) Discount ({((userData && userData.MembershipPlanDuration && userData.MembershipPlanDuration.discount !== null) ? userData.MembershipPlanDuration.discount : "0")}%) :</span>   </p> */}
                                            <p style={{margin:"0px", padding:"5px 0px", fontSize:"18px"}}><span style={{ display: "inline-block", }}>Grand Total :</span>   </p> 
                                        </td>
                                        <td>
                                            <p style={{margin:"0px", minWidth:"140px", padding:"5px 0px", fontSize:"18px"}}> <span style={{  display: "inline-block",}}>{(currentCurrency === "NO") ? "INR" : currentCurrency} {parseFloat(sumOfTotalCost).toFixed(2)}</span></p> 
                                            <p style={{margin:"0px", minWidth:"140px", padding:"5px 0px", fontSize:"18px"}}><span style={{ display: "inline-block",}}>{(currentCurrency === "NO") ? "INR" : currentCurrency} {(userData.MembershipPlan != null) ? userData.MembershipPlan.setup_cost : "0.00"}</span> </p> 
                                            <p style={{margin:"0px", minWidth:"140px", padding:"5px 0px", fontSize:"18px"}}> <span style={{  display: "inline-block",}}>{(currentCurrency === "NO") ? "INR" : currentCurrency} {(userData.MembershipPlan != null) ? userData.MembershipPlan.recurring_cost * ((userData.MembershipPlanDuration && userData.MembershipPlanDuration.plan_duration) ? userData.MembershipPlanDuration.plan_duration : 1) : "0.00"}</span></p> 
                                            {/* <p style={{margin:"0px", minWidth:"140px", padding:"5px 0px", fontSize:"18px"}}> <span style={{  display: "inline-block",}}>{(currentCurrency === "NO") ? "INR" : currentCurrency} {(userData.MembershipPlan != null) ? ((userData.MembershipPlan.recurring_cost * ((userData.MembershipPlanDuration && userData.MembershipPlanDuration.plan_duration) ? userData.MembershipPlanDuration.plan_duration : 1)) * userData.MembershipPlanDuration.discount)/100 : "0.00"}</span></p>  */}
                                            <p style={{margin:"0px", minWidth:"140px", padding:"5px 0px", fontSize:"18px"}}><span style={{  display: "inline-block",}}>{(currentCurrency === "NO") ? "INR" : currentCurrency} {(userData.MembershipPlan != null) ? (parseFloat(sumOfTotalCost + parseFloat(userData.MembershipPlan.setup_cost) + parseFloat(userData.MembershipPlan.recurring_cost * ((userData.MembershipPlanDuration && userData.MembershipPlanDuration.plan_duration) ? userData.MembershipPlanDuration.plan_duration : 1))).toFixed(2)) - (((userData.MembershipPlan.recurring_cost * ((userData.MembershipPlanDuration && userData.MembershipPlanDuration.plan_duration) ? userData.MembershipPlanDuration.plan_duration : 1)) * userData.MembershipPlanDuration.discount)/100) : "0.00"}</span></p>
                                        </td> 
                                    </tr> 
                                </table>
                                </div>
                            </GridItem> 
                            </GridContainer>

                            

                            <GridContainer style={{paddingTop:10}}>
                            <GridItem xs={12} sm={2} md={2}>
                                <Button simple color="primary" style={{float:'left'}} className={classes.signinmodalbtn2 + " " + classes.signinmodallast} type="button" onClick={()=>{setCurrentPosition(3);setSelectedTab(3); clickedOnTabs(3);}} size="lg" >
                                    Back
                                </Button> 
                            </GridItem>
                            <GridItem xs={12} sm={10} md={10}>
                                <Button simple color="primary" className={classes.signinmodalbtn2 +" signupbtn " + classes.signinmodallast} type="submit" size="lg" >
                                    Proceed to Payment
                                </Button> 
                            </GridItem>
                        </GridContainer>
                        </div>
                        </Formsy>
                    )
                  }
                ]}
              />
            </GridItem>
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={classicModal2}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setClassicModal2(false)}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <b className={classes.paymethead}>Proceed to Payment</b>
                    <IconButton
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={() => setClassicModal2(false)}
                    >
                        <Close className={classes.modalClose} />
                    </IconButton>
                    
                </DialogTitle>
                <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                >  
                    
                    <div id="overlay" className="loadfixxer" >
                        <div className="circle"></div>
                        <div className="circle1"></div> 
                        <div className="preloaderTxt"></div>
                    </div>
                    
                    <Formsy   
                        onValidSubmit={handleSubmitPayment}
                        ref={formRefstepFive}
                        style={{margin:"0"}}
                        onValid={enableButtonPayment}
                        onInvalid={disableButtonPayment}
                        autoComplete={"off"}
                        className={classes.form + " flex flex-col justify-center w-full"}
                    >  
                            
                        <GridContainer >
                            <GridItem className={classes.formdixdd} xs={12} sm={12} md={12}>
                            <div style={{margin:"0px 0px 10px"}} className={classes.errormsg + " message_login"} id="message_login"></div>
                            </GridItem>
                            <GridItem className={classes.formdixdd} xs={12} sm={12} md={6}>
                                <div  className={classes.designbox}>
                                    <div onClick={couponcodeBox} className={classes.radiobtn8 + " radiobtn"} style={{lineHeight: '30px'}}> COUPON CODE</div>
                                </div>
                            </GridItem>
                            {(() => {
                                if(payPalKey != ""){
                                return(
                                    <GridItem className={classes.formdixdd} xs={12} sm={12} md={6}>
                                        <div  className={classes.designbox}>
                                            <div onClick={paypalBox} className={classes.radiobtn9 + " "}> <img src={paypalimg} style={{height: '30px', width:'auto'}} className={classes.designimg} alt="" /></div>
                                        </div>
                                    </GridItem>
                                    )
                                }
                            })()}
                            <TextFieldFormsy type="hidden" className="hidden" id="payment_type" name="payment_type" value={paymentTypeId}></TextFieldFormsy>
                            <TextFieldFormsy type="hidden" className="hidden" id="user_gateway_id" name="user_gateway_id" value={gatewayId}></TextFieldFormsy>
                            </GridContainer>
                            <div className='couponcode'>
                            <GridContainer style={{margin:"0px"}} >
                            <GridItem className={classes.formdixdde + " " + classes.formdix} style={{boxShadow:"0px 0px 0px 0px", padding:"0px 15px 0px 15px"}} xs={12} sm={12} md={12}>
                                <TextFieldFormsy
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    type="text"
                                    name="coupon_code"
                                    label="Enter Coupon"
                                    validations={{
                                        minLength: 3, 
                                    }}
                                    validationErrors={{
                                        minLength: 'Min character length is 3',  
                                        
                                    }} 
                                    variant="outlined"
                                    required
                                />
                                        
                            </GridItem> 
                            <GridItem xs={12} sm={12} md={12}>
                                <Button simple color="primary" className={classes.signinmodalbtn2 +" signupbtnpay"} type="submit" size="lg" >
                                    Proceed Now
                                </Button> 
                            </GridItem>
                            </GridContainer>
                            </div>
                            <div className='paypalcode' style={{display : "none"}}>
                            <GridContainer >
                            <GridItem xs={12} sm={12} md={12}>
                                <div className={classes.depositformbtni}>
                                    {(() => {
                                        if(payPalKey != ""){
                                        return(
                                    <PayPalButton
                                        amount={(userData.MembershipPlan != null) ? ((parseFloat(sumOfTotalCost + parseFloat(userData.MembershipPlan.setup_cost) + parseFloat(userData.MembershipPlan.recurring_cost * ((userData.MembershipPlanDuration && userData.MembershipPlanDuration.plan_duration) ? userData.MembershipPlanDuration.plan_duration : 1))).toFixed(2)) - (((userData.MembershipPlan.recurring_cost * ((userData.MembershipPlanDuration && userData.MembershipPlanDuration.plan_duration) ? userData.MembershipPlanDuration.plan_duration : 1)) * userData.MembershipPlanDuration.discount)/100)) : "0.00"}
                                        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                        onSuccess={(details, data) => {
                                            if(props.sectionPopup != null){
                                                props.sectionPopup.openPopup("popup", "Success", "Please don't refresh till transaction proceed.");
                                            }else{
                                                alert("Please don't refresh till transaction proceed.");
                                            }
                                            //alert("Transaction completed by " + details.payer.name.given_name);
                                            // OPTIONAL: Call your server to save the transaction
                                            if(details != null && details.id != null && details.purchase_units != null && details.purchase_units[0].payments != null && details.purchase_units[0].payments.captures != null && details.purchase_units[0].payments.captures[0].id != null){
                                                var payPalEmail = (details.payer != null && details.payer.email_address != null) ? details.payer.email_address : "NOIDAVAIL";
                                                var payPalId = (details.payer != null && details.payer.payer_id != null) ? details.payer.payer_id : "NOIDAVAIL";
                                                dispatch(authActions.submitPaymentSettings({payment_type: paymentTypeId, api_response: details, user_gateway_id:gatewayId},props,userData.Registration));
                                                //dispatch(authActions.paypalRequest({amount: {(userData.MembershipPlan != null) ? parseFloat(sumOfTotalCost + parseFloat(userData.MembershipPlan.setup_cost) + parseFloat(userData.MembershipPlan.recurring_cost * ((userData.MembershipPlanDuration && userData.MembershipPlanDuration.plan_duration) ? userData.MembershipPlanDuration.plan_duration : 1))).toFixed(2) : "0.00"}, comment: comment, paypal_email:payPalEmail,paypal_id:payPalId, transation_id: details.purchase_units[0].payments.captures[0].id, transaction_response: JSON.stringify(details)}));  
                                            }else{
                                                var payPalEmail = (details.payer != null && details.payer.email_address != null) ? details.payer.email_address : "NOIDAVAIL";
                                                var payPalId = (details.payer != null && details.payer.payer_id != null) ? details.payer.payer_id : "NOIDAVAIL";
                                                dispatch(authActions.submitPaymentSettings({payment_type: paymentTypeId, api_response: details, user_gateway_id:gatewayId},props,userData.Registration));
                                                //dispatch(authActions.paypalRequest({amount: {(userData.MembershipPlan != null) ? parseFloat(sumOfTotalCost + parseFloat(userData.MembershipPlan.setup_cost) + parseFloat(userData.MembershipPlan.recurring_cost * ((userData.MembershipPlanDuration && userData.MembershipPlanDuration.plan_duration) ? userData.MembershipPlanDuration.plan_duration : 1))).toFixed(2) : "0.00"}, comment: comment, paypal_email:payPalEmail,paypal_id:payPalId, transation_id: 0, transaction_response: JSON.stringify(details)}));  
                                            }
                                            
                                        }}
                                        options={{
                                            clientId: payPalKey,
                                            currency:((currentCurrency === "NO") ? "INR" : currentCurrency)
                                        }}
                                        disabled={!isFormValid}
                                    />
                                        )
                                    }
                                })()}
                                
                                </div>
                            </GridItem>
                            </GridContainer>
                            </div>
                    </Formsy>
                </DialogContent>
            </Dialog>
          </GridContainer>
          <div className={classes.clerfix}></div>
          </div></div>
         </div>
    );
}
import React, {Component} from 'react';
import {CasinoUtils} from '../../../@casino';
import {matchRoutes} from 'react-router-config';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import AppContext from '../../../AppContext';
import axios from 'axios';
import * as globalurl from '../../../global/Global.js';

class CasinoAuthorization extends Component {

    constructor(props, context)
    {
        super(props);
        const {routes} = context;
        this.state = {
            accessGranted: true,
            routes
        };
    }

    getCircularReplacer = () => {
        const seen = new WeakSet();
        return (key, value) => {
          if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
              return;
            }
            seen.add(value);
          }
          return value;
        };
      };

    componentDidMount()
    {
        if ( !this.state.accessGranted )
        {
            this.redirectRoute();
        }
        this.getMyLocation();
    }

    componentDidUpdate()
    {
        if ( !this.state.accessGranted )
        {
            this.redirectRoute();
        }
    }
    
    getMyLocation() {
        const location = window.navigator && window.navigator.geolocation
        
        if (location) {
          location.getCurrentPosition((position) => {
            if(window.localStorage.getItem('jwt_access_token') != null){
                const request = axios.post(globalurl.UPDATELOCATION , {
                    token: window.localStorage.getItem('jwt_access_token'),
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                });

                request.then((response) => {

                });
                request.catch((error) => {

                });
            }
          }, (error) => {
            //this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
          })
        }
    
      }

    static getDerivedStateFromProps(props, state)
    {
        const {location, userRole} = props;
        const {pathname} = location;
        const matched = matchRoutes(state.routes, pathname)[0];

        return {
            accessGranted: matched ? CasinoUtils.hasPermission(matched.route.auth, userRole) : true
        }
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        return nextState.accessGranted !== this.state.accessGranted;
    }
    
    redirectRoute()
    {
        const {location, userRole, history} = this.props;
        const {pathname, state} = location;
        const redirectUrl = state && state.redirectUrl ? state.redirectUrl : '/';
        /*
        User is guest
        Redirect to Login Page
        */
        if ( !userRole || userRole.length === 0 )
        {
            history.push({
                pathname: '/#login',
                state   : {redirectUrl: pathname}
            });
        }
        /*
        User is member
        User must be on unAuthorized page or just logged in
        Redirect to dashboard or redirectUrl
        */
        else
        {
            history.push({
                pathname: redirectUrl
            });
        }
    }

    render()
    {
        // console.info('Casino Authorization rendered', accessGranted);
        return this.state.accessGranted ? <React.Fragment>{this.props.children}</React.Fragment> : null;
    }
}

function mapStateToProps({auth})
{
    return {
        userRole: auth.user.role
    }
}

CasinoAuthorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps)(CasinoAuthorization));

/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import {IconButton, MenuItem} from "@material-ui/core"; 
import People from "@material-ui/icons/People";
import Email from "@material-ui/icons/Email";
// react components for routing our app without refresh
import { Link, withRouter } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// @material-ui/core components
import { Button, Divider, Typography, InputAdornment, Icon } from '@material-ui/core';
import CustomInput from "../elements/CustomInput/CustomInput.js";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// core components
import CustomDropdown from "../elements/CustomDropdown/CustomDropdown.js";
import CustomButton from "../elements/CustomButtons/Button.js";
//Third Party Authentication
import { FacebookLogin, GoogleLogin } from "../../../@casino"
import ImgUpload from "../pages/imgupload.js";
import ImgUploaddl from "../pages/imgupload.js";
import ImgUploadpan from "../pages/imgupload.js";
import Formsy from 'formsy-react';
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import menuDb from "../data/menu.js";
import logo from "../assets/img/logo.png";
import GridItem from "../elements/Grid/GridItem";
import GridContainer from "../elements/Grid/GridContainer";
import styles from "../assets/jss/components/headerLinksStyle.js";
import stylesr from "../assets/jss/components/headerLinkstopStyle";

import * as authActions from '../../../auth/casino/actions';
import { useDispatch, useSelector } from 'react-redux';
import {TextFieldFormsy, SelectFormsy} from '../../../@casino';
import secloginboxDb from "../data/secloginbox.js";
import kycmoduleDb from "../data/kycmodule.js";
import { useTranslation } from 'react-i18next';
const history = createBrowserHistory()
let classicModal = null;
let setClassicModal = null;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";
const validEmailRegex =
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

export default function UploadAadhar(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const imgupload = useSelector(({ auth }) => auth.imgupload);
  const kycupload = useSelector(({ auth }) => auth.kycupload);
  const [classAdd, setClassAdd] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [uploadText, setUploadText] = useState("");
  const [aadharFrontImage, setAadharFrontImage] = useState("");
  const [aadharBackImage, setAadharBackImage] = useState("");

  const formRefdl = useRef(null);
  const imguploaddl = useSelector(({ auth }) => auth.imgupload);
  const kycuploaddl = useSelector(({ auth }) => auth.kycupload);
  const [classAdddl, setClassAdddl] = useState(false);
  const [isFormValiddl, setIsFormValiddl] = useState(false);
  const [uploadTextdl, setUploadTextdl] = useState("");
  const [dlBackImage, setDLBackImage] = useState("");
  const [dlFrontImage, setDLFrontImage] = useState("");
  let currentStyle = styles;


  const formRefpan = useRef(null);
  const imguploadpan = useSelector(({ auth }) => auth.imgupload);
  const kycuploadpan = useSelector(({ auth }) => auth.kycupload);
  const [classAddpan, setClassAddpan] = useState(false);
  const [isFormValidpan, setIsFormValidpan] = useState(false);
  const [uploadTextpan, setUploadTextpan] = useState("");
  const [panBackImage, setPanBackImage] = useState("");
  const [panFrontImage, setPanFrontImage] = useState("");

  

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  [classicModal, setClassicModal] = React.useState(false);
  const [depositType, setDepositType] = React.useState(0);

  function selectplanHandler(event){
    if(event.target.value!=null ){
    //  alert(event.target.value);
    setDepositType(event.target.value);
    
  
    }   
}
function kychandler(event){
  if(event.target.value == "AadharCard"){
    document.body
    .getElementsByClassName("aadharbox")[0]
    .setAttribute("style", "display:inline-block;");
    document.body
    .getElementsByClassName("panbox")[0]
    .setAttribute("style", "display:none;");
    document.body
    .getElementsByClassName("dlbox")[0]
    .setAttribute("style", "display:none;");
  }else if(event.target.value == "PanCard"){
    document.body
    .getElementsByClassName("aadharbox")[0]
    .setAttribute("style", "display:none;");
    document.body
    .getElementsByClassName("panbox")[0]
    .setAttribute("style", "display:inline-block;");
    document.body
    .getElementsByClassName("dlbox")[0]
    .setAttribute("style", "display:none;");
  }else {
    document.body
    .getElementsByClassName("aadharbox")[0]
    .setAttribute("style", "display:none;");
    document.body
    .getElementsByClassName("panbox")[0]
    .setAttribute("style", "display:none;");
    document.body
    .getElementsByClassName("dlbox")[0]
    .setAttribute("style", "display:inline-block;");
  }
}



  useEffect(() => {
    if (imgupload.success) {
      if (imgupload.name == 'front_scan') {
        setAadharFrontImage(imgupload.data.img);
      } else if (imgupload.name == 'back_scan') {
        setAadharBackImage(imgupload.data.img);
      }
      setUploadText("");
      enableButton();
    } else if (imgupload.error) {
      if (imgupload.error.message != null) {
        document.body
          .getElementsByClassName("message_aadharupload")[0]
          .innerHTML = imgupload.error.message;
        setClassAdd(classes.msg = " errormsg");
      }
      disableButton();
      setUploadText("");
    }
  }, [imgupload]);

  useEffect(() => {
    if (kycupload.success) {
      document.body
        .getElementsByClassName("message_aadharupload")[0]
        .innerHTML = "File uploaded successfully";
      setClassAdd(classes.msg = " successmsg");
      setUploadText("");
      props.getKYCData();
      const timer = setTimeout(() => {
        setClassicModal(false);
      }, 3000);
    } else if (kycupload.error) {
      if (kycupload.error.message != null) {
        document.body
          .getElementsByClassName("message_aadharupload")[0]
          .innerHTML = kycupload.error.message;
        setClassAdd(classes.msg = " errormsg");
      }
      setUploadText("");
      disableButton();
    }
  }, [kycupload]);

  function disableButton() {
    setIsFormValid(false);
    setIsFormValidpan(false);
    setIsFormValiddl(false);
    document.body
      .getElementsByClassName("signinbtn")[0]
      .setAttribute("style", "opacity:0.5;");
  }

  function enableButton() {
    setIsFormValid(true);
    setIsFormValidpan(true);
    setIsFormValiddl(true);
    document.body
      .getElementsByClassName("signinbtn")[0]
      .setAttribute("style", "opacity:1;");
  }

  function uploadImage(e, name) {
    if (e != undefined && e != null) {
      setUploadText("Please Wait...");
      dispatch(authActions.uploadImage(e, name, props));
    }
    document.body
      .getElementsByClassName("message_aadharupload")[0]
      .innerHTML = " "
  }

  function handleSubmit() {
    setUploadText("Uploading...");
    
    if (aadharFrontImage != "" && aadharBackImage != "") {
      var model = {
        doc_type: "AadharCard",
        front_scan: aadharFrontImage,
        back_scan: aadharBackImage,
        select_pay_by:depositType,
        update: (props.kycData.name != undefined) ? true : false,
        token: window.localStorage.getItem('jwt_access_token')
      };
      dispatch(authActions.uploadKYC(model, props));
      document.body
        .getElementsByClassName("message_aadharupload")[0]
        .innerHTML = "";
    } else {
      document.body
        .getElementsByClassName("message_aadharupload")[0]
        .innerHTML = "Please select both front and back image";
      setClassAdd(classes.msg = " errormsg");
      
    }
  }

  useEffect(() => {
    if (imgupload.success) {
      if (imgupload.name == 'front_scan') {
        setDLFrontImage(imgupload.data.img);
      } else if (imgupload.name == 'back_scan') {
        setDLBackImage(imgupload.data.img);
      }
      setUploadText("");
      enableButton();
    } else if (imgupload.error) {
      if (imgupload.error.message != null) {
        document.body
          .getElementsByClassName("message_dlupload")[0]
          .innerHTML = imgupload.error.message;
        setClassAdd(classes.msg = " errormsg");
      }
      setUploadText("");
      disableButton();
    }
  }, [imgupload]);

  useEffect(() => {
    if (kycupload.success) {
      document.body
        .getElementsByClassName("message_dlupload")[0]
        .innerHTML = "File uploaded successfully";
      setClassAdd(classes.msg = " successmsg");
      setUploadTextdl("");
      props.getKYCData();
      const timer = setTimeout(() => {
        setClassicModal(false);
      }, 3000);
    } else if (kycupload.error) {
      if (kycupload.error.message != null) {
        document.body
          .getElementsByClassName("message_dlupload")[0]
          .innerHTML = kycupload.error.message;
        setClassAdd(classes.msg = " errormsg");
      }
      setUploadTextdl("");
      disableButton();
    }
  }, [kycupload]);
  
  function handleSubmitdl() {
    setUploadTextdl("Uploading...");
    if (dlFrontImage != "" && dlBackImage != "") {
      var model = {
        doc_type: 'id_proof',
        front_scan: dlFrontImage,
        back_scan: dlBackImage,
        update: (props.kycData.name != undefined) ? true : false,
        token: window.localStorage.getItem('jwt_access_token')
      };
      dispatch(authActions.uploadKYC(model, props));
      document.body
        .getElementsByClassName("message_dlupload")[0]
        .innerHTML = "";
    } else {
      document.body
        .getElementsByClassName("message_dlupload")[0]
        .innerHTML = "Please select both front and back image";
      setClassAdd(classes.msg = " errormsg");
    }
  }


  useEffect(() => {
    if (imgupload.success) {
      if (imgupload.name == 'front_scan') {
        setPanFrontImage(imgupload.data.img);
      } else if (imgupload.name == 'back_scan') {
        setPanBackImage(imgupload.data.img);
      }
      setUploadText("");
      enableButton();
    } else if (imgupload.error) {
      if (imgupload.error.message != null) {
        document.body
          .getElementsByClassName("message_panupload")[0]
          .innerHTML = imgupload.error.message;
        setClassAdd(classes.msg = " errormsg");
      }
      setUploadTextpan("");
      disableButton();
    }
  }, [imgupload]);

  useEffect(() => {
    if (kycupload.success) {
      document.body
        .getElementsByClassName("message_panupload")[0].innerHTML = "File uploaded successfully";
      setClassAdd(classes.msg = " successmsg");
      setUploadTextpan("");
      props.getKYCData();
      const timer = setTimeout(() => {
        setClassicModal(false);
      }, 3000);
    } else if (kycupload.error) {
      if (kycupload.error.message != null) {
        document.body
          .getElementsByClassName("message_panupload")[0]
          .innerHTML = kycupload.error.message;
        setClassAdd(classes.msg = " errormsg");
      }
      setUploadTextpan("");
      disableButton();
    }
  }, [kycupload]);

  function uploadImage(e, name) {
    if (e != undefined && e != null) {
      setUploadText("Please Wait...");
      dispatch(authActions.uploadImage(e, name, props));
    }
    document.body
      .getElementsByClassName("message_panupload")[0]
      .innerHTML = " "
  }

  function handleSubmitpan() {
    setUploadTextpan("Uploading...");
    if (panFrontImage != "" ) {
      var model = {
        doc_type: 'pan',
        front_scan: panFrontImage,
        back_scan: panFrontImage,
        update: (props.kycData.name != undefined) ? true : false,
        token: window.localStorage.getItem('jwt_access_token')
      };
      document.body
        .getElementsByClassName("message_panupload")[0]
        .innerHTML = "";
      dispatch(authActions.uploadKYC(model, props));
    } else {
      document.body
        .getElementsByClassName("message_panupload")[0]
        .innerHTML = "Please select both front and back image";
      setClassAdd(classes.msg = " errormsg");
    }
  }

  if (props.themeColors != null) {
    // styles.custom_file_upload.background = props.themeColors.bgColors.main_color;
     styles.signinbtn.backgroundImage = props.themeColors.bgColors.main_color;
     styles.signinbtn.color = props.themeColors.bgColors.text_primary_dark_color;
     styles.modalHeader.background = props.themeColors.bgColors.primary_bgcolor;
     styles.modalBody.background = props.themeColors.bgColors.primary_bgcolor;
     styles.modalFooter.background = props.themeColors.bgColors.primary_bgcolor;
     styles.modalSubtitle.color = props.themeColors.bgColors.text_primary_color;
     styles.signupbixp.color = props.themeColors.bgColors.text_primary_color;
     styles.signinmodalbtnkyc.background = props.themeColors.bgColors.main_color;
      styles.signinmodalbtnkyc.backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinmodalbtnkyc.color = props.themeColors.bgColors.text_primary_dark_color;
      styles.formgroupwidthlabel["& label"].color = props.themeColors.bgColors.text_secondary_dark_color;
      styles.selectboxx.color = props.themeColors.bgColors.text_primary_color;
      styles.selectboxx["& div div"].color = props.themeColors.bgColors.text_primary_color;
    // styles.signinmodalbtn["&:hover"].backgroundImage = props.themeColors.bgColors.golden;
    // styles.signinmodalbtn.color = props.themeColors.bgColors.goldenbtntext;
    styles.signinmodalbtnkyc.fontFamily = props.themeColors.bgColors.base_font; 
   }

  return ( 
      <div>
{kycmoduleDb[sessionStorage.getItem('lang')].map((jsondata) => (
<div>

    <Formsy
          onValidSubmit={handleSubmit}
          ref={formRef}
          style={{display:"inline-block"}} 
          className={classes.form + " " + classes.aadharcardbox + " flex flex-col justify-center w-full aadharbox " + classes.uploadkyccs }
        >

               <GridContainer>  
                    <GridItem xs={12}><div className={classes.msg + " message_aadharupload " + classAdd} id="message"></div></GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                        <div className={classes.formgroupwidthlabel}> 
                             <label>{jsondata.selecttypeid}</label> 
                            <SelectFormsy 
                                    className={classes.selectboxx + " selectbox"} 
                                    label=" "
                                    name="select_pay_by"  
                                    variant="outlined"
                                    value="AadharCard"
                                    // onChange={selectplanHandler} 
                                    onChange={kychandler}
                                    required>  
                                  <MenuItem value="AadharCard">{jsondata.aadharcard}</MenuItem>
                                  <MenuItem value="PanCard">{jsondata.pancard}</MenuItem>
                                  <MenuItem value="DrivingLicense">{jsondata.driving_license}</MenuItem>
                            </SelectFormsy> 
                        </div>  
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <div className={classes.formgroupwidthlabel}> 
                             <label>{jsondata.aadhar_one}</label>
                            <ImgUpload
                              name="front_scan"
                              onChange={uploadImage}
                              themeColors={props.themeColors}
                              src={aadharFrontImage}
                              required
                            />
                        </div>    
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <div className={classes.formgroupwidthlabel}> 
                            <label>{jsondata.aadhar_two}</label>
                            <ImgUpload
                              name="back_scan"
                              onChange={uploadImage}
                              themeColors={props.themeColors}
                              src={aadharBackImage}
                              required
                            />
                        </div>  
                    </GridItem>
                    
                    <GridItem xs={12} sm={12} md={2}>
                        <div className={classes.formgroupwidthlabel}> 
                        <label>{uploadText}</label>
                        <Button
                            color="primary" className={classes.signinmodalbtnkyc + " signinbtn"} disabled={!isFormValid} type="submit"
                             
                        >
                             {jsondata.aadhar_verify_btn}  
                        </Button> 
                        </div>  
                    </GridItem>
                </GridContainer> 
    </Formsy>       
  

    <Formsy
          onValidSubmit={handleSubmitpan}
          ref={formRefpan}
          style={{display:"none"}} 
          className={classes.form + " " + classes.pancardbox + " flex flex-col justify-center w-full panbox" }
        >

               <GridContainer>  
                    <GridItem xs={12}><div className={classes.msg + " message_panupload " + classAdd} id="message"></div></GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                        <div className={classes.formgroupwidthlabel}> 
                            <label>{jsondata.selecttypeid}</label> 
                            <SelectFormsy 
                                    className={classes.selectboxx + " selectbox"} 
                                    label=" "
                                    name="select_pay_by"  
                                    variant="outlined"
                                    value="PanCard" 
                                    onChange={kychandler}
                                    required>  
                                  
                                  <MenuItem value="AadharCard">{jsondata.aadharcard}</MenuItem>   
                                  <MenuItem value="PanCard">{jsondata.pancard}</MenuItem>
                                  <MenuItem value="DrivingLicense">{jsondata.driving_license}</MenuItem>
                            </SelectFormsy> 
                        </div>  
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <div className={classes.formgroupwidthlabel}> 
                             <label>{jsondata.aadhar_one}</label>
                            <ImgUpload
                              name="front_scan"
                              onChange={uploadImage}
                              themeColors={props.themeColors}
                              src={panFrontImage}
                              required
                            />
                        </div>    
                    </GridItem> 
                    
                    <GridItem xs={12} sm={12} md={2}>
                        <div className={classes.formgroupwidthlabel}> 
                        <label>{uploadTextpan}</label>
                        <Button
                            color="primary" className={classes.signinmodalbtnkyc + " signinbtn"} disabled={!isFormValidpan} type="submit"
                             
                        >
                            {jsondata.aadhar_verify_btn}  
                        </Button> 
                        </div>  
                    </GridItem>
                </GridContainer> 
    </Formsy>       


    <Formsy
          onValidSubmit={handleSubmitdl}
          ref={formRefdl}
          style={{display:"none"}} 
          className={classes.form + " " + classes.dlcardbox + " flex flex-col justify-center w-full dlbox" }
        >

               <GridContainer>  
                    <GridItem xs={12}><div className={classes.msg + " message_dlupload " + classAdd} id="message"></div></GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                        <div className={classes.formgroupwidthlabel}> 
                            <label>{jsondata.selecttypeid}</label> 
                            <SelectFormsy 
                                    className={classes.selectboxx + " selectbox"} 
                                    label=" "
                                    name="select_pay_by"  
                                    variant="outlined" 
                                    value="DrivingLicense"
                                    onChange={kychandler}
                                    required>  
                                    
                                  <MenuItem value="AadharCard">{jsondata.aadharcard}</MenuItem>
                                  <MenuItem value="PanCard">{jsondata.pancard}</MenuItem>
                                  <MenuItem value="DrivingLicense">{jsondata.driving_license}</MenuItem>
                                  
                            </SelectFormsy> 
                        </div>  
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <div className={classes.formgroupwidthlabel}> 
                             <label>{jsondata.aadhar_one}</label>
                            <ImgUpload
                              name="front_scan"
                              onChange={uploadImage}
                              themeColors={props.themeColors}
                              src={dlFrontImage}
                              required
                            />
                        </div>    
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <div className={classes.formgroupwidthlabel}> 
                             <label>{jsondata.aadhar_two}</label>
                            <ImgUpload
                              name="back_scan"
                              onChange={uploadImage}
                              themeColors={props.themeColors}
                              src={dlBackImage}
                              required
                            />
                        </div>  
                    </GridItem>
                    
                    <GridItem xs={12} sm={12} md={2}>
                        <div className={classes.formgroupwidthlabel}> 
                        <label>{uploadTextdl}</label>
                        <Button
                            color="primary" className={classes.signinmodalbtnkyc + " signinbtn"} disabled={!isFormValiddl} type="submit"
                             
                        >
                             {jsondata.aadhar_verify_btn}    
                        </Button> 
                        </div>  
                    </GridItem>
                </GridContainer> 
    </Formsy>       



</div>
))}
</div>
     
  );
}


 
/*eslint-disable*/
import React, {useEffect, useRef, useState} from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components 
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/iconske 
import styles from "../../assets/jss/components/footerStyle.js";
// import Section4 from "../../sections/Section4";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer"; 
import Button from "../CustomButtons/Button"; 
const useStyles = makeStyles(styles);
import footertopDb from "../../data/footertop.js"; 
 

export default function Footer(props) {
  const currentLocation = props.location.pathname.replace("/","");
  if(props.themeColors != null){
    styles.footer.backgroundColor = props.themeColors.bgColors.primary_bgcolor
    styles.titlefooter.color = props.themeColors.bgColors.text_primary_color
    styles.atag.color = props.themeColors.bgColors.text_secondary_dark_color
    styles.atag["&:hover"].color = props.themeColors.bgColors.text_secondary_color
    styles.copybg.background = props.themeColors.bgColors.primary_bgcolor
    styles.leftarea["& p"].color = props.themeColors.bgColors.text_secondary_dark_color 
    styles.copyrighttext.color = props.themeColors.bgColors.text_primary_color
    styles.copyrightatag.color = props.themeColors.bgColors.main_color  
    styles.backtotop.borderColor = props.themeColors.bgColors.main_color
    styles.backtotop.color = props.themeColors.bgColors.text_secondary_color

    styles.backtotop["&:hover"].background = props.themeColors.bgColors.main_color
    styles.backtotop["&:hover"].color = props.themeColors.bgColors.text_primary_dark_color

    styles.titlefooter.fontFamily = props.themeColors.bgColors.base_font; 
    styles.atag.fontFamily = props.themeColors.bgColors.base_font; 
    styles.rightarea["& p"].fontFamily = props.themeColors.bgColors.base_font;  
    styles.copyrightatag.fontFamily = props.themeColors.bgColors.base_font;    
    styles.backtotop.fontFamily = props.themeColors.bgColors.base_font; 
 }
 
  const classes = useStyles();
  const { whiteFont } = props;  

  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
 
  function parseJwt(token) {
    if (!token) { return; }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}
function createMarkup(text) { return {__html: text}; };
  function getCurrentThemeInfo(){
    if(window.localStorage.getItem('jwt_theme_token') != null){
        let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
        return themeData;
    }
  }  

  var copyrightText = null; 
  var themeInfo = getCurrentThemeInfo(); 

  if(themeInfo != null && themeInfo.id != null){
      if(themeInfo.copyright_text != null && themeInfo.copyright_text.length > 0){
        copyrightText = themeInfo.copyright_text;
      } 
  } 
  
  return (


<div>

      {(() =>  {

        if (currentLocation === "domainnotadjust") {

          return (

              <div></div>


          )
        } else {
          
          return (
            
              <footer className={footerClasses}>
                  {(() => { 
                    if(themeInfo.copyright_text !== null && (themeInfo.copyright_text !== undefined && themeInfo.copyright_text !== "undefined" && themeInfo.copyright_text.length > 0)) { 
                            return (
                              <div className={classes.footertop}>
                                <GridContainer > 
                                  <GridItem xs={12} sm={12} md={12}>
                                        <div className={classes.centerarea}> 
                                            <p className={classes.copyrighttext} >
                                            <span dangerouslySetInnerHTML={createMarkup(copyrightText)} />  
                                            </p>  
                                        </div> 
                                  </GridItem>
                                </GridContainer>
                              </div>
                    ) }else{
                      return (
                          <span> 
                          </span>
                        )
                  }
                  })()} 
                
                
 
              </footer>
          )
        }
      })()}

</div>




  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};

import history from '../../../@history';
//import * as UserActions from './user.actions';
import jwtService from '../../../services/jwtService';
import {setUserData} from './user.actions';
//import * as Actions from '../../../store/actions';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const FORGOT_ERROR = 'FORGOT_ERROR';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';

export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_ERROR = 'VERIFY_ERROR';

export function submitForgot(model, props){
    var forgotBy = "";
    var recaptcha = "";
    if(model.username != null && model.username.length > 0){
        forgotBy = model.username;
        recaptcha = model.recaptcha;
    }else if(model.email != null && model.email.length > 0){
        forgotBy = model.email;
        recaptcha = model.recaptcha;
    }
    return (dispatch) =>
        jwtService.forgotPassword(forgotBy, recaptcha)
            .then((user) => { 
                    return dispatch({
                        type: FORGOT_SUCCESS,
                        payload: user
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type   : FORGOT_ERROR,
                    payload: error
                });
            });
}
export function verifyEmail({email},props)
{
    return (dispatch) =>
        jwtService.verifyEmailAddress(email)
            .then((user) => { 
                    return dispatch({
                        type: VERIFY_SUCCESS,
                        payload: user 
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type   : VERIFY_ERROR,
                    payload: error
                });
            });
}

export function submitLogin({username, password, recaptcha},props)
{

//const {...rest} = props;
//const currentLocation = props.location.pathname.replace("/","");

    return (dispatch) =>
        jwtService.signInWithEmailAndPassword(username, password, recaptcha)
            .then((user) => { 
                    user = {
                        role: ['player'],
                        data: {
                            displayName: user.username,
                            photoURL   : user.username,
                            email      : user.username,
                            settings   : {},
                            shortcuts  : []
                        }
                    };
                    dispatch(setUserData(user));
                    
                    // history.push({
                    //     pathname: '/casino'
                    // });
                   
                    return dispatch({
                        type: LOGIN_SUCCESS,
                        payload: user 
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type   : LOGIN_ERROR,
                    payload: error
                });
            });
}



const accountsDb = {
    en: [
        {
            'id':'3923923892',
            'title':"User Profile",
            'tagName': 'tab1', 
            'userprofilediv': [
                {
                    'id':"name",
                    'display_name':"Full Name"
                },
                {
                    'id':"username",
                    'display_name':"User Name"
                },
                {
                    'id':"email",
                    'display_name':"Email Id",
                },
                {
                    'id':"mobile",
                    'display_name':"Mobile Number",
                },
                // {
                //     'id':"commission_type",
                //     'display_name':"Commission Type",
                // }
                {
                    'id':"date_of_birth",
                    'display_name':"Date Of Birth",
                },
                {
                    'id':"address",
                    'display_name':"Address",
                }, 

                {
                    'id':"state",
                    'display_name':"State",
                },
                {
                    'id':"locality",
                    'display_name':"City",
                },
                {
                    'id':"pincode",
                    'display_name':"Pincode",
                },
            ],
            'changepasswordbtn':"Change Password",
            'editprofilebtn':"Edit Profile",
            'oldpassword':"Old Password",
            'newpassword':"New Password",
            'confirmpassword':"Confirm Password",
            'updatepasswordbtn':" Update Password",
            'cancelbtn':"Cancel",
            'formfirstname':"Full Name",
            'formlastname':"Last Name",
            'formname':"Full Name",
            'formusername':"Username",

            'formcountry':"Country", 
            'formaddress':"Address",
            'formcity':"City",
            'formstate':"State",
            'formpincode':"Pincode",

            'formemail':"Email",
            'formphone':"Phone Number",
            'formgender':"Gender",
            'formmale':"Male",
            'formfemale':"Female",
            'formdob':"Date of Birth",
            'updateprofilebtn':"Update Profile",
            'one_p':"On name update KYC need to verify again with new name",
            'two_p':"On email update Email need to verify again with new email id",
            'three_p':"On mobile update Mobile need to verify again with new mobile",
        },
        {
            'id':'3923923893',
            'title':"Bank Account",
            'tagName': 'tab2',
            'ptext': "To change your Bank Account, please contact Customer Service .",
            'acountname': "Account Name",
            'bankdiv': [
                {
                    'banknameoption':"Bank Name",
                },
                {
                    'banknameoption':"SBI",
                },
                {
                    'banknameoption':"ICICI",
                },
                {
                    'banknameoption':"Panjab National Bank",
                },
                {
                    'banknameoption':"HDFC",
                },
            ],
            'bankbranch': "Bank Branch",
            'accountnumber': "Account Number",
            'ifsccode': "IFSC Code",
            'whyifsc': "What is my IFSC Code?",
            'myaccountbtn': "Add Account",
            'addbankaccount': "Add Bank Account",
            'ifsc_text': "Fetch bank Details Click Here",
            'bank_name': "Bank Name", 
        },
        {
            'id':'3923923894',
            'title':"Kyc Verification",
            'tagName': 'tab3', 
            'aadharcard': 'Aadhar Card', 
            'pancard': 'Pan Card', 
            'driving_license': 'Driving License', 
        },
    ],
    tl: [
        {
            'id':'3923923892',
            'title':"వినియోగదారు వివరాలు",
            'tagName': 'tab1', 
            'userprofilediv': [
                {
                    'id':"name",
                    'display_name':"పూర్తి పేరు"
                },
                {
                    'id':"username",
                    'display_name':"వినియోగదారు పేరు"
                },
                {
                    'id':"email",
                    'display_name':"ఇమెయిల్ ఐడి",
                },
                {
                    'id':"mobile",
                    'display_name':"మొబైల్ సంఖ్య",
                },
                // {
                //     'id':"commission_type",
                //     'display_name':"కమిషన్ రకం",
                // }
                {
                    'id':"date_of_birth",
                    'display_name':"పుట్టిన తేది",
                },
                {
                    'id':"address",
                    'display_name':"చిరునామా",
                }, 
                 
                {
                    'id':"state",
                    'display_name':"రాష్ట్రం",
                },
                {
                    'id':"locality",
                    'display_name':"నగరం",
                },
                {
                    'id':"pincode",
                    'display_name':"పిన్ కోడ్",
                },
            ],
            'changepasswordbtn':"పాస్వర్డ్ మార్చండి",
            'editprofilebtn':"ప్రొఫైల్‌ను సవరించండి",
            'oldpassword':"పాత పాస్‌వర్డ్",
            'newpassword':"కొత్త పాస్వర్డ్",
            'confirmpassword':"పాస్వర్డ్ను నిర్ధారించండి",
            'updatepasswordbtn':" పాస్వర్డ్ను నవీకరించండి",
            'cancelbtn':"రద్దు చేయండి",
            'formfirstname':"పూర్తి పేరు",
            'formlastname':"చివరి పేరు",
            'formname':"పూర్తి పేరు",
            'formusername':"వినియోగదారు పేరు",
            'formemail':"ఇమెయిల్",
            'formphone':"ఫోను నంబరు",

            'formcountry':"దేశం", 
            'formaddress':"చిరునామా",
            'formcity':"నగరం",
            'formstate':"రాష్ట్రం",
            'formpincode':"పిన్ కోడ్", 
            
            'formgender':"లింగం",
            'formmale':"పురుషుడు",
            'formfemale':"స్త్రీ",
            'formdob':"పుట్టిన తేది",
            'updateprofilebtn':"ప్రొఫైల్‌ను నవీకరించండి",
            'one_p':"పేరు నవీకరణలో KYC క్రొత్త పేరుతో మళ్ళీ ధృవీకరించాలి",
            'two_p':"ఇమెయిల్ నవీకరణలో ఇమెయిల్ క్రొత్త ఇమెయిల్ ఐడితో మళ్ళీ ధృవీకరించాలి",
            'three_p':"మొబైల్ నవీకరణలో మొబైల్ కొత్త మొబైల్‌తో మళ్లీ ధృవీకరించాలి",
        },
        {
            'id':'3923923893',
            'title':"బ్యాంకు ఖాతా",
            'tagName': 'tab2',
            'ptext': "మీ బ్యాంక్ ఖాతాను మార్చడానికి, దయచేసి కస్టమర్ సేవను సంప్రదించండి.",
            'acountname': "ఖాతా పేరు",
            'bankdiv': [
                {
                    'banknameoption':"బ్యాంక్ పేరు",
                },
                {
                    'banknameoption':"ఎస్బిఐ",
                },
                {
                    'banknameoption':"ఐసిఐసిఐ",
                },
                {
                    'banknameoption':"పంజాబ్ నేషనల్ బ్యాంక్",
                },
                {
                    'banknameoption':"HDFC",
                },
            ],
            'bankbranch': "బ్యాంకు శాఖ",
            'accountnumber': "ఖాతా సంఖ్య",
            'ifsccode': "IFSC కోడ్",
            'whyifsc': "నా IFSC కోడ్ ఏమిటి?",
            'myaccountbtn': "ఖాతా జోడించండి",
            'addbankaccount': "బ్యాంక్ ఖాతాను జోడించండి",
            'ifsc_text': "బ్యాంక్ వివరాలను ఇక్కడ క్లిక్ చేయండి",
            'bank_name': "బ్యాంక్ పేరు", 
        },
        {
            'id':'3923923894',
            'title':"Kyc ధృవీకరణ",
            'tagName': 'tab3', 
            'aadharcard': 'ఆధార్ కార్డు', 
            'pancard': 'పాన్ కార్డ్', 
            'driving_license': 'వాహనం నడపడానికి చట్టబద్ధమైన అర్హత', 
        },
        
    ],
    hi: [
        {
            'id':'3923923892',
            'title':"उपयोगकर्ता प्रोफ़ाइल",
            'tagName': 'tab1', 
            'userprofilediv': [
                {
                    'id':"name",
                    'display_name':"पूरा नाम"
                },
                {
                    'id':"username",
                    'display_name':"उपयोगकर्ता नाम"
                },
                {
                    'id':"email",
                    'display_name':"ईमेल",
                },
                {
                    'id':"mobile",
                    'display_name':"मोबाइल",
                }, 
                // {
                //     'id':"commission_type",
                //     'display_name':"कमीशन का प्रकार",
                // }
                {
                    'id':"address",
                    'display_name':"पता",
                },
                 
                {
                    'id':"state",
                    'display_name':"राज्य",
                },
                {
                    'id':"locality",
                    'display_name':"शहर",
                },
                {
                    'id':"pincode",
                    'display_name':"पिन कोड",
                },
            ],
            'changepasswordbtn':"पासवर्ड बदलें",
            'editprofilebtn':"प्रोफ़ाइल संपादित करें",
            'oldpassword':"पुराना पासवर्ड",
            'newpassword':"नया पासवर्ड",
            'confirmpassword':"पासवर्ड की पुष्टि कीजिये",
            'updatepasswordbtn':"पासवर्ड अपडेट करें",
            'formfirstname':"पहला नाम",
            'formlastname':"उपनाम",
            'cancelbtn':"रद्द करना",
            'formname':"पूरा नाम",
            'formusername':"उपयोगकर्ता नाम",
            'formemail':"ईमेल",
            'formphone':"फ़ोन नंबर",
            'formgender':"लिंग",
            'formcountry':"देश", 
            'formaddress':"पता",
            'formcity':"शहर",  
            'formstate':"राज्य",
            'formpincode':"पिन कोड",   
            
            'formmale':"पुरुष",
            'formfemale':"महिला",
            'formdob':"जन्म की तारीख",
            'updateprofilebtn':"प्रोफ़ाइल अपडेट करें",
            'one_p':"नाम अपडेट पर केवाईसी को नए नाम के साथ फिर से सत्यापित करने की आवश्यकता है",
            'two_p':"ईमेल अपडेट पर ईमेल को नई ईमेल आईडी के साथ फिर से सत्यापित करने की आवश्यकता है",
            'three_p':"मोबाइल अपडेट पर मोबाइल को नए मोबाइल के साथ फिर से सत्यापित करने की आवश्यकता है",
        },
        {
            'id':'3923923893',
            'title':"बैंक खाता",
            'tagName': 'tab2',
            'ptext': "अपना बैंक खाता बदलने के लिए, कृपया ग्राहक सेवा से संपर्क करें।",
            'acountname': "खाते का नाम",
            'bankdiv': [
                {
                    'banknameoption':"बैंक का नाम",
                },
                {
                    'banknameoption':"भारतीय स्टेट बैंक",
                },
                {
                    'banknameoption':"आईसीआईसीआई",
                },
                {
                    'banknameoption':"पंजाब नेशनल बैंक",
                },
                {
                    'banknameoption':"एचडीफसी ",
                },
            ],
            'bankbranch': "बैंक शाखा",
            'accountnumber': "खाता संख्या",
            'ifsccode': "IFSC कोड",
            'whyifsc': "मेरा IFSC कोड क्या है?",
            'myaccountbtn': "खाता जोड़ो", 
            'addbankaccount': "बैंक खाता जोड़ें",
            'ifsc_text': "फ़ेच बैंक विवरण यहां क्लिक करें",
            'bank_name': "बैंक का नाम", 
        },
        {
            'id':'3923923894',
            'title':"केवाईसी मॉड्यूल",
            'tagName': 'tab3', 
            'aadharcard': 'आधार कार्ड', 
            'pancard': 'पैन कार्ड', 
            'driving_license': 'ड्राइविंग लाइसेंस', 
        },
    ]
}
export default accountsDb;

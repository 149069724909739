/*eslint-disable*/
import React, {useEffect, useRef, useState} from "react"; 
import {IconButton, MenuItem} from "@material-ui/core";  
import Email from "@material-ui/icons/Email";  
// @material-ui/core components
import {Button, Divider, Typography, InputAdornment, Icon} from '@material-ui/core'; 
import { makeStyles } from "@material-ui/core/styles"; 
import Close from "@material-ui/icons/Close";
// @material-ui/icons 
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { NumberTextfield } from "react-number-textfield";
 
// @database
import { useIndexedDB } from 'react-indexed-db';
import queryString from 'query-string';
//import { DbService } from '../../../@database/DBService';
// @api calling
import axios from 'axios';

import Formsy from 'formsy-react';
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import menuDb from "../data/menu.js";
import logo from "../assets/img/logo.png";
import GridItem from "../elements/Grid/GridItem";
import GridContainer from "../elements/Grid/GridContainer";
import styles from "../assets/jss/components/headerLinksStyle.js";
//import stylesr from "../assets/jss/components/headerLinkstopStyle";

import * as authActions from '../../../auth/casino/actions';
import * as Gurl from '../../../global/Global.js';
import {useDispatch, useSelector} from 'react-redux';
import {TextFieldFormsy, SelectFormsy, CheckboxFormsy} from '../../../@casino';
import secsignupboxDb from "../data/secsignupbox.js";
//import { useTranslation } from 'react-i18next'
const history = createBrowserHistory()



let test = "ok";
let classicModal2 = null;
let setClassicModal2 = null;
let notSelectCountry = false;
let countryData = "0";
let setCountryData = null;

let mobile, setMobile, countryId, setCountryId, otp, setOtp = null;

let currentCountryId = "99";
let setCurrentCountryId = null;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

const validEmailRegex = 
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
  var isExistCountryApiCall = false;
function SectionSignupboxMobile(props) {
    var isTest = false;
    if(props != null && props.location != null && props.location.search != null){
        let params = queryString.parse(props.location.search);
        if(params != null && params.istest != null){
            isTest = true;
        }
    }
    const dispatch = useDispatch();
    const [isFormValid, setIsFormValid] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPrivacy, setIsPrivacy] = useState(false);
    const [classAdd, setClassAdd] = useState(false); 
    const formRef = useRef(null);
    const register = useSelector(({auth}) => auth.registermobile);
    [mobile, setMobile] = useState(false);
    [countryId, setCountryId] = useState(false);
    [otp, setOtp] = useState(false); 
    [currentCountryId, setCurrentCountryId] = React.useState("99"); 
    [countryData, setCountryData] = React.useState("0"); 
    const [userName, setUserName] = React.useState("");
     
    
    styles.signinbtn.backgroundImage = props.themeColors.bgColors.main_color;
    styles.signinbtn.color = props.themeColors.bgColors.text_primary_dark_color;
    styles.modalHeader.background = props.themeColors.bgColors.primary_dark_bgcolor;
    styles.modalBody.background = props.themeColors.bgColors.primary_dark_bgcolor;
    styles.modalFooter.background = props.themeColors.bgColors.primary_dark_bgcolor;
    styles.modalSubtitle.color = props.themeColors.bgColors.text_primary_color;
    styles.modalTitle.color = props.themeColors.bgColors.text_secondary_dark_color;
    
    
  styles.modalCloseButton.color = props.themeColors.bgColors.text_primary_color;
    styles.signupbixp.color = props.themeColors.bgColors.text_primary_color; 
    styles.form["& input"].color = props.themeColors.bgColors.text_primary_color; 
    styles.form["& label"].color = props.themeColors.bgColors.text_secondary_dark_color; 
    styles.selectboxx["& div div"].color = props.themeColors.bgColors.text_primary_color; 
    
    styles.signinmodalbtn.backgroundImage = props.themeColors.bgColors.main_color;
    styles.signinmodalbtn.background = props.themeColors.bgColors.main_color;
    styles.signinmodalbtn["&:hover"].background = props.themeColors.bgColors.main_color;
    styles.signinmodalbtn["&:hover"].backgroundImage = props.themeColors.bgColors.main_color;
    styles.signinmodalbtn.color = props.themeColors.bgColors.text_primary_dark_color;
    styles.tnctext.color = props.themeColors.bgColors.text_primary_color;
    styles.tnctext["& a"].color = props.themeColors.bgColors.text_secondary_color;
    styles.logoname.color = props.themeColors.bgColors.text_primary_color; 

    styles.logoname.fontFamily = props.themeColors.bgColors.logo_font; 
      styles.modalTitle.fontFamily = props.themeColors.bgColors.base_font; 
      styles.modalSubtitle.fontFamily = props.themeColors.bgColors.base_font;  
      styles.form["& label"].fontFamily = props.themeColors.bgColors.base_font;   
      styles.form["& input"].fontFamily = props.themeColors.bgColors.base_font; 
      styles.signupbix.fontFamily = props.themeColors.bgColors.base_font;
      styles.signinmodalbtn.fontFamily = props.themeColors.bgColors.base_font; 
      styles.tnctext.fontFamily = props.themeColors.bgColors.base_font; 
      

   // const { t } = useTranslation()
    let currentStyle = styles;
    const useStyles = makeStyles(styles);

    const classes = useStyles();

    if(isPopupOpen === "stop"){
        setClassicModal2(false);
        // props.LoginBox.openPopup("login");
        setIsPopupOpen(false);
    }
   useEffect(() => {
        if ( register.success != null && register.success == false && register.error && (register.error.email || register.error.username || register.error.message || register.error.mobile) )
        {
            if(register.error.email || register.error.username  || register.error.mobile){
                
                if(register.error.username){
                    register.error.personal_mydata = register.error.username;
                    delete register.error["username"];
                }
                delete register.error["message"];
                //alert("JSONALERT" + JSON.stringify(register.error));
                formRef.current.updateInputsWithError({
                    ...register.error
                });
            }else if(register.error.message != null){
                
                if(document.body.getElementsByClassName("message_register_mobile")[0] != null){
                    document.body.getElementsByClassName("message_register_mobile")[0].innerHTML=register.error.message;
                }
                setClassAdd(classes.msg = " errormsg" );
            }
            disableButton();
            
        }else{
            if(register.success != null && register.success == true && register.data != null && register.data.status != null && register.data.status > 0){
                if(formRef != null && formRef.current != null){
                    formRef.current.reset();
                }
                if(register.data.ask_otp_status === "1"){
                    
                    setTimeout(function() { 
                        if(document.body.getElementsByClassName("message_register_mobile")[0] != null){
                            document.body.getElementsByClassName("message_register_mobile")[0].innerHTML="";
                        }
                        setClassicModal2(false);
                        props.OtpBox.openPopup("otpbox", register.data.status);
                        setIsPopupOpen(false); 
                      }, 1000); 
                }else{
                    setTimeout(function() { 
                        if(document.body.getElementsByClassName("message_register_mobile")[0] != null){
                            document.body.getElementsByClassName("message_register_mobile")[0].innerHTML="";
                        }
                        setClassicModal2(false);
                        // props.LoginBox.openPopup("login");
                        setIsPopupOpen(false); 
                      }, 5000); 
                }

                if(register.data.message != null){
                    setClassAdd(classes.msg = " successmsg" )
                    if(document.body.getElementsByClassName("message_register_mobile")[0] != null){
                        document.body.getElementsByClassName("message_register_mobile")[0].innerHTML=register.data.message;
                    }
                }
                  
            }
            
        }
    }, [register.error]);
 

    function disableButton()
    {
        setIsFormValid(false);
        document.body
        .getElementsByClassName("signupbtnnew3")[0]
          .setAttribute("style", "opacity:0.5;");
    }

    function enableButton()
    {
        setIsFormValid(true);
        if(document.body.getElementsByClassName("signupbtnnew3")[0] !== null){  
            document.body
            .getElementsByClassName("signupbtnnew3")[0]
              .setAttribute("style", "opacity:1;");
        }  
         
    }

    function handleSubmit(model)
    { 
        disableButton(); 
        model = {"username": model.personal_mydata, "mobile": mobile, "email": email, "country_id": countryId, "otp": otp, ...model}  
        dispatch(authActions.submitRegisterMobile(model,setIsPopupOpen,props)); 
        
        if(document.body.getElementsByClassName("message_register_mobile")[0] !== null){
            document.body
            .getElementsByClassName("message_register_mobile")[0]
            .innerHTML=" ";  
          } 
    } 

    function parseJwt(token) {
      if (!token) { return; }
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
  }
 function getCurrentThemeInfo(){
    if(window.localStorage.getItem('jwt_theme_token') != null){
        let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
        return themeData;
    }
  }

 
   var icon = null; 
   var brandName = null; 
  var themeInfo = getCurrentThemeInfo();
  
  if(themeInfo != null && themeInfo.id != null){ 
      if(themeInfo.icon != null && themeInfo.icon.length > 0){
        icon = themeInfo.icon;
      } 
      if(themeInfo.brand_name != null && themeInfo.brand_name.length > 0){
        brandName = themeInfo.brand_name;
      }  
  }
  // const brandComponent = "";
  

  [classicModal2, setClassicModal2] = React.useState(false);
  return (
    <div>
     {secsignupboxDb[sessionStorage.getItem('lang')].map((jsondata) => (   
        
      <div key={jsondata.id} className="sporto">
    <Dialog
    classes={{
        root: classes.center,
        paper: classes.modal + " sportot   " + classes.responsivemodelllogin
    }}
    open={classicModal2}
    TransitionComponent={Transition}
    keepMounted
    onClose={() => setClassicModal2(false)}
    aria-labelledby="classic-modal-slide-title"
    aria-describedby="classic-modal-slide-description"
>
    <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeaderlogin}
    >
        <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setClassicModal2(false)}
        >
            <Close className={classes.modalClose} />
        </IconButton> 
    </DialogTitle>
    <Formsy
                onValidSubmit={handleSubmit}
                onValid={enableButton}
                onInvalid={disableButton}
                ref={formRef}
                autoComplete={"nope"}
                className={classes.form + " flex flex-col justify-center w-full " + classes.modalBodylogin}
            >
                
    <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBodylogin + " signupmodallx"}
    > 

    <div style={{textAlign:"center", minHeight:"50px"}}>
        {(() => {
            // alert(JSON.stringify(themeInfo))
                if(themeInfo.icon != null && (themeInfo.icon != undefined && themeInfo.icon != "undefined" && themeInfo.icon.length > 0)) { 
                        return ( 
                            <img src={icon} height={"40px"} alt={""} style={{minHeight: "85px", paddingTop: "20px"}} className={classes.modellogo} />
                ) }else{
                return ( 
                        <h4 style={{minHeight: "85px", paddingTop: "20px"}} className={classes.logoname}>{brandName}</h4>  
                    )
            }
            })()}

            <h4 className={classes.modalTitle}>{jsondata.title} </h4>
    </div>
         
            <GridContainer style={{marginLeft:'0'}}>
                <GridItem xs={12}><div className={classes.msg + " message_register_mobile " + classAdd} id="message"></div></GridItem>
                    <GridItem xs={12} style={{padding:'0'}}>
                    <div className={classes.formggroup}>
                     <TextFieldFormsy 
                        label={jsondata.username}
                        id="personal_mydata"
                        value={userName}
                        onChange={(myText) => {if(myText.target != null && myText.target.value) { setUserName(myText.target.value.toLowerCase()) }}}
                        name="personal_mydata"
                        formControlProps={{
                            fullWidth: true
                        }}
                        validations={{
                            minLength: 5,
                            maxLength: 20,
                            noSpecialCase: function(value){
                                if(new RegExp(/^(?=[a-zA-Z0-9._]{0,50}$)(?!.*[_.]{2})[^_.].*[^_.].*$/).test(value.personal_mydata)){
                                    return true;
                                }else{
                                    return false;
                                }
                            },
                            noSpaceCase: function(value){
                                if(new RegExp(/^(?=.*[ ]).*$/).test(value.personal_mydata)){
                                    return false;
                                }else{
                                    return true;
                                }
                            },
                        }}
                        validationErrors={{
                            minLength: "Minimum 5 character length",
                            maxLength: "Maximum 20 character allowed",
                            noSpecialCase: "Special character not allowed",
                            noSpaceCase: "Space not allowed",
                        }}
                        autoComplete={"new-mydata"}
                        variant="outlined"
                        required
                    /> 
                    </div>
                </GridItem>
                <GridItem xs={12} style={{padding:'0'}}>
                <div className={classes.formggroup}>
                    <TextFieldFormsy
                        label={jsondata.email}
                        id="email"
                        name="email"
                        formControlProps={{
                            fullWidth: true
                        }}
                        validations={{
                            isEmail: true
                        }}
                        validationErrors={{
                            isEmail: 'Please enter a valid email'
                        }}
                        
                        variant="outlined"
                        required
                    /> 
                    </div>
                </GridItem>
                
                <GridItem xs={12} style={{padding:'0'}}>
                <div className={classes.formggroup}>
                    <TextFieldFormsy
                        label={jsondata.name}
                        id="name"
                        name="name"
                        formControlProps={{
                            fullWidth: true
                        }}
                        validations={{
                            minLength: 2, 
                        }}
                        validationErrors={{
                            minLength: "Minimum 2 character length", 
                        }}
                        variant="outlined"
                        required
                    /> 
                    </div>
                </GridItem>
                <GridItem xs={12} style={{padding:'0'}}>

                <div className={classes.tnctext} dangerouslySetInnerHTML={{__html: jsondata.aggreetnc.replace(jsondata.aggreetncs,"<a href='/terms-of-service' target='_blank'>"+jsondata.aggreetncs+"</a>" )}}></div>
 
                </GridItem>
            </GridContainer>
 
    </DialogContent>
    <DialogActions className={classes.modalFooterlogin}>
        <Button color="primary" className={classes.signinmodalbtn +" signupbtnnew3"} type="submit"  disabled={!isFormValid}>
            {jsondata.sbmitbtn}
        </Button>
       
    </DialogActions>
    </Formsy>                  
</Dialog>
</div>
))}
</div>
  );
}

SectionSignupboxMobile.propTypes = {
    openPopup: function(isState){
        if(isState === "signup" && setClassicModal2 != null){
            setClassicModal2(true);
          }  
          if(isState === false){
            setClassicModal2(false);
          } 
    }
};

 
SectionSignupboxMobile.openPopup = function(isState, country_id, mobile, otp){
    if(isState === "signup" && setClassicModal2 != null){
        
      if(country_id != null && Number(country_id) > 0 && mobile != null && Number(mobile) > 0 && otp != null && Number(otp) > 0){
        setCountryId(country_id);
        setMobile(mobile);
        setOtp(otp);
      } 
      setClassicModal2(true);
    }   
    if(isState === false){
        setClassicModal2(false);
      } 
  };
export default SectionSignupboxMobile;
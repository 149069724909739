//import React, {useEffect} from "react";
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Carousel from "react-slick";
import Card from "../elements/Card/Card.js";

// core components
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js"; 
import SectionLoginbox from "../sections/SectionLoginBox";
import SectionLoginboxMobile from "../sections/SectionLoginBoxMobile";
import jwtService from '../../../services/jwtService' 
import styles from "../assets/jss/components/tabsDashboardGames.js";
import Button from "../elements/CustomButtons/Button";
import Lodder from "../assets/img/lodder.gif"; 
import Loadgaming from "../assets/img/lodi.gif";  
import gamesDb from "../data/games"; 
import * as Gurl from "../../../global/Global.js"
import Zoom from '@material-ui/core/Zoom';  
import axios from 'axios';  
import CustomDropdown from "../elements/CustomDropdown/CustomDropdown"; 
// @database
import { useIndexedDB } from 'react-indexed-db';

const token = "kdkddjdj2929j38jv88fpwad83pakwdi3";
let tabData = ["{\"category_id\":0,\"category_name\":\"All Games\"}"];
var searchText = "";
// var isExistApiCalls = false;
var searchCat = false;
export default function Games(props) {

    function parseJwt(token) {
        if (!token) { return; }
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }
    var registration_type ;
    var themeInfo = getCurrentThemeInfo(); 
    function getCurrentThemeInfo(){
        if(window.localStorage.getItem('jwt_theme_token') != null){
            let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
            return themeData;
        }
    } 
    
    if(themeInfo != null && themeInfo.id != null){ 
        if(themeInfo.registration_type != null && themeInfo.registration_type > 0){ 
          registration_type = themeInfo.registration_type;
        }  
    } 

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 10,
        slidesToScroll: 1,
        autoplay: false,
        responsive: [
            {
              breakpoint: 2000,
              settings: {
                slidesToShow: 8,
                slidesToScroll: 1, 
              }
            },
            {
              breakpoint: 1700,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 1, 
              }
            },
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1, 
              }
            },
            {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1, 
                }
            },
            {
                breakpoint: 1100,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1, 
                }
            },
            {
                breakpoint: 1000,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1, 
                }
            },
            {
                breakpoint: 900,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1, 
                }
            },
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1, 
                }
            },
            {
                breakpoint: 540,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1, 
                }
            },
            {
                breakpoint: 440,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1, 
                }
            }
          ]
       }; 

    var gpsLocation = {latitude: 0, longitude: 0};
    const { add, getByIndex, update } = useIndexedDB('Games');
    styles.singlegame.borderColor = props.themeColors.bgColors.primary_bgcolor;
    styles.singlegame["&::before"].background = props.themeColors.bgColors.gradient_border;
    // styles.playgamebtn.background = props.themeColors.bgColors.primary_bgcolor; 
    styles.playgamebtn.color = props.themeColors.bgColors.text_primary_color; 
    styles.mybtn2.background = props.themeColors.bgColors.primary_bgcolor; 
    styles.mybtn2.color = props.themeColors.bgColors.text_primary_color; 
   // styles.mybtn2["& span"].color = props.themeColors.bgColors.text_primary_dark_color; 
    styles.mybtn2["&:hover"].background = props.themeColors.bgColors.main_color;
    styles.mybtn2["&:hover"].color = props.themeColors.bgColors.text_primary_dark_color;
    styles.gametabpage.color = props.themeColors.bgColors.text_primary_color; 
    styles.gametabpage["&.active"].borderBottomColor = props.themeColors.bgColors.main_color; 
    styles.gametabpage["&.active"].color = props.themeColors.bgColors.text_primary_color; 
    styles.gamelabel.color = props.themeColors.bgColors.text_primary_color;
    styles.gamesearching.background = props.themeColors.bgColors.secondary_dark_bgcolor;
    styles.gamesearching.color = props.themeColors.bgColors.text_primary_color;
      styles.gamebtnwrap.background = props.themeColors.bgColors.primary_bgcolor;
    //   styles.gamebtnwrap["& button.active"].color = props.themeColors.bgColors.main_color; 
      styles.gamebtnwrap["& button"].color = props.themeColors.bgColors.primary_color;
    styles.searchgameicon.color = props.themeColors.bgColors.primary_color;
    styles.gamecategaryname["& .slick-arrow::before"].color = props.themeColors.bgColors.text_primary_color; 
    styles.spikegame["& .spikegamehover"].borderColor = props.themeColors.bgColors.main_color; 
 
    // const [clickEvent, setTabClickEvent] = React.useState("NOVALUE");
    const [gameData, setGamesData] = React.useState("NOVALUE");
    const [gameDataOnHold, setGameDataOnHold] = React.useState("NOVALUE");

    const useStyles = makeStyles(styles);
    const classes = useStyles();
  //alert(Gurl.GETGAMES);
    function loadGamesData(){
        // const request = axios.post(Gurl.GETGAMES, {
        //     "token" : token,
        //     "gameids" : props.gameIds,
        // });
        const request = axios.post(Gurl.GETGAMES, {
            "token" : (window.localStorage.getItem('jwt_access_token') != null) ? window.localStorage.getItem('jwt_access_token') : "NOTOKEN",
            "gameids" : props.gameIds,
        });
        request.catch((error) => { 
            setGamesData("NOVALUE");
        });
        request.then((response) => { 
            if(response.data.data != null){ 
                if(Array.isArray(response.data.data)){ 
                    try{
                        getByIndex('id', props.gameIds).then(personFromDB => {
                            if(personFromDB !== null && personFromDB !== undefined && JSON.stringify({id: props.gameIds, ...response.data.data}) !== JSON.stringify(personFromDB)){
                                update({id: props.gameIds, data: response.data.data}).then(
                                    event => {
                                        //alert('ID Generated: ', JSON.stringify(event.target));
                                    },
                                    error => {
                                        //alert(error);
                                    }
                                );
                            }else{
                                if(JSON.stringify({id: props.gameIds, ...response.data.data}) !== JSON.stringify(personFromDB)){
                                    add({id: props.gameIds, data: response.data.data}).then(
                                        event => {
                                        //alert('ID Generated: ', JSON.stringify(event.target));
                                        },
                                        error => {
                                        //alert(error);
                                        }
                                    );
                                }
                            }
                        });
                    }catch(error){

                    }
                }
                setGameDataInFormat(response.data.data);
                setGameDataOnHold(response.data.data);
            }else{
                setGamesData("NO GAMES FOUND");
                //props.propTypes.openPopup("popup");
            }
        });  
    }

    function setGameDataInFormat(dataResponse){
        var myOutPut = "";
        if(Array.isArray(dataResponse)){ 
            myOutPut = (
                dataResponse.map((jsondata) => {
                    /*var newData = {category_id:jsondata.category_id, category_name: jsondata.category_name};
                    if(tabData.indexOf(JSON.stringify(newData)) === -1){
                        tabData.push(JSON.stringify(newData));
                    }*/
                    let selectedProduct = undefined;
                    
                    if(jsondata.categories_info != null){
                        var catInfo = JSON.parse(jsondata.categories_info);
                        catInfo.map((catDataJson) => {
                            var newData = {category_id:catDataJson.category_id, category_name: catDataJson.name};
                            if(tabData.indexOf(JSON.stringify(newData)) === -1){
                                tabData.push(JSON.stringify(newData));
                            }
                        });
                        if(searchCat !== false && searchCat !== 0){
                            selectedProduct = catInfo.find(inCatInfo => inCatInfo.category_id === searchCat);
                        }
                    }
                    
                    if(selectedProduct != undefined || searchCat === false || searchCat === 0){
                        if(searchText.length === 0 || (searchText.length > 0 &&  ((jsondata.tags != null && jsondata.tags.toLowerCase().includes(searchText.toLowerCase())) || (jsondata.game_name != null && jsondata.game_name.toLowerCase().includes(searchText.toLowerCase()))))){
                            var limit = "Play Now";
                            if(jsondata.table_limits !== null && jsondata.table_limits.length > 0 && jsondata.currency_code !== null && jsondata.currency_code.length > 0){
                                let limitJSON = JSON.parse(jsondata.table_limits);
                                if(limitJSON[jsondata.currency_code] != null){
                                    let limitDetails = limitJSON[jsondata.currency_code];
                                    limit = limitDetails.symbol + " " + limitDetails.min + " - " + limitDetails.max;
                                }
                            }
                            return (<GridItem className={classes.gridgame + " spikegame " + classes.spikegame} xs={6} sm={6} md={3} xl={2} key={jsondata.id}>
                            <Zoom in={true} timeout={1000} key={"gzoom_" + jsondata.game_id}> 
                                <div className={"singlegame " + classes.singlegame} key={"gdiv_" + jsondata.game_id}>
                                    <img  key={"gimg1_" + jsondata.game_id} src={((jsondata.game_icon != null && (jsondata.game_icon.substr(0,4) === "http" || jsondata.game_icon.substr(0,2) === "//") && ((navigator != null && navigator.onLine != null && navigator.onLine === true) || (navigator === null && navigator.onLine === null))) ? jsondata.game_icon : "assets/img/" + ((jsondata.game_icon != null && jsondata.game_icon.length > 0  && ((navigator != null && navigator.onLine != null && navigator.onLine === true) || (navigator === null && navigator.onLine === null))) ? jsondata.game_icon : "game_img.jpg"))} alt={""} />
                                    {(() => {
                                    if(jsondata.provider_icon !== undefined && jsondata.provider_icon !== null && jsondata.provider_icon.length > 0)
                                    {
                                        return(
                                            <img  key={"gimg2_" + jsondata.game_id} style={{
                                                width:"32px", 
                                                height:"32px", 
                                                position: "absolute", 
                                                top:"5px", 
                                                right:"5px"}} src={jsondata.provider_icon}/>
                                        )
                                    }else {
                                        return(   
                                            <span  key={"gspan_" + jsondata.game_id} style={{
                                                width:"32px", 
                                                height:"32px", 
                                                position: "absolute", 
                                                top:"5px", 
                                                right:"5px"}}  ></span>
                                        )
                                    }
                                })()}
                                    <span  key={"gspan2_" + jsondata.game_id} className={"gamelabel " + classes.gamelabel}>{jsondata.game_name}</span>
                                    <div  key={"gdiv2_" + jsondata.game_id} className={"spikegamehover"}>
                                    
                                    <table key={"gtb_" + jsondata.game_id}> 
                                    <tbody>
                                        <tr key={"gtr_" + jsondata.game_id}>
                                            <td key={"gtd_" + jsondata.game_id}>
                                                <Button
                                                    key={"gbtn_" + jsondata.game_id}
                                                    onClick={(event) => fetchAuthenticationToken(jsondata.game_id, event)}
                                                    title={jsondata.game_name}
                                                    className={"mybtn2 " + classes.playgamebtn}
                                                >
                                                    {limit}
                                                </Button> 
                                                
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                   
                                    </div>
                                </div> 
                            </Zoom>            
                        </GridItem>)
                        }
                    }
                    
                })
            );
        }else{
            var jsondata = dataResponse;
            var limit = "Play Now";
            if(jsondata.table_limits !== null && jsondata.table_limits.length > 0 && jsondata.currency_code !== null && jsondata.currency_code.length > 0){
                let limitJSON = JSON.parse(jsondata.table_limits);
                if(limitJSON[jsondata.currency_code] != null){
                    let limitDetails = limitJSON[jsondata.currency_code];
                    limit = limitDetails.symbol + " " + limitDetails.min + " - " + limitDetails.max;
                }
                
            }
            myOutPut = ( 
                <GridItem className={classes.gridgame  + " spikegame " + classes.spikegame} xs={6} sm={6} md={3} xl={2}>
                    <Zoom in={true} timeout={1000}> 
                    <div className={" singlegame " + classes.singlegame}>
                        <img src={((jsondata.game_icon != null && (jsondata.game_icon.substr(0,4) === "http" || jsondata.game_icon.substr(0,2) === "//") && ((navigator != null && navigator.onLine != null && navigator.onLine === true) || (navigator === null && navigator.onLine === null))) ? jsondata.game_icon : "assets/img/" + ((jsondata.game_icon != null && jsondata.game_icon.length > 0  && ((navigator != null && navigator.onLine != null && navigator.onLine === true) || (navigator === null && navigator.onLine === null))) ? jsondata.game_icon : "game_img.jpg"))} alt={""} />
                        {(() => {
                            if(jsondata.provider_icon === undefined || jsondata.provider_icon.length > 0 || jsondata.provider_icon === null)
                            {
                                return(
                                    <img style={{
                                        width:"32px", 
                                        height:"32px", 
                                        position: "absolute", 
                                        top:"5px", 
                                        right:"5px"}} src={jsondata.provider_icon}/>
                                )
                            }else {
                                return(   
                                    <span style={{
                                        width:"32px", 
                                        height:"32px", 
                                        position: "absolute", 
                                        top:"5px", 
                                        right:"5px"}}  ></span>
                                )
                            }
                        })()}                
                        <span className={"gamelabel " + classes.gamelabel}>{JSON.stringify(jsondata)}{jsondata.game_name}</span>
                        <div className={"spikegamehover"}>
                        
                      
                         <table> 
                                                <tr>
                                                    <td>
                                                        <Button
                                                            onClick={(event) => fetchAuthenticationToken(jsondata.game_id, event)}
                                                            title={jsondata.game_name}
                                                            className={"mybtn2 " + classes.playgamebtn}
                                                        >
                                                            {limit}
                                                        </Button> 
                                                        
                                                    </td>
                                                </tr>
                                            </table>
                        </div>
                    </div> 
                    </Zoom>
                </GridItem>
            );
        }
        setGamesData(myOutPut);
        
    }
    function searchTextChange(event){
        if(event.target !== null && event.target.value !== null){
            searchText = event.target.value;
            try{
                if(gameDataOnHold !== "NOVALUE" && gameDataOnHold != null){
                    setGameDataInFormat(gameDataOnHold); 
                }else{
                    getByIndex('id', props.gameIds).then(allData => {
                        if(allData === null || allData === undefined){
                            loadGamesData();
                        }else{
                            setGameDataInFormat(allData.data); 
                        }
                    });
                }
            }catch(error){
                
            }
        }
    }
    function openCategoryWise(categoryId){
        searchCat = categoryId;
        try{
            if(gameDataOnHold !== "NOVALUE" && gameDataOnHold != null){
                setGameDataInFormat(gameDataOnHold); 
            }else{
                getByIndex('id', props.gameIds).then(allData => {
                    if(allData === null || allData === undefined){
                        loadGamesData();
                    }else{
                        setGameDataInFormat(allData.data); 
                    }
                });
            }
        }catch(error){
            
        }
    }
    
    const fetchAuthenticationToken = async function(myGameId, event) {
      
        if(parseInt(myGameId) > 0 && window.localStorage.getItem('jwt_access_token') != null && jwtService.isAuthenticationLogin()){
            if(event.target != null){
                event.target.setAttribute("style","pointer-events:none;cursor:default;");
                document.getElementById("showGameLoad").style.display = "flex";
            }
            await getMyLocation();
            var newSenderArray = {"gameid": myGameId,"token":window.localStorage.getItem('jwt_access_token'),'latitude': gpsLocation.latitude, 'longitude': gpsLocation.longitude}
            var gettingToken = await getGamesToken(newSenderArray);
            if(gettingToken != null){
                //alert("OutPUT:" + JSON.stringify(gettingToken));
            }
            if(gettingToken.status != null && gettingToken.status === true){
                if(gettingToken.MYGAMEAUTH != null && gettingToken.MYGAMEAUTH.length > 0){
                    var finalAuthUrl = JSON.parse(gettingToken.MYGAMEAUTH);
                    if(finalAuthUrl.entry != null && finalAuthUrl.entry.length > 0){
                      
                        //window.open(finalAuthUrl.entry, "_blank","fullscreen,scrollbars") //to open new page
                        window.localStorage.setItem("game_access", finalAuthUrl.entry);
                        window.open("/playnow", "_self") //to open new page
                        if(event.target != null){
                            event.target.setAttribute("style","pointer-events:auto;cursor:pointer;");
                        }
                    }else{
                        if(finalAuthUrl.error != null){
                            if(isObject(finalAuthUrl.error)){
                                // var JSONInfo = finalAuthUrl.error;
                                if(isObject(finalAuthUrl.error.data) && finalAuthUrl.error.data.message !== undefined){
                                    document.getElementById("showGameLoad").style.display = "none";
                                    props.sectionPopup.openPopup("popup", "Error", finalAuthUrl.error.data.message);
                                }else{
                                    props.sectionPopup.openPopup("popup", "Error", "Getting Authentication Issue on server. Please contact to Sponsor.");
                                }
                            }else{
                                document.getElementById("showGameLoad").style.display = "none";
                                props.sectionPopup.openPopup("popup", "Error", finalAuthUrl.error);
                            }
                            getMyLocation();
                        }
                        if(event.target != null){
                        
                            event.target.setAttribute("style","pointer-events:auto;cursor:pointer;");
                        }
                    }
                }else{
                    if(event.target != null){
                        event.target.setAttribute("style","pointer-events:auto;cursor:pointer;");
                    }
                }
            }else{
                if(event.target != null){
                    event.target.setAttribute("style","pointer-events:auto;cursor:pointer;");
                }
            }
            
        }else{
            if(registration_type === 1){ 
                SectionLoginboxMobile.openPopup("login");
            }else{
                SectionLoginbox.openPopup("login");
            }  
            if(event.target != null){
                event.target.setAttribute("style","pointer-events:auto;cursor:pointer;");
            }
        }
    }
    let isObject = function(a) {
        return (!!a) && (a.constructor === Object);
    };
    async function getMyLocation() {
        const location = window.navigator && window.navigator.geolocation
        
        if (location) {
            let pro = new Promise((resolve,reject) =>  {
                location.getCurrentPosition((position) => {
                    if(window.localStorage.getItem('jwt_access_token') != null){
                        gpsLocation = {latitude: position.coords.latitude, longitude: position.coords.longitude};
                        resolve({latitude: position.coords.latitude, longitude: position.coords.longitude});
                    }
                }, (error) => {
                    //this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
                    resolve({status:false});
                });
            }); 
            const val = await pro;
            return val;
        }else{
            return;
        }
    
      }
    async function getGamesToken(newSenderArray) {
        let pro = new Promise((resolve,reject) => {
            try {
                const request = axios.post(Gurl.GETGAMEAUTH, newSenderArray,{headers:{"Accept": "application/json"}});
                request.catch((error) => {
                    var data = {
                        "status":false,
                        "data":error
                    }
                    resolve(data);
                })
                request.then((response) => {
                    if(response.data != null && response.data.status != null && response.data.status === true){
                        resolve(response.data);
                    }else{
                        var data = {
                            "status":false,
                            "data":response
                        }
                        resolve(data);
                    }
                })
            } catch (error) {
                console.log("MYIN:"+error);
                var data = {
                    "status":false,
                    "data":error
                }
                resolve(data);
            }
        }); 
        const val = await pro;
        return val;
    }
    
    if(gameData === "NOVALUE"){
        setGamesData(<img src={Lodder} className={classes.loadimg} alt=""/>); 
        if(navigator != null && navigator.onLine != null && navigator.onLine !== true){
            getByIndex('id', props.gameIds).then(allData => {
                if(allData === null || allData === undefined){
                    loadGamesData();
                }else{
                    setGameDataInFormat(allData.data); 
                }
            });
        }else{
            loadGamesData();
        }
    }
    
    //alert("NEW:" + JSON.stringify(tabData));

    return (
    
        <div id="nav-tabs"> 
        {(() => {
            if(tabData !== null) {
            return( 
                    <div className={classes.gamebtnwrap + " gamebtnwarp"}>
           <div className={classes.gamescrolltab}> 
            
                <Card carousel className={classes.gamecategaryname}>
                <Carousel {...settings}> 
                    {tabData.map((jsondata) => {
                    jsondata = JSON.parse(jsondata);
                    return (
                        <Button
                            color="transparent"
                            className={classes.tabbtn + " tabbtn" + ((jsondata.category_id === searchCat || (searchCat === false && jsondata.category_id === 0)) ? " active " : " ") + classes.gametabpage  }
                            onClick={() => openCategoryWise(jsondata.category_id)}
                        >
                    {jsondata.category_name}
                        </Button> 
                    )
                    })}  
                </Carousel>
                </Card>
                </div> 
                
                <input type="text" onChange={(event) => {searchTextChange(event)}} className={classes.gamesearchingonly + " gamesearching " + classes.gamesearching} placeholder="Search Game..." />
             
                <div style={{clear:"both"}}></div>    
                </div>     
            )}
        })()}
            <GridContainer className={classes.gameboxico}>                                 
                {gameData}
              </GridContainer>
              <div id="showGameLoad" style={{position:"fixed", width:"77%", top:"0",display:"none", justifyContent: "center", height:"100%", background:"rgba(0,0,0,0.5)", zIndex: "9999"}}><img style={{alignSelf: "center", width:"60px"}} src={Loadgaming} alt=""/></div>
        </div>    
   );
     
}
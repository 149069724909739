
const SectionAbout = {
  allbody:{display:"none"},
  preloader:{
    position:"fixed",
    top:"0",
    left:"0",
    width:"100%",
    height:"100%",
    backgroundColor:"#000",
    zIndex:"9999999",
    transition:"all 0.3s ease-in", 
    display:"flex",
    flexDirection:"column", 
    alignItems:"center",
    justifyContent:"center",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      padding: "20px 0px 20px",
    },
  },
  bandname:{
    color:"#fff", 
    textAlign:'center',
    fontFamily:'Ubuntu',
    textTransform:'capitalize'
  },
  loader:{
    position:"relative",
    width:"60px",
    height:"60px",
    borderRadius:"50%",
    margin:"75px",
    display:"inline-block",
    verticalAlign:"middle",
  },
  loader1:{   
  },
  loaderOutter:{
    position:"absolute",
    border:"4px solid #ffb80c",
    borderLeftColor:"transparent",
    borderBottom:"0",
    width:"100%",
    height:"100%",
    borderRadius:"50%",
    animation:"loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite",
  },
  loaderInner:{
    position:"absolute",
    border:"4px solid #ffb80c",
    borderRadius:"50%",
    width:"40px",
    height:"40px",
    left:"calc(50% - 20px)",
    top:"calc(50% - 20px)",
    borderRight:"0",
    borderTopColor:"transparent",
    animation:"loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite",
  }            
};

export default SectionAbout;



const accountsDb = {
    en: [
        {
            'id':'3923923894',
           'pageheading': 'My Bets', 
            'btn_one': 'All Bets', 
            'btn_two': 'Open',
            'btn_three':'Won',
            'btn_four': "Lost",
            'btn_five': "Returned",
            'btn_six': "Won Returned",
            'betid': "Bet Id",
            'bettype': "Bet Type",
            'period': 'Period',
            'start_date': 'Start Date',
            'end_date': 'End Date', 
            'showbtn':'Show',
        },
        
    ],
    tm: [
        {
            'id':'3923923894', 
            'pageheading': 'மை பெட்ஸ்',
            'btn_one': 'ஆல் பெட்ஸ்',
            'btn_two': 'திற',
            'btn_three': 'வென்றது',
            'btn_four': "இழந்தது",
            'btn_five': "திரும்பியது",
            'btn_six': "திரும்பியது",
            'betid': "பந்தயம் ஐடி",
            'bettype': "பந்தய வகை",
            'period': 'காலம்',
            'start_date': 'தொடக்க தேதி',
            'end_date': 'இறுதி தேதி',
            'showbtn': 'காட்டு',
        },
        
    ],
    kn: [
        {
            'id':'3923923894', 
            'pageheading': 'ಮೈ ಬೆಟ್ಸ್',
            'btn_one': 'ಆಲ್ ಬೆಟ್ಸ್',
            'btn_two': 'ಓಪನ್',
            'btn_three': 'ಗೆದ್ದಿದೆ',
            'btn_four': "ಕಳೆದುಹೋಯಿತು",
            'btn_five': "ಹಿಂತಿರುಗಿಸಲಾಗಿದೆ",
            'btn_six': "ಗೆದ್ದಿದೆ",
            'betid': "ಬೆಟ್ ಐಡಿ",
            'bettype': "ಬೆಟ್ ಟೈಪ್",
            'period': 'ಅವಧಿ',
            'start_date': 'ಪ್ರಾರಂಭ ದಿನಾಂಕ',
            'end_date': 'ಅಂತಿಮ ದಿನಾಂಕ',
            'showbtn': 'ತೋರಿಸು',
        },
        
    ],
    ne: [
        {
            'id':'3923923894', 
            'pageheading': 'मेरो बेट्स',
            'btn_one': 'सबै बेट्स',
            'btn_two': 'खोल्नुहोस्',
            'btn_three': 'जित्यो',
            'btn_four': "हरायो",
            'btn_five': "फिर्ता",
            'btn_six': "वोन फिर्ता गरियो",
            'betid': "बेट आईडी",
            'bettype': "शर्त प्रकार",
            'period': 'अवधि',
            'start_date': 'सुरूवात मिति',
            'end_date': 'अन्त्य मिति',
            'showbtn': 'देखाउनुहोस्',
        },
        
    ],
    tl: [
        {
            'id':'3923923894',
            'pageheading': 'నా బెట్స్', 
            'btn_one': 'అన్ని బెట్స్', 
            'btn_two': 'తెరవండి',
            'btn_three':'గెలిచింది',
            'btn_four': "కోల్పోయిన",
            'btn_five': "తిరిగి వచ్చింది",
            'btn_six': "గెలిచింది",
            'betid': "పందెం ఐడి",
            'bettype': "పందెం రకం",
            'period': 'కాలం',
            'start_date': 'ప్రారంబపు తేది',
            'end_date': 'చివరి తేది', 
            'showbtn':'చూపించు',
        },
        
    ],
    hi: [
        {
            'id':'3923923892',
             'pageheading': 'मेरे दांव', 
            'btn_one': 'सभी दांव', 
            'btn_two': 'खुला हुआ',
            'btn_three':'जीत लिया',
            'btn_four': "खो गया",
            'btn_five': "लौटाया हुआ",
            'btn_six': "जीत गए",
            'betid': "शर्त आईडी",
            'bettype': "शर्त प्रकार",
            'period': 'अवधि',
            'start_date': 'आरंभ करने की तिथि',
            'end_date': 'अंतिम तिथि',  
            'showbtn':'प्रदर्शन',   
        },
    ]
}
export default accountsDb;

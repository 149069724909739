import React, {useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";
import CustomTabs from "../elements/CustomTabs/CustomTabs.js";

import dashboardgameDb from "../data/dashboard.js";

import styles from "../assets/jss/components/tabsDashboardGames.js";
import Button from "../elements/CustomButtons/Button"; 
import * as Gurl from "../../../global/Global.js"
import axios from 'axios';
const token = "kdkddjdj2929j38jv88fpwad83pakwdi3"; 

export default function Dashboard(props) {

    
    styles.singlegame.border = props.themeColors.bgColors.gameboxborder;
    styles.mybtn2.background = props.themeColors.bgColors.headbackground; 
    styles.mybtn2["&:hover"].background = props.themeColors.bgColors.golden;
    styles.mybtn2["&:hover"].color = props.themeColors.bgColors.goldenbtntext;
    const [clickEvent, setTabClickEvent] = React.useState("NOVALUE");
    const [gameData, setGamesData] = React.useState("NOVALUE");
    const useStyles = makeStyles(styles);
    const classes = useStyles();
  //alert(Gurl.MYGAMES);
  useEffect(() => {
    setTabClickEvent(0);
     }, []);
if(clickEvent !== "NOVALUE"){
   
    if(clickEvent === 0){
        const request = axios.post(Gurl.MYGAMES, {
        
            params: {
                token
            },
            headers: {
                "Content-Type" : "application/json"
            }
        });
        request.then((response) => { 
        
            
            if(response.data.data != null){ 
                
                var myOutPut = (
                    response.data.data.map((jsondata) => (
                    <GridItem xs={12} sm={6} md={2}>
                        <div className={classes.singlegame + " singlegame"}>
                            <img src="assets/img/gami1.jpg" alt={""} />
                            <Button
                                href="#"
                                title={jsondata.game_name}
                                className={classes.mybtn2}
                            >
                                Play Now
                            </Button>
                        </div> 
                    </GridItem>
                    ))
                );
                setGamesData(myOutPut);
            };
        }); 
    }else if(clickEvent === 1){
        setGamesData("");
    }else if(clickEvent === 2){
        setGamesData("");   
    }
    setTabClickEvent("NOVALUE");
}
    return (
    
<div>
        

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                
             <div className={classes.searchboxi}>
              <i className="fa fa-search"></i>  
              <input type="text" placeholder="Search Game Here.."   /> 
              </div> 
            
                <CustomTabs
                    themeColors={props.themeColors}
                    plainTabs
                  border="round"
                    clickEvent={setTabClickEvent}
                    tabs={dashboardgameDb[sessionStorage.getItem('lang')].map((jsondata) => (
                        {
                            tabName: (<span>{jsondata.tagName}</span>),
                             
                            tabContent: (
                                     <div>
                                   
                                <CustomTabs
                                plainTabs
                                    headerColor="primary"
                                    tabs={jsondata.categorie.map((jsondata) => (
                                        {
                                            tabName: (<span><img src={jsondata.subcategorietitle} alt={""} /></span>),
                                            tabContent: (
                                                <GridContainer>
                                                      
                                                    {gameData}
                                                   


                                                </GridContainer>
                                            )
                                        }
                                            ))}
                                />
                                </div>
                            )
                        }
                    ))}

                />




            </GridItem>
          </GridContainer>
          </div>
                    );
     
}
import React, {useRef, useState} from "react";
// @material-ui/core components
import 'react-accessible-accordion/dist/fancy-example.css';

import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";
import Formsy from 'formsy-react'; 
import StatementList from "../sections/SectionStatementList";

import styles from "../assets/jss/components/tabsStatementstyle.js";
//import Button from "../elements/CustomButtons/Button"; 
import statementDb from "../data/statement.js"; 
//import ListItem from "@material-ui/core/ListItem";
//import List from "@material-ui/core/List";
import {TextFieldFormsy, SelectFormsy} from '../../../@casino';
// import { useTranslation } from 'react-i18next'
import {MenuItem} from '@material-ui/core'; 
const useStyles = makeStyles(styles);



export default function Statement(props) {
    const [paymentType, setPaymentType] = useState("NO");
    const [durationType, setDurationType] = useState("NO");
    const formRef = useRef(null); 

    if(props.themeColors != null){
       // styles.messagebox.background = props.themeColors.bgColors.messagebox;
        styles.listItem["&:nth-child(even)"].background = props.themeColors.bgColors.primary_dark_bgcolor;
        styles.listItem.color = props.themeColors.bgColors.text_primary_color;
        styles.selectboxx["& label"].color = props.themeColors.bgColors.text_primary_color;
        styles.selectboxx["& div div"].color = props.themeColors.bgColors.text_primary_color;
        styles.formtodate.color = props.themeColors.bgColors.text_primary_color;
        styles.formtodate["& label"].color = props.themeColors.bgColors.text_primary_color;
        styles.formtodate["& input"].color = props.themeColors.bgColors.text_primary_color;
     //   styles.statementbox.background = props.themeColors.bgColors.bggray;
      //  styles.pageheadinginner.color = props.themeColors.bgColors.pageheadinginner;
       styles.depositbtn.backgroundImage = props.themeColors.bgColors.main_color;
       styles.depositbtn.background = props.themeColors.bgColors.main_color;
        // styles.depositbtn.color = props.themeColors.bgColors.text_primary_dark_color;

        styles.selectboxx["& label"].fontFamily = props.themeColors.bgColors.base_font;
        styles.formtodate["& label"].fontFamily = props.themeColors.bgColors.base_font;
        styles.formtodate["& input"].fontFamily = props.themeColors.bgColors.base_font;
        styles.listItem.fontFamily = props.themeColors.bgColors.base_font;
        styles.depositbtn.fontFamily = props.themeColors.bgColors.base_font;
    }
    

    const dateNow = new Date(); // Creating a new date object with the current date and time
    const year = dateNow.getFullYear(); // Getting current year from the created Date object
    const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const month = // Setting current Month number from current Date object
        monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
        ? `0${monthWithOffset}`
        : monthWithOffset;
    const date =
        dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${dateNow.getUTCDate()}`
        : dateNow.getUTCDate();
    //dateNow.setDate(dateNow.getDate() - 7);
    var newYear = ((parseInt(month) === 6 && parseInt(date) === 1) || parseInt(month) < 6) ? year - 1 : year;
    var minDateNow = new Date(newYear, month - 6, date);
    const minyear = minDateNow.getFullYear(); // Getting current year from the created Date object
    const minmonthWithOffset = minDateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const minmonth = // Setting current Month number from current Date object
        minmonthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
        ? `0${minmonthWithOffset}`
        : minmonthWithOffset;
    const mindate =
        minDateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${minDateNow.getUTCDate()}`
        : minDateNow.getUTCDate();

    var oneDateNow = new Date();
    oneDateNow.setDate(oneDateNow.getDate() - 30);
    const oneyear = oneDateNow.getFullYear(); // Getting current year from the created Date object
    const onemonthWithOffset = oneDateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const onemonth = // Setting current Month number from current Date object
        onemonthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
        ? `0${onemonthWithOffset}`
        : onemonthWithOffset;
    const onedate =
    oneDateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${oneDateNow.getUTCDate()}`
        : oneDateNow.getUTCDate();

    var minSevenDateNow = new Date();
    minSevenDateNow.setDate(minSevenDateNow.getDate() - 7);
    const min7year = minSevenDateNow.getFullYear(); // Getting current year from the created Date object
    const min7monthWithOffset = minSevenDateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const min7month = // Setting current Month number from current Date object
        min7monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
        ? `0${min7monthWithOffset}`
        : min7monthWithOffset;
    const min7date =
    minSevenDateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${minSevenDateNow.getUTCDate()}`
        : minSevenDateNow.getUTCDate();
    
    const MaxDateAvailable = `${year}-${month}-${date}`;
    const MinDateAvailable = `${minyear}-${minmonth}-${mindate}`;
    const OneMonthDateAvailable = `${oneyear}-${onemonth}-${onedate}`;
    const SevenDateAvailable = `${min7year}-${min7month}-${min7date}`;

    const [dateConfig, setDateConfig] = React.useState({start: SevenDateAvailable, end: MaxDateAvailable});
    
    function selectDurationType(event){
        if(event.target != null && event.target.value === "1"){
        setDateConfig({start: SevenDateAvailable, end: MaxDateAvailable});
        } else if(event.target != null && event.target.value === "2"){
        setDateConfig({start: OneMonthDateAvailable, end: MaxDateAvailable});
        } else if(event.target != null && event.target.value === "3"){
        setDateConfig({start: MinDateAvailable, end: MaxDateAvailable});
        }
    }
    function changeStartDate(event){
        if(event.target != null && event.target.value > dateConfig.end){
            setDateConfig({start: dateConfig.end, end: dateConfig.end});
        }else if(event.target != null && event.target.value < MinDateAvailable){
            setDateConfig({start: MinDateAvailable, end: dateConfig.end});
        }else if(event.target != null && event.target.value > MaxDateAvailable){
            setDateConfig({start: MaxDateAvailable, end: dateConfig.end});
        }else{
            setDateConfig({start: event.target.value, end: dateConfig.end});
        }
    }
    function changeEndDate(event){
        if(event.target != null && event.target.value < dateConfig.start){
            setDateConfig({start: dateConfig.start, end: dateConfig.start});
        }else if(event.target != null && event.target.value < MinDateAvailable){
            setDateConfig({start: dateConfig.start, end: MinDateAvailable});
        }else if(event.target != null && event.target.value > MaxDateAvailable){
            setDateConfig({start: dateConfig.start, end: MaxDateAvailable});
        }else{
            setDateConfig({start: dateConfig.start, end: event.target.value});
        }
    }

//    const { t } = useTranslation()
    const classes = useStyles();

    function handleSubmit(model) { 
        
    }

    const selectPaymentType = function(event){
        if(event.target != null && event.target.value != null){
            setPaymentType(event.target.value);
        }
    }
    
    if(durationType === "NO"){
        if(statementDb[sessionStorage.getItem('lang')] != null && statementDb[sessionStorage.getItem('lang')].periodoptions != null){
            setDurationType(statementDb[sessionStorage.getItem('lang')].periodoptions[0].id);
        }
    }
    if(paymentType === "NO"){
        if(statementDb[sessionStorage.getItem('lang')] != null && statementDb[sessionStorage.getItem('lang')].paymentoptions != null){
            setDurationType(statementDb[sessionStorage.getItem('lang')].paymentoptions[0].id);
        }
    }
    return ( 
            

            <div   > 
                {statementDb[sessionStorage.getItem('lang')].map((jsondata) => (
                    <GridContainer key={jsondata.id}>
                        <GridItem xs={12} sm={12} md={12}>

                           
                            <div className={classes.statementbox}>
                                <div className={classes.statementfilterbox}>
                                    <Formsy   
                                        onValidSubmit={handleSubmit}
                                        ref={formRef}
                                        className={classes.form + " flex flex-col justify-center w-full"}
                                    > 
                                        <GridContainer>
                                            
                                            <GridItem xs={12} sm={12} md={3}> 
                                            <SelectFormsy 
                                                name="payment_type" 
                                                label={jsondata.paymentheading}
                                                className={classes.selectboxx + " selectbox"} 
                                                onChange={(event) => selectPaymentType(event)}
                                                value={((paymentType === "NO") ? jsondata.paymentoptions[0].id : paymentType)}
                                            >
                                                {jsondata.paymentoptions.map((jsondata) => (   
                                                    <MenuItem key={jsondata.id} value={jsondata.id}>{jsondata.paymentoption}</MenuItem>
                                                ))}    
                                            </SelectFormsy> 
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={3}>
                                                <SelectFormsy 
                                                    name="duration" 
                                                    label={jsondata.periodheading}
                                                    className={classes.selectboxx + " selectbox"}
                                                    onChange={(event) => selectDurationType(event)}
                                                    value={((durationType === "NO") ? 1 : durationType)}
                                                >
                                                    {statementDb[sessionStorage.getItem('lang')][0].periodoptions.map((jsondata) => (   
                                                        <MenuItem key={jsondata.id} value={jsondata.id}>{jsondata.periodoption}</MenuItem>
                                                    ))}    
                                                </SelectFormsy>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={3}>
                                            <TextFieldFormsy 
                                                className={classes.formtodate + " MuiInputBase-input MuiOutlinedInput-input"}
                                                label={jsondata.start_date}
                                                name="start_date"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                type="date"
                                                defaultValue={dateConfig.start}
                                                onChange={(event) => {changeStartDate(event)}}
                                                value={dateConfig.start}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                InputProps={{inputProps: { min: MinDateAvailable, max: MaxDateAvailable} }}
                                                variant="outlined"
                                                required
                                            />  
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={3}>
                                            <TextFieldFormsy 
                                                className={classes.formtodate + " MuiInputBase-input MuiOutlinedInput-input"}
                                                label={jsondata.end_date}
                                                name="end_date"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                type="date"
                                                defaultValue={dateConfig.end}
                                                onChange={(event) => {changeEndDate(event)}}
                                                value={dateConfig.end}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                InputProps={{inputProps: { min: MinDateAvailable, max: MaxDateAvailable} }}
                                                variant="outlined"
                                                required
                                            /> 
                                            </GridItem>
                                             
                                        </GridContainer>
                                    </Formsy>
                                </div>
                            </div>

                        </GridItem>
                    </GridContainer>
                ))}
                <div className={classes.depositlistwarp}>
                <div className={classes.depositlistw} >  
                <StatementList themeColors={props.themeColors} statement={true} paymentType={paymentType} durationType={durationType}  startDate={dateConfig.start} endDate={dateConfig.end}/>
                 
                </div></div>    
            </div> 

    );
}

import React from 'react';
import {ThemeProvider} from '@material-ui/styles';
import {useSelector} from 'react-redux';

function CasinoTheme(props)
{
    
    const mainTheme = useSelector(({casino}) => casino.settings.mainTheme);
    
    return (
        <ThemeProvider theme={mainTheme}>
            {props.children}
        </ThemeProvider>
    )
}

export default React.memo(CasinoTheme);

import React, {useEffect, useRef, useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";


import styles from "../assets/jss/components/contact.js";
import Button from "../elements/CustomButtons/Button";
// import {IconButton, MenuItem} from "@material-ui/core"; 
import { MenuItem} from "@material-ui/core"; 
import classNames from "classnames"; 
import leftimgconatct from "../assets/img/contact-left.png";
import {useDispatch, useSelector} from 'react-redux';
// import * as globalurl from '../../../global/Global.js'; 
import * as authActions from '../../../auth/casino/actions';
import Formsy from 'formsy-react'; 
import {TextFieldFormsy, SelectFormsy, CheckboxFormsy} from '../../../@casino';
import seccontactDb from "../data/seccontact.js";
// import { useTranslation } from 'react-i18next' 
const useStyles = makeStyles(styles);

export default function SectionContact(props) {

    const [isFormValid, setIsFormValid] = useState(false);
    const isPermission = 1;
    const userAuth = useSelector(({auth}) => auth.user);
    const formRef = useRef(null); 
    const dispatch = useDispatch();
    
    useEffect(() => {
        if ( userAuth.error && (userAuth.error.parent_banker_id || userAuth.error.sender_banker_id || userAuth.error.amount || userAuth.error.message) )
        {
            if(userAuth.error.message != null){
                document.body
                .getElementsByClassName("message")[0] 
                .innerHTML=userAuth.error.message
            }else{
                formRef.current.updateInputsWithError({
                    ...userAuth.error
                });
            }
        }else{
            if(userAuth.success != null && userAuth.success === true){
                alert("Contact request sent successfully.")
                window.location.reload();
            }
        }
    }, [userAuth.error, userAuth.success]);

    function disableButton()
    {
        setIsFormValid(false);
        document.body
        .getElementsByClassName("signupbtn")[0]
          .setAttribute("style", "opacity:0.5;");
    }
    function enableButton()
    {
        setIsFormValid(true);
        document.body
        .getElementsByClassName("signupbtn")[0]
          .setAttribute("style", "opacity:1;");
    }
 
    function handleSubmit(model)
    {
         //CONTACTREQUEST
         dispatch(authActions.contactRequest(model));  
    }
    // function checkNow(isChecked){
    //     if(isChecked === true){
    //         setIsPermission(1)
    //     }else{
    //         setIsPermission(0)
    //     }
    // }
    if(props.themeColors != null){
        styles.subtitle.color = props.themeColors.bgColors.text_secondary_color
        styles.title.color = props.themeColors.bgColors.text_primary_color
        styles.text.color = props.themeColors.bgColors.text_secondary_dark_color
        styles.contactbox.background = props.themeColors.bgColors.primary_bgcolor 
        styles.mybtn1.backgroundImage = props.themeColors.bgColors.main_color
        styles.mybtn1.color = props.themeColors.bgColors.text_primary_in_color  
        styles.contactbox.background = props.themeColors.bgColors.primary_bgcolor 
        styles.contacttitle.color = props.themeColors.bgColors.text_primary_color
        styles.contactbox["& .MuiFormLabel-root.Mui-focused"].color = props.themeColors.bgColors.text_secondary_dark_color
        styles.contactbox["& input"].color = props.themeColors.bgColors.text_primary_color
        styles.singlecontact.background = props.themeColors.bgColors.primary_bgcolor
        styles.swither["& svg"].color = props.themeColors.bgColors.main_color_bg

        styles.title.fontFamily = props.themeColors.bgColors.base_font 
        styles.contacttitle.fontFamily = props.themeColors.bgColors.base_font
        styles.contactbox["& label"].fontFamily = props.themeColors.bgColors.base_font
        styles.contactbox["& input"].fontFamily = props.themeColors.bgColors.base_font
        styles.contactbox["& textarea"].fontFamily = props.themeColors.bgColors.base_font
        styles.contactbox.fontFamily = props.themeColors.bgColors.base_font
        styles.mybtn1.fontFamily = props.themeColors.bgColors.base_font

        
    }
    // const { t } = useTranslation()
  const classes = useStyles();
  return (
      <div className={classNames(classes.singlecontact)}>
          <img src={leftimgconatct} alt={""} className={classes.leftimgconatct} />
          <div className={classNames(classes.container)}> 
             
      <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
          <Formsy
                onValidSubmit={handleSubmit}
                onValid={enableButton}
                onInvalid={disableButton}
                ref={formRef}
                className={classes.form + " flex flex-col justify-center w-full"}
            >
              <div className={classes.contactformwrapper}>
              {seccontactDb.categories.map((jsondata) => (
                  <div className={classes.contactbox  + " contactbox2"}>
                      
                      <h4 className={classes.contacttitle}>
                      Send Us a Message
                      </h4>
                                <GridItem md={12}><div className={classes.msg + " message errormsg "} id="message"></div></GridItem>
                                <TextFieldFormsy 
                                    label="Enter Your Full Name"
                                    id="name"
                                    name="name"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validations={{
                                        minLength: 2
                                    }} 
                                    className={classes.contactiinpu}
                                    validationErrors={{
                                        minLength: 'Min character length is 2'
                                    }} 
                                    variant="outlined"
                                    required
                                />  

                                <TextFieldFormsy 
                                    label="Enter Your Email Address"
                                    id="email"
                                    name="email"
                                    type="email"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validations={{
                                        isEmail: true
                                    }} 
                                    className={classes.contactiinpu}
                                    validationErrors={{
                                        isEmail: 'Email format must be valid'
                                    }} 
                                    variant="outlined"
                                    required
                                />   

 
                               
                                  <SelectFormsy 
                                    name="type_of"
                                    className={classes.selectboxx + " selectbox"} 
                                    value="tom"
                                    label="Topic"
                                    variant="outlined"
                                    validations={{
                                        isNumeric: true
                                    }}
                                    validationErrors={{
                                        isNumeric: 'Please select topic'
                                    }} 
                                    required>
                                    {jsondata.submenus.map((jsondata) => (
                                      <MenuItem value={((jsondata.id > 0) ? jsondata.id : "tom")}>{jsondata.options}</MenuItem> 
                                    ))}
                                  </SelectFormsy>
                                  <TextFieldFormsy 
                                        name="message" 
                                        placeholder="Message" 
                                        className={classes.contacttextarea}
                                        multiline
                                        rowsMax={4}
                                        rows={4}
                                        required
                                    />
                                  <br/> 
                                  <div className={classes.yesnofild}>
                                    <div className={classes.swither + " " + classes.checkbti} >
                                     
                                        <CheckboxFormsy 
                                            id="checkPermission"
                                            name="checkPermission"
                                            value={((isPermission === 1) ? "1" : "")} 
                                            data="1" 
                                            checked={((isPermission === 1) ? "1" : "")} 
                                            required
                                        />
                                    </div>
                                    <div className={classes.checkbtitext} >I have read and accept the general terms and conditions </div>
                                  </div>
                                  <Button
                                      type="submit"
                                      color="transparent" 
                                      className={classes.mybtn1 +" signupbtn"}
                                      disabled={!isFormValid}
                                  >
                                      SUBMIT NOW
                                  </Button> 
                       
                  </div>))}
              </div>
            </Formsy>
          </GridItem>
      </GridContainer>
      </div>
      </div>
  );
}

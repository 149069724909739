

const themeDb = {
    bgColors:
        {
            'parallaxbg':"#000",
            'balanceamountshadow':"0px 0px 5px 1px #ffb80c",
            'headsigninbgimg':"url(assets/img/headerboobg.jpg)",
            'headsigninbg':"linear-gradient(to bottom, rgba(217,175,102,1) 0%, rgba(250,208,40,1) 20%, rgba(255,249,178,1) 48%, rgba(250,208,40,1) 73%, rgba(217,175,102,1) 100%);",
            'goldennbtn':"linear-gradient(135deg, rgba(217,175,102,1) 0%, rgba(250,208,40,1) 20%, rgba(255,249,178,1) 48%, rgba(250,208,40,1) 73%, rgba(217,175,102,1) 100%)",
            'coinbalanceicon':"#ffb80c",
            'breadcrumbbg':"#000",
            'modalheaderbg': '#3c3d3e',
            'modalheaderbody': '#3c3d3e',
            'modalsubtitle': '#bdbdbd',
            'modalptag': '#bdbdbd !important',
            'modalatag': "#fff",
            'modalataghover':"#ffb80c ",
            'goldenshadow':"0px 0px 0px 15px rgba(255, 184, 12, 0.2), 0px 0px 0px 15px rgba(255, 184, 12, 0.2)",
            'goldenshadowhover':"0px 0px 0px 15px rgba(255, 184, 12, 0.2), 0px 0px 0px 30px rgba(255, 184, 12, 0.2)",
            'modalheaderfooter': '#3c3d3e',
            'headbackground': '#3c3d3e',
            'headiconbg': '#3c3d3e',
            'headicontext': '#fff',
            'userdropdowntext':"#000",
            'userdropdownhover':"#ffb80c",
            'topheader': "#000",
            'teambackground':"#151515",
            'toolbarafter_before':"#000",
            'toolbarafter_after':"83px solid #000",
            'headertoptext':"#bdbdbd",
            'footertext':"#bdbdbd !important",
            'darkbg':"#0f0f0f",
            'signinheadersidebar':"#3c3d3e",
            'listheadsidebartop':"rgba(0, 0, 0, 0.87)",
            'listheadsidebartoptext':"#bdbdbd",
            'listheadsidebaranker':"#fff",
            'listheadsidebarankerhover':"#ffb80c",
            'walletbtn':"#000",
            'walletbtntext':"#3c3d3e !important",
            'black':"#000000 !important",
            'gameboxborder':"1px solid #3c3d3e",
            'tabletext':"#898989",
            'pinkcolor':"#f9314b !important",
            'yellow':"#FDF202 !important",
            'golden':"linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07) !important",
            'goldenbtntext':"rgb(120,50,5) !important",
            'goldentext':"#ffb80c !important",
            'pageheadinginner':"#ffb80c !important",
            'goldenborder':"#ffb80c", 
            'widthdrawboxnobank':"#bdbdbd",
            'copyrightleftareatext':"#bdbdbd",
            'messagebox':"#2d2d2d",
            'tabletextcolor':"#bdbdbd",
            'tablebordercolor':"#bdbdbd",
            'rupeemainbox':"#2d2d2d",
            'accountsbox':"#2d2d2d",
            'widthdrawbox':"#2d2d2d",
            'userproboxhead':"#bdbdbd",
            'depositrupeeform':"#3c3d3e",
            'creditcardform':"#3c3d3e",
            'messageboxlist':"#3c3d3e",
            'messageboxlistcolor':"#bdbdbd",
            'copyrightbg':"#000",
            'tablepagebg':"#000",
            'sliderbottombox':"#2d2d2d",
            'teamimagebg':"#3c3d3e",    
            'teamoverlay':"linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%)",
            'teamsocialarea':"#3c3d3e",
            'sociallinksteam':"#000",    
            'amazingfeaturespagebg':"#000",
            'amazingfeaturesboxborder':"#ffb80c", 
            'visionpagetext':"#bdbdbd",
            'aboutpagebg':"#2d2d2d",
            'communityblogbg':"#0f0f0f",
            'communityblogbox': '#3c3d3e',
            'communityblogtext':"#bdbdbd",
            'vippagebg':"#151515",
            'vipboxbg': '#3c3d3e',
            'vipboxborder': '#3c3d3e',
            'faqpagebg':"#2d2d2d",
            'accordianlabel': '#3c3d3e',
            'accordiandiv': '#3c3d3e',
            'accordiandivtext':"#bdbdbd",
            'contactpagebg':"#151515",
            'contactformbox': '#3c3d3e',
            'contactformshadow':"0px 0px 0px 10px rgba(60, 61, 62, 0.2), 0px 0px 0px 20px rgba(60, 61, 62, 0.2), 0px 0px 0px 30px rgba(60, 61, 62, 0.2)",
        }

}
export default themeDb;

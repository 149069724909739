const customTabsStyle = {
  cardTitle: {
    float: "left",
    padding: "10px 10px 10px 0px",
    lineHeight: "24px"
  },
  cardTitleRTL: {
    float: "right",
    padding: "10px 0px 10px 10px !important"
  },
  displayNone: {
    display: "none !important"
  },
  tabsRoot: {
    minHeight: "unset !important",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      overflow: "auto",
    },
  },
  tabRootButton: {
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    fontFamily: 'Open Sans',
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    padding: "0px 30px",
    lineHeight: "50px",
    border: "0 !important",
    color: "#fff !important",
    backgroundColor: "#000", 
    fontWeight: "600",
    fontSize: "18px",
    textTransform: "uppercase",
    margin: "0px 0px", 
    borderRadius:"0px 0px 0px",
    "&:first-child":{
        borderRadius:"50px 0px 0px 50px"
    },
    "&:last-child":{
      borderRadius:"0px 50px 50px 0px",
      marginLeft: "0px",
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
       padding: "0px 20px",
    },
  },
  tabSelected: {
    background: "#ffb80c",
    transition: "0.2s background-color 0.1s",
    borderRadius:"0px 0px 0px",
    color:"#000 !important",
    "&:first-child":{
        borderRadius:"50px 0px 0px 50px"
    },
    "&:last-child":{
      borderRadius:"0px 50px 50px 0px"
    }
  },
  tabWrapper: {
    display: "inline-block",
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    "& > svg": {
      verticalAlign: "middle",
      margin: "-1.55px 5px 0 0 !important"
    },
    "&,& *": {
      letterSpacing: "normal !important"
    }
  }
};

export default customTabsStyle;

 

const secotpboxDb = {
    en: [
        {
            'id'    : '5725a680b3249769ea21de52', 
            'title'    : 'OTP',
            'email'    : 'Enter Your OTP',  
            'sbmitbtn'    : 'Submit',  
            'text_p'    : 'Already have an account?', 
            'text_a'    : 'Sign In', 
        }, 
    ],
    tl: [
        {
            'id'    : '5725a680b3249769ea21de52', 
            'title'    : 'OTP',
            'email'    : 'మీ OTP ని నమోదు చేయండి',  
            'sbmitbtn'    : 'సమర్పించండి',  
            'text_p'    : 'ఖాతా కలిగి ఉన్నారా?', 
            'text_a'    : 'సైన్ ఇన్ చేయండి', 
        }, 
    ],
    hi: [
        {
            'id'    : '5725a680b3249769ea21de52',
            'title'    : 'ओटीपी  ',
            'email'    : 'अपना ओटीपी दर्ज करें  ',  
            'sbmitbtn'    : 'प्रस्तुत',  
            'text_p'    : 'पहले से ही एक खाता है?', 
            'text_a'    : 'साइन इन करें',    
        },  
    ],
    

};

export default secotpboxDb;
import { defaultFont } from "../../casino-kit-react";

import tooltip from "../kit/tooltipsStyle.js";
// import themeDb from "../../../data/theme.js";  
const headerLinksStyle = ({
  modalCloseButton:{
    position: "absolute",
    right: "0px",
    zIndex: "9",
    top: "0",
    color: "#fff",
  }, 
  testlabel:{
    color:"#fff !important",
  },
  formggroup:{
    marginBottom:"20px"
  },
  msg:{
    
  },
  errormsg:{
    color: "#fff",
    margin: "0px 0px 5px",
    fontSize: "12px",
    background: "#bb2121",
    fontFamily: "ubuntu",
    padding: "0px 10px",
    borderRadius: "5px", 
    lineHeight:"30px"
  },
  normaltxt:{
    color: "#fff",
    textAlign: "center"
  },
  signupfor:{ 
    float: "right",
    lineHeight: "40px",
  },
  successmsg:{
    color: "#fff",
    margin: "0px 0px 5px",
    fontSize: "12px",
    background: "#15a912",
    fontFamily: "ubuntu",
    padding: "0px 10px",
    borderRadius: "5px", 
    lineHeight:"30px"
  },
  form:{
    margin:"0px",
    "& fieldset":{
      borderColor: "#ffffff !important",
      borderRight:"0px",
      borderLeft:"0px",
      borderTop:"0px",
      borderRadius:"0px"
    },
    "& input":{
      color: "#ffffff !important", 
      background:"none",
      fontSize:"18px",
      fontFamily:"ubuntu",
      tapHighlightColor:"#000",
    },
    "& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus":{
      backgroundColor: "#ff0000 !important", 
      background:"#ff0000 !important",
      border: "0px" ,
      textFillColor: "#ff0000",
      color: "#ff0000",
      boxShadow: "0 0 0px" ,
      tapHighlightColor:"#ff0000",
    },
    "& input:-internal-autofill-selected":{
      backgroundColor: "#ff0000 !important", 
      background:"#ff0000 !important",
      border: "0px" ,
      textFillColor: "#ff0000",
      color: "#ff0000",
      boxShadow: "0 0 0px" ,
      tapHighlightColor:"#ff0000",
    },
    "& label":{
      color: "#AAAAAA",
      fontSize:"18px",
      fontFamily:"ubuntu",
      transform: "translate(0px, 20px) scale(1)",
    },
    "& div":{
      width: "100%",
    },
    "& .MuiFormLabel-root.Mui-focused":{
      color: "#AAAAAA", 
    }
  },
  responsivemodell:{
    padding:"2px",
    backgroundImage: "linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07) !important",
    ['@media (max-width:710px)']: { // eslint-disable-line no-useless-computed-key
      width:"100%",
      maxWidth:"100%"
    }, 
   
  },
  modellogo:{
    height:"70px",
  },
  modalhide:{
    display:"none !important"
  },
  modalHeader:{
    background:"#252f5a",
    textAlign:"center",
    minWidth:"596px",
    position:"relative",
    paddingBottom:"0px",
    ['@media (max-width:710px)']: { // eslint-disable-line no-useless-computed-key
      minWidth:"100%",
    },
  },
  signupbixp:{
    color: "#a1aed4",
  },
  selectboxx:{
    "& div div":{
      color: "#fff",
      fontSize: "18px", 
      fontFamily: "ubuntu",
      padding: "15px 50px 13px 10px",
    } 
  },
  modalBody:{
    background:"#252f5a",
    position:"relative",
    minWidth:"596px",
    ['@media (max-width:710px)']: { // eslint-disable-line no-useless-computed-key
      minWidth:"100%",
    },
  },
  modalFooter:{
    // minWidth:"596px",
    background:"#252f5a",
    textAlign:"center",
    paddingTop:'20px',
    fontFamily: "Ubuntu",
    display: "block !important",
    ['@media (max-width:710px)']: { // eslint-disable-line no-useless-computed-key
      minWidth:"100%",
    },
  },
  modalSubtitle:{
    fontSize: "20px",
    lineHeight: "34px",
    fontWeight: "300",
    margin: "0px",
    color: "#a1aed4",
    fontFamily: "Ubuntu",
  },
  signupbix:{
    color: "#fff",
    textDecoration:"none",
    fontSize:"13px",
    padding: "0px",
    fontFamily: "ubuntu",
    verticalAlign: "text-bottom",
    "& i":{
      marginLeft:"10px"
    }
  },
  modalTitle:{
    fontSize: "22px",
    lineHeight: "34px",
    fontWeight:" 700",
    color: "#fff",
    textTransform: "uppercase",
    margin: "0px",
    marginBottom: "2px",
    fontFamily: "Ubuntu",
  },
  signinmodalbtn:{
    display: "block",
    width: "90%",
    fontSize: "16px",
    textTransform: "uppercase",
    fontWeight: "600",
    padding: "14px 35px",
    color: "#fff !important",
    border: "0px",
    borderRadius: "50px",
    cursor: "pointer",
    margin:"0px 5% 20px",
    transition: "all 0.3s ease-in",
    backgroundImage: "linear-gradient(to left, #ff344d, #d2273c)",
    "&:hover":{
      backgroundImage: "linear-gradient(to left, #ff344d, #d2273c)",
    },
  }, 
  signinbtn:{
    fontSize: "16px",
    textTransform: "uppercase",
    fontWeight: "600",
    padding: "14px 35px",
    display: "inline-block",
    color: "#fff",
    border: "0px",
    borderRadius: "50px",
    boxShadow:"0px 0px 0px",
    cursor:" pointer",
    marginTop: "16px",
    transition: "all 0.3s ease-in",
    backgroundImage: "linear-gradient(to left, #ff344d, #d2273c)",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      padding: "14px 25px",
      marginLeft:"5%",
      width:"90% !important",
    },
  }, 
  list: {
    ...defaultFont,
    fontSize: "14px",
    margin: 0,
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: "inherit"
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      width: "100%",
      "&:after": {
        width: "calc(100% - 30px)",
        content: '""',
        display: "block",
        height: "1px",
        marginLeft: "15px",
        backgroundColor: "#e5e5e5",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
          background: "none",
        },
      }
    },
  },

  listItemText: {
    padding: "0 !important"
  },
  navLink: {
    fontFamily: 'Open Sans',
    color: "#bdbdbd",
    position: "relative",
    padding: "28px 20px", 
    fontSize: "16px",
    textTransform: "uppercase",
    borderRadius: "3px",
    lineHeight: "26px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover": {
      color: "inherit",
      background: "rgba(200, 200, 200, 0.2)"
    }, 
    "&:focus": {
      color: "inherit",
      background: "rgba(200, 200, 200, 0.2)"
    }, 
    "&.navlinkactive": {
      color: "#ffb80c",
    },
    "& .mrhovereffect::before": {
      borderTopColor: "#ffb80c",
    },
    "& .mrhovereffect": {
      borderTopColor: "#ffb80c",
      borderBottomColor: "#ffb80c",
    },
    "& .mrhovereffect::after": {
      borderTopColor: "#ffb80c",
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      color:"#bdbdbd !important",
      padding: "5px 0px",
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start"
      }
    },
  },
  notificationNavLink: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    top: "4px"
  },
  registerNavLink: {
    top: "3px",
    position: "relative",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex"
  },
  navLinkActive: {
    color: "inherit",
    backgroundColor: "rgba(255, 255, 255, 0.1)"
  },
  icons: {
    width: "20px",
    height: "20px",
    marginRight: "3px"
  },
  socialIcons: {
    position: "relative",
    fontSize: "20px !important",
    marginRight: "4px",
    marginTop: "5px",
  }, 
  dropdownLink:{ 
    textAlign:"left",
    borderTop:"1px solid #7b7b7b",
    margin:"0px",
    fontFamily:"ubuntu",
    fontWeight:'normal',
    fontSize:"16px",
    "&, &:hover, &:focus": {
      color: "inherit",
      textDecoration: "none",
      display: "block",
      padding: "10px 20px",  
    },
    "&:first-child":{
      borderTop:"0px"
    }
  },
  ...tooltip,
  marginRight5: {
    marginRight: "5px"
  },
  checkboxin:{
    "& p":{
      float:"left",
      color:"#fff",
      fontFamily:"ubuntu",
      lineHeight:"40px"
    },
    "& span":{
      color:"#ffb80c"
    },
    "& p a":{
      color:"#ffb80c",
      textDecoration:"none"
    },
    "& label":{
      margin:"-27px 0px 0px"
    }
  },
  backnone:{
   background:"none"
  },
  tnctext:{
    fontSize:"12px",
    fontFamily:"'Open Sans'",
    color:"#fff",
    "& a":{
      color:"#efde1d"
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      fontSize:"10px",
    },
  },
  receptadiv:{
    float: "left",
    width: "auto !important",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      transform: "scale(0.77)",
      transformOrigin:" 0 0",
    },
  },
  logoname:{
    color:"#ffffff",
    margin: "0px 0 0 10px",
    fontFamily: 'Open Sans',
 //  fontFamily: (themeDb.bgColors.logo_font !== null) ? themeDb.bgColors.logo_font : 'Conv_Orbitron-VariableFont_wght',
    fontWeight: "500",
    textTransform:"capitalize"
  },
  signuprecepta:{ 
    marginBottom: "10px",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      transform: "scale(0.77)",
      transformOrigin:" 0 0",
      marginBottom: "0px",
    },
  },
  checkboxx:{
    float:"left",
    width:"auto !important",
    "& p":{
        top: "15px",
        left: "56px",
        width: "190px",
        display: "block",
        position: "absolute",
    }
  }
});

export default headerLinksStyle;

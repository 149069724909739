// import * as UserActions from './user.actions'; 
//import * as Actions from '../../../src/store/actions';
import jwtService from '../../../services/jwtService';

export const PAYPALREQUEST_SUCCESS = 'PAYPALREQUEST_SUCCESS';
export const PAYPALREQUEST_ERROR = 'PAYPALREQUEST_ERROR';


export function paypalRequest({amount, comment, transation_id, paypal_email, paypal_id, transaction_response}){
    return (dispatch) => {
        jwtService.depositPaypalRequest({
            token: window.localStorage.getItem('jwt_access_token'),
            payby: 8,
            deposit_mode: 4,
            amount,
            transation_id,
            paypal_email,
            paypal_id,
            transaction_response,
            comment
        })
        .then((user) => { 
            
            return dispatch({
                    type: PAYPALREQUEST_SUCCESS,
                    payload: user
                });
            }
        )
        .catch(error => { 
            return dispatch({
                type   : PAYPALREQUEST_ERROR,
                payload: error
            });
        });
    }
}
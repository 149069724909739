/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import {IconButton, MenuItem} from "@material-ui/core"; 
// @material-ui/core components
import { Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Slide from "@material-ui/core/Slide";
import ImgUpload from "../pages/imgupload.js";
import Formsy from 'formsy-react';
import GridItem from "../elements/Grid/GridItem";
import GridContainer from "../elements/Grid/GridContainer";
import styles from "../assets/jss/components/headerLinksStyle.js";

import * as authActions from '../../../auth/casino/actions';
import { useDispatch, useSelector } from 'react-redux';
import {SelectFormsy} from '../../../@casino';
import kycmoduleDb from "../data/kycmodule.js";
import { useTranslation } from 'react-i18next';
import axios from 'axios'; 
import * as Gurl from "../../../global/Global.js";
let classicModal = null;
let setClassicModal = null;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";
const validEmailRegex =
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
  var runExistingKYCList = false;
export default function UploadAadhar(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const imgupload = useSelector(({ auth }) => auth.imgupload);
  const kycupload = useSelector(({ auth }) => auth.kycupload);
  const [classAdd, setClassAdd] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [uploadText, setUploadText] = useState("");
  const [aadharFrontImage, setAadharFrontImage] = useState("");
  const [aadharBackImage, setAadharBackImage] = useState("");

  const [kycListItem, setKycListItem] = useState("");
  const [kycDefault, setKycDefault] = useState("id_proof");
  const formRefdl = useRef(null);
  const imguploaddl = useSelector(({ auth }) => auth.imgupload);
  const kycuploaddl = useSelector(({ auth }) => auth.kycupload);
  const [classAdddl, setClassAdddl] = useState(false);
  const [isFormValiddl, setIsFormValiddl] = useState(false);
  const [uploadTextdl, setUploadTextdl] = useState("");
  const [dlBackImage, setDLBackImage] = useState("");
  const [dlFrontImage, setDLFrontImage] = useState("");
  let currentStyle = styles;


  const formRefpan = useRef(null);
  const imguploadpan = useSelector(({ auth }) => auth.imgupload);
  const kycuploadpan = useSelector(({ auth }) => auth.kycupload);
  const [classAddpan, setClassAddpan] = useState(false);
  const [isFormValidpan, setIsFormValidpan] = useState(false);
  const [uploadTextpan, setUploadTextpan] = useState("");
  const [panBackImage, setPanBackImage] = useState("");
  const [panFrontImage, setPanFrontImage] = useState("");
  const [kycList, setKycList] = React.useState("NOVALUE" );
  const [reloadKycList, setReloadKycList] = React.useState(false);
  

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  [classicModal, setClassicModal] = React.useState(false);
  const [depositType, setDepositType] = React.useState(0);
  function getKYCLists(isReload){
    if((kycList === "NOVALUE" || reloadKycList === true || isReload === true) && runExistingKYCList === false){
        runExistingKYCList = true;
        
        const request = axios.post(Gurl.KYCLIST, {
            token: window.localStorage.getItem('jwt_access_token')
        });
        
        request.then((response) => {
            if (response.data.data != null) {
                setKycList(response.data.data);
            } else {
                setKycList("NOVALUE");
            }
            setReloadKycList(false);
            runExistingKYCList = false;
        });
        request.catch(() => {
            setKycList("NODATA");
            setReloadKycList(false);
            runExistingKYCList = false;
        });
        
    }
}
if(kycList === "NOVALUE"){
    setKycList("GETTING");
    getKYCLists(true);
}

function kychandler(event){
  if(Array.isArray(kycList)){
    kycList.map((rowData) => {
      
      if(event.target.value == rowData.name){
        if(rowData.no_of_document === 2){
          document.body
          .getElementsByClassName("panbox")[0]
          .setAttribute("style", "display:inline-block;");
        }else{
          document.body
          .getElementsByClassName("panbox")[0]
          .setAttribute("style", "display:none;");
        }
      }
    })
  }
}



  useEffect(() => {
    if (imgupload.success) {
      if (imgupload.name == 'front_scan') {
        setAadharFrontImage(imgupload.data.img);
      } else if (imgupload.name == 'back_scan') {
        setAadharBackImage(imgupload.data.img);
      }
      setUploadText("");
      enableButton();
    } else if (imgupload.error) {
      if (imgupload.error.message != null) {
        document.body
          .getElementsByClassName("message_aadharupload")[0]
          .innerHTML = imgupload.error.message;
        setClassAdd(classes.msg = " errormsg");
      }
      disableButton();
      setUploadText("");
    }
  }, [imgupload]);

  useEffect(() => {
    if (kycupload.success) {
      props.setReloadKyc(true);
      document.body
        .getElementsByClassName("message_aadharupload")[0]
        .innerHTML = "File uploaded successfully";
      setClassAdd(classes.msg = " successmsg");
      setUploadText("");
      
      props.getKYCData(true);
      const timer = setTimeout(() => {
        setClassicModal(false);
      }, 3000);
       
    } else if (kycupload.error) {
      if (kycupload.error.message != null) {
        document.body
          .getElementsByClassName("message_aadharupload")[0]
          .innerHTML = kycupload.error.message;
        setClassAdd(classes.msg = " errormsg");
      }
      setUploadText("");
      disableButton();
    }
  }, [kycupload]);

  function disableButton() {
    setIsFormValid(false);
    setIsFormValidpan(false);
    setIsFormValiddl(false);
    document.body
      .getElementsByClassName("signinbtn")[0]
      .setAttribute("style", "opacity:0.5;");
  }

  function enableButton() {
    setIsFormValid(true);
    setIsFormValidpan(true);
    setIsFormValiddl(true);
    document.body
      .getElementsByClassName("signinbtn")[0]
      .setAttribute("style", "opacity:1;");
  }

  function uploadImage(e, name) {
    if (e != undefined && e != null) {
      setUploadText("Please Wait...");
      dispatch(authActions.uploadImage(e, name, props));
    }
    document.body
      .getElementsByClassName("message_aadharupload")[0]
      .innerHTML = " "
  }

  function handleSubmit(modelori) {
    setUploadText("Uploading...");
    let numberOfDocReq = 1;
    if(Array.isArray(kycList)){
      kycList.map((jsondata) => {
          if(jsondata.name === modelori.doc_type){
            numberOfDocReq = jsondata.no_of_document;
          }
      });
    }
    
    if (aadharFrontImage != "" && aadharBackImage != "" && numberOfDocReq === 2) {
      let isUpdate = false;
      if(Array.isArray(props.kycData)){
        const result = props.kycData.find( ({ name }) => name === modelori.doc_type );
        if(result !== null && result !== false && result !== undefined){
          isUpdate = true;
        }
      }else if(props.kycData != null && props.kycData.name != null && props.kycData.name === modelori.doc_type){
        isUpdate = true;
      }
      var model = {
        doc_type: modelori.doc_type,
        front_scan: aadharFrontImage,
        back_scan: aadharBackImage,
        select_pay_by:depositType,
        update: isUpdate,
        token: window.localStorage.getItem('jwt_access_token')
      };
      dispatch(authActions.uploadKYC(model, props));
      document.body
        .getElementsByClassName("message_aadharupload")[0]
        .innerHTML = "";
    }else if (aadharFrontImage != "" && numberOfDocReq !== 2) {
      let isUpdate = false;
      if(Array.isArray(props.kycData)){
        const result = props.kycData.find( ({ name }) => name === modelori.doc_type );
        if(result !== null && result !== false && result !== undefined){
          isUpdate = true;
        }
      }else if(props.kycData != null && props.kycData.name != null && props.kycData.name === modelori.doc_type){
        isUpdate = true;
      }
      var model = {
        doc_type: modelori.doc_type,
        front_scan: aadharFrontImage,
        back_scan: aadharFrontImage,
        select_pay_by:depositType,
        update: isUpdate,
        token: window.localStorage.getItem('jwt_access_token')
      };
      dispatch(authActions.uploadKYC(model, props));
      document.body
        .getElementsByClassName("message_aadharupload")[0]
        .innerHTML = "";
    } else {
      document.body
        .getElementsByClassName("message_aadharupload")[0]
        .innerHTML = (numberOfDocReq === 2) ? "Please select both front and back image" : "Please select image";
      setClassAdd(classes.msg = " errormsg");
      
    }
  }


  useEffect(() => {
    if (kycupload.success) { 
      props.setReloadKyc(true);
      document.body
        .getElementsByClassName("message_aadharupload")[0]
        .innerHTML = "File uploaded successfully";
      setClassAdd(classes.msg = " successmsg");
      setUploadTextdl("");
      props.getKYCData(true);
      const timer = setTimeout(() => {
        setClassicModal(false);
      }, 3000);
     
    } else if (kycupload.error) {
      if (kycupload.error.message != null) {
        document.body
          .getElementsByClassName("message_aadharupload")[0]
          .innerHTML = kycupload.error.message;
        setClassAdd(classes.msg = " errormsg");
      }
      setUploadTextdl("");
      disableButton();
    }
  }, [kycupload]);
  
  

  

  useEffect(() => {
    if (kycupload.success) {
      props.setReloadKyc(true);
      document.body
        .getElementsByClassName("message_aadharupload")[0].innerHTML = "File uploaded successfully";
      setClassAdd(classes.msg = " successmsg");
      setUploadTextpan("");
      props.getKYCData(true);
      const timer = setTimeout(() => {
        setClassicModal(false);
      }, 3000);
      
    } else if (kycupload.error) {
      if (kycupload.error.message != null) {
        document.body
          .getElementsByClassName("message_aadharupload")[0]
          .innerHTML = kycupload.error.message;
        setClassAdd(classes.msg = " errormsg");
      }
      setUploadTextpan("");
      disableButton();
    }
  }, [kycupload]);

  function uploadImage(e, name) {
    if (e != undefined && e != null) {
      setUploadText("Please Wait...");
      dispatch(authActions.uploadImage(e, name, props));
    }
    document.body
      .getElementsByClassName("message_aadharupload")[0]
      .innerHTML = " "
  }

  function handleSubmitpan() {
    setUploadTextpan("Uploading...");
    if (panFrontImage != "" ) {
      let isUpdate = false;
      if(Array.isArray(props.kycData)){
        const result = props.kycData.find( ({ name }) => name === 'pan' );
        if(result !== null && result !== false && result !== undefined){
          isUpdate = true;
        }
      }else if(props.kycData != null && props.kycData.name != null && props.kycData.name === "pan"){
        isUpdate = true;
      }
      var model = {
        doc_type: 'pan',
        front_scan: panFrontImage,
        back_scan: panFrontImage,
        update: isUpdate,
        token: window.localStorage.getItem('jwt_access_token')
      };
      document.body
        .getElementsByClassName("message_aadharupload")[0]
        .innerHTML = "";
      dispatch(authActions.uploadKYC(model, props));
    } else {
      document.body
        .getElementsByClassName("message_aadharupload")[0]
        .innerHTML = "Please select both front and back image";
      setClassAdd(classes.msg = " errormsg");
    }
  } 
  if (props.themeColors != null) {
    // styles.custom_file_upload.background = props.themeColors.bgColors.main_color;
     styles.signinbtn.backgroundImage = props.themeColors.bgColors.main_color;
     styles.signinbtn.color = props.themeColors.bgColors.text_primary_dark_color;
     styles.modalHeader.background = props.themeColors.bgColors.primary_bgcolor;
     styles.modalBody.background = props.themeColors.bgColors.primary_bgcolor;
     styles.modalFooter.background = props.themeColors.bgColors.primary_bgcolor;
     styles.modalSubtitle.color = props.themeColors.bgColors.text_primary_color;
     styles.signupbixp.color = props.themeColors.bgColors.text_primary_color;
     styles.signinmodalbtnkyc.background = props.themeColors.bgColors.main_color;
      styles.signinmodalbtnkyc.backgroundImage = props.themeColors.bgColors.main_color;
      // styles.signinmodalbtnkyc.color = props.themeColors.bgColors.text_primary_dark_color;
      styles.formgroupwidthlabel["& label"].color = props.themeColors.bgColors.text_secondary_dark_color;
      styles.selectboxx.color = props.themeColors.bgColors.text_primary_color;
      styles.selectboxx["& div div"].color = props.themeColors.bgColors.text_primary_color;
    // styles.signinmodalbtn["&:hover"].backgroundImage = props.themeColors.bgColors.golden;
    // styles.signinmodalbtn.color = props.themeColors.bgColors.goldenbtntext;
    styles.signinmodalbtnkyc.fontFamily = props.themeColors.bgColors.base_font; 
   }
   if(Array.isArray(kycList) && kycListItem === ""){
     var isSelected = true;
    var myOutPut = (
        kycList.map((jsondata) => {
          if(isSelected === true){
            if(jsondata.no_of_document === 1){
              document.body
              .getElementsByClassName("panbox")[0]
              .setAttribute("style", "display:none;");
            }
            setKycDefault(jsondata.name);
          }else{
            isSelected = false;
          }
          return(<MenuItem value={jsondata.name} key={jsondata.name}>{jsondata.title}</MenuItem>); 
        })
    );
    setKycListItem(myOutPut);
   }
  return ( 
      <div>
{kycmoduleDb[sessionStorage.getItem('lang')].map((jsondata) => (
<div>

    <Formsy
          onValidSubmit={handleSubmit}
          ref={formRef}
          style={{display:"inline-block"}} 
          className={classes.form + " " + classes.aadharcardbox + " flex flex-col justify-center w-full aadharbox " + classes.uploadkyccs }
        >

               <GridContainer>  
                    <GridItem xs={12}><div className={classes.msg + " message_aadharupload " + classAdd} id="message"></div></GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                        <div className={classes.formgroupwidthlabel}> 
                             <label>{jsondata.selecttypeid}</label> 
                            <SelectFormsy 
                                    className={classes.selectboxx + " selectbox"} 
                                    label=" "
                                    name="doc_type"  
                                    variant="outlined"
                                    value={kycDefault}
                                    onChange={kychandler}
                                    required>  
                                  {kycListItem}
                            </SelectFormsy> 
                        </div>  
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <div className={classes.formgroupwidthlabel}> 
                             <label>{jsondata.aadhar_one}</label>
                            <ImgUpload
                              name="front_scan"
                              onChange={uploadImage}
                              themeColors={props.themeColors}
                              src={aadharFrontImage}
                              required
                            />
                        </div>    
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} className={"panbox"}>
                        <div className={classes.formgroupwidthlabel}> 
                            <label>{jsondata.aadhar_two}</label>
                            <ImgUpload
                              name="back_scan"
                              onChange={uploadImage}
                              themeColors={props.themeColors}
                              src={aadharBackImage}
                              required
                            />
                        </div>  
                    </GridItem>
                    
                    <GridItem xs={12} sm={12} md={2}>
                        <div className={classes.formgroupwidthlabel}> 
                        <label>{uploadText}</label>
                        <Button
                            color="primary" className={classes.signinmodalbtnkyc + " signinbtn"} disabled={!isFormValid} type="submit"
                             
                        >
                             {jsondata.aadhar_verify_btn}  
                        </Button> 
                        </div>  
                    </GridItem>
                </GridContainer> 
    </Formsy>         



</div>
))}
</div>
     
  );
}


 
// import * as UserActions from './user.actions';
//import * as Actions from '../../../src/store/actions';
import jwtService from '../../../services/jwtService';

export const GATEWAYREQUEST_SUCCESS = 'GATEWAYREQUEST_SUCCESS';
export const GATEWAYREQUEST_ERROR = 'GATEWAYREQUEST_ERROR';


export function gatewayRequest({gateway_type_id, amount, comment}, receipt_url){
    return (dispatch) => { 
        if(gateway_type_id === "2" || gateway_type_id === "3"){
            jwtService.depositGatewayRequest({
                token: window.localStorage.getItem('jwt_access_token'),
                gateway_id: (gateway_type_id === "2") ? "45" : "46",
                amount,
                comment
            })
            .then((user) => { 
                return dispatch({
                        type: GATEWAYREQUEST_SUCCESS,
                        payload: user
                    });
                }
            )
            .catch(error => { 
                return dispatch({
                    type   : GATEWAYREQUEST_ERROR,
                    payload: error
                });
            });
        }
    }
}
var getonlydomain = window.location.href.split(":"); 
var portNumber = (window.location.protocol === 'https:' ? "8083" : "8080");
//portNumber = (window.location.protocol === 'https:' ? "8090" : "8089");
var baseAdmin = "soft";
if(window.location.port > 500 && window.location.port < 4500){
    //portNumber = (window.location.protocol === 'https:' ? "8089" : "8085");
    //baseAdmin = "uat";
}
//var portNumber = (window.location.protocol === 'https:' ? "8089" : "8085");
//var API_FIX_URL = "https://www.casinodestination.asia";

//var finalDomainUsed = getonlydomain[1].split("/");
//var newfinalDomainUsed = finalDomainUsed[0].split(":");
export var API_BASE_URL = window.location.protocol + "//" + window.location.hostname; 
if(getonlydomain[1].search("localhost") >= 0 || getonlydomain[1].search("192.168.1.59") >= 0){
    API_BASE_URL = "https://spikes.casinodestination.asia"; 
}
export const API_BASE_SOFT_URL = (API_BASE_URL.charAt(API_BASE_URL.length - 1) === "/") ?  API_BASE_URL + baseAdmin : API_BASE_URL + "/" + baseAdmin;
var API_HTTP_N_BASE_URL = API_BASE_URL; // "http://" + getonlydomain[1];
export const API_BASE_PORT = portNumber;
if(API_HTTP_N_BASE_URL.charAt(API_HTTP_N_BASE_URL.length - 1) === "/"){
    API_HTTP_N_BASE_URL = API_HTTP_N_BASE_URL.substring(0,API_HTTP_N_BASE_URL.length - 1);
}
export const API_HTTP_BASE_URL = API_HTTP_N_BASE_URL;
//After Login
export const TOKEN_PATH = API_HTTP_BASE_URL + ":" + API_BASE_PORT + "/users/validatetoken";
export const GETSTATEMENT = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/getmytransaction';
export const GETGAMESTATEMENT = API_BASE_URL + ":" + API_BASE_PORT + '/apis/getgametransaction';
export const GETPROFILE = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/myprofile';
export const CHANGEPASSWORD = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/users/changepassword';
export const VERIFYMOBILE = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/users/verifymobile';
export const VERIFYEMAIL = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/users/verifyemail';
export const VERIFYEMAILTOKEN = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/users/verifyemailtoken';
export const VALIDATEPAY10 = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/pgateway/validatepay10';

export const VERIFYMOBILEOTP = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/users/verifymobileotp';
export const VERIFYOTP = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/users/verifyotp';
export const verifymeotp = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/users/verifymeotp';

//Before Login
export const VERIFYEMAILDIRECT = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/users/usrverifyemail';
export const VERIFYMOBILEDIRECT = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/users/usrverifymobile';
export const VERIFYOPREMAILDIRECT = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/users/oprverifyemail';
export const VERIFYOPRMOBILEDIRECT = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/users/oprverifymobile';

export const LOGIN_PATH = API_HTTP_BASE_URL + ":" + API_BASE_PORT + "/users/login";
export const LOGINME_PATH = API_HTTP_BASE_URL + ":" + API_BASE_PORT + "/users/loginme";
//export const SIGNUP_PATH = API_BASE_URL + ":" + API_BASE_PORT + "/users/signup";
export const SIGNUP_PATH = API_BASE_SOFT_URL + "/users/register_player_nodews";
export const SIGNUP_MOBILE = API_HTTP_BASE_URL + ":" + API_BASE_PORT + "/users/completeprofile";
export const SIGNUP_OTP_RESEND = API_BASE_SOFT_URL + "/users/node_resend_otp";
//Not required for login
export const SPORTS_BOOK_BTI = (window.location.port > 500 && window.location.port < 4500) ? "https://stg20138.core-tech.dev/en/sports" : "https://stg20138.core-tech.dev/en/sports";
export const CONTACTREQUEST = API_HTTP_BASE_URL + ":" + API_BASE_PORT + "/users/contactrequest";
export const SUBSCRIBERREQUEST = API_HTTP_BASE_URL + ":" + API_BASE_PORT + "/users/subscriberequest";
export const MYGAMES = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/getmygames';
export const GETBARNDING = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/getbranding';
export const GETGAMES = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/getgames';
export const GETGAMECATS = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/getmycategories';
export const GETMENUS = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/getmymenu';
export const GETMENUCONTENTS = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/getpageconent';
export const MEDIA_CONTENT_API = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/getmediacontent';
export const FAQS_CONTENT_API = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/faqs';
export const WINNER_CONTENT_API = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/winners';
export const FORGOT_PATH =  API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/users/forgot';
export const PROMOTIONS_CONTENT_API = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/promotions';
export const GET_MY_PROVIDERS = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/getmyproviders';
//Gate-way uri
export const PAY10GATEWAY = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/pgateway/payby10';
//Required Login
export const GETGAMEAUTH = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/getgameauth';
export const GETBALANCE = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/balance';
export const LOGOUTUSER = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/users/logout';
export const STATES_DATA = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/users/states';
export const BANKACCOUNTDELETE = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/bankaccountdelete';
export const BANKACCOUNTS = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/bankaccounts';
export const SELFBANKACCOUNTS = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/selfbankacclist';
export const PARENTBANKACCOUNTS = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/parentbankacclist';
export const ADDBANK = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/bankacc';
export const WITHDRAWREQUEST = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/wallets/withdrawrequest';
export const BTBDEPOSITREQUEST = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/wallets/btobdepositrequest';
export const CTBDEPOSITREQUEST = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/wallets/ctobdepositrequest';
export const PAYPALDEPOSITREQUEST = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/wallets/paypaldepositrequest';
export const ANGADIYADEPOSITREQUEST = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/wallets/angadiyadepositrequest';
export const EWALLETDEPOSITREQUEST = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/wallets/ewalldepositrequest';
export const GETFUNDREQUESTSTATEMENT = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/getmyfundrequest';
export const GETWITHDRAWREQUESTSTATEMENT = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/getmywithdrawrequest';
export const GETWALLETSTATS = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/walletstats';
export const GETDEPOSITWITHDRAWSTATS = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/depositwithdrawstats';
export const GETGAMEWISESTATS = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/gamewisestats';
export const GETIFSCDETAILS = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/ifscdetails';
export const UPDATELOCATION = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/updatelocation';
export const GETWITHDRAWBLEINFO = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/wallets/withdrawable';
export const GETLOGINLOGS = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/loginlogs';
export const EDITPROFILE = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/users/profileupdate';
export const GETGATEWAYDETAILS = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/wallets/paymentgatways';

//export const IMAGEUPLOAD = API_BASE_SOFT_URL + '/cms_themes/image_upload_nodejs';
export const IMAGEUPLOAD = API_BASE_SOFT_URL + '/cms_themes/image_upload_nodejs';
export const KYCINSERT = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/kycinsert';
export const KYCUPDATE = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/kycupdate';
export const KYCINFO = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/apis/kycinfo';
export const KYCLIST = API_HTTP_BASE_URL + ":" + API_BASE_PORT + '/users/kyclist';

//White Label SM
export const REGENERATE_OTP = API_BASE_SOFT_URL + "/registrations/regenerateotp";
export const PROFILESETUP_PATH = API_BASE_SOFT_URL + "/registrations/update_plandata";
export const WLREGISTER_PATH = API_BASE_SOFT_URL + "/registrations/send_request"; 
export const WLLOGIN_PATH = API_BASE_SOFT_URL + "/registrations/login"; 
export const WLGAME_TYPE = API_BASE_SOFT_URL + "/website_types/get_list";
export const WLCOUNTRY_DATA = API_BASE_SOFT_URL + "/location/countries/get_countries_list";
export const WLUSER_CONFIG = API_BASE_SOFT_URL + "/configuration/configurations/configurations_api";
export const WLOTP_DATA = API_BASE_SOFT_URL + "/registrations/verifyminemobile";
export const WLMEMBER_SHIP_PLAN = API_BASE_SOFT_URL + "/membership_plans/app_user_plan_list";
export const CURRENCIES = API_BASE_SOFT_URL + "/currencies/app_list";
export const WLVALIDDOMAIN = API_BASE_SOFT_URL + "/registrations/validate_domain_subdm"; 
export const WLSECURITYSETTING = API_BASE_SOFT_URL + "/settings/get_list";
export const WLPROFILE_SETTING = API_BASE_SOFT_URL + "/registrations/update_security_settings";
export const WLTHEME_SETTING = API_BASE_SOFT_URL + "/registrations/update_cmsthemedata";
export const WLBANK_SETTING = API_BASE_SOFT_URL + "/registrations/update_banker_details";
export const WLTHEME_LIST = API_BASE_SOFT_URL + "/cms_themes/get_theme_list";
export const WLMODULE_LIST = API_BASE_SOFT_URL + "/user_modules/app_user_module_list";
export const WLMODULE_SETTING = API_BASE_SOFT_URL + "/registrations/update_addon_modules";
export const WLPAYMENT_SETTING = API_BASE_SOFT_URL + "/payments/validate_payments";


export const LOGINALLOWED = ["deposit","withdraw","stats","loginhistory","mybonus","kycmodule","mybets","statement","account", "message", "playnow", "sports"];
export const PAYBYTYPE = {"1":"Bank to Bank","2":"Cash Deposit","3":"Cash (Others)","4":"PayTM", "5":"PhonePay","6":"GooglePay","7":"Other Pay","8":"Gateway"};
export const PAYBYRECIVER = [1,2,4,5,6,8];
export const PAYBYSENDER = [1,4,5,6,8]; 
export const COMMISSIONTYPE = {1 : "Losing Commission", 2 : "VIP Level Commission"}; 
export const THEMES = ["casinodestination","smdesign1","smdesign2","spike","sporto","brezza","ambiance","freddy","gloster"];
export const reCaptchaKey = "6Le-VdwUAAAAABT9-w1scvpcQRs3BRCL_AnovPl0";//6LcOrH4aAAAAAA0poXIVmYVsbxlz7oyB0ryDVIpa";
import { container } from "../../casino-kit-react.js";
import Background5 from "../../img/verify.png";
import Background6 from "../../img/verifyed.png";
const tabsStyle = {
    signinnerpagebox:{
        background:"#000",
        padding:"0px 20px 0px",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            padding:"0px 0px 0px",
          },
    },
    tablebonus:{
        width:"100%",  
    },
    verify:{
        backgroundImage:"url(" + Background5 + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "100%",
          float:"right",
          width:"24px",
          height:"24px",
          position:"absolute",
          top:"10px",
          right:"10px",
    },
    verifyed:{
        backgroundImage:"url(" + Background6 + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "100%",
          position:"absolute",
          top:"10px",
          right:"10px",
          width:"24px",
          float:"right",
          height:"24px",
    },
    moduleboxwrap:{
        padding:"10px 20px 30px"
    }, 
    modulebox:{
        border:"1px solid #ffb80c",
        background:"#000",
        position:"relative",
        borderRadius:"10px",
        padding:"10px",
        textAlign: "center",
        "& img":{
            maxWidth:"100%",
            height:"160px",
        },
        "& h3":{
            marginTop: "10px",
            fontFamily:"ubuntu",
            fontSize:"18px",
            textAlign:"center",
            color:"#fff !important"
        },
    },
    btntabwarp:{
        marginBottom: "30px",
        "& .tabbtn":{
            background:"#000",
            borderRadius:"0px",
            margin:"0px",
            fontFamily:"ubuntu",
            padding:"10px 20px",
            fontSize:"16px",
            border:"1px solid #ffb80c"
        },
        "& .active":{
            background:"linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07) !important",
            color:"rgb(120,50,5) !important"
        },
        "& .tabbtn:first-child":{
            borderRadius:"25px 0px 0px 25px"
        },
        "& .tabbtn:last-child":{
            borderRadius:"0px 25px 25px 0px"
        }
    },
    tablewrap:{
        overflow:"auto",
        marginBottom:"20px",
        width:"100%",
        "& th":{
            background:"#000",
            padding:"15px",
            fontFamily:"ubuntu",
            fontSize:"14px"
        },
        "& td":{
            padding:"15px",
            fontFamily:"ubuntu",
            fontSize:"14px"
        },
        "& td table th":{
            padding:"5px 10px", 
        },
        "& td table td":{
            padding:"5px 10px", 
        },
        "& a":{
            color:"#FFF"
        },
        "& tr:nth-child(2n)":{
            background: "#2d2d2d",
        },
        "& tr:nth-child(2n+1)":{
            background:"none"
        }
    },
    tableresponsi:{
        color:"#fff !important",
    },
    signinnerpageboxin:{
        background:"#131212",
        margin:"0px 0px 50px",
        borderRadius:"10px"
    },
    tableheading:{
        fontWeight:"bold",
        color: "#fff !important",
        background:"#000 !important"
    }, 
    section: {
        background: "#EEEEEE",
        padding: "70px 0"
    },
    pageheadinginner:{
        fontFamily: "ubuntu",
        color:"#fff",
        fontSize:"22px",
        padding:"10px 40px 0px"
    },
    depositbtn:{
        color: "rgb(120,50,5)",
        border: "0px",
        cursor: "pointer",
        padding: "14px 35px",
        fontSize: "16px",
        boxShadow: "0px 0px 0px",
        transition: "all 0.3s ease-in",
        fontWeight: "600",
        borderRadius: "50px",
        textTransform: "uppercase",
        backgroundImage: "linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07) !important",
        margin: "10px auto 0px",
        display: "block",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            float:"left",
          },
    },
    messagebox:{
        background: "#252f5a",
        padding:"30px 0px",
        borderRadius:"10px",
    }, 
    list:{
      width:"100%",
      display:"block"
    },
    listItem:{
        width:"100%",
        paddingTop:"15px",
        paddingBottom:"15px",
        display:"block",
        color:"#a1aed4",
        fontFamily:"ubuntu",
        fontSize:"18px",
        "& a":{
            margin:"0px",
        },
        "& p":{
            margin:"0px",
        },
        "&:nth-child(even)":{
            background:"#364275"
        },
        "&:nth-child(odd)":{
            background:"none"
        }
    },
    statementfilterbox:{
        fontSize:"18px",
        fontFamily: "ubuntu",
        textAlign: "left",
        color:"#fff",
        paddingBottom: "0px",
        "& select":{
            background:"none",
            border:"0px",
            width:"100%",
            marginTop:"10px",
            borderBottom:"1px solid #f1f1f1",
            height:"40px",
            color:"#fff",
        },
        "& select option":{
            color:"#000",
        },
        "& .selectbox":{
            marginBottom:"20px"
        }
    },
    selectboxx:{ 
        
        border:"0px",
        width:"100%",
        fontSize: "16px",
        fontFamily: "ubuntu",
        padding: "11px 0 11px",
        borderBottom:"1px solid #bdbdbd",
        color:"#fff",
        background:"none !important",
        marginBottom:"20px",
        "& input":{
            color:"#fff !important",
            fontSize:"8px",
            fontFamily:"ubuntu", 
            paddingLeft:"0px",
            paddingTop:"0px",
            height: "28px",
            marginTop:"24px",
            paddingBottom: "0px",
        },
        "& label":{
            color:"#fff !important", 
            fontFamily:"ubuntu", 
            marginTop:"-3px",
            fontSize:"16px",
            transform: "translate(0px, -3px) scale(1)",
        },
        "& div:hover fieldset":{
            borderColor:"#fff !important",
        },
        "& fieldset":{
            color:"#fff !important",
            border:"0px",
            fontFamily:"ubuntu",
            borderBottom:"1px solid #fff",
            borderColor:"#fff !important",
            borderRadius:"0px",
        },
        "& option":{
            color:"#000",
        },
        "& div div":{
            color: "#fff",
            fontSize: "14px",
            fontFamily: "ubuntu",
            padding:"0px",
        }
      },
      formtodate:{
        color:"#fff !important",
        fontFamily:"ubuntu",
        fontSize:"18px",
        paddingLeft: "0",
        marginBottom: "20px",
        "& input":{
            color:"#fff !important",
            fontSize:"14px",
            fontFamily:"ubuntu", 
            paddingLeft:"0px",
            paddingTop:"0px",
            height: "30px",
            paddingBottom: "5px",
        },
        "& label":{
            color:"#fff !important", 
            fontFamily:"ubuntu", 
            transform: "translate(0px, -3px) scale(1)",
        },
        "& div:hover fieldset":{
            borderColor:"#fff !important",
        },
        "& fieldset":{
            color:"#fff !important",
            border:"0px",
            fontFamily:"ubuntu",
            borderBottom:"1px solid #fff",
            borderColor:"#fff !important",
            borderRadius:"0px",
        },
    },
    depositlistwarp:{
        paddingBottom: "20px",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            overflow:"auto",  
            width:"100%",
          }, 
    },
    depositlistw:{
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"1000px",
          }, 
    },
    statementbox:{
        background: "#131212",
        padding:"20px 40px 0px",
        borderRadius:"10px",
        "& p":{
            color:"#a1aed4",
            fontSize:"20px",
            fontFamily: "ubuntu",
        },
        "& i":{
            color:"#a1aed4",
            fontSize:"88px",
        },
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            padding: "20px 20px 0px",
          }, 
    },

    container,
    textCenter: {
        textAlign: "center"
    }
};

export default tabsStyle;

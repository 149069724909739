//import React, {useEffect, useRef, useState} from "react";
import React from "react";
// @material-ui/core components

import 'react-accessible-accordion/dist/fancy-example.css';

import { makeStyles } from "@material-ui/core/styles"; 
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Radio from "@material-ui/core/Radio"; 
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";
import CustomTabs from "../elements/CustomTabs/CustomTabs.js";
import hometabDb from "../data/hometab.js";

import styles from "../assets/jss/components/tabsAccountstyle.js";
import Button from "../elements/CustomButtons/Button";
// import FiberManualRecord from "@material-ui/icons/FiberManualRecord";  
// import Formsy from 'formsy-react'; 
// import {TextFieldFormsy, SelectFormsy} from '../../../@casino'; 
// import * as globalurl from '../../../global/Global.js'; 
import Accountpage from "../pages/account";
import Balance from "../pages/balance";
import Mybets from "../pages/myBets";
// import Statspopup from "../pages/stats-popup";
import Bonuspage from "../pages/myBonus";

// import MobileOtpbox from "../sections/SectionOtpBox"; 
// import VerifyMobile from "../sections/VerifyMobile";
// import VerifyEmail from "../sections/VerifyEmail";
// import KycModule from "./kycModule"; 
// import ListItem from "@material-ui/core/ListItem";
// import List from "@material-ui/core/List";
// import localDb from "../data/bankaccount";
// const token = "kdkddjdj2929j38jv88fpwad83pakwdi3"; 
const useStyles = makeStyles(styles);
var selectedTabName = "NOVALUE";
export default function Hometab(props) {
    const {...rest} = props; 
    
    if(props.themeColors != null){
    styles.tabwrap.background = props.themeColors.bgColors.secondary_dark_bgcolor;
    
    styles.depositbtn.background = props.themeColors.bgColors.main_color;
    styles.firstwrap.background = props.themeColors.bgColors.primary_dark_bgcolor;
    styles.secondwrap.background = props.themeColors.bgColors.primary_inner_bgcolor;
    styles.secondbox.background = props.themeColors.bgColors.primary_dark_bgcolor; 
    styles.thirdwrap.background = props.themeColors.bgColors.primary_light_bgcolor;
    styles.thirdbox.background = props.themeColors.bgColors.primary_inner_bgcolor;
    styles.accountsbox.background = props.themeColors.bgColors.primary_inner_bgcolor;
   //styles.tabSelected.background = props.themeColors.bgColors.firsttab_selected_bg;
    styles.addbtn.background = props.themeColors.bgColors.main_color;
     // styles.depositbtn.background = props.themeColors.bgColors.messagebox;
   // styles.depositbtn.background = props.themeColors.bgColors.messagebox;
   // styles.depositbtn.background = props.themeColors.bgColors.messagebox;
   // styles.depositbtn.background = props.themeColors.bgColors.messagebox;
   // styles.depositbtn.background = props.themeColors.bgColors.messagebox;
   // styles.depositbtn.background = props.themeColors.bgColors.messagebox;
   // styles.depositbtn.background = props.themeColors.bgColors.messagebox;
    }
    const classes = useStyles();  
const [selectedTab, setSelectedTab] = React.useState("NOVALUE");
   var [currentPosition, setCurrentPosition] = React.useState(0);
   var [tabIsMenu, setTabIsMenu] = React.useState(false);
   
   var tabIndex = 0;
   if(props.manualClose === true && tabIsMenu !== false){
    setTabIsMenu(false);
    props.setManualClose(false);
   }
   
   if(selectedTab === "NOVALUE" || selectedTabName !== props.selectedTab){
        hometabDb[sessionStorage.getItem('lang')].forEach((jsondata) => 
        {
            if(jsondata.tagName === props.selectedTab){
                selectedTabName = props.selectedTab;
                setSelectedTab(tabIndex);
            }
            tabIndex++;
        }
    );
   }
   var clickedOnTabs = function(event){
        setSelectedTab(event);
        setCurrentPosition(event);
        currentPosition = event;
   }
    
    return (
        
                <div id="nav-tabs" className={classes.signinnerpageboxin + " " + classes.signinnerpageboxinProfile} >
                    <Button
                        href="/home"
                        color="transparent"
                        className={classes.depositbtn + " " + classes.depositbtns}
                         
                    >
                        <i className={"fa fa-arrow-left"}></i> Back
                    </Button> 
               <div className={classes.tabwrap}>
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomTabs
                        themeColors={props.themeColors}
                        plainTabs
                        border="round"
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        currentPosition={currentPosition}
                        clickEvent={clickedOnTabs}
                        tabs={hometabDb[sessionStorage.getItem('lang')].map((jsondata) => (
                            {
                            tabName: (<span><i className={jsondata.titleicon}></i> {jsondata.title}</span>),
                            tabContent: (
                                <div>
                                {(() =>  {
                                    if (jsondata.tagName === "tab1") {
        
                                        return (
                                        <Accountpage themeColors={props.themeColors} subTab={props.selectedSubTab} manualCheck={tabIsMenu} setTabMenu={setTabIsMenu} {...rest}  />  
                                            )
        
                                    }else if (jsondata.tagName === "tab2"){
                                        return ( 
                                            <Balance themeColors={props.themeColors} subTab={props.selectedSubTab} manualCheck={tabIsMenu} setTabMenu={setTabIsMenu} {...rest} />
                                        )
        
                                    }
                                      else if (jsondata.tagName === "tab3"){
                                        return (
                                            <Mybets  themeColors={props.themeColors} {...rest} />
                                        )
        
                                    } 
                                     else if (jsondata.tagName === "tab4"){
                                        return ( 
                                         <Bonuspage themeColors={props.themeColors} subTab={props.selectedSubTab} {...rest} />
                                        
                                        )
        
                                    } 
                                    // else if (jsondata.tagName === "tab5"){
                                    //     return (
                                    //         <div className={classes.firstwrap}>
                                    //     <div className={classes.firstbox}> 
                                    //     <div className={classes.paddingdiv}>  
                                    //      <Statspopup themeColors={props.themeColors} subTab={props.selectedSubTab} {...rest} />
                                    //     </div>
                                    //     </div>
                                    //     </div>
                                    //     )
        
                                    // }

                                })()}
                                    </div>
        
                            )
                            }
        
                        ))}
                        />
                    </GridItem>
                    </GridContainer>
                     
                </div>
                </div>
               
    );
  }
  
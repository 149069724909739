const kycmoduleDb = {
    en: [ 
        {
            'id':'3923923894', 
            'aadharcard': 'Aadhar Card', 
            'pancard': 'Pan Card', 
            'driving_license': 'Driving License', 
            'aadhar_title': 'Verify Aadhar Card',
            'aadhar_one': 'Front Side Upload',
            'aadhar_two': 'Back Side Upload',
            'aadhar_verify_btn': 'Verify Now',
            'pan_title': 'Verify Aadhar Card',
            'pan_one': 'Front Side Upload',
            'pan_two': 'Back Side Upload',
            'pan_verify_btn': 'Verify Now',
            'dl_title': 'Verify Aadhar Card',
            'dl_one': 'Front Side Upload',
            'dl_two': 'Back Side Upload',
            'dl_verify_btn': 'Verify Now',
            'selecttypeid':"Please select your type of ID",
            'doctype':"Document type",
            'uploaddate':"Upload date",
            'images':"Images",
            'status':"Status",
        },
    ],
    tl: [ 
        {
            'id':'3923923894', 
            'aadharcard': 'ఆధార్ కార్డు', 
            'pancard': 'పాన్ కార్డ్', 
            'driving_license': 'వాహనం నడపడానికి చట్టబద్ధమైన అర్హత', 
            'aadhar_title': 'ఆధార్ కార్డును ధృవీకరించండి',
            'aadhar_one': 'ఫ్రంట్ సైడ్ అప్‌లోడ్',
            'aadhar_two': 'బ్యాక్ సైడ్ అప్‌లోడ్',
            'aadhar_verify_btn': 'ఇప్పుడు ధృవీకరించండి',
            'pan_title': 'ఆధార్ కార్డును ధృవీకరించండి',
            'pan_one': 'ఫ్రంట్ సైడ్ అప్‌లోడ్',
            'pan_two': 'బ్యాక్ సైడ్ అప్‌లోడ్',
            'pan_verify_btn': 'ఇప్పుడు ధృవీకరించండి',
            'dl_title': 'ఆధార్ కార్డును ధృవీకరించండి',
            'dl_one': 'ఫ్రంట్ సైడ్ అప్‌లోడ్',
            'dl_two': 'బ్యాక్ సైడ్ అప్‌లోడ్',
            'dl_verify_btn': 'ఇప్పుడు ధృవీకరించండి',
            'selecttypeid':"దయచేసి మీ ఐడి రకాన్ని ఎంచుకోండి",
            'doctype':"దస్తావేజు పద్దతి",
            'uploaddate':"అప్‌లోడ్ తేదీ",
            'images':"చిత్రాలు",
            'status':"స్థితి",
        },
    ],
    hi: [ 
        {
            'id':'3923923894', 
            'aadharcard': 'आधार कार्ड', 
            'pancard': 'पैन कार्ड', 
            'driving_license': 'ड्राइविंग लाइसेंस',  
            'aadhar_title': 'आधार कार्ड सत्यापित करें',
            'aadhar_one': 'फ्रंट साइड अपलोड',
            'aadhar_two': 'बैक साइड अपलोड',
            'aadhar_verify_btn': 'अभी सत्यापित करें',
            'pan_title': 'पैन कार्ड सत्यापित करें',
            'pan_one': 'फ्रंट साइड अपलोड',
            'pan_two': 'बैक साइड अपलोड',
            'pan_verify_btn': 'अभी सत्यापित करें',
            'dl_title': 'ड्राइविंग लाइसेंस सत्यापित करें',
            'dl_one': 'फ्रंट साइड अपलोड',
            'dl_two': 'बैक साइड अपलोड',
            'dl_verify_btn': 'अभी सत्यापित करें',
            'selecttypeid':"कृपया अपनी आईडी का चयन करें",
            'doctype':"दस्तावेज़ का प्रकार",
            'uploaddate':"अपलोड की तारीख",
            'images':"छवि",
            'status':"स्थिति",
        },
    ]
}
export default kycmoduleDb;

import React from "react";
import axios from 'axios';
import ReactDOM from "react-dom";
// import { createBrowserHistory } from "history";
import * as globalurl from './global/Global.js';
//import App from './App';
import jwtDecode from 'jwt-decode';
import * as serviceWorker from './serviceWorker';
import { DBConfig } from './casino-config/dbConfig';
import { initDB } from 'react-indexed-db';
// pages for this product
// import * as Templates from "./theme/index.js";

if(sessionStorage.getItem('lang') === null || sessionStorage.getItem('lang') === undefined || sessionStorage.getItem('lang') === ""){
    sessionStorage.setItem('lang', "en");
}           
initDB(DBConfig);          
// var hist = createBrowserHistory();
const token = "kdkddjdj2929j38jv88fpwad83pakwdi3";
// const pwaInfo = window.navigator && window.navigator.standalone;
var hostDetails = window.location.hostname.split(".");
if(window.location.protocol === 'http:'){
    if(hostDetails.length === 2){
        window.location = "https://www." + window.location.hostname;
    }else{
        window.location = "https://" + window.location.hostname;
    }
}else{
    if(hostDetails.length === 2){
        window.location = "https://www." + window.location.hostname;
    }
}
let tokenExpired = false;
if(localStorage.getItem("jwt_theme_token") != null && localStorage.getItem("jwt_theme_token").length > 0){
    var {exp}  = jwtDecode(localStorage.getItem("jwt_theme_token"));
    if (Date.now() >= exp * 1000) {
        tokenExpired = true;
    }
    
    if(tokenExpired === true && navigator != null && navigator.onLine != null && navigator.onLine !== true){
        tokenExpired = false;
    }
}else{
    tokenExpired = true;
}

if(localStorage.getItem("jwt_theme_token") != null && localStorage.getItem("jwt_theme_token").length > 0 && tokenExpired === false){
    //var templateName = sessionStorage.getItem("currentTheme");
    const request = axios.get(globalurl.GETBARNDING , {
        params: {
            token
        },
        headers: {
            "Content-Type" : "application/json"
        }
    });
    request.then(async (response) => {
            if(response.data.TOKEN != null){
                localStorage.setItem('jwt_theme_token', response.data.TOKEN);
            }
        }
    );
    let App = require('./App').default;
    ReactDOM.render(
        <App/>,
        document.getElementById("root")
    );
}else{
    const request = axios.get(globalurl.GETBARNDING , {
        params: {
            token
        },
        headers: {
            "Content-Type" : "application/json"
        }
    });
    request.then(async (response) => {
            if(response.data.TOKEN != null){
                localStorage.setItem('jwt_theme_token', response.data.TOKEN);
                //window.location.reload();
                const data = await localStorage.getItem('jwt_theme_token'); 
                if(data){
                    let App = require('./App').default;
                    ReactDOM.render(
                        <App themeInfo={data}/>,
                        document.getElementById("root")
                    );
                }
                
                // setTimeout(new function(){
                //     ReactDOM.render(
                //         <App themeInfo={response.data.TOKEN}/>,
                //         document.getElementById("root")
                //     );
                // }, 4000)
                
                // ReactDOM.render(
                //     <Redirect to="/" />,
                //     document.getElementById("root")
                // );
                
            }
        }
    );
    request.catch((error) => { 
        if(window.location.protocol === 'https:'){
            //window.location = "http://" + window.location.hostname;
        }
        ReactDOM.render(
            <center><h1>Your domain CA certificate is not verified.</h1></center>,
            document.getElementById("root")
        );
    });
}
serviceWorker.register();
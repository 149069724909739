

const loginhistoryDb = {
    en: [
        { 
            'id': '2342424424',
            'pageheading': 'Login History',  
            'period': 'Period',
            'start_date': 'Start Date',
            'end_date': 'End Date',            
            
        },
    ],
    tl: [
        { 
            'id': '2342424424',
            'pageheading': 'లాగిన్ చరిత్ర',  
            'period': 'కాలం',
            'start_date': 'ప్రారంబపు తేది',
            'end_date': 'చివరి తేది',            
            
        },
    ],
    hi: [
        { 
            'id': '32423424234',
            'pageheading': 'लॉगिन इतिहास',  
            'period': 'अवधि',
            'start_date': 'आरंभ करने की तिथि',
            'end_date': 'अंतिम तिथि', 
        },
    ]
}
export default loginhistoryDb;

import React from "react";
// @material-ui/core components
import 'react-accessible-accordion/dist/fancy-example.css';

import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";
import CustomTabs from "../elements/CustomTabs/CustomTabs.js";

import styles from "../assets/jss/components/tabsPromotionstyle.js";

import promotionDb from "../data/promotion";
import Slide from "@material-ui/core/Slide";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";


const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";
export default function Promotions(props) {

    styles.messagebox.background = props.themeColors.bgColors.messagebox;
    styles.listItem["&:nth-child(even)"].background = props.themeColors.bgColors.messageboxlist;
    styles.listItem.color = props.themeColors.bgColors.messageboxlistcolor;

    const classes = useStyles();
    return (
          <div id="nav-tabs" className={classes.signinnerpagebox} >


              <div id="nav-tabs" className={classes.signinnerpageboxin} > 
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomTabs
                  themeColors={props.themeColors}
                  plainTabs
                  border="round"
                  tabs={promotionDb[sessionStorage.getItem('lang')].map((jsondata) => (
                    {
                      tabName: (<span>{jsondata.title}</span>),
                      tabContent: (
                          <div>
                          {(() =>  {
                              if (jsondata.tagName === "tab1") {
  
                                  return (<div className={classes.messagebox}>
                                      <List className={classes.list}>
                                          <ListItem className={classes.listItem}>
                                          {jsondata.tablehead.map((jsondata) => (    
                                           
                                              <GridContainer>
                                                  <GridItem xs={1} sm={1} md={1}>
                                                      <p className={classes.promotionhead}>{jsondata.tablethsno}</p>
                                                  </GridItem>
                                                  <GridItem xs={4} sm={4} md={4}>
                                                      <p className={classes.promotionhead}>{jsondata.tablethname}</p>
                                                  </GridItem>
                                                  <GridItem xs={2} sm={2} md={2}>
                                                      <p className={classes.promotionhead}>{jsondata.tablethstarttime}</p>
                                                  </GridItem>
                                                  <GridItem xs={2} sm={2} md={2}>
                                                      <p className={classes.promotionhead}>{jsondata.tablethendtime}</p>
                                                  </GridItem>
                                                  <GridItem xs={3} sm={3} md={3}>
                                                      <p className={classes.promotionhead}>{jsondata.tablethamoount}</p>
                                                  </GridItem>
                                              </GridContainer>
                                               ))} 
                                          </ListItem>
                                          {jsondata.promessages.map((jsondata) => (
                                              <ListItem className={classes.listItem}>
                                                  <GridContainer>
                                                      <GridItem xs={1} sm={1} md={1}>
                                                          <p className={classes.promotioncont}>{jsondata.srialno}</p>
                                                      </GridItem>
                                                      <GridItem xs={4} sm={4} md={4}>
                                                          <p className={classes.promotioncont}>{jsondata.name}</p>
                                                      </GridItem>
                                                      <GridItem xs={2} sm={2} md={2}>
                                                          <p className={classes.promotioncont}>{jsondata.startdate}</p>
                                                      </GridItem>
                                                      <GridItem xs={2} sm={2} md={2}>
                                                          <p className={classes.promotioncont}>{jsondata.enddate}</p>
                                                      </GridItem>
                                                      <GridItem xs={3} sm={3} md={3}>
                                                          <p className={classes.promotioncont}>{jsondata.amount}</p>
                                                      </GridItem>
                                                  </GridContainer>
                                              </ListItem>
                                          ))}
                                      </List>
                                  </div>)
  
                              }else if (jsondata.tagName === "tab2"){
                                  return (<div className={classes.messagebox}>
                                      <List className={classes.list}>
                                          <ListItem className={classes.listItem}>
                                          {jsondata.tablehead.map((jsondata) => (    
                                           
                                           <GridContainer>
                                               <GridItem xs={1} sm={1} md={1}>
                                                   <p className={classes.promotionhead}>{jsondata.tablethsno}</p>
                                               </GridItem>
                                               <GridItem xs={4} sm={4} md={4}>
                                                   <p className={classes.promotionhead}>{jsondata.tablethname}</p>
                                               </GridItem>
                                               <GridItem xs={2} sm={2} md={2}>
                                                   <p className={classes.promotionhead}>{jsondata.tablethstarttime}</p>
                                               </GridItem>
                                               <GridItem xs={2} sm={2} md={2}>
                                                   <p className={classes.promotionhead}>{jsondata.tablethendtime}</p>
                                               </GridItem>
                                               <GridItem xs={3} sm={3} md={3}>
                                                   <p className={classes.promotionhead}>{jsondata.tablethamoount}</p>
                                               </GridItem>
                                           </GridContainer>
                                            ))} 
                                          </ListItem>
                                          {jsondata.promessages.map((jsondata) => (
                                              <ListItem className={classes.listItem}>
                                                  <GridContainer>
                                                      <GridItem xs={1} sm={1} md={1}>
                                                          <p className={classes.promotioncont}>{jsondata.srialno}</p>
                                                      </GridItem>
                                                      <GridItem xs={4} sm={4} md={4}>
                                                          <p className={classes.promotioncont}>{jsondata.name}</p>
                                                      </GridItem>
                                                      <GridItem xs={2} sm={2} md={2}>
                                                          <p className={classes.promotioncont}>{jsondata.startdate}</p>
                                                      </GridItem>
                                                      <GridItem xs={2} sm={2} md={2}>
                                                          <p className={classes.promotioncont}>{jsondata.enddate}</p>
                                                      </GridItem>
                                                      <GridItem xs={3} sm={3} md={3}>
                                                          <p className={classes.promotioncont}>{jsondata.amount}</p>
                                                      </GridItem>
                                                  </GridContainer>
                                              </ListItem>
                                          ))}
                                      </List>
                                  </div>)
  
                              }
                          })()}
                              </div>
  
                      )
                    }
  
                  ))}
                />
              </GridItem>
            </GridContainer>
            </div>   
             </div>
  
    );
  }
  
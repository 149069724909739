

const statementDb = {
    en: [
        { 
            'id': 'stmhddi0r_1',
            'pageheading': 'Bank Accounts', 
            'periodheading': 'Period',
            'addbankaccount':"Add Bank Account",
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 2,
                    'field_name': 'Name',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'Number',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 2,
                    'field_name': 'IFSC',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'Bank',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 2,
                    'field_name': 'Branch',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 1,
                    'field_name': 'Address',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 1,
                    'field_name': 'Action',
                    'visible': 'desktop',
                    'expandable': true
                },
                

            ],
            'tabledata':[
                {
                    'id'    : '5725a680b3249760ea2wde51',
                    'type'  : '1',
                    'name'  : 'Signup bonus- new ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde52',
                    'type'  : '2',
                    'name'  : 'Signup bonus- new ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde53',
                    'type'  : '3',
                    'name'  : 'Signup bonus- new ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ] ,
            'periodoptions': [
                {
                    'id':"5725a680b3249760ea2wdd01", 
                    'periodoption':"All", 
                },
                { 
                    'id':"5725a680b3249760ea2wdd02", 
                    'periodoption':"Today",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd03", 
                    'periodoption':"This Week",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd04", 
                    'periodoption':"This Month",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd05", 
                    'periodoption':"Specific Time",
                },  
            ], 
            'paymentheading':"Payment Type",
            'paymentoptions': [
                {
                    'id':"5725a680b3249760ea2wdd06", 
                    'paymentoption':"All",
                },
                {
                    'id':"5725a680b3249760ea2wdd07", 
                    'paymentoption':"Deposit",
                },
                {
                    'id':"5725a680b3249760ea2wdd08", 
                    'paymentoption':"Withdraw",
                },
                {
                    'id':"5725a680b3249760ea2wdd09", 
                    'paymentoption':"Games",
                }, 
            ],
            'searchbtn': "Search", 
            'tablehead':[
                { 
                    'tablethsno'  : 'S.No', 
                    'tablethname'  : 'Name',  
                    'tablethstarttime'  : 'Start Time',  
                    'tablethendtime'  : 'End Time',  
                    'tablethamoount'  : 'Amount', 
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'Signup bonus- new ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'Signup bonus- new ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'Signup bonus- new ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ],
            'loadmore':"Load more...",

        },
    ],
    tm: [
        { 
            'id': 'stmhddi0r_1',
            'pageheading': 'வங்கி கணக்குகள்', 
            'periodheading': 'காலம்',
            'addbankaccount':"வங்கி கணக்கைச் சேர்க்கவும்",
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 2,
                    'field_name': 'பெயர்',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'எண்',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 2,
                    'field_name': 'IFSC',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'வங்கி',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 2,
                    'field_name': 'கிளை',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 1,
                    'field_name': 'முகவரி',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 1,
                    'field_name': 'செயல்',
                    'visible': 'desktop',
                    'expandable': true
                },
                

            ],
            'tabledata':[
                {
                    'id'    : '5725a680b3249760ea2wde51',
                    'type'  : '1',
                    'name'  : 'பதிவுபெறும் போனஸ்- புதியது ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde52',
                    'type'  : '2',
                    'name'  : 'பதிவுபெறும் போனஸ்- புதியது  ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde53',
                    'type'  : '3',
                    'name'  : 'பதிவுபெறும் போனஸ்- புதியது  ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ] ,
            'periodoptions': [
                {
                    'id':"5725a680b3249760ea2wdd01", 
                    'periodoption':"அனைத்தும்", 
                },
                { 
                    'id':"5725a680b3249760ea2wdd02", 
                    'periodoption':"இன்று",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd03", 
                    'periodoption':"இந்த வாரம்",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd04", 
                    'periodoption':"இந்த மாதம்",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd05", 
                    'periodoption':"குறிப்பிட்ட நேரத்தில்",
                },  
            ], 
            'paymentheading':"கட்டணம் வகை",
            'paymentoptions': [
                {
                    'id':"5725a680b3249760ea2wdd06", 
                    'paymentoption':"அனைத்தும்",
                },
                {
                    'id':"5725a680b3249760ea2wdd07", 
                    'paymentoption':"வைப்பு",
                },
                {
                    'id':"5725a680b3249760ea2wdd08", 
                    'paymentoption':"திரும்பப் பெறுங்கள்",
                },
                {
                    'id':"5725a680b3249760ea2wdd09", 
                    'paymentoption':"விளையாட்டுகள்",
                }, 
            ],
            'searchbtn': "தேடல்", 
            'tablehead':[
                { 
                    'tablethsno'  : 'எஸ்', 
                    'tablethname'  : 'பெயர்',  
                    'tablethstarttime'  : 'ஆரம்பிக்கும் நேரம்',  
                    'tablethendtime'  : 'இறுதி நேரம்',  
                    'tablethamoount'  : 'தொகை', 
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'பதிவுபெறும் போனஸ்- புதியது ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'பதிவுபெறும் போனஸ்- புதியது ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'பதிவுபெறும் போனஸ்- புதியது ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ],
            'loadmore':"அதிகமாக ஏற்று...",

        },
    ],
    kn: [
        { 
            'id': 'stmhddi0r_1',
            'pageheading': 'ಬ್ಯಾಂಕ್ ಖಾತೆಗಳು', 
            'periodheading': 'ಅವಧಿ',
            'addbankaccount':"ಬ್ಯಾಂಕ್ ಖಾತೆಯನ್ನು ಸೇರಿಸಿ",
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 2,
                    'field_name': 'ಹೆಸರು',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'ಸಂಖ್ಯೆ',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 2,
                    'field_name': 'ಐಎಫ್‌ಎಸ್‌ಸಿ',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'ಬ್ಯಾಂಕ್',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 2,
                    'field_name': 'ಶಾಖೆ',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 1,
                    'field_name': 'ವಿಳಾಸ',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 1,
                    'field_name': 'ಕ್ರಿಯೆ',
                    'visible': 'desktop',
                    'expandable': true
                },
                

            ],
            'tabledata':[
                {
                    'id'    : '5725a680b3249760ea2wde51',
                    'type'  : '1',
                    'name'  : 'ಸೈನ್ ಅಪ್ ಬೋನಸ್- ಹೊಸದು ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde52',
                    'type'  : '2',
                    'name'  : 'ಸೈನ್ ಅಪ್ ಬೋನಸ್- ಹೊಸದು ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde53',
                    'type'  : '3',
                    'name'  : 'ಸೈನ್ ಅಪ್ ಬೋನಸ್- ಹೊಸದು ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ] ,
            'periodoptions': [
                {
                    'id':"5725a680b3249760ea2wdd01", 
                    'periodoption':"ಎಲ್ಲಾ", 
                },
                { 
                    'id':"5725a680b3249760ea2wdd02", 
                    'periodoption':"ಇಂದು",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd03", 
                    'periodoption':"ಈ ವಾರ",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd04", 
                    'periodoption':"ಈ ತಿಂಗಳು",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd05", 
                    'periodoption':"ನಿರ್ದಿಷ್ಟ ಸಮಯ",
                },  
            ], 
            'paymentheading':"ಪಾವತಿ ವಿಧಾನ",
            'paymentoptions': [
                {
                    'id':"5725a680b3249760ea2wdd06", 
                    'paymentoption':"ಎಲ್ಲಾ",
                },
                {
                    'id':"5725a680b3249760ea2wdd07", 
                    'paymentoption':"ಠೇವಣಿ",
                },
                {
                    'id':"5725a680b3249760ea2wdd08", 
                    'paymentoption':"ಹಿಂತೆಗೆದುಕೊಳ್ಳಿ",
                },
                {
                    'id':"5725a680b3249760ea2wdd09", 
                    'paymentoption':"ಆಟಗಳು",
                }, 
            ],
            'searchbtn': "ಹುಡುಕಿ", 
            'tablehead':[
                { 
                    'tablethsno'  : 'ಎಸ್.ನಂ.', 
                    'tablethname'  : 'ಹೆಸರು',  
                    'tablethstarttime'  : 'ಆರಂಭವಾಗುವ',  
                    'tablethendtime'  : 'ಅಂತಿಮ ಸಮಯ',  
                    'tablethamoount'  : 'ಮೊತ್ತ', 
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'ಸೈನ್ ಅಪ್ ಬೋನಸ್- ಹೊಸದು ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'ಸೈನ್ ಅಪ್ ಬೋನಸ್- ಹೊಸದು ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'ಸೈನ್ ಅಪ್ ಬೋನಸ್- ಹೊಸದು ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ],
            'loadmore':"ಇನ್ನಷ್ಟು ಲೋಡ್ ಮಾಡಿ ...",

        },
    ],
    ne: [
        { 
            'id': 'stmhddi0r_1',
            'pageheading': 'बैंक खाताहरू', 
            'periodheading': 'अवधि',
            'addbankaccount':"बैंक खाता थप्नुहोस्",
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 2,
                    'field_name': 'नाम',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'संख्या',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 2,
                    'field_name': 'आईएफएससी',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'बैंक',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 2,
                    'field_name': 'शाखा',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 1,
                    'field_name': 'ठेगाना',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 1,
                    'field_name': 'कार्य',
                    'visible': 'desktop',
                    'expandable': true
                },
                

            ],
            'tabledata':[
                {
                    'id'    : '5725a680b3249760ea2wde51',
                    'type'  : '1',
                    'name'  : 'साइन अप बोनस - नयाँ ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde52',
                    'type'  : '2',
                    'name'  : 'साइन अप बोनस - नयाँ  ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde53',
                    'type'  : '3',
                    'name'  : 'साइन अप बोनस - नयाँ ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ] ,
            'periodoptions': [
                {
                    'id':"5725a680b3249760ea2wdd01", 
                    'periodoption':"सबै", 
                },
                { 
                    'id':"5725a680b3249760ea2wdd02", 
                    'periodoption':"आज",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd03", 
                    'periodoption':"यो हप्ता",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd04", 
                    'periodoption':"यो महिना",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd05", 
                    'periodoption':"विशिष्ट समय",
                },  
            ], 
            'paymentheading':"भुक्तानी प्रकार",
            'paymentoptions': [
                {
                    'id':"5725a680b3249760ea2wdd06", 
                    'paymentoption':"सबै",
                },
                {
                    'id':"5725a680b3249760ea2wdd07", 
                    'paymentoption':"निक्षेप",
                },
                {
                    'id':"5725a680b3249760ea2wdd08", 
                    'paymentoption':"निकाल्नु",
                },
                {
                    'id':"5725a680b3249760ea2wdd09", 
                    'paymentoption':"खेलहरू",
                }, 
            ],
            'searchbtn': "खोज्नुहोस्", 
            'tablehead':[
                { 
                    'tablethsno'  : 'S.No', 
                    'tablethname'  : 'नाम',  
                    'tablethstarttime'  : 'सुरु समय',  
                    'tablethendtime'  : 'अन्त समय',  
                    'tablethamoount'  : 'रकम', 
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'साइन अप बोनस - नयाँ ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'साइन अप बोनस - नयाँ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'साइन अप बोनस - नयाँ ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ],
            'loadmore':"अधिक लोड गर्नुहोस् ...",

        },
    ],
    tl: [
        { 
            'id': 'stmhddi0r_1',
            'pageheading': 'బ్యాంకు ఖాతాల', 
            'periodheading': 'కాలం', 
            'addbankaccount':"బ్యాంక్ ఖాతాను జోడించండి",
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 2,
                    'field_name': 'పేరు',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'సంఖ్య',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 2,
                    'field_name': 'IFSC',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'బ్యాంక్',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 2,
                    'field_name': 'బ్రాంచ్',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 1,
                    'field_name': 'చిరునామా',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 1,
                    'field_name': 'చర్య',
                    'visible': 'desktop',
                    'expandable': true
                },
                

            ],
            'tabledata':[
                {
                    'id'    : '5725a680b3249760ea2wde51',
                    'type'  : '1',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde52',
                    'type'  : '2',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde53',
                    'type'  : '3',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ] ,
            'periodoptions': [
                {
                    'id':"5725a680b3249760ea2wdd01", 
                    'periodoption':"అన్నీ", 
                },
                { 
                    'id':"5725a680b3249760ea2wdd02", 
                    'periodoption':"ఈ రోజు",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd03", 
                    'periodoption':"ఈ వారం",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd04", 
                    'periodoption':"ఈ నెల",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd05", 
                    'periodoption':"నిర్దిష్ట సమయం",
                },  
            ], 
            'paymentheading':"చెల్లించు విధానము",
            'paymentoptions': [
                {
                    'id':"5725a680b3249760ea2wdd06", 
                    'paymentoption':"అన్నీ",
                },
                {
                    'id':"5725a680b3249760ea2wdd07", 
                    'paymentoption':"డిపాజిట్",
                },
                {
                    'id':"5725a680b3249760ea2wdd08", 
                    'paymentoption':"ఉపసంహరించుకోండి",
                },
                {
                    'id':"5725a680b3249760ea2wdd09", 
                    'paymentoption':"ఆటలు",
                }, 
            ],
            'searchbtn': "వెతకండి", 
            'tablehead':[
                { 
                    'tablethsno'  : 'ఎస్', 
                    'tablethname'  : 'పేరు',  
                    'tablethstarttime'  : 'ప్రారంభ సమయం',  
                    'tablethendtime'  : 'ముగింపు సమయం',  
                    'tablethamoount'  : 'మొత్తం', 
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ],            
            'loadmore':"మరింత లోడ్ చేయండి ...",
        },
    ],
    hi: [
        { 
            'id': 'stmhddi0r_1',
            'pageheading': 'बैंक खाते', 
            'periodheading': 'अवधि',
            'addbankaccount':"बैंक खाता जोड़ें",
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 2,
                    'field_name': 'नाम',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                     'field_name': 'संख्या',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 2,
                    'field_name': 'IFSC',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'बैंक',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 2,
                    'field_name': 'शाखा',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 1,
                    'field_name': 'पता',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 1,
                    'field_name': 'कार्य',
                    'visible': 'desktop',
                    'expandable': true
                },
                

            ],  
            'tabledata':[
                {
                    'id'    : '5725a680b3249760ea2wde51',
                    'type'  : '1',
                    'name'  : 'साइनअप बोनस- नया ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde52',
                    'type'  : '2',
                    'name'  : 'साइनअप बोनस- नया ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde53',
                    'type'  : '3',
                    'name'  : 'साइनअप बोनस- नया ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
            ],
            'periodoptions': [
                {
                    'id':"5725a680b3249760ea2wdd01", 
                    'periodoption':"सब", 
                },
                { 
                    'id':"5725a680b3249760ea2wdd02", 
                    'periodoption':"आज",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd03", 
                    'periodoption':"इस सप्ताह",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd04", 
                    'periodoption':"इस महीने",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd05", 
                    'periodoption':"विशिष्ट समय",
                },  
            ], 
            'paymentheading':"भुगतान के प्रकार",
            'paymentoptions': [
                {
                    'id':"5725a680b3249760ea2wdd06", 
                    'paymentoption':"सब",
                },
                {
                    'id':"5725a680b3249760ea2wdd07", 
                    'paymentoption':"जमा",
                },
                {
                    'id':"5725a680b3249760ea2wdd08", 
                    'paymentoption':"निकालना",
                },
                {
                    'id':"5725a680b3249760ea2wdd09", 
                    'paymentoption':"खेल",
                } 
            ],
            'searchbtn': "खोज", 
            'tablehead':[
                { 
                    'tablethsno'  : 'नम्बर', 
                    'tablethname'  : 'नाम',  
                    'tablethstarttime'  : 'समय शुरू',  
                    'tablethendtime'  : 'अंतिम समय',  
                    'tablethamoount'  : 'रकम', 
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'साइनअप बोनस- नया ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'साइनअप बोनस- नया ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'साइनअप बोनस- नया ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
            ],
             'loadmore':"और लोड करें...",
        },
    ]
}
export default statementDb;

//import React, {useState} from "react";
import React from "react";
// @material-ui/core components
import 'react-accessible-accordion/dist/fancy-example.css';

import axios from 'axios';
import * as globalurl from '../../../global/Global.js'; 

import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";
    
import styles from "../assets/jss/components/tabsStatementstyle.js";
import Button from "../elements/CustomButtons/Button";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
//import * as Datetime from 'react-datetime'; 
//database selection 
import localDb from "../data/withdraw";
    import Norecord from "../assets/img/no-record.png";
const useStyles = makeStyles(styles);
 
var isExistLoginApiCall = false;
export default function SectionWithdrawList(props) { 
    if(props.themeColors != null){
        styles.messagebox.background = props.themeColors.bgColors.secondary_dark_bgcolor;
        styles.listItem["&:nth-child(even)"].background = props.themeColors.bgColors.primary_dark_bgcolor;
        styles.listItem.color = props.themeColors.bgColors.text_primary_color; 
        styles.tableheading.background = props.themeColors.bgColors.primary_bgcolor; 
        styles.depositbtn.background = props.themeColors.bgColors.main_color; 
    }
    const classes = useStyles();
    const [dateConfig, setDateConfig] = React.useState("NO");
    const [limitStartFrom, setLimitStartFrom] = React.useState(0);
    const limitMaxRange = 5;
    const [loadMore, setLoadMore] = React.useState(false);
    const [isChange, setIsChange] = React.useState(false);
    const [statementData, setStatementData] = React.useState([{status: false}]);
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    const isObject = function(a) {
        return (!!a) && (a.constructor === Object);
    };
    if(dateConfig === "NO"){
        setDateConfig({start: props.startDate, end: props.endDate})
    }
    if(dateConfig.start !== null && ((props.startDate !== undefined && props.startDate !== dateConfig.start) || (props.endDate !== undefined && props.endDate !== dateConfig.end))){
        setDateConfig({start: props.startDate, end: props.endDate});
        setIsChange(true);
        setLoadMore(true);
    }
    if(((statementData[0].status === false && statementData[0].checked === null && props.statement != null) || (loadMore === true && props.statement != null)) && isExistLoginApiCall === false){
        //alert("IN:" + payByType);
        isExistLoginApiCall = true;
        const request = axios.post(globalurl.GETWITHDRAWREQUESTSTATEMENT , {
            token: window.localStorage.getItem('jwt_access_token'),
            start_date: dateConfig.start,
            end_date: dateConfig.end,
            LSTART: limitStartFrom,
            LEND: limitMaxRange
        });

        request.then((response) => {
            isExistLoginApiCall = false;
            setLoadMore(false);
            var statementDb = "";
            if(response.data.data != null){
                statementDb = [{
                    "status":true,
                    "data":response.data.data
                }]
                if(statementData[0].status != null && statementData[0].status === true && isChange === false){
                    if(Array.isArray(statementDb[0].data)){
                        statementDb[0].data = [...statementData[0].data, ...statementDb[0].data];
                    }else if(statementDb[0].data != null){
                        statementDb[0].data = [...statementData[0].data, statementDb[0].data];
                    }else{
                        statementDb[0].data = statementData[0].data;
                    }
                    
                    setStatementData(statementDb);
                }else{
                    setStatementData(statementDb);
                    setIsChange(false);
                }
            }else{
                statementDb = [{
                    "status":false,
                    "checked":false
                }]   
                if(statementData[0].status != null && statementData[0].status === true){
                    if(isChange === true){
                        setStatementData(statementDb);
                    }else{
                        setLimitStartFrom((limitStartFrom - limitMaxRange));
                    }
                }else{
                    setStatementData(statementDb);
                }
            }
        });
        request.catch((error) => {
            isExistLoginApiCall = false;
            setLoadMore(false);
            var statementDb = [{
                "status":false
            }]   
            setStatementData(statementDb);
        });
    }
    var LoadMoreRecord = function(){
        setLimitStartFrom((limitStartFrom + limitMaxRange));
        setLoadMore(true);
    }
    return(
        <div className={classes.withdrawlistmobiwrap}>
        {(() => {
            if(statementData[0].status != null && statementData[0].status === true){
                return (<div id="nav-tabs" className={classes.withdrawlistmobi} >
                {statementData.map((jsondata) => (
                        <div   key={":myunique_1"}> 
                            <table> 
                                <tr>
                                    {localDb[sessionStorage.getItem('lang')][0].tableheader.map((headData) => (
                                    <th key={headData.id}>{headData.field_name}</th>
                                    ))} 
                                </tr> 
                                {(() => {if(Array.isArray(jsondata.data)){return (jsondata.data.map((bodyData) => (
                                    <tr key={bodyData.id}>
                                        <td>{bodyData.id}</td>
                                        <td>{bodyData.user_bank_acc_no}</td>
                                        <td>{(() => {if(parseFloat(bodyData.amount).toFixed(2) === "0.00"){return ("0.00")}else{return(parseFloat(bodyData.amount).toFixed(2))}})()}</td>
                                        <td>{(new Date(bodyData.created)).toLocaleDateString('en-US', DATE_OPTIONS)}</td>
                                        <td>
                                            {(()=>{
                                                if(bodyData.status === "APPROVED"){
                                                return(
                                                    <span className={classes.approved}>{bodyData.status}</span>
                                                ) 
                                            }else if(bodyData.status === "REJECTED"){
                                                return(
                                                    <span className={classes.rejected}>{bodyData.status}</span>
                                                )
                                            }
                                            else{
                                                return(
                                                    <span className={classes.pending}>{bodyData.status}</span> 
                                                )
                                            }
                                            })()}
                                        </td>
                                    </tr>
                                )))}
                                else if(isObject(jsondata.data)){return(
                                    <tr>
                                        <td>{jsondata.data.id}</td>
                                        <td>{jsondata.data.user_bank_acc_no}</td>
                                        <td>{(() => {if(parseFloat(jsondata.data.amount).toFixed(2) === "0.00"){return ("0.00")}else{return(parseFloat(jsondata.data.amount).toFixed(2))}})()}</td>
                                        <td>{(new Date(jsondata.data.created)).toLocaleDateString('en-US', DATE_OPTIONS)}</td>
                                        <td>{jsondata.data.status}</td>
                                    </tr>
                                )}else{
                                    return(
                                    <tr key={"statement_nodata"}>
                                        <td><p className={classes.promotioncont}><img src={Norecord} className={classes.norecordimg + " norecordimg"} style={{display:"block", margin:"0px auto"}} alt=""/></p></td>
                                    </tr>
                                 )
                                }})()}
                            </table> 
                            
                         
                            
                                <Button
                                    href=""
                                    color="transparent"
                                    className={classes.depositbtn}
                                    onClick={LoadMoreRecord}
                                >
                                Load more...
                                </Button>
                            
                        </div>
                    ))}
            </div>);
            }else{
                return (
                    <ListItem className={classes.listItem} key={"statement_nodata"}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <p className={classes.promotioncont}><img src={Norecord} className={classes.norecordimg + " norecordimg"} style={{display:"block", margin:"0px auto"}} alt=""/></p>
                            </GridItem>
                        </GridContainer>
                    </ListItem>
                    );
            }
        })()}
        </div>
    );
}
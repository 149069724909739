import React, {useEffect, useRef, useState}  from "react";
import {makeStyles} from "@material-ui/core/styles"; 
import styles from "../assets/jss/views/landingPage.js"; 
import GridItem from "../elements/Grid/GridItem";
import GridContainer from "../elements/Grid/GridContainer";
import logo from "../assets/img/logo.png";
import screenone from "../assets/img/screenone.jpg";
import screentwo from "../assets/img/screentwo.jpg";
import screenthree from "../assets/img/screenthree.jpg";
import screenfour from "../assets/img/screenfour.png"; 

// import girl from "../assets/img/girl.png"; 
import Formsy from 'formsy-react';  
import {TextFieldFormsy, SelectFormsy} from '../../../@casino';
import history from '../../../@history';

import {Button, MenuItem} from '@material-ui/core';

import * as authActions from '../../../auth/casino/actions';
import {useDispatch, useSelector} from 'react-redux';
import * as Gurl from "../../../global/Global.js"
import axios from 'axios';

import ReCAPTCHA from "react-google-recaptcha";
import Zoom from '@material-ui/core/Zoom';
// const token = "kdkddjdj2929j38jv88fpwad83pakwdi3"; 

const useStyles = makeStyles(styles);
// const recaptchaRef = React.createRef();

// const validEmailRegex = 
//   RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

export default function Home(props) { 
    const classes = useStyles(); 
    let recaptchaInstance, recaptchaInstanceOtp, recaptchaInstanceNew;
    const dispatch = useDispatch();
    let [recaptchaHide, setRecaptchaHide] = React.useState(true);
    const otpCode = "NOVALUE";
    const [recaptchaOtpCode, setRecaptchaOtpCode] = React.useState("NOVALUE");
    const [userName, setUserName] = React.useState("");
    const [userId, setUserId] = React.useState(0);
      const wlOtp = useSelector(({auth}) => auth.wlOtp);
 
   /*sign up  start*/
   const [countryData, setCountryData] = React.useState("NOVALUE");
    const [gameType, setGameType] = React.useState("NOVALUE");
    const [mobileNumberMaxLen, setMobileNumberMaxLen] = React.useState(9);
    const [currentCountry, setCurrentCountry] = React.useState("Sri Lanka"); 
    const [currentCountryId, setCurrentCountryId] = React.useState("200"); 
    const [userConfig, setUserConfig] = React.useState("NOVALUE");
    const [classAdd, setClassAdd] = useState(false);
    const [countryCode, setCountryCode] = React.useState("NOVALUE");
    const [recaptchaCode, setRecaptchaCode] = React.useState("NOVALUE");
    const [isFormValid, setIsFormValid] = useState(false); 
    const [isError, setIsError] = useState(false);  
    const formRef = useRef(null);
    const wlregister = useSelector(({auth}) => auth.wlregister);
    const resendotp = useSelector(({auth}) => auth.resendotp);
    
    /*sign up  end*/
 
    const forgotformRef = useRef(null);
    const otpformRef = useRef(null);
    
    const resetRecaptcha = () => {
      recaptchaInstance.reset();  
    };
    const resetRecaptchaOtp = () => {
      recaptchaInstanceOtp.reset();  
    };
    if(recaptchaHide === true && document.body.getElementsByClassName("recapchadivm")[0] != null){
        document.body
      .getElementsByClassName("recapchadivm")[0]
      .setAttribute("style", "display:none;");  
    }else{
        if(document.body.getElementsByClassName("recapchadivm")[0] != null){
            document.body
            .getElementsByClassName("recapchadivm")[0]
            .setAttribute("style", "display:block;"); 
        }
    }
    // function otpBox(){    
    //     document.body 
    //         .getElementsByClassName(classes.signupwrap)[0]
    //         .setAttribute("style", "display:none;");
    //     document.body 
    //         .getElementsByClassName(classes.otpwrap)[0]
    //         .setAttribute("style", "display:block;"); 
    //     document.body 
    //         .getElementsByClassName(classes.forgotwrap)[0]
    //         .setAttribute("style", "display:none;");
    // }
    
    useEffect(() => {
        if ( wlOtp.error && (wlOtp.error.otp ||  wlOtp.error.rid ||  wlOtp.error.user_id || wlOtp.error.recaptcha || wlOtp.error.message ) ){
            if(wlOtp.error.message != null){ 
                resetRecaptchaOtp();
                document.body
                    .getElementsByClassName("message")[0]
                    .innerHTML=wlOtp.error.message 
                setClassAdd(classes.msg = " errormsg" );
            }else{
                resetRecaptchaOtp();
                otpformRef.current.updateInputsWithError({
                    ...wlOtp.error
                });
            }
        }else{
            if(wlOtp.data != null && wlOtp.data.message != null){
                setTimeout(function() {
                    history.push({
                        pathname:'/profilesetup'
                    })
                }, 3000);

                document.body
                .getElementsByClassName("message_otp")[0].innerHTML =wlOtp.data.message
                setClassAdd(classes.msg = " successmsg" );
            }
        }
    }, [wlOtp.error, classes.msg, wlOtp.data]);

    

    function handleSubmitotp(model){
        model = {"recaptcha": recaptchaOtpCode, ...model}
        dispatch(authActions.wlsubmitOtp(model,props));
        document.body
            .getElementsByClassName("message")[0]
            .innerHTML=""; 
    }
    
    var verifyOtpCallback = function (response) {
        setRecaptchaOtpCode(response);
    };
 
    // function forgotBox(){    
    //     document.body 
    //         .getElementsByClassName(classes.signupwrap)[0]
    //         .setAttribute("style", "display:none;");
    //     document.body 
    //         .getElementsByClassName(classes.otpwrap)[0]
    //         .setAttribute("style", "display:none;"); 
    //     document.body 
    //         .getElementsByClassName(classes.forgotwrap)[0]
    //         .setAttribute("style", "display:block;");
    // }
       

    /*sign up  start*/
    function signupBox(){    
        document.body 
            .getElementsByClassName(classes.otpwrap)[0]
            .setAttribute("style", "display:none;");
        document.body 
            .getElementsByClassName(classes.signupwrap)[0]
            .setAttribute("style", "display:block;");
        document.body 
            .getElementsByClassName(classes.loginwrap)[0]
            .setAttribute("style", "display:none;");
        document.body 
            .getElementsByClassName(classes.forgotwrap)[0]
            .setAttribute("style", "display:none;");
    } 
    // useEffect(() => {
    //     alert("CHECKME:" + JSON.stringify(wlregister));
    // }, [dispatch]);
    useEffect(() => {
        if ( wlregister.error && (wlregister.error.message || wlregister.error.website_type_id || wlregister.error.country_id || wlregister.error.mobile || wlregister.error.email || wlregister.error.firm_name || wlregister.error.company_name || wlregister.error.name || wlregister.error.username || wlregister.error.password || wlregister.error.confirm_password || wlregister.error.recaptcha || wlregister.error.user_id)){
            setIsError(false)
            if(wlregister.error.message != null){
                resetRecaptcha();
                document.body
                    .getElementsByClassName("message")[0] 
                    .innerHTML=wlregister.error.message 
            }else{
                resetRecaptcha();
                formRef.current.updateInputsWithError({
                    ...wlregister.error
                });
            }
            disableButton(); 
        }else{
            if(wlregister.success != null && wlregister.success === true){
                if(wlregister.data != null && wlregister.data.data != null){
                    if(wlregister.data.data > 0){
                        setUserId(wlregister.data.data);
                    }
                }
                document.body 
                    .getElementsByClassName("otpwrap")[0] 
                    .setAttribute("style", "display:block !important;"); 
                
                document.body 
                    .getElementsByClassName("signupwrap")[0] 
                    .setAttribute("style", "display:none !important;"); 
            }
            if(wlregister.status != null && wlregister.status === 1){
                document.body 
                    .getElementsByClassName("otpwrap")[0] 
                    .setAttribute("style", "display:block !important;"); 
                
                document.body 
                    .getElementsByClassName("signupwrap")[0] 
                    .setAttribute("style", "display:none !important;"); 
            }
        } 
    }, [wlregister.error, wlregister.data, wlregister.status, wlregister.success,]);

    useEffect(() => {
        if ( resendotp.error && (resendotp.error.message || resendotp.error.user_id)){
            setIsError(false) 
            if(resendotp.error.message != null){ 
                document.body
                    .getElementsByClassName("messageresend")[0] 
                    .innerHTML=resendotp.error.message 
            }else{ 
                formRef.current.updateInputsWithError({
                     
                });
            }
            disableButton(); 
        }else{
            if(resendotp.success != null && resendotp.success === true){
                if(resendotp.data != null && resendotp.data.data != null){
                    if(resendotp.data.data > 0){
                        setUserId(resendotp.data.data);
                    }
                }
               
            }
            if(resendotp.status != null && resendotp.status === 1){
                
            }
        } 
    }, [resendotp.error, resendotp.data, resendotp.status, setIsError, resendotp.success]);

    function handleResendOtp( ){ 
        dispatch(authActions.resendOtp(userId));
        document.body
            .getElementsByClassName("message")[0]
            .innerHTML=" " 
    }

    let setRequestTime = function(mydata){
        if(mydata.status != null && mydata.data != null && mydata.status === 1){
            if(mydata.data != null && mydata.data > 0){
                setUserId(mydata.data);
                document.body 
                    .getElementsByClassName("otpwrap")[0] 
                    .setAttribute("style", "display:block !important;"); 
                
                document.body 
                    .getElementsByClassName("signupwrap")[0] 
                    .setAttribute("style", "display:none !important;"); 
            }
        }
    }

    function signupBack(){
        document.body 
            .getElementsByClassName("otpwrap")[0] 
            .setAttribute("style", "display:none !important;"); 
        resetRecaptcha();
        document.body 
            .getElementsByClassName("signupwrap")[0] 
            .setAttribute("style", "display:block !important;"); 
    }
    function loginBox(){    
        history.push({
            pathname:'/wllogin'
        })
    }
    function landingjoin(){    
        document.body
            .getElementsByClassName(classes.landingpage)[0]
            .setAttribute("style", "display:none;");
        document.body
            .getElementsByClassName(classes.ragisterrpage)[0]
            .setAttribute("style", "display:block;");    
    }

    function disableButton(){
        setRecaptchaHide(true);
        setIsFormValid(false);
        document.body
            .getElementsByClassName("signupbtn")[0]
            .setAttribute("style", "opacity:0.5;");
    }

    function enableButton(){
        setRecaptchaHide(false);
    }

    function handleSubmit(model){ 
        model = {"recaptcha": recaptchaCode, "id": userId,...model}
        dispatch(authActions.submitWlRegister(model, setRequestTime));
        document.body
            .getElementsByClassName("message")[0]
            .innerHTML=" " 
    }
    
    var verifyCallback = function (response) {
        enableButton();
        setIsFormValid(true);
        document.body
            .getElementsByClassName("signupbtn")[0]
            .setAttribute("style", "opacity:1;");
        setRecaptchaCode(response);
    };
    var gcErrorCallback = function (issue){
        document.body
            .getElementsByClassName("signupbtn")[0]
            .setAttribute("style", "opacity:1;");
     };
           
    function getgamedata(){
        const request = axios.post(Gurl.WLGAME_TYPE, JSON.stringify({
            token: window.localStorage.getItem('jwt_access_token')
        }),{header:{"Content-Type": "application/x-www-form-urlencoded"}});
   
        request.catch((error) => {
            setGameType("NOVALUE");
        }); 

        request.then((response) => { 
            if(response.data.data != null){ 
                var myOutPut = (
                    response.data.data.map((jsondata) => (
                        <MenuItem value={jsondata.id}>{jsondata.type_name}</MenuItem> 
                    ))
                );
                setGameType(myOutPut);
            };
        });  
    }
    
    if(gameType === "NOVALUE"){
        setGameType("Processing");
        getgamedata();
    }

    function getuserconfig(){
        const request = axios.post(Gurl.WLUSER_CONFIG, JSON.stringify({
            token: window.localStorage.getItem('jwt_access_token')
        }),{header:{"Content-Type": "application/x-www-form-urlencoded"}});
   
        request.catch((error) => {
            setUserConfig("NOVALUE");
        }); 

        request.then((response) => { 
            if(response.data.data != null){ 
                var myConfig = response.data.data;
                setUserConfig(myConfig[0]);
            };
        });  
    }
    if(gameType === "NOVALUE"){
        getuserconfig("Processing");
        getgamedata();
    }
    function countryWiseMobileValidate(event){
        if(countryData != null && event != null && event.target != null && event.target.value != null){
            countryData.forEach((jsonData) => {
                if(jsonData.id === event.target.value){
                    setCurrentCountry(jsonData.title);
                    setMobileNumberMaxLen(jsonData.mobile_number_length);
                }
            })
        }
    }     
    function getCurrentCountry(myCountryData){
        axios.get('https://ipapi.co/json/').then((response) => {
                let data = response.data;
                myCountryData.forEach((jsonData) => {
                    var phonecode = ("+" + jsonData.phonecode.toString()).toString();
                    if(phonecode === data.country_calling_code){
                        setCurrentCountryId(jsonData.id);
                        setCurrentCountry(jsonData.title);
                        setMobileNumberMaxLen(jsonData.mobile_number_length);
                    }
                })
            }).catch((error) => {
                console.log(error);
            });
    }     
    function getcountrydata(){
        const request = axios.post(Gurl.WLCOUNTRY_DATA, JSON.stringify({
            token: window.localStorage.getItem('jwt_access_token')
        }),{header:{"Content-Type": "application/x-www-form-urlencoded"}});
    
        request.catch((error) => {
            setCountryCode("NOVALUE");
        }); 

        request.then((response) => { 
            if(response.data.data != null){ 
                setCountryData(response.data.data);
                //<img src={jsondata.flag} alt={""} width="25px" />&nbsp;
                var myOutPut = (
                    response.data.data.map((jsondata) => (
                        <MenuItem value={jsondata.id}><img src={jsondata.flag} alt={""} width="25px" />&nbsp;{jsondata.title} - ({jsondata.phonecode})</MenuItem> 
                    ))
                );
                setCountryCode(myOutPut);
                getCurrentCountry(response.data.data);
            };
        });  
    }      
    if(countryCode === "NOVALUE"){
        setCountryCode("Processing");
        getcountrydata();
    }


    
    
   

    /*sign up  end*/  
    return (
        <div>
        <div className={classes.landingpage}>
            <div className={classes.landingpagein}>
            <GridContainer>
                <GridItem xs={12} sm={4} md={4}> 
                   <img className={classes.landinglogo} alt="" src={logo}/>
                    <h3>Would You Like to<br/> <span>Create Your Own Casino</span><br/> in few Clicks.</h3>
                    <Button 
                        simple 
                        color="primary" 
                        onClick={landingjoin}
                        className={classes.joinnowbtn} 
                        type="button" 
                    >
                        Join Now     
                    </Button> 
                </GridItem>
                <GridItem xs={12} sm={8} md={8}>
                    <h4>Create your own Online Casino in a few clicks..</h4>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}> 
                            <Zoom in="Show" style={{ transitionDelay: '1s, 500ms' }}>
                                <img className={classes.screens} alt="" src={screenone}/>
                            </Zoom>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}> 
                            <Zoom in="Show" style={{ transitionDelay: '1s, 1000ms' }}>
                             <img className={classes.screens} alt="" src={screentwo}/>
                            </Zoom>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <Zoom in="Show" style={{ transitionDelay: '1s, 1500ms' }}> 
                                <img className={classes.screens} alt="" src={screenthree}/>
                            </Zoom>
                        </GridItem> 
                        <GridItem xs={6} sm={6} md={6}> 
                            <Zoom in="Show" style={{ transitionDelay: '1s, 2000ms',  }} > 
                                <img className={classes.screens} alt="" src={screenfour}/>
                            </Zoom> 
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
            </div>
        </div>    
        <div className={classes.fullpagelogin + " smdesigntwo " + classes.ragisterrpage}>
            <img className={classes.girlimg} alt="" src={logo}/>
            <div className={classes.signupwrap+ " signupwrap"}>
                <Formsy
                    onValidSubmit={handleSubmit}
                    onValid={enableButton}
                    onInvalid={disableButton}
                    ref={formRef}
                    autoComplete={"off"}
                    className={classes.form + " flex flex-col justify-center w-full"}
                >
                   
                  <h4>Sign Up</h4>
                  <GridContainer>
                  <GridItem xs={12}><div className={classes.errormsg + " message"} id="message"></div></GridItem>
                    <GridItem xs={12} sm={12} md={6}> 
                        <TextFieldFormsy
                            formControlProps={{
                                fullWidth: true
                            }}
                            type="text"
                            name="firm_name"
                            label="Brand Name"
                            value=""
                            validations={{
                                minLength: 4, 
                                maxLength: 25, 
                            }}
                            validationErrors={{
                                minLength: 'Min character length is 4', 
                                maxLength: 'Max character length is 25', 
                            }}
                            
                            variant="outlined"
                            required
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}> 
                        <TextFieldFormsy
                            formControlProps={{
                                fullWidth: true
                            }}
                            type="text"
                            name="company_name"
                            label="Company Name"
                            value=""
                            validations={{
                                minLength: 4, 
                                maxLength: 50, 
                            }}
                            validationErrors={{
                                minLength: 'Min character length is 4', 
                                maxLength: 'Max character length is 50', 
                            }}
                            
                            variant="outlined"
                            required
                        />
                    </GridItem>
                   <TextFieldFormsy
                            formControlProps={{
                                fullWidth: true
                            }}
                            type="hidden"
                            name="website_type_id" 
                            value="1"  
                        />
                    <GridItem xs={12} sm={12} md={6}>
                     <SelectFormsy className={classes.selectboxx + " selectbox"}
                             
                            name="country_id"
                            label="Country" 
                            variant="outlined"
                            value={currentCountryId} 
                            onChange={(event) => countryWiseMobileValidate(event)}
                            required
                        >
                            
                            {countryCode}
                            </SelectFormsy>
                    </GridItem>
                    
                    <GridItem xs={12} sm={12} md={6}>
                        <TextFieldFormsy
                            formControlProps={{
                                fullWidth: true
                            }}
                            type="number"
                            name="mobile"
                            label="Mobile Number"
                            value=""
                            className={classes.mnumber}
                            validations={"isLength:"+mobileNumberMaxLen}
                            validationErrors={{
                                isLength: "Mobile number length must be " + mobileNumberMaxLen + " digit for " + currentCountry,
                            }}
                            
                            variant="outlined"
                            required
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <TextFieldFormsy
                            formControlProps={{
                                fullWidth: true
                            }}
                            type="email"
                            name="email"
                            value=""
                            label="Email"
                            validations={{
                                isEmail: true
                            }}
                            validationErrors={{
                                isEmail: 'Please enter a valid email'
                            }}
                            
                            variant="outlined"
                            required
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}> 
                        <TextFieldFormsy
                            formControlProps={{
                                fullWidth: true
                            }}
                            type="text"
                            name="username"
                            value={userName}
                            label="Username"
                            onChange={(myText) => {if(myText.target != null && myText.target.value) { setUserName(myText.target.value.toLowerCase()) }}}
                            validations={{
                                minLength: 5,
                                maxLength: 20,
                                noSpecialCase: function(value){
                                    if(new RegExp(/^(?=[a-zA-Z0-9._]{0,50}$)(?!.*[_.]{2})[^_.].*[^_.].*$/).test(value.username)){
                                        return true;
                                    }else{
                                        return false;
                                    }
                                },
                                noSpaceCase: function(value){
                                    if(new RegExp(/^(?=.*[ ]).*$/).test(value.username)){
                                        return false;
                                    }else{
                                        return true;
                                    }
                                },
                                //matchRegexp: (userConfig.username != null) ? /^(?=[a-zA-Z0-9._]{5,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/ : /^(?=[a-zA-Z0-9._]{5,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/
                            }}
                            validationErrors={{
                                minLength: "Minimum 5 character length",
                                maxLength: "Maximum 20 character allowed",
                                noSpecialCase: "Special character not allowed",
                                noSpaceCase: "Space not allowed",
                                //matchRegexp: (userConfig.username_msg != null) ? userConfig.username_msg :"Username must be 5-20 characters long. No Special character allowed"
                            }}
                            
                            variant="outlined"
                            required
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <TextFieldFormsy
                            formControlProps={{
                                fullWidth: true
                            }}
                            id="password"
                            type="password"
                            value=""
                            name="password"
                            label="Enter Password"
                            validations={{
                                minLength: 6,
                                maxLength: 20,
                                upperCase: function(value){
                                    if(new RegExp(/^(?=.*[A-Z]).*$/).test(value.password)){
                                        return true;
                                    }else{
                                        return false;
                                    }
                                },
                                numberCase: function(value){
                                    if(new RegExp(/^(?=.*[0-9]).*$/).test(value.password)){
                                        return true;
                                    }else{
                                        return false;
                                    }
                                },
                                //matchRegexp: (userConfig.password != null) ? new RegExp(userConfig.password) : /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9]).{6,20}$/
                            }}
                            validationErrors={{
                                minLength: "Length should be minimum 6",
                                maxLength: "Length should not be greater 20",
                                upperCase: "Atleast one uppercase",
                                numberCase: "Atleast one numeric char",
                                //matchRegexp: (userConfig.password_msg != null) ? userConfig.password_msg :"Password mins 1 letters in Upper,1 Special Character(!@#$&*),2 numerical,3 letters in Lower"
					        }}
                            
                            variant="outlined"
                            required
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <TextFieldFormsy
                            formControlProps={{
                                fullWidth: true
                            }}
                            type="password"
                            value=""
                            name="confirm_password"
                            label="Confirm Password"
                            validations={{
                                equalsField: "password"
                            }}
                            validationErrors={{
                                equalsField: 'Please Re-enter same password'
                            }}
                            
                            variant="outlined"
                            required
                        />
                    </GridItem>
                    
                    <GridItem xs={12} sm={12} md={6}>
                    <ReCAPTCHA 
                        ref={e => recaptchaInstance = e}
                        onChange={verifyCallback}
                        className={classes.receptadiv + " recapchadivm "}
                        onExpired={gcErrorCallback}
                        onErrored={gcErrorCallback}
                        sitekey={Gurl.reCaptchaKey}
                        />
                    </GridItem>
                  
                  </GridContainer>
                  
                  
                    <Button simple color="primary" className={classes.signinmodalbtn +" signupbtn"} type="submit" size="lg" disabled={!isFormValid}>
                        Register Now     
                    </Button> 
                    <Button simple color="primary" className={classes.otpbtn} type="submit" onClick={loginBox} size="lg" >
                        Already Registered? Login Now
                    </Button>
                </Formsy> 
            </div>
            <div className={classes.otpwrap+ " otpwrap"}>
               <Formsy 
                    onValidSubmit={handleSubmitotp} 
                    ref={otpformRef}
                    autoComplete={"off"}
                    className={classes.form + " flex flex-col justify-center w-full"}
                >
                   
                  <h4>OTP</h4>
                  <GridItem xs={12}><div className={classes.sucessmsg + " messageresend"} id="message"></div></GridItem>
                  
                  <div className={classAdd + " message_otp"} id="message_otp">{wlOtp.error.message}</div>
                  <GridContainer>
                  <GridItem xs={12}>
                  <TextFieldFormsy
                        formControlProps={{
                            fullWidth: true
                        }}
                        type="number"
                        id="otp"
                        name="otp"
                        label="Enter OTP"
                        value={otpCode}
                        validations={{
                            isLength: 6, 
                        }}
                        validationErrors={{
                            isLength: 'OTP length is 6', 
                        }}
                        
                        variant="outlined"
                        required
                    />
                    </GridItem>
                    </GridContainer>
                     
                    <GridContainer>
                  <GridItem xs={12}>
                    <ReCAPTCHA
                        ref={e => recaptchaInstanceOtp = e}
                        onChange={verifyOtpCallback}
                        className={classes.receptadiv}
                        sitekey={Gurl.reCaptchaKey}
                        />
                    </GridItem>
                    </GridContainer>
                     
                        
                        
                    <Button simple color="primary" style={{
                        float: 'right',
                        marginTop: '-100px',
                        fontSize: '12px',
                        color: '#863304',
                        background: 'radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #d29817 30%, #bf8315 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFF 0%, #FFFFAC 8%, #d2a732 25%, #5d4a1f 62.5%, #f5c761 100%)',
                        borderRadius: '20px',
                        padding: '6px 15px 4px',
                        
                    }} className="resendbtn"  onClick={handleResendOtp} type="submit" size="lg" >
                        Resend   
                    </Button>


                   <Button simple color="primary" className={classes.signinmodalbtn}  type="submit" size="lg" >
                        Continue
                    </Button>
                    <Button simple color="primary" className={classes.otpbtn} type="button" href="/wllogin" size="lg" >
                      Login
                    </Button>
                    <Button simple color="primary" className={classes.otpbtn} type="button" onClick={() => signupBack()} size="lg" >
                      Go Back
                    </Button>
                </Formsy> 
            </div>
            <div className={classes.forgotwrap}>
               <Formsy  
                    ref={forgotformRef}
                    autoComplete={"off"}
                    className={classes.form + " flex flex-col justify-center w-full"}
                >
                  <img className={classes.logoimg} alt="" src={logo}/>
                  <h4>Forgot Password</h4> 
                  <TextFieldFormsy
                        formControlProps={{
                            fullWidth: true
                        }}
                        type="text"
                        name="username"
                        label="Enter Username"
                        validations={{
                            minLength: 6, 
                            maxLength: 20, 
                        }}
                        validationErrors={{
                            minLength: 'Min character length is 6', 
                            maxLength: 'Max character length is 20'  
                        }}
                        
                        variant="outlined"
                        required
                    />
                    <p className={classes.orrow}>OR</p>
                    <TextFieldFormsy
                        formControlProps={{
                            fullWidth: true
                        }}
                        type="number"
                        name="mobile"
                        label="Enter Mobile Number"
                        validations={{
                            minLength: 6, 
                            maxLength: 20, 
                        }}
                        validationErrors={{
                            minLength: 'Min character length is 6', 
                            maxLength: 'Max character length is 6'  
                        }}
                        
                        variant="outlined"
                        required
                    />
                    <p className={classes.orrow}>OR</p>
                  <TextFieldFormsy
                        formControlProps={{
                            fullWidth: true
                        }}
                        type="text"
                        name="email"
                        label="Enter Email"
                        validations={{
                            minLength: 6, 
                            maxLength: 50, 
                        }}
                        validationErrors={{
                            minLength: 'Min character length is 6', 
                            maxLength: 'Max character length is 50'  
                        }}
                        
                        variant="outlined"
                        required
                    />
                     <ReCAPTCHA
                        ref={e => recaptchaInstanceNew = e}
                        className={classes.receptadiv}
                        //size="invisible"
                        sitekey={Gurl.reCaptchaKey}
                        />
                   <Button simple color="primary" className={classes.signinmodalbtn +" signupbtn"} onClick={signupBox} type="submit" size="lg"  disabled={!isFormValid} >
                        Continue
                    </Button>
                    <Button simple color="primary" className={classes.otpbtn} type="submit"  size="lg" >
                      Login
                    </Button>
                    
                </Formsy> 
            </div>
 
                        
            <div className={classes.clerfix}></div>
        </div>
        </div>
    );
}


const statementDb = {
    en: [
        { 
            'id': 'stmhddi0r_1',
            'pageheading': 'Statement', 
            'periodheading': 'Period', 
            'payment_type': 'Payment Type',
            'bonus_received':'Bonus Received',
            'period': 'Period',
            'start_date': 'Start Date',
            'end_date': 'End Date',
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 3,
                    'field_name': 'Transaction Id',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'Type Of Transaction',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 3,
                    'field_name': 'Transaction On',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_4',
                    'xs': 1,
                    'field_name': 'Credit',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'Debit',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': 'Status',
                    'visible': 'desktop',
                    'expandable': true
                },
                

            ],
            'tabledata':[
                {
                    'id'    : '5725a680b3249760ea2wde51',
                    'type'  : '1',
                    'name'  : 'Signup bonus- new ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde52',
                    'type'  : '2',
                    'name'  : 'Signup bonus- new ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde53',
                    'type'  : '3',
                    'name'  : 'Signup bonus- new ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ] ,
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"Last 7 Days", 
                },
                { 
                    'id':"2", 
                    'periodoption':"Last 1 Month",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"Last 6 Months",
                },  
            ], 
            'paymentheading':"Payment Type",
            'paymentoptions': [
                {
                    'id':"1,2,3,4,5,6,7,8,9,10", 
                    'paymentoption':"All",
                },
                {
                    'id':"6,1", 
                    'paymentoption':"Deposit",
                },
                {
                    'id':"3,1", 
                    'paymentoption':"Bonus",
                },
                {
                    'id':"7,2", 
                    'paymentoption':"Withdraw",
                },
                {
                    'id':"8,31,32", 
                    'paymentoption':"Games",
                }
            ],
            'searchbtn': "Search", 
            'tablehead':[
                { 
                    'tablethsno'  : 'S.No', 
                    'tablethname'  : 'Name',  
                    'tablethstarttime'  : 'Start Time',  
                    'tablethendtime'  : 'End Time',  
                    'tablethamoount'  : 'Amount', 
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'Signup bonus- new ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'Signup bonus- new ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'Signup bonus- new ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ]            

        },
    ],
    tl: [
        { 
            'id': 'stmhddi0r_1',
            'pageheading': ' ప్రకటన', 
            'periodheading': 'కాలం',  
            'payment_type': 'చెల్లించు విధానము',
            'bonus_received':'బోనస్ స్వీకరించబడింది',
            'period': 'కాలం',
            'start_date':'ప్రారంబపు తేది',
            'end_date':'చివరి తేది', 
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 3,
                    'field_name': 'లావాదేవి ఐడి',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'లావాదేవీ రకం',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 3,
                    'field_name': 'లావాదేవీ ఆన్',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_4',
                    'xs': 1,
                    'field_name': 'క్రెడిట్',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'డెబిట్',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': ' స్థితి',
                    'visible': 'desktop',
                    'expandable': true
                },
                

            ],
            'tabledata':[
                {
                    'id'    : '5725a680b3249760ea2wde51',
                    'type'  : '1',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde52',
                    'type'  : '2',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde53',
                    'type'  : '3',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ] ,
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"చివరి 7 రోజులు", 
                },
                { 
                    'id':"2", 
                    'periodoption':"చివరి 1 నెల",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"చివరి 6 నెలలు",
                },  
            ], 
            'paymentheading':"చెల్లించు విధానము",
            'paymentoptions': [
                {
                    'id':"1,2,3,4,5,6,7,8,9,10", 
                    'paymentoption':"అన్నీ",
                },
                {
                    'id':"6,1", 
                    'paymentoption':"డిపాజిట్",
                },
                {
                    'id':"3,1", 
                    'paymentoption':"ఉపరి లాభ బహుమానము",
                },
                {
                    'id':"7,2", 
                    'paymentoption':"ఉపసంహరించుకోండి",
                },
                {
                    'id':"8,31,32", 
                    'paymentoption':"ఆటలు",
                }
            ],
            'searchbtn': "వెతకండి", 
            'tablehead':[
                { 
                    'tablethsno'  : 'ఎస్', 
                    'tablethname'  : 'పేరు',  
                    'tablethstarttime'  : 'ప్రారంభ సమయం',  
                    'tablethendtime'  : 'ముగింపు సమయం',  
                    'tablethamoount'  : 'మొత్తం', 
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ],  
        },
    ],
    hi: [
        { 
            'id': 'stmhddi0r_1',
            'pageheading': 'विवरण', 
            'periodheading': 'अवधि',
            'payment_type': 'भुगतान के प्रकार',
            'bonus_received':'बोनस प्राप्त हुआ',
            'period': 'अवधि',
            'start_date': 'आरंभ करने की तिथि',
            'end_date': 'अंतिम तिथि',
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 3,
                    'field_name': 'नम्बर',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'लेन-देन का प्रकार',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 3,
                    'field_name': 'लेनदेन समय',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_4',
                    'xs': 1,
                    'field_name': 'जमा',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'ख़र्च',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': 'स्थति',
                    'visible': 'desktop',
                    'expandable': true
                },
                

            ],  
            'tabledata':[
                {
                    'id'    : '5725a680b3249760ea2wde51',
                    'type'  : '1',
                    'name'  : 'साइनअप बोनस- नया ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde52',
                    'type'  : '2',
                    'name'  : 'साइनअप बोनस- नया ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde53',
                    'type'  : '3',
                    'name'  : 'साइनअप बोनस- नया ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
            ],
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"पिछले सात दिन", 
                },
                { 
                    'id':"2", 
                    'periodoption':"पिछले एक महीने से",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"पिछले 6 महीने",
                },  
            ],  
            'paymentheading':"भुगतान के प्रकार",
            'paymentoptions': [
                {
                    'id':"1,2,3,4,5,6,7,8,9,10", 
                    'paymentoption':"सब",
                },
                {
                    'id':"6,1", 
                    'paymentoption':"जमा",
                },
                {
                    'id':"7,2", 
                    'paymentoption':"निकालना",
                },
                {
                    'id':"8,31,32",  
                    'paymentoption':"खेल",
                } 
            ],
            'searchbtn': "खोज", 
            'tablehead':[
                { 
                    'tablethsno'  : 'नम्बर', 
                    'tablethname'  : 'नाम',  
                    'tablethstarttime'  : 'समय शुरू',  
                    'tablethendtime'  : 'अंतिम समय',  
                    'tablethamoount'  : 'रकम', 
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'साइनअप बोनस- नया ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'साइनअप बोनस- नया ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'साइनअप बोनस- नया ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
            ]
        },
    ]
}
export default statementDb;

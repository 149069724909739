import React from "react";
// nodejs library that concatenates classes 
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components 
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/loader.js";

//import SectionHeaderwithoutlogin from "../../sections/SectionHeader";
import SectionHeaderLogin from "../../sections/SectionHeaderlogin";

import jwt from 'jsonwebtoken'; 
import jwtDecode from 'jwt-decode';
import jwtService from '../../../../services/jwtService'
import axios from 'axios';
import * as globalurl from '../../../../global/Global.js'; 
import sectionpop from "../../sections/SectionPopup";
export default function Header(props) {
  const {...rest} = props;  
  const { ...sectionPopup} = sectionpop;
  var setIsPageRender = 0;
  
  var isLoader = true;
  window.onload = function (){    
    if(document.body 
      .getElementsByClassName(classes.preloader) != null && document.body 
      .getElementsByClassName(classes.preloader)[0] != null){
      document.body 
        .getElementsByClassName(classes.preloader)[0]
        .setAttribute("style", "display:none;");
        isLoader = false;
      }
  }
  setTimeout(function() { 
    if(isLoader === true){
      if(document.body 
        .getElementsByClassName(classes.preloader) != null && document.body 
        .getElementsByClassName(classes.preloader)[0] != null){
          document.body 
          .getElementsByClassName(classes.preloader)[0]
          .setAttribute("style", "display:none;");
          isLoader = false;
        }     
    } 
  }, 3000);
 // const currentLocation = props.location.pathname.replace("/","");
  const currentLocation = props.location.pathname.split("/");
  const pageName = "dashboard"; 
  if(props.themeColors != null){
   
    styles.preloader.backgroundColor = props.themeColors.bgColors.primary_bgcolor; 
    styles.loaderOutter.borderColor = props.themeColors.bgColors.main_color; 
    styles.loaderInner.borderColor = props.themeColors.bgColors.main_color; 
    styles.bandname.fontFamily = props.themeColors.bgColors.logo_font;
  }

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  var parentId = 0;
  if(jwtService.isAuthenticationLogin() === true){
    if(window.localStorage.getItem("header_access_token")){
      const decodedData = jwtDecode(window.localStorage.getItem("header_access_token"));
      if(decodedData.TopMenu != null){
        decodedData.TopMenu.forEach((myMenuData) => {
          if(myMenuData.path === "dashboard"){
            parentId = myMenuData.id;
          }
        });
      }
    }
  }
  async function getDataFromServer(){
    let pro = new Promise((resolve,reject) => {
        const request = axios.post(globalurl.GETMENUS , {
            token: window.localStorage.getItem('jwt_access_token'),
            menutypes: 8,
            parent_id: parentId,
            pageslug: pageName
        });
        request.then((response) => {
            if(response.data.data != null){
                var data = {
                    "status":true,
                    "data":response.data.data
                }
                resolve(data);
            }else{
                resolve({"status":false, "message":'No record found'});
            }
        });
        request.catch((error) => {
            reject(error);
        });
    }); 
    const val = await pro;
    return val;
  }
  function setHeaderData(data, access_token_name){
      var jwtConfig = {
          "secret"   : "324934dsfj786ykjhgiyyi4439wri",
          "expiresIn": "1 hours" // A numeric value is interpreted as a seconds count. If you use a string be sure you provide the time units (days, hours, etc)
      };
      const access_token = jwt.sign(data, jwtConfig.secret, {expiresIn: jwtConfig.expiresIn});
      window.localStorage.setItem(access_token_name, access_token);
  }

  var dataOfMenu = [];
  async function getDataNow(){
      dataOfMenu = await getDataFromServer();
      if(dataOfMenu.data != null){
          setHeaderData({TopMenu: dataOfMenu.data}, "dashboard_access_token");
          setIsPageRender = 1;
      }
  }
  if(jwtService.isAuthenticationLogin() === true){
      let headerData = false; 
      if(window.localStorage.getItem("dashboard_access_token") != null){
          const decodedData = jwtDecode(window.localStorage.getItem("dashboard_access_token"));
          const currentTime = Date.now() / 1000;
          let tokenExpired = false;
          if ( decodedData.exp < currentTime ){
              tokenExpired = true;
          }
          if(tokenExpired === true && navigator != null && navigator.onLine != null && navigator.onLine !== true){
              tokenExpired = false;
          }
          if (tokenExpired === false){
              if(decodedData.TopMenu != null){
                  headerData = true;
              }  
          }
      }
      if(headerData === false){
        getDataNow();
      }
  }
  function parseJwt(token) {
    if (!token) { return; }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}
function getCurrentThemeInfo(){
    if(window.localStorage.getItem('jwt_theme_token') != null){
        let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
        return themeData;
    }
  }
  var icon = null;
  var brandName = null;
  var themeInfo = getCurrentThemeInfo();
  if(themeInfo != null && themeInfo.id != null){ 
    if(themeInfo.icon != null && themeInfo.icon.length > 0){
      icon = themeInfo.icon;
    } 
    if(themeInfo.brand_name != null && themeInfo.brand_name.length > 0){
      brandName = themeInfo.brand_name;
    }  
}
  return (
      <div>
        


        <div className={classes.preloader} id="preloader">
          {(() => { 
                  if(themeInfo.icon !== null && (themeInfo.icon !== undefined && themeInfo.icon !== "undefined" && themeInfo.icon.length > 0)) { 
                          return ( 
                              <img width="128" style={{display:'block', margin:'0px auto', paddingTop:'10%'}} src={icon} alt="logo"/>
                  ) }else{
                  return ( 
                          <h4 className={classes.bandname} >{brandName}</h4>  
                      )
          }
          })()}
          <div className={classes.loader + " " + classes.loader1}>
            <div className={classes.loaderOutter}></div>
            <div className={classes.loaderInner}></div>
          </div>
        </div>


       {(() =>  {
          var isAuthenticatedOnly = false;
          if(window.localStorage.getItem("dashboard_access_token")){
            const decodedData = jwtDecode(window.localStorage.getItem("dashboard_access_token"));
            if(decodedData.TopMenu != null){
              if(Array.isArray(decodedData.TopMenu)){
                decodedData.TopMenu.forEach((myMenuData) => {
                  if(myMenuData.path === currentLocation[1] && myMenuData.is_login === 1){
                    isAuthenticatedOnly = true;
                  }
                });
              }else{
                let myMenuData = decodedData.TopMenu;
                if(myMenuData.path === currentLocation[1] && myMenuData.is_login === 1){
                  isAuthenticatedOnly = true;
                }
              }
              globalurl.LOGINALLOWED.forEach((myMenuData) => {
                if(myMenuData === currentLocation[1]){
                  isAuthenticatedOnly = true;
                }
              });
            }
          }
          if (jwtService.isAuthenticationLogin() === true && isAuthenticatedOnly === true) {

            return (

            <SectionHeaderLogin { ...sectionPopup} {...rest} />

          )
          } else if (currentLocation[1] === "domainnotadjust" ) {

            return (
                <div>

                </div>
            )

          } else {
            return (

              <SectionHeaderLogin { ...sectionPopup} key="333" {...rest} />

            
            )
          }
        })()}





      </div>


  );
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};

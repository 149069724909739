 

const secloginboxDb = {
    en: [
        {
            'id'    : '5725a680b3249860ea21de51',
            'subtitle'    : ' ',
            'title'    : 'GREAT TO HAVE YOU BACK!',
            'name'    : 'Enter Your Username', 
            'password'    : 'Password', 
            'sbmitbtn'    : 'Sign In', 
            'text_p'    : 'Not a member?', 
            'text_a'    : 'Create account', 
            'aggreetnc':'By clicking Sign Up, you agree to our Terms and Conditions. You may receive SMS notifications from us and can opt out at any time.',
            'aggreetncs':'Terms and Conditions', 
            'forgotpassord':'Forgot Password',
        }, 
    ],
    tl: [
        {
            'id'    : '5725a680b3249860ea21de51',
            'subtitle'    : 'బాగుంది, లాగిన్ అవ్వండి.',
            'title'    : 'మీరు తిరిగి రావడానికి గొప్పది!',
            'name'    : 'మీ వినియోగదారు పేరును నమోదు చేయండి', 
            'password'    : 'పాస్వర్డ్', 
            'sbmitbtn'    : 'సైన్ ఇన్ చేయండి', 
            'text_p'    : 'సభ్యుడు కాదు?', 
            'text_a'    : 'ఖాతాను సృష్టించండి', 
            'aggreetnc':'సైన్ అప్ క్లిక్ చేయడం ద్వారా, మీరు మా నిబంధనలు మరియు షరతులను అంగీకరిస్తున్నారు. మీరు మా నుండి SMS నోటిఫికేషన్లను స్వీకరించవచ్చు మరియు ఎప్పుడైనా నిలిపివేయవచ్చు.',
            'aggreetncs':'నిబంధనలు మరియు షరతులను',   
            'forgotpassord':'పాస్వర్డ్ మర్చిపోయాను',
        }, 
    ],
    hi: [
        {
            'id'    : '5725a680b3249860ea21de52',
            'subtitle'    : 'अपना विवरण नीचे दर्ज करें।',
            'title'    : 'आप वापस आ गए हैं!',
            'name'    : 'अपना उपयोगकर्ता नाम दर्ज करें', 
            'password'    : 'पासवर्ड', 
            'sbmitbtn'    : 'साइन इन करें', 
            'text_p'    : 'सदस्य नहीं है?', 
            'text_a'    : 'खाता बनाएं',    
            'aggreetnc':'साइन अप पर क्लिक करके, आप हमारे नियमों और शर्तों से सहमत होते हैं। आप हमसे एसएमएस सूचनाएं प्राप्त कर सकते हैं और किसी भी समय चुन सकते हैं।',
            'aggreetncs':'नियमों और शर्तों',  
            'forgotpassord':'पासवर्ड भूल गए',
        },  
    ],
    

};

export default secloginboxDb;


const statementDb = {
    en: [
        { 
            'id': 'stmhddi0r_1',
            'pageheading': 'Statement', 
            'periodheading': 'Period', 
            'periodd':'Period',
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 2,
                    'field_name': 'Id',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'Type',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 3,
                    'field_name': 'Date',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_4',
                    'xs': 1,
                    'field_name': 'Cr',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'Dr',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_7',
                    'xs': 1,
                    'field_name': 'Balance',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': 'Status',
                    'visible': 'desktop',
                    'expandable': true
                },
                

            ],
            'tabledata':[
                {
                    'id'    : '5725a680b3249760ea2wde51',
                    'type'  : '1',
                    'name'  : 'Signup bonus- new ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde52',
                    'type'  : '2',
                    'name'  : 'Signup bonus- new ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde53',
                    'type'  : '3',
                    'name'  : 'Signup bonus- new ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ] ,
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"Last 7 Days", 
                },
                { 
                    'id':"2", 
                    'periodoption':"Last 1 Month",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"Last 6 Months",
                },  
            ], 
            'paymentheading':"Payment Type",
            'paymentoptions': [
                {
                    'id':"1,2,3,4,5,6,7,8,9", 
                    'paymentoption':"All",
                },
                {
                    'id':"6,1", 
                    'paymentoption':"Deposit",
                },
                {
                    'id':"3,1", 
                    'paymentoption':"Bonus",
                },
                {
                    'id':"7,2", 
                    'paymentoption':"Withdraw",
                },
                {
                    'id':"8,31,32", 
                    'paymentoption':"Games",
                }
            ],
            'searchbtn': "Search", 
            'tablehead':[
                { 
                    'tablethsno'  : 'S.No', 
                    'tablethname'  : 'Name',  
                    'tablethstarttime'  : 'Start Time',  
                    'tablethendtime'  : 'End Time',  
                    'tablethamoount'  : 'Amount', 
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'Signup bonus- new ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'Signup bonus- new ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'Signup bonus- new ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ],
            'start_date':'Start Date',
            'end_date':'End Date',            
            'bonusresiver':'Bonus Received',
            'loadmore':'Load more...',
        },
    ],
    tm: [
        { 
            'id': 'stmhddi0r_1',
            'pageheading': 'அறிக்கை', 
            'periodheading': 'காலம்', 
            'periodd':'காலம்',
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 3,
                    'field_name': 'ஐடி',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'வகை',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 3,
                    'field_name': 'தேதி',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_4',
                    'xs': 1,
                    'field_name': 'கடன்',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'பற்று',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_7',
                    'xs': 1,
                    'field_name': 'பணம்',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': 'நிலை',
                    'visible': 'desktop',
                    'expandable': true
                },
                

            ],
            'tabledata':[
                {
                    'id'    : '5725a680b3249760ea2wde51',
                    'type'  : '1',
                    'name'  : 'பதிவுபெறும் போனஸ்- புதியது ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde52',
                    'type'  : '2',
                    'name'  : 'பதிவுபெறும் போனஸ்- புதியது ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde53',
                    'type'  : '3',
                    'name'  : 'பதிவுபெறும் போனஸ்- புதியது',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ] ,
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"கடைசி 7 நாட்கள்", 
                },
                { 
                    'id':"2", 
                    'periodoption':"கடந்த 1 மாதம்",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"கடந்த 6 மாதங்கள்",
                },  
            ], 
            'paymentheading':"கட்டணம் வகை",
            'paymentoptions': [
                {
                    'id':"1,2,3,4,5,6,7,8,9", 
                    'paymentoption':"அனைத்தும்",
                },
                {
                    'id':"6,1", 
                    'paymentoption':"வைப்பு",
                },
                {
                    'id':"3,1", 
                    'paymentoption':"போனஸ்",
                },
                {
                    'id':"7,2", 
                    'paymentoption':"திரும்பப் பெறுங்கள்",
                },
                {
                    'id':"8,31,32", 
                    'paymentoption':"விளையாட்டுகள்",
                } 
            ],
            'searchbtn': "தேடல்", 
            'tablehead':[
                {  
                    'tablethsno': 'எஸ். இல்லை',
                    'tablethname': 'பெயர்',
                    'tablethstarttime': 'தொடக்க நேரம்',
                    'tablethendtime': 'முடிவு நேரம்',
                    'tablethamoount': 'தொகை',
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'பதிவுபெறும் போனஸ்- புதியது',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'பதிவுபெறும் போனஸ்- புதியது ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'பதிவுபெறும் போனஸ்- புதியது',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ], 
            'start_date': 'தொடக்க தேதி',
            'end_date': 'இறுதி தேதி',
            'bonusresiver': 'போனஸ் பெறப்பட்டது',
            'loadmore': 'மேலும் ஏற்றவும் ...',
        },
    ],
    kn: [
        { 
            'id': 'stmhddi0r_1',
            'pageheading': 'ಹೇಳಿಕೆ', 
            'periodheading': 'ಅವಧಿ', 
            'periodd':'ಅವಧಿ',
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 3,
                    'field_name': 'ಐಡಿ',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'ಮಾದರಿ',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 3,
                    'field_name': 'ದಿನಾಂಕ',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_4',
                    'xs': 1,
                    'field_name': 'ಕ್ರೆಡಿಟ್',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'ಡೆಬಿಟ್',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_7',
                    'xs': 1,
                    'field_name': 'ಹಣ',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': 'ಸ್ಥಿತಿ',
                    'visible': 'desktop',
                    'expandable': true
                },
                

            ],
            'tabledata':[
                {
                    'id'    : '5725a680b3249760ea2wde51',
                    'type'  : '1',
                    'name'  : 'ಸೈನ್ ಅಪ್ ಬೋನಸ್- ಹೊಸದು ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde52',
                    'type'  : '2',
                    'name'  : 'ಸೈನ್ ಅಪ್ ಬೋನಸ್- ಹೊಸದು',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde53',
                    'type'  : '3',
                    'name'  : 'ಸೈನ್ ಅಪ್ ಬೋನಸ್- ಹೊಸದು ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ] ,
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"ಕೊನೆಯ 7 ದಿನಗಳು", 
                },
                { 
                    'id':"2", 
                    'periodoption':"ಕೊನೆಯ 1 ತಿಂಗಳು",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"ಕಳೆದ 6 ತಿಂಗಳುಗಳು",
                },  
            ], 
            'paymentheading':"ಪಾವತಿ ವಿಧಾನ",
            'paymentoptions': [
                {
                    'id':"1,2,3,4,5,6,7,8,9", 
                    'paymentoption':"ಎಲ್ಲಾ",
                },
                {
                    'id':"6,1", 
                    'paymentoption':"ಠೇವಣಿ",
                },
                {
                    'id':"3,1", 
                    'paymentoption':"ಬೋನಸ್",
                },
                {
                    'id':"7,2", 
                    'paymentoption':"ಹಿಂತೆಗೆದುಕೊಳ್ಳಿ",
                },
                {
                    'id':"8,31,32", 
                    'paymentoption':"ಆಟಗಳು",
                }
            ],
            'searchbtn': "ಹುಡುಕಿ", 
            'tablehead':[
                {   
                    'tablethsno': 'S. ಇಲ್ಲ',
                    'tablethname': 'ಹೆಸರು',
                    'tablethstarttime': 'ಪ್ರಾರಂಭ ಸಮಯ',
                    'tablethendtime': 'ಅಂತಿಮ ಸಮಯ',
                    'tablethamoount': 'ಮೊತ್ತ',
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'ಸೈನ್ ಅಪ್ ಬೋನಸ್- ಹೊಸದು ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'ಸೈನ್ ಅಪ್ ಬೋನಸ್- ಹೊಸದು',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'ಸೈನ್ ಅಪ್ ಬೋನಸ್- ಹೊಸದು ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ],  
            'start_date': 'ಪ್ರಾರಂಭ ದಿನಾಂಕ',
            'end_date': 'ಅಂತಿಮ ದಿನಾಂಕ',
            'bonusresiver': 'ಬೋನಸ್ ಸ್ವೀಕರಿಸಲಾಗಿದೆ',
            'loadmore': 'ಇನ್ನಷ್ಟು ಲೋಡ್ ಮಾಡಿ ...',
        },
    ],
    ne: [
        { 
            'id': 'stmhddi0r_1',
            'pageheading': 'वक्तव्य', 
            'periodheading': 'अवधि', 
            'periodd':'अवधि',
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 3,
                    'field_name': 'आईडी',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'प्रकार',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 3,
                    'field_name': 'मिति',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_4',
                    'xs': 1,
                    'field_name': 'क्रेडिट',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'डेबिट',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_7',
                    'xs': 1,
                    'field_name': 'पैसा',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': 'स्थिति',
                    'visible': 'desktop',
                    'expandable': true
                },
                

            ],
            'tabledata':[
                {
                    'id'    : '5725a680b3249760ea2wde51',
                    'type'  : '1',
                    'name'  : 'साइन अप बोनस - नयाँ ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde52',
                    'type'  : '2',
                    'name'  : 'साइन अप बोनस - नयाँ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde53',
                    'type'  : '3',
                    'name'  : 'साइन अप बोनस - नयाँ ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ] ,
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"पछिल्लो 7 दिन", 
                },
                { 
                    'id':"2", 
                    'periodoption':"पछिल्लो 30 दिन",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"पछिल्लो 6 महिना",
                },  
            ], 
            'paymentheading':"भुक्तानी प्रकार",
            'paymentoptions': [
                {
                    'id':"1,2,3,4,5,6,7,8,9", 
                    'paymentoption':"सबै",
                },
                {
                    'id':"6,1", 
                    'paymentoption':"निक्षेप",
                },
                {
                    'id':"3,1", 
                    'paymentoption':"बोनस",
                },
                {
                    'id':"7,2", 
                    'paymentoption':"निकाल्नु",
                },
                {
                    'id':"8,31,32", 
                    'paymentoption':"खेलहरू",
                }
            ],
            'searchbtn': "खोज्नुहोस्", 
            'tablehead':[
                {   
                    'tablethsno': 'S.No',
                    'tablethname': 'नाम',
                    'tablethstarttime': 'सुरु समय',
                    'tablethendtime': 'अन्त समय',
                    'tablethamoount': 'रकम',
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'साइन अप बोनस - नयाँ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'साइन अप बोनस - नयाँ ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'साइन अप बोनस - नयाँ ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ], 
            'start_date': 'सुरूवात मिति',
            'end_date': 'अन्त्य मिति',
            'bonusresiver': 'बोनस प्राप्त भयो',
            'loadmore': 'अधिक लोड गर्नुहोस् ...',
        },
    ],
    tl: [
        { 
            'id': 'stmhddi0r_1',
            'pageheading': ' ప్రకటన', 
            'periodheading': 'కాలం', 
            'periodd':'కాలం', 
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 3,
                    'field_name': 'ఐడి',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 3,
                    'field_name': 'రకము',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 3,
                    'field_name': 'తేదీ',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_4',
                    'xs': 1,
                    'field_name': 'క్రెడిట్',
                    'visible': 'both',
                },
                {
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'డెబిట్',
                    'visible': 'both',
                },
                {
                    'id': 'stmhdr_7',
                    'xs': 1,
                    'field_name': 'డబ్బు',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': ' స్థితి',
                    'visible': 'desktop',
                    'expandable': true
                },
                

            ],
            'tabledata':[
                {
                    'id'    : '5725a680b3249760ea2wde51',
                    'type'  : '1',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde52',
                    'type'  : '2',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde53',
                    'type'  : '3',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ] ,
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"చివరి 7 రోజులు", 
                },
                { 
                    'id':"2", 
                    'periodoption':"చివరి 1 నెల",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"చివరి 6 నెలలు",
                },  
            ], 
            'paymentheading':"చెల్లించు విధానము",
            'paymentoptions': [
                {
                    'id':"1,2,3,4,5,6,7,8,9", 
                    'paymentoption':"అన్నీ",
                },
                {
                    'id':"6,1", 
                    'paymentoption':"డిపాజిట్",
                },
                {
                    'id':"3,1", 
                    'paymentoption':"ఉపరి లాభ బహుమానము",
                },
                {
                    'id':"7,2", 
                    'paymentoption':"ఉపసంహరించుకోండి",
                },
                {
                    'id':"8,31,32", 
                    'paymentoption':"ఆటలు",
                }, 
            ],
            'searchbtn': "వెతకండి", 
            'tablehead':[
                { 
                    'tablethsno'  : 'ఎస్', 
                    'tablethname'  : 'పేరు',  
                    'tablethstarttime'  : 'ప్రారంభ సమయం',  
                    'tablethendtime'  : 'ముగింపు సమయం',  
                    'tablethamoount'  : 'మొత్తం', 
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ],
            'start_date':'ప్రారంబపు తేది',
            'end_date':'చివరి తేది',            
            'loadmore':'మరింత లోడ్ చేయండి ...',
            'bonusresiver':'బోనస్ స్వీకరించబడింది',
        },
    ],
    hi: [
        { 
            'id': 'stmhddi0r_1',
            'pageheading': 'विवरण', 
            'periodheading': 'अवधि',
            'periodd':'अवधि',
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 3,
                    'field_name': 'आईडी',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 3,
                    'field_name': 'प्रकार',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 3,
                    'field_name': 'तारीख',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_4',
                    'xs': 1,
                    'field_name': 'जमा',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'ख़र्च',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_7',
                    'xs': 1,
                    'field_name': 'धन',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': 'स्थिति',
                    'visible': 'desktop',
                    'expandable': true
                },
                

            ],  
            'tabledata':[
                {
                    'id'    : '5725a680b3249760ea2wde51',
                    'type'  : '1',
                    'name'  : 'साइनअप बोनस- नया ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde52',
                    'type'  : '2',
                    'name'  : 'साइनअप बोनस- नया ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde53',
                    'type'  : '3',
                    'name'  : 'साइनअप बोनस- नया ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
            ],
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"पिछले सात दिन", 
                },
                { 
                    'id':"2", 
                    'periodoption':"पिछले एक महीने से",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"पिछले 6 महीने",
                },  
            ],  
            'paymentheading':"भुगतान के प्रकार",
            'paymentoptions': [
                {
                    'id':"1,2,3,4,5,6,7,8,9", 
                    'paymentoption':"सब",
                },
                {
                    'id':"6,1", 
                    'paymentoption':"जमा",
                },
                {
                    'id':"3,1",  
                    'paymentoption':"बोनस",
                },
                {
                    'id':"7,2", 
                    'paymentoption':"निकालना",
                } ,
                {
                    'id':"8,31,32", 
                    'paymentoption':"खेल",
                } 
            ],
            'searchbtn': "खोज", 
            'tablehead':[
                { 
                    'tablethsno'  : 'नम्बर', 
                    'tablethname'  : 'नाम',  
                    'tablethstarttime'  : 'समय शुरू',  
                    'tablethendtime'  : 'अंतिम समय',  
                    'tablethamoount'  : 'रकम', 
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'साइनअप बोनस- नया ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'साइनअप बोनस- नया ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'साइनअप बोनस- नया ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
            ],
            'start_date'  : 'आरंभ करने की तिथि',
            'end_date'  : 'अंतिम तिथि',
            'loadmore':'और लोड करें...',
            'bonusresiver':'बोनस प्राप्त हुआ',
        },
    ]
}
export default statementDb;

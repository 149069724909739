import React, {useEffect, useRef, useState}  from "react";
import {makeStyles} from "@material-ui/core/styles";  

import styles from "../assets/jss/views/landingPage.js"; 
import GridItem from "../elements/Grid/GridItem";
import GridContainer from "../elements/Grid/GridContainer";
import logo from "../assets/img/logo.png"; 
// import girl from "../assets/img/girl.png"; 
import Formsy from 'formsy-react';  
import {TextFieldFormsy} from '../../../@casino';
import history from '../../../@history';

import {Button} from '@material-ui/core';

import * as authActions from '../../../auth/casino/actions';
import {useDispatch, useSelector} from 'react-redux';
import * as Gurl from "../../../global/Global.js"
// import axios from 'axios';

import ReCAPTCHA from "react-google-recaptcha";

// const token = "kdkddjdj2929j38jv88fpwad83pakwdi3"; 

const useStyles = makeStyles(styles);
// const recaptchaRef = React.createRef();

// const validEmailRegex = 
//   RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

export default function Home(props) { 
    const classes = useStyles();    
    const loginformRef = useRef(null);
    let recaptchaInstance;
    const [recaptchaCode, setRecaptchaCode] = React.useState("NOVALUE");
    const [classAdd, setClassAdd] = useState(false);
    const dispatch = useDispatch();
    const [isFormValid, setIsFormValid] = useState(false); 
    const wllogin = useSelector(({auth}) => auth.wllogin);
    useEffect(() => {
        if ( wllogin.error && (wllogin.error.username ||  wllogin.error.password ||  wllogin.error.recaptcha || wllogin.error.message ) )
        {
            resetRecaptcha();
            if(wllogin.error.message != null){
                document.body
                .getElementsByClassName("message_logins")[0]
                .innerHTML=wllogin.error.message 
                setClassAdd(classes.msg = " errormsg" );
            }
            else{
                loginformRef.current.updateInputsWithError({
                    ...wllogin.error
                });
            }
            disableButton();
        } else{
             
            if(wllogin.success === true && wllogin.data != null && wllogin.data.message != null){


                setTimeout(function() {
                    history.push({
                        pathname:'/profilesetup'
                    })
                  }, 3000);
                  
                  
                  document.body
                  .getElementsByClassName("message_logins")[0].innerHTML =wllogin.data.message
                  setClassAdd(classes.msg = " successmsg" );
            }
          }
    }, [wllogin.error, classes.msg, wllogin.data, wllogin.success]);
    const resetRecaptcha = () => {
        recaptchaInstance.reset();  
      };
    function disableButton()
    {
        setIsFormValid(false);
        document.body
        .getElementsByClassName("signinbtn")[0]
          .setAttribute("style", "opacity:0.5;");
    }

    function enableButton()
    {
        setIsFormValid(true);
        document.body
        .getElementsByClassName("signinbtn")[0]
          .setAttribute("style", "opacity:1;");
    }

    // function forgetPassword(){
    //     history.push({
    //         pathname:'/profilesetup'
    //     })
    // }

    function registerNew(){
        history.push({
            pathname:'/'
        })
    }

    function handleSubmit(model)
    {
        model = {"recaptcha": recaptchaCode, ...model}

        dispatch(authActions.wlsubmitLogin(model,props));
        document.body
        .getElementsByClassName("message_logins")[0]
        .innerHTML=" " 

    }

    var verifyCallback = function (response) {
            setRecaptchaCode(response);
        };
         
    return (
        <div className={classes.fullpagelogin + " smdesigntwo"}>

 


        <img className={classes.girlimg} alt="" src={logo}/>
        <div className={classes.loginwrap}>
                <Formsy
                    onValidSubmit={handleSubmit}
                    onValid={enableButton}
                    onInvalid={disableButton}
                    ref={loginformRef}
                    autoComplete={"off"}
                    className={classes.form + " flex flex-col justify-center w-full"}
                >
                  
                  <h4>Sign In</h4>
                  <div className={" message_logins " + classAdd} id="message_logins"></div>
                  <GridContainer>
                      
                    <GridItem xs={12} sm={12} md={12}> 
                        <TextFieldFormsy
                            formControlProps={{
                                fullWidth: true
                            }}
                            type="text"
                            name="username"
                            label="Username"
                            validations={{
                                minLength: 5, 
                                maxLength: 20, 
                            }}
                            validationErrors={{
                                minLength: 'Min character length is 5', 
                                maxLength: 'Max character length is 20', 
                            }}
                            
                            variant="outlined"
                            required
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <TextFieldFormsy
                            formControlProps={{
                                fullWidth: true
                            }}
                            type="password"
                            name="password"
                            label="Enter Password"
                            validations={{
                                minLength: 6
                            }}
                            validationErrors={{
                                minLength: 'Min character length is 6',  
                            }}
                            variant="outlined"
                            required
                        />
                        
                    </GridItem> 
                    
                    <GridItem xs={12} sm={12} md={12}>
                    <ReCAPTCHA 
                        ref={e => recaptchaInstance = e}
                       onChange={verifyCallback}
                       className={classes.receptadiv}
                        sitekey={Gurl.reCaptchaKey}
                        />
                    </GridItem>
                  
                  </GridContainer>
                  
                  
                    <Button simple color="primary" className={classes.signinmodalbtn +" signinbtn"} type="submit" size="lg"  disabled={!isFormValid}>
                        Login
                    </Button> 
                    
                    <Button simple color="primary" className={classes.otpbtn} onClick={registerNew} type="button"   size="lg" >
                       Register New Account
                    </Button>
                </Formsy> 
            
         </div>
         </div>    
    );
}


const bankaccountDb = {
    en: [ 
        {
            'id':'3923923811',
            'title':"Bank Account",
            'tagName': 'tab3',
            'ptext': "To change your Bank Account, please contact Customer Service .",
            'acountname': "Account Name",
            'banknameoption':"Bank Name",
            'bankdiv': [
                {
                    'banknameoption':"Bank Name",
                },
                {
                    'banknameoption':"SBI",
                },
                {
                    'banknameoption':"ICICI",
                },
                {
                    'banknameoption':"Panjab National Bank",
                },
                {
                    'banknameoption':"HDFC",
                },
            ],
            'bankbranch': "Bank Branch",
            'accountnumber': "Account Number",
            'ibannumber': "IBAN / SWIFT Number",
            'ifsccode': "IFSC Code",
            'whyifsc': "FETCH BANK DETAILS CLICK HERE",
            'myaccountbtn': "Add Account", 
        }, 
        
    ],
    tm: [ 
        {
            'id':'3923923811',
            'title':"வங்கி கணக்கு",
            'tagName': 'tab3',
            'ptext': "உங்கள் வங்கி கணக்கை மாற்ற, வாடிக்கையாளர் சேவையைத் தொடர்பு கொள்ளவும்.",
            'acountname': "கணக்கின் பெயர்",
            'banknameoption':"வங்கி பெயர்",
            'bankdiv': [
                {
                    'banknameoption':"வங்கி பெயர்",
                },
                {
                    'banknameoption':"எஸ்பிஐ",
                },
                {
                    'banknameoption':"ஐ.சி.ஐ.சி.ஐ.",
                },
                {
                    'banknameoption':"பஞ்சாப் நேஷனல் வங்கி",
                },
                {
                    'banknameoption':"HDFC",
                },
            ],  
            'bankbranch': "வங்கி கிளை",
            'accountnumber': "கணக்கு எண்",
            'ifsccode': "IFSC குறியீடு",
            'ibannumber': "IBAN / ஸ்விஃப்ட் எண்",
            'Whyifsc': "வங்கி விவரங்களை இங்கே கிளிக் செய்க",
            'myaccountbtn': "கணக்கைச் சேர்",
        }, 
        
    ],
    kn: [ 
        {
            'id':'3923923811',
            'title':"ಬ್ಯಾಂಕ್ ಖಾತೆ",
            'tagName': 'tab3',
            'ptext': "ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಖಾತೆಯನ್ನು ಬದಲಾಯಿಸಲು, ದಯವಿಟ್ಟು ಗ್ರಾಹಕ ಸೇವೆಯನ್ನು ಸಂಪರ್ಕಿಸಿ.",
            'acountname': "ಖಾತೆಯ ಹೆಸರು",
            'banknameoption':"ಬ್ಯಾಂಕ್ ಹೆಸರು",
            'bankdiv': [
                {
                    'banknameoption':"ಬ್ಯಾಂಕ್ ಹೆಸರು",
                },
                {
                    'banknameoption':"ಎಸ್‌ಬಿಐ",
                },
                {
                    'banknameoption':"ಐಸಿಐಸಿಐ",
                },
                {
                    'banknameoption':"ಪಂಜಾಬ್ ನ್ಯಾಷನಲ್ ಬ್ಯಾಂಕ್",
                },
                {
                    'banknameoption':"ಎಚ್‌ಡಿಎಫ್‌ಸಿ",
                },
            ],  
            'bankbranch': "ಬ್ಯಾಂಕ್ ಶಾಖೆ",
            'accountnumber': "ಖಾತೆ ಸಂಖ್ಯೆ",
            'ifsccode': "IFSC ಕೋಡ್",
            'ibannumber': "ಐಬಿಎನ್ / ಸ್ವಿಫ್ಟ್ ಸಂಖ್ಯೆ",
            'Whyifsc': "ಬ್ಯಾಂಕ್ ವಿವರಗಳನ್ನು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ",
            'myaccountbtn': "ಖಾತೆಯನ್ನು ಸೇರಿಸಿ",
        }, 
    ],
    ne: [ 
        {
            'id':'3923923811',
            'title':"बैंक खाता",
            'tagName': 'tab3',
            'ptext': "तपाईंको बैंक खाता परिवर्तन गर्न, कृपया ग्राहक सेवालाई सम्पर्क गर्नुहोस्।",
            'acountname': "खाताको नाम",
            'banknameoption':"बैंक नाम",
            'bankdiv': [
                {
                    'banknameoption':"बैंक नाम",
                },
                {
                    'banknameoption':"एसबीआई",
                },
                {
                    'banknameoption':"आईसीआईसीआई",
                },
                {
                    'banknameoption':"पंजाब नेशनल बैंक",
                },
                {
                    'banknameoption':"HDFC",
                },
            ], 
            'bankbranch': "Bank शाखा",
            'accountnumber': "खाता नम्बर",
            'ifsccode': "IFSC Code",
            'ibannumber': "IBAN / स्विफ्ट संख्या",
            'whyifsc': "फ्याच बैंक विवरण यहाँ क्लिक गर्नुहोस्",
            'myaccountbtn': "खाता थप्नुहोस्",
        }, 
        
    ],
    tl: [ 
        {
            'id':'3923923811',
            'title':"బ్యాంకు ఖాతా",
            'tagName': 'tab3',
            'ptext': "మీ బ్యాంక్ ఖాతాను మార్చడానికి, దయచేసి కస్టమర్ సేవను సంప్రదించండి.",
            'acountname': "ఖాతా పేరు",
            'banknameoption':"బ్యాంక్ పేరు",
            'bankdiv': [
                {
                    'banknameoption':"బ్యాంక్ పేరు",
                },
                {
                    'banknameoption':"ఎస్బిఐ",
                },
                {
                    'banknameoption':"ఐసిఐసిఐ",
                },
                {
                    'banknameoption':"పంజాబ్ నేషనల్ బ్యాంక్",
                },
                {
                    'banknameoption':"HDFC",
                },
            ],
            'bankbranch': "బ్యాంకు శాఖ",
            'accountnumber': "ఖాతా సంఖ్య",
            'ifsccode': "IFSC కోడ్",
            'ibannumber': "IBAN / స్విఫ్ట్ సంఖ్య",
            'whyifsc': "బ్యాంక్ వివరాలను ఇక్కడ క్లిక్ చేయండి",
            'myaccountbtn': "ఖాతా జోడించండి", 
        }, 
        
    ],
    hi: [ 
        {
            'id':'3923923893',
            'title':"बैंक खाता",
            'tagName': 'tab3',
            'ptext': "अपना बैंक खाता बदलने के लिए, कृपया ग्राहक सेवा से संपर्क करें।",
            'banknameoption':"बैंक का नाम",
            'acountname': "खाते का नाम",
            'bankdiv': [
                {
                    'banknameoption':"बैंक का नाम",
                },
                {
                    'banknameoption':"भारतीय स्टेट बैंक",
                },
                {
                    'banknameoption':"आईसीआईसीआई",
                },
                {
                    'banknameoption':"पंजाब नेशनल बैंक",
                },
                {
                    'banknameoption':"एचडीफसी ",
                },
            ],
            'bankbranch': "बैंक शाखा",
            'accountnumber': "खाता संख्या",
            'ibannumber': "आईबीएएन / स्विफ्ट नंबर",
            'ifsccode': "IFSC कोड",
          'whyifsc': "FETCH BANK विवरण यहां क्लिक करें",
            'myaccountbtn': "खाता जोड़ो", 
        }, 
    ]
}
export default bankaccountDb;

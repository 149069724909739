import React from "react";
// @material-ui/core components
import 'react-accessible-accordion/dist/fancy-example.css';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";
import CustomTabs from "../elements/CustomTabs/CustomTabs.js";

import styles from "../assets/jss/components/tabsMessagestyle.js";
import Button from "../elements/CustomButtons/Button";

import messageDb from "../data/message";
import Slide from "@material-ui/core/Slide";
//import ListItem from "@material-ui/core/ListItem";
//import List from "@material-ui/core/List";


const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function Deposit(props) {
    styles.messagebox.background = props.themeColors.bgColors.messagebox;
    styles.listItem["&:nth-child(even)"].background = props.themeColors.bgColors.messageboxlist;
    styles.listItem.color = props.themeColors.bgColors.messageboxlistcolor;
    const classes = useStyles();
    return (
        <div id="nav-tabs" className={classes.signinnerpagebox} >


        <div id="nav-tabs" className={classes.signinnerpageboxin} > 
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomTabs
                themeColors={props.themeColors}
                  plainTabs
                  border="round"
                  tabs={messageDb[sessionStorage.getItem('lang')].map((jsondata) => (
                    {
                      tabName: (<span>{jsondata.title}</span>),
                      tabContent: (
                          <div>
                          {(() =>  {
                              if (jsondata.tagName === "tab1") {
  
                                  return (<div className={classes.messagebox}>
                                     

                                    <Accordion>
                                    {jsondata.messages.map((jsondata) => (
                                        <AccordionItem className={classes.accordianiitm}>
                                            <AccordionItemHeading className={classes.accordianbtn}>
                                                <AccordionItemButton >
                                                    <GridContainer>
                                                        <GridItem xs={1} sm={1} md={1}>
                                                            <p className={classes.serialnumber}>{jsondata.srialno}</p>
                                                        </GridItem>
                                                        <GridItem xs={6} sm={6} md={7}>
                                                            <Button
                                                                href="#"
                                                                color="transparent"
                                                                className={classes.messagedetalbtn}

                                                            >
                                                                <i className={"fa fa-envelope-open"}></i>&nbsp; {jsondata.subject}
                                                            </Button>
                                                        </GridItem>
                                                        <GridItem xs={3} sm={3} md={3}>
                                                            <p className={classes.messagedate}>{jsondata.messagedate}</p>
                                                        </GridItem>
                                                        <GridItem xs={2} sm={2} md={1}>
                                                            <Button
                                                                href="#"
                                                                color="transparent"
                                                                className={classes.messagedeletebtn}
                                                            >
                                                                <i className={"fa fa-trash"}></i>
                                                            </Button>
                                                        </GridItem>
                                                    </GridContainer>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel className={classes.accordianp}>
                                              <div className={classes.msgdiscription}>
                                                <h4>{jsondata.subject_full}</h4> 
                                                <p>{jsondata.discription}</p>
                                              </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>  
                                        ))}
                                    </Accordion> 
                                  </div>)
  
                              }else if (jsondata.tagName === "tab2"){
                                  return (<div className={classes.messagebox}>
                                      <Accordion>
                                    {jsondata.messages.map((jsondata) => (
                                        <AccordionItem className={classes.accordianiitm}>
                                            <AccordionItemHeading className={classes.accordianbtn}>
                                                <AccordionItemButton >
                                                    <GridContainer>
                                                        <GridItem xs={1} sm={1} md={1}>
                                                            <p className={classes.serialnumber}>{jsondata.srialno}</p>
                                                        </GridItem>
                                                        <GridItem xs={6} sm={6} md={7}>
                                                            <Button
                                                                href="#"
                                                                color="transparent"
                                                                className={classes.messagedetalbtn}

                                                            >
                                                                <i className={"fa fa-envelope-open"}></i>&nbsp; {jsondata.subject}
                                                            </Button>
                                                        </GridItem>
                                                        <GridItem xs={3} sm={3} md={3}>
                                                            <p className={classes.messagedate}>{jsondata.messagedate}</p>
                                                        </GridItem>
                                                        <GridItem xs={2} sm={2} md={1}>
                                                            <Button
                                                                href="#"
                                                                color="transparent"
                                                                className={classes.messagedeletebtn}
                                                            >
                                                                <i className={"fa fa-trash"}></i>
                                                            </Button>
                                                        </GridItem>
                                                    </GridContainer>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel className={classes.accordianp}>
                                              <div className={classes.msgdiscription}>
                                                <h4>{jsondata.subject_full}</h4> 
                                                <p>{jsondata.discription}</p>
                                              </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>  
                                        ))}
                                    </Accordion> 
                                  </div>)
  
                              }else if (jsondata.tagName === "tab3"){
                                  return (<div className={classes.messagebox}>
                                      <Accordion>
                                    {jsondata.messages.map((jsondata) => (
                                        <AccordionItem className={classes.accordianiitm}>
                                            <AccordionItemHeading className={classes.accordianbtn}>
                                                <AccordionItemButton >
                                                    <GridContainer>
                                                        <GridItem xs={1} sm={1} md={1}>
                                                            <p className={classes.serialnumber}>{jsondata.srialno}</p>
                                                        </GridItem>
                                                        <GridItem xs={6} sm={6} md={7}>
                                                            <Button
                                                                href="#"
                                                                color="transparent"
                                                                className={classes.messagedetalbtn}

                                                            >
                                                                <i className={"fa fa-envelope-open"}></i>&nbsp; {jsondata.subject}
                                                            </Button>
                                                        </GridItem>
                                                        <GridItem xs={3} sm={3} md={3}>
                                                            <p className={classes.messagedate}>{jsondata.messagedate}</p>
                                                        </GridItem>
                                                        <GridItem xs={2} sm={2} md={1}>
                                                            <Button
                                                                href="#"
                                                                color="transparent"
                                                                className={classes.messagedeletebtn}
                                                            >
                                                                <i className={"fa fa-trash"}></i>
                                                            </Button>
                                                        </GridItem>
                                                    </GridContainer>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel className={classes.accordianp}>
                                              <div className={classes.msgdiscription}>
                                                <h4>{jsondata.subject_full}</h4> 
                                                <p>{jsondata.discription}</p>
                                              </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>  
                                        ))}
                                    </Accordion> 
                                  </div>)
                              }
                          })()}
                              </div>
  
                      )
                    }
  
                  ))}
                />
              </GridItem>
            </GridContainer>
            </div></div>
  
    );
  }
  
import React, {useEffect, useRef, useState} from "react";
 
// @material-ui/core components
import 'react-accessible-accordion/dist/fancy-example.css';

import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";
import axios from 'axios';
// import history from '../../../@history';
import * as globalurl from '../../../global/Global.js'; 
import styles from "../assets/jss/components/tabsWithdrawstyle.js";
import Button from "../elements/CustomButtons/Button";
import { MenuItem} from "@material-ui/core"; 
import Formsy from 'formsy-react'; 
import * as authActions from '../../../auth/casino/actions'; 
import {TextFieldFormsy, SelectFormsy} from '../../../@casino';
import Slide from "@material-ui/core/Slide";
import {useDispatch, useSelector} from 'react-redux';
// import { useTranslation } from 'react-i18next' 
import WithdrawStatementList from "../sections/SectionWithdrawList";
import depositDb from "../data/deposit.js";
// import CustomInput from "../elements/CustomInput/CustomInput";
import withdrawpageDb from "../data/withdrawpage";
import CustomTabsSecond from "../elements/CustomTabs/CustomTabsSecond.js"; 
const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";
var isExistWithdrawApiCall = false;
export default function Widthdraw(props) {
    const formRef = useRef(null); 
    const durationType = "NO";
    const [withdrawData, setWithdrawData] = useState("NO");
    if(props.themeColors != null){
    styles.depositform.background = props.themeColors.bgColors.secondary_dark_bgcolor;
    styles.depositbtn.background = props.themeColors.bgColors.main_color;
    styles.depositbtn.backgroundImage = props.themeColors.bgColors.main_color;
    styles.depositbtn["&:hover"].background = props.themeColors.bgColors.main_color;
    styles.depositbtn["&:hover"].backgroundImage = props.themeColors.bgColors.main_color;
    styles.depositbtn["&:focus"].background = props.themeColors.bgColors.main_color;
    styles.depositbtn["&:focus"].backgroundImage = props.themeColors.bgColors.main_color;
    styles.depositbtn.color = props.themeColors.bgColors.text_primary_dark_color;
    styles.depositbtn["& span"].color = props.themeColors.bgColors.text_primary_dark_color;
    styles.widthdrawbox.background = props.themeColors.bgColors.secondary_dark_bgcolor;  
    //   styles.depositbtn.backgroundImage = props.themeColors.bgColors.golden; 
       styles.criclebox.borderColor = props.themeColors.bgColors.main_color;
       styles.criclebox.background = props.themeColors.bgColors.primary_bgcolor;
      // styles.listItem.color = props.themeColors.bgColors.primary_color;
       styles.headingcenter.color = props.themeColors.bgColors.text_primary_color;
       styles.cricleboxwrap.color = props.themeColors.bgColors.text_primary_color;
       styles.selectboxx["& label"].color = props.themeColors.bgColors.text_primary_color;
       styles.selectboxx["& div div"].color = props.themeColors.bgColors.text_primary_color;
       styles.depositform["& input"].color = props.themeColors.bgColors.text_primary_color;
        styles.depositform["& label"].color = props.themeColors.bgColors.text_secondary_dark_color;
        styles.depositform["& input"].borderBottomColor = props.themeColors.bgColors.secondary_color; 
        styles.thirdwrap.background = props.themeColors.bgColors.primary_light_bgcolor;
        styles.thirdbox.background = props.themeColors.bgColors.primary_inner_bgcolor;
      // styles.tableheading.background = props.themeColors.bgColors.primary_bgcolor;
   //    styles.listItem["&:nth-child(even)"].background = props.themeColors.bgColors.primary_dark_bgcolor;

        styles.headingcenter.fontFamily = props.themeColors.bgColors.base_font
        styles.criclebox.fontFamily = props.themeColors.bgColors.base_font   
        styles.depositform["& label"].fontFamily = props.themeColors.bgColors.base_font   
        styles.depositform["& input"].fontFamily = props.themeColors.bgColors.base_font  
        styles.depositbtn.fontFamily = props.themeColors.bgColors.base_font 
         
        styles.makedeposit["& table tr th"].background = props.themeColors.bgColors.secondary_dark_bgcolor;
        styles.makedeposit["& table tr td"].background = props.themeColors.bgColors.primary_dark_bgcolor; 
    }
    const dateNow = new Date(); // Creating a new date object with the current date and time
    const year = dateNow.getFullYear(); // Getting current year from the created Date object
    const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const month = // Setting current Month number from current Date object
        monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
        ? `0${monthWithOffset}`
        : monthWithOffset;
    const date =
        dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${dateNow.getUTCDate()}`
        : dateNow.getUTCDate();
    //dateNow.setDate(dateNow.getDate() - 7);
    var newYear = ((parseInt(month) === 6 && parseInt(date) === 1) || parseInt(month) < 6) ? year - 1 : year;
    var minDateNow = new Date(newYear, month - 6, date);
    const minyear = minDateNow.getFullYear(); // Getting current year from the created Date object
    const minmonthWithOffset = minDateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const minmonth = // Setting current Month number from current Date object
        minmonthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
        ? `0${minmonthWithOffset}`
        : minmonthWithOffset;
    const mindate =
        minDateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${minDateNow.getUTCDate()}`
        : minDateNow.getUTCDate();

    var oneDateNow = new Date();
    oneDateNow.setDate(oneDateNow.getDate() - 30);
    const oneyear = oneDateNow.getFullYear(); // Getting current year from the created Date object
    const onemonthWithOffset = oneDateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const onemonth = // Setting current Month number from current Date object
        onemonthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
        ? `0${onemonthWithOffset}`
        : onemonthWithOffset;
    const onedate =
    oneDateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${oneDateNow.getUTCDate()}`
        : oneDateNow.getUTCDate();

    var minSevenDateNow = new Date();
    minSevenDateNow.setDate(minSevenDateNow.getDate() - 7);
    const min7year = minSevenDateNow.getFullYear(); // Getting current year from the created Date object
    const min7monthWithOffset = minSevenDateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const min7month = // Setting current Month number from current Date object
        min7monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
        ? `0${min7monthWithOffset}`
        : min7monthWithOffset;
    const min7date =
    minSevenDateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${minSevenDateNow.getUTCDate()}`
        : minSevenDateNow.getUTCDate();
    
    const MaxDateAvailable = `${year}-${month}-${date}`;
    const MinDateAvailable = `${minyear}-${minmonth}-${mindate}`;
    const OneMonthDateAvailable = `${oneyear}-${onemonth}-${onedate}`;
    const SevenDateAvailable = `${min7year}-${min7month}-${min7date}`;

    const [dateConfig, setDateConfig] = React.useState({start: SevenDateAvailable, end: MaxDateAvailable});
    
    function selectDurationType(event){
        if(event.target != null && event.target.value === "1"){
        setDateConfig({start: SevenDateAvailable, end: MaxDateAvailable});
        } else if(event.target != null && event.target.value === "2"){
        setDateConfig({start: OneMonthDateAvailable, end: MaxDateAvailable});
        } else if(event.target != null && event.target.value === "3"){
        setDateConfig({start: MinDateAvailable, end: MaxDateAvailable});
        }
    }
    if(withdrawData === "NO"){
        setWithdrawData("Processing");
        fetchWithdrawableInfo();
    }
    function fetchWithdrawableInfo(){
        const request = axios.post(globalurl.GETWITHDRAWBLEINFO , {
            token: window.localStorage.getItem('jwt_access_token')
        });
        
        request.then((response) => {
            if(response.data.data != null){
                var bankDb = [{
                    "status":true,
                    "data":response.data.data
                }]
                setWithdrawData(response.data.data);
            }
        });
        request.catch((error) => {
            setWithdrawData("NO");
        });
    }
    function changeStartDate(event){
        if(event.target != null && event.target.value > dateConfig.end){
            setDateConfig({start: dateConfig.end, end: dateConfig.end});
        }else if(event.target != null && event.target.value < MinDateAvailable){
            setDateConfig({start: MinDateAvailable, end: dateConfig.end});
        }else if(event.target != null && event.target.value > MaxDateAvailable){
            setDateConfig({start: MaxDateAvailable, end: dateConfig.end});
        }else{
            setDateConfig({start: event.target.value, end: dateConfig.end});
        }
    }
    function changeEndDate(event){
        if(event.target != null && event.target.value < dateConfig.start){
            setDateConfig({start: dateConfig.start, end: dateConfig.start});
        }else if(event.target != null && event.target.value < MinDateAvailable){
            setDateConfig({start: dateConfig.start, end: MinDateAvailable});
        }else if(event.target != null && event.target.value > MaxDateAvailable){
            setDateConfig({start: dateConfig.start, end: MaxDateAvailable});
        }else{
            setDateConfig({start: dateConfig.start, end: event.target.value});
        }
    }

    const dispatch = useDispatch();
    const userAuth = useSelector(({auth}) => auth.withdrawrequest);
   
    // const { t } = useTranslation()
    const [isFormValid, setIsFormValid] = useState(false);
    const [bankData, setBankData] = React.useState([{status: false}]);
    const classes = useStyles();
    const [classAdd, setClassAdd] = useState("errormsg");
  
    useEffect(() => {
        
        if ( userAuth.error && (userAuth.error.banker_id || userAuth.error.amount || userAuth.error.message) )
        {
            if(userAuth.error.message != null){
                if(document.body.getElementsByClassName("messagewithdraw")[0] != null){
                    document.body
                    .getElementsByClassName("messagewithdraw")[0] 
                    .innerHTML=userAuth.error.message;
                    setClassAdd(classes.msg = " errormsg" );
                }else{
                    alert("Withdraw request failed. " + userAuth.error.message);
                }
            }else{
                formRef.current.updateInputsWithError({
                    ...userAuth.error
                });
            }
        }else{
            if(userAuth.success != null && userAuth.success === true){
                //alert("Withdraw request added successfully.")
                if(document.body.getElementsByClassName("messagewithdraw")[0] != null){
                    document.body
                    .getElementsByClassName("messagewithdraw")[0] 
                    .innerHTML="Withdraw request added successfully.";
                    setClassAdd(classes.msg = " successmsg" );
                }else{
                    alert("Withdraw request added successfully.");
                }
                if(formRef != null && formRef.current != null){
                    formRef.current.reset();
                }
                //window.location.reload();
            }
        }
    }, [userAuth.error, userAuth.success, classes.msg]);

  function disableButton()
  {
      setIsFormValid(false);
      document.body
      .getElementsByClassName("signupbtn")[0]
        .setAttribute("style", "opacity:0.5;");
  }
  function enableButton()
  {
      setIsFormValid(true);
      document.body
      .getElementsByClassName("signupbtn")[0]
        .setAttribute("style", "opacity:1;");
  }
  const isObject = function(a) {
    return (!!a) && (a.constructor === Object);
  };
  function banklist(){
    if(isExistWithdrawApiCall === false){
        isExistWithdrawApiCall = true;
    const request = axios.post(globalurl.SELFBANKACCOUNTS , {
        token: window.localStorage.getItem('jwt_access_token'),
        LSTART: 0,
        LEND: 50
    });
    
    request.then((response) => {
        isExistWithdrawApiCall = false;
        if(response.data.data != null){
            var bankDb = [{
                "status":true,
                "data":response.data.data
            }]
            setBankData(bankDb);
        }
    });
    request.catch((error) => {
        isExistWithdrawApiCall = false;
        var bankDb = [{
            "status":false
        }]   
        setBankData(bankDb);
    });
    }
  }

  function handleSubmit(model)
  {
    if(document.body.getElementsByClassName("messagewithdraw")[0] != null){
        document.body
        .getElementsByClassName("messagewithdraw")[0] 
        .innerHTML="";
    }
    dispatch(authActions.withdrawrequest(model));    
  }
  if(bankData[0].status != null && bankData[0].status === false){
    banklist();
  }
  return (  

<div className="sporto">
    
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
             
                    <CustomTabsSecond
                        themeColors={props.themeColors}
                        plainTabs
                        border="round"
                        tabs={withdrawpageDb[sessionStorage.getItem('lang')].map((jsondata) => (
                        {
                            tabName: (<span>{jsondata.title}</span>),
                            tabContent: (
                                <div>
                                {(() =>  {
                                    if (jsondata.tagName === "tab1") {

                                        return ( 
                                            <div className={classes.thirdwrap}>
                                                <div className={classes.thirdbox}>
                                                <div className={classes.makedeposit}>  




                                                <h3 className={classes.headingcenter}>{jsondata.one_heading_text}</h3>     

                                                <div className={classes.cricleboxwrap}> 
                                                    <div className={classes.cricleboxmain}>
                                                        <div className={classes.criclebox}>
                                                        <div className={classes.boxcenter}> 
                                                             {jsondata.one_box}
                                                            <b>{(withdrawData.TOTALAVIALBLEBALNCE != null) ? withdrawData.TOTALAVIALBLEBALNCE : "Processing"}</b>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className={classes.cricleboxmain}>
                                                        <div className={classes.criclebox}>
                                                        <div className={classes.boxcenter}> 
                                                            {jsondata.two_box}
                                                            <b>{(withdrawData.NONWITHDARABLEBONUS != null) ? withdrawData.NONWITHDARABLEBONUS : "Processing"}</b>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className={classes.cricleboxmain}>
                                                        <div className={classes.criclebox}>
                                                        <div className={classes.boxcenter}> 
                                                             {jsondata.three_box}
                                                            <b>{(withdrawData.LASTDEPOSITAMOUNT != null) ? withdrawData.LASTDEPOSITAMOUNT : "Processing"}</b>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className={classes.cricleboxmain}>
                                                        <div className={classes.criclebox}>
                                                        <div className={classes.boxcenter}> 
                                                            {jsondata.four_box}
                                                            <b>{(withdrawData.DEPOSITMULTIPLYBY != null) ? withdrawData.DEPOSITMULTIPLYBY + "X" : "Processing"}</b>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className={classes.cricleboxmain}>
                                                        <div className={classes.criclebox}>
                                                        <div className={classes.boxcenter}> 
                                                             {jsondata.five_box}
                                                            <b>{(withdrawData.TODAYWITHDRAWABLEAMOUNT != null) ? withdrawData.TODAYWITHDRAWABLEAMOUNT : "Processing"}</b>
                                                        </div>
                                                        </div>
                                                    </div> 
                                                    <div style={{clear:"both"}}></div>
                                                *{jsondata.box_bottom_text_one} {(withdrawData.MINWITHDRAWAMOUNT != null) ? withdrawData.MINWITHDRAWAMOUNT : "Processing"} {jsondata.box_bottom_text_two} {(withdrawData.MAXWITHDRAWAMOUNT != null) ? withdrawData.MAXWITHDRAWAMOUNT : "Processing"}
                                                </div>
                                            
                                            


                                            <h3 className={classes.headingcenter}>{jsondata.two_heading_text}</h3>    
                                            
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                <div className={classes.addaccountform}>
                                                        <Formsy
                                                                onValidSubmit={handleSubmit}
                                                                onValid={enableButton}
                                                                onInvalid={disableButton}
                                                                ref={formRef}
                                                                autoComplete={"off"}
                                                                className={classes.form + " flex flex-col justify-center w-full"}
                                                            >
                                                        

                                                        <div className={classes.depositform + " withdrawreqbox"}>
                                                        
                                                        <GridContainer> 
                                                        <GridItem xs={12}><div className={classes.msg + " messagewithdraw " + classAdd} id="messagewithdraw"></div></GridItem>
                                                            <GridItem className={classes.btb + " withdrawcolums"}   sm={6} md={6}>  
                                                        
                                                                <SelectFormsy 
                                                                className={classes.selectboxx + " selectbox"} 
                                                                        label={jsondata.selectbankaccount}
                                                                        name="banker_id"  
                                                                        variant="outlined"
                                                                        required>
                                                                            {
                                                                                (() => {
                                                                                    if(bankData[0].status != null && bankData[0].status === true){
                                                                                        var mItems = bankData.map((jsondata) => {
                                                                                            if(Array.isArray(jsondata.data)){
                                                                                                return (
                                                                                                    jsondata.data.map((bodyData) => (
                                                                                                        <MenuItem value={bodyData.id}>{bodyData.bank_name} (*****{bodyData.acc_number.substr(bodyData.acc_number.length-4, 4)})</MenuItem>
                                                                                                    ))
                                                                                                )
                                                                                            
                                                                                            }else if(isObject(jsondata.data)){
                                                                                                return(
                                                                                                    <MenuItem value={jsondata.data.id}>{jsondata.data.bank_name} (*****{jsondata.data.acc_number.substr(jsondata.data.acc_number.length-4, 4)})</MenuItem>
                                                                                                )
                                                                                            }
                                                                                        });
                                                                                        return mItems;
                                                                                    }
                                                                                })()
                                                                            }
                                                                </SelectFormsy>  
                                                            </GridItem>
                                                            
                                                            <GridItem className={classes.btb + " withdrawcolums"} xs={12} sm={6} md={6}>
                                                                    <TextFieldFormsy 
                                                                        label={jsondata.comment}
                                                                        name="comment"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        validations={{
                                                                            minLength: 6
                                                                        }} 
                                                                        validationErrors={{
                                                                            minLength: 'Min character length is 6'
                                                                        }} 
                                                                        variant="outlined"
                                                                        required
                                                                    />    
                                                            </GridItem>

                                                            <GridItem className={classes.btb + " withdrawcolums"}   sm={6} md={6}>
                                                                
                                                                <TextFieldFormsy 
                                                                        label={jsondata.amount}
                                                                        name="amount"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        validations={{
                                                                            minLength: 3,
                                                                            isNumeric:true,
                                                                        }} 
                                                                        validationErrors={{
                                                                            minLength: 'Min character length is 3',
                                                                            isNumeric:"Number Only",
                                                                        }} 
                                                                        variant="outlined"
                                                                        required
                                                                    />  
                                                            </GridItem> 
                                                            <GridItem className={classes.btb + " withdrawcolums"}   sm={6} md={6}>
                                                                
                                                                <TextFieldFormsy 
                                                                        label={jsondata.confirm_amount}
                                                                        name="confirm_amount"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        validations={{
                                                                            minLength: 3,
                                                                            isNumeric:true,
                                                                            equalsField: "amount"
                                                                        }} 
                                                                        validationErrors={{
                                                                            minLength: 'Min character length is 3',
                                                                            isNumeric:"Number Only",
                                                                            equalsField: 'Confirm Amount must match with amount'
                                                                        }} 
                                                                        variant="outlined"
                                                                        required
                                                                    />  
                                                            </GridItem> 
                                                            

                                                            <GridItem xs={12} sm={12} md={12}>
                                                            <div className={classes.depositformbtni}> 
                                                            <Button 
                                                                type="submit"
                                                                color="transparent" 
                                                                className={classes.depositbtn +" signupbtn"}
                                                                disabled={!isFormValid}
                                                            >
                                                                {jsondata.withdraw_btn}
                                                            </Button></div>
                                                            </GridItem>
                                                            </GridContainer>
                                                        </div>
                                                        </Formsy>
                                                        </div>
                                                </GridItem>
                                            </GridContainer> 
                                            <br/>
                                          
                                            </div>
                                            </div>
                                            </div>

                                        )

                                    }else if (jsondata.tagName === "tab2"){
                                        return (

                                            <div className={classes.thirdwrap}>
                                                <div className={classes.thirdbox}> 


                                                

                                                <div className={classes.makedeposit}>  
                                                <div className={classes.depositform + " withdrawstatus"}>
                                                        <Formsy   
                                                            onValidSubmit={handleSubmit}
                                                            ref={formRef}
                                                            autoComplete={"off"}
                                                            className={classes.form + " flex flex-col justify-center w-full"}
                                                        > 
                                                        <GridContainer>
                                                            <GridItem xs={12} sm={12} md={3}>
                                                                <SelectFormsy 
                                                                    name="duration" 
                                                                    label={jsondata.period}
                                                                    className={classes.selectboxx + " selectbox labelactive "}
                                                                    onChange={(event) => selectDurationType(event)}
                                                                    value={((durationType === "NO") ? 1 : durationType)}
                                                                >
                                                                    {depositDb[sessionStorage.getItem('lang')][0].periodoptions.map((jsondata) => (   
                                                                        <MenuItem key={jsondata.id} value={jsondata.id}>{jsondata.periodoption}</MenuItem>
                                                                    ))}    
                                                                </SelectFormsy>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={3}>
                                                            <TextFieldFormsy 
                                                                className={classes.formtodate + " MuiInputBase-input MuiOutlinedInput-input"}
                                                                label={jsondata.startdate}
                                                                name="start_date"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                type="date"
                                                                defaultValue={dateConfig.start}
                                                                onChange={(event) => {changeStartDate(event)}}
                                                                value={dateConfig.start}
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                InputProps={{inputProps: { min: MinDateAvailable, max: MaxDateAvailable} }}
                                                                variant="outlined"
                                                                required
                                                            />  
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={3}>
                                                            <TextFieldFormsy 
                                                                className={classes.formtodate + " MuiInputBase-input MuiOutlinedInput-input"}
                                                                label={jsondata.enddate}
                                                                name="end_date"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                type="date"
                                                                defaultValue={dateConfig.end}
                                                                onChange={(event) => {changeEndDate(event)}}
                                                                value={dateConfig.end}
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                InputProps={{inputProps: { min: MinDateAvailable, max: MaxDateAvailable} }}
                                                                variant="outlined"
                                                                required
                                                            /> 
                                                            </GridItem>
                                                        </GridContainer>
                                                        </Formsy>                            
                                           
                                                </div>

                                                                            
                                                <WithdrawStatementList themeColors={props.themeColors} statement={true} startDate={dateConfig.start} endDate={dateConfig.end} />
                                                <div className={classes.clearboth}></div>
                                                 

                                                </div> </div>
                                                </div>


                                            
                                            )

                                    } 
                                })()}
                                    </div>

                            )
                        }

                        ))}
                    />
                    </GridItem>
                </GridContainer>

</div>
 
  );
}
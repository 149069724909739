import { container } from "../../casino-kit-react.js";
const tabsStyle = {
    signinnerpagebox:{
        background:"#000",
        padding:"0px 20px 0px",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            padding:"0px 0px 0px",
          },
    },
    signinnerpageboxin:{
        background:"#000",
        margin:"0px 0px 0px",
        borderRadius:"10px", 
    },
    tablewrap:{
        overflow:"auto", 
        borderRadius:"10px",
        border:"1px solid #ffb80c",
        marginBottom:"20px",
        width:"100%",
        "& th":{
            background:"#000",
            padding:"15px", 
            borderLeft:"1px solid #ffb80c",
            borderBottom:"1px solid #ffb80c",
            fontFamily:"ubuntu",
            fontSize:"14px"
        },
        "& th:first-child":{
            borderLeft:"0px",
        },
        "& td":{
            padding:"15px", 
            borderLeft:"1px solid #ffb80c",
            borderColor:"#ffb80c",
            fontFamily:"ubuntu",
            fontSize:"14px"
        },
        "& td:first-child":{
            borderLeft:"0px",
        },
        "& td table th":{
            padding:"5px 10px", 
        },
        "& td table td":{
            padding:"5px 10px", 
        },
        "& a":{
            color:"#FFF"
        },
        "& tr:nth-child(2n)":{
            background: "#2d2d2d",
        },
        "& tr:nth-child(2n+1)":{
            background:"none"
        }
    },
    deposittablee:{
     paddingLeft:"5px !important",
     paddingRight:"5px !important",
     "& h3":{
         paddingLeft:"25px"
     }
    },
    cricleboxwrap:{
      color:"#fff"
    },
    cricleboxmain:{
      width:"19.4%",
      float:"left",
      margin:"0 0.2%",
      ['@media (max-width:890px)']: { // eslint-disable-line no-useless-computed-key
        width:"31%",
        margin:"0 0.8%",
      }, 
      ['@media (max-width:620px)']: { // eslint-disable-line no-useless-computed-key
        width:"49%",
        margin:"0 0.5%",
      }, 
      ['@media (max-width:410px)']: { // eslint-disable-line no-useless-computed-key
        width:"100%",
        margin:"0 0%",
      }, 
    },
    boxcenter:{
      position: "absolute",
      top: "50%",
      left: "0%",
      transform: "translate(0%, -50%)",
      width:"100%", 
    },
    criclebox:{
        position: "relative", 
        minHeight: "100px",
        background:"#000",
        border:"1px solid #efde1d",
        borderRadius:"3px",
        fontFamily:"ubuntu",
        marginBottom:"10px",
        textAlign:"center",
        padding:"10px",
        "& b":{
            fontSize:"22px",
            display:"block",
        }
    },
    formtodate:{
        color:"#fff !important",
        fontFamily:"ubuntu",
        fontSize:"18px",
        paddingLeft: "0",
        marginBottom: "20px",  
        "& input":{
            color:"#fff !important",
            fontSize:"14px",
            fontFamily:"ubuntu", 
            paddingLeft:"0px",
            paddingTop:"0px",
            height: "30px",
            paddingBottom: "5px",
        },
        "& label":{
            color:"#fff !important", 
            fontFamily:"ubuntu", 
            transform: "translate(0px, -3px) scale(1)",
        },
        "& div:hover fieldset":{
            borderColor:"#fff !important",
        },
        "& fieldset":{
            color:"#fff !important",
            border:"0px",
            fontFamily:"ubuntu",
            borderBottom:"1px solid #fff",
            borderColor:"#fff !important",
            borderRadius:"0px",
        },
    },
    depositlistwarp:{
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            overflow:"auto",  
            width:"100%",
          }, 
    },
    depositlistw:{
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"1000px",
          }, 
    },
    clearboth:{
        clear:"both",
    },
    tablewarapbox:{
        background:"#131212",
        borderRadius:"10px",
        padding:"30px 10px 20px",
        margin:"0px 0px 50px",
        "& form":{
            margin:"0px",
            padding:"0px 10px 0px 20px",
        },
        "& form div:before":{
            content: "url !important",
            position: "inherit !important",
        },
        "& .selectbox":{
            margin:"0px",
            ['@media (max-width:960px)']: { // eslint-disable-line no-useless-computed-key
                marginBottom:"25px",
            },
        }
    },
    alert:{
        margin: "0px 0px 0px",
        background:"#ffb80c",
        fontSize: "16px",
        fontFamily: "ubuntu",
        color:"#333",
        padding: "10px 15px",
        borderRadius: "5px",
        clear: "both",
    },
    selectboxx:{ 
        border:"0px",
        width:"100%",
        fontSize: "16px",
        fontFamily: "ubuntu",
        padding: "11px 0 11px",
        borderBottom:"1px solid #fff",
        color:"#fff",
        background:"none !important",
        marginBottom:"20px",
        "&.labelactive label":{
          transform: "translate(0px, -6px) scale(0.75)",
        },
        "& input":{
            color:"#fff !important",
            fontSize:"8px",
            fontFamily:"ubuntu", 
            paddingLeft:"0px",
            paddingTop:"0px",
            height: "28px",
            marginTop:"24px",
            paddingBottom: "0px",
        },
        "& label":{
            color:"#fff !important", 
            fontFamily:"ubuntu", 
            marginTop:"-3px",
            fontSize:"16px",
            transform: "translate(0px, -3px) scale(1)",
        },
        "& div:hover fieldset":{
            borderColor:"#fff !important",
        },
        "& fieldset":{
            color:"#fff !important",
            border:"0px",
            fontFamily:"ubuntu",
            borderBottom:"1px solid #fff",
            borderColor:"#fff !important",
            borderRadius:"0px",
        },
        "& option":{
            color:"#000",
        },
        "& div div":{
            color: "#fff",
            fontSize: "14px",
            fontFamily: "ubuntu",
            padding:"0px",
        }
      },
      depositbtn:{
        color:"#000 !important",
        float: "left",
        border: "0px",
        cursor: "pointer",
        margin: "10px 5px 0px",
        display: "block",
        padding: "4px 25px",
        fontSize: "14px",
        boxShadow: "0px 0px 0px",
        transition: "all 0.3s ease-in",
        fontWeight: "600",
        lineHeight: "32px",
        borderRadius: "3px",
        textTransform: "capitalize",
        backgroundImage: "linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07) !important",
        "& span":{
            color: "000 !important",
        },
        "&:hover":{
          background:"#efde1d !important",
          color:"#000"
        },
        "&:focus":{
          background:"#efde1d !important",
          color:"#000"
        },
    },  
      depositform:{
        background:"#131212",
        margin:"20px 0px 50px",
        borderRadius:"10px",
        padding:"20px 10px 20px 30px",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"100%",
        },
        "& input":{
          background:"none",
          color:"#fff",
            width:"100%",
            fontSize: "14px", 
            borderBottom:"1px solid #bdbdbd",
            marginBottom:"10px",
        },
        "& label":{
            color:"#bdbdbd",
            fontSize:"14px",
            fontFamily:"ubuntu",
            transform: "translate(0px, 20px) scale(1)",
        //    marginTop:"25px",
        },
        "& .MuiFormLabel-root.Mui-focused":{
            color:"#bdbdbd",
        },
        "& div":{
          width:"100%",
          ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            marginLeft:"0px",
            marginRight:"0px",
            paddingLeft:"0px",
            paddingRight:"0px"
            },
        },
        "& fieldset":{
            color:"#fff", 
            border:"0px",
          },
        "& select":{
            background:"none",
            marginBottom:"20px",
            lineHeight:"40px",
            height:"40px",
            width:"100%",
            border:"0px",
            fontSize: "16px",
            borderBottom:"1px solid #a1aed4"
        },
        "& .selectbox":{
            background:"none"
        }
    }, 
    section: {
        background: "#EEEEEE",
        padding: "70px 0"
    },
    pageheadinginner:{
        fontFamily: "ubuntu",
        color:"#fff",
        fontSize:"22px",
        padding:"10px 40px 0px"
    },
    headingcenter:{
        color: "#fff",
        fontSize: "22px",
        fontFamily: "ubuntu",
    },
     
    widthdrawbox:{
        background: "#252f5a",
        padding:"20px 40px",
        textAlign:"center",
        borderRadius:"10px",
        "& p":{
            color:"#a1aed4",
            fontSize:"20px",
            fontFamily: "ubuntu",
        },
        "& i":{
            color:"#a1aed4",
            fontSize:"88px",
            marginBottom:"20px"
        }
    },

    container,
    textCenter: {
        textAlign: "center"
    },
    thirdwrap:{
        background:"#353535",
        padding:"10px",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
          padding: "5px 1px 1px",
        },
      },
      thirdbox:{
        background:"#1e1e1e" ,
        padding:"10px",
        "&.instepselect":{
          backgroundColor: "#ff0000",
        }
      },  
      makedeposit:{
         paddingTop:"10px", 
        "& h6":{
          fontFamily:"ubuntu",
          fontSize:"12px",
          paddingBottom:"10px",
          color:"#ccc",
          margin:"0"
        }, 
        "& table":{
          width:"100%",
          fontFamily:"ubuntu",
          color:"#ccc",
          fontSize:"12px",
          "& tr th":{
            padding:"10px",
            background:"#0f0f0f",
          },
          "& tr td":{
            padding:"10px",
            background:"#2b2b2b",
          }
        },
      },
      timeout:{
        color:"#ccc"
      },
      historybox:{
    padding:"10px",
    ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
      padding: "5px 1px 1px",
    },
    },
    norecordimg:{
      ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
        margin: "0px !important",
        height: "110px",
      },
    },
    betsbox:{
    padding:"10px"
    },
    bonusbox:{
    padding:"10px"
    },
    balancehistory:{
    color:"#ccc",
    fontSize:"12px",
    fontFamily:"ubuntu",
    "& table":{
      width:"100%"
    },
    "& table td":{
      padding:"10px",
      "& table p":{
        textAlign:"center"
      }
    }
    },
    
    bonusbalancewrap:{
    fontFamily:"ubuntu",
    color:"#fff",
    opacity:"0.4",
    borderBottom:"5px solid #efde1d",
    "& h5":{
      margin:"0px",
      padding:"0px",
      fontSize:"18px",
      lineHeight:"20px",
      fontWeight:"bold",
    },
    "& p":{
      margin:"0px",
      fontSize:"12px",
      padding:"0px",
      textTransform:"uppercase",
      color:"#ccc",
    },
    },
    bonusmainbalance:{
    fontFamily:"ubuntu",
    color:"#fff",
    opacity:"0.4",
    textAlign:"right",
    "& h4":{
      fontSize:"24px",
      margin:"0px",
      fontWeight:"bold",
      lineHeight:"26px",
      padding:"0px",
    },
    "& p":{
      margin:"0px",
      fontSize:"12px",
      padding:"0px",
      color:"#ccc",
    },
    },
    activebonus:{ 
    textAlign:"right",
    "& h5":{
      color:"#efde1d",
      fontWeight:"bold",
    },
    "& p":{
      color:"#ccc",
    }
    },
    balancebonus:{
    color:"#ccc",
    marginTop:"20px",
    fontSize:"12px",
    fontFamily:"ubuntu", 
    width:"100%",
    "& td":{
      padding:"10px",
      background:"#2b2b2b",
      "& p":{
        textAlign:"left",
        fontSize:"12px"
      }
    },
    "& tr:nth-child(odd) td":{
      background: "#000",
    }
    },
    balancebonusinner:{
    width:"100%",
    "& tr th":{
      borderBottom:"1px solid #ccc"
    }, 
    },
    bonusbalancewrapactive:{
    opacity:"1"
    },
    bonusmainbalanceactive:{
    opacity:"1"
    },
    
    
    
    
    tableresponsi:{
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      overflow: "auto",
    },
    },
    table:{
    boxShadow:" 0px 10px 40px 0px rgba(0, 0, 0, 0.4)",
    position: "relative",
    width: "100%",
    fontFamily: 'Open Sans',
    textAlign: "left",
    marginBottom: "1rem",
    color: "#212529",
    '& tr:hover': {
      background:"#192246",
    },
    '& td': {
      borderTop:"1px solid rgba(255, 255, 255, 0.09)",
      border:"0px",
      verticalAlign:"middle",
      padding:"20px 15px",
      fontWeight:"600",
      fontSize:"18px",
      textTransform:"uppercase",
      color:"#a1aed4",
    },
    '& th': {
      borderTop:"1px solid rgba(255, 255, 255, 0.09)",
      border:"0px",
      fontSize:"18px",
      fontWeight:"600",
      textTransform:"uppercase",
      borderBottom:"0px",
      padding:"20px 15px",
      background:"#0b122e",
      color:"#fff",
    },
    '& img': {
      width:"40px",
      height:"40px",
      borderRadius:"50%",
      verticalAlign:"middle",
      display:"inline-block"
    },
    '& td:nth-child(1) img': {
      marginRight:"5px",
    },
    '& td:nth-child(3) img': {
      marginRight:"5px",
    },
    '& td:nth-child(6) img': {
      marginRight:"5px",
    },
    '& td:nth-child(1)': {
      color:"#fff",
    },
    '& td:nth-child(3)': {
       color:"#045cf0",
    },
    '& td:nth-child(5)': {
      color:"#f0ad4e",
    },
    '& td:nth-child(6)': {
      color:"#f0ad4e",
    },
    '& td:last-child': {
      color:"#f0ad4e",
    },
    },
    
    tableresponsie:{
      overflow:"auto",
    },
     
    form:{
        margin:"0px"
    },
     
};

export default tabsStyle;



const statementDb = {
    en: [
        { 
            'id': 'stmhddi0r_1',
            'pageheading': 'Bank Accounts', 
            'periodheading': 'Period', 
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 2,
                    'field_name': 'Account Name',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'Account Number',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 2,
                    'field_name': 'IFSC Code',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'Bank Name',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 2,
                    'field_name': 'Branch Name',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 1,
                    'field_name': 'Address',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 1,
                    'field_name': 'Action',
                    'visible': 'desktop',
                    'expandable': true
                },
                

            ],
            'tabledata':[
                {
                    'id'    : '5725a680b3249760ea2wde51',
                    'type'  : '1',
                    'name'  : 'Signup bonus- new ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde52',
                    'type'  : '2',
                    'name'  : 'Signup bonus- new ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde53',
                    'type'  : '3',
                    'name'  : 'Signup bonus- new ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ] ,
            'periodoptions': [
                {
                    'id':"5725a680b3249760ea2wdd01", 
                    'periodoption':"All", 
                },
                { 
                    'id':"5725a680b3249760ea2wdd02", 
                    'periodoption':"Today",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd03", 
                    'periodoption':"This Week",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd04", 
                    'periodoption':"This Month",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd05", 
                    'periodoption':"Specific Time",
                },  
            ], 
            'paymentheading':"Payment Type",
            'paymentoptions': [
                {
                    'id':"5725a680b3249760ea2wdd06", 
                    'paymentoption':"All",
                },
                {
                    'id':"5725a680b3249760ea2wdd07", 
                    'paymentoption':"Deposit",
                },
                {
                    'id':"5725a680b3249760ea2wdd08", 
                    'paymentoption':"Withdraw",
                },
                {
                    'id':"5725a680b3249760ea2wdd09", 
                    'paymentoption':"Games",
                }, 
            ],
            'searchbtn': "Search", 
            'tablehead':[
                { 
                    'tablethsno'  : 'S.No', 
                    'tablethname'  : 'Name',  
                    'tablethstarttime'  : 'Start Time',  
                    'tablethendtime'  : 'End Time',  
                    'tablethamoount'  : 'Amount', 
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'Signup bonus- new ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'Signup bonus- new ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'Signup bonus- new ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ]            

        },
    ],
    tl: [
        { 
            'id': 'stmhddi0r_1',
            'pageheading': 'బ్యాంకు ఖాతాల', 
            'periodheading': 'కాలం', 
            'addbankaccount':"బ్యాంక్ ఖాతాను జోడించండి",
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 2,
                    'field_name': 'ఖాతా పేరు',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'ఖాతా సంఖ్య',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 2,
                    'field_name': 'IFSC కోడ్',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'బ్యాంక్ పేరు',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 2,
                    'field_name': 'శాఖ పేరు',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 1,
                    'field_name': 'చిరునామా',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 1,
                    'field_name': 'చర్య',
                    'visible': 'desktop',
                    'expandable': true
                },
                

            ],
            'tabledata':[
                {
                    'id'    : '5725a680b3249760ea2wde51',
                    'type'  : '1',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde52',
                    'type'  : '2',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde53',
                    'type'  : '3',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ] ,
            'periodoptions': [
                {
                    'id':"5725a680b3249760ea2wdd01", 
                    'periodoption':"అన్నీ", 
                },
                { 
                    'id':"5725a680b3249760ea2wdd02", 
                    'periodoption':"ఈ రోజు",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd03", 
                    'periodoption':"ఈ వారం",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd04", 
                    'periodoption':"ఈ నెల",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd05", 
                    'periodoption':"నిర్దిష్ట సమయం",
                },  
            ], 
            'paymentheading':"చెల్లించు విధానము",
            'paymentoptions': [
                {
                    'id':"5725a680b3249760ea2wdd06", 
                    'paymentoption':"అన్నీ",
                },
                {
                    'id':"5725a680b3249760ea2wdd07", 
                    'paymentoption':"డిపాజిట్",
                },
                {
                    'id':"5725a680b3249760ea2wdd08", 
                    'paymentoption':"ఉపసంహరించుకోండి",
                },
                {
                    'id':"5725a680b3249760ea2wdd09", 
                    'paymentoption':"ఆటలు",
                }, 
            ],
            'searchbtn': "వెతకండి", 
            'tablehead':[
                { 
                    'tablethsno'  : 'ఎస్', 
                    'tablethname'  : 'పేరు',  
                    'tablethstarttime'  : 'ప్రారంభ సమయం',  
                    'tablethendtime'  : 'ముగింపు సమయం',  
                    'tablethamoount'  : 'మొత్తం', 
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ],            
            'loadmore':"మరింత లోడ్ చేయండి ...",
        },
    ],
    hi: [
        { 
            'id': 'stmhddi0r_1',
            'pageheading': 'विवरण', 
            'periodheading': 'अवधि',
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 2,
                    'field_name': 'नम्बर',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'क्रेडिट डेबिट',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 2,
                    'field_name': 'लेन-देन का प्रकार',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'लेनदेन समय',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 2,
                    'field_name': 'राशि',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 1,
                    'field_name': 'स्थति',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 1,
                    'field_name': 'स्थति',
                    'visible': 'desktop',
                    'expandable': true
                },
                

            ],  
            'tabledata':[
                {
                    'id'    : '5725a680b3249760ea2wde51',
                    'type'  : '1',
                    'name'  : 'साइनअप बोनस- नया ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde52',
                    'type'  : '2',
                    'name'  : 'साइनअप बोनस- नया ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea2wde53',
                    'type'  : '3',
                    'name'  : 'साइनअप बोनस- नया ',
                    'transaction_on'  : '2019-12-20 ',
                    'status'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
            ],
            'periodoptions': [
                {
                    'id':"5725a680b3249760ea2wdd01", 
                    'periodoption':"सब", 
                },
                { 
                    'id':"5725a680b3249760ea2wdd02", 
                    'periodoption':"आज",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd03", 
                    'periodoption':"इस सप्ताह",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd04", 
                    'periodoption':"इस महीने",
                }, 
                { 
                    'id':"5725a680b3249760ea2wdd05", 
                    'periodoption':"विशिष्ट समय",
                },  
            ], 
            'paymentheading':"भुगतान के प्रकार",
            'paymentoptions': [
                {
                    'id':"5725a680b3249760ea2wdd06", 
                    'paymentoption':"सब",
                },
                {
                    'id':"5725a680b3249760ea2wdd07", 
                    'paymentoption':"जमा",
                },
                {
                    'id':"5725a680b3249760ea2wdd08", 
                    'paymentoption':"निकालना",
                },
                {
                    'id':"5725a680b3249760ea2wdd09", 
                    'paymentoption':"खेल",
                } 
            ],
            'searchbtn': "खोज", 
            'tablehead':[
                { 
                    'tablethsno'  : 'नम्बर', 
                    'tablethname'  : 'नाम',  
                    'tablethstarttime'  : 'समय शुरू',  
                    'tablethendtime'  : 'अंतिम समय',  
                    'tablethamoount'  : 'रकम', 
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'साइनअप बोनस- नया ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'साइनअप बोनस- नया ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'साइनअप बोनस- नया ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
            ]
        },
    ]
}
export default statementDb;

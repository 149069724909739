

const accountsDb = {
    en: [
        {
            'id':'3923923892',
            'title':"User Profile",
            'tagName': 'tab1', 
            'userprofilediv': [
                {
                    'id':"name",
                    'display_name':"Full Name"
                },
                {
                    'id':"username",
                    'display_name':"User Name"
                },
                {
                    'id':"email",
                    'display_name':"Email Id",
                },
                {
                    'id':"mobile",
                    'display_name':"Mobile Number",
                },
                // {
                //     'id':"commission_type",
                //     'display_name':"Commission Type",
                // },
                {
                    'id':"date_of_birth",
                    'display_name':"Date Of Birth",
                },
                {
                    'id':"address",
                    'display_name':"Address",
                },
                
                {
                    'id':"locality",
                    'display_name':"City",
                },
            ],
            'editprofilebtn':"Edit Profile", 
            'cancelbtn':"Cancel",
            'formname':"Full Name",
            'formusername':"Username",
            'formfirstname':"First Name",
            'formlastname':"Last Name",
            'formcountry':"Country",
            'formdob':"Date of Birth",
            'formaddress':"Address",
            'formcity':"City",
            'formemail':"Email",
            'formphone':"Phone Number", 
            'updateprofilebtn':"Update Profile",
            'formp1':"On name update KYC need to verify again with new name",
            'formp2':"On email update Email need to verify again with new email id",
            'formp3':"On mobile update Mobile need to verify again with new mobile",
        },
        {
            'id':'3923923894',
            'title':"Change Password",
            'tagName': 'tab2', 
            'changepasswordbtn':"Change Password",
            'editprofilebtn':"Edit Profile",
            'oldpassword':"Old Password",
            'newpassword':"New Password",
            'confirmpassword':"Confirm Password",
            'updatepasswordbtn':" Update Password",
            'cancelbtn':"Cancel",
            'formname':"Full Name",
            'formusername':"Username",
            'formemail':"Email",
            'formphone':"Phone Number",
            'formgender':"Gender",
            'formmale':"Male",
            'formfemale':"Female",
            'formdob':"Date of Birth",
            'updateprofilebtn':"Update Profile",
        },
        {
            'id':'3923923811',
            'title':"Bank Account",
            'tagName': 'tab3',
            'ptext': "To change your Bank Account, please contact Customer Service .",
            'acountname': "Account Name",
            'banknameoption':"Bank Name",
            'bankdiv': [
                {
                    'banknameoption':"Bank Name",
                },
                {
                    'banknameoption':"SBI",
                },
                {
                    'banknameoption':"ICICI",
                },
                {
                    'banknameoption':"Panjab National Bank",
                },
                {
                    'banknameoption':"HDFC",
                },
            ],
            'bankbranch': "Bank Branch",
            'accountnumber': "Account Number",
            'ifsccode': "IFSC Code",
            'whyifsc': "FETCH BANK DETAILS CLICK HERE",
            'myaccountbtn': "Add Account", 
        },
        {
            'id':'3923923894',
            'title':"Kyc Verification",
            'tagName': 'tab4', 
        },
        {
            'id':'3923923894',
            'title':"Login History",
            'tagName': 'tab5', 
        },
        // {
        //     'id':'39232923894',
        //     'title':"Notifications",
        //     'tagName': 'tab6', 
        // },
        
    ],
    tl: [
        {
            'id':'3923923892',
            'title':"వినియోగదారు వివరాలు",
            'tagName': 'tab1', 
            'userprofilediv': [
                {
                    'id':"name",
                    'display_name':"పూర్తి పేరు"
                },
                {
                    'id':"username",
                    'display_name':"వినియోగదారు పేరు"
                },
                {
                    'id':"email",
                    'display_name':"ఇమెయిల్ ఐడి",
                },
                {
                    'id':"mobile",
                    'display_name':"మొబైల్ సంఖ్య",
                },
                // {
                //     'id':"commission_type",
                //     'display_name':"కమిషన్ రకం",
                // },
                {
                    'id':"date_of_birth",
                    'display_name':"పుట్టిన తేది",
                },
                {
                    'id':"address",
                    'display_name':"చిరునామా",
                },
                
                {
                    'id':"locality",
                    'display_name':"నగరం",
                },
            ], 
            'editprofilebtn':"ప్రొఫైల్‌ను సవరించండి", 
            'cancelbtn':"రద్దు చేయండి",
            'formname':"పూర్తి పేరు",
            'formusername':"వినియోగదారు పేరు",
            'formfirstname':"మొదటి పేరు",
            'formlastname':"చివరి పేరు",
            'formcountry':"దేశం",
            'formdob':"పుట్టిన తేది",
            'formaddress':"చిరునామా",
            'formcity':"నగరం",
            'formemail':"ఇమెయిల్",
            'formphone':"ఫోను నంబరు", 
            'updateprofilebtn':"ప్రొఫైల్‌ను నవీకరించండి",
            'formp1':"పేరు నవీకరణలో KYC క్రొత్త పేరుతో మళ్ళీ ధృవీకరించాలి",
            'formp2':"ఇమెయిల్ నవీకరణలో ఇమెయిల్ క్రొత్త ఇమెయిల్ ఐడితో మళ్ళీ ధృవీకరించాలి",
            'formp3':"మొబైల్ నవీకరణలో మొబైల్ కొత్త మొబైల్‌తో మళ్లీ ధృవీకరించాలి",
        },
        {
            'id':'3923923894',
            'title':"పాస్వర్డ్ మార్చండి",
            'tagName': 'tab2', 
            'changepasswordbtn':"పాస్వర్డ్ మార్చండి",
            'editprofilebtn':"ప్రొఫైల్‌ను సవరించండి",
            'oldpassword':"పాత పాస్‌వర్డ్",
            'newpassword':"కొత్త పాస్వర్డ్",
            'confirmpassword':"పాస్వర్డ్ను నిర్ధారించండి",
            'updatepasswordbtn':" పాస్వర్డ్ను నవీకరించండి",
            'cancelbtn':"రద్దు చేయండి",
            'formname':"పూర్తి పేరు",
            'formusername':"వినియోగదారు పేరు",
            'formemail':"ఇమెయిల్",
            'formphone':"ఫోను నంబరు",
            'formgender':"లింగం",
            'formmale':"పురుషుడు",
            'formfemale':"స్త్రీ",
            'formdob':"పుట్టిన తేది",
            'updateprofilebtn':"ప్రొఫైల్‌ను నవీకరించండి",
        },
        {
            'id':'3923923811',
            'title':"బ్యాంకు ఖాతా",
            'tagName': 'tab3',
            'ptext': "మీ బ్యాంక్ ఖాతాను మార్చడానికి, దయచేసి కస్టమర్ సేవను సంప్రదించండి.",
            'acountname': "ఖాతా పేరు",
            'banknameoption':"బ్యాంక్ పేరు",
            'bankdiv': [
                {
                    'banknameoption':"బ్యాంక్ పేరు",
                },
                {
                    'banknameoption':"ఎస్బిఐ",
                },
                {
                    'banknameoption':"ఐసిఐసిఐ",
                },
                {
                    'banknameoption':"పంజాబ్ నేషనల్ బ్యాంక్",
                },
                {
                    'banknameoption':"HDFC",
                },
            ],
            'bankbranch': "బ్యాంకు శాఖ",
            'accountnumber': "ఖాతా సంఖ్య",
            'ifsccode': "IFSC కోడ్",
            'whyifsc': "బ్యాంక్ వివరాలను ఇక్కడ క్లిక్ చేయండి",
            'myaccountbtn': "ఖాతా జోడించండి", 
        },
        {
            'id':'3923923894',
            'title':"Kyc ధృవీకరణ",
            'tagName': 'tab4',  
        },
        {
            'id':'3923923894',
            'title':"లాగిన్ చరిత్ర",
            'tagName': 'tab5', 
        },
        // {
        //     'id':'39232923894',
        //     'title':"నోటిఫికేషన్‌లు",
        //     'tagName': 'tab6', 
        // },
        
    ],
    hi: [
        {
            'id':'3923923892',
            'title':"उपयोगकर्ता प्रोफ़ाइल",
            'tagName': 'tab1', 
            'userprofilediv': [
                {
                    'id':"name",
                    'display_name':"पूरा नाम"
                },
                {
                    'id':"username",
                    'display_name':"उपयोगकर्ता नाम"
                },
                {
                    'id':"email",
                    'display_name':"ईमेल",
                },
                {
                    'id':"mobile",
                    'display_name':"मोबाइल",
                }, 
                {
                    'id':"date_of_birth",
                    'display_name':"जन्म की तारीख",
                },
                // {
                //     'id':"commission_type",
                //     'display_name':"कमीशन का प्रकार",
                // },
                {
                    'id':"address",
                    'display_name':"पता",
                },
                
                {
                    'id':"locality",
                    'display_name':"शहर",
                },
            ],
            'editprofilebtn':"प्रोफ़ाइल संपादित करें", 
            'cancelbtn':"रद्द करना", 
            'formname':"पूरा नाम",
            'formusername':"उपयोगकर्ता नाम",
            'formemail':"ईमेल", 
            'formphone':"फ़ोन नंबर",  
            'formdob':"जन्म की तारीख",
            'updateprofilebtn':"प्रोफ़ाइल अपडेट करें", 
            'formfirstname':"पहला नाम",
            'formlastname':"उपनाम",
            'formcountry':"देश", 
            'formaddress':"पता",
            'formcity':"शहर",    
            'formp1':"नाम अपडेट पर केवाईसी को नए नाम के साथ फिर से सत्यापित करने की आवश्यकता है",
            'formp2':"ईमेल अपडेट पर ईमेल को नई ईमेल आईडी के साथ फिर से सत्यापित करने की आवश्यकता है",
            'formp3':"मोबाइल अपडेट पर मोबाइल को नए मोबाइल के साथ फिर से सत्यापित करने की आवश्यकता है",
        },
        {
            'id':'3923923893',
            'title':"पासवर्ड बदलें",
            'tagName': 'tab2',
            'changepasswordbtn':"पासवर्ड बदलें", 
            'oldpassword':"पुराना पासवर्ड",
            'newpassword':"नया पासवर्ड",
            'confirmpassword':"पासवर्ड की पुष्टि कीजिये",
            'updatepasswordbtn':"पासवर्ड अपडेट करें",
            'cancelbtn':"रद्द करना", 
        },
        {
            'id':'3923923893',
            'title':"बैंक खाता",
            'tagName': 'tab3',
            'ptext': "अपना बैंक खाता बदलने के लिए, कृपया ग्राहक सेवा से संपर्क करें।",
            'banknameoption':"बैंक का नाम",
            'acountname': "खाते का नाम",
            'bankdiv': [
                {
                    'banknameoption':"बैंक का नाम",
                },
                {
                    'banknameoption':"भारतीय स्टेट बैंक",
                },
                {
                    'banknameoption':"आईसीआईसीआई",
                },
                {
                    'banknameoption':"पंजाब नेशनल बैंक",
                },
                {
                    'banknameoption':"एचडीफसी ",
                },
            ],
            'bankbranch': "बैंक शाखा",
            'accountnumber': "खाता संख्या",
            'ifsccode': "IFSC कोड",
          'whyifsc': "FETCH BANK विवरण यहां क्लिक करें",
            'myaccountbtn': "खाता जोड़ो", 
        },
        {
            'id':'3923923894',
            'title':"Kyc सत्यापन",
            'tagName': 'tab4', 
        },
        {
            'id':'3923923894',
            'title':"लॉगिन इतिहास",
            'tagName': 'tab5', 
        },
        // {
        //     'id':'39232923894',
        //     'title':"सूचनाएं",
        //     'tagName': 'tab6', 
        // },
    ]
}
export default accountsDb;

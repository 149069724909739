import { defaultFont } from "../../casino-kit-react";

import tooltip from "../kit/tooltipsStyle.js";

const headerLinksStyle = ({

  leftcontent:{
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      display:"none"
    },
  },
  texttiele:{
    
    color:"#fff",
    paddingRight:"10px",
  },
  radiobbn:{
    width:"100%",
    height:"50px",
    position:"absolute",
    left:"0px",
    top:"0px",
    margin:"0px",
    opacity:"0",
  },
  contryflag:{
    width:"26px",
    height:"26px",
    borderRadius:"50px",
    verticalAlign: "middle"
  },
  contryflagt:{
    width:"30px",
    height:"30px",
    borderRadius:"50px",
    verticalAlign: "middle",
    margin:"0 !important",
    float:"left"
  },
  userflagbtn:{
    textTransform:"capitalize",
    fontSize: "16px !important",
    fontFamily: "ubuntu",
    background: "#0f0f0f !important",
    padding: "7px 10px !important",
    "& b":{
      display:"none"
    },
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      width: "100% !important",
      marginTop: "0px !important", 
      marginLeft: "10px !important",
      marginBottom: "0px !important",
    },
  },
  usersigninanker:{
    fontSize: "14px",
    color:"#252f5a",
    textDecoration:"none",
    display:"block",
    padding:"6px 20px",
    fontFamily:'ubuntu',
    fontWeight:"lighter",
    width: "100%", 
    textAlign: "left",
    "&:hover":{
      color:"#fff",
      background:"#000000",
    }
  },
  dropdownLinkbtnpink:{
    fontSize: "16px",
    textTransform: "uppercase",
    fontWeight: "600",
    padding: "14px 35px",
    display: "inline-block",
    color: "#fff",
    border: "0px",
    borderRadius: "50px",
    cursor: "pointer",
    transition: "all 0.3s ease-in",
    backgroundImage: "linear-gradient(to left, #ff344d, #d2273c)",
  },
  topheader:{
    // backgroundColor:"#070b28",
    color:"#fff",
    padding:"1px 0px 3px",
    // ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
    //   padding:"1px 0px 3px"
    // },
  },
  headbackground:{
    backgroundColor:"#252f5a",
      flex:"auto"
  },
  list2: {
    ...defaultFont,
    fontSize: "14px",
    margin: 0,
    float:"right",
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: "inherit",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      float:"none",
    },
  },
  onlanguage:{
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      float:"right !important",
    },
  },
  norecordimg:{
    ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
      margin: "0px !important",
      height: "110px",
    },
  },
  list: {
    ...defaultFont,
    fontSize: "14px",
    margin: 0,
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: "inherit"
  },
  listItem: {
    float: "left",
    color: "inherit",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    marginRight: "2px",
    position: "relative",
    "&::before":{
      // position: "absolute",
      // content: "'|'",
      // top: "4px",
      // right: "-14px",
    },
    "&:last-child::before":{
      display:"none"
    },
    "&:last-child":{
      marginRight:"0px"
    },
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      marginRight: "12px",
      "&::before":{
        position: "absolute",
        content: "none",
        top: "0",
        right: "-23px",
      },
    },
  },
  socialmobile:{
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      paddingTop: "8px",
    },
  },
  listItemText: {
    padding: "0 !important"
  },
  navLink: {
    border: "0px",
    background: "none",
    fontFamily: 'Open Sans',
    fontSize: "18px",
    color: "#a1aed4 !important",
    position: "relative",
    padding: "0px 0px 0px",
    fontWeight: "400",
    textTransform: "capitalize",
    borderRadius: "3px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: "#f9314b",
      background: "none"
    },
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      color:"#a1aed4 !important",
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start"
      },
    },
  },
  notificationNavLink: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    top: "4px"
  },
  registerNavLink: {
    top: "3px",
    position: "relative",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex"
  },
  navLinkActive: {
    color: "inherit",
    backgroundColor: "rgba(255, 255, 255, 0.1)"
  },
  icons: {
    width: "20px",
    height: "20px",
    marginRight: "3px"
  },
  socialIcons: {
    position: "relative",
    fontSize: "20px !important",
    marginRight: "4px",
    marginTop: "5px",
  },
  dropdownLink: {
    padding:"0px",
    fontSize: "15px",
    fontFamily: 'Open Sans',
    color: "#a1aed4 !important",
    textTransform:"capitalize",
    lineHeight: "1.625",
    fontWeight:"normal",
    marginTop:"3px",
    "&:hover,&:focus": {
      color: "inherit",
      textDecoration: "none",
      display: "block",
    },
  },
  ...tooltip,
  marginRight5: {
    marginRight: "5px"
  }
});

export default headerLinksStyle;

/*eslint-disable*/
import React, {useEffect, useRef, useState} from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton"; 
import People from "@material-ui/icons/People";
import Email from "@material-ui/icons/Email"; 
// react components for routing our app without refresh
import { Link, withRouter } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// @material-ui/core components
import {Button, Divider, Typography, InputAdornment, Icon} from '@material-ui/core';
import CustomInput from "../elements/CustomInput/CustomInput.js";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// core components
import CustomDropdown from "../elements/CustomDropdown/CustomDropdown.js";
import CustomButton from "../elements/CustomButtons/Button.js";
//Third Party Authentication
import {FacebookLogin, GoogleLogin} from "../../../@casino"

import Formsy from 'formsy-react';
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import menuDb from "../data/menu.js";
import logo from "../assets/img/logo.png";
import Hometab from "../pages/hometab";
import GridItem from "../elements/Grid/GridItem";
import GridContainer from "../elements/Grid/GridContainer";
import styles from "../assets/jss/components/headerLinksStyle.js";
import stylesr from "../assets/jss/components/headerLinkstopStyle"; 

import * as authActions from '../../../auth/casino/actions';
import {useDispatch, useSelector} from 'react-redux';
import {TextFieldFormsy, CheckboxFormsy} from '../../../@casino';
import secloginboxDb from "../data/secloginbox.js";
import themeDb from "../data/theme.js";
//import { useTranslation } from 'react-i18next'
const history = createBrowserHistory()
let classicModal = null;
let setClassicModal = null;
let bodyData = null;
let setBodyData = null;
var manualClose = false;
let bodyDataOutPut = null
let setBodyDataOutPut = null;
 
function SectionTransactionDetailNew(props) {
    [classicModal, setClassicModal] = React.useState(false);
    [bodyData, setBodyData] = React.useState(false);
    [bodyDataOutPut, setBodyDataOutPut] = React.useState(false);
    const dispatch = useDispatch();
     
    if(props.themeColors != null){
      styles.signinbtn.backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinbtn.color = props.themeColors.bgColors.text_primary_dark_color;
      styles.modalHeader.background = props.themeColors.bgColors.primary_dark_bgcolor;
      styles.modalBody.background = props.themeColors.bgColors.primary_dark_bgcolor;
      styles.modalFooter.background = props.themeColors.bgColors.primary_dark_bgcolor;
      styles.modalSubtitle.color = props.themeColors.bgColors.text_primary_color;
      styles.signupbixp.color = props.themeColors.bgColors.text_primary_color; 
      styles.modalCloseButton.color = props.themeColors.bgColors.text_primary_color;
      styles.signinmodalbtn.backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn["&:hover"].backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn.color = props.themeColors.bgColors.text_primary_dark_color;
      styles.modalBodyprofile.background = props.themeColors.bgColors.primary_dark_bgcolor;

      styles.tableheading.background = props.themeColors.bgColors.primary_bgcolor; 
      styles.tableheading.color = props.themeColors.bgColors.text_primary_color; 
      styles.listItemss.color = props.themeColors.bgColors.text_primary_color; 
    }
    var setManualClose = function(state){
      manualClose = state;
    }
  //  const { t } = useTranslation()
    let currentStyle = styles;
    const useStyles = makeStyles(styles);

    const classes = useStyles();
  [classicModal, setClassicModal] = React.useState(false);
  
  if((bodyDataOutPut === null || bodyDataOutPut === false) && bodyData != null && bodyData != false && bodyData.game_actual_result != null){
        setBodyDataOutPut(fetchDataValues());
    }
  function fetchDataValues(){
        var totalStack = 0.00;
        var totalPayout = 0.00;
        var transactionId = "";
        var gameDataInfo = JSON.parse(bodyData.game_actual_result);
        if(gameDataInfo.data != null && gameDataInfo.data.participants != null && gameDataInfo.data.participants[0].bets != null){
            gameDataInfo.data.participants[0].bets.map((resultMaps) => {
                totalStack = Number(totalStack) + Number(resultMaps.stake);
                totalPayout = Number(totalPayout) + Number(resultMaps.payout);
                transactionId = resultMaps.transactionId;
            })
        }
        return(
            <div className={classes.wraptable}>
                <h3 className={classes.accountsheads}>Result [{(bodyData.trnref != null) ? bodyData.trnref : bodyData.txnno}]</h3>
                <h4 className={classes.centerheading}>Game Play Information</h4>
                <div className={classes.borderbox}>
                    <div className={classes.resulttablebox}>
                    <table> 
                        <tr> 
                            <td>Game Play ID</td>  
                            <td align="right">{(bodyData.txnref != null) ? bodyData.txnref : bodyData.txnno}</td>  
                        </tr> 
                        <tr> 
                            <td>Game</td>  
                            <td align="right">{(gameDataInfo.data != null && gameDataInfo.data.table != null && gameDataInfo.data.table.name != null) ? gameDataInfo.data.table.name : ""}</td>  
                        </tr> 
                        <tr> 
                            <td>Supplier</td>  
                            <td align="right">Evolution</td>  
                        </tr> 
                        <tr> 
                            <td>Game Profile</td>  
                            <td align="right">Primary</td>  
                        </tr> 
                        <tr> 
                            <td>Result</td>  
                            <td align="right">{(totalStack > totalPayout) ? "Lost" : ((totalStack < totalPayout) ? "Win" : "Push")}</td>  
                        </tr> 
                        <tr> 
                            <td>Evolution Game ID</td>  
                            <td align="right">{(gameDataInfo.data != null && gameDataInfo.data.id) ? gameDataInfo.data.id : ""}</td>
                        </tr>
                    </table> 
                    </div>
                    
                </div>
                <h4 className={classes.centerheading}>Transactions</h4>
                <div className={classes.tableresponsive}>
                    <div className={classes.resulttablebox}>
                    <table> 
                        <tr> 
                            <th>ID</th>  
                            <th>Date/Time</th>  
                            <th>Stake Amount</th>  
                            <th>Result Amount</th>  
                            <th>Starting balance</th>  
                            <th>New Balance</th>  
                        </tr> 
                        <tr key="1"> 
                            <td>{transactionId}</td>  
                            <td>{(gameDataInfo.data != null && gameDataInfo.data.startedAt != null) ? gameDataInfo.data.startedAt : ""}</td> 
                            <td>{totalStack}</td>  
                            <td>{totalPayout}</td>  
                            <td>{(bodyData.balance_open_avail != null) ? bodyData.balance_open_avail : ""}</td>  
                            <td>{(bodyData.balance_avail != null) ? bodyData.balance_avail : ""}</td>   
                        </tr>  
                    </table> 
                    </div>
                
                </div>
                <h4 className={classes.centerheading}>Bets</h4>
                <div className={classes.resulttablebox}>
                    <table> 
                        <tr> 
                            <th>Bet</th>  
                            <th>Staked</th>  
                            <th>Returned</th>   
                        </tr> 
                        {(() => {
                        if(gameDataInfo.data != null && gameDataInfo.data.participants != null && gameDataInfo.data.participants[0].bets != null){
                            var myBets = gameDataInfo.data.participants[0].bets.map((resultMaps) => 
                                (
                                <tr key="1"> 
                                    <td>{resultMaps.description}</td>  
                                    <td>{resultMaps.stake}</td> 
                                    <td>{resultMaps.payout}</td>   
                                </tr>  
                                )
                                );
                                return myBets;
                            }
                        })()}
                    </table> 
                </div>
                
                <h4 className={classes.centerheading}>Result</h4>
                <div className={classes.borderbox}>
                    <div className={classes.resulttablebox}>
                        <table>  
                            <tr> 
                                <td>Status</td>  
                                <td>{(gameDataInfo.data != null && gameDataInfo.data.status != null) ? gameDataInfo.data.status : ""}</td> 
                            </tr>  
                            <tr> 
                                <td>Table</td>  
                                <td>{(gameDataInfo.data != null && gameDataInfo.data.table != null && gameDataInfo.data.table.name != null) ? gameDataInfo.data.table.name : ""} [{(gameDataInfo.data != null && gameDataInfo.data.table != null && gameDataInfo.data.table.id != null) ? gameDataInfo.data.table.id : ""}]</td> 
                            </tr> 
                            <tr> 
                                <td>Outcomes</td>  
                                <td>
                                    {(() => {
                                        if(gameDataInfo.data != null && gameDataInfo.data.result != null && gameDataInfo.data.result.outcomes != null){
                                            var outcomeHTML = gameDataInfo.data.result.outcomes.map((innerOutcome) => {
                                                (
                                                    <span>{innerOutcome.number} ({innerOutcome.type}, {innerOutcome.color}) </span>
                                                )
                                            })
                                            return outcomeHTML;
                                        }
                                        
                                    })()}
                                </td> 
                            </tr> 
                        </table> 
                    </div>
                    
                </div>  
                </div>
            
        )
  }

  return ( <div>
   {secloginboxDb[sessionStorage.getItem('lang')].map((jsondata) => (   
      <div key={jsondata.id}>
    <Dialog
    classes={{
      root: classes.center + " " + classes.topfiix,
      paper: classes.modal + " " + classes.responsivemodellAllpro
      
    }}
    key={jsondata.id}
    open={classicModal}
     
    keepMounted
    onClose={() => { manualClose = true;setClassicModal(false)}}
    aria-labelledby="classic-modal-slide-title"
    aria-describedby="classic-modal-slide-description"
>

  <DialogTitle
      id="classic-modal-slide-title"
      disableTypography
      className={classes.modalHeader + " " + classes.modalHeaderprofile}
  >
    <IconButton
        className={classes.modalCloseButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => { manualClose = true;setClassicModal(false)}}
    >
      <Close className={classes.modalClose} />
    </IconButton>
     
  </DialogTitle>
 
  <DialogContent
      id="classic-modal-slide-description"
      className={classes.modalBody + " " + classes.modalBodyprofile}
  >  
    {bodyDataOutPut}
  </DialogContent>
   
</Dialog>
</div>
))}
</div>
  );
}
SectionTransactionDetailNew.propTypes = {
  openPopup: function(isState, transactionRefId){ 
      if(isState === "login"){
        bodyDataOutPut = null;
        setClassicModal(true);
        setBodyDataOutPut(false);
        setBodyData(transactionRefId);
      } 
    }
};

SectionTransactionDetailNew.openPopup = function(isState, transactionRefId){ 
    if(isState === "login"){
        bodyDataOutPut = null;
        setClassicModal(true);
        setBodyDataOutPut(false);
        setBodyData(transactionRefId);
    } 
}

export default SectionTransactionDetailNew;
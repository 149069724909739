


const depositDb = {
    en: [
        {
            'id' : '5725a680b3249770ea31de79',
            'title':"Make Withdraw",
            'tagName': 'tab1',
            'mobalbtn':"How do i make a Rupee deposit?",
            'mobalheading':"How can I deposit money?",
            'paybyoptions':[
                {
                    'id': "1,2,3,4,5,6,7",
                    'name': 'All Type Deposit',
                    'xs': 1
                },
                {
                    'id': "1",
                    'name': 'Bant to Bank',
                    'xs': 1
                },
                {
                    'id': "2",
                    'name': 'Cash Deposit',
                    'xs': 1
                },
                {
                    'id': "4",
                    'name': 'PayTM',
                    'xs': 1
                },
                {
                    'id': "5",
                    'name': 'Phone Pay',
                    'xs': 1
                },
                {
                    'id': "6",
                    'name': 'Google Pay',
                    'xs': 1
                },
                {
                    'id': "7",
                    'name': 'Other Pay',
                    'xs': 1
                }
            ],
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"Last 7 Days", 
                },
                { 
                    'id':"2", 
                    'periodoption':"Last 1 Month",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"Last 6 Months",
                },  
            ], 
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 1,
                    'field_name': 'Request Number',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'Type of Request',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 1,
                    'field_name': 'Send On',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'Send From',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'Amount',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': 'Reference Id',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 2,
                    'field_name': 'Request On',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_8',
                    'xs': 1,
                    'field_name': 'Status',
                    'visible': 'desktop',
                    'expandable': true
                },
            ],
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de80',
                    'mobalitem':"In the “Deposit” tab of your iSoft Gaming account, select Rupee.",
                },
                {
                    'id' : '5725a680b3249770ea31de81',
                    'mobalitem':"Select which bank you want to transfer from and its amount.",
                },
                {
                    'id' : '5725a680b3249770ea31de82',
                    'mobalitem':"You will be redirected to the bank page. Follow the instructions on the page and complete the transaction.",
                },
                {
                    'id' : '5725a680b3249770ea31de83',
                    'mobalitem':"After a successful transaction, your credit will be added automatically within 5 minutes.",
                },
                {
                    'id' : '5725a680b3249770ea31de84',
                    'mobalitem':"To avoid any delays in processing the deposit, kindly ensure all information is submitted correctly.",
                },
                {
                    'id' : '5725a680b3249770ea31de85',
                    'mobalitem':"Please feel free to contact Customer Service if you have any questions.",
                }, 
            ], 
            'guidebtn':"Rupee Guide",
            'bankname': "Select your bank",
            'bankoptions':[
                {
                    'id' : '5725a680b3249770ea31de86',
                    'bankoptions':"SBI",
                },
                {
                    'id' : '5725a680b3249770ea31de87',
                    'bankoptions':"HDFC",
                },
                {
                    'id' : '5725a680b3249770ea31de88',
                    'bankoptions':"ICICI",
                },
                {
                    'id' : '5725a680b3249770ea31de89',
                    'bankoptions':"PNB",
                },
            ],
            'amountheading': "Enter amount to deposit",
            'amountoption': "Enter Your Amount",
            'depositbtn':"Deposit",
            'tableheading':"Rupee Limit",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea31de90',
                    'tableth':"Minimum deposit per transaction", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea31de91',
                    'tableth':"Maximum deposit per transaction", 
                    'tabletd':"INR 20,000.00",
                },
            ],
            'tablenote':"NOTE: Deposits are subjected to a 1x wagering requirement.",
            'top_text'  : 'Your account is demo purpose only. Not authorize to do payment withdrawal/deposit', 
            'one_heading_text'  : 'Available Balance', 
            'one_box'  : 'Total Available Balance', 
            'two_box'  : 'Total Non Withdrawable Balance', 
            'three_box'  : 'Last Deposit Amount', 
            'four_box'  : 'Wager on Last Deposit', 
            'five_box'  : 'Today Withdrawable Balance', 
            'box_bottom_text_one'  : 'Withdrawal amount must be greater then', 
            'box_bottom_text_two'  : 'and maximum', 
            'two_heading_text'  : 'Withdrawal Request',
            'selectbankaccount'  : 'Select Bank Account',
            'amount'  : 'Amount',
            'confirm_amount'  : 'Confirm Amount',
            'comment'  : 'Comment', 
            'withdraw_btn'  : 'Withdraw',
            'period'  : 'Period',
            'start_date'  : 'Start Date',
            'end_date'  : 'End Date',
        },
      
        {
            'id' : '5725a680b3249770ea31de92',
            'title':"Withdraw Status",
            'tagName': 'tab2',
            'mobalbtn':"How do I make a Credit deposit?",
            'mobalheading':"How do I make a Credit Card deposit?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de93',
                    'mobalitem':"Log in to your iSoft Gaming account.",
                },
                {
                    'id' : '5725a680b3249770ea31de94',
                    'mobalitem':"Click the “Deposit” button at the dropdown arrow near your username.",
                },
                {
                    'id' : '5725a680b3249770ea31de95',
                    'mobalitem':"Inside the deposit tab, select “Credit Card” as your preferred deposit method.",
                },
                {
                    'id' : '5725a680b3249770ea31de96',
                    'mobalitem':"Enter your Credit card information including the card number, CVV number and expiration date.",
                },
                {
                    'id' : '5725a680b3249770ea31de97',
                    'mobalitem':"Type your desired amount of payment in the empty field of the deposit amount.",
                },
                {
                    'id' : '5725a680b3249770ea31de98',
                    'mobalitem':"Click on the “Deposit” button at the end of the page to confirm your deposit.",
                },
                {
                    'id' : '5725a680b3249770ea31de99',
                    'mobalitem':"You will receive the deposit confirmation afterward.",
                },
            ], 
            'card_cardnumber':"#### #### #### ####",
            'card_date_head':"Expires",
            'card_date':"MM/YY",
            'card_cvv_head':"CVV",
            'card_cvv':"####",
            'p_tag_first':"Exclusive use for Credit Card only. Do not insert Credit Card details.",
            'p_tag_a':"Download",
            'p_tag_second':"Credit App to purchase an Credit card.",
            'card_number':"Card Number",
            'expiry_date':"Expiry Date",
            'cvv':"CVV",
            'deposit_amount':"Deposit Amount",
            'deposit_btn':"Deposit",
            'tableheading':"Credit Card Limit",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de01',
                    'tableth':"Minimum deposit per transaction", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de02',
                    'tableth':"Maximum deposit per transaction", 
                    'tabletd':"INR 99,000.00",
                },
            ],
            'tablenote':"NOTE: Deposits are subjected to a 1x wagering requirement.",
            'period':'Period',
            'startdate':'Start Date',
            'enddate':'End Date',
        },
        
    ],
    tl: [
        {
            'id' : '5725a680b3249770ea31de79',
            'title':"ఉపసంహరించుకోండి",
            'tagName': 'tab1',
            'mobalbtn':"నేను రూపాయి డిపాజిట్ ఎలా చేయాలి?",
            'mobalheading':"నేను డబ్బును ఎలా జమ చేయగలను?",
            'paybyoptions':[
                {
                    'id': "1,2,3,4,5,6,7",
                    'name': 'ఆల్ టైప్ డిపాజిట్',
                    'xs': 1
                },
                {
                    'id': "1",
                    'name': 'బ్యాంకుకు బాంట్',
                    'xs': 1
                },
                {
                    'id': "2",
                    'name': 'నగదు జమచేయు',
                    'xs': 1
                },
                {
                    'id': "4",
                    'name': 'పేటీఎం',
                    'xs': 1
                },
                {
                    'id': "5",
                    'name': 'ఫోన్ పే',
                    'xs': 1
                },
                {
                    'id': "6",
                    'name': 'గూగుల్ పే',
                    'xs': 1
                },
                {
                    'id': "7",
                    'name': 'ఇతర పే',
                    'xs': 1
                }
            ],
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"చివరి 7 రోజులు", 
                },
                { 
                    'id':"2", 
                    'periodoption':"చివరి 1 నెల",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"చివరి 6 నెలలు",
                },  
            ], 
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 1,
                    'field_name': 'అభ్యర్థన సంఖ్య',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'అభ్యర్థన రకం',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 1,
                    'field_name': 'పంపండి',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'నుండి పంపండి',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'మొత్తం',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': 'రిఫరెన్స్ ఐడి',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 2,
                    'field_name': 'అభ్యర్థించండి',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_8',
                    'xs': 1,
                    'field_name': 'స్థితి',
                    'visible': 'desktop',
                    'expandable': true
                },
            ],
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de80',
                    'mobalitem':"మీ ఐసోఫ్ట్ గేమింగ్ ఖాతా యొక్క 'డిపాజిట్' టాబ్‌లో, రూపాయిలను ఎంచుకోండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de81',
                    'mobalitem':"మీరు ఏ బ్యాంక్ నుండి బదిలీ చేయాలనుకుంటున్నారో మరియు దాని మొత్తాన్ని ఎంచుకోండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de82',
                    'mobalitem':"మీరు బ్యాంక్ పేజీకి మళ్ళించబడతారు. పేజీలోని సూచనలను అనుసరించండి మరియు లావాదేవీని పూర్తి చేయండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de83',
                    'mobalitem':"విజయవంతమైన లావాదేవీ తరువాత, మీ క్రెడిట్ 5 నిమిషాల్లో స్వయంచాలకంగా జోడించబడుతుంది.",
                },
                {
                    'id' : '5725a680b3249770ea31de84',
                    'mobalitem':"డిపాజిట్‌ను ప్రాసెస్ చేయడంలో జాప్యం జరగకుండా ఉండటానికి, అన్ని సమాచారం సరిగ్గా సమర్పించబడిందని నిర్ధారించుకోండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de85',
                    'mobalitem':"మీకు ఏవైనా ప్రశ్నలు ఉంటే కస్టమర్ సేవను సంప్రదించడానికి సంకోచించకండి.",
                }, 
            ], 
            'guidebtn':"రూపాయి గైడ్",
            'bankname': "మీ బ్యాంకును ఎంచుకోండి",
            'bankoptions':[
                {
                    'id' : '5725a680b3249770ea31de86',
                    'bankoptions':"ఎస్బిఐ",
                },
                {
                    'id' : '5725a680b3249770ea31de87',
                    'bankoptions':"HDFC",
                },
                {
                    'id' : '5725a680b3249770ea31de88',
                    'bankoptions':"ఐసిఐసిఐ",
                },
                {
                    'id' : '5725a680b3249770ea31de89',
                    'bankoptions':"పిఎన్‌బి",
                },
            ],
            'amountheading': "డిపాజిట్ చేయడానికి మొత్తాన్ని నమోదు చేయండి",
            'amountoption': "మీ మొత్తాన్ని నమోదు చేయండి",
            'depositbtn':"డిపాజిట్",
            'tableheading':"రూపాయి పరిమితి",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea31de90',
                    'tableth':"ప్రతి లావాదేవీకి కనీస డిపాజిట్", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea31de91',
                    'tableth':"ప్రతి లావాదేవీకి గరిష్ట డిపాజిట్", 
                    'tabletd':"INR 20,000.00",
                },
            ],
            'tablenote':"గమనిక: డిపాజిట్లు 1x పందెపు అవసరానికి లోబడి ఉంటాయి.",

            'top_text'  : 'మీ ఖాతా డెమో ప్రయోజనం మాత్రమే. చెల్లింపు ఉపసంహరణ / డిపాజిట్ చేయడానికి అధికారం లేదు', 
            'one_heading_text'  : 'అందుబాటులో ఉన్న బ్యాలెన్స్', 
            'one_box'  : 'మొత్తం అందుబాటులో ఉన్న బ్యాలెన్స్', 
            'two_box'  : 'మొత్తం ఉపసంహరించలేని బ్యాలెన్స్', 
            'three_box'  : 'చివరి డిపాజిట్ మొత్తం', 
            'four_box'  : 'చివరి డిపాజిట్పై పందెం', 
            'five_box'  : 'ఈ రోజు ఉపసంహరించుకోగల బ్యాలెన్స్', 
            'box_bottom_text_one'  : 'ఉపసంహరణ మొత్తం అప్పుడు ఎక్కువగా ఉండాలి', 
            'box_bottom_text_two'  : 'మరియు గరిష్టంగా', 
            'two_heading_text'  : 'ఉపసంహరణ అభ్యర్థన',
            'selectbankaccount'  : 'బ్యాంక్ ఖాతాను ఎంచుకోండి',
            'amount'  : 'మొత్తం',
            'confirm_amount'  : 'మొత్తాన్ని నిర్ధారించండి',
            'comment'  : 'వ్యాఖ్య', 
            'withdraw_btn'  : 'ఉపసంహరించుకోండి',
            'period'  : 'కాలం',
            'start_date'  : 'ప్రారంబపు తేది',
            'end_date'  : 'చివరి తేది',
        },
      
        {
            'id' : '5725a680b3249770ea31de92',
            'title':"స్థితిని ఉపసంహరించుకోండి",
            'tagName': 'tab2',
            'mobalbtn':"నేను క్రెడిట్ డిపాజిట్ ఎలా చేయగలను?",
            'mobalheading':"నేను క్రెడిట్ కార్డ్ డిపాజిట్ ఎలా చేయాలి?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de93',
                    'mobalitem':"మీ ఐసోఫ్ట్ గేమింగ్ ఖాతాకు లాగిన్ అవ్వండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de94',
                    'mobalitem':"మీ వినియోగదారు పేరుకు సమీపంలో ఉన్న డ్రాప్‌డౌన్ బాణం వద్ద “డిపాజిట్” బటన్‌ను క్లిక్ చేయండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de95',
                    'mobalitem':"డిపాజిట్ టాబ్ లోపల, మీకు ఇష్టమైన డిపాజిట్ పద్ధతిగా “క్రెడిట్ కార్డ్” ఎంచుకోండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de96',
                    'mobalitem':"కార్డ్ నంబర్, సివివి నంబర్ మరియు గడువు తేదీతో సహా మీ క్రెడిట్ కార్డ్ సమాచారాన్ని నమోదు చేయండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de97',
                    'mobalitem':"డిపాజిట్ మొత్తం ఖాళీ ఫీల్డ్‌లో మీకు కావలసిన మొత్తాన్ని చెల్లించండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de98',
                    'mobalitem':"మీ డిపాజిట్‌ను నిర్ధారించడానికి పేజీ చివర “డిపాజిట్” బటన్‌పై క్లిక్ చేయండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de99',
                    'mobalitem':"మీరు తరువాత డిపాజిట్ నిర్ధారణను అందుకుంటారు.",
                },
            ], 
            'card_cardnumber':"#### #### #### ####",
            'card_date_head':"ముగుస్తుంది",
            'card_date':"MM/YY",
            'card_cvv_head':"CVV",
            'card_cvv':"####",
            'p_tag_first':"క్రెడిట్ కార్డ్ కోసం మాత్రమే ప్రత్యేకమైన ఉపయోగం. క్రెడిట్ కార్డ్ వివరాలను చొప్పించవద్దు.",
            'p_tag_a':"డౌన్‌లోడ్",
            'p_tag_second':"క్రెడిట్ కార్డు కొనడానికి క్రెడిట్ అనువర్తనం.",
            'card_number':"కార్డు సంఖ్య",
            'expiry_date':"గడువు తీరు తేదీ",
            'cvv':"సివివి",
            'deposit_amount':"జమ చేయవలసిన రొక్కం",
            'deposit_btn':"డిపాజిట్",
            'tableheading':"క్రెడిట్ కార్డ్ పరిమితి",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de01',
                    'tableth':"ప్రతి లావాదేవీకి కనీస డిపాజిట్", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de02',
                    'tableth':"ప్రతి లావాదేవీకి గరిష్ట డిపాజిట్", 
                    'tabletd':"INR 99,000.00",
                },
            ],
            'tablenote':"గమనిక: డిపాజిట్లు 1x పందెపు అవసరానికి లోబడి ఉంటాయి.",
            'period':'కాలం',
            'startdate':'ప్రారంబపు తేది',
            'enddate':'చివరి తేది',
        },
        
    ],
    hi: [
        {
            'id' : '5725a680b3249770ea32de19',
            'title':"वापस ले लो",
            'tagName': 'tab1',
            'mobalbtn':"मैं रुपया जमा कैसे करूँ?",
            'mobalheading':"मैं रुपया कैसे जमा कर सकता हूं?",
            'paybyoptions':[
                {
                    'id': "1,2,3,4,5,6,7",
                    'name': 'All Type Deposit',
                    'xs': 1
                },
                {
                    'id': "1",
                    'name': 'Bant to Bank',
                    'xs': 1
                },
                {
                    'id': "2",
                    'name': 'Cash Deposit',
                    'xs': 1
                },
                {
                    'id': "4",
                    'name': 'PayTM',
                    'xs': 1
                },
                {
                    'id': "5",
                    'name': 'Phone Pay',
                    'xs': 1
                },
                {
                    'id': "6",
                    'name': 'Google Pay',
                    'xs': 1
                },
                {
                    'id': "7",
                    'name': 'Other Pay',
                    'xs': 1
                }
            ],
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"पिछले सात दिन", 
                },
                { 
                    'id':"2", 
                    'periodoption':"पिछले एक महीने से",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"पिछले 6 महीने",
                },  
            ],  
            'moballist':[
                {
                    'id' : '5725a680b3249770ea32de20',
                    'mobalitem':"अपने आइसॉफ्ट गेमिंग खाते के 'डिपॉजिट' टैब में, रुपे-ओ चुनें।",
                },
                {
                    'id' : '5725a680b3249770ea32de21',
                    'mobalitem':"आप किस बैंक से और उसकी राशि से ट्रांसफर करना चाहते हैं, उसे चुनें।",
                },
                {
                    'id' : '5725a680b3249770ea32de22',
                    'mobalitem':"आपको बैंक पृष्ठ पर पुनः निर्देशित किया जाएगा। पृष्ठ पर दिए गए निर्देशों का पालन करें और लेनदेन को पूरा करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de23',
                    'mobalitem':"एक सफल लेनदेन के बाद, आपका क्रेडिट 5 मिनट के भीतर स्वचालित रूप से जुड़ जाएगा।",
                },
                {
                    'id' : '5725a680b3249770ea32de24',
                    'mobalitem':"जमा को संसाधित करने में किसी भी देरी से बचने के लिए, कृपया सुनिश्चित करें कि सभी जानकारी सही ढंग से प्रस्तुत की गई है।",
                },
                {
                    'id' : '5725a680b3249770ea32de25',
                    'mobalitem':"यदि आपके कोई प्रश्न हैं, तो कृपया ग्राहक सेवा से संपर्क करें।",
                }
            ],
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 1,
                    'field_name': 'Request Number',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'Type of Request',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 1,
                    'field_name': 'Send On',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'Send From',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'Amount',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': 'Reference Id',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 2,
                    'field_name': 'Request On',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_8',
                    'xs': 1,
                    'field_name': 'Status',
                    'visible': 'desktop',
                    'expandable': true
                },
            ],
            'guidebtn':"रुपया गाइड",
            'bankname': "अपना बैंक चुनें",
            'bankoptions':[
                {
                    'id' : '5725a680b3249770ea32de26',
                    'bankoptions':"भारतीय स्टेट बैंक",
                },
                {
                    'id' : '5725a680b3249770ea32de27',
                    'bankoptions':"एचडीफसी",
                },
                {
                    'id' : '5725a680b3249770ea32de28',
                    'bankoptions':"आईसीआईसीआई",
                },
                {
                    'id' : '5725a680b3249770ea32de29',
                    'bankoptions':"पीएनबी",
                },
            ],
            'amountheading': "जमा करने के लिए राशि दर्ज करें",
            'amountoption': "अपनी राशि दर्ज करें",
            'depositbtn':"जमा",
            'tableheading':"रुपया सीमा",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de30',
                    'tableth':"प्रति लेनदेन न्यूनतम जमा", 
                    'tabletd':"रूपया 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de31',
                    'tableth':"प्रति लेनदेन अधिकतम जमा", 
                    'tabletd':"रूपया 20,000.00",
                },
            ],
            'tablenote':"नोट: जमाराशि 1x के लिए आवश्यक चेतावनी के अधीन हैं।",
            'top_text'  : 'आपका खाता केवल डेमो उद्देश्य है। भुगतान वापसी / जमा करने के लिए अधिकृत नहीं', 
            'one_heading_text'  : 'उपलब्ध शेष राशि', 
            'one_box'  : 'कुल उपलब्ध शेष', 
            'two_box'  : 'कुल गैर निकासी योग्य शेष राशि', 
            'three_box'  : 'अंतिम जमा राशि', 
            'four_box'  : 'अंतिम जमा पर दांव', 
            'five_box'  : 'आज शेष राशि की निकासी', 
            'box_bottom_text_one'  : 'निकासी की राशि ',
            'box_bottom_text_two'  : 'से अधिक होनी चाहिए और अधिकतम', 
            'two_heading_text'  : 'वापसी का निवेदन',
            'selectbankaccount'  : 'बैंक खाता चुनें',
            'amount'  : 'रकम',
            'confirm_amount'  : 'राशि की पुष्टि करें',
            'comment'  : 'टिप्पणी',
            'withdraw_btn'  : 'निकालना',
            'period'  : 'अवधि',
            'start_date'  : 'आरंभ करने की तिथि',
            'end_date'  : 'अंतिम तिथि',

        },
        {
            'id' : '5725a680b3249770ea32de32',
            'title':"वापसी की स्थिति",
            'tagName': 'tab2',
            'mobalbtn':"मैं क्रेडिट जमा कैसे करूँ?",
            'mobalheading':"मैं क्रेडिट कार्ड जमा कैसे करूँ?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea32de33',
                    'mobalitem':"अपने आइसॉफ्ट गेमिंग खाते में प्रवेश करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de34',
                    'mobalitem':"अपने उपयोगकर्ता नाम के पास ड्रॉपडाउन तीर पर 'जमा' बटन पर क्लिक करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de35',
                    'mobalitem':"जमा टैब के अंदर, अपनी पसंदीदा जमा पद्धति के रूप में 'क्रेडिट कार्ड' चुनें।",
                },
                {
                    'id' : '5725a680b3249770ea32de36',
                    'mobalitem':"कार्ड नंबर, सीवीवी नंबर और समाप्ति तिथि सहित अपनी क्रेडिट कार्ड जानकारी दर्ज करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de37',
                    'mobalitem':"जमा राशि के खाली क्षेत्र में अपनी वांछित राशि का भुगतान करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de38',
                    'mobalitem':"अपनी जमा राशि की पुष्टि करने के लिए पृष्ठ के अंत में 'जमा' बटन पर क्लिक करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de39',
                    'mobalitem':"आपको बाद में जमा पुष्टिकरण प्राप्त होगा।",
                }
            ],
            'card_cardnumber':"#### #### #### ####",
            'card_date_head':"समय-सीमा समाप्त",
            'card_date':"महीना/साल",
            'card_cvv_head':"सीवीवी",
            'card_cvv':"####",
            'p_tag_first':"केवल क्रेडिट कार्ड के लिए विशेष उपयोग। क्रेडिट कार्ड का विवरण न डालें।",
            'p_tag_a':"डाउनलोड",
            'p_tag_second':"क्रेडिट ऐप क्रेडिट कार्ड खरीदने के लिए।",
            'card_number':"कार्ड नंबर",
            'expiry_date':"समाप्ति तिथि",
            'cvv':"सीवीवी",
            'deposit_amount':"जमा राशि",
            'deposit_btn':"जमा",
            'tableheading':"क्रेडिट कार्ड की सीमा",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de40',
                    'tableth':"प्रति लेनदेन न्यूनतम जमा", 
                    'tabletd':"रूपया 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de41',
                    'tableth':"प्रति लेनदेन अधिकतम जमा", 
                    'tabletd':"रूपया 99,000.00",
                },
            ],
            'tablenote':"नोट: जमाराशि 1x के लिए आवश्यक चेतावनी के अधीन हैं।",
            'period':'अवधि',
            'startdate':'आरंभ करने की तिथि',
            'enddate':'अंतिम तिथि',
        },
        
    ]
}
export default depositDb;

import { container } from "../../casino-kit-react.js";
import Background from "../../img/creditcard.png";
import Background3 from "../../img/radio.png";
import Background4 from "../../img/radioi.png";
import Background5 from "../../img/radio.png";
import Background6 from "../../img/radioi.png";
const tabsStyle = {
    signinnerpagebox:{
        background:"#000",
        padding:"0px 0px 0px",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            padding:"0px 0px 0px",
          },
    },
    paymenttypewrap:{
      "& svg":{
        color:"#efde1d"
      },
      "& label":{
        width: "50px",
        float: "left",
        display: "block",
      }
    },
    paymenttypeimg:{
      width: "160px",
      float: "left",
      marginTop: "-34px",
      marginLeft: "50px",
    },
    paymenttypeimgg:{
      float: "left",
      width: "130px",
      marginTop: "27px",
      marginLeft: "0px",
    },
    signinnerpageboxin:{
        background:"#000",
        margin:"0px 0px 0px",
        borderRadius:"10px"
    },
    spaceform:{
      "& div":{
        marginBottom: "5px"
      }
    },
    depositlistwarp:{
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            overflow:"auto",  
            width:"100%",
          }, 
    },
    depositlistw:{
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"1000px",
          }, 
    },
    tablewarapbox:{
        background:"#131212",
        borderRadius:"10px",
        padding:"30px 10px 0px",
        margin:"0px 0px 50px",
        "& form":{
            margin:"0px",
            padding:"0px 10px 0px 20px",
        },
        "& form div:before":{
            content: " ",
            position: "inherit !important",
        },
        "& .selectbox":{
            margin:"0px",
            ['@media (max-width:960px)']: { // eslint-disable-line no-useless-computed-key
                marginBottom:"25px",
            },
            ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            background:"none"
            },
        },
        "& .selectbox .MuiSelect-select:focus":{
            background:"none"
        }
    },
    alert:{
        margin: "0px 0px 0px",
        background:"#ffb80c",
        fontSize: "16px",
        fontFamily: "ubuntu",
        color:"#333",
        padding: "10px 15px",
        borderRadius: "5px",
        clear: "both",
    },
    messagebox:{
        background: "#252f5a",
        padding:"30px 0px",
        borderRadius:"10px",
    }, 
    list:{
      width:"100%",
      display:"block"
    },
    listItem:{
        width:"100%",
        paddingTop:"15px",
        paddingBottom:"15px",
        display:"block",
        color:"#a1aed4",
        fontFamily:"ubuntu",
        fontSize:"18px",
        "& a":{
            margin:"0px",
        },
        "& p":{
            margin:"0px",
        },
        "&:nth-child(even)":{
            background:"#364275"
        },
        "&:nth-child(odd)":{
            background:"none"
        }
    },
    section: {
        background: "#EEEEEE",
        padding: "70px 0"
    },
    tableresponsive:{
        overflow:"auto",
        width:"100%",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            "& table":{
                width:"300px"
            },
        },
        ['@media (max-width:560px)']: { // eslint-disable-line no-useless-computed-key
            "& table":{
                width:"100%"
            },
        },
    },
    ewalletp:{
        color: "#a1aed4",
        fontSize: "18px",
        textAlign: "left",
        fontFamily: "ubuntu",
    },
    ewalletaccordion:{
        "& .accordion":{
            border:"0px",
        }
    },
    accordianiitm:{
        background: "#0b122e !important",
        border: "none",
        borderRadius: "30px",
        marginTop: "15px",
    },
    accordianbtn: {
        "& div":{
            padding: "20px 30px",
            display: "flex",
            alignItems: "center",
            textAlign: "left",
            color: "#fff",
            fontSize: "18px",
            fontWeight: "600",
            outline: "none",
            textTransform: "uppercase",
            background: "#0b122e",
            fontFamily: "Ubuntu",
            position: "relative",
            borderRadius: "20px",
            width: "100%",
            ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
                fontSize: "16px",
                padding: "10px 17% 10px 10px",
                width: "80%",
            },
        },
        "& div::before":{
            position: "absolute",
            right: "20px",
        },
        "& div:hover":{
            background: "#1c294e",
        },
    },
    ewalletpaymentbox:{
      textAlign:"left",
      padding:"30px 0px 30px"
    },
    ewalletpaymentboxlabel:{
        padding:"0px",
        margin:"0px",
        color: "#fff",
        lineHeight: "50px",
    },
    selectboxx:{ 
        border:"0px",
        width:"100%",
        fontSize: "16px",
        fontFamily: "ubuntu",
        padding: "11px 0 11px",
        borderBottom:"1px solid #bdbdbd",
        color:"#fff", 
        background:"none",
        marginBottom:"20px",
        "&.labelactive label":{
          transform: "translate(0px, -6px) scale(0.75)",
        },
        "& input":{
            color:"#fff !important",
            fontSize:"8px",
            fontFamily:"ubuntu", 
            paddingLeft:"0px",
            paddingTop:"0px",
            height: "28px",
            marginTop:"24px",
            paddingBottom: "0px",
        },
        "& label":{
            color:"#fff !important", 
            fontFamily:"ubuntu", 
            marginTop:"-3px",
            fontSize:"16px",
            transform: "translate(0px, -3px) scale(1)",
        },
        "& option":{
          color:"#000",
        },
        "& div div":{
          color: "#fff",
          fontSize: "14px",
          fontFamily: "ubuntu",
          padding:"0px",
        }
      },
      formtodate:{
        color:"#fff !important",
        fontFamily:"ubuntu",
        fontSize:"18px",
        paddingLeft: "0",
        marginBottom: "20px",
        "& input":{
            color:"#fff !important",
            fontSize:"14px",
            fontFamily:"ubuntu", 
            paddingLeft:"0px",
            paddingTop:"0px",
            height: "30px",
            paddingBottom: "5px",
        },
        "& label":{
            color:"#fff !important", 
            fontFamily:"ubuntu", 
            transform: "translate(0px, -3px) scale(1)",
        },
        "& div:hover fieldset":{
            borderColor:"#fff !important",
        },
        "& fieldset":{
            color:"#fff !important",
            border:"0px",
            fontFamily:"ubuntu",
            borderBottom:"1px solid #fff",
            borderColor:"#fff !important",
            borderRadius:"0px",
        },
    },
      cd:{
        display:"none",
      }, 
      designimg:{
        height:"40px",
        width:"auto",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            height:"26px",
            paddingLeft:"40px",
          },
      },
      radiobtn1:{
        fontSize:"18px",
        color:"#fff",
        fontFamily:"ubuntu",
        backgroundImage:"url(" + Background3 + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left center",
        backgroundSize:"26px",
        padding:"20px 0px 20px 40px",
        "&:hover":{
          backgroundImage:"url(" + Background4 + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left center",
        },
        "&.radiobtn":{
          backgroundImage:"url(" + Background4 + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left center",
        }
      },
      radiobtn2:{
        fontSize:"18px",
        color:"#fff",
        fontFamily:"ubuntu",
        backgroundImage:"url(" + Background5 + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left center",
        backgroundSize:"26px",
        padding:"20px 0px 20px 40px",
        "&:hover":{
          backgroundImage:"url(" + Background6 + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left center",
        },
        "&.radiobtn":{
          backgroundImage:"url(" + Background6 + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left center",
        }
      },
      radiobtn3:{
        fontSize:"18px",
        color:"#fff",
        fontFamily:"ubuntu",
        backgroundImage:"url(" + Background5 + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left center",
        backgroundSize:"26px",
        padding:"20px 0px 20px 40px",
        "&:hover":{
          backgroundImage:"url(" + Background6 + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left center",
        },
        "&.radiobtn":{
          backgroundImage:"url(" + Background6 + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left center",
        }
      },
      radiobtn4:{
        fontSize:"18px",
        color:"#fff",
        fontFamily:"ubuntu",
        backgroundImage:"url(" + Background5 + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left center",
        backgroundSize:"26px",
        padding:"20px 0px 20px 40px",
        "&:hover":{
          backgroundImage:"url(" + Background6 + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left center",
        },
        "&.radiobtn":{
          backgroundImage:"url(" + Background6 + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left center",
        }
      },
      radiobtn5:{
        fontSize:"18px",
        color:"#fff",
        fontFamily:"ubuntu",
        backgroundImage:"url(" + Background5 + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left center",
        backgroundSize:"26px",
        padding:"20px 0px 20px 40px",
        "&:hover":{
          backgroundImage:"url(" + Background6 + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left center",
        },
        "&.radiobtn":{
          backgroundImage:"url(" + Background6 + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left center",
        }
      },
    depositform:{
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"100%",
        },
        "& input":{
          background:"none",
          color:"#fff",
            width:"100%",
            fontSize: "14px", 
            borderBottom:"1px solid #bdbdbd",
            marginBottom:"10px",
        },
        "& label":{
            color:"#bdbdbd",
            fontSize:"14px",
            fontFamily:"ubuntu",
            transform: "translate(0px, 20px) scale(1)",
        },
        "& .MuiFormLabel-root.Mui-focused":{
            color:"#bdbdbd",
        },
        "& div":{
          width:"100%",
          ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            marginLeft:"0px",
            marginRight:"0px",
            paddingLeft:"0px",
            paddingRight:"0px"
            },
        },
        "& fieldset":{
            color:"#fff", 
            border:"0px",
          },
        "& select":{
            background:"none",
            marginBottom:"20px",
            lineHeight:"40px",
            height:"40px",
            width:"100%",
            border:"0px",
            fontSize: "16px",
            borderBottom:"1px solid #a1aed4"
        },
        "& .selectbox":{
            background:"none"
        }
    },
    depositbtn:{
        color:"#fff !important",
        float: "left",
        border: "0px",
        cursor: "pointer",
        margin: "10px auto 0px",
        display: "block",
        padding: "7px 25px",
        fontSize: "14px",
        boxShadow: "0px 0px 0px",
        transition: "all 0.3s ease-in",
        fontWeight: "600",
        lineHeight: "30px",
        borderRadius: "3px",
        textTransform: "uppercase",
        backgroundImage: "#ff3342 !important",
        "& span":{
            color: "#fff !important",
        },
        "&:hover":{
          background: "#ff3342 !important",
          color:"#fff"
        },
        "&:focus":{
          background: "#ff3342 !important",
          color:"#fff"
        },
    },
    paymentbtn:{
        color: "#fff !important",
        width: "unset !important",
        border: "0 !important",
        height: "unset !important",
        margin: "0px 5px 10px",
        padding: "0px 50px",
        fontSize: "18px",
        maxWidth: "unset !important",
        maxHeight: "unset !important",
        fontFamily: "Open Sans",
        fontWeight: "600",
        lineHeight: "50px",
        marginLeft: "4px",
        borderRadius: "50px",
        textTransform: "uppercase",
        backgroundImage: "linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07) !important",
        "&:hover":{
            background:"#ff344d",
            color:"#fff",
        },
        '&:visited': {
            background: "linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07) !important",
            color: "rgb(120,50,5) !important",
        },
        '&:focus': {
            background: "linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07) !important",
            color: "rgb(120,50,5) !important",
        },
    },
    paymentbtnactive:{
        color: "#fff !important",
        width: "unset !important",
        border: "0 !important",
        height: "unset !important",
        margin: "0px 5px 10px",
        padding: "0px 50px",
        fontSize: "18px",
        maxWidth: "unset !important",
        maxHeight: "unset !important",
        fontFamily: "Open Sans",
        fontWeight: "600",
        lineHeight: "50px",
        marginLeft: "4px",
        borderRadius: "50px",
        textTransform: "uppercase",
        backgroundColor: "#ff344d",
        "&:hover":{
            background:"#ff344d",
            color:"#fff",
        }
    },
    modalHeader:{
        background: "#252f5a",
        color:"#fff",
        fontFamily: "Open Sans",
        fontSize: "20px",
        lineHeight: "46px",
        paddingBottom: "0",
    },
    modalCloseButton:{
      float:"right",
      color:"#fff",
    },
    modalBody:{
        background: "#252f5a",
        color:"#fff",
        fontFamily: "Open Sans",
    },
    ewallettotalamount:{
        color:"#fff",
        fontSize:"32px",
        margin:"0px"
    },
    customamount:{
      border:"0px",
      display:"block",
      width:"280px",
      borderBottom:"1px solid #fff",
      background:"none",
      lineHeight:"40px",
      color:"#fff",
      margin:"30px 0px 30px",
    },
    accordianp:{
        marginTop: "10px", 
        padding: "20px 50px",
        fontFamily: "Open Sans",
        fontSize: "18px",
        color: "#a1aed4",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            padding: "10px 20px 20px 20px",
            fontSize: "16px",
        },
    },
    dwonlbtn:{
      background:"none",
      padding:"0px",
      color:"#ff344d",
    },
    rupeemainbox:{
        background: "#252f5a",
        padding:"10px 30px 20px",
        borderRadius:"10px",
        "& h3":{
            color:"#f44336",
            fontSize:"22px",
            fontFamily: "ubuntu",
        }
    },
    dangerText:{
        color:"#ff0000",
        padding:"0px",
        fontFamily: "ubuntu",
        margin:"0px"
    },
    tablerupee:{
        width: "500px", 
        fontFamily: "ubuntu",
        textAlign:"left",
        fontSize:"18px",
        color:"#a1aed4",
        "& tr":{
          border:"1px solid #a1a2b1",
        },
        "& tr td":{
            borderRight:"1px solid #a1a2b1",
            borderBottom:"1px solid #a1a2b1",
            padding:"10px 10px",
        },
        "& tr td:last-child" :{
            borderRight:"0px",
        },
        "& tr:last-child td":{
            borderBottom:"0px",
        },
    },
    grid:{
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            padding:"0px !important",
            width:"100%"
        },
    },
    depositfully:{
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            padding:"0px 4% !important",
            width:"92%"
        },
    },
    depositrupeeform:{
        width: "440px",
        margin: "20px auto 0px",
        background: "#0b122e",
        borderRadius: "10px",
        padding: "30px",
        textAlign:"left",
        fontFamily: "ubuntu",
        color:"#fff",
        fontSize:"16px",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"84%",
        },
        ['@media (max-width:560px)']: { // eslint-disable-line no-useless-computed-key
            width:"100%",
        },
        "& select":{
          background:"none",
          border:"0px",
        lineHeight:"40px",
            height:"40px",
        marginBottom: "20px",
        borderBottom:"1px solid #a1aed4",
            color:"#fff",
          width:"100%",
        },
        "& select option":{
           color:"#000",
           lineHeight:"40px",
           display:"block"
        },
        "& input":{
            background:"none",
            color:"#fff",
            border:"0px",
            lineHeight:"40px",
            marginBottom: "20px",
            borderBottom:"1px solid #a1aed4",
            width:"100%",
        },
    }, 
    rupeeguide:{
        textAlign: "right",
    },
    qutionbtn:{
        padding: "0",
        textTransform: "capitalize",
        fontSize: "16px",
        color: "#fff !important",
        fontFamily: "ubuntu",
        display: "block",
        background:"none",
        boxShadow:"0px 0px 0px",
        textAlign: "right",
        "&:focus":{
            background:"none",
        },
        "&:hover":{
            background:"none",
        },
        "&:visited":{
            background:"none",
        },
        "& i":{
            color:"#f7314a",
        },
    },
    rupeeguidebtn:{
        background: "#f7314a",
        color: "#fff",
        fontFamily: "ubuntu",
        textTransform: "capitalize",
        fontSize: "18px",
        padding: "8px 20px",
    },
    roundCardHeader:{
        border:"0px",
    },

    singlegame:{
        height:"350px",
        display:"block",
        lineHeight:"300px",
        textAlign:"center",
        marginBottom:"40px",
        fontFamily: "Ubuntu",
        border:"1px solid #273b71",
        borderRadius:"10px",
        boxShadow:"0px 10px 15px 0px rgba(0, 0, 0, 0.4)",
        position:"relative",
        transition:"all 0.3s ease-in",
        '& img': {
            marginTop:"-25px",
            verticalAlign: "middle",
            animation:"opacityAnimation 2s linear infinite",
        },
        '&:hover': {
            borderColor:"#681f40",
            transform:"translateY(-10px)",
        },
    },
    mybtn2:{
        position:"absolute",
        display:"block",
        height:"50px",
        bottom:"-25px",
        left:"50%",
        fontFamily: 'Open Sans',
        transform:"translateX(-50%)",
        boxShadow:"0px 10px 15px 0px rgba(0, 0, 0, 0.4)",
        fontSize: "16px",
        textTransform: "uppercase",
        fontWeight: "600",
        lineHeight: "28px",
        width: "190px",
        color: "#fff",
        border: "0px",
        borderRadius: "50px",
        cursor: "pointer",
        transition: "all 0.3s ease-in",
        background: "linear-gradient(to left, #1c294e, #1c294e)",
        '&:hover': {
            background: "linear-gradient(to right, #ff344d, #d2273c)",
            color: "#fff",
        },
        '&:visited': {
            background: "linear-gradient(to right, #ff344d, #d2273c)",
            color: "#fff",
        },
    },
    creditcardnumber:{
        background:"none",
        width:"100%",
        color:"#fff",
        marginTop:"60px",
        '& p': {
            height:"40px !important"
        },
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            marginBottom: "0px",
        },
    },
    creditcarddate:{
        color:"#fff",
        fontSize: "18px",
        '& p': {
            height:"40px !important"
        },
    },
    creditcardcvv:{
        color:"#fff",
        fontSize: "18px",
        '& p': {
            height:"40px !important"
        },
    },
    creditcardimg:{ 
        background: "url(" + Background + ")",
        width: "100%",
        backgroundSize: "100%",
        minHeight: "193px",
        backgroundRepeat: "no-repeat",
        marginTop:"20px",
        padding: "100px 40px 40px 40px",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            top: "-180px",
            width: "84%",
            padding: "30px 40px 40px 40px",
            position: "absolute",
            background: "url(" + Background + ")",
            minHeight: "193px",
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
            left: "-7%",
        },
        ['@media (max-width:500px)']: { // eslint-disable-line no-useless-computed-key
            top: "auto",
            width: "100%",
            padding: "30px 40px 40px 40px",
            position: "inherit",
            background: "url(" + Background + ")",
            minHeight: "193px",
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
            left: "auto",
        },
    },
    creditcardform:{
        width: "100%",
        margin: "20px auto 0px",
        background: "#0b122e",
        borderRadius: "10px",
        padding: "30px 30px 30px 30px",
        textAlign:"left",
        position:"relative",
        fontFamily: "ubuntu",
        color:"#fff",
        fontSize:"16px",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"84%",
            padding:" 60px 30px 30px 30px",
        },
        ['@media (max-width:500px)']: { // eslint-disable-line no-useless-computed-key
            width:"100%",
            padding:" 60px 30px 30px 30px",
        },
        "& select":{
            background:"none",
            border:"0px",
            lineHeight:"40px",
            height:"40px",
            marginBottom: "20px",
            borderBottom:"1px solid #a1aed4",
            color:"#fff",
            width:"100%",
        },
        "& input":{
            background:"none",
            color:"#fff",
            border:"0px",
            lineHeight:"40px",
            marginBottom: "20px",
            borderBottom:"1px solid #a1aed4",
            width:"100%",
            fontFamily:"ubuntu",
            fontSize:"16px",
        },
        "& div":{
            width:"100%",
        },
        "& fieldset":{
            background:"none",
            color:"#fff", 
            border:"0px",
            width:"100%",
        },
    },
    container,
    textCenter: {
        textAlign: "center"
    },
    

  custom_fileu:{
    opacity:"0",
    width:"0px",
  },
  custom_fileimg:{
    width: "auto",
    height: "100%",
  },
  field:{
    margin: "5px",
    display: "flex",
    flexDirection: "column",
    "& input[type='file']": {
      display: "none",
    }
  },
  custom_file_upload: { 
    display: "inline-block",
    position: "relative",
    padding: "6px",
    cursor: "pointer",
    background: "#000",
    borderRadius: "10px",
    marginBottom: "0px",
    width: "100%",
    textAlign: "center",
    height: "100px",
  },
  
  img_wrap:{
    height: "90px",
    overflow: "hidden",
    position: "relative",
    margin:"0px auto",
    borderRadius: "10px",
    width: "auto !important",
  },
  img_upload:{
    "&:before":{
    content: "\f093",
    fontSize: "90px",
    position: "absolute",
    paddingTop: "80px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    color: "#63d3a6",
    width: "200px",
    height: "200px",
    borderRadius: "50%",
    opacity: "0",
    transition: ".5s ease",
    backgroundColor: "#fff",
  }
  },  
  thirdwrap:{
    background:"#353535",
    padding:"10px",
    ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
      padding: "5px 1px 1px",
    },
  },
  thirdbox:{
    background:"#1e1e1e" ,
    padding:"10px",
    "&.instepselect":{
      backgroundColor: "#ff0000",
    }
  },  
  makedeposit:{
    "& h6":{
      fontFamily:"ubuntu",
      fontSize:"12px",
      paddingBottom:"10px",
      color:"#ccc",
      margin:"0"
    }, 
    "& table":{
      whiteSpace: "nowrap",
      width:"100%",
      fontFamily:"ubuntu",
      color:"#ccc",
      fontSize:"12px",
      
    },
    "& table tr th":{
      padding:"10px",
      color:"#fff",
      background:"#0f0f0f",
    },
    "& table tr td":{
      padding:"10px",
      color:"#fff",
      background:"#2b2b2b",
    }
  },
  timeout:{
    color:"#ccc"
  },
  historybox:{
    padding:"10px",
    ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
      padding: "5px 1px 1px",
    },
    },
    norecordimg:{
      ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
        margin: "0px !important",
        height: "110px",
      },
    },
betsbox:{
padding:"10px"
},
bonusbox:{
padding:"10px"
},
balancehistory:{
color:"#ccc",
fontSize:"12px",
fontFamily:"ubuntu",
"& table":{
  width:"100%"
},
"& table td":{
  padding:"10px",
  "& table p":{
    textAlign:"center"
  }
}
},

bonusbalancewrap:{
fontFamily:"ubuntu",
color:"#fff",
opacity:"0.4",
borderBottom:"5px solid #efde1d",
"& h5":{
  margin:"0px",
  padding:"0px",
  fontSize:"18px",
  lineHeight:"20px",
  fontWeight:"bold",
},
"& p":{
  margin:"0px",
  fontSize:"12px",
  padding:"0px",
  textTransform:"uppercase",
  color:"#ccc",
},
},
bonusmainbalance:{
fontFamily:"ubuntu",
color:"#fff",
opacity:"0.4",
textAlign:"right",
"& h4":{
  fontSize:"24px",
  margin:"0px",
  fontWeight:"bold",
  lineHeight:"26px",
  padding:"0px",
},
"& p":{
  margin:"0px",
  fontSize:"12px",
  padding:"0px",
  color:"#ccc",
},
},
activebonus:{ 
textAlign:"right",
"& h5":{
  color:"#efde1d",
  fontWeight:"bold",
},
"& p":{
  color:"#ccc",
}
},
balancebonus:{
color:"#ccc",
marginTop:"20px",
fontSize:"12px",
fontFamily:"ubuntu", 
width:"100%",
"& td":{
  padding:"10px",
  background:"#2b2b2b",
  "& p":{
    textAlign:"left",
    fontSize:"12px"
  }
},
"& tr:nth-child(odd) td":{
  background: "#000",
}
},
balancebonusinner:{
width:"100%",
"& tr th":{
  borderBottom:"1px solid #ccc"
}, 
},
bonusbalancewrapactive:{
opacity:"1"
},
bonusmainbalanceactive:{
opacity:"1"
},
tableresponsie:{
  overflow:"auto",
},



tableresponsi:{
['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
  overflow: "auto",
},
},
table:{
boxShadow:" 0px 10px 40px 0px rgba(0, 0, 0, 0.4)",
position: "relative",
width: "100%",
fontFamily: 'Open Sans',
textAlign: "left",
marginBottom: "1rem",
color: "#212529",
'& tr:hover': {
  background:"#192246",
},
'& td': {
  borderTop:"1px solid rgba(255, 255, 255, 0.09)",
  border:"0px",
  verticalAlign:"middle",
  padding:"20px 15px",
  fontWeight:"600",
  fontSize:"18px",
  textTransform:"uppercase",
  color:"#a1aed4",
},
'& th': {
  borderTop:"1px solid rgba(255, 255, 255, 0.09)",
  border:"0px",
  fontSize:"18px",
  fontWeight:"600",
  textTransform:"uppercase",
  borderBottom:"0px",
  padding:"20px 15px",
  background:"#0b122e",
  color:"#fff",
},
'& img': {
  width:"40px",
  height:"40px",
  borderRadius:"50%",
  verticalAlign:"middle",
  display:"inline-block"
},
'& td:nth-child(1) img': {
  marginRight:"5px",
},
'& td:nth-child(3) img': {
  marginRight:"5px",
},
'& td:nth-child(6) img': {
  marginRight:"5px",
},
'& td:nth-child(1)': {
  color:"#fff",
},
'& td:nth-child(3)': {
   color:"#045cf0",
},
'& td:nth-child(5)': {
  color:"#f0ad4e",
},
'& td:nth-child(6)': {
  color:"#f0ad4e",
},
'& td:last-child': {
  color:"#f0ad4e",
},
},
};

export default tabsStyle;

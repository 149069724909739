import React, { useState } from "react";
import 'react-accessible-accordion/dist/fancy-example.css';

import { makeStyles } from "@material-ui/core/styles"; 
import styles from "../assets/jss/components/tabsStatementstyle.js"; 
import Uploadkycc from "../sections/Uploadkycc";
import Dlimg from "../sections/Dlimg"; 
import kycmoduleDb from "../data/kycmodule.js";
import { Button  } from '@material-ui/core'; 
import * as Gurl from "../../../global/Global.js"
import axios from 'axios';   

export default function KycModule(props) {
    const { ...rest } = props;
   
    const [kycData, setKycData] = React.useState("NOVALUE" );
    const [dlData, setDlData] = useState("NOVALUE");
    const [panData, setPanData] = useState("NOVALUE");
    const [adharData, setAadharData] = useState("NOVALUE");
    const [imagePopup, setImagePopup] = useState("");
    const [reloadKyc, setReloadKyc] = React.useState(false);

     
    function dlfront(image) { 
        setImagePopup(image);
        document.body
        .getElementsByClassName("imagePopup")[0]
        .setAttribute("style", "display:block;"); 
    }
    function dlfrontclose() { 
        document.body
        .getElementsByClassName("imagePopup")[0]
        .setAttribute("style", "display:none;"); 
    }
 

    if(props.themeColors != null){ 
        styles.actionbtn.background = props.themeColors.bgColors.main_color; 
        styles.actionbtn.color = props.themeColors.bgColors.text_primary_dark_color;   
        styles.actionbtn["& span"].color = props.themeColors.bgColors.text_primary_dark_color;   
        styles.actionbtn["& span i"].color = props.themeColors.bgColors.text_primary_dark_color;   
        styles.verifyaccounttable["& tbody tr"].background = props.themeColors.bgColors.secondary_bgcolor;
        styles.verifyaccounttable["& tbody tr:hover"].background = props.themeColors.bgColors.primary_light_bgcolor;
        styles.verifyaccounttable["& tr th"].color = props.themeColors.bgColors.text_primary_color;
        styles.verifyaccounttable["& tbody tr td"].color = props.themeColors.bgColors.text_primary_color;

        // styles.signinmodalbtnkyc.fontFamily = props.themeColors.bgColors.base_font; 
        styles.formgroupwidthlabel["& label"].fontFamily = props.themeColors.bgColors.base_font;  
        styles.verifyaccounttable["& table"].fontFamily = props.themeColors.bgColors.base_font; 
       } 
       const useStyles = makeStyles(styles);
    
       const classes = useStyles();
    function getKYCData(){
        if((kycData === null ||  kycData === "NOVALUE" || reloadKyc === true)){
            const request = axios.post(Gurl.KYCINFO, {
                token: window.localStorage.getItem('jwt_access_token')
            });
            
            request.then((response) => {
                if (response.data.data != null) {
                    setKycData(response.data.data);
                    if(Array.isArray(response.data.data)){
                        const dlResponse = response.data.data.filter(kyc => kyc.name == 'id_proof');
                        if (dlResponse.length > 0) {
                            setDlData(dlResponse[0]);
                        }
                        const panResponse = response.data.data.filter(kyc => kyc.name == 'pan');
                        if (panResponse.length > 0) {
                            setPanData(panResponse[0]);
                        }
                        const adharResponse = response.data.data.filter(kyc => kyc.name == 'aadharcard');
                        if (adharResponse.length > 0) {
                            setAadharData(adharResponse[0]);
                        }
                    }else{
                        if (response.data.data.name == 'id_proof') {
                            setDlData(response.data.data);
                        }
                        if (response.data.data.name == 'pan') {
                            setPanData(response.data.data);
                        }
                        //const adharResponse = response.data.filter(kyc => kyc.name == 'aadharcard');
                        if (response.data.data.name == 'aadharcard') {
                            setAadharData(response.data.data);
                        }
                    }
                    
                } else {
                    setKycData("NOVALUE");
                }
                setReloadKyc(false);
            });
            request.catch(() => {
                setKycData("NOVALUE");
                setReloadKyc(false);
            });
            
        }
    }
    
    if(kycData === "NOVALUE"){
        getKYCData();
    }

    return (
        <div className={classes.moduleboxwrap}>
 
                <Uploadkycc kycData={adharData, dlData, panData} getKYCData={getKYCData} setReloadKyc={setReloadKyc} themeColors={props.themeColors} {...rest}/>
                
                <div className={classes.verifyaccounttable}>
                    <table border="0">
                        {kycmoduleDb[sessionStorage.getItem('lang')].map((jsondata) => (
                        <thead>
                        <tr>
                            <th>{jsondata.doctype}</th>
                            <th>{jsondata.uploaddate}</th> 
                            <th>{jsondata.images}</th>
                            <th>{jsondata.status}</th>
                        </tr>
                        </thead>
                    ))}
                        
                        {(() => { 
                            
            if( kycData != null && kycData !== "NOVALUE" ){
                if(Array.isArray(kycData)){
                    return ( <tbody>
                        {kycData.map((jsondata) => (
                            <tr> 
                                <td><span><i className="fa fa-file"></i></span>{jsondata.name}</td> 
                                <td> 
                                {(new Date(jsondata.created_on)).toLocaleDateString('fr-CA',{year:"numeric",month:"2-digit", day:"2-digit"})}
                                </td>
                                <td>
                                {(()=>{
                                    if(jsondata.front_scan != null){
                                    return( 
                                        <Button
                                            color="primary" 
                                            className={classes.actionbtn + " " + classes.mobiactionbtn} 
                                            href="#/"
                                            onClick={() => dlfront(jsondata.front_scan)}
                                        >
                                            <i className="fa fa-eye"></i> Front 
                                        </Button>  
                                        )
                                    }
                                })()}      
                                {(()=>{
                                    if(jsondata.back_scan != null){
                                    return( 
                                        <Button
                                            color="primary" 
                                            className={classes.actionbtn} 
                                            href="#/"
                                            onClick={() => dlfront(jsondata.back_scan)}
                                        >
                                            <i className="fa fa-eye"></i> Back 
                                        </Button>  
                                        )
                                    }
                                })()}         
                                </td>
                                
                                <td>
                                {(()=>{
                                if(jsondata.status == 0){
                                    return(
                                        <span className={classes.verifycolor}>Not Verified</span>
                                    )
                                }else{
                                    return(
                                    <span className={classes.verifyedcolor}>Verified</span>
                                    )
                                    }
                                })()}
                                </td>
                            </tr>
                        ))}
                    </tbody>);
                }else{
                    return ( 
                        <tbody>
                        <tr> 
                            <td><span><i className="fa fa-file"></i></span>{kycData.name}</td> 
                            <td> 
                            {(new Date(kycData.created_on)).toLocaleDateString('fr-CA',{year:"numeric",month:"2-digit", day:"2-digit"})}
                            </td>
                             
                            <td>
                            {(()=>{
                                if(kycData.front_scan != null){
                                  return( 
                                    <Button
                                        color="primary" 
                                        className={classes.actionbtn + " " + classes.mobiactionbtn} 
                                        href="#/"
                                        onClick={() => dlfront(kycData.front_scan)}
                                    >
                                        <i className="fa fa-eye"></i> Front 
                                    </Button>  
                                    )
                                }
                            })()}      
                             {(()=>{
                                if(kycData.back_scan != null){
                                  return( 
                                    <Button
                                        color="primary" 
                                        className={classes.actionbtn} 
                                        href="#/"
                                        onClick={() => dlfront(kycData.back_scan)}
                                    >
                                        <i className="fa fa-eye"></i> Back 
                                    </Button>  
                                    )
                                }
                            })()} 
                            </td>
                                      
                                
                                
                            <td>
                            {(()=>{
                            if(kycData.status === 0){
                                return(
                                    <span className={classes.verifycolor}>Not Verified</span>
                                )
                            }else{
                                return(
                                <span className={classes.verifyedcolor}>Verified</span>
                                )
                                }
                            })()}
                            </td>
                        </tr>
                        </tbody>
                 );
                }
            } else{
                return(
                    <tbody>
                        <tr>
                            <td colspan="4">
                            No Record Found
                            </td>
                        </tr>
                    </tbody>
                )
            }
            })()}

 
                         
                    </table>
                    
                    <div className={classes.popupimg + " imagePopup"} onClick={dlfrontclose}>
                        <i className={"fa fa-times " + classes.closeimg}></i><img src={imagePopup} height="300px" alt=""/>
                    </div>
                     
                </div>
                                    

        </div>
    );
}

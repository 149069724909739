import * as UserActions from './user.actions';
//import * as Actions from '../../../src/store/actions';
import jwtService from '../../../services/jwtService';

export const WLRESENDOTP_ERROR = 'WLRESENDOTP_ERROR';
export const WLRESENDOTP_SUCCESS = 'WLRESENDOTP_SUCCESS';
 

export function resendOtp(userId)
{
    let userData = false;
    function parseJwt(token) {
        if (!token) { return; }
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        var userDatat = JSON.parse(window.atob(base64));
        return userDatat;
    }
    
    if(userData === false){
        userData = parseJwt(window.localStorage.getItem("jwt_theme_token"));

    }  
    return (dispatch) =>
        jwtService.createResendOtp({
            "rid": userId, 
            "user_id":userData.web_id
        })
        .then((user) => { 
            if(user.status != null && user.status === 1){
                    UserActions.setWlUserData(user);  
                    return dispatch({
                        type: WLRESENDOTP_SUCCESS,
                        payload: user 
                    });
                }else{
                    if(user.error != null){
                        return dispatch({
                            type   : WLRESENDOTP_ERROR,
                            payload: user
                        });
                    }else{
                        return dispatch({
                            type   : WLRESENDOTP_ERROR,
                            payload: "unknown error"
                        });
                    }
                }
            }
        )
        .catch(error => { 
            return dispatch({
                type   : WLRESENDOTP_ERROR,
                payload: error
            });
        });
}

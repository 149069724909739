// import history from '../../../@history';
import jwtService from '../../../services/jwtService';

export const IMGUPLOAD_SUCCESS = 'IMGUPLOAD_SUCCESS';
export const IMGUPLOAD_ERROR = 'IMGUPLOAD_ERROR';

export function uploadImage(file,name,  props) {
    return (dispatch) =>
        jwtService.uploadImage(file)
        .then((response) => {
            return dispatch({
                type: IMGUPLOAD_SUCCESS,
                name : name,
                payload: response
            });
        })
        .catch(error => {
            return dispatch({
            type: IMGUPLOAD_ERROR,
            name : name,
            payload: error
        });
    });
}
import { container, primaryColor } from "../../casino-kit-react.js";
// import Background from "../../img/footerbg.jpg";
import Backgroundn from "../../img/newsletter.jpg";

const footerStyle = {
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  copyrighttext:{
    color:'#fff',
    textAlign:"center",
    margin:"0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right!important"
  },
  footertop:{
    padding:"0 20px",
  },
  footer: {
    paddingTop: "30px",
    overflow: "hidden",
    position: "relative",
    backgroundColor: "#fff",
    background: "#000",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      paddingTop: "30px",
    },
  },
  contactiinpu:{
    width: "100%",
    "& label":{
      color:"#fff !important",
      fontFamily:"ubuntu",
      fontSize:"18px",
    },
    "& input":{
      color:"#fff !important",
      fontFamily:"ubuntu",
      fontSize:"18px",
      height:"30px !important",
    }
  },
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  container,
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  icon: {
    width:"100%",
    textAlign:"right",
  },
  subscribearea:{

  },
  subscribebox:{
    marginBottom: "110px",
    zIndex: "99",
    background: "url(" + Backgroundn + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    padding: "53px 60px 55px",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      padding: "1px 16px 20px",
      marginBottom: "20px",
    },
  },

  headingarea:{
    textAlign:"center",
  },
  subtitle:{
    fontSize:"24px",
    fontWeight:"600",
    lineHeight:"34px",
    fontFamily: 'Ubuntu',
    textTransform:"uppercase",
    marginBottom:"10px",
    color:"#ff344d",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      fontSize:"18px",
    },
  },
  title:{
    fontSize:"40px",
    fontFamily: 'Ubuntu',
    lineHeight:"50px",
    color:"#fff",
    margin:"0px",
    fontWeight:"700",
    marginBottom:"50px",
    textTransform:"uppercase",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      fontSize:"28px",
      lineHeight:"36px",
      marginBottom:"0px",
    },
  },
  mailboxdiv:{
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      display:"none",
    },
  },
  mailbox:{
    display:"inline-block",
    verticalAlign:"middle",
  },
  formarea:{
    alignSelf: "center!important",
    width:"100%",
    fontFamily: 'Open Sans',
    marginTop: "30px",
    "& input": {
      width:"100%",
      height:"50px",
      background:"none",
      border:"0px",
      outline: "none",
      borderBottom:"1px solid #b4b8d1",
      borderRadius:"0px",
      color:"#a1aed4",
    },
  },
  buttonarea:{
    width:"100%",
    marginTop: "30px",
  },
  spanbtn:{
    position:"absolute",
    top:"0",
    right:"0px",
    width:"50px",
    height:"50px",
    textAlign:"center",
    lineHeight:"50px",
    background:"#db5263",
    borderRadius:"50%",
  },
  mybtn1:{
    display:"block",
    width:"100%",
    height:"50px",
    position:"relative",
    fontSize: "16px",
    fontFamily: 'Open Sans',
    textTransform: "uppercase",
    fontWeight: "600",
    padding: "14px 35px",
    color: "#fff",
    border: "0px",
    borderRadius: "50px",
    cursor: "pointer",
    backgroundImage:"linear-gradient(to right, #ff344d, #d2273c)",
    "&:hover": {
      backgroundImage:"linear-gradient(to left, #ff344d, #d2273c)",
    },
  },
  footerwidgetinfolinkwidget:{
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      marginBottom:"20px",
      padding:"0px 10px",
    },
  },
  titlefooter:{
    fontSize: "18px",
    margin:"0px",
    lineHeight: "34px",
    fontFamily: 'Ubuntu',
    fontWeight: "600",
    color: "#FFF",
    textTransform: "uppercase",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: "20px",
    },
  },
  linklist:{
    paddingLeft: "0px",
    marginBottom: "0px",
    marginTop: "0px",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      marginTop: "0px",
    },
    "& li": {
      listStyle: "none",
    },
  },
  atag:{
    transition: "all 0.3s ease-in",
    lineHeight: "30px",
    fontWeight: "400",
    fontFamily: 'Open Sans',
    padding: "0px",
    fontSize: "14px",
    textTransform: "capitalize",
    margin: "0px",
    color:" #a1aed4 !important",
    "& i":{
      fontSize: "12px",
      paddingRight: "0px",
    },
    "&:hover": {
      color: "#f9314b",
      paddingLeft: "10px",
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: "14px",
      lineHeight: "28px",
    },
  },
  copybg:{
    marginTop: "30px",
    padding: "10px 15px 10px", 
    background: "#0b122e",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      marginTop: "10px",
      padding: "10px 0px 10px",
    },
  },
  leftarea:{
    "& p":{
      fontSize: "16px",
      lineHeight: "26px",
      margin: "0px",
      fontFamily: 'Open Sans',
      color:"#a1aed4",
      ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
        textAlign: "center",
        fontSize: "12px",
      },
    },
  },
  rightarea:{
    textAlign:"right",
    "& p":{
      fontSize: "14px",
      lineHeight: "26px",
      margin: "0px",
      fontFamily: 'ubuntu',
      color:"#fff",
      ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
        textAlign: "right",
        fontSize: "12px",
      },
    },
  },
  backtotop:{
    border:"1px solid #fdc600 ",
    borderRadius:"3px",
    color:"#fdc600",
    fontWeight: "bold",
    float: "left",
    marginTop:"10px",
    padding: "10px 20px",
    "& i":{
      marginRight:"10px"
    },
    "&:hover":{
      background:"#efde1d",
      color:"#000"
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      display:"none"
    },
  },
  centerarea:{
    textAlign:"left",
    "& img":{
      filter: "url(data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale)",
  //  filter: "gray",
      transition: "filter .6s,opacity .6s",
      height:"40px",
      padding:"0px 10px 0px 0px"
    }
  },
  copyrightatag:{
    fontSize: "15px",
    fontFamily: 'Open Sans',
    lineHeight: "26px",
    padding:"0px",
    display:"inline-block",
    margin:"0px",
    marginLeft: "3px",
    marginTop: "-3px",
    color: "#045cf0",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      textAlign: "center",
      fontSize:"12px",
    },
  },
  coprightarealinks:{
    textAlign: "right",
    margin: "0px",
    "& li":{
      display: "inline-block",
      marginRight: "30px",
      position: "relative",
      ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
        marginRight: "20px",
      },
    },
    "& li::after":{
      position: "absolute",
      content: "'|'",
      right: "-22px",
      color:"#a1aed4",
      top: "0px",
      ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
        right: "-12px",
        top: "2px",
      },
    },
    "& li:last-child":{
      marginRight: "0px",
    },
    "& li:last-child::after":{
      display: "none",
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      textAlign: "center",
      padding:"0px",
    },
  },
  copyrightrightatag:{
    fontSize: "16px",
    fontWeight: "400",
    color: "#a1aed4 !important",
    padding:"0px",
    fontFamily: 'Open Sans',
    margin:"0px",
    textTransform: "capitalize",
    transition: "all 0.3s ease-in",
    "&:hover":{
      color:"#f9314b",
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      paddingTop: "0px",
      fontSize: "12px",
    },
  },
};
export default footerStyle;

import brezza from './brezza/ThemeConfig';
import casinodestination from './casinodestination/ThemeConfig';
import smdesign1 from './smdesign1/ThemeConfig';
import smdesign2 from './smdesign2/ThemeConfig';
import spike from './spike/ThemeConfig';
import sporto from './sporto/ThemeConfig';
import ambiance from './ambiance/ThemeConfig';
import freddy from './freddy/ThemeConfig';
import gloster from './gloster/ThemeConfig';
const CasinoLayoutConfigs = {
    brezza,
    casinodestination,
    smdesign1,
    smdesign2,
    spike,
    sporto,
    ambiance,
    freddy,
    gloster
};

export default CasinoLayoutConfigs;

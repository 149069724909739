// import React from 'react';
// import about from './pages/about';
import deposit from './pages/deposit';
import withdraw from './pages/withdraw';
import mybonus from './pages/myBonus';

// import stats from './pages/stats';
// import dashboard from './pages/dashboard';
import statement from './pages/statement';
import account from './pages/account';
import {authRoles} from '../../auth';


// const DashboardPageConfig = {
//     settings: {
//         layout: {
//             config: {}
//         }
//     },
//     auth    : authRoles.player,
//     routes  : [
//         {
//             path     : '/dashboard',
//             component: dashboard
//         }
//     ]
// };

const BonusPageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth    : authRoles.player,
    routes  : [
        {
            path     : '/mybonus',
            component: mybonus
        }
    ]
};

// const AboutPageConfig = {
//     settings: {
//         layout: {
//             config: {}
//         }
//     },
//     routes  : [
//         {
//             path     : '/about',
//             component: about
//         }
//     ]
// };

const DepositPageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth    : authRoles.player,
    routes  : [
        {
            path     : '/deposit',
            component: deposit
        }
    ]
};

const WithdrawPageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth    : authRoles.player,
    routes  : [
        {
            path     : '/withdraw',
            component: withdraw
        }
    ]
};

// const StatsPageConfig = {
//     settings: {
//         layout: {
//             config: {}
//         }
//     },
//     auth    : authRoles.player,
//     routes  : [
//         {
//             path     : '/stats',
//             component: stats
//         }
//     ]
// };

const StatementPageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth    : authRoles.player,
    routes  : [
        {
            path     : '/statement',
            component: statement
        }
    ]
};

const ProfilePageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth    : authRoles.player,
    routes  : [
        {
            path     : '/account',
            component: account
        }
    ]
};
export const pagesConfigs = [
    // AboutPageConfig,
    BonusPageConfig,
   // DashboardPageConfig,
    DepositPageConfig,
    StatementPageConfig,
    ProfilePageConfig,
    WithdrawPageConfig,
    // StatsPageConfig
];

import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Icon from "@material-ui/core/Icon";
// core components
import Card from "../Card/Card.js";
import CardBody from "../Card/CardBody.js";
import CardHeader from "../Card/CardHeader.js";

import styles from "../../assets/jss/components/customTabsStyle.js";



export default function CustomTabs(props) {
    const { headerColor, plainTabs, tabs, title, rtlActive, border, clickEvent } = props;

    styles.tabSelectedSecond.backgroundColor = props.themeColors.bgColors.primary_light_bgcolor;
    styles.tabSelectedSecond.borderBottomColor = props.themeColors.bgColors.primary_light_bgcolor;
    styles.tabRootButtonSecond.color = props.themeColors.bgColors.text_primary_color;
    styles.tabRootButtonSecond.fontFamily = props.themeColors.bgColors.base_font  
    styles.tabRootButtonSecond.borderColor = props.themeColors.bgColors.secondary_dark_bgcolor;
  //   styles.thirdwrap.background = props.themeColors.bgColors.firsttab_body_bg;

  //   if(themeColors != null){
  //       styles.tabRootButton.backgroundColor = themeColors.bgColors.lightblackbg;
  //   }
  //   if(themeColors != null){
  //       styles.tabSelected.background = themeColors.bgColors.golden;
  //   }
  //   if(themeColors != null){
  //     styles.tabSelected.color = themeColors.bgColors.goldenbtntext;
  // }
  const useStyles = makeStyles(styles);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, value) => {
    setValue(value);
  };

  const handleClick = (value) => {
    if(clickEvent != null){
      clickEvent(value);
    }
  };
  const classes = useStyles();

  const cardTitle = classNames({
    [classes.cardTitle]: true,
    [classes.cardTitleRTL]: rtlActive
  });
  return (



    <Card plain={plainTabs}>
 

        <CardHeader color={headerColor} border={border} plain={plainTabs}>
        {title !== undefined ? <div className={cardTitle}>{title}</div> : null}
        <Tabs
          value={value}
          onChange={handleChange}
         
          classes={{
            root: classes.tabsRoot, 
            indicator: classes.displayNone
          }}
        >
          {tabs.map((prop, key) => {
            var icon = {};
            if (prop.tabIcon) {
              icon = {
                icon:
                  typeof prop.tabIcon === "string" ? (
                    <Icon>{prop.tabIcon}</Icon>
                  ) : (
                    <prop.tabIcon />
                  )
              };
            }
            return (
              <Tab
                classes={{
                  root: classes.tabRootButtonSecond,
                  label: classes.tabLabel,
                  selected: classes.tabSelectedSecond,
                  wrapper: classes.tabWrapper
                }}
                key={key}
                onClick={()=>handleClick(key)}
                label={prop.tabName}
                {...icon}
              />
            );
          })}
        </Tabs>
      </CardHeader>
      <CardBody>
        {tabs.map((prop, key) => {
          if (key === value) {
            return <div key={key}>{prop.tabContent}</div>;
          }
          return null;
        })}
      </CardBody>
    </Card>
  );
}

CustomTabs.propTypes = {
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose"
  ]),
   border: PropTypes.oneOf([
        "round",
        "square"
   ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.object.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool
};

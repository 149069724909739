import React  from "react";
import {makeStyles} from "@material-ui/core/styles";   
import 'react-accessible-accordion/dist/fancy-example.css';
import logo from "../assets/img/logo.png"; 
import styles from "../assets/jss/views/landingPage.js";  
import {Button} from '@material-ui/core';
const useStyles = makeStyles(styles);
 
 
export default function WlVerifyemail(props) { 
    const classes = useStyles();  

     
    return (
        <div className={classes.fullpagelogin}>

 

 
        <img className={classes.girlimg} alt="" src={logo}/>
        <div className={classes.loginwrap}>
            <h4 style={{color:'green', }}>Account Activated</h4>
            <div style={{textAlign:'center'  }}>
            <i style={{fontSize:'50px',   }} className="fa fa-envelope"></i>
            <h5 style={{fontWeight:'bold', color:'green', }}>Hello User,</h5>
            <p>Thank you, Your email has been verifyed. Your account is now active. Please use the link below to login to
your account.</p>
</div>
            <Button simple color="primary" className={classes.signinmodalbtn +" signinbtn"} type="submit" size="lg"   >
            Login to Your Account
                    </Button>
                    
        </div>
         </div>
    );
}
import React from "react"; 
import 'react-accessible-accordion/dist/fancy-example.css'; 
import { makeStyles } from "@material-ui/core/styles"; 
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js"; 
import * as Gurl from "../../../global/Global.js"
import axios from 'axios'; 

import styles from "../assets/jss/components/tabsFaqstyle.js";
 
export default function SectionTabs(props) {
  
  if(props.themeColors != null){
  styles.accordianbtn["& div i"].background = props.themeColors.bgColors.golden
  styles.accordianbtn["& div i"].color = props.themeColors.bgColors.goldenbtntext
  styles.accordianbtn["& div"].background = props.themeColors.bgColors.accordianlabel
  styles.accordianbtn["& div:hover"].background = props.themeColors.bgColors.accordianlabel
  styles.accordianiitm.background = props.themeColors.bgColors.accordiandiv
  styles.accordianp.color = props.themeColors.bgColors.accordiandivtext 
  styles.recentwinnhead.color = props.themeColors.bgColors.text_primary_color 
  styles.spikerecentwinn["& .singlewiner"].background = props.themeColors.bgColors.primary_bgcolor 
  styles.spikerecentwinn["& .singlewiner"].borderColor = props.themeColors.bgColors.secondary_dark_bgcolor 
  styles.spikerecentwinn["& .singlewiner .id"].background = props.themeColors.bgColors.primary_inner_bgcolor 
  styles.spikerecentwinn["& .singlewiner .id"].borderColor = props.themeColors.bgColors.primary_inner_bgcolor 
  }
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric' };
 
  const [winnerData, setWinnerData] = React.useState("NOVALUE");
  // function createMarkup(text) { return {__html: text}; };
    function loadWinnerData(){
        const request = axios.post(Gurl.WINNER_CONTENT_API, {
            token: window.localStorage.getItem('jwt_access_token'),
            "gameids" : props.gameIds,
            "LEND"    : props.record
        });
        request.catch((error) => { 
          setWinnerData("NOVALUE");
        });
        request.then((response) => { 
            if(response.data.data != null){ 
              setWinnerData(response.data.data);
            }else{
              setWinnerData("NO Winner Loaded");
            }
        });  
    }
  if(winnerData === "NOVALUE"){
    setWinnerData("Loading...");
    loadWinnerData();
  }
  var outputData = "";
  var rc = "0";
  if(props.design === "1"){
    if(Array.isArray(winnerData)){ 
      rc = 0;
      outputData = winnerData.map(jsonData => {
        rc = rc + 1;
        return(
          <GridItem xs={12} sm={6} md={3}  className={classes.spikerecentitem} >
          <div className={"singlewiner" + ((rc%2) ? " fleft" : " fleft")}>
            <div className="toparea">
              <div className="left">
                <h4 className="name">{jsonData.name}</h4>
                <p className="date">{jsonData.gametype}</p>
              </div> 
            </div>
            <div className="bottomarea">
              <div className="leftbt">{jsonData.winning_amount} {jsonData.currency_code}</div>
              <div className="rightbt">
                <p className="id">{(new Date(jsonData.created)).toLocaleDateString('en-US', DATE_OPTIONS)}</p>
              </div>
            </div>
          </div>
          </GridItem>
        )
      });
      return(
        <div>
          {outputData}
        </div>
      )
    } else {
      if(winnerData !== "NOVALUE" && winnerData !== "Loading..."){
        return (<div className="singlewiner">
              <div className="toparea">
                <div className="left">
                  <h4 className="name">{winnerData.name}</h4>
                  <p className="date">{winnerData.gametype}</p>
                </div> 
              </div>
              <div className="bottomarea">
                <div className="leftbt">{winnerData.winning_amount} {winnerData.currency_code}</div>
                <div className="rightbt">
                  <p className="id">{(new Date(winnerData.created)).toLocaleDateString('en-US', DATE_OPTIONS)}</p>
                </div>
              </div>
            </div>);
      }else{
      return (<div></div>)
      }
    }
  }else{
    if(Array.isArray(winnerData)){ 
      rc = 0;
      outputData = winnerData.map(jsonData => {
        rc = rc + 1;
        return(
          <GridItem xs={12} sm={6} md={3}  key={rc} className={classes.spikerecentitem}>
          <div className={"singlewiner" + ((rc%2) ? " fleft" : " fleft")}>
            <div className="toparea">
              <div className="left">
                <h4 className="name">{jsonData.name}</h4>
                <p className="date">{jsonData.gametype}</p>
              </div> 
            </div>
            <div className="bottomarea">
              <div className="leftbt">{jsonData.winning_amount} {jsonData.currency_code}</div>
              <div className="rightbt">
                <p className="id">{(new Date(jsonData.created)).toLocaleDateString('en-US', DATE_OPTIONS)}</p>
              </div>
            </div>
          </div>
          </GridItem>
        )
      })
      return(
        <div className={classes.recentwinn}>
          <h4 className={classes.recentwinnhead}>Recent Winners</h4>
          <GridContainer className={classes.spikerecentwinn}>
          {outputData}
          </GridContainer>
        </div>
      )
    } else {
      if(winnerData !== "NOVALUE" && winnerData !== "Loading..."){
        return (<div className="singlewiner">
              <div className="toparea">
                <div className="left">
                  <h4 className="name">{winnerData.name}</h4>
                  <p className="date">{winnerData.gametype}</p>
                </div> 
              </div>
              <div className="bottomarea">
                <div className="leftbt">{winnerData.winning_amount} {winnerData.currency_code}</div>
                <div className="rightbt">
                  <p className="id">{(new Date(winnerData.created)).toLocaleDateString('en-US', DATE_OPTIONS)}</p>
                </div>
              </div>
            </div>);
      }else{
        return (<div></div>)
      }
    }
  }
}
import { container } from "../../casino-kit-react.js";
import Background2 from '../../img/bgge.png';

const tabsStyle = {
  signinnerpagebox:{
    background:"#0f0f0f",
    padding:"140px 20px 0px",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      padding:"90px 20px 0px",
    },

},
gamesearchingonly:{
  width: "260px !important",
  float: "right",
  ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
    width: "100% !important",
    margin: "10px 0px",
  }, 
},
gamecategaryname:{
  background:"none",
  "& button":{
    margin:"0px"
  },
  "& .slick-track":{
    float:"left"
  },
  "& .slick-list":{
    margin:"0px 30px", 
  },
  "& .slick-arrow":{
    width:"30px !important",
    opacity:"1 !important"
  },
  "& .slick-arrow::before":{
    fontSize:"16px",
    color:"#fff"
  }
},
gameboxico:{
  marginLeft:"-12px",
  marginRight:"-12px",
},
gameboxicoin:{
  paddingLeft:"2px",
  paddingRight:"2px",
},
gamebtnwrap:{
  background:"#000",
  padding: "0 10px",
  marginBottom: "10px",
  marginTop: "-20px",
  marginLeft:"-15px",
  marginRight:"-15px",
  position:"relative",
  "& .slick-slide button":{
   padding: "10px 0px",
   opacity:"1",
   color:"#000",
   border:"0px !important"
  },
  "& div":{
    boxShadow:"0 0 0"
  },
  "& .slick-slide button.active":{
    background: "none",
   },
   "& .gamesearchingwrap":{
     paddingTop:"4px"
   }
},
gamescrolltab:{
  // overflow:"auto",
  width:"calc(100% - 270px)",
  float:"left",
  ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
   width:"100%",
 },
 },
 gamescrolltabin:{ 
   width:"1000px"
  },
dropdownborderwrap:{
  float:"left",
  "& b":{
    marginLeft:"-20px"
  },
  "& button":{
    padding:"0px"
  },
  "& button:last-child":{
    borderBottom:"0px !important"
  }
},
dropdownborder:{ 
  fontSize:"14px", 
  padding: "8px 40px 8px 20px",
  textAlign:"left",
  textTransform:"capitalize",
  color:"#fff",
  borderRadius: "20px",
  border: "1px solid #fff",
},
spikegamewrap:{
  marginLeft:"-6px",
  marginRight:"-6px",
},
spikegame:{
  paddingLeft:"6px",
  paddingRight:"6px",
  minHeight:"150px !important",
  ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
    minHeight:"auto !important",
  },
  "& .singlegame":{
    padding:"0",
    border:"0"
  },
  "& .singlegame:before":{
    background:"none !important"
  },
  "& .spikegamehover":{
    display:"none",
    backgroundImage:"url(" + Background2 + ")",
    backgroundSize: "100%",
    width: "100%",
    height: "100%",
    backgroundColor:"rgba(0,0,0,0.5)",
    bottom:"0",
    right:"0",
    alignItems: "center",
    justifyContent: "center",
    backgroundPosition: "left", 
    color: "#fff !important",
    fontWeight: "lighter",
  },
  "& table":{
    width:"100%",
    fontSize:"14px",
  }, 
  "& .gamelabell":{
    display:"block",
    background: "none",
    width: "100%", 
    bottom:"5px",
    right:"0", 
    color: "#fff !important",
    fontWeight: "lighter",
  },
  "&:hover .spikegamehover":{
    display: "flex",
  },
  
},
dropdownborderbtn:{
  width: "100%",
  padding: "7px !important",
  textAlign: "left",
  margin: "0 !important",
  background: "none",
  borderBottom: "1px solid #ccc !important",
  "& span":{
    textAlign: "left",
  },
  "&:hover":{
    background: "#000 !important",
  },
},
gamewrap:{
  padding:"180px 20px 0px 20px"
},
categoryicon:{
  display:"block",
  margin:"0px auto 5px",
  height:"40px"
},
categorycounter:{
  position: "absolute",
  top: "10px",
  background: "#ff3341",
  color: "#fff",
  borderRadius: "10px",
  padding: "2px 4px",
  fontSize: "9px",
  right: "29%",
},
gamesearching:{
  background:"#131212",
  color:"#fff",
  marginTop:"2px", 
  width: "100%", 
  ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
    width: "49%",
    float:"right",
    marginTop:"0"
  },
},
searchgameicon:{
  color:"#fff",
  position:"absolute",
  right:"20px",
  bottom:"16px",
  cursor:"pointer",
  ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
    bottom:"21px",
  },
},
showGameLoad:{
  position:"fixed", 
  width:"100%", top:"0",left:"0",
  display:"none", justifyContent: "center", 
  height:"100%", background:"rgba(0,0,0,0.5)", 
  zIndex: "9999",
  ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key 
    width:"100%", 
  },
},
gamesearchingbtn:{
  background:"#131212",
  color:"#fff",
  width: "100%", 
  boxShadow: "0px 0px",
  borderRadius: "5px !important",
  textTransform: "capitalize",
  padding: "8px 10px !important", 
  textAlign: "left",
  marginTop:"4px ​!important",
  display: "block",
  opacity:"1 !important",
  "&:hover":{
    boxShadow:"0 0 0",
  },
  "& b":{
    float:"right",
    marginTop:"10px"
  },
  ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
    width: "49%",
    float:"left" ,
    margin:"0px !important"
  },
   
},
gamepro:{
  "& ul":{
    listStyle:"none",
    margin:"0",
    padding:"0",
    width: "100%"
  },
  "& ul ul":{
    listStyle:"none",
    margin:"0",
    padding:"10px",
    width: "300px",
    height: "180px",
    overflow: "auto",
  },
  "& ul li":{
    width: "100%", 
    width: "97%",
    "&:hover":{
      background:"none",
      boxShadow:"0 0 0"
    }
  },
  "& ul li ul li":{
    width: "20%",
    cursor: "pointer",
    display: "inline-block",
    padding: "3px 1%",
    float: "left"
  },
  "& ul li ul li img":{
    width: "100%", 
  }
},
gamesearchingbtnicon:{
  float:"right",
  marginTop:"5px",
},
gameproviderdrop:{ 
  width:"300px", 
  height:"180px",
  overflow:"auto",
  display:"none",
  position: "absolute",
  zIndex: "9",
  right: "10px",
  background: "#0d0d0d",
  padding: "15px",
  "& ul":{
    padding:"0",
    listStyle:"none",
    margin:"0",
  },
  "& ul li":{
    width:"20%",
    padding:"3px 1%",
    display:"inline-block",
    cursor:"pointer",
  },
  "& ul li img":{
    width:"100%", 
  },
  ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
    right: "auto",
    left:"10px",
    marginTop:"38px",
  },
   
  
},
gamelabel:{
  position:"absolute", 
  height:"32px",
  bottom:"0px", 
  fontFamily: 'Open Sans', 
  fontSize: "14px",
  textTransform: "uppercase",
  fontWeight: "normal",
  lineHeight: "24px",
  color:"#ffb80c", 
  width:"100%",
  background: "rgba(0,0,0,0.7)",
  padding: "4px 0px",
  borderRadius: "0px 0px 0px 0px",
  ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
    fontSize: "12px",
  },
},
gridgame:{ 
  ['@media (max-width:959px)']: { // eslint-disable-line no-useless-computed-key 
    "&:nth-child(odd)":{
      paddingRight:"5px",
    },
    "&:nth-child(even)":{
      paddingLeft:"5px",
    } 
  },
},
pageheadinginner:{
  fontFamily: "ubuntu",
  color:"#ffb80c",
  fontSize:"22px",
  padding:"10px 0px 10px"
},
signinnerpageboxin:{
    background:"#000",
    margin:"40px 0px 0px",
    borderRadius:"10px"
},
  section: {
    background: "#EEEEEE",
    padding: "70px 0"
  },
  dashboardgames:{
    padding:"0px 20px"
  },
  tableresponsi:{
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      overflow: "auto",
    },
  },
  table:{
    boxShadow:" 0px 10px 40px 0px rgba(0, 0, 0, 0.4)",
    position: "relative",
    width: "100%",
    fontFamily: 'Open Sans',
    textAlign: "left",
    marginBottom: "1rem",
    color: "#212529",
    '& tr:hover': {
      background:"#192246",
    },
    '& td': {
      borderTop:"1px solid rgba(255, 255, 255, 0.09)",
      border:"0px",
      verticalAlign:"middle",
      padding:"20px 15px",
      fontWeight:"600",
      fontSize:"18px",
      textTransform:"uppercase",
      color:"#a1aed4",
    },
    '& th': {
      borderTop:"1px solid rgba(255, 255, 255, 0.09)",
      border:"0px",
      fontSize:"18px",
      fontWeight:"600",
      textTransform:"uppercase",
      borderBottom:"0px",
      padding:"20px 15px",
      background:"#0b122e",
      color:"#fff",
    },
    '& img': {
      width:"40px",
      height:"40px",
      borderRadius:"50%",
      verticalAlign:"middle",
      display:"inline-block"
    },
    '& td:nth-child(1) img': {
      marginRight:"5px",
    },
    '& td:nth-child(3) img': {
      marginRight:"5px",
    },
    '& td:nth-child(6) img': {
      marginRight:"5px",
    },
    '& td:nth-child(1)': {
      color:"#fff",
    },
    '& td:nth-child(3)': {
       color:"#045cf0",
    },
    '& td:nth-child(5)': {
      color:"#f0ad4e",
    },
    '& td:nth-child(6)': {
      color:"#f0ad4e",
    },
    '& td:last-child': {
      color:"#f0ad4e",
    },
  },
  playgamebtn:{
    background:"#efde1d",
    color:"#000",
    borderRadius:"30px !important",
    fontWeight:"lighter !important",
  },
  gametabpage:{
    "&.active":{
      borderColor:"#efde1d",
    }
  },
  singlegame:{
    display:"block", 
    textAlign:"center",
    marginBottom:"4px",
    border:"1px solid #273b71",
    borderRadius:"0px",
    padding:"0px",
    boxShadow:"0px 0px 0px 0px",
    position:"relative", 
    transition:"all 0.3s ease-in", 
    width: "100%",
    overflow: "hidden",
    '&:hover a': { 
      display:"block", 
    },
    '& img': { 
      verticalAlign: "middle",
     // animation:"opacityAnimation 2s linear infinite",
      width: "100%",
      borderRadius: "0px",
      position: "relative",
      height: "auto",
      ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
        height: "auto",
      },
    },
    '&:hover': {
      borderColor:"#681f40",
      transform:"translateY(-10px)",
    },
    '&::before': { 
      content:"close-quote",
      position:"absolute",
      left:"-2px",
      top:"-2px",
      width:"calc(100% + 4px)",
      height:"calc(100% + 4px)", 
      background:"linear-gradient(135deg,#a1902e,#ffdb66 35%,#baa73e 40%,#fff8d4 44%,#6e5706 48%,#574300 52%,#fff6c7 56%,#ab8f00 76%,#ab8f00)",
      animation:"BorderAnimation-data-v-7fdc8ba0 20s linear infinite",
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      height:"auto",
      lineHeight:"inherit",
      marginBottom:"20px",
    },
  },
  searchboxi:{
    top: "112px",
    right: "15px",
    border: "0px",
    padding: "15px 20px",
    position: "absolute",
    background: "#151515",
    borderRadius: "50px",
    zIndex: "20",
    color:"#ccc",
    "&::placeholder":{
      color:"#ccc",
    },
    "& input":{
      background:"#151515",
      color:"#ccc",
      border:"0px",
      marginLeft:"20px"
    }
  },
  mybtn2:{
    position:"absolute", 
    height:"34px",
    bottom:"40%",
    display:"none",
    left:"50%",
    fontFamily: 'Open Sans',
    transform:"translateX(-50%)",
    boxShadow:"0px 10px 15px 0px rgba(0, 0, 0, 0.4)",
    fontSize: "14px",
    textTransform: "uppercase",
    fontWeight: "600",
    lineHeight: "11px",
    width: "130px",
    color: "#000",
    border: "0px",
    borderRadius: "3px",
    cursor: "pointer",
    transition: "all 0.3s ease-in",
    background: "linear-gradient(to left, #1c294e, #1c294e)",
    '&:hover': {
      background: "linear-gradient(to right, #ff344d, #d2273c)",
      color: "#fff",
    }, 
  },
  container,
  textCenter: {
    textAlign: "center"
  },
  loadimg:{
    margin:"0px auto",
    height:"60px",
  }
};

export default tabsStyle;

import * as Actions from '../actions';

const initialState = {
    role: [],//guest
    data: {
        'displayName': 'M Singh',
        'photoURL'   : 'assets/images/avatars/Velazquez.jpg',
        'email'      : 'noemail@gameapi.live',
        shortcuts    : [
            'calendar',
            'mail',
            'contacts',
            'todo'
        ]
    }
};

const initialStateTwo = {
    success: false,
    data: null,
    error  : {
        username: null,
        password: null
    }
}

export const user = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.SET_USER_DATA:
        {
            return {
                ...initialState,
                ...action.payload
            };
        }
        case Actions.REMOVE_USER_DATA:
        {
            return {
                ...initialState
            };
        }
        case Actions.USER_LOGGED_OUT:
        {
            return initialState;
        }
        case Actions.CONTACTREQUEST_SUCCESS:
        {
            return {
                ...initialStateTwo,
                success: true
            };
        }
        case Actions.CONTACTREQUEST_ERROR:
        {
            return {
                success: false,
                error  : action.payload
            };
        }
        case Actions.SUBSCRIBEREQUEST_SUCCESS:
        {
            return {
                ...initialStateTwo,
                success: true
            };
        }
        case Actions.SUBSCRIBEREQUEST_ERROR:
        {
            return {
                success: false,
                error  : action.payload
            };
        }
        default:
        {
            return state
        }
    }
};


const initialStateChangeProfile = {
    success: false,
    data: null,
    error  : {
        first_name: null,
        last_name: null,
        email: null,
        mobile: null
    }
};
export const changeProfile = function (state = initialStateChangeProfile, action) {
    switch ( action.type )
    {
        case Actions.PROFILEEDIT_SUCCESS:
        {
            return {
                success: true,
                data: action.payload,
                error: null
            };
        }
        case Actions.PROFILEEDIT_ERROR:
        {
            return {
                success: false,
                data: null,
                error  : action.payload
            };
        }
        
        default:
        {
            return state
        }
    }
};

const initialStateChangePassword = {
    success: false,
    error  : {
        password: null,
        old_password: null
    }
};
export const changePassword = function (state = initialStateChangePassword, action) {
    switch ( action.type )
    {
        case Actions.FORM_SUCCESS:
        {
            return {
                ...initialState,
                success: true
            };
        }
        case Actions.FORM_ERROR:
        {
            return {
                success: false,
                error  : action.payload
            };
        }
        
        default:
        {
            return state
        }
    }
};
/* eslint-disable eqeqeq */
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Route } from "react-router-dom";
import history from '../../../@history';
// import classNames from "classnames";
// import SectionGames from "../sections/SectionGames";
import Games from "../pages/games.js"; 
import Contentbox from "../pages/contentbox.js"; 

import Mediacontent from "../pages/mediacontent.js"; 
// import Carousel from "react-slick";
// import Card from "../elements/Card/Card.js";
import Contact from "../pages/contactform.js";
import Hometab from "../pages/hometab.js";  
import Inquiry from "../pages/inquiryform.js";  

import styles from "../assets/jss/views/landingPage.js";
import jwt from 'jsonwebtoken'; 
import jwtDecode from 'jwt-decode';
import jwtService from '../../../services/jwtService'
import axios from 'axios';

import UserGames from "../pages/usergames.js";
import * as globalurl from '../../../global/Global.js'; 
import GridItem from "../elements/Grid/GridItem.js"; 
// import Formsy from 'formsy-react'; 
import Deposit from "../pages/deposit.js";
import Withdraw from "../pages/withdraw.js"; 
// import Stats from "../pages/stats.js"; 
import LoginHistory from "../pages/loginHistory.js"; 
import MyBonus from "../pages/myBonus.js"; 
import MyBets from "../pages/myBets.js";  
import Account from "../pages/account.js";
import Offer from "../pages/offer.js";
import Sports from "../pages/sports.js";
import Playnow from "./playnow.js";
import VerifyEmailtoken from "../pages/verifyemailtoken.js"
import Verifyemail from "../pages/verifyemail.js";
import Verifyphone from "../pages/verifyphone.js";
import Pcbypg from "../pages/pcbypg.js";
import Statement from "../pages/statement.js";
import Message from "../pages/message.js";
import Promotions from "../pages/promotion.js";
import SectionFaqtabs from "../sections/SectionFaqtabs";
import SectionPromotions from "../sections/SectionPromotions";
import SectionWinners from "../sections/SectionWinners";
//  import Fourzerofour from "../pages/404.js"; 
import GridContainer from "../elements/Grid/GridContainer.js";
import themeDb from "../data/theme.js";
// import stylesr from "../assets/jss/components/carouselStyle.js";
const useStyles = makeStyles(styles);
// const useStylesr = makeStyles(stylesr);

export default function ComponentLoader(props) {
    var defaultPage = "/home";
    styles.title.color = props.themeColors.bgColors.primary_bgcolor 
    styles.title.fontFamily = props.themeColors.bgColors.base_font 
    styles.titledesc.fontFamily = props.themeColors.bgColors.base_font 
    styles.titledesc.color = props.themeColors.bgColors.primary_bgcolor
    
    const classes = useStyles();
    // const classesr = useStylesr();
    const { ...myTheme} = themeDb; 
    const [components, setComponents] = React.useState(false);
    // const [previousId, setPreviousId] = React.useState(false);
    const currentLocation = props.location.pathname.split("/");
    //const [isContainerStart, setIsContainerStart] = React.useState(false);
    var isContainerStart = false;
    var containerGroup = -1000000;
    let dataOfComponents = [];
    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: true
    //   };
     
    
    if(currentLocation[1] == null || currentLocation[1].length == 0){
        let themeInfo = getCurrentThemeInfo();
       
        if(themeInfo != null && themeInfo.pageinfo != null && themeInfo.pageinfo.length > 0){
            let pageInfo = JSON.parse("{" + themeInfo.pageinfo + "}");
            if(pageInfo != null){
                if(jwtService.isAuthenticationLogin() == true){
                    if(pageInfo.home_login != null){
                        defaultPage = "/" + pageInfo.home_login;
                    }else{
                        if(pageInfo.home_before != null){
                            defaultPage = "/" + pageInfo.home_before;
                        }
                    }
                }else{
                    if(pageInfo.home_before != null){
                        defaultPage = "/" + pageInfo.home_before;
                    }
                }
            }
        }
        
        history.push({
            pathname: defaultPage
        });
    }
    function getCurrentThemeInfo(){
        if(window.localStorage.getItem('jwt_theme_token') != null){
            let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
            return themeData;
        }
    } 
    function parseJwt(token) {
        if (!token) { return; }
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }
    if(currentLocation[1] === "logout"){
        let themeInfo = getCurrentThemeInfo();
        if(themeInfo != null && themeInfo.pageinfo != null && themeInfo.pageinfo.length > 0){
            let pageInfo = JSON.parse("{" + themeInfo.pageinfo + "}");
            if(pageInfo != null){
                if(pageInfo.home_before != null){
                    defaultPage = "/" + pageInfo.home_before;
                }
            }
        }
        if(window.localStorage.getItem('jwt_access_token') != null){
            getLogoutNow();
            window.localStorage.removeItem('jwt_access_token');
            window.localStorage.removeItem('sports_access');
            sessionStorage.removeItem('welcome_message');
            // history.push({
            //     pathname: defaultPage
            // });
            window.location.reload();
        }else{
            history.push({
                pathname: defaultPage
            });
        }
    }
    
    async function getLogoutNow(){
        dataOfComponents = await logoutFromServer();
    }
    
    async function logoutFromServer(){
        let pro = new Promise((resolve,reject) => {
            const request = axios.post(globalurl.LOGOUTUSER , {
                token: window.localStorage.getItem('jwt_access_token')
            });
            request.then((response) => {
                if(response.data.data != null){
                    var data = {
                        "status":true,
                        "data":response.data.data
                    }
                    resolve(data);
                }else{
                    resolve({"status":false, "message":'No record found'});
                }
            });
            request.catch((error) => {
                reject(error);
            });
        }); 
        const val = await pro;
        return val;
    }
    async function getDataFromServer(){
        let pro = new Promise((resolve,reject) => {
            const request = axios.post(globalurl.GETMENUCONTENTS , {
                token: window.localStorage.getItem('jwt_access_token'),
                pageslug: currentLocation[1]
            });
            request.then((response) => {
                if(response.data.data != null){
                    var data = {
                        "status":true,
                        "data":response.data.data
                    }
                    resolve(data);
                }else{
                    resolve({"status":false, "message":'No record found'});
                }
            });
            request.catch((error) => {
                reject(error);
            });
        }); 
        const val = await pro;
        return val;
    }
    function setHeaderData(data, access_token_name){
        var jwtConfig = {
            "secret"   : "324934dsfj786ykjhgiyyi4439wri",
            "expiresIn": "1 hours" // A numeric value is interpreted as a seconds count. If you use a string be sure you provide the time units (days, hours, etc)
        };
        const access_token = jwt.sign(data, jwtConfig.secret, {expiresIn: jwtConfig.expiresIn});
        window.localStorage.setItem(access_token_name, access_token);
    }

    
    async function getDataNow(){
        dataOfComponents = await getDataFromServer();
        if(dataOfComponents.data != null){
            setHeaderData({Components: dataOfComponents.data}, currentLocation[1].toString().toLowerCase() + "_access_token");
            setComponents(dataOfComponents.data);
        }
    }
    if(components === false){
        
        let headerData = false; 
        if(window.localStorage.getItem(currentLocation[1].toString().toLowerCase()+"_access_token") != null){
            const decodedData = jwtDecode(window.localStorage.getItem(currentLocation[1].toString().toLowerCase()+"_access_token"));
            const currentTime = Date.now() / 1000;
            let tokenExpired = false;
            if ( decodedData.exp < currentTime ){
                tokenExpired = true;
            }
            if(tokenExpired === true && navigator != null && navigator.onLine != null && navigator.onLine !== true){
                tokenExpired = false;
            }
            if (tokenExpired === false){
                if(decodedData.Components != null){
                    setComponents(decodedData.Components); 
                    headerData = true;
                }  
            }
        }
        if(headerData === false){
            getDataNow();
        }
    }else{
        dataOfComponents = components;
    }
    const isObject = function(a) {
        return (!!a) && (a.constructor === Object);
    };
    function createMarkup(text) { return {__html: text}; };

    var loginclass = "";
    if(jwtService.isAuthenticationLogin() == true){   
        loginclass = 'gamewrappp';       
      }else{          
        loginclass = 'gamewrap';
      } 


    return (
        <div className={classes.gamewrap + " " + loginclass}>
            {
                (() => {
                    
                    if(dataOfComponents != null && ((Array.isArray(dataOfComponents) && dataOfComponents.length > 0) || (isObject(dataOfComponents) && Object.keys(dataOfComponents).length > 0))){
                        if(isObject(dataOfComponents)){
                            dataOfComponents = [dataOfComponents];
                        }
                        
                      //alert(JSON.stringify(dataOfComponents));
                        var previousSequanceOrder = false;
                        var runComponent = 0;
                        var subhJourney = "";
                        var checkByContainer = [];
                        var checkByContainerFinal = [];
                        subhJourney = dataOfComponents.map((companentData) => {
                            var newLineClass = ""; 
                            runComponent = runComponent + 1;
                             if(previousSequanceOrder != false && previousSequanceOrder != companentData.content_sequence_order){
                                 newLineClass = "clearbox";  
                               //  newLineClass = "clearbox";   
                             }
                             if(previousSequanceOrder != companentData.content_sequence_order){
                                 previousSequanceOrder = companentData.content_sequence_order; 
                             }
                         
                             if(companentData.cms_component_type_id == "7"){
                                /*var dataInVar = () => container + (
                                 <GridItem md={companentData.column_width} className={newLineClass} key={companentData.id}>
                                     <Games themeColors={props.themeColors} gameIds={companentData.data_contents} />
                                 </GridItem>
                                 
                                ) + ((dataOfComponents.length == runComponent && isContainerStart == true) ? containerend : "");*/
                                checkByContainer = [
                                    ...checkByContainer,
                                    <GridItem xs={companentData.column_width} className={newLineClass} key={companentData.id}>
                                       <div className={classes.gamespadding}> 
                                            <Games themeColors={props.themeColors} sectionPopup={props.sectionPopup} gameIds={companentData.data_contents} />
                                       </div>
                                    </GridItem>
                                ];
                             }else if(companentData.cms_component_type_id == "14"){
                                checkByContainer = [
                                    ...checkByContainer,
                                    <GridItem xs={companentData.column_width} className={newLineClass} key={companentData.id}>
                                       <div className={classes.gamespadding}> 
                                            <UserGames themeColors={props.themeColors} sectionPopup={props.sectionPopup} gameIds={companentData.data_contents} />
                                       </div>
                                    </GridItem>
                                ];
                             }else if(companentData.cms_component_type_id == "1"){
                                /*var dataInVar = () => container + (
                                     <GridItem md={companentData.column_width} className={newLineClass}>
                                         <div className={classes.titledesc}  dangerouslySetInnerHTML={createMarkup(companentData.data_contents)}></div> 
                                    </GridItem> 
                                ) + ((dataOfComponents.length == runComponent && isContainerStart == true) ? containerend : "");*/
                                checkByContainer = [
                                    ...checkByContainer,
                                    <GridItem md={companentData.column_width} className={newLineClass} key={companentData.id}>
                                        <div className={classes.titledesc + " textpage"}  dangerouslySetInnerHTML={createMarkup(companentData.data_contents)}></div> 
                                        {/* <Contentbox className={classes.titledesc + " textpage"}  dangerouslySetInnerHTML={createMarkup(companentData.data_contents)}/> */}
                                   </GridItem>
                                ];
                                /*return[
                                    ReactHtmlParser(container),
                                    <GridItem md={companentData.column_width} className={newLineClass} >
                                        <div className={classes.titledesc}  dangerouslySetInnerHTML={createMarkup(companentData.data_contents)}></div> 
                                   </GridItem>,
                                   ((dataOfComponents.length == runComponent && isContainerStart == true) ? ReactHtmlParser(containerend) : "")
                                ]*/
                             }else if(companentData.cms_component_type_id == "2"){ 
                              //   alert(JSON.stringify(companentData));
                                /*var dataInVar = () => container + (
                                     <GridItem xs={companentData.column_width} className={newLineClass}>   
                                           <h2 className={classes.title}>{companentData.title}</h2> 
                                            <Mediacontent themeColors={props.themeColors} mediaIds={companentData.data_contents} />  
                                     </GridItem>
                                 ) + ((dataOfComponents.length == runComponent && isContainerStart == true) ? containerend : "");*/
                                checkByContainer = [
                                    ...checkByContainer,
                                    <GridItem xs={companentData.column_width} className={newLineClass} key={companentData.id}>   
                                         <h2 className={classes.title}>{companentData.title}</h2> 
                                         <Mediacontent themeColors={props.themeColors} sectionPopup={props.sectionPopup} mediaIds={companentData.data_contents} />  
                                    </GridItem>
                                ];
                                /*return[  
                                     ReactHtmlParser(container),
                                     <GridItem xs={companentData.column_width} className={newLineClass}>   
                                         <h2 className={classes.title}>{companentData.title}</h2> 
                                         <Mediacontent themeColors={props.themeColors} mediaIds={companentData.data_contents} />  
                                     </GridItem>,
                                     ((dataOfComponents.length == runComponent && isContainerStart == true) ? ReactHtmlParser(containerend) : "")
                                 ]*/
                             }else if(companentData.cms_component_type_id == "3"){ 
                                // alert(JSON.stringify(companentData));
                                /*var dataInVar = () => container + (
                                     <GridItem xs={companentData.column_width} className={newLineClass}>    
                                         <Mediacontent themeColors={props.themeColors} mediaIds={companentData.data_contents.replace(/ /g,"")} />
                                     </GridItem>
                                 ) + ((dataOfComponents.length == runComponent && isContainerStart == true) ? containerend : "");*/
                                checkByContainer = [
                                    ...checkByContainer,
                                    <GridItem xs={companentData.column_width} className={newLineClass} key={companentData.id}>    
                                         <Mediacontent themeColors={props.themeColors} sectionPopup={props.sectionPopup} mediaIds={companentData.data_contents.replace(/ /g,"")} />
                                    </GridItem>
                                ]; 
                                /*return[
                                     ReactHtmlParser(container),
                                     <GridItem xs={companentData.column_width} className={newLineClass}>    
                                         <Mediacontent themeColors={props.themeColors} mediaIds={companentData.data_contents.replace(/ /g,"")} />
                                     </GridItem>,
                                     ((dataOfComponents.length == runComponent && isContainerStart == true) ? ReactHtmlParser(containerend) : "")
                                 ]*/
                             }else if(companentData.cms_component_type_id == "9" && companentData.data_contents.length > 0){ 
                                // alert(JSON.stringify(companentData));
                                /*var dataInVar = () => container + (
                                     <GridItem xs={companentData.column_width} className={newLineClass}>    
                                         <Mediacontent themeColors={props.themeColors} mediaIds={companentData.data_contents.replace(/ /g,"")} />
                                     </GridItem>
                                 ) + ((dataOfComponents.length == runComponent && isContainerStart == true) ? containerend : "");*/
                                checkByContainer = [
                                    ...checkByContainer,
                                    <GridItem xs={companentData.column_width} className={newLineClass} key={companentData.id}>    
                                         <SectionFaqtabs themeColors={props.themeColors} sectionPopup={props.sectionPopup} faqIds={companentData.data_contents.replace(/ /g,"")} />
                                    </GridItem>
                                ]; 
                                /*return[
                                     ReactHtmlParser(container),
                                     <GridItem xs={companentData.column_width} className={newLineClass}>    
                                         <Mediacontent themeColors={props.themeColors} mediaIds={companentData.data_contents.replace(/ /g,"")} />
                                     </GridItem>,
                                     ((dataOfComponents.length == runComponent && isContainerStart == true) ? ReactHtmlParser(containerend) : "")
                                 ]*/
                             }else if(companentData.cms_component_type_id == "12"){ 
                                // alert(JSON.stringify(companentData));
                                /*var dataInVar = () => container + (
                                     <GridItem xs={companentData.column_width} className={newLineClass}>    
                                         <Mediacontent themeColors={props.themeColors} mediaIds={companentData.data_contents.replace(/ /g,"")} />
                                     </GridItem>
                                 ) + ((dataOfComponents.length == runComponent && isContainerStart == true) ? containerend : "");*/
                                checkByContainer = [
                                    ...checkByContainer,
                                    <GridItem xs={companentData.column_width} className={newLineClass} key={companentData.id}>    
                                         <SectionPromotions sectionPopup={props.sectionPopup} themeColors={props.themeColors} />
                                    </GridItem>
                                ]; 
                                /*return[
                                     ReactHtmlParser(container),
                                     <GridItem xs={companentData.column_width} className={newLineClass}>    
                                         <Mediacontent themeColors={props.themeColors} mediaIds={companentData.data_contents.replace(/ /g,"")} />
                                     </GridItem>,
                                     ((dataOfComponents.length == runComponent && isContainerStart == true) ? ReactHtmlParser(containerend) : "")
                                 ]*/
                             }else if(companentData.cms_component_type_id == "8" && companentData.data_contents.length > 0){ 
                                // alert(JSON.stringify(companentData));
                                /*var dataInVar = () => container + (
                                     <GridItem xs={companentData.column_width} className={newLineClass}>    
                                         <Mediacontent themeColors={props.themeColors} mediaIds={companentData.data_contents.replace(/ /g,"")} />
                                     </GridItem>
                                 ) + ((dataOfComponents.length == runComponent && isContainerStart == true) ? containerend : "");*/
                                 var innerDataComponent = JSON.parse(companentData.data_contents);
                                checkByContainer = [
                                    ...checkByContainer,
                                    <GridItem xs={companentData.column_width} className={newLineClass} key={companentData.id}>    
                                         <SectionWinners sectionPopup={props.sectionPopup} themeColors={props.themeColors} gameIds={innerDataComponent.game_ids.replace(/ /g,"")} design={innerDataComponent.design}  record={innerDataComponent.record} />
                                    </GridItem>
                                ]; 
                                /*return[
                                     ReactHtmlParser(container),
                                     <GridItem xs={companentData.column_width} className={newLineClass}>    
                                         <Mediacontent themeColors={props.themeColors} mediaIds={companentData.data_contents.replace(/ /g,"")} />
                                     </GridItem>,
                                     ((dataOfComponents.length == runComponent && isContainerStart == true) ? ReactHtmlParser(containerend) : "")
                                 ]*/
                             }else if(companentData.cms_component_type_id == "4" && companentData.data_contents == "CONTACT"){ 
                                /*var dataInVar = () => container + (
                                     <GridItem xs={companentData.column_width} className={newLineClass} >
                                         <h2 className={classes.title}>{companentData.title}</h2> 
                                         <Contact themeColors={props.themeColors} />  
                                     </GridItem>
                                 ) + ((dataOfComponents.length == runComponent && isContainerStart == true) ? containerend : "");*/
                                checkByContainer = [
                                    ...checkByContainer,
                                    <GridItem xs={companentData.column_width} className={newLineClass} key={companentData.id}>
                                         <h2 className={classes.title}>{companentData.title}</h2> 
                                         <Contact sectionPopup={props.sectionPopup} themeColors={props.themeColors} />  
                                    </GridItem>
                                ]; 
                                /*return [
                                     ReactHtmlParser(container),
                                     <GridItem xs={companentData.column_width} className={newLineClass} >
                                         <h2 className={classes.title}>{companentData.title}</h2> 
                                         <Contact themeColors={props.themeColors} />  
                                     </GridItem>,
                                     ((dataOfComponents.length == runComponent && isContainerStart == true) ? ReactHtmlParser(containerend) : "")
                                 ]*/
                             }else if(companentData.cms_component_type_id == "4" && companentData.data_contents == "INQUIRY"){ 
                                /*var dataInVar = () => container + (
                                     <GridItem xs={companentData.column_width}  className={newLineClass}>
                                         <h2 className={classes.title}>{companentData.title}</h2> 
                                         <Inquiry themeColors={props.themeColors} />  
                                     </GridItem>  
                                 ) + ((dataOfComponents.length == runComponent && isContainerStart == true) ? containerend : "");*/
                                checkByContainer = [
                                    ...checkByContainer,
                                    <GridItem xs={companentData.column_width}  className={newLineClass} key={companentData.id}>
                                         <h2 className={classes.title}>{companentData.title}</h2> 
                                         <Inquiry sectionPopup={props.sectionPopup} themeColors={props.themeColors} />  
                                     </GridItem>
                                ];  
                                 /*return[
                                     ReactHtmlParser(container),
                                     <GridItem xs={companentData.column_width}  className={newLineClass}>
                                         <h2 className={classes.title}>{companentData.title}</h2> 
                                         <Inquiry themeColors={props.themeColors} />  
                                     </GridItem>,
                                     ((dataOfComponents.length == runComponent && isContainerStart == true) ? ReactHtmlParser(containerend) : "")
                                 ]*/
                             }
                            //alert(companentData.is_container + "-:::-" + containerGroup + "-::::-" + companentData.container_group + "-::::-" + isContainerStart)
                             if(companentData.is_container == 1 && containerGroup != companentData.container_group){
                                var isCheck = false;
                                if(isContainerStart == true){
                                    checkByContainerFinal = [
                                        <div className='container' key={companentData.id}>
                                            <GridContainer className={classes.gridcenter}>
                                                {checkByContainer}
                                            </GridContainer>
                                        </div>
                                    ];
                                    //alert("1:"+checkByContainerFinal)
                                    checkByContainer = [];
                                    isCheck = true;
                                }else{
                                    checkByContainerFinal = [
                                        <div className='container ss' key={companentData.id}>
                                            <GridContainer className={classes.gridcenter}>
                                                {checkByContainer}
                                            </GridContainer>
                                        </div>
                                    ];
                                    //alert("1:"+checkByContainerFinal)
                                    checkByContainer = [];
                                    isCheck = true;
                                }
                                isContainerStart = true;
                                containerGroup = companentData.container_group;
                                if(isCheck === true){
                                    return checkByContainerFinal;
                                }
                                
                            }else if(companentData.is_container != 1 && isContainerStart == true){
                                checkByContainerFinal = [
                                    <div className='container' key={companentData.id}>
                                        <GridContainer className={classes.gridcenter}>
                                            {checkByContainer}
                                        </GridContainer>
                                    </div>
                                ]
                                //alert("2:"+checkByContainerFinal)
                                isContainerStart = false;
                                checkByContainer = [];
                                return checkByContainerFinal;
                            }else if(companentData.is_container != 1){
                                checkByContainerFinal = [
                                    <GridContainer className={classes.gridcenter} key={companentData.id}>
                                        {checkByContainer}
                                    </GridContainer>
                                ]
                                isContainerStart = false;
                                ///alert("3:"+checkByContainerFinal)
                                checkByContainer = [];
                                return checkByContainerFinal;
                            }else if(dataOfComponents.length == runComponent){
                                if(isContainerStart == true){
                                    checkByContainerFinal = [
                                        <div className='container qq' key={companentData.id}>
                                            <GridContainer className={classes.gridcenter}>
                                                {checkByContainer}
                                            </GridContainer>
                                        </div>
                                    ]
                                }else{
                                    checkByContainerFinal = [
                                        <GridContainer className={classes.gridcenter} key={companentData.id}>
                                            {checkByContainer}
                                        </GridContainer>
                                    ]
                                }
                                //alert("5:"+checkByContainerFinal)
                                checkByContainer = [];
                                return checkByContainerFinal;
                            }  
                            
                         });
                        
                        //  subhJourney.map((checkForInner) => {
                        //      return[<div>,<div>MYCHECK</div>]
                        //  })
                        //alert("4:"+checkByContainerFinal);
                         return [subhJourney]
                            
                            
                    }else{
                        
                        if(currentLocation[1] != "deposit" && currentLocation[1] != "withdraw" && currentLocation[1] != "verifyToken" && currentLocation[1] != "verifyemail" && currentLocation[1] != "verifyphone" && currentLocation[1] != "pcbypg" && currentLocation[1] != "offer" && currentLocation[1] != "stats" && currentLocation[1] != "loginhistory" && currentLocation[1] != "mybonus" && currentLocation[1] != "mybets"  && currentLocation[1] != "playnow" && currentLocation[1] != "account" && currentLocation[1] != "hometab" && currentLocation[1] != "statement" && currentLocation[1] != "message" && currentLocation[1] != "promotions" && currentLocation[1] != "dashboard" && currentLocation[1] != "sports" ){
                            //return (<div></div>);
                            // return(
                              //   <Route path="*" >
                                //    <Fourzerofour themeColors={myTheme}/>  
                                     
                                // </Route>
                            // )
                        }else if(currentLocation[1] == "verifyToken"){
                            return (
                                <div className="containergame">
                                    <Route path="/verifyToken">
                                        <VerifyEmailtoken sectionPopup={props.sectionPopup} themeColors={myTheme} {...props}/>
                                    </Route>
                                </div>
                            )
                        }else if(currentLocation[1] == "verifyemail"){
                            return (
                                <div className="containergame">
                                    <Route path="/verifyemail" >
                                        <Verifyemail sectionPopup={props.sectionPopup} themeColors={myTheme} {...props}/>
                                    </Route> 
                                </div>
                            )
                        }else if(currentLocation[1] == "verifyphone"){
                            return (
                                <div className="containergame">
                                    <Route path="/verifyphone" >
                                        <Verifyphone sectionPopup={props.sectionPopup} themeColors={myTheme} {...props}/>
                                    </Route>
                                    
                                </div>
                            )
                        }else if(currentLocation[1] == "pcbypg"){
                            return (
                                <div className="containergame">
                                    <Route path="/pcbypg" >
                                        <Pcbypg sectionPopup={props.sectionPopup} themeColors={myTheme}  {...props}/>
                                    </Route>
                                </div>
                            )
                        }else if(jwtService.isAuthenticationLogin() == true && currentLocation[1] == "playnow"){
                            return (
                                <div className="containergame">
                                    <Route path="/playnow" >
                                        <Playnow sectionPopup={props.sectionPopup} themeColors={myTheme}/>
                                    </Route>
                                </div>
                            )
                        }else{
                            
                            return(
                                <div className=" ">
                                    <div className={classes.logininerpg}>
                                        <Route path="/deposit" >
                                            <Deposit sectionPopup={props.sectionPopup} themeColors={myTheme}/>
                                        </Route>
                                        <Route path="/withdraw" >
                                            <Withdraw sectionPopup={props.sectionPopup} themeColors={myTheme}/>
                                        </Route> 
                                        {/* <Route path="/stats" >
                                            <Stats sectionPopup={props.sectionPopup} themeColors={myTheme}/>
                                        </Route>  */}
                                        <Route path="/loginhistory" >
                                            <LoginHistory sectionPopup={props.sectionPopup} themeColors={myTheme}/>
                                        </Route> 
                                        <Route path="/mybonus" >
                                            <MyBonus sectionPopup={props.sectionPopup} themeColors={myTheme}/>
                                        </Route> 
                                        <Route path="/mybets" >
                                            <MyBets sectionPopup={props.sectionPopup} themeColors={myTheme}/>
                                        </Route>  
                                        <Route path="/account" >
                                            <Account sectionPopup={props.sectionPopup} themeColors={myTheme}/>
                                        </Route>
                                        <Route path="/hometab" >
                                            <Hometab sectionPopup={props.sectionPopup} themeColors={myTheme}/>
                                        </Route>
                                        <Route path="/statement" >
                                            <Statement sectionPopup={props.sectionPopup} themeColors={myTheme}/>
                                        </Route>
                                        <Route path="/message" >
                                            <Message sectionPopup={props.sectionPopup} themeColors={myTheme}/>
                                        </Route>
                                        <Route path="/promotions" >
                                            <Promotions themeColors={myTheme}/>
                                        </Route>
                                        <Route path="/sports" >
                                            <Sports sectionPopup={props.sectionPopup} themeColors={myTheme}/>
                                        </Route>
                                        <Route path="/Offer" >
                                            <Offer sectionPopup={props.sectionPopup} themeColors={myTheme}/>
                                        </Route>
                                        
                                    </div>
                                </div>
                            )
                        }
                        
                    }
                })()
            }
            
        </div>
    );
}


const tabnotificationDb = {
    en: [
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-envelope-open',  
            'noti_name': 'Internal Messages',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-bell',  
            'noti_name': 'Push Notifications',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-phone',  
            'noti_name': 'Phone Call ',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-envelope',  
            'noti_name': 'Email ',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-comments',  
            'noti_name': 'SMS',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-gamepad',  
            'noti_name': 'Bets',  
            'noti_disable':"disablebox",   
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-trophy',  
            'noti_name': 'Winners',   
            'noti_disable':"disablebox",  
        },
    ],
    tm: [
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-envelope-open',  
            'noti_name': 'உள் செய்திகள்',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-bell',  
            'noti_name': 'அறிவிப்புகளை அழுத்துக',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-phone',  
            'noti_name': 'தொலைபேசி அழைப்பு ',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-envelope',  
            'noti_name': 'மின்னஞ்சல்',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-comments',  
            'noti_name': 'எஸ்.எம்.எஸ்',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-gamepad',  
            'noti_name': 'பெட்ஸ்',  
            'noti_disable':"disablebox",   
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-trophy',  
            'noti_name': 'வெற்றியாளர்கள்',   
            'noti_disable':"disablebox",  
        },
    ],
    kn: [
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-envelope-open',  
            'noti_name': 'ಆಂತರಿಕ ಸಂದೇಶಗಳು',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-bell',  
            'noti_name': 'ಅಧಿಸೂಚನೆಗಳನ್ನು ಒತ್ತಿರಿ',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-phone',  
            'noti_name': 'ದೂರವಾಣಿ ಕರೆ ',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-envelope',  
            'noti_name': 'ಇಮೇಲ್',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-comments',  
            'noti_name': 'ಎಸ್‌ಎಂಎಸ್',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-gamepad',  
            'noti_name': 'ಬೆಟ್ಸ್',  
            'noti_disable':"disablebox",   
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-trophy',  
            'noti_name': 'ವಿಜೇತರು',   
            'noti_disable':"disablebox",  
        },
    ],
    ne: [
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-envelope-open',  
            'noti_name': 'आन्तरिक सन्देशहरू',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-bell',  
            'noti_name': 'सूचनाहरु पुश गर्नुहोस्',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-phone',  
            'noti_name': 'फोन कल',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-envelope',  
            'noti_name': 'ईमेल',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-comments',  
            'noti_name': 'एस एम एस',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-gamepad',  
            'noti_name': 'बेट्स',  
            'noti_disable':"disablebox",   
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-trophy',  
            'noti_name': 'विजेता',   
            'noti_disable':"disablebox",  
        },
    ],
    tl: [
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-envelope-open',  
            'noti_name': 'అంతర్గత సందేశాలు',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-bell',  
            'noti_name': 'పుష్ నోటిఫికేషన్‌లు',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-phone',  
            'noti_name': 'ఫోన్ కాల్',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-envelope',  
            'noti_name': 'ఇమెయిల్',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-comments',  
            'noti_name': 'SMS',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-gamepad',  
            'noti_name': 'పందెం',  
            'noti_disable':"disablebox",   
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-trophy',  
            'noti_name': 'విజేతలు',   
            'noti_disable':"disablebox",  
        },
    ],
    hi: [
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-envelope-open',  
            'noti_name': 'आंतरिक संदेश',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-bell',  
            'noti_name': 'सूचनाएं भेजना',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-phone',  
            'noti_name': 'फ़ोन कॉल ',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-envelope',  
            'noti_name': 'ईमेल',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-comments',  
            'noti_name': 'एसएमएस',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-gamepad',  
            'noti_name': 'दांव',  
            'noti_disable':"disablebox",  
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-trophy',  
            'noti_name': 'विजेताओं',
            'noti_disable':"disablebox",    
        },
    ]
}
export default tabnotificationDb;



const menusidebartopDb = {
    en: [
        {
            'id'    : '5725a680b3249761ea21de51',
            'username'  : 'Username',
            'emailid' : ' ', 
        } 
    ],
    tm: [
        {
            'id'    : '5725a680b3249761ea21de51',
            'username'  : 'பயனர்பெயர்',
            'emailid' : ' ', 
        } 
    ],
    kn: [
        {
            'id'    : '5725a680b3249761ea21de51',
            'username'  : 'ಬಳಕೆದಾರ ಹೆಸರು',
            'emailid' : ' ', 
        } 
    ],
    ne: [
        {
            'id'    : '5725a680b3249761ea21de51',
            'username'  : 'प्रयोगकर्ता नाम',
            'emailid' : ' ', 
        } 
    ],
    tl: [
        {
            'id'    : '5725a680b3249761ea21de51',
            'username'  : 'వినియోగదారు పేరు',
            'emailid' : ' ', 
        } 
    ],
    hi: [
        {
            'id'    : '5725a680b3249761ea21de52',
            'username'  : 'उपयोगकर्ता नाम',
            'emailid' : 'इन्फो@जीमेल.कॉम ',
        } 
    ],

};

export default menusidebartopDb;
const kycmoduleDb = {
    en: [ 
        {
            'id':'3923923894', 
            'aadharcard': 'Aadhar Card', 
            'pancard': 'Pan Card', 
            'driving_license': 'Driving License', 
            'aadhar_title': 'Verify Aadhar Card',
            'aadhar_one': 'Front Side Upload',
            'aadhar_two': 'Back Side Upload',
            'aadhar_verify_btn': 'Upload',
            'pan_verify_btn': 'Upload',
            'dl_verify_btn': 'Upload',
            'pan_title': 'Verify Aadhar Card',
            'pan_one': 'Front Side Upload',
            'pan_two': 'Back Side Upload', 
            'dl_title': 'Verify Aadhar Card',
            'dl_one': 'Front Side Upload',
            'dl_two': 'Back Side Upload', 
            'selecttypeid':"Please select your type of ID",
            'doctype':"Document type",
            'uploaddate':"Upload date",
            'images':"Images",
            'status':"Status",
        },
    ],
    tm: [ 
        {
            'id':'3923923894',  
            'aadharcard': 'ஆதார் அட்டை',
            'pancard': 'பான் கார்டு',
            'driving_license': 'ஓட்டுநர் உரிமம்',
            'aadhar_title': 'ஆதார் அட்டையைச் சரிபார்க்கவும்',
            'aadhar_one': 'முன் பக்க பதிவேற்றம்',
            'aadhar_two': 'பின் பக்க பதிவேற்றம்',
            'aadhar_verify_btn': 'பதிவேற்று',
            'pan_verify_btn': 'பதிவேற்று',
            'dl_verify_btn': 'பதிவேற்று',
            'pan_title': 'ஆதார் அட்டையைச் சரிபார்க்கவும்',
            'pan_one': 'முன் பக்க பதிவேற்றம்',
            'pan_two': 'பின் பக்க பதிவேற்றம்', 
            'dl_title': 'ஆதார் அட்டையைச் சரிபார்க்கவும்',
            'dl_one': 'முன் பக்க பதிவேற்றம்',
            'dl_two': 'பின் பக்க பதிவேற்றம்', 
            'selecttypeid': "தயவுசெய்து உங்கள் ஐடி வகையைத் தேர்ந்தெடுக்கவும்",
            'doctype': "ஆவண வகை",
            'uploaddate': "பதிவேற்ற தேதி",
            'images': "படங்கள்",
            'status': "நிலை",
        },
    ],
    kn: [ 
        {
            'id':'3923923894',  
            'aadharcard': 'ಆಧಾರ್ ಕಾರ್ಡ್',
            'pancard': 'ಪ್ಯಾನ್ ಕಾರ್ಡ್',
            'driving_license': 'ಚಾಲನಾ ಪರವಾನಗಿ',
            'aadhar_title': 'ಆಧಾರ್ ಕಾರ್ಡ್ ಪರಿಶೀಲಿಸಿ',
            'aadhar_one': 'ಫ್ರಂಟ್ ಸೈಡ್ ಅಪ್‌ಲೋಡ್',
            'aadhar_two': 'ಬ್ಯಾಕ್ ಸೈಡ್ ಅಪ್‌ಲೋಡ್',
            'aadhar_verify_btn': 'ಅಪ್‌ಲೋಡ್ ಮಾಡಿ',
            'pan_verify_btn': 'ಅಪ್‌ಲೋಡ್ ಮಾಡಿ',
            'dl_verify_btn': 'ಅಪ್‌ಲೋಡ್ ಮಾಡಿ',
            'pan_title': 'ಆಧಾರ್ ಕಾರ್ಡ್ ಪರಿಶೀಲಿಸಿ',
            'pan_one': 'ಫ್ರಂಟ್ ಸೈಡ್ ಅಪ್‌ಲೋಡ್',
            'pan_two': 'ಬ್ಯಾಕ್ ಸೈಡ್ ಅಪ್‌ಲೋಡ್', 
            'dl_title': 'ಆಧಾರ್ ಕಾರ್ಡ್ ಪರಿಶೀಲಿಸಿ',
            'dl_one': 'ಫ್ರಂಟ್ ಸೈಡ್ ಅಪ್‌ಲೋಡ್',
            'dl_two': 'ಬ್ಯಾಕ್ ಸೈಡ್ ಅಪ್‌ಲೋಡ್', 
            'selecttypeid': "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಪ್ರಕಾರದ ID ಯನ್ನು ಆರಿಸಿ",
            'doctype': "ಡಾಕ್ಯುಮೆಂಟ್ ಪ್ರಕಾರ",
            'uploaddate': "ಅಪ್‌ಲೋಡ್ ದಿನಾಂಕ",
            'images': "ಚಿತ್ರಗಳು",
            'status': "ಸ್ಥಿತಿ",
        },
    ],
    ne: [ 
        {
            'id':'3923923894',  
            'aadharcard': 'आधार कार्ड',
            'pancard': 'पान कार्ड',
            'driving_license': 'ड्राइभिंग इजाजतपत्र',
            'aadhar_title': 'आधार कार्ड प्रमाणित गर्नुहोस्',
            'aadhar_one': 'फ्रन्ट साइड अपलोड',
            'aadhar_two': 'पछाडि साइड अपलोड',
            'aadhar_verify_btn': 'अपलोड',
            'pan_verify_btn': 'अपलोड',
            'dl_verify_btn': 'अपलोड',
            'pan_title': 'आधार कार्ड प्रमाणित गर्नुहोस्',
            'pan_one': 'फ्रन्ट साइड अपलोड',
            'pan_two': 'पछाडि साइड अपलोड', 
            'dl_title': 'आधार कार्ड प्रमाणित गर्नुहोस्',
            'dl_one': 'फ्रन्ट साइड अपलोड',
            'dl_two': 'पछाडि साइड अपलोड', 
            'selecttypeid': "कृपया तपाइँको आईडी प्रकार चयन गर्नुहोस्",
            'doctype': "कागजात प्रकार",
            'uploaddate': "अपलोड मिति",
            'images': "छविहरू",
            'status': "स्थिति",
        },
    ],
    tl: [ 
        {
            'id':'3923923894', 
            'aadharcard': 'ఆధార్ కార్డు', 
            'pancard': 'పాన్ కార్డ్', 
            'driving_license': 'వాహనం నడపడానికి చట్టబద్ధమైన అర్హత', 
            'aadhar_title': 'ఆధార్ కార్డును ధృవీకరించండి',
            'aadhar_one': 'ఫ్రంట్ సైడ్ అప్‌లోడ్',
            'aadhar_two': 'బ్యాక్ సైడ్ అప్‌లోడ్',
            'aadhar_verify_btn': 'అప్‌లోడ్ చేయండి',
            'pan_verify_btn': 'అప్‌లోడ్ చేయండి',
            'dl_verify_btn': 'అప్‌లోడ్ చేయండి',
            'pan_title': 'ఆధార్ కార్డును ధృవీకరించండి',
            'pan_one': 'ఫ్రంట్ సైడ్ అప్‌లోడ్',
            'pan_two': 'బ్యాక్ సైడ్ అప్‌లోడ్', 
            'dl_title': 'ఆధార్ కార్డును ధృవీకరించండి',
            'dl_one': 'ఫ్రంట్ సైడ్ అప్‌లోడ్',
            'dl_two': 'బ్యాక్ సైడ్ అప్‌లోడ్', 
            'selecttypeid':"దయచేసి మీ ఐడి రకాన్ని ఎంచుకోండి",
            'doctype':"దస్తావేజు పద్దతి",
            'uploaddate':"అప్‌లోడ్ తేదీ",
            'images':"చిత్రాలు",
            'status':"స్థితి",
        },
    ],
    hi: [ 
        {
            'id':'3923923894', 
            'aadharcard': 'आधार कार्ड', 
            'pancard': 'पैन कार्ड', 
            'driving_license': 'ड्राइविंग लाइसेंस',  
            'aadhar_title': 'आधार कार्ड सत्यापित करें',
            'aadhar_one': 'फ्रंट साइड अपलोड',
            'aadhar_two': 'बैक साइड अपलोड',
            'aadhar_verify_btn': 'अपलोड',
            'pan_verify_btn': 'अपलोड',
            'dl_verify_btn': 'अपलोड',
            'pan_title': 'पैन कार्ड सत्यापित करें',
            'pan_one': 'फ्रंट साइड अपलोड',
            'pan_two': 'बैक साइड अपलोड', 
            'dl_title': 'ड्राइविंग लाइसेंस सत्यापित करें',
            'dl_one': 'फ्रंट साइड अपलोड',
            'dl_two': 'बैक साइड अपलोड', 
            'selecttypeid':"कृपया अपनी आईडी का चयन करें",
            'doctype':"दस्तावेज़ का प्रकार",
            'uploaddate':"अपलोड की तारीख",
            'images':"छवि",
            'status':"स्थिति",
        },
    ]
}
export default kycmoduleDb;



// import Background from '../../img/sm-bg-1.jpg';
// import Background4 from '../../img/smbg.jpg';

const landingPageStyle = {
  about:{

  },
  successpage:{
    background:"#ddffcb !important"
  },
  failpage:{
    background:"#fad5d5 !important"
  },
  tablebox:{
    width:"100%",
    margin:"20px 0"
  },
  landingpagein:{
    width:"1250px",
    margin:"0px auto",
    ['@media (max-width:1340px)']: { // eslint-disable-line no-useless-computed-key 
      width: "100%",
    }, 
    ['@media (max-width:599px)']: { // eslint-disable-line no-useless-computed-key 
      textAlign:"center",
      
    },
  },
  landingpage:{
    background:"#f1f1f1",
    position:"fixed",
    width:"100%",
    padding:"4%",
    height:"100%",
    zIndex: "9",
    "&::before":{
      content: "''",
      position: "fixed",
      width: "75%",
      height: "100%",
      top: "0",
      left: "50%",
      transform: "skew(-45deg) translateX(0%)",
      background: "#ef1d26",
      ['@media (max-width:599px)']: { // eslint-disable-line no-useless-computed-key 
        position: "unset",
      },
    },
    "& h3":{
      color:"#1d1d1d",
      fontFamily:"system-ui",
      fontSize:"34px",
      fontWeight:"bold",
      margin:"30px 0",
      ['@media (max-width:1300px)']: { // eslint-disable-line no-useless-computed-key 
        fontSize:"28px",
      },
      ['@media (max-width:1090px)']: { // eslint-disable-line no-useless-computed-key 
        fontSize:"22px",
      }, 
      ['@media (max-width:850px)']: { // eslint-disable-line no-useless-computed-key 
        fontSize:"18px",
      },
      ['@media (max-width:599px)']: { // eslint-disable-line no-useless-computed-key 
        margin:"10px 0",
        "& br":{
          display:"none"
        }
      },
    },
    "& h4":{
      color:"#1d1d1d",
      fontFamily:"system-ui",
      fontSize:"32px",
      textAlign:"center",
      fontWeight:"bold",
      marginBottom:"30px",
      ['@media (max-width:1090px)']: { // eslint-disable-line no-useless-computed-key 
        fontSize:"22px",
      },
      ['@media (max-width:599px)']: { // eslint-disable-line no-useless-computed-key 
        margin:"10px 0",
        fontSize:"18px",
      },
    },
    "& h3 span":{
      color:"#ef1d26", 
    },
    ['@media (max-width:599px)']: { // eslint-disable-line no-useless-computed-key  
      overflow:"auto",
      
    },
  },
  landinglogo:{
    width:"auto",
    height:"100px",
    marginTop:"30%",
    ['@media (max-width:959px)']: { // eslint-disable-line no-useless-computed-key 
      marginTop:"20%",
      height:"80px",
    },
    ['@media (max-width:850px)']: { // eslint-disable-line no-useless-computed-key 
      marginTop:"20%",
      height:"60px",
    },
    ['@media (max-width:599px)']: { // eslint-disable-line no-useless-computed-key 
      height:"60px",
      margin:"0px auto",
    },
  },  
  joinnowbtn:{
    background: "#ef1d26",
    borderRadius:"40px",
    padding:"10px 30px",
    color:"#fff",
    fontFamily:"ubuntu",
    fontSize:"18px",
    fontWeight:"bold",
    ['@media (max-width:850px)']: { // eslint-disable-line no-useless-computed-key 
      padding:"6px 26px",
      fontSize:"14px",
    },
    "&:hover":{
      background: "#ef1d26",
      
    }
  },
  screens:{
    width:"100%",
    border:"3px solid #fff",
    borderRadius:"10px",
    marginBottom:"30px"
  },
   
  otpwrap:{
    borderRadius: "10px",
    width:"40%",
    margin:"10px auto",  
    background:"#fff",
    display:"none",
    boxShadow: "0px 0px 5px #ccc",
    padding:"10px",
    "& fieldset":{
      border:"0px",
     
    },
    "& form":{
      margin:"0px",     
    },
    "& p":{
      margin: "-10px 0px 10px",
      fontSize: "14px",
      fontFamily: "ubuntu",
    },
    "& input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button ":{
      opacity: "0",  
    },
    "& h4":{
      fontFamily:"ubuntu",
      color:"#000",
      fontSize:"24px",
      textAlign:"center",
      fontWeight: "bold",
      marginBottom: "0",
    },
    "& label":{
      border:"0px",
      color:"#3c3c3c",
      fontSize:"16px",
      transform: "translate(0px, 10px) scale(1)",
      fontFamily:"ubuntu",
      ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key 
        paddingTop: "15px",
      }, 
    },
    "& label.MuiFormLabel-root.Mui-focused":{
      color:"#3c3c3c !important"
    },
    "& input":{
      fontFamily:"ubuntu",
      color:"#3c3c3c",
      fontSize:"14px",
      padding:"20px 0px 10px 0px",
      marginBottom:"20px",
      borderBottom:"1px solid #3c3c3c",
      ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key 
        marginBottom:"10px", 
        fontSize:"14px",
        paddingTop: "30px",
      },
    },
    "& div":{
      width:"100%", 
      marginTop:"0px",
      marginRight: "0px",
      float: "right",
      ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key 
        margin:"0%", 
      },
    },
    "& div div":{ 
      marginTop:"0px"
    },
    ['@media (max-width:1200px)']: { // eslint-disable-line no-useless-computed-key 
      width:"80%", 
      left:"10%",
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      // background:"rgba(0,0,0,0.6)",
      width:"96%",
      // height:"100%",
      padding:"5% 10%",
      margin:"0px auto",
      float:"none",
      // position: "fixed", 
      // overflow:"auto",
      top:"0%", 
      right:"auto",
      "& #main_text":{
        marginBottom:"40px"    
      },
      "& .resendbtn":{
        marginTop:"-120px !important"
      }
    },
    ['@media (max-width:460px)']: { // eslint-disable-line no-useless-computed-key 
      padding:"5% 2%", 
    },
  },
  orrow:{
    display:"block",
    margin:"0px",
    marginBottom: "-30px !important",
    marginTop: "10px  !important",
  },
  forgotwrap:{
    borderRadius: "10px",
    width:"40%",
    margin:"10px auto",  
    background:"#fff",
    boxShadow: "0px 0px 5px #ccc",
    display:"none",
    padding:"10px",
    "& fieldset":{
      border:"0px",
     
    },
    "& form":{
      margin:"0px",     
    },
    "& p":{
      margin: "-10px 0px 10px",
      fontSize: "14px",
      fontFamily: "ubuntu",
    },
    "& input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button ":{
      opacity: "0",  
    },
    "& h4":{
      fontFamily:"ubuntu",
      color:"#000",
      fontSize:"24px",
      textAlign:"center",
      fontWeight: "bold",
      marginBottom: "0",
    },
    "& label":{
      border:"0px",
      color:"#3c3c3c",
      fontSize:"16px",
      transform: "translate(0px, 10px) scale(1)",
      fontFamily:"ubuntu",
      ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key 
        paddingTop: "15px",
      }, 
    },
    "& label.MuiFormLabel-root.Mui-focused":{
      color:"#3c3c3c !important"
    },
    "& input":{
      fontFamily:"ubuntu",
      color:"#3c3c3c",
      fontSize:"14px",
      padding:"20px 0px 10px 0px",
      marginBottom:"20px",
      borderBottom:"1px solid #3c3c3c",
      ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key 
        marginBottom:"10px", 
        fontSize:"14px",
        paddingTop: "30px",
      },
    },
    "& div":{
      width:"100%", 
      marginTop:"0px",
      marginRight: "0px",
      float: "right",
      ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key 
        margin:"0%", 
      },
    },
    "& div div":{ 
      marginTop:"0px"
    },
    ['@media (max-width:1200px)']: { // eslint-disable-line no-useless-computed-key 
      width:"80%", 
      left:"10%",
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      width:"96%", 
      margin:"0px auto",
      float:"none", 
    },
  },
  mnumber:{
    "& input[type=number]::-webkit-outer-spin-button,input[type=number]::-webkit-inner-spin-button": {
      appearance: "none",
    }, 
  },
  girlimg:{ 
    borderRadius: "20px", 
    margin:"0px auto",
    display:"block",
    width: "auto",
    maxWidth: "20%",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
       
      width: "auto",
      maxWidth: "inherit",
      height: "70px", 
      marginTop: "-20px",
    },
  },
  dropi:{
    color:"#000 !important",
    "& svg":{
      top: "40%",
    },
    "& div div div":{
      color:"#000 !important",
    },
    "& input":{
      color:" #000 !important",
      padding: "15px 0px 15px 30px !important",
      marginBottom: "10px !important",
    },
    "& img":{
      margin:"0px !important"
    }
  },
  flgselett:{
    "& div":{
      width:"100% !important"
    },
    "& div div:before":{
      right: "10px !important"
    },
    "& div div":{
      color: "#fff !important",
      fontSize:"16px"
    },
    "& img":{
      height: "30px !important",
      width: "auto !important",
      marginTop: "18px",
    }
  },
  loginwrap:{
    borderRadius: "10px",
    width:"40%",
    margin:"10px auto",  
    background:"#fff",
    boxShadow: "0px 0px 5px #ccc",
    padding:"10px",
    "& fieldset":{
      border:"0px",
     
    },
    "& form":{
      margin:"0px",     
    },
    "& p":{
      margin: "-10px 0px 10px",
      fontSize: "14px",
      fontFamily: "ubuntu",
    },
    "& input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button ":{
      opacity: "0",  
    },
    "& h4":{
      fontFamily:"ubuntu",
      color:"#000",
      fontSize:"24px",
      textAlign:"center",
      fontWeight: "bold",
      marginBottom: "0",
    },
    "& label":{
      border:"0px",
      color:"#3c3c3c",
      fontSize:"16px",
      transform: "translate(0px, 10px) scale(1)",
      fontFamily:"ubuntu",
      ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key 
        paddingTop: "15px",
      }, 
    },
    "& label.MuiFormLabel-root.Mui-focused":{
      color:"#3c3c3c !important"
    },
    "& input":{
      fontFamily:"ubuntu",
      color:"#3c3c3c",
      fontSize:"14px",
      padding:"20px 0px 10px 0px",
      marginBottom:"20px",
      borderBottom:"1px solid #3c3c3c",
      ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key 
        marginBottom:"10px", 
        fontSize:"14px",
        paddingTop: "30px",
      },
    },
    "& div":{
      width:"100%", 
      marginTop:"0px",
      marginRight: "0px",
      float: "right",
      ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key 
        margin:"0%", 
      },
    },
    "& div div":{ 
      marginTop:"0px"
    },
    ['@media (max-width:1200px)']: { // eslint-disable-line no-useless-computed-key 
      width:"80%", 
      left:"10%",
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      width:"96%", 
      margin:"0px auto",
      float:"none", 
    },
  },
  signupwrap:{ 
    borderRadius: "10px",
    width:"60%",
    margin:"0px auto",  
    background:"#fff",
    boxShadow: "0px 0px 5px #ccc",
    padding:"10px",
    "& fieldset":{
      border:"0px",     
    },
    "& form":{
      margin:"0px",     
    },
    "& p":{
      margin: "-10px 0px 10px",
      fontSize: "14px",
      fontFamily: "ubuntu",
    },
    "& input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button ":{
      opacity: "0",  
    },
    "& h4":{
      fontFamily:"ubuntu",
      color:"#000",
      fontSize:"24px",
      textAlign:"center",
      fontWeight: "bold",
      marginBottom: "0",
    },
    "& label":{
      border:"0px",
      color:"#3c3c3c",
      fontSize:"16px",
      transform: "translate(0px, 10px) scale(1)",
      fontFamily:"ubuntu",
      ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key 
        paddingTop: "15px",
      }, 
    },
    "& label.MuiFormLabel-root.Mui-focused":{
      color:"#3c3c3c !important"
    },
    "& input":{
      fontFamily:"ubuntu",
      color:"#3c3c3c",
      fontSize:"14px",
      padding:"20px 0px 10px 0px",
      marginBottom:"20px",
      borderBottom:"1px solid #3c3c3c",
      ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key 
        marginBottom:"10px", 
        fontSize:"14px",
        paddingTop: "30px",
      },
    },
    "& div":{
      width:"100%", 
      marginTop:"0px",
      marginRight: "0px",
      float: "right",
      ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key 
        margin:"0%", 
      },
    },
    "& div div":{ 
      marginTop:"0px"
    },
    ['@media (max-width:1200px)']: { // eslint-disable-line no-useless-computed-key 
      width:"80%", 
      left:"10%",
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      width:"96%", 
      margin:"0px auto",
      float:"none", 
    },
  },
  receptadiv:{
    float:"left",
    marginBottom:"10px !important",
    "& div":{
      float:"left"
    }
  },
  otpbtn:{
    display:"block",
    textAlign:"center",
    color:"#404040",
    margin: "0px auto",
    fontWeight: "600",
    fontSize: "14px",
    textTransform:"capitalize",
    "&:hover":{
      background:"none"
    }
  },
  signinmodalbtn:{
    color: "#863304 !important",
    background: "radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #d29817 30%, #bf8315 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #d2a732 25%, #5d4a1f 62.5%, #f5c761 100%)",
    margin: "0px auto",
    borderRadius: "50px",
    padding: "10px 30px",
    textShadow: "2px 2px 1px #bb7e15",
    fontFamily: "ubuntu",
    fontWeight: "600",
    fontSize: "16px",
    display: "inherit",
    marginTop: "30px",
  },
  logoimg:{
    textAlign:"center",
    margin:"0px auto",
    display:"block",
    height:"80px"
  },  
  clerfix:{
    clear:"both",
  },
  fullpagelogin:{
   // overflow: "auto",
    background:"#f1f1f1",
  // background: "url(" + Background4 + ")", 
   paddingBottom:"20px",  
    backgroundPosition: "center",
    backgroundSize: "cover",
    height:"100%", 
    overflow:"auto",
    width:"100%",
    position:"fixed",
    backgroundRepeat: "no-repeat",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      padding: "20px 0px 20px",
    },
    "&::after": {
      background:"rgba(0,0,0,0.5)",
      content: "dd",
      top: "0",
      left: "0",
      bottom: "0",
      right: "0",
      position: "absolute", 
    }
  }, 
  errormsg:{
    color: "#fff",
    padding: "0px 10px",
    background: "#8c0b0b",
    marginBottom: "20px",
    borderRadius: "5px",
    fontFamily: "ubuntu",
    fontSize: "14px",
    lineHeight: "28px", 
  },
  sucessmsg:{
    color: "#fff",
    padding: "0px 10px",
    background: "#0b8c21",
    marginBottom: "20px",
    borderRadius: "5px",
    fontFamily: "ubuntu",
    fontSize: "14px",
    lineHeight: "28px", 
  },
  selectboxx:{ 
    border:"0px",
    width:"100%",
    fontSize: "16px",
    fontFamily: "ubuntu",
    padding: "11px 0 6px",
    marginBottom:"20px",
    borderBottom:"1px solid #3c3c3c",
    color:"#3c3c3c",
    "& option":{
      color:"#000",
    },
    "& div div":{
      color: "#fff",
      fontSize: "14px",
      fontFamily: "ubuntu",
      padding:"0px",
    },
    ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
      marginBottom:"20px !important",
    },
  },
  flagselect:{
    borderBottom:"1px solid #fff",
    color:"#fff",  
    padding: "21px 0px 15px",
    fontFamily: "ubuntu",
    "& div.selected--flag--option":{
      padding:"0px !important",
    },
    "& input":{
      padding:"5px 10px",
      color:"#000",
      border:"1px solid #ccc",
    },
    "& span.country-label":{
      paddingLeft:"0px !important"
    },
    "& span.country-flag span.country-label":{
      paddingLeft:"10px !important",
      color:"#333",
    }
  },



 


};

export default landingPageStyle;

import { container, title } from "../../casino-kit-react.js";
 

const SectionAbout = {
  singlecontact:{
    background: "#000",
    padding: "0px 0px 120px",
    position: "relative",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      padding: "20px 0px 20px",
    },
  },
  text: {
    fontSize: "24px",
    lineHeight: "34px",
    fontWeight: "300",
    fontFamily: 'Open Sans',
    margin:"0px",
    textAlign:"center",
    marginBottom: "40px",
    color: "#a1aed4",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: "20px",
      lineHeight: "28px",
      marginBottom: "20px",
    },
  },
  subtitle:{
    fontSize: "24px",
    lineHeight: "34px",
    fontWeight: "600",
    textTransform: "uppercase",
    margin: "8px",
    textAlign:"center",
    marginBottom: "8px",
    fontFamily: "Ubuntu",
    color: "#ff344d",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: "20px",
      lineHeight: "28px",
    },
  },  
  checkboxin:{
    "& p":{
      float:"left",
      color:"#fff",
      fontFamily:"ubuntu",
      lineHeight:"40px"
    },
    "& span":{
      color:"#ffb80c"
    },
    "& p a":{
      color:"#ffb80c",
      textDecoration:"none"
    },
    "& label":{
      margin:"0px 0px 0px",
      float:"left",
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      float: "left",
      width: "auto !important",
    },
  },
  checkboxx:{
    float:"left",
    width:"auto !important"
  },
  checkboxxlogin:{ 
    display: "block",  
    marginBottom:"10px",
    "& span":{
      padding:"0px",
    },
    "& svg":{
      width: "0.7em",
      height: "0.7em",
    },
    "& p":{
      lineHeight:"10px",
      marginLeft:"30px",
      position: "absolute",
      paddingTop:"8px",
    },
    "& p.MuiFormHelperText-root":{ 
      paddingTop:"20px",
      paddingLeft:"0px",
      color:"#ff0000 !important"
    }
  },
  clearfix:{
    clear:"both"
  },
  selectboxx:{
    background:"none",
    "& div":{
      padding:"0",
      marginTop: "10px",
    },
    "& fieldset":{
      borderBottom: "1px solid #333 !important",
      borderRadius: "0",
      height: "52px",
    },
    "& p.MuiFormHelperText-root.Mui-error":{
      paddingTop: "7px",
       marginLeft: "0",
    }
  }, 
  checkbtitext:{
    float:"left",
    display: "block",
    width: "92% !important",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: "12px",
      lineHeight: "16px",
      float: "left",
      width: "80% !important",
    },
    
  },
  yesnofild:{
    display:"block"
  },
  mybtn1:{
    fontSize: "14px",
    textTransform: "uppercase",
    marginTop:"20px",
    fontWeight: "600",
    padding: "8px 25px",
    display: "block",
    color: "#fff",
    border: "0px",
    borderRadius: "3px",
    cursor: "pointer",
    transition: "all 0.3s ease-in",
    backgroundImage: "linear-gradient(to left, #ff344d, #d2273c)",
  },

  about:{

  },
  containernew: {
    ...container,
    minHeight: "50px",
    display: "block",
    alignItems: "center",
    justifyContent: "space-between",

  },
  content:{

  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  leftimgconatct:{
    paddingTop: "80px",
    position: "absolute",
    top: "-5px",
    left: "0px",
    maxWidth: "520px",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      display: "none",
    },
  },
  title: {
    ...title,
    display: "block",
    position: "relative",
    fontFamily: 'Ubuntu',
    marginTop: "0px",
    textAlign:"center",
    marginBottom: "13px",
    fontSize: "70px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
       fontSize: "50px",
       lineHeight: "60px",
    },
  },
  contactformwrapper:{
    paddingTop: "65px",
  },
  contactbox:{  
   
    position: "relative",
    zIndex: "9",
    fontFamily: 'Ubuntu',
    background: "#19254d",
    padding: "32px 30px 40px",
    boxShadow: "0px 0px 0px 10px rgba(60, 61, 62, 0.2), 0px 0px 0px 20px rgba(60, 61, 62, 0.2), 0px 0px 0px 30px rgba(60, 61, 62, 0.2)",
    "& input":{
      outline:"0px",
      width: "100%",
      border: "0px",
      height: "auto",
      fontFamily: 'Ubuntu',
      color: "#bdbdbd",
      background: "none",
      borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
      marginBottom: "22px",
    },
    "& label":{
      color: "#bdbdbd",
    },
    "& fieldset":{
      border: "0px",
    },
    "& div":{
    //  width: "50%",
      ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      //  width: "100%",
     },
    },
    "& div div":{
      width: "100%",
      ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
        width: "100%",
     },
    },
    "& .MuiFormLabel-root.Mui-focused":{
      color:"#bdbdbd",
    },
    "& textarea":{
      width: "100%",
      outline:"0px",
      border: "0px",
      fontFamily: 'Ubuntu',
      fontSize:"14px",
      color: "#bdbdbd",
      background: "none",
      borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
      marginBottom: "22px",
      height: "120px",
      resize: "none",
    },
    "& select":{
      width: "100%",
      border: "0px",
      height: "50px",
      outline:"0px",
      color: "#bdbdbd",
      background: "none",
      borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
      marginBottom: "22px",
    },
  },
  contactiinpu:{
    fontSize:"14px",
    "& label":{
      fontSize:"14px",
      transform: "translate(5px, 20px) scale(1)",
    },
    "& input":{
      fontSize:"14px",
      padding: "10px 5px 10px 5px",
    },
    "& p":{
      fontSize:"12px",
      marginTop:"-10px", 
    },
    "&::placeholder":{
      outline:"0px",
      color: "#a1aed4",
      fontSize:"16px",
      fontFamily: 'Ubuntu',
    }
  },
  contactchechbx:{
    width: "30px !important",
    height: "26px !important",
    display: "inline-block",
    verticalAlign: "middle",
    margin: "0 !important",
  },
  contacttitle:{
    fontSize: "24px",
    fontWeight: "700",
    fontFamily: 'Ubuntu',
    lineHeight: "34px",
    color: "#fff",
    marginBottom: "16px",
    marginTop: "0px",
  }

};

export default SectionAbout;

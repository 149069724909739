import React, {useEffect, useRef, useState} from "react"; 

import 'react-accessible-accordion/dist/fancy-example.css';

import { makeStyles } from "@material-ui/core/styles"; 
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js"; 
import bankaccountDb from "../data/bankaccountpage";

import styles from "../assets/jss/components/tabsAccountstyle.js";
import Button from "../elements/CustomButtons/Button"; 
import Formsy from 'formsy-react'; 
import {TextFieldFormsy} from '../../../@casino';
import * as authActions from '../../../auth/casino/actions';    
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import * as globalurl from '../../../global/Global.js';  
import Norecord from "../assets/img/no-record.png";  
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import localDb from "../data/bankaccount";
 
const useStyles = makeStyles(styles); 
export default function Account(props) { 
    const {...rest} = props;
    const dispatch = useDispatch(); 
    const [isFormValid, setIsFormValid] = useState(false); 
    const formRefBank = useRef(null); 
    const [classAdd, setClassAdd] = useState(false);
    const addBank = useSelector(({auth}) => auth.addbank); 
    styles.listItem["&:nth-child(even)"].background = props.themeColors.bgColors.messageboxlist;
    styles.listItem.color = props.themeColors.bgColors.messageboxlistcolor; 

    

    if(props.themeColors != null){ 
        styles.addbtn.background = props.themeColors.bgColors.main_color;
        styles.addbtn.backgroundImage = props.themeColors.bgColors.main_color;
        styles.addbtn.color = props.themeColors.bgColors.text_primary_dark_color;
        styles.addbtn["& span"].color = props.themeColors.bgColors.text_primary_dark_color; 
        styles.messagedeletebtn.color = props.themeColors.bgColors.text_primary_color;
        styles.messagedeletebtn["&:hover"].color = props.themeColors.bgColors.text_secondary_color; 
        styles.depositbtn.background = props.themeColors.bgColors.main_color;
        styles.depositbtn.backgroundImage = props.themeColors.bgColors.main_color;
        styles.depositbtn.color = props.themeColors.bgColors.text_primary_dark_color;
        styles.depositbtn["& span"].color = props.themeColors.bgColors.text_primary_dark_color;
        styles.ifscbtn.color = props.themeColors.bgColors.text_secondary_color;
       styles.depositbtn2.background = props.themeColors.bgColors.main_color;
       styles.depositbtn2.backgroundImage = props.themeColors.bgColors.main_color;     
       styles.listItem.color = props.themeColors.bgColors.text_secondary_dark_color;
       styles.listItem["&:nth-child(odd)"].background = props.themeColors.bgColors.primary_dark_bgcolor;   
       styles.depositbtn.fontFamily = props.themeColors.bgColors.base_font;  
       styles.depositbtn.fontFamily = props.themeColors.bgColors.base_font; 
       styles.accountsbox["& h3"].fontFamily = props.themeColors.bgColors.base_font; 
       styles.addbtn.fontFamily = props.themeColors.bgColors.base_font;  
       styles.changebankacc.fontFamily = props.themeColors.bgColors.base_font;  
       styles.addbankaccoutbox["& label"].fontFamily = props.themeColors.bgColors.base_font;  
       styles.addbankaccoutbox["& input"].fontFamily = props.themeColors.bgColors.base_font;  
       styles.ifscbtn.fontFamily = props.themeColors.bgColors.base_font; 
       styles.depositbtn2.fontFamily = props.themeColors.bgColors.base_font;  

       styles.changebankacc.color = props.themeColors.bgColors.text_primary_color;  
       styles.addbankaccoutbox["& input"].color = props.themeColors.bgColors.text_primary_color;
       styles.addbankaccoutbox["& label"].color = props.themeColors.bgColors.text_secondary_dark_color;
       styles.addbankaccoutbox["& input"].borderBottomColor = props.themeColors.bgColors.secondary_color; 
       styles.accounttablebox["& table tr th"].background = props.themeColors.bgColors.secondary_dark_bgcolor;
       styles.accounttablebox["& table tr td"].background = props.themeColors.bgColors.primary_dark_bgcolor; 
    }  
    const classes = useStyles();   
    const [limitStartFrom, setLimitStartFrom] = React.useState(0);
    const limitMaxRange = 5;
    const [loadMore, setLoadMore] = React.useState(false);
    const [bankData, setBankData] = React.useState([{status: false}]);
    const [branchName, setBranchName] = React.useState("");
    const [bankName, setBankName] = React.useState("");
    var ifscCode = "";

    function ifscChange(event){
        
        if(event.target != null && event.target.value != null && event.target.value.length >= 11){
            ifscCode = event.target.value;
            fetchIFSCDetails();
        }else{
            ifscCode = event.target.value;
        }
        document.body
                .getElementsByClassName("message_addbank")[0] 
                .innerHTML=""; 
    }
    function fetchIFSCDetails(){
        if(ifscCode != null && ifscCode.length >= 11){
            document.body
            .getElementsByClassName("message_addbank")[0]
            .innerHTML=""
            const request = axios.post(globalurl.GETIFSCDETAILS , {
                token: window.localStorage.getItem('jwt_access_token'),
                ifsc_code: ifscCode
            });
            
            request.then((response) => {
                if(response.data.data != null){
                    var bankDetails = response.data.data;
                    if(bankDetails.branch != null){
                        setBranchName(bankDetails.branch);
                    }
                    if(bankDetails.bankname != null){
                        setBankName(bankDetails.bankname);
                    }
                }else{
                    document.body
                    .getElementsByClassName("message_addbank")[0] 
                    .innerHTML="Bank details not found as per IFSC. Please put details in fields."; 
                    setClassAdd(classes.msg = " errormsg" );
                }
            });
            request.catch((error) => {
                
            });
        }else{
            document.body
            .getElementsByClassName("message_addbank")[0] 
            .innerHTML="Please put correct IFSC Code."; 
            setClassAdd(classes.msg = " errormsg" );
        }
    }
   
    


      useEffect(() => {
            if ( addBank.error && (addBank.error.acc_number || addBank.error.acc_name || addBank.error.ifsc_code || addBank.error.bank_name || addBank.error.branch_name || addBank.error.iban_number || addBank.error.message) )
            {
                if(addBank.error.message != null){
                    document.body
                    .getElementsByClassName("message_addbank")[0] 
                    .innerHTML=addBank.error.message   
                    setClassAdd(classes.msg = " errormsg" );
                //   alert(addBank.error.message);
                }else{
                    formRefBank.current.updateInputsWithError({
                        ...addBank.error
                    });
                    
                }
                disableButton();
                
            }else{
                if(addBank.data != null && addBank.data.status != null && addBank.data.status === "OK"){


                    setTimeout(function() {
                      //  window.location.reload();  
                    }, 5000); 

                    setClassAdd(classes.msg = " successmsg" )
                    if(formRefBank != null && formRefBank.current != null){
                        formRefBank.current.reset();
                    }
                    setBankData([{status:false}]); 
                    if(document.body.getElementsByClassName("message_addbank")[0] != null){
                        document.body
                        .getElementsByClassName("message_addbank")[0].innerHTML="Bank Account added successfully";
                    }
                }
                
            }
        }, [addBank.error, addBank.data, classes.msg]); 
        function disableButton()
        {
            setIsFormValid(false);
            document.body
            .getElementsByClassName("signupbtn")[0]
              .setAttribute("style", "opacity:0.5;");
        }
    
        function enableButton()
        {
            setIsFormValid(true);
            document.body
            .getElementsByClassName("signupbtn")[0]
              .setAttribute("style", "opacity:1;");
        } 
        function handleSubmit(model)
        { 
            dispatch(authActions.submitAddbank(model,props));
           // window.location.reload(false);
            setBankData([{status:false}]);
            document.body
            .getElementsByClassName("message_addbank")[0]
            .innerHTML=" " 
        }  
        const isObject = function(a) {
            return (!!a) && (a.constructor === Object);
        };
        if((bankData[0].status == false && bankData[0].checked == null ) || (loadMore === true )){
         //   alert("sadads");
         if(bankData[0].status != null && bankData[0].status === true){
         }else{
            setBankData([{status:true}]);
         }
            setLoadMore(false);
            const request = axios.post(globalurl.BANKACCOUNTS , {
                token: window.localStorage.getItem('jwt_access_token'),
                LSTART: limitStartFrom,
                LEND: limitMaxRange
            });
            
            request.then((response) => {
             
                setLoadMore(false);
                var bankDb = "";
                if(response.data.data != null){
                    bankDb = [{
                        "status":true,
                        "data":response.data.data
                    }]
                    if(bankData[0].status != null && bankData[0].status === true){
                        bankDb[0].data = [...bankData[0].data, ...bankDb[0].data]
                        setBankData(bankDb);
                    }else{
                        setBankData(bankDb);
                    }
                }else{
                    bankDb = [{
                        "status":false,
                        "checked":false
                    }]   
                    if(bankData[0].status != null && bankData[0].status === true){
                        setLimitStartFrom((limitStartFrom - limitMaxRange));
                    }else{
                        setBankData(bankDb);
                    }
                }
            });
            request.catch((error) => {
                setLoadMore(false);
                var bankDb = [{
                    "status":false
                }]   
                setBankData(bankDb);
            });
        }
        var LoadMoreRecord = function(){
            setLimitStartFrom((limitStartFrom + limitMaxRange));
            setLoadMore(true);
        }  
        function addaccountf()
        {
            setIsFormValid(true);
            document.body
            .getElementsByClassName("addaccountform")[0]
              .setAttribute("style", "display:block;");
        }
        function deleteaccountf(bankerId) {
            var r = window.confirm("Do you really want to delete your bank account!");
            if (r === true) {
            const request = axios.post(globalurl.BANKACCOUNTDELETE , {
                token: window.localStorage.getItem('jwt_access_token'),
                banker_id: bankerId
             }); 
            
            request.then((response) => { 
             //   alert(JSON.stringify(response));
              
                if(response.data != null && response.data.status != null && response.data.status === true){
                    setBankData([{status:false}]); 
                }  
            });
            request.catch(() => { 
                 
            });
        }  
    }

      

   


    return (
        <div className="sporto"> 

                        {bankaccountDb[sessionStorage.getItem('lang')].map((jsondata) => (
                            <div className={classes.accountsbox + " addbankaccountt"}>
                            {localDb[sessionStorage.getItem('lang')].map((jsondata) => (
                                <h3 key="225" className={classes.accountsheads}> {jsondata.pageheading} 
                                <Button 
                                        href="#"
                                        key="224"
                                        color="transparent" 
                                        onClick={addaccountf}
                                        className={classes.addbtn} 
                                    >
                                            {jsondata.addbankaccount} 
                                    </Button>
                                </h3>
                                    ))}
                                <div className={classes.addaccountform + " addaccountform"}>
                                <Formsy
                                        onValidSubmit={handleSubmit}
                                        onValid={enableButton}
                                        onInvalid={disableButton}
                                        ref={formRefBank}
                                        autoComplete={"off"}
                                        className={classes.formaddaccount + " flex flex-col justify-center w-full"}
                                    >
                                <div className={classes.addbankaccoutbox}>
                                <p className={classes.changebankacc}> {jsondata.ptext}</p>
                                <GridContainer>
                                <GridItem xs={12}><div className={classes.msg + " message_addbank " + classAdd} id="message"></div></GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                        <TextFieldFormsy   
                                                label={jsondata.acountname}   
                                                id="acountname"
                                                name="acc_name"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                validations={{
                                                    minLength: 2
                                                }} 
                                                validationErrors={{
                                                    minLength: 'Min character length is 2'
                                                }} 
                                                variant="outlined"
                                                required
                                            />   
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                            <TextFieldFormsy  
                                                label={jsondata.ifsccode} 
                                                id="ifsccode"
                                                name="ifsc_code"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                validations={{
                                                    minLength: 6
                                                }} 
                                                validationErrors={{
                                                    minLength: 'Min character length is 6'
                                                }} 
                                                onChange={ifscChange}
                                                variant="outlined"
                                                required
                                            />   
                                    <Button
                                        color="transparent"
                                        type="button"
                                        className={classes.ifscbtn}
                                        onClick={fetchIFSCDetails}
                                    >
                                        {jsondata.whyifsc} 
                                    </Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>  
                                        <TextFieldFormsy 
                                                label={jsondata.banknameoption}
                                                id="bank_name"
                                                name="bank_name"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                validations={{
                                                    minLength: 2
                                                }} 
                                                validationErrors={{
                                                    minLength: 'Min character length is 2'
                                                }} 
                                                value={bankName}
                                                variant="outlined"
                                                required
                                            />  
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                            <TextFieldFormsy 
                                                label={jsondata.bankbranch}
                                                id="bankbranch"
                                                name="branch_name"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                validations={{
                                                    minLength: 3
                                                }} 
                                                validationErrors={{
                                                    minLength: 'Min character length is 3'
                                                }} 
                                                value={branchName}
                                                variant="outlined"
                                                required
                                            /> 
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextFieldFormsy 
                                                label={jsondata.accountnumber}
                                                id="accountnumber"
                                                name="acc_number"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                validations={{
                                                    minLength: 4
                                                }} 
                                                validationErrors={{
                                                    minLength: 'Min character length is 4'
                                                }} 
                                                variant="outlined"
                                                required
                                            />  
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextFieldFormsy 
                                                label={jsondata.ibannumber}
                                                id="ibannumber"
                                                name="iban_number"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                validations={{
                                                    minLength: 4
                                                }} 
                                                validationErrors={{
                                                    minLength: 'Min character length is 4'
                                                }} 
                                                variant="outlined" 
                                            />  
                                    </GridItem>
                                    
                                    <GridItem xs={12} sm={12} md={12}>
                                    <div className={classes.depositformbtni + " bordernone"}> 
                                    <Button 
                                        type="submit"
                                        color="transparent" 
                                        className={classes.depositbtn2 +" signupbtn"}
                                        disabled={!isFormValid}
                                    >
                                        {jsondata.myaccountbtn}
                                    </Button></div>
                                    </GridItem>
                                    </GridContainer>
                                </div>
                                </Formsy>
                                </div>
                                <div className={classes.depositlistwarp}>
                                




                                {(() => {
                                if(bankData[0].status != null && bankData[0].status === true){
                                    
                                    return (<div id="nav-tabs" className={classes.depositlistw} >
                                    {bankData.map((jsondata) => (
                                            <div className={classes.messagebox + " " + classes.accounttablebox} key={":myunique_1"}>
                                                <div className={classes.tablewrapresposnive}>
                                                <table> 
                                                    <tr> 
                                                        {localDb[sessionStorage.getItem('lang')][0].tableheader.map((headData) => (
                                                        <th key={headData.id}>{headData.field_name}</th>  
                                                        ))} 
                                                    </tr>
                                                    {(() => {if(Array.isArray(jsondata.data)){return (jsondata.data.map((bodyData) => (
                                                    <tr key={bodyData.id}> 
                                                        <td>{bodyData.acc_name}</td>  
                                                        <td>{bodyData.acc_number}</td>
                                                        <td>{bodyData.ifsc_code}</td>
                                                        <td>{bodyData.bank_name}</td>
                                                        <td>{bodyData.branch_name}</td>
                                                        <td>{bodyData.branch_address}</td>
                                                        <td>
                                                            <Button
                                                                href="#"
                                                                color="transparent"
                                                                onClick={() => deleteaccountf(bodyData.id)}
                                                                className={classes.messagedeletebtn}
                                                                style={{ padding: "5px 10px", margin: "0"}}
                                                            >
                                                                <i className={"fa fa-trash"}></i>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                     )))}else if(isObject(jsondata.data)){return(
                                                    <tr> 
                                                        <td>{jsondata.data.acc_name}</td> 
                                                        <td>{jsondata.data.acc_number}</td> 
                                                        <td>{jsondata.data.ifsc_code}</td> 
                                                        <td>{jsondata.data.bank_name}</td> 
                                                        <td>{jsondata.data.branch_name}</td> 
                                                        <td>{jsondata.data.branch_address}</td> 
                                                        <td>
                                                            <Button
                                                                href="#"
                                                                color="transparent"
                                                                onClick={() => deleteaccountf(jsondata.data.id)}
                                                                className={classes.messagedeletebtn}
                                                                style={{ padding: "5px 10px", margin: "0"}}
                                                            >
                                                                <i className={"fa fa-trash"}></i>
                                                            </Button>
                                                        </td>  
                                                    </tr>
                                                    )}else{
                                                        return(
                                                        <tr key={"statement_nodata"}> 
                                                            <td><p className={classes.promotioncont}><img src={Norecord} className={classes.norecordimg + " norecordimg"} style={{display:"block", margin:"0px auto"}} alt=""/></p></td>  
                                                        </tr>
                                                      )
                                                    }})()}
                                                </table>
                                                </div>

                                              
                                                {localDb[sessionStorage.getItem('lang')].map((jsondata) => (
                                                
                                                <Button
                                                    href=""
                                                    key="223"
                                                    color="transparent"
                                                    className={classes.depositbtn}
                                                    onClick={LoadMoreRecord}
                                                >
                                                    {jsondata.loadmore}
                                                </Button> 
                                                    ))}
                                            </div>
                                        ))}
                                </div>);
                                }else{
                                    return (
                                        <ListItem className={classes.listItem} key={"statement_nodata"}>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <p className={classes.promotioncont}><img src={Norecord} className={classes.norecordimg + " norecordimg"} style={{display:"block", margin:"0px auto"}} alt=""/></p>
                                                </GridItem>
                                            </GridContainer>
                                        </ListItem>
                                        );
                                }
                            })()}




                                </div>
                                <div style={{clear:"both"}}></div>     
                            </div> 
                        ))}     
                   
        </div>
    );
  }
  
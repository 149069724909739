import { container } from "../../casino-kit-react.js";
import Background5 from "../../img/verify.png";
import Background6 from "../../img/verifyed.png";
const tabsStyle = {
    signinnerpagebox:{
        background:"#000",
        padding:"0px 20px 0px",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            padding:"0px 0px 0px",
          },
    }, 
    iconnotiboxwrap:{
      padding:"0px 10px"
    },
    tablewrapresposnive:{
      overflow:"auto",
      ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
        marginTop:"10px",
      },
    },
    accounttablebox:{  
      "& table":{
        whiteSpace: "nowrap",
        width:"100%",
        fontFamily:"ubuntu",
        color:"#ccc",
        fontSize:"12px", 
      },
      "& table tr th":{
        padding:"10px",
        background:"#0f0f0f",
      },
      "& table tr td":{
        padding:"10px",
        background:"#2b2b2b",
      }
    },
    iconnotibox:{
        background:"#0f0f0f",
        width:"100%",
        textAlign:"center",
        color:"#fff",
        fontSize:"14px",
        marginBottom:"20px",
        padding: "15px 15px",
        fontFamily: 'Ubuntu',
        borderRadius: "5px",
        "& i":{
          fontSize: "38px",
          display: "block",
          marginBottom: "6px",
        },
        "&:hover":{ 
          background:"#2b2b2b",
        },
        "&.disablebox":{
          opacity: "0.3 !important",
          cursor: "not-allowed",
        }
    },
    editiconnotiboxwrap:{
      padding: "0px 10px",
      display:"none",
      "& div div":{
        width:"100%"
      }
    },
    checkboxAndRadioHorizontal:{
     marginTop: "10px",
      marginBottom: "10px",
      "& div":{
        width: "130px !important",
        alignSelf: "flex-end",
        display: "block",
        marginTop: "-44px",
      },
      "&.inrediobn":{
        marginBottom: "0px",
      },
      "&.inrediobn div":{
        marginTop: "-22px",
      },
      "&.inrediobn div div":{
        marginTop: "0px",
      },
      "& div div":{
        width: "auto !important",
        alignSelf: "inherit",
        display: "inherit",
        marginTop: "0px",
      },
      
      "& div label":{
        width: "60px",
      },
      "& div input":{
        width: "60px",
        margin: "0 !important",
      },
      "& legend":{
        fontSize: "14px",
        color: "#fff",
        fontFamily: 'Ubuntu',
      },
      "& legend.MuiFormLabel-root.Mui-focused":{
        color:"#fff"
      }, 
      "& span.MuiRadio-colorPrimary.Mui-checked":{
        color: "#efde1d !important",
      }
    },
    formtodate:{
      "& label":{
        transform: "translate(0px, -6px) scale(0.75)",
      }
    },
    signinnerpageboxin:{
        background:"#131212",
        margin:"0px 0px 50px",
        borderRadius:"10px"
    },
    formaddaccount:{
      padding:"0 10px",
      "& .bordernone":{
        border:"0",
        paddingTop:"0px"
      }
    },
    signinnerpageboxinProfile:{
      background:"#131212",
      margin:"0px 0px 0px",
      borderRadius:"10px",
      height:"100%",
      ['@media (max-width:560px)']: { // eslint-disable-line no-useless-computed-key
     //   width:"500px",
      },
    },
    paddingdiv:{
      padding:"20px",
      color:"#fff",
      "& h2":{
        fontFamily: "ubuntu",
        fontSize: "22px",
        color: "#fff",
        margin: "0",
        padding: "0 0 20px",
      }
    },
    section: {
        background: "#EEEEEE",
        padding: "70px 0"
    },
    accountsheads:{
        color:"#fff !important",
        marginTop: "0",
        fontSize:"18px !important",
        padding: "0px 10px", 
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key 
            padding: "20px 10px 0px", 
            
          },
        "& a":{
            float:"right",
           // width:"140px",
            marginTop: "-10px",
            ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
                float: "right",
             //   width: "130px",
                margin: "0px auto 0px",
                fontSize: "14px",
                padding: "5px 10px",
                lineHeight: "30px",
              },
        },
        
    },
    accountsbox:{
        background: "none",
        padding:"30px 10px",
    //    borderRadius:"10px",
        "& h3":{
            color:"#f44336",
            fontSize:"22px",
            fontFamily: "ubuntu",
        },
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            padding:"20px 10px",
        },
    },
    userprobox:{ 
        width:"100%",
        fontFamily: "ubuntu",
        margin:"0px auto",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"100%",
        },
        ['@media (max-width:480px)']: { // eslint-disable-line no-useless-computed-key
            width:"100%",
            margin:"0px auto",
        },
        "& p":{
            margin:"0px",
            color:"#bdbdbd",
            fontSize:"16px",
            textAlign:"left",
            width: "30%",
            float:"left",
            padding:"10px 10px 10px 0px",
            ['@media (max-width:480px)']: { // eslint-disable-line no-useless-computed-key
              fontSize:"13px",
             },
        },
        "& span":{
            display:"block",
            textAlign: "left",
            fontSize:"16px",
            color:"#fff",
            padding:"10px 0px",
            ['@media (max-width:480px)']: { // eslint-disable-line no-useless-computed-key
              fontSize:"13px",
             },
        },
        "& a":{
            textAlign: "center",
            color:"#fff",
            display:"inline-block",
            marginRight:"10px",
        },
        "& a span":{
            padding:"0px",
        },
    },
    changebankacc:{
        fontFamily: "ubuntu",
        color:"#fff",
        textAlign:"left",
        fontSize:"16px",
    },
    editprobox:{
        width:"100%",
        display:"block",
        margin:"0px auto",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"100%",
        },
        "& input":{
            background:"none",
            color:"#fff",
            width:"100%",
            fontSize: "16px",
            paddingLeft:"0px",
            paddingRight:"0px",
            marginBottom: "20px",
            borderBottom:"1px solid #6b6b6b",
        },
        "& h6":{
            color: "#aaa",
            margin: "0",
            fontSize: "14px",
            textTransform: "uppercase",
            fontWeight: "500",
        },
        "& label":{ 
            color:"#bdbdbd", 
            fontSize:"14px",
            transform: "translate(0px, 20px) scale(1)",
            fontFamily:"ubuntu",
        },
        "& div":{ 
            width:"100%", 
        },
        "& fieldset":{ 
            border:"0px", 
        },
        "& .MuiFormLabel-root.Mui-focused":{
            color:"#bdbdbd",
        },
        "& a":{
            textAlign: "center",
            color:"#fff",
            display:"inline-block",
            marginRight:"10px",
        },
        "& a span":{
            padding:"0px",
        },
        "& strong":{
            display:"block",
            color:"#fff",
            paddingBottom:"10px",
            textAlign:"right",
            fontFamily: "ubuntu",
        },
        "& p":{
            display:"block",
            color:"#fff",
            fontFamily: "ubuntu",
            lineHeight: "34px",
            fontSize: "14px",
            margin:"0px"
        },
    },
    list:{
        width:"100%",
        display:"block"
      },
      listItem:{
          width:"100%",
          paddingTop:"15px",
          paddingBottom:"15px",
          display:"block",
          color:"#a1aed4",
          fontFamily:"ubuntu",
          fontSize:"18px",
          "& a":{
              margin:"0px",
          },
          "& p":{
              margin:"0px",
          },
          "&:nth-child(even)":{
              background:"#364275"
          },
          "&:nth-child(odd)":{
              background:"none"
          }
      },
    changepasbox:{
        width:"100%",
        display:"block",
        padding:"30px 10px",
        margin:"0px 0px 0px 10px",
        "& input":{
            background:"none",
            color:"#fff",
            width:"100%",
            fontSize: "16px",
            marginBottom: "20px",
            borderBottom:"1px solid #a1aed4",
        },
        "& label":{ 
            color:"#bdbdbd", 
            transform: "translate(0px, 20px) scale(1)",
            fontSize:"14px",
            fontFamily:"ubuntu",
        },
        "& div":{ 
            width:"100%", 
        },
        "& fieldset":{ 
            border:"0px", 
        },
        "& .MuiFormLabel-root.Mui-focused":{
            color:"#bdbdbd",
        },
        "& a":{
            textAlign: "center",
            color:"#fff",
            display:"inline-block",
            marginRight:"10px",
        },
        "& a span":{
            padding:"0px",
        },
        "& p.MuiFormHelperText-root":{
            marginTop: "-10px",
            marginBottom: "10px",
            marginLeft: "0",
        },
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"100%",
        },
        ['@media (max-width:480px)']: { // eslint-disable-line no-useless-computed-key
            marginRight: "-15px",
        },
    },
    selectboxx:{
        marginTop:"20px",
        color:"#bdbdbd",
        background:"none !important",
        "&:value":{
            color:"#bdbdbd",
        },
    },
    selectboxxs:{
      background:"none !important",
      borderBottom: "1px solid #6b6b6b",
      "& div div":{
        color: "#fff",
        fontSize: "16px", 
        fontFamily: "ubuntu",
        padding: "13px 50px 13px 10px",
      },
      "& div img":{ 
      }
    },
    depositlistwarp:{
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            overflow:"auto",  
            width:"100%",
          }, 
    },
    depositlistw:{
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"1000px",
          }, 
    },
    accountstableewrap:{
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"100%",
            overflow:"auto",
        },
    },
    accountstablee:{
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"990px"
        },
    },
    addbankaccoutbox:{
        width:"100%",
        margin:"0px auto",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"100%",
            paddingTop: "30px",
            marginRight: "-10px",
        },
        "& input":{
          background:"none",
          color:"#fff",
            width:"100%",
            fontSize: "16px", 
            borderBottom:"1px solid #a1aed4",
            marginBottom:"10px",
        },
        "& label":{
            color:"#bdbdbd",
            fontSize:"14px",
            transform: "translate(0px, 20px) scale(1)",
            fontFamily:"ubuntu",
        },
        "& .MuiFormLabel-root.Mui-focused":{
            color:"#bdbdbd",
        },
        "& div":{
          width:"100%",
        },
        "& fieldset":{
            color:"#fff", 
            border:"0px",
          },
        "& select":{
            background:"none",
            marginBottom:"20px",
            lineHeight:"40px",
            height:"40px",
            width:"100%",
            border:"0px",
            fontSize: "16px",
            borderBottom:"1px solid #a1aed4"
        },
    },
    ifscbtn:{
        textAlign:"right",
        color:"#ff344d !important",
        padding:"0px",
        display:"block"
    },
    checkboxAndRadio:{
      paddingTop:"20px",
      color:"#aaa",
      lineHeight:"27px",
      "& span":{
           padding:"2px",
        fontSize:"14px"
      },
      "& label":{
          marginTop:"-10px",
          color:"#e4e4e4"
      },
      "& svg":{
          fontSize: "1.6rem",
      }
    },
    propic:{
      maxWidth: "60%",
      border: "2px solid #efde1d",
      borderRadius: "5px",
      maxHeight: "100px",
  
    },
    depositformbtni:{
        borderTop:"1px solid #696969",
        marginTop:"20px",
        paddingTop:"20px",
        textAlign:"center",
        "& button":{
            float:"none",
            display:"inline-block"
        },
        "& a":{
            float:"none",
            display:"inline-block"
        }
    },
    depositbtn:{
        color: "#000 !important",
        border: "0px",
        cursor: "pointer",
        padding: "4px 25px",
        float:"left",
        fontSize: "14px",
        boxShadow: "0px 0px 0px",
        transition: "all 0.3s ease-in",
        fontWeight: "600",
        borderRadius: "3px",
        lineHeight:"30px",
        textTransform: "capitalize",
        background: "#efde1d",
        margin: "10px 5px 10px",
        display: "block",
        "& span":{
            padding:"0px",
            color:"#000"
        },
        "&:hover":{
            background:"#efde1d",
            color:"#000",
            "& span":{
                color:"#000"
            },
        },
        "&:focus":{
          background:"#efde1d",
          color:"#000",
          "& span":{
              color:"#000"
          },
      },
    },
    addaccountform:{
        display:"none"
    },
    addbtn:{
        color: "#fff !important",
        border: "0px",
        cursor: "pointer",
        padding: "4px 25px",
        fontSize: "14px",
        boxShadow: "0px 0px 0px",
        transition: "all 0.3s ease-in",
        fontWeight: "600",
        borderRadius: "3px",
        lineHeight:"30px",
        textTransform: "uppercase",
        backgroundImage: "linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07) !important",
        margin: "10px 5px 0px",
        display: "block",
        "& span":{
            padding:"0px",
            color: "rgb(120,50,5) !important",
        },
        "&:hover":{
          background:"#efde1d"
        },
        "&:focus":{
          background:"#efde1d"
        }
    },
    depositbtn2:{
        color: "#000 !important",
        border: "0px",
        cursor: "pointer",
        padding: "4px 25px",
        fontSize: "14px",
        boxShadow: "0px 0px 0px",
        transition: "all 0.3s ease-in",
        fontWeight: "600",
        borderRadius: "3px",
        lineHeight:"30px",
        textTransform: "uppercase",
        backgroundImage: "linear-gradient(to left, #ff344d, #d2273c)",
        margin: "10px 5px 0px",
        display: "block",
        "& span":{
            padding:"0px",
        },
        "&:hover":{
          background:"#efde1d"
        },
        "&:focus":{
          background:"#efde1d"
        },
    },
    container,
    textCenter: {
        textAlign: "center"
    },
    userprowrapp:{
        width:"100%",
        margin:"0px auto",
        ['@media (max-width:1100px)']: { // eslint-disable-line no-useless-computed-key
            width:"100%",
          },
    },
    verify:{
        backgroundImage:"url(" + Background5 + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "100%",
          float:"right",
          width:"24px",
          height:"24px",
          cursor: "pointer"
    },
    verifyed:{
        backgroundImage:"url(" + Background6 + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "100%",
          width:"24px",
          float:"right",
          height:"24px",
    },
     
      rightrediobtn:{
        float: "right",
        width: "auto !important",
      },
      clearboth:{
        clear:"both"
      },
      messagedeletebtn:{
        color:"#efde1d",
        "&:hover":{
          color:"#efde1d",
        },
        "& i":{
          color:"#ff0013",
        } 
      },
      tabwrap:{
        overflow: "hidden",
        background: "#1e1e1e",
        width:"100%", 
    //    minHeight:"560px",
      },
      depositbtns:{
        marginBottom: "10px",
        padding: "10px",
        lineHeight: "12px",
        fontSize: "14px",
        display:"none"
      },
      firstwrap:{
        padding: "10px",
        background: "#2b2b2b",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
          padding: "5px 1px 1px",
        },
      },
      firstbox:{
        background: "#1e1e1e",
      },
      secondwrap:{
        background:"#1e1e1e",
        padding:"10px",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
          padding: "5px 1px 1px",
        },
      },
      secondbox:{
        background:"#2b2b2b" ,
        "&.instepselect":{
          backgroundColor: "#ff0000",
        }
      },  
      thirdwrap:{
        background:"#353535",
        padding:"10px",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
          padding: "5px 1px 1px",
        },
      },
      thirdbox:{
        background:"#1e1e1e" ,
        padding:"10px",
        "&.instepselect":{
          backgroundColor: "#ff0000",
        }
      },  
      makedeposit:{
        "& h6":{
          fontFamily:"ubuntu",
          fontSize:"12px",
          paddingBottom:"10px",
          color:"#ccc",
          margin:"0"
        }, 
        "& table":{
          width:"100%",
          fontFamily:"ubuntu",
          color:"#ccc",
          fontSize:"12px",
          "& tr th":{
            padding:"10px",
            background:"#0f0f0f",
          },
          "& tr td":{
            padding:"10px",
            background:"#2b2b2b",
          }
        },
      },
      tableheading:{
        background:"#000"
      },
      timeout:{
        color:"#ccc"
      },
      norecordimg:{
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
          margin: "0px !important",
          height: "110px",
        },
      },
      historybox:{
        padding:"10px",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
          padding: "5px 1px 1px",
        },
        },
  betsbox:{
    padding:"10px"
  },
  bonusbox:{
    padding:"10px"
  },
  balancehistory:{
    color:"#ccc",
    fontSize:"12px",
    fontFamily:"ubuntu",
    "& table":{
      width:"100%"
    },
    "& table td":{
      padding:"10px",
      "& table p":{
        textAlign:"center"
      }
    }
  },
  
  bonusbalancewrap:{
    fontFamily:"ubuntu",
    color:"#fff",
    opacity:"0.4",
    borderBottom:"5px solid #efde1d",
    "& h5":{
      margin:"0px",
      padding:"0px",
      fontSize:"18px",
      lineHeight:"20px",
      fontWeight:"bold",
    },
    "& p":{
      margin:"0px",
      fontSize:"12px",
      padding:"0px",
      textTransform:"uppercase",
      color:"#ccc",
    },
  },
  bonusmainbalance:{
    fontFamily:"ubuntu",
    color:"#fff",
    opacity:"0.4",
    textAlign:"right",
    "& h4":{
      fontSize:"24px",
      margin:"0px",
      fontWeight:"bold",
      lineHeight:"26px",
      padding:"0px",
    },
    "& p":{
      margin:"0px",
      fontSize:"12px",
      padding:"0px",
      color:"#ccc",
    },
  },
  activebonus:{ 
    textAlign:"right",
    "& h5":{
      color:"#efde1d",
      fontWeight:"bold",
    },
    "& p":{
      color:"#ccc",
    }
  },
  balancebonus:{
    color:"#ccc",
    marginTop:"20px",
    fontSize:"12px",
    fontFamily:"ubuntu", 
    width:"100%",
    "& td":{
      padding:"10px",
      background:"#2b2b2b",
      "& p":{
        textAlign:"left",
        fontSize:"12px"
      }
    },
    "& tr:nth-child(odd) td":{
      background: "#000",
    }
  },
  balancebonusinner:{
    width:"100%",
    "& tr th":{
      borderBottom:"1px solid #ccc"
    }, 
  },
  bonusbalancewrapactive:{
    opacity:"1"
  },
  bonusmainbalanceactive:{
    opacity:"1"
  },




  tableresponsi:{
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      overflow: "auto",
    },
  },
  table:{
    boxShadow:" 0px 10px 40px 0px rgba(0, 0, 0, 0.4)",
    position: "relative",
    width: "100%",
    fontFamily: 'Open Sans',
    textAlign: "left",
    marginBottom: "1rem",
    color: "#212529",
    '& tr:hover': {
      background:"#192246",
    },
    '& td': {
      borderTop:"1px solid rgba(255, 255, 255, 0.09)",
      border:"0px",
      verticalAlign:"middle",
      padding:"20px 15px",
      fontWeight:"600",
      fontSize:"18px",
      textTransform:"uppercase",
      color:"#a1aed4",
    },
    '& th': {
      borderTop:"1px solid rgba(255, 255, 255, 0.09)",
      border:"0px",
      fontSize:"18px",
      fontWeight:"600",
      textTransform:"uppercase",
      borderBottom:"0px",
      padding:"20px 15px",
      background:"#0b122e",
      color:"#fff",
    },
    '& img': {
      width:"40px",
      height:"40px",
      borderRadius:"50%",
      verticalAlign:"middle",
      display:"inline-block"
    },
    '& td:nth-child(1) img': {
      marginRight:"5px",
    },
    '& td:nth-child(3) img': {
      marginRight:"5px",
    },
    '& td:nth-child(6) img': {
      marginRight:"5px",
    },
    '& td:nth-child(1)': {
      color:"#fff",
    },
    '& td:nth-child(3)': {
       color:"#045cf0",
    },
    '& td:nth-child(5)': {
      color:"#f0ad4e",
    },
    '& td:nth-child(6)': {
      color:"#f0ad4e",
    },
    '& td:last-child': {
      color:"#f0ad4e",
    },
  },
};

export default tabsStyle;

/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import People from "@material-ui/icons/People";
import Email from "@material-ui/icons/Email";
// react components for routing our app without refresh
import { Link, withRouter } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// @material-ui/core components
import { Button, Divider, Typography, InputAdornment, Icon } from '@material-ui/core';
import CustomInput from "../elements/CustomInput/CustomInput.js";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// core components
import CustomDropdown from "../elements/CustomDropdown/CustomDropdown.js";
import CustomButton from "../elements/CustomButtons/Button.js";
//Third Party Authentication
import { FacebookLogin, GoogleLogin } from "../../../@casino"
import ImgUpload from "../pages/imgupload.js";
import Formsy from 'formsy-react';
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import menuDb from "../data/menu.js";
import logo from "../assets/img/logo.png";
import GridItem from "../elements/Grid/GridItem";
import GridContainer from "../elements/Grid/GridContainer";
import styles from "../assets/jss/components/headerLinksStyle.js";
import stylesr from "../assets/jss/components/headerLinkstopStyle";
import kycmoduleDb from "../data/kycmodule.js";
import * as authActions from '../../../auth/casino/actions';
import { useDispatch, useSelector } from 'react-redux';
import { TextFieldFormsy, CheckboxFormsy } from '../../../@casino';
import secloginboxDb from "../data/secloginbox.js";
import { useTranslation } from 'react-i18next';
const history = createBrowserHistory()
let classicModal = null;
let setClassicModal = null;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";
const validEmailRegex =
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

function UploadAadhar(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const imgupload = useSelector(({ auth }) => auth.imgupload);
  const kycupload = useSelector(({ auth }) => auth.kycupload);
  const [classAdd, setClassAdd] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [uploadText, setUploadText] = useState("");
  const [aadharFrontImage, setAadharFrontImage] = useState("");
  const [aadharBackImage, setAadharBackImage] = useState("");
  let currentStyle = styles;

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  [classicModal, setClassicModal] = React.useState(false);

  useEffect(() => {
    if (imgupload.success) {
      if (imgupload.name == 'front_scan') {
        setAadharFrontImage(imgupload.data.img);
      } else if (imgupload.name == 'back_scan') {
        setAadharBackImage(imgupload.data.img);
      }
      setUploadText("");
      enableButton();
    } else if (imgupload.error) {
      if (imgupload.error.message != null) {
        document.body
          .getElementsByClassName("message_aadharupload")[0]
          .innerHTML = imgupload.error.message;
        setClassAdd(classes.msg = " errormsg");
      }
      disableButton();
      setUploadText("");
    }
  }, [imgupload]);

  useEffect(() => {
    if (kycupload.success) {
      document.body
        .getElementsByClassName("message_aadharupload")[0]
        .innerHTML = "File uploaded successfully";
      setClassAdd(classes.msg = " successmsg");
      setUploadText("");
      props.getKYCData();
      const timer = setTimeout(() => {
        setClassicModal(false);
      }, 3000);
    } else if (kycupload.error) {
      if (kycupload.error.message != null) {
        document.body
          .getElementsByClassName("message_aadharupload")[0]
          .innerHTML = kycupload.error.message;
        setClassAdd(classes.msg = " errormsg");
      }
      setUploadText("");
      disableButton();
    }
  }, [kycupload]);

  function disableButton() {
    setIsFormValid(false);
    document.body
      .getElementsByClassName("signinbtn")[0]
      .setAttribute("style", "opacity:0.5;");
  }

  function enableButton() {
    setIsFormValid(true);
    document.body
      .getElementsByClassName("signinbtn")[0]
      .setAttribute("style", "opacity:1;");
  }

  function uploadImage(e, name) {
    if (e != undefined && e != null) {
      setUploadText("Please Wait...");
      dispatch(authActions.uploadImage(e, name, props));
    }
    document.body
      .getElementsByClassName("message_aadharupload")[0]
      .innerHTML = " "
  }

  function handleSubmit() {
    setUploadText("Uploading...");
    if (aadharFrontImage != "" && aadharBackImage != "") {
      var model = {
        doc_type: 'aadharcard',
        front_scan: aadharFrontImage,
        back_scan: aadharBackImage,
        update: (props.kycData.name != undefined) ? true : false,
        token: window.localStorage.getItem('jwt_access_token')
      };
      dispatch(authActions.uploadKYC(model, props));
      document.body
        .getElementsByClassName("message_aadharupload")[0]
        .innerHTML = "";
    } else {
      document.body
        .getElementsByClassName("message_aadharupload")[0]
        .innerHTML = "Please select both front and back image";
      setClassAdd(classes.msg = " errormsg");
    }
  }

  if (props.themeColors != null) {
    styles.signinbtn.backgroundImage = props.themeColors.bgColors.main_color;
    styles.signinbtn.color = props.themeColors.bgColors.text_primary_in_color;
    styles.modalHeader.background = props.themeColors.bgColors.primary_bgcolor;
    styles.modalBody.background = props.themeColors.bgColors.primary_bgcolor;
    styles.modalFooter.background = props.themeColors.bgColors.primary_bgcolor;
    styles.modalSubtitle.color = props.themeColors.bgColors.text_primary_color;
    styles.signupbixp.color = props.themeColors.bgColors.text_primary_color;
    styles.responsivemodell.backgroundImage = props.themeColors.bgColors.main_color; 
    styles.signinmodalbtn.backgroundImage = props.themeColors.bgColors.main_color;
    styles.signinmodalbtn["&:hover"].backgroundImage = props.themeColors.bgColors.main_color;
    styles.signinmodalbtn.color = props.themeColors.bgColors.text_primary_in_color;
  }
  function parseJwt(token) {
    if (!token) { return; }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}
function getCurrentThemeInfo(){
  if(window.localStorage.getItem('jwt_theme_token') != null){
      let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
      return themeData;
  }
}
  var icon = null; 
  var brandName = null;
  var themeInfo = getCurrentThemeInfo();
  
  if(themeInfo != null && themeInfo.id != null){ 
      if(themeInfo.icon != null && themeInfo.icon.length > 0){
        icon = themeInfo.icon;
      } 
      if(themeInfo.brand_name != null && themeInfo.brand_name.length > 0){
        brandName = themeInfo.brand_name;
      }  
  }

  return (<div>
    <div>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal + " " + classes.responsivemodell

        }}
        open={classicModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setClassicModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
         {kycmoduleDb[sessionStorage.getItem('lang')].map((jsondata) => (
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setClassicModal(false)}
          >
            <Close className={classes.modalClose} />
          </IconButton>


          {(() => {
          // alert(JSON.stringify(themeInfo))
            if(themeInfo.icon != null && (themeInfo.icon != undefined && themeInfo.icon != "undefined" && themeInfo.icon.length > 0)) { 
                    return ( 
                        <img src={icon} height={"40px"} alt={""} className={classes.modellogo} /> 
            ) }else{
              return ( 
                      <h4 className={classes.logoname}>{brandName}</h4>  
                )
      }
      })()}

          
         <h4 className={classes.modalTitle}>{jsondata.aadhar_title}</h4>
        </DialogTitle>
         ))}
       {kycmoduleDb[sessionStorage.getItem('lang')].map((jsondata) => (
        <Formsy
          onValidSubmit={handleSubmit}
          ref={formRef}
          className={classes.form + " flex flex-col justify-center w-full " + classes.modalBody}
        >
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <div className={classes.formggroup}>
              <GridContainer>
                <GridItem xs={12}><div className={classes.msg + " message_aadharupload " + classAdd} id="message"></div></GridItem>
                <GridItem className={classes.btb} xs={12} sm={12} md={6}>
                  <label>{jsondata.aadhar_one}</label>
                  <ImgUpload
                    name="front_scan"
                    onChange={uploadImage}
                    themeColors={props.themeColors}
                    src={aadharFrontImage}
                    required
                  />
                </GridItem>
                <GridItem className={classes.btb} xs={12} sm={12} md={6}>
                  <label>{jsondata.aadhar_two}</label>
                  <ImgUpload
                    name="back_scan"
                    onChange={uploadImage}
                    themeColors={props.themeColors}
                    src={aadharBackImage}
                    required
                  />
                </GridItem>
              </GridContainer>
            </div>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <label>{uploadText}</label>
            <Button color="primary" className={classes.signinmodalbtn + " signinbtn"} disabled={!isFormValid} type="submit">{jsondata.aadhar_verify_btn}</Button>
          </DialogActions>
        </Formsy>
        ))}
      </Dialog>
    </div>
  </div>
  );
}


UploadAadhar.propTypes = {
  openPopup: function (isState) {
    if (isState === "UploadAadhar") {
      setClassicModal(true);
    }
  }
};

UploadAadhar.openPopup = function (isState) {
    if (isState === "UploadAadhar") {
      setClassicModal(true);
    }
};

export default UploadAadhar;
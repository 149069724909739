

const loginhistoryDb = {
    en: [
        { 
            'id': '2342424424',
            'pageheading': 'Login History',  
            'period': 'Period',
            'start_date': 'Start Date',
            'end_date': 'End Date',            
            
        },
    ],
    tm: [
        { 
            'id': '2342424424',
            'pageheading': 'உள்நுழைவு வரலாறு',  
            'period': 'காலம்',
            'start_date': 'தொடக்க தேதி',
            'end_date': 'கடைசி தேதி',            
            
        },
    ],
    kn: [
        { 
            'id': '2342424424',
            'pageheading': 'ಲಾಗಿನ್ ಇತಿಹಾಸ',  
            'period': 'ಅವಧಿ',
            'start_date': 'ಪ್ರಾರಂಭ ದಿನಾಂಕ',
            'end_date': 'ಅಂತಿಮ ದಿನಾಂಕ',            
            
        },
    ],
    ne: [
        { 
            'id': '2342424424',
            'pageheading': 'लग ईन ईतिहास',  
            'period': 'अवधि',
            'start_date': 'सुरू मिति',
            'end_date': 'अन्त्य मिति',            
            
        },
    ],
    tl: [
        { 
            'id': '2342424424',
            'pageheading': 'లాగిన్ చరిత్ర',  
            'period': 'కాలం',
            'start_date': 'ప్రారంబపు తేది',
            'end_date': 'చివరి తేది',            
            
        },
    ],
    hi: [
        { 
            'id': '32423424234',
            'pageheading': 'लॉगिन इतिहास',  
            'period': 'अवधि',
            'start_date': 'आरंभ करने की तिथि',
            'end_date': 'अंतिम तिथि', 
        },
    ]
}
export default loginhistoryDb;



const flagDb = {
    en: [
        {
            'id':'5725a680b3240760ea21de79',
            'titletext':"Choose Your Language",
            'flag_img':"assets/img/english.jpg", 
            'flag_name':"English", 
        } 
    ],
    tm: [
        {
            'id':'5725a680b3240760ea21de79',
            'flag_img':"assets/img/tamil.jpg", 
            'titletext':"உங்கள் மொழியைத் தேர்வுசெய்க",
            'flag_name':"தமிழ்", 
        } 
    ],
    kn: [
        {
            'id':'5725a680b3240760ea21de79',
            'flag_img':"assets/img/kannada.jpg", 
            'titletext':"ನಿಮ್ಮ ಭಾಷೆಯನ್ನು ಆರಿಸಿ",
            'flag_name':"ಕನ್ನಡ", 
        } 
    ],
    ne: [
        {
            'id':'5725a680b3240760ea21de79',
            'flag_img':"assets/img/napali.jpg", 
            'titletext':"तपाइँको भाषा छनौट गर्नुहोस्",
            'flag_name':"नापाली", 
        } 
    ],
    hi: [
        {
            'id':'5725a680b3240760ea21de80',
            'titletext':"अपनी भाषा चुनिए",
            'flag_img':"assets/img/hindi.jpg", 
            'flag_name':"हिन्दी", 
        } 
    ],
    tl: [
        {
            'id':'5725a680b3240760ea21de80',
            'titletext':"మీ భాషను ఎంచుకోండి",
            'flag_img':"assets/img/telugu.jpg", 
            'flag_name':"తెలుగు", 
        } 
    ]
}
export default flagDb;

import React from "react";
// @material-ui/core components
import 'react-accessible-accordion/dist/fancy-example.css';

import axios from 'axios';
import * as globalurl from '../../../global/Global.js'; 

import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";
    
import styles from "../assets/jss/components/tabsStatementstyle.js";
import Button from "../elements/CustomButtons/Button";
import ListItem from "@material-ui/core/ListItem";
// import List from "@material-ui/core/List";
// import * as Datetime from 'react-datetime'; 
// //database selection 
// import localDb from "../data/statement";
   
const useStyles = makeStyles(styles);

// async function getDataFromServer(){
//     let pro = new Promise((resolve,reject) => {
//         const request = axios.post(globalurl.GETSTATEMENT , {
//             token: window.localStorage.getItem('jwt_access_token')
//         });
//         request.then((response) => {
//             //alert(JSON.stringify(response));
//                 if(response.data.data != null){
//                     var data = {
//                         "status":true,
//                         "data":response.data.data
//                     }
//                     resolve(data);
//                 }else{
//                     resolve({"status":false, "message":'No record found'});
//                 }
//         });
//     }); 
//     const val = await pro;
//     return val;
// }


export default function LoginHistoryList(props) {  
    const classes = useStyles();
    const [dateConfig, setDateConfig] = React.useState("NO");
    const [limitStartFrom, setLimitStartFrom] = React.useState(0);
    const limitMaxRange = 5;
    const [loadMore, setLoadMore] = React.useState(false);
    const [isChange, setIsChange] = React.useState(false);
    const [loginLogsData, setLoginLogsData] = React.useState([{status: false}]);
    // const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    const isObject = function(a) {
        return (!!a) && (a.constructor === Object);
    };
   
    if(dateConfig === "NO"){
        setDateConfig({start: props.startDate, end: props.endDate})
    }
    
    if(dateConfig.start !== null && ((props.startDate !== undefined && props.startDate !== dateConfig.start) || (props.endDate !== undefined && props.endDate !== dateConfig.end))){
        setDateConfig({start: props.startDate, end: props.endDate});
        setIsChange(true);
        setLoadMore(true);
    }
//    alert(loginLogsData[0].checked)
    if((loginLogsData[0].status === false && loginLogsData[0].checked == null && props.statement != null) || (loadMore === true && props.statement != null)){
        const request = axios.post(globalurl.GETLOGINLOGS , {
            token: window.localStorage.getItem('jwt_access_token'),
            start_date: dateConfig.start,
            end_date: dateConfig.end,
            LSTART: limitStartFrom,
            LEND: limitMaxRange
        });

        request.then((response) => {
            var statementDb = "";
            setLoadMore(false);
            if(response.data.data != null){
                statementDb = [{
                    "status":true,
                    "data":response.data.data
                }]
                if(loginLogsData[0].status != null && loginLogsData[0].status === true && isChange === false){
                    if(Array.isArray(statementDb[0].data)){
                        statementDb[0].data = [...loginLogsData[0].data, ...statementDb[0].data];
                    }else if(statementDb[0].data != null){
                        statementDb[0].data = [...loginLogsData[0].data, statementDb[0].data];
                    }else{
                        statementDb[0].data = loginLogsData[0].data;
                    }
                    
                    setLoginLogsData(statementDb);
                }else{
                    setLoginLogsData(statementDb);
                    setIsChange(false);
                }
            }else{
                statementDb = [{
                    "status":false,
                    "checked":false
                }]   
                if(loginLogsData[0].status != null && loginLogsData[0].status === true){
                  
                    if(isChange === true){
                        setLoginLogsData(statementDb);
                    }else{
                        setLimitStartFrom((limitStartFrom - limitMaxRange));
                    }
                }else{
                    setLoginLogsData(statementDb);
                }
            }
        });
        request.catch((error) => {
            setLoadMore(false);
            var statementDb = [{
                "status":false
            }]   
            setLoginLogsData(statementDb);
        });
    }
    var LoadMoreRecord = function(){
        setLimitStartFrom((limitStartFrom + limitMaxRange));
        setLoadMore(true);
    }
    return(
        <div>
        {(() => {
            if(loginLogsData[0].status != null && loginLogsData[0].status === true){
                return (<div id="nav-tabs" >
                    {loginLogsData.map((jsondata) => (
                        <div>
                            <table className={classes.tablewrap}>
                                <tr>
                                    <th>IP</th>
                                    <th>Login Status</th>
                                    <th>Location</th>
                                    <th>Time</th>
                                </tr>
                                
                                {(() => {if(Array.isArray(jsondata.data)){return (jsondata.data.map((bodyData) => (
                                    <tr key={bodyData.id}>
                                        <td>{bodyData.ip}</td>
                                        <td>{bodyData.logstatus}</td>
                                <td>{(bodyData.location != null && bodyData.location.length > 0) ? bodyData.location : "Not Available" }</td>
                                        <td>{bodyData.timetaken}</td>
                                    </tr>
                                )))}else if(isObject(jsondata.data)){return(
                                    <tr key={jsondata.data.id}>
                                        <td>{jsondata.data.ip}</td>
                                        <td>{jsondata.data.logstatus}</td>
                                        <td>{(jsondata.data.location != null && jsondata.data.location.length > 0) ? jsondata.data.location: "Not Available" }</td>
                                        <td>{jsondata.data.timetaken}</td>
                                    </tr>
                                )}else{
                                    return(
                                        <tr key={jsondata.data.id}>
                                            <td colspan="4"> <p className={classes.promotioncont}>No record Found</p></td>
                                        </tr>
                                    )
                                }})()}
                            </table>
                            <GridItem xs={12} sm={12} md={12}>
                                <Button
                                    href=""
                                    color="transparent"
                                    className={classes.depositbtn}
                                    onClick={LoadMoreRecord}
                                >
                                Load more...
                                </Button>
                            </GridItem>
                        </div>
                    ))}
                
            </div>);
            }else{
                return (
                    <ListItem className={classes.listItem} key={"statement_nodata"}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <p className={classes.promotioncont}>No record Found</p>
                            </GridItem>
                        </GridContainer>
                    </ListItem>
                    );
            }
        })()}
        </div>
    );
}
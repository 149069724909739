import React, {useEffect, useRef, useState} from "react";
// @material-ui/core components

import 'react-accessible-accordion/dist/fancy-example.css';

import { makeStyles } from "@material-ui/core/styles";


// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Radio from "@material-ui/core/Radio";

import {IconButton, MenuItem} from "@material-ui/core"; 
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";
import CustomTabs from "../elements/CustomTabs/CustomTabs.js";
import accountsDb from "../data/account.js";

import styles from "../assets/jss/components/tabsAccountstyle.js";
import Button from "../elements/CustomButtons/Button";
// import FiberManualRecord from "@material-ui/icons/FiberManualRecord"; 
// import { useTranslation } from 'react-i18next'
import Formsy from 'formsy-react'; 
import {TextFieldFormsy, SelectFormsy} from '../../../@casino';
import * as authActions from '../../../auth/casino/actions';    
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import * as globalurl from '../../../global/Global.js'; 

import MobileOtpbox from "../sections/SectionOtpBox"; 
import VerifyMobile from "../sections/VerifyMobile";
import VerifyEmail from "../sections/VerifyEmail";
import KycModule from "../pages/kycModule";
// import promotionDb from "../data/promotion";
// import Slide from "@material-ui/core/Slide";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import localDb from "../data/bankaccount";
// const token = "kdkddjdj2929j38jv88fpwad83pakwdi3"; 
const useStyles = makeStyles(styles);
var isExistApiCall = false; 
// let isManual = false;
let isProcessRunning = false;
let isCountryRunning = false;
let isStateRunning = false;
export default function Account(props) {
    const [isEditProfile, setIsEditProfile] = React.useState(false);
    const [countryCode, setCountryCode] = React.useState("0");
    const [stateCode, setStateCode] = React.useState("0");
    const {...rest} = props;
    const dispatch = useDispatch();
    const userPass = useSelector(({auth}) => auth.changePassword);
    // const [bankAccounts, setBankAccounts] = React.useState(0);
    const [reloadProfile, setReloadProfile] = React.useState(true);
    const [isFormValid, setIsFormValid] = useState(false);
    const formRef = useRef(null); 
    const formRefBank = useRef(null); 
    const [classAdd, setClassAdd] = useState(false);
    const addBank = useSelector(({auth}) => auth.addbank);
    const userEdit = useSelector(({auth}) => auth.changeProfile);
    
    

    
    const changePassFormRef = useRef(null); 
    const [isChangePasswordFormValid, setIsChangePasswordFormValid] = useState(false);
    
    function disableChangePasswordButton(){
        setIsChangePasswordFormValid(false)
        document.body
        .getElementsByClassName("changepassbtn")[0]
          .setAttribute("style", "opacity:0.5;");
    }
    function enableChangePasswordButton(){
        setIsChangePasswordFormValid(true)
        document.body
        .getElementsByClassName("changepassbtn")[0]
          .setAttribute("style", "opacity:1;");
    }
    useEffect(() => {
        if ( userPass.error && (userPass.error.newpassword || userPass.error.oldpassword || userPass.error.message) )
        { 
            if(userPass.error.message != null && document.body
                .getElementsByClassName("message_passs")[0] !== null){
                document.body
                .getElementsByClassName("message_passs")[0] 
                .innerHTML=userPass.error.message; 
                setClassAdd(classes.msg = " errormsg" );
            }else{
                changePassFormRef.current.updateInputsWithError({
                    ...userPass.error
                });
            } 
            disableChangePasswordButton();
        }
       //alert(JSON.stringify(userPass.success));
        if(userPass.success != null && userPass.success === true){
            if(userPass.data != null && userPass.data.message != null){
                document.body
                .getElementsByClassName("message_passs")[0] 
                .innerHTML=userPass.data.message; 
                setClassAdd(classes.msg = " successmsg" );
            }else{
                document.body
                .getElementsByClassName("message_passs")[0] 
                .innerHTML="Your password updated successfully."; 
                setClassAdd(classes.msg = " successmsg" );
            }  
        } 
    }, [userPass.error, userPass.data, userPass.success]);

    useEffect(() => {
        if ( userEdit.error && (userEdit.error.first_name || userEdit.error.last_name || userEdit.error.email || userEdit.error.mobile || userEdit.error.message) )
        {
            if(userEdit.error.message != null){
                document.body
                .getElementsByClassName("message_profile")[0] 
                .innerHTML=userEdit.error.message; 
                setClassAdd(classes.msg = " errormsg" );
            }else{
                formRef.current.updateInputsWithError({
                    ...userEdit.error
                });
            }
            
            
            disableChangePasswordButton();
        }
        
        if(userEdit.success != null && userEdit.success === true){
            if(userEdit.data != null && userEdit.data.message != null){
                document.body
                .getElementsByClassName("message_profile")[0] 
                .innerHTML=userEdit.data.message; 
                setClassAdd(classes.msg = " successmsg" );
            }else{
                document.body
                .getElementsByClassName("message_profile")[0] 
                .innerHTML="Profile updated successfully."; 
                setClassAdd(classes.msg = " successmsg" );
            }
            setReloadProfile(true); 
            setIsEditProfile(false);
        }
    }, [userEdit.error, userEdit.data, userEdit.success]);

    function handleChangePasswordSubmit(model)
    { 
        dispatch(authActions.submitChangePassword(model, props));
         document.body
            .getElementsByClassName("message_passs")[0]
            .innerHTML=" "
    } 
    const [formData, setFormData] = React.useState([{status: false}]);
    
    if(((formData[0].status === false && formData[0].checked === null) || reloadProfile === true) && isExistApiCall === false){
        isExistApiCall = true;
        const request = axios.post(globalurl.GETPROFILE , {
            token: window.localStorage.getItem('jwt_access_token')
        });
        request.then((response) => {
            isExistApiCall = false;
            var statementDb = "";
            if(response.data.data != null){
                statementDb = [{
                    "status":true,
                    "data":response.data.data
                }]
                setFormData(statementDb);
            }else{
                statementDb = [{
                    "status":false,
                    "checked":false
                }]   
                setFormData(statementDb);
            }
            setReloadProfile(false);
        });
        request.catch(() => {
            isExistApiCall = false;
            var statementDb = [{
                "status":false
            }]   
            setFormData(statementDb);
            setReloadProfile(false);
        });
    }

    if(props.themeColors != null){
        styles.accountsbox.background = props.themeColors.bgColors.secondary_dark_bgcolor; 
        styles.listItem["&:nth-child(even)"].background = props.themeColors.bgColors.primary_bgcolor;
        styles.listItem.color = props.themeColors.bgColors.text_secondary_dark_color;
        styles.signinnerpageboxin.background = props.themeColors.bgColors.secondary_dark_bgcolor;
        styles.userprobox["& span"].color = props.themeColors.bgColors.text_primary_color;
        styles.depositbtn.background = props.themeColors.bgColors.main_color;
        styles.depositbtn.color = props.themeColors.bgColors.text_primary_in_color;
        styles.depositbtn["& span"].color = props.themeColors.bgColors.text_primary_in_color
        styles.accountsheads.color = props.themeColors.bgColors.text_primary_color;
        styles.addbtn.color = props.themeColors.bgColors.text_primary_in_color;
        styles.addbtn["& span"].color = props.themeColors.bgColors.text_primary_in_color;
        styles.addbtn.background = props.themeColors.bgColors.main_color;
        styles.addbtn.backgroundImage = props.themeColors.bgColors.main_color;
        styles.changebankacc.color = props.themeColors.bgColors.text_primary_color;
        styles.addbankaccoutbox["& input"].color = props.themeColors.bgColors.text_primary_color;
        styles.ifscbtn.color = props.themeColors.bgColors.text_secondary_color;
        styles.depositbtn2.background = props.themeColors.bgColors.main_color;
        styles.depositbtn2.color = props.themeColors.bgColors.text_primary_in_color;
        styles.editprobox["& input"].color = props.themeColors.bgColors.text_primary_color;
        styles.editprobox["& p"].color = props.themeColors.bgColors.text_primary_color;
        styles.signinnerpagebox.background = props.themeColors.bgColors.primary_bgcolor; 
        styles.signinnerpageboxin.background = props.themeColors.bgColors.primary_bgcolor; 
        styles.accountsbox["& h3"].color = props.themeColors.bgColors.text_primary_color; 

        styles.depositbtn["& span"].fontFamily = props.themeColors.bgColors.base_font;
        styles.depositbtn.fontFamily = props.themeColors.bgColors.base_font;    
        styles.userprobox.fontFamily = props.themeColors.bgColors.base_font;     
        styles.editprobox["& label"].fontFamily = props.themeColors.bgColors.base_font; 
        styles.editprobox["& input"].fontFamily = props.themeColors.bgColors.base_font;
        styles.editprobox["& p"].fontFamily = props.themeColors.bgColors.base_font;    
        styles.changepasbox["& label"].fontFamily = props.themeColors.bgColors.base_font;    
        styles.changepasbox["& input"].fontFamily = props.themeColors.bgColors.base_font; 
        
        styles.accountsbox["& h3"].fontFamily = props.themeColors.bgColors.base_font;    
        styles.addbtn.fontFamily = props.themeColors.bgColors.base_font;    
        styles.addbtn["& span"].fontFamily = props.themeColors.bgColors.base_font;    
        styles.listItem.fontFamily = props.themeColors.bgColors.base_font;    
        styles.changebankacc.fontFamily = props.themeColors.bgColors.base_font;    
        styles.addbankaccoutbox["& label"].fontFamily = props.themeColors.bgColors.base_font;    
        styles.addbankaccoutbox["& input"].fontFamily = props.themeColors.bgColors.base_font;   
        styles.ifscbtn.fontFamily = props.themeColors.bgColors.base_font;    
        styles.depositbtn2.fontFamily = props.themeColors.bgColors.base_font;      
    } 
    // const { t } = useTranslation()
    const classes = useStyles();  
    // const [selectedEnabled, setSelectedEnabled] = React.useState("b");
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};  
    const [limitStartFrom, setLimitStartFrom] = React.useState(0);
    const limitMaxRange = 5;
    const [loadMore, setLoadMore] = React.useState(false);
    const [bankData, setBankData] = React.useState([{status: false}]);
    const [branchName, setBranchName] = React.useState("");
    const [bankName, setBankName] = React.useState("");
    var ifscCode = "";

    function ifscChange(event){
        
        if(event.target != null && event.target.value != null && event.target.value.length >= 11){
            ifscCode = event.target.value;
            fetchIFSCDetails();
        }else{
            ifscCode = event.target.value;
        }
        document.body
                .getElementsByClassName("message_addbank")[0] 
                .innerHTML=""; 
    }
    function fetchIFSCDetails(){
        if(ifscCode != null && ifscCode.length >= 11){
            document.body
            .getElementsByClassName("message_addbank")[0]
            .innerHTML=""
            const request = axios.post(globalurl.GETIFSCDETAILS , {
                token: window.localStorage.getItem('jwt_access_token'),
                ifsc_code: ifscCode
            });
            
            request.then((response) => {
                if(response.data.data != null){
                    var bankDetails = response.data.data;
                    if(bankDetails.branch != null){
                        setBranchName(bankDetails.branch);
                    }
                    if(bankDetails.bankname != null){
                        setBankName(bankDetails.bankname);
                    }
                }else{
                    document.body
                    .getElementsByClassName("message_addbank")[0] 
                    .innerHTML="Bank details not found as per IFSC. Please put details in fields."; 
                    setClassAdd(classes.msg = " errormsg" );
                }
            });
            request.catch((error) => {
                
            });
        }else{
            document.body
            .getElementsByClassName("message_addbank")[0] 
            .innerHTML="Please put correct IFSC Code."; 
            setClassAdd(classes.msg = " errormsg" );
        }
    }
    function showChangePassword() {
        document.body
            .getElementsByClassName("changePasswordDiv")[0]
            .setAttribute("style", "display:block;");
        document.body
            .getElementsByClassName("userProfileDiv")[0]
            .setAttribute("style", "display:none;");
    } 
    function showEditProfile() {
        setIsEditProfile(true);
  }
      function showUserProfile() {
        setIsEditProfile(false);
      } 

      useEffect(() => {
            if ( addBank.error && (addBank.error.acc_number || addBank.error.acc_name || addBank.error.ifsc_code || addBank.error.bank_name || addBank.error.branch_name || addBank.error.message) )
            {
                if(addBank.error.message != null){
                    document.body
                    .getElementsByClassName("message_addbank")[0] 
                    .innerHTML=addBank.error.message   
                    setClassAdd(classes.msg = " errormsg" );
                //   alert(addBank.error.message);
                }else{
                    formRefBank.current.updateInputsWithError({
                        ...addBank.error
                    });
                    
                }
                disableButton();
                
            }else{
                if(addBank.data != null && addBank.data.status != null && addBank.data.status === "OK"){


                    setTimeout(function() {
                        window.location.reload();  
                    }, 5000); 

                    setClassAdd(classes.msg = " successmsg" )
                    
                    document.body
                    .getElementsByClassName("message_addbank")[0].innerHTML="Bank Account added successfully"
                }
                
            }
        }, [addBank.error, addBank.data, classes.msg]); 
        function disableButton()
        {
            setIsFormValid(false);
            document.body
            .getElementsByClassName("signupbtn")[0]
              .setAttribute("style", "opacity:0.5;");
        }
    
        function enableButton()
        {
            setIsFormValid(true);
            document.body
            .getElementsByClassName("signupbtn")[0]
              .setAttribute("style", "opacity:1;");
        }
        function handleSubmitProfile(model){
            dispatch(authActions.submitProfileInfo(model,props));
            
            document.body
            .getElementsByClassName("message_profile")[0]
            .innerHTML=" " 
        }
        function handleSubmit(model)
        { 
            dispatch(authActions.submitAddbank(model,props));
           // window.location.reload(false);
            setBankData([{status:false}]);
            document.body
            .getElementsByClassName("message_addbank")[0]
            .innerHTML=" " 
        } 
        
        const isObject = function(a) {
            return (!!a) && (a.constructor === Object);
        };
        if((bankData[0].status == false && bankData[0].checked == null ) || (loadMore === true )){
         //   alert("sadads");
         if(bankData[0].status != null && bankData[0].status === true){
         }else{
            setBankData([{status:true}]);
         }
            setLoadMore(false);
            const request = axios.post(globalurl.BANKACCOUNTS , {
                token: window.localStorage.getItem('jwt_access_token'),
                LSTART: limitStartFrom,
                LEND: limitMaxRange
            });
            
            request.then((response) => {
             
                setLoadMore(false);
                var bankDb = "";
                if(response.data.data != null){
                    bankDb = [{
                        "status":true,
                        "data":response.data.data
                    }]
                    if(bankData[0].status != null && bankData[0].status === true){
                        bankDb[0].data = [...bankData[0].data, ...bankDb[0].data]
                        setBankData(bankDb);
                    }else{
                        setBankData(bankDb);
                    }
                }else{
                    bankDb = [{
                        "status":false,
                        "checked":false
                    }]   
                    if(bankData[0].status != null && bankData[0].status === true){
                        setLimitStartFrom((limitStartFrom - limitMaxRange));
                    }else{
                        setBankData(bankDb);
                    }
                }
            });
            request.catch((error) => {
                setLoadMore(false);
                var bankDb = [{
                    "status":false
                }]   
                setBankData(bankDb);
            });
        }
        var LoadMoreRecord = function(){
            setLimitStartFrom((limitStartFrom + limitMaxRange));
            setLoadMore(true);
        }  
        function addaccountf()
        {
            setIsFormValid(true);
            document.body
            .getElementsByClassName("addaccountform")[0]
              .setAttribute("style", "display:block;");
        }
        function deleteaccountf(bankerId) {
            var r = window.confirm("Do you really want to delete your bank account!");
            if (r === true) {
            const request = axios.post(globalurl.BANKACCOUNTDELETE , {
                token: window.localStorage.getItem('jwt_access_token'),
                banker_id: bankerId
             }); 
            
            request.then((response) => { 
             //   alert(JSON.stringify(response));
              
                if(response.data != null && response.data.status != null && response.data.status === true){
                    setBankData([{status:false}]); 
                }  
            });
            request.catch(() => { 
                 
            });
        }  
    }


    const dateNow = new Date();
   const year = dateNow.getFullYear(); // Getting current year from the created Date object
    const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const month = // Setting current Month number from current Date object
        monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
        ? `0${monthWithOffset}`
        : monthWithOffset;
    const date =
        dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${dateNow.getUTCDate()}`
        : dateNow.getUTCDate();
   const MaxDateAvailable = `${year}-${month}-${date}`; 

   function changeCountryId(event){
        if(event != null && event.target != null && event.target.value != null && event.target.value > 0){
            fetchStatesDetails(event.target.value);
        }
    }

    function fetchStatesDetails(country_id){
        //    alert("dsfdfsds");
        if( isStateRunning === false){
            isStateRunning = true;
            setStateCode("PROCESSING");        
            const request = axios.post(globalurl.STATES_DATA,{
                country_id: Number(country_id)
            });
        
            request.catch((error) => {
                isStateRunning = false;
                setStateCode("0");
            }); 
            request.then((response) => { 
                isStateRunning = false;
                //alert("hkjhh"+JSON.stringify(response));
                
                if(response.data.data != null){ 
                    var myOutPut = (
                        response.data.data.map((jsondata) => (
                            <MenuItem value={jsondata.id} key={jsondata.id}>{jsondata.title} ({jsondata.iso2})</MenuItem> 
                        ))
                    );
                    setStateCode(myOutPut);
                    //alert(JSON.stringify(gameType));
                };
            }); 
        }
                 
        }  

   function getcountrydatalist(){
    //    alert("dsfdfsds");
    if( isCountryRunning === false){
        isCountryRunning = true;
        setCountryCode("PROCESSING");
        
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = "*";
        axios.defaults.headers.post['Access-Control-Allow-Methods'] = "GET, POST, OPTIONS, PUT, PATCH, DELETE";
        axios.defaults.headers.post['Access-Control-Allow-Headers'] = "rigin, X-Requested-With, Content-Type, Accept";
        axios.defaults.headers.common = {};
        axios.defaults.headers.common.accept = 'application/json';
        
        const request = axios.get(globalurl.WLCOUNTRY_DATA);
    
        request.catch((error) => {
            isCountryRunning = false;
            setCountryCode("0");
        }); 
        request.then((response) => { 
            isCountryRunning = false;
            //alert("hkjhh"+JSON.stringify(response));
            
            if(response.data.data != null){ 
                var myOutPut = (
                    response.data.data.map((jsondata) => (
                        <MenuItem value={jsondata.id} key={jsondata.id}>{jsondata.title} ({jsondata.phonecode})</MenuItem> 
                    ))
                );
                setCountryCode(myOutPut);
                //alert(JSON.stringify(gameType));
            };
        }); 
    }
             
    }   
    //alert(countryCode);   
    if(countryCode === "0"){
        setCountryCode("Processing");
        getcountrydatalist();
    }
    
    return (
        <div id="nav-tabs" className={classes.signinnerpagebox} >
            {(() =>  {if(formData[0].status === true){
                var fullName = formData[0].data["name"].split(" ");
                var firstName = "";
                var lastName = "";
                if(stateCode === "0" && formData[0].data["country_id"] != null && formData[0].data["country_id"] > 0){
                    fetchStatesDetails(formData[0].data["country_id"]);
                }
                if(fullName != null){
                    for(var nameRun=0;nameRun<fullName.length;nameRun++){
                        if(nameRun === 0){
                            firstName = fullName[nameRun];
                        }else if(fullName.length === (nameRun + 1) && nameRun > 0){
                            lastName = fullName[nameRun];
                        }else{
                            firstName = firstName + " " + fullName[nameRun];
                        }
                    }
                }
                
                return (
                <div id="nav-tabs" className={classes.signinnerpageboxin} > 
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomTabs
                        themeColors={props.themeColors}
                        plainTabs
                        border="round"
                        tabs={accountsDb[sessionStorage.getItem('lang')].map((jsondata) => (
                            {
                            tabName: (<span>{jsondata.title}</span>),
                            tabContent: (
                                <div>
                                {(() =>  {
                                    if (jsondata.tagName === "tab1") {
        
                                        return (<div className={classes.accountsbox}>
                                         {(() =>  {
                                            if(isEditProfile === false){
                                                return (
                                            <div className={classes.userprobox + " userProfileDiv"}>
                                            <div className={classes.userprowrapp}>
                                                <GridContainer>
                                                        {jsondata.userprofilediv.map((jsondata) => (   
                                                            <GridItem xs={12} sm={5} md={6} key={jsondata.id}>
                                                                <p>{jsondata.display_name}</p> 
                                                                
                                                                    {(()=>{
                                                                        if(jsondata.id === "date_of_birth" && formData[0].data[jsondata.id] !== "0000-00-00"){
                                                                            return(
                                                                                <span>
                                                                                {(new Date(formData[0].data[jsondata.id])).toLocaleDateString('en-US', DATE_OPTIONS)} <span title="verifyed" className={classes.verifyed}></span>
                                                                                </span>
                                                                            )
                                                                        }if(jsondata.id === "date_of_birth"){
                                                                            return(
                                                                                <span>
                                                                                N/A <span  title="verifyed" className={classes.verifyed}></span>
                                                                                </span>
                                                                            )
                                                                        }else{
                                                                            if(jsondata.id === "email" && formData[0].data.is_email_confirm === 1){
                                                                                return(
                                                                                    <span>
                                                                                    {formData[0].data[jsondata.id]}  <span title="verified" className={((formData[0].data.is_email_confirm === 0) ? classes.verify : classes.verifyed)}></span>
                                                                                    </span>
                                                                                )
                                                                            }else if(jsondata.id === "email" && formData[0].data.is_email_confirm === 0){
                                                                                return(
                                                                                    <span>
                                                                                    {formData[0].data[jsondata.id]}  <span title="Not Verified" onClick={() => {VerifyEmail.openPopup("VerifyEmail")}} className={((formData[0].data.is_email_confirm === 0) ? classes.verify : classes.verifyed)}></span>
                                                                                    </span>
                                                                                )
                                                                            }else if(jsondata.id === "mobile" && formData[0].data.is_mobile_confirm === 1){
                                                                                return(
                                                                                    <span>
                                                                                    {formData[0].data[jsondata.id]}  <span title="verified" className={classes.verifyed}></span>
                                                                                    </span>
                                                                                )
                                                                            }else if(jsondata.id === "mobile" && formData[0].data.is_mobile_confirm === 0){
                                                                                return(
                                                                                    <span>
                                                                                    {formData[0].data[jsondata.id]}  <span title="Not Verified" onClick={() => {VerifyMobile.openPopup("VerifyMobile");}}  className={classes.verify}></span>
                                                                                    </span>
                                                                                )
                                                                            }else if(jsondata.id === "commission_type"){
                                                                                // return(
                                                                                //     <span>
                                                                                //     {(globalurl.COMMISSIONTYPE[formData[0].data[jsondata.id]] != null) ? globalurl.COMMISSIONTYPE[formData[0].data[jsondata.id]] : ""}
                                                                                //     </span>
                                                                                // )
                                                                            }else{
                                                                                return(
                                                                                    <span>
                                                                                    {formData[0].data[jsondata.id]}
                                                                                    </span>
                                                                                )
                                                                            }
                                                                        }
                                                                    })()}
                                                                     <MobileOtpbox otpVerifyBy={"Mobile"} {...rest} />
                                                                     <VerifyMobile OtpBox={MobileOtpbox} mobileNumber={formData[0].data["mobile"]} {...rest} />
                                                                     <VerifyEmail emailId={formData[0].data["email"]} {...rest} />
                                                            </GridItem>
                                                        ))}   
                                                 </GridContainer>
                                                 </div>
                                                 <GridContainer> 
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <div className={classes.depositformbtni}>
                                                        <Button
                                                            onClick={showChangePassword}
                                                            color="transparent"
                                                            className={classes.depositbtn}
                                                        >
                                                           {jsondata.changepasswordbtn}
                                                        </Button> 
                                                        <Button
                                                            onClick={showEditProfile}
                                                            color="transparent"
                                                            className={classes.depositbtn}
                                                        >
                                                           {jsondata.editprofilebtn}
                                                        </Button>
                                                        </div>
                                                    </GridItem>
                                                </GridContainer>
        
                                            </div>
                                            )
                                            }
                                            
                                            })()}
                                            <div className={classes.changepasbox + " changePasswordDiv"}>
                                                <Formsy
                                                    onValidSubmit={handleChangePasswordSubmit}
                                                    onValid={enableChangePasswordButton}
                                                    onInvalid={disableChangePasswordButton}
                                                    ref={changePassFormRef}
                                                    autoComplete={"off"}
                                                    className={classes.form + " flex flex-col justify-center w-full"}
                                                >
                                                <GridContainer>
                                                <GridItem xs={12}><div className={classes.msg + " message_passs " + classAdd} id="message"></div></GridItem>
                                               
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.oldpassword}
                                                            id="oldpassword"
                                                            name="oldpassword"
                                                            type="password"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 4
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 4'
                                                            }} 
                                                            variant="outlined"
                                                            required
                                                        />  
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.newpassword}
                                                            id="newpassword"
                                                            name="newpassword"
                                                            type="password"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }} 
                                                            validations={{
                                                                minLength: 6,
                                                                maxLength: 20,
                                                                upperCase: function(value){
                                                                    if(new RegExp(/^(?=.*[A-Z]).*$/).test(value.newpassword)){
                                                                        return true;
                                                                    }else{
                                                                        return false;
                                                                    }
                                                                },
                                                                specialCase: function(value){
                                                                    if(new RegExp(/^(?=.*[!@#$&*]).*$/).test(value.newpassword)){
                                                                        return true;    
                                                                    }else{
                                                                        return false;
                                                                    }
                                                                },
                                                                numberCase: function(value){
                                                                    if(new RegExp(/^(?=.*[0-9]).*$/).test(value.newpassword)){
                                                                        return true;
                                                                    }else{
                                                                        return false;
                                                                    }
                                                                },
                                                            }}
                                                            validationErrors={{
                                                                minLength: "Length should be minimum 6",
                                                                maxLength: "Length should not be greater 20",
                                                                upperCase: "Atleast one uppercase",
                                                                specialCase: "Atleast one special char",
                                                                numberCase: "Atleast one numeric char",
                                                            }}
                                                            variant="outlined"
                                                            required
                                                        />  
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.confirmpassword}
                                                            id="confirmpassword"
                                                            name="confirmpassword"
                                                            type="password"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{ 
                                                                equalsField: "newpassword"
                                                            }} 
                                                            validationErrors={{ 
                                                                equalsField: 'Please Re-enter same password'
                                                            }} 
                                                            variant="outlined"
                                                            required
                                                        />   
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <Button 
                                                            type="submit"
                                                            color="transparent" 
                                                            className={classes.depositbtn +" changepassbtn"}
                                                            disabled={!isChangePasswordFormValid}
                                                        >
                                                            {jsondata.updatepasswordbtn}
                                                        </Button>
                                                        <Button
                                                            onClick={showUserProfile}
                                                            color="transparent"
                                                            className={classes.depositbtn}
                                                        >
                                                           {jsondata.cancelbtn}
                                                        </Button>
                                                    </GridItem>
                                                </GridContainer>
                                                </Formsy>              
                                            </div>
                                            {(() =>  {
                                                if(isEditProfile === true){
                                                return (
                                            <div className={classes.editprobox + " editProfileDiv"}>
                                            <Formsy
                                                    onValidSubmit={handleSubmitProfile}
                                                    onValid={enableButton}
                                                    onInvalid={disableButton}
                                                    ref={formRef}
                                                    autoComplete={"off"}
                                                    className={classes.form + " flex flex-col justify-center w-full"}
                                                >
                                                <GridContainer>
                                                    <GridItem xs={12}><div className={classes.msg + " message_profile " + classAdd} id="message"></div></GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.formfirstname}
                                                            id="first_name"
                                                            name="first_name"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 2
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 2'
                                                            }} 
                                                            value={firstName}
                                                            variant="outlined"
                                                            required
                                                        />   
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.formlastname}
                                                            id="last_name"
                                                            name="last_name"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 2
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 2'
                                                            }} 
                                                            value={lastName}
                                                            variant="outlined"
                                                            required
                                                        />    
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.formemail}
                                                            id="email"
                                                            name="email"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 5,
                                                                isEmail: true
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 5',
                                                                isEmail: 'Please enter a valid email'
                                                            }}
                                                            value={formData[0].data["email"]}
                                                            variant="outlined"
                                                            required
                                                        />     
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.formphone}
                                                            id="mobile"
                                                            name="mobile"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 9,
                                                                isNumeric:true
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 9',
                                                                isNumeric:"Number Only"
                                                            }} 
                                                            value={formData[0].data["mobile"]}
                                                            variant="outlined"
                                                            required
                                                        />     
                                                    </GridItem>
                                                    
                                                    <GridItem xs={12} sm={12} md={6}> 
                                                        <div className={classes.formggroup}>
                                                            <SelectFormsy 
                                                            className={classes.selectboxx + " selectbox " + classes.countrydopdown} 
                                                                    name="country_id"
                                                                    label={jsondata.formcountry} 
                                                                    value={formData[0].data["country_id"]}
                                                                    onChange={changeCountryId}
                                                                    variant="outlined"
                                                                    required
                                                                >
                                                                    {countryCode}
                                                            </SelectFormsy>
                                                        </div>
                                                    </GridItem> 
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.formaddress}
                                                            id="address"
                                                            name="address"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 2
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 2'
                                                            }} 
                                                            value={formData[0].data["address"]}
                                                            variant="outlined"
                                                             
                                                        />    
                                                        
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}> 
                                                        <TextFieldFormsy  
                                                            label={jsondata.formdob}
                                                            name="date_of_birth"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            type="date" 
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            value={(new Date(formData[0].data["date_of_birth"])).toLocaleDateString('fr-CA',{year:"numeric",month:"2-digit", day:"2-digit"})}
                                                            InputProps={{inputProps: {  max: MaxDateAvailable} }}
                                                            
                                                            variant="outlined" 
                                                        />  
                                                        
                                                    </GridItem>
                                                             

                                                    <GridItem xs={12} sm={12} md={6}>
                                                    <div className={classes.formggroup}>
                                                            <SelectFormsy 
                                                            className={classes.selectboxx + " selectbox " + classes.countrydopdown}
                                                                    
                                                                    name="state_id"
                                                                    label={jsondata.formstate}
                                                                    value={formData[0].data["state_id"]}
                                                                    variant="outlined"
                                                                    required
                                                                >
                                                                    {stateCode}
                                                            </SelectFormsy> 

                                                            
                                                         </div>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.formcity}
                                                            id="city"
                                                            name="city"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 2
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 2'
                                                            }} 
                                                            value={formData[0].data["locality"]}
                                                            variant="outlined"
                                                             required
                                                        /> 
                                                        
                                                        
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextFieldFormsy  
                                                             label={jsondata.formpincode}
                                                            id="pincode"
                                                            name="pincode"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            value={formData[0].data["post_code"]}
                                                            variant="outlined"
                                                            required
                                                        /> 
                                                         
                                                    </GridItem>        

                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <p>*{jsondata.one_p}</p>
                                                        <p>*{jsondata.two_p}</p>
                                                        <p>*{jsondata.three_p}</p>
                                                    <div className={classes.depositformbtni}>
                                                        <Button
                                                            type="submit"
                                                            color="transparent" 
                                                            className={classes.depositbtn +" signupbtn"}
                                                            disabled={!isFormValid}
                                                        >
                                                               {jsondata.updateprofilebtn}
                                                        </Button>
                                                        <Button
                                                            onClick={showUserProfile}
                                                            color="transparent"
                                                            className={classes.depositbtn}
                                                        >
                                                           {jsondata.cancelbtn}
                                                        </Button>
                                                        </div>
                                                    </GridItem>
                                                </GridContainer>
                                                </Formsy>
                                            </div>
                                            )
                                            }
                                            
                                            })()}
                                        </div>)
        
                                    }else if (jsondata.tagName === "tab2"){
                                        return (<div className={classes.accountsbox}>
                                            <h3 className={classes.accountsheads}>{jsondata.title}
                                            <Button 
                                                    href="#"
                                                    color="transparent" 
                                                    onClick={addaccountf}
                                                    className={classes.addbtn} 
                                                >
                                                    {jsondata.addbankaccount}  
                                                </Button>
                                            </h3>
                                            <div className={classes.addaccountform + " addaccountform"}>
                                            <Formsy
                                                    onValidSubmit={handleSubmit}
                                                    onValid={enableButton}
                                                    onInvalid={disableButton}
                                                    ref={formRefBank}
                                                    autoComplete={"off"}
                                                    className={classes.form + " flex flex-col justify-center w-full"}
                                                >
                                            <div className={classes.addbankaccoutbox}>
                                            <p className={classes.changebankacc}> {jsondata.ptext}  </p>
                                            <GridContainer>
                                            <GridItem xs={12}><div className={classes.msg + " message_addbank " + classAdd} id="message"></div></GridItem>
                                                    <GridItem xs={12} sm={12} md={4}>
                                                    <TextFieldFormsy 
                                                            label={jsondata.acountname}  
                                                            id="acountname"
                                                            name="acc_name"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 2
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 2'
                                                            }} 
                                                            variant="outlined"
                                                            required
                                                        />   
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.ifsccode}  
                                                            id="ifsccode"
                                                            name="ifsc_code"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 6
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 6'
                                                            }} 
                                                            onChange={ifscChange}
                                                            variant="outlined"
                                                            required
                                                        />   
                                                <Button
                                                    color="transparent"
                                                    type="button"
                                                    className={classes.ifscbtn}
                                                    onClick={fetchIFSCDetails}
                                                >
                                                   {jsondata.ifsc_text}  
                                                </Button>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4}>  
                                                    <TextFieldFormsy 
                                                            label={jsondata.bank_name}  
                                                            id="bank_name"
                                                            name="bank_name"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 2
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 2'
                                                            }} 
                                                            value={bankName}
                                                            variant="outlined"
                                                            required
                                                        />  
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.bankbranch}  
                                                            id="bankbranch"
                                                            name="branch_name"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 3
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 3'
                                                            }} 
                                                            value={branchName}
                                                            variant="outlined"
                                                            required
                                                        /> 
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4}>
                                                    <TextFieldFormsy 
                                                            label={jsondata.accountnumber} 
                                                            id="accountnumber"
                                                            name="acc_number"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 4
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 4'
                                                            }} 
                                                            variant="outlined"
                                                            required
                                                        />  
                                                </GridItem>
                                                
                                                <GridItem xs={12} sm={12} md={12}>
                                                <div className={classes.depositformbtni}> 
                                                <Button 
                                                    type="submit"
                                                    color="transparent" 
                                                    className={classes.depositbtn2 +" signupbtn"}
                                                    disabled={!isFormValid}
                                                >
                                                    {jsondata.myaccountbtn} 
                                                </Button></div>
                                                </GridItem>
                                                </GridContainer>
                                            </div>
                                            </Formsy>
                                            </div>
                                            <div className={classes.depositlistwarp}>
                                           




                                            {(() => {
                                            if(bankData[0].status != null && bankData[0].status === true){
                                                
                                                return (<div id="nav-tabs" className={classes.depositlistw} >
                                                {bankData.map((jsondata) => (
                                                        <div className={classes.messagebox} key={":myunique_1"}>
                                                            <List className={classes.list}>
                                                                <ListItem className={classes.listItem + " " + classes.tableheading}>
                                                                    <GridContainer>
                                                                        {localDb[sessionStorage.getItem('lang')][0].tableheader.map((headData) => (
                                                                        <GridItem xs={headData.xs} sm={headData.xs} md={headData.xs} key={headData.id}>
                                                                            <p className={classes.promotionhead}>{headData.field_name}</p>
                                                                        </GridItem>
                                                                        ))} 
                                                                    </GridContainer>
                                                                </ListItem>
                                                                
                                                                {(() => {if(Array.isArray(jsondata.data)){return (jsondata.data.map((bodyData) => (
                                                                    <ListItem className={classes.listItem} key={bodyData.id}>
                                                                        <GridContainer>
                                                                            <GridItem xs={2} sm={2} md={2}>
                                                                                <p className={classes.promotioncont}>{bodyData.acc_name}</p>
                                                                            </GridItem>
                                                                            <GridItem xs={2} sm={2} md={2}>
                                                                                <p className={classes.promotioncont}>{bodyData.acc_number}</p>
                                                                            </GridItem>
                                                                            <GridItem xs={2} sm={2} md={2}>
                                                                                <p className={classes.promotioncont}>{bodyData.ifsc_code}</p>
                                                                            </GridItem>
                                                                            <GridItem xs={2} sm={2} md={2}>
                                                                                <p className={classes.promotioncont}>{bodyData.bank_name}</p>
                                                                            </GridItem>
                                                                            <GridItem xs={2} sm={2} md={2}>
                                                                                <p className={classes.promotioncont}>{bodyData.branch_name}</p>
                                                                            </GridItem>
                                                                            <GridItem xs={1} sm={1} md={1}>
                                                                                <p className={classes.promotioncont}>{bodyData.branch_address}</p>
                                                                            </GridItem>
                                                                            <GridItem xs={1} sm={1} md={1}>
                                                                                <Button
                                                                                    href="#"
                                                                                    color="transparent"
                                                                                    onClick={() => deleteaccountf(bodyData.id)}
                                                                                    className={classes.messagedeletebtn}
                                                                                >
                                                                                    <i className={"fa fa-trash"}></i>
                                                                                </Button>
                                                                            </GridItem>
                                                                        </GridContainer>
                                                                    </ListItem>
                                                                )))}else if(isObject(jsondata.data)){return(
                                                                <ListItem className={classes.listItem}>
                                                                    <GridContainer>
                                                                        <GridItem xs={2} sm={2} md={2}>
                                                                            <p className={classes.promotioncont}>{jsondata.data.acc_name}</p>
                                                                        </GridItem>
                                                                        <GridItem xs={2} sm={2} md={2}>
                                                                            <p className={classes.promotioncont}>{jsondata.data.acc_number}</p>
                                                                        </GridItem>
                                                                        <GridItem xs={2} sm={2} md={2}>
                                                                            <p className={classes.promotioncont}>{jsondata.data.ifsc_code}</p>
                                                                        </GridItem>
                                                                        <GridItem xs={2} sm={2} md={2}>
                                                                            <p className={classes.promotioncont}>{jsondata.data.bank_name}</p>
                                                                        </GridItem>
                                                                        <GridItem xs={2} sm={2} md={2}>
                                                                            <p className={classes.promotioncont}>{jsondata.data.branch_name}</p>
                                                                        </GridItem>
                                                                        <GridItem xs={1} sm={1} md={1}>
                                                                            <p className={classes.promotioncont}>{jsondata.data.branch_address}</p>
                                                                        </GridItem>
                                                                        <GridItem xs={1} sm={1} md={1}>
                                                                            <Button
                                                                                href="#"
                                                                                color="transparent"
                                                                                onClick={() => deleteaccountf(jsondata.data.id)}
                                                                                className={classes.messagedeletebtn}
                                                                            >
                                                                                <i className={"fa fa-trash"}></i>
                                                                            </Button>
                                                                        </GridItem>
                                                                    </GridContainer>
                                                                </ListItem>
                                                                )}else{
                                                                    return(
                                                                        <ListItem className={classes.listItem} key={"statement_nodata"}>
                                                                            <GridContainer>
                                                                                <GridItem xs={12} sm={12} md={12}>
                                                                                    <p className={classes.promotioncont}>No record Found</p>
                                                                                </GridItem>
                                                                            </GridContainer>
                                                                        </ListItem>
                                                                    )
                                                                }})()}
                                                            </List>
                                                            <GridItem xs={12} sm={12} md={12}>
                                                                <Button
                                                                    href=""
                                                                    color="transparent"
                                                                    className={classes.depositbtn}
                                                                    onClick={LoadMoreRecord}
                                                                >
                                                                Load more...
                                                                </Button>
                                                            </GridItem>
                                                        </div>
                                                    ))}
                                            </div>);
                                            }else{
                                                return (
                                                    <ListItem className={classes.listItem} key={"statement_nodata"}>
                                                        <GridContainer>
                                                            <GridItem xs={12} sm={12} md={12}>
                                                                <p className={classes.promotioncont}>No record Found</p>
                                                            </GridItem>
                                                        </GridContainer>
                                                    </ListItem>
                                                    );
                                            }
                                        })()}




                                            </div>
                                        </div> 
                                        )
        
                                    }else if (jsondata.tagName === "tab3"){
                                        return (<div className={classes.accountsbox}>
                                           <KycModule {...rest} />
                                        </div> 
                                        )
        
                                    }

                                })()}
                                    </div>
        
                            )
                            }
        
                        ))}
                        />
                    </GridItem>
                    </GridContainer>
                </div>
                )
            }})()}
      </div>
    );
  }
  
//import React, {useEffect} from "react";
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js"; 
import SectionLoginbox from "../sections/SectionLoginBox";
import jwtService from '../../../services/jwtService' 
import styles from "../assets/jss/components/tabsDashboardGames.js";
import Button from "../elements/CustomButtons/Button";
import Lodder from "../assets/img/lodder.gif"; 
import gamesDb from "../data/games"; 
import roulette from "../assets/img/roulette.png"; 
import * as Gurl from "../../../global/Global.js"
import Zoom from '@material-ui/core/Zoom';  
import axios from 'axios';  
// @database
import { useIndexedDB } from 'react-indexed-db';

const token = "kdkddjdj2929j38jv88fpwad83pakwdi3";
let tabData = ["{\"category_id\":0,\"category_name\":\"All Games\"}"];
var searchText = "";
// var isExistApiCalls = false;
var searchCat = false;
export default function Games(props) {
    var gpsLocation = {latitude: 0, longitude: 0};
    const { add, getByIndex, update } = useIndexedDB('Games');
    styles.singlegame.borderColor = props.themeColors.bgColors.primary_bgcolor;
    styles.singlegame["&::before"].background = props.themeColors.bgColors.gradient_border;
    styles.playgamebtn.background = props.themeColors.bgColors.main_color; 
    styles.playgamebtn.color = props.themeColors.bgColors.text_primary_dark_color; 
    styles.mybtn2.background = props.themeColors.bgColors.main_color; 
    styles.mybtn2.color = props.themeColors.bgColors.text_primary_dark_color; 
   // styles.mybtn2["& span"].color = props.themeColors.bgColors.text_primary_dark_color; 
    styles.mybtn2["&:hover"].background = props.themeColors.bgColors.main_color;
    styles.mybtn2["&:hover"].color = props.themeColors.bgColors.text_primary_dark_color;
    styles.gametabpage.color = props.themeColors.bgColors.text_primary_color; 
    styles.gametabpage["&.active"].borderBottomColor = props.themeColors.bgColors.main_color; 
    styles.gametabpage["&.active"].color = props.themeColors.bgColors.text_primary_color; 
    styles.gamelabel.color = props.themeColors.bgColors.text_secondary_color;
    styles.gamesearching.background = props.themeColors.bgColors.secondary_dark_bgcolor;
    styles.gamesearching.color = props.themeColors.bgColors.text_primary_color;
    // const [clickEvent, setTabClickEvent] = React.useState("NOVALUE");
    const [gameData, setGamesData] = React.useState("NOVALUE");
    const [gameDataOnHold, setGameDataOnHold] = React.useState("NOVALUE");

    const useStyles = makeStyles(styles);
    const classes = useStyles();
  //alert(Gurl.GETGAMES);
    function loadGamesData(){
        const request = axios.post(Gurl.GETGAMES, {
            "token" : token,
            "gameids" : props.gameIds,
        });
        request.catch((error) => { 
            setGamesData("NOVALUE");
        });
        request.then((response) => { 
            if(response.data.data != null){ 
                if(Array.isArray(response.data.data)){ 
                    try{
                        getByIndex('id', props.gameIds).then(personFromDB => {
                            if(personFromDB !== null && personFromDB !== undefined && JSON.stringify({id: props.gameIds, ...response.data.data}) !== JSON.stringify(personFromDB)){
                                update({id: props.gameIds, data: response.data.data}).then(
                                    event => {
                                        //alert('ID Generated: ', JSON.stringify(event.target));
                                    },
                                    error => {
                                        //alert(error);
                                    }
                                );
                            }else{
                                if(JSON.stringify({id: props.gameIds, ...response.data.data}) !== JSON.stringify(personFromDB)){
                                    add({id: props.gameIds, data: response.data.data}).then(
                                        event => {
                                        //alert('ID Generated: ', JSON.stringify(event.target));
                                        },
                                        error => {
                                        //alert(error);
                                        }
                                    );
                                }
                            }
                        });
                    }catch(error){

                    }
                }
                setGameDataInFormat(response.data.data);
                setGameDataOnHold(response.data.data);
            }else{
                setGamesData("NO GAMES FOUND");
                //props.propTypes.openPopup("popup");
            }
        });  
    }

    function setGameDataInFormat(dataResponse){
        var myOutPut = "";
        if(Array.isArray(dataResponse)){ 
            myOutPut = (
                dataResponse.map((jsondata) => {
                    var newData = {category_id:jsondata.category_id, category_name: jsondata.category_name};
                    if(tabData.indexOf(JSON.stringify(newData)) === -1){
                        tabData.push(JSON.stringify(newData));
                    }
                    if(jsondata.category_id === searchCat || searchCat === false || searchCat === 0){
                        if(searchText.length === 0 || (searchText.length > 0 &&  ((jsondata.tags != null && jsondata.tags.toLowerCase().includes(searchText.toLowerCase())) || (jsondata.game_name != null && jsondata.game_name.toLowerCase().includes(searchText.toLowerCase()))))){
                            return (<GridItem className={classes.gridgame} xs={6} sm={6} md={3} xl={2} key={jsondata.id}>
                                <Zoom in={true} timeout={1000}> 
                                    <div className={"singlegame " + classes.singlegame}>
                                        <img src={((jsondata.game_icon != null && jsondata.game_icon.substr(0,4) === "http" && ((navigator != null && navigator.onLine != null && navigator.onLine === true) || (navigator === null && navigator.onLine === null))) ? jsondata.game_icon : "assets/img/" + ((jsondata.game_icon != null && jsondata.game_icon.length > 0  && ((navigator != null && navigator.onLine != null && navigator.onLine === true) || (navigator === null && navigator.onLine === null))) ? jsondata.game_icon : "game_img.jpg"))} alt={""} />
                                        <span className={"gamelabel " + classes.gamelabel}>{jsondata.game_name}</span>
                                        {gamesDb[sessionStorage.getItem('lang')].map((jsondataLang) => (
                                        <Button
                                            onClick={(event) => fetchAuthenticationToken(jsondata.game_id, event)}
                                            title={jsondata.game_name}
                                            className={"mybtn2 " + classes.playgamebtn}
                                        >
                                            
                                            {jsondataLang.btnName} 
                                        </Button>
                                        ))}
                                    </div> 
                                </Zoom>            
                            </GridItem>)
                        }
                    }
                    
                })
            );
        }else{
            var jsondata = dataResponse;
            myOutPut = ( 
                <GridItem className={classes.gridgame} xs={6} sm={6} md={3} xl={2}>
                    <Zoom in={true} timeout={1000}> 
                    <div className={" singlegame " + classes.singlegame}>
                        <img src={((jsondata.game_icon != null && jsondata.game_icon.substr(0,4) === "http" && ((navigator != null && navigator.onLine != null && navigator.onLine === true) || (navigator === null && navigator.onLine === null))) ? jsondata.game_icon : "assets/img/" + ((jsondata.game_icon != null && jsondata.game_icon.length > 0  && ((navigator != null && navigator.onLine != null && navigator.onLine === true) || (navigator === null && navigator.onLine === null))) ? jsondata.game_icon : "game_img.jpg"))} alt={""} />
                        <span className={"gamelabel " + classes.gamelabel}>{jsondata.game_name}</span>
                        {gamesDb[sessionStorage.getItem('lang')].map((jsondataLang) => (
                        <Button
                            onClick={(event) => fetchAuthenticationToken(jsondata.game_id, event)}
                            title={jsondata.game_name}
                            className={"mybtn2 " + classes.playgamebtn}
                        >
                             {jsondataLang.btnName} 
                        </Button>
                        ))}
                    </div> 
                    </Zoom>
                </GridItem>
                
            );
        }
        setGamesData(myOutPut);
        
    }
    function searchTextChange(event){
        if(event.target !== null && event.target.value !== null){
            searchText = event.target.value;
            try{
                if(gameDataOnHold !== "NOVALUE" && gameDataOnHold != null){
                    setGameDataInFormat(gameDataOnHold); 
                }else{
                    getByIndex('id', props.gameIds).then(allData => {
                        if(allData === null || allData === undefined){
                            loadGamesData();
                        }else{
                            setGameDataInFormat(allData.data); 
                        }
                    });
                }
            }catch(error){
                
            }
        }
    }
    function openCategoryWise(categoryId){
        searchCat = categoryId;
        try{
            if(gameDataOnHold !== "NOVALUE" && gameDataOnHold != null){
                setGameDataInFormat(gameDataOnHold); 
            }else{
                getByIndex('id', props.gameIds).then(allData => {
                    if(allData === null || allData === undefined){
                        loadGamesData();
                    }else{
                        setGameDataInFormat(allData.data); 
                    }
                });
            }
        }catch(error){
            
        }
    }
    
    const fetchAuthenticationToken = async function(myGameId, event) {
        if(parseInt(myGameId) > 0 && window.localStorage.getItem('jwt_access_token') != null && jwtService.isAuthenticationLogin()){
            if(event.target != null){
                event.target.setAttribute("style","pointer-events:none;cursor:default;");
            }
            await getMyLocation();
            var newSenderArray = {"gameid": myGameId,"token":window.localStorage.getItem('jwt_access_token'),'latitude': gpsLocation.latitude, 'longitude': gpsLocation.longitude}
            var gettingToken = await getGamesToken(newSenderArray);
            if(gettingToken != null){
                //alert("OutPUT:" + JSON.stringify(gettingToken));
            }
            if(gettingToken.status != null && gettingToken.status === true){
                if(gettingToken.MYGAMEAUTH != null && gettingToken.MYGAMEAUTH.length > 0){
                    var finalAuthUrl = JSON.parse(gettingToken.MYGAMEAUTH);
                    if(finalAuthUrl.entry != null && finalAuthUrl.entry.length > 0){
                        //window.open(finalAuthUrl.entry, "_blank","fullscreen,scrollbars") //to open new page
                        window.localStorage.setItem("game_access", finalAuthUrl.entry);
                        window.open("/newframe", "_self") //to open new page
                        if(event.target != null){
                            event.target.setAttribute("style","pointer-events:auto;cursor:pointer;");
                        }
                    }else{
                        if(finalAuthUrl.error != null){
                            if(isObject(finalAuthUrl.error)){
                                // var JSONInfo = finalAuthUrl.error;
                                if(isObject(finalAuthUrl.error.data) && finalAuthUrl.error.data.message !== undefined){
                                    props.sectionPopup.openPopup("popup", "Error", finalAuthUrl.error.data.message);
                                }else{
                                    props.sectionPopup.openPopup("popup", "Error", "Getting Authentication Issue on server. Please contact to Sponsor.");
                                }
                            }else{
                                props.sectionPopup.openPopup("popup", "Error", finalAuthUrl.error);
                            }
                            getMyLocation();
                        }
                        if(event.target != null){
                            event.target.setAttribute("style","pointer-events:auto;cursor:pointer;");
                        }
                    }
                }else{
                    if(event.target != null){
                        event.target.setAttribute("style","pointer-events:auto;cursor:pointer;");
                    }
                }
            }else{
                if(event.target != null){
                    event.target.setAttribute("style","pointer-events:auto;cursor:pointer;");
                }
            }
            
        }else{
            SectionLoginbox.openPopup("login");
            if(event.target != null){
                event.target.setAttribute("style","pointer-events:auto;cursor:pointer;");
            }
        }
    }
    let isObject = function(a) {
        return (!!a) && (a.constructor === Object);
    };
    async function getMyLocation() {
        const location = window.navigator && window.navigator.geolocation
        
        if (location) {
            let pro = new Promise((resolve,reject) =>  {
                location.getCurrentPosition((position) => {
                    if(window.localStorage.getItem('jwt_access_token') != null){
                        gpsLocation = {latitude: position.coords.latitude, longitude: position.coords.longitude};
                        resolve({latitude: position.coords.latitude, longitude: position.coords.longitude});
                    }
                }, (error) => {
                    //this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
                    resolve({status:false});
                });
            }); 
            const val = await pro;
            return val;
        }else{
            return;
        }
    
      }
    async function getGamesToken(newSenderArray) {
        let pro = new Promise((resolve,reject) => {
            try {
                const request = axios.post(Gurl.GETGAMEAUTH, newSenderArray,{headers:{"Accept": "application/json"}});
                request.catch((error) => {
                    var data = {
                        "status":false,
                        "data":error
                    }
                    resolve(data);
                })
                request.then((response) => {
                    if(response.data != null && response.data.status != null && response.data.status === true){
                        resolve(response.data);
                    }else{
                        var data = {
                            "status":false,
                            "data":response
                        }
                        resolve(data);
                    }
                })
            } catch (error) {
                console.log("MYIN:"+error);
                var data = {
                    "status":false,
                    "data":error
                }
                resolve(data);
            }
        }); 
        const val = await pro;
        return val;
    }
    
    if(gameData === "NOVALUE"){
        setGamesData(<img src={Lodder} className={classes.loadimg} alt=""/>); 
        if(navigator != null && navigator.onLine != null && navigator.onLine !== true){
            getByIndex('id', props.gameIds).then(allData => {
                if(allData === null || allData === undefined){
                    loadGamesData();
                }else{
                    setGameDataInFormat(allData.data); 
                }
            });
        }else{
            loadGamesData();
        }
    }
    


    return (
    
        <div id="nav-tabs"> 
        {(() => {
            if(tabData !== null) {
            return(
                <div className={"gamebtnwarp"}>
            {tabData.map((jsondata) => {
                jsondata = JSON.parse(jsondata);
                return (
                    <Button
                        color="transparent"
                        className={classes.tabbtn + " tabbtn" + ((jsondata.category_id === searchCat || (searchCat === false && jsondata.category_id === 0)) ? " active " : " ") + classes.gametabpage  }
                        onClick={() => openCategoryWise(jsondata.category_id)}
                    >
                        {/* <img src={roulette} alt=""/> */}
                       
                   {jsondata.category_name}
                    </Button> 
                )
                })} 
                <input type="text" onChange={(event) => {searchTextChange(event)}} className={"gamesearching " + classes.gamesearching} placeholder="Search Game..." />
                </div>
            )}
        })()}
            <GridContainer>                                 
                {gameData}
              </GridContainer>
        </div>    
   );
     
}
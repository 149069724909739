import * as Actions from '../actions';

const initialState = {
    success: false,
    data: null,
    error  : {
        username: null,
        password: null
    }
};

const profilesetup = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.PROFILESETUP_SUCCESS:
        {
            return {
                success: true,
                data: action.payload,
                error  : {
                    username: null,
                    password: null
                }
            };
        }
        case Actions.PROFILESETUP_ERROR:
        {
            return {
                success: false,
                error  : action.payload
            };
        }
        default:
        {
            return state
        }
    }
};

export default profilesetup;
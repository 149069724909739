


const depositDb = {
    en: [
        {
            'id' : '5725a680b3249770ea31de79',
            'title':"Make Withdraw",
            'tagName': 'tab1',
            'mobalbtn':"How do i make a Rupee deposit?",
            'mobalheading':"How can I deposit money?",
            'paybyoptions':[
                {
                    'id': "1,2,3,4,5,6,7",
                    'name': 'All Type Deposit',
                    'xs': 1
                },
                {
                    'id': "1",
                    'name': 'Bant to Bank',
                    'xs': 1
                },
                {
                    'id': "2",
                    'name': 'Cash Deposit',
                    'xs': 1
                },
                {
                    'id': "4",
                    'name': 'PayTM',
                    'xs': 1
                },
                {
                    'id': "5",
                    'name': 'Phone Pay',
                    'xs': 1
                },
                {
                    'id': "6",
                    'name': 'Google Pay',
                    'xs': 1
                },
                {
                    'id': "7",
                    'name': 'Other Pay',
                    'xs': 1
                }
            ],
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"Last 7 Days", 
                },
                { 
                    'id':"2", 
                    'periodoption':"Last 1 Month",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"Last 6 Months",
                },  
            ], 
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 1,
                    'field_name': 'Request Number',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'Type of Request',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 1,
                    'field_name': 'Send On',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'Send From',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'Amount',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': 'Reference Id',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 2,
                    'field_name': 'Request On',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_8',
                    'xs': 1,
                    'field_name': 'Status',
                    'visible': 'desktop',
                    'expandable': true
                },
            ],
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de80',
                    'mobalitem':"In the “Deposit” tab of your iSoft Gaming account, select Rupee.",
                },
                {
                    'id' : '5725a680b3249770ea31de81',
                    'mobalitem':"Select which bank you want to transfer from and its amount.",
                },
                {
                    'id' : '5725a680b3249770ea31de82',
                    'mobalitem':"You will be redirected to the bank page. Follow the instructions on the page and complete the transaction.",
                },
                {
                    'id' : '5725a680b3249770ea31de83',
                    'mobalitem':"After a successful transaction, your credit will be added automatically within 5 minutes.",
                },
                {
                    'id' : '5725a680b3249770ea31de84',
                    'mobalitem':"To avoid any delays in processing the deposit, kindly ensure all information is submitted correctly.",
                },
                {
                    'id' : '5725a680b3249770ea31de85',
                    'mobalitem':"Please feel free to contact Customer Service if you have any questions.",
                }, 
            ], 
            'guidebtn':"Rupee Guide",
            'bankname': "Select your bank",
            'bankoptions':[
                {
                    'id' : '5725a680b3249770ea31de86',
                    'bankoptions':"SBI",
                },
                {
                    'id' : '5725a680b3249770ea31de87',
                    'bankoptions':"HDFC",
                },
                {
                    'id' : '5725a680b3249770ea31de88',
                    'bankoptions':"ICICI",
                },
                {
                    'id' : '5725a680b3249770ea31de89',
                    'bankoptions':"PNB",
                },
            ],
            'amountheading': "Enter amount to deposit",
            'amountoption': "Enter Your Amount",
            'depositbtn':"Deposit",
            'tableheading':"Rupee Limit",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea31de90',
                    'tableth':"Minimum deposit per transaction", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea31de91',
                    'tableth':"Maximum deposit per transaction", 
                    'tabletd':"INR 20,000.00",
                },
            ],
            'tablenote':"NOTE: Deposits are subjected to a 1x wagering requirement.",
            'top_text'  : 'Your account is demo purpose only. Not authorize to do payment withdrawal/deposit', 
            'one_heading_text'  : 'Available Balance', 
            'one_box'  : 'Total Available Balance', 
            'two_box'  : 'Total Non Withdrawable Balance', 
            'three_box'  : 'Last Deposit Amount', 
            'four_box'  : 'Wager on Last Deposit', 
            'five_box'  : 'Today Withdrawable Balance', 
            'box_bottom_text_one'  : 'Withdrawal amount must be greater then', 
            'box_bottom_text_two'  : 'and maximum', 
            'two_heading_text'  : 'Withdrawal Request',
            'selectbankaccount'  : 'Select Bank Account',
            'amount'  : 'Amount',
            'confirm_amount'  : 'Confirm Amount',
            'comment'  : 'Comment', 
            'withdraw_btn'  : 'Withdraw',
            'period'  : 'Period',
            'start_date'  : 'Start Date',
            'end_date'  : 'End Date',
        },
      
        {
            'id' : '5725a680b3249770ea31de92',
            'title':"Withdraw Status",
            'tagName': 'tab2',
            'mobalbtn':"How do I make a Credit deposit?",
            'mobalheading':"How do I make a Credit Card deposit?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de93',
                    'mobalitem':"Log in to your iSoft Gaming account.",
                },
                {
                    'id' : '5725a680b3249770ea31de94',
                    'mobalitem':"Click the “Deposit” button at the dropdown arrow near your username.",
                },
                {
                    'id' : '5725a680b3249770ea31de95',
                    'mobalitem':"Inside the deposit tab, select “Credit Card” as your preferred deposit method.",
                },
                {
                    'id' : '5725a680b3249770ea31de96',
                    'mobalitem':"Enter your Credit card information including the card number, CVV number and expiration date.",
                },
                {
                    'id' : '5725a680b3249770ea31de97',
                    'mobalitem':"Type your desired amount of payment in the empty field of the deposit amount.",
                },
                {
                    'id' : '5725a680b3249770ea31de98',
                    'mobalitem':"Click on the “Deposit” button at the end of the page to confirm your deposit.",
                },
                {
                    'id' : '5725a680b3249770ea31de99',
                    'mobalitem':"You will receive the deposit confirmation afterward.",
                },
            ], 
            'card_cardnumber':"#### #### #### ####",
            'card_date_head':"Expires",
            'card_date':"MM/YY",
            'card_cvv_head':"CVV",
            'card_cvv':"####",
            'p_tag_first':"Exclusive use for Credit Card only. Do not insert Credit Card details.",
            'p_tag_a':"Download",
            'p_tag_second':"Credit App to purchase an Credit card.",
            'card_number':"Card Number",
            'expiry_date':"Expiry Date",
            'cvv':"CVV",
            'deposit_amount':"Deposit Amount",
            'deposit_btn':"Deposit",
            'tableheading':"Credit Card Limit",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de01',
                    'tableth':"Minimum deposit per transaction", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de02',
                    'tableth':"Maximum deposit per transaction", 
                    'tabletd':"INR 99,000.00",
                },
            ],
            'tablenote':"NOTE: Deposits are subjected to a 1x wagering requirement.",
            'period':'Period',
            'startdate':'Start Date',
            'enddate':'End Date',
        },
        
    ],
    tm: [
        {
            'id' : '5725a680b3249770ea31de79',
            'title':"திரும்பப் பெறுங்கள்",
            'tagName': 'tab1',
            'mobalbtn':"ரூபாய் வைப்பு செய்வது எப்படி?",
            'mobalheading':"நான் எவ்வாறு பணத்தை டெபாசிட் செய்யலாம்?",
            'paybyoptions':[
                {
                    'id': "1,2,3,4,5,6,7",
                    'name': 'அனைத்து வகை வைப்பு',
                    'xs': 1
                },
                {
                    'id': "1",
                    'name': 'வங்கி முதல் வங்கி',
                    'xs': 1
                },
                {
                    'id': "2",
                    'name': 'பண வைப்பு',
                    'xs': 1
                },
                {
                    'id': "4",
                    'name': 'PayTM',
                    'xs': 1
                },
                {
                    'id': "5",
                    'name': 'தொலைபேசி கட்டணம்',
                    'xs': 1
                },
                {
                    'id': "6",
                    'name': 'Google Pay',
                    'xs': 1
                },
                {
                    'id': "7",
                    'name': 'பிற ஊதியம்',
                    'xs': 1
                }
            ],
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"கடைசி 7 நாட்கள்", 
                },
                { 
                    'id':"2", 
                    'periodoption':"கடந்த 1 மாதம்",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"கடந்த 6 மாதங்கள்",
                },  
            ], 
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 1,
                    'field_name': 'கோரிக்கை எண்',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'கோரிக்கை வகை',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 1,
                    'field_name': 'அனுப்பு',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'அனுப்பவும்',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'தொகை',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': 'குறிப்பு ஐடி',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 2,
                    'field_name': 'கோரிக்கை',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_8',
                    'xs': 1,
                    'field_name': 'நிலை',
                    'visible': 'desktop',
                    'expandable': true
                },
            ],
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de80',
                    'mobalitem':"உங்கள் ஐசாஃப்ட் கேமிங் கணக்கின் “டெபாசிட்” தாவலில், ரூபாயைத் தேர்ந்தெடுக்கவும்.",
                },
                {
                    'id' : '5725a680b3249770ea31de81',
                    'mobalitem':"நீங்கள் எந்த வங்கியில் இருந்து மாற்ற விரும்புகிறீர்கள் மற்றும் அதன் தொகையைத் தேர்ந்தெடுக்கவும்.",
                },
                {
                    'id' : '5725a680b3249770ea31de82',
                    'mobalitem':"நீங்கள் வங்கி பக்கத்திற்கு திருப்பி விடப்படுவீர்கள். பக்கத்தில் உள்ள வழிமுறைகளைப் பின்பற்றி பரிவர்த்தனையை முடிக்கவும்.",
                },
                {
                    'id' : '5725a680b3249770ea31de83',
                    'mobalitem':"வெற்றிகரமான பரிவர்த்தனைக்குப் பிறகு, உங்கள் கடன் 5 நிமிடங்களுக்குள் தானாக சேர்க்கப்படும்.",
                },
                {
                    'id' : '5725a680b3249770ea31de84',
                    'mobalitem':"வைப்புத்தொகையை செயலாக்குவதில் எந்த தாமதத்தையும் தவிர்க்க, அனைத்து தகவல்களும் சரியாக சமர்ப்பிக்கப்படுவதை தயவுசெய்து உறுதிப்படுத்தவும்.",
                },
                {
                    'id' : '5725a680b3249770ea31de85',
                    'mobalitem':"உங்களுக்கு ஏதேனும் கேள்விகள் இருந்தால் தயவுசெய்து வாடிக்கையாளர் சேவையைத் தொடர்பு கொள்ளவும்.",
                }, 
            ], 
            'guidebtn':"ரூபாய் கையேடு",
            'bankname': "உங்கள் வங்கியைத் தேர்ந்தெடுக்கவும்",
            'bankoptions':[
                {
                    'id' : '5725a680b3249770ea31de86',
                    'bankoptions':"எஸ்பிஐ",
                },
                {
                    'id' : '5725a680b3249770ea31de87',
                    'bankoptions':"HDFC",
                },
                {
                    'id' : '5725a680b3249770ea31de88',
                    'bankoptions':"ஐ.சி.ஐ.சி.ஐ.",
                },
                {
                    'id' : '5725a680b3249770ea31de89',
                    'bankoptions':"பி.என்.பி.",
                },
            ],  
            'amountheading': "வைப்புக்கான தொகையை உள்ளிடுக",
            'amountoption': "உங்கள் தொகையை உள்ளிடுக",
            'depositbtn': "வைப்பு",
            'tableheading': "ரூபாய் வரம்பு",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea31de90',
                    'tableth':"ஒரு பரிவர்த்தனைக்கு குறைந்தபட்ச வைப்பு", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea31de91',
                    'tableth':"ஒரு பரிவர்த்தனைக்கு அதிகபட்ச வைப்பு", 
                    'tabletd':"INR 20,000.00",
                },
            ],  
            'tablenote': "குறிப்பு: வைப்புக்கள் 1x வேகரிங் தேவைக்கு உட்படுத்தப்படுகின்றன.",
            'top_text': 'உங்கள் கணக்கு டெமோ நோக்கம் மட்டுமே. கட்டணம் திரும்பப் பெறுதல் / வைப்புச் செய்ய அங்கீகாரம் இல்லை ',
            'one_heading_text': 'கிடைக்கும் இருப்பு',
            'one_box': 'மொத்தம் கிடைக்கும் இருப்பு',
            'two_box': 'திரும்பப் பெறமுடியாத மொத்த இருப்பு',
            'three_box': 'கடைசி வைப்புத் தொகை',
            'four_box': 'கடைசி வைப்புத்தொகையில் பந்தயம்',
            'five_box': 'இன்று திரும்பப் பெறக்கூடிய இருப்பு',
            'box_bottom_text_one': 'திரும்பப் பெறும் தொகை அதிகமாக இருக்க வேண்டும்',
            'box_bottom_text_two': 'மற்றும் அதிகபட்சம்',
            'two_heading_text': 'திரும்பப் பெறுதல் கோரிக்கை',
            'selectbankaccount': 'வங்கி கணக்கைத் தேர்ந்தெடு',
            'amount': 'தொகை',
            'confirm_amount': 'தொகையை உறுதிப்படுத்தவும்',
            'comment': 'கருத்து',
            'withdraw_btn': 'திரும்பப் பெறு',
            'period': 'காலம்',
            'start_date': 'தொடக்க தேதி',
            'end_date': 'இறுதி தேதி',
        },
      
        {
            'id' : '5725a680b3249770ea31de92',
            'title':"நிலையைத் திரும்பப் பெறுங்கள்",
            'tagName': 'tab2',
            'mobalbtn':"கிரெடிட் டெபாசிட் செய்வது எப்படி?",
            'mobalheading':"கிரெடிட் கார்டு டெபாசிட் செய்வது எப்படி?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de93',
                    'mobalitem':"உங்கள் ஐசாஃப்ட் கேமிங் கணக்கில் உள்நுழைக.",
                },
                {
                    'id' : '5725a680b3249770ea31de94',
                    'mobalitem':"உங்கள் பயனர்பெயருக்கு அருகிலுள்ள கீழ்தோன்றும் அம்புக்குறியில் உள்ள “வைப்பு” பொத்தானைக் கிளிக் செய்க.",
                },
                {
                    'id' : '5725a680b3249770ea31de95',
                    'mobalitem':"வைப்பு தாவலின் உள்ளே, உங்களுக்கு விருப்பமான வைப்பு முறையாக “கிரெடிட் கார்டு” என்பதைத் தேர்ந்தெடுக்கவும்.",
                },
                {
                    'id' : '5725a680b3249770ea31de96',
                    'mobalitem':"அட்டை எண், சி.வி.வி எண் மற்றும் காலாவதி தேதி உள்ளிட்ட உங்கள் கிரெடிட் கார்டு தகவலை உள்ளிடவும்.",
                },
                {
                    'id' : '5725a680b3249770ea31de97',
                    'mobalitem':"நீங்கள் விரும்பிய தொகையை டெபாசிட் தொகையின் வெற்று புலத்தில் தட்டச்சு செய்க.",
                },
                {
                    'id' : '5725a680b3249770ea31de98',
                    'mobalitem':"உங்கள் வைப்புத்தொகையை உறுதிப்படுத்த பக்கத்தின் இறுதியில் உள்ள “வைப்பு” பொத்தானைக் கிளிக் செய்க.",
                },
                {
                    'id' : '5725a680b3249770ea31de99',
                    'mobalitem':"நீங்கள் டெபாசிட் உறுதிப்படுத்தலைப் பெறுவீர்கள்.",
                },
            ], 
            'card_cardnumber':"#### #### #### ####",  
            'card_date_head': "காலாவதியாகிறது",
            'card_date': "MM / YY",
            'card_cvv_head': "CVV",
            'card_cvv': "####",
            'p_tag_first': "கிரெடிட் கார்டுக்கு மட்டுமே பிரத்தியேக பயன்பாடு. கிரெடிட் கார்டு விவரங்களைச் செருக வேண்டாம்.",
            'p_tag_a': "பதிவிறக்கு",
            'p_tag_second': "கிரெடிட் கார்டை வாங்க கடன் பயன்பாடு.",  
            'card_number': "அட்டை எண்",
            'expiry_date': "காலாவதி தேதி",
            'cvv': "CVV",
            'deposit_amount': "வைப்புத் தொகை",
            'deposit_btn': "வைப்பு",
            'tableheading': "கிரெடிட் கார்டு வரம்பு",

            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de01',
                    'tableth':"ஒரு பரிவர்த்தனைக்கு குறைந்தபட்ச வைப்பு", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de02',
                    'tableth':"ஒரு பரிவர்த்தனைக்கு அதிகபட்ச வைப்பு", 
                    'tabletd':"INR 99,000.00",
                },
            ],
            'tablenote':"குறிப்பு: வைப்புத்தொகை 1x வேகரிங் தேவைக்கு உட்பட்டது.",
            'period':'காலம்',
            'startdate':'தொடக்க தேதி',
            'enddate':'கடைசி தேதி',
        },
        
    ],
    kn: [
        {
            'id' : '5725a680b3249770ea31de79',
            'title':"ಹಿಂತೆಗೆದುಕೊಳ್ಳಿ",
            'tagName': 'tab1',
            'mobalbtn':"ರೂಪಾಯಿ ಠೇವಣಿ ಮಾಡುವುದು ಹೇಗೆ?",
            'mobalheading':"ನಾನು ಹಣವನ್ನು ಹೇಗೆ ಠೇವಣಿ ಮಾಡಬಹುದು?",
            'paybyoptions':[
                {
                    'id': "1,2,3,4,5,6,7",
                    'name': 'ಎಲ್ಲಾ ಪ್ರಕಾರದ ಠೇವಣಿ',
                    'xs': 1
                },
                {
                    'id': "1",
                    'name': 'ಬ್ಯಾಂಕ್ ಟು ಬ್ಯಾಂಕ್',
                    'xs': 1
                },
                {
                    'id': "2",
                    'name': 'ನಗದು ಠೇವಣಿ',
                    'xs': 1
                },
                {
                    'id': "4",
                    'name': 'ಪೇಟಿಎಂ',
                    'xs': 1
                },
                {
                    'id': "5",
                    'name': 'ಫೋನ್ ಪಾವತಿ',
                    'xs': 1
                },
                {
                    'id': "6",
                    'name': 'Google Pay',
                    'xs': 1
                },
                {
                    'id': "7",
                    'name': 'ಇತರೆ ವೇತನ',
                    'xs': 1
                }
            ],
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"ಕೊನೆಯ 7 ದಿನಗಳು", 
                },
                { 
                    'id':"2", 
                    'periodoption':"ಕೊನೆಯ 1 ತಿಂಗಳು",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"ಕಳೆದ 6 ತಿಂಗಳುಗಳು",
                },  
            ], 
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 1,
                    'field_name': 'ವಿನಂತಿ ಸಂಖ್ಯೆ',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'ವಿನಂತಿಯ ಪ್ರಕಾರ',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 1,
                    'field_name': 'ಕಳುಹಿಸಿ ',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'ನಿಂದ ಕಳುಹಿಸಿ',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'ಮೊತ್ತ',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': 'ಉಲ್ಲೇಖ ಐಡಿ',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 2,
                    'field_name': 'ವಿನಂತಿಯನ್ನು ಆನ್ ಮಾಡಿ',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_8',
                    'xs': 1,
                    'field_name': 'ಸ್ಥಿತಿ',
                    'visible': 'desktop',
                    'expandable': true
                },
            ],
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de80',
                    'mobalitem':"ನಿಮ್ಮ ಐಸಾಫ್ಟ್ ಗೇಮಿಂಗ್ ಖಾತೆಯ “ಠೇವಣಿ” ಟ್ಯಾಬ್‌ನಲ್ಲಿ, ರೂಪಾಯಿ ಆಯ್ಕೆಮಾಡಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de81',
                    'mobalitem':"ನೀವು ಯಾವ ಬ್ಯಾಂಕ್‌ನಿಂದ ವರ್ಗಾಯಿಸಲು ಬಯಸುತ್ತೀರಿ ಮತ್ತು ಅದರ ಮೊತ್ತವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de82',
                    'mobalitem':"ನಿಮ್ಮನ್ನು ಬ್ಯಾಂಕ್ ಪುಟಕ್ಕೆ ಮರುನಿರ್ದೇಶಿಸಲಾಗುತ್ತದೆ. ಪುಟದಲ್ಲಿನ ಸೂಚನೆಗಳನ್ನು ಅನುಸರಿಸಿ ಮತ್ತು ವ್ಯವಹಾರವನ್ನು ಪೂರ್ಣಗೊಳಿಸಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de83',
                    'mobalitem':"ಯಶಸ್ವಿ ವಹಿವಾಟಿನ ನಂತರ, ನಿಮ್ಮ ಕ್ರೆಡಿಟ್ ಅನ್ನು 5 ನಿಮಿಷಗಳಲ್ಲಿ ಸ್ವಯಂಚಾಲಿತವಾಗಿ ಸೇರಿಸಲಾಗುತ್ತದೆ.",
                },
                {
                    'id' : '5725a680b3249770ea31de84',
                    'mobalitem':"ಠೇವಣಿ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ ಯಾವುದೇ ವಿಳಂಬವನ್ನು ತಪ್ಪಿಸಲು, ಎಲ್ಲಾ ಮಾಹಿತಿಯನ್ನು ಸರಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆಯೆ ಎಂದು ದಯೆಯಿಂದ ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de85',
                    'mobalitem':"ನೀವು ಯಾವುದೇ ಪ್ರಶ್ನೆಗಳನ್ನು ಹೊಂದಿದ್ದರೆ ದಯವಿಟ್ಟು ಗ್ರಾಹಕ ಸೇವೆಯನ್ನು ಸಂಪರ್ಕಿಸಲು ಮುಕ್ತವಾಗಿರಿ.",
                }, 
            ], 
            'guidebtn':"ರೂಪಾಯಿ ಮಾರ್ಗದರ್ಶಿ",
            'bankname': "ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಆಯ್ಕೆಮಾಡಿ",
            'bankoptions':[
                {
                    'id' : '5725a680b3249770ea31de86',
                    'bankoptions':"ಎಸ್‌ಬಿಐ",
                },
                {
                    'id' : '5725a680b3249770ea31de87',
                    'bankoptions':"ಎಚ್‌ಡಿಎಫ್‌ಸಿ",
                },
                {
                    'id' : '5725a680b3249770ea31de88',
                    'bankoptions':"ಐಸಿಐಸಿಐ",
                },
                {
                    'id' : '5725a680b3249770ea31de89',
                    'bankoptions':"ಪಿಎನ್‌ಬಿ",
                },
            ], 
            'amountheading': "ಠೇವಣಿಗೆ ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ",
            'amountoption': "ನಿಮ್ಮ ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ",
            'depositbtn': "ಠೇವಣಿ",
            'tableheading': "ರೂಪಾಯಿ ಮಿತಿ",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea31de90',
                    'tableth':"ಪ್ರತಿ ವಹಿವಾಟಿಗೆ ಕನಿಷ್ಠ ಠೇವಣಿ", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea31de91',
                    'tableth':"ಪ್ರತಿ ವಹಿವಾಟಿಗೆ ಗರಿಷ್ಠ ಠೇವಣಿ", 
                    'tabletd':"INR 20,000.00",
                },
            ], 
            'tablenote': "ಸೂಚನೆ: ಠೇವಣಿಗಳನ್ನು 1x ವೇಜಿಂಗ್ ಅವಶ್ಯಕತೆಗೆ ಒಳಪಡಿಸಲಾಗುತ್ತದೆ.",
            'top_text': 'ನಿಮ್ಮ ಖಾತೆಯು ಡೆಮೊ ಉದ್ದೇಶ ಮಾತ್ರ. ಪಾವತಿ ಹಿಂಪಡೆಯುವಿಕೆ / ಠೇವಣಿ ಮಾಡಲು ಅಧಿಕಾರ ಹೊಂದಿಲ್ಲ ',
            'one_heading_text': 'ಲಭ್ಯವಿರುವ ಬ್ಯಾಲೆನ್ಸ್',
            'one_box': 'ಒಟ್ಟು ಲಭ್ಯವಿರುವ ಬ್ಯಾಲೆನ್ಸ್',
            'two_box': 'ಒಟ್ಟು ಹಿಂತೆಗೆದುಕೊಳ್ಳಲಾಗದ ಸಮತೋಲನ',
            'three_box': 'ಕೊನೆಯ ಠೇವಣಿ ಮೊತ್ತ',
            'four_box': 'ಕೊನೆಯ ಠೇವಣಿಯಲ್ಲಿ ಪಂತ',
            'five_box': 'ಇಂದು ಹಿಂತೆಗೆದುಕೊಳ್ಳುವ ಸಮತೋಲನ',
            'box_bottom_text_one': 'ಹಿಂತೆಗೆದುಕೊಳ್ಳುವ ಮೊತ್ತವು ಆಗ ಹೆಚ್ಚಿರಬೇಕು', 
            'box_bottom_text_two': 'ಮತ್ತು ಗರಿಷ್ಠ',
            'two_heading_text': 'ಹಿಂತೆಗೆದುಕೊಳ್ಳುವ ವಿನಂತಿ',
            'selectbankaccount': 'ಬ್ಯಾಂಕ್ ಖಾತೆಯನ್ನು ಆರಿಸಿ',
            'amount': 'ಮೊತ್ತ',
            'confirm_amount': 'ಮೊತ್ತವನ್ನು ದೃ irm ೀಕರಿಸಿ',
            'comment': 'ಕಾಮೆಂಟ್',
            'withdraw_btn': 'ಹಿಂತೆಗೆದುಕೊಳ್ಳಿ',
            'period': 'ಅವಧಿ',
            'start_date': 'ಪ್ರಾರಂಭ ದಿನಾಂಕ',
            'end_date': 'ಅಂತಿಮ ದಿನಾಂಕ',
        },
      
        {
            'id' : '5725a680b3249770ea31de92',
            'title':"ಸ್ಥಿತಿಯನ್ನು ಹಿಂತೆಗೆದುಕೊಳ್ಳಿ",
            'tagName': 'tab2',
            'mobalbtn':"ನಾನು ಕ್ರೆಡಿಟ್ ಠೇವಣಿ ಮಾಡುವುದು ಹೇಗೆ?",
            'mobalheading':"ನಾನು ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಠೇವಣಿ ಮಾಡುವುದು ಹೇಗೆ?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de93',
                    'mobalitem':"ನಿಮ್ಮ ಐಸಾಫ್ಟ್ ಗೇಮಿಂಗ್ ಖಾತೆಗೆ ಲಾಗ್ ಇನ್ ಮಾಡಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de94',
                    'mobalitem':"ನಿಮ್ಮ ಬಳಕೆದಾರಹೆಸರು ಬಳಿಯ ಡ್ರಾಪ್‌ಡೌನ್ ಬಾಣದಲ್ಲಿರುವ “ಠೇವಣಿ” ಬಟನ್ ಕ್ಲಿಕ್ ಮಾಡಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de95',
                    'mobalitem':"ಠೇವಣಿ ಟ್ಯಾಬ್ ಒಳಗೆ, ನಿಮ್ಮ ಆದ್ಯತೆಯ ಠೇವಣಿ ವಿಧಾನವಾಗಿ “ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್” ಆಯ್ಕೆಮಾಡಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de96',
                    'mobalitem':"ಕಾರ್ಡ್ ಸಂಖ್ಯೆ, ಸಿವಿವಿ ಸಂಖ್ಯೆ ಮತ್ತು ಮುಕ್ತಾಯ ದಿನಾಂಕ ಸೇರಿದಂತೆ ನಿಮ್ಮ ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಮಾಹಿತಿಯನ್ನು ನಮೂದಿಸಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de97',
                    'mobalitem':"ಠೇವಣಿ ಮೊತ್ತದ ಖಾಲಿ ಕ್ಷೇತ್ರದಲ್ಲಿ ನಿಮ್ಮ ಅಪೇಕ್ಷಿತ ಪಾವತಿಯನ್ನು ಟೈಪ್ ಮಾಡಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de98',
                    'mobalitem':"ನಿಮ್ಮ ಠೇವಣಿಯನ್ನು ದೃ to ೀಕರಿಸಲು ಪುಟದ ಕೊನೆಯಲ್ಲಿರುವ “ಠೇವಣಿ” ಬಟನ್ ಕ್ಲಿಕ್ ಮಾಡಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de99',
                    'mobalitem':"ನೀವು ನಂತರ ಠೇವಣಿ ದೃ mation ೀಕರಣವನ್ನು ಸ್ವೀಕರಿಸುತ್ತೀರಿ.",
                },
            ], 
            'card_cardnumber':"#### #### #### ####", 
            'card_date_head': "ಅವಧಿ ಮೀರುತ್ತದೆ",
            'card_date': "MM / YY",
            'card_cvv_head': "CVV",
            'card_cvv': "####",
            'p_tag_first': "ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್‌ಗಾಗಿ ಮಾತ್ರ ವಿಶೇಷ ಬಳಕೆ. ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ವಿವರಗಳನ್ನು ಸೇರಿಸಬೇಡಿ.",
            'p_tag_a': "ಡೌನ್‌ಲೋಡ್",
            'p_tag_second': "ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಖರೀದಿಸಲು ಕ್ರೆಡಿಟ್ ಅಪ್ಲಿಕೇಶನ್.",  
            'card_number': "ಕಾರ್ಡ್ ಸಂಖ್ಯೆ",
            'expiry_date': "ಮುಕ್ತಾಯ ದಿನಾಂಕ",
            'cvv': "ಸಿವಿವಿ",
            'deposit_amount': "ಠೇವಣಿ ಮೊತ್ತ",
            'deposit_btn': "ಠೇವಣಿ",
            'tableheading': "ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಮಿತಿ",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de01',
                    'tableth':"ಪ್ರತಿ ವಹಿವಾಟಿಗೆ ಕನಿಷ್ಠ ಠೇವಣಿ", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de02',
                    'tableth':"ಪ್ರತಿ ವಹಿವಾಟಿಗೆ ಗರಿಷ್ಠ ಠೇವಣಿ", 
                    'tabletd':"INR 99,000.00",
                },
            ],
            'tablenote':"ಸೂಚನೆ: ಠೇವಣಿಗಳನ್ನು 1x ವೇಜರಿಂಗ್ ಅವಶ್ಯಕತೆಗೆ ಒಳಪಡಿಸಲಾಗುತ್ತದೆ.",
            'period':'ಅವಧಿ',
            'startdate':'ಪ್ರಾರಂಭ ದಿನಾಂಕ',
            'enddate':'ಅಂತಿಮ ದಿನಾಂಕ',
        },
        
    ],
    ne: [
        {
            'id' : '5725a680b3249770ea31de79',
            'title':"फिर्ता लिनुहोस्",
            'tagName': 'tab1',
            'mobalbtn':"म कसरी रूपैयाँ जम्मा गर्न सक्छु?",
            'mobalheading':"म कसरी पैसा जम्मा गर्न सक्छु?",
            'paybyoptions':[
                {
                    'id': "1,2,3,4,5,6,7",
                    'name': 'सबै प्रकार निक्षेप',
                    'xs': 1
                },
                {
                    'id': "1",
                    'name': 'बैंकमा बैंक',
                    'xs': 1
                },
                {
                    'id': "2",
                    'name': 'नगद जम्मा',
                    'xs': 1
                },
                {
                    'id': "4",
                    'name': 'PayTM',
                    'xs': 1
                },
                {
                    'id': "5",
                    'name': 'फोन भुक्तान',
                    'xs': 1
                },
                {
                    'id': "6",
                    'name': 'गुगल भुक्तान',
                    'xs': 1
                },
                {
                    'id': "7",
                    'name': 'अन्य भुक्तान',
                    'xs': 1
                }
            ],
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"पछिल्लो 7 दिन", 
                },
                { 
                    'id':"2", 
                    'periodoption':"पछिल्लो १ महिना",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"पछिल्लो 6 महिना",
                },  
            ], 
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 1,
                    'field_name': 'अनुरोध संख्या',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'अनुरोधको प्रकार',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 1,
                    'field_name': 'पठाउनुहोस्',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'बाट पठाउनुहोस्',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'रकम',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': 'सन्दर्भ आईडी',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 2,
                    'field_name': 'अनुरोध गर्नुहोस्',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_8',
                    'xs': 1,
                    'field_name': 'स्थिति',
                    'visible': 'desktop',
                    'expandable': true
                },
            ],
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de80',
                    'mobalitem':"तपाईंको isoft गेमि account खाताको 'निक्षेप; ट्याबमा, रूपी चयन गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de81',
                    'mobalitem':"तपाई कुन बैंक बाट स्थानान्तरण गर्न चाहानुहुन्छ र यसको रकम छनौट गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de82',
                    'mobalitem':"तपाइँलाई बैंक पृष्ठमा रिडिरेक्ट गरिनेछ। पृष्ठमा निर्देशनहरू पालना गर्नुहोस् र लेनदेन पूर्ण गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de83',
                    'mobalitem':"सफल लेनदेन पछि, तपाईंको क्रेडिट credit मिनेट भित्रमा स्वचालित रूपमा थपिने छ।",
                },
                {
                    'id' : '5725a680b3249770ea31de84',
                    'mobalitem':"जम्मा प्रक्रियामा कुनै ढिलाइबाट बच्न, कृपया सबै जानकारी ठीकसँग पेश गरिएको सुनिश्चित गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de85',
                    'mobalitem':"कृपया तपाईंसँग कुनै प्रश्नहरू छन् भने कृपया ग्राहक सेवामा सम्पर्क गर्न नहिचकिचाउनुहोस्।",
                }, 
            ], 
            'guidebtn':"रुपैया गाइड",
            'bankname': "तपाईंको बैंक चयन गर्नुहोस्",
            'bankoptions':[
                {
                    'id' : '5725a680b3249770ea31de86',
                    'bankoptions':"एसबीआई",
                },
                {
                    'id' : '5725a680b3249770ea31de87',
                    'bankoptions':"HDFC",
                },
                {
                    'id' : '5725a680b3249770ea31de88',
                    'bankoptions':"आईसीआईसीआई",
                },
                {
                    'id' : '5725a680b3249770ea31de89',
                    'bankoptions':"PNB",
                },
            ], 
            'amountheading': "जम्मा गर्न रकम प्रविष्ट गर्नुहोस्",
            'amountoption': "तपाइँको रकम प्रविष्ट गर्नुहोस्",
            'depositbtn': "जम्मा",
            'tableheading': "रुपैयाँ सीमा", 
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea31de90',
                    'tableth':"प्रति लेनदेन न्यूनतम निक्षेप", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea31de91',
                    'tableth':"प्रति लेनदेन अधिकतम जम्मा", 
                    'tabletd':"INR 20,000.00",
                },
            ], 
            'tablenote': "नोट: निक्षेपहरू १x wagering आवश्यकताको अधीनमा छन्।",
            'top_text': 'तपाईंको खाता डेमो उद्देश्य मात्र हो। भुक्तानी फिर्ता / जम्मा गर्न अधिकृत छैन ',
            'one_heading_text': 'उपलब्ध ब्यालेन्स',
            'one_box': 'कुल उपलब्ध ब्यालेन्स',
            'two_box': 'कुल गैर निकासी योग्य ब्यालेन्स',
            'three_box': 'अन्तिम जम्मा राशि',
            'four_box': 'अन्तिम जम्मामा दांव',
            'five_box': 'आज फिर्ता योग्य शेष',
            'box_bottom_text_one': 'फिर्ता रकम त्यति ठूलो हुनुपर्दछ', 
            'box_bottom_text_two': 'र अधिकतम',
            'two_heading_text': 'वापसी अनुरोध',
            'selectbankaccount': 'बैंक खाता चयन गर्नुहोस्',
            'amount': 'रकम',
            'confirm_amount': 'कन्फर्म रकम',
            'comment': 'टिप्पणी',
            'withdraw_btn': 'फिर्ता लिनुहोस्',
            'period': 'अवधि',
            'start_date': 'सुरूवात मिति',
            'end_date': 'अन्त्य मिति',
        },
      
        {
            'id' : '5725a680b3249770ea31de92',
            'title':"स्थिति फिर्ता लिनुहोस्",
            'tagName': 'tab2',
            'mobalbtn':"म कसरी क्रेडिट जम्मा गर्न सक्छु?",
            'mobalheading':"म कसरी क्रेडिट कार्ड जम्मा गर्न सक्छु?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de93',
                    'mobalitem':"तपाईको आईसोफ्ट गेमि account खातामा लग इन गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de94',
                    'mobalitem':"तपाईंको प्रयोगकर्ता नामको नजिक ड्रपडाउन एर्रोमा 'जम्मा' बटन क्लिक गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de95',
                    'mobalitem':"निक्षेप ट्याब भित्र, चयन गर्नुहोस् 'क्रेडिट कार्ड' तपाईंको मनपर्ने जम्मा विधिको रूपमा।",
                },
                {
                    'id' : '5725a680b3249770ea31de96',
                    'mobalitem':"कार्ड नम्बर, CVV नम्बर र समयावधि मिति सहित तपाईंको क्रेडिट कार्ड जानकारी प्रविष्ट गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de97',
                    'mobalitem':"निक्षेप रकमको खाली फिल्डमा तपाईंको इच्छित भुक्तानी रकम टाइप गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de98',
                    'mobalitem':"तपाईंको जम्मा राशि पुष्टि गर्न पृष्ठको अन्तमा 'जम्मा गर्नुहोस्' बटन क्लिक गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de99',
                    'mobalitem':"तपाईं पछि जम्मा पुष्टि प्राप्त गर्नुहुनेछ।",
                },
            ], 
            'card_cardnumber':"#### #### #### ####", 
            'card_date_head': "म्याद सकिन्छ",
            'card_date': "MM / YY",
            'card_cvv_head': "CVV",
            'card_cvv': "####",
            'p_tag_first': "क्रेडिट कार्डको लागि मात्र विशेष प्रयोग। क्रेडिट कार्ड विवरण घुसाउनुहोस्।",
            'p_tag_a': "डाउनलोड",
            'p_tag_second': "क्रेडिट कार्ड खरीद गर्न क्रेडिट अनुप्रयोग।", 
            'card_number': "कार्ड नम्बर",
            'expiry_date': "समाप्ति मिति",
            'cvv': "CVV",
            'deposit_amount': "जम्मा रकम",
            'deposit_btn': "जम्मा",
            'tableheading': "क्रेडिट कार्ड सीमा",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de01',
                    'tableth':"प्रति लेनदेन न्यूनतम निक्षेप", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de02',
                    'tableth':"प्रति लेनदेन अधिकतम जम्मा", 
                    'tabletd':"INR 99,000.00",
                },
            ],
            'tablenote':"नोट: निक्षेपहरू १x wagering आवश्यकताको अधीनमा छन्।",
            'period':'अवधि',
            'startdate':'सुरू मिति',
            'enddate':'अन्त्य मिति',
        },
        
    ],
    tl: [
        {
            'id' : '5725a680b3249770ea31de79',
            'title':"ఉపసంహరించుకోండి",
            'tagName': 'tab1',
            'mobalbtn':"నేను రూపాయి డిపాజిట్ ఎలా చేయాలి?",
            'mobalheading':"నేను డబ్బును ఎలా జమ చేయగలను?",
            'paybyoptions':[
                {
                    'id': "1,2,3,4,5,6,7",
                    'name': 'ఆల్ టైప్ డిపాజిట్',
                    'xs': 1
                },
                {
                    'id': "1",
                    'name': 'బ్యాంకుకు బాంట్',
                    'xs': 1
                },
                {
                    'id': "2",
                    'name': 'నగదు జమచేయు',
                    'xs': 1
                },
                {
                    'id': "4",
                    'name': 'పేటీఎం',
                    'xs': 1
                },
                {
                    'id': "5",
                    'name': 'ఫోన్ పే',
                    'xs': 1
                },
                {
                    'id': "6",
                    'name': 'గూగుల్ పే',
                    'xs': 1
                },
                {
                    'id': "7",
                    'name': 'ఇతర పే',
                    'xs': 1
                }
            ],
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"చివరి 7 రోజులు", 
                },
                { 
                    'id':"2", 
                    'periodoption':"చివరి 1 నెల",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"చివరి 6 నెలలు",
                },  
            ], 
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 1,
                    'field_name': 'అభ్యర్థన సంఖ్య',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'అభ్యర్థన రకం',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 1,
                    'field_name': 'పంపండి',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'నుండి పంపండి',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'మొత్తం',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': 'రిఫరెన్స్ ఐడి',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 2,
                    'field_name': 'అభ్యర్థించండి',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_8',
                    'xs': 1,
                    'field_name': 'స్థితి',
                    'visible': 'desktop',
                    'expandable': true
                },
            ],
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de80',
                    'mobalitem':"మీ ఐసోఫ్ట్ గేమింగ్ ఖాతా యొక్క “డిపాజిట్” టాబ్‌లో, రూపాయిని ఎంచుకోండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de81',
                    'mobalitem':"మీరు ఏ బ్యాంక్ నుండి బదిలీ చేయాలనుకుంటున్నారో మరియు దాని మొత్తాన్ని ఎంచుకోండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de82',
                    'mobalitem':"మీరు బ్యాంక్ పేజీకి మళ్ళించబడతారు. పేజీలోని సూచనలను అనుసరించండి మరియు లావాదేవీని పూర్తి చేయండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de83',
                    'mobalitem':"విజయవంతమైన లావాదేవీ తరువాత, మీ క్రెడిట్ 5 నిమిషాల్లో స్వయంచాలకంగా జోడించబడుతుంది.",
                },
                {
                    'id' : '5725a680b3249770ea31de84',
                    'mobalitem':"డిపాజిట్‌ను ప్రాసెస్ చేయడంలో జాప్యం జరగకుండా ఉండటానికి, అన్ని సమాచారం సరిగ్గా సమర్పించబడిందని నిర్ధారించుకోండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de85',
                    'mobalitem':"మీకు ఏవైనా ప్రశ్నలు ఉంటే కస్టమర్ సేవను సంప్రదించడానికి సంకోచించకండి.",
                }, 
            ], 
            'guidebtn':"రూపాయి గైడ్",
            'bankname': "మీ బ్యాంకును ఎంచుకోండి",
            'bankoptions':[
                {
                    'id' : '5725a680b3249770ea31de86',
                    'bankoptions':"ఎస్బిఐ",
                },
                {
                    'id' : '5725a680b3249770ea31de87',
                    'bankoptions':"HDFC",
                },
                {
                    'id' : '5725a680b3249770ea31de88',
                    'bankoptions':"ఐసిఐసిఐ",
                },
                {
                    'id' : '5725a680b3249770ea31de89',
                    'bankoptions':"పిఎన్‌బి",
                },
            ],
            'amountheading': "డిపాజిట్ చేయడానికి మొత్తాన్ని నమోదు చేయండి",
            'amountoption': "మీ మొత్తాన్ని నమోదు చేయండి",
            'depositbtn':"డిపాజిట్",
            'tableheading':"రూపాయి పరిమితి",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea31de90',
                    'tableth':"ప్రతి లావాదేవీకి కనీస డిపాజిట్", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea31de91',
                    'tableth':"ప్రతి లావాదేవీకి గరిష్ట డిపాజిట్", 
                    'tabletd':"INR 20,000.00",
                },
            ],
            'tablenote':"గమనిక: డిపాజిట్లు 1x పందెపు అవసరానికి లోబడి ఉంటాయి.",

            'top_text'  : 'మీ ఖాతా డెమో ప్రయోజనం మాత్రమే. చెల్లింపు ఉపసంహరణ / డిపాజిట్ చేయడానికి అధికారం లేదు', 
            'one_heading_text'  : 'అందుబాటులో ఉన్న బ్యాలెన్స్', 
            'one_box'  : 'మొత్తం అందుబాటులో ఉన్న బ్యాలెన్స్', 
            'two_box'  : 'మొత్తం ఉపసంహరించలేని బ్యాలెన్స్', 
            'three_box'  : 'చివరి డిపాజిట్ మొత్తం', 
            'four_box'  : 'చివరి డిపాజిట్పై పందెం', 
            'five_box'  : 'ఈ రోజు ఉపసంహరించుకోగల బ్యాలెన్స్', 
            'box_bottom_text_one'  : 'ఉపసంహరణ మొత్తం అప్పుడు ఎక్కువగా ఉండాలి', 
            'box_bottom_text_two'  : 'మరియు గరిష్టంగా', 
            'two_heading_text'  : 'ఉపసంహరణ అభ్యర్థన',
            'selectbankaccount'  : 'బ్యాంక్ ఖాతాను ఎంచుకోండి',
            'amount'  : 'మొత్తం',
            'confirm_amount'  : 'మొత్తాన్ని నిర్ధారించండి',
            'comment'  : 'వ్యాఖ్య', 
            'withdraw_btn'  : 'ఉపసంహరించుకోండి',
            'period'  : 'కాలం',
            'start_date'  : 'ప్రారంబపు తేది',
            'end_date'  : 'చివరి తేది',
        },
      
        {
            'id' : '5725a680b3249770ea31de92',
            'title':"స్థితిని ఉపసంహరించుకోండి",
            'tagName': 'tab2',
            'mobalbtn':"నేను క్రెడిట్ డిపాజిట్ ఎలా చేయగలను?",
            'mobalheading':"నేను క్రెడిట్ కార్డ్ డిపాజిట్ ఎలా చేయాలి?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de93',
                    'mobalitem':"మీ ఐసోఫ్ట్ గేమింగ్ ఖాతాకు లాగిన్ అవ్వండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de94',
                    'mobalitem':"మీ వినియోగదారు పేరుకు సమీపంలో ఉన్న డ్రాప్‌డౌన్ బాణం వద్ద “డిపాజిట్” బటన్‌ను క్లిక్ చేయండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de95',
                    'mobalitem':"డిపాజిట్ టాబ్ లోపల, మీకు ఇష్టమైన డిపాజిట్ పద్ధతిగా “క్రెడిట్ కార్డ్” ఎంచుకోండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de96',
                    'mobalitem':"కార్డ్ నంబర్, సివివి నంబర్ మరియు గడువు తేదీతో సహా మీ క్రెడిట్ కార్డ్ సమాచారాన్ని నమోదు చేయండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de97',
                    'mobalitem':"డిపాజిట్ మొత్తం ఖాళీ ఫీల్డ్‌లో మీకు కావలసిన మొత్తాన్ని చెల్లించండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de98',
                    'mobalitem':"మీ డిపాజిట్‌ను నిర్ధారించడానికి పేజీ చివర “డిపాజిట్” బటన్‌పై క్లిక్ చేయండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de99',
                    'mobalitem':"మీరు తరువాత డిపాజిట్ నిర్ధారణను అందుకుంటారు.",
                },
            ], 
            'card_cardnumber':"#### #### #### ####",
            'card_date_head':"ముగుస్తుంది",
            'card_date':"MM/YY",
            'card_cvv_head':"CVV",
            'card_cvv':"####",
            'p_tag_first':"క్రెడిట్ కార్డ్ కోసం మాత్రమే ప్రత్యేకమైన ఉపయోగం. క్రెడిట్ కార్డ్ వివరాలను చొప్పించవద్దు.",
            'p_tag_a':"డౌన్‌లోడ్",
            'p_tag_second':"క్రెడిట్ కార్డు కొనడానికి క్రెడిట్ అనువర్తనం.",
            'card_number':"కార్డు సంఖ్య",
            'expiry_date':"గడువు తీరు తేదీ",
            'cvv':"సివివి",
            'deposit_amount':"జమ చేయవలసిన రొక్కం",
            'deposit_btn':"డిపాజిట్",
            'tableheading':"క్రెడిట్ కార్డ్ పరిమితి",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de01',
                    'tableth':"ప్రతి లావాదేవీకి కనీస డిపాజిట్", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de02',
                    'tableth':"ప్రతి లావాదేవీకి గరిష్ట డిపాజిట్", 
                    'tabletd':"INR 99,000.00",
                },
            ],
            'tablenote':"గమనిక: డిపాజిట్లు 1x పందెపు అవసరానికి లోబడి ఉంటాయి.",
            'period':'కాలం',
            'startdate':'ప్రారంబపు తేది',
            'enddate':'చివరి తేది',
        },
        
    ],
    hi: [
        {
            'id' : '5725a680b3249770ea32de19',
            'title':"वापस ले लो",
            'tagName': 'tab1',
            'mobalbtn':"मैं रुपया जमा कैसे करूँ?",
            'mobalheading':"मैं रुपया कैसे जमा कर सकता हूं?",
            'paybyoptions':[
                {
                    'id': "1,2,3,4,5,6,7",
                    'name': 'All Type Deposit',
                    'xs': 1
                },
                {
                    'id': "1",
                    'name': 'Bant to Bank',
                    'xs': 1
                },
                {
                    'id': "2",
                    'name': 'Cash Deposit',
                    'xs': 1
                },
                {
                    'id': "4",
                    'name': 'PayTM',
                    'xs': 1
                },
                {
                    'id': "5",
                    'name': 'Phone Pay',
                    'xs': 1
                },
                {
                    'id': "6",
                    'name': 'Google Pay',
                    'xs': 1
                },
                {
                    'id': "7",
                    'name': 'Other Pay',
                    'xs': 1
                }
            ],
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"पिछले सात दिन", 
                },
                { 
                    'id':"2", 
                    'periodoption':"पिछले एक महीने से",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"पिछले 6 महीने",
                },  
            ],  
            'moballist':[
                {
                    'id' : '5725a680b3249770ea32de20',
                    'mobalitem':"अपने आइसोटो गेमिंग खाते के 'डिपॉजिट' टैब में, रुपए का चयन करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de21',
                    'mobalitem':"आप किस बैंक से और उसकी राशि से ट्रांसफर करना चाहते हैं, उसे चुनें।",
                },
                {
                    'id' : '5725a680b3249770ea32de22',
                    'mobalitem':"आपको बैंक पृष्ठ पर पुनः निर्देशित किया जाएगा। पृष्ठ पर दिए गए निर्देशों का पालन करें और लेनदेन को पूरा करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de23',
                    'mobalitem':"एक सफल लेनदेन के बाद, आपका क्रेडिट 5 मिनट के भीतर स्वचालित रूप से जुड़ जाएगा।",
                },
                {
                    'id' : '5725a680b3249770ea32de24',
                    'mobalitem':"जमा को संसाधित करने में किसी भी देरी से बचने के लिए, कृपया सुनिश्चित करें कि सभी जानकारी सही ढंग से प्रस्तुत की गई है।",
                },
                {
                    'id' : '5725a680b3249770ea32de25',
                    'mobalitem':"यदि आपके कोई प्रश्न हैं, तो कृपया ग्राहक सेवा से संपर्क करें।",
                }
            ],
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 1,
                    'field_name': 'Request Number',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'Type of Request',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 1,
                    'field_name': 'Send On',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'Send From',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'Amount',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': 'Reference Id',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 2,
                    'field_name': 'Request On',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_8',
                    'xs': 1,
                    'field_name': 'Status',
                    'visible': 'desktop',
                    'expandable': true
                },
            ],
            'guidebtn':"रुपया गाइड",
            'bankname': "अपना बैंक चुनें",
            'bankoptions':[
                {
                    'id' : '5725a680b3249770ea32de26',
                    'bankoptions':"भारतीय स्टेट बैंक",
                },
                {
                    'id' : '5725a680b3249770ea32de27',
                    'bankoptions':"एचडीफसी",
                },
                {
                    'id' : '5725a680b3249770ea32de28',
                    'bankoptions':"आईसीआईसीआई",
                },
                {
                    'id' : '5725a680b3249770ea32de29',
                    'bankoptions':"पीएनबी",
                },
            ],
            'amountheading': "जमा करने के लिए राशि दर्ज करें",
            'amountoption': "अपनी राशि दर्ज करें",
            'depositbtn':"जमा",
            'tableheading':"रुपया सीमा",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de30',
                    'tableth':"प्रति लेनदेन न्यूनतम जमा", 
                    'tabletd':"रूपया 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de31',
                    'tableth':"प्रति लेनदेन अधिकतम जमा", 
                    'tabletd':"रूपया 20,000.00",
                },
            ],
            'tablenote':"नोट: जमाराशि 1x के लिए आवश्यक चेतावनी के अधीन हैं।",
            'top_text'  : 'आपका खाता केवल डेमो उद्देश्य है। भुगतान वापसी / जमा करने के लिए अधिकृत नहीं', 
            'one_heading_text'  : 'उपलब्ध शेष राशि', 
            'one_box'  : 'कुल उपलब्ध शेष', 
            'two_box'  : 'कुल गैर निकासी योग्य शेष राशि', 
            'three_box'  : 'अंतिम जमा राशि', 
            'four_box'  : 'अंतिम जमा पर दांव', 
            'five_box'  : 'आज शेष राशि की निकासी', 
            'box_bottom_text_one'  : 'निकासी की राशि ',
            'box_bottom_text_two'  : 'से अधिक होनी चाहिए और अधिकतम', 
            'two_heading_text'  : 'वापसी का निवेदन',
            'selectbankaccount'  : 'बैंक खाता चुनें',
            'amount'  : 'रकम',
            'confirm_amount'  : 'राशि की पुष्टि करें',
            'comment'  : 'टिप्पणी',
            'withdraw_btn'  : 'निकालना',
            'period'  : 'अवधि',
            'start_date'  : 'आरंभ करने की तिथि',
            'end_date'  : 'अंतिम तिथि',

        },
        {
            'id' : '5725a680b3249770ea32de32',
            'title':"वापसी की स्थिति",
            'tagName': 'tab2',
            'mobalbtn':"मैं क्रेडिट जमा कैसे करूँ?",
            'mobalheading':"मैं क्रेडिट कार्ड जमा कैसे करूँ?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea32de33',
                    'mobalitem':"अपने आइसॉफ्ट गेमिंग खाते में प्रवेश करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de34',
                    'mobalitem':"अपने उपयोगकर्ता नाम के पास ड्रॉपडाउन तीर पर 'जमा' बटन पर क्लिक करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de35',
                    'mobalitem':"जमा टैब के अंदर, अपनी पसंदीदा जमा पद्धति के रूप में 'क्रेडिट कार्ड' चुनें।",
                },
                {
                    'id' : '5725a680b3249770ea32de36',
                    'mobalitem':"कार्ड नंबर, सीवीवी नंबर और समाप्ति तिथि सहित अपनी क्रेडिट कार्ड जानकारी दर्ज करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de37',
                    'mobalitem':"जमा राशि के खाली क्षेत्र में अपनी वांछित राशि का भुगतान करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de38',
                    'mobalitem':"अपनी जमा राशि की पुष्टि करने के लिए पृष्ठ के अंत में 'जमा' बटन पर क्लिक करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de39',
                    'mobalitem':"आपको बाद में जमा पुष्टिकरण प्राप्त होगा।",
                }
            ],
            'card_cardnumber':"#### #### #### ####",
            'card_date_head':"समय-सीमा समाप्त",
            'card_date':"महीना/साल",
            'card_cvv_head':"सीवीवी",
            'card_cvv':"####",
            'p_tag_first':"केवल क्रेडिट कार्ड के लिए विशेष उपयोग। क्रेडिट कार्ड का विवरण न डालें।",
            'p_tag_a':"डाउनलोड",
            'p_tag_second':"क्रेडिट ऐप क्रेडिट कार्ड खरीदने के लिए।",
            'card_number':"कार्ड नंबर",
            'expiry_date':"समाप्ति तिथि",
            'cvv':"सीवीवी",
            'deposit_amount':"जमा राशि",
            'deposit_btn':"जमा",
            'tableheading':"क्रेडिट कार्ड की सीमा",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de40',
                    'tableth':"प्रति लेनदेन न्यूनतम जमा", 
                    'tabletd':"रूपया 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de41',
                    'tableth':"प्रति लेनदेन अधिकतम जमा", 
                    'tabletd':"रूपया 99,000.00",
                },
            ],
            'tablenote':"नोट: जमाराशि 1x के लिए आवश्यक चेतावनी के अधीन हैं।",
            'period':'अवधि',
            'startdate':'आरंभ करने की तिथि',
            'enddate':'अंतिम तिथि',
        },
        
    ]
}
export default depositDb;

/*eslint-disable*/
import React, {useEffect, useRef, useState} from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton"; 
import People from "@material-ui/icons/People"; 
// react components for routing our app without refresh 
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// @material-ui/core components
import {Button, Divider, Typography, InputAdornment, Icon} from '@material-ui/core'; 
import { makeStyles } from "@material-ui/core/styles"; 
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions"; 

import Formsy from 'formsy-react';  
import { createBrowserHistory } from "history"; 
import logo from "../assets/img/logo.png"; 
import styles from "../assets/jss/components/headerLinksStyle.js"; 
import themeDb from "../data/theme.js"; 
import * as authActions from '../../../auth/casino/actions';
import {useDispatch, useSelector} from 'react-redux'; 
const history = createBrowserHistory()
let classicModal = null;
let setClassicModal = null;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

let test = "ok"; 
let currentStyle = styles;

function SectionPopup(props) {
    
    if(themeDb != null){
      styles.signinbtn.backgroundImage = themeDb.bgColors.golden;
      styles.signinbtn.color = themeDb.bgColors.goldenbtntext; 
      styles.modalSubtitle.color = themeDb.bgColors.whitetextcolor;
      styles.signupbixp.color = themeDb.bgColors.whitetextcolor; 
   
       
      styles.signinmodalbtn.backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn.background = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn["&:hover"].background = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn["&:hover"].backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn.color = props.themeColors.bgColors.text_primary_dark_color;

    }
    if(props.themeColors != null){
      styles.signinbtn.backgroundImage = props.themeColors.bgColors.golden;
      styles.signinbtn.color = props.themeColors.bgColors.goldenbtntext; 
      styles.modalSubtitle.color = props.themeColors.bgColors.whitetextcolor;
      styles.signupbixp.color = props.themeColors.bgColors.whitetextcolor; 
      styles.signinmodalbtn.backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn.background = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn["&:hover"].background = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn["&:hover"].backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn.color = props.themeColors.bgColors.text_primary_dark_color;
    }
     
    

    
  [classicModal, setClassicModal] = React.useState({status: false});
  function parseJwt(token) {
    if (!token) { return; }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}
 function getCurrentThemeInfo(){
  if(window.localStorage.getItem('jwt_theme_token') != null){
      let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
      return themeData;
  }
}


 var icon = null; 
var themeInfo = getCurrentThemeInfo();

if(themeInfo != null && themeInfo.id != null){ 
    if(themeInfo.icon != null && themeInfo.icon.length > 0){
      icon = themeInfo.icon;
    } 
}
const useStyles = makeStyles(styles);
    const classes = useStyles();
  return ( <div>
      
      <div key="8898">
    <Dialog
    classes={{
      root: classes.center,
      paper: classes.modal + " " + classes.responsivemodelllogin
      
    }}
    key="445"
    open={classicModal.status}
    TransitionComponent={Transition}
    keepMounted
    onClose={() => setClassicModal({status:false})}
    aria-labelledby="classic-modal-slide-title"
    aria-describedby="classic-modal-slide-description"
>

  <DialogTitle
      id="classic-modal-slide-title"
      disableTypography
      className={classes.modalHeaderlogin}
  >
    <IconButton
        className={classes.modalCloseButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => setClassicModal({status:false})}
    >
      <Close className={classes.modalClose} />
    </IconButton>
      
    <h4 className={classes.modalSubtitle} style={{marginTop:"10px", color:"#fff"}}>{(classicModal.title != null) ? classicModal.title : "Message"}  </h4>
  </DialogTitle>
   
  <DialogContent
      id="classic-modal-slide-description"
      className={classes.modalBodylogin}
  >  
       <p style={{fontFamily:"ubuntu", color:"#fff", margin:"0 0 15px 0", textAlign:"center"}}>{(classicModal.message != null) ? classicModal.message : ""}</p>
       {(() => {
          if(classicModal && classicModal.type != null && classicModal.type === "Button"){
            return(
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                <Button   
                    onClick={() => {setClassicModal({status:false});history.push({pathname: "/deposit"});}}
                    className={classes.signinmodalbtn +" "} 
                    style={{fontFamily:"ubuntu", 
                    color:"#fff", 
                    margin:0, 
                    textAlign:"center", 
                    backgroundColor:"red",
                    fontSize: "14px",
                    paddingLeft: "0",
                    paddingRight: "0",
                    width: "100%"}}
                    key={"117227"} 
                >
                 {"Deposit Now"}
                </Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                <Button   
                    onClick={() => {setClassicModal({status:false});}}
                    className={classes.signinmodalbtn +" "} 
                    style={{fontFamily:"ubuntu", 
                    color:"#fff", 
                    margin:0, 
                    textAlign:"center", 
                    backgroundColor:"red",
                    fontSize: "14px",  
                    paddingLeft: "0",
                    paddingRight: "0",
                    width: "100%"}}
                    key={"117228"} 
                >
                 {"Play Now"}
                </Button>
                </GridItem>
                </GridContainer>
            )
          }
       })()}
  </DialogContent>
  <DialogActions className={classes.modalFooterlogin}>
        
  </DialogActions>
 
</Dialog>
</div>
 
</div>
  );
}
SectionPopup.propTypes = {
  openPopup: function(isState){ 
      if(isState === "popup"){
        setClassicModal({status:true});
      } 
    }
};

SectionPopup.openPopup = function(isState, title, message){ 
  if(isState === "popup"){
    setClassicModal({status:true, title: title, message: message});
  } 
}

SectionPopup.openPopupWithButton = function(isState, title, message){ 
  if(isState === "popup"){
    setClassicModal({status:true, title: title, message: message, type:"Button"});
  } 
}

export default SectionPopup;
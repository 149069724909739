// import * as UserActions from './user.actions';
//import * as Actions from '../../../src/store/actions';
import jwtService from '../../../services/jwtService';

export const ADDREQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const ADDREQUEST_ERROR = 'REQUEST_ERROR';

export function withdrawrequest({banker_id, amount, comment}){
    return (dispatch) =>
        jwtService.withdrawalRequest({
            token: window.localStorage.getItem('jwt_access_token'),
            banker_id,
            amount,
            comment
        })
            .then((user) => { 
                    return dispatch({
                        type: ADDREQUEST_SUCCESS,
                        payload: user
                    });
                }
            )
            .catch(error => { 
                return dispatch({
                    type   : ADDREQUEST_ERROR,
                    payload: error
                });
            });
}




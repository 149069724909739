import React from 'react';
import FbLogin from 'react-facebook-login';

function FacebookLogin(props)
{
   return (
            <FbLogin
                appId={props.FacebookAuthId}
                autoLoad={false}
                fields="name,email,picture"
                onClick={props.componentClicked}
                callback={props.responseFacebook} />
    )
}

export default React.memo(FacebookLogin);
import React, {useEffect, useRef, useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";


import styles from "../assets/jss/components/contact.js";
import Button from "../elements/CustomButtons/Button";

import { MenuItem} from "@material-ui/core"; 
import classNames from "classnames"; 
// import leftimgconatct from "../assets/img/contact-left.png";
import {useDispatch, useSelector} from 'react-redux';
// import * as globalurl from '../../../global/Global.js'; 
import * as authActions from '../../../auth/casino/actions';
import Formsy from 'formsy-react'; 
import {TextFieldFormsy, SelectFormsy, CheckboxFormsy} from '../../../@casino';
import seccontactDb from "../data/seccontact.js";
// import { useTranslation } from 'react-i18next' 
const useStyles = makeStyles(styles);

export default function SectionContact(props) {

    const [isFormValid, setIsFormValid] = useState(false);
    const isPermission = 1;
    const userAuth = useSelector(({auth}) => auth.user);
    const formRef = useRef(null); 
    const dispatch = useDispatch();
    
    useEffect(() => {
        if ( userAuth.error && (userAuth.error.parent_banker_id || userAuth.error.sender_banker_id || userAuth.error.amount || userAuth.error.message) )
        {
            if(userAuth.error.message != null){
                document.body
                .getElementsByClassName("message")[0] 
                .innerHTML=userAuth.error.message
            }else{
                formRef.current.updateInputsWithError({
                    ...userAuth.error
                });
            }
        }else{
            if(userAuth.success != null && userAuth.success === true){
                alert("Contact request sent successfully.")
                window.location.reload();
            }
        }
    }, [userAuth.error, userAuth.success]);

    function disableButton()
    {
        setIsFormValid(false);
        document.body
        .getElementsByClassName("signupbtn")[0]
          .setAttribute("style", "opacity:0.5;");
    }
    function enableButton()
    {
        setIsFormValid(true);
        document.body
        .getElementsByClassName("signupbtn")[0]
          .setAttribute("style", "opacity:1;");
    }
 
    function handleSubmit(model)
    {
         //CONTACTREQUEST
         dispatch(authActions.contactRequest(model));  
    }
    // function checkNow(isChecked){
    //     if(isChecked === true){
    //         setIsPermission(1)
    //     }else{
    //         setIsPermission(0)
    //     }
    // }
 
    styles.subtitle.color = props.themeColors.bgColors.text_secondary_color
    styles.text.color = props.themeColors.bgColors.text_secondary_dark_color
    styles.contactbox.background = props.themeColors.bgColors.primary_light_bgcolor 
    styles.mybtn1.backgroundImage = props.themeColors.bgColors.main_color
    styles.mybtn1.background = props.themeColors.bgColors.main_color
    styles.mybtn1.color = props.themeColors.bgColors.text_primary_dark_color 

    styles.singlecontact.background = props.themeColors.bgColors.text_secondary_dark_color  
    styles.contactbox["& textarea"].color = props.themeColors.bgColors.text_primary_color 
    styles.contactbox["& input"].color = props.themeColors.bgColors.text_primary_color 
    styles.contactbox["& input"].borderColor = props.themeColors.bgColors.secondary_dark_bgcolor 
    styles.contactbox["& textarea"].borderColor = props.themeColors.bgColors.secondary_dark_bgcolor 
    styles.selectboxx["& fieldset"].borderColor = props.themeColors.bgColors.secondary_dark_bgcolor 
    // styles.selectboxx["& .MuiSelect-icon"].color = props.themeColors.bgColors.secondary_dark_bgcolor 
    styles.contactbox["& label"].color = props.themeColors.bgColors.text_secondary_dark_color
    styles.checkboxin["& p"].color = props.themeColors.bgColors.text_primary_color 
    styles.checkboxin["& span"].color = props.themeColors.bgColors.text_secondary_color 
    styles.checkboxxlogin.color = props.themeColors.bgColors.text_secondary_color 
    
    styles.contacttitle.color = props.themeColors.bgColors.text_primary_color  
    styles.checkbtitext.color = props.themeColors.bgColors.text_primary_color
    // styles.addbankaccoutbox["& label"].fontFamily = props.themeColors.bgColors.base_font;  

    styles.contacttitle.fontFamily = props.themeColors.bgColors.base_font; 
    styles.contactiinpu["& label"].fontFamily = props.themeColors.bgColors.base_font; 
    styles.contactiinpu["& input"].fontFamily = props.themeColors.bgColors.base_font;   
    styles.contactbox["& textarea"].fontFamily = props.themeColors.bgColors.base_font;      
    styles.checkbtitext.fontFamily = props.themeColors.bgColors.base_font; 
    styles.mybtn1.fontFamily = props.themeColors.bgColors.base_font; 
    
    // const { t } = useTranslation()
  const classes = useStyles();
  return (
      <div className={classNames(classes.singlecontact)}>
     
          <div className={classNames(classes.container)}> 
             
      <GridContainer>
         
          <GridItem xs={12} sm={12} md={12}>
          <Formsy
                onValidSubmit={handleSubmit}
                onValid={enableButton}
                onInvalid={disableButton}
                ref={formRef}
                className={classes.form + " flex flex-col justify-center w-full"}
            >
              <div className={classes.contactformwrapper}>
              {seccontactDb.categories.map((jsondata) => (
                  <div className={classes.contactbox}>
                      
                      <h4 className={classes.contacttitle}>
                      Send Us a Message
                      </h4>
                      <GridContainer>
                                <GridItem md={12}><div className={classes.msg + " message errormsg "} id="message"></div></GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                <TextFieldFormsy 
                                    label="Enter Your Full Name"
                                    id="name"
                                    name="name"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validations={{
                                        minLength: 2
                                    }} 
                                    className={classes.contactiinpu}
                                    validationErrors={{
                                        minLength: 'Min character length is 2'
                                    }} 
                                    variant="outlined"
                                    required
                                />  
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                <TextFieldFormsy 
                                    label="Enter Your Email Address"
                                    id="email"
                                    name="email"
                                    type="email"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validations={{
                                        isEmail: true
                                    }} 
                                    className={classes.contactiinpu}
                                    validationErrors={{
                                        isEmail: 'Email format must be valid'
                                    }} 
                                    variant="outlined"
                                    required
                                />   
                                </GridItem>
 
                                <GridItem xs={12} sm={12} md={12}>
                                  <SelectFormsy 
                                    name="type_of"
                                    className={classes.selectboxx + " selectbox"} 
                                    value="tom"
                                    label="Topic"
                                    variant="outlined"
                                    validations={{
                                        isNumeric: true
                                    }}
                                    validationErrors={{
                                        isNumeric: 'Please select topic'
                                    }} 
                                    required>
                                    {jsondata.submenus.map((jsondata) => (
                                      <MenuItem value={((jsondata.id > 0) ? jsondata.id : "tom")}>{jsondata.options}</MenuItem> 
                                    ))}
                                  </SelectFormsy>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={12}>
                                  <TextFieldFormsy 
                                        name="message" 
                                        placeholder="Message" 
                                        className={classes.contacttextarea}
                                        multiline
                                        rowsMax={4}
                                        rows={4}
                                        required
                                    />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                  <div className={classes.yesnofild}>
                                    <div className={classes.checkboxin + " " + classes.checkboxxlogin} >
                                        <CheckboxFormsy 
                                            id="checkPermission"
                                            name="checkPermission"
                                            value={((isPermission === 1) ? "1" : "")} 
                                            data="1" 
                                            className={classes.checkboxx + " " + classes.checkboxxlogin}
                                            checked={((isPermission === 1) ? "1" : "")} 
                                            required
                                        />
                                    </div>
                                    <div className={classes.checkbtitext} >I have read and accept the general terms and conditions </div>
                                  </div>
                                  <div className={classes.clearfix}></div>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={12}>
                                  <Button
                                      type="submit"
                                      color="transparent" 
                                      className={classes.mybtn1 +" signupbtn"}
                                      disabled={!isFormValid}
                                  >
                                      SUBMIT NOW
                                  </Button> 
                                  </GridItem>
                                  </GridContainer>
                  </div>))}
              </div>
            </Formsy>
          </GridItem>
      </GridContainer>
      </div>
      </div>
  );
}

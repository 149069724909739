import React, { useState } from "react";
import 'react-accessible-accordion/dist/fancy-example.css';

import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";

import styles from "../assets/jss/components/tabsStatementstyle.js";
import UploadAadhar from "../sections/UploadAadhar";
// import VerifyAadhar from "../sections/VerifyAadhar";
import VerifyPan from "../sections/VerifyPan";
import VerifyDl from "../sections/VerifyDl";
import aadhar from "../assets/img/aadharpic.png";
import dl from "../assets/img/dlpic.png";
import pan from "../assets/img/panpic.png";
// import { useTranslation } from 'react-i18next';
import * as Gurl from "../../../global/Global.js"
import axios from 'axios';
import kycmoduleDb from "../data/kycmodule.js";
const useStyles = makeStyles(styles);
export default function KycModule(props) {
    const { ...rest } = props;

    if(props.themeColors != null){ 
        styles.modulebox.background = props.themeColors.bgColors.primary_bgcolor;
        styles.modulebox.borderColor = props.themeColors.bgColors.main_color_bg;
        
    } 
    const classes = useStyles();
    const [kycData, setKycData] = useState("NOVALUE");
    const [dlData, setDlData] = useState("NOVALUE");
    const [pandData, setPanData] = useState("NOVALUE");
    const [adharData, setAadharData] = useState("NOVALUE");

    function getKYCData(){
        const request = axios.post(Gurl.KYCINFO, {
            token: window.localStorage.getItem('jwt_access_token')
        });
        request.then((response) => {
            if (response.data.data != null && Array.isArray(response.data.data)) {
              //  alert(JSON.stringify(response.data.data))
                setKycData(response.data.data);
                const dlResponse = response.data.data.filter(kyc => kyc.name == 'id_proof');
                if (dlResponse.length > 0) {
                    setDlData(dlResponse[0]);
                }
                const panResponse = response.data.data.filter(kyc => kyc.name == 'pan');
                if (panResponse.length > 0) {
                    setPanData(panResponse[0]);
                }
                const adharResponse = response.data.data.filter(kyc => kyc.name == 'aadharcard');
                if (adharResponse.length > 0) {
                    setAadharData(adharResponse[0]);
                }
            } else if (response.data.data != null) {
                setKycData(response.data.data);
                const dlResponse = (response.data.data.name == 'id_proof') ? response.data.data : {};
                if (dlResponse.length > 0) {
                    setDlData(dlResponse[0]);
                }
                const panResponse = (response.data.data.name == 'pan') ? response.data.data : {};
                if (panResponse.length > 0) {
                    setPanData(panResponse[0]);
                }
                const adharResponse = (response.data.data.name == 'aadharcard') ? response.data.data : {};
                if (adharResponse.length > 0) {
                    setAadharData(adharResponse[0]);
                }
            }else{
                setKycData("NOVALUE");
            }
        });
        request.catch(() => {
            setKycData("NOVALUE");
        });
    }
    
    if(kycData === "NOVALUE"){
        getKYCData();
    }

    return (
        <div className={classes.moduleboxwrap}>
            {kycmoduleDb[sessionStorage.getItem('lang')].map((jsondata) => (
               
            <GridContainer>
                <GridItem xs={12} sm={12} md={4}> 
                    <div className={classes.modulebox} >
                        <a href="#/" onClick={() => { UploadAadhar.openPopup("UploadAadhar"); }}>
                            <img src={(adharData.front_scan != undefined) ? adharData.front_scan : aadhar} alt="" />
                            <h3>{jsondata.aadharcard}</h3>
                            <span title="Not Verified" className={classes.verify}></span>
                        </a>
                    </div>
                    <UploadAadhar kycData={adharData} getKYCData={getKYCData} {...rest} />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.modulebox} onClick={() => { VerifyPan.openPopup("VerifyPan"); }}>
                        <img src={(pandData.front_scan != undefined) ? pandData.front_scan : pan} alt="" />
                        <h3>{jsondata.pancard}</h3>
                        <span title="Not Verified" className={classes.verify}></span>
                    </div>
                    <VerifyPan kycData={pandData} getKYCData={getKYCData} {...rest} />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.modulebox} onClick={() => { VerifyDl.openPopup("VerifyDl"); }}>
                        <img src={(dlData.front_scan != undefined) ? dlData.front_scan : dl} alt="" />
                        <h3>{jsondata.driving_license}</h3>
                        <span title="Not Verified" className={classes.verify}></span>
                    </div>
                    <VerifyDl kycData={dlData} getKYCData={getKYCData} {...rest} />
                </GridItem>
            </GridContainer>
            ))}
        </div>
    );
}

import React  from "react";
import history from '../../../@history';
import {makeStyles} from "@material-ui/core/styles";   
import 'react-accessible-accordion/dist/fancy-example.css';
import logo from "../assets/img/logo.png"; 
import styles from "../assets/jss/views/landingPage.js";  
import {Button} from '@material-ui/core';
import axios from 'axios';
import queryString from 'query-string';
import * as globalurl from '../../../global/Global.js';  

const useStyles = makeStyles(styles);
 
var webServiceRunning = false;
export default function WlVerifyemail(props) { 
    const classes = useStyles();  
    let params = queryString.parse(props.location.search);
    const [messageTitle, setMessageTitle] = React.useState("Code is expired or account is not registered.");
    const [userName, setUserName] = React.useState("User");
    const [color, setColor] = React.useState({color:'red'});
    const [messageContent, setMessageContentTitle] = React.useState("Your email has not been verified. Please re verify your email id or may be token is expired");
    if(params != null && params.code && params.email && webServiceRunning === false){
        verifyEmail();
    }
    function goToLogin(){
        history.push({
            pathname: '/wllogin'
        });
    }
    function verifyEmail(){
      webServiceRunning = true;
      const request = axios.post(globalurl.VERIFYOPREMAILDIRECT, {
        token: params.code,
        email: params.email
      });
        request.then((response) => {
            if(response != null && response.data != null && response.data.status === true){
              setColor({color:'green'});
              setMessageTitle("EMAIL VERIFIED");
              setMessageContentTitle("Thank you, Your email (" + response.data.email_id + ") has been verified. Your account is now active. Please use the link below to login to your account.");
              setUserName(response.data.name)
            }
            webServiceRunning = false;
        });
        request.catch((error) => {
          webServiceRunning = false;
        });
    }
     
    return (
        <div className={classes.fullpagelogin}>

 

 
        <img className={classes.logoimg} alt="" style={{marginTop:"10px",marginBottom:"10px",}} src={logo}/>
        <div className={classes.loginwrap}>
            <h4 style={color}>{messageTitle}</h4>
            <div style={{textAlign:'center'  }}>
            <i style={{fontSize:'50px',   }} className="fa fa-envelope"></i>
            <h5 style={{fontWeight:'bold', color:'green', }}>Hello {userName},</h5>
            <p>{messageContent}</p>
</div>
            <Button onClick={goToLogin} simple color="primary" className={classes.signinmodalbtn +" signinbtn"} type="submit" size="lg"   >
            Login to Your Account
                    </Button>
                    
        </div>
         </div>
    );
}
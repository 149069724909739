import {combineReducers} from 'redux';
import {user, changePassword, changeProfile} from './user.reducer';
import {login, forgot} from './login.reducer';
import {loginmobile} from './loginmobile.reducer';
import {otpnew} from './otpnew.reducer';
import register from './register.reducer';
import registermobile from './registermobile.reducer';
import addbank from './bankaccount.reducer';
import depositrequest from './depositrequest.reducer';
import gatewayrequest from './gatewayrequest.reducer';
import ewalletdeposit from './ewalletdeposit.reducer';
import paypalrequest from './paypalrequest.reducer';
import angadiyadeposit from './angadiyadeposit.reducer';
import withdrawrequest from './withdrawrequest.reducer';

import wllogin from './wllogin.reducer';
import wlOtp from './wlotp.reducer';
import profilesetup from './profilesetup.reducer'; 
import wlregister from './wlregister.reducer';
import imgupload from './imgupload.reducer';
import kycupload from './kycupload.reducer';
import resendotp from './resendotp.reducer';

const authReducers = combineReducers({
    user,
    login,
    loginmobile,
    otpnew,
    forgot,
    register,
    registermobile,
    addbank,
    gatewayrequest,
    depositrequest,
    ewalletdeposit,
    paypalrequest,
    angadiyadeposit,
    withdrawrequest,
    changePassword,
    changeProfile,
    wllogin,
    wlOtp,
    profilesetup,
    wlregister,
    imgupload,
    kycupload,
    resendotp
});

export default authReducers;
function getCurrentThemeInfo(){
    if(window.localStorage.getItem('jwt_theme_token') != null){
        let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
        return themeData;
    }
  } 
  function parseJwt(token) {
    if (!token) { return; }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}
 
  var primary_color = "#ffffff"; 
  var primary_bgcolor = "#000000"; 
  var primary_dark_bgcolor = "#2b2b2b"; 
  var primary_light_bgcolor = "#353535"; 
  var primary_inner_bgcolor = "#1e1e1e"; 
  var secondary_bgcolor = "#0f0f0f"; 
  var secondary_dark_bgcolor = "#131212"; 
  var secondary_color = "#b3b2b2"; 
  var main_color = "linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07)"; 
  var main_color_bg = "#ffb80c"; 
  var gradient_border = "linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07)"; 
  var text_primary_color = "#ffffff"; 
  var text_primary_dark_color = "#000000"; 
  var text_primary_in_color = "#783205"; 
  var text_secondary_color = "#ffb80c"; 
  var text_secondary_dark_color = "#b3b2b2"; 
  var text_secondary_in_color = "#7d7d7d"; 
  var primary_shadow = "0px 0px 0px 15px rgba(255, 184, 12, 0.2), 0px 0px 0px 15px rgba(255, 184, 12, 0.2)";
  var primary_light_shadow = "0px 0px 0px 15px rgba(255, 184, 12, 0.2), 0px 0px 0px 30px rgba(255, 184, 12, 0.2)";
  var logo_font = 'Open Sans';
  var base_font = 'Ubuntu'; 
  var themeInfo = getCurrentThemeInfo(); 
 // alert(JSON.stringify(JSON.parse("["+themeInfo.themecolors+"]")))
//alert(JSON.stringify(themeInfo))
  if(themeInfo != null && themeInfo.id != null){
  //  alert("check: " + themeInfo.themecolors);  
        var parseThemeColors = "";
        if(themeInfo.themecolors != null && themeInfo.themecolors.length > 0){ 
            parseThemeColors = JSON.parse(themeInfo.themecolors); 
            if(parseThemeColors.primary_color != null && parseThemeColors.primary_color.length > 0){
                primary_color = parseThemeColors.primary_color; 
            } 
        }
        if(themeInfo.themecolors != null && themeInfo.themecolors.length > 0){ 
            parseThemeColors = JSON.parse(themeInfo.themecolors); 
            if(parseThemeColors.primary_bgcolor != null && parseThemeColors.primary_bgcolor.length > 0){
                primary_bgcolor = parseThemeColors.primary_bgcolor; 
            } 
        }
        if(themeInfo.themecolors != null && themeInfo.themecolors.length > 0){ 
            parseThemeColors = JSON.parse(themeInfo.themecolors); 
            if(parseThemeColors.primary_dark_bgcolor != null && parseThemeColors.primary_dark_bgcolor.length > 0){
                primary_dark_bgcolor = parseThemeColors.primary_dark_bgcolor; 
            } 
        }
        if(themeInfo.themecolors != null && themeInfo.themecolors.length > 0){ 
            parseThemeColors = JSON.parse(themeInfo.themecolors); 
            if(parseThemeColors.primary_light_bgcolor != null && parseThemeColors.primary_light_bgcolor.length > 0){
                primary_light_bgcolor = parseThemeColors.primary_light_bgcolor; 
            } 
        }
        if(themeInfo.themecolors != null && themeInfo.themecolors.length > 0){ 
            parseThemeColors = JSON.parse(themeInfo.themecolors); 
            if(parseThemeColors.primary_inner_bgcolor != null && parseThemeColors.primary_inner_bgcolor.length > 0){
                primary_inner_bgcolor = parseThemeColors.primary_inner_bgcolor; 
            } 
        }
        if(themeInfo.themecolors != null && themeInfo.themecolors.length > 0){ 
            parseThemeColors = JSON.parse(themeInfo.themecolors); 
            if(parseThemeColors.secondary_bgcolor != null && parseThemeColors.secondary_bgcolor.length > 0){
                secondary_bgcolor = parseThemeColors.secondary_bgcolor; 
            } 
        }
        if(themeInfo.themecolors != null && themeInfo.themecolors.length > 0){ 
            parseThemeColors = JSON.parse(themeInfo.themecolors); 
            if(parseThemeColors.secondary_dark_bgcolor != null && parseThemeColors.secondary_dark_bgcolor.length > 0){
                secondary_dark_bgcolor = parseThemeColors.secondary_dark_bgcolor; 
            } 
        }
        if(themeInfo.themecolors != null && themeInfo.themecolors.length > 0){ 
            parseThemeColors = JSON.parse(themeInfo.themecolors); 
            if(parseThemeColors.secondary_color != null && parseThemeColors.secondary_color.length > 0){
                secondary_color = parseThemeColors.secondary_color; 
            } 
        }
        if(themeInfo.themecolors != null && themeInfo.themecolors.length > 0){ 
            parseThemeColors = JSON.parse(themeInfo.themecolors); 
            if(parseThemeColors.main_color != null && parseThemeColors.main_color.length > 0){
                main_color = parseThemeColors.main_color; 
            } 
        }
        if(themeInfo.themecolors != null && themeInfo.themecolors.length > 0){ 
            parseThemeColors = JSON.parse(themeInfo.themecolors); 
            if(parseThemeColors.main_color_bg != null && parseThemeColors.main_color_bg.length > 0){
                main_color_bg = parseThemeColors.main_color_bg; 
            } 
        }
        if(themeInfo.themecolors != null && themeInfo.themecolors.length > 0){ 
            parseThemeColors = JSON.parse(themeInfo.themecolors); 
            if(parseThemeColors.gradient_border != null && parseThemeColors.gradient_border.length > 0){
                gradient_border = parseThemeColors.gradient_border; 
            } 
        }
        if(themeInfo.themecolors != null && themeInfo.themecolors.length > 0){ 
            parseThemeColors = JSON.parse(themeInfo.themecolors); 
            if(parseThemeColors.text_primary_color != null && parseThemeColors.text_primary_color.length > 0){
                text_primary_color = parseThemeColors.text_primary_color; 
            } 
        }
        if(themeInfo.themecolors != null && themeInfo.themecolors.length > 0){ 
            parseThemeColors = JSON.parse(themeInfo.themecolors); 
            if(parseThemeColors.text_primary_dark_color != null && parseThemeColors.text_primary_dark_color.length > 0){
                text_primary_dark_color = parseThemeColors.text_primary_dark_color; 
            } 
        }
        if(themeInfo.themecolors != null && themeInfo.themecolors.length > 0){ 
            parseThemeColors = JSON.parse(themeInfo.themecolors); 
            if(parseThemeColors.text_primary_in_color != null && parseThemeColors.text_primary_in_color.length > 0){
                text_primary_in_color = parseThemeColors.text_primary_in_color; 
            } 
        }
        if(themeInfo.themecolors != null && themeInfo.themecolors.length > 0){ 
            parseThemeColors = JSON.parse(themeInfo.themecolors); 
            if(parseThemeColors.text_secondary_color != null && parseThemeColors.text_secondary_color.length > 0){
                text_secondary_color = parseThemeColors.text_secondary_color; 
            } 
        }
        if(themeInfo.themecolors != null && themeInfo.themecolors.length > 0){ 
            parseThemeColors = JSON.parse(themeInfo.themecolors); 
            if(parseThemeColors.text_secondary_dark_color != null && parseThemeColors.text_secondary_dark_color.length > 0){
                text_secondary_dark_color = parseThemeColors.text_secondary_dark_color; 
            } 
        }
        if(themeInfo.themecolors != null && themeInfo.themecolors.length > 0){ 
            parseThemeColors = JSON.parse(themeInfo.themecolors); 
            if(parseThemeColors.text_secondary_in_color != null && parseThemeColors.text_secondary_in_color.length > 0){
                text_secondary_in_color = parseThemeColors.text_secondary_in_color; 
            } 
        }
        if(themeInfo.themecolors != null && themeInfo.themecolors.length > 0){ 
            parseThemeColors = JSON.parse(themeInfo.themecolors); 
            if(parseThemeColors.primary_shadow != null && parseThemeColors.primary_shadow.length > 0){
                primary_shadow = parseThemeColors.primary_shadow; 
            } 
        }
        if(themeInfo.themecolors != null && themeInfo.themecolors.length > 0){ 
            parseThemeColors = JSON.parse(themeInfo.themecolors); 
            if(parseThemeColors.primary_light_shadow != null && parseThemeColors.primary_light_shadow.length > 0){
                primary_light_shadow = parseThemeColors.primary_light_shadow; 
            } 
        }
        if(themeInfo.themecolors != null && themeInfo.themecolors.length > 0){ 
            parseThemeColors = JSON.parse(themeInfo.themecolors); 
            if(parseThemeColors.logo_font != null && parseThemeColors.logo_font.length > 0){
                logo_font = parseThemeColors.logo_font; 
            } 
        }
        if(themeInfo.themecolors != null && themeInfo.themecolors.length > 0){ 
            parseThemeColors = JSON.parse(themeInfo.themecolors); 
            if(parseThemeColors.base_font != null && parseThemeColors.base_font.length > 0){
                base_font = parseThemeColors.base_font; 
            } 
        }
  } 

const themeDb = {
    bgColors:
        { 
            'primary_color':primary_color + " !important",
            'primary_bgcolor':primary_bgcolor + " !important",
            'primary_dark_bgcolor':primary_dark_bgcolor + " !important",
            'primary_light_bgcolor':primary_light_bgcolor,
            'primary_inner_bgcolor':primary_inner_bgcolor,  
            'secondary_bgcolor':secondary_bgcolor,
            'secondary_dark_bgcolor':secondary_dark_bgcolor + " !important",
            'secondary_color':secondary_color + " !important",
            'main_color':main_color + " !important",
            'main_color_bg': main_color_bg + " !important",
            'gradient_border':gradient_border + " !important",
             
            'text_primary_color':text_primary_color + " !important",
            'text_primary_dark_color':text_primary_dark_color + " !important",
            'text_primary_in_color': text_primary_in_color + " !important",
            'text_secondary_color':text_secondary_color + " !important",
            'text_secondary_dark_color':text_secondary_dark_color + " !important",
            'text_secondary_in_color': text_secondary_in_color + " !important",
            'primary_shadow': primary_shadow,
            'primary_light_shadow': primary_light_shadow,
            'logo_font': logo_font,
            'base_font': base_font,
        }

}
export default themeDb;



 

import {
  container,
  defaultFont,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor,
  transition,
  boxShadow,
  drawerWidth
} from "../../casino-kit-react";


import Background from '../../img/nov.png';
import Background2 from '../../img/headerboobg.jpg';

const headerStyle = {
  about:{

  },
  logoname:{
    color:"#ffffff",
    margin: "0px 0 0 10px",
    fontFamily: 'Open Sans',
    fontWeight: "500",
    textTransform:"capitalize",
    ['@media (max-width:450px)']: { // eslint-disable-line no-useless-computed-key
      display:"none"
    },
  },
  
  vipicon:{
    background:"none",
    border:"0px",
    marginRight:"10px",
    "& img":{
      height:"36px"
    },
    ['@media (max-width:959px)']: { // eslint-disable-line no-useless-computed-key
      display:"none",
    },
  },
  langheader:{
    display:"none",
    ['@media (max-width:959px)']: { // eslint-disable-line no-useless-computed-key
      display:"block",
    },
  },
  laguagetrans:{
    "& img":{
      width:"30px !important",
      height:"30px !important"
    }
  },
  navLink:{
    padding:"0px 0px",
    minWidth: "24px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      color:"#bdbdbd !important",
      minWidth: "24px",
    },
  },
  headtitle:{
    fontSize: "70px",
    lineHeight: "80px",
    fontWeight: "700",
    marginBottom: "11px",
    textTransform: "uppercase",
    color: "#fff",
    fontFamily: "Ubuntu",
  },
  breadcrumbarea:{
    padding: "170px 0px 135px",
    background: "#070b28",
    position: "relative",
    overflow: "hidden",
    borderBottom: "1px solid rgba(255, 255, 255, 0.04)",
  },
  bcimg:{
    position: "absolute",
    right: "0px",
    bottom: "0px",
    maxWidth: "40%",
    verticalAlign: "middle",
  },
  listmar:{
    margin:"0px !important",
  },
  breadcrumblist:{
    "& li":{
      display: "inline-block",
    },
    "& li a":{
      fontSize: "16px",
      transition: "all 0.3s ease-in",
      color: "#fff",
    },
    "& li a span":{
      margin: "0px",
    },
    "& span":{
      margin: "0px 7px",
      color: "#fff",
    },
  },
  imglogo:{
    marginLeft: "20px",
    marginTop: "5px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
       marginLeft: "0px",
    },
    ['@media (max-width:959px)']: { // eslint-disable-line no-useless-computed-key
      maxWidth: "120px",
      maxHeight: "60px",
      height: "auto",
   },
  },
  displayNone: {
    display: "none"
  },
  appBar: {
    display: "block",
    border: "0",
    borderRadius: "3px",
    padding: "0 0",
    marginBottom: "20px",
    color: "#555",
    width: "100%",
    backgroundColor: "#000",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
    transition: "all 150ms ease 0s",
    alignItems: "center",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    position: "relative",
    zIndex: "unset"
  },
  absolute: {
    position: "absolute",
    zIndex: "1100"
  },
  fixed: {
    position: "fixed",
    zIndex: "1100"
  },
  containernew: {
    ...container,
    minHeight: "50px",
    display: "block",
    alignItems: "center",
    justifyContent: "space-between",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      width:"auto"
    },
  },
  container: {
    ...container,
    minHeight: "50px",
    flex: "1",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      width:"auto"
    },
  },
  flex: {
    flex: 1
  },
  socialIcons: {
    position: "relative",
    fontSize: "20px !important",
    marginRight: "4px",
    marginTop: "5px",
    color:"#fff",
  },
  title: {
    ...defaultFont,
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    padding: "8px 16px",
    letterSpacing: "unset",
    "&:hover,&:focus": {
      color: "inherit",
      background: "transparent"
    }
  },
  appResponsive: {
    margin: "20px 10px"
  },
  primary: {
    backgroundColor: primaryColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46)"
  },
  info: {
    backgroundColor: infoColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46)"
  },
  success: {
    backgroundColor: successColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(76, 175, 80, 0.46)"
  },
  warning: {
    backgroundColor: warningColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(255, 152, 0, 0.46)"
  },
  danger: {
    backgroundColor: dangerColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46)"
  },
  toolbarafter:{
    '&::before': {
      position: "absolute",
      content: 'close-quote',
      width: "1800px",
      height: "100%",
      top: "0",
      right: "100%",
      background: "#070b28",
    },
    '&::after': {
      position: "absolute",
      content: 'close-quote',
      width: "0",
      height: "0",
      left: "0px",
      bottom: "0px",
      borderBottom: "83px solid #000",
      borderRight: "35px solid transparent",
    },
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      '&::after': {
        borderRight: "0px",
      },
    },
  },
  rose: {
    backgroundColor: roseColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(233, 30, 99, 0.46)"
  },
  transparent: {
    backgroundColor: "transparent !important",
    boxShadow: "none",
    paddingTop: "0px",
    color: "#FFFFFF"
  },
  dark: {
    color: "#FFFFFF",
    backgroundColor: "#212121 !important",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)"
  },
  white: {
    border: "0",
    padding: "0.625rem 0",
    marginBottom: "20px",
    color: "#555",
    backgroundColor: "#fff !important",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)"
  },
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    width: drawerWidth,
    ...boxShadow,
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "0",
    left: "auto",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    ...transition,
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      background:"#3c3d3e"
    },
  },






// Signin
  topheader:{
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      padding:"5px 0px 5px !important"
    }, 
  },
  imglogo2:{
    marginLeft: "20px",
    marginTop: "5px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      marginLeft: "0px",
      height:"40px"
    },
    ['@media (max-width:959px)']: { // eslint-disable-line no-useless-computed-key
      maxWidth: "120px",
      maxHeight: "60px",
      height: "auto",
    },
    ['@media (max-width:450px)']: { // eslint-disable-line no-useless-computed-key
      display:"none"
    },
  },
  himeicon:{
    display:"none",
    padding:"20px 0px 0px 10px",
    ['@media (max-width:450px)']: { // eslint-disable-line no-useless-computed-key
      display:"block",
      color:"#fff",
    },
  },
  headersignin:{
  },
  headersigninfix:{
    top:"0px"
  },
  headsigninbg:{
    background:"url(" + Background2 + ")",
    height: "58px",
    padding: "7px 0px",
    backgroundSize: "100% 100%",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      overflow: "auto",
      width:"100%",
      height: "44px",
      overflowY: "hidden",
    },
  },
  balanceamount:{
    border: "2px solid #ffb80c",
    borderColor:"#ffb80c",
    borderRadius: "25px",
    width: "180px",
    display:"inline-block",
    color: "#fff",
    textAlign: "right",
    lineHeight: "34px",
    fontSize:"18px",
    background:"#000",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      width: "150px",
      fontSize: "14px",
      lineHeight: "24px",      
    },
    ['@media (max-width:400px)']: { // eslint-disable-line no-useless-computed-key
      width: "auto",     
      paddingLeft: '15px',
    },
    "& .fa-credit-card":{
      ['@media (max-width:400px)']: { // eslint-disable-line no-useless-computed-key
        display:"none"
      },
    },
    "&:hover":{
      color:"#fff"
    }
  },
  coinbalance:{
    float:"left",
    color:"#ff344d",
    marginTop: "6px",
    marginLeft: "5px",
  },
  plusbalance:{
    float:"right",
    color:"#ff344d",
    marginLeft: "10px",
    marginTop: "6px",
    marginRight: "5px",
  },
  balancerefresh:{
    background: "#ffb80c",
    fontSize: "18px",
    borderRadius: "50px",
    height: "42px",
    padding: "0",
    marginLeft: "10px",
    color: "#fff",
    minWidth: "42px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      height: "34px",
      padding: "0",
      fontSize: "14px",
      minWidth: "34px",
      background: "#ffb80c",
    },
  },
  balanceboxwrap:{
    display:"block",
    float:"right",
    paddingTop: "60px",
    position:"fixed",
    top:"-25px",
    right:"350px",
    background: "url(" + Background + ")",
    backgroundRepeat:"no-repeat",
    zIndex:"999",
    backgroundPosition:"-11px -3px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      paddingTop: "7px",
      top: "auto",
      right: "auto", 
      position: "inherit", 
      background:"none"
    },
  },
  rightcontent2:{
    display:"inline-block",
    paddingLeft: "20px",
    paddingRight: "20px",
    float:"right",
    paddingTop: "8px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "2px",
    },
  },
  usersigninbtn:{
    textTransform:"capitalize",
    fontSize: "18px",
    fontFamily: "ubuntu",
    color:"#fff",
    "& i":{
      ['@media (max-width:761px)']: { // eslint-disable-line no-useless-computed-key
        color: "#ffffff !important",
        background:"#3c3d3e !important",
      },
    },
    "& b":{
      ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
        display:"none",
      },
    }, 
  },
  userdetailicon:{
    display:"none",
    textTransform:"capitalize",
    fontSize: "18px",
    color:" #a1aed4",
    height: "42px",
    padding: "0",
    lineHeight: "40px",
    minWidth: "42px",
    background: "#252f5a",
    borderRadius: "50px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      display: "inline-block",
      height: "34px",
      fontSize: "14px",
      lineHeight: "34px",
      minWidth: "34px",
    },
  },
  userdetailname:{
    margin:"0px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      display: "none",
    },
  },
  userflagbtn:{
    textTransform:"capitalize",
    fontSize: "18px",
    fontFamily: "ubuntu",
    "& b":{
      display:"none"
    }
  },
  rightsidebarbtn:{
    textTransform:"capitalize",
    fontSize: "18px",
    fontFamily: "ubuntu",
    color:" #fff",
    height: "42px",
    padding: "0",
    minWidth: "42px",
    background: "#252f5a",
    borderRadius: "50px",
    display:"block",
    textAlign: "center",
    lineHeight: "40px",
    "& b":{
      display:"none"
    },
    "& i":{
      marginTop:"1px"
    },
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      height: "34px",
      fontSize: "14px",
      lineHeight: "34px",
      minWidth: "34px",
    },
  },
  rightsidebardiv:{
    display:"inline-block",
    marginLeft:"20px",
    float: "right",
    paddingTop: "0px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      marginLeft:"5px",
      paddingTop: "6px",
    },
    ['@media (max-width:450px)']: { // eslint-disable-line no-useless-computed-key
    //  display:"none",
    },
  },
  signinnotificationbox:{
    display:"none",
    position: "absolute",
    top: "50px",
    background: "#fff",
    color: "#000",
    right: "0px",
    zIndex:"9999",
    borderRadius:"10px",
    padding: "15px",
    "& > div:first-child":{
      margin:"0px"
    },
    "& div:nth-child(1)":{
      background:"none",
      boxShadow:"0px 0px 0px 0px",
      padding:"0px",
      margin:"0px",
    },
    "& button":{
      width: "50px !important",
      padding: "0",
    }
  },
  listItemnot:{
    padding:"0px"
  },
  notificationmsgbox:{
    padding:"10px !important",
    marginBottom:"10px !important",
    minWidth:"280px", 
    borderRadius:"10px",
    background:"#f1f1f1 !important",
    fontFamily: "ubuntu",
    "&:hover":{
      background:"#252f5a !important",
      color:"#fff"
    },
    "& h5":{
      padding:"0px",
      fontSize:"14px",
      fontWeight:"bold",
      margin:"0px 0px 5px",
      color:"#f9314b",
    },
    "& p":{
      padding:"0px",
      margin:"0px",
      fontSize:"12px"
    },
    "&:hover h5":{ 
      color:"#fff"
    },
  },
  signinsidebarbox:{
    display:"none",
    background: "rgba(0,0,0,0.5)",
    width:" 100%",
    height: "100%",
    position: "fixed",
    left: "0",
    top: "0",
    zIndex: "9",
  },
  messagebox:{
    overflow:"auto",
    height:"260px"
  },
  usernotibtn:{
    color: "#fff",
    height: "42px",
    padding: "0",
    fontSize: "18px",
    minWidth: "42px",
    background: "#252f5a",
    fontFamily: "ubuntu",
    borderRadius: "50px",
    textTransform: "capitalize",
    display: "inline-block",
    textAlign: "center",
    lineHeight:" 38px",
    "& b":{
      display:"none"
    },
    "&:hover":{
      color:"#fff"
    },
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      height: "34px",
      fontSize: "14px",
      lineHeight: "34px",
      minWidth: "34px",
    },
  },
  userdetaildiv:{
    display:"inline-block",
    "& button":{
      ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
        margin: "6px 0 0" ,
      },
    },
    "& div div.MuiPaper-root.MuiPaper-elevation1":{
      ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
        marginLeft: "-80px" ,
      },
    }
  },
  contryflagdiv:{
    display:"inline-block",
    marginLeft:"20px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      display: "none",
    },
  },
  notificationdiv:{
    display:"inline-block",
    position:"relative",
    marginLeft:"20px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      marginLeft:"5px",
    },
    ['@media (max-width:959px)']: { // eslint-disable-line no-useless-computed-key
      display:"none",
    },
  },
  usersigninanker:{
    fontSize: "16px",
    color:"#fff",
    textDecoration:"none",
    display:"block",
    padding:"10px 20px",
    "&:hover":{
      color:"#fff",
      background:"#252f5a",
    }
  },
  contryflag:{
    width:"40px", 
    height:"40px",
    borderRadius:"50px",
    verticalAlign: "middle"
  },
  listsign:{
    padding:"0",
    margin:"0",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      display:"block",
      width: "850px",
    },
  },
  listItemsign:{
    float: "left",
    margin: "0",
    display: "block",
    padding: "0",
    width: "auto",
  },
  menusignin:{
    padding: "8px 20px",
    color: "#9ba7cd",
    fontSize: "16px",
    borderRadius:"0px",
    textShadow: "1px 1px 1px #fff",
    fontFamily: "ubuntu",
    fontWeight: "bold",
    "&:hover":{
      background:"linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07) !important",
      color:"#fff"
    },
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      padding: "4px 15px",
      fontSize: "14px",
    },
  },
  signinheadersidebar:{
    position: "fixed",
    right: "0",
    top: "0",
    height: "100%",
    background: "#252f5a",
    minWidth: "280px",
  },
  listheadsidebartop:{
    background:"#070b28",
    padding:"10px 15px",
    "& h5":{
      display:"block",
      margin:"0",
      fontSize: "16px",
      borderRadius:"0px",
      fontFamily: "ubuntu",
      padding:"10px 0px",
      color:"#fff",
      fontWeight:"normal"
    },
    "& h6":{
      display:"block",
      margin:"0",
      fontWeight:"normal",
      fontSize: "16px",
      color:"#a1aed4",
      borderRadius:"0px",
      fontFamily: "ubuntu",
      padding:"0px 0px"
    },
  },
  listheadsidebar:{

  },
  listheadsidebarlogo:{
    margin:"0px auto",
    display:"block",
  },
  listheadsidebaritem:{
    padding:"5px 0px",
  },
  listheadsidebaranker:{
    fontFamily: "ubuntu",
    textTransform:"capitalize",
    display:"block",
    width:"100%",
    textAlign:"left",
    fontSize:"16px",
    color:"#a1aed4",
    "& i.fa":{
      marginRight:"5px"
    },
    "&:hover":{
      background:"none",
      color:"#000"
    }
  },


};

export default headerStyle;

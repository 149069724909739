import React from 'react';
import {TextField} from '@material-ui/core';
import {withFormsy} from 'formsy-react';
import _ from '../../../@lodash';

function TextFieldFormsy(props)
{
    const importedProps = _.pick(props, [
        'autoComplete',
        'autoFocus',
        'children',
        'className',
        'defaultValue',
        'disabled',
        'FormHelperTextProps',
        'fullWidth',
        'id',
        'InputLabelProps',
        'inputProps',
        'InputProps',
        'inputRef',
        'label',
        'multiline',
        'name',
        'onBlur',
        'onChange',
        'onFocus',
        'placeholder',
        'required',
        'rows',
        'min',
        'rowsMax',
        'select',
        'SelectProps',
        'type',
        'variant',
        'readonly'
    ]);

    const errorMessage = props.errorMessage;
    const value = props.value || '';
    // const [validate, setValidate] = React.useState({minLength: false, maxLength: false});
    function changeValue(event)
    {
        props.setValue(event.currentTarget.value);
        if ( props.onChange )
        {
            props.onChange(event);
        }
        //textFieldValidator(event);
    }
    var isObject = function(a) {
        return (!!a) && (a.constructor === Object);
    };
    // function textFieldValidator(event){
    //     if(props.validations != null && isObject(props.validations) && Object.keys(props.validations).length > 0) {
    //         Object.keys(props.validations).map((key) => {
    //             if(key === "minLength"){
    //                 if(event.target.value.length >= props.validations[key]){
    //                     validate.minLength = true;
    //                     setValidate(validate);
    //                 }else{
    //                     validate.minLength = false;
    //                     setValidate(validate);
    //                 }
    //             }else if(key === "maxLength"){
    //                 if(event.target.value.length <= props.validations[key]){
    //                     validate.maxLength = true;
    //                     setValidate(validate);
    //                 }else{
    //                     validate.maxLength = false;
    //                     setValidate(validate);
    //                 }
    //             }else if(key === "isLength"){
    //                 if(event.target.value.length === props.validations[key]){
    //                     validate.isLength = true;
    //                     setValidate(validate);
    //                 }else{
    //                     validate.isLength = false;
    //                     setValidate(validate);
    //                 }
    //             }else if(key === "upperCase"){
    //                 alert("UPPER:" + props.validations[key]);
    //                 alert("J:" + JSON.stringify(props));
    //                 if(props.validations[key]){
    //                     validate.isLength = true;
    //                     setValidate(validate);
    //                 }else{
    //                     validate.isLength = false;
    //                     setValidate(validate);
    //                 }
    //             }else if(key === "isEmail"){
    //                 if(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i.test(event.target.value)){
    //                     validate.isEmail = true;
    //                     setValidate(validate);
    //                 }else{
    //                     validate.isEmail = false;
    //                     setValidate(validate);
    //                 }
    //             }else if(key === "matchRegexp"){
    //                 if(new RegExp(props.validations[key]).test(event.target.value)){
    //                     validate.matchRegexp = true;
    //                     setValidate(validate);
    //                 }else{
    //                     validate.matchRegexp = false;
    //                     setValidate(validate);
    //                 }
    //             }
    //         });
    //     }
    // }
    
    // var isMinMaxDisplayed = false;
    return (
        <div id="main_text">
            <TextField
                {...importedProps}
                onChange={changeValue}
                value={value}
                error={Boolean(errorMessage)}
                helperText={errorMessage}
            />
          
            {(props.behindContainer) ? props.behindContainer : ""}
            {
            (props.validations != null && isObject(props.validations) && Object.keys(props.validations).length > 0) ?
            (<div id="validation_info">
                <ul>
                    {
                        Object.keys(props.validations).map((key) => {
                            if(props.errorMessages.indexOf(props.validationErrors[key]) === -1 && props.value != null && props.value.length > 0){
                                return (<li id={key} class="valid">{props.validationErrors[key]}</li>)
                            }else{
                                return (<li id={key} class="invalid">{props.validationErrors[key]}</li>)
                            }
                            
                        })
                    }
                </ul>
            </div>)
            : 
            ((props.validations !== null || props.validationErrors.length > 0) ?
            (<div id="validation_info">
                <ul>
                    {
                        Object.keys(props.validationErrors).map((key) => {
                            if(props.errorMessages.indexOf(props.validationErrors[key]) === -1 && props.value != null && props.value.length > 0){
                                return (<li id={key} class="valid">{props.validationErrors[key]}</li>)
                            }else{
                                return (<li id={key} class="invalid">{props.validationErrors[key]}</li>)
                            }
                            
                        })
                    }
                </ul>
            </div>)
            :
            "")    
        } 
        </div>
    );
}
/*
 
            InputLabelProps={{
                className: "testlabel" 
              }}
*/
export default React.memo(withFormsy(TextFieldFormsy));

// import * as UserActions from './user.actions';
//import * as Actions from '../../../src/store/actions';
import jwtService from '../../../services/jwtService';

export const REGISTER_ERROR = 'REGISTER_ERROR';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
 

export function submitRegister({username, password, email, mobile, country_id, recaptcha},SectionSignupbox)
{
    
    return (dispatch) =>
        jwtService.createUser({
            username,
            password,
            email,
            country_id,
            name: username,
            mobile: mobile,
            recaptcha,
        })
            .then((user) => { 

                    //dispatch(UserActions.setUserData(user)); 
                    //SectionSignupbox("stop"); 
                    return dispatch({
                        type: REGISTER_SUCCESS,
                        payload: user 
                    });
                }
            )
            .catch(error => { 
                return dispatch({
                    type   : REGISTER_ERROR,
                    payload: error
                });
            });
}

import { container } from "../../casino-kit-react.js";
import Background5 from "../../img/verify.png";
import Background6 from "../../img/verifyed.png";
const tabsStyle = {
    signinnerpagebox:{
        background:"#000",
        padding:"0px 20px 0px",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            padding:"0px 0px 0px",
          },
    },
    signinnerpageboxin:{
        background:"#131212",
        margin:"0px 0px 50px",
        borderRadius:"10px"
    },
    section: {
        background: "#EEEEEE",
        padding: "70px 0"
    },
    accountsheads:{
        color:"#fff !important",
        marginTop: "0",
        padding: "0px 10px", 
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key 
            padding: "20px 10px 0px", 
          },
        "& a":{
            float:"right",
            width:"230px",
            marginTop: "-10px",
            ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
                float: "none",
                width: "180px",
                margin: "-80px auto 0px",
                fontSize: "14px",
                padding: "5px 10px",
                lineHeight: "30px",
              },
        },
        
    },
    accountsbox:{
        background: "#252f5a",
        padding:"30px 10px",
        borderRadius:"10px",
        "& h3":{
            color:"#f44336",
            fontSize:"22px",
            fontFamily: "ubuntu",
        },
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            padding:"20px 10px",
        },
    },
    userprobox:{ 
        width:"80%",
        fontFamily: "ubuntu",
        margin:"0px auto",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"100%",
        },
        ['@media (max-width:480px)']: { // eslint-disable-line no-useless-computed-key
            width:"80%",
            margin:"0px auto",
        },
        "& p":{
            margin:"0px",
            color:"#bdbdbd",
            fontSize:"18px",
            textAlign:"right",
            float:"left",
            padding:"10px 10px 10px 0px"
        },
        "& span":{
            display:"block",
            textAlign: "left",
            fontSize:"18px",
            color:"#fff",
            padding:"10px 0px"
        },
        "& a":{
            textAlign: "center",
            color:"#fff",
            display:"inline-block",
            marginRight:"10px",
        },
        "& a span":{
            padding:"0px",
        },
    },
    changebankacc:{
        fontFamily: "ubuntu",
        color:"#fff",
        textAlign:"left",
        fontSize:"16px",
    },
    editprobox:{
        width:"80%",
        display:"block",
        margin:"0px auto",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"100%",
        },
        "& input":{
            background:"none",
            color:"#fff",
            width:"100%",
            fontSize: "16px",
            marginBottom: "20px",
            borderBottom:"1px solid #a1aed4",
        },
        "& label":{ 
            color:"#bdbdbd", 
            fontSize:"18px",
            fontFamily:"ubuntu",
        },
        "& div":{ 
            width:"100%", 
        },
        "& fieldset":{ 
            border:"0px", 
        },
        "& .MuiFormLabel-root.Mui-focused":{
            color:"#bdbdbd",
        },
        "& a":{
            textAlign: "center",
            color:"#fff",
            display:"inline-block",
            marginRight:"10px",
        },
        "& a span":{
            padding:"0px",
        },
        "& strong":{
            display:"block",
            color:"#fff",
            paddingBottom:"10px",
            textAlign:"right",
            fontFamily: "ubuntu",
        },
        "& p":{
            display:"block",
            color:"#fff",
            fontFamily: "ubuntu",
            lineHeight: "38px",
            fontSize: "17px",
            margin:"0px"
        },
    },
    list:{
        width:"100%",
        display:"block"
      },
      listItem:{
          width:"100%",
          paddingTop:"15px",
          paddingBottom:"15px",
          display:"block",
          color:"#a1aed4",
          fontFamily:"ubuntu",
          fontSize:"18px",
          "& a":{
              margin:"0px",
          },
          "& p":{
              margin:"0px",
          },
          "&:nth-child(even)":{
              background:"#364275"
          },
          "&:nth-child(odd)":{
              background:"none"
          }
      },
    changepasbox:{
        width:"400px",
        display:"none",
        margin:"0px auto",
        "& input":{
            background:"none",
            color:"#fff",
            width:"100%",
            fontSize: "16px",
            marginBottom: "20px",
            borderBottom:"1px solid #a1aed4",
        },
        "& label":{ 
            color:"#bdbdbd", 
            fontSize:"18px",
            fontFamily:"ubuntu",
        },
        "& div":{ 
            width:"100%", 
        },
        "& fieldset":{ 
            border:"0px", 
        },
        "& .MuiFormLabel-root.Mui-focused":{
            color:"#bdbdbd",
        },
        "& a":{
            textAlign: "center",
            color:"#fff",
            display:"inline-block",
            marginRight:"10px",
        },
        "& a span":{
            padding:"0px",
        },
        "& p.MuiFormHelperText-root":{
            marginTop: "-10px",
            marginBottom: "10px",
            marginLeft: "0",
        },
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"100%",
        },
        ['@media (max-width:480px)']: { // eslint-disable-line no-useless-computed-key
            marginRight: "-15px",
        },
    },
    countrydopdown:{
        borderBottom:"1px solid #a1aed4",
        marginBottom:"20px",
        marginTop:"0px !important",
        background:"none",
        "& .MuiSelect-select":{
            background: "none",
            paddingTop: "14px",
            paddingBottom: "11px",
        }
    },
    selectboxx:{
        marginTop:"20px",
        color:"#bdbdbd",
        "&:value":{
            color:"#bdbdbd",
        },
    },
    depositlistwarp:{
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            overflow:"auto",  
            width:"100%",
          }, 
    },
    depositlistw:{
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"1000px",
          }, 
    },
    accountstableewrap:{
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"100%",
            overflow:"auto",
        },
    },
    accountstablee:{
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"990px"
        },
    },
    addbankaccoutbox:{
        width:"80%",
        margin:"0px auto",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"100%",
            paddingTop: "30px",
            marginRight: "-10px",
        },
        "& input":{
          background:"none",
          color:"#fff",
            width:"100%",
            fontSize: "16px", 
            borderBottom:"1px solid #a1aed4",
            marginBottom:"10px",
        },
        "& label":{
            color:"#bdbdbd",
            fontSize:"18px",
            fontFamily:"ubuntu",
        },
        "& .MuiFormLabel-root.Mui-focused":{
            color:"#bdbdbd",
        },
        "& div":{
          width:"100%",
        },
        "& fieldset":{
            color:"#fff", 
            border:"0px",
          },
        "& select":{
            background:"none",
            marginBottom:"20px",
            lineHeight:"40px",
            height:"40px",
            width:"100%",
            border:"0px",
            fontSize: "16px",
            borderBottom:"1px solid #a1aed4"
        },
    },
    ifscbtn:{
        textAlign:"right",
        color:"#ff344d !important",
        padding:"0px",
        display:"block"
    },
    checkboxAndRadio:{
        paddingTop:"20px",
        "& span":{
            fontSize:"18px",
        },
        "& label":{
            marginTop:"-10px",
        }
    },
    depositformbtni:{
        borderTop:"1px solid #696969",
        marginTop:"20px",
        paddingTop:"20px",
        textAlign:"center",
        "& button":{
            float:"none",
            display:"inline-block"
        },
        "& a":{
            float:"none",
            display:"inline-block"
        }
    },
    depositbtn:{
        color: "#fff !important",
        border: "0px",
        cursor: "pointer",
        padding: "4px 30px",
        float:"left",
        fontSize: "16px",
        boxShadow: "0px 0px 0px",
        transition: "all 0.3s ease-in",
        fontWeight: "600",
        borderRadius: "50px",
        lineHeight:"42px",
        textTransform: "capitalize",
        backgroundImage: "linear-gradient(to left, #ff344d, #d2273c)",
        margin: "10px 5px 0px",
        display: "block",
        "& span":{
            padding:"0px",
        }
    },
    addaccountform:{
        display:"none"
    },
    addbtn:{
        color: "#fff !important",
        border: "0px",
        cursor: "pointer",
        padding: "4px 30px",
        fontSize: "16px",
        boxShadow: "0px 0px 0px",
        transition: "all 0.3s ease-in",
        fontWeight: "600",
        borderRadius: "50px",
        lineHeight:"42px",
        textTransform: "uppercase",
        backgroundImage: "linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07) !important",
        margin: "10px 5px 0px",
        display: "block",
        "& span":{
            padding:"0px",
            color: "rgb(120,50,5) !important",
        }
    },
    depositbtn2:{
        color: "#fff !important",
        border: "0px",
        cursor: "pointer",
        padding: "4px 30px",
        fontSize: "16px",
        boxShadow: "0px 0px 0px",
        transition: "all 0.3s ease-in",
        fontWeight: "600",
        borderRadius: "50px",
        lineHeight:"42px",
        textTransform: "uppercase",
        backgroundImage: "linear-gradient(to left, #ff344d, #d2273c)",
        margin: "10px 5px 0px",
        display: "block",
        "& span":{
            padding:"0px",
        }
    },
    container,
    textCenter: {
        textAlign: "center"
    },
    userprowrapp:{
        width:"100%",
        margin:"0px auto",
        ['@media (max-width:1100px)']: { // eslint-disable-line no-useless-computed-key
            width:"100%",
          },
    },
    verify:{
        backgroundImage:"url(" + Background5 + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "100%",
          float:"right",
          width:"24px",
          height:"24px",
          cursor: "pointer"
    },
    verifyed:{
        backgroundImage:"url(" + Background6 + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "100%",
          width:"24px",
          float:"right",
          height:"24px",
    },
};

export default tabsStyle;

const customTabsStyle = {
  cardTitle: {
    float: "left",
    padding: "10px 10px 10px 0px",
    lineHeight: "24px"
  },
  cardTitleRTL: {
    float: "right",
    padding: "10px 0px 10px 10px !important"
  },
  displayNone: {
    display: "none !important"
  },
  tabsRoot: {
    minHeight: "unset !important",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      overflow: "auto",
    },
  },
  tabRootButton: {
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    fontFamily: 'Open Sans',
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    padding: "15px 25px", 
    lineHeight: "20px",
    border: "0 ",
    color: "#fff !important", 
    borderRight:"1px solid #0f0f0f", 
    borderBottom:"1px solid #0f0f0f", 
    fontWeight: "normal",
    fontSize: "14px",
    textTransform: "uppercase",
    margin: "0px",
    "&:last-child": {
      marginLeft: "0px",
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
       padding: "10px 20px",
    },
  },
  tabRootButtonSport: {
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    fontFamily: 'Open Sans',
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    padding: "15px 25px", 
    lineHeight: "20px",
    border: "0 ",
    color: "#fff !important", 
    borderRight:"1px solid #0f0f0f", 
    borderBottom:"1px solid #0f0f0f", 
    fontWeight: "600",
    fontSize: "14px",
    textTransform: "uppercase",
    margin: "0px",
    "&:last-child": {
      marginLeft: "0px",
    },
    "& i": {
      display: "block",
      fontSize: "26px",
      marginBottom:"5px",
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
       padding: "10px 20px",
    },
  },
  tabRootButtonFirst: {
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    fontFamily: 'Open Sans',
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    padding: "10px 25px", 
    lineHeight: "20px",
    border: "0 ",
    color: "#fff !important", 
    borderRight:"1px solid #2b2b2b", 
    borderBottom:"1px solid #2b2b2b", 
    fontWeight: "normal",
    fontSize: "14px",
    textTransform: "uppercase",
    margin: "0px",
    "&:last-child": {
      marginLeft: "0px",
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
       padding: "8px 20px",
    },
  },
  tabRootButtonThree: {
    minHeight: "unset !important",
    minWidth: "unset !important",
    // width: "unset !important",
    fontFamily: 'Open Sans',
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    padding: "10px 25px", 
    lineHeight: "20px",
    border: "0 ",
    color: "#fff !important", 
    borderRight:"1px solid #2b2b2b", 
    borderBottom:"1px solid #2b2b2b", 
    fontWeight: "normal",
    fontSize: "14px",
    textTransform: "uppercase",
    margin: "0px",
    width:"25% !important",
    "&:last-child": {
      marginLeft: "0px",
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
       padding: "8px 20px",
    },
  },
  tabRootButtonSecond: {
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    fontFamily: 'Open Sans',
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    padding: "8px 25px", 
    lineHeight: "20px",
    border: "0 ",
    color: "#fff !important", 
    borderRight:"1px solid #0f0f0f", 
    borderBottom:"1px solid #0f0f0f", 
    fontWeight: "normal",
    fontSize: "12px",
    textTransform: "capitalize",
    margin: "0px",
    "&:last-child": {
      marginLeft: "0px",
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
       padding: "5px 20px",
    },
  },
  tabSelected: {
    backgroundColor: "#2b2b2b", 
    borderRight:"1px solid #0f0f0f", 
    borderBottom:"1px solid #2b2b2b",
    transition: "0.2s background-color 0.1s"
  },
  tabSelectedSport: {
    backgroundColor: "#2b2b2b", 
    borderRight:"1px solid #0f0f0f", 
    borderBottom:"1px solid #2b2b2b",
    transition: "0.2s background-color 0.1s"
  },
  tabSelectedFirst: { 
    backgroundColor: "none", 
    borderRight:"1px solid #2b2b2b", 
    padding: "10px 25px 9px", 
    borderBottom:"2px solid #2fe70c",
    transition: "0.2s background-color 0.1s"
  },
  tabSelectedThree: { 
    backgroundColor: "none", 
    borderRight:"1px solid #2b2b2b", 
    padding: "10px 25px 9px", 
    borderBottom:"2px solid #2fe70c",
    transition: "0.2s background-color 0.1s",

  },
  tabSelectedSecond: {
    backgroundColor: "#353535", 
    borderRight:"1px solid #0f0f0f", 
    borderBottom:"1px solid #353535",
    transition: "0.2s background-color 0.1s"
  },
  tabWrapper: {
    display: "inline-block",
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    "& > svg": {
      verticalAlign: "middle",
      margin: "-1.55px 5px 0 0 !important"
    },
    "&,& *": {
      letterSpacing: "normal !important"
    }
  }
};

export default customTabsStyle;

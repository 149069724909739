/*eslint-disable*/
import React, {useEffect, useRef, useState} from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton"; 
// react components for routing our app without refresh
import { Link, withRouter } from "react-router-dom";
// @material-ui/core components
import {Button, Divider, Typography, InputAdornment, Icon} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// core components
//Third Party Authentication
import {FacebookLogin, GoogleLogin} from "../../../@casino"

import Formsy from 'formsy-react';
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import menuDb from "../data/menu.js";
import logo from "../assets/img/logo.png";
import Hometab from "../pages/hometab";
import GridItem from "../elements/Grid/GridItem";
import GridContainer from "../elements/Grid/GridContainer";
import styles from "../assets/jss/components/headerLinksStyle.js";
import stylesr from "../assets/jss/components/headerLinkstopStyle"; 

import * as authActions from '../../../auth/casino/actions';
import {useDispatch, useSelector} from 'react-redux';
import {TextFieldFormsy, CheckboxFormsy} from '../../../@casino';
import secloginboxDb from "../data/secloginbox.js";
//import { useTranslation } from 'react-i18next'
const history = createBrowserHistory()
let classicModal = null;
let setClassicModal = null;

 
function Dlimg(props) {

    const dispatch = useDispatch();
      
    
  //  const { t } = useTranslation()
    let currentStyle = styles;
    const useStyles = makeStyles(styles);

    const classes = useStyles();
  [classicModal, setClassicModal] = React.useState(false);
  return ( <div>
    
      <div key="5565">
    <Dialog
    classes={{
      root: classes.center ,
      paper: classes.modal 
      
    }}
    key="adsdad"
    open={classicModal}
     
    keepMounted
    onClose={() => setClassicModal(false)}
    aria-labelledby="classic-modal-slide-title"
    aria-describedby="classic-modal-slide-description"
>

  <DialogTitle
      id="classic-modal-slide-title"
      disableTypography
      className={classes.modalHeader }
  >
    <IconButton
        className={classes.modalCloseButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => setClassicModal(false)}
    >
      <Close className={classes.modalClose} />
    </IconButton>
     
  </DialogTitle>
 
  <DialogContent
      id="classic-modal-slide-description"
      className={classes.modalBody }
  >    
      
  {props.getKYCData()}
  {(dlData.front_scan !== undefined) ? dlData.front_scan : dl}
  {props.dlData.front_scan}
  sdfsf
  </DialogContent>
   
</Dialog>
</div>
 
</div>
  );
}
Dlimg.propTypes = {
  openPopup: function(isState){ 
      if(isState === "login"){
        setClassicModal(true);
      } 
    }

};

Dlimg.openPopup = function(isState){ 
    if(isState === "login"){
    setClassicModal(true);
    } 
}

export default Dlimg;



const withdrawDb = {
    en: [
            {
                'tableheader':[
                    {
                        'id': 'stmhdr_1',
                        'xs': 2,
                        'field_name': 'Request Number',
                        'visible': 'both',
                    },{
                        'id': 'stmhdr_4',
                        'xs': 3,
                        'field_name': 'Request Account',
                        'visible': 'desktop',
                        'expandable': true
                    },{
                        'id': 'stmhdr_5',
                        'xs': 2,
                        'field_name': 'Amount',
                        'visible': 'both',
                    },{
                        'id': 'stmhdr_6',
                        'xs': 3,
                        'field_name': 'Request On',
                        'visible': 'desktop',
                        'expandable': true
                    },{
                        'id': 'stmhdr_7',
                        'xs': 2,
                        'field_name': 'Status',
                        'visible': 'desktop',
                        'expandable': true
                    },
                ]
            }
    ],
    tl: [
        {
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 2,
                    'field_name': 'అభ్యర్థన సంఖ్య',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 3,
                    'field_name': 'ఖాతాను అభ్యర్థించండి',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 2,
                    'field_name': 'మొత్తం',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 3,
                    'field_name': 'అభ్యర్థించండి',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 2,
                    'field_name': 'స్థితి',
                    'visible': 'desktop',
                    'expandable': true
                },
            ]
        }
    ],
    hi: [
            {
            'tableheader':[
                    {
                        'id': 'stmhdr_1',
                        'xs': 2,
                        'field_name': 'Request Number',
                        'visible': 'both',
                    },{
                        'id': 'stmhdr_4',
                        'xs': 3,
                        'field_name': 'Request Account',
                        'visible': 'desktop',
                        'expandable': true
                    },{
                        'id': 'stmhdr_5',
                        'xs': 2,
                        'field_name': 'Amount',
                        'visible': 'both',
                    },{
                        'id': 'stmhdr_6',
                        'xs': 3,
                        'field_name': 'Request On',
                        'visible': 'desktop',
                        'expandable': true
                    },{
                        'id': 'stmhdr_7',
                        'xs': 2,
                        'field_name': 'Status',
                        'visible': 'desktop',
                        'expandable': true
                    },
                ]
            }
    ],
    categories: [
        {
            'heading':"Net Banking",
            'title': 'No Bank Account',
            'pragraph':"Please add your bank account information before proceeding.",
            'btn':"Add Bank Details",  
        } 
    ],
    categoriesHi: [
        {
            'heading':"नेट बैंकिंग",
            'title': 'कोई बैंक खाता नहीं',
            'pragraph':"आगे बढ़ने से पहले कृपया अपने बैंक खाते की जानकारी जोड़ें।",
            'btn':"बैंक विवरण जोड़ें",  
        },
    ]
}
export default withdrawDb;

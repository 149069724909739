


const promotionDb = {
    en: [
        {
            'title':"Promotion History",
            'tagName': 'tab1',
            'tablehead':[
                { 
                    'tablethsno'  : 'S.No', 
                    'tablethname'  : 'Name',  
                    'tablethstarttime'  : 'Start Time',  
                    'tablethendtime'  : 'End Time',  
                    'tablethamoount'  : 'Amount', 
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'Signup bonus- new ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'Signup bonus- new ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'Signup bonus- new ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ]
        },
        {
            'title':"Rewards",
            'tagName': 'tab2',
            'tablehead':[
                { 
                    'tablethsno'  : 'S.No', 
                    'tablethname'  : 'Name',  
                    'tablethstarttime'  : 'Start Time',  
                    'tablethendtime'  : 'End Time',  
                    'tablethamoount'  : 'Amount', 
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'Signup bonus- new ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ]
        },

    ],
    tm: [
        {
            'title':"விளம்பர வரலாறு",
            'tagName': 'tab1',
            'tablehead':[
                {  
                    'tablethsno': 'எஸ். இல்லை',
                    'tablethname': 'பெயர்',
                    'tablethstarttime': 'தொடக்க நேரம்',
                    'tablethendtime': 'முடிவு நேரம்',
                    'tablethamoount': 'தொகை',
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'பதிவுபெறும் போனஸ்- புதியது',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'பதிவுபெறும் போனஸ்- புதியது ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'பதிவுபெறும் போனஸ்- புதியது',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ]
        },
        {
            'title':"வெகுமதிகள்",
            'tagName': 'tab2',
            'tablehead':[
                {   
                    'tablethsno': 'எஸ். இல்லை',
                    'tablethname': 'பெயர்',
                    'tablethstarttime': 'தொடக்க நேரம்',
                    'tablethendtime': 'முடிவு நேரம்',
                    'tablethamoount': 'தொகை',
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'பதிவுபெறும் போனஸ்- புதியது ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ]
        },

    ],
    kn: [
        {
            'title':"ಪ್ರಚಾರ ಇತಿಹಾಸ",
            'tagName': 'tab1',
            'tablehead':[
                {  
                    'tablethsno': 'S. ಇಲ್ಲ',
                    'tablethname': 'ಹೆಸರು',
                    'tablethstarttime': 'ಪ್ರಾರಂಭ ಸಮಯ',
                    'tablethendtime': 'ಅಂತಿಮ ಸಮಯ',
                    'tablethamoount': 'ಮೊತ್ತ',
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'ಸೈನ್ ಅಪ್ ಬೋನಸ್- ಹೊಸದು ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'ಸೈನ್ ಅಪ್ ಬೋನಸ್- ಹೊಸದು ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'ಸೈನ್ ಅಪ್ ಬೋನಸ್- ಹೊಸದು ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ]
        },
        {
            'title':"ಪ್ರತಿಫಲಗಳು",
            'tagName': 'tab2',
            'tablehead':[
                {  
                    'tablethsno': 'S. ಇಲ್ಲ',
                    'tablethname': 'ಹೆಸರು',
                    'tablethstarttime': 'ಪ್ರಾರಂಭ ಸಮಯ',
                    'tablethendtime': 'ಅಂತಿಮ ಸಮಯ',
                    'tablethamoount': 'ಮೊತ್ತ',
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'ಸೈನ್ ಅಪ್ ಬೋನಸ್- ಹೊಸದು',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ]
        },

    ],
    ne: [
        {
            'title':"पदोन्नति ईतिहास",
            'tagName': 'tab1',
            'tablehead':[
                {   
                    'tablethsno': 'S.No',
                    'tablethname': 'नाम',
                    'tablethstarttime': 'सुरु समय',
                    'tablethendtime': 'अन्त समय',
                    'tablethamoount': 'रकम',
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'साइन अप बोनस - नयाँ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'साइन अप बोनस - नयाँ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'साइन अप बोनस - नयाँ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ]
        },
        {
            'title':"पुरस्कार",
            'tagName': 'tab2',
            'tablehead':[
                {  
                    'tablethsno': 'S.No',
                    'tablethname': 'नाम',
                    'tablethstarttime': 'सुरु समय',
                    'tablethendtime': 'अन्त समय',
                    'tablethamoount': 'रकम',
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'साइन अप बोनस - नयाँ ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ]
        },

    ],
    tl: [
        {
            'title':"ప్రమోషన్ చరిత్ర",
            'tagName': 'tab1',
            'tablehead':[
                {   
                    'tablethsno': 'S.No',
                    'tablethname': 'పేరు',
                    'tablethstarttime': 'ప్రారంభ సమయం',
                    'tablethendtime': 'ముగింపు సమయం',
                    'tablethamoount': 'మొత్తం',
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ]
        },
        {
            'title':"బహుమతులు",
            'tagName': 'tab2',
            'tablehead':[
                {  
                    'tablethsno': 'S.No',
                    'tablethname': 'పేరు',
                    'tablethstarttime': 'ప్రారంభ సమయం',
                    'tablethendtime': 'ముగింపు సమయం',
                    'tablethamoount': 'మొత్తం',
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'సైన్అప్ బోనస్- క్రొత్తది ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'INR 1,000.00'
                },
            ]
        },

    ],
    hi: [
        {
            'title':"पदोन्नति का इतिहास",
            'tagName': 'tab1',
            'tablehead':[
                { 
                    'tablethsno'  : 'नम्बर', 
                    'tablethname'  : 'नाम',  
                    'tablethstarttime'  : 'समय शुरू',  
                    'tablethendtime'  : 'अंतिम समय',  
                    'tablethamoount'  : 'रकम', 
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'साइनअप बोनस- नया ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'name'  : 'साइनअप बोनस- नया ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'name'  : 'साइनअप बोनस- नया ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
            ]
        },
        {
            'title':"पुरस्कार",
            'tagName': 'tab2',
            'tablehead':[
                { 
                    'tablethsno'  : 'नम्बर', 
                    'tablethname'  : 'नाम',  
                    'tablethstarttime'  : 'समय शुरू',  
                    'tablethendtime'  : 'अंतिम समय',  
                    'tablethamoount'  : 'रकम', 
                },
            ],
            'promessages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'name'  : 'साइनअप बोनस- नया ',
                    'startdate'  : '2019-12-20 ',
                    'enddate'  : '2019-12-29 ',
                    'amount' :'रूपया 1,000.00'
                },
            ]
        },

    ]
}
export default promotionDb;

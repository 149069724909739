import * as UserActions from './user.actions';
//import * as Actions from '../../../src/store/actions';
import jwtService from '../../../services/jwtService';

export const PROFILESETUP_ERROR = 'PROFILESETUP_ERROR';
export const PROFILESETUP_SUCCESS = 'PROFILESETUP_SUCCESS';
 

export function submitProfilesetup({username, password, website_type_id,final_sub_domain, membership_plan_id, membership_plan_duration_id, domain_type, plan_id, domain, subdomain, currency_id, logo, support_email,  support_contact_number, support_address},props, existingData, finalDomainName)
{
    if(subdomain != null && subdomain.length > 0 && finalDomainName != null && finalDomainName.length > 0){
        subdomain = subdomain + "." + finalDomainName;
    }
    return (dispatch) =>
        jwtService.createUserProfileSetup({ 
            username: existingData.username, 
            password: existingData.password, 
            website_type_id, 
            membership_plan_id, 
            membership_plan_duration_id,
            domain_type, 
            domain: ((domain_type==="subdomain")?(subdomain):domain),
            currency_id, 
            logo, 
            support_email,  
            support_contact_number, 
            support_address
        })
            .then((user) => { 
                 //  dispatch(UserActions.setUserDataProfilesetup(user));   
                    UserActions.setWlUserData(user.data); 
                    return dispatch({
                        type: PROFILESETUP_SUCCESS,
                        payload: user.data
                        
                    });
                }
            )
            .catch(error => { 
                return dispatch({
                    type   : PROFILESETUP_ERROR,
                    payload: error
                });
            });
}

export function submitProfileSettings(settingData, props, existingData)
{
    return (dispatch) =>
        jwtService.updateUserSecuritySettings({ 
            username: existingData.username, 
            password: existingData.password, 
            user_setting: JSON.stringify(settingData)
        })
            .then((user) => { 
                 //  dispatch(UserActions.setUserDataProfilesetup(user));   
                    UserActions.setWlUserData(user.data); 
                    return dispatch({
                        type: PROFILESETUP_SUCCESS,
                        payload: user.data
                        
                    });
                }
            )
            .catch(error => { 
                return dispatch({
                    type   : PROFILESETUP_ERROR,
                    payload: error
                });
            });
}

export function submitThemeSettings({cms_theme_id}, props, existingData)
{
    return (dispatch) =>
        jwtService.updateUserThemeSettings({ 
            username: existingData.username, 
            password: existingData.password, 
            cms_theme_id: cms_theme_id
        })
            .then((user) => { 
                    UserActions.setWlUserData(user.data); 
                    return dispatch({
                        type: PROFILESETUP_SUCCESS,
                        payload: user.data
                        
                    });
                }
            )
            .catch(error => { 
                return dispatch({
                    type   : PROFILESETUP_ERROR,
                    payload: error
                });
            });
}

export function submitBankSettings({account_name,account_number,ifsc_code,bank_name,bank_branch}, props, existingData)
{
    return (dispatch) =>
        jwtService.updateUserBankSettings({ 
            username: existingData.username, 
            password: existingData.password, 
            account_name: account_name,
            account_number: account_number,
            ifsc_code: ifsc_code,
            bank_name: bank_name,
            bank_branch: bank_branch
        })
            .then((user) => { 
                    UserActions.setWlUserData(user.data); 
                    return dispatch({
                        type: PROFILESETUP_SUCCESS,
                        payload: user.data
                        
                    });
                }
            )
            .catch(error => { 
                return dispatch({
                    type   : PROFILESETUP_ERROR,
                    payload: error
                });
            });
}

export function submitModuleSettings({main_data}, props, existingData)
{

    return (dispatch) =>
        jwtService.updateUserModuleSettings({ 
            username: existingData.username, 
            password: existingData.password, 
            user_modules: main_data
        })
            .then((user) => { 
                    UserActions.setWlUserData(user.data); 
                    return dispatch({
                        type: PROFILESETUP_SUCCESS,
                        payload: user.data
                        
                    });
                }
            )
            .catch(error => { 
                return dispatch({
                    type   : PROFILESETUP_ERROR,
                    payload: error
                });
            });
}

export function submitPaymentSettings({payment_type,coupon_code,api_response,user_gateway_id}, props, existingData)
{

    return (dispatch) =>
        jwtService.updateUserPaymentSettings({ 
            username: existingData.username, 
            password: existingData.password,
            payment_type, 
            user_gateway_id,
            response: JSON.stringify(((api_response) ? api_response : {coupon_code}))
        })
            .then((user) => { 
                    //UserActions.setWlUserData(user.data); 
                    return dispatch({
                        type: PROFILESETUP_SUCCESS,
                        payload: user.data
                        
                    });
                }
            )
            .catch(error => { 
                return dispatch({
                    type   : PROFILESETUP_ERROR,
                    payload: error
                });
            });
}
import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons 
// core components 
import Card from "../elements/Card/Card.js";
 
// import image4 from "../assets/img/sliderr4.jpg";
// import image5 from "../assets/img/sliderr5.jpg"; 
// import image7 from "../assets/img/sliderr7.jpg";

import styles from "../assets/jss/components/carouselStyle.js";

const useStyles = makeStyles(styles);
 
export default function SectionCarousel(props) {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  }; 
  return (
    
            <Card carousel className={classes.sllid}>
              <Carousel {...settings}>
               
             { props.response.map((jsondata) => (                        
                             <div key={jsondata.id}>
                                <img src={jsondata.path} className={classes.fullimgwidth+ " slick-image"}  alt={""} /> 
                                </div>
                            ))}
                
              </Carousel>
            </Card>


    
  );
}

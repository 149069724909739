import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons 
// core components 
import Card from "../elements/Card/Card.js"; 
import jwtService from '../../../services/jwtService' 
import Button from "@material-ui/core/Button";   
import SectionOtpbox from "./SectionOtpBox";  
import SectionSignupbox from "./SectionSignupBox";
import SectionLoginbox from "./SectionLoginBox"; 
import styles from "../assets/jss/components/carouselStyle.js";

const useStyles = makeStyles(styles);
 
export default function SectionCarousel(props) {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true 
  }; 
  return (
    
            <Card carousel className={classes.sllid}>
              <Carousel {...settings}>
               
             { props.response.map((jsondata) => (                        
                             <div key={jsondata.id}>
                                <img src={jsondata.path} className={classes.fullimgwidth + " slick-image"}  alt={""} /> 
                                </div>
                            ))}
                
              </Carousel>
              {(() =>  {
                
                if(jwtService.isAuthenticationLogin() === true){
                  return (
                <div></div>
                )
              }else{
                return(
                  <div className={classes.slidertext + " slidertext"}>
                  <h3><i className="fa fa-inr"></i> 1000,000 Bonus</h3>
                  <h5>+10 Free Spins</h5>
                  <p>Join now and get a bonus boost on your first three deposits, plus 10 free spins on Book of Dead!</p>
                  <Button   
                      className={classes.sliderjoinbtn}
                      onClick={() => {SectionSignupbox.openPopup("signup");}}
                  >
                    Join for Free
                  </Button> 
                  <SectionSignupbox
                                  OtpBox={SectionOtpbox}
                                  LoginBox={SectionLoginbox}
                                  {...props} /> 
                  <p>18+. First 3 deposits only. Bonus amount varies per deposit. Min dep ₹1000 (₹2000 3rd deposit). 10 free spins on 1st deposit. Wagering x35. T&Cs apply. Gamble Responsibly.</p>
                </div>
                )
              }
              })()}   
            </Card>


    
  );
}

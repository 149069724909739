import { container } from "../../casino-kit-react.js";
const tabsStyle = {
    signinnerpagebox:{
        background:"#000",
        padding:"0px 20px 0px",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            padding:"0px 0px 0px",
          },
    },
    signinnerpageboxin:{
        background:"#000",
        margin:"0px 0px 0px",
        borderRadius:"10px", 
    },
    tablewrap:{
        overflow:"auto", 
        borderRadius:"10px",
        border:"1px solid #ffb80c",
        marginBottom:"20px",
        width:"100%",
        "& th":{
            background:"#000",
            padding:"15px", 
            borderLeft:"1px solid #ffb80c",
            borderBottom:"1px solid #ffb80c",
            fontFamily:"ubuntu",
            fontSize:"14px"
        },
        "& th:first-child":{
            borderLeft:"0px",
        },
        "& td":{
            padding:"15px", 
            borderLeft:"1px solid #ffb80c",
            borderColor:"#ffb80c",
            fontFamily:"ubuntu",
            fontSize:"14px"
        },
        "& td:first-child":{
            borderLeft:"0px",
        },
        "& td table th":{
            padding:"5px 10px", 
        },
        "& td table td":{
            padding:"5px 10px", 
        },
        "& a":{
            color:"#FFF"
        },
        "& tr:nth-child(2n)":{
            background: "#2d2d2d",
        },
        "& tr:nth-child(2n+1)":{
            background:"none"
        }
    },
    deposittablee:{
     paddingLeft:"5px !important",
     paddingRight:"5px !important",
     "& h3":{
         paddingLeft:"25px"
     }
    },
    cricleboxwrap:{

    },
    cricleboxmain:{
        width:"19.4% !important",
        float:"left",
        margin:"0 0.2%",
        ['@media (max-width:890px)']: { // eslint-disable-line no-useless-computed-key
          width:"31% !important",
          margin:"0 0.8%",
        }, 
        ['@media (max-width:620px)']: { // eslint-disable-line no-useless-computed-key
          width:"49% !important",
          margin:"0 0.5%",
        }, 
        ['@media (max-width:410px)']: { // eslint-disable-line no-useless-computed-key
          width:"100% !important",
          margin:"0 0%",
        }, 
      },
      boxcenter:{
        position: "absolute",
        top: "50%",
        left: "0%",
        transform: "translate(0%, -50%)",
        width:"100%", 
      },
    criclebox:{
        position: "relative", 
        minHeight: "100px",
        background:"#000",
        border:"1px solid #ffb80c",
        borderRadius:"10px",
        fontFamily:"ubuntu",
        marginBottom:"10px",
        textAlign:"center",
        padding:"10px",
        "& b":{
            fontSize:"22px",
            display:"block",
        }
    },
    formtodate:{
        color:"#fff !important",
        fontFamily:"ubuntu",
        fontSize:"18px",
        paddingLeft: "0",
        marginBottom: "20px",
        "& input":{
            color:"#fff !important",
            fontSize:"14px",
            fontFamily:"ubuntu", 
            paddingLeft:"0px",
            paddingTop:"0px",
            height: "30px",
            paddingBottom: "5px",
        },
        "& label":{
            color:"#fff !important", 
            fontFamily:"ubuntu", 
            transform: "translate(0px, -3px) scale(1)",
        },
        "& div:hover fieldset":{
            borderColor:"#fff !important",
        },
        "& fieldset":{
            color:"#fff !important",
            border:"0px",
            fontFamily:"ubuntu",
            borderBottom:"1px solid #fff",
            borderColor:"#fff !important",
            borderRadius:"0px",
        },
    },
    depositlistwarp:{
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            overflow:"auto",  
            width:"100%",
          }, 
    },
    depositlistw:{
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"1000px",
          }, 
    },
    clearboth:{
        clear:"both",
    },
    tablewarapbox:{
        background:"#131212",
        borderRadius:"10px",
        padding:"30px 10px 20px",
        margin:"0px 0px 50px",
        "& form":{
            margin:"0px",
            padding:"0px 10px 0px 20px",
        },
        "& form div:before":{
            content: "url !important",
            position: "inherit !important",
        },
        "& .selectbox":{
            margin:"0px",
            ['@media (max-width:960px)']: { // eslint-disable-line no-useless-computed-key
                marginBottom:"25px",
            },
        }
    },
    alert:{
        margin: "0px 0px 0px",
        background:"#ffb80c",
        fontSize: "16px",
        fontFamily: "ubuntu",
        color:"#333",
        padding: "10px 15px",
        borderRadius: "5px",
        clear: "both",
    },
    selectboxx:{ 
        border:"0px",
        width:"100%",
        fontSize: "16px",
        fontFamily: "ubuntu",
        padding: "11px 0 11px",
        borderBottom:"1px solid #fff",
        color:"#fff",
        background:"none !important",
        marginBottom:"20px",
        "& input":{
            color:"#fff !important",
            fontSize:"8px",
            fontFamily:"ubuntu", 
            paddingLeft:"0px",
            paddingTop:"0px",
            height: "28px",
            marginTop:"24px",
            paddingBottom: "0px",
        },
        "& label":{
            color:"#fff !important", 
            fontFamily:"ubuntu", 
            marginTop:"-3px",
            fontSize:"16px",
            transform: "translate(0px, -3px) scale(1)",
        },
        "& div:hover fieldset":{
            borderColor:"#fff !important",
        },
        "& fieldset":{
            color:"#fff !important",
            border:"0px",
            fontFamily:"ubuntu",
            borderBottom:"1px solid #fff",
            borderColor:"#fff !important",
            borderRadius:"0px",
        },
        "& option":{
            color:"#000",
        },
        "& div div":{
            color: "#fff",
            fontSize: "14px",
            fontFamily: "ubuntu",
            padding:"0px",
        }
      },
      depositbtn:{
        color:"#fff !important",
        float: "left",
        border: "0px",
        cursor: "pointer",
        margin: "10px auto 0px", 
        display: "block",
        padding: "14px 35px",  
        fontSize: "16px",
        boxShadow: "0px 0px 0px",
        transition: "all 0.3s ease-in",
        fontWeight: "600",
        // lineHeight: "42px",
        borderRadius: "50px",
        textTransform: "uppercase", 
        backgroundImage: "linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07) !important",
        "& span":{
            color: "rgb(120,50,5) !important",
        }
     },  
 
      depositform:{
        background:"#131212",
        margin:"20px 0px 50px",
        borderRadius:"10px",
        padding:"20px 10px 20px 30px",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            width:"100%",
        },
        "& input":{
          background:"none",
          color:"#fff",
            width:"100%",
            fontSize: "16px", 
            borderBottom:"1px solid #bdbdbd",
            marginBottom:"10px",
        },
        "& label":{
            color:"#bdbdbd",
            fontSize:"18px",
            fontFamily:"ubuntu",
        //    marginTop:"25px",
        },
        "& .MuiFormLabel-root.Mui-focused":{
            color:"#bdbdbd",
        },
        "& div":{
          width:"100%",
        },
        "& fieldset":{
            color:"#fff", 
            border:"0px",
          },
        "& select":{
            background:"none",
            marginBottom:"20px",
            lineHeight:"40px",
            height:"40px",
            width:"100%",
            border:"0px",
            fontSize: "16px",
            borderBottom:"1px solid #a1aed4"
        },
        "& .selectbox":{
            background:"none"
        }
    },
    section: {
        background: "#EEEEEE",
        padding: "70px 0"
    },
    pageheadinginner:{
        fontFamily: "ubuntu",
        color:"#fff",
        fontSize:"22px",
        padding:"10px 40px 0px"
    },
    headingcenter:{
        color: "#fff",
        fontSize: "22px",
        fontFamily: "ubuntu",
    },
     
    widthdrawbox:{
        background: "#252f5a",
        padding:"20px 40px",
        textAlign:"center",
        borderRadius:"10px",
        "& p":{
            color:"#a1aed4",
            fontSize:"20px",
            fontFamily: "ubuntu",
        },
        "& i":{
            color:"#a1aed4",
            fontSize:"88px",
            marginBottom:"20px"
        }
    },

    container,
    textCenter: {
        textAlign: "center"
    }
};

export default tabsStyle;

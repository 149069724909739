import { container, title } from "../../casino-kit-react.js";
import {defaultFont, roseColor} from "../../casino-kit-react";

import Background from '../../img/404-bg.png';

const landingPageStyle = {
  about:{ 

  },
  
  gamewrap: {
    overflow: "hidden",
    padding:"69px 0px 0px 0px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      padding:"49px 0px 0px 0px", 
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      padding:"49px 0px 0px 0px",
      "&.gamewrap":{
        padding:"51px 0px 0px 0px",
      }
    },
    position: "relative",
    "& iframe": {
      border: "0",
      left: "0",
      position: "relative",
      top: "0",
      width: "100%"
   }
  },
  
  parallaxd:{
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      height: "auto",
      padding: "130px 0px 15px !important",
    },
  },
  fullimgwidth:{
    width:"100%"
  },
  fourzerofour:{
    backgroundImage:"url(" + Background + ")",
    width:"100%",
    backgroundPosition:"center center",
    backgroundSize:"100%",
    padding:"10% 1%",
    textAlign:"center",
    "& h3":{
      color:"#fff",
      fontSize:"30px",
      fontFamily:"ubuntu",
      fontWeight:"bold",
    },
    "& a":{
      background:"#efde1d",
      fontSize:"18px",
      fontFamily:"ubuntu", 
      color:"rgb(120,50,5) !important"
    },
    "& img":{
      maxHeight:"120px"
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      "& img":{
        maxHeight:"80px"
      },
      "& h3":{ 
        fontSize:"22px", 
      },
    },
  },
  selectboxx:{ 
    border:"0px",
    width:"100%",
    fontSize: "16px",
    fontFamily: "ubuntu",
    padding: "11px 0 11px",
    borderBottom:"1px solid #fff",
    color:"#fff",
    "& option":{
      color:"#000",
    },
    "& div div":{
      color: "#fff",
      fontSize: "14px",
      fontFamily: "ubuntu",
      padding:"0px",
    }
  },
  heroimgmobile:{
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      display: "none",
    },
  },
  headtitle:{
    fontSize: "70px",
    lineHeight: "80px",
    fontWeight: "700",
    marginBottom: "11px",
    textTransform: "uppercase",
    color: "#fff",
    fontFamily: "Ubuntu",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      margin: "0px",
      fontSize: "50px",
      lineHeight: "60px",
    },
  },
  breadcrumbarea:{
    padding: "170px 0px 135px",
    background: "#070b28",
    position: "relative",
    overflow: "hidden",
    borderBottom: "1px solid rgba(255, 255, 255, 0.04)",
  },
  bcimgcontact:{
    position: "absolute",
    right: "0px",
    bottom: "-35px",
    maxWidth: "27%",
    verticalAlign: "middle",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      right: "-40px",
      maxWidth: "90%",
    },
  },
  bcimg:{
    position: "absolute",
    right: "0px",
    bottom: "0px",
    maxWidth: "40%",
    verticalAlign: "middle",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      right: "-40px",
      maxWidth: "90%",
    },
  },
  bcimg2:{
    position: "absolute",
    right: "0px",
    bottom: "0px",
    maxWidth: "50%",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      maxWidth: "100%",
    },
  },
  listmar:{
    margin:"0px !important",
  },
  list: {
    ...defaultFont,
    fontSize: "14px",
    margin: 0,
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: "inherit"
  },
  listItem: {
    float: "left",
    color: "inherit",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    marginRight: "35px",
    position: "relative",
    "&::before":{
      position: "absolute",
      content: "'|'", 
      right: "-2px",
      top: "8px",
    },
    "& .fa-angle-right":{
      marginTop: "12px",
    },
    "&:last-child::before":{
      display:"none"
    },
    "&:last-child":{
      marginRight:"0px"
    },
  },
  containernew: {
    ...container,
    minHeight: "50px",
    display: "block",
    alignItems: "center",
    justifyContent: "space-between",

  },
  breadcrumblist:{
    "& li":{
      display: "inline-block",
    },
    "& li a":{
      fontSize: "16px",
      transition: "all 0.3s ease-in",
      color: "#fff",
    },
    "& li a span":{
      margin: "0px",
    },
    "& span":{
      margin: "0px 7px",
      color: "#fff",
    },
  },
  threesection:{
    padding: "120px 0px 120px",
    background: "#0b122e",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      padding: "20px 0px 30px",
    },
  },
  foursection:{
    padding: "112px 0px 0px",
    background: "#070b28",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      padding: "20px 0px 10px",
    },
  },
  players:{
    padding:"80px 0px 10px",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      padding: "10px 0px 10px",
    },
  },
  singleFun:{
    textAlign: "center",
    "& img": {
      marginBottom: "13px",
    },
    "& h3": {
      fontSize: "70px",
      lineHeight: "80px",
      fontWeight: "600",
      color: "#fff",
      fontFamily: 'Ubuntu',
      margin:"0px",
    },
    "& p": {
      fontSize: "24px",
      lineHeight: "34px",
      fontWeight: "300",
      marginBottom: "0px",
      fontFamily: 'Open Sans',
      display: "inline-block",
      marginTop: "9px",
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      marginBottom: "40px",
    },
  },
  singleFunmargin:{
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      marginBottom: "0px",
    },
  },
  heroimg:{
    marginTop: "50px",
    position: "relative",
  },
  firstsection:{
    //backgroundImage:"url(" + Background + ")",
    background:"#000",
    padding: "30px 0px 20px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      padding: "20px 0px 20px",
    },
  },
  shape:{
    position:"absolute",
    left:"50%",
    transform:"translateX(-50%)",
  },
  shape1:{
    zIndex:"-5",
    top:"320px",
    animation:"upDownAnimation 7s linear infinite",
  },
  shape2:{
    zIndex:"-6",
    top:"260px",
    animation:"upDownAnimation 6s linear infinite",
  },
  shape3:{
    zIndex:"-7",
    top:"220px",
    animation:"upDownAnimation 5s linear infinite",
  },
  seoundsection:{
    padding: "110px 0px 113px",
    position: "relative",
    overflow: "hidden",
    "&::after": {
      position: "absolute",
      content: "close-quote",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      background: "#070b28",
      zIndex: "-99",
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      padding: "20px 0px 30px",
    },
  },
  singlefeature:{
    textAlign:"center",
    padding:"55px 30px 35px",
    transition:"all 0.3s ease-in",
    background:"#252f5a",
    borderRadius:"10px",
    "&:hover": {
        transform:"translateY(-10px)",
    },
    "&:hover .one": {
        boxShadow:"0px 0px 0px 15px rgba(249, 49, 75, 0.2), 0px 0px 0px 30px rgba(249, 49, 75, 0.2)",
    },
    "&:hover .two": {
      boxShadow:"0px 0px 0px 15px rgba(246, 122, 31, 0.2), 0px 0px 0px 30px rgba(246, 122, 31, 0.2)",
    },
    "&:hover .three": {
      boxShadow:"0px 0px 0px 15px rgba(188, 44, 221, 0.2), 0px 0px 0px 30px rgba(188, 44, 221, 0.2)",
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      padding:"45px 30px 25px",
      marginBottom:"10px",
    },
  },
  icon:{
    width:"125px",
    height:"125px",
    lineHeight:"120px",
    marginBottom:"37px",
    position:"relative",
    "& img": {
      verticalAlign:"middle",
    }
  },
  one:{
    border:"7px solid #f9314b",
    borderRadius:"50%",
    textAlign:"center",
    display:"inline-block",
    lineHeight:"110px",
    boxShadow:"0px 0px 0px 15px rgba(249, 49, 75, 0.2), 0px 0px 0px 15px rgba(249, 49, 75, 0.2)",
    transition:"all 0.3s ease-in",
  },
  two:{
    border:"7px solid #f67a1f",
    borderRadius:"50%",
    textAlign:"center",
    display:"inline-block",
    lineHeight:"110px",
    boxShadow:"0px 0px 0px 15px rgba(246, 122, 31, 0.2), 0px 0px 0px 15px rgba(246, 122, 31, 0.2)",
    transition:"all 0.3s ease-in",
  },
  three:{
    border:"7px solid #bc2cdd",
    borderRadius:"50%",
    textAlign:"center",
    display:"inline-block",
    lineHeight:"110px",
    boxShadow:"0px 0px 0px 15px rgba(188, 44, 221, 0.2), 0px 0px 0px 15px rgba(188, 44, 221, 0.2)",
    transition:"all 0.3s ease-in",
  },
  content:{

  },
  titlenew:{
    fontSize:"24px",
    lineHeight:"34px",
    fontWeight:"600",
    textTransform:"uppercase",
    fontFamily: 'Ubuntu',
    color:"#FFF",
    margin:"0px",
    marginBottom:"15px",
  },
  link:{
    fontSize:"16px",
    lineHeight:"26px",
    fontWeight:"600",
    textTransform:"uppercase",
    color:"#a1aed4",
    fontFamily: 'Open Sans',
    marginBottom:"0px",
    padding:"0px",
    transition:"all 0.3s ease-in",
    "&:hover": {
      color:"#f9314b",
    },
    "&:hover i": {
      color:"#f9314b",
      paddingLeft:"10px",
    },
    "& i": {
      fontSize:"14px",
      position:"relative",
      transition:"all 0.3s ease-in",
    },
    "& i::after": {
      position:"absolute",
      content:"",
      top:"5px",
      left:"0",
      height:"3px",
      width:"80%",
      background:"#a1aed4",
      borderRadius:"50px",
    },
    "&:hover i::after": {
      background:"#f9314b",
    },
  },
  gridcenter:{
    justifyContent: "center",
   // margin: "0px",
  },
  featuregames:{
    textAlign:"center",
  },
  singlefeaturebbx:{
    paddingBottom: "50px",
    paddingTop: "50px",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      paddingBottom: "20px",
      paddingTop: "20px",
    },
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container,
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      width: "auto",
    },
  },
  subtitle2:{
    display: "block",
    fontFamily: 'Ubuntu',
    marginTop: "30px",
    color: "#ff344d",
    marginLeft: "0px",
    fontSize: "28px",
    margin: "0px",
    marginBottom: "10px",
    textDecoration: "none",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: "20px",
    },
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    fontFamily: 'Ubuntu',
    marginTop: "0px",
    marginBottom: "13px",
    fontSize: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: "50px",
    },
  },
  titledesc: {
    fontSize: "28px",
    lineHeight: "38px",
    fontWeight: "300",
    fontFamily: 'Open Sans',
    margin:"0px 15px",
    marginBottom: "40px",
    color: "#bdbdbd",
    // background:"#fff",
    // borderRadius:"10px",
    // padding:"10px 20px",
    "& h1":{
      fontSize:"20px",
      fontWeight:"bold",
      color:"#000"
    },
    "& h3":{
      fontSize:"20px",
      fontWeight:"bold",
      color:"#000"
    },
    "& h2":{
      fontSize:"20px",
      fontWeight:"bold",
      color:"#000"
    },
    "& ul":{
      fontSize: "14px",
      lineHeight: "24px",
      color:"#000"
    },
    "& p":{
      lineHeight: "22px",
      color:"#000"
    },
    "& a":{
      textDecoration: "underline"
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: "20px",
      lineHeight: "28px",
      marginBottom: "20px",
    },
  },
  slidbtnb:{
    fontSize: "16px",
    textTransform: "uppercase",
    fontWeight: "600",
    fontFamily: 'Open Sans',
    padding: "14px 35px",
    display: "inline-block",
    color: "#fff",
    border: "0px",
    borderRadius: "50px",
    cursor: "pointer",
    transition: "all 0.3s ease-in",
    backgroundImage: "linear-gradient(to left, #ff344d, #d2273c)",
  },
  forslidbtnb:{
    fontSize: "16px",
    textTransform: "uppercase",
    fontWeight: "600",
    fontFamily: 'Open Sans',
    padding: "7px 35px",
    display: "inline-block",
    color: "#fff",
    border: "0px",
    borderRadius: "3px",
    cursor: "pointer",
    transition: "all 0.3s ease-in", 
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  headertop: {
    backgroundColor: roseColor,
    color: "black"
  },
  clear:{
    clear:"both"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  skydivin:{    
    position: "relative",
    width: "100%",
    zIndex: "1",
  },
  skydiv:{
    "& #sky":{
      zIndex:"1 !important",
    } 
  },
  skydivwrap:{
    position:"relative",
  },
  mainRaised: {
    margin: "0px 0px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  logininerpg:{
    width:"100%"
  },
  gamespadding:{
    padding:"0px",
  }
  
};

export default landingPageStyle;

 

const secotpboxDb = {
    en: [
        {
            'id'    : '5725a680b3249769ea21de52', 
            'title'    : 'OTP',
            'email'    : 'Enter Your OTP',  
            'sbmitbtn'    : 'Submit',  
            'text_p'    : 'Already have an account?', 
            'text_a'    : 'Sign In', 
        }, 
    ],
    tm: [
        {
            'id'    : '5725a680b3249769ea21de52',  
            'title': 'OTP',
            'email': 'உங்கள் OTP ஐ உள்ளிடுக',
            'sbmitbtn': 'சமர்ப்பி',
            'text_p': 'ஏற்கனவே கணக்கு உள்ளதா?',
            'text_a': 'உள்நுழை',
        }, 
    ],
    kn: [
        {
            'id'    : '5725a680b3249769ea21de52',  
            'title': 'ಒಟಿಪಿ',
            'email': 'ನಿಮ್ಮ ಒಟಿಪಿ ನಮೂದಿಸಿ',
            'sbmitbtn': 'ಸಲ್ಲಿಸು',
            'text_p': 'ಈಗಾಗಲೇ ಖಾತೆ ಇದೆಯೇ?',
            'text_a': 'ಸೈನ್ ಇನ್',
        }, 
    ],
    ne: [
        {
            'id'    : '5725a680b3249769ea21de52',   
            'title': 'OTP',
            'email': 'तपाईंको ओटिपी प्रविष्ट गर्नुहोस्',
            'sbmitbtn': 'बुझाउनुहोस्',
            'text_p': 'पहिले नै खाता छ?',
            'text_a': 'साइन इन',
        }, 
    ],
    tl: [
        {
            'id'    : '5725a680b3249769ea21de52', 
            'title'    : 'OTP',
            'email'    : 'మీ OTP ని నమోదు చేయండి',  
            'sbmitbtn'    : 'సమర్పించండి',  
            'text_p'    : 'ఖాతా కలిగి ఉన్నారా?', 
            'text_a'    : 'సైన్ ఇన్ చేయండి', 
        }, 
    ],
    hi: [
        {
            'id'    : '5725a680b3249769ea21de52',
            'title'    : 'ओटीपी  ',
            'email'    : 'अपना ओटीपी दर्ज करें  ',  
            'sbmitbtn'    : 'प्रस्तुत',  
            'text_p'    : 'पहले से ही एक खाता है?', 
            'text_a'    : 'साइन इन करें',    
        },  
    ],
    

};

export default secotpboxDb;
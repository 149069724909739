 

const secloginboxDb = {
    en: [
        {
            'id'    : '5725a680b3249860ea21de51',
            'subtitle'    : 'Enter your details below.',
            'title'    : 'GREAT TO HAVE YOU BACK!',
            'name'    : 'Enter Your Username', 
            'password'    : 'Password', 
            'sbmitbtn'    : 'Sign In', 
            'text_p'    : 'Not a member?', 
            'text_a'    : 'Create account',
            'text_fp'    : 'Forgot Password', 
            'text_tnc_one' : 'I agree to the',
            'text_tnc_two' : 'Terms and Conditions',
        }, 
    ],
    tl: [
        {
            'id'    : '5725a680b3249860ea21de51',
            'subtitle'    : 'బాగుంది, లాగిన్ అవ్వండి.',
            'title'    : 'మీరు తిరిగి రావడానికి గొప్పది!',
            'name'    : 'మీ వినియోగదారు పేరును నమోదు చేయండి', 
            'password'    : 'పాస్వర్డ్', 
            'sbmitbtn'    : 'సైన్ ఇన్ చేయండి', 
            'text_p'    : 'సభ్యుడు కాదు?', 
            'text_a'    : 'ఖాతాను సృష్టించండి', 
            'text_fp'    : 'పాస్వర్డ్ మర్చిపోయారా', 
            'text_tnc_one' : 'నేను అంగీకరిస్తున్నాను',
            'text_tnc_two' : 'నిబంధనలు మరియు షరతులు',
        }, 
    ],
    hi: [
        {
            'id'    : '5725a680b3249860ea21de52',
            'subtitle'    : 'अपना विवरण नीचे दर्ज करें।',
            'title'    : 'आप वापस आ गए हैं!',
            'name'    : 'अपना उपयोगकर्ता नाम दर्ज करें', 
            'password'    : 'पासवर्ड', 
            'sbmitbtn'    : 'साइन इन करें', 
            'text_p'    : 'सदस्य नहीं है?', 
            'text_a'    : 'खाता बनाएं',
            'text_fp'    : 'पासवर्ड भूल गए', 
            'text_tnc_one' : 'मैं सहमत हु', 
            'text_tnc_two' : 'नियमों और शर्तों से',    
        },  
    ],
    

};

export default secloginboxDb;
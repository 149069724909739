import React from "react";
// @material-ui/core components
import 'react-accessible-accordion/dist/fancy-example.css';
import history from '../../../@history';
// import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";
// import CustomTabs from "../elements/CustomTabs/CustomTabs.js";

import styles from "../assets/jss/components/tabsPromotionstyle.js";
import axios from 'axios';
// import promotionDb from "../data/promotion";
import Slide from "@material-ui/core/Slide";
// import ListItem from "@material-ui/core/ListItem"; 
// import List from "@material-ui/core/List";
import * as globalurl from '../../../global/Global.js'; 

// const useStyles = makeStyles(styles); 

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";
export default function verifyEmailToken(props) {
    const currentLocation = props.location.pathname.split("/");
    if(currentLocation[2] != null && currentLocation[2].length > 0){
        verifyEmail();
    }
    function verifyEmail(){
        const request = axios.post(globalurl.VERIFYEMAILTOKEN, {
          token: window.localStorage.getItem('jwt_access_token'),
          emailtoken:currentLocation[2]
        });
        request.then((response) => {
            history.push({
                pathname: '/verifyToken'
            });
        });
        request.catch((error) => {
            history.push({
                pathname: '/verifyToken'
            });
        });
        history.push({
            pathname: '/verifyToken'
        });
      }

    styles.messagebox.background = props.themeColors.bgColors.secondary_dark_bgcolor;
    styles.listItem["&:nth-child(even)"].background = props.themeColors.bgColors.primary_bgcolor;
    styles.listItem.color = props.themeColors.bgColors.text_secondary_dark_color;

    
    return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                  
              <div> <br/><br/><br/><h3 className={"successmsg"}>Your account email id is successfully verified.</h3></div>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                </GridItem>
            </GridContainer>
  
    );
  }
  
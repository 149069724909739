import * as GlobalVar from '../global/Global';

function parseJwt(token) {
    if (!token) { return; }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}
function getCurrentTheme(){
  if(window.localStorage.getItem('jwt_theme_token') != null){
      let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
      if(themeData.website_theme != null){
        if(GlobalVar.THEMES.indexOf(themeData.website_theme) >= 0){
            return themeData.website_theme;
        }else{
            return "casinodestination";
        }
      }
  }
}

var themeName = getCurrentTheme();

const settingsConfig = {
    layout          : {
        style : (themeName != null && themeName.length > 0) ? themeName : 'smdesign1', // layout-1 layout-2 layout-3
        config: {} 
    },
    customScrollbars: true,
    theme           : {
        main   : 'default',
        navbar : 'mainThemeDark',
        toolbar: 'mainThemeLight',
        footer : 'mainThemeDark'
    }
};

export default settingsConfig;

import React from "react";
// @material-ui/core components
// import {
//     Accordion,
//     AccordionItem,
//     AccordionItemHeading,
//     AccordionItemButton,
//     AccordionItemPanel,
// } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import Carousel from "react-slick";
import Card from "../elements/Card/Card.js";
import { makeStyles } from "@material-ui/core/styles";
// import faqDb from "../data/faqs.js";
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";
// import CustomTabs from "../elements/CustomTabs/CustomTabs.js";
import * as Gurl from "../../../global/Global.js"
import axios from 'axios';
import Offer2 from "../assets/img/offr.jpg";

import styles from "../assets/jss/components/tabsFaqstyle.js";





export default function SectionTabs(props) {
  
  if(props.themeColors != null){
  styles.accordianbtn["& div i"].background = props.themeColors.bgColors.golden
  styles.accordianbtn["& div i"].color = props.themeColors.bgColors.goldenbtntext
  styles.accordianbtn["& div"].background = props.themeColors.bgColors.accordianlabel
  styles.accordianbtn["& div:hover"].background = props.themeColors.bgColors.accordianlabel
  styles.accordianiitm.background = props.themeColors.bgColors.accordiandiv
  styles.accordianp.color = props.themeColors.bgColors.accordiandivtext 
  styles.recentwinnhead.color = props.themeColors.bgColors.text_primary_color 
  styles.spikerecentwinn["& .singlewiner"].background = props.themeColors.bgColors.primary_bgcolor 
  styles.spikerecentwinn["& .singlewiner"].borderColor = props.themeColors.bgColors.secondary_dark_bgcolor 
  styles.spikerecentwinn["& .singlewiner .id"].background = props.themeColors.bgColors.primary_inner_bgcolor 
  styles.spikerecentwinn["& .singlewiner .id"].borderColor = props.themeColors.bgColors.primary_inner_bgcolor 
  }
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric' };
 
  const [offerData, setOfferData] = React.useState("NOVALUE");
  // function createMarkup(text) { return {__html: text}; };
    function loadOffersData(){
        const request = axios.post(Gurl.PROMOTIONS_CONTENT_API, {
            token: window.localStorage.getItem('jwt_access_token')
        });
        request.catch((error) => { 
          setOfferData("NOVALUE");
        });
        request.then((response) => { 
            if(response.data.data != null){ 
              setOfferData(response.data.data);
            }else{
              props.setOffersData("NO");
              setOfferData("NO Offer Loaded");
            }
        });  
    }
  if(offerData === "NOVALUE"){
    setOfferData("Loading...");
    loadOffersData();
  }
  var outputData = "";
  var rc = "0";
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,


    // className: "center",
    // centerMode: true, 
    // centerPadding: "0%",  
  }; 
  if(Array.isArray(offerData)){ 
    rc = 0;
    outputData = offerData.map(jsonData => {
      rc = rc + 1;
      return(
        <div key={jsonData.id}>
          <img src={((jsonData.coupon_logo != null && jsonData.coupon_logo.length > 0) ? jsonData.coupon_logo  : Offer2)} className={classes.fullimgwidth + " slick-image"}  alt={""}
          style={{"cursor":"pointer","width":"100%"}}
          onClick={() => {(jsonData.bonus_type_id !== 3) ? props.SectionLoginbox.openPopup("login") : props.SectionSignupbox.openPopup("signup")}}/> 
        </div>
      )
    });
    return(
      <Card carousel className={classes.sllid + " offersimages"}>
        <Carousel {...settings}>
          {outputData}
        </Carousel>
      </Card>
    )
  } else {
    if(offerData !== "NOVALUE" && offerData !== "Loading..." && offerData !== "NO Offer Loaded"){
      return (
          <Card carousel className={classes.sllid + " offersimages"}>
            <Carousel {...settings}>
              <div key={offerData.id}>
                <img src={((offerData.coupon_logo != null && offerData.coupon_logo.length > 0) ? offerData.coupon_logo  : Offer2)} className={classes.fullimgwidth + " slick-image"}  alt={""} /> 
              </div>
            </Carousel>
        </Card>
          );
    }else{
    return (<div></div>)
    }
  }
  
}
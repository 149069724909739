

const flagDb = {
    en: [
        {
            'id':'5725a680b3240760ea21de79',
            'flag_img':"assets/img/english.jpg", 
        } 
    ],
    tl: [
        {
            'id':'5725a680b3240760ea21de79',
            'flag_img':"assets/img/telugu.jpg", 
        } 
    ],
    hi: [
        {
            'id':'5725a680b3240760ea21de80',
            'flag_img':"assets/img/hindi.jpg", 
        } 
    ]
}
export default flagDb;

import * as UserActions from './user.actions';
//import * as Actions from '../../../src/store/actions';
import jwtService from '../../../services/jwtService';

export const WLREGISTER_ERROR = 'WLREGISTER_ERROR';
export const WLREGISTER_SUCCESS = 'WLREGISTER_SUCCESS';
export const WLREGISTER_EDIT_SUCCESS = 'WLREGISTER_EDIT_SUCCESS';
 

export function submitWlRegister({id, website_type_id, country_id, mobile, email, firm_name, company_name, name, username, password, confirm_password, recaptcha, user_id}, setRequestTime)
{
    let userData = false;
    function parseJwt(token) {
        if (!token) { return; }
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        var userDatat = JSON.parse(window.atob(base64));
        return userDatat;
    }
    if(userData === false){
        userData = parseJwt(window.localStorage.getItem("jwt_theme_token"));
    } 
    return (dispatch) =>
        jwtService.createUserWlRegister({
            id,
            website_type_id, 
            country_id, 
            mobile, 
            email, 
            firm_name, 
            "name":firm_name, 
            username, 
            password, 
            company_name,
            confirm_password, 
            recaptcha, 
            "user_id":userData.web_id
        })
        .then((user) => { 
            if(user.status != null && user.status === 1){
                    UserActions.setWlUserData(user); 
                    if(id > 0){
                        setRequestTime(user);
                    }
                    return dispatch({
                        type: WLREGISTER_SUCCESS,
                        payload: user 
                    });
                    
                }else{
                    if(user.error != null){
                        return dispatch({
                            type   : WLREGISTER_ERROR,
                            payload: user
                        });
                    }else{
                        return dispatch({
                            type   : WLREGISTER_ERROR,
                            payload: "unknown error"
                        });
                    }
                }
            }
        )
        .catch(error => { 
            return dispatch({
                type   : WLREGISTER_ERROR,
                payload: error
            });
        });
}

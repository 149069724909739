

const balanceDb = {
    en: [
        {
            'id':'3923923894',
            'title':"Deposit",
            'tagName': 'tab1', 
        },
        {
            'id':'3923923894',
            'title':"Withdraw",
            'tagName': 'tab2', 
        },
        {
            'id':'3923923894',
            'title':"History",
            'tagName': 'tab3', 
        },
        
    ],
    tl: [
        {
            'id':'3923923894',
            'title':"డిపాజిట్",
            'tagName': 'tab1', 
        },
        {
            'id':'3923923894',
            'title':"ఉపసంహరించుకోండి",
            'tagName': 'tab2', 
        },
        {
            'id':'3923923894',
            'title':"చరిత్ర",
            'tagName': 'tab3', 
        },
        
    ],
    hi: [
        {
            'id':'3923923894',
            'title':"जमा",
            'tagName': 'tab1', 
        },
        {
            'id':'3923923894',
            'title':"निकालना",
            'tagName': 'tab2', 
        },
        {
            'id':'3923923894',
            'title':"इतिहास",
            'tagName': 'tab3', 
        }, 
    ]
}
export default balanceDb;

// import * as UserActions from './user.actions';
//import * as Actions from '../../../src/store/actions';
import jwtService from '../../../services/jwtService';

export const ADDBANK_ERROR = 'ADDBANK_ERROR';
export const ADDBANK_SUCCESS = 'ADDBANK_SUCCESS';

export function submitAddbank({acc_number, token, acc_name, ifsc_code, micr_code, bank_name, branch_name, iban_number, branch_address,})
{
   
    return (dispatch) =>
        jwtService.createBankaccount({
            acc_number,
            acc_name,
            ifsc_code,
            micr_code: ifsc_code,
            bank_name,
            branch_name, 
            iban_number,
            token: window.localStorage.getItem('jwt_access_token'),
            branch_address: branch_name,
        })
            .then((user) => { 
                //    alert(JSON.stringify(user));
                  //  dispatch(UserActions.setUserData(user)); 
                    
                    
                    return dispatch({
                        type: ADDBANK_SUCCESS,
                        payload: user 
                        
                    });
                }
            )
            .catch(error => { 
                return dispatch({
                    type   : ADDBANK_ERROR,
                    payload: error
                });
            });
}
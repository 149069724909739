import { container } from "../../casino-kit-react.js";
const tabsStyle = {
    signinnerpagebox:{
        background:"#0f0f0f",
        padding:"0px 20px 0px",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            padding:"90px 20px 0px",
          },
    },
    signinnerpageboxin:{
        background:"#131212",
        margin:"0px 0px 0px",
        borderRadius:"10px"
    },
    msgdiscription:{
        "& p":{
            color: "#bdbdbd",
            fontFamily:"ubuntu",
        },
        "& h4":{
            color: "#bdbdbd",
            fontFamily:"ubuntu", 
            padding:"0px 0px 10px",
            margin:"0px 0px 10px",
            borderBottom: "1px solid #565758",
            fontSize: "20px",
        },
    },
    accordianiitm:{
        background: "none",
        "&:nth-child(even)":{
            background:"#000"
        },
        "&:nth-child(odd)":{
            background:"none"
        },
        
    },
    accordianbtn:{
        
        "& div":{
            background: "none",
            color: "#bdbdbd",
            padding:"10px 0px",
            lineHeight:"40px"
        },
        "& div div":{ 
            padding:"0px", 
        },
        "& div:hover":{
            background: "none", 
        },
        "& .accordion__button:before":{
            border:"0px",
            display:"none"
        }
    },
    section: {
        background: "#EEEEEE",
        padding: "70px 0"
    },
    messagebox:{
        background: "#252f5a",
        padding:"30px 0px",
        borderRadius:"10px",
    },
    list:{
      width:"100%",
      display:"block"
    },
    listItem:{
        width:"100%",
        paddingTop:"15px",
        paddingBottom:"15px",
        display:"block",
        color:"#a1aed4",
        fontFamily:"ubuntu",
        fontSize:"18px",
        "& a":{
            margin:"0px",
        },
        "& p":{
            margin:"0px",
        },
        "&:nth-child(even)":{
            background:"#364275"
        },
        "&:nth-child(odd)":{
            background:"none"
        }
    },
    serialnumber:{
        textAlign: "center",
        margin:"0px"
    },
    messagedetalbtn:{
        padding:"0px",
        fontSize:"18px",
        fontFamily:"ubuntu",
        textAlign: "left",
        display:"block",
        margin:"0px",
        lineHeight:"40px",
        textTransform:"capitalize",
        "& i":{
            marginTop:"-3px",
        },
        "& span":{
            ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
                display: "inline-block",
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden !important",
                textOverflow: "ellipsis !important",
            },
        },

    },
    messagedate:{
        margin:"0px",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            fontSize:"12px"
        },
    },
    messagedeletebtn:{
        margin:"0px",
        display:"block",
        padding:"0px",
        textAlign:"center",
        lineHeight:"40px",
        fontSize:"18px",
    },
    container,
    textCenter: {
        textAlign: "center"
    }
};

export default tabsStyle;

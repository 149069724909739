import React from "react";

// nodejs library that concatenates classes

// core elements
import Header from "./elements/Header/Header.js";
import Footer from "./elements/Footer/Footer.js"; 
import HeaderLinks from "./elements/Header/HeaderLinks.js";
import SectionPopup from "./sections/SectionPopup";
import './webroot/App.css'; 
import {Route } from "react-router-dom";
import CookieConsent from "react-cookie-consent"; 
import ComponentLoader from "./pages/componentloader.js";  
import themeDb from "./data/theme.js";  
import "./assets/scss/material-kit-react.scss?v=1.8.0";
import jwtService from '../../services/jwtService'
import  "./i18n";
const dashboardRoutes = []; 

export default function LandingPage(props) {

    const { ...rest} = props;     
    const { ...myTheme} = themeDb;  
    const currentLocation = props.location.pathname.split("/");
    var colorSplit = "#000000";
    if(themeDb.bgColors.primary_bgcolor !== null){
        colorSplit = themeDb.bgColors.primary_bgcolor.split(" ");
    }
    return (  
        <div className={"casinoprime " + ((currentLocation[1] !== null) ? currentLocation[1] : "")}
          ref={(el) => {
            if (el && colorSplit[1] !== null && colorSplit[1] !== "!important") {
              el.style.setProperty('background-color', ((colorSplit[0] !== null) ? colorSplit[0] : "#000000"), '!important');
            }else if (el && colorSplit[1] !== null && colorSplit[1] !== "important") {
                el.style.setProperty('background-color', ((colorSplit[0] !== null) ? colorSplit[0] : "#000000"), 'important');
            }else{
               // alert(themeDb.bgColors.primary_bgcolor);
               if(el){
                el.style.setProperty('background-color', ((themeDb.bgColors.primary_bgcolor !== null) ? themeDb.bgColors.primary_bgcolor : "#000000"), '#000000');
               }
            }
          }}
        >
            <div className="cookiesbox">   
                <CookieConsent 
                    style={{
                    margin: '0',
                    fontFamily: 'Open Sans', fontSize:'14px' }} 
                    enableDeclineButton flipButtons 
                    >
                Third Party Cookies Policy
                <p style={{ margin: '0', fontSize:'12px', fontFamily: 'Open Sans'}}>Our website uses cookies to make your browsing experience better. By using our site you agree to our use of cookies.</p>
                </CookieConsent>
            </div>
            <Header
                color="transparent"
                routes={dashboardRoutes}
                brand="iSoft Gaming"
                rightLinks={<HeaderLinks themeColors={myTheme} {...rest}/>}
                fixed
                changeColorOnScroll={{
                    height: 30,
                    color: "rose"
                }}
                sectionPopup={SectionPopup}
                themeColors={myTheme} 
                {...rest}
            />
            <SectionPopup  themeColors={myTheme}  {...rest} />
            {(() => {
                const currentLocation = props.location.pathname.split("/");
                if(jwtService.isAuthenticationLogin() === true){
                    return(
                        <div>
                        <Route path={"/"+currentLocation[1]}>
                            <ComponentLoader themeColors={myTheme} sectionPopup={SectionPopup} {...props}/>
                        </Route>
                        </div>
                    )  
                }else{
                    if(currentLocation[1].length > 0){
                        return(
                            <div>
                            <Route path={"/"+currentLocation[1]}>
                                <ComponentLoader sectionPopup={SectionPopup} themeColors={myTheme} {...props}/>
                            </Route>
                
                            
                            </div>
                        )
                    }else{
                        return(
                            <div>
                            <Route path={"/"}>
                                <ComponentLoader sectionPopup={SectionPopup} themeColors={myTheme} {...props}/>
                            </Route>
                            </div>
                        )
                    }
                    
                }
            })()} 
            <Footer
                themeColors={myTheme}
                sectionPopup={SectionPopup}
                {...rest}/>
        </div>
    );
}
import * as UserActions from './user.actions'; 
import jwtService from '../../../services/jwtService';
//import {setUserData} from './user.actions';
//import * as Actions from '../../../store/actions';

export const WLOTP_ERROR = 'WLOTP_ERROR';
export const WLOTP_SUCCESS = 'WLOTP_SUCCESS';


export function wlsubmitOtp({otp, user_id, rid, recaptcha},props)
{
    let userData = false; 
    function parseJwt(token) {
        if (!token) { return; }
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        var userDatat = JSON.parse(window.atob(base64));
        return userDatat;
    }
    if(userData === false){
        userData = parseJwt(window.localStorage.getItem("access_token"));
        let xyzData = parseJwt(window.localStorage.getItem("jwt_theme_token"));
        userData = {...userData, ...xyzData};
    } 
//const {...rest} = props; 
//const currentLocation = props.location.pathname.replace("/","");

    return (dispatch) =>
        jwtService.WlotpVerification({otp, "user_id":userData.web_id, recaptcha, "rid":userData.data})
            .then((user) => { 
                   
                    //user = {"role":"player", ...user}; 
                    UserActions.setWlUserData(user.data);
                    return dispatch({
                        type: WLOTP_SUCCESS,
                        payload: user 
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type   : WLOTP_ERROR,
                    payload: error
                });
            });
}

import React from "react";
// @material-ui/core components
// import {
//     Accordion,
//     AccordionItem,
//     AccordionItemHeading,
//     AccordionItemButton,
//     AccordionItemPanel,
// } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import { makeStyles } from "@material-ui/core/styles";
// import faqDb from "../data/faqs.js";
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";
import imgg from "../assets/img/ad1.jpg";
import imgg2 from "../assets/img/ad2.jpg";
// import CustomTabs from "../elements/CustomTabs/CustomTabs.js";
import * as Gurl from "../../../global/Global.js"
import axios from 'axios';
import Offers from "../pages/offers";
import SectionLoginbox from "./SectionLoginBox";
import SectionSignupbox from "./SectionSignupBox";
import styles from "../assets/jss/components/tabsFaqstyle.js";

export default function SectionTabs(props) {
  
  if(props.themeColors != null){
    styles.accordianbtn["& div i"].background = props.themeColors.bgColors.golden
    styles.accordianbtn["& div i"].color = props.themeColors.bgColors.goldenbtntext
    styles.accordianbtn["& div"].background = props.themeColors.bgColors.accordianlabel
    styles.accordianbtn["& div:hover"].background = props.themeColors.bgColors.accordianlabel
    styles.accordianiitm.background = props.themeColors.bgColors.accordiandiv
    styles.accordianp.color = props.themeColors.bgColors.accordiandivtext 
    styles.recentwinnhead.color = props.themeColors.bgColors.text_primary_dark_color 
  }
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric' };
 
  const [winnerData, setWinnerData] = React.useState("NOVALUE");
  // function createMarkup(text) { return {__html: text}; };
    function loadWinnerData(){
        const request = axios.post(Gurl.WINNER_CONTENT_API, {
            token: window.localStorage.getItem('jwt_access_token'),
            "gameids" : props.gameIds,
            "LEND"    : props.record
        });
        request.catch((error) => { 
          setWinnerData("NOVALUE");
        });
        request.then((response) => { 
            if(response.data.data != null){ 
              setWinnerData(response.data.data);
            }else{
              setWinnerData("NO Winner Loaded");
            }
        });  
    }
  if(winnerData === "NOVALUE"){
    setWinnerData("Loading...");
    loadWinnerData();
  }
  var outputData = "";
  var rc = "0";
  if(props.design === "1"){
    if(Array.isArray(winnerData)){ 
      rc = 0;
      outputData = winnerData.map(jsonData => {
        rc = rc + 1;
        return(
          <GridItem xs={12} >
            <div className={"singlewiner" + ((rc%2) ? " fleft" : " fleft")}>
                <table> 
                  <tr>
                    <td width="32px"><img className="icotwo" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="" /></td>
                    <td>{jsonData.name}</td> 
                    <td align="right">{jsonData.winning_amount} {jsonData.currency_code}</td>
                  </tr> 
                </table>   
            </div> 
          </GridItem>
        )
      });
      return(
        <div>
          {outputData}
        </div>
      )
    } else {
      if(winnerData !== "NOVALUE" && winnerData !== "Loading..."){
        return (<div className="singlewiner">
          <table> 
            <tr>
              <td width="32px"><img className="icotwo" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="" /></td>
              <td>{winnerData.name}</td> 
              <td align="right">{winnerData.winning_amount} {winnerData.currency_code}</td>
            </tr> 
          </table>  
        </div> );
      }else{
      return (<div></div>)
      }
    }
  }else{
    if(Array.isArray(winnerData)){ 
      rc = 0;
      outputData = winnerData.map(jsonData => {
        rc = rc + 1;
        return(
          <GridItem xs={12} key={rc}>
             <div className={"singlewiner" + ((rc%2) ? " fleft" : " fleft")}>
             <table> 
                <tr>
                  <td width="32px"><img className="icotwo" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="" /></td>
                  <td>{jsonData.name}</td> 
                  <td align="right">{jsonData.winning_amount} {jsonData.currency_code}</td>
                </tr> 
              </table> 
          </div> 
          </GridItem>
        )
      })
      return(
        <div className={classes.recentwinn}>
          <h4 className={classes.recentwinnhead}>Recent Winners</h4>
          <GridContainer>
          {outputData}
          </GridContainer> 
            <Offers  {...props} themeColors={props.themeColors} SectionLoginbox={SectionLoginbox} SectionSignupbox={SectionSignupbox} />
            <SectionLoginbox 
                SignupBox={SectionSignupbox} 
                {...props} />  
            <SectionSignupbox 
                LoginBox={SectionLoginbox}
                {...props} />    
        </div>
      )
    } else {
      if(winnerData !== "NOVALUE" && winnerData !== "Loading..."){
        return (<div> <div className="singlewiner"> 
        <table> 
          <tr>
            <td>No Record Found </td> 
          </tr> 
        </table> 
      </div>  
      <Offers  {...props} themeColors={props.themeColors} SectionLoginbox={SectionLoginbox} SectionSignupbox={SectionSignupbox} />
      <SectionLoginbox 
          SignupBox={SectionSignupbox} 
          {...props} />  
      <SectionSignupbox 
          LoginBox={SectionLoginbox}
          {...props} />    
      </div>);
      }else{
        return (<div></div>)
      }
    }
  }
}
import React, {useEffect, useRef, useState} from "react";
// @material-ui/core components

import 'react-accessible-accordion/dist/fancy-example.css';

import { makeStyles } from "@material-ui/core/styles";


import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import {MenuItem} from "@material-ui/core"; 
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";
import CustomTabsFirst from "../elements/CustomTabs/CustomTabsFirst.js";
import accountsDb from "../data/account.js";

import styles from "../assets/jss/components/tabsAccountstyle.js";
import Button from "../elements/CustomButtons/Button";
// import FiberManualRecord from "@material-ui/icons/FiberManualRecord"; 
// import { useTranslation } from 'react-i18next'
import Formsy from 'formsy-react'; 
import {TextFieldFormsy, SelectFormsy, RadioGroupFormsy} from '../../../@casino';
import * as authActions from '../../../auth/casino/actions';    
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import * as globalurl from '../../../global/Global.js'; 
import tabnotificationDb from "../data/tabnotification.js";
import tabnotificationeditDb from "../data/tabnotificationedit.js"; 
import MobileOtpbox from "../sections/SectionOtpBox"; 
import VerifyMobile from "../sections/VerifyMobile";
import VerifyEmail from "../sections/VerifyEmail";
import KycModule from "./kycModule";
import LoginHistory from "./loginHistory";
import Norecord from "../assets/img/no-record.png"; 
// import promotionDb from "../data/promotion";
// import Slide from "@material-ui/core/Slide";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import localDb from "../data/bankaccount";

// const token = "kdkddjdj2929j38jv88fpwad83pakwdi3"; 
const useStyles = makeStyles(styles);
var selectedTabName = "NOVALUE";
// let isManual = false;
let isProcessRunning = false;
let isCountryRunning = false;
export default function Account(props) {

    const [countryCode, setCountryCode] = React.useState(0);
    const {...rest} = props;
    const dispatch = useDispatch();
    const userPass = useSelector(({auth}) => auth.changePassword);
    // const [bankAccounts, setBankAccounts] = React.useState(0);
    const [reloadProfile, setReloadProfile] = React.useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const formRef = useRef(null); 
    const formRefNoti = useRef(null); 
    const formRefBank = useRef(null); 
    const [classAdd, setClassAdd] = useState(false);
    const addBank = useSelector(({auth}) => auth.addbank);
    const userEdit = useSelector(({auth}) => auth.changeProfile);
    styles.listItem["&:nth-child(even)"].background = props.themeColors.bgColors.messageboxlist;
    styles.listItem.color = props.themeColors.bgColors.messageboxlistcolor;
     const changePassFormRef = useRef(null); 
    const [isChangePasswordFormValid, setIsChangePasswordFormValid] = useState(false);
    // const [selectedEnabled, setSelectedEnabled] = React.useState("imb");
    // const [selectedEnabledpn, setSelectedEnabledpn] = React.useState("pna");
    // const [selectedEnabledpc, setSelectedEnabledpc] = React.useState("pca");
    // const [selectedEnabledem, setSelectedEnabledem] = React.useState("ema");
    // const [selectedEnabledsm, setSelectedEnabledsm] = React.useState("sma");
    // const [selectedEnabledto, setSelectedEnabledto] = React.useState("to");


    function disableChangePasswordButton(){
        setIsChangePasswordFormValid(false)
        document.body
        .getElementsByClassName("changepassbtn")[0]
          .setAttribute("style", "opacity:0.5;");
    }
    function enableChangePasswordButton(){
        setIsChangePasswordFormValid(true)
        document.body
        .getElementsByClassName("changepassbtn")[0]
          .setAttribute("style", "opacity:1;");
    }
    useEffect(() => {
        if ( userPass.error && (userPass.error.newpassword || userPass.error.oldpassword || userPass.error.message) )
        { 
            if(userPass.error.message != null && document.body
                .getElementsByClassName("message_passs")[0] !== null){
                document.body
                .getElementsByClassName("message_passs")[0] 
                .innerHTML=userPass.error.message; 
                setClassAdd(classes.msg = " errormsg" );
            }else{
                changePassFormRef.current.updateInputsWithError({
                    ...userPass.error
                });
            } 
            disableChangePasswordButton();
        }
        if(userPass.success != null && userPass.success === true){
            if(userPass.data != null && userPass.data.message != null){
                document.body
                .getElementsByClassName("message_passs")[0] 
                .innerHTML=userPass.data.message; 
                setClassAdd(classes.msg = " successmsg" );
            }else{
                document.body
                .getElementsByClassName("message_passs")[0] 
                .innerHTML="Your password updated successfully."; 
                setClassAdd(classes.msg = " successmsg" );
            }  
        } 
    }, [userPass.error, userPass.data, userPass.success]);

    useEffect(() => {
        if ( userEdit.error && (userEdit.error.first_name || userEdit.error.last_name || userEdit.error.email || userEdit.error.mobile || userEdit.error.message) )
        {
            if(userEdit.error.message != null){
                document.body
                .getElementsByClassName("message_profile")[0] 
                .innerHTML=userEdit.error.message; 
                setClassAdd(classes.msg = " errormsg" );
            }else{
                formRef.current.updateInputsWithError({
                    ...userEdit.error
                });
            }
            
            
            disableChangePasswordButton();
        }
        if(userEdit.success != null && userEdit.success === true){
            if(userEdit.data != null && userEdit.data.message != null){
                document.body
                .getElementsByClassName("message_profile")[0] 
                .innerHTML=userEdit.data.message; 
                setClassAdd(classes.msg = " successmsg" );
            }else{
                document.body
                .getElementsByClassName("message_profile")[0] 
                .innerHTML="Profile updated successfully."; 
                setClassAdd(classes.msg = " successmsg" );
            }
            setReloadProfile(true); 
            document.body
            .getElementsByClassName("editProfileDiv")[0]
            .setAttribute("style", "display:none;");
        document.body
            .getElementsByClassName("userProfileDiv")[0]
            .setAttribute("style", "display:block;");
        }
    }, [userEdit.error, userEdit.data, userEdit.success]);

    function handleChangePasswordSubmit(model)
    {  
        dispatch(authActions.submitChangePassword(model, props));
      document.body
            .getElementsByClassName("message_passs")[0]
            .innerHTML=" "
    } 
    const [formData, setFormData] = React.useState([{status: false}]);
   
    if(((formData[0].status == false && formData[0].checked == null) || reloadProfile === true) && isProcessRunning === false){
        isProcessRunning = true;
        const request = axios.post(globalurl.GETPROFILE , {
            token: window.localStorage.getItem('jwt_access_token')
        });
        request.then((response) => {
            isProcessRunning = false;
            var statementDb = "";
            if(response.data.data != null){
                statementDb = [{
                    "status":true,
                    "data":response.data.data
                }]
                setFormData(statementDb);
            }else{
                statementDb = [{
                    "status":false,
                    "checked":false
                }]   
                setFormData(statementDb);
            }
            setReloadProfile(false);
        });
        request.catch(() => {
            isProcessRunning = false;
            var statementDb = [{
                "status":false 
            }]   
            setFormData(statementDb);
            setReloadProfile(false);
        });
    }

    if(props.themeColors != null){
     //   styles.accountsbox.background = props.themeColors.bgColors.accountsbox;
        //styles.userprobox["& p"].color = props.themeColors.bgColors.userproboxhead; 
        styles.addbtn.background = props.themeColors.bgColors.main_color;
        styles.addbtn.backgroundImage = props.themeColors.bgColors.main_color;
        styles.addbtn.color = props.themeColors.bgColors.text_primary_dark_color;
        styles.addbtn["& span"].color = props.themeColors.bgColors.text_primary_dark_color;
        
        styles.messagedeletebtn.color = props.themeColors.bgColors.text_primary_color;
        styles.messagedeletebtn["&:hover"].color = props.themeColors.bgColors.text_secondary_color;

        styles.depositbtn.background = props.themeColors.bgColors.main_color;
        styles.depositbtn.backgroundImage = props.themeColors.bgColors.main_color;
        styles.depositbtn.color = props.themeColors.bgColors.text_primary_dark_color;
        styles.depositbtn["& span"].color = props.themeColors.bgColors.text_primary_dark_color;
        styles.ifscbtn.color = props.themeColors.bgColors.text_secondary_color;
       styles.depositbtn2.background = props.themeColors.bgColors.main_color;
       styles.depositbtn2.backgroundImage = props.themeColors.bgColors.main_color;
       styles.userprobox["& p"].color = props.themeColors.bgColors.text_secondary_dark_color;
       styles.userprobox["& span"].color = props.themeColors.bgColors.text_primary_color;
       styles.selectboxxs["& div div"].color = props.themeColors.bgColors.text_primary_color;
       
       styles.editprobox["& label"].color = props.themeColors.bgColors.text_secondary_dark_color;
       styles.editprobox["& input"].color = props.themeColors.bgColors.text_primary_color;
       styles.editprobox["& p"].color = props.themeColors.bgColors.text_primary_color;
       styles.editprobox["& input"].borderBottomColor = props.themeColors.bgColors.secondary_color;
       styles.tableheading.background = props.themeColors.bgColors.primary_bgcolor;
       styles.accountsheads.color = props.themeColors.bgColors.text_primary_color; 
       styles.changepasbox["& label"].color = props.themeColors.bgColors.text_secondary_dark_color;
       styles.changepasbox["& input"].color = props.themeColors.bgColors.text_primary_color; 
       styles.changepasbox["& input"].borderBottomColor = props.themeColors.bgColors.secondary_color;
       styles.listItem.color = props.themeColors.bgColors.text_secondary_dark_color;
       styles.listItem["&:nth-child(odd)"].background = props.themeColors.bgColors.primary_dark_bgcolor;
       styles.iconnotibox.background = props.themeColors.bgColors.secondary_bgcolor; 
       styles.iconnotibox["&:hover"].background = props.themeColors.bgColors.primary_dark_bgcolor; 
       styles.iconnotibox.color = props.themeColors.bgColors.text_primary_color; 
       styles.checkboxAndRadioHorizontal["& legend"].color = props.themeColors.bgColors.text_primary_color; 
       styles.firstbox.background = props.themeColors.bgColors.primary_inner_bgcolor; 


       styles.userprobox.fontFamily = props.themeColors.bgColors.base_font; 
       styles.depositbtn.fontFamily = props.themeColors.bgColors.base_font; 
       styles.changepasbox["& label"].fontFamily = props.themeColors.bgColors.base_font; 
       styles.changepasbox["& input"].fontFamily = props.themeColors.bgColors.base_font; 
       styles.depositbtn.fontFamily = props.themeColors.bgColors.base_font; 
       styles.accountsbox["& h3"].fontFamily = props.themeColors.bgColors.base_font; 
       styles.addbtn.fontFamily = props.themeColors.bgColors.base_font; 
       styles.editprobox["& label"].fontFamily = props.themeColors.bgColors.base_font; 
       styles.editprobox["& input"].fontFamily = props.themeColors.bgColors.base_font; 
       styles.editprobox["& p"].fontFamily = props.themeColors.bgColors.base_font;  
       styles.changebankacc.fontFamily = props.themeColors.bgColors.base_font;  
       styles.addbankaccoutbox["& label"].fontFamily = props.themeColors.bgColors.base_font;  
       styles.addbankaccoutbox["& input"].fontFamily = props.themeColors.bgColors.base_font;  
       styles.ifscbtn.fontFamily = props.themeColors.bgColors.base_font; 
       styles.depositbtn2.fontFamily = props.themeColors.bgColors.base_font; 
       
    //    styles.pageheadinginner.fontFamily = props.themeColors.bgColors.base_font; 
    //    styles.selectboxx["& label"].fontFamily = props.themeColors.bgColors.base_font; 
    //    styles.formtodate["& label"].fontFamily = props.themeColors.bgColors.base_font; 
    //    styles.formtodate["& input"].fontFamily = props.themeColors.bgColors.base_font; 
    //    styles.verifyaccounttable["& table"].fontFamily = props.themeColors.bgColors.base_font; 
       //styles.checkboxAndRadio["& label"].color = props.themeColors.bgColors.text_primary_color; 
       
      //  styles.depositbtn2["& span"].color = props.themeColors.bgColors.goldenbtntext;
    } 
    // const { t } = useTranslation()
    const classes = useStyles();  
    // const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};  
    // const NEW_DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};  
    const [limitStartFrom, setLimitStartFrom] = React.useState(0);
    const limitMaxRange = 5;
    const [loadMore, setLoadMore] = React.useState(false);
    const [bankData, setBankData] = React.useState([{status: false}]);
    const [branchName, setBranchName] = React.useState("");
    const [bankName, setBankName] = React.useState("");
    var ifscCode = "";

    function ifscChange(event){
        
        if(event.target != null && event.target.value != null && event.target.value.length >= 11){
            ifscCode = event.target.value;
            fetchIFSCDetails();
        }else{
            ifscCode = event.target.value;
        }
        document.body
                .getElementsByClassName("message_addbank")[0] 
                .innerHTML=""; 
    }
    function fetchIFSCDetails(){
        if(ifscCode != null && ifscCode.length >= 11){
            document.body
            .getElementsByClassName("message_addbank")[0]
            .innerHTML=""
            const request = axios.post(globalurl.GETIFSCDETAILS , {
                token: window.localStorage.getItem('jwt_access_token'),
                ifsc_code: ifscCode
            });
            
            request.then((response) => {
                if(response.data.data != null){
                    var bankDetails = response.data.data;
                    if(bankDetails.branch != null){
                        setBranchName(bankDetails.branch);
                    }
                    if(bankDetails.bankname != null){
                        setBankName(bankDetails.bankname);
                    }
                }else{
                    document.body
                    .getElementsByClassName("message_addbank")[0] 
                    .innerHTML="Bank details not found as per IFSC. Please put details in fields."; 
                    setClassAdd(classes.msg = " errormsg" );
                }
            });
            request.catch((error) => {
                
            });
        }else{
            document.body
            .getElementsByClassName("message_addbank")[0] 
            .innerHTML="Please put correct IFSC Code."; 
            setClassAdd(classes.msg = " errormsg" );
        }
    }
   
    function showEditProfile() {
        document.body
            .getElementsByClassName("editProfileDiv")[0]
            .setAttribute("style", "display:block;");
        document.body
            .getElementsByClassName("userProfileDiv")[0]
            .setAttribute("style", "display:none;");
  }
      function showUserProfile() {
          document.body
              .getElementsByClassName("userProfileDiv")[0]
              .setAttribute("style", "display:block;"); 
              document.body
              .getElementsByClassName("editProfileDiv")[0]
              .setAttribute("style", "display:none;");
      } 
       function showEditNotification() {
        document.body
            .getElementsByClassName("editiconnotiboxwrap")[0]
            .setAttribute("style", "display:block;");
        document.body
            .getElementsByClassName("iconnotiboxwrap")[0]
            .setAttribute("style", "display:none;");
  }
      function showNotification() {
          document.body
              .getElementsByClassName("iconnotiboxwrap")[0]
              .setAttribute("style", "display:block;"); 
              document.body
              .getElementsByClassName("editiconnotiboxwrap")[0]
              .setAttribute("style", "display:none;");
      } 


      useEffect(() => {
            if ( addBank.error && (addBank.error.acc_number || addBank.error.acc_name || addBank.error.ifsc_code || addBank.error.bank_name || addBank.error.branch_name || addBank.error.message) )
            {
                if(addBank.error.message != null){
                    document.body
                    .getElementsByClassName("message_addbank")[0] 
                    .innerHTML=addBank.error.message   
                    setClassAdd(classes.msg = " errormsg" );
                //   alert(addBank.error.message);
                }else{
                    formRefBank.current.updateInputsWithError({
                        ...addBank.error
                    });
                    
                }
                disableButton();
                
            }else{
                if(addBank.data != null && addBank.data.status != null && addBank.data.status === "OK"){


                    setTimeout(function() {
                      //  window.location.reload();  
                    }, 5000); 

                    setClassAdd(classes.msg = " successmsg" )
                    if(formRefBank != null && formRefBank.current != null){
                        formRefBank.current.reset();
                    }
                    if(document.body.getElementsByClassName("message_addbank")[0] != null){
                        document.body
                        .getElementsByClassName("message_addbank")[0].innerHTML="Bank Account added successfully";
                    }
                }
                
            }
        }, [addBank.error, addBank.data, classes.msg]); 
        function disableButton()
        {
            setIsFormValid(false);
            document.body
            .getElementsByClassName("signupbtn")[0]
              .setAttribute("style", "opacity:0.5;");
        }
    
        function enableButton()
        {
            setIsFormValid(true);
            document.body
            .getElementsByClassName("signupbtn")[0]
              .setAttribute("style", "opacity:1;");
        }
        function handleSubmitProfile(model){
            dispatch(authActions.submitProfileInfo(model,props));
            document.body
            .getElementsByClassName("message_profile")[0]
            .innerHTML=" " 
        }
        function handleSubmit(model)
        { 
            dispatch(authActions.submitAddbank(model,props));
           // window.location.reload(false);
            setBankData([{status:false}]);
            document.body
            .getElementsByClassName("message_addbank")[0]
            .innerHTML=" " 
        } 
        function handleSubmitNotification(model){
            dispatch(authActions.submitProfileInfo(model,props));
            document.body
            .getElementsByClassName("message_notification")[0]
            .innerHTML=" " 
        }
        const isObject = function(a) {
            return (!!a) && (a.constructor === Object);
        };
        if((bankData[0].status == false && bankData[0].checked == null ) || (loadMore === true )){
         //   alert("sadads");
         if(bankData[0].status != null && bankData[0].status === true){
         }else{
            setBankData([{status:true}]);
         }
            setLoadMore(false);
            const request = axios.post(globalurl.BANKACCOUNTS , {
                token: window.localStorage.getItem('jwt_access_token'),
                LSTART: limitStartFrom,
                LEND: limitMaxRange
            });
            
            request.then((response) => {
             
                setLoadMore(false);
                var bankDb = "";
                if(response.data.data != null){
                    bankDb = [{
                        "status":true,
                        "data":response.data.data
                    }]
                    if(bankData[0].status != null && bankData[0].status === true){
                        bankDb[0].data = [...bankData[0].data, ...bankDb[0].data]
                        setBankData(bankDb);
                    }else{
                        setBankData(bankDb);
                    }
                }else{
                    bankDb = [{
                        "status":false,
                        "checked":false
                    }]   
                    if(bankData[0].status != null && bankData[0].status === true){
                        setLimitStartFrom((limitStartFrom - limitMaxRange));
                    }else{
                        setBankData(bankDb);
                    }
                }
            });
            request.catch((error) => {
                setLoadMore(false);
                var bankDb = [{
                    "status":false
                }]   
                setBankData(bankDb);
            });
        }
        var LoadMoreRecord = function(){
            setLimitStartFrom((limitStartFrom + limitMaxRange));
            setLoadMore(true);
        }  
        function addaccountf()
        {
            setIsFormValid(true);
            document.body
            .getElementsByClassName("addaccountform")[0]
              .setAttribute("style", "display:block;");
        }
        function deleteaccountf(bankerId) {
            var r = window.confirm("Do you really want to delete your bank account!");
            if (r === true) {
            const request = axios.post(globalurl.BANKACCOUNTDELETE , {
                token: window.localStorage.getItem('jwt_access_token'),
                banker_id: bankerId
             }); 
            
            request.then((response) => { 
             //   alert(JSON.stringify(response));
              
                if(response.data != null && response.data.status != null && response.data.status === true){
                    setBankData([{status:false}]); 
                }  
            });
            request.catch(() => { 
                 
            });
        }  
    }

    const [selectedTab, setSelectedTab] = React.useState("NOVALUE");
    var [currentPosition, setCurrentPosition] = React.useState(0);

    var tabIndex = 0;
    
    if((selectedTab === "NOVALUE" || selectedTabName !== props.selectedSubTab) && props.manualCheck === false){
        accountsDb[sessionStorage.getItem('lang')].forEach((jsondata) => 
         {
            if(jsondata.tagName === props.selectedSubTab){
                 selectedTabName = props.selectedSubTab;
                 setSelectedTab(tabIndex);
             }
             tabIndex++;
         }
     );
    }
    
    var clickedOnTabs = function(event){
        props.setTabMenu(true);
        selectedTabName = "change";
        setSelectedTab(event);
        setCurrentPosition(event);
        currentPosition = event;
   }

   const dateNow = new Date();
   const year = dateNow.getFullYear(); // Getting current year from the created Date object
    const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const month = // Setting current Month number from current Date object
        monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
        ? `0${monthWithOffset}`
        : monthWithOffset;
    const date =
        dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${dateNow.getUTCDate()}`
        : dateNow.getUTCDate();
   const MaxDateAvailable = `${year}-${month}-${date}`; 


   function getcountrydatalist(){
    //    alert("dsfdfsds");
    if( isCountryRunning === false){
        isCountryRunning = true;
        setCountryCode("PROCESSING");
        
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = "*";
        axios.defaults.headers.post['Access-Control-Allow-Methods'] = "GET, POST, OPTIONS, PUT, PATCH, DELETE";
        axios.defaults.headers.post['Access-Control-Allow-Headers'] = "rigin, X-Requested-With, Content-Type, Accept";
        axios.defaults.headers.common = {};
        axios.defaults.headers.common.accept = 'application/json';
        
        const request = axios.get(globalurl.WLCOUNTRY_DATA);
    
        request.catch((error) => {
            isCountryRunning = false;
            setCountryCode("0");
        }); 
        request.then((response) => { 
            isCountryRunning = false;
            //alert("hkjhh"+JSON.stringify(response));
            
            if(response.data.data != null){ 
                var myOutPut = (
                    response.data.data.map((jsondata) => (
                        <MenuItem value={jsondata.id} key={jsondata.id}>{jsondata.title} ({jsondata.phonecode})</MenuItem> 
                    ))
                );
                setCountryCode(myOutPut);
                //alert(JSON.stringify(gameType));
            };
        }); 
    }
             
    }   
    //alert(countryCode);   
    if(countryCode === "0"){
        setCountryCode("Processing");
        getcountrydatalist();
    }


    return (
        <div className="sporto">
            {(() =>  {if(formData[0].status === true && formData[0].data != null){
                var fullName = formData[0].data["name"].split(" ");
                var firstName = "";
                var lastName = "";
                if(fullName != null){
                    for(var nameRun=0;nameRun<fullName.length;nameRun++){
                        if(nameRun == 0){
                            firstName = fullName[nameRun];
                        }else if(fullName.length == (nameRun + 1) && nameRun > 0){
                            lastName = fullName[nameRun];
                        }else{
                            firstName = firstName + " " + fullName[nameRun];
                        }
                    }
                }
                
                return (  
                <div className={classes.firstwrap}>
                    <div className={classes.firstbox}>
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomTabsFirst
                        themeColors={props.themeColors}
                        plainTabs
                        border="round"
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        currentPosition={currentPosition}
                        clickEvent={clickedOnTabs}
                        tabs={accountsDb[sessionStorage.getItem('lang')].map((jsondata) => (
                            {
                            tabName: (<span>{jsondata.title}</span>),
                            tabContent: (
                                <div>
                                {(() =>  {
                                    if (jsondata.tagName === "tab1") {
        
                                        return (<div className={classes.accountsbox}>
                                         
                                            <div className={classes.userprobox + " userProfileDiv"}>
                                            <div className={classes.userprowrapp}>
                                                <GridContainer>
                                                        {jsondata.userprofilediv.map((jsondata) => (   
                                                            <GridItem xs={12} sm={5} md={6} key={jsondata.id}>
                                                                <p>{jsondata.display_name}</p> 
                                                                {/* {alert(JSON.stringify(formData))} */}
                                                                    {(()=>{
                                                                        if(jsondata.id === "date_of_birth" && formData[0].data.is_dob_confirmed === 1 && formData[0].data[jsondata.id] !== "0000-00-00"){
                                                                            return(
                                                                                <span>
                                                                                 {(new Date(formData[0].data[jsondata.id])).toLocaleDateString('fr-CA',{year:"numeric",month:"2-digit", day:"2-digit"})}   <span title="verifyed" className={classes.verifyed}></span>
                                                                                </span>
                                                                            )
                                                                        }if(jsondata.id === "date_of_birth" && formData[0].data.is_dob_confirmed === 0){
                                                                            return(
                                                                                <span>
                                                                                {(new Date(formData[0].data[jsondata.id])).toLocaleDateString('fr-CA',{year:"numeric",month:"2-digit", day:"2-digit"})} <span  title="Not Verified" className={classes.verify}></span>
                                                                                </span>
                                                                            )
                                                                            }if(jsondata.id === "date_of_birth" && formData[0].data[jsondata.id] !== "0000-00-00"){
                                                                                return(
                                                                                    <span>
                                                                                     N/a   
                                                                                    </span>
                                                                                )
                                                                        }else{
                                                                            if(jsondata.id === "email" && formData[0].data.is_email_confirm === 1){
                                                                                return(
                                                                                    <span>
                                                                                    {formData[0].data[jsondata.id]}  <span title="verified" className={((formData[0].data.is_email_confirm === 0) ? classes.verify : classes.verifyed)}></span>
                                                                                    </span>
                                                                                )
                                                                            }else if(jsondata.id === "email" && formData[0].data.is_email_confirm === 0){
                                                                                return(
                                                                                    <span>
                                                                                    {formData[0].data[jsondata.id]}  <span title="Not Verified" onClick={() => {VerifyEmail.openPopup("VerifyEmail")}} className={((formData[0].data.is_email_confirm === 0) ? classes.verify : classes.verifyed)}></span>
                                                                                    </span>
                                                                                )
                                                                            }else if(jsondata.id === "mobile" && formData[0].data.is_mobile_confirm === 1){
                                                                                return(
                                                                                    <span>
                                                                                    {formData[0].data[jsondata.id]}  <span title="verified" className={classes.verifyed}></span>
                                                                                    </span>
                                                                                )
                                                                            }else if(jsondata.id === "mobile" && formData[0].data.is_mobile_confirm === 0){
                                                                                return(
                                                                                    <span>
                                                                                    {formData[0].data[jsondata.id]}  <span title="Not Verified" onClick={() => {VerifyMobile.openPopup("VerifyMobile");}}  className={classes.verify}></span>
                                                                                    </span>
                                                                                )
                                                                            }else if(jsondata.id === "commission_type"){
                                                                                // return(
                                                                                //     <span>
                                                                                //     {(globalurl.COMMISSIONTYPE[formData[0].data[jsondata.id]] != null) ? globalurl.COMMISSIONTYPE[formData[0].data[jsondata.id]] : ""}
                                                                                //     </span>
                                                                                // )
                                                                            }else if(jsondata.id === "icon"){
                                                                                return(
                                                                                    <span>
                                                                                    <img className={classes.propic} src={formData[0].data[jsondata.id]} alt=""/>
                                                                                    </span>
                                                                                )
                                                                            }else{
                                                                                return(
                                                                                    <span>
                                                                                    {formData[0].data[jsondata.id]}
                                                                                    </span>
                                                                                )
                                                                            }
                                                                        }
                                                                    })()}
                                                                     <MobileOtpbox themeColors={props.themeColors} otpVerifyBy={"Mobile"} {...rest} />
                                                                     <VerifyMobile themeColors={props.themeColors} OtpBox={MobileOtpbox} mobileNumber={formData[0].data["mobile"]} {...rest} />
                                                                     <VerifyEmail themeColors={props.themeColors} emailId={formData[0].data["email"]} {...rest} />
                                                            </GridItem>
                                                        ))}   
                                                 </GridContainer>
                                                 </div>
                                                 <GridContainer> 
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <div className={classes.depositformbtni}>
                                                     
                                                        <Button
                                                            onClick={showEditProfile}
                                                            color="transparent"
                                                            className={classes.depositbtn}
                                                        >
                                                          {jsondata.editprofilebtn}   
                                                        </Button>
                                                        </div>
                                                    </GridItem>
                                                </GridContainer>
        
                                            </div>
                                            
                                            <div className={classes.editprobox + " editProfileDiv"}>
                                            <Formsy
                                                    onValidSubmit={handleSubmitProfile}
                                                    onValid={enableButton}
                                                    onInvalid={disableButton}
                                                    ref={formRef}
                                                    autoComplete={"off"}
                                                    className={classes.form + " flex flex-col justify-center w-full"}
                                                >
                                                <GridContainer>
                                                    <GridItem xs={12}><div className={classes.msg + " message_profile " + classAdd} id="message"></div></GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.formusername}
                                                            id="user_name"
                                                            name="user_name"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 2
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 2'
                                                            }} 
                                                            value={formData[0].data["username"]}
                                                            variant="outlined"
                                                            InputProps={{
                                                                readOnly: true,
                                                              }}
                                                            required
                                                        />   
                                                        
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.formfirstname}
                                                            id="first_name"
                                                            name="first_name"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 2
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 2'
                                                            }} 
                                                            value={firstName}
                                                            variant="outlined"
                                                            InputProps={{
                                                                readOnly: true,
                                                              }}
                                                            required
                                                        />   
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.formlastname}
                                                            id="last_name"
                                                            name="last_name"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 2
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 2'
                                                            }} 
                                                            value={lastName}
                                                            variant="outlined"
                                                            required   
                                                        />     
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.formemail}
                                                            id="email"
                                                            name="email"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 5,
                                                                isEmail: true
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 5',
                                                                isEmail: 'Please enter a valid email'
                                                            }}
                                                            InputProps={{
                                                                readOnly: true,
                                                              }}
                                                            value={formData[0].data["email"]}
                                                            variant="outlined"
                                                            required
                                                        />     
                                                    </GridItem>
                                                    
                                                    <GridItem xs={12} sm={12} md={6}> 
                                                        <div className={classes.formggroup}>
                                                            <SelectFormsy className={classes.selectboxxs + " selectbox"}
                                                                    
                                                                    name="country_id"
                                                                    label={jsondata.formcountry} 
                                                                    value="99"
                                                                    variant="outlined"
                                                                    required
                                                                >
                                                                    {countryCode}
                                                            </SelectFormsy>
                                                        </div>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.formphone}
                                                            id="mobile"
                                                            name="mobile"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 9,
                                                                isNumeric:true
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 9',
                                                                isNumeric:"Number Only"             
                                                            }} 
                                                            InputProps={{
                                                                readOnly: true,
                                                              }}
                                                            value={formData[0].data["mobile"]}
                                                            variant="outlined"
                                                            required
                                                        />     
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.formaddress}
                                                            id="address"
                                                            name="address"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 2
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 2'
                                                            }} 
                                                            value={formData[0].data["address"]}
                                                            variant="outlined"
                                                             
                                                        />    
                                                        
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}> 
                                                        <TextFieldFormsy  
                                                            label={jsondata.formdob}
                                                            name="date_of_birth"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            type="date" 
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            value={(new Date(formData[0].data["date_of_birth"])).toLocaleDateString('fr-CA',{year:"numeric",month:"2-digit", day:"2-digit"})}
                                                            InputProps={{inputProps: {  max: MaxDateAvailable} }}
                                                            
                                                            variant="outlined" 
                                                        />  
                                                        
                                                    </GridItem>
                                                     
                                                    
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.formcity}
                                                            id="city"
                                                            name="city"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 2
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 2'
                                                            }} 
                                                            value={formData[0].data["locality"]}
                                                            variant="outlined"
                                                             required
                                                        /> 
                                                        
                                                        
                                                    </GridItem>
                                                     
                                                    <GridItem xs={12} sm={12} md={6} style={{display:"none"}}>
                                                    <h6>Notify me about news and offers by:</h6>
                                                        
                                                        <RadioGroupFormsy
                                                            className={
                                                                classes.checkboxAndRadio +
                                                                " " +
                                                                classes.checkboxAndRadioHorizontal
                                                            }
                                                            name="internal_messages"
                                                            label="Internal Messages" 
                                                            validationError=""
                                                        >
                                                            <FormControlLabel value="0" control={<Radio color="primary"/>} label="Yes"/>
                                                            <FormControlLabel value="1" control={<Radio color="primary"/>} label="No"/> 
                                                        </RadioGroupFormsy>  

                                                        <RadioGroupFormsy
                                                            className={
                                                                classes.checkboxAndRadio +
                                                                " " +
                                                                classes.checkboxAndRadioHorizontal
                                                            }
                                                            name="push_notifications"
                                                            label="Push Notifications" 
                                                            validationError=""
                                                        >
                                                            <FormControlLabel value="0" control={<Radio color="primary"/>} label="Yes"/>
                                                            <FormControlLabel value="1" control={<Radio color="primary"/>} label="No"/> 
                                                        </RadioGroupFormsy>

                                                        <RadioGroupFormsy
                                                            className={
                                                                classes.checkboxAndRadio +
                                                                " " +
                                                                classes.checkboxAndRadioHorizontal
                                                            }
                                                            name="phone_call"
                                                            label="Phone Call" 
                                                            validationError=""
                                                        >
                                                            <FormControlLabel value="0" control={<Radio color="primary"/>} label="Yes"/>
                                                            <FormControlLabel value="1" control={<Radio color="primary"/>} label="No"/> 
                                                        </RadioGroupFormsy>

                                                        <RadioGroupFormsy
                                                            className={
                                                                classes.checkboxAndRadio +
                                                                " " +
                                                                classes.checkboxAndRadioHorizontal
                                                            }
                                                            name="email_notify"
                                                            label="Email" 
                                                            validationError=""
                                                        >
                                                            <FormControlLabel value="0" control={<Radio color="primary"/>} label="Yes"/>
                                                            <FormControlLabel value="1" control={<Radio color="primary"/>} label="No"/> 
                                                        </RadioGroupFormsy>

                                                        <RadioGroupFormsy
                                                            className={
                                                                classes.checkboxAndRadio +
                                                                " " +
                                                                classes.checkboxAndRadioHorizontal
                                                            }
                                                            name="sms_notify"
                                                            label="SMS" 
                                                            value="0"
                                                            validationError=""
                                                            required
                                                        >
                                                            <FormControlLabel value="0" control={<Radio color="primary"/>} label="Yes"/>
                                                            <FormControlLabel value="1" control={<Radio color="primary"/>} label="No"/> 
                                                        </RadioGroupFormsy>  
                                                             
                                                
                                                        
                                                    </GridItem> 
                                                 
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <p>*{jsondata.formp1}</p>
                                                        <p>*{jsondata.formp2}</p>
                                                        <p>*{jsondata.formp3}</p>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                    <div className={classes.depositformbtni}>
                                                        <Button
                                                            type="submit"
                                                            color="transparent" 
                                                            className={classes.depositbtn +" signupbtn"}
                                                            disabled={!isFormValid}
                                                        >
                                                            {jsondata.updateprofilebtn}        
                                                        </Button>
                                                        <Button
                                                            onClick={showUserProfile}
                                                            color="transparent"
                                                            className={classes.depositbtn}
                                                        >
                                                          {jsondata.cancelbtn}       
                                                        </Button>
                                                        </div>
                                                    </GridItem>
                                                </GridContainer>
                                                </Formsy>
                                            </div>
                                        </div>)
        
                                    }else if (jsondata.tagName === "tab2"){
                                        return (
                                            <div className={classes.changepasbox + " changePasswordDiv"}>
                                            <Formsy
                                                onValidSubmit={handleChangePasswordSubmit}
                                                onValid={enableChangePasswordButton}
                                                onInvalid={disableChangePasswordButton}
                                                ref={changePassFormRef}
                                                className={classes.form + " flex flex-col justify-center w-full"}
                                            >
                                            <GridContainer>
                                            <GridItem xs={12}><div className={classes.msg + " message_passs " + classAdd} id="message"></div></GridItem>
                                                
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <TextFieldFormsy 
                                                        label={jsondata.oldpassword}     
                                                        id="oldpassword"
                                                        name="oldpassword"
                                                        type="password"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        validations={{
                                                            minLength: 4
                                                        }} 
                                                        validationErrors={{
                                                            minLength: 'Min character length is 4'
                                                        }} 
                                                        variant="outlined"
                                                        required
                                                    />  
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <TextFieldFormsy 
                                                        label={jsondata.newpassword} 
                                                        id="newpassword"
                                                        name="newpassword"
                                                        type="password"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }} 
                                                        validations={{
                                                            minLength: 6,
                                                            maxLength: 20,
                                                            upperCase: function(value){
                                                                if(new RegExp(/^(?=.*[A-Z]).*$/).test(value.newpassword)){
                                                                    return true;
                                                                }else{
                                                                    return false;
                                                                }
                                                            },
                                                            specialCase: function(value){
                                                                if(new RegExp(/^(?=.*[!@#$&*]).*$/).test(value.newpassword)){
                                                                    return true;    
                                                                }else{
                                                                    return false;
                                                                }
                                                            },
                                                            numberCase: function(value){
                                                                if(new RegExp(/^(?=.*[0-9]).*$/).test(value.newpassword)){
                                                                    return true;
                                                                }else{
                                                                    return false;
                                                                }
                                                            },
                                                        }}
                                                        validationErrors={{
                                                            minLength: "Length should be minimum 6",
                                                            maxLength: "Length should not be greater 20",
                                                            upperCase: "Atleast one uppercase",
                                                            specialCase: "Atleast one special char",
                                                            numberCase: "Atleast one numeric char",
                                                        }}
                                                        variant="outlined"
                                                        required
                                                    />  
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <TextFieldFormsy 
                                                        label={jsondata.confirmpassword} 
                                                        id="confirmpassword"
                                                        name="confirmpassword"
                                                        type="password"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        validations={{ 
                                                            equalsField: "newpassword"
                                                        }} 
                                                        validationErrors={{ 
                                                            equalsField: 'Passwords do not match'
                                                        }} 
                                                        variant="outlined"
                                                        required
                                                    />   
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <Button
                                                       type="submit"
                                                        color="transparent" 
                                                        className={classes.depositbtn +" changepassbtn"}
                                                        disabled={!isChangePasswordFormValid}
                                                    >
                                                      {jsondata.updatepasswordbtn}    
                                                    </Button>
                                                    <Button
                                                        onClick={showUserProfile}
                                                        color="transparent"
                                                        className={classes.depositbtn}
                                                    >
                                                       {jsondata.cancelbtn}  
                                                    </Button>
                                                </GridItem>
                                            </GridContainer>
                                            </Formsy>              
                                        </div>
                                        )
        
                                    }else if (jsondata.tagName === "tab3"){
                                        return (<div className={classes.accountsbox}>
                                        {localDb[sessionStorage.getItem('lang')].map((jsondata) => (
                                            <h3 key="225" className={classes.accountsheads}> {jsondata.pageheading} 
                                            <Button 
                                                    href="#"
                                                    key="224"
                                                    color="transparent" 
                                                    onClick={addaccountf}
                                                    className={classes.addbtn} 
                                                >
                                                     {jsondata.addbankaccount} 
                                                </Button>
                                            </h3>
                                             ))}
                                            <div className={classes.addaccountform + " addaccountform"}>
                                            <Formsy
                                                    onValidSubmit={handleSubmit}
                                                    onValid={enableButton}
                                                    onInvalid={disableButton}
                                                    ref={formRefBank}
                                                    autoComplete={"off"}
                                                    className={classes.formaddaccount + " flex flex-col justify-center w-full"}
                                                >
                                            <div className={classes.addbankaccoutbox}>
                                            <p className={classes.changebankacc}> {jsondata.ptext}</p>
                                            <GridContainer>
                                            <GridItem xs={12}><div className={classes.msg + " message_addbank " + classAdd} id="message"></div></GridItem>
                                                    <GridItem xs={12} sm={12} md={4}>
                                                    <TextFieldFormsy   
                                                            label={jsondata.acountname}   
                                                            id="acountname"
                                                            name="acc_name"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 2
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 2'
                                                            }} 
                                                            variant="outlined"
                                                            required
                                                        />   
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4}>
                                                        <TextFieldFormsy  
                                                            label={jsondata.ifsccode} 
                                                            id="ifsccode"
                                                            name="ifsc_code"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 6
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 6'
                                                            }} 
                                                            onChange={ifscChange}
                                                            variant="outlined"
                                                            required
                                                        />   
                                                <Button
                                                    color="transparent"
                                                    type="button"
                                                    className={classes.ifscbtn}
                                                    onClick={fetchIFSCDetails}
                                                >
                                                    {jsondata.whyifsc} 
                                                </Button>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4}>  
                                                    <TextFieldFormsy 
                                                            label={jsondata.banknameoption}
                                                            id="bank_name"
                                                            name="bank_name"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 2
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 2'
                                                            }} 
                                                            value={bankName}
                                                            variant="outlined"
                                                            required
                                                        />  
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4}>
                                                        <TextFieldFormsy 
                                                            label={jsondata.bankbranch}
                                                            id="bankbranch"
                                                            name="branch_name"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 3
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 3'
                                                            }} 
                                                            value={branchName}
                                                            variant="outlined"
                                                            required
                                                        /> 
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4}>
                                                    <TextFieldFormsy 
                                                            label={jsondata.accountnumber}
                                                            id="accountnumber"
                                                            name="acc_number"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            validations={{
                                                                minLength: 4
                                                            }} 
                                                            validationErrors={{
                                                                minLength: 'Min character length is 4'
                                                            }} 
                                                            variant="outlined"
                                                            required
                                                        />  
                                                </GridItem>
                                                
                                                <GridItem xs={12} sm={12} md={12}>
                                                <div className={classes.depositformbtni + " bordernone"}> 
                                                <Button 
                                                    type="submit"
                                                    color="transparent" 
                                                    className={classes.depositbtn2 +" signupbtn"}
                                                    disabled={!isFormValid}
                                                >
                                                   {jsondata.myaccountbtn}
                                                </Button></div>
                                                </GridItem>
                                                </GridContainer>
                                            </div>
                                            </Formsy>
                                            </div>
                                            <div className={classes.depositlistwarp}>
                                           




                                            {(() => {
                                            if(bankData[0].status != null && bankData[0].status === true){
                                                
                                                return (<div id="nav-tabs" className={classes.depositlistw} >
                                                {bankData.map((jsondata) => (
                                                        <div className={classes.messagebox} key={":myunique_1"}>
                                                            <List className={classes.list}>
                                                                <ListItem className={classes.listItem + " " + classes.tableheading}>
                                                                    <GridContainer>
                                                                        {localDb[sessionStorage.getItem('lang')][0].tableheader.map((headData) => (
                                                                        <GridItem xs={headData.xs} sm={headData.xs} md={headData.xs} key={headData.id}>
                                                                            <p className={classes.promotionhead}>{headData.field_name}</p>
                                                                        </GridItem>
                                                                        ))} 
                                                                    </GridContainer>
                                                                </ListItem>
                                                                
                                                                {(() => {if(Array.isArray(jsondata.data)){return (jsondata.data.map((bodyData) => (
                                                                    <ListItem className={classes.listItem} key={bodyData.id}>
                                                                        <GridContainer>
                                                                            <GridItem xs={2} sm={2} md={2}>
                                                                                <p className={classes.promotioncont}>{bodyData.acc_name}</p>
                                                                            </GridItem>
                                                                            <GridItem xs={2} sm={2} md={2}>
                                                                                <p className={classes.promotioncont}>{bodyData.acc_number}</p>
                                                                            </GridItem>
                                                                            <GridItem xs={2} sm={2} md={2}>
                                                                                <p className={classes.promotioncont}>{bodyData.ifsc_code}</p>
                                                                            </GridItem>
                                                                            <GridItem xs={2} sm={2} md={2}>
                                                                                <p className={classes.promotioncont}>{bodyData.bank_name}</p>
                                                                            </GridItem>
                                                                            <GridItem xs={2} sm={2} md={2}>
                                                                                <p className={classes.promotioncont}>{bodyData.branch_name}</p>
                                                                            </GridItem>
                                                                            <GridItem xs={1} sm={1} md={1}>
                                                                                <p className={classes.promotioncont}>{bodyData.branch_address}</p>
                                                                            </GridItem>
                                                                            <GridItem xs={1} sm={1} md={1}>
                                                                                <Button
                                                                                    href="#"
                                                                                    color="transparent"
                                                                                    onClick={() => deleteaccountf(bodyData.id)}
                                                                                    className={classes.messagedeletebtn}
                                                                                >
                                                                                    <i className={"fa fa-trash"}></i>
                                                                                </Button>
                                                                            </GridItem>
                                                                        </GridContainer>
                                                                    </ListItem>
                                                                )))}else if(isObject(jsondata.data)){return(
                                                                <ListItem className={classes.listItem}>
                                                                    <GridContainer>
                                                                        <GridItem xs={2} sm={2} md={2}>
                                                                            <p className={classes.promotioncont}>{jsondata.data.acc_name}</p>
                                                                        </GridItem>
                                                                        <GridItem xs={2} sm={2} md={2}>
                                                                            <p className={classes.promotioncont}>{jsondata.data.acc_number}</p>
                                                                        </GridItem>
                                                                        <GridItem xs={2} sm={2} md={2}>
                                                                            <p className={classes.promotioncont}>{jsondata.data.ifsc_code}</p>
                                                                        </GridItem>
                                                                        <GridItem xs={2} sm={2} md={2}>
                                                                            <p className={classes.promotioncont}>{jsondata.data.bank_name}</p>
                                                                        </GridItem>
                                                                        <GridItem xs={2} sm={2} md={2}>
                                                                            <p className={classes.promotioncont}>{jsondata.data.branch_name}</p>
                                                                        </GridItem>
                                                                        <GridItem xs={1} sm={1} md={1}>
                                                                            <p className={classes.promotioncont}>{jsondata.data.branch_address}</p>
                                                                        </GridItem>
                                                                        <GridItem xs={1} sm={1} md={1}>
                                                                            <Button
                                                                                href="#"
                                                                                color="transparent"
                                                                                onClick={() => deleteaccountf(jsondata.data.id)}
                                                                                className={classes.messagedeletebtn}
                                                                            >
                                                                                <i className={"fa fa-trash"}></i>
                                                                            </Button>
                                                                        </GridItem>
                                                                    </GridContainer>
                                                                </ListItem>
                                                                )}else{
                                                                    return(
                                                                        <ListItem className={classes.listItem} key={"statement_nodata"}>
                                                                            <GridContainer>
                                                                                <GridItem xs={12} sm={12} md={12}>
                                                                                    <p className={classes.promotioncont}><img src={Norecord} className={classes.norecordimg + " norecordimg"} style={{display:"block", margin:"0px auto"}} alt=""/></p>
                                                                                </GridItem>
                                                                            </GridContainer>
                                                                        </ListItem>
                                                                    )
                                                                }})()}
                                                            </List>
                                                           {localDb[sessionStorage.getItem('lang')].map((jsondata) => (
                                                            
                                                            <Button
                                                                href=""
                                                                key="223"
                                                                color="transparent"
                                                                className={classes.depositbtn}
                                                                onClick={LoadMoreRecord}
                                                            >
                                                             {jsondata.loadmore}
                                                            </Button> 
                                                             ))}
                                                        </div>
                                                    ))}
                                            </div>);
                                            }else{
                                                return (
                                                    <ListItem className={classes.listItem} key={"statement_nodata"}>
                                                        <GridContainer>
                                                            <GridItem xs={12} sm={12} md={12}>
                                                                <p className={classes.promotioncont}><img src={Norecord} className={classes.norecordimg + " norecordimg"} style={{display:"block", margin:"0px auto"}} alt=""/></p>
                                                            </GridItem>
                                                        </GridContainer>
                                                    </ListItem>
                                                    );
                                            }
                                        })()}




                                            </div>
                                        </div> 
                                        )
        
                                    }else if (jsondata.tagName === "tab4"){
                                        return (<div className={classes.accountsbox}>
                                           <KycModule themeColors={props.themeColors} {...rest} />
                                        </div> 
                                        )
        
                                    }else if (jsondata.tagName === "tab5"){
                                        return (<div className={classes.accountsbox}>
                                           <LoginHistory themeColors={props.themeColors} {...rest} />
                                        </div> 
                                        )
        
                                    }else if (jsondata.tagName === "tab6"){
                                        return ( 
                                            
                                        <div className={classes.accountsbox}>
                                            
                                            <div className={classes.iconnotiboxwrap + " iconnotiboxwrap"}>
                                            
                                             
                                                 <GridContainer> 
                                                 {tabnotificationDb[sessionStorage.getItem('lang')].map((jsondata) => (
                                                <GridItem xs={12} sm={6} md={3}>
                                                    
                                                    <div className={classes.iconnotibox + " " + jsondata.noti_disable}>
                                                        <i className={jsondata.noti_icon}></i>
                                                        {jsondata.noti_name}
                                                    </div>
                                                     
                                                </GridItem> 
                                                 ))}
                                                </GridContainer>
                                             
                                            
                                            <GridContainer> 
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <div className={classes.depositformbtni}>
                                                    {tabnotificationeditDb[sessionStorage.getItem('lang')].map((jsondata) => (
                                                    <Button
                                                        onClick={showEditNotification}
                                                        color="transparent"
                                                        className={classes.depositbtn}
                                                    >
                                                       {jsondata.editnotification} 
                                                    </Button>

                                                    ))}
                                                    </div>
                                                </GridItem>
                                            </GridContainer>
                                            </div>
                                       
                                            <div className={classes.editiconnotiboxwrap + " editiconnotiboxwrap"}>
                                            <Formsy 
                                                    onValidSubmit={handleSubmitNotification}
                                                    onValid={enableButton}
                                                    onInvalid={disableButton}
                                                    ref={formRefNoti}
                                                    autoComplete={"off"}
                                                    className={classes.form + " flex flex-col justify-center w-full"}
                                                >
                                                    {tabnotificationeditDb[sessionStorage.getItem('lang')].map((jsondata) => (
                                                <GridContainer>
                                                    <GridItem xs={12}><div className={classes.msg + " message_notification " + classAdd} id="message"></div></GridItem>
                                                         
                                                    <GridItem xs={12} sm={12} md={6}  >  
                                                        <RadioGroupFormsy
                                                            className={
                                                                classes.checkboxAndRadio +
                                                                " inrediobn " +
                                                                classes.checkboxAndRadioHorizontal
                                                            }
                                                            value="1"
                                                            name="internal_messages"
                                                            label={jsondata.noti_name1}
                                                            validationError=""
                                                            required
                                                        >
                                                            <FormControlLabel value="0" control={<Radio color="primary"/>} label={jsondata.noti_yes} />
                                                            <FormControlLabel value="1" control={<Radio color="primary"/>} label={jsondata.noti_no}/> 
                                                        </RadioGroupFormsy>  

                                                        <RadioGroupFormsy
                                                            className={
                                                                classes.checkboxAndRadio +
                                                                " inrediobn " +
                                                                classes.checkboxAndRadioHorizontal
                                                            }
                                                            value="0"
                                                            name="push_notifications"
                                                            label={jsondata.noti_name2}
                                                            validationError=""
                                                            required
                                                        >
                                                            <FormControlLabel value="0" control={<Radio color="primary"/>} label={jsondata.noti_yes} />
                                                            <FormControlLabel value="1" control={<Radio color="primary"/>} label={jsondata.noti_no}/> 
                                                        </RadioGroupFormsy>

                                                        <RadioGroupFormsy
                                                            className={
                                                                classes.checkboxAndRadio +
                                                                " inrediobn " +
                                                                classes.checkboxAndRadioHorizontal
                                                            }
                                                            value="1"
                                                            name="phone_call"
                                                            label={jsondata.noti_name3}
                                                            validationError=""
                                                            required
                                                        >
                                                            <FormControlLabel value="0" control={<Radio color="primary"/>} label={jsondata.noti_yes} />
                                                            <FormControlLabel value="1" control={<Radio color="primary"/>} label={jsondata.noti_no}/> 
                                                        </RadioGroupFormsy>

                                                        <RadioGroupFormsy
                                                            className={
                                                                classes.checkboxAndRadio +
                                                                " inrediobn " +
                                                                classes.checkboxAndRadioHorizontal
                                                            }
                                                            value="0"
                                                            name="email_notify"
                                                            label={jsondata.noti_name4}
                                                            validationError=""
                                                            required
                                                        >
                                                            <FormControlLabel value="0" control={<Radio color="primary"/>} label={jsondata.noti_yes} />
                                                            <FormControlLabel value="1" control={<Radio color="primary"/>} label={jsondata.noti_no}/> 
                                                        </RadioGroupFormsy>

                                                        <RadioGroupFormsy
                                                            className={
                                                                classes.checkboxAndRadio +
                                                                " inrediobn " +
                                                                classes.checkboxAndRadioHorizontal
                                                            }
                                                            name="sms_notify"
                                                            label={jsondata.noti_name5}
                                                            value="0"
                                                            validationError=""
                                                            required
                                                        >
                                                            <FormControlLabel value="0" control={<Radio color="primary"/>} label={jsondata.noti_yes} />
                                                            <FormControlLabel value="1" control={<Radio color="primary"/>} label={jsondata.noti_no}/> 
                                                        </RadioGroupFormsy>  
                                                              
                                                    </GridItem> 
                                                 
                                                    <GridItem xs={12} sm={12} md={6}>
                                                    <RadioGroupFormsy
                                                            className={
                                                                classes.checkboxAndRadio +
                                                                " inrediobn " +
                                                                classes.checkboxAndRadioHorizontal
                                                            }
                                                            value="0"
                                                            name="internal_messages"
                                                            label={jsondata.noti_name1}
                                                            validationError=""
                                                            required
                                                        >
                                                            <FormControlLabel value="0" control={<Radio color="primary"/>} label={jsondata.noti_yes} />
                                                            <FormControlLabel value="1" control={<Radio color="primary"/>} label={jsondata.noti_no}/> 
                                                        </RadioGroupFormsy>  

                                                        <RadioGroupFormsy
                                                            className={
                                                                classes.checkboxAndRadio +
                                                                " inrediobn " +
                                                                classes.checkboxAndRadioHorizontal
                                                            }
                                                            value="0"
                                                            name="push_notifications"
                                                            label={jsondata.noti_name2}
                                                            validationError=""
                                                            required
                                                        >
                                                            <FormControlLabel value="0" control={<Radio color="primary"/>} label={jsondata.noti_yes} />
                                                            <FormControlLabel value="1" control={<Radio color="primary"/>} label={jsondata.noti_no}/> 
                                                        </RadioGroupFormsy>

                                                        <RadioGroupFormsy
                                                            className={
                                                                classes.checkboxAndRadio +
                                                                " inrediobn " +
                                                                classes.checkboxAndRadioHorizontal
                                                            }
                                                            value="0"
                                                            name="phone_call"
                                                            label={jsondata.noti_name3}
                                                            validationError=""
                                                            required
                                                        >
                                                            <FormControlLabel value="0" control={<Radio color="primary"/>} label={jsondata.noti_yes} />
                                                            <FormControlLabel value="1" control={<Radio color="primary"/>} label={jsondata.noti_no}/> 
                                                        </RadioGroupFormsy>

                                                        <RadioGroupFormsy
                                                            className={
                                                                classes.checkboxAndRadio +
                                                                " inrediobn " +
                                                                classes.checkboxAndRadioHorizontal
                                                            }
                                                            value="0"
                                                            name="email_notify"
                                                            label={jsondata.noti_name4}
                                                            validationError=""
                                                            required
                                                        >
                                                            <FormControlLabel value="0" control={<Radio color="primary"/>} label={jsondata.noti_yes} />
                                                            <FormControlLabel value="1" control={<Radio color="primary"/>} label={jsondata.noti_no}/>  
                                                        </RadioGroupFormsy>

                                                        <RadioGroupFormsy
                                                            className={
                                                                classes.checkboxAndRadio +
                                                                " inrediobn " +
                                                                classes.checkboxAndRadioHorizontal
                                                            }
                                                            name="sms_notify"
                                                            label={jsondata.noti_name5}
                                                            value="0"
                                                            validationError=""
                                                            required
                                                        >
                                                            <FormControlLabel value="0" control={<Radio color="primary"/>} label={jsondata.noti_yes} />
                                                            <FormControlLabel value="1" control={<Radio color="primary"/>} label={jsondata.noti_no}/> 
                                                        </RadioGroupFormsy>    
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                    <div className={classes.depositformbtni}>
                                                        <Button
                                                            type="submit"
                                                            color="transparent" 
                                                            className={classes.depositbtn +" signupbtn"}
                                                            disabled={!isFormValid}
                                                        >
                                                            {jsondata.submitbtn}       
                                                        </Button>
                                                        <Button
                                                            onClick={showNotification}
                                                            color="transparent"
                                                            className={classes.depositbtn}
                                                        >
                                                         {jsondata.cancelbtn}    
                                                        </Button>
                                                        </div>
                                                    </GridItem>
                                                </GridContainer>
                                                    ))}
                                                </Formsy>
                                            </div>

                                        </div> 
                                             
                                       )
        
                                    }

                                })()}
                                    </div>
        
                            )
                            }
        
                        ))}
                        />
                    </GridItem>
                    </GridContainer>
                    </div>
                </div>
            
                )
            }})()}
      </div>
    );
  }
  
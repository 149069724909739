import { container } from "../../casino-kit-react.js";
const Promotions = {
    signinnerpagebox:{
        background:"#0f0f0f",
        padding:"0px 20px 0px",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            padding:"90px 20px 0px",
          },
    },
    successwrap:{
        background: "#082608",
        width: "80%",
        margin: "0px auto",
        borderRadius: "10px",
        marginTop: "10px",
        padding: "0 20px 20px 20px",
    },
    failwrap:{
        background: "#490f0f",
        width: "80%",
        margin: "0px auto",
        borderRadius: "10px",
        marginTop: "10px",
        padding: "0 20px 20px 20px",
    },
    tablebox:{
        width:"100%",
        margin:"20px 0",
        background: "rgba(0,0,0,0.3)",
        padding: "10px",
        borderRadius: "10px",
    },
    signinnerpageboxin:{
        background:"#131212",
        margin:"0px 0px 0px",
        borderRadius:"10px"
    },
    section: {
        background: "#EEEEEE",
        padding: "70px 0"
    },
    messagebox:{
        background: "#252f5a",
        padding:"0px 0px",
        borderRadius:"10px",
    }, 
    list:{
      width:"100%",
      display:"block"
    },
    listItem:{
        width:"100%",
        paddingTop:"15px",
        paddingBottom:"15px",
        display:"block",
        color:"#a1aed4",
        fontFamily:"ubuntu",
        fontSize:"18px",
        "& a":{
            margin:"0px",
        },
        "& p":{
            margin:"0px",
        },
        "&:nth-child(even)":{
            background:"#364275"
        },
        "&:nth-child(odd)":{
            background:"none"
        }
    },
    promotionhead:{
        textAlign: "left",
        padding:"0px",
        margin:"0px",
        color:"#fff",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            fontSize:"12px",
        },
    },
    promotioncont:{
        textAlign: "left",
        padding:"0px",
        margin:"0px",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            fontSize:"12px",
        },
    },
    messagedetalbtn:{
        padding:"0px",
        fontSize:"18px",
        fontFamily:"ubuntu",
        textAlign: "left",
        display:"block",
        textTransform:"capitalize",
        "& i":{
            marginTop:"-3px",
        }
    },
    messagedeletebtn:{
        padding:"0px",
        textAlign:"center",
        fontSize:"18px",
    },
    container,
    textCenter: {
        textAlign: "center"
    }
};

export default Promotions;

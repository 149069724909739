


const messageDb = {
    en: [
        {
            'title':"System Bulletin",
            'tagName': 'tab1',
            'messages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'subject'  : 'UG game maintenance ',
                    'messagedate' :'2020-01-17 10:32:44',
                    'subject_full'  : 'UG game maintenance ',
                    'discription'  : 'Dear Gamers, SSG Game Maintenance starts from 11:30 AM to 07:30 PM (IST) on 18/02/2020. The maintenance time can be extended according to need. Sorry for the inconvenience caused. For more information, please contact our customer support in Live chat and WhatsApp/ Telegram us at +91 1234567890. Best regards, iSoft Gaming Customer Support',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'subject'  : 'Casino Routine Maintenance ',
                    'messagedate' :'2020-01-17 11:32:44',
                    'subject_full'  : 'UG game maintenance ',
                    'discription'  : 'Dear Gamers, SSG Game Maintenance starts from 11:30 AM to 07:30 PM (IST) on 18/02/2020. The maintenance time can be extended according to need. Sorry for the inconvenience caused. For more information, please contact our customer support in Live chat and WhatsApp/ Telegram us at +91 1234567890. Best regards, iSoft Gaming Customer Support',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'subject'  : 'SEXY Game Platform Maintenance ',
                    'messagedate' :'2020-01-17 09:32:44',
                    'subject_full'  : 'UG game maintenance ',
                    'discription'  : 'Dear Gamers, SSG Game Maintenance starts from 11:30 AM to 07:30 PM (IST) on 18/02/2020. The maintenance time can be extended according to need. Sorry for the inconvenience caused. For more information, please contact our customer support in Live chat and WhatsApp/ Telegram us at +91 1234567890. Best regards, iSoft Gaming Customer Support',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '4',
                    'subject'  : 'SA Game Maintenance ',
                    'messagedate' :'2020-01-17 18:32:44',
                    'subject_full'  : 'UG game maintenance ',
                    'discription'  : 'Dear Gamers, SSG Game Maintenance starts from 11:30 AM to 07:30 PM (IST) on 18/02/2020. The maintenance time can be extended according to need. Sorry for the inconvenience caused. For more information, please contact our customer support in Live chat and WhatsApp/ Telegram us at +91 1234567890. Best regards, iSoft Gaming Customer Support',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '5',
                    'subject'  : 'CQ9 Game Maintenance.',
                    'messagedate' :'2020-01-17 07:32:44',
                    'subject_full'  : 'UG game maintenance ',
                    'discription'  : 'Dear Gamers, SSG Game Maintenance starts from 11:30 AM to 07:30 PM (IST) on 18/02/2020. The maintenance time can be extended according to need. Sorry for the inconvenience caused. For more information, please contact our customer support in Live chat and WhatsApp/ Telegram us at +91 1234567890. Best regards, iSoft Gaming Customer Support',
                },
            ]
        },
        {
            'title':"Event Announcement",
            'tagName': 'tab2',
            'messages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'subject'  : 'UG game maintenance ',
                    'messagedate' :'2020-01-17 10:32:44',
                    'subject_full'  : 'UG game maintenance ',
                    'discription'  : 'Dear Gamers, SSG Game Maintenance starts from 11:30 AM to 07:30 PM (IST) on 18/02/2020. The maintenance time can be extended according to need. Sorry for the inconvenience caused. For more information, please contact our customer support in Live chat and WhatsApp/ Telegram us at +91 1234567890. Best regards, iSoft Gaming Customer Support',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'subject'  : 'Casino Routine Maintenance ',
                    'messagedate' :'2020-01-17 11:32:44',
                    'subject_full'  : 'UG game maintenance ',
                    'discription'  : 'Dear Gamers, SSG Game Maintenance starts from 11:30 AM to 07:30 PM (IST) on 18/02/2020. The maintenance time can be extended according to need. Sorry for the inconvenience caused. For more information, please contact our customer support in Live chat and WhatsApp/ Telegram us at +91 1234567890. Best regards, iSoft Gaming Customer Support',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'subject'  : 'SEXY Game Platform Maintenance ',
                    'messagedate' :'2020-01-17 09:32:44', 
                    'subject_full'  : 'UG game maintenance ',
                    'discription'  : 'Dear Gamers, SSG Game Maintenance starts from 11:30 AM to 07:30 PM (IST) on 18/02/2020. The maintenance time can be extended according to need. Sorry for the inconvenience caused. For more information, please contact our customer support in Live chat and WhatsApp/ Telegram us at +91 1234567890. Best regards, iSoft Gaming Customer Support',
                },
            ]
        },
        {
            'title':"Personal Message",
            'tagName': 'tab3',
            'messages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'subject'  : 'UG game maintenance ',
                    'messagedate' :'2020-01-17 10:32:44',
                    'subject_full'  : 'UG game maintenance ',
                    'discription'  : 'Dear Gamers, SSG Game Maintenance starts from 11:30 AM to 07:30 PM (IST) on 18/02/2020. The maintenance time can be extended according to need. Sorry for the inconvenience caused. For more information, please contact our customer support in Live chat and WhatsApp/ Telegram us at +91 1234567890. Best regards, iSoft Gaming Customer Support',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'subject'  : 'Casino Routine Maintenance ',
                    'messagedate' :'2020-01-17 11:32:44',
                    'subject_full'  : 'UG game maintenance ',
                    'discription'  : 'Dear Gamers, SSG Game Maintenance starts from 11:30 AM to 07:30 PM (IST) on 18/02/2020. The maintenance time can be extended according to need. Sorry for the inconvenience caused. For more information, please contact our customer support in Live chat and WhatsApp/ Telegram us at +91 1234567890. Best regards, iSoft Gaming Customer Support',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'subject'  : 'SEXY Game Platform Maintenance ',
                    'messagedate' :'2020-01-17 09:32:44',
                    'subject_full'  : 'UG game maintenance ',
                    'discription'  : 'Dear Gamers, SSG Game Maintenance starts from 11:30 AM to 07:30 PM (IST) on 18/02/2020. The maintenance time can be extended according to need. Sorry for the inconvenience caused. For more information, please contact our customer support in Live chat and WhatsApp/ Telegram us at +91 1234567890. Best regards, iSoft Gaming Customer Support',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '4',
                    'subject'  : 'SA Game Maintenance ',
                    'messagedate' :'2020-01-17 18:32:44',
                    'subject_full'  : 'UG game maintenance ',
                    'discription'  : 'Dear Gamers, SSG Game Maintenance starts from 11:30 AM to 07:30 PM (IST) on 18/02/2020. The maintenance time can be extended according to need. Sorry for the inconvenience caused. For more information, please contact our customer support in Live chat and WhatsApp/ Telegram us at +91 1234567890. Best regards, iSoft Gaming Customer Support',
                },
            ]
        },
    ],
    tm: [
        {
            'title':"கணினி புல்லட்டின்",
            'tagName': 'tab1',
            'messages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'subject'  : 'யுஜி விளையாட்டு பராமரிப்பு ',
                    'messagedate' :'2020-01-17 10:32:44',
                    'subject_full'  : 'யுஜி விளையாட்டு பராமரிப்பு ',
                    'discription'  : 'அன்புள்ள விளையாட்டாளர்களே, எஸ்.எஸ்.ஜி விளையாட்டு பராமரிப்பு 18/02/2020 அன்று காலை 11:30 மணி முதல் மாலை 07:30 மணி வரை (IST) தொடங்குகிறது. பராமரிப்பு நேரத்தை தேவைக்கேற்ப நீட்டிக்க முடியும். ஏற்பட்ட சிரமமத்திற்கு வருந்துகிறேன். மேலும் தகவலுக்கு, எங்கள் வாடிக்கையாளர் ஆதரவை லைவ் அரட்டை மற்றும் வாட்ஸ்அப் / டெலிகிராமில் +91 1234567890 என்ற எண்ணில் தொடர்பு கொள்ளவும். வாழ்த்துக்கள், ஐசாஃப்ட் கேமிங் வாடிக்கையாளர் ஆதரவு',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'subject'  : 'கேசினோ வழக்கமான பராமரிப்பு',
                    'messagedate' :'2020-01-17 11:32:44',
                    'subject_full'  : 'யுஜி விளையாட்டு பராமரிப்பு ',
                    'discription'  : 'அன்புள்ள விளையாட்டாளர்களே, எஸ்.எஸ்.ஜி விளையாட்டு பராமரிப்பு 18/02/2020 அன்று காலை 11:30 மணி முதல் மாலை 07:30 மணி வரை (IST) தொடங்குகிறது. பராமரிப்பு நேரத்தை தேவைக்கேற்ப நீட்டிக்க முடியும். ஏற்பட்ட சிரமமத்திற்கு வருந்துகிறேன். மேலும் தகவலுக்கு, எங்கள் வாடிக்கையாளர் ஆதரவை லைவ் அரட்டை மற்றும் வாட்ஸ்அப் / டெலிகிராமில் +91 1234567890 என்ற எண்ணில் தொடர்பு கொள்ளவும். வாழ்த்துக்கள், ஐசாஃப்ட் கேமிங் வாடிக்கையாளர் ஆதரவு',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'subject'  : 'கவர்ச்சியான விளையாட்டு இயங்குதள பராமரிப்பு',
                    'messagedate' :'2020-01-17 09:32:44',
                    'subject_full'  : 'யுஜி விளையாட்டு பராமரிப்பு ',
                    'discription'  : 'அன்புள்ள விளையாட்டாளர்களே, எஸ்.எஸ்.ஜி விளையாட்டு பராமரிப்பு 18/02/2020 அன்று காலை 11:30 மணி முதல் மாலை 07:30 மணி வரை (IST) தொடங்குகிறது. பராமரிப்பு நேரத்தை தேவைக்கேற்ப நீட்டிக்க முடியும். ஏற்பட்ட சிரமமத்திற்கு வருந்துகிறேன். மேலும் தகவலுக்கு, எங்கள் வாடிக்கையாளர் ஆதரவை லைவ் அரட்டை மற்றும் வாட்ஸ்அப் / டெலிகிராமில் +91 1234567890 என்ற எண்ணில் தொடர்பு கொள்ளவும். வாழ்த்துக்கள், ஐசாஃப்ட் கேமிங் வாடிக்கையாளர் ஆதரவு',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '4',
                    'subject'  : 'எஸ்.ஏ விளையாட்டு பராமரிப்பு ',
                    'messagedate' :'2020-01-17 18:32:44',
                    'subject_full'  : 'யுஜி விளையாட்டு பராமரிப்பு ',
                    'discription'  : 'அன்புள்ள விளையாட்டாளர்களே, எஸ்.எஸ்.ஜி விளையாட்டு பராமரிப்பு 18/02/2020 அன்று காலை 11:30 மணி முதல் மாலை 07:30 மணி வரை (IST) தொடங்குகிறது. பராமரிப்பு நேரத்தை தேவைக்கேற்ப நீட்டிக்க முடியும். ஏற்பட்ட சிரமமத்திற்கு வருந்துகிறேன். மேலும் தகவலுக்கு, எங்கள் வாடிக்கையாளர் ஆதரவை லைவ் அரட்டை மற்றும் வாட்ஸ்அப் / டெலிகிராமில் +91 1234567890 என்ற எண்ணில் தொடர்பு கொள்ளவும். வாழ்த்துக்கள், ஐசாஃப்ட் கேமிங் வாடிக்கையாளர் ஆதரவு',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '5',
                    'subject'  : 'CQ9 விளையாட்டு பராமரிப்பு.',
                    'messagedate' :'2020-01-17 07:32:44',
                    'subject_full'  : 'யுஜி விளையாட்டு பராமரிப்பு ',
                    'discription'  : 'அன்புள்ள விளையாட்டாளர்களே, எஸ்.எஸ்.ஜி விளையாட்டு பராமரிப்பு 18/02/2020 அன்று காலை 11:30 மணி முதல் மாலை 07:30 மணி வரை (IST) தொடங்குகிறது. பராமரிப்பு நேரத்தை தேவைக்கேற்ப நீட்டிக்க முடியும். ஏற்பட்ட சிரமமத்திற்கு வருந்துகிறேன். மேலும் தகவலுக்கு, எங்கள் வாடிக்கையாளர் ஆதரவை லைவ் அரட்டை மற்றும் வாட்ஸ்அப் / டெலிகிராமில் +91 1234567890 என்ற எண்ணில் தொடர்பு கொள்ளவும். வாழ்த்துக்கள், ஐசாஃப்ட் கேமிங் வாடிக்கையாளர் ஆதரவு',
                },
            ]
        },
        {
            'title':"நிகழ்வு அறிவிப்பு",
            'tagName': 'tab2',
            'messages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'subject'  : 'யுஜி விளையாட்டு பராமரிப்பு ',
                    'messagedate' :'2020-01-17 10:32:44',
                    'subject_full'  : 'யுஜி விளையாட்டு பராமரிப்பு ',
                    'discription'  : 'அன்புள்ள விளையாட்டாளர்களே, எஸ்.எஸ்.ஜி விளையாட்டு பராமரிப்பு 18/02/2020 அன்று காலை 11:30 மணி முதல் மாலை 07:30 மணி வரை (IST) தொடங்குகிறது. பராமரிப்பு நேரத்தை தேவைக்கேற்ப நீட்டிக்க முடியும். ஏற்பட்ட சிரமமத்திற்கு வருந்துகிறேன். மேலும் தகவலுக்கு, எங்கள் வாடிக்கையாளர் ஆதரவை லைவ் அரட்டை மற்றும் வாட்ஸ்அப் / டெலிகிராமில் +91 1234567890 என்ற எண்ணில் தொடர்பு கொள்ளவும். வாழ்த்துக்கள், ஐசாஃப்ட் கேமிங் வாடிக்கையாளர் ஆதரவு',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'subject'  : 'கேசினோ வழக்கமான பராமரிப்பு ',
                    'messagedate' :'2020-01-17 11:32:44',
                    'subject_full'  : 'யுஜி விளையாட்டு பராமரிப்பு ',
                    'discription'  : 'அன்புள்ள விளையாட்டாளர்களே, எஸ்.எஸ்.ஜி விளையாட்டு பராமரிப்பு 18/02/2020 அன்று காலை 11:30 மணி முதல் மாலை 07:30 மணி வரை (IST) தொடங்குகிறது. பராமரிப்பு நேரத்தை தேவைக்கேற்ப நீட்டிக்க முடியும். ஏற்பட்ட சிரமமத்திற்கு வருந்துகிறேன். மேலும் தகவலுக்கு, எங்கள் வாடிக்கையாளர் ஆதரவை லைவ் அரட்டை மற்றும் வாட்ஸ்அப் / டெலிகிராமில் +91 1234567890 என்ற எண்ணில் தொடர்பு கொள்ளவும். வாழ்த்துக்கள், ஐசாஃப்ட் கேமிங் வாடிக்கையாளர் ஆதரவு',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'subject'  : 'கவர்ச்சியான விளையாட்டு இயங்குதள பராமரிப்பு',
                    'messagedate' :'2020-01-17 09:32:44', 
                    'subject_full'  : 'யுஜி விளையாட்டு பராமரிப்பு ',
                    'discription'  : 'அன்புள்ள விளையாட்டாளர்களே, எஸ்.எஸ்.ஜி விளையாட்டு பராமரிப்பு 18/02/2020 அன்று காலை 11:30 மணி முதல் மாலை 07:30 மணி வரை (IST) தொடங்குகிறது. பராமரிப்பு நேரத்தை தேவைக்கேற்ப நீட்டிக்க முடியும். ஏற்பட்ட சிரமமத்திற்கு வருந்துகிறேன். மேலும் தகவலுக்கு, எங்கள் வாடிக்கையாளர் ஆதரவை லைவ் அரட்டை மற்றும் வாட்ஸ்அப் / டெலிகிராமில் +91 1234567890 என்ற எண்ணில் தொடர்பு கொள்ளவும். வாழ்த்துக்கள், ஐசாஃப்ட் கேமிங் வாடிக்கையாளர் ஆதரவு',
                },
            ]
        },
        {
            'title':"தனி பட்ட செய்தி",
            'tagName': 'tab3',
            'messages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'subject'  : 'யுஜி விளையாட்டு பராமரிப்பு ',
                    'messagedate' :'2020-01-17 10:32:44',
                    'subject_full'  : 'யுஜி விளையாட்டு பராமரிப்பு ',
                    'discription'  : 'அன்புள்ள விளையாட்டாளர்களே, எஸ்.எஸ்.ஜி விளையாட்டு பராமரிப்பு 18/02/2020 அன்று காலை 11:30 மணி முதல் மாலை 07:30 மணி வரை (IST) தொடங்குகிறது. பராமரிப்பு நேரத்தை தேவைக்கேற்ப நீட்டிக்க முடியும். ஏற்பட்ட சிரமமத்திற்கு வருந்துகிறேன். மேலும் தகவலுக்கு, எங்கள் வாடிக்கையாளர் ஆதரவை லைவ் அரட்டை மற்றும் வாட்ஸ்அப் / டெலிகிராமில் +91 1234567890 என்ற எண்ணில் தொடர்பு கொள்ளவும். வாழ்த்துக்கள், ஐசாஃப்ட் கேமிங் வாடிக்கையாளர் ஆதரவு',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'subject'  : 'கேசினோ வழக்கமான பராமரிப்பு ',
                    'messagedate' :'2020-01-17 11:32:44',
                    'subject_full'  : 'யுஜி விளையாட்டு பராமரிப்பு ',
                    'discription'  : 'அன்புள்ள விளையாட்டாளர்களே, எஸ்.எஸ்.ஜி விளையாட்டு பராமரிப்பு 18/02/2020 அன்று காலை 11:30 மணி முதல் மாலை 07:30 மணி வரை (IST) தொடங்குகிறது. பராமரிப்பு நேரத்தை தேவைக்கேற்ப நீட்டிக்க முடியும். ஏற்பட்ட சிரமமத்திற்கு வருந்துகிறேன். மேலும் தகவலுக்கு, எங்கள் வாடிக்கையாளர் ஆதரவை லைவ் அரட்டை மற்றும் வாட்ஸ்அப் / டெலிகிராமில் +91 1234567890 என்ற எண்ணில் தொடர்பு கொள்ளவும். வாழ்த்துக்கள், ஐசாஃப்ட் கேமிங் வாடிக்கையாளர் ஆதரவு',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'subject'  : 'கவர்ச்சியான விளையாட்டு இயங்குதள பராமரிப்பு',
                    'messagedate' :'2020-01-17 09:32:44',
                    'subject_full'  : 'யுஜி விளையாட்டு பராமரிப்பு ',
                    'discription'  : 'அன்புள்ள விளையாட்டாளர்களே, எஸ்.எஸ்.ஜி விளையாட்டு பராமரிப்பு 18/02/2020 அன்று காலை 11:30 மணி முதல் மாலை 07:30 மணி வரை (IST) தொடங்குகிறது. பராமரிப்பு நேரத்தை தேவைக்கேற்ப நீட்டிக்க முடியும். ஏற்பட்ட சிரமமத்திற்கு வருந்துகிறேன். மேலும் தகவலுக்கு, எங்கள் வாடிக்கையாளர் ஆதரவை லைவ் அரட்டை மற்றும் வாட்ஸ்அப் / டெலிகிராமில் +91 1234567890 என்ற எண்ணில் தொடர்பு கொள்ளவும். வாழ்த்துக்கள், ஐசாஃப்ட் கேமிங் வாடிக்கையாளர் ஆதரவு',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '4',
                    'subject'  : 'எஸ்.ஏ விளையாட்டு பராமரிப்பு ',
                    'messagedate' :'2020-01-17 18:32:44',
                    'subject_full'  : 'யுஜி விளையாட்டு பராமரிப்பு ',
                    'discription'  : 'அன்புள்ள விளையாட்டாளர்களே, எஸ்.எஸ்.ஜி விளையாட்டு பராமரிப்பு 18/02/2020 அன்று காலை 11:30 மணி முதல் மாலை 07:30 மணி வரை (IST) தொடங்குகிறது. பராமரிப்பு நேரத்தை தேவைக்கேற்ப நீட்டிக்க முடியும். ஏற்பட்ட சிரமமத்திற்கு வருந்துகிறேன். மேலும் தகவலுக்கு, எங்கள் வாடிக்கையாளர் ஆதரவை லைவ் அரட்டை மற்றும் வாட்ஸ்அப் / டெலிகிராமில் +91 1234567890 என்ற எண்ணில் தொடர்பு கொள்ளவும். வாழ்த்துக்கள், ஐசாஃப்ட் கேமிங் வாடிக்கையாளர் ஆதரவு',
                },
            ]
        },
    ],
    kn: [
        {
            'title':"ಸಿಸ್ಟಮ್ ಬುಲೆಟಿನ್",
            'tagName': 'tab1',
            'messages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'subject'  : 'ಯುಜಿ ಆಟದ ನಿರ್ವಹಣೆ ',
                    'messagedate' :'2020-01-17 10:32:44',
                    'subject_full'  : 'ಯುಜಿ ಆಟದ ನಿರ್ವಹಣೆ ',
                    'discription'  : 'ಆತ್ಮೀಯ ಗೇಮರುಗಳಿಗಾಗಿ, ಎಸ್‌ಎಸ್‌ಜಿ ಗೇಮ್ ನಿರ್ವಹಣೆ 18/02/2020 ರಂದು ಬೆಳಿಗ್ಗೆ 11:30 ರಿಂದ 07:30 PM (IST) ವರೆಗೆ ಪ್ರಾರಂಭವಾಗುತ್ತದೆ. ನಿರ್ವಹಣೆಯ ಸಮಯವನ್ನು ಅಗತ್ಯಕ್ಕೆ ಅನುಗುಣವಾಗಿ ವಿಸ್ತರಿಸಬಹುದು. ಉಂಟಾದ ಅನಾನುಕೂಲತೆಗೆ ಕ್ಷಮಿಸಿ. ಹೆಚ್ಚಿನ ಮಾಹಿತಿಗಾಗಿ, ದಯವಿಟ್ಟು ನಮ್ಮ ಗ್ರಾಹಕರ ಬೆಂಬಲವನ್ನು ಲೈವ್ ಚಾಟ್ ಮತ್ತು ವಾಟ್ಸಾಪ್ / ಟೆಲಿಗ್ರಾಮ್ ನಲ್ಲಿ +91 1234567890 ನಲ್ಲಿ ಸಂಪರ್ಕಿಸಿ. ಅಭಿನಂದನೆಗಳು, ಐಸಾಫ್ಟ್ ಗೇಮಿಂಗ್ ಗ್ರಾಹಕ ಬೆಂಬಲ',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'subject'  : 'ಕ್ಯಾಸಿನೊ ವಾಡಿಕೆಯ ನಿರ್ವಹಣೆ ',
                    'messagedate' :'2020-01-17 11:32:44',
                    'subject_full'  : 'ಯುಜಿ ಆಟದ ನಿರ್ವಹಣೆ ',
                    'discription'  : 'ಆತ್ಮೀಯ ಗೇಮರುಗಳಿಗಾಗಿ, ಎಸ್‌ಎಸ್‌ಜಿ ಗೇಮ್ ನಿರ್ವಹಣೆ 18/02/2020 ರಂದು ಬೆಳಿಗ್ಗೆ 11:30 ರಿಂದ 07:30 PM (IST) ವರೆಗೆ ಪ್ರಾರಂಭವಾಗುತ್ತದೆ. ನಿರ್ವಹಣೆಯ ಸಮಯವನ್ನು ಅಗತ್ಯಕ್ಕೆ ಅನುಗುಣವಾಗಿ ವಿಸ್ತರಿಸಬಹುದು. ಉಂಟಾದ ಅನಾನುಕೂಲತೆಗೆ ಕ್ಷಮಿಸಿ. ಹೆಚ್ಚಿನ ಮಾಹಿತಿಗಾಗಿ, ದಯವಿಟ್ಟು ನಮ್ಮ ಗ್ರಾಹಕರ ಬೆಂಬಲವನ್ನು ಲೈವ್ ಚಾಟ್ ಮತ್ತು ವಾಟ್ಸಾಪ್ / ಟೆಲಿಗ್ರಾಮ್ ನಲ್ಲಿ +91 1234567890 ನಲ್ಲಿ ಸಂಪರ್ಕಿಸಿ. ಅಭಿನಂದನೆಗಳು, ಐಸಾಫ್ಟ್ ಗೇಮಿಂಗ್ ಗ್ರಾಹಕ ಬೆಂಬಲ',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'subject'  : 'ಸೆಕ್ಸಿ ಗೇಮ್ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ನಿರ್ವಹಣೆ',
                    'messagedate' :'2020-01-17 09:32:44',
                    'subject_full'  : 'ಯುಜಿ ಆಟದ ನಿರ್ವಹಣೆ',
                    'discription'  : 'ಆತ್ಮೀಯ ಗೇಮರುಗಳಿಗಾಗಿ, ಎಸ್‌ಎಸ್‌ಜಿ ಗೇಮ್ ನಿರ್ವಹಣೆ 18/02/2020 ರಂದು ಬೆಳಿಗ್ಗೆ 11:30 ರಿಂದ 07:30 PM (IST) ವರೆಗೆ ಪ್ರಾರಂಭವಾಗುತ್ತದೆ. ನಿರ್ವಹಣೆಯ ಸಮಯವನ್ನು ಅಗತ್ಯಕ್ಕೆ ಅನುಗುಣವಾಗಿ ವಿಸ್ತರಿಸಬಹುದು. ಉಂಟಾದ ಅನಾನುಕೂಲತೆಗೆ ಕ್ಷಮಿಸಿ. ಹೆಚ್ಚಿನ ಮಾಹಿತಿಗಾಗಿ, ದಯವಿಟ್ಟು ನಮ್ಮ ಗ್ರಾಹಕರ ಬೆಂಬಲವನ್ನು ಲೈವ್ ಚಾಟ್ ಮತ್ತು ವಾಟ್ಸಾಪ್ / ಟೆಲಿಗ್ರಾಮ್ ನಲ್ಲಿ +91 1234567890 ನಲ್ಲಿ ಸಂಪರ್ಕಿಸಿ. ಅಭಿನಂದನೆಗಳು, ಐಸಾಫ್ಟ್ ಗೇಮಿಂಗ್ ಗ್ರಾಹಕ ಬೆಂಬಲ',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '4',
                    'subject'  : 'ಎಸ್‌ಎ ಗೇಮ್ ನಿರ್ವಹಣೆ ',
                    'messagedate' :'2020-01-17 18:32:44',
                    'subject_full'  : 'ಯುಜಿ ಆಟದ ನಿರ್ವಹಣೆ ',
                    'discription'  : 'ಆತ್ಮೀಯ ಗೇಮರುಗಳಿಗಾಗಿ, ಎಸ್‌ಎಸ್‌ಜಿ ಗೇಮ್ ನಿರ್ವಹಣೆ 18/02/2020 ರಂದು ಬೆಳಿಗ್ಗೆ 11:30 ರಿಂದ 07:30 PM (IST) ವರೆಗೆ ಪ್ರಾರಂಭವಾಗುತ್ತದೆ. ನಿರ್ವಹಣೆಯ ಸಮಯವನ್ನು ಅಗತ್ಯಕ್ಕೆ ಅನುಗುಣವಾಗಿ ವಿಸ್ತರಿಸಬಹುದು. ಉಂಟಾದ ಅನಾನುಕೂಲತೆಗೆ ಕ್ಷಮಿಸಿ. ಹೆಚ್ಚಿನ ಮಾಹಿತಿಗಾಗಿ, ದಯವಿಟ್ಟು ನಮ್ಮ ಗ್ರಾಹಕರ ಬೆಂಬಲವನ್ನು ಲೈವ್ ಚಾಟ್ ಮತ್ತು ವಾಟ್ಸಾಪ್ / ಟೆಲಿಗ್ರಾಮ್ ನಲ್ಲಿ +91 1234567890 ನಲ್ಲಿ ಸಂಪರ್ಕಿಸಿ. ಅಭಿನಂದನೆಗಳು, ಐಸಾಫ್ಟ್ ಗೇಮಿಂಗ್ ಗ್ರಾಹಕ ಬೆಂಬಲ',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '5',
                    'subject'  : 'ಸಿಕ್ಯೂ 9 ಗೇಮ್ ನಿರ್ವಹಣೆ.',
                    'messagedate' :'2020-01-17 07:32:44',
                    'subject_full'  : 'ಯುಜಿ ಆಟದ ನಿರ್ವಹಣೆ ',
                    'discription'  : 'ಆತ್ಮೀಯ ಗೇಮರುಗಳಿಗಾಗಿ, ಎಸ್‌ಎಸ್‌ಜಿ ಗೇಮ್ ನಿರ್ವಹಣೆ 18/02/2020 ರಂದು ಬೆಳಿಗ್ಗೆ 11:30 ರಿಂದ 07:30 PM (IST) ವರೆಗೆ ಪ್ರಾರಂಭವಾಗುತ್ತದೆ. ನಿರ್ವಹಣೆಯ ಸಮಯವನ್ನು ಅಗತ್ಯಕ್ಕೆ ಅನುಗುಣವಾಗಿ ವಿಸ್ತರಿಸಬಹುದು. ಉಂಟಾದ ಅನಾನುಕೂಲತೆಗೆ ಕ್ಷಮಿಸಿ. ಹೆಚ್ಚಿನ ಮಾಹಿತಿಗಾಗಿ, ದಯವಿಟ್ಟು ನಮ್ಮ ಗ್ರಾಹಕರ ಬೆಂಬಲವನ್ನು ಲೈವ್ ಚಾಟ್ ಮತ್ತು ವಾಟ್ಸಾಪ್ / ಟೆಲಿಗ್ರಾಮ್ ನಲ್ಲಿ +91 1234567890 ನಲ್ಲಿ ಸಂಪರ್ಕಿಸಿ. ಅಭಿನಂದನೆಗಳು, ಐಸಾಫ್ಟ್ ಗೇಮಿಂಗ್ ಗ್ರಾಹಕ ಬೆಂಬಲ',
                },
            ]
        },
        {
            'title':"ಈವೆಂಟ್ ಪ್ರಕಟಣೆ",
            'tagName': 'tab2',
            'messages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'subject'  : 'ಯುಜಿ ಆಟದ ನಿರ್ವಹಣೆ ',
                    'messagedate' :'2020-01-17 10:32:44',
                    'subject_full'  : 'ಯುಜಿ ಆಟದ ನಿರ್ವಹಣೆ ',
                    'discription'  : 'ಆತ್ಮೀಯ ಗೇಮರುಗಳಿಗಾಗಿ, ಎಸ್‌ಎಸ್‌ಜಿ ಗೇಮ್ ನಿರ್ವಹಣೆ 18/02/2020 ರಂದು ಬೆಳಿಗ್ಗೆ 11:30 ರಿಂದ 07:30 PM (IST) ವರೆಗೆ ಪ್ರಾರಂಭವಾಗುತ್ತದೆ. ನಿರ್ವಹಣೆಯ ಸಮಯವನ್ನು ಅಗತ್ಯಕ್ಕೆ ಅನುಗುಣವಾಗಿ ವಿಸ್ತರಿಸಬಹುದು. ಉಂಟಾದ ಅನಾನುಕೂಲತೆಗೆ ಕ್ಷಮಿಸಿ. ಹೆಚ್ಚಿನ ಮಾಹಿತಿಗಾಗಿ, ದಯವಿಟ್ಟು ನಮ್ಮ ಗ್ರಾಹಕರ ಬೆಂಬಲವನ್ನು ಲೈವ್ ಚಾಟ್ ಮತ್ತು ವಾಟ್ಸಾಪ್ / ಟೆಲಿಗ್ರಾಮ್ ನಲ್ಲಿ +91 1234567890 ನಲ್ಲಿ ಸಂಪರ್ಕಿಸಿ. ಅಭಿನಂದನೆಗಳು, ಐಸಾಫ್ಟ್ ಗೇಮಿಂಗ್ ಗ್ರಾಹಕ ಬೆಂಬಲ',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'subject'  : 'ಕ್ಯಾಸಿನೊ ವಾಡಿಕೆಯ ನಿರ್ವಹಣೆ ',
                    'messagedate' :'2020-01-17 11:32:44',
                    'subject_full'  : 'ಯುಜಿ ಆಟದ ನಿರ್ವಹಣೆ ',
                    'discription'  : 'ಆತ್ಮೀಯ ಗೇಮರುಗಳಿಗಾಗಿ, ಎಸ್‌ಎಸ್‌ಜಿ ಗೇಮ್ ನಿರ್ವಹಣೆ 18/02/2020 ರಂದು ಬೆಳಿಗ್ಗೆ 11:30 ರಿಂದ 07:30 PM (IST) ವರೆಗೆ ಪ್ರಾರಂಭವಾಗುತ್ತದೆ. ನಿರ್ವಹಣೆಯ ಸಮಯವನ್ನು ಅಗತ್ಯಕ್ಕೆ ಅನುಗುಣವಾಗಿ ವಿಸ್ತರಿಸಬಹುದು. ಉಂಟಾದ ಅನಾನುಕೂಲತೆಗೆ ಕ್ಷಮಿಸಿ. ಹೆಚ್ಚಿನ ಮಾಹಿತಿಗಾಗಿ, ದಯವಿಟ್ಟು ನಮ್ಮ ಗ್ರಾಹಕರ ಬೆಂಬಲವನ್ನು ಲೈವ್ ಚಾಟ್ ಮತ್ತು ವಾಟ್ಸಾಪ್ / ಟೆಲಿಗ್ರಾಮ್ ನಲ್ಲಿ +91 1234567890 ನಲ್ಲಿ ಸಂಪರ್ಕಿಸಿ. ಅಭಿನಂದನೆಗಳು, ಐಸಾಫ್ಟ್ ಗೇಮಿಂಗ್ ಗ್ರಾಹಕ ಬೆಂಬಲ',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'subject'  : 'ಸೆಕ್ಸಿ ಗೇಮ್ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ನಿರ್ವಹಣೆ ',
                    'messagedate' :'2020-01-17 09:32:44', 
                    'subject_full'  : 'ಯುಜಿ ಆಟದ ನಿರ್ವಹಣೆ',
                    'discription'  : 'ಆತ್ಮೀಯ ಗೇಮರುಗಳಿಗಾಗಿ, ಎಸ್‌ಎಸ್‌ಜಿ ಗೇಮ್ ನಿರ್ವಹಣೆ 18/02/2020 ರಂದು ಬೆಳಿಗ್ಗೆ 11:30 ರಿಂದ 07:30 PM (IST) ವರೆಗೆ ಪ್ರಾರಂಭವಾಗುತ್ತದೆ. ನಿರ್ವಹಣೆಯ ಸಮಯವನ್ನು ಅಗತ್ಯಕ್ಕೆ ಅನುಗುಣವಾಗಿ ವಿಸ್ತರಿಸಬಹುದು. ಉಂಟಾದ ಅನಾನುಕೂಲತೆಗೆ ಕ್ಷಮಿಸಿ. ಹೆಚ್ಚಿನ ಮಾಹಿತಿಗಾಗಿ, ದಯವಿಟ್ಟು ನಮ್ಮ ಗ್ರಾಹಕರ ಬೆಂಬಲವನ್ನು ಲೈವ್ ಚಾಟ್ ಮತ್ತು ವಾಟ್ಸಾಪ್ / ಟೆಲಿಗ್ರಾಮ್ ನಲ್ಲಿ +91 1234567890 ನಲ್ಲಿ ಸಂಪರ್ಕಿಸಿ. ಅಭಿನಂದನೆಗಳು, ಐಸಾಫ್ಟ್ ಗೇಮಿಂಗ್ ಗ್ರಾಹಕ ಬೆಂಬಲ',
                },
            ]
        },
        {
            'title':"ವೈಯಕ್ತಿಕ ಸಂದೇಶ",
            'tagName': 'tab3',
            'messages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'subject'  : 'ಯುಜಿ ಆಟದ ನಿರ್ವಹಣೆ',
                    'messagedate' :'2020-01-17 10:32:44',
                    'subject_full'  : 'ಯುಜಿ ಆಟದ ನಿರ್ವಹಣೆ',
                    'discription'  : 'ಆತ್ಮೀಯ ಗೇಮರುಗಳಿಗಾಗಿ, ಎಸ್‌ಎಸ್‌ಜಿ ಗೇಮ್ ನಿರ್ವಹಣೆ 18/02/2020 ರಂದು ಬೆಳಿಗ್ಗೆ 11:30 ರಿಂದ 07:30 PM (IST) ವರೆಗೆ ಪ್ರಾರಂಭವಾಗುತ್ತದೆ. ನಿರ್ವಹಣೆಯ ಸಮಯವನ್ನು ಅಗತ್ಯಕ್ಕೆ ಅನುಗುಣವಾಗಿ ವಿಸ್ತರಿಸಬಹುದು. ಉಂಟಾದ ಅನಾನುಕೂಲತೆಗೆ ಕ್ಷಮಿಸಿ. ಹೆಚ್ಚಿನ ಮಾಹಿತಿಗಾಗಿ, ದಯವಿಟ್ಟು ನಮ್ಮ ಗ್ರಾಹಕರ ಬೆಂಬಲವನ್ನು ಲೈವ್ ಚಾಟ್ ಮತ್ತು ವಾಟ್ಸಾಪ್ / ಟೆಲಿಗ್ರಾಮ್ ನಲ್ಲಿ +91 1234567890 ನಲ್ಲಿ ಸಂಪರ್ಕಿಸಿ. ಅಭಿನಂದನೆಗಳು, ಐಸಾಫ್ಟ್ ಗೇಮಿಂಗ್ ಗ್ರಾಹಕ ಬೆಂಬಲ',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'subject'  : 'ಕ್ಯಾಸಿನೊ ವಾಡಿಕೆಯ ನಿರ್ವಹಣೆ',
                    'messagedate' :'2020-01-17 11:32:44',
                    'subject_full'  : 'ಯುಜಿ ಆಟದ ನಿರ್ವಹಣೆ',
                    'discription'  : 'ಆತ್ಮೀಯ ಗೇಮರುಗಳಿಗಾಗಿ, ಎಸ್‌ಎಸ್‌ಜಿ ಗೇಮ್ ನಿರ್ವಹಣೆ 18/02/2020 ರಂದು ಬೆಳಿಗ್ಗೆ 11:30 ರಿಂದ 07:30 PM (IST) ವರೆಗೆ ಪ್ರಾರಂಭವಾಗುತ್ತದೆ. ನಿರ್ವಹಣೆಯ ಸಮಯವನ್ನು ಅಗತ್ಯಕ್ಕೆ ಅನುಗುಣವಾಗಿ ವಿಸ್ತರಿಸಬಹುದು. ಉಂಟಾದ ಅನಾನುಕೂಲತೆಗೆ ಕ್ಷಮಿಸಿ. ಹೆಚ್ಚಿನ ಮಾಹಿತಿಗಾಗಿ, ದಯವಿಟ್ಟು ನಮ್ಮ ಗ್ರಾಹಕರ ಬೆಂಬಲವನ್ನು ಲೈವ್ ಚಾಟ್ ಮತ್ತು ವಾಟ್ಸಾಪ್ / ಟೆಲಿಗ್ರಾಮ್ ನಲ್ಲಿ +91 1234567890 ನಲ್ಲಿ ಸಂಪರ್ಕಿಸಿ. ಅಭಿನಂದನೆಗಳು, ಐಸಾಫ್ಟ್ ಗೇಮಿಂಗ್ ಗ್ರಾಹಕ ಬೆಂಬಲ',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'subject'  : 'ಸೆಕ್ಸಿ ಗೇಮ್ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ನಿರ್ವಹಣೆ',
                    'messagedate' :'2020-01-17 09:32:44',
                    'subject_full'  : 'ಯುಜಿ ಆಟದ ನಿರ್ವಹಣೆ',
                    'discription'  : 'ಆತ್ಮೀಯ ಗೇಮರುಗಳಿಗಾಗಿ, ಎಸ್‌ಎಸ್‌ಜಿ ಗೇಮ್ ನಿರ್ವಹಣೆ 18/02/2020 ರಂದು ಬೆಳಿಗ್ಗೆ 11:30 ರಿಂದ 07:30 PM (IST) ವರೆಗೆ ಪ್ರಾರಂಭವಾಗುತ್ತದೆ. ನಿರ್ವಹಣೆಯ ಸಮಯವನ್ನು ಅಗತ್ಯಕ್ಕೆ ಅನುಗುಣವಾಗಿ ವಿಸ್ತರಿಸಬಹುದು. ಉಂಟಾದ ಅನಾನುಕೂಲತೆಗೆ ಕ್ಷಮಿಸಿ. ಹೆಚ್ಚಿನ ಮಾಹಿತಿಗಾಗಿ, ದಯವಿಟ್ಟು ನಮ್ಮ ಗ್ರಾಹಕರ ಬೆಂಬಲವನ್ನು ಲೈವ್ ಚಾಟ್ ಮತ್ತು ವಾಟ್ಸಾಪ್ / ಟೆಲಿಗ್ರಾಮ್ ನಲ್ಲಿ +91 1234567890 ನಲ್ಲಿ ಸಂಪರ್ಕಿಸಿ. ಅಭಿನಂದನೆಗಳು, ಐಸಾಫ್ಟ್ ಗೇಮಿಂಗ್ ಗ್ರಾಹಕ ಬೆಂಬಲ',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '4',
                    'subject'  : 'ಎಸ್‌ಎ ಗೇಮ್ ನಿರ್ವಹಣೆ ',
                    'messagedate' :'2020-01-17 18:32:44',
                    'subject_full'  : 'ಯುಜಿ ಆಟದ ನಿರ್ವಹಣೆ',
                    'discription'  : 'ಆತ್ಮೀಯ ಗೇಮರುಗಳಿಗಾಗಿ, ಎಸ್‌ಎಸ್‌ಜಿ ಗೇಮ್ ನಿರ್ವಹಣೆ 18/02/2020 ರಂದು ಬೆಳಿಗ್ಗೆ 11:30 ರಿಂದ 07:30 PM (IST) ವರೆಗೆ ಪ್ರಾರಂಭವಾಗುತ್ತದೆ. ನಿರ್ವಹಣೆಯ ಸಮಯವನ್ನು ಅಗತ್ಯಕ್ಕೆ ಅನುಗುಣವಾಗಿ ವಿಸ್ತರಿಸಬಹುದು. ಉಂಟಾದ ಅನಾನುಕೂಲತೆಗೆ ಕ್ಷಮಿಸಿ. ಹೆಚ್ಚಿನ ಮಾಹಿತಿಗಾಗಿ, ದಯವಿಟ್ಟು ನಮ್ಮ ಗ್ರಾಹಕರ ಬೆಂಬಲವನ್ನು ಲೈವ್ ಚಾಟ್ ಮತ್ತು ವಾಟ್ಸಾಪ್ / ಟೆಲಿಗ್ರಾಮ್ ನಲ್ಲಿ +91 1234567890 ನಲ್ಲಿ ಸಂಪರ್ಕಿಸಿ. ಅಭಿನಂದನೆಗಳು, ಐಸಾಫ್ಟ್ ಗೇಮಿಂಗ್ ಗ್ರಾಹಕ ಬೆಂಬಲ',
                },
            ]
        },
    ],
    ne: [
        {
            'title':"प्रणाली बुलेटिन",
            'tagName': 'tab1',
            'messages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'subject'  : 'यूजी खेल मर्मतसम्भार ',
                    'messagedate' :'2020-01-17 10:32:44',
                    'subject_full'  : 'यूजी खेल मर्मतसम्भार',
                    'discription'  : 'प्रिय गेमरहरू, एसएसजी खेल मर्मतसम्भार 11:30 बिहान देखि 07:30 अपराह्न (IST) १/0/०२/२०२० मा सुरु हुन्छ। आवश्यकता अनुसार मर्मत समय बढाउन सकिन्छ। असुविधाको कारण माफ गर्नुहोस्। अधिक जानकारीको लागि, कृपया लाइभ च्याट र व्हाट्सएप / टेलिग्राममा हामीलाई +१ 12 १२3456767890 at ०० मा ग्राहक समर्थनलाई सम्पर्क गर्नुहोस्। शुभकामना, आईसोफ्ट गेमिंग ग्राहक समर्थन',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'subject'  : 'क्यासिनो नियमित रखरखाव ',
                    'messagedate' :'2020-01-17 11:32:44',
                    'subject_full'  : 'यूजी खेल मर्मतसम्भार ',
                    'discription'  : 'प्प्रिय गेमरहरू, एसएसजी खेल मर्मतसम्भार 11:30 बिहान देखि 07:30 अपराह्न (IST) १/0/०२/२०२० मा सुरु हुन्छ। आवश्यकता अनुसार मर्मत समय बढाउन सकिन्छ। असुविधाको कारण माफ गर्नुहोस्। अधिक जानकारीको लागि, कृपया लाइभ च्याट र व्हाट्सएप / टेलिग्राममा हामीलाई +१ 12 १२3456767890 at ०० मा ग्राहक समर्थनलाई सम्पर्क गर्नुहोस्। शुभकामना, आईसोफ्ट गेमिंग ग्राहक समर्थन',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'subject'  : 'SEXY खेल प्लेटफार्म रखरखाव ',
                    'messagedate' :'2020-01-17 09:32:44',
                    'subject_full'  : 'यूजी खेल मर्मतसम्भार',
                    'discription'  : 'प्प्रिय गेमरहरू, एसएसजी खेल मर्मतसम्भार 11:30 बिहान देखि 07:30 अपराह्न (IST) १/0/०२/२०२० मा सुरु हुन्छ। आवश्यकता अनुसार मर्मत समय बढाउन सकिन्छ। असुविधाको कारण माफ गर्नुहोस्। अधिक जानकारीको लागि, कृपया लाइभ च्याट र व्हाट्सएप / टेलिग्राममा हामीलाई +१ 12 १२3456767890 at ०० मा ग्राहक समर्थनलाई सम्पर्क गर्नुहोस्। शुभकामना, आईसोफ्ट गेमिंग ग्राहक समर्थन',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '4',
                    'subject'  : 'SA खेल मर्मतसम्भार ',
                    'messagedate' :'2020-01-17 18:32:44',
                    'subject_full'  : 'यूजी खेल मर्मतसम्भार ',
                    'discription'  : 'प्प्रिय गेमरहरू, एसएसजी खेल मर्मतसम्भार 11:30 बिहान देखि 07:30 अपराह्न (IST) १/0/०२/२०२० मा सुरु हुन्छ। आवश्यकता अनुसार मर्मत समय बढाउन सकिन्छ। असुविधाको कारण माफ गर्नुहोस्। अधिक जानकारीको लागि, कृपया लाइभ च्याट र व्हाट्सएप / टेलिग्राममा हामीलाई +१ 12 १२3456767890 at ०० मा ग्राहक समर्थनलाई सम्पर्क गर्नुहोस्। शुभकामना, आईसोफ्ट गेमिंग ग्राहक समर्थन',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '5',
                    'subject'  : 'CQ9 खेल मर्मत।',
                    'messagedate' :'2020-01-17 07:32:44',
                    'subject_full'  : 'यूजी खेल मर्मतसम्भार ',
                    'discription'  : 'प्प्रिय गेमरहरू, एसएसजी खेल मर्मतसम्भार 11:30 बिहान देखि 07:30 अपराह्न (IST) १/0/०२/२०२० मा सुरु हुन्छ। आवश्यकता अनुसार मर्मत समय बढाउन सकिन्छ। असुविधाको कारण माफ गर्नुहोस्। अधिक जानकारीको लागि, कृपया लाइभ च्याट र व्हाट्सएप / टेलिग्राममा हामीलाई +१ 12 १२3456767890 at ०० मा ग्राहक समर्थनलाई सम्पर्क गर्नुहोस्। शुभकामना, आईसोफ्ट गेमिंग ग्राहक समर्थन',
                },
            ]
        },
        {
            'title':"घटना घोषणा",
            'tagName': 'tab2',
            'messages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'subject'  : 'यूजी खेल मर्मतसम्भार ',
                    'messagedate' :'2020-01-17 10:32:44',
                    'subject_full'  : 'यूजी खेल मर्मतसम्भार',
                    'discription'  : 'प्प्रिय गेमरहरू, एसएसजी खेल मर्मतसम्भार 11:30 बिहान देखि 07:30 अपराह्न (IST) १/0/०२/२०२० मा सुरु हुन्छ। आवश्यकता अनुसार मर्मत समय बढाउन सकिन्छ। असुविधाको कारण माफ गर्नुहोस्। अधिक जानकारीको लागि, कृपया लाइभ च्याट र व्हाट्सएप / टेलिग्राममा हामीलाई +१ 12 १२3456767890 at ०० मा ग्राहक समर्थनलाई सम्पर्क गर्नुहोस्। शुभकामना, आईसोफ्ट गेमिंग ग्राहक समर्थन',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'subject'  : 'क्यासिनो नियमित रखरखाव ',
                    'messagedate' :'2020-01-17 11:32:44',
                    'subject_full'  : 'यूजी खेल मर्मतसम्भार',
                    'discription'  : 'प्प्रिय गेमरहरू, एसएसजी खेल मर्मतसम्भार 11:30 बिहान देखि 07:30 अपराह्न (IST) १/0/०२/२०२० मा सुरु हुन्छ। आवश्यकता अनुसार मर्मत समय बढाउन सकिन्छ। असुविधाको कारण माफ गर्नुहोस्। अधिक जानकारीको लागि, कृपया लाइभ च्याट र व्हाट्सएप / टेलिग्राममा हामीलाई +१ 12 १२3456767890 at ०० मा ग्राहक समर्थनलाई सम्पर्क गर्नुहोस्। शुभकामना, आईसोफ्ट गेमिंग ग्राहक समर्थन',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'subject'  : 'SEXY खेल प्लेटफार्म रखरखाव ',
                    'messagedate' :'2020-01-17 09:32:44', 
                    'subject_full'  : 'यूजी खेल मर्मतसम्भार',
                    'discription'  : 'प्प्रिय गेमरहरू, एसएसजी खेल मर्मतसम्भार 11:30 बिहान देखि 07:30 अपराह्न (IST) १/0/०२/२०२० मा सुरु हुन्छ। आवश्यकता अनुसार मर्मत समय बढाउन सकिन्छ। असुविधाको कारण माफ गर्नुहोस्। अधिक जानकारीको लागि, कृपया लाइभ च्याट र व्हाट्सएप / टेलिग्राममा हामीलाई +१ 12 १२3456767890 at ०० मा ग्राहक समर्थनलाई सम्पर्क गर्नुहोस्। शुभकामना, आईसोफ्ट गेमिंग ग्राहक समर्थन',
                },
            ]
        },
        {
            'title':"व्यक्तिगत सन्देश",
            'tagName': 'tab3',
            'messages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'subject'  : 'यूजी खेल मर्मतसम्भार ',
                    'messagedate' :'2020-01-17 10:32:44',
                    'subject_full'  : 'यूजी खेल मर्मतसम्भार ',
                    'discription'  : 'प्प्रिय गेमरहरू, एसएसजी खेल मर्मतसम्भार 11:30 बिहान देखि 07:30 अपराह्न (IST) १/0/०२/२०२० मा सुरु हुन्छ। आवश्यकता अनुसार मर्मत समय बढाउन सकिन्छ। असुविधाको कारण माफ गर्नुहोस्। अधिक जानकारीको लागि, कृपया लाइभ च्याट र व्हाट्सएप / टेलिग्राममा हामीलाई +१ 12 १२3456767890 at ०० मा ग्राहक समर्थनलाई सम्पर्क गर्नुहोस्। शुभकामना, आईसोफ्ट गेमिंग ग्राहक समर्थन',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'subject'  : 'क्यासिनो नियमित रखरखाव ',
                    'messagedate' :'2020-01-17 11:32:44',
                    'subject_full'  : 'यूजी खेल मर्मतसम्भार ',
                    'discription'  : 'प्प्रिय गेमरहरू, एसएसजी खेल मर्मतसम्भार 11:30 बिहान देखि 07:30 अपराह्न (IST) १/0/०२/२०२० मा सुरु हुन्छ। आवश्यकता अनुसार मर्मत समय बढाउन सकिन्छ। असुविधाको कारण माफ गर्नुहोस्। अधिक जानकारीको लागि, कृपया लाइभ च्याट र व्हाट्सएप / टेलिग्राममा हामीलाई +१ 12 १२3456767890 at ०० मा ग्राहक समर्थनलाई सम्पर्क गर्नुहोस्। शुभकामना, आईसोफ्ट गेमिंग ग्राहक समर्थन',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'subject'  : 'SEXY खेल प्लेटफार्म रखरखाव',
                    'messagedate' :'2020-01-17 09:32:44',
                    'subject_full'  : 'यूजी खेल मर्मतसम्भार ',
                    'discription'  : 'प्प्रिय गेमरहरू, एसएसजी खेल मर्मतसम्भार 11:30 बिहान देखि 07:30 अपराह्न (IST) १/0/०२/२०२० मा सुरु हुन्छ। आवश्यकता अनुसार मर्मत समय बढाउन सकिन्छ। असुविधाको कारण माफ गर्नुहोस्। अधिक जानकारीको लागि, कृपया लाइभ च्याट र व्हाट्सएप / टेलिग्राममा हामीलाई +१ 12 १२3456767890 at ०० मा ग्राहक समर्थनलाई सम्पर्क गर्नुहोस्। शुभकामना, आईसोफ्ट गेमिंग ग्राहक समर्थन',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '4',
                    'subject'  : 'SA खेल मर्मतसम्भार ',
                    'messagedate' :'2020-01-17 18:32:44',
                    'subject_full'  : 'यूजी खेल मर्मतसम्भार ',
                    'discription'  : 'प्प्रिय गेमरहरू, एसएसजी खेल मर्मतसम्भार 11:30 बिहान देखि 07:30 अपराह्न (IST) १/0/०२/२०२० मा सुरु हुन्छ। आवश्यकता अनुसार मर्मत समय बढाउन सकिन्छ। असुविधाको कारण माफ गर्नुहोस्। अधिक जानकारीको लागि, कृपया लाइभ च्याट र व्हाट्सएप / टेलिग्राममा हामीलाई +१ 12 १२3456767890 at ०० मा ग्राहक समर्थनलाई सम्पर्क गर्नुहोस्। शुभकामना, आईसोफ्ट गेमिंग ग्राहक समर्थन',
                },
            ]
        },
    ],
    tl: [
        {
            'title':"సిస్టమ్ బులెటిన్",
            'tagName': 'tab1',
            'messages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'subject'  : 'UG ఆట నిర్వహణ ',
                    'messagedate' :'2020-01-17 10:32:44',
                    'subject_full'  : 'UG ఆట నిర్వహణ ',
                    'discription'  : 'ప్రియమైన గేమర్స్, SSG గేమ్ నిర్వహణ 18/02/2020 న ఉదయం 11:30 నుండి 07:30 PM (IST) వరకు ప్రారంభమవుతుంది. నిర్వహణ సమయం అవసరానికి అనుగుణంగా పొడిగించబడుతుంది. అసౌకర్యానికి చింతిస్తున్నాము. మరింత సమాచారం కోసం, దయచేసి లైవ్ చాట్‌లో మా కస్టమర్ మద్దతును మరియు వాట్సాప్ / టెలిగ్రామ్‌ను +91 1234567890 వద్ద సంప్రదించండి. శుభాకాంక్షలు, ఐసాఫ్ట్ గేమింగ్ కస్టమర్ సపోర్ట్',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'subject'  : 'క్యాసినో రొటీన్ నిర్వహణ ',
                    'messagedate' :'2020-01-17 11:32:44',
                    'subject_full'  : 'UG ఆట నిర్వహణ ',
                    'discription'  : 'ప్రియమైన గేమర్స్, SSG గేమ్ నిర్వహణ 18/02/2020 న ఉదయం 11:30 నుండి 07:30 PM (IST) వరకు ప్రారంభమవుతుంది. నిర్వహణ సమయం అవసరానికి అనుగుణంగా పొడిగించబడుతుంది. అసౌకర్యానికి చింతిస్తున్నాము. మరింత సమాచారం కోసం, దయచేసి లైవ్ చాట్‌లో మా కస్టమర్ మద్దతును మరియు వాట్సాప్ / టెలిగ్రామ్‌ను +91 1234567890 వద్ద సంప్రదించండి. శుభాకాంక్షలు, ఐసాఫ్ట్ గేమింగ్ కస్టమర్ సపోర్ట్',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'subject'  : 'సెక్సీ గేమ్ ప్లాట్‌ఫాం నిర్వహణ ',
                    'messagedate' :'2020-01-17 09:32:44',
                    'subject_full'  : 'UG ఆట నిర్వహణ ',
                    'discription'  : 'ప్రియమైన గేమర్స్, SSG గేమ్ నిర్వహణ 18/02/2020 న ఉదయం 11:30 నుండి 07:30 PM (IST) వరకు ప్రారంభమవుతుంది. నిర్వహణ సమయం అవసరానికి అనుగుణంగా పొడిగించబడుతుంది. అసౌకర్యానికి చింతిస్తున్నాము. మరింత సమాచారం కోసం, దయచేసి లైవ్ చాట్‌లో మా కస్టమర్ మద్దతును మరియు వాట్సాప్ / టెలిగ్రామ్‌ను +91 1234567890 వద్ద సంప్రదించండి. శుభాకాంక్షలు, ఐసాఫ్ట్ గేమింగ్ కస్టమర్ సపోర్ట్',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '4',
                    'subject'  : 'SA గేమ్ నిర్వహణ',
                    'messagedate' :'2020-01-17 18:32:44',
                    'subject_full'  : 'UG ఆట నిర్వహణ ',
                    'discription'  : 'ప్రియమైన గేమర్స్, SSG గేమ్ నిర్వహణ 18/02/2020 న ఉదయం 11:30 నుండి 07:30 PM (IST) వరకు ప్రారంభమవుతుంది. నిర్వహణ సమయం అవసరానికి అనుగుణంగా పొడిగించబడుతుంది. అసౌకర్యానికి చింతిస్తున్నాము. మరింత సమాచారం కోసం, దయచేసి లైవ్ చాట్‌లో మా కస్టమర్ మద్దతును మరియు వాట్సాప్ / టెలిగ్రామ్‌ను +91 1234567890 వద్ద సంప్రదించండి. శుభాకాంక్షలు, ఐసాఫ్ట్ గేమింగ్ కస్టమర్ సపోర్ట్',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '5',
                    'subject'  : 'CQ9 గేమ్ నిర్వహణ.',
                    'messagedate' :'2020-01-17 07:32:44',
                    'subject_full'  : 'UG ఆట నిర్వహణ ',
                    'discription'  : 'ప్రియమైన గేమర్స్, SSG గేమ్ నిర్వహణ 18/02/2020 న ఉదయం 11:30 నుండి 07:30 PM (IST) వరకు ప్రారంభమవుతుంది. నిర్వహణ సమయం అవసరానికి అనుగుణంగా పొడిగించబడుతుంది. అసౌకర్యానికి చింతిస్తున్నాము. మరింత సమాచారం కోసం, దయచేసి లైవ్ చాట్‌లో మా కస్టమర్ మద్దతును మరియు వాట్సాప్ / టెలిగ్రామ్‌ను +91 1234567890 వద్ద సంప్రదించండి. శుభాకాంక్షలు, ఐసాఫ్ట్ గేమింగ్ కస్టమర్ సపోర్ట్',
                },
            ]
        },
        {
            'title':"Event Announcement",
            'tagName': 'tab2',
            'messages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'subject'  : 'UG ఆట నిర్వహణ ',
                    'messagedate' :'2020-01-17 10:32:44',
                    'subject_full'  : 'UG ఆట నిర్వహణ ',
                    'discription'  : 'ప్రియమైన గేమర్స్, SSG గేమ్ నిర్వహణ 18/02/2020 న ఉదయం 11:30 నుండి 07:30 PM (IST) వరకు ప్రారంభమవుతుంది. నిర్వహణ సమయం అవసరానికి అనుగుణంగా పొడిగించబడుతుంది. అసౌకర్యానికి చింతిస్తున్నాము. మరింత సమాచారం కోసం, దయచేసి లైవ్ చాట్‌లో మా కస్టమర్ మద్దతును మరియు వాట్సాప్ / టెలిగ్రామ్‌ను +91 1234567890 వద్ద సంప్రదించండి. శుభాకాంక్షలు, ఐసాఫ్ట్ గేమింగ్ కస్టమర్ సపోర్ట్',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'subject'  : 'క్యాసినో రొటీన్ నిర్వహణ ',
                    'messagedate' :'2020-01-17 11:32:44',
                    'subject_full'  : 'UG ఆట నిర్వహణ ',
                    'discription'  : 'ప్రియమైన గేమర్స్, SSG గేమ్ నిర్వహణ 18/02/2020 న ఉదయం 11:30 నుండి 07:30 PM (IST) వరకు ప్రారంభమవుతుంది. నిర్వహణ సమయం అవసరానికి అనుగుణంగా పొడిగించబడుతుంది. అసౌకర్యానికి చింతిస్తున్నాము. మరింత సమాచారం కోసం, దయచేసి లైవ్ చాట్‌లో మా కస్టమర్ మద్దతును మరియు వాట్సాప్ / టెలిగ్రామ్‌ను +91 1234567890 వద్ద సంప్రదించండి. శుభాకాంక్షలు, ఐసాఫ్ట్ గేమింగ్ కస్టమర్ సపోర్ట్',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'subject'  : 'సెక్సీ గేమ్ ప్లాట్‌ఫాం నిర్వహణ ',
                    'messagedate' :'2020-01-17 09:32:44', 
                    'subject_full'  : 'UG ఆట నిర్వహణ ',
                    'discription'  : 'ప్రియమైన గేమర్స్, SSG గేమ్ నిర్వహణ 18/02/2020 న ఉదయం 11:30 నుండి 07:30 PM (IST) వరకు ప్రారంభమవుతుంది. నిర్వహణ సమయం అవసరానికి అనుగుణంగా పొడిగించబడుతుంది. అసౌకర్యానికి చింతిస్తున్నాము. మరింత సమాచారం కోసం, దయచేసి లైవ్ చాట్‌లో మా కస్టమర్ మద్దతును మరియు వాట్సాప్ / టెలిగ్రామ్‌ను +91 1234567890 వద్ద సంప్రదించండి. శుభాకాంక్షలు, ఐసాఫ్ట్ గేమింగ్ కస్టమర్ సపోర్ట్',
                },
            ]
        },
        {
            'title':"Personal Message",
            'tagName': 'tab3',
            'messages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'subject'  : 'UG ఆట నిర్వహణ ',
                    'messagedate' :'2020-01-17 10:32:44',
                    'subject_full'  : 'UG ఆట నిర్వహణ ',
                    'discription'  : 'ప్రియమైన గేమర్స్, SSG గేమ్ నిర్వహణ 18/02/2020 న ఉదయం 11:30 నుండి 07:30 PM (IST) వరకు ప్రారంభమవుతుంది. నిర్వహణ సమయం అవసరానికి అనుగుణంగా పొడిగించబడుతుంది. అసౌకర్యానికి చింతిస్తున్నాము. మరింత సమాచారం కోసం, దయచేసి లైవ్ చాట్‌లో మా కస్టమర్ మద్దతును మరియు వాట్సాప్ / టెలిగ్రామ్‌ను +91 1234567890 వద్ద సంప్రదించండి. శుభాకాంక్షలు, ఐసాఫ్ట్ గేమింగ్ కస్టమర్ సపోర్ట్',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'subject'  : 'క్యాసినో రొటీన్ నిర్వహణ ',
                    'messagedate' :'2020-01-17 11:32:44',
                    'subject_full'  : 'UG ఆట నిర్వహణ ',
                    'discription'  : 'ప్రియమైన గేమర్స్, SSG గేమ్ నిర్వహణ 18/02/2020 న ఉదయం 11:30 నుండి 07:30 PM (IST) వరకు ప్రారంభమవుతుంది. నిర్వహణ సమయం అవసరానికి అనుగుణంగా పొడిగించబడుతుంది. అసౌకర్యానికి చింతిస్తున్నాము. మరింత సమాచారం కోసం, దయచేసి లైవ్ చాట్‌లో మా కస్టమర్ మద్దతును మరియు వాట్సాప్ / టెలిగ్రామ్‌ను +91 1234567890 వద్ద సంప్రదించండి. శుభాకాంక్షలు, ఐసాఫ్ట్ గేమింగ్ కస్టమర్ సపోర్ట్',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'subject'  : 'సెక్సీ గేమ్ ప్లాట్‌ఫాం నిర్వహణ ',
                    'messagedate' :'2020-01-17 09:32:44',
                    'subject_full'  : 'UG ఆట నిర్వహణ ',
                    'discription'  : 'ప్రియమైన గేమర్స్, SSG గేమ్ నిర్వహణ 18/02/2020 న ఉదయం 11:30 నుండి 07:30 PM (IST) వరకు ప్రారంభమవుతుంది. నిర్వహణ సమయం అవసరానికి అనుగుణంగా పొడిగించబడుతుంది. అసౌకర్యానికి చింతిస్తున్నాము. మరింత సమాచారం కోసం, దయచేసి లైవ్ చాట్‌లో మా కస్టమర్ మద్దతును మరియు వాట్సాప్ / టెలిగ్రామ్‌ను +91 1234567890 వద్ద సంప్రదించండి. శుభాకాంక్షలు, ఐసాఫ్ట్ గేమింగ్ కస్టమర్ సపోర్ట్',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '4',
                    'subject'  : 'SA గేమ్ నిర్వహణ ',
                    'messagedate' :'2020-01-17 18:32:44',
                    'subject_full'  : 'UG ఆట నిర్వహణ ',
                    'discription'  : 'ప్రియమైన గేమర్స్, SSG గేమ్ నిర్వహణ 18/02/2020 న ఉదయం 11:30 నుండి 07:30 PM (IST) వరకు ప్రారంభమవుతుంది. నిర్వహణ సమయం అవసరానికి అనుగుణంగా పొడిగించబడుతుంది. అసౌకర్యానికి చింతిస్తున్నాము. మరింత సమాచారం కోసం, దయచేసి లైవ్ చాట్‌లో మా కస్టమర్ మద్దతును మరియు వాట్సాప్ / టెలిగ్రామ్‌ను +91 1234567890 వద్ద సంప్రదించండి. శుభాకాంక్షలు, ఐసాఫ్ట్ గేమింగ్ కస్టమర్ సపోర్ట్',
                },
            ]
        },
    ],
    hi: [
        {
            'title':"सिस्टम बुलेटिन",
            'tagName': 'tab1',
            'messages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'subject'  : 'और खेल रखरखाव ',
                    'messagedate' :'2020-01-17 10:32:44',
                    'subject_full'  : 'और खेल रखरखाव ',
                    'discription'  : 'प्रिय गेमर्स, एसएसजी गेम का रख-रखाव सुबह 11:30 बजे से शाम 07:30 बजे तक (IST) 18/02/2020 से शुरू होता है। जरूरत के मुताबिक रखरखाव का समय बढ़ाया जा सकता है। असुविधा के लिए क्षमा करें। अधिक जानकारी के लिए, लाइव चैट और व्हाट्सएप / टेलीग्राम में हमारे ग्राहक सहायता से संपर्क करें।',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'subject'  : 'कैसीनो दिनचर्या रखरखाव ',
                    'messagedate' :'2020-01-17 11:32:44',
                    'subject_full'  : 'और खेल रखरखाव ',
                    'discription'  : 'प्रिय गेमर्स, एसएसजी गेम का रख-रखाव सुबह 11:30 बजे से शाम 07:30 बजे तक (IST) 18/02/2020 से शुरू होता है। जरूरत के मुताबिक रखरखाव का समय बढ़ाया जा सकता है। असुविधा के लिए क्षमा करें। अधिक जानकारी के लिए, लाइव चैट और व्हाट्सएप / टेलीग्राम में हमारे ग्राहक सहायता से संपर्क करें।',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'subject'  : 'SEXY खेल प्लेटफार्म रखरखाव ',
                    'messagedate' :'2020-01-17 09:32:44',
                    'subject_full'  : 'और खेल रखरखाव ',
                    'discription'  : 'प्रिय गेमर्स, एसएसजी गेम का रख-रखाव सुबह 11:30 बजे से शाम 07:30 बजे तक (IST) 18/02/2020 से शुरू होता है। जरूरत के मुताबिक रखरखाव का समय बढ़ाया जा सकता है। असुविधा के लिए क्षमा करें। अधिक जानकारी के लिए, लाइव चैट और व्हाट्सएप / टेलीग्राम में हमारे ग्राहक सहायता से संपर्क करें।',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '4',
                    'subject'  : 'एटी गेम रखरखाव ',
                    'messagedate' :'2020-01-17 18:32:44',
                    'subject_full'  : 'और खेल रखरखाव ',
                    'discription'  : 'प्रिय गेमर्स, एसएसजी गेम का रख-रखाव सुबह 11:30 बजे से शाम 07:30 बजे तक (IST) 18/02/2020 से शुरू होता है। जरूरत के मुताबिक रखरखाव का समय बढ़ाया जा सकता है। असुविधा के लिए क्षमा करें। अधिक जानकारी के लिए, लाइव चैट और व्हाट्सएप / टेलीग्राम में हमारे ग्राहक सहायता से संपर्क करें।',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '5',
                    'subject'  : 'CQ9 खेल रखरखाव।',
                    'messagedate' :'2020-01-17 07:32:44',
                    'subject_full'  : 'और खेल रखरखाव ',
                    'discription'  : 'प्रिय गेमर्स, एसएसजी गेम का रख-रखाव सुबह 11:30 बजे से शाम 07:30 बजे तक (IST) 18/02/2020 से शुरू होता है। जरूरत के मुताबिक रखरखाव का समय बढ़ाया जा सकता है। असुविधा के लिए क्षमा करें। अधिक जानकारी के लिए, लाइव चैट और व्हाट्सएप / टेलीग्राम में हमारे ग्राहक सहायता से संपर्क करें।',
                },
            ]
        },
        {
            'title':"घटना की घोषणा",
            'tagName': 'tab2',
            'messages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'subject'  : 'और खेल रखरखाव',
                    'messagedate' :'2020-01-17 10:32:44',
                    'subject_full'  : 'और खेल रखरखाव ',
                    'discription'  : 'प्रिय गेमर्स, एसएसजी गेम का रख-रखाव सुबह 11:30 बजे से शाम 07:30 बजे तक (IST) 18/02/2020 से शुरू होता है। जरूरत के मुताबिक रखरखाव का समय बढ़ाया जा सकता है। असुविधा के लिए क्षमा करें। अधिक जानकारी के लिए, लाइव चैट और व्हाट्सएप / टेलीग्राम में हमारे ग्राहक सहायता से संपर्क करें।',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'subject'  : 'कैसीनो नियमित रखरखाव',
                    'messagedate' :'2020-01-17 11:32:44',
                    'subject_full'  : 'और खेल रखरखाव ',
                    'discription'  : 'प्रिय गेमर्स, एसएसजी गेम का रख-रखाव सुबह 11:30 बजे से शाम 07:30 बजे तक (IST) 18/02/2020 से शुरू होता है। जरूरत के मुताबिक रखरखाव का समय बढ़ाया जा सकता है। असुविधा के लिए क्षमा करें। अधिक जानकारी के लिए, लाइव चैट और व्हाट्सएप / टेलीग्राम में हमारे ग्राहक सहायता से संपर्क करें।',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'subject'  : 'SEXY खेल प्लेटफार्म रखरखाव',
                    'messagedate' :'2020-01-17 09:32:44',
                    'subject_full'  : 'और खेल रखरखाव ',
                    'discription'  : 'प्रिय गेमर्स, एसएसजी गेम का रख-रखाव सुबह 11:30 बजे से शाम 07:30 बजे तक (IST) 18/02/2020 से शुरू होता है। जरूरत के मुताबिक रखरखाव का समय बढ़ाया जा सकता है। असुविधा के लिए क्षमा करें। अधिक जानकारी के लिए, लाइव चैट और व्हाट्सएप / टेलीग्राम में हमारे ग्राहक सहायता से संपर्क करें।',
                },
            ]
        },
        {
            'title':"व्यक्तिगत संदेश",
            'tagName': 'tab3',
            'messages':[
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '1',
                    'subject'  : 'और खेल रखरखाव ',
                    'messagedate' :'2020-01-17 10:32:44',
                    'subject_full'  : 'और खेल रखरखाव ',
                    'discription'  : 'प्रिय गेमर्स, एसएसजी गेम का रख-रखाव सुबह 11:30 बजे से शाम 07:30 बजे तक (IST) 18/02/2020 से शुरू होता है। जरूरत के मुताबिक रखरखाव का समय बढ़ाया जा सकता है। असुविधा के लिए क्षमा करें। अधिक जानकारी के लिए, लाइव चैट और व्हाट्सएप / टेलीग्राम में हमारे ग्राहक सहायता से संपर्क करें।',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '2',
                    'subject'  : 'कैसीनो दिनचर्या रखरखाव ',
                    'messagedate' :'2020-01-17 11:32:44',
                    'subject_full'  : 'और खेल रखरखाव ',
                    'discription'  : 'प्रिय गेमर्स, एसएसजी गेम का रख-रखाव सुबह 11:30 बजे से शाम 07:30 बजे तक (IST) 18/02/2020 से शुरू होता है। जरूरत के मुताबिक रखरखाव का समय बढ़ाया जा सकता है। असुविधा के लिए क्षमा करें। अधिक जानकारी के लिए, लाइव चैट और व्हाट्सएप / टेलीग्राम में हमारे ग्राहक सहायता से संपर्क करें।',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '3',
                    'subject'  : 'SEXY खेल प्लेटफार्म रखरखाव ',
                    'messagedate' :'2020-01-17 09:32:44',
                    'subject_full'  : 'और खेल रखरखाव ',
                    'discription'  : 'प्रिय गेमर्स, एसएसजी गेम का रख-रखाव सुबह 11:30 बजे से शाम 07:30 बजे तक (IST) 18/02/2020 से शुरू होता है। जरूरत के मुताबिक रखरखाव का समय बढ़ाया जा सकता है। असुविधा के लिए क्षमा करें। अधिक जानकारी के लिए, लाइव चैट और व्हाट्सएप / टेलीग्राम में हमारे ग्राहक सहायता से संपर्क करें।',
                },
                {
                    'id'    : '5725a680b3249760ea21de52',
                    'srialno'  : '4',
                    'subject'  : 'एटी गेम रखरखाव ',
                    'messagedate' :'2020-01-17 18:32:44',
                    'subject_full'  : 'और खेल रखरखाव ',
                    'discription'  : 'प्रिय गेमर्स, एसएसजी गेम का रख-रखाव सुबह 11:30 बजे से शाम 07:30 बजे तक (IST) 18/02/2020 से शुरू होता है। जरूरत के मुताबिक रखरखाव का समय बढ़ाया जा सकता है। असुविधा के लिए क्षमा करें। अधिक जानकारी के लिए, लाइव चैट और व्हाट्सएप / टेलीग्राम में हमारे ग्राहक सहायता से संपर्क करें।',
                },
            ]
        },
    ]
}
export default messageDb;

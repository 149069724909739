//import React, {useEffect} from "react";
import React, {useRef, useEffect, useCallback} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Carousel from "react-slick";
import Card from "../elements/Card/Card.js";
//import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
// core components
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js"; 
import SectionLoginbox from "../sections/SectionLoginBox";
import SectionLoginboxMobile from "../sections/SectionLoginBoxMobile";
import jwtService from '../../../services/jwtService' 
import styles from "../assets/jss/components/tabsDashboardGames.js";
import Button from "../elements/CustomButtons/Button";
import Lodder from "../assets/img/lodder.gif"; 
import Loadgaming from "../assets/img/lodi.gif"; 
import Allgame from "../assets/img/allgame.png";  
import gamesDb from "../data/games"; 
import * as Gurl from "../../../global/Global.js"
import Zoom from '@material-ui/core/Zoom';  
import axios from 'axios';  
import CustomDropdown from "../elements/CustomDropdown/CustomDropdown"; 
// @database
import { useIndexedDB } from 'react-indexed-db';

const token = "kdkddjdj2929j38jv88fpwad83pakwdi3";
let tabData = "";
let setTabData;
let providerId = "0";
let proData = "";
let setProData;
var searchText = "";
// var isExistApiCalls = false;
var searchCat = false;
let limitStartFrom = 0;
let setLimitStartFrom;
let loadMore = null;
let allGameCount = 0;
let setAllGameCount;
let setLoadMore;
let isGameLoading = "NO";
let setIsGameLoading;
var isAllGameLoaded = "NO";
var isCatLoaded = "NO";
var isCatLoading = "NO";
var isProLoaded = "NO";
var isProLoading = "NO";
var checkToggle = 1;
export default function UserGames(props) {

    function parseJwt(token) {
        if (!token) { return; }
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }
    var registration_type ;
    var themeInfo = getCurrentThemeInfo(); 
    function getCurrentThemeInfo(){
        if(window.localStorage.getItem('jwt_theme_token') != null){
            let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
            return themeData;
        }
    } 
    
    if(themeInfo != null && themeInfo.id != null){ 
        if(themeInfo.registration_type != null && themeInfo.registration_type > 0){ 
          registration_type = themeInfo.registration_type;
        }  
    } 
    
    //[limitStartFrom, setLimitStartFrom] = React.useState(((limitStartFrom !== null && limitStartFrom > 0) ? limitStartFrom : 0));
    console.log("III:" + limitStartFrom);
    const limitMaxRange = 40;
    [proData, setProData] = React.useState(((proData !== null && proData.length > 0) ? proData : [{"provider_id":0, "provider_name":"All Games",}]));
    
    
    [tabData, setTabData] = React.useState(((tabData !== null && tabData.length > 0) ? tabData : [{"category_id":0, "category_name":"All Games","total_game":0}]));
    [loadMore, setLoadMore] = React.useState(((loadMore !== null && loadMore.length > 0) ? loadMore : "NO"));
    //[isGameLoading, setIsGameLoading] = React.useState(((isGameLoading !== null && isGameLoading.length > 0) ? isGameLoading : "NO"));
    [allGameCount, setAllGameCount] = React.useState(((allGameCount !== null && allGameCount > 0) ? allGameCount : 0));
    const [y, setY] = React.useState(window.scrollY);
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
    useEffect(() => {
        window.addEventListener("scroll", (e) => handleNavigation(e));
        //window.addEventListener('resize', handleResize);
        return () => { // return a cleanup function to unregister our function since its gonna run multiple times
            window.removeEventListener("scroll", (e) => handleNavigation(e));
            //window.removeEventListener('resize', handleResize);
        };
    }, [y]);
    function handleResize() {
        console.log("scrolling resizing:")
        setWindowDimensions(getWindowDimensions());
      }
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
      }
    const handleNavigation = 
        e => {
        //     var node = e.currentTarget;
        //     const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
        //     console.log("scrollHeight:"+node.scrollHeight+"::scrollTop:" + node.scrollTop +":clientHeight"+node.clientHeight);
        //     if (bottom) {
        //         console.log('BOTTOM REACHED:', bottom);
        //     }
            const windowCurrent = e.currentTarget;
        if (hasReachedBottom(windowCurrent,windowCurrent.scrollY)) {
            if(loadMore === "NO" && isGameLoading === "NO" && isAllGameLoaded === "NO"){
                console.log("YES CALLING:");
                LoadMoreRecord();
            }
        }
        //   console.log("scrollHeight:"+node.scrollHeight+"::scrollTop:" + node.scrollTop +":clientHeight"+windowCurrent.scrollY);
        //   let newHeight = (windowDimensions.height * 0.8)-10;
        //   if(windowCurrent.pageYOffset >= newHeight && loadMore === false && isGameLoading === false){
        //     // setIsGameLoading(true);
        //     // LoadMoreRecord();
        //   }
        //   if (y > windowCurrent.scrollY) {
        //     console.log("scrolling up:"+windowDimensions.width+"::" + windowDimensions.height +":"+windowCurrent.pageYOffset);
        //   } else if (y < windowCurrent.scrollY) {
        //     console.log("scrolling down:"+windowDimensions.width+"::" + windowDimensions.height +":"+windowCurrent.pageYOffset);
        //   }
          setY(windowCurrent.scrollY);
        };
        var LoadMoreRecord = function(){
            
            limitStartFrom = limitStartFrom + limitMaxRange;
            isGameLoading = "NO";
            setLoadMore("YES");
            //setLimitStartFrom((limitStartFrom + limitMaxRange));
            console.log("LOAD START");
        }  
        function hasReachedBottom(windowCurrent, currentHeight) {
            //console.log(windowCurrent);
            console.log("availHeight:"+windowCurrent.screen.availHeight+":::offsetHeight:"+document.body.offsetHeight+"::"+ "::" + ((document.body.offsetHeight + document.body.scrollTop) - windowCurrent.screen.availHeight)+"::scrollTop:" + document.body.scrollTop +":clientHeight"+currentHeight);
            //return ((document.body.offsetHeight * 0.70) + document.body.scrollTop <= currentHeight);
            return (((document.body.offsetHeight + document.body.scrollTop) - windowCurrent.screen.availHeight) <= currentHeight);
          }
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 10,
        slidesToScroll: 1,
        autoplay: false,
        responsive: [
            {
              breakpoint: 2000,
              settings: {
                slidesToShow: 8,
                slidesToScroll: 1, 
              }
            },
            {
              breakpoint: 1700,
              settings: {
                slidesToShow: 7,
                slidesToScroll: 1, 
              }
            },
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 7,
                slidesToScroll: 1, 
              }
            },
            {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 6,
                  slidesToScroll: 1, 
                }
            },
            {
                breakpoint: 1100,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 1, 
                }
            },
            {
                breakpoint: 1000,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1, 
                }
            },
            {
                breakpoint: 900,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1, 
                }
            },
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1, 
                }
            },
            {
                breakpoint: 540,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1, 
                }
            },
            {
                breakpoint: 440,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1, 
                }
            }
          ]
       }; 

    var gpsLocation = {latitude: 0, longitude: 0};
    //const { add, getByIndex, update } = useIndexedDB('Games');
    styles.singlegame.borderColor = props.themeColors.bgColors.primary_bgcolor;
    styles.singlegame["&::before"].background = props.themeColors.bgColors.gradient_border;
    styles.playgamebtn.background = props.themeColors.bgColors.primary_bgcolor; 
    styles.playgamebtn.color = props.themeColors.bgColors.text_primary_color; 
    styles.mybtn2.background = props.themeColors.bgColors.primary_bgcolor; 
    styles.mybtn2.color = props.themeColors.bgColors.text_primary_color; 
   // styles.mybtn2["& span"].color = props.themeColors.bgColors.text_primary_dark_color; 
    styles.mybtn2["&:hover"].background = props.themeColors.bgColors.main_color;
    styles.mybtn2["&:hover"].color = props.themeColors.bgColors.text_primary_dark_color;
    styles.gametabpage.color = props.themeColors.bgColors.text_primary_color; 
    styles.gametabpage["&.active"].borderBottomColor = props.themeColors.bgColors.main_color; 
    styles.gametabpage["&.active"].color = props.themeColors.bgColors.text_primary_color; 
    // styles.gamelabel.color = props.themeColors.bgColors.text_primary_color;
    styles.gamesearching.background = props.themeColors.bgColors.secondary_dark_bgcolor;
    styles.gamesearching.color = props.themeColors.bgColors.text_primary_color;
    styles.searchgameicon.color = props.themeColors.bgColors.primary_color;
    styles.gamesearchingbtn.background = props.themeColors.bgColors.secondary_dark_bgcolor;
    styles.gameproviderdrop.background = props.themeColors.bgColors.secondary_dark_bgcolor; 
    styles.gamelabel.color = props.themeColors.bgColors.text_secondary_color; 
    styles.gamebtnwrap.background = props.themeColors.bgColors.primary_color;
    styles.gamebtnwrap["& .slick-slide button.active"].background = props.themeColors.bgColors.gradient_border; 
    styles.gamebtnwrap["& .slick-slide button"].color = props.themeColors.bgColors.primary_bgcolor; 
    styles.gamecategaryname["& .slick-arrow::before"].color = props.themeColors.bgColors.text_primary_dark_color;



    // const [clickEvent, setTabClickEvent] = React.useState("NOVALUE");
    const [gameData, setGamesData] = React.useState("NOVALUE");
    const [gameDataOnHold, setGameDataOnHold] = React.useState("NOVALUE");

    const useStyles = makeStyles(styles);
    const classes = useStyles();
    if(isCatLoading === "NO" && isCatLoaded === "NO"){
        isCatLoading = "YES";
        fetchCategories();
    }
    function fetchCategories(){
        let gameIds = "";
        if(props.gameIds != null && props.gameIds.length > 0 && props.gameIds !== "ALLGAMES"){
            try{
                let gameIdsInfos = JSON.parse(props.gameIds);
                if(gameIdsInfos != null && gameIdsInfos.game_ids != null && gameIdsInfos.game_ids.length > 0){
                    gameIds = gameIdsInfos.game_ids;
                }
            }catch(error){
                console.log(error);
            }
        }
        if((gameIds.length > 0) || props.gameIds === "ALLGAMES"){
            let totalGames = gameIds.split(",");
            if(totalGames.length == 1 && props.gameIds !== "ALLGAMES"){

            }else{
                if(props.gameIds === "ALLGAMES"){
                    gameIds = props.gameIds;
                }
                const request = axios.post(Gurl.GETGAMECATS, {
                    "token" : (window.localStorage.getItem('jwt_access_token') != null) ? window.localStorage.getItem('jwt_access_token') : "NOTOKEN",
                    "gameids" : gameIds,
                    "provider": (providerId != null && providerId > 0) ? providerId : 0,
                });
                request.catch((error) => { 
                    isCatLoaded = "NO";
                    isCatLoading = "NO";
                });
                request.then((response) => { 
                    if(response !== null && response.data !== null && response.data.data !== null){ 
                        var initData = "";
                        if(Array.isArray(response.data.data)){
                            initData = [...[{"category_id":0, "category_name":"All Games"}], ...response.data.data];
                        }else{
                            initData = [...[{"category_id":0, "category_name":"All Games"}], ...[response.data.data]];
                        }
                        setTabData(initData);
                        if(Array.isArray(response.data.data)){
                            let tempAllGameCount = 0;
                            for(var cArr=0;cArr<response.data.data.length;cArr++){
                                let oneByOneCat = response.data.data[cArr];
                                tempAllGameCount += oneByOneCat.total_game;
                            }
                            if(response.data.total_game !== null){
                                tempAllGameCount = response.data.total_game;
                            }
                            setAllGameCount(tempAllGameCount);
                        }else{
                            if(response.data.data != null && response.data.data.total_game != null){
                                setAllGameCount(response.data.data.total_game);
                            }
                        }
                    }
                    isCatLoaded = "YES";
                    isCatLoading = "NO";
                });  
            }
        }
        
    }

    if(isProLoading === "NO" && isProLoaded === "NO"){
        isProLoading = "YES";
        fetchProviders();
    }
    function fetchProviders(){
        let gameIds = "";
        if(props.gameIds != null && props.gameIds.length > 0 && props.gameIds !== "ALLGAMES"){
            try{
                let gameIdsInfos = JSON.parse(props.gameIds);
                if(gameIdsInfos != null && gameIdsInfos.game_ids != null && gameIdsInfos.game_ids.length > 0){
                    gameIds = gameIdsInfos.game_ids;
                }
            }catch(error){
                console.log(error);
            }
        }
        if((gameIds.length > 0) || props.gameIds === "ALLGAMES"){
            let totalGames = gameIds.split(",");
            if(totalGames.length == 1 && props.gameIds !== "ALLGAMES"){

            }else{
                if(props.gameIds === "ALLGAMES"){
                    gameIds = props.gameIds;
                }
                const request = axios.post(Gurl.GET_MY_PROVIDERS, {
                    "token" : (window.localStorage.getItem('jwt_access_token') != null) ? window.localStorage.getItem('jwt_access_token') : "NOTOKEN",
                    "gameids" : gameIds
                });
                request.catch((error) => { 
                    isProLoaded = "NO";
                    isProLoading = "NO";
                });
                request.then((response) => { 
                    isProLoaded = "YES";
                    isProLoading = "NO";
                    if(response.data.data != null){ 
                        var initpData = "";
                        if(Array.isArray(response.data.data)){
                            initpData = [...[{"provider_id":0, "provider_name":"All Games"}], ...response.data.data];
                        }else{
                            initpData = [...[{"provider_id":0, "provider_name":"All Games"}], ...[response.data.data]];
                        }
                        setProData(initpData);
                         
                    }
                    
                });  
            }
        }
        
    }
    
    function loadGamesData(){
        // const request = axios.post(Gurl.GETGAMES, {
        //     "token" : token,
        //     "gameids" : props.gameIds,
        // });
        if(isGameLoading === "NO"){
            isGameLoading = "YES";
            console.log("HITTING ISGL:" + isGameLoading + ":::ISLOAD:" + loadMore);
            if(loadMore === "YES"){
                if(document.getElementById("loaderimg") != null){
                    document.getElementById("loaderimg").style.display = "block";
                }
            }
            let gameIds = "";
            if(props.gameIds != null && props.gameIds.length > 0 && props.gameIds !== "ALLGAMES"){
                try{
                    
                    let gameIdsInfos = JSON.parse(props.gameIds);
                    if(gameIdsInfos != null && gameIdsInfos.game_ids != null && gameIdsInfos.game_ids.length > 0){
                        gameIds = gameIdsInfos.game_ids;
                    }
                }catch(error){
                    console.log(error);
                }
            }
            if((gameIds.length > 0) || props.gameIds === "ALLGAMES"){
                let totalGames = gameIds.split(",");
                if(totalGames.length == 1 && props.gameIds !== "ALLGAMES"){
                    fetchAuthenticationToken(totalGames[0], null);
                }else{
                    if(props.gameIds === "ALLGAMES"){
                        gameIds = props.gameIds;
                    }
                    const request = axios.post(Gurl.GETGAMES, {
                        "token" : (window.localStorage.getItem('jwt_access_token') != null) ? window.localStorage.getItem('jwt_access_token') : "NOTOKEN",
                        "gameids" : gameIds,
                        "search": (searchText === "") ? null : searchText,
                        "category": (searchCat === false) ? 0 : searchCat,
                        "provider": (providerId != null && providerId > 0) ? providerId : 0,
                        LSTART: limitStartFrom,
                        LEND: limitMaxRange
                    });
                    request.catch((error) => { 
                        if(document.getElementById("showGameLoad") != null){
                            document.getElementById("showGameLoad").style.display = "none";
                        }
                        setGamesData("NOVALUE");
                        setLoadMore("NO");
                        isGameLoading = "NO";
                        if(loadMore === "YES"){
                            if(document.getElementById("loaderimg") != null){
                                document.getElementById("loaderimg").style.display = "none";
                            }
                        }
                    });
                    request.then((response) => { 
                        if(response.data.data != null){ 
                            if(Array.isArray(response.data.data)){ 
                                try{
                                    // getByIndex('id', props.gameIds).then(personFromDB => {
                                    //     if(personFromDB !== null && personFromDB !== undefined && JSON.stringify({id: props.gameIds, ...response.data.data}) !== JSON.stringify(personFromDB)){
                                    //         update({id: props.gameIds, data: response.data.data}).then(
                                    //             event => {
                                    //                 //alert('ID Generated: ', JSON.stringify(event.target));
                                    //             },
                                    //             error => {
                                    //                 //alert(error);
                                    //             }
                                    //         );
                                    //     }else{
                                    //         if(JSON.stringify({id: props.gameIds, ...response.data.data}) !== JSON.stringify(personFromDB)){
                                    //             add({id: props.gameIds, data: response.data.data}).then(
                                    //                 event => {
                                    //                 //alert('ID Generated: ', JSON.stringify(event.target));
                                    //                 },
                                    //                 error => {
                                    //                 //alert(error);
                                    //                 }
                                    //             );
                                    //         }
                                    //     }
                                    // });
                                }catch(error){
            
                                }
                            }
                            
                            if(Array.isArray(response.data.data)){
                                if(loadMore === "YES" && Array.isArray(gameDataOnHold)){
                                    setGameDataInFormat(gameDataOnHold.concat(response.data.data));
                                    setGameDataOnHold(gameDataOnHold.concat(response.data.data));
                                }else{
                                    setGameDataInFormat(response.data.data);
                                    setGameDataOnHold(response.data.data);
                                }
                            }else{
                                let initGames = [response.data.data];
                                if(gameDataOnHold !== null && gameDataOnHold.length > 0 && loadMore === "YES"){
                                    setGameDataInFormat(gameDataOnHold.concat(initGames));
                                    setGameDataOnHold(gameDataOnHold.concat(initGames));
                                }else{
                                    setGameDataInFormat(initGames);
                                    setGameDataOnHold(initGames);
                                }
                            }
                            isGameLoading = "NO";
                            setLoadMore("NO");
                            if(loadMore === "YES"){
                                if(document.getElementById("loaderimg") != null){
                                    document.getElementById("loaderimg").style.display = "none";
                                }
                            }
                        }else{
                            isAllGameLoaded = "YES";
                            if(loadMore === "YES"){
                                if(!Array.isArray(gameDataOnHold)){
                                    setGamesData("NO GAMES FOUND");
                                }
                            }else{
                                setGamesData("NO GAMES FOUND");
                            }
                            
                            isGameLoading = "NO";
                            setLoadMore("NO");
                            //props.propTypes.openPopup("popup");
                            if(loadMore === "YES"){
                                if(document.getElementById("loaderimg") != null){
                                    document.getElementById("loaderimg").style.display = "none";
                                }
                            }
                        }
                        if(document.getElementById("loaderimg") != null){
                            document.getElementById("loaderimg").style.display = "none";
                        }
                        if(document.getElementById("showGameLoad") != null){
                            document.getElementById("showGameLoad").style.display = "none";
                        }
                    });  
                }
            }
        }
    }

    function setGameDataInFormat(dataResponse){
        var myOutPut = "";
        if(Array.isArray(dataResponse)){ 
            myOutPut = (
                dataResponse.map((jsondata) => {
                    /*var newData = {category_id:jsondata.category_id, category_name: jsondata.category_name};
                    if(tabData.indexOf(JSON.stringify(newData)) === -1){
                        tabData.push(JSON.stringify(newData));
                    }*/
                    let selectedProduct = undefined;
                    
                    if(jsondata.categories_info != null){
                        var catInfo = JSON.parse(jsondata.categories_info);
                        // catInfo.map((catDataJson) => {
                        //     var newData = {category_id:catDataJson.category_id, category_name: catDataJson.name};
                        //     if(tabData.indexOf(JSON.stringify(newData)) === -1){
                        //         tabData.push(JSON.stringify(newData));
                        //     }
                        // });
                        if(searchCat !== false && searchCat !== 0){
                            selectedProduct = catInfo.find(inCatInfo => inCatInfo.category_id === searchCat);
                        }
                    }
                    console.log("CS:" + selectedProduct);
                    //if(selectedProduct != undefined || searchCat === false || searchCat === 0){
                        if(searchText.length === 0 || (searchText.length > 0 &&  ((jsondata.tags != null && jsondata.tags.toLowerCase().includes(searchText.toLowerCase())) || (jsondata.game_name != null && jsondata.game_name.toLowerCase().includes(searchText.toLowerCase()))))){
                            var limit = "Play Now";
                            if(jsondata.table_limits !== undefined && jsondata.table_limits !== null && jsondata.table_limits.length > 0 && jsondata.currency_code !== null && jsondata.currency_code.length > 0){
                                let limitJSON = JSON.parse(jsondata.table_limits);
                                if(limitJSON[jsondata.currency_code] != null){
                                    let limitDetails = limitJSON[jsondata.currency_code];
                                    limit = limitDetails.symbol + " " + limitDetails.min + " - " + limitDetails.max;
                                }
                            }
                            return (<GridItem className={classes.gridgame + " freddygame gameboxicoin " + classes.gameboxicoin} xs={6} sm={6} md={3} xl={2} key={jsondata.id}>
                                <Zoom in={true} timeout={1000} key={"gzoom_" + jsondata.game_id}> 
                                    <div className={"singlegame sportogame " + classes.singlegame} key={"gdiv_" + jsondata.game_id}>
                                        <img  key={"gimg1_" + jsondata.game_id} src={((jsondata.game_icon != null && (jsondata.game_icon.substr(0,4) === "http" || jsondata.game_icon.substr(0,2) === "//") && ((navigator != null && navigator.onLine != null && navigator.onLine === true) || (navigator === null && navigator.onLine === null))) ? jsondata.game_icon : "assets/img/" + ((jsondata.game_icon != null && jsondata.game_icon.length > 0  && ((navigator != null && navigator.onLine != null && navigator.onLine === true) || (navigator === null && navigator.onLine === null))) ? jsondata.game_icon : "game_img.jpg"))} alt={""} />
                                        {(() => {
                                        if(jsondata.provider_icon !== undefined && jsondata.provider_icon !== null && jsondata.provider_icon.length > 0)
                                        {
                                            return(
                                                <img  key={"gimg2_" + jsondata.game_id} style={{
                                                    width:"32px", 
                                                    height:"32px", 
                                                    position: "absolute", 
                                                    top:"5px", 
                                                    right:"5px"}} src={jsondata.provider_icon}/>
                                            )
                                        }else {
                                            return(   
                                                <span  key={"gspan_" + jsondata.game_id} style={{
                                                    width:"32px", 
                                                    height:"32px", 
                                                    position: "absolute", 
                                                    top:"5px", 
                                                    right:"5px"}}  ></span>
                                            )
                                        }
                                    })()}
                                        <span  key={"gspan2_" + jsondata.game_id} className={"gamelabel " + classes.gamelabel}>{jsondata.game_name}</span>
                                        <div  key={"gdiv2_" + jsondata.game_id} className={"spikegamehover"}>
                                        
                                        <table key={"gtb_" + jsondata.game_id}> 
                                        <tbody>
                                            <tr key={"gtr_" + jsondata.game_id}>
                                                <td key={"gtd_" + jsondata.game_id}>
                                                    <Button
                                                        key={"gbtn_" + jsondata.game_id}
                                                        onClick={(event) => fetchAuthenticationToken(jsondata.game_id, event)}
                                                        title={jsondata.game_name}
                                                        className={"mybtn2 " + classes.playgamebtn}
                                                    >
                                                        {limit}
                                                    </Button> 
                                                    
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <span  key={"gspan3_" + jsondata.game_id} className={"gamelabel " + classes.gamelabel}>{jsondata.game_name}</span>
                                        
                                        </div>
                                    </div> 
                                </Zoom>            
                            </GridItem>)
                        }
                    //}
                    
                })
            );
        }else{
            var jsondata = dataResponse;
            var limit = "Play Now";
            if(jsondata.table_limits !== undefined && jsondata.table_limits !== null && jsondata.table_limits.length > 0 && jsondata.currency_code !== null && jsondata.currency_code.length > 0){
                let limitJSON = JSON.parse(jsondata.table_limits);
                if(limitJSON[jsondata.currency_code] != null){
                    let limitDetails = limitJSON[jsondata.currency_code];
                    limit = limitDetails.symbol + " " + limitDetails.min + " - " + limitDetails.max;
                }
                
            }
            myOutPut = ( 
                <GridItem className={classes.gridgame  + " freddygame gameboxicoin  " + classes.gameboxicoin} xs={6} sm={6} md={3} xl={2}>
                    <Zoom in={true} timeout={1000}> 
                    <div className={" singlegame " + classes.singlegame}>
                        <img src={((jsondata.game_icon != null && (jsondata.game_icon.substr(0,4) === "http" || jsondata.game_icon.substr(0,2) === "//") && ((navigator != null && navigator.onLine != null && navigator.onLine === true) || (navigator === null && navigator.onLine === null))) ? jsondata.game_icon : "assets/img/" + ((jsondata.game_icon != null && jsondata.game_icon.length > 0  && ((navigator != null && navigator.onLine != null && navigator.onLine === true) || (navigator === null && navigator.onLine === null))) ? jsondata.game_icon : "game_img.jpg"))} alt={""} />
                        {(() => {
                            if(jsondata.provider_icon === undefined || jsondata.provider_icon.length > 0 || jsondata.provider_icon === null)
                            {
                                return(
                                    <img style={{
                                        width:"32px", 
                                        height:"32px", 
                                        position: "absolute", 
                                        top:"5px", 
                                        right:"5px"}} src={jsondata.provider_icon}/>
                                )
                            }else {
                                return(   
                                    <span style={{
                                        width:"32px", 
                                        height:"32px", 
                                        position: "absolute", 
                                        top:"5px", 
                                        right:"5px"}}  ></span>
                                )
                            }
                        })()}                
                        <span className={"gamelabel " + classes.gamelabel}>{JSON.stringify(jsondata)}{jsondata.game_name}</span>
                        <div className={"spikegamehover"}>
                        
                        {/* {gamesDb[sessionStorage.getItem('lang')].map((jsondataLang) => (
                        <Button
                            onClick={(event) => fetchAuthenticationToken(jsondata.game_id, event)}
                            title={jsondata.game_name}
                            className={"mybtn2 " + classes.playgamebtn}
                        >
                             {jsondataLang.btnName} 
                        </Button>
                        ))} */}
                         <table>
                                                <tr>
                                                    <td>
                                                        
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Button
                                                            onClick={(event) => fetchAuthenticationToken(jsondata.game_id, event)}
                                                            title={jsondata.game_name}
                                                            className={"mybtn2 " + classes.playgamebtn}
                                                        >
                                                            {limit}
                                                        </Button> 
                                                        
                                                    </td>
                                                </tr>
                                            </table>
                        </div>
                    </div> 
                    </Zoom>
                </GridItem>
                
            );
        }
        setGamesData(myOutPut);
        
    }
    function searchTextChange(event){
        if(document.getElementById("showGameLoad") != null){
            document.getElementById("showGameLoad").style.display = "flex";
        }
        if(event.target !== null && event.target.value !== null){
            searchText = event.target.value;
            try{
                if(gameDataOnHold !== "NOVALUE" && gameDataOnHold != null){
                    //setGameDataInFormat(gameDataOnHold); 
                    limitStartFrom = 0;
                    isAllGameLoaded = "NO";
                    loadGamesData();
                }else{
                    // getByIndex('id', props.gameIds).then(allData => {
                    //     if(allData === null || allData === undefined){
                    //         loadGamesData();
                    //     }else{
                    //         setGameDataInFormat(allData.data); 
                    //     }
                    // });
                    limitStartFrom = 0;
                    isAllGameLoaded = "NO";
                    loadGamesData();
                }
            }catch(error){
                
            }
        }
    }
    function setProviderDetail(pId){
        if(document.getElementById("showGameLoad") != null){
            document.getElementById("showGameLoad").style.display = "flex";
        }
        providerId = pId;
        limitStartFrom = 0;
        isAllGameLoaded = "NO";
        isCatLoading = "NO";
        isCatLoaded = "NO";
        fetchCategories();
        loadGamesData();
        checkToggle = 1;
        if(document.body.getElementsByClassName("gamesearchingbtn")[0] != null){
            document.body
            .getElementsByClassName("gamesearchingbtn")[0]
            .setAttribute("style", "display:none;");
        }
    }
    function openCategoryWise(categoryId){
        if(document.getElementById("showGameLoad") != null){
            document.getElementById("showGameLoad").style.display = "flex";
        }
        searchCat = categoryId;
        try{
            if(gameDataOnHold !== "NOVALUE" && gameDataOnHold != null){
                limitStartFrom = 0;
                isAllGameLoaded = "NO";
                loadGamesData();
                //setGameDataInFormat(gameDataOnHold); 
            }else{
                // getByIndex('id', props.gameIds).then(allData => {
                //     if(allData === null || allData === undefined){
                //         loadGamesData();
                //     }else{
                //         setGameDataInFormat(allData.data); 
                //     }
                // });
                limitStartFrom = 0;
                isAllGameLoaded = "NO";
                loadGamesData();
            }
        }catch(error){
            
        }
    }
    
    const fetchAuthenticationToken = async function(myGameId, event) { 
        if(parseInt(myGameId) > 0 && window.localStorage.getItem('jwt_access_token') != null && jwtService.isAuthenticationLogin()){
            if(event != null && event.target != null){
                event.target.setAttribute("style","pointer-events:none;cursor:default;");
                document.getElementById("showGameLoad").style.display = "flex";
            }
            await getMyLocation();
            var newSenderArray = {"gameid": myGameId,"token":window.localStorage.getItem('jwt_access_token'),'latitude': gpsLocation.latitude, 'longitude': gpsLocation.longitude}
            var gettingToken = await getGamesToken(newSenderArray);
            if(gettingToken != null){
                //alert("OutPUT:" + JSON.stringify(gettingToken));
            }
            if(gettingToken.status != null && gettingToken.status === true){
                if(gettingToken.MYGAMEAUTH != null && gettingToken.MYGAMEAUTH.length > 0){
                    var finalAuthUrl = JSON.parse(gettingToken.MYGAMEAUTH);
                    if(finalAuthUrl.entry != null && finalAuthUrl.entry.length > 0){
                      
                        //window.open(finalAuthUrl.entry, "_blank","fullscreen,scrollbars") //to open new page
                        window.localStorage.setItem("game_access", finalAuthUrl.entry);
                        window.open("/playnow", "_self") //to open new page
                        if(event != null && event.target != null){
                            event.target.setAttribute("style","pointer-events:auto;cursor:pointer;");
                        }
                    }else{
                        if(finalAuthUrl.error != null){
                            if(isObject(finalAuthUrl.error)){
                                // var JSONInfo = finalAuthUrl.error;
                                if(isObject(finalAuthUrl.error.data) && finalAuthUrl.error.data.message !== undefined){
                                    document.getElementById("showGameLoad").style.display = "none";
                                    props.sectionPopup.openPopup("popup", "Error", finalAuthUrl.error.data.message);
                                }else{
                                    props.sectionPopup.openPopup("popup", "Error", "Getting Authentication Issue on server. Please contact to Sponsor.");
                                }
                            }else{
                                document.getElementById("showGameLoad").style.display = "none";
                                props.sectionPopup.openPopup("popup", "Error", finalAuthUrl.error);
                            }
                            getMyLocation();
                        }
                        if(event != null && event.target != null){
                        
                            event.target.setAttribute("style","pointer-events:auto;cursor:pointer;");
                        }
                    }
                }else{
                    if(event != null && event.target != null){
                        event.target.setAttribute("style","pointer-events:auto;cursor:pointer;");
                    }
                }
            }else{
                if(event != null && event.target != null){
                    event.target.setAttribute("style","pointer-events:auto;cursor:pointer;");
                }
            }
            
        }else{
            if(registration_type === 1){ 
                SectionLoginboxMobile.openPopup("login");
            }else{
                SectionLoginbox.openPopup("login");
            }  
            if(event != null && event.target != null){
                event.target.setAttribute("style","pointer-events:auto;cursor:pointer;");
            }
        }
    }
    let isObject = function(a) {
        return (!!a) && (a.constructor === Object);
    };
    async function getMyLocation() {
        const location = window.navigator && window.navigator.geolocation
        
        if (location) {
            let pro = new Promise((resolve,reject) =>  {
                location.getCurrentPosition((position) => {
                    if(window.localStorage.getItem('jwt_access_token') != null){
                        gpsLocation = {latitude: position.coords.latitude, longitude: position.coords.longitude};
                        resolve({latitude: position.coords.latitude, longitude: position.coords.longitude});
                    }
                }, (error) => {
                    //this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
                    resolve({status:false});
                });
            }); 
            const val = await pro;
            return val;
        }else{
            return;
        }
    
      }
    async function getGamesToken(newSenderArray) {
        let pro = new Promise((resolve,reject) => {
            try {
                const request = axios.post(Gurl.GETGAMEAUTH, newSenderArray,{headers:{"Accept": "application/json"}});
                request.catch((error) => {
                    var data = {
                        "status":false,
                        "data":error
                    }
                    resolve(data);
                })
                request.then((response) => {
                    if(response.data != null && response.data.status != null && response.data.status === true){
                        resolve(response.data);
                    }else{
                        var data = {
                            "status":false,
                            "data":response
                        }
                        resolve(data);
                    }
                })
            } catch (error) {
                console.log("MYIN:"+error);
                var data = {
                    "status":false,
                    "data":error
                }
                resolve(data);
            }
        }); 
        const val = await pro;
        return val;
    }
    
    if((gameData === "NOVALUE" || loadMore === "YES") && isGameLoading === "NO" && isAllGameLoaded === "NO"){
        
        console.log("START Load");
        if(loadMore !== "YES"){
            setGamesData(<img src={Lodder} className={classes.loadimg} alt=""/>); 
        }
        
        if(navigator != null && navigator.onLine != null && navigator.onLine !== true){
            try{
                // getByIndex('id', props.gameIds).then(allData => {
                //     if(allData === null || allData === undefined){
                //         loadGamesData();
                //     }else{
                //         setGameDataInFormat(allData.data); 
                //     }
                // });
                loadGamesData();
            }catch(error){
                
            }
        }else{
            loadGamesData();
        }
    }
      

    return (
    
        <div id="nav-tabs"> 
        {(() => {
            if(tabData !== null) {
            return( 
                    <div className={classes.gamebtnwrap + " gamebtnwarp"}>
           <div className={classes.gamescrolltab}> 
            
                <Card carousel className={classes.gamecategaryname}>
                <Carousel {...settings}> 
                    {tabData.map((jsondata) => {
                    //jsondata = JSON.parse(jsondata);
                    let totalGames = 0;
                    if(jsondata.category_id == 0){
                        totalGames = allGameCount;
                    }else{
                        totalGames = jsondata.total_game;
                    }
                    let cateIcon;
                    if(jsondata.icon === null || jsondata.icon === undefined || jsondata.icon.length === 0 ){
                        cateIcon = Allgame;
                    }else{
                        cateIcon = jsondata.icon;
                    } 
                    // alert(JSON.stringify(jsondata.icon));
                    return (
                      
                        <Button
                            color="transparent"
                            className={classes.tabbtn + " tabbtn" + ((jsondata.category_id === searchCat || (searchCat === false && jsondata.category_id === 0)) ? " active " : " ") + classes.gametabpage  }
                            onClick={() => openCategoryWise(jsondata.category_id)}
                        >
                            <img className={classes.categoryicon} src={cateIcon} alt=""/>
                            {jsondata.category_name}
                            <span className={classes.categorycounter }>{totalGames}</span> 
                        </Button> 
                    )
                    })}  
                </Carousel>
                </Card>
                </div> 
                <div className={"gamesearchingwrap"}>
                
                <div className={classes.gamepro}>    
                <CustomDropdown
                    noLiPadding
                    themeColors={props.themeColors}  
                    buttonText={"Providers"}
                    buttonProps={{
                        className: classes.gamesearchingbtn,
                        color: "transparent"
                    }} 
                    dropdownList={[
                        <ul >
                            {proData.map((jsondata) => {
                            let totalPro = 0; 
                            let totalProName ; 
                            if(jsondata.provider_id === 0 ){
                                totalPro = Allgame;
                                totalProName = "All";
                            }else if(jsondata.icon === null || jsondata.icon === undefined || jsondata.icon.length === 0){ 
                                return;
                            }else{
                                totalPro = jsondata.icon;
                            } 
                            return (
                                <li onClick={() => setProviderDetail(jsondata.provider_id) } style={{position: "relative"}}><span style={{position: "absolute", top: "22%", display: "block", textAlign: "center", width: "94%", color: "#fff", fontWeight: "800", background: "rgba(0,0,0,0.8)", borderRadius: "12px", fontSize: "12px",}}>{totalProName}</span><img src={totalPro} alt={jsondata.provider_name} title={jsondata.provider_name}/></li>
                                )
                            })} 
                        </ul>
                    ]}
                />        
                </div> 


                <input type="text" onChange={(event) => {searchTextChange(event)}} className={"gamesearching " + classes.gamesearching} placeholder="Search Game..." />
                <i className={classes.searchgameicon + " fa fa-search"}></i>
                </div>
                <div style={{clear:"both"}}></div>    
                </div>     
            )}
        })()}
            <GridContainer className={classes.spikegamewrap + " CHECKME " + classes.gameboxico + " freddy"} >                                 
                {gameData}
                <GridItem xs={12} sm={12} md={12} xl={12} key={"loaderImg_Grid_Pre"}></GridItem>
                <GridItem xs={12} sm={12} md={12} xl={12} key={"loaderImg_Grid"}>
                    <img src={Lodder} style={{display:"none",height:"50px", margin:"0px auto"}} key="loaderfooter" id={"loaderimg"} />
                </GridItem>
              </GridContainer>
              <div id="showGameLoad" className={classes.showGameLoad}><img style={{alignSelf: "center", width:"60px"}} src={Loadgaming} alt=""/></div>
        </div>    
        
   );
     
}


const accountsDb = {
    en: [
        {
            'id':'3923923892',
            'title':"User Profile",
            'tagName': 'tab1', 
            'userprofilediv': [
                {
                    'id':"name",
                    'display_name':"Full Name"
                },
                {
                    'id':"username",
                    'display_name':"User Name"
                },
                {
                    'id':"email",
                    'display_name':"Email Id",
                },
                {
                    'id':"mobile",
                    'display_name':"Mobile Number",
                },
                // {
                //     'id':"commission_type",
                //     'display_name':"Commission Type",
                // },
                {
                    'id':"date_of_birth",
                    'display_name':"Date Of Birth",
                },
                {
                    'id':"address",
                    'display_name':"Address",
                },
                {
                    'id':"state",
                    'display_name':"State",
                },
                {
                    'id':"locality",
                    'display_name':"City",
                },
                {
                    'id':"pincode",
                    'display_name':"Pincode",
                },
            ],
            'editprofilebtn':"Edit Profile", 
            'cancelbtn':"Cancel",
            'formname':"Full Name",
            'formusername':"Username",
            'formfirstname':"First Name",
            'formlastname':"Last Name",
            'formcountry':"Country",
            'formdob':"Date of Birth",
            'formaddress':"Address",
            'formcity':"City",
            'formstate':"State",
            'formpincode':"Pincode", 
            'formemail':"Email",
            'formphone':"Phone Number", 
            'updateprofilebtn':"Update Profile",
            'formp1':"On name update KYC need to verify again with new name",
            'formp2':"On email update Email need to verify again with new email id",
            'formp3':"On mobile update Mobile need to verify again with new mobile",
        },
        {
            'id':'3923923894',
            'title':"Change Password",
            'tagName': 'tab2', 
            'changepasswordbtn':"Change Password",
            'editprofilebtn':"Edit Profile",
            'oldpassword':"Old Password",
            'newpassword':"New Password",
            'confirmpassword':"Confirm Password",
            'updatepasswordbtn':" Update Password",
            'cancelbtn':"Cancel",
            'formname':"Full Name",
            'formusername':"Username",
            'formemail':"Email",
            'formphone':"Phone Number",
            'formgender':"Gender",
            'formmale':"Male",
            'formfemale':"Female",
            'formdob':"Date of Birth",
            'updateprofilebtn':"Update Profile",
        }, 
        {
            'id':'3923923894',
            'title':"Kyc Verification",
            'tagName': 'tab3', 
        },
        {
            'id':'3923923894',
            'title':"Login History",
            'tagName': 'tab4', 
        }, 
        
    ],
    tm: [
        {
            'id':'3923923892',
            'title':"பயனர் சுயவிவரம்",
            'tagName': 'tab1', 
            'userprofilediv': [
                {
                    'id':"name",
                    'display_name':"முழு பெயர்"
                },
                {
                    'id':"username",
                    'display_name':"பயனர் பெயர்"
                },
                {
                    'id':"email",
                    'display_name':"மின்னஞ்சல் முகவரி",
                },
                {
                    'id':"mobile",
                    'display_name':"கைபேசி எண்",
                },
                // {
                //     'id':"commission_type",
                //     'display_name':"கமிஷன் வகை",
                // },
                {
                    'id':"date_of_birth",
                    'display_name':"பிறந்த தேதி",
                },
                {
                    'id':"address",
                    'display_name':"முகவரி",
                }, 

                {
                    'id':"state",
                    'display_name':"நிலை",
                },
                {
                    'id':"locality",
                    'display_name':"நகரம்",
                },
                {
                    'id':"pincode",
                    'display_name':"அஞ்சல் குறியீடு",
                },
            ],
            'editprofilebtn':"சுயவிவரத்தைத் திருத்து", 
            'cancelbtn':"ரத்துசெய்",
            'formname':"முழு பெயர்",
            'formusername':"பயனர்பெயர்",  
            'formfirstname': "முதல் பெயர்",
            'formlastname': "கடைசி பெயர்",
            'formcountry': "நாடு",
            'formdob': "பிறந்த தேதி",
            'formaddress': "முகவரி",
            'formcity': "நகரம்", 
            'formstate':"நிலை",
            'formpincode':"அஞ்சல் குறியீடு", 
            'formemail': "மின்னஞ்சல்",
            'formphone': "தொலைபேசி எண்",
            'updateprofilebtn': "சுயவிவரத்தைப் புதுப்பிக்கவும்",
            'formp1': "பெயர் புதுப்பிப்பில் KYC புதிய பெயருடன் மீண்டும் சரிபார்க்க வேண்டும்",
            'formp2': "மின்னஞ்சல் புதுப்பிப்பில் மின்னஞ்சல் புதிய மின்னஞ்சல் ஐடியுடன் மீண்டும் சரிபார்க்க வேண்டும்",
            'formp3': "மொபைல் புதுப்பிப்பில் மொபைல் புதிய மொபைலுடன் மீண்டும் சரிபார்க்க வேண்டும்",
        },
        {
            'id':'3923923894',  
            'title': "கடவுச்சொல்லை மாற்று",
            'tagName': 'tab2',
            'changepasswordbtn': "கடவுச்சொல்லை மாற்று",
            'editprofilebtn': "சுயவிவரத்தைத் திருத்து",
            'oldpassword': "பழைய கடவுச்சொல்",
            'newpassword': "புதிய கடவுச்சொல்",
            'confirmpassword': "கடவுச்சொல்லை உறுதிப்படுத்தவும்",
            'updatepasswordbtn': "கடவுச்சொல்லைப் புதுப்பிக்கவும்",
            'cancelbtn': "ரத்துசெய்",
            'formname': "முழுப்பெயர்",  
            'formusername': "பயனர்பெயர்",
            'formemail': "மின்னஞ்சல்",
            'formphone': "தொலைபேசி எண்",
            'formgender': "பாலினம்",
            'formmale': "ஆண்",
            'formfemale': "பெண்",
            'formdob': "பிறந்த தேதி",
            'updateprofilebtn': "சுயவிவரத்தைப் புதுப்பிக்கவும்",
        }, 
        {
            'id':'3923923894',
            'title':"கை சரிபார்ப்பு",
            'tagName': 'tab3', 
        },
        {
            'id':'3923923894',
            'title':"உள்நுழைவு வரலாறு",
            'tagName': 'tab4', 
        }, 
        
    ],
    kn: [
        {
            'id':'3923923892',
            'title':"ಬಳಕೆದಾರರ ವಿವರ",
            'tagName': 'tab1', 
            'userprofilediv': [
                {
                    'id':"name",
                    'display_name':"ಪೂರ್ಣ ಹೆಸರು"
                },
                {
                    'id':"username",
                    'display_name':"ಬಳಕೆದಾರ ಹೆಸರು"
                },
                {
                    'id':"email",
                    'display_name':"ಇಮೇಲ್ ಐಡಿ",
                },
                {
                    'id':"mobile",
                    'display_name':"ಮೊಬೈಲ್ ನಂಬರ",
                },
                // {
                //     'id':"commission_type",
                //     'display_name':"Commission Type",
                // },
                {
                    'id':"date_of_birth",
                    'display_name':"ಹುಟ್ತಿದ ದಿನ",
                },
                {
                    'id':"address",
                    'display_name':"ವಿಳಾಸ",
                },
                 
                {
                    'id':"state",
                    'display_name':"ರಾಜ್ಯ",
                },
                {
                    'id':"locality",
                    'display_name':"ನಗರ",
                },
                {
                    'id':"pincode",
                    'display_name':"ಪಿನ್ಕೋಡ್",
                },
            ],  
            'editprofilebtn': "ಪ್ರೊಫೈಲ್ ಸಂಪಾದಿಸಿ",
            'cancelbtn': "ರದ್ದುಮಾಡು",
            'formname': "ಪೂರ್ಣ ಹೆಸರು",
            'formusername': "ಬಳಕೆದಾರಹೆಸರು",
            'formfirstname': "ಮೊದಲ ಹೆಸರು",
            'formlastname': "ಕೊನೆಯ ಹೆಸರು",
            'formcountry': "ದೇಶ",
            'formdob': "ಹುಟ್ಟಿದ ದಿನಾಂಕ",
            'formaddress': "ವಿಳಾಸ", 
            'formcity': "ಸಿಟಿ",
            'formstate':"ರಾಜ್ಯ",
            'formpincode':"ಪಿನ್ಕೋಡ್", 
            'formemail': "ಇಮೇಲ್",
            'formphone': "ಫೋನ್ ಸಂಖ್ಯೆ",
            'updateprofilebtn': "ಪ್ರೊಫೈಲ್ ನವೀಕರಿಸಿ",
            'formp1': "ಹೆಸರು ನವೀಕರಣದಲ್ಲಿ KYC ಹೊಸ ಹೆಸರಿನೊಂದಿಗೆ ಮತ್ತೆ ಪರಿಶೀಲಿಸಬೇಕಾಗಿದೆ",
            'formp2': "ಇಮೇಲ್ ನವೀಕರಣದಲ್ಲಿ ಇಮೇಲ್ ಹೊಸ ಇಮೇಲ್ ಐಡಿಯೊಂದಿಗೆ ಮತ್ತೆ ಪರಿಶೀಲಿಸಬೇಕಾಗಿದೆ",
            'formp3': "ಮೊಬೈಲ್ ನವೀಕರಣದಲ್ಲಿ ಮೊಬೈಲ್ ಹೊಸ ಮೊಬೈಲ್‌ನೊಂದಿಗೆ ಮತ್ತೆ ಪರಿಶೀಲಿಸಬೇಕಾಗಿದೆ",
        },
        {
            'id':'3923923894',
            'title':"ಗುಪ್ತಪದವನ್ನು ಬದಲಿಸಿ",
            'tagName': 'tab2', 
            'changepasswordbtn':"ಗುಪ್ತಪದವನ್ನು ಬದಲಿಸಿ",  
            'editprofilebtn': "ಪ್ರೊಫೈಲ್ ಸಂಪಾದಿಸಿ",
            'oldpassword': "ಹಳೆಯ ಪಾಸ್‌ವರ್ಡ್",
            'newpassword': "ಹೊಸ ಪಾಸ್‌ವರ್ಡ್",
            'confirmpassword': "ಪಾಸ್ವರ್ಡ್ ದೃ irm ೀಕರಿಸಿ",
            'updatepasswordbtn': "ಪಾಸ್ವರ್ಡ್ ನವೀಕರಿಸಿ",
            'cancelbtn': "ರದ್ದುಮಾಡು",
            'formname': "ಪೂರ್ಣ ಹೆಸರು",
            'formusername': "ಬಳಕೆದಾರಹೆಸರು",
            'formemail': "ಇಮೇಲ್",  
            'formphone': "ಫೋನ್ ಸಂಖ್ಯೆ",
            'formgender': "ಲಿಂಗ",
            'formmale': "ಪುರುಷ",
            'formfemale': "ಸ್ತ್ರೀ",
            'formdob': "ಹುಟ್ಟಿದ ದಿನಾಂಕ",
            'updateprofilebtn': "ಪ್ರೊಫೈಲ್ ನವೀಕರಿಸಿ",
        }, 
        {
            'id':'3923923894',
            'title':"ಕೈಕ್ ಪರಿಶೀಲನೆ",
            'tagName': 'tab3', 
        },
        {
            'id':'3923923894',
            'title':"ಲಾಗಿನ್ ಇತಿಹಾಸ",
            'tagName': 'tab4', 
        }, 
    ],
    ne: [
        {
            'id':'3923923892',
            'title':"प्रयोगकर्ता प्रोफाइल",
            'tagName': 'tab1', 
            'userprofilediv': [
                {
                    'id':"name",
                    'display_name':"पुरा नाम"
                },
                {
                    'id':"username",
                    'display_name':"प्रयोगकर्ता नाम"
                },
                {
                    'id':"email",
                    'display_name':"ईमेल आईडी",
                },
                {
                    'id':"mobile",
                    'display_name':"मोबाइल नम्बर",
                },
                // {
                //     'id':"commission_type",
                //     'display_name':"Commission Type",
                // },
                {
                    'id':"date_of_birth",
                    'display_name':"जन्म मिति",
                },
                {
                    'id':"address",
                    'display_name':"ठेगाना",
                },
                 
                {
                    'id':"state",
                    'display_name':"राज्य",
                },
                {
                    'id':"locality",
                    'display_name':"शहर",
                },
                {
                    'id':"pincode",
                    'display_name':"पिन कोड",
                },
            ],  
            'editprofilebtn': "प्रोफाइल सम्पादन गर्नुहोस्",
            'cancelbtn': "रद्द गर्नुहोस्",
            'formname': "पूर्ण नाम",
            'formusername': "प्रयोगकर्तानाम",
            'formfirstname': "पहिलो नाम",
            'formlastname': "अंतिम नाम",
            'formcountry': "देश",
            'formdob': "जन्म मिति",
            'formaddress': "ठेगाना",
            'formcity': "शहर",  
            'formstate':"राज्य",
            'formpincode':"पिन कोड", 
            'formemail': "ईमेल",
            'formphone': "फोन नम्बर",
            'updateprofilebtn': "प्रोफाइल अपडेट गर्नुहोस्",
            'formp1': "नाम अद्यावधिकमा KYC नयाँ नामको साथ फेरि प्रमाणित गर्न आवश्यक छ",
            'formp2': "ईमेल अपडेट ईमेलमा नयाँ ईमेल आईडीको साथ फेरि प्रमाणित गर्न आवश्यक छ",
            'formp3': "मोबाइल अपडेटमा मोबाइलले फेरि नयाँ मोबाइलको साथ प्रमाणित गर्नु पर्छ",
        },
        {
            'id':'3923923894',
            'title':"पासवर्ड परिवर्तन गर्नुहोस्",
            'tagName': 'tab2', 
            'changepasswordbtn':"पासवर्ड परिवर्तन गर्नुहोस्",  
            'editprofilebtn': "प्रोफाइल सम्पादन गर्नुहोस्",
            'oldpassword': "पुरानो पासवर्ड",
            'newpassword': "नयाँ पासवर्ड",
            'confirmpassword': "पासवर्ड पुष्टि गर्नुहोस्",
            'updatepasswordbtn': "अपडेट पासवर्ड",
            'cancelbtn': "रद्द गर्नुहोस्",
            'formname': "पूर्ण नाम",
            'formusername': "प्रयोगकर्तानाम",  
            'formemail': "ईमेल",
            'formphone': "फोन नम्बर",
            'formgender': "लिंग",
            'formmale': "पुरुष",
            'formfemale': "महिला",
            'formdob': "जन्म मिति",
            'updateprofilebtn': "प्रोफाइल अपडेट गर्नुहोस्",
        }, 
        {
            'id':'3923923894',
            'title':"Kyc प्रमाणीकरण",
            'tagName': 'tab3', 
        },
        {
            'id':'3923923894',
            'title':"लग ईन ईतिहास",
            'tagName': 'tab4', 
        }, 
        
    ],
    tl: [
        {
            'id':'3923923892',
            'title':"వినియోగదారు వివరాలు",
            'tagName': 'tab1', 
            'userprofilediv': [
                {
                    'id':"name",
                    'display_name':"పూర్తి పేరు"
                },
                {
                    'id':"username",
                    'display_name':"వినియోగదారు పేరు"
                },
                {
                    'id':"email",
                    'display_name':"ఇమెయిల్ ఐడి",
                },
                {
                    'id':"mobile",
                    'display_name':"మొబైల్ సంఖ్య",
                },
                // {
                //     'id':"commission_type",
                //     'display_name':"కమిషన్ రకం",
                // },
                {
                    'id':"date_of_birth",
                    'display_name':"పుట్టిన తేది",
                },
                {
                    'id':"address",
                    'display_name':"చిరునామా",
                }, 
                {
                    'id':"state",
                    'display_name':"రాష్ట్రం",
                },
                {
                    'id':"locality",
                    'display_name':"నగరం",
                },
                {
                    'id':"pincode",
                    'display_name':"పిన్ కోడ్",
                },
            ], 
            'editprofilebtn':"ప్రొఫైల్‌ను సవరించండి", 
            'cancelbtn':"రద్దు చేయండి",
            'formname':"పూర్తి పేరు",
            'formusername':"వినియోగదారు పేరు",
            'formfirstname':"మొదటి పేరు",
            'formlastname':"చివరి పేరు",
            'formcountry':"దేశం",
            'formdob':"పుట్టిన తేది",
            'formaddress':"చిరునామా",
            'formcity':"నగరం",
            'formstate':"రాష్ట్రం",
            'formpincode':"పిన్ కోడ్", 
            'formemail':"ఇమెయిల్",
            'formphone':"ఫోను నంబరు", 
            'updateprofilebtn':"ప్రొఫైల్‌ను నవీకరించండి",
            'formp1':"పేరు నవీకరణలో KYC క్రొత్త పేరుతో మళ్ళీ ధృవీకరించాలి",
            'formp2':"ఇమెయిల్ నవీకరణలో ఇమెయిల్ క్రొత్త ఇమెయిల్ ఐడితో మళ్ళీ ధృవీకరించాలి",
            'formp3':"మొబైల్ నవీకరణలో మొబైల్ కొత్త మొబైల్‌తో మళ్లీ ధృవీకరించాలి",
        },
        {
            'id':'3923923894',
            'title':"పాస్వర్డ్ మార్చండి",
            'tagName': 'tab2', 
            'changepasswordbtn':"పాస్వర్డ్ మార్చండి",
            'editprofilebtn':"ప్రొఫైల్‌ను సవరించండి",
            'oldpassword':"పాత పాస్‌వర్డ్",
            'newpassword':"కొత్త పాస్వర్డ్",
            'confirmpassword':"పాస్వర్డ్ను నిర్ధారించండి",
            'updatepasswordbtn':" పాస్వర్డ్ను నవీకరించండి",
            'cancelbtn':"రద్దు చేయండి",
            'formname':"పూర్తి పేరు",
            'formusername':"వినియోగదారు పేరు",
            'formemail':"ఇమెయిల్",
            'formphone':"ఫోను నంబరు",
            'formgender':"లింగం",
            'formmale':"పురుషుడు",
            'formfemale':"స్త్రీ",
            'formdob':"పుట్టిన తేది",
            'updateprofilebtn':"ప్రొఫైల్‌ను నవీకరించండి",
        }, 
        {
            'id':'3923923894',
            'title':"Kyc ధృవీకరణ",
            'tagName': 'tab3',  
        },
        {
            'id':'3923923894',
            'title':"లాగిన్ చరిత్ర",
            'tagName': 'tab4', 
        }, 
        
    ],
    hi: [
        {
            'id':'3923923892',
            'title':"उपयोगकर्ता प्रोफ़ाइल",
            'tagName': 'tab1', 
            'userprofilediv': [
                {
                    'id':"name",
                    'display_name':"पूरा नाम"
                },
                {
                    'id':"username",
                    'display_name':"उपयोगकर्ता नाम"
                },
                {
                    'id':"email",
                    'display_name':"ईमेल",
                },
                {
                    'id':"mobile",
                    'display_name':"मोबाइल",
                }, 
                {
                    'id':"date_of_birth",
                    'display_name':"जन्म की तारीख",
                },
                // {
                //     'id':"commission_type",
                //     'display_name':"कमीशन का प्रकार",
                // },
                {
                    'id':"address",
                    'display_name':"पता",
                }, 
                {
                    'id':"state",
                    'display_name':"राज्य",
                },
                {
                    'id':"locality",
                    'display_name':"शहर",
                },
                {
                    'id':"pincode",
                    'display_name':"पिन कोड",
                },
            ],
            'editprofilebtn':"प्रोफ़ाइल संपादित करें", 
            'cancelbtn':"रद्द करना", 
            'formname':"पूरा नाम",
            'formusername':"उपयोगकर्ता नाम",
            'formemail':"ईमेल", 
            'formphone':"फ़ोन नंबर",  
            'formdob':"जन्म की तारीख",
            'updateprofilebtn':"प्रोफ़ाइल अपडेट करें", 
            'formfirstname':"पहला नाम",
            'formlastname':"उपनाम",
            'formcountry':"देश",
            'formstate':"राज्य",
            'formpincode':"पिन कोड", 
            'formaddress':"पता",
            'formcity':"शहर",    
            'formp1':"नाम अपडेट पर केवाईसी को नए नाम के साथ फिर से सत्यापित करने की आवश्यकता है",
            'formp2':"ईमेल अपडेट पर ईमेल को नई ईमेल आईडी के साथ फिर से सत्यापित करने की आवश्यकता है",
            'formp3':"मोबाइल अपडेट पर मोबाइल को नए मोबाइल के साथ फिर से सत्यापित करने की आवश्यकता है",
        },
        {
            'id':'3923923893',
            'title':"पासवर्ड बदलें",
            'tagName': 'tab2',
            'changepasswordbtn':"पासवर्ड बदलें", 
            'oldpassword':"पुराना पासवर्ड",
            'newpassword':"नया पासवर्ड",
            'confirmpassword':"पासवर्ड की पुष्टि कीजिये",
            'updatepasswordbtn':"पासवर्ड अपडेट करें",
            'cancelbtn':"रद्द करना", 
        }, 
        {
            'id':'3923923894',
            'title':"Kyc सत्यापन",
            'tagName': 'tab3', 
        },
        {
            'id':'3923923894',
            'title':"लॉगिन इतिहास",
            'tagName': 'tab4', 
        }, 
    ]
}
export default accountsDb;

 

const secsignupboxDb = {
    en: [
        {
            'id'    : '5725a680b3249760ea22de51',
            'subtitle'    : 'Create An new Account',
            'title'    : 'Sign Up',
            'email'    : 'Enter Your Email', 
            'password'    : 'Password', 
            'confirmpassword'    : 'Confirm Password', 
            'username'    : 'Enter Your Username', 
            'name'    : 'Enter Your Name', 
            'mobilen'    : 'Mobile Number', 
            'sbmitbtn'    : 'Sign Up',  
            'text_a'    : 'OTP', 
            'forgotpasswordhead'    : 'Forgot Password', 
            'forgotpasswordbtn'    : 'Forgot Password',  
            'aggreetnc':'By clicking Sign Up, you agree to our Terms and Conditions. You may receive SMS notifications from us and can opt out at any time.',
            'aggreetncs':'Terms and Conditions', 
        }, 
    ],
    tm: [
        {
            'id'    : '5725a680b3249760ea22de51', 
            'subtitle': 'புதிய கணக்கை உருவாக்கு',
            'title': 'பதிவுபெறு',
            'email': 'உங்கள் மின்னஞ்சலை உள்ளிடுக',
            'password': 'கடவுச்சொல்',
            'confirmpassword': 'கடவுச்சொல்லை உறுதிப்படுத்தவும்',
            'username': 'உங்கள் பயனர்பெயரை உள்ளிடுக',
            'name'    : 'உங்கள் பெயரை உள்ளிடவும்', 
            'mobilen': 'மொபைல் எண்',
            'sbmitbtn': 'பதிவுபெறு',
            'text_a': 'OTP',
            'forgotpasswordhead': 'கடவுச்சொல்லை மறந்துவிட்டீர்களா',
            'forgotpasswordbtn': 'கடவுச்சொல்லை மறந்துவிட்டீர்களா',
            'aggreetnc': 'பதிவுபெறுவதைக் கிளிக் செய்வதன் மூலம், எங்கள் விதிமுறைகளையும் நிபந்தனைகளையும் ஒப்புக்கொள்கிறீர்கள். எங்களிடமிருந்து நீங்கள் எஸ்எம்எஸ் அறிவிப்புகளைப் பெறலாம், எந்த நேரத்திலும் விலகலாம். ',
            'aggreetncs': 'விதிமுறைகள் மற்றும் நிபந்தனைகள்',
        }, 
    ],
    kn: [
        {
            'id'    : '5725a680b3249760ea22de51',  
            'subtitle': 'ಹೊಸ ಖಾತೆಯನ್ನು ರಚಿಸಿ',
            'title': 'ಸೈನ್ ಅಪ್',
            'email': 'ನಿಮ್ಮ ಇಮೇಲ್ ನಮೂದಿಸಿ',
            'password': 'ಪಾಸ್‌ವರ್ಡ್',
            'confirmpassword': 'ಪಾಸ್‌ವರ್ಡ್ ದೃ irm ೀಕರಿಸಿ',
            'username': 'ನಿಮ್ಮ ಬಳಕೆದಾರ ಹೆಸರನ್ನು ನಮೂದಿಸಿ',
            'name'    : 'ನಿಮ್ಮ ಹೆಸರನ್ನು ನಮೂದಿಸಿ', 
            'mobilen': 'ಮೊಬೈಲ್ ಸಂಖ್ಯೆ',
            'sbmitbtn': 'ಸೈನ್ ಅಪ್',
            'text_a': 'OTP',
            'forgotpasswordhead': 'ಪಾಸ್‌ವರ್ಡ್ ಮರೆತಿರುವಿರಾ',
            'forgotpasswordbtn': 'ಪಾಸ್‌ವರ್ಡ್ ಮರೆತಿರುವಿರಾ',
            'aggreetnc': 'ಸೈನ್ ಅಪ್ ಕ್ಲಿಕ್ ಮಾಡುವ ಮೂಲಕ, ನೀವು ನಮ್ಮ ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳನ್ನು ಒಪ್ಪುತ್ತೀರಿ. ನೀವು ನಮ್ಮಿಂದ SMS ಅಧಿಸೂಚನೆಗಳನ್ನು ಸ್ವೀಕರಿಸಬಹುದು ಮತ್ತು ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ಹೊರಗುಳಿಯಬಹುದು. ',
            'aggreetncs': 'ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳು',
        }, 
    ],
    ne: [
        {
            'id'    : '5725a680b3249760ea22de51', 
            'subtitle': 'नयाँ खाता सिर्जना गर्नुहोस्',
            'title': 'साइन अप गर्नुहोस्',
            'email': 'तपाईंको ईमेल प्रविष्ट गर्नुहोस्',
            'password': 'पासवर्ड',
            'confirmpassword': 'पासवर्ड कन्फर्म गर्नुहोस्',
            'username': 'तपाईंको प्रयोगकर्तानाम प्रविष्ट गर्नुहोस्',
            'name'    : 'तपाइँको नाम प्रविष्ट गर्नुहोस्', 
            'mobilen': 'मोबाइल नम्बर',
            'sbmitbtn': 'साइन अप गर्नुहोस्',
            'text_a': 'OTP',
            'forgotpasswordhead': 'पासवर्ड बिर्सनुभयो',
            'forgotpasswordbtn': 'पासवर्ड बिर्सनुभयो',
            'aggreetnc': 'साइन अप क्लिक गरेर, तपाईं हाम्रो सर्त र नियमहरूसँग सहमत हुनुहुन्छ। तपाईं हामीबाट एसएमएस सूचनाहरू प्राप्त गर्न सक्नुहुनेछ र कुनै पनि समयमा अप्ट आउट गर्न सक्नुहुनेछ। ',
            'aggreetncs': 'नियम र सर्तहरू',
        }, 
    ],
    tl: [
        {
            'id'    : '5725a680b3249760ea22de51',
            'subtitle'    : 'క్రొత్త ఖాతాను సృష్టించండి',
            'title'    : 'చేరడం',
            'email'    : 'మీ ఇమెయిల్‌ను నమోదు చేయండి', 
            'password'    : 'పాస్వర్డ్', 
            'confirmpassword'    : 'పాస్వర్డ్ను నిర్ధారించండి', 
            'username'    : 'మీ వినియోగదారు పేరును నమోదు చేయండి', 
            'name'    : 'మీ పేరు రాయుము, మీ పేరు రాయండి', 
            'mobilen'    : 'మొబైల్ సంఖ్య', 
            'sbmitbtn'    : 'చేరడం',  
            'text_a'    : 'OTP', 
            'forgotpasswordhead'    : 'పాస్వర్డ్ మర్చిపోయారా', 
            'forgotpasswordbtn'    : 'పాస్వర్డ్ మర్చిపోయారా',
            // 'aggreetnc':'నేను అంగీకరిస్తున్నాను',
            'aggreetnc':'సైన్ అప్ క్లిక్ చేయడం ద్వారా, మీరు మా నిబంధనలు మరియు షరతులను అంగీకరిస్తున్నారు. మీరు మా నుండి SMS నోటిఫికేషన్లను స్వీకరించవచ్చు మరియు ఎప్పుడైనా నిలిపివేయవచ్చు.',
            'aggreetncs':'నిబంధనలు మరియు షరతులను',   
        }, 
    ],
    hi: [
        {
            'id'    : '5725a680b3249760ea22de51',
            'subtitle'    : 'एक नया खाता बनाएँ',
            'title'    : 'साइन अप करें',
            'email'    : 'अपना ईमेल दर्ज करें', 
            'password'    : 'पासवर्ड', 
            'confirmpassword'    : 'पासवर्ड की पुष्टि कीजिये', 
            'username'    : 'अपने उपयोगकर्ता नाम दर्ज करें', 
            'name'    : 'अपना नाम दर्ज करें', 
            'mobilen'    : 'मोबाइल नंबर', 
            'sbmitbtn'    : 'साइन अप करें',  
            'text_a'    : 'औटीपि ',   
            'forgotpasswordhead'    : 'पासवर्ड भूल गए', 
            'forgotpasswordbtn'    : 'पासवर्ड भूल गए', 
            // 'aggreetnc':'मैं इसके लिए सहमत हूँ',
            'aggreetnc':'साइन अप पर क्लिक करके, आप हमारे नियमों और शर्तों से सहमत होते हैं। आप हमसे एसएमएस सूचनाएं प्राप्त कर सकते हैं और किसी भी समय चुन सकते हैं।',
            'aggreetncs':'नियमों और शर्तों',  
        },  
    ],
    

};

export default secsignupboxDb;
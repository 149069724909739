import React from 'react';
import {CasinoAuthorization, CasinoLayout, CasinoTheme} from './@casino';
import Provider from 'react-redux/es/components/Provider';
import {Router} from 'react-router-dom';
import history from './@history';
import {Auth} from './auth';
import store from './store';
import AppContext from './AppContext';
import Themecolor from './Themecolor'; 
import {Redirect} from 'react-router-dom';
import {CasinoUtils} from './@casino/index';
import routes from './casino-config/routesConfig';

const App = (props) => {
    return (
        <AppContext.Provider
            value={{
                routes
            }}
        >
                <Provider store={store}>
                    <Auth>
                        <Router history={history}>
                            <CasinoAuthorization>
                                <CasinoTheme>
                                    <Themecolor/>
                                    <CasinoLayout themeInfo={props.themeInfo}/>
                                    
                                </CasinoTheme>
                            </CasinoAuthorization>
                        </Router>
                    </Auth>
                </Provider>
        </AppContext.Provider>
    );
}; 
export default App;
//import React, {useEffect} from "react";
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components 
import GridItem from "../elements/Grid/GridItem.js";  
import * as Gurl from "../../../global/Global.js"
import axios from 'axios';
import Galleryslider from "../sections/galleryslider.js";
 import GridContainer from "../elements/Grid/GridContainer.js";
import styles from "../assets/jss/components/carouselStyle.js";
// const token = "kdkddjdj2929j38jv88fpwad83pakwdi3"; 
import Offers from "./offers";
import SectionLoginbox from "../sections/SectionLoginBox";
import SectionSignupbox from "../sections/SectionSignupBox";

export default function Mediacontent(props) {
  
    const [mediaData, setMediaData] = React.useState("NOVALUE");
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const [offersData, setOffersData] = React.useState(true);
    async function getMedia( data) {
        
            try {
                const request = axios.post(Gurl.MEDIA_CONTENT_API, { media_id: props.mediaIds, headers:{"Accept": "application/json"}});
                request.catch((error) => {
                    console.log(error);
                    setMediaData("NOVALUE");
                }) 
            //    alert(JSON.stringify(request));
                request.then((response) => {   
                 //  alert(JSON.stringify(response));
                 var myOutPut = "";
                    if( response.data.data != null && response.data.status === true && response.data.data.path != null){
                        myOutPut = (
                            <GridItem xs="3" >
                            <img src={response.data.data.path} className={classes.fullimgwidth} alt={""} /> 
                            </GridItem>
                        );
                        setMediaData(myOutPut);
                    }else if(Array.isArray(response.data.data) && response.data.data != null && response.data.status === true ){
                        myOutPut = (
                            <GridItem xs="12" >
                                 {(() => { 
                                    if(offersData === true){
                                        return (
                                            <GridContainer>
                                                <GridItem xs={9} style={{padding:"0px"}}>
                                                    <Galleryslider response={response.data.data} /> 
                                                </GridItem> 
                                                <GridItem xs={3} style={{padding:"0px"}}>
                                                    <Offers {...props}  setOffersData={setOffersData}  themeColors={props.themeColors} SectionLoginbox={SectionLoginbox} SectionSignupbox={SectionSignupbox} />
                                                    <SectionLoginbox 
                                                        SignupBox={SectionSignupbox} 
                                                        {...props} />  
                                                    <SectionSignupbox 
                                                        LoginBox={SectionLoginbox}
                                                        {...props} /> 
                                                </GridItem> 
                                            </GridContainer>
                                        )
                                    }else{ 
                                        return (
                                            <GridContainer>
                                                <GridItem xs={12} style={{padding:"0px"}}>
                                                    <Galleryslider response={response.data.data} /> 
                                                </GridItem>  
                                            </GridContainer>
                                        )
                                    }
                                })()} 
                            

                                
                            </GridItem> 
                          //  response.data.data.map((jsondata) => ( 
                         //   <div>
                          //      <img src={jsondata.path} className={classes.fullimgwidth+ " slick-image"}  alt={""} /> 
                         //      </div>
                          //  ))
                            
                        );
                        setMediaData(myOutPut);
                    }else{
                        
                        setMediaData("NO RECORD FOUND");
                    }
                })
            } catch (error) {
                console.log("MYIN:"+error);
                
                 
            }
    } 

    if((mediaData === "NOVALUE" || offersData === "NO") && props.mediaIds != null){
        setOffersData("DONE");
        setMediaData("Loading...");   
        getMedia({media_id: props.mediaIds});
         //alert(JSON.stringify(rmedia));
         
    }
 
    return (
         
        mediaData 
         
                  
                 
     );
     
}
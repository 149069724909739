import React, { useRef, useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";


import styles from "../assets/jss/components/contact.js";
import Button from "../elements/CustomButtons/Button";

 
import classNames from "classnames"; 
// import leftimgconatct from "../assets/img/contact-left.png";

import Formsy from 'formsy-react'; 
import {TextFieldFormsy} from '../../../@casino';
import seccontactDb from "../data/seccontact.js";
import { useTranslation } from 'react-i18next' 
const useStyles = makeStyles(styles);

export default function SectionContact(props) {

    const [isFormValid, setIsFormValid] = useState(false);
    const formRef = useRef(null); 
 
    function disableButton()
    {
        setIsFormValid(false);
        document.body
        .getElementsByClassName("signupbtn")[0]
          .setAttribute("style", "opacity:0.5;");
    }
    function enableButton()
    {
        setIsFormValid(true);
        document.body
        .getElementsByClassName("signupbtn")[0]
          .setAttribute("style", "opacity:1;");
    }
 
    function handleSubmit(model)
    {
         
    }

    if(props.themeColors != null){
        styles.singlecontact.background = props.themeColors.bgColors.secondary_dark_bgcolor
        styles.subtitle.color = props.themeColors.bgColors.text_secondary_color
        styles.text.color = props.themeColors.bgColors.text_secondary_dark_color
        styles.contactbox.background = props.themeColors.bgColors.primary_bgcolor 
        styles.mybtn1.backgroundImage = props.themeColors.bgColors.main_color
        styles.mybtn1.color = props.themeColors.bgColors.text_primary_in_color 
        styles.title.color = props.themeColors.bgColors.text_primary_color
        styles.contactbox.background = props.themeColors.bgColors.primary_bgcolor
        styles.contacttitle.color = props.themeColors.bgColors.text_primary_color

        styles.title.fontFamily = props.themeColors.bgColors.base_font 
        
    }
    const { t } = useTranslation()
  const classes = useStyles();
  return (
      <div className={classNames(classes.singlecontact)}>
         
          <div className={classNames(classes.container)}> 
              
      <GridContainer>
  
          <GridItem xs={12} sm={12} md={6}>
          <Formsy
                onValidSubmit={handleSubmit}
                onValid={enableButton}
                onInvalid={disableButton}
                ref={formRef}
                className={classes.form + " flex flex-col justify-center w-full"}
            >
              <div className={classes.contactformwrapper}>
              {seccontactDb.categories.map((jsondata) => (
                  <div className={classes.contactbox}>
                      
                      <h4 className={classes.contacttitle}>
                       Inquiry Form
                      </h4>
                                <TextFieldFormsy 
                                    label={t('name')}
                                    id="name"
                                    name="name"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validations={{
                                        minLength: 2
                                    }} 
                                    className={classes.contactiinpu}
                                    validationErrors={{
                                        minLength: 'Min character length is 2'
                                    }} 
                                    variant="outlined"
                                    required
                                />  

                                <TextFieldFormsy 
                                    label={t('email')}
                                    id="email"
                                    name="email"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validations={{
                                        minLength: 2
                                    }} 
                                    className={classes.contactiinpu}
                                    validationErrors={{
                                        minLength: 'Min character length is 2'
                                    }} 
                                    variant="outlined"
                                    required
                                />   
                                  <select className={classes.contactiinpu}>
                                  {jsondata.submenus.map((jsondata) => (
                                      <option>{jsondata.options}</option> 
                                      ))}
                                  </select>
                                  <textarea placeholder={t('textarea')} className={classes.contactiinpu}></textarea> 
                                  <input type="checkbox" className={classes.contactchechbx}/> {t('checkb')} 
                                  <Button
                                      href="#"
                                      color="transparent" 
                                      className={classes.mybtn1 +" signupbtn"}
                                      disabled={!isFormValid}
                                  >
                                      {t('submitbtn')} 
                                  </Button> 
                       
                  </div>))}
              </div>
            </Formsy>
          </GridItem>
      </GridContainer>
      </div>
      </div>
  );
}

import React from 'react';
import {Redirect} from 'react-router-dom';
import {CasinoUtils} from '../@casino/index';
//import {pagesConfigs} from '../theme/'+(sessionStorage.getItem("currentTheme") != null) ? sessionStorage.getItem("currentTheme") : 'casinodestination'+'/pagesConfigs';


function parseJwt(token) {
    if (!token) { return; }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}
function getCurrentTheme(){
  if(window.localStorage.getItem('jwt_theme_token') != null){
      let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
      if(themeData.website_theme != null){
        return themeData.website_theme;
      }
  }
}
const themeName = getCurrentTheme();
var routeConfigs = [];
if(themeName != null && themeName.length > 0){
    try {
        var {pagesConfigs} = require("../theme/"+ ((themeName != null) ? themeName : 'casinodestination') +"/pagesConfigs");
        routeConfigs = [
            ...pagesConfigs
        ];
    }catch(err){
        var {pagesConfigs} = require("../theme/"+ 'casinodestination' +"/pagesConfigs");
        routeConfigs = [
            ...pagesConfigs
        ];
    }
    
}else{
    var {pagesConfigs} = require("../theme/"+ 'smdesign1' +"/pagesConfigs");
    routeConfigs = [
        ...pagesConfigs
    ];
}




const routes = [
    //if you want to make whole app auth protected by default change defaultAuth for example:
    // ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
    // The individual route configs which has auth option won't be overridden.
    ...CasinoUtils.generateRoutesFromConfigs(routeConfigs, null),
    {
        path     : '/',
        exact    : true,
        component: () => <Redirect to="/casino"/>
    },
    {
        component: () => <Redirect to="/errors/error-404"/>
    }
];

export default routes;

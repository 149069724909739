//import React, {useEffect, useRef, useState} from "react";
import React  from "react";
// @material-ui/core components

import 'react-accessible-accordion/dist/fancy-example.css';

import { makeStyles } from "@material-ui/core/styles";


// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Radio from "@material-ui/core/Radio";

// import {IconButton, MenuItem} from "@material-ui/core"; 
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";
import CustomTabsFirst from "../elements/CustomTabs/CustomTabsFirst.js";
// import CustomTabsSecond from "../elements/CustomTabs/CustomTabsSecond.js";
import balanceDb from "../data/balance.js";
 


import styles from "../assets/jss/components/tabsAccountstyle.js";
// import Button from "../elements/CustomButtons/Button";
// import FiberManualRecord from "@material-ui/icons/FiberManualRecord"; 
// import { useTranslation } from 'react-i18next'
// import Formsy from 'formsy-react'; 
// import {TextFieldFormsy, SelectFormsy} from '../../../@casino';
// import * as authActions from '../../../auth/casino/actions';    
// import axios from 'axios';
// import {useDispatch, useSelector} from 'react-redux';
// import * as globalurl from '../../../global/Global.js'; 
// import CustomInput from "../elements/CustomInput/CustomInput";
// import MobileOtpbox from "../sections/SectionOtpBox"; 
// import VerifyMobile from "../sections/VerifyMobile";
// import VerifyEmail from "../sections/VerifyEmail";
// import KycModule from "./kycModule";
import Deposit from "./deposit";
import Withdraw from "./withdraw";


// import promotionDb from "../data/promotion";
// import Slide from "@material-ui/core/Slide";
// import ListItem from "@material-ui/core/ListItem";
// import List from "@material-ui/core/List";
// import localDb from "../data/bankaccount";
import Statement from "./statement.js";
// const token = "kdkddjdj2929j38jv88fpwad83pakwdi3"; 
const useStyles = makeStyles(styles);
var selectedTabName = "NOVALUE";
// let isManual = false;
export default function Balance(props) {
    const {...rest} = props;
 
    const classes = useStyles();  

    styles.depositbtn.background = props.themeColors.bgColors.main_color;
     
const [selectedTab, setSelectedTab] = React.useState("NOVALUE");
    var [currentPosition, setCurrentPosition] = React.useState(0);

    var tabIndex = 0;
    
    if((selectedTab === "NOVALUE" || selectedTabName !== props.selectedSubTab) && props.manualCheck === false){
        balanceDb[sessionStorage.getItem('lang')].forEach((jsondata) => 
         {
            if(jsondata.tagName === props.selectedSubTab){
                 selectedTabName = props.selectedSubTab;
                 setSelectedTab(tabIndex);
             }
             tabIndex++;
         }
     );
    }
    
    var clickedOnTabs = function(event){
        props.setTabMenu(true);
        selectedTabName = "change";
        setSelectedTab(event);
        setCurrentPosition(event);
        currentPosition = event;
   }
    
    return (   
                <div className={classes.firstwrap}>
                    <div className={classes.firstbox}>
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomTabsFirst
                        themeColors={props.themeColors}
                        plainTabs
                        border="round"
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        currentPosition={currentPosition}
                        clickEvent={clickedOnTabs}
                        tabs={balanceDb[sessionStorage.getItem('lang')].map((jsondata) => (
                            {
                            tabName: (<span>{jsondata.title}</span>),
                            tabContent: (
                                <div>
                                {(() =>  {
                                    if (jsondata.tagName === "tab1") {
        
                                        return (
                                            <div className={classes.secondwrap}>
                                            <div className={classes.secondbox}>  
                                            
                                            <Deposit themeColors={props.themeColors} {...rest} />

                                          
                                            </div>
                                            </div>
                                        
                                            )
                                    }else if (jsondata.tagName === "tab2"){
                                        return (
                                            <div className={classes.secondwrap}>
                                            <div className={classes.secondbox}> 

                                            <Withdraw themeColors={props.themeColors} {...rest} />

                                             </div>
                                            </div>
                                        )
        
                                    }else if (jsondata.tagName === "tab3"){
                                        return (
                                            <div className={classes.secondwrap}>
                                            <div className={classes.secondbox}>
                                            <div className={classes.historybox}>

                                                <Statement themeColors={props.themeColors} {...rest} />

                                            </div></div>
                                            </div>
                                        
                                        )
        
                                    } 

                                })()}
                                    </div>
        
                            )
                            }
        
                        ))}
                        />
                    </GridItem>
                    </GridContainer>
                    </div>
                </div>
            
             
    );
  }
  
import React, {useEffect, useRef, useState}  from "react";
import {makeStyles} from "@material-ui/core/styles"; 
import styles from "../assets/jss/views/landingPage.js"; 
import GridItem from "../elements/Grid/GridItem";
import GridContainer from "../elements/Grid/GridContainer";
import logo from "../assets/img/logo.png";
import screenone from "../assets/img/screenone.jpg";
import screentwo from "../assets/img/screentwo.jpg";
import screenthree from "../assets/img/screenthree.jpg";
import screenfour from "../assets/img/screenfour.png"; 

// import girl from "../assets/img/girl.png"; 
import Formsy from 'formsy-react';  
import {TextFieldFormsy, SelectFormsy} from '../../../@casino';
import history from '../../../@history';

import {Button, MenuItem} from '@material-ui/core';

import * as authActions from '../../../auth/casino/actions';
import {useDispatch, useSelector} from 'react-redux';
import * as Gurl from "../../../global/Global.js"
import axios from 'axios';

import ReCAPTCHA from "react-google-recaptcha";
import Zoom from '@material-ui/core/Zoom';
// const token = "kdkddjdj2929j38jv88fpwad83pakwdi3"; 

const useStyles = makeStyles(styles);
// const recaptchaRef = React.createRef();

// const validEmailRegex = 
//   RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

export default function Home(props) { 
    const classes = useStyles(); 
    let recaptchaInstance, recaptchaInstanceOtp, recaptchaInstanceNew;
    const dispatch = useDispatch();

    const otpCode = "NOVALUE";
    const [recaptchaOtpCode, setRecaptchaOtpCode] = React.useState("NOVALUE");
    const [userId, setUserId] = React.useState(0);
      const wlOtp = useSelector(({auth}) => auth.wlOtp);
 
   /*sign up  start*/
    const [gameType, setGameType] = React.useState("NOVALUE");
    const [userConfig, setUserConfig] = React.useState("NOVALUE");
    const [classAdd, setClassAdd] = useState(false);
    const [recaptchaCode, setRecaptchaCode] = React.useState("NOVALUE");
    const [isFormValid, setIsFormValid] = useState(false); 
    const [isError, setIsError] = useState(false);  
    const formRef = useRef(null);
    const wlregister = useSelector(({auth}) => auth.wlregister);
    const resendotp = useSelector(({auth}) => auth.resendotp);
    
    /*sign up  end*/
 
    const forgotformRef = useRef(null);
    const otpformRef = useRef(null);
  
    const resetRecaptcha = () => {
      recaptchaInstance.reset();  
    };
    const resetRecaptchaOtp = () => {
      recaptchaInstanceOtp.reset();  
    };
    
    useEffect(() => {
        if ( wlOtp.error && (wlOtp.error.otp ||  wlOtp.error.rid ||  wlOtp.error.user_id || wlOtp.error.recaptcha || wlOtp.error.message ) ){
            if(wlOtp.error.message != null){ 
                resetRecaptchaOtp();
                document.body
                    .getElementsByClassName("message")[0]
                    .innerHTML=wlOtp.error.message 
                setClassAdd(classes.msg = " errormsg" );
            }else{
                resetRecaptchaOtp();
                otpformRef.current.updateInputsWithError({
                    ...wlOtp.error
                });
            }
        }else{
            if(wlOtp.data != null && wlOtp.data.message != null){
                setTimeout(function() {
                    history.push({
                        pathname:'/profilesetup'
                    })
                }, 3000);

                document.body
                .getElementsByClassName("message_otp")[0].innerHTML =wlOtp.data.message
                setClassAdd(classes.msg = " successmsg" );
            }
        }
    }, [wlOtp.error, classes.msg, wlOtp.data]);

    

    function handleSubmitotp(model){
        model = {"recaptcha": recaptchaOtpCode, ...model}
        dispatch(authActions.wlsubmitOtp(model,props));
        document.body
            .getElementsByClassName("message")[0]
            .innerHTML=""; 
    }
    
    var verifyOtpCallback = function (response) {
        setRecaptchaOtpCode(response);
    };
        

    
    useEffect(() => {
        if ( resendotp.error && (resendotp.error.message || resendotp.error.user_id)){
            setIsError(false) 
            if(resendotp.error.message != null){ 
                document.body
                    .getElementsByClassName("messageresend")[0] 
                    .innerHTML=resendotp.error.message 
            }else{ 
                formRef.current.updateInputsWithError({
                     
                });
            }
            disableButton(); 
        }else{
            if(resendotp.success != null && resendotp.success === true){
                if(resendotp.data != null && resendotp.data.data != null){
                    if(resendotp.data.data > 0){
                        setUserId(resendotp.data.data);
                    }
                }
               
            }
            if(resendotp.status != null && resendotp.status === 1){
                
            }
        } 
    }, [resendotp.error, resendotp.data, resendotp.status, setIsError, resendotp.success]);

    function handleResendOtp( ){ 
        dispatch(authActions.resendOtp(userId));
        document.body
            .getElementsByClassName("message")[0]
            .innerHTML=" " 
    }

    let setRequestTime = function(mydata){
        if(mydata.status != null && mydata.data != null && mydata.status === 1){
            if(mydata.data != null && mydata.data > 0){
                setUserId(mydata.data);
                document.body 
                    .getElementsByClassName("otpwrap")[0] 
                    .setAttribute("style", "display:block !important;"); 
                
                document.body 
                    .getElementsByClassName("signupwrap")[0] 
                    .setAttribute("style", "display:none !important;"); 
            }
        }
    }

     
    function disableButton(){
        setIsFormValid(false);
        document.body
            .getElementsByClassName("signupbtn")[0]
            .setAttribute("style", "opacity:0.5;");
    }

    function enableButton(){
        setIsFormValid(true);
        document.body
            .getElementsByClassName("signupbtn")[0]
            .setAttribute("style", "opacity:1;");
    }

    function handleSubmit(model){ 
        model = {"recaptcha": recaptchaCode, "id": userId,...model}
        dispatch(authActions.submitWlRegister(model, setRequestTime));
        document.body
            .getElementsByClassName("message")[0]
            .innerHTML=" " 
    }
    
    var verifyCallback = function (response) {
        setRecaptchaCode(response);
    };
           
    function getgamedata(){
        const request = axios.post(Gurl.WLGAME_TYPE, JSON.stringify({
            token: window.localStorage.getItem('jwt_access_token')
        }),{header:{"Content-Type": "application/x-www-form-urlencoded"}});
   
        request.catch((error) => {
            setGameType("NOVALUE");
        }); 

        request.then((response) => { 
            if(response.data.data != null){ 
                var myOutPut = (
                    response.data.data.map((jsondata) => (
                        <MenuItem value={jsondata.id}>{jsondata.type_name}</MenuItem> 
                    ))
                );
                setGameType(myOutPut);
            };
        });  
    }
    
    if(gameType === "NOVALUE"){
        setGameType("Processing");
        getgamedata();
    }

    function getuserconfig(){
        const request = axios.post(Gurl.WLUSER_CONFIG, JSON.stringify({
            token: window.localStorage.getItem('jwt_access_token')
        }),{header:{"Content-Type": "application/x-www-form-urlencoded"}});
   
        request.catch((error) => {
            setUserConfig("NOVALUE");
        }); 

        request.then((response) => { 
            if(response.data.data != null){ 
                var myConfig = response.data.data;
                setUserConfig(myConfig[0]);
            };
        });  
    }
    if(gameType === "NOVALUE"){
        getuserconfig("Processing");
        getgamedata();
    } 


    
    
   

    /*sign up  end*/  
    return (
        <div> 
        <div className={classes.fullpagelogin + " smdesigntwo " + classes.ragisterrpage}>
            <img className={classes.girlimg} alt="" style={{marginTop:"10px"}} src={logo}/>
             <div className={classes.otpwrap+ " otpwrap"} style={{display:"block"}}>
               <Formsy 
                    onValidSubmit={handleSubmitotp} 
                    ref={otpformRef}
                    autoComplete={"off"}
                    className={classes.form + " flex flex-col justify-center w-full"}
                >
                   
                  <h4>OTP</h4>
                  <GridItem xs={12}><div className={classes.sucessmsg + " messageresend"} id="message"></div></GridItem>
                  
                  <div className={classAdd + " message_otp"} id="message_otp">{wlOtp.error.message}</div>
                  <GridContainer>
                  <GridItem xs={12}>
                  <TextFieldFormsy
                        formControlProps={{
                            fullWidth: true
                        }}
                        type="number"
                        id="otp"
                        name="otp"
                        label="Enter OTP"
                        value={otpCode}
                        validations={{
                            isLength: 6, 
                        }}
                        validationErrors={{
                            isLength: 'OTP length is 6', 
                        }}
                        
                        variant="outlined"
                        required
                    />
                    </GridItem>
                    </GridContainer>
                     
                    <GridContainer>
                  <GridItem xs={12}>
                    <ReCAPTCHA
                        ref={e => recaptchaInstanceOtp = e}
                        onChange={verifyOtpCallback}
                        className={classes.receptadiv}
                        sitekey={Gurl.reCaptchaKey}
                        />
                    </GridItem>
                    </GridContainer>
                     
                        
                        
                    <Button simple color="primary" style={{
                        float: 'right',
                        marginTop: '-100px',
                        fontSize: '12px',
                        color: '#863304',
                        background: 'radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #d29817 30%, #bf8315 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFF 0%, #FFFFAC 8%, #d2a732 25%, #5d4a1f 62.5%, #f5c761 100%)',
                        borderRadius: '20px',
                        padding: '6px 15px 4px',
                        
                    }} className="resendbtn"  onClick={handleResendOtp} type="submit" size="lg" >
                        Resend   
                    </Button>


                   <Button simple color="primary" className={classes.signinmodalbtn}  type="submit" size="lg" >
                        Continue
                    </Button>
                    <Button simple color="primary" className={classes.otpbtn} type="button" href="/wllogin" size="lg" >
                      Login
                    </Button> 
                </Formsy> 
            </div>
            
                        
            <div className={classes.clerfix}></div>
        </div>
        </div>
    );
}


const tabnotificationDb = {
    en: [
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-envelope-open',  
            'noti_name': 'Internal Messages',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-bell',  
            'noti_name': 'Push Notifications',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-phone',  
            'noti_name': 'Phone Call ',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-envelope',  
            'noti_name': 'Email ',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-comments',  
            'noti_name': 'SMS',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-gamepad',  
            'noti_name': 'Bets',  
            'noti_disable':"disablebox",   
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-trophy',  
            'noti_name': 'Winners',   
            'noti_disable':"disablebox",  
        },
    ],
    tl: [
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-envelope-open',  
            'noti_name': 'అంతర్గత సందేశాలు',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-bell',  
            'noti_name': 'పుష్ నోటిఫికేషన్‌లు',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-phone',  
            'noti_name': 'ఫోన్ కాల్',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-envelope',  
            'noti_name': 'ఇమెయిల్',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-comments',  
            'noti_name': 'SMS',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-gamepad',  
            'noti_name': 'పందెం',  
            'noti_disable':"disablebox",   
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-trophy',  
            'noti_name': 'విజేతలు',   
            'noti_disable':"disablebox",  
        },
    ],
    hi: [
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-envelope-open',  
            'noti_name': 'आंतरिक संदेश',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-bell',  
            'noti_name': 'सूचनाएं भेजना',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-phone',  
            'noti_name': 'फ़ोन कॉल ',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-envelope',  
            'noti_name': 'ईमेल',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-comments',  
            'noti_name': 'एसएमएस',    
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-gamepad',  
            'noti_name': 'दांव',  
            'noti_disable':"disablebox",  
        },
        { 
            'id': '2342424424',
            'noti_icon': 'fa fa-trophy',  
            'noti_name': 'विजेताओं',
            'noti_disable':"disablebox",    
        },
    ]
}
export default tabnotificationDb;

import {
  container,
  defaultFont,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor,
  transition,
  boxShadow,
  drawerWidth
} from "../../casino-kit-react";


// import Background from '../../img/nov.png';
// import Background2 from '../../img/backi.jpg';

const headerStyle = {
  about:{

  },
  mobilemenu:{
    height: "15px",
    margin: "0px 7px 0 10px",
  },
  headermobileonly:{
    display:"none",
    "& header":{
      margin:"0"
    },
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      display:"block",
    }  
  },
  topheaderrow:{ 
    background:"#131212",
    height: "40px",
    padding:"0px 8px 0px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
     "&.topheaderrow":{
       // display:"none",
     }    
   },
  },
  navigationwrap:{
    width:"100%",
    padding: "3px 0px 8px",
    borderBottom: "1px solid #292929",
  },
  balanceboxi:{
    textAlign:"center",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      "&.balanceboxi":{
        display: "block",
        padding: "3px 10px",
      }
    },
    "& button":{
      padding:"0",
      margin:"0"
    },
    "& button b":{
     display:"none" 
    }
  },
  balnceixo:{
    height: "26px",
    marginTop: "6px",
    marginLeft: "6px",
  },
  navigationitem:{
    width: "33%",
    display: "inline-block",
    textAlign: "center",
    fontSize: "12px",
    cursor:"pointer",
    "& img":{
      display:"block", 
      margin: "1px auto",
      height: "26px",
    },
    "&.active":{
      color:"#ff3341"
    },
    "&.gamesidebar.active .gamesactive":{
      display:"block !important"
    },
    "&.gamesidebar.active .games":{
      display:"none"
    },
    "&.signinsidebar.active .menuicoactive":{
      display:"block !important"
    },
    "&.signinsidebar.active .menuico":{
      display:"none"
    }
  },
  gamemenu:{
    float:"right",
    display:"none",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      display:"block", 
      paddingTop: "7px",
    },
    ['@media (max-width:640px)']: { // eslint-disable-line no-useless-computed-key
      paddingTop: "9px",
    },
  },
  logoutbtnt:{
    background: "none  ",
    padding: "0px 10px",
    color: "#efde1d ",
    border: "0  ",
    borderRadius: "0",
    fontFamily: "ubuntu",
    fontSize:"14px",
    fontWeight:"normal",
    lineHeight: "30px", 
  },
  vipicon:{
    background:"none",
    border:"0px",
    marginRight:"0px",
    "& img":{
      height:"30px"
    }
  },
  laguagetrans:{ 
    float:"right", 
    marginTop: "5px",
    "& img":{
      width:"26px !important",
      height:"26px !important",
      marginTop: "2px",
      ['@media (max-width:640px)']: { // eslint-disable-line no-useless-computed-key
        width:"26px !important",
        height:"26px !important",
        marginTop: "1px",
      },
    }, 
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      padding:"0 !important",
      marginTop: "12px",
    },
    ['@media (max-width:460px)']: { // eslint-disable-line no-useless-computed-key 
      display: "none",
    },
    ['@media (max-width:410px)']: { // eslint-disable-line no-useless-computed-key 
      marginTop: "9px",
    },
    "& div":{
      ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
       padding:"0 !important"
      }
    } 
  },
  loginusericon:{
    display:"none",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      display:"inline-block",
    },
  },
  navLink:{
    padding:"0px 0px",
    minWidth: "24px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      color:"#bdbdbd !important",
      minWidth: "24px",
    },
  },
  headtitle:{
    fontSize: "70px",
    lineHeight: "80px",
    fontWeight: "700",
    marginBottom: "11px",
    textTransform: "uppercase",
    color: "#fff",
    fontFamily: "Ubuntu",
  },
  breadcrumbarea:{
    padding: "170px 0px 135px",
    background: "#070b28",
    position: "relative",
    overflow: "hidden",
    borderBottom: "1px solid rgba(255, 255, 255, 0.04)",
  },
  bcimg:{
    position: "absolute",
    right: "0px",
    bottom: "0px",
    maxWidth: "40%",
    verticalAlign: "middle",
  },
  listmar:{
    margin:"0px !important",
  },
  breadcrumblist:{
    "& li":{
      display: "inline-block",
    },
    "& li a":{
      fontSize: "16px",
      transition: "all 0.3s ease-in",
      color: "#fff",
    },
    "& li a span":{
      margin: "0px",
    },
    "& span":{
      margin: "0px 7px",
      color: "#fff",
    },
  },
  imglogo:{
    marginLeft: "20px",
    marginTop: "5px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
       marginLeft: "0px",
    },
    ['@media (max-width:959px)']: { // eslint-disable-line no-useless-computed-key
      maxWidth: "120px",
      maxHeight: "60px",
      height: "auto",
    },
    
  },
  displayNone: {
    display: "none"
  },
  appBar: {
    display: "block",
    border: "0",
    borderRadius: "3px",
    padding: "0 0",
    marginBottom: "20px",
    color: "#555",
    width: "100%",
    backgroundColor: "#000",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
    transition: "all 150ms ease 0s",
    alignItems: "center",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    position: "relative",
    zIndex: "unset"
  },
  absolute: {
    position: "absolute",
    zIndex: "1100"
  },
  fixed: {
    position: "fixed",
    zIndex: "1100"
  },
  containernew: {
    ...container,
    minHeight: "50px",
    display: "block",
    alignItems: "center",
    justifyContent: "space-between",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      width:"auto"
    },
  },
  container: {
    ...container,
    minHeight: "50px",
    flex: "1",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      width:"auto"
    },
  },
  flex: {
    flex: 1
  },
  socialIcons: {
    position: "relative",
    fontSize: "20px !important",
    marginRight: "4px",
    marginTop: "5px",
    color:"#fff",
  },
  title: {
    ...defaultFont,
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    padding: "8px 16px",
    letterSpacing: "unset",
    "&:hover,&:focus": {
      color: "inherit",
      background: "transparent"
    }
  },
  appResponsive: {
    margin: "20px 10px"
  },
  primary: {
    backgroundColor: primaryColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46)"
  },
  info: {
    backgroundColor: infoColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46)"
  },
  success: {
    backgroundColor: successColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(76, 175, 80, 0.46)"
  },
  warning: {
    backgroundColor: warningColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(255, 152, 0, 0.46)"
  },
  danger: {
    backgroundColor: dangerColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46)"
  },
  toolbarafter:{
    '&::before': {
      position: "absolute",
      content: 'close-quote',
      width: "1800px",
      height: "100%",
      top: "0",
      right: "100%",
      background: "#070b28",
    },
    '&::after': {
      position: "absolute",
      content: 'close-quote',
      width: "0",
      height: "0",
      left: "0px",
      bottom: "0px",
      borderBottom: "83px solid #070b28",
      borderRight: "35px solid transparent",
    },
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      '&::after': {
        borderRight: "0px",
      },
    },
  },
  rose: {
    backgroundColor: roseColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(233, 30, 99, 0.46)"
  },
  transparent: {
    backgroundColor: "transparent !important",
    boxShadow: "none",
    paddingTop: "0px",
    color: "#FFFFFF"
  },
  dark: {
    color: "#FFFFFF",
    backgroundColor: "#212121 !important",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)"
  },
  white: {
    border: "0",
    padding: "0.625rem 0",
    marginBottom: "20px",
    color: "#555",
    backgroundColor: "#fff !important",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)"
  },
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    width: drawerWidth,
    ...boxShadow,
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "0",
    left: "auto",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    ...transition,
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      background:"#3c3d3e"
    },
  },






// Signin
topheaderside:{
  ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
    display:"none !important"
  },
},
  topheader:{
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      padding:"5px 0px 5px !important"
    },
  },
  imglogo2:{
    marginLeft: "20px",
    marginTop: "5px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      marginLeft: "0px",
      height:"40px"
    },
    ['@media (max-width:959px)']: { // eslint-disable-line no-useless-computed-key
      maxWidth: "120px",
      maxHeight: "60px",
      height: "auto",
    },
  },
  logoname:{
    color:"#ffffff",
    margin: "12px 0 0 10px",
    fontFamily: "Ubuntu",
    fontWeight: "500"
  },
  himeicon:{
    display:"none",
    padding:"20px 0px 0px 10px",
    ['@media (max-width:450px)']: { // eslint-disable-line no-useless-computed-key
     // display:"block",
     // color:"#fff",
    },
  },
  headersignin:{
  },
  headersigninfix:{
    top:"0px",
    borderBottomWidth: "0px",
   // borderBottomStyle: "solid",
   // borderColor:"#efde1d"
  },
  headsigninbg:{
   // height: "58px",
   display:"inline-block",
    padding: "0px 0px",
   // backgroundSize: "100% 100%",
   
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
    //  top: "48px",
    // right: "50px",
    width: "auto",
    display: "block",
    overflow: "auto",
    // position: "absolute",
    // background: "#efde1d",
    // zIndex: "999",
      "&.mobilmenuset":{
        top: "51px",
       },
    },
  },
  balanceamount:{
    color: "#fff",
    width: "auto",
    display: "inline-block",
    fontSize: "14px",
    background: "none",
    boxShadow: "0px 0px 0px",
    textAlign: "right",
    lineHeight: "25px",
    padding:"3px 8px",
    borderRadius: "0px",
    "&:hover":{
      color:"#efde1d",
    },
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      // width: "auto",
      // fontSize: "14px",
      // lineHeight: "24px",   
      // display: "flex",  
    },
    ['@media (max-width:400px)']: { // eslint-disable-line no-useless-computed-key
      width: "auto",       
    },
    "& .fa-credit-card":{
      // ['@media (max-width:400px)']: { // eslint-disable-line no-useless-computed-key
      //   display:"none"
      // },
    }
  },
  logininlinebtn:{
    float:"right",
    marginLeft: "10px",
    marginTop: "6px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      marginLeft: "10px", 
      paddingTop: "1px",
      },
      ['@media (max-width:640px)']: { // eslint-disable-line no-useless-computed-key
        marginLeft: "10px",
        marginTop: "10px",
        paddingTop: "1px",
        },
        ['@media (max-width:410px)']: { // eslint-disable-line no-useless-computed-key 
          marginTop: "8px", 
          }, 
  },
  signinbtn:{
    fontSize: "14px",
    textTransform: "uppercase",
    fontWeight: "600",
    padding: "3px 15px 2px 15px",
    display: "inline-block",
    color: "#fff",
    border: "0px",
    borderRadius: "3px",
    boxShadow:"0px 0px 0px",
    cursor:" pointer",
    marginTop: "0px",
    transition: "all 0.3s ease-in",
    backgroundImage: "linear-gradient(to left, #ff344d, #d2273c)",
    "&:hover":{
      background:"#efde1d",
    },
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      padding: "5px 16px 4px 16px", 
      width:"100% !important",
      fontSize: "14px",
    },
    ['@media (max-width:640px)']: { // eslint-disable-line no-useless-computed-key
      padding: "2px 12px 2px 12px", 
      width:"100% !important",
      fontSize: "14px",
    },
  }, 
  othermenubtn:{
    display:"none !important",
    padding: "10px 7px !important",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      display:"block !important",
      padding: "10px 14px !important",
    },
    "& span#team4Plus":{
      float: "right",
      fontSize: "16px",
    },
    "& .showminus:before":{
      content: "'-'",
    },
    "& .showplus:before":{
      content: "'+'",
    } 
  },
  othermenu:{
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      display:"none",
    },
    "& li":{
      ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
         padding:"0px !important",
      }
    },
    "&.othermenu":{
      display:"block",
    }
  },
  mobilemenuside:{
    display:"none",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      display:"block",
      "& i":{
        display:"none"
      },
      "& button":{
        padding: "10px 7px",
      },
      "& a":{
        padding: "10px 7px",
      }
    },
  },
  coinbalance:{
    float:"left",
    color:"#efde1d",
    marginTop: "6px",
    marginLeft: "5px",
    marginRight: "10px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      marginTop: "7px",
    },
  },
  plusbalance:{
    float:"right",
    color:"#ff344d",
    marginLeft: "10px",
    marginTop: "6px",
    marginRight: "5px",
  },
  balnceiconbox:{
    background: "#000",
    width: "36px",
    height: "36px",
    display:"block",
    borderRadius: "50%",
    padding: "6px 8px",
    marginLeft: "4px",
    marginTop: "-2px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      marginLeft: "0px",
      marginTop: "0px", 
    },
    "& img":{
      height: "18px",
      margin: "0",
    },
    "& img.signinbalnce":{
      marginTop:"3px"
    }
  },
  balancerefresh:{
    color: "#efde1d",
    //display:"none",
    height: "30px",
    padding: "0",
    fontSize: "14px",
    minWidth: "36px",
    background: "none",
    marginLeft: "0px",
    marginRight: "0px",
    borderRadius: "50px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      // height: "34px",
      // padding: "0",
      // fontSize: "14px",
      // minWidth: "34px",
      // color:"#efde1d",
      // background: "none",
    },
  },
  vipiconn:{
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      display:"none !important"
    },
  },
  balanceboxwrap:{
    float: "right", 
    display: "block",  
    background: "rgba(0,0,0,0.4)",
    borderRadius: "5px",
    marginLeft:"10px",
    marginTop:"6px",
  //  backgroundPosition:"-11px -3px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      // paddingTop: "9px",
      // top: "-3px",
      // right: "60px",
      marginTop:"12px",
      // background:"none", 
    },
    ['@media (max-width:410px)']: { // eslint-disable-line no-useless-computed-key 
      // display:"none",
      marginTop:"8px"
    },
  },
  rightcontent2:{
    display:"inline-block",
    paddingLeft: "0px",
    paddingRight: "10px",
    float:"right",
    paddingTop: "5px",
    paddingBottom: "5px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "0px",
    },
    ['@media (max-width:410px)']: { // eslint-disable-line no-useless-computed-key
      paddingBottom: "0px", 
    },
  },
  navmenu:{
    padding:"0px",
    background:"none",
    position: "relative",
    "&:before":{
      right: "10px",
      position: "absolute",
      top: "23px",
      height: "8px",
      width: "8px",
    },
    "&:hover":{
      background:"none"
    }
  },
  usersigninbtn:{
    textTransform:"capitalize",
    fontSize: "18px",
    fontFamily: "ubuntu",
    fontWeight:"normal",
    "& i":{
      ['@media (max-width:761px)']: { // eslint-disable-line no-useless-computed-key
        
      },
    },
    "& b":{
      ['@media (max-width:460px)']: { // eslint-disable-line no-useless-computed-key
        marginLeft:"0"
      }, 
    }, 
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      // color: "#000 !important", 
      // display:"block",
      // padding: "0",  
      // margin: "0",  
      // background: "#efde1d",
      // textAlign: "center",
      // fontFamily: "ubuntu", 
      // borderRadius: "3px",
      // textTransform: "capitalize",
      // height: "34px",
      // fontSize: "14px",
      // minWidth: "34px",
      // lineHeight: "34px",
      "&:hover":{
        color: "#fff !important", 
      },
      "&:focus":{
        color: "#fff !important", 
      }
    },
    ['@media (max-width:640px)']: { // eslint-disable-line no-useless-computed-key
      height: "28px",
      fontSize: "14px",
      minWidth: "28px",
      lineHeight: "30px", 
    },
  },
  balanceboxwrapmobile:{    
    display: "flex",
    position: "absolute",
    background: "#131212",
    right: "0",
    padding: "4px",
    zIndex: "9",
    borderRadius: "5px",
  },
  userdetailicon:{
    display:"none",
    textTransform:"capitalize",
    fontSize: "18px", 
    height: "42px",
    padding: "0",
    lineHeight: "40px",
    minWidth: "42px", 
    borderRadius: "50px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      display: "inline-block",
      height: "30px",
      fontSize: "14px",
      lineHeight: "30px",
      minWidth: "30px",
    },
  },
  userdetailname:{
    margin:"0px",
    textTransform: "uppercase",
    fontSize: "16px",
    fontWeight: "normal",
    ['@media (max-width:460px)']: { // eslint-disable-line no-useless-computed-key
       display: "none",
    },
  },
  userflagbtn:{
    textTransform:"capitalize",
    fontSize: "18px",
    fontFamily: "ubuntu",
    "& b":{
      display:"none"
    },
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      marginTop: "0px !important", 
      marginLeft: "10px !important",
      marginBottom: "0px !important",
    },
  },
  rightsidebarbtn:{
    textTransform:"capitalize",
    fontSize: "25px",
    fontFamily: "ubuntu",
    color:" #fff",
    height: "30px",
    padding: "0",
    minWidth: "30px", 
    borderRadius: "3px",
    display:"block",
    textAlign: "center",
    lineHeight: "30px",
    "& b":{
      display:"none"
    },
    "& i":{
      marginTop:"1px"
    },
    "&:hover":{
      background:"none",
    },
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      height: "34px",
      // fontSize: "14px",
      lineHeight: "34px",
      minWidth: "34px",
    },
    ['@media (max-width:640px)']: { // eslint-disable-line no-useless-computed-key
      // height: "28px",
      // fontSize: "14px",
      // lineHeight: "28px",
      // minWidth: "30px",
    },
  },
  mainboxsearch:{
    padding:"7px 10px 10px",
    position:"relative", 
    clear:"both",
    background:"#000000", 
    borderRadius:"6px", 
    width:"94%",
    color:"#fff",
    margin: "5px 3%",
    padding:"5px 10px 8px" ,
    "& ul li":{
      float:"none"
    },
    "& ul li button":{
      fontSize:"13px"
    }
  },
  rightsidebardiv:{
    display:"inline-block",
    marginLeft:"10px",
    float: "right",
    marginTop:"6px",
    paddingTop: "0px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      marginLeft:"5px",
      paddingTop: "8px",
      marginTop:"0px",
    },
    ['@media (max-width:640px)']: { // eslint-disable-line no-useless-computed-key
      marginLeft:"5px",
      paddingTop: "9px",
      marginTop:"0px",
    },
    ['@media (max-width:410px)']: { // eslint-disable-line no-useless-computed-key
   //   display:"none",
    paddingTop: "7px",
    },
  },
  signinnotificationbox:{
    display:"none",
    position: "absolute",
    top: "50px",
    background: "#fff",
    color: "#000",
    right: "0px",
    zIndex:"9999",
    borderRadius:"10px",
    padding: "15px",
    "& > div:first-child":{
      margin:"0px"
    },
    "& div:nth-child(1)":{
      background:"none",
      boxShadow:"0px 0px 0px 0px",
      padding:"0px",
      margin:"0px",
    },
    "& button":{
      width: "50px !important",
      padding: "0",
    }
  },
  listItemnot:{
    padding:"0px"
  },
  notificationmsgbox:{
    padding:"10px !important",
    marginBottom:"10px !important",
    minWidth:"280px", 
    borderRadius:"10px",
    background:"#f1f1f1 !important",
    fontFamily: "ubuntu",
    "&:hover":{
      background:"#252f5a !important",
      color:"#fff"
    },
    "& h5":{
      padding:"0px",
      fontSize:"14px",
      fontWeight:"bold",
      margin:"0px 0px 5px",
      color:"#f9314b",
    },
    "& p":{
      padding:"0px",
      margin:"0px",
      fontSize:"12px"
    },
    "&:hover h5":{ 
      color:"#fff"
    },
  },
  signinsidebarbox:{
    display:"none",
    background: "rgba(0,0,0,0.5)",
    width:" 100%",
    height: "100%",
    position: "fixed",
    left: "0",
    top: "0",
    zIndex: "99999",
  },
  messagebox:{
    overflow:"auto",
    height:"260px"
  },
  usernotibtn:{
    color: "rgb(120,50,5) !important",
    height: "30px",
    display: "block",
    padding: "0",
    fontSize: "18px",
    minWidth: "30px",
    background: "linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07) !important",
    textAlign: "center",
    fontFamily: "ubuntu",
    lineHeight: "30px",
    borderRadius: "3px",
    textTransform: "capitalize",
    "& b":{
      display:"none"
    },
    "&:hover":{
      color:"#fff"
    },
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      height: "34px",
      fontSize: "14px",
      lineHeight: "34px",
      display:"none",
      minWidth: "34px", 
    },
    ['@media (max-width:640px)']: { // eslint-disable-line no-useless-computed-key
      height: "28px",
      fontSize: "14px",
      lineHeight: "28px",
      minWidth: "30px",  
    },
  },
  gamemenupad:{
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key 
      marginRight: "-5px",
    },
    ['@media (max-width:640px)']: { // eslint-disable-line no-useless-computed-key 
      marginRight: "-5px", 
    },
  },
  userdetaildiv:{
    display:"inline-block",
    float: "right",
    marginLeft: "15px",
    paddingTop: "5px",
    "& ul li a:hover":{
      color:'#000'
    },
    "& div":{
      padding:"0 !important"
    },
    "& div div":{
      padding:"0 !important"
    },
    "& div button div":{
      padding:"0 !important"
    },
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      marginLeft: "5px",
      paddingTop: "7px",
    },
    ['@media (max-width:640px)']: { // eslint-disable-line no-useless-computed-key
      marginLeft: "5px",
      paddingTop: "9px",
    },
    ['@media (max-width:410px)']: { // eslint-disable-line no-useless-computed-key
      paddingTop: "5px",
    },
    "& .MuiPaper-root.MuiPaper-elevation1":{
      ['@media (max-width:640px)']: { // eslint-disable-line no-useless-computed-key
        marginLeft: "-60px", 
      },
    }
  },
  cookiesbtntext:{
    margin: '0',
    fontFamily: 'Open Sans',
    fontSize:'14px',  
    ['@media (max-width:640px)']: { // eslint-disable-line no-useless-computed-key
        marginLeft: "-60px", 
      },
  },
  contryflagdiv:{
    display:"inline-block",
    marginLeft:"20px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      display: "none",
    },
  },
  notificationdiv:{
    display:"inline-block",
    position:"relative",
    marginLeft:"10px",
    float: "right",
    marginTop:"6px",  
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      marginLeft:"5px",
      paddingTop: "7px",
      marginTop:"0px",  
    },
  },
  balancewrap:{
    "& button":{
      padding:"0",
      margin:"0"
    },
    "& b":{
      display:"none"
    },
    "& li:hover":{
      background:"none !important",
      boxShadow:"0 0 0 0 !important"
    },
  },
  balancespike:{
    "& button":{
      background:"none !important"
    },
    "& li:hover":{
      background:"none !important"
    },
    "& button:hover":{
      background:"none !important"
    }
  },
  usersigninanker:{
    fontSize: "16px",
    color:"#fff",
    textDecoration:"none",
    display:"block",
    padding:"10px 20px", 
    textTransform: "capitalize",
    fontWeight: "normal",
    fontFamily: 'Ubuntu',
    lineHeight: "normal",
    width: "100%",
    
    textAlign: "left",
    "&:hover":{
      color:"#000",
      background:"#252f5a",
    },
    "& span":{
      fontFamily: 'Ubuntu',
    }
  },
  contryflag:{
    width:"40px", 
    height:"40px",
    borderRadius:"50px",
    verticalAlign: "middle"
  },
  listsign:{
    padding:"0",
    margin:"0",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      // display:"block",
      // width: "150px",
    },
  },
  listItemsign:{
    float: "left",
    margin: "0",
    display: "block",
    padding: "0",
    borderRight:"1px solid #666",
    width: "auto",
     ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      // display:"block",
      // width: "100%",
    },
  },
  listItemsignimg:{
    background:"#000",
    width:"40px",
    height:"40px",
    borderRadius:"50%",
    display: "inline-block",
    textAlign: "center",
    padding: "6px",
    marginRight:"8px",
    marginLeft:"10px",
    "& img":{
      height:"24px"
    }
  },
  accordianp:{
    padding: "0px 0px 10px 40px",
    "& a":{
      fontSize:"13px", 
      fontWeight: "lighter",
      color:"#d0d0d0",
      paddingTop:"4px",
      paddingBottom:"4px",
    }
  },
  menusignin:{ 
    padding: "7px 10px",
    color: "#fff",
    fontSize: "15px",
    borderRadius:"0px",
  //  textShadow: "1px 1px 1px #fff",
    fontFamily: "ubuntu",
    fontWeight: "normal",
    "&:hover":{
  //    background:"linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07) !important",
   //   color:"#fff"
   color:"#ffb80c"
    },
    "& i":{
      display:"none",
    },
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      padding: "8px 15px",
      fontSize: "14px",
      // color:"#000 !important",
      "&:hover":{
         color:"#000 !important"
      },
    },
  }, 
  signinheadersidebar:{ 
    position: "fixed",
    right: "0",
    top: "0",
    height: "100%",
    background: "#252f5a",
    minWidth: "280px",
  },
  sportosidemenu:{
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      overflow: "auto",
      height: "calc(100% - 70px)",
      position: "fixed",
      width: "280px",
      top: "20%",
    }, 
  },
  norecordimg:{
    ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
      margin: "0px !important",
      height: "110px",
    },
  },
  usernemme:{
    display:"none", 
    margin:"0px auto",
    height:"60px",
    ['@media (max-width:480px)']: { // eslint-disable-line no-useless-computed-key
      display:"block",
    },
  },
  mobisidebarlogo:{
    display:"none",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      display:"block",
      height:"60px",
      margin:"0px auto"
    },
    ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
      display:"block",
      height:"30px",
    },
    ['@media (max-width:480px)']: { // eslint-disable-line no-useless-computed-key
      display:"block",
      height:"60px",
      margin:"10% auto 0"
    },
  },
  listheadsidebartop:{
    background:"#070b28",
    padding:"10px 15px",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      position: "fixed",
      height: "20%",
      width: "280px",
    },
    "& h5":{
      display:"block",
      margin:"0",
      fontSize: "16px",
      borderRadius:"0px",
      fontFamily: "ubuntu",
      padding:"10px 0px",
      color:"#fff",
      fontWeight:"normal"
    },
    "& h6":{
      display:"block",
      margin:"0",
      fontWeight:"normal",
      fontSize: "16px",
      color:"#a1aed4",
      borderRadius:"0px",
      fontFamily: "ubuntu",
      padding:"0px 0px"
    },
  },
  listheadsidebar:{

  },
  listheadsidebarlogo:{
    margin:"0px auto",
    display:"block",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key 
      
      display: "none ", 
    },
  },
  listheadsidebaritem:{
    padding:"5px 0px",
  },
  listheadsidebaranker:{
    fontFamily: "ubuntu",
    textTransform:"capitalize",
    display:"block",
    width:"100%",
    textAlign:"left",
    paddingLeft:"15px",
    fontSize:"14px",
    fontWeight:"normal",
    color:"#a1aed4",
    "& i.fa":{
      marginRight:"5px"
    },
    "&:hover":{
      background:"none",
      color:"#000"
    }
  },
  demopopup:{
    float: "left",
    color: "#efde1d",
    padding: "0",
    fontSize: "15px",
    lineHeight: "30px",
  }

};

export default headerStyle;

import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roundCardHeader
} from "../../casino-kit-react";
const cardHeaderStyle = {
  cardHeader: {
    borderRadius: "3px",
    padding: "1rem 15px",
    marginLeft: "15px",
    marginRight: "15px",
    marginTop: "-10px",
    border: "0",
    marginBottom: "0",
    width: "282px !important",
    margin: "0px auto !important" ,
    paddingTop: "0px !important",
  },
  cardHeaderPlain: {
    marginLeft: "0px",
    marginRight: "0px",
    "& .MuiTabs-fixed":{
      overflow: "auto !important",
    }
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roundCardHeader
};

export default cardHeaderStyle;

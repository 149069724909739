/**
 * Authorization Roles
 */
const authRoles = {
    admin    : ['admin'],
    player    : ['player'],
    onlyGuest: []
};

export default authRoles;

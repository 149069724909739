 

const secsignupboxDb = {
    en: [
        {
            'id'    : '5725a680b3249760ea22de51',
            'subtitle'    : 'Create An new Account',
            'title'    : 'Sign Up',
            'email'    : 'Enter Your Email', 
            'password'    : 'Password', 
            'confirmpassword'    : 'Confirm Password', 
            'name'    : 'Enter Your Username', 
            'mobilen'    : 'Mobile Number', 
            'sbmitbtn'    : 'Sign Up',  
            'text_a'    : 'OTP',
            'text_or'    : 'Or',
            'text_fp_btn'    : 'Forgot Password',  
            'text_tnc_one':'By clicking Sign Up, you agree to our Terms and Conditions. You may receive SMS notifications from us and can opt out at any time.',
            'text_tnc_two':'Terms and Conditions', 
        }, 
    ],
    tl: [
        {
            'id'    : '5725a680b3249760ea22de51',
            'subtitle'    : 'క్రొత్త ఖాతాను సృష్టించండి',
            'title'    : 'చేరడం',
            'email'    : 'మీ ఇమెయిల్‌ను నమోదు చేయండి', 
            'password'    : 'పాస్వర్డ్', 
            'confirmpassword'    : 'పాస్వర్డ్ను నిర్ధారించండి', 
            'name'    : 'మీ వినియోగదారు పేరును నమోదు చేయండి', 
            'mobilen'    : 'మొబైల్ సంఖ్య', 
            'sbmitbtn'    : 'చేరడం',  
            'text_a'    : 'OTP', 
            'text_or'    : 'లేదా',
            'text_fp_btn'    : 'పాస్వర్డ్ మర్చిపోయారా',  
            'text_tnc_one':'సైన్ అప్ క్లిక్ చేయడం ద్వారా, మీరు మా నిబంధనలు మరియు షరతులను అంగీకరిస్తున్నారు. మీరు మా నుండి SMS నోటిఫికేషన్లను స్వీకరించవచ్చు మరియు ఎప్పుడైనా నిలిపివేయవచ్చు.',
            'text_tnc_two':'నిబంధనలు మరియు షరతులను', 
        }, 
    ],
    hi: [
        {
            'id'    : '5725a680b3249760ea22de51',
            'subtitle'    : 'एक नया खाता बनाएँ',
            'title'    : 'साइन अप करें',
            'email'    : 'अपना ईमेल दर्ज करें', 
            'password'    : 'पासवर्ड', 
            'confirmpassword'    : 'पासवर्ड की पुष्टि कीजिये', 
            'name'    : 'अपने उपयोगकर्ता नाम दर्ज करें', 
            'mobilen'    : 'मोबाइल नंबर', 
            'sbmitbtn'    : 'साइन अप करें',  
            'text_a'    : 'औटीपि ',  
            'text_or'    : 'या',
            'text_fp_btn'    : 'पासवर्ड भूल गए',    
            'text_tnc_one':'साइन अप पर क्लिक करके, आप हमारे नियमों और शर्तों से सहमत होते हैं। आप हमसे एसएमएस सूचनाएं प्राप्त कर सकते हैं और किसी भी समय चुन सकते हैं।',
            'text_tnc_two':'नियमों और शर्तों',    
        },  
    ],
    

};

export default secsignupboxDb;
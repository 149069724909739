import * as Actions from '../actions';

const initialState = {
    success: false,
    data: null,
    error  : null
};

const kycupload = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.KYC_SUCCESS:
        {
            return {
                success: true,
                data: action.payload
            };
        }
        case Actions.KYC_ERROR:
        {
            return {
                success: false,
                error  : action.payload
            };
        }
        default:
        {
            return state
        }
    }
};

export default kycupload;



const withdrawDb = {
    en: [
            {
                'tableheader':[
                    {
                        'id': 'stmhdr_1',
                        'xs': 2,
                        'field_name': 'No.',
                        'visible': 'both',
                    },{
                        'id': 'stmhdr_4',
                        'xs': 3,
                        'field_name': 'Account',
                        'visible': 'desktop',
                        'expandable': true
                    },{
                        'id': 'stmhdr_5',
                        'xs': 2,
                        'field_name': 'Chips',
                        'visible': 'both',
                    },{
                        'id': 'stmhdr_6',
                        'xs': 3,
                        'field_name': 'Date',
                        'visible': 'desktop',
                        'expandable': true
                    },{
                        'id': 'stmhdr_7',
                        'xs': 2,
                        'field_name': 'Status',
                        'visible': 'desktop',
                        'expandable': true
                    },
                ]
            }
    ],
    tm: [
        {
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 2,
                    'field_name': 'எண்',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 3,
                    'field_name': 'கணக்கு',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 2,
                    'field_name': 'சீவல்கள்',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 3,
                    'field_name': 'தேதி',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 2,
                    'field_name': 'நிலை',
                    'visible': 'desktop',
                    'expandable': true
                },
            ]
        }
    ],
    kn: [
        {
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 2,
                    'field_name': 'ಸಂಖ್ಯೆ',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 3,
                    'field_name': 'ಖಾತೆ',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 2,
                    'field_name': 'ಚಿಪ್ಸ್',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 3,
                    'field_name': 'ದಿನಾಂಕ',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 2,
                    'field_name': 'ಸ್ಥಿತಿ',
                    'visible': 'desktop',
                    'expandable': true
                },
            ]
        }
    ],
    ne: [
    {
        'tableheader':[
            {
                'id': 'stmhdr_1',
                'xs': 2,
                'field_name': 'संख्या',
                'visible': 'both',
            },{
                'id': 'stmhdr_4',
                'xs': 3,
                'field_name': 'खाता',
                'visible': 'desktop',
                'expandable': true
            },{
                'id': 'stmhdr_5',
                'xs': 2,
                'field_name': 'चिप्स',
                'visible': 'both',
            },{
                'id': 'stmhdr_6',
                'xs': 3,
                'field_name': 'मिति',
                'visible': 'desktop',
                'expandable': true
            },{
                'id': 'stmhdr_7',
                'xs': 2,
                'field_name': 'स्थिति',
                'visible': 'desktop',
                'expandable': true
            },
        ]
    }
],
    tl: [
        {
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 2,
                    'field_name': 'సంఖ్య',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 3,
                    'field_name': 'ఖాతా',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 2,
                    'field_name': 'చిప్స్',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 3,
                    'field_name': 'తేదీ',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 2,
                    'field_name': 'స్థితి',
                    'visible': 'desktop',
                    'expandable': true
                },
            ]
        }
],
    hi: [
            {
            'tableheader':[
                    {
                        'id': 'stmhdr_1',
                        'xs': 2,
                        'field_name': 'संख्या',
                        'visible': 'both',
                    },{
                        'id': 'stmhdr_4',
                        'xs': 3,
                        'field_name': 'खाता',
                        'visible': 'desktop',
                        'expandable': true
                    },{
                        'id': 'stmhdr_5',
                        'xs': 2,
                        'field_name': 'चिप्स',
                        'visible': 'both',
                    },{
                        'id': 'stmhdr_6',
                        'xs': 3,
                        'field_name': 'तारीख',
                        'visible': 'desktop',
                        'expandable': true
                    },{
                        'id': 'stmhdr_7',
                        'xs': 2,
                        'field_name': 'स्थिति',
                        'visible': 'desktop',
                        'expandable': true
                    },
                ]
            }
    ],
    categories: [
        {
            'heading':"Net Banking",
            'title': 'No Bank Account',
            'pragraph':"Please add your bank account information before proceeding.",
            'btn':"Add Bank Details",  
        } 
    ],
    categoriesHi: [
        {
            'heading':"नेट बैंकिंग",
            'title': 'कोई बैंक खाता नहीं',
            'pragraph':"आगे बढ़ने से पहले कृपया अपने बैंक खाते की जानकारी जोड़ें।",
            'btn':"बैंक विवरण जोड़ें",  
        },
    ]
}
export default withdrawDb;

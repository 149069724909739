 

const footertopDb = {
    en: [
        {
            'id'    : '5725a680b3249760ea21ee52', 
            'copyrighttext' : 'Copyright © 2020.All Rights Reserved By',
            'copyrightname' : 'iSoftGaming',
            'topbtntext':"Back to top",  
        }, 
    ],
    tm: [
        {
            'id'    : '5725a680b3249760ea21ee52', 
            'copyrighttext' : 'பதிப்புரிமை © 2020. அனைத்து உரிமைகளும் பாதுகாக்கப்பட்டவை',
            'copyrightname' : 'iSoftGaming',
            'topbtntext':"மீண்டும் மேலே",  
        }, 
    ],
    kn: [
        {
            'id'    : '5725a680b3249760ea21ee52', 
            'copyrighttext' : 'ಕೃತಿಸ್ವಾಮ್ಯ © 2020. ಎಲ್ಲ ಹಕ್ಕುಗಳನ್ನು ಕಾಯ್ದಿರಿಸಲಾಗಿದೆ',
            'copyrightname' : 'iSoftGaming',
            'topbtntext':"ಮತ್ತೆ ಮೇಲಕ್ಕೆ",  
        }, 
    ],
    ne: [
        {
            'id'    : '5725a680b3249760ea21ee52', 
            'copyrighttext' : 'प्रतिलिपि अधिकार © २०२०। सबै अधिकारहरू द्वारा सुरक्षित',
            'copyrightname' : 'iSoftGaming',
            'topbtntext':"शीर्षमा फिर्ता",  
        }, 
    ],
    tl: [
        {
            'id'    : '5725a680b3249760ea21ee52', 
            'copyrighttext' : 'కాపీరైట్ © 2020. అన్ని హక్కులూ ప్రత్యేకించుకోవడమైనది',
            'copyrightname' : 'iSoftGaming', 
            'topbtntext':"తిరిగి పైకి", 
        }, 
    ],
    hi: [
        {
            'id'    : '5725a680b3249760ea22de52',
            'copyrighttext' : 'कॉपीराइट © 2020. सर्वाधिकार सुरक्षित',
            'copyrightname' : 'iSoftGaming ',
            'topbtntext':"वापस शीर्ष पर", 
        },   
    ],
    

};

export default footertopDb;



const depositDb = {
    en: [
        {
            'id' : '5725a680b3249770ea31de79',
            'title':"Make a Deposit",
            'tagName': 'tab1',
            'mobalbtn':"How do i make a Rupee deposit?",
            'mobalheading':"How can I deposit money?",
            'paybyoptions':[
                {
                    'id': "1,2,3,4,5,6,7,8",
                    'name': 'All Types of Deposit',
                    'xs': 1
                },
                {
                    'id': "1",
                    'name': 'Bant to Bank',
                    'xs': 1
                },
                {
                    'id': "2",
                    'name': 'Cash Deposit',
                    'xs': 1
                },
                {
                    'id': "8",
                    'name': 'PayPal',
                    'xs': 1
                },
                {
                    'id': "4",
                    'name': 'PayTM',
                    'xs': 1
                },
                {
                    'id': "5",
                    'name': 'Phone Pay',
                    'xs': 1
                },
                {
                    'id': "6",
                    'name': 'Google Pay',
                    'xs': 1
                },
                {
                    'id': "7",
                    'name': 'Other Pay',
                    'xs': 1
                }
            ],
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"Last 7 Days", 
                },
                { 
                    'id':"2", 
                    'periodoption':"Last 1 Month",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"Last 6 Months",
                },  
            ], 
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 1,
                    'field_name': 'Request Number',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'Type of Request',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 1,
                    'field_name': 'Send On',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'Send From',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'Amount',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': 'Reference Id',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 2,
                    'field_name': 'Request On',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_8',
                    'xs': 1,
                    'field_name': 'Status',
                    'visible': 'desktop',
                    'expandable': true
                },
            ],
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de80',
                    'mobalitem':"In the “Deposit” tab of your iSoft Gaming account, select Rupee.",
                },
                {
                    'id' : '5725a680b3249770ea31de81',
                    'mobalitem':"Select which bank you want to transfer from and its amount.",
                },
                {
                    'id' : '5725a680b3249770ea31de82',
                    'mobalitem':"You will be redirected to the bank page. Follow the instructions on the page and complete the transaction.",
                },
                {
                    'id' : '5725a680b3249770ea31de83',
                    'mobalitem':"After a successful transaction, your credit will be added automatically within 5 minutes.",
                },
                {
                    'id' : '5725a680b3249770ea31de84',
                    'mobalitem':"To avoid any delays in processing the deposit, kindly ensure all information is submitted correctly.",
                },
                {
                    'id' : '5725a680b3249770ea31de85',
                    'mobalitem':"Please feel free to contact Customer Service if you have any questions.",
                }, 
            ], 
            'guidebtn':"Rupee Guide",
            'bankname': "Select your bank",
            'bankoptions':[
                {
                    'id' : '5725a680b3249770ea31de86',
                    'bankoptions':"SBI",
                },
                {
                    'id' : '5725a680b3249770ea31de87',
                    'bankoptions':"HDFC",
                },
                {
                    'id' : '5725a680b3249770ea31de88',
                    'bankoptions':"ICICI",
                },
                {
                    'id' : '5725a680b3249770ea31de89',
                    'bankoptions':"PNB",
                },
            ],
            'amountheading': "Enter amount to deposit",
            'amountoption': "Enter Your Amount",
            'depositbtn':"Deposit",
            'tableheading':"Rupee Limit",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea31de90',
                    'tableth':"Minimum deposit per transaction", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea31de91',
                    'tableth':"Maximum deposit per transaction", 
                    'tabletd':"INR 20,000.00",
                },
            ],
            'tablenote':"NOTE: Deposits are subjected to a 1x wagering requirement.",
            
            'select_pay_by':"Deposit By",
            'receiver_bank_account':"Receiver Bank Account",
            'sender_bank_account':"Sender Bank Account",
            'payment_mode':"Payment Mode",
            'trasationid':"Transaction Id (Reference Number)",
            'amount':"Amount",
            'confirm_amount':"Confirm Amount",
            'comment':"Comment",
            'receipt_upload':"Receipt Upload",
            'depositbtns':"Deposit",
        },
      
        {
            'id' : '5725a680b3249770ea31de92',
            'title':"Deposit Status",
            'tagName': 'tab2',
            'mobalbtn':"How do I make a Credit deposit?",
            'mobalheading':"How do I make a Credit Card deposit?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de93',
                    'mobalitem':"Log in to your iSoft Gaming account.",
                },
                {
                    'id' : '5725a680b3249770ea31de94',
                    'mobalitem':"Click the “Deposit” button at the dropdown arrow near your username.",
                },
                {
                    'id' : '5725a680b3249770ea31de95',
                    'mobalitem':"Inside the deposit tab, select “Credit Card” as your preferred deposit method.",
                },
                {
                    'id' : '5725a680b3249770ea31de96',
                    'mobalitem':"Enter your Credit card information including the card number, CVV number and expiration date.",
                },
                {
                    'id' : '5725a680b3249770ea31de97',
                    'mobalitem':"Type your desired amount of payment in the empty field of the deposit amount.",
                },
                {
                    'id' : '5725a680b3249770ea31de98',
                    'mobalitem':"Click on the “Deposit” button at the end of the page to confirm your deposit.",
                },
                {
                    'id' : '5725a680b3249770ea31de99',
                    'mobalitem':"You will receive the deposit confirmation afterward.",
                },
            ], 
            'card_cardnumber':"#### #### #### ####",
            'card_date_head':"Expires",
            'card_date':"MM/YY",
            'card_cvv_head':"CVV",
            'card_cvv':"####",
            'p_tag_first':"Exclusive use for Credit Card only. Do not insert Credit Card details.",
            'p_tag_a':"Download",
            'p_tag_second':"Credit App to purchase an Credit card.",
            'card_number':"Card Number",
            'expiry_date':"Expiry Date",
            'cvv':"CVV",
            'deposit_amount':"Deposit Amount",
            'deposit_btn':"Deposit",
            'tableheading':"Credit Card Limit",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de01',
                    'tableth':"Minimum deposit per transaction", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de02',
                    'tableth':"Maximum deposit per transaction", 
                    'tabletd':"INR 99,000.00",
                },
            ],
            'tablenote':"NOTE: Deposits are subjected to a 1x wagering requirement.",
            'payingtpe':'Deposit Type',
            'period':'Period',
            'startdate':'Start Date',
            'enddate':'End Date',
        },
        
      
        {
            'id' : '5725a680b3249770ea31de92',
            'title':"Online Payment",
            'tagName': 'tab3',
            'mobalbtn':"How do I make a Credit deposit?",
            'mobalheading':"How do I make a Credit Card deposit?",
            'depositbtns':"Deposit",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de93',
                    'mobalitem':"Log in to your iSoft Gaming account.",
                },
                {
                    'id' : '5725a680b3249770ea31de94',
                    'mobalitem':"Click the “Deposit” button at the dropdown arrow near your username.",
                },
                {
                    'id' : '5725a680b3249770ea31de95',
                    'mobalitem':"Inside the deposit tab, select “Credit Card” as your preferred deposit method.",
                },
                {
                    'id' : '5725a680b3249770ea31de96',
                    'mobalitem':"Enter your Credit card information including the card number, CVV number and expiration date.",
                },
                {
                    'id' : '5725a680b3249770ea31de97',
                    'mobalitem':"Type your desired amount of payment in the empty field of the deposit amount.",
                },
                {
                    'id' : '5725a680b3249770ea31de98',
                    'mobalitem':"Click on the “Deposit” button at the end of the page to confirm your deposit.",
                },
                {
                    'id' : '5725a680b3249770ea31de99',
                    'mobalitem':"You will receive the deposit confirmation afterward.",
                },
            ], 
            'card_cardnumber':"#### #### #### ####",
            'card_date_head':"Expires",
            'card_date':"MM/YY",
            'card_cvv_head':"CVV",
            'card_cvv':"####",
            'p_tag_first':"Exclusive use for Credit Card only. Do not insert Credit Card details.",
            'p_tag_a':"Download",
            'p_tag_second':"Credit App to purchase an Credit card.",
            'card_number':"Card Number",
            'expiry_date':"Expiry Date",
            'cvv':"CVV",
            'deposit_amount':"Deposit Amount",
            'deposit_btn':"Deposit",
            'tableheading':"Credit Card Limit",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de01',
                    'tableth':"Minimum deposit per transaction", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de02',
                    'tableth':"Maximum deposit per transaction", 
                    'tabletd':"INR 99,000.00",
                },
            ],
            'tablenote':"NOTE: Deposits are subjected to a 1x wagering requirement.",
            'amount':'Amount',
            'confirmamount':'Confirm Amount',
            'comment':'Comment',
        },
        {
            'id' : '5725a680b3249770ea31de92',
            'title':"E-Wallet",
            'tagName': 'tab4',
            'mobalbtn':"How do I make a Credit deposit?",
            'mobalheading':"How do I make a Credit Card deposit?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de93',
                    'mobalitem':"Log in to your iSoft Gaming account.",
                },
                {
                    'id' : '5725a680b3249770ea31de94',
                    'mobalitem':"Click the “Deposit” button at the dropdown arrow near your username.",
                },
                {
                    'id' : '5725a680b3249770ea31de95',
                    'mobalitem':"Inside the deposit tab, select “Credit Card” as your preferred deposit method.",
                },
                {
                    'id' : '5725a680b3249770ea31de96',
                    'mobalitem':"Enter your Credit card information including the card number, CVV number and expiration date.",
                },
                {
                    'id' : '5725a680b3249770ea31de97',
                    'mobalitem':"Type your desired amount of payment in the empty field of the deposit amount.",
                },
                {
                    'id' : '5725a680b3249770ea31de98',
                    'mobalitem':"Click on the “Deposit” button at the end of the page to confirm your deposit.",
                },
                {
                    'id' : '5725a680b3249770ea31de99',
                    'mobalitem':"You will receive the deposit confirmation afterward.",
                },
            ], 
            'card_cardnumber':"#### #### #### ####",
            'card_date_head':"Expires",
            'card_date':"MM/YY",
            'card_cvv_head':"CVV",
            'card_cvv':"####",
            'p_tag_first':"Exclusive use for Credit Card only. Do not insert Credit Card details.",
            'p_tag_a':"Download",
            'p_tag_second':"Credit App to purchase an Credit card.",
            'card_number':"Card Number",
            'expiry_date':"Expiry Date",
            'cvv':"CVV",
            'deposit_amount':"Deposit Amount",
            'deposit_btn':"Deposit",
            'tableheading':"Credit Card Limit",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de01',
                    'tableth':"Minimum deposit per transaction", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de02',
                    'tableth':"Maximum deposit per transaction", 
                    'tabletd':"INR 99,000.00",
                },
            ],
            'tablenote':"NOTE: Deposits are subjected to a 1x wagering requirement.",
            'transferedphonenumber':"To Phone Number",
            'senderphonenumber':"From Phone Number", 
            'trasationid':"Transaction Id ",
            'amount':"Amount",
            'confirm_amount':"Confirm Amount",
            'comment':"Comment",
            'receipt_upload':"Receipt Upload",
            'depositbtns':"Deposit",
        },
        
    ],
    tm: [
        {
            'id' : '5725a680b3249770ea31de79',
            'title':"ஒரு வைப்பு செய்யுங்கள்",
            'tagName': 'tab1',
            'mobalbtn':"ரூபாய் வைப்பு செய்வது எப்படி?",
            'mobalheading':"நான் எவ்வாறு பணத்தை டெபாசிட் செய்யலாம்?",
            'paybyoptions':[
                {
                    'id': "1,2,3,4,5,6,7,8",
                    'name': 'அனைத்து வகையான வைப்புத்தொகையும்',
                    'xs': 1
                },
                {
                    'id': "1",
                    'name': 'வங்கி முதல் வங்கி',
                    'xs': 1
                },
                {
                    'id': "2",
                    'name': 'பண வைப்பு',
                    'xs': 1
                },
                {
                    'id': "8",
                    'name': 'பேபால்',
                    'xs': 1
                },
                {
                    'id': "4",
                    'name': 'PayTM',
                    'xs': 1
                },
                {
                    'id': "5",
                    'name': 'தொலைபேசி கட்டணம்',
                    'xs': 1
                },
                {
                    'id': "6",
                    'name': 'Google Pay',
                    'xs': 1
                },
                {
                    'id': "7",
                    'name': 'பிற ஊதியம்',
                    'xs': 1
                }
            ],
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"கடைசி 7 நாட்கள்", 
                },
                { 
                    'id':"2", 
                    'periodoption':"கடந்த 1 மாதம்",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"கடந்த 6 மாதங்கள்",
                },  
            ], 
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 1,
                    'field_name': 'கோரிக்கை எண்',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'கோரிக்கை வகை',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 1,
                    'field_name': 'அனுப்பு',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'அனுப்பவும்',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'தொகை',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': 'குறிப்பு ஐடி',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 2,
                    'field_name': 'கோரிக்கை',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_8',
                    'xs': 1,
                    'field_name': 'நிலை',
                    'visible': 'desktop',
                    'expandable': true
                },
            ],
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de80',
                    'mobalitem':"உங்கள் ஐசாஃப்ட் கேமிங் கணக்கின் “டெபாசிட்” தாவலில், ரூபாயைத் தேர்ந்தெடுக்கவும்.",
                },
                {
                    'id' : '5725a680b3249770ea31de81',
                    'mobalitem':"நீங்கள் எந்த வங்கியில் இருந்து மாற்ற விரும்புகிறீர்கள் மற்றும் அதன் தொகையைத் தேர்ந்தெடுக்கவும்.",
                },
                {
                    'id' : '5725a680b3249770ea31de82',
                    'mobalitem':"நீங்கள் வங்கி பக்கத்திற்கு திருப்பி விடப்படுவீர்கள். பக்கத்தில் உள்ள வழிமுறைகளைப் பின்பற்றி பரிவர்த்தனையை முடிக்கவும்.",
                },
                {
                    'id' : '5725a680b3249770ea31de83',
                    'mobalitem':"வெற்றிகரமான பரிவர்த்தனைக்குப் பிறகு, உங்கள் கடன் 5 நிமிடங்களுக்குள் தானாக சேர்க்கப்படும்.",
                },
                {
                    'id' : '5725a680b3249770ea31de84',
                    'mobalitem':"வைப்புத்தொகையை செயலாக்குவதில் தாமதம் ஏற்படுவதைத் தவிர்க்க, எல்லா தகவல்களும் சரியாக சமர்ப்பிக்கப்படுவதை தயவுசெய்து உறுதிப்படுத்தவும்.",
                },
                {
                    'id' : '5725a680b3249770ea31de85',
                    'mobalitem':"உங்களுக்கு ஏதேனும் கேள்விகள் இருந்தால் தயவுசெய்து வாடிக்கையாளர் சேவையைத் தொடர்பு கொள்ளவும்.",
                }, 
            ], 
            'guidebtn':"ரூபாய் கையேடு",
            'bankname': "உங்கள் வங்கியைத் தேர்ந்தெடுக்கவும்",
            'bankoptions':[
                {
                    'id' : '5725a680b3249770ea31de86',
                    'bankoptions':"எஸ்பிஐ",
                },
                {
                    'id' : '5725a680b3249770ea31de87',
                    'bankoptions':"HDFC",
                },
                {
                    'id' : '5725a680b3249770ea31de88',
                    'bankoptions':"ஐ.சி.ஐ.சி.ஐ.",
                },
                {
                    'id' : '5725a680b3249770ea31de89',
                    'bankoptions':"பி.என்.பி.",
                },
            ],
            'amountheading': "டெபாசிட் செய்ய தொகையை உள்ளிடவும்",
            'amountoption': "உங்கள் தொகையை உள்ளிடவும்",
            'depositbtn':"வைப்பு",
            'tableheading':"ரூபாய் வரம்பு",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea31de90',
                    'tableth':"ஒரு பரிவர்த்தனைக்கு குறைந்தபட்ச வைப்பு", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea31de91',
                    'tableth':"ஒரு பரிவர்த்தனைக்கு அதிகபட்ச வைப்பு", 
                    'tabletd':"INR 20,000.00",
                },
            ],
            'tablenote':"குறிப்பு: வைப்புத்தொகை 1x வேகரிங் தேவைக்கு உட்பட்டது.", 
            'select_pay_by': "வைப்பு மூலம்",
            'receiver_bank_account': "ரிசீவர் வங்கி கணக்கு",
            'sender_bank_account': "அனுப்புநர் வங்கி கணக்கு",
            'payment_mode': "கட்டண முறை",
            'trasationid': "பரிவர்த்தனை ஐடி (குறிப்பு எண்)",
            'amount': "தொகை",
            'confirm_amount': "தொகையை உறுதிப்படுத்தவும்",
            'comment': "கருத்து",
            'receipt_upload': "ரசீது பதிவேற்றம்",
            'depositbtns': "வைப்பு",
        },
      
        {
            'id' : '5725a680b3249770ea31de92',
            'title':"வைப்பு நிலை",
            'tagName': 'tab2',
            'mobalbtn':"கிரெடிட் டெபாசிட் செய்வது எப்படி?",
            'mobalheading':"கிரெடிட் கார்டு டெபாசிட் செய்வது எப்படி?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de93',
                    'mobalitem':"உங்கள் ஐசாஃப்ட் கேமிங் கணக்கில் உள்நுழைக.",
                },
                {
                    'id' : '5725a680b3249770ea31de94',
                    'mobalitem':"உங்கள் பயனர்பெயருக்கு அருகிலுள்ள கீழ்தோன்றும் அம்புக்குறியில் உள்ள “வைப்பு” பொத்தானைக் கிளிக் செய்க.",
                },
                {
                    'id' : '5725a680b3249770ea31de95',
                    'mobalitem':"வைப்பு தாவலின் உள்ளே, உங்களுக்கு விருப்பமான வைப்பு முறையாக “கிரெடிட் கார்டு” என்பதைத் தேர்ந்தெடுக்கவும்.",
                },
                {
                    'id' : '5725a680b3249770ea31de96',
                    'mobalitem':"அட்டை எண், சி.வி.வி எண் மற்றும் காலாவதி தேதி உள்ளிட்ட உங்கள் கிரெடிட் கார்டு தகவலை உள்ளிடவும்.",
                },
                {
                    'id' : '5725a680b3249770ea31de97',
                    'mobalitem':"நீங்கள் விரும்பிய தொகையை டெபாசிட் தொகையின் வெற்று புலத்தில் தட்டச்சு செய்க.",
                },
                {
                    'id' : '5725a680b3249770ea31de98',
                    'mobalitem':"உங்கள் வைப்புத்தொகையை உறுதிப்படுத்த பக்கத்தின் இறுதியில் உள்ள “வைப்பு” பொத்தானைக் கிளிக் செய்க.",
                },
                {
                    'id' : '5725a680b3249770ea31de99',
                    'mobalitem':"நீங்கள் டெபாசிட் உறுதிப்படுத்தலைப் பெறுவீர்கள்.",
                },
            ], 
            'card_cardnumber':"#### #### #### ####",
            'card_date_head':"காலாவதியாகிறது",
            'card_date':"MM/YY",
            'card_cvv_head':"சி.வி.வி.",
            'card_cvv':"####",
            'p_tag_first':"கிரெடிட் கார்டுக்கு மட்டுமே பிரத்யேக பயன்பாடு. கிரெடிட் கார்டு விவரங்களைச் செருக வேண்டாம்.",
            'p_tag_a':"பதிவிறக்க",
            'p_tag_second':"கிரெடிட் கார்டு வாங்க கிரெடிட் ஆப்.",
            'card_number':"அட்டை எண்",
            'expiry_date':"காலாவதி தேதி",
            'cvv':"சி.வி.வி.",
            'deposit_amount':"வைப்பு தொகை",
            'deposit_btn':"வைப்பு",
            'tableheading':"கடன் அட்டை வரம்பு",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de01',
                    'tableth':"ஒரு பரிவர்த்தனைக்கு குறைந்தபட்ச வைப்பு", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de02',
                    'tableth':"ஒரு பரிவர்த்தனைக்கு அதிகபட்ச வைப்பு", 
                    'tabletd':"INR 99,000.00",
                },
            ],
            'tablenote':"குறிப்பு: வைப்புத்தொகை 1x வேகரிங் தேவைக்கு உட்பட்டது.",
            'payingtpe':'வைப்பு வகை',
            'period':'காலம்',
            'startdate':'தொடக்க தேதி',
            'enddate':'கடைசி தேதி',
        },
        
      
        {
            'id' : '5725a680b3249770ea31de92',
            'title':"ஆன்லைன் கட்டணம்",
            'tagName': 'tab3',
            'mobalbtn':"கிரெடிட் டெபாசிட் செய்வது எப்படி?",
            'mobalheading':"கிரெடிட் கார்டு டெபாசிட் செய்வது எப்படி?",
            'deposit_btn': "வைப்பு",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de93',
                    'mobalitem':"உங்கள் ஐசாஃப்ட் கேமிங் கணக்கில் உள்நுழைக.",
                },
                {
                    'id' : '5725a680b3249770ea31de94',
                    'mobalitem':"உங்கள் பயனர்பெயருக்கு அருகிலுள்ள கீழ்தோன்றும் அம்புக்குறியில் உள்ள “வைப்பு” பொத்தானைக் கிளிக் செய்க.",
                },
                {
                    'id' : '5725a680b3249770ea31de95',
                    'mobalitem':"வைப்பு தாவலின் உள்ளே, உங்களுக்கு விருப்பமான வைப்பு முறையாக “கிரெடிட் கார்டு” என்பதைத் தேர்ந்தெடுக்கவும்.",
                },
                {
                    'id' : '5725a680b3249770ea31de96',
                    'mobalitem':"அட்டை எண், சி.வி.வி எண் மற்றும் காலாவதி தேதி உள்ளிட்ட உங்கள் கிரெடிட் கார்டு தகவலை உள்ளிடவும்.",
                },
                {
                    'id' : '5725a680b3249770ea31de97',
                    'mobalitem':"நீங்கள் விரும்பிய தொகையை டெபாசிட் தொகையின் வெற்று புலத்தில் தட்டச்சு செய்க.",
                },
                {
                    'id' : '5725a680b3249770ea31de98',
                    'mobalitem':"உங்கள் வைப்புத்தொகையை உறுதிப்படுத்த பக்கத்தின் இறுதியில் உள்ள “வைப்பு” பொத்தானைக் கிளிக் செய்க.",
                },
                {
                    'id' : '5725a680b3249770ea31de99',
                    'mobalitem':"நீங்கள் டெபாசிட் உறுதிப்படுத்தலைப் பெறுவீர்கள்.",
                },
            ], 
            'card_cardnumber':"#### #### #### ####", 
            'card_date_head': "காலாவதியாகிறது",
            'card_date': "MM / YY",
            'card_cvv_head': "CVV",
            'card_cvv': "####",
            'p_tag_first': "கிரெடிட் கார்டுக்கு மட்டுமே பிரத்தியேக பயன்பாடு. கிரெடிட் கார்டு விவரங்களைச் செருக வேண்டாம்.",
            'p_tag_a': "பதிவிறக்கு",
            'p_tag_second': "கிரெடிட் கார்டை வாங்க கடன் பயன்பாடு.",
            'card_number': "அட்டை எண்",
            'expiry_date': "காலாவதி தேதி",
            'cvv':"CVV", 
            'deposit_amount': "வைப்புத் தொகை",
            'deposit_btn': "வைப்பு",
            'tableheading': "கிரெடிட் கார்டு வரம்பு",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de01',
                    'tableth':"ஒரு பரிவர்த்தனைக்கு குறைந்தபட்ச வைப்பு", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de02',
                    'tableth':"ஒரு பரிவர்த்தனைக்கு அதிகபட்ச வைப்பு", 
                    'tabletd':"INR 99,000.00",
                },
            ],
            'tablenote':"குறிப்பு: வைப்புத்தொகை 1x வேகரிங் தேவைக்கு உட்பட்டது.",
            'amount':'தொகை',
            'confirmamount':'தொகையை உறுதிப்படுத்தவும்',
            'comment':'கருத்து',
        },
        {
            'id' : '5725a680b3249770ea31de92',
            'title':"இ-வாலட்",
            'tagName': 'tab4',
            'mobalbtn':"கிரெடிட் டெபாசிட் செய்வது எப்படி?",
            'mobalheading':"கிரெடிட் கார்டு டெபாசிட் செய்வது எப்படி?",
            'moballist':[ 

                {
                    'id': '5725a680b3249770ea31de93',
                    'mobalitem': "உங்கள் ஐசாஃப்ட் கேமிங் கணக்கில் உள்நுழைக.",
                },
                {
                    'id': '5725a680b3249770ea31de94',
                    'mobalitem': "உங்கள் பயனர்பெயருக்கு அருகிலுள்ள கீழ்தோன்றும் அம்புக்குறியில் உள்ள' வைப்பு 'பொத்தானைக் கிளிக் செய்க.",
                },
                {
                    'id': '5725a680b3249770ea31de95',
                    'mobalitem': "வைப்புத் தாவலின் உள்ளே, உங்கள் விருப்பமான வைப்பு முறையாக“ கிரெடிட் கார்டு ”என்பதைத் தேர்ந்தெடுக்கவும்.",
                },
                {
                    'id': '5725a680b3249770ea31de96',
                    'mobalitem': "அட்டை எண், சி.வி.வி எண் மற்றும் காலாவதி தேதி உள்ளிட்ட உங்கள் கிரெடிட் கார்டு தகவலை உள்ளிடவும்.",
                },
                {
                    'id': '5725a680b3249770ea31de97',
                    'mobalitem': "நீங்கள் விரும்பிய தொகையை டெபாசிட் தொகையின் வெற்று புலத்தில் தட்டச்சு செய்க.",
                },
                {
                    'id': '5725a680b3249770ea31de98',
                    'mobalitem': "உங்கள் வைப்புத்தொகையை உறுதிப்படுத்த பக்கத்தின் இறுதியில் உள்ள' வைப்பு 'பொத்தானைக் கிளிக் செய்க.",
                },
                {
                    'id': '5725a680b3249770ea31de99',
                    'mobalitem': "நீங்கள் வைப்பு உறுதிப்படுத்தலைப் பெறுவீர்கள்.",
                },
            ], 
            'card_cardnumber':"#### #### #### ####",  
            'card_date_head': "காலாவதியாகிறது",
            'card_date': "MM / YY",
            'card_cvv_head': "CVV",
            'card_cvv': "####",
            'p_tag_first': "கிரெடிட் கார்டுக்கு மட்டுமே பிரத்தியேக பயன்பாடு. கிரெடிட் கார்டு விவரங்களைச் செருக வேண்டாம்.",
            'p_tag_a': "பதிவிறக்கு",
            'p_tag_second': "கிரெடிட் கார்டை வாங்க கடன் பயன்பாடு.",
            'card_number': "அட்டை எண்",
            'expiry_date': "காலாவதி தேதி",
            'cvv': "CVV",
            'deposit_amount': "வைப்புத் தொகை",
            'deposit_btn': "வைப்பு",
            'tableheading': "கிரெடிட் கார்டு வரம்பு",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de01',
                    'tableth':"ஒரு பரிவர்த்தனைக்கு குறைந்தபட்ச வைப்பு", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de02',
                    'tableth':"ஒரு பரிவர்த்தனைக்கு அதிகபட்ச வைப்பு", 
                    'tabletd':"INR 99,000.00",
                },
            ],
            'tablenote':"குறிப்பு: வைப்புத்தொகை 1x வேகரிங் தேவைக்கு உட்பட்டது.",
            'transferedphonenumber':"தொலைபேசி எண்ணுக்கு",
            'senderphonenumber':"தொலைபேசி எண்ணிலிருந்து", 
            'trasationid':"பரிவர்த்தனை ஐடி ",
            'amount':"தொகை",
            'confirm_amount':"தொகையை உறுதிப்படுத்தவும்",
            'comment':"கருத்து",
            'receipt_upload':"ரசீது பதிவேற்றம்",
            'depositbtns':"வைப்பு",
        },
        
    ],
    kn: [
        {
            'id' : '5725a680b3249770ea31de79',
            'title':"ಠೇವಣಿ ಮಾಡಿ",
            'tagName': 'tab1',
            'mobalbtn':"ರೂಪಾಯಿ ಠೇವಣಿ ಮಾಡುವುದು ಹೇಗೆ?",
            'mobalheading':"ನಾನು ಹಣವನ್ನು ಹೇಗೆ ಠೇವಣಿ ಮಾಡಬಹುದು?",
            'paybyoptions':[
                {
                    'id': "1,2,3,4,5,6,7,8",
                    'name': 'ಎಲ್ಲಾ ರೀತಿಯ ಠೇವಣಿ',
                    'xs': 1
                },
                {
                    'id': "1",
                    'name': 'ಬ್ಯಾಂಕ್ ಟು ಬ್ಯಾಂಕ್',
                    'xs': 1
                },
                {
                    'id': "2",
                    'name': 'ನಗದು ಠೇವಣಿ',
                    'xs': 1
                },
                {
                    'id': "8",
                    'name': 'ಪೇಪಾಲ್',
                    'xs': 1
                },
                {
                    'id': "4",
                    'name': 'ಪೇಟಿಎಂ',
                    'xs': 1
                },
                {
                    'id': "5",
                    'name': 'ಫೋನ್ ಪಾವತಿ',
                    'xs': 1
                },
                {
                    'id': "6",
                    'name': 'Google Pay',
                    'xs': 1
                },
                {
                    'id': "7",
                    'name': 'ಇತರೆ ವೇತನ',
                    'xs': 1
                }
            ],
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"ಕೊನೆಯ 7 ದಿನಗಳು", 
                },
                { 
                    'id':"2", 
                    'periodoption':"ಕೊನೆಯ 1 ತಿಂಗಳು",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"ಕಳೆದ 6 ತಿಂಗಳುಗಳು",
                },  
            ], 
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 1,
                    'field_name': 'ವಿನಂತಿ ಸಂಖ್ಯೆ',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'ವಿನಂತಿಯ ಪ್ರಕಾರ',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 1,
                    'field_name': 'ಕಳುಹಿಸಿ',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'ನಿಂದ ಕಳುಹಿಸಿ',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'ಮೊತ್ತ',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': 'ಉಲ್ಲೇಖ ಐಡಿ',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 2,
                    'field_name': 'ವಿನಂತಿಯನ್ನು ಆನ್ ಮಾಡಿ',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_8',
                    'xs': 1,
                    'field_name': 'ಸ್ಥಿತಿ',
                    'visible': 'desktop',
                    'expandable': true
                },
            ],
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de80',
                    'mobalitem':"ನಿಮ್ಮ ಐಸಾಫ್ಟ್ ಗೇಮಿಂಗ್ ಖಾತೆಯ “ಠೇವಣಿ” ಟ್ಯಾಬ್‌ನಲ್ಲಿ, ರೂಪಾಯಿ ಆಯ್ಕೆಮಾಡಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de81',
                    'mobalitem':"ನೀವು ಯಾವ ಬ್ಯಾಂಕ್‌ನಿಂದ ವರ್ಗಾಯಿಸಲು ಬಯಸುತ್ತೀರಿ ಮತ್ತು ಅದರ ಮೊತ್ತವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ.",
                }, 
                {
                    'id': '5725a680b3249770ea31de82',
                    'mobalitem': "ನಿಮ್ಮನ್ನು ಬ್ಯಾಂಕ್ ಪುಟಕ್ಕೆ ಮರುನಿರ್ದೇಶಿಸಲಾಗುತ್ತದೆ. ಪುಟದಲ್ಲಿನ ಸೂಚನೆಗಳನ್ನು ಅನುಸರಿಸಿ ಮತ್ತು ವ್ಯವಹಾರವನ್ನು ಪೂರ್ಣಗೊಳಿಸಿ.",
                },
                {
                    'id': '5725a680b3249770ea31de83',
                    'mobalitem': "ಯಶಸ್ವಿ ವಹಿವಾಟಿನ ನಂತರ, ನಿಮ್ಮ ಕ್ರೆಡಿಟ್ ಅನ್ನು 5 ನಿಮಿಷಗಳಲ್ಲಿ ಸ್ವಯಂಚಾಲಿತವಾಗಿ ಸೇರಿಸಲಾಗುತ್ತದೆ.",
                },
                {
                    'id': '5725a680b3249770ea31de84',
                    'mobalitem': "ಠೇವಣಿ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ ಯಾವುದೇ ವಿಳಂಬವನ್ನು ತಪ್ಪಿಸಲು, ಎಲ್ಲಾ ಮಾಹಿತಿಯನ್ನು ಸರಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆಯೆ ಎಂದು ದಯೆಯಿಂದ ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de85',
                    'mobalitem':"ನೀವು ಯಾವುದೇ ಪ್ರಶ್ನೆಗಳನ್ನು ಹೊಂದಿದ್ದರೆ ದಯವಿಟ್ಟು ಗ್ರಾಹಕ ಸೇವೆಯನ್ನು ಸಂಪರ್ಕಿಸಲು ಮುಕ್ತವಾಗಿರಿ.",
                }, 
            ], 
            'guidebtn':"ರೂಪಾಯಿ ಮಾರ್ಗದರ್ಶಿ",
            'bankname': "ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಆಯ್ಕೆಮಾಡಿ",
            'bankoptions':[
                {
                    'id' : '5725a680b3249770ea31de86',
                    'bankoptions':"ಎಸ್‌ಬಿಐ",
                },
                {
                    'id' : '5725a680b3249770ea31de87',
                    'bankoptions':"ಎಚ್‌ಡಿಎಫ್‌ಸಿ",
                },
                {
                    'id' : '5725a680b3249770ea31de88',
                    'bankoptions':"ಐಸಿಐಸಿಐ",
                },
                {
                    'id' : '5725a680b3249770ea31de89',
                    'bankoptions':"ಪಿಎನ್‌ಬಿ",
                },
            ], 

            'amountheading': "ಠೇವಣಿಗೆ ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ",
            'amountoption': "ನಿಮ್ಮ ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ",
            'depositbtn': "ಠೇವಣಿ",
            'tableheading': "ರೂಪಾಯಿ ಮಿತಿ", 
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea31de90',
                    'tableth':"ಪ್ರತಿ ವಹಿವಾಟಿಗೆ ಕನಿಷ್ಠ ಠೇವಣಿ", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea31de91',
                    'tableth':"ಪ್ರತಿ ವಹಿವಾಟಿಗೆ ಗರಿಷ್ಠ ಠೇವಣಿ", 
                    'tabletd':"INR 20,000.00",
                },
            ],
            'tablenote':"ಸೂಚನೆ: ಠೇವಣಿಗಳನ್ನು 1x ವೇಜರಿಂಗ್ ಅವಶ್ಯಕತೆಗೆ ಒಳಪಡಿಸಲಾಗುತ್ತದೆ.",
            
            'select_pay_by':"ಇವರಿಂದ ಠೇವಣಿ ಇರಿಸಿ",
            'receiver_bank_account':"ಸ್ವೀಕರಿಸುವವರ ಬ್ಯಾಂಕ್ ಖಾತೆ",
            'sender_bank_account':"ಕಳುಹಿಸುವವರ ಬ್ಯಾಂಕ್ ಖಾತೆ",
            'payment_mode':"ಪಾವತಿ ಮೋಡ್",
            'trasationid':"ವಹಿವಾಟು ಐಡಿ (ಉಲ್ಲೇಖ ಸಂಖ್ಯೆ)",
            'amount':"ಮೊತ್ತ",
            'confirm_amount':"ಮೊತ್ತವನ್ನು ದೃ irm ೀಕರಿಸಿ",
            'comment':"ಕಾಮೆಂಟ್ ಮಾಡಿ",
            'receipt_upload':"ರಶೀದಿ ಅಪ್‌ಲೋಡ್",
            'depositbtns':"ಠೇವಣಿ",
        },
      
        {
            'id' : '5725a680b3249770ea31de92',
            'title':"ಠೇವಣಿ ಸ್ಥಿತಿ",
            'tagName': 'tab2',
            'mobalbtn':"ನಾನು ಕ್ರೆಡಿಟ್ ಠೇವಣಿ ಮಾಡುವುದು ಹೇಗೆ?",
            'mobalheading':"ನಾನು ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಠೇವಣಿ ಮಾಡುವುದು ಹೇಗೆ?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de93',
                    'mobalitem':"ನಿಮ್ಮ ಐಸಾಫ್ಟ್ ಗೇಮಿಂಗ್ ಖಾತೆಗೆ ಲಾಗ್ ಇನ್ ಮಾಡಿ.",
                },  

                {
                    'id': '5725a680b3249770ea31de94',
                    'mobalitem': "ನಿಮ್ಮ ಬಳಕೆದಾರಹೆಸರು ಬಳಿಯ ಡ್ರಾಪ್‌ಡೌನ್ ಬಾಣದಲ್ಲಿರುವ“ ಠೇವಣಿ ”ಬಟನ್ ಕ್ಲಿಕ್ ಮಾಡಿ.",
                },
                {
                    'id': '5725a680b3249770ea31de95',
                    'mobalitem': "ಠೇವಣಿ ಟ್ಯಾಬ್ ಒಳಗೆ, ನಿಮ್ಮ ಆದ್ಯತೆಯ ಠೇವಣಿ ವಿಧಾನವಾಗಿ“ ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ”ಆಯ್ಕೆಮಾಡಿ.",
                },
                {
                    'id': '5725a680b3249770ea31de96',
                    'mobalitem': "ಕಾರ್ಡ್ ಸಂಖ್ಯೆ, ಸಿವಿವಿ ಸಂಖ್ಯೆ ಮತ್ತು ಮುಕ್ತಾಯ ದಿನಾಂಕ ಸೇರಿದಂತೆ ನಿಮ್ಮ ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಮಾಹಿತಿಯನ್ನು ನಮೂದಿಸಿ.",
                },
                {
                    'id': '5725a680b3249770ea31de97',
                    'mobalitem': "ಠೇವಣಿ ಮೊತ್ತದ ಖಾಲಿ ಕ್ಷೇತ್ರದಲ್ಲಿ ನಿಮ್ಮ ಅಪೇಕ್ಷಿತ ಪಾವತಿಯನ್ನು ಟೈಪ್ ಮಾಡಿ.",
                },
                {
                    'id': '5725a680b3249770ea31de98',
                    'mobalitem': "ನಿಮ್ಮ ಠೇವಣಿಯನ್ನು ದೃ to ೀಕರಿಸಲು ಪುಟದ ಕೊನೆಯಲ್ಲಿರುವ' ಠೇವಣಿ 'ಗುಂಡಿಯನ್ನು ಕ್ಲಿಕ್ ಮಾಡಿ.",
                },
                {
                    'id': '5725a680b3249770ea31de99',
                    'mobalitem': "ನೀವು ನಂತರ ಠೇವಣಿ ದೃ mation ೀಕರಣವನ್ನು ಸ್ವೀಕರಿಸುತ್ತೀರಿ.",
                },
            ], 
            'card_cardnumber':"#### #### #### ####",  
            'card_date_head': "ಅವಧಿ ಮೀರುತ್ತದೆ",
            'card_date': "MM / YY",
            'card_cvv_head': "CVV",
            'card_cvv': "####",
            'p_tag_first': "ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್‌ಗಾಗಿ ಮಾತ್ರ ವಿಶೇಷ ಬಳಕೆ. ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ವಿವರಗಳನ್ನು ಸೇರಿಸಬೇಡಿ.",
            'p_tag_a': "ಡೌನ್‌ಲೋಡ್",
            'p_tag_second': "ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಖರೀದಿಸಲು ಕ್ರೆಡಿಟ್ ಅಪ್ಲಿಕೇಶನ್.",
            'card_number': "ಕಾರ್ಡ್ ಸಂಖ್ಯೆ",
            'expiry_date': "ಮುಕ್ತಾಯ ದಿನಾಂಕ",
            'cvv': "ಸಿವಿವಿ",
            'deposit_amount': "ಠೇವಣಿ ಮೊತ್ತ",
            'deposit_btn': "ಠೇವಣಿ",
            'tableheading': "ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಮಿತಿ", 

            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de01',
                    'tableth':"ಪ್ರತಿ ವಹಿವಾಟಿಗೆ ಕನಿಷ್ಠ ಠೇವಣಿ", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de02',
                    'tableth':"ಪ್ರತಿ ವಹಿವಾಟಿಗೆ ಗರಿಷ್ಠ ಠೇವಣಿ", 
                    'tabletd':"INR 99,000.00",
                },
            ],
            'tablenote':"ಸೂಚನೆ: ಠೇವಣಿಗಳನ್ನು 1x ವೇಜರಿಂಗ್ ಅವಶ್ಯಕತೆಗೆ ಒಳಪಡಿಸಲಾಗುತ್ತದೆ.",
            'payingtpe':'ಠೇವಣಿ ಪ್ರಕಾರ',
            'period':'ಅವಧಿ',
            'startdate':'ಪ್ರಾರಂಭ ದಿನಾಂಕ',
            'enddate':'ಅಂತಿಮ ದಿನಾಂಕ',
        },
        
      
        {
            'id' : '5725a680b3249770ea31de92',
            'title':"ಆನ್ಲೈನ್ ಪಾವತಿ",
            'tagName': 'tab3',
            'mobalbtn':"ನಾನು ಕ್ರೆಡಿಟ್ ಠೇವಣಿ ಮಾಡುವುದು ಹೇಗೆ?",
            'mobalheading':"ನಾನು ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಠೇವಣಿ ಮಾಡುವುದು ಹೇಗೆ?",
            'deposit_btn': "ಠೇವಣಿ",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de93',
                    'mobalitem':"ನಿಮ್ಮ ಐಸಾಫ್ಟ್ ಗೇಮಿಂಗ್ ಖಾತೆಗೆ ಲಾಗ್ ಇನ್ ಮಾಡಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de94',
                    'mobalitem':"ನಿಮ್ಮ ಬಳಕೆದಾರಹೆಸರು ಬಳಿಯ ಡ್ರಾಪ್‌ಡೌನ್ ಬಾಣದಲ್ಲಿರುವ “ಠೇವಣಿ” ಬಟನ್ ಕ್ಲಿಕ್ ಮಾಡಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de95',
                    'mobalitem':"ಠೇವಣಿ ಟ್ಯಾಬ್ ಒಳಗೆ, ನಿಮ್ಮ ಆದ್ಯತೆಯ ಠೇವಣಿ ವಿಧಾನವಾಗಿ “ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್” ಆಯ್ಕೆಮಾಡಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de96',
                    'mobalitem':"ಕಾರ್ಡ್ ಸಂಖ್ಯೆ, ಸಿವಿವಿ ಸಂಖ್ಯೆ ಮತ್ತು ಮುಕ್ತಾಯ ದಿನಾಂಕ ಸೇರಿದಂತೆ ನಿಮ್ಮ ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಮಾಹಿತಿಯನ್ನು ನಮೂದಿಸಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de97',
                    'mobalitem':"ಠೇವಣಿ ಮೊತ್ತದ ಖಾಲಿ ಕ್ಷೇತ್ರದಲ್ಲಿ ನಿಮ್ಮ ಅಪೇಕ್ಷಿತ ಪಾವತಿಯನ್ನು ಟೈಪ್ ಮಾಡಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de98',
                    'mobalitem':"ನಿಮ್ಮ ಠೇವಣಿಯನ್ನು ದೃ to ೀಕರಿಸಲು ಪುಟದ ಕೊನೆಯಲ್ಲಿರುವ “ಠೇವಣಿ” ಬಟನ್ ಕ್ಲಿಕ್ ಮಾಡಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de99',
                    'mobalitem':"ನೀವು ನಂತರ ಠೇವಣಿ ದೃ mation ೀಕರಣವನ್ನು ಸ್ವೀಕರಿಸುತ್ತೀರಿ.",
                },
            ], 
            'card_cardnumber':"#### #### #### ####",
            'card_date_head':"ಅವಧಿ ಮೀರುತ್ತದೆ",
            'card_date':"MM/YY",
            'card_cvv_head':"CVV",
            'card_cvv':"####",  
            'p_tag_first': "ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್‌ಗಾಗಿ ಮಾತ್ರ ವಿಶೇಷ ಬಳಕೆ. ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ವಿವರಗಳನ್ನು ಸೇರಿಸಬೇಡಿ.",
            'p_tag_a': "ಡೌನ್‌ಲೋಡ್",
            'p_tag_second': "ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಖರೀದಿಸಲು ಕ್ರೆಡಿಟ್ ಅಪ್ಲಿಕೇಶನ್.",
            'card_number': "ಕಾರ್ಡ್ ಸಂಖ್ಯೆ",
            'expiry_date': "ಮುಕ್ತಾಯ ದಿನಾಂಕ",
            'cvv': "ಸಿವಿವಿ",
            'deposit_amount': "ಠೇವಣಿ ಮೊತ್ತ",
            'deposit_btn': "ಠೇವಣಿ",
            'tableheading': "ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಮಿತಿ",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de01',
                    'tableth':"ಪ್ರತಿ ವಹಿವಾಟಿಗೆ ಕನಿಷ್ಠ ಠೇವಣಿ", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de02',
                    'tableth':"ಪ್ರತಿ ವಹಿವಾಟಿಗೆ ಗರಿಷ್ಠ ಠೇವಣಿ", 
                    'tabletd':"INR 99,000.00",
                },
            ],
            'tablenote':"ಸೂಚನೆ: ಠೇವಣಿಗಳನ್ನು 1x ವೇಜರಿಂಗ್ ಅವಶ್ಯಕತೆಗೆ ಒಳಪಡಿಸಲಾಗುತ್ತದೆ.",
            'amount':'ಮೊತ್ತ',
            'confirmamount':'ಮೊತ್ತವನ್ನು ದೃ irm ೀಕರಿಸಿ',
            'comment':'ಕಾಮೆಂಟ್ ಮಾಡಿ',
        },
        {
            'id' : '5725a680b3249770ea31de92',
            'title':"ಇ-ವಾಲೆಟ್",
            'tagName': 'tab4',
            'mobalbtn':"ನಾನು ಕ್ರೆಡಿಟ್ ಠೇವಣಿ ಮಾಡುವುದು ಹೇಗೆ?",
            'mobalheading':"ನಾನು ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಠೇವಣಿ ಮಾಡುವುದು ಹೇಗೆ?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de93',
                    'mobalitem':"ನಿಮ್ಮ ಐಸಾಫ್ಟ್ ಗೇಮಿಂಗ್ ಖಾತೆಗೆ ಲಾಗ್ ಇನ್ ಮಾಡಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de94',
                    'mobalitem':"ನಿಮ್ಮ ಬಳಕೆದಾರಹೆಸರು ಬಳಿಯ ಡ್ರಾಪ್‌ಡೌನ್ ಬಾಣದಲ್ಲಿರುವ “ಠೇವಣಿ” ಬಟನ್ ಕ್ಲಿಕ್ ಮಾಡಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de95',
                    'mobalitem':"ಠೇವಣಿ ಟ್ಯಾಬ್ ಒಳಗೆ, ನಿಮ್ಮ ಆದ್ಯತೆಯ ಠೇವಣಿ ವಿಧಾನವಾಗಿ “ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್” ಆಯ್ಕೆಮಾಡಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de96',
                    'mobalitem':"ಕಾರ್ಡ್ ಸಂಖ್ಯೆ, ಸಿವಿವಿ ಸಂಖ್ಯೆ ಮತ್ತು ಮುಕ್ತಾಯ ದಿನಾಂಕ ಸೇರಿದಂತೆ ನಿಮ್ಮ ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಮಾಹಿತಿಯನ್ನು ನಮೂದಿಸಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de97',
                    'mobalitem':"ಠೇವಣಿ ಮೊತ್ತದ ಖಾಲಿ ಕ್ಷೇತ್ರದಲ್ಲಿ ನಿಮ್ಮ ಅಪೇಕ್ಷಿತ ಪಾವತಿಯನ್ನು ಟೈಪ್ ಮಾಡಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de98',
                    'mobalitem':"ನಿಮ್ಮ ಠೇವಣಿಯನ್ನು ದೃ to ೀಕರಿಸಲು ಪುಟದ ಕೊನೆಯಲ್ಲಿರುವ “ಠೇವಣಿ” ಬಟನ್ ಕ್ಲಿಕ್ ಮಾಡಿ.",
                },
                {
                    'id' : '5725a680b3249770ea31de99',
                    'mobalitem':"ನೀವು ನಂತರ ಠೇವಣಿ ದೃ mation ೀಕರಣವನ್ನು ಸ್ವೀಕರಿಸುತ್ತೀರಿ.",
                },
            ], 
            'card_cardnumber':"#### #### #### ####",
            'card_date_head':"ಅವಧಿ ಮೀರುತ್ತದೆ",
            'card_date':"MM/YY",
            'card_cvv_head':"CVV",
            'card_cvv':"####",  
            'p_tag_first': "ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್‌ಗಾಗಿ ಮಾತ್ರ ವಿಶೇಷ ಬಳಕೆ. ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ವಿವರಗಳನ್ನು ಸೇರಿಸಬೇಡಿ.",
            'p_tag_a': "ಡೌನ್‌ಲೋಡ್",
            'p_tag_second': "ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಖರೀದಿಸಲು ಕ್ರೆಡಿಟ್ ಅಪ್ಲಿಕೇಶನ್.",
            'card_number': "ಕಾರ್ಡ್ ಸಂಖ್ಯೆ",
            'expiry_date': "ಮುಕ್ತಾಯ ದಿನಾಂಕ",
            'cvv': "ಸಿವಿವಿ",
            'deposit_amount': "ಠೇವಣಿ ಮೊತ್ತ",
            'deposit_btn': "ಠೇವಣಿ",
            'tableheading': "ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಮಿತಿ",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de01',
                    'tableth':"ಪ್ರತಿ ವಹಿವಾಟಿಗೆ ಕನಿಷ್ಠ ಠೇವಣಿ", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de02',
                    'tableth':"ಪ್ರತಿ ವಹಿವಾಟಿಗೆ ಗರಿಷ್ಠ ಠೇವಣಿ", 
                    'tabletd':"INR 99,000.00",
                },
            ],  
            'tablenote': "ಸೂಚನೆ: ಠೇವಣಿಗಳನ್ನು 1x ವೇಜಿಂಗ್ ಅವಶ್ಯಕತೆಗೆ ಒಳಪಡಿಸಲಾಗುತ್ತದೆ.",
            'transferedphonenumber': "ಫೋನ್ ಸಂಖ್ಯೆಗೆ",
            'senderphonenumber': "ದೂರವಾಣಿ ಸಂಖ್ಯೆಯಿಂದ",
            'trasationid': "ವಹಿವಾಟು ಐಡಿ",
            'amount': "ಮೊತ್ತ",
            'confirm_amount': "ಮೊತ್ತವನ್ನು ದೃ irm ೀಕರಿಸಿ",
            'comment': "ಕಾಮೆಂಟ್",
            'receipt_upload': "ರಶೀದಿ ಅಪ್‌ಲೋಡ್",
            'depositbtns': "ಠೇವಣಿ",
        },
        
    ],
    ne: [
        {
            'id' : '5725a680b3249770ea31de79',
            'title':"जम्मा गर्नुहोस्",
            'tagName': 'tab1',
            'mobalbtn':"म कसरी रूपैयाँ जम्मा गर्न सक्छु?",
            'mobalheading':"म कसरी पैसा जम्मा गर्न सक्छु?",
            'paybyoptions':[
                {
                    'id': "1,2,3,4,5,6,7,8",
                    'name': 'जम्मा को सबै प्रकारहरु',
                    'xs': 1
                },
                {
                    'id': "1",
                    'name': 'बैंकमा बैंक',
                    'xs': 1
                },
                {
                    'id': "2",
                    'name': 'नगद जम्मा',
                    'xs': 1
                },
                {
                    'id': "8",
                    'name': 'PayPal',
                    'xs': 1
                },
                {
                    'id': "4",
                    'name': 'PayTM',
                    'xs': 1
                },
                {
                    'id': "5",
                    'name': 'फोन भुक्तान',
                    'xs': 1
                },
                {
                    'id': "6",
                    'name': 'गुगल भुक्तान',
                    'xs': 1
                },
                {
                    'id': "7",
                    'name': 'अन्य भुक्तान',
                    'xs': 1
                }
            ],
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"पछिल्लो Day दिन", 
                },
                { 
                    'id':"2", 
                    'periodoption':"पछिल्लो १ महिना",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"पछिल्लो Mon महिना",
                },  
            ], 
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 1,
                    'field_name': 'अनुरोध संख्या',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'अनुरोधको प्रकार',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 1,
                    'field_name': 'पठाउनुहोस्',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'बाट पठाउनुहोस्',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'रकम',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': 'सन्दर्भ आईडी',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 2,
                    'field_name': 'अनुरोध गर्नुहोस्',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_8',
                    'xs': 1,
                    'field_name': 'स्थिति',
                    'visible': 'desktop',
                    'expandable': true
                },
            ],
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de80',
                    'mobalitem':"तपाईको सफ्ट गेमि account खाताको 'निक्षेप' पछाडि, रूपी चयन गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de81',
                    'mobalitem':"तपाई कुन बैंक बाट स्थानान्तरण गर्न चाहानुहुन्छ र यसको रकम छान्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de82',
                    'mobalitem':"तपाइँलाई बैंक पृष्ठमा रिडिरेक्ट गरिनेछ। पृष्ठमा निर्देशनहरू पालना गर्नुहोस् र लेनदेन पूर्ण गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de83',
                    'mobalitem':"सफल लेनदेन पछि, तपाईंको क्रेडिट 5 मिनेट भित्रमा स्वचालित रूपमा थपिने छ।",
                },
                {
                    'id' : '5725a680b3249770ea31de84',
                    'mobalitem':"जम्मा प्रक्रियामा कुनै ढिलाइबाट बच्न, कृपया सबै जानकारी ठीकसँग पेश गरिएको सुनिश्चित गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de85',
                    'mobalitem':"कृपया तपाईंसँग कुनै प्रश्नहरू छन् भने कृपया ग्राहक सेवामा सम्पर्क गर्न नहिचकिचाउनुहोस्।",
                }, 
            ], 
            'guidebtn':"रुपैया गाइड",
            'bankname': "तपाईंको बैंक चयन गर्नुहोस्",
            'bankoptions':[
                {
                    'id' : '5725a680b3249770ea31de86',
                    'bankoptions':"एसबीआई",
                },
                {
                    'id' : '5725a680b3249770ea31de87',
                    'bankoptions':"HDFC",
                },
                {
                    'id' : '5725a680b3249770ea31de88',
                    'bankoptions':"आईसीआईसीआई",
                },
                {
                    'id' : '5725a680b3249770ea31de89',
                    'bankoptions':"PNB",
                },
            ], 
            'amountheading': "जम्मा गर्न रकम प्रविष्ट गर्नुहोस्",
            'amountoption': "तपाइँको रकम प्रविष्ट गर्नुहोस्",
            'depositbtn': "जम्मा",
            'tableheading': "रुपैयाँ सीमा",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea31de90',
                    'tableth':"प्रति लेनदेन न्यूनतम निक्षेप", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea31de91',
                    'tableth':"प्रति लेनदेन अधिकतम जम्मा", 
                    'tabletd':"INR 20,000.00",
                },
            ],
            'tablenote':"नोट: निक्षेपहरू १x wagering आवश्यकताको अधीनमा छन्।",
            
            'select_pay_by':"जम्मा गर्नुहोस्",
            'receiver_bank_account':"प्राप्तकर्ता बैंक खाता",
            'sender_bank_account':"प्रेषक बैंक खाता",
            'payment_mode':"भुक्तानी मोड",
            'trasationid':"लेनदेन आईडी (सन्दर्भ संख्या)",
            'amount':"रकम",
            'confirm_amount':"रकम पुष्टि गर्नुहोस्",
            'comment':"टिप्पणी",
            'receipt_upload':"रसिद अपलोड",
            'depositbtns':"निक्षेप",
        },
      
        {
            'id' : '5725a680b3249770ea31de92',
            'title':"जम्मा स्थिति",
            'tagName': 'tab2',
            'mobalbtn':"म कसरी क्रेडिट जम्मा गर्न सक्छु?",
            'mobalheading':"म कसरी क्रेडिट कार्ड जम्मा गर्न सक्छु?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de93',
                    'mobalitem':"तपाईको आईसोफ्ट गेमि खातामा लग इन गर्नुहोस्।।",
                },
                {
                    'id' : '5725a680b3249770ea31de94',
                    'mobalitem':"तपाईंको प्रयोगकर्ता नामको नजिक ड्रपडाउन एर्रोमा 'जम्मा' बटन क्लिक गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de95',
                    'mobalitem':"निक्षेप ट्याब भित्र, चयन गर्नुहोस् 'क्रेडिट कार्ड' तपाईंको मनपर्ने जम्मा विधिको रूपमा।",
                },
                {
                    'id' : '5725a680b3249770ea31de96',
                    'mobalitem':"कार्ड नम्बर, CVV नम्बर र समयावधि मिति सहित तपाईंको क्रेडिट कार्ड जानकारी प्रविष्ट गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de97',
                    'mobalitem':"निक्षेप रकमको खाली फिल्डमा तपाईंको इच्छित भुक्तानी रकम टाइप गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de98',
                    'mobalitem':"तपाईंको जम्मा राशि पुष्टि गर्न पृष्ठको अन्तमा 'जम्मा गर्नुहोस्' बटन क्लिक गर्नुहोस्",
                },
                {
                    'id' : '5725a680b3249770ea31de99',
                    'mobalitem':"तपाईं पछि जम्मा पुष्टि प्राप्त गर्नुहुनेछ।",
                },
            ], 
            'card_cardnumber':"#### #### #### ####",  
            'card_date_head': "म्याद सकिन्छ",
            'card_date': "MM / YY",
            'card_cvv_head': "CVV",
            'card_cvv': "####",
            'p_tag_first': "क्रेडिट कार्डको लागि मात्र विशेष प्रयोग। क्रेडिट कार्ड विवरण घुसाउनुहोस्।",
            'p_tag_a': "डाउनलोड",
            'p_tag_second': "क्रेडिट कार्ड खरीद गर्न क्रेडिट अनुप्रयोग।",
            'card_number': "कार्ड नम्बर",
            'expiry_date': "समाप्ति मिति",
            'cvv': "CVV",
            'deposit_amount': "जम्मा रकम",
            'deposit_btn': "जम्मा",
            'tableheading': "क्रेडिट कार्ड सीमा",  
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de01',
                    'tableth':"प्रति लेनदेन न्यूनतम निक्षेप", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de02',
                    'tableth':"प्रति लेनदेन अधिकतम जम्मा", 
                    'tabletd':"INR 99,000.00",
                },
            ],
            'tablenote':"नोट: निक्षेपहरू १x wagering आवश्यकताको अधीनमा छन्।",
            'payingtpe':'निक्षेप प्रकार',
            'period':'अवधि',
            'startdate':'सुरू मिति',
            'enddate':'अन्त्य मिति',
        },
        
      
        {
            'id' : '5725a680b3249770ea31de92',
            'title':"अनलाइन भुक्तानी",
            'tagName': 'tab3',
            'mobalbtn':"म कसरी क्रेडिट जम्मा गर्न सक्छु?",
            'mobalheading':"म कसरी क्रेडिट कार्ड जम्मा गर्न सक्छु?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de93',
                    'mobalitem':"तपाईको आईसोफ्ट गेमि खातामा लग इन गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de94',
                    'mobalitem':"तपाईंको प्रयोगकर्ता नामको नजिक ड्रपडाउन एर्रोमा 'जम्मा' बटन क्लिक गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de95',
                    'mobalitem':"निक्षेप ट्याब भित्र, चयन गर्नुहोस् 'क्रेडिट कार्ड' तपाईंको मनपर्ने जम्मा विधिको रूपमा।",
                },
                {
                    'id' : '5725a680b3249770ea31de96',
                    'mobalitem':"कार्ड नम्बर, CVV नम्बर र समयावधि मिति सहित तपाईंको क्रेडिट कार्ड जानकारी प्रविष्ट गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de97',
                    'mobalitem':"निक्षेप रकमको खाली फिल्डमा तपाईंको इच्छित भुक्तानी रकम टाइप गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de98',
                    'mobalitem':"तपाईंको जम्मा राशि पुष्टि गर्न पृष्ठको अन्तमा 'जम्मा गर्नुहोस्' बटन क्लिक गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de99',
                    'mobalitem':"तपाईं पछि जम्मा पुष्टि प्राप्त गर्नुहुनेछ।",
                },
            ], 
            'card_cardnumber':"#### #### #### ####",
            'card_date_head':"म्याद सकिन्छ",
            'card_date':"MM/YY",
            'card_cvv_head':"CVV",
            'card_cvv':"####",  
            'p_tag_first': "क्रेडिट कार्डको लागि मात्र विशेष प्रयोग। क्रेडिट कार्ड विवरण घुसाउनुहोस्।",
            'p_tag_a': "डाउनलोड",
            'p_tag_second': "क्रेडिट कार्ड खरीद गर्न क्रेडिट अनुप्रयोग।",
            'card_number': "कार्ड नम्बर",
            'expiry_date': "समाप्ति मिति",
            'cvv': "CVV",
            'deposit_amount': "जम्मा रकम",
            'deposit_btn': "जम्मा",
            'tableheading': "क्रेडिट कार्ड सीमा",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de01',
                    'tableth':"प्रति लेनदेन न्यूनतम निक्षेप", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de02',
                    'tableth':"प्रति लेनदेन अधिकतम जम्मा", 
                    'tabletd':"INR 99,000.00",
                },
            ],
            'tablenote':"नोट: निक्षेपहरू १x wagering आवश्यकताको अधीनमा छन्।",
            'amount':'रकम',
            'confirmamount':'रकम पुष्टि गर्नुहोस्',
            'comment':'टिप्पणी',
        },
        {
            'id' : '5725a680b3249770ea31de92',
            'title':"E-Wallet",
            'tagName': 'tab4',
            'mobalbtn':"म कसरी क्रेडिट जम्मा गर्न सक्छु?",
            'mobalheading':"म कसरी क्रेडिट कार्ड जम्मा गर्न सक्छु?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de93',
                    'mobalitem':"तपाईको आईसोफ्ट गेमि खातामा लग इन गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de94',
                    'mobalitem':"तपाईंको प्रयोगकर्ता नामको नजिक ड्रपडाउन एर्रोमा 'जम्मा' बटन क्लिक गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de95',
                    'mobalitem':"निक्षेप ट्याब भित्र, चयन गर्नुहोस् 'क्रेडिट कार्ड' तपाईंको मनपर्ने जम्मा विधिको रूपमा।",
                },
                {
                    'id' : '5725a680b3249770ea31de96',
                    'mobalitem':"कार्ड नम्बर, CVV नम्बर र समयावधि मिति सहित तपाईंको क्रेडिट कार्ड जानकारी प्रविष्ट गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de97',
                    'mobalitem':"निक्षेप रकमको खाली फिल्डमा तपाईंको इच्छित भुक्तानी रकम टाइप गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de98',
                    'mobalitem':"तपाईंको जम्मा राशि पुष्टि गर्न पृष्ठको अन्तमा 'जम्मा गर्नुहोस्' बटन क्लिक गर्नुहोस्।",
                },
                {
                    'id' : '5725a680b3249770ea31de99',
                    'mobalitem':"तपाईं पछि जम्मा पुष्टि प्राप्त गर्नुहुनेछ।",
                },
            ], 
            'card_cardnumber':"#### #### #### ####",
            'card_date_head':"म्याद सकिन्छ",
            'card_date':"MM/YY",
            'card_cvv_head':"CVV",
            'card_cvv':"####",  
            'p_tag_first': "क्रेडिट कार्डको लागि मात्र विशेष प्रयोग। क्रेडिट कार्ड विवरण घुसाउनुहोस्।",
            'p_tag_a': "डाउनलोड",
            'p_tag_second': "क्रेडिट कार्ड खरीद गर्न क्रेडिट अनुप्रयोग।",
            'card_number': "कार्ड नम्बर",
            'expiry_date': "समाप्ति मिति",
            'cvv': "CVV",
            'deposit_amount': "जम्मा रकम",
            'deposit_btn': "जम्मा",
            'tableheading': "क्रेडिट कार्ड सीमा",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de01',
                    'tableth':"प्रति लेनदेन न्यूनतम निक्षेप", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de02',
                    'tableth':"प्रति लेनदेन अधिकतम जम्मा", 
                    'tabletd':"INR 99,000.00",
                },
            ],
            'tablenote':"नोट: निक्षेपहरू १x wagering आवश्यकताको अधीनमा छन्।",
            'transferedphonenumber':"फोन नम्बरमा",
            'senderphonenumber':"फोन नम्बरबाट", 
            'trasationid':"कारोबार आईडी ",
            'amount':"रकम",
            'confirm_amount':"रकम पुष्टि गर्नुहोस्",
            'comment':"टिप्पणी",
            'receipt_upload':"रसिद अपलोड",
            'depositbtns':"निक्षेप",
        },
        
    ],
    tl: [
        {
            'id' : '5725a680b3249770ea31de79',
            'title':"డిపాజిట్ చేయండి",
            'tagName': 'tab1',
            'mobalbtn':"నేను రూపాయి డిపాజిట్ ఎలా చేయాలి?",
            'mobalheading':"నేను డబ్బును ఎలా జమ చేయగలను?", 
            'paybyoptions':[
                {
                    'id': "1,2,3,4,5,6,7,8",
                    'name': 'ఆల్ టైప్ డిపాజిట్',
                    'xs': 1
                },
                {
                    'id': "1",
                    'name': 'బ్యాంకుకు బాంట్',
                    'xs': 1
                },
                {
                    'id': "2",
                    'name': 'నగదు జమచేయు',
                    'xs': 1
                },
                {
                    'id': "8",
                    'name': 'పేపాల్',
                    'xs': 1
                },
                {
                    'id': "4",
                    'name': 'పేటీఎం',
                    'xs': 1
                },
                {
                    'id': "5",
                    'name': 'ఫోన్ పే',
                    'xs': 1
                },
                {
                    'id': "6",
                    'name': 'గూగుల్ పే',
                    'xs': 1
                },
                {
                    'id': "7",
                    'name': 'ఇతర పే',
                    'xs': 1
                }
            ],
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"చివరి 7 రోజులు", 
                },
                { 
                    'id':"2", 
                    'periodoption':"చివరి 1 నెల",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"చివరి 6 నెలలు",
                },  
            ], 
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 1,
                    'field_name': 'అభ్యర్థన సంఖ్య',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'అభ్యర్థన రకం',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 1,
                    'field_name': 'పంపండి',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'నుండి పంపండి',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'మొత్తం',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': 'రిఫరెన్స్ ఐడి',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 2,
                    'field_name': 'అభ్యర్థించండి',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_8',
                    'xs': 1,
                    'field_name': 'స్థితి',
                    'visible': 'desktop',
                    'expandable': true
                },
            ],
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de80',
                    'mobalitem':"మీ ఐసాఫ్ట్ గేమింగ్ ఖాతా యొక్క “డిపాజిట్” టాబ్‌లో రూపాయి ఎంచుకోండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de81',
                    'mobalitem':"మీరు ఏ బ్యాంక్ నుండి బదిలీ చేయాలనుకుంటున్నారో మరియు దాని మొత్తాన్ని ఎంచుకోండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de82',
                    'mobalitem':"మీరు బ్యాంక్ పేజీకి మళ్ళించబడతారు. పేజీలోని సూచనలను అనుసరించండి మరియు లావాదేవీని పూర్తి చేయండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de83',
                    'mobalitem':"విజయవంతమైన లావాదేవీ తరువాత, మీ క్రెడిట్ 5 నిమిషాల్లో స్వయంచాలకంగా జోడించబడుతుంది.",
                },
                {
                    'id' : '5725a680b3249770ea31de84',
                    'mobalitem':"డిపాజిట్‌ను ప్రాసెస్ చేయడంలో జాప్యం జరగకుండా ఉండటానికి, అన్ని సమాచారం సరిగ్గా సమర్పించబడిందని నిర్ధారించుకోండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de85',
                    'mobalitem':"మీకు ఏవైనా ప్రశ్నలు ఉంటే కస్టమర్ సేవను సంప్రదించడానికి సంకోచించకండి.",
                }, 
            ], 
            'guidebtn':"Rupee Guide",
            'bankname': "మీ బ్యాంకును ఎంచుకోండి",
            'bankoptions':[
                {
                    'id' : '5725a680b3249770ea31de86',
                    'bankoptions':"ఎస్బిఐ",
                },
                {
                    'id' : '5725a680b3249770ea31de87',
                    'bankoptions':"HDFC",
                },
                {
                    'id' : '5725a680b3249770ea31de88',
                    'bankoptions':"ఐసిఐసిఐ",
                },
                {
                    'id' : '5725a680b3249770ea31de89',
                    'bankoptions':"పిఎన్‌బి",
                },
            ],
            'amountheading': "డిపాజిట్ చేయడానికి మొత్తాన్ని నమోదు చేయండి",
            'amountoption': "మీ మొత్తాన్ని నమోదు చేయండి",
            'depositbtn':"డిపాజిట్",
            'tableheading':"రూపాయి పరిమితి",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea31de90',
                    'tableth':"ప్రతి లావాదేవీకి కనీస డిపాజిట్", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea31de91',
                    'tableth':"ప్రతి లావాదేవీకి గరిష్ట డిపాజిట్", 
                    'tabletd':"INR 20,000.00",
                },
            ],
            'tablenote':"గమనిక: డిపాజిట్లు 1x పందెపు అవసరానికి లోబడి ఉంటాయి.",

            'select_pay_by':"ద్వారా చెల్లించండి",
            'receiver_bank_account':"స్వీకర్త బ్యాంక్ ఖాతా",
            'sender_bank_account':"పంపినవారి బ్యాంక్ ఖాతా",
            'payment_mode':"చెల్లింపు మోడ్",
            'trasationid':"పరివర్తన ఐడి (సూచన సంఖ్య)",
            'amount':"మొత్తం",
            'confirm_amount':"మొత్తాన్ని నిర్ధారించండి",
            'comment':"వ్యాఖ్య",
            'receipt_upload':"రసీదు అప్‌లోడ్",
            'depositbtns':"డిపాజిట్",
        },
      
        {
            'id' : '5725a680b3249770ea31de92',
            'title':"డిపాజిట్ స్థితి",
            'tagName': 'tab2',
            'mobalbtn':"నేను క్రెడిట్ డిపాజిట్ ఎలా చేయాలి?",
            'mobalheading':"నేను క్రెడిట్ కార్డ్ డిపాజిట్ ఎలా చేయాలి?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de93',
                    'mobalitem':"మీ ఐసోఫ్ట్ గేమింగ్ ఖాతాకు లాగిన్ అవ్వండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de94',
                    'mobalitem':"మీ వినియోగదారు పేరుకు సమీపంలో ఉన్న డ్రాప్‌డౌన్ బాణం వద్ద “డిపాజిట్” బటన్‌ను క్లిక్ చేయండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de95',
                    'mobalitem':"డిపాజిట్ టాబ్ లోపల, మీకు ఇష్టమైన డిపాజిట్ పద్ధతిగా “క్రెడిట్ కార్డ్” ఎంచుకోండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de96',
                    'mobalitem':"కార్డ్ నంబర్, సివివి నంబర్ మరియు గడువు తేదీతో సహా మీ క్రెడిట్ కార్డ్ సమాచారాన్ని నమోదు చేయండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de97',
                    'mobalitem':"డిపాజిట్ మొత్తం ఖాళీ ఫీల్డ్‌లో మీకు కావలసిన మొత్తాన్ని చెల్లించండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de98',
                    'mobalitem':"మీ డిపాజిట్‌ను నిర్ధారించడానికి పేజీ చివర “డిపాజిట్” బటన్‌పై క్లిక్ చేయండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de99',
                    'mobalitem':"మీరు తరువాత డిపాజిట్ నిర్ధారణను అందుకుంటారు.",
                },
            ],  
            'deposit_amount':" జమ చేయవలసిన రొక్కం",
            'deposit_btn':"డిపాజిట్",
            'tableheading':"క్రెడిట్ కార్డ్ పరిమితి",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de01',
                    'tableth':"ప్రతి లావాదేవీకి కనీస డిపాజిట్", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de02',
                    'tableth':"ప్రతి లావాదేవీకి గరిష్ట డిపాజిట్", 
                    'tabletd':"INR 99,000.00",
                },
            ],
            'tablenote':"గమనిక: డిపాజిట్లు 1x పందెపు అవసరానికి లోబడి ఉంటాయి.",
            'payingtpe':'చెల్లింపు రకం',
            'period':'కాలం',
            'startdate':'ప్రారంబపు తేది',
            'enddate':'చివరి తేది',
        },
        
      
        {
            'id' : '5725a680b3249770ea31de92',
            'title':"ఆన్లైన్ చెల్లింపు",
            'tagName': 'tab3',
            'mobalbtn':"నేను క్రెడిట్ డిపాజిట్ ఎలా చేయాలి?",
            'mobalheading':"నేను క్రెడిట్ కార్డ్ డిపాజిట్ ఎలా చేయాలి?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de93',
                    'mobalitem':"మీ ఐసోఫ్ట్ గేమింగ్ ఖాతాకు లాగిన్ అవ్వండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de94',
                    'mobalitem':"మీ వినియోగదారు పేరుకు సమీపంలో ఉన్న డ్రాప్‌డౌన్ బాణం వద్ద “డిపాజిట్” బటన్‌ను క్లిక్ చేయండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de95',
                    'mobalitem':"డిపాజిట్ టాబ్ లోపల, మీకు ఇష్టమైన డిపాజిట్ పద్ధతిగా “క్రెడిట్ కార్డ్” ఎంచుకోండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de96',
                    'mobalitem':"కార్డ్ నంబర్, సివివి నంబర్ మరియు గడువు తేదీతో సహా మీ క్రెడిట్ కార్డ్ సమాచారాన్ని నమోదు చేయండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de97',
                    'mobalitem':"డిపాజిట్ మొత్తం ఖాళీ ఫీల్డ్‌లో మీకు కావలసిన మొత్తాన్ని చెల్లించండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de98',
                    'mobalitem':"మీ డిపాజిట్‌ను నిర్ధారించడానికి పేజీ చివర “డిపాజిట్” బటన్‌పై క్లిక్ చేయండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de99',
                    'mobalitem':"మీరు తరువాత డిపాజిట్ నిర్ధారణను అందుకుంటారు.",
                },
            ],  
            'deposit_amount':"జమ చేయవలసిన రొక్కం",
            'deposit_btn':"డిపాజిట్",
            'tableheading':"క్రెడిట్ కార్డ్ పరిమితి",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de01',
                    'tableth':"ప్రతి లావాదేవీకి కనీస డిపాజిట్", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de02',
                    'tableth':"ప్రతి లావాదేవీకి గరిష్ట డిపాజిట్", 
                    'tabletd':"INR 99,000.00",
                },
            ],
            'tablenote':"గమనిక: డిపాజిట్లు 1x పందెపు అవసరానికి లోబడి ఉంటాయి.",
            'amount':'మొత్తం',
            'confirmamount':'మొత్తాన్ని నిర్ధారించండి',
            'comment':'వ్యాఖ్య',
        },
        {
            'id' : '5725a680b3249770ea31de92',
            'title':"ఇ-వాలెట్",
            'tagName': 'tab4',
            'mobalbtn':"నేను క్రెడిట్ డిపాజిట్ ఎలా చేయాలి?",
            'mobalheading':"నేను క్రెడిట్ కార్డ్ డిపాజిట్ ఎలా చేయాలి?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea31de93',
                    'mobalitem':"మీ ఐసోఫ్ట్ గేమింగ్ ఖాతాకు లాగిన్ అవ్వండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de94',
                    'mobalitem':"మీ వినియోగదారు పేరుకు సమీపంలో ఉన్న డ్రాప్‌డౌన్ బాణం వద్ద “డిపాజిట్” బటన్‌ను క్లిక్ చేయండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de95',
                    'mobalitem':"డిపాజిట్ టాబ్ లోపల, మీకు ఇష్టమైన డిపాజిట్ పద్ధతిగా “క్రెడిట్ కార్డ్” ఎంచుకోండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de96',
                    'mobalitem':"కార్డ్ నంబర్, సివివి నంబర్ మరియు గడువు తేదీతో సహా మీ క్రెడిట్ కార్డ్ సమాచారాన్ని నమోదు చేయండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de97',
                    'mobalitem':"డిపాజిట్ మొత్తం ఖాళీ ఫీల్డ్‌లో మీకు కావలసిన మొత్తాన్ని చెల్లించండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de98',
                    'mobalitem':"మీ డిపాజిట్‌ను నిర్ధారించడానికి పేజీ చివర “డిపాజిట్” బటన్‌పై క్లిక్ చేయండి.",
                },
                {
                    'id' : '5725a680b3249770ea31de99',
                    'mobalitem':"మీరు తరువాత డిపాజిట్ నిర్ధారణను అందుకుంటారు.",
                },
            ], 
            'card_cardnumber':"#### #### #### ####",
            'card_date_head':"ముగుస్తుంది",
            'card_date':"MM/YY",
            'card_cvv_head':"CVV",
            'card_cvv':"####",
            'p_tag_first':"క్రెడిట్ కార్డ్ కోసం మాత్రమే ప్రత్యేకమైన ఉపయోగం. క్రెడిట్ కార్డ్ వివరాలను చొప్పించవద్దు.",
            'p_tag_a':"డౌన్‌లోడ్",
            'p_tag_second':"క్రెడిట్ కార్డు కొనడానికి క్రెడిట్ అనువర్తనం.",
            'card_number':"కార్డు సంఖ్య",
            'expiry_date':"గడువు తీరు తేదీ",
            'cvv':"సివివి",
            'deposit_amount':"జమ చేయవలసిన రొక్కం",
            'deposit_btn':"డిపాజిట్",
            'tableheading':"క్రెడిట్ కార్డ్ పరిమితి",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de01',
                    'tableth':"ప్రతి లావాదేవీకి కనీస డిపాజిట్", 
                    'tabletd':"INR 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de02',
                    'tableth':"ప్రతి లావాదేవీకి గరిష్ట డిపాజిట్", 
                    'tabletd':"INR 99,000.00",
                },
            ],
            'tablenote':"గమనిక: డిపాజిట్లు 1x పందెపు అవసరానికి లోబడి ఉంటాయి.",
 
            'transferedphonenumber':"బదిలీ చేసిన ఫోన్ నంబర్",
            'senderphonenumber':"పంపినవారి ఫోన్ నంబర్", 
            'trasationid':"లావాదేవి ఐడి ",
            'amount':"మొత్తం",
            'confirm_amount':"మొత్తాన్ని నిర్ధారించండి",
            'comment':"వ్యాఖ్య",
            'receipt_upload':"రసీదు అప్‌లోడ్",
            'depositbtns':"డిపాజిట్",
        },
        
    ],
    hi: [
        {
            'id' : '5725a680b3249770ea32de19',
            'title':"जमा कर दो",
            'tagName': 'tab1',
            'mobalbtn':"मैं रुपया जमा कैसे करूँ?",
            'mobalheading':"मैं रुपया कैसे जमा कर सकता हूं?",
            'paybyoptions':[
                {
                    'id': "1,2,3,4,5,6,7,8",
                    'name': 'सभी प्रकार की जमा राशि',
                    'xs': 1
                },
                {
                    'id': "1",
                    'name': 'बैंक टू बैंक',
                    'xs': 1
                },
                {
                    'id': "2",
                    'name': 'नकद जमा',
                    'xs': 1
                },
                {
                    'id': "8",
                    'name': 'PayPal',
                    'xs': 1
                },
                {
                    'id': "4",
                    'name': 'PayTM',
                    'xs': 1
                },
                {
                    'id': "5",
                    'name': 'Phone Pay',
                    'xs': 1
                },
                {
                    'id': "6",
                    'name': 'Google Pay',
                    'xs': 1
                },
                {
                    'id': "7",
                    'name': 'अन्य वेतन',
                    'xs': 1
                }
            ],
            'periodoptions': [
                {
                    'id':"1", 
                    'periodoption':"पिछले सात दिन", 
                },
                { 
                    'id':"2", 
                    'periodoption':"पिछले एक महीने से",
                }, 
                { 
                    'id':"3", 
                    'periodoption':"पिछले 6 महीने",
                },  
            ],  
            'moballist':[
                {
                    'id' : '5725a680b3249770ea32de20',
                    'mobalitem':"अपने आइसॉफ्ट गेमिंग खाते के 'डिपॉजिट' टैब में, रुपे-ओ चुनें।",
                },
                {
                    'id' : '5725a680b3249770ea32de21',
                    'mobalitem':"आप किस बैंक से और उसकी राशि से ट्रांसफर करना चाहते हैं, उसे चुनें।",
                },
                {
                    'id' : '5725a680b3249770ea32de22',
                    'mobalitem':"आपको बैंक पृष्ठ पर पुनः निर्देशित किया जाएगा। पृष्ठ पर दिए गए निर्देशों का पालन करें और लेनदेन को पूरा करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de23',
                    'mobalitem':"एक सफल लेनदेन के बाद, आपका क्रेडिट 5 मिनट के भीतर स्वचालित रूप से जुड़ जाएगा।",
                },
                {
                    'id' : '5725a680b3249770ea32de24',
                    'mobalitem':"जमा को संसाधित करने में किसी भी देरी से बचने के लिए, कृपया सुनिश्चित करें कि सभी जानकारी सही ढंग से प्रस्तुत की गई है।",
                },
                {
                    'id' : '5725a680b3249770ea32de25',
                    'mobalitem':"यदि आपके कोई प्रश्न हैं, तो कृपया ग्राहक सेवा से संपर्क करें।",
                }
            ],
            'tableheader':[
                {
                    'id': 'stmhdr_1',
                    'xs': 1,
                    'field_name': 'Request Number',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_2',
                    'xs': 2,
                    'field_name': 'Type of Request',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_3',
                    'xs': 1,
                    'field_name': 'Send On',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_4',
                    'xs': 2,
                    'field_name': 'Send From',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_5',
                    'xs': 1,
                    'field_name': 'Amount',
                    'visible': 'both',
                },{
                    'id': 'stmhdr_6',
                    'xs': 2,
                    'field_name': 'Reference Id',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_7',
                    'xs': 2,
                    'field_name': 'Request On',
                    'visible': 'desktop',
                    'expandable': true
                },{
                    'id': 'stmhdr_8',
                    'xs': 1,
                    'field_name': 'Status',
                    'visible': 'desktop',
                    'expandable': true
                },
            ],
            'guidebtn':"रुपया गाइड",
            'bankname': "अपना बैंक चुनें",
            'bankoptions':[
                {
                    'id' : '5725a680b3249770ea32de26',
                    'bankoptions':"भारतीय स्टेट बैंक",
                },
                {
                    'id' : '5725a680b3249770ea32de27',
                    'bankoptions':"एचडीफसी",
                },
                {
                    'id' : '5725a680b3249770ea32de28',
                    'bankoptions':"आईसीआईसीआई",
                },
                {
                    'id' : '5725a680b3249770ea32de29',
                    'bankoptions':"पीएनबी",
                },
            ],
            'amountheading': "जमा करने के लिए राशि दर्ज करें",
            'amountoption': "अपनी राशि दर्ज करें",
            'depositbtn':"जमा",
            'tableheading':"रुपया सीमा",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de30',
                    'tableth':"प्रति लेनदेन न्यूनतम जमा", 
                    'tabletd':"रूपया 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de31',
                    'tableth':"प्रति लेनदेन अधिकतम जमा", 
                    'tabletd':"रूपया 20,000.00",
                },
            ],
            'tablenote':"नोट: जमाराशि 1x के लिए आवश्यक चेतावनी के अधीन हैं।",
            
            'select_pay_by':"द्वारा भुगतान",
            'receiver_bank_account':"प्राप्तकर्ता बैंक खाता",
            'sender_bank_account':"प्रेषक बैंक खाता",
            'payment_mode':"भुगतान का प्रकार",
            'trasationid':"परिवर्तन आईडी (संदर्भ संख्या)",
            'amount':"रकम",
            'confirm_amount':"राशि की पुष्टि करें",
            'comment':"टिप्पणी",
            'receipt_upload':"रसीद अपलोड",
            'depositbtns':"जमा",

        },
        {
            'id' : '5725a680b3249770ea32de32',
            'title':"जमा की स्थिति",
            'tagName': 'tab2',
            'mobalbtn':"मैं क्रेडिट जमा कैसे करूँ?",
            'mobalheading':"मैं क्रेडिट कार्ड जमा कैसे करूँ?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea32de33',
                    'mobalitem':"अपने आइसॉफ्ट गेमिंग खाते में प्रवेश करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de34',
                    'mobalitem':"अपने उपयोगकर्ता नाम के पास ड्रॉपडाउन तीर पर 'जमा' बटन पर क्लिक करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de35',
                    'mobalitem':"जमा टैब के अंदर, अपनी पसंदीदा जमा पद्धति के रूप में 'क्रेडिट कार्ड' चुनें।",
                },
                {
                    'id' : '5725a680b3249770ea32de36',
                    'mobalitem':"कार्ड नंबर, सीवीवी नंबर और समाप्ति तिथि सहित अपनी क्रेडिट कार्ड जानकारी दर्ज करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de37',
                    'mobalitem':"जमा राशि के खाली क्षेत्र में अपनी वांछित राशि का भुगतान करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de38',
                    'mobalitem':"अपनी जमा राशि की पुष्टि करने के लिए पृष्ठ के अंत में 'जमा' बटन पर क्लिक करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de39',
                    'mobalitem':"आपको बाद में जमा पुष्टिकरण प्राप्त होगा।",
                }
            ],
            'deposit_amount':"जमा राशि",
            'deposit_btn':"जमा",
            'tableheading':"क्रेडिट कार्ड की सीमा",
            'tableraw':[
                {
                    'id' : '5725a680b3249770ea32de40',
                    'tableth':"प्रति लेनदेन न्यूनतम जमा", 
                    'tabletd':"रूपया 1,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de41',
                    'tableth':"प्रति लेनदेन अधिकतम जमा", 
                    'tabletd':"रूपया 99,000.00",
                },
            ],
            'tablenote':"नोट: जमाराशि 1x के लिए आवश्यक चेतावनी के अधीन हैं।",
            'payingtpe':'भुगतान प्रकार',
            'period':'अवधि',
            'startdate':'आरंभ करने की तिथि',
            'enddate':'अंतिम तिथि',
        },
        {
            'id' : '5725a680b3249770ea32de42',
            'title':"ऑनलाइन भुगतान",
            'tagName': 'tab3',
           'mobalbtn':"मैं क्रेडिट जमा कैसे करूँ?",
            'mobalheading':"मैं क्रेडिट कार्ड जमा कैसे करूँ?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea32de43',
                    'mobalitem':"अपने आइसॉफ्ट गेमिंग खाते में लॉगिन करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de44',
                    'mobalitem':"पृष्ठ के दाईं ओर 'जमा / निकासी' बटन पर क्लिक करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de45',
                    'mobalitem':"चुनें कि आप किस ई-वॉलेट का उपयोग करना चाहते हैं (Skrill / Neteller)।",
                },
                {
                    'id' : '5725a680b3249770ea32de46',
                    'mobalitem':"वह राशि दर्ज करें जिसे आप जमा करना चाहते हैं और पृष्ठ के अंत में 'जमा' बटन पर क्लिक करें।,",
                },
                {
                    'id' : '5725a680b3249770ea32de47',
                    'mobalitem':"अपना बटुआ विवरण दर्ज करें और अपने लेनदेन को सत्यापित करें।",
                },
            ], 
            'deposit_amount':"जमा राशि",
            'deposit_btn':"जमा",
            'tableheading':"क्रेडिट कार्ड की सीमा",
            'ewallettexthead':"कृपया अपना पसंदीदा भुगतान प्रकार चुनें:",
            'tableheadingfirst':"SKRILL जमा सीमा",
            'tablerawfirst':[
                {
                    'id' : '5725a680b3249770ea32de48',
                    'tableth':"प्रति लेनदेन न्यूनतम जमा", 
                    'tabletd':"रूपया 2,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de49',
                    'tableth':"प्रति लेनदेन अधिकतम जमा", 
                    'tabletd':"रूपया 20,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de50',
                    'tableth':"कुल दैनिक जमा", 
                    'tabletd':"कोई सीमा नहीं",
                },
            ],
            'tablenotefirst':"नोट: जमाराशि 1x के लिए आवश्यक चेतावनी के अधीन हैं।",

            'paymentamohead':"भुगतान राशि:",
            'paymentamobtnoptions':[
                {
                    'id' : '5725a680b3249770ea32de51',
                    'paymentamooption':"2,000",
                },
                {
                    'id' : '5725a680b3249770ea32de52',
                    'paymentamooption':"4,500",
                },
                {
                    'id' : '5725a680b3249770ea32de53',
                    'paymentamooption':"7,100",
                },
                {
                    'id' : '5725a680b3249770ea32de54',
                    'paymentamooption':"9,700",
                },
            ],
            'paymentinput':"कस्टम राशि",
            'totalamohead':"कुल रकम:",
            'totalamotext':"INR 0.00",
            'depositbtn':"जमा",

            'tableheadingsecond':"NETELLER जमा सीमा",
            'tablerawsecond':[
                {
                    'id' : '5725a680b3249770ea32de55',
                    'tableth':"प्रति लेनदेन न्यूनतम जमा", 
                    'tabletd':"रूपया 2,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de56',
                    'tableth':"प्रति लेनदेन अधिकतम जमा", 
                    'tabletd':"रूपया 20,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de57',
                    'tableth':"कुल दैनिक जमा", 
                    'tabletd':"कोई सीमा नहीं",
                },
            ],
            'tablenotesecond':"नोट: जमाराशि 1x के लिए आवश्यक चेतावनी के अधीन हैं।",
             'amount':'रकम',
            'confirmamount':'राशि की पुष्टि करें',
            'comment':'टिप्पणी',
        },
        {
            'id' : '5725a680b3249770ea32de42',
            'title':"ई-वॉलेट",
            'tagName': 'tab4',
            'mobalbtn':"मैं ई-वॉलेट जमा कैसे कर सकता हूं?",
            'mobalheading':"मैं ई-वॉलेट जमा कैसे कर सकता हूं?",
            'moballist':[
                {
                    'id' : '5725a680b3249770ea32de43',
                    'mobalitem':"अपने आइसॉफ्ट गेमिंग खाते में लॉगिन करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de44',
                    'mobalitem':"पृष्ठ के दाईं ओर 'जमा / निकासी' बटन पर क्लिक करें।",
                },
                {
                    'id' : '5725a680b3249770ea32de45',
                    'mobalitem':"चुनें कि आप किस ई-वॉलेट का उपयोग करना चाहते हैं (Skrill / Neteller)।",
                },
                {
                    'id' : '5725a680b3249770ea32de46',
                    'mobalitem':"वह राशि दर्ज करें जिसे आप जमा करना चाहते हैं और पृष्ठ के अंत में 'जमा' बटन पर क्लिक करें।,",
                },
                {
                    'id' : '5725a680b3249770ea32de47',
                    'mobalitem':"अपना बटुआ विवरण दर्ज करें और अपने लेनदेन को सत्यापित करें।",
                },
            ], 
            'ewallettexthead':"कृपया अपना पसंदीदा भुगतान प्रकार चुनें:",
            'tableheadingfirst':"SKRILL जमा सीमा",
            'tablerawfirst':[
                {
                    'id' : '5725a680b3249770ea32de48',
                    'tableth':"प्रति लेनदेन न्यूनतम जमा", 
                    'tabletd':"रूपया 2,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de49',
                    'tableth':"प्रति लेनदेन अधिकतम जमा", 
                    'tabletd':"रूपया 20,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de50',
                    'tableth':"कुल दैनिक जमा", 
                    'tabletd':"कोई सीमा नहीं",
                },
            ],
            'tablenotefirst':"नोट: जमाराशि 1x के लिए आवश्यक चेतावनी के अधीन हैं।",

            'paymentamohead':"भुगतान राशि:",
            'paymentamobtnoptions':[
                {
                    'id' : '5725a680b3249770ea32de51',
                    'paymentamooption':"2,000",
                },
                {
                    'id' : '5725a680b3249770ea32de52',
                    'paymentamooption':"4,500",
                },
                {
                    'id' : '5725a680b3249770ea32de53',
                    'paymentamooption':"7,100",
                },
                {
                    'id' : '5725a680b3249770ea32de54',
                    'paymentamooption':"9,700",
                },
            ],
            'paymentinput':"कस्टम राशि",
            'totalamohead':"कुल रकम:",
            'totalamotext':"INR 0.00",
            'depositbtn':"जमा",

            'tableheadingsecond':"NETELLER जमा सीमा",
            'tablerawsecond':[
                {
                    'id' : '5725a680b3249770ea32de55',
                    'tableth':"प्रति लेनदेन न्यूनतम जमा", 
                    'tabletd':"रूपया 2,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de56',
                    'tableth':"प्रति लेनदेन अधिकतम जमा", 
                    'tabletd':"रूपया 20,000.00",
                },
                {
                    'id' : '5725a680b3249770ea32de57',
                    'tableth':"कुल दैनिक जमा", 
                    'tabletd':"कोई सीमा नहीं",
                },
            ],
            'tablenotesecond':"नोट: जमाराशि 1x के लिए आवश्यक चेतावनी के अधीन हैं।",

            'transferedphonenumber':"स्थानांतरित फोन नंबर",
            'senderphonenumber':"प्रेषक का फ़ोन नंबर",  
            'trasationid':"परिवर्तन आईडी ",
            'amount':"रकम",
            'confirm_amount':"राशि की पुष्टि करें",
            'comment':"टिप्पणी",
            'receipt_upload':"रसीद अपलोड",
            'depositbtns':"जमा",

        },
    ]
}
export default depositDb;

import history from '../../../@history';
import {setDefaultSettings, setInitialSettings} from '../../../store/actions/casino';
import _ from '../../../@lodash';
import store from '../../../store';
import * as Actions from '../../../store/actions';
import jwtService from '../../../services/jwtService';

import jwt from 'jsonwebtoken';

export const SET_USER_DATA = '[USER] SET DATA';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const REMOVE_USER_DATA = '[USER] REMOVE DATA';
export const USER_LOGGED_OUT = '[USER] LOGGED OUT';

export const FORM_ERROR = 'FORM_ERROR';
export const FORM_SUCCESS = 'FORM_SUCCESS';

export const PROFILEEDIT_ERROR = 'PROFILEEDIT_ERROR';
export const PROFILEEDIT_SUCCESS = 'PROFILEEDIT_SUCCESS';

export const CONTACTREQUEST_ERROR = 'CONTACTREQUEST_ERROR';
export const CONTACTREQUEST_SUCCESS = 'CONTACTREQUEST_SUCCESS';
export const SUBSCRIBEREQUEST_ERROR = 'SUBSCRIBEREQUEST_ERROR';
export const SUBSCRIBEREQUEST_SUCCESS = 'SUBSCRIBEREQUEST_SUCCESS';

/**
 * Set user data from Auth0 token data
 */
export function setUserDataAuth0(tokenData)
{
    const user = {
        role: ['player'],
        from: 'auth0',
        data: {
            displayName: tokenData.username,
            photoURL   : tokenData.picture,
            email      : tokenData.email,
            settings   : (tokenData.user_metadata && tokenData.user_metadata.settings) ? tokenData.user_metadata.settings : {},
            shortcuts  : (tokenData.user_metadata && tokenData.user_metadata.shortcuts) ? tokenData.user_metadata.shortcuts : []
        }
    };

    return setUserData(user);
}

export function setWlUserData(user){
    var jwtConfig = {
		"secret"   : "324934dsfj786ykjhgiyyi4439wri",
		"expiresIn": "2 days" // A numeric value is interpreted as a seconds count. If you use a string be sure you provide the time units (days, hours, etc)
    };
    const access_token = jwt.sign(user, jwtConfig.secret, {expiresIn: jwtConfig.expiresIn});
   // alert(access_token);
    window.localStorage.setItem("access_token", access_token);
  //  alert(window.localStorage.getItem("access_token"))
}

/*
Change Profile
*/
export function submitProfileInfo({
    first_name, 
    last_name, 
    email, 
    mobile, 
    date_of_birth, 
    address, 
    country_id, 
    state_id,
    pincode,
    city, 
    status,
    internal_messages,
    push_notifications,
    phone_call,
    email_notify,
    sms_notify,
}){
    return (dispatch) =>
        jwtService.changeProfile({
            "first_name" : first_name,
            "last_name" : last_name,
            "email" : email,
            "mobile" : mobile,
            "date_of_birth" : date_of_birth,
            "address" : address, 
            "country": country_id,
            "state": state_id,
            "pincode": pincode,
            "city": city, 
            "internal_messages": internal_messages,
            "push_notifications": push_notifications,
            "phone_call": phone_call,
            "email_notify": email_notify,
            "sms_notify": sms_notify,
            "token": window.localStorage.getItem('jwt_access_token')
        })
            .then((responseData) => { 
                    
                    return dispatch({
                        type: PROFILEEDIT_SUCCESS,
                        payload: responseData 
                    });
                }
            )
            .catch(error => { 
                return dispatch({
                    type   : PROFILEEDIT_ERROR,
                    payload: error
                });
            });
}
/*
Change Password
*/
export function submitChangePassword({newpassword, oldpassword})
{
    
    return (dispatch) =>
        jwtService.changeUserPassword({
            "newpassword" : newpassword,
            "old_password" : oldpassword,
            "token": window.localStorage.getItem('jwt_access_token')
        })
            .then((responseData) => { 
                    return dispatch({
                        type: FORM_SUCCESS,
                        payload: responseData 
                        
                    });
                }
            )
            .catch(error => { 
                return dispatch({
                    type   : FORM_ERROR,
                    payload: error
                });
            });
}



export function setUserDataProfilesetup(user)
{
    var jwtConfig = {
		"secret"   : "324934dsfj786ykjhgiyyi4439wri",
		"expiresIn": "2 days" // A numeric value is interpreted as a seconds count. If you use a string be sure you provide the time units (days, hours, etc)
    };
    const access_token = jwt.sign(user, jwtConfig.secret, {expiresIn: jwtConfig.expiresIn});
    window.localStorage.setItem("access_token", access_token);
}

/**
 * Set User Data
 */
export function setUserData(user)
{
    return (dispatch) => {

        /*
        Set User Settings
         */
        dispatch(setDefaultSettings(user.data.settings));

        /*
        Set User Data
         */
        
        dispatch({
            type   : SET_USER_DATA,
            payload: user
        })
    }
}

export function contactRequest({name,email,type_of,message}){
    return (dispatch) =>
        jwtService.contactRequest({
            token: window.localStorage.getItem('jwt_access_token'),
            name,
            email,
            type_of,
            message
        })
        .then((user) => { 
            
                return dispatch({
                    type: CONTACTREQUEST_SUCCESS
                });
            }
        )
        .catch(error => { 
            return dispatch({
                type   : CONTACTREQUEST_ERROR,
                payload: error
            });
        });
}

export function subscriberRequest({email}){
    return (dispatch) =>
        jwtService.subscriberRequest({
            token: window.localStorage.getItem('jwt_access_token'),
            email
        })
        .then((user) => { 
            
                return dispatch({
                    type: SUBSCRIBEREQUEST_SUCCESS
                });
            }
        )
        .catch(error => { 
            return dispatch({
                type   : SUBSCRIBEREQUEST_ERROR,
                payload: error
            });
        });
}

/**
 * Update User Settings
 */
export function updateUserSettings(settings)
{
    return (dispatch, getState) => {
        const oldUser = getState().auth.user;
        const user = _.merge({}, oldUser, {data: {settings}});

        updateUserData(user);

        return dispatch(setUserData(user));
    }
}

/**
 * Update User Shortcuts
 */
export function updateUserShortcuts(shortcuts)
{
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const newUser = {
            ...user,
            data: {
                ...user.data,
                shortcuts
            }
        };

        updateUserData(newUser);

        return dispatch(setUserData(newUser));
    }
}

/**
 * Remove User Data
 */
export function removeUserData()
{
    return {
        type: REMOVE_USER_DATA
    }
}

/**
 * Logout
 */
export function logoutUser()
{

    return (dispatch, getState) => {

        const user = getState().auth.user;

        if ( !user.role || user.role.length === 0 )// is guest
        {
            return null;
        }

        history.push({
            pathname: '/'
        });

        switch ( user.from )
        {
            default:
            {
                jwtService.logout();
            }
        }

        dispatch(setInitialSettings());

        dispatch({
            type: USER_LOGGED_OUT
        })
    }
}

/**
 * Update User Data
 */
function updateUserData(user)
{
    if ( !user.role || user.role.length === 0 )// is guest
    {
        return;
    }

    switch ( user.from )
    {
        default:
        {
            jwtService.updateUserData(user)
                .then(() => {
                    store.dispatch(Actions.showMessage({message: "User data saved with api"}));
                })
                .catch(error => {
                    store.dispatch(Actions.showMessage({message: error.message}));
                });
            break;
        }
    }
}

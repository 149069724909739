import { container } from "../../casino-kit-react.js";

const tabsStyle = {
  signinnerpagebox:{
    background:"#0f0f0f",
    padding:"140px 20px 0px",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      padding:"90px 20px 0px",
    },
},
tabbtn:{
  "& img":{
    height:"40px",
    display:"block"
  }
},
gamewrap:{
  padding:"180px 20px 0px 20px"
},
gamesearching:{
  background:"#131212",
  color:"#fff"
},
gamelabel:{
  position:"absolute", 
  height:"32px",
  bottom:"2px",
  left:"50%",
  fontFamily: 'Open Sans',
  transform:"translateX(-50%)",
  fontSize: "14px",
  textTransform: "uppercase",
  fontWeight: "bold",
  lineHeight: "24px",
  color:"#ffb80c", 
  width:"99%",
  background: "rgba(0,0,0,0.7)",
  padding: "4px 0px",
  borderRadius: "0px 0px 10px 10px",
  ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
    fontSize: "12px",
  },
},
gridgame:{ 
  ['@media (max-width:959px)']: { // eslint-disable-line no-useless-computed-key 
    "&:nth-child(odd)":{
      paddingRight:"5px",
    },
    "&:nth-child(even)":{
      paddingLeft:"5px",
    } 
  },
},
pageheadinginner:{
  fontFamily: "ubuntu",
  color:"#ffb80c",
  fontSize:"22px",
  padding:"10px 0px 10px"
},
signinnerpageboxin:{
    background:"#000",
    margin:"40px 0px 0px",
    borderRadius:"10px"
},
  section: {
    background: "#EEEEEE",
    padding: "70px 0"
  },
  dashboardgames:{
    padding:"0px 20px"
  },
  tableresponsi:{
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      overflow: "auto",
    },
  },
  table:{
    boxShadow:" 0px 10px 40px 0px rgba(0, 0, 0, 0.4)",
    position: "relative",
    width: "100%",
    fontFamily: 'Open Sans',
    textAlign: "left",
    marginBottom: "1rem",
    color: "#212529",
    '& tr:hover': {
      background:"#192246",
    },
    '& td': {
      borderTop:"1px solid rgba(255, 255, 255, 0.09)",
      border:"0px",
      verticalAlign:"middle",
      padding:"20px 15px",
      fontWeight:"600",
      fontSize:"18px",
      textTransform:"uppercase",
      color:"#a1aed4",
    },
    '& th': {
      borderTop:"1px solid rgba(255, 255, 255, 0.09)",
      border:"0px",
      fontSize:"18px",
      fontWeight:"600",
      textTransform:"uppercase",
      borderBottom:"0px",
      padding:"20px 15px",
      background:"#0b122e",
      color:"#fff",
    },
    '& img': {
      width:"40px",
      height:"40px",
      borderRadius:"50%",
      verticalAlign:"middle",
      display:"inline-block"
    },
    '& td:nth-child(1) img': {
      marginRight:"5px",
    },
    '& td:nth-child(3) img': {
      marginRight:"5px",
    },
    '& td:nth-child(6) img': {
      marginRight:"5px",
    },
    '& td:nth-child(1)': {
      color:"#fff",
    },
    '& td:nth-child(3)': {
       color:"#045cf0",
    },
    '& td:nth-child(5)': {
      color:"#f0ad4e",
    },
    '& td:nth-child(6)': {
      color:"#f0ad4e",
    },
    '& td:last-child': {
      color:"#f0ad4e",
    },
  },
  playgamebtn:{
    background:"#efde1d",
    color:"#000"
  },
  gametabpage:{
    "&.active":{
      borderColor:"#efde1d",
    }
  },
  singlegame:{
    display:"block", 
    textAlign:"center",
    marginBottom:"40px",
    border:"1px solid #273b71",
    borderRadius:"10px",
    padding:"2px",
    boxShadow:"0px 10px 15px 0px rgba(0, 0, 0, 0.4)",
    position:"relative", 
    transition:"all 0.3s ease-in", 
    width: "100%",
    overflow: "hidden",
    '&:hover a': { 
      display:"block", 
    },
    '& img': { 
      verticalAlign: "middle",
     // animation:"opacityAnimation 2s linear infinite",
      width: "100%",
      borderRadius: "10px",
      position: "relative",
      height: "auto",
      ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
        height: "auto",
      },
    },
    '&:hover': {
      borderColor:"#681f40",
      transform:"translateY(-10px)",
    },
    '&::before': { 
      content:"close-quote",
      position:"absolute",
      left:"-2px",
      top:"-2px",
      width:"calc(100% + 4px)",
      height:"calc(100% + 4px)", 
      background:"linear-gradient(135deg,#a1902e,#ffdb66 35%,#baa73e 40%,#fff8d4 44%,#6e5706 48%,#574300 52%,#fff6c7 56%,#ab8f00 76%,#ab8f00)",
      animation:"BorderAnimation-data-v-7fdc8ba0 20s linear infinite",
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      height:"auto",
      lineHeight:"inherit",
      marginBottom:"20px",
    },
  },
  searchboxi:{
    top: "112px",
    right: "15px",
    border: "0px",
    padding: "15px 20px",
    position: "absolute",
    background: "#151515",
    borderRadius: "50px",
    zIndex: "20",
    color:"#ccc",
    "&::placeholder":{
      color:"#ccc",
    },
    "& input":{
      background:"#151515",
      color:"#ccc",
      border:"0px",
      marginLeft:"20px"
    }
  },
  mybtn2:{
    position:"absolute", 
    height:"34px",
    bottom:"40%",
    display:"none",
    left:"50%",
    fontFamily: 'Open Sans',
    transform:"translateX(-50%)",
    boxShadow:"0px 10px 15px 0px rgba(0, 0, 0, 0.4)",
    fontSize: "14px",
    textTransform: "uppercase",
    fontWeight: "600",
    lineHeight: "11px",
    width: "130px",
    color: "#000",
    border: "0px",
    borderRadius: "3px",
    cursor: "pointer",
    transition: "all 0.3s ease-in",
    background: "linear-gradient(to left, #1c294e, #1c294e)",
    '&:hover': {
      background: "linear-gradient(to right, #ff344d, #d2273c)",
      color: "#fff",
    }, 
  },
  container,
  textCenter: {
    textAlign: "center"
  },
  loadimg:{
    margin:"0px auto",
    height:"60px",
  }
};

export default tabsStyle;

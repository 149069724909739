import axios from 'axios';
import jwtDecode from 'jwt-decode';
import CasinoUtils from '../../@casino/CasinoUtils';
import * as GlobalVar from '../../global/Global';

class jwtService extends CasinoUtils.EventEmitter {

    init() {
        this.setInterceptors();
        this.handleAuthentication();
    }

    setInterceptors = () => {
        axios.interceptors.response.use(response => {
            return response;
        }, err => {

            return new Promise((resolve, reject) => {

                if (err.response === null || (err.response != null && err.response.status === 401 && err.config && !err.config.__isRetryRequest)) {
                    // if you ever get an unauthorized response, logout the user
                    //this.emit('onAutoLogout', 'Invalid access_token');
                    //this.setSession(null, null);
                }
                throw err;
            });
        });
    };


    /*signInWithEmailAndPassword = (email, password) => {
        return new Promise((resolve, reject) => {
            var xyz = {"email": "Check your username/emai", "password": "Check your password"};
            reject(xyz);
        });
    };*/

    signInWithEmailAndPassword = (username, password, recaptcha) => {
        return new Promise((resolve, reject) => {
            const loginUrl = GlobalVar.LOGIN_PATH; //process.env.MSN_WIFI; 

            const data = 'username=' + username + '&password=' + password +'&recaptcha=' + recaptcha;

            axios.post(loginUrl, data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(response => {
                if (response.data.status === true) {
                    this.setSession(response.data.TOKEN, response.data.SIDTOKEN);
                    this.handleAuthentication();
                    resolve(response.data);
                }
                else {
                    var errorObj = { "password": response.data.message }; // This Message will be show at Password field
                    reject(errorObj);
                }
            });
        });
    };

    signInOtpNew = (mobile, country_id, otp) => {
        return new Promise((resolve, reject) => {
            const loginUrl = GlobalVar.verifymeotp; //process.env.MSN_WIFI; 

            const data = '&mobile=' + mobile +'&country_id=' + country_id +'&otp=' + otp;

            axios.post(loginUrl, data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(response => {
                 
                if (response.data.status === true) { 
                     
                    if(response.data.PROFILETOKEN != null && response.data.PROFILETOKEN != "0" && response.data.PROFILESID != null && response.data.PROFILESID != "0"){
                        this.setSession(response.data.PROFILETOKEN, response.data.PROFILESID);
                        this.handleAuthentication();
                        resolve(response.data);
                    }else{
                        resolve(response.data);
                    }
                    // this.setSession(response.data.TOKEN, response.data.SIDTOKEN);
                    // this.handleAuthentication();
                   
                }
                else {
                    var errorObj = { "message": response.data.message }; // This Message will be show at Password field
                    reject(errorObj);
                }
            });
        });
    };

    signInWithEmailAndPasswordNew = (mobile, country_id) => {
        return new Promise((resolve, reject) => {
            const loginUrl = GlobalVar.LOGINME_PATH; //process.env.MSN_WIFI; 

            const data = '&mobile=' + mobile +'&country_id=' + country_id;

            axios.post(loginUrl, data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(response => {
                if (response.data.status === true) {
                    // this.setSession(response.data.TOKEN, response.data.SIDTOKEN);
                    // this.handleAuthentication();
                    resolve(response.data);
                }
                else {
                    var errorObj = { "message": response.data.message }; // This Message will be show at Password field
                    reject(errorObj);
                }
            });
        });
    };

    forgotPassword = (forgotBy, recaptcha) => {
        return new Promise((resolve, reject) => {
            const data = 'forgotBy=' + forgotBy +'&recaptcha=' + recaptcha;

            axios.post(GlobalVar.FORGOT_PATH, data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(response => {
                if (response.data.status === true) {
                    resolve(response.data);
                }
                else {
                    var errorObj = { "message": response.data.message }; // This Message will be show at Password field
                    reject(errorObj);
                }
            });
        });
    };

    changeUserPassword = (data) => {
        return new Promise((resolve, reject) => {
            const changePassword = GlobalVar.CHANGEPASSWORD; //process.env.MSN_WIFI; 
            
            axios.post(changePassword, data, {
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                }
            })
            // .then(response => {
            //     if (response.data.status === true) {
            //         resolve(response.data);
            //     }
            //     else {
            //         var errorObj = { "password": response.data.message }; // This Message will be show at Password field
            //         reject(errorObj);
            //     }
            // });
            .then(response => {
                if (response.data.status === true) {
                    resolve(response.data);
                } else {
                    if (response.data.error != null && Object.keys(response.data.error).length > 0) {
                        reject(response.data.error);
                    } else {
                        reject(response.data);
                    }
                }
            });

        });
    };


    handleAuthentication = () => {
        let access_token = this.getAccessToken();
        let sid_token = this.getSidToken();
        if (!access_token) {
            this.emit('onNoAccessToken');
            return;
        }

        if (this.isAuthTokenValid(access_token)) {
           
            this.setSession(access_token, sid_token);
            this.emit('onAutoLogin', true);
        }
        else {
            this.setSession(null, null);
            this.emit('onAutoLogout', 'access_token expired');
        }
    };

    isAuthenticationLogin = () => {
        let access_token = this.getAccessToken();

        if (!access_token) {
            return false;
        }

        if (this.isAuthTokenValid(access_token)) {
            return true;
        }
        else {
            return false;
        }
    };

    createUser = (data) => {
        return new Promise((resolve, reject) => {
            const datatwo = Object.entries(data)
                .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
                .join('&');
            axios.post(GlobalVar.SIGNUP_PATH, datatwo)
                .then(response => {
                    if (response.data.status > 0) {
                        resolve(response.data);
                    }
                    else {
                        if (response.data.error != null) {
                            response.data.error["message"] = response.data.message;
                            reject(response.data.error);
                        } else {
                            reject({ "message": response.data.message });
                        }
                    }
                });
        });
    };
    createUserMobile = (data) => {
        return new Promise((resolve, reject) => {
            const datatwo = Object.entries(data)
                .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
                .join('&');
            axios.post(GlobalVar.SIGNUP_MOBILE, datatwo)
                .then(response => {
                   
                    if (response.data.status === true) {  
                        if(response.data.TOKEN != null && response.data.TOKEN != "0" && response.data.SIDTOKEN != null && response.data.SIDTOKEN != "0"){
                            this.setSession(response.data.TOKEN, response.data.SIDTOKEN);
                            this.handleAuthentication();
                            resolve(response.data);
                        }else{
                            resolve(response.data);
                        }  
                    } 
                    else {
                        if (response.data.error != null) {
                            response.data.error["message"] = response.data.message;
                            reject(response.data.error);
                        } else {
                            reject({ "message": response.data.message });
                        }
                    }
                });
        });
    };


    createBankaccount = (data) => {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded";
            const datatwo = Object.entries(data)
                .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
                .join('&');
            axios.post(GlobalVar.ADDBANK, datatwo)

                .then(response => {
                    

                    if (response.data.status === "OK") {

                        resolve(response.data);

                    }
                    else {
                        // reject({"message":response.data.message});

                        if (response.data.error != null && Object.keys(response.data.error).length > 0) {
                            reject(response.data.error);
                        } else {
                            reject(response.data);
                        }
                    }
                });
        });
    };

    withdrawalRequest = (data) => {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded";
            axios.post(GlobalVar.WITHDRAWREQUEST, data)
                .then(response => {
                    
                    if (response.data.status === "OK") {
                        resolve(response.data);
                    } else {
                        if (response.data.error != null && Object.keys(response.data.error).length > 0) {
                            reject(response.data.error);
                        } else {
                            reject(response.data);
                        }
                    }
                });
        });
    };

    contactRequest = (data) => {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded";
            axios.post(GlobalVar.CONTACTREQUEST, data)
                .then(response => {
                   
                    if (response.data.status === "OK") {
                        resolve(response.data);
                    } else {
                        if (response.data.error != null && Object.keys(response.data.error).length > 0) {
                            reject(response.data.error);
                        } else {
                            reject(response.data);
                        }
                    }
                });
        });
    };
    subscriberRequest = (data) => {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded";
            axios.post(GlobalVar.SUBSCRIBERREQUEST, data)
                .then(response => {
                   
                    if (response.data.status === "OK") {
                        resolve(response.data);
                    } else {
                        if (response.data.error != null && Object.keys(response.data.error).length > 0) {
                            reject(response.data.error);
                        } else {
                            reject(response.data);
                        }
                    }
                });
        });
    };

    depositBtBRequest = (data) => {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded";
            axios.post(GlobalVar.BTBDEPOSITREQUEST, data)
                .then(response => {
                    if (response.data.status === "OK") {
                        resolve(response.data);
                    } else {
                        if (response.data.error != null && Object.keys(response.data.error).length > 0) {
                            reject(response.data.error);
                        } else {
                            reject(response.data);
                        }
                    }
                });
        });
    };
    depositBtCRequest = (data) => {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded";
            axios.post(GlobalVar.CTBDEPOSITREQUEST, data)
                .then(response => {
                    if (response.data.status === "OK") {
                        resolve(response.data);
                    } else {
                        if (response.data.error != null && Object.keys(response.data.error).length > 0) {
                            reject(response.data.error);
                        } else {
                            reject(response.data);
                        }
                    }
                });
        });
    };

    depositGatewayRequest = (data) => {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.post['Content-Type'] = "application/json";
            axios.post(GlobalVar.PAY10GATEWAY, data)
            .then(response => {
                if (response.data.status === true) {
                    if(response.data && response.data.REDIRECTURL != null && response.data.REDIRECTURL.toString().substr(0,4) === "http"){
                        resolve(response.data);
                    }else{
                        if(response.data && response.data.REDIRECTURL != null){
                            let innerDataUrl = JSON.parse(response.data.REDIRECTURL);
                            if(innerDataUrl != null && innerDataUrl.status !== null && innerDataUrl.type !== null && innerDataUrl.type === "FORM"){
                                resolve(response.data);
                            }else{
                                reject(JSON.parse(response.data.REDIRECTURL));
                            }
                            
                        }else if(response.data){
                            reject(response.data);
                        }else {
                            reject(response);
                        }
                        
                    }
                } else {
                    if (response.data.error != null && Object.keys(response.data.error).length > 0) {
                        reject(response.data.error);
                    } else {
                        reject(response.data);
                    }
                }
            });
        });
    };
    depositPaypalRequest = (data) => {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded";
            axios.post(GlobalVar.PAYPALDEPOSITREQUEST, data)
                .then(response => {
                    if (response.data.status === "OK") {
                        resolve(response.data);
                    } else {
                        if (response.data.error != null && Object.keys(response.data.error).length > 0) {
                            reject(response.data.error);
                        } else {
                            reject(response.data);
                        }
                    }
                });
        });
    };
    depositEWalletRequest = (data) => {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded";
            axios.post(GlobalVar.EWALLETDEPOSITREQUEST, data)
                .then(response => {
                    if (response.data.status === "OK") {
                        resolve(response.data);
                    } else {
                        if (response.data.error != null && Object.keys(response.data.error).length > 0) {
                            reject(response.data.error);
                        } else {
                            reject(response.data);
                        }
                    }
                });
        });
    };
    changeProfile = (data) => {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.post['Content-Type'] = "application/json;charset=utf-8";
            axios.post(GlobalVar.EDITPROFILE, data)
                .then(response => {
                    if (response.data.status === true) {
                        resolve(response.data);
                    } else {
                        if (response.data.error != null && Object.keys(response.data.error).length > 0) {
                            reject(response.data.error);
                        } else {
                            reject(response.data);
                        }
                    }
                });
        });
    };
    depositAngadiyaRequest = (data) => {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded";
            axios.post(GlobalVar.ANGADIYADEPOSITREQUEST, data)
                .then(response => {
                    if (response.data.status === "OK") {
                        resolve(response.data);
                    } else {
                        if (response.data.error != null && Object.keys(response.data.error).length > 0) {
                            reject(response.data.error);
                        } else {
                            reject(response.data);
                        }
                    }
                });
        });
    };
    verifyEmailAddress = (data) => {
        return new Promise((resolve, reject) => {
            axios.post(GlobalVar.VERIFYEMAIL, {
                token: this.getAccessToken()
            })
                .then(response => {
                    JSON.stringify("CHECK:" + response);
                    if (response.data != null && response.data.data != null && response.data.status != null && response.data.status === true) {
                        resolve(response.data.data);
                    }
                    else {
                        reject({ status: false, message: 'Failed to verify with email.' });
                    }
                })
                .catch(error => {
                    reject({ status: false, message: 'Failed to verify with email' });
                });
        });
    };
    signInWithToken = () => {

        return new Promise((resolve, reject) => {
            const tokenUrl = GlobalVar.TOKEN_PATH;
            axios.post(tokenUrl, {
                token: this.getAccessToken()
            })
                .then(response => {

                    if (response.data != null && response.data.data != null && response.data.status != null && response.data.status === true) {
                        resolve(response.data.data);
                    }
                    else {
                        this.logout();
                        reject('Failed to login with token.');
                    }
                })
                .catch(error => {
                    this.logout();
                    reject('Failed to login with token.');
                });
        });
    };

    updateUserData = (user) => {
        return axios.post('/api/auth/user/update', {
            user: user
        });
    };

    setSession = (access_token, sid_token) => {
        if (access_token) {
            localStorage.setItem('jwt_access_token', access_token);
            if(sid_token){
                localStorage.setItem('sports_access', sid_token);
            }
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
            //axios.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false });
        }
        else {
            localStorage.removeItem('jwt_access_token');
            localStorage.removeItem('sports_access');
            delete axios.defaults.headers.common['Authorization'];
        }
    };

    logout = () => {
        this.setSession(null, null);
        localStorage.removeItem('jwt_access_token');
        localStorage.removeItem('sports_access');
    };

    isAuthTokenValid = access_token => {
        if (!access_token) {
            return false;
        }
        const decoded = jwtDecode(access_token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            return false;
        }
        else {
            return true;
        }
    };

    getAccessToken = () => {
        return window.localStorage.getItem('jwt_access_token');
    };
    
    getSidToken = () => {
        return window.localStorage.getItem('sports_access');
    };


    WlsignInWithEmailAndPassword = (data) => {
        return new Promise((resolve, reject) => {
            const loginUrl = GlobalVar.WLLOGIN_PATH; //process.env.MSN_WIFI; 

            axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded";
            const datatwo = Object.entries(data)
                .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
                .join('&');
            axios.post(loginUrl, datatwo, { header: { "Content-Type": "application/x-www-form-urlencoded" } })
                .then(response => {
                    if (response.data.status === "1") {
                        resolve(response.data);
                    } else {
                        var errorObj = { "message": response.data.message }; // This Message will be show at Password field
                        reject(errorObj);
                    }
                });
        });
    };

    updateUserSecuritySettings = (data) => {
        return new Promise((resolve, reject) => {
            const loginUrl = GlobalVar.WLPROFILE_SETTING; //process.env.MSN_WIFI; 

            axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded";
            const datatwo = Object.entries(data)
                .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
                .join('&');
            axios.post(loginUrl, datatwo, { header: { "Content-Type": "application/x-www-form-urlencoded" } })
                .then(response => {
                    if (response.data.status === "1") {
                        resolve(response.data);
                    } else {
                        var errorObj = { "message": response.data.message }; // This Message will be show at Password field
                        reject(errorObj);
                    }
                });
        });
    };

    updateUserThemeSettings = (data) => {
        return new Promise((resolve, reject) => {
            const loginUrl = GlobalVar.WLTHEME_SETTING; //process.env.MSN_WIFI; 

            axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded";
            const datatwo = Object.entries(data)
                .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
                .join('&');
            axios.post(loginUrl, datatwo, { header: { "Content-Type": "application/x-www-form-urlencoded" } })
                .then(response => {
                    if (response.data.status === "1") {
                        resolve(response.data);
                    } else {
                        var errorObj = { "message": response.data.message }; // This Message will be show at Password field
                        reject(errorObj);
                    }
                });
        });
    };

    updateUserBankSettings = (data) => {
        return new Promise((resolve, reject) => {
            const loginUrl = GlobalVar.WLBANK_SETTING; //process.env.MSN_WIFI; 

            axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded";
            const datatwo = Object.entries(data)
                .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
                .join('&');
            axios.post(loginUrl, datatwo, { header: { "Content-Type": "application/x-www-form-urlencoded" } })
                .then(response => {
                    if (response.data.status === "1") {
                        resolve(response.data);
                    } else {
                        var errorObj = { "message": response.data.message }; // This Message will be show at Password field
                        reject(errorObj);
                    }
                });
        });
    };

    updateUserModuleSettings = (data) => {
        return new Promise((resolve, reject) => {
            const loginUrl = GlobalVar.WLMODULE_SETTING; //process.env.MSN_WIFI; 

            axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded";
            const datatwo = Object.entries(data)
                .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
                .join('&');
            axios.post(loginUrl, datatwo, { header: { "Content-Type": "application/x-www-form-urlencoded" } })
                .then(response => {
                    if (response.data.status === "1") {
                        resolve(response.data);
                    } else {
                        var errorObj = { "message": response.data.message }; // This Message will be show at Password field
                        reject(errorObj);
                    }
                });
        });
    };

    updateUserPaymentSettings = (data) => {
        return new Promise((resolve, reject) => {
            const loginUrl = GlobalVar.WLPAYMENT_SETTING; //process.env.MSN_WIFI; 

            axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded";
            const datatwo = Object.entries(data)
                .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
                .join('&');
            axios.post(loginUrl, datatwo, { header: { "Content-Type": "application/x-www-form-urlencoded" } })
                .then(response => {
                    if (response.data.status === 1) {
                        resolve(response.data);
                    } else {
                        var errorObj = { "message": response.data.message }; // This Message will be show at Password field
                        reject(errorObj);
                    }
                });
        });
    };

    WlotpVerification = (data) => {
        return new Promise((resolve, reject) => {
            const otpUrl = GlobalVar.WLOTP_DATA; //process.env.MSN_WIFI; 

            axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded";
            const datatwo = Object.entries(data)
                .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
                .join('&');
            axios.post(otpUrl, datatwo, { header: { "Content-Type": "application/x-www-form-urlencoded" } })
                .then(response => {
                    //console.log(response);
                    
                    if (response.data.status === "1") {
                        this.setSession(response.data.TOKEN,null);
                        this.handleAuthentication();
                        resolve(response.data);
                    }
                    else {
                        var errorObj = { "otp": response.data.message }; // This Message will be show at Password field
                        reject(errorObj);
                    }
                });
        });
    };
    createUserProfileSetup = (data) => {
        return new Promise((resolve, reject) => {
            const profileUrl = GlobalVar.PROFILESETUP_PATH; //process.env.MSN_WIFI; 

            axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded";
            const datathree = Object.entries(data)
                .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
                .join('&');
            axios.post(profileUrl, datathree, { header: { "Content-Type": "application/x-www-form-urlencoded" } })
                .then(response => {
                    if (response.data.status === "1" && response.data.data != null) {
                        this.setSession(response.data.TOKEN,null);
                        this.handleAuthentication();
                        resolve(response.data);
                    }
                    else {
                        var errorObj = { "message": response.data.message }; // This Message will be show at Password field
                        reject(errorObj);
                    }
                });
        });
    };
    createUserWlRegister = (data) => {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded";
            const datatwo = Object.entries(data)
                .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
                .join('&');
            axios.post(GlobalVar.WLREGISTER_PATH, datatwo, { header: { "Content-Type": "application/x-www-form-urlencoded" } })
                .then(response => {
                  
                    if (response.data.status === 1) {
                        resolve(response.data);
                    }
                    else {
                       
                        if (response.data.error != null && Object.keys(response.data.error).length > 0) {
                            reject(response.data.error);
                        } else {
                            reject(response.data);
                        }
                    }
                });
        });
    };
    
    createResendOtp = (data) => {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded";
            const datatwo = Object.entries(data)
                .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
                .join('&');
            axios.post(GlobalVar.REGENERATE_OTP, datatwo, { header: { "Content-Type": "application/x-www-form-urlencoded" } })
                .then(response => {
                  
                    if (response.data.status === 1) {
                        resolve(response.data);
                    }
                    else {
                        
                        if (response.data.error != null && Object.keys(response.data.error).length > 0) {
                            reject(response.data.error);
                        } else {
                            reject(response.data);
                        }
                    }
                });
        });
    };
    
    
    /**
     * Upload Image On Server
     */
    uploadImage = (file) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData()
            formData.append(
                'icon',
                file
            )
            axios.defaults.headers.post['Content-Type'] = "multipart/form-data";
            axios.defaults.headers.common = {};
            axios.defaults.headers.common.accept = 'application/json';
            axios.post(GlobalVar.IMAGEUPLOAD, formData, { header: { "Content-Type": "multipart/form-data" } })
                .then(response => {
                    if (response.data.status === 1) {
                        resolve(response.data);
                    }
                    else {
                        if (response.data.error != null && Object.keys(response.data.error).length > 0) {
                            reject(response.data.error);
                        } else {
                            reject(response.data);
                        }
                    }
                });
        });
    };
    /**
     * Upload KYC documents
     */
    uploadKYC = (data) => {
        return new Promise((resolve, reject) => {
            const datatwo = Object.entries(data)
                .filter(key => key !== 'update')
                .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
                .join('&');
            const URL = (data.update) ? GlobalVar.KYCUPDATE : GlobalVar.KYCINSERT;
            axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded";
            axios.post(URL, datatwo, { header: { "Content-Type": "application/x-www-form-urlencoded" } })
            
                .then(response => {
                    if (response.data.status === 1 || response.data.status) {
                        resolve(response.data);
                    }
                    else {
                        if (response.data.error != null && Object.keys(response.data.error).length > 0) {
                            reject(response.data.error);
                        } else {
                            reject(response.data);
                        }
                    }
                });
        });
    };
}

const instance = new jwtService();

export default instance;

import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components 
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import styles from "../assets/jss/components/headerStyle.js";
import GridContainer from "../elements/Grid/GridContainer";
import GridItem from "../elements/Grid/GridItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
 
import stylesr from "../assets/jss/components/headerLinkstopStyle";


import menusidebartopDb from "../data/menusidebartop.js";
// import logo from "../assets/img/logo.png";

import Tooltip from "@material-ui/core/Tooltip";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"; 
import Slide from "@material-ui/core/Slide"; 
//import { useTranslation } from 'react-i18next'
import LanguageSelector from './LanguageSelector'
 

export default function SectionHeader(props) {

    stylesr.headbackground.backgroundColor = props.themeColors.bgColors.primary_bgcolor;
  stylesr.topheader.backgroundColor = props.themeColors.bgColors.secondary_dark_bgcolor; 
  styles.toolbarafter["&::before"].backgroundColor = props.themeColors.bgColors.primary_bgcolor;
  styles.toolbarafter["&::after"].borderBottom = props.themeColors.bgColors.primary_bgcolor;
  stylesr.dropdownLink.color = props.themeColors.bgColors.text_secondary_dark_color;
  styles.logoname.color = props.themeColors.bgColors.text_primary_color;
  styles.logoname.fontFamily = props.themeColors.bgColors.logo_font;

  stylesr.dropdownLink.fontFamily = props.themeColors.bgColors.base_font;  
  
   
  //const { t } = useTranslation()
    const useStyles = makeStyles(styles);
    const useStylesr = makeStyles(stylesr);
    const classes = useStyles();
    const classesr = useStylesr(); 
    const [mobileOpen, setMobileOpen] = React.useState(false);   
    const currentLocation = props.location.pathname.replace("/","");  
    React.useEffect(() => {      
      if (props.changeColorOnScroll && (currentLocation === "" || currentLocation === "about" || currentLocation === "vipprograms" || currentLocation === "faq" || currentLocation === "contact")) {
        window.addEventListener("scroll", headerColorChange);
      }
      return function cleanup() {
        if (props.changeColorOnScroll && (currentLocation === "" || currentLocation === "about" || currentLocation === "vipprograms" || currentLocation === "faq" || currentLocation === "contact")) {
          window.removeEventListener("scroll", headerColorChange);
        }
      };
    });
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
    const headerColorChange = () => {
      const { color, changeColorOnScroll } = props;
      const windowsScrollTop = window.pageYOffset;
      if (windowsScrollTop > changeColorOnScroll.height) {
        document.body
          .getElementsByTagName("header")[0]
          .classList.remove(classes[color]);
        document.body
          .getElementsByTagName("header")[0]
          .classList.add(classes[changeColorOnScroll.color]);
  
        document.body
            .getElementsByClassName("topheadercl")[0]
            .setAttribute("style", "display:none;");
  
        document.body
            .getElementsByClassName(classes.toolbarafter)[0]
            .classList.add("removeAfterEffect");
  
      } else {
        document.body
          .getElementsByTagName("header")[0]
          .classList.add(classes[color]);
        document.body
          .getElementsByTagName("header")[0]
          .classList.remove(classes[changeColorOnScroll.color]);
        document.body
            .getElementsByClassName("topheadercl")[0]
            .setAttribute("style", "display:block;");
  
        document.body
            .getElementsByClassName(classes.toolbarafter)[0]
            .classList.remove("removeAfterEffect");
      }
    };
  
    

  
    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="down" ref={ref} {...props} />;
    });
  
    Transition.displayName = "Transition";
  
  
    const { color, rightLinks, leftLinks, fixed, absolute } = props;
    
    const appBarClasses = classNames({
      [classes.appBar]: true,
      [classes[color]]: color,
      [classes.absolute]: absolute,
      [classes.fixed]: fixed
    });
    const toolBarClasses = classNames({
      [classes.container]: true,
      [classes.toolbarafter]: true,
    });
    function parseJwt(token) {
        if (!token) { return; }
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }
    function getCurrentThemeInfo(){
      if(window.localStorage.getItem('jwt_theme_token') != null){
          let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
          return themeData;
      }
    }
    
    var supportNumber = null;
    var supportEmail = null;
    var icon = null;
    var fbLink = null;
    var twitterLink = null;
    var brandName = null;
   // var gplusLink = null;
  //  var linkDin = null;
    var themeInfo = getCurrentThemeInfo();
    
    if(themeInfo != null && themeInfo.id != null){
        if(themeInfo.support_number != null && themeInfo.support_number.length > 0){
            supportNumber = themeInfo.support_number;
        }
        if(themeInfo.support_mail != null && themeInfo.support_mail.length > 0){
            supportEmail = themeInfo.support_mail;
        }
        if(themeInfo.facebook_url != null && themeInfo.facebook_url.length > 0){
          fbLink = themeInfo.facebook_url;
        }
        if(themeInfo.twitter_url != null && themeInfo.twitter_url.length > 0){
          twitterLink = themeInfo.twitter_url;
        }
        if(themeInfo.icon != null && themeInfo.icon.length > 0){
          icon = themeInfo.icon;
        }
        if(themeInfo.brand_name != null && themeInfo.brand_name.length > 0){
          brandName = themeInfo.brand_name;
        } 
   //     if(themeInfo.google_plus_url != null && themeInfo.google_plus_url.length > 0){
   //       gplusLink = themeInfo.google_plus_url;
   //     }
   //     if(themeInfo.linkdin_url != null && themeInfo.linkdin_url.length > 0){
   //       linkDin = themeInfo.linkdin_url;
    //    }
    }
    const brandComponent = "";


    const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1000);
    React.useEffect(() => {
      window.addEventListener("resize", () => {
          const ismobile = window.innerWidth < 1000;
          if (ismobile !== isMobile) setIsMobile(ismobile);
      }, false);
  }, [isMobile]);
  
  
    React.useEffect(() => {
      if (props.changeColorOnScroll) {
        window.addEventListener("scroll", hhiddeheader);
      }
      return function cleanup() {
        if (props.changeColorOnScroll ) {
          window.removeEventListener("scroll", hhiddeheader);
        }
      };
    });  
   
    const hhiddeheader = () => {
      const {  changeColorOnScroll } = props;
      const windowsScrollTop = window.pageYOffset;
      if (windowsScrollTop > changeColorOnScroll.height) { 
        document.body
        .getElementsByClassName("fixheadermobilehide1")[0]
        .setAttribute("style", "display:none ;");
        document.body
        .getElementsByClassName("fixheadermobile")[0]
        .setAttribute("style", "display:block;"); 
      }else { 
        document.body
        .getElementsByClassName("fixheadermobilehide1")[0]
        .setAttribute("style", "display:block ;"); 
        document.body
        .getElementsByClassName("fixheadermobile")[0]
        .setAttribute("style", "display:none;");
      }
    } 


  return (      
    <AppBar className={appBarClasses}>
              <section className={classesr.topheader + " topheadercl  " + classesr.topheaderind} id="top">
                <div className={classes.containernew + " containernew"}>
                
                
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6} key={"1"}>
                      <div className={classesr.leftcontent}>
                        <List className={classesr.list}>
                        {(() => {
                          if(supportNumber != null) {
                            return(
                          <ListItem className={classesr.listItem} key={"3"}>
                            
                            <Button 
                                href=""
                                target="_blank"
                                className={classesr.dropdownLink}
                            >
                              <i className={"fa fa-phone-square"}></i>&nbsp;
                              +{supportNumber}
                            
                            </Button>
                          </ListItem>
                            )}
                          })()}
                         {(() => {
                           if(supportEmail != null) {
                             return(
                          <ListItem className={classesr.listItem} key={"4"}>
                          {menusidebartopDb[sessionStorage.getItem('lang')].map((jsondata) => (  <Button
                                href=""
                                key={jsondata.id}
                                target="_blank"
                                className={classesr.dropdownLink}
                            >
                              <i className={"fa fa-envelope"}></i>&nbsp; 
                              {supportEmail}
                            </Button>
                          ))}
                          </ListItem>
                           )}
                          })()}
                        </List>
                      </div>

                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}  key={"5"}>
                      <div className={classes.rightcontent}>
                        <List className={classesr.list2} key={"6"}>
                        {(() => {
                          if(twitterLink != null) {
                            return(
                          <ListItem className={classesr.listItem + " " + classesr.socialmobile} key={"7"}>
                            <Tooltip
                                id=""
                                title="Follow As on Twitter"
                                placement={window.innerWidth > 959 ? "top" : "left"}
                                classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                  href=""
                                  target="_blank"
                                  className={classes.navLink}
                              >
                                <i className={classes.socialIcons + " fab fa-twitter"  } />
                              </Button>
                            </Tooltip>
                          </ListItem>
                             )}
                            })()}
                            {(() => {
                              if(fbLink != null) {
                                return(
                          <ListItem className={classesr.listItem + " " + classesr.socialmobile} key={"8"}>
                            <Tooltip
                                id=""
                                title="Follow As on Facebook"
                                placement={window.innerWidth > 959 ? "top" : "left"}
                                classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                  href=""
                                  target="_blank"
                                  className={classes.navLink}
                              >
                                <i className={classes.socialIcons + " fab fa-facebook"  } />
                              </Button>
                            </Tooltip>
                          </ListItem>
                          )}
                        })()}
                        {(() => {
                          if(fbLink != null) {
                            return(
                          <ListItem className={classesr.listItem + " " + classesr.socialmobile} key={"9"}>
                            <Tooltip
                                id=""
                                title="Follow As on Instagram"
                                placement={window.innerWidth > 959 ? "top" : "left"}
                                classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                  href=""
                                  target="_blank"
                                  className={classes.navLink}
                              >
                                <i className={classes.socialIcons + " fab fa-instagram"  } />
                              </Button>
                            </Tooltip>
                          </ListItem>
                          )}
                        })()}
                          <ListItem className={classesr.listItem + " " + classesr.onlanguage} key={"10"}>
                          <div className={classes.laguagetrans}>  
                          <LanguageSelector {...props} />
                          </div>
                          </ListItem>
                        </List>
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
              </section>
              <div className={classesr.headbackground + " fixheadermobilehide1"}>
                <Toolbar className={toolBarClasses}>
                  {leftLinks !== undefined ? brandComponent : null}
                  <div className={classes.flex}>
                    {leftLinks !== undefined ? (
                        <Hidden smDown implementation="css">
                          {leftLinks}
                        </Hidden>
                    ) : (
                        
                      <div>
                        {(() => { 
                       //   alert(JSON.stringify(themeInfo.icon))
                            if(themeInfo.icon !== "null" && (themeInfo.icon !== "undefined" && themeInfo.icon.length > 0)) { 
                                    return (
                                      <a href="/">
                                        <img src={icon} height={"70px"} alt={""} className={classes.imglogo} /> 
                                      </a>
                            ) }else{
                              return (
                                   <a href="/">
                                     <h4 className={classes.logoname}>{brandName}</h4> 
                                   </a>
                                )
                              }
                          })()}
                      </div>
                    )}
                  </div>
                  <Hidden smDown implementation="css">
                  {rightLinks}
                  </Hidden>
                  <Hidden mdUp>
                    <div className={classes.langheader}>
                      <div className={classes.laguagetrans}>  
                        <LanguageSelector {...props} />
                      </div>
                    </div>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                    >
                      <Menu />
                    </IconButton>
                  </Hidden>
                </Toolbar>
                <Hidden mdUp implementation="js">
                  <Drawer
                      variant="temporary"
                      anchor={"right"}
                      open={mobileOpen}
                      classes={{
                        paper: classes.drawerPaper
                      }}
                      onClose={handleDrawerToggle}
                  >
                    <div className={classes.appResponsive}>
                      {leftLinks}
                      {rightLinks }
                    </div>
                  </Drawer>
                </Hidden>
              </div>
            
              <Button  
                href="#top"   
                color="transparent"
                className={`${isMobile ? "mobile-class fixheadermobile" : "non-mobile-class fixheadermobile"}`}
              >
                  <i className={"fa fa-arrow-up"}></i> Top
              </Button>     
            </AppBar>
            

  );
}

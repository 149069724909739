import { container } from "../../casino-kit-react.js";
import Background from '../../img/check.png';
import Background1 from '../../img/checki.png';
// import Background2 from '../../img/loginbg.jpg';
import Background3 from '../../img/radio.png';
import Background4 from '../../img/radioi.png';
const profilesetup = {
  imgpopupbox:{
    display:"none",
    background:"rgba(0,0,0,0.6)",
    position:"fixed",
    width:"100%",
    left:"0px",
    top:"0px",
    zIndex:"9999",
    height:"100%", 
  },
  imgpopupboxclose:{ 
    position: "fixed",
    background: "#000",
    fontSize: "20px",
    padding: "10px 15px",
    color: "#fff !important",
    right: "0",
    top: "0",
    margin: "0",
    borderRadius: "0px 0px 0px 0px",
  },
  chipselct:{
 
    width:'100%',
    marginBottom:"30px",
    marginTop:'30px !important',
    "& div":{ 
      float: 'none !important',
     // display: 'flex',
      //width: 'auto !important',
    },
    "& svg":{
      fontSize:'20px'
    },
    "& .MuiFormControl-fullWidth":{
      display: 'block',
      borderBottom:'1px solid #ccc',
    },
    "& div[role=button]":{
      width:'auto !important',
      marginRight:'5px',
      fontSize:'16px'
    },
    ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key
      "& label":{
        paddingTop: "2px !important"
      }
    },
  },
  chipselctwrap:{
    fontSize:'14px !important',
    width:'100% !important', 
    '& .MuiPaper-elevation1':{
      fontSize:'14px !important',
    },
    "& label":{
      marginTop:'-30px',
    },  
    "& .fuse-chip-select__indicators":{
      width:'40px'
    },
    "& .fuse-chip-select__indicator":{
      marginLeft:'-30px',
    },
    "& .fuse-chip-select__indicator:last-child":{
      marginLeft:'0px',
    },
    "& input":{
      marginBottom:'0px !important'
    },
    "& p":{
      margin:'0px !important'
    },
    "& .css-b8ldur-Input":{
      position: 'absolute',
      zIndex: '-1',  
    },
    "& .fuse-chip-select__input":{
      zIndex: '-1',
      position: 'absolute',
    }
  },
  paymethead:{
    fontSize: "18px",
    fontFamily: "ubuntu",
    fontWeight: "600",
    paddingTop: "3px",
    display: "inline-block",
  },
  formdixdde:{
    paddingLeft:"0px"
  }, 


  profilesetup: {
    background: "#f1f1f1",
    padding: "20px 20px",
    margin:"0px",
    position:"fixed",
    width:"100%",
    height:"100%", 
    overflow: "auto",
    ['@media (max-width:580px)']: { // eslint-disable-line no-useless-computed-key
      padding: "20px 0px",
    },
  },   
  hiddeninput:{
    border:"0px",
    "& div:before":{
      border:"0px",
      content: "inherit",
    },
    "& div:after":{
      border:"0px",
      content: "inherit",
    }
  },
  errormsg:{
    color: "#fff",
    padding: "0px 10px",
    background: "#8c0b0b",
    marginBottom: "20px",
    borderRadius: "5px",
    fontFamily: "ubuntu",
    fontSize: "14px",
    lineHeight: "28px", 
  },
  yesnofild:{
    fontFamily:"ubuntu",
    color:"#fff",
    fontSize:"16px",
    padding:"20px 0px 0px",
    verticleAlign:"middle",
    
  },
  securitysetting:{
    padding:"0px 0px 0px"
  }, 
  swither:{
    float:"left !important",
    marginRight: "10px !important",
    width: "56px !important", 
    "& div":{
      float:"left !important",
    },
    ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key
      marginTop: "-31px !important",
    }, 
    ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key
      marginRight: "3px !important",
    }, 
  },
  checkbtitext:{
    float:"left",
    color:"#000",
    width:"82% !important",
  },
  checkbti:{
    width: "40px !important",
    marginTop:"-18px !important",
    "& label":{
      padding:"0px",
      margin:"0px"
    },
    "& label span":{
      padding:"0px"
    },
    "& label span svg":{
      color:"#e8be35 !important"
    }, 
    ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key
      marginTop: "-31px !important",
    }, 
  },
  checkbtitextt:{
    float:"left",
    width:"60% !important",
    color:"#000",
  },  
  checkbtii:{
    width: "40px !important",
    marginTop:"-5px !important",
    "& label":{
      padding:"0px",
      margin:"0px"
    },
    "& label span":{
      padding:"0px"
    },
    "& label span svg":{
      color:"#e8be35 !important"
    }, 
  },
  checkbtimarket:{
    width: "40px !important",
    marginTop: "0px !important",
    float:"left !important",
    height: "58px",
    "& label":{
      padding:"0px",
      margin:"0px"
    },
    "& label span":{
      padding:"0px"
    },
    "& label span svg":{
      color:"#e8be35 !important"
    }, 
  }, 
  popupcheck:{
    height:"50px"
  },
  form:{
    marginLeft:"-10px",
    marginRight:"-10px",
  },
  formdix:{
    borderRadius: "10px", 
    background:"#fff",
    boxShadow: "0px 0px 5px #ccc",
    padding:"10px",
    "& fieldset":{
      border:"0px",
     
    },
    "& form":{
      margin:"0px",     
    },
    "& p":{
      margin: "-10px 0px 10px",
      fontSize: "14px",
      fontFamily: "ubuntu",
    },
    "& input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button ":{
      opacity: "0",  
    },
    "& h4":{
      fontFamily:"ubuntu",
      color:"#000",
      fontSize:"24px",
      textAlign:"center",
      fontWeight: "bold",
      marginBottom: "0",
    },
    "& label":{
      border:"0px",
      color:"#3c3c3c",
      fontSize:"16px",
      transform: "translate(0px, 10px) scale(1)",
      fontFamily:"ubuntu",
      ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key 
        paddingTop: "15px",
      }, 
    },
    "& label.MuiFormLabel-root.Mui-focused":{
      color:"#3c3c3c !important"
    },
    "& input":{
      fontFamily:"ubuntu",
      color:"#3c3c3c",
      fontSize:"14px",
      padding:"20px 0px 10px 0px",
      marginBottom:"20px",
      borderBottom:"1px solid #3c3c3c",
      ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key 
        marginBottom:"10px", 
        fontSize:"14px",
        paddingTop: "30px",
      },
    },
    "& div":{
      width:"100%", 
      marginTop:"0px",
      marginRight: "0px",
      float: "right",
      ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key 
        margin:"0%", 
      },
    },
    "& div div":{ 
      marginTop:"0px"
    },
    ['@media (max-width:1200px)']: { // eslint-disable-line no-useless-computed-key 
      width:"100%", 
      left:"10%",
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
       
      padding:"1% 0%",
      margin:"0px auto",
      float:"none", 
      top:"0%", 
      right:"auto",
    },
    ['@media (max-width:580px)']: { // eslint-disable-line no-useless-computed-key 
      padding:"0% 0%", 
    },
  },
  tablefulywrap:{
    //overflow:"auto"
    ['@media (max-width:580px)']: { // eslint-disable-line no-useless-computed-key 
      overflow:"auto"
    },
  },
  bols:{
    fontWeight:"bold",
    fontSize:"18px"
  },
  formdixdd:{
    paddingRight:"0px",
    ['@media (max-width:580px)']: { // eslint-disable-line no-useless-computed-key 
      padding:"0px", 
    },
  },
  tablefuly:{
    background: "#fff",
    width: "100%",
    borderRadius: "10px",
    boxShadow:"0px 0px 5px #ccc",
    padding:"10px 0px",
    color:"#000",
    ['@media (max-width:580px)']: { // eslint-disable-line no-useless-computed-key 
      width:"500px",
      boxShadow:"0px 0px 0px",
      "& .subtotalprofile td p":{
        fontSize:"16px ​!important",
      }
    },
    "& th":{
      padding:"10px 20px",
      fontSize:"16px",
      ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key 
        padding:"10px 5px",
        fontSize:"14px",
      },
    },
    "& tr:nth-child(even)": {
      backgroundColor: "#f1f1f1",
    },
    "& td":{
      padding:"6px 20px",
      fontSize:"14px",
      ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key 
        padding:"10px 5px",
      },
    },
    "& tr:hover td":{
      background:"#f1f1f1"
    },
    
  },
  headingtag:{
    color:"#e8be35 !important",
    fontSize:"24px !important",
    textAlign:"left !important",
    padding:"0px 15px",
    "& a":{
      color:"#e8be35 !important",
      paddingRight:"10px"
    }
  },
  modalHeader:{
    background:"#fff", 
    border: "1px solid #e8be35",
    borderRadius: "5px 5px 0px 0px",
    borderBottom: "0",
    paddingBottom: "0",
    color:"#000",
  },
  modal:{
    width:"500px"
  },
  modalCloseButton:{
    marginTop: "-20px",
    float: "right",
    marginRight: "-20px",
    "& svg":{
    width: "24px",
    }
  },
  modalBody:{
    background:"#fff",
    border: "1px solid #e8be35",
    borderRadius: "0px 0px 5px 5px",
    borderTop: "0",
    paddingBottom: "30px"
  },
  domaininput:{
    width:"65% !important",
    float: "left !important",
    ['@media (max-width:540px)']: { // eslint-disable-line no-useless-computed-key 
      width:"53% !important", 
    },
    ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key 
      width:"53% !important", 
    },
    "&.domaincl":{
      width:"100% !important",
    },
  },
  domaintext:{ 
    color:"#3c3c3c",
    fontFamily: "ubuntu",
    fontSize: "16px",
    float: "right",
    paddingTop: "12px", 
    ['@media (max-width:1200px)']: { // eslint-disable-line no-useless-computed-key 
      fontSize: "13px", 
    },
    ['@media (max-width:960px)']: { // eslint-disable-line no-useless-computed-key 
      fontSize: "14px", 
    },
    ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key 
      width:"47% !important", 
    },
  },
  maindomaininput:{  
    display:"none"
  },
  radiobtn:{
    fontSize:"18px",
    color:"#3c3c3c",
    fontFamily:"ubuntu",
    backgroundImage:"url(" + Background3 + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left center",
    backgroundSize:"26px",
    padding:"20px 0px 20px 40px",
    "&:hover":{
      backgroundImage:"url(" + Background4 + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
    },
    "&.radiobtn":{
      backgroundImage:"url(" + Background4 + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
    }
  },
  planboxx:{
    display:"block", 
  },
  domainboxx:{
    display:"none"
  },
  radiobtn2:{
    fontSize:"18px",
    color:"#3c3c3c",
    fontFamily:"ubuntu",
    backgroundImage:"url(" + Background3 + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left center",
    backgroundSize:"26px",
    padding:"20px 0px 20px 40px",
    "&:hover":{
      backgroundImage:"url(" + Background4 + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
    },
    "&.radiobtn":{
      backgroundImage:"url(" + Background4 + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
    }
  },
  radiobtn3:{
    fontSize:"18px",
    color:"#fff",
    fontFamily:"ubuntu",
    backgroundImage:"url(" + Background3 + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left center",
    backgroundSize:"26px",
    padding:"20px 0px 20px 40px",
    "&:hover":{
      backgroundImage:"url(" + Background4 + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
    },
    "&.radiobtn":{
      backgroundImage:"url(" + Background4 + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
    }
  },
  radiobtn5:{
    fontSize:"18px",
    color:"#fff",
    fontFamily:"ubuntu",
    backgroundImage:"url(" + Background3 + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left center",
    backgroundSize:"26px",
    padding:"20px 0px 20px 40px",
    "&:hover":{
      backgroundImage:"url(" + Background4 + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
    },
    "&.radiobtn":{
      backgroundImage:"url(" + Background4 + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
    }
  },
  radiobtn6:{
    fontSize:"18px",
    color:"#fff",
    fontFamily:"ubuntu",
    backgroundImage:"url(" + Background3 + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left center",
    backgroundSize:"26px",
    padding:"20px 0px 20px 40px",
    "&:hover":{
      backgroundImage:"url(" + Background4 + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
    },
    "&.radiobtn":{
      backgroundImage:"url(" + Background4 + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
    }
  },
  radiobtn8:{
    fontSize:"18px",
    color:"#000",
    fontFamily:"ubuntu",
    backgroundImage:"url(" + Background3 + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left center",
    backgroundSize:"26px",
    padding:"20px 0px 20px 40px",
    "&:hover":{
      backgroundImage:"url(" + Background4 + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
    },
    "&.radiobtn":{
      backgroundImage:"url(" + Background4 + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
    }
  },
  radiobtn9:{
    fontSize:"18px",
    color:"#000",
    fontFamily:"ubuntu",
    backgroundImage:"url(" + Background3 + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left center",
    backgroundSize:"26px",
    padding:"20px 0px 20px 40px",
    "&:hover":{
      backgroundImage:"url(" + Background4 + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
    },
    "&.radiobtn":{
      backgroundImage:"url(" + Background4 + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
    }
  },
  selectboxxwrap:{ 
    paddingBottom:"10px", 
  },
  
  selectboxx:{ 
    border:"0px",
    width:"100%",
    minWidth:"125px",
    fontSize: "16px",
    fontFamily: "ubuntu",
    padding: "11px 0 6px",
    marginBottom:"20px",
    borderBottom:"1px solid #3c3c3c",
    color:"#3c3c3c",
    ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key 
      marginBottom: "20px !important", 
    },
    "& option":{
      color:"#000",
    },
    "& div div":{
      color: "#fff",
      fontSize: "14px",
      fontFamily: "ubuntu",
      padding:"0px",
    },
    "& svg":{
      color: "rgba(0,0,0,0) !important", 
    }
  },
  seltbox:{
    margin:"0px",
  },
  clerfix:{
    clear:"both",
    float: "none !important",
  },
  radiobtn7:{
    fontSize:"18px",
    color:"#fff",
    fontFamily:"ubuntu",
    backgroundImage:"url(" + Background3 + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left center",
    backgroundSize:"26px",
    padding:"20px 0px 20px 40px",
    "&:hover":{
      backgroundImage:"url(" + Background4 + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
    },
    "&.radiobtn":{
      backgroundImage:"url(" + Background4 + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
    }
  },
  radiobtn4:{
    fontSize:"18px",
    color:"#fff",
    fontFamily:"ubuntu",
    backgroundImage:"url(" + Background3 + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left center",
    backgroundSize:"26px",
    padding:"20px 0px 20px 40px",
    "&:hover":{
      backgroundImage:"url(" + Background4 + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
    },
    "&.radiobtn":{
      backgroundImage:"url(" + Background4 + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
    }
  },
  selectbox:{
    background:"none",
    border:"0px",
    width: "100%",
    color: "#fff",
    fontFamily: "ubuntu",
    fontSize: "16px",
    marginTop: "20px",
    borderBottom: "1px solid #fff",
    paddingBottom: "20px",
    "& option":{
      color:"#333",
    },
  },

  designbox:{
    "& img":{
      width:"100%",
    }
  },




  custom_fileu:{
    opacity:"0",
    width:"0px",
  },
  custom_fileimg:{
    width:"100px",
  },
  field:{
    margin: "5px",
    display: "flex",
    flexDirection: "column",
    "& input[type='file']": {
      display: "none",
    }
  },
  custom_file_upload: { 
    display: "inline-block",
    position: "relative",
    padding: "6px",
    cursor: "pointer",
    background: "#000",
    borderRadius: "10px",
    marginBottom: "0px",
    height: "100px",
  },
  
  img_wrap:{
    height: "90px",
    overflow: "hidden",
    position: "relative",
    borderRadius: "10px",
    width: "auto !important",
  },
  img_upload:{
    "&:before":{
    content: "\f093",
    fontSize: "90px",
    position: "absolute",
    paddingTop: "80px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    color: "#63d3a6",
    width: "200px",
    height: "200px",
    borderRadius: "50%",
    opacity: "0",
    transition: ".5s ease",
    backgroundColor: "#fff",
  }
  },  
  centerbtnb:{
    display: "block !important",
    width: "99.7% ",
    borderRadius: "0 !important",
    float: "left !important",
    border: "0 !important",
  },
  signinmodalbtn:{
    //color: "#863304 !important",
    //background: "radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #d29817 30%, #bf8315 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #d2a732 25%, #5d4a1f 62.5%, #f5c761 100%)",
    backgroundImage: "linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07)",
    border: "1px solid #a55d07",
    color: "rgb(120,50,5) !important",
    textShadow: "0 2px 2px rgba(250, 227, 133, 1)",
    margin: "0px auto",
    borderRadius: "50px",
    padding: "10px 30px",
//    textShadow: "2px 2px 1px #bb7e15",
    fontFamily: "ubuntu",
    fontWeight: "600",
    fontSize: "16px",
    display: "inherit", 
    float:"right",
    "&:hover":{
      backgroundImage: "linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07)",
      border: "1px solid #a55d07",
      color: "rgb(120,50,5) !important",
    },
    "&:focus":{
      backgroundImage: "linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07)",
      border: "1px solid #a55d07",
      color: "rgb(120,50,5) !important",
    }
  },
  signinmodalbtn2:{
   // color: "#863304 !important",
  //  background: "radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #d29817 30%, #bf8315 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #d2a732 25%, #5d4a1f 62.5%, #f5c761 100%)",
    backgroundImage: "linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07)",
    border: "1px solid #a55d07",
    color: "rgb(120,50,5) !important",
    textShadow: "0 2px 2px rgba(250, 227, 133, 1)",
    margin: "0px auto 0px",
    borderRadius: "50px",
    padding: "10px 30px",
//    textShadow: "2px 2px 1px #bb7e15",
    fontFamily: "ubuntu",
    fontWeight: "600",
    fontSize: "16px",
    display: "inherit", 
    float:"right",
    "&:hover":{
      backgroundImage: "linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07)",
      border: "1px solid #a55d07",
      color: "rgb(120,50,5) !important",
    },
    "&:focus":{
      backgroundImage: "linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07)",
      border: "1px solid #a55d07",
      color: "rgb(120,50,5) !important",
    }
  },
  signinmodallast:{
    ['@media (max-width:540px)']: { // eslint-disable-line no-useless-computed-key
      width:"100%",
      marginTop:"10px"
    }, 
  },
  action:{
    ['@media (max-width:540px)']: { // eslint-disable-line no-useless-computed-key
      width:"100%",
    },
  },
  planbox:{
    border: "1px solid #fff",
    background: "#fff",
    borderRadius: "15px",
    boxShadow:"0px 0px 5px #ccc",
    padding: "10px",
    marginBottom:"20px",
    color: "#fff",
    fontFamily:"ubuntu",
    position:"relative",
    textAlign:"center",
    "&:hover":{
      border: "1px solid #e8be35",
    },
    "& h4":{
      fontFamily:"ubuntu",
      fontSize:"22px",
      marginTop: "25px"
    },
    "& h5":{
      fontFamily:"ubuntu",
      fontSize:"14px",
      color:"#848484"
    },
    "& ul":{
      listStyle:"none",
      padding:"0px",
      margin:"0px",
    },
    "& ul li":{
      borderTop:"1px solid #151515",
      padding:"5px 0",
      fontSize: "12px",
      color:"#cacaca"
    },
    "&:hover .planboxcheck":{
      backgroundImage:"url(" + Background1 + ")",
    },
    "&:hover .planboxchecked":{
      backgroundImage:"url(" + Background1 + ")",
    },
    "& img":{
      ['@media (max-width:1200px)']: { // eslint-disable-line no-useless-computed-key
        width:"100%",
      },  
    },
    ['@media (max-width:960px)']: { // eslint-disable-line no-useless-computed-key
      marginBottom:"10px !important",
    },
  },
  planboxcheck:{
    backgroundImage:"url(" + Background + ")",
    width:"30px !important",
    height:"40px",
    position:"absolute",
    top:"3px",
    left:"8px",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  planboxchecked:{
    backgroundImage:"url(" + Background1 + ")",
    width:"30px !important",
    height:"40px",
    position:"absolute",
    top:"3px",
    left:"8px",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  choosedesign:{

  },
  container,
  textCenter: {
    textAlign: "center"
  }, 
  
};

export default profilesetup;

import * as UserActions from './user.actions';
import jwtService from '../../../services/jwtService';
//import {setUserData} from './user.actions';
//import * as Actions from '../../../store/actions';

export const WLLOGIN_ERROR = 'WLLOGIN_ERROR';
export const WLLOGIN_SUCCESS = 'WLLOGIN_SUCCESS';


export function wlsubmitLogin({username, password, recaptcha},props)
{

//const {...rest} = props;
//const currentLocation = props.location.pathname.replace("/","");

    return (dispatch) =>
        jwtService.WlsignInWithEmailAndPassword({username, password, recaptcha})
            .then((user) => { 
                UserActions.setWlUserData(user.data);  
                    return dispatch({
                        type: WLLOGIN_SUCCESS,
                        payload: user 
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type   : WLLOGIN_ERROR,
                    payload: error
                });
            });
}

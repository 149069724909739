import React from "react";

// nodejs library that concatenates classes

// core elements 

import './webroot/App.css';
import Login from "./pages/login.js";
import ProfileSetup from "./pages/profileSetup.js";
import Wllogin from "./pages/wllogin.js";
import WlSuccess from "./pages/wlSuccess.js";
import WlFail from "./pages/wlFail.js";
import Otp from "./pages/otp.js";
import {Route } from "react-router-dom";
import VerifyEmail from "./pages/verifyemail";
import VerifyPhone from "./pages/verifyphone";

import themeDb from "./data/theme.js";  
//import "./assets/scss/material-kit-react.scss?v=1.8.0";

import  "./i18n";




 

export default function LandingPage(props) {

    // const { ...rest} = props;
    const { ...myTheme} = themeDb;

    
    return (

        <div>
 
 

            <Route exact path="/">
                <Login themeColors={myTheme} />
            </Route> 
            <Route exact path="/profilesetup">
                <ProfileSetup themeColors={myTheme} />
            </Route>
            <Route path="/verifyemail">
                <VerifyEmail themeColors={myTheme}  {...props} />
            </Route>
            <Route path="/verifyphone">
                <VerifyPhone themeColors={myTheme}  {...props} />
            </Route>
            <Route exact path="/wlsuccess">
                <WlSuccess themeColors={myTheme} />
            </Route>
            <Route exact path="/wlfail">
                <WlFail themeColors={myTheme} />
            </Route>
            <Route exact path="/otp">
                <Otp themeColors={myTheme} />
            </Route>
            <Route exact path="/wllogin">
                <Wllogin themeColors={myTheme} />
            </Route>
        </div>
    );
}





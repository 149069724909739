// import React from 'react';
import login from './pages/login';
import setupprofile from './pages/profileSetup';

// import {authRoles} from '../../auth';

  

const LoginPageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/',
            component: login
        }
    ]
};
const SetupProfileConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/profilesetup',
            component: setupprofile
        }
    ]
};
export const pagesConfigs = [
    LoginPageConfig, 
    SetupProfileConfig,
];

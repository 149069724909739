import React from "react"; 
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import 'react-accessible-accordion/dist/fancy-example.css'; 
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js"; 
import Button from "@material-ui/core/Button";
import styles from "../assets/jss/components/tabsPromotionstyle.js";
import axios from 'axios'; 
import Slide from "@material-ui/core/Slide"; 
import queryString from 'query-string';
import * as globalurl from '../../../global/Global.js';  

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);

Transition.displayName = "Transition";
var webServiceRunning = false;
export default function Verifyemail(props) {
    const classes = useStyles();
    let params = queryString.parse(props.location.search);
    styles.messagebox.background = props.themeColors.bgColors.messagebox;
    styles.listItem["&:nth-child(even)"].background = props.themeColors.bgColors.messageboxlist;
    styles.listItem.color = props.themeColors.bgColors.messageboxlistcolor;
    const [allDetails, SetAllDetails] = React.useState({});
    const [isGettingSuccess, SetIsGettingSuccess] = React.useState(false);
    var donePayment = false;
    
    if(donePayment === false && params != null && params.amount && params.hash && params.status && params.txid && webServiceRunning === false){
        validateTransaction();
    }

    function validateTransaction(){
        webServiceRunning = true;
        const request = axios.post(globalurl.VALIDATEPAY10, {
            ref_no: ((params.txid) ? params.txid : ""),
            token: window.localStorage.getItem('jwt_access_token')
        });
          request.then((response) => {
              donePayment = true;
              if(response != null && response.data != null && response.data.status === true){
                SetIsGettingSuccess(true);
                SetAllDetails(response.data.data);
              }else{
                SetIsGettingSuccess(false);
                SetAllDetails({});
              }
              webServiceRunning = false;
          });
          request.catch((error) => {
            webServiceRunning = false;
          });
      }

    function parseJwt(token) {
        if (!token) { return; }
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }

    function getCurrentThemeInfo(){
        if(window.localStorage.getItem('jwt_theme_token') != null){
            let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
            return themeData;
        }
      } 

    var icon = null;
    var brandName = null;
    var themeInfo = getCurrentThemeInfo(); 
    //alert(JSON.stringify(themeInfo))
    if(themeInfo != null && themeInfo.id != null){
        if(themeInfo.brand_name != null && themeInfo.brand_name.length > 0){
          brandName = themeInfo.brand_name;
        }
        if(themeInfo.icon != null && themeInfo.icon.length > 0){
          icon = themeInfo.icon;
        } 
    } 
    
    return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{textAlign:"center", color:"#fff"}}>
               
                {(() =>  {
                    if(isGettingSuccess === true){
                        return(
                            <div className={classes.successwrap}> 
                                <div style={{textAlign:'center'  }}>
                                <i style={{fontSize:'80px', marginTop:"30px", color:'green',  }} className="fa fa-check"></i>
                                <h4 style={{color:'green', }}>Success</h4> 
                                <table className={classes.tablebox}>
                                    <tr>
                                        <td>
                                            Transaction Id
                                        </td>
                                        <th>
                                            {((params && params.txid) ? params.txid : "Invalid Transaction")}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            Amount
                                        </td>
                                        <th>
                                        {((params && params.amount) ? params.amount : "0.00")}
                                        </th>
                                    </tr>
                                </table>
                                <p>Thank you, Your transaction completed successfully.</p>
                            </div>
                                        
                            </div>

                        )
                    }else{
                        return(
                            <div className={classes.failwrap}> 
                                <div style={{textAlign:'center'  }}>
                                <i style={{fontSize:'80px', marginTop:"30px", color:'red',  }} className="fa fa-times"></i>
                                <h4 style={{color:'red', }}>Failed</h4> 
                                <table className={classes.tablebox}>
                                    <tr>
                                        <td>
                                            Transaction Id
                                        </td>
                                        <th>
                                        {((params && params.txid) ? params.txid : "Invalid Transaction")}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            Amount
                                        </td>
                                        <th>
                                        {((params && params.amount) ? params.amount : "0.00")}
                                        </th>
                                    </tr>
                                </table>
                                <p>Sorry, Your transaction failed. Please try again. If payment deducted from account please contact to sponsor.</p>
                            </div>
                                        
                            </div>
                        
                        )
                    }
                })()} 
               
                </GridItem>
            </GridContainer>
  
    );
  }
  
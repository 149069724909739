import { container } from "../../casino-kit-react.js";
const Offer = {
    offerpage:{
        padding:"0px 15px",
        "& h3":{
            padding:"0px",
            margin:"10px 0",
            textAlign: "center",
            fontWeight: "bold",
            color: "#fff",
        }
    }, 
    offer_box:{
        textAlign:"center", 
       "& img":{
        borderRadius:"10px",
        width:"100%"
       },
       "& p":{
           padding:"5px 0",
           margin:"0 0px 5px",
           fontSize:"14px",
           color:"#fff"
       } 
    }
};

export default Offer;

import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Icon from "@material-ui/core/Icon";
// core components
import Card from "../Card/Card.js";
import CardBody from "../Card/CardBody.js";
import CardHeader from "../Card/CardHeader.js";

import styles from "../../assets/jss/components/customTabsStyle.js";



export default function CustomTabs(props) {
    const { headerColor, plainTabs, tabs, title, rtlActive, border, themeColors, clickEvent , selectedTab, setSelectedTab, currentPosition} = props;

    
    
    styles.tabRootButton.color = props.themeColors.bgColors.text_primary_color;
    styles.tabSelected.backgroundColor = props.themeColors.bgColors.primary_dark_bgcolor;
     styles.tabSelected.background = props.themeColors.bgColors.primary_dark_bgcolor;
     styles.tabSelected.borderBottomColor = props.themeColors.bgColors.primary_dark_bgcolor;
     styles.tabSelected.color = props.themeColors.bgColors.text_primary_color;
    if(themeColors != null){
      //  styles.tabRootButton.backgroundColor = themeColors.bgColors.lightblackbg;
      styles.tabRootButton.fontFamily = props.themeColors.bgColors.base_font  
    }
    if(themeColors != null){
      //  styles.tabSelected.background = themeColors.bgColors.golden;
    }
    if(themeColors != null){
    //  styles.tabSelected.color = themeColors.bgColors.goldenbtntext;
  }
    
  const useStyles = makeStyles(styles);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, value) => {
    
    if(currentPosition != null){
      if(currentPosition >= value){
        setValue(value);
      }
      
    }else{
      setValue(value);
    }
  };
  if(selectedTab != null && selectedTab >= 0 && value !== selectedTab){
   
    setSelectedTab(selectedTab);
    setValue(selectedTab);
    
  }
  
  const handleClick = (value) => {
    if(clickEvent != null){
      clickEvent(value);
    }
  };


  const classes = useStyles();

  const cardTitle = classNames({
    [classes.cardTitle]: true,
    [classes.cardTitleRTL]: rtlActive
  });
  return (



    <Card plain={plainTabs}>
 

        <CardHeader color={headerColor} border={border} plain={plainTabs}>
        {title !== undefined ? <div className={cardTitle}>{title}</div> : null}
        <Tabs
          value={value}
          onChange={handleChange}
         
          classes={{
            root: classes.tabsRoot, 
            indicator: classes.displayNone
          }}
        >
          {tabs.map((prop, key) => {
            var icon = {};
            if (prop.tabIcon) {
              icon = {
                icon:
                  typeof prop.tabIcon === "string" ? (
                    <Icon>{prop.tabIcon}</Icon>
                  ) : (
                    <prop.tabIcon />
                  )
              };
            }
            return (
              <Tab
                classes={{
                  root: classes.tabRootButton,
                  label: classes.tabLabel,
                  selected: classes.tabSelected,
                  wrapper: classes.tabWrapper
                }}
                key={key}
                onClick={()=>handleClick(key)}
                label={prop.tabName}
                {...icon}
              />
            );
          })}
        </Tabs>
      </CardHeader>
      <CardBody>
        {tabs.map((prop, key) => {
          if (key === value) {
            return <div key={key}>{prop.tabContent}</div>;
          }
          return null;
        })}
      </CardBody>
    </Card>
  );
}

CustomTabs.propTypes = {
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose"
  ]),
   border: PropTypes.oneOf([
        "round",
        "square"
   ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool
};

import React from "react";
// @material-ui/core components
import 'react-accessible-accordion/dist/fancy-example.css';

import { makeStyles } from "@material-ui/core/styles"; 
import styles from "../assets/jss/components/tabsOfferstyle.js";
import Slide from "@material-ui/core/Slide";  
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js"; 
import Zoom from '@material-ui/core/Zoom';

import Offer1 from "../assets/img/offer1.jpg"
import Offer2 from "../assets/img/offer2.jpg"
import Offer3 from "../assets/img/offer3.jpg"
import Offer4 from "../assets/img/offer4.jpg"
import Offer5 from "../assets/img/offer5.jpg"

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";
export default function Offer(props) {
 
    const classes = useStyles();
    return (
          <div id="nav-tabs"  className={classes.offerpage} >
            <h3>Offers</h3>
            <GridContainer style={{marginLeft:"-5px", marginRight:"-5px"}}>   
              <GridItem  xs={6} sm={6} md={3} style={{paddingLeft:"5px", paddingRight:"5px"}}>
                <Zoom in={true} timeout={1000}> 
                  <div className={classes.offer_box}>
                    <img src={Offer1} alt=""/>
                    <p>Expired : 13-04-2021</p>
                  </div>
                </Zoom>
              </GridItem>
              <GridItem  xs={6} sm={6} md={3} style={{paddingLeft:"5px", paddingRight:"5px"}}>
                <Zoom in={true} timeout={1000}> 
                  <div className={classes.offer_box}>
                    <img src={Offer2} alt=""/>
                    <p>Expired : 16-04-2021</p>
                  </div>
                </Zoom>
              </GridItem>
              <GridItem  xs={6} sm={6} md={3} style={{paddingLeft:"5px", paddingRight:"5px"}}>
                <Zoom in={true} timeout={1000}> 
                  <div className={classes.offer_box}>
                    <img src={Offer3} alt=""/>
                    <p>Expired : 21-05-2021</p>
                  </div>
                </Zoom>
              </GridItem>
              <GridItem  xs={6} sm={6} md={3} style={{paddingLeft:"5px", paddingRight:"5px"}}>
                <Zoom in={true} timeout={1000}> 
                  <div className={classes.offer_box}>
                    <img src={Offer4} alt=""/>
                    <p>Expired : 26-05-2021</p>
                  </div>
                </Zoom>
              </GridItem>
              <GridItem  xs={6} sm={6} md={3} style={{paddingLeft:"5px", paddingRight:"5px"}}>
                <Zoom in={true} timeout={1000}> 
                  <div className={classes.offer_box}>
                    <img src={Offer5} alt=""/>
                    <p>Expired : 13-06-2021</p>
                  </div>
                </Zoom>
              </GridItem>
            </GridContainer>
          
          </div>
  
    );
  }
  
import {combineReducers} from 'redux';
import casino from './casino';
import auth from '../../auth/casino/reducers';

const createReducer = (asyncReducers) =>
    combineReducers({
        auth,
        casino,
        ...asyncReducers
    });

export default createReducer;

import { defaultFont } from "../../casino-kit-react";

import tooltip from "../kit/tooltipsStyle.js";

const headerLinksStyle = ({
  modalCloseButton:{
    position: "absolute",
    right: "0px",
    zIndex: "9",
    top: "0",
    color: "#fff",
  },
  tableheading:{
    fontWeight:"bold",
    color: "#fff !important",
    background:"#000 !important"
},
wraptable:{
  padding:"10px"
},
list:{
  width:"100%",
  display:"block"
},
transactionsubtotal:{
 borderTop:"1px dotted #ccc"
}, 
resulttablebox:{  
  overflow:"auto",
  padding:"10px",
  "& table":{
    whiteSpace: "nowrap",
    width:"100%",
    fontFamily:"ubuntu",
    color:"#ccc",
    fontSize:"14px", 
    "& tr th":{
      padding:"10px",
      background:"#0f0f0f",
    },
    "& tr td":{
      padding:"3px",
      fontSize:"14px",
      color:"#fff",
      background:"#2b2b2b",
    }
  },
},
listItemss:{
    width:"100%",
    paddingTop:"15px",
    paddingBottom:"15px",
    display:"block",
    color:"#fff",
    fontFamily:"ubuntu",
    fontSize:"18px",
    "&.listItemfooter":{
      textAlign:"right"
    },
    "&.listItemfooter p":{
      padding:"5px 0px"
    },
    "& a":{
        margin:"0px",
    },
    "& p":{
        margin:"0px",
    },
    "&:nth-child(even)":{
        background:"#2d2d2d"
    },
    "&:nth-child(odd)":{
        background:"none"
    }
},
  resendbtn:{
    color:"#efde1d"
  },
  signinsignup:{
    color:"#efde1d"
  },
  receptadiv:{
    marginLeft:"-15px",
    marginTop:"10px",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      transform: "scale(0.77)",
      transformOrigin:" 0 0",
    },
  },
  signcaptcha:{
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      transform: "scale(0.77)",
      transformOrigin:" 0 0",
    },
  },
  logoname:{
    color:"#ffffff",
    margin: "17px 0 0 10px",
    fontFamily: "Ubuntu",
    fontWeight: "500"
  },
  tnctext:{
    fontSize:"12px",
    fontFamily:"'Open Sans'",
    color:"#fff",
    "& a":{
      color:"#efde1d"
    }
  },
  testlabel:{
    color:"#fff !important",
  },
  formggroup:{
    marginBottom:"20px"
  },
  verifyheader:{
    minWidth:"300px !important",
    background:"#2b2b2b !important",
  },
  verifybody:{
    minWidth:"300px !important",
    background:"#2b2b2b !important",
  },
  verifyfooter:{
    minWidth:"300px !important",
    background:"#2b2b2b !important",
  },
  msg:{
    
  },
  tabwrap:{
    
  },
  topfiix:{
    top: "auto !important",
    height: "100%",
  },
  redtext:{
    color:"#ff0026",
  },
  greentext:{
    color:"#00ff1d",
  },
  accountsheads:{
    color: "#ffffff",
    padding: "0px 10px",
    fontSize: "18px",
    margin: "10px 0",
  },
  borderbox:{
    border:"1px solid #000",
    "& p":{
      padding:"3px 0"
    }
  },
  tableresponsive:{
    overflow:"auto",
    "& ul":{
      minWidth:"700px"
    }
  },
  centerheading:{
    color: "#ffffff",
    padding: "0px 10px",
    fontSize: "16px",
    margin: "20px 0 10px",
    textAlign:"center"
  },
  errormsg:{
    color: "#fff",
    margin: "0px 0px 5px",
    fontSize: "12px",
    background: "#bb2121",
    fontFamily: "ubuntu",
    padding: "0px 10px",
    borderRadius: "5px", 
    lineHeight:"30px"
  },
  normaltxt:{
    color: "#fff",
    textAlign: "center"
  },
  alignright:{
    textAlign:"right",
  },
  signupfor:{ 
    float: "right !important",
    lineHeight: "24px",
  },
  formgroupwidthlabel:{
    "& label":{
      fontFamily:"ubuntu",
      fontWeight:"normal",
      fontSize:"12px !important",
      transform: "none !important",
      color:"#ccc",
      paddingBottom:"10px",
      display:"block"   
    },
    "& select":{
      border:"1px solid #4f4f4f",
      fontFamily:"ubuntu",
      fontWeight:"normal",
      fontSize:"12px",
      color:"#ccc",
      padding:"10px",
      width:"100%",
      background:"none",
      display:"block"   
    },
    "& input":{
      border:"1px solid #4f4f4f",
      fontFamily:"ubuntu",
      fontWeight:"normal",
      fontSize:"12px",
      color:"#ccc",
      padding:"8px",
      width:"100%",
      background:"none",
      display:"block"   
    }
  },
  formbtn:{
    background:"#efde1d",
    color:"#000",
    textAlign:"center",
    fontFamily:"ubuntu",
    fontWeight:"bold",
    fontSize:"14px", 
    padding:"9px 20px",
    width:"100%",
    margin:"0",
  },
  successmsg:{
    color: "#fff",
    margin: "0px 0px 5px",
    fontSize: "12px",
    background: "#15a912",
    fontFamily: "ubuntu",
    padding: "0px 10px",
    borderRadius: "5px", 
    lineHeight:"30px"
  },
  clearfix:{
    clear:"both",
    paddingBottom:"7px",
  },
  onlypayment:{
      padding:"30px 30px 10px",
      margin: "0",
      "& p":{
        color:"#fff"
      }
  },
  form:{
    margin:"0px",
    "& fieldset":{
      borderColor: "#ffffff !important",
      borderRight:"0px",
      borderLeft:"0px",
      borderTop:"0px",
      borderRadius:"0px"
    },
    "& input":{
      color: "#ffffff !important", 
      background:"none",
      fontSize:"14px",
      paddingLeft:"0px",
      paddingRight:"0px",
      fontFamily:"ubuntu",
      tapHighlightColor:"#000",
    },
    "& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus":{
      backgroundColor: "#ff0000 !important", 
      background:"#ff0000 !important",
      border: "0px" ,
      textFillColor: "#ff0000",
      color: "#ff0000",
      boxShadow: "0 0 0px" ,
      tapHighlightColor:"#ff0000",
    },
    "& input:-internal-autofill-selected":{
      backgroundColor: "#ff0000 !important", 
      background:"#ff0000 !important",
      border: "0px" ,
      textFillColor: "#ff0000",
      color: "#ff0000",
      boxShadow: "0 0 0px" ,
      tapHighlightColor:"#ff0000",
    },
    "& label":{
      color: "#AAAAAA",
      fontSize:"14px",
      fontFamily:"ubuntu",
      transform: "translate(0px, 20px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink":{
      transform: "translate(0px, -4px) scale(0.9)",
    },
    "& div":{
      width: "100%",
    },
    "& .MuiFormLabel-root.Mui-focused":{
      color: "#AAAAAA", 
    }
  },
  responsivemodell:{
    padding:"2px",
    background:"none",
  //  backgroundImage: "linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07) !important",
    ['@media (max-width:710px)']: { // eslint-disable-line no-useless-computed-key
      width:"100%",
      maxWidth:"100%"
    }, 
   
  },
  responsivemodelllogin:{
    padding:"2px",
    maxWidth:"360px",
    background:"none",
 //   backgroundImage: "linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07) !important",
    ['@media (max-width:710px)']: { // eslint-disable-line no-useless-computed-key
      width:"100%",
      maxWidth:"100%"
    }, 
   
  },
  responsivemodellAllpro:{
    padding:"2px",
    background: "none",
    maxWidth: "98% !important",
    margin: "0",
    width: "100%",
    minHeight:"90%"
    
  },
  responsivemodellonlinepay:{
    padding:"2px",
    background: "none",
    maxWidth: "98% !important",
    margin: "0",
    width: "310px",
    minHeight:"auto"
    
  },
  modalHeaderprofile:{
    padding:"0px",
  },
  modalBodyprofile:{
    padding:"0px",
    background: "#2b2b2b !important",
  },
  modellogo:{
    height:"70px",
  },
  modalhide:{
    display:"none !important"
  },
  modalHeader:{
    background:"#2b2b2b",
    textAlign:"center",
    minWidth:"596px",
    position:"relative",
    paddingBottom:"0px",
    borderRadius:"4px 4px 0 0",
    ['@media (max-width:710px)']: { // eslint-disable-line no-useless-computed-key
      minWidth:"100%",
    },
  },
  modalHeaderonlinepay:{ 
    minWidth:"auto", 
  },
  modalHeaderlogin:{
    background:"#2b2b2b",
    textAlign:"center",
    minWidth:"338px",
    position:"relative",
    paddingBottom:"0px",
    borderRadius:"4px 4px 0 0",
    ['@media (max-width:710px)']: { // eslint-disable-line no-useless-computed-key
      minWidth:"100%",
    },
  },
  forgotinmodalbtn:{
    width:'100% !important',
    marginLeft:'0px !important',
    marginRight:'0px !important',
  },
  signupbixp:{
    color: "#a1aed4",
    float:"left",
    marginBottom:"0",
    marginTop:"10px",
  },
  selectboxx:{
    background:"none !important",
    "& div div":{
      color: "#fff",
      fontSize: "18px", 
      fontFamily: "ubuntu",
      padding: "15px 50px 13px 10px",
    },
    "&.cutcountry div div span":{
      display:"none"
    },
    "&.cutcountry div div ":{
      padding:"12px 0px 12px",
      minHeight: "30px"
    }, 
    "&.cutcountry div svg":{
      fontSize: "25px",
      margin: "5px 5px 0px 0px",
    } 
  },
  modalBody:{
    background:"#2b2b2b",
    position:"relative",
    minWidth:"596px",
    borderRadius:"0 0 4px 4px",
    ['@media (max-width:710px)']: { // eslint-disable-line no-useless-computed-key
      minWidth:"100%",
    },
  },
  modalBodyonlinepay:{ 
    minWidth:"auto", 
  },
  modalBodylogin:{
    background:"#2b2b2b",
    position:"relative",
    minWidth:"338px",
    borderRadius:"0 0 4px 4px",
    ['@media (max-width:710px)']: { // eslint-disable-line no-useless-computed-key
      minWidth:"100%",
    },
  },
  modalFooter:{
    minWidth:"596px",
    background:"#2b2b2b",
    textAlign:"center",
    fontFamily: "Ubuntu",
    display: "block !important",
    borderRadius:"0 0 4px 4px",
    ['@media (max-width:710px)']: { // eslint-disable-line no-useless-computed-key
      minWidth:"100%",
    },
  },
  modalFooterlogin:{
    minWidth:"338px",
    background:"#2b2b2b",
    textAlign:"center",
    fontFamily: "Ubuntu",
    display: "block !important",
    borderRadius:"0 0 4px 4px",
    ['@media (max-width:710px)']: { // eslint-disable-line no-useless-computed-key
      minWidth:"100%",
    },
  },
  modalSubtitle:{
    fontSize: "17px",
    lineHeight: "22px",
    fontWeight: "300",
    margin: "0px",
    color: "#a1aed4",
    fontFamily: "Ubuntu",
  },
  signupbix:{
    color: "#fff",
    textDecoration:"none",
    fontSize:"13px",
    padding: "0px",
    fontFamily: "ubuntu",
    verticalAlign: "text-bottom",
    "& i":{
      marginRight:"10px"
    }
  },
  modalTitle:{
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight:" 700",
    color: "#8e8e8e",
    textTransform: "uppercase",
    margin: "10px 0px 0px",
    marginBottom: "0px",
    fontFamily: "Ubuntu",
  },
  signinmodalbtn:{
    display: "block",
    width: "90%",
    fontSize: "16px",
    textTransform: "uppercase",
    fontWeight: "600",
    padding: "7px 35px",
    color: "#000 !important",
    border: "0px",
    borderRadius: "3px",
    cursor: "pointer",
    margin:"0px 5% 20px",
    transition: "all 0.3s ease-in",
    background: "#efde1d",
    "&:hover":{
      background: "#efde1d",
    },
  }, 
  signinmodalbtnkyc:{
    display: "block",
    width: "90%",
    fontSize: "14px",
    textTransform: "uppercase",
    fontWeight: "600",
    padding: "7px 35px",
    color: "#fff !important",
    border: "0px",
    borderRadius: "3px",
    cursor: "pointer",
    margin:"10px 5% 20px",
    transition: "all 0.3s ease-in",
    background: "#ff3342",
    "&:hover":{
      background: "#ff3342",
      color: "#fff !important",
    },
    "&:focus":{
      background: "#ff3342",
      color: "#fff !important",
    },
  }, 
  signinbtn:{
    fontSize: "16px",
    textTransform: "uppercase",
    fontWeight: "600",
    padding: "14px 35px",
    display: "inline-block",
    color: "#fff",
    border: "0px",
    borderRadius: "50px",
    boxShadow:"0px 0px 0px",
    cursor:" pointer",
    marginTop: "16px",
    transition: "all 0.3s ease-in",
    backgroundImage: "linear-gradient(to left, #ff344d, #d2273c)",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      padding: "14px 25px",
      marginLeft:"5%",
      width:"90% !important",
    },
    "&.onlinepay":{
      padding: "10px 35px"
    }
  },
  aadharcardbox:{
    width:"100%", 
  },
  pancardbox:{
    width:"100%", 
  },
  dlcardbox:{
    width:"100%", 
  },
  signupbtnnew:{
    fontSize: "16px",
    textTransform: "uppercase",
    fontWeight: "600",
    padding: "14px 35px",
    display: "inline-block",
    color: "#fff",
    border: "0px",
    borderRadius: "50px",
    boxShadow:"0px 0px 0px",
    cursor:" pointer",
    marginTop: "16px",
    transition: "all 0.3s ease-in",
    backgroundImage: "linear-gradient(to left, #ff344d, #d2273c)",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      padding: "14px 25px",
      marginLeft:"5%",
      width:"90% !important",
    },
  }, 
  list: {
    ...defaultFont,
    fontSize: "14px",
    margin: 0,
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: "inherit"
  },
  norecordimg:{
    ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
      margin: "0px !important",
      height: "110px",
    },
  },
  uploadkyccs:{
    ['@media (max-width:760px)']: {// eslint-disable-line no-useless-computed-key
    "& label":{ 
      padding:"10px 0" 
    },
    "& .selectbox":{ 
        marginBottom:"10px" 
    },
    "& .selectbox div div":{ 
        padding:"0 0 10px"  
    },
    "& .signinbtn":{ 
        width: "100%", 
        margin: "0px 0% 0px", 
    }
   }
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      width: "100%",
      "&:after": {
        width: "calc(100% - 30px)",
        content: '""',
        display: "block",
        height: "1px",
        marginLeft: "15px",
        backgroundColor: "#e5e5e5",
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
          background: "none",
        },
      }
    },
  },

  listItemText: {
    padding: "0 !important"
  },
  navLink: {
    fontFamily: 'Open Sans',
    color: "#bdbdbd",
    position: "relative",
    padding: "28px 20px",
    fontWeight: "600",
    fontSize: "16px",
    textTransform: "uppercase",
    borderRadius: "3px",
    lineHeight: "26px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: "inherit",
      background: "rgba(200, 200, 200, 0.2)"
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      color:"#bdbdbd !important",
      padding: "5px 0px",
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start"
      }
    },
  },
  notificationNavLink: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    top: "4px"
  },
  registerNavLink: {
    top: "3px",
    position: "relative",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex"
  },
  navLinkActive: {
    color: "inherit",
    backgroundColor: "rgba(255, 255, 255, 0.1)"
  },
  icons: {
    width: "20px",
    height: "20px",
    marginRight: "3px"
  },
  socialIcons: {
    position: "relative",
    fontSize: "20px !important",
    marginRight: "4px",
    marginTop: "5px",
  }, 
  dropdownLink:{ 
    textAlign:"left",
    borderTop:"1px solid #7b7b7b",
    margin:"0px",
    fontFamily:"ubuntu",
    fontSize:"16px",
    "&, &:hover, &:focus": {
      color: "inherit",
      textDecoration: "none",
      display: "block",
      padding: "10px 20px",  
    },
    "&:first-child":{
      borderTop:"0px"
    }
  },
  ...tooltip,
  marginRight5: {
    marginRight: "5px"
  },
  checkboxin:{
    "& p":{
      float:"left",
      color:"#fff",
      fontFamily:"ubuntu",
      lineHeight:"40px"
    },
    "& span":{
      color:"#ffb80c"
    },
    "& p a":{
      color:"#ffb80c",
      textDecoration:"none"
    },
    "& label":{
      margin:"-27px 0px 0px"
    }
  },
  checkboxx:{
    float:"left",
    width:"auto !important",
    "& p":{
      position: "absolute",
      width: "190px",
      display: "block",
      top: "15px",
    }
  },
  checkboxxlogin:{
    float:"left",
    width:"auto !important",
    "& span":{
      padding:"0px",
    },
    "& svg":{
      width: "0.7em",
      height: "0.7em",
    },
    "& p":{
      lineHeight:"10px",
      paddingLeft:"30px"
    },
    "& p.MuiFormHelperText-root.Mui-error":{
      color:"#ff0000 !important", 
    }
  }
});

export default headerLinksStyle;



const dashboardgameDb = {
    en: [
            {
                'id':'5725a680b3249760ea12de99',
                'tagName': 'Recent Played',
                'searchinput': 'Search Game Here...',
                'categorie':[
                    {
                        'id':'5725a680b3249760ea13de99',
                        'subcategorietitle'  : 'assets/img/subcateg1.png',
                        'subcategorie':[
                            {
                                'id'    : '5725a680b3249760ea31de51',
                                'gameimg'  : 'assets/img/gami1.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de52',
                                'gameimg'  : 'assets/img/gami2.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de53',
                                'gameimg'  : 'assets/img/gami3.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de54',
                                'gameimg'  : 'assets/img/gami4.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de55',
                                'gameimg'  : 'assets/img/gami1.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de56',
                                'gameimg'  : 'assets/img/gami2.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de57',
                                'gameimg'  : 'assets/img/gami3.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de58',
                                'gameimg'  : 'assets/img/gami4.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de59',
                                'gameimg'  : 'assets/img/gami1.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de60',
                                'gameimg'  : 'assets/img/gami2.jpg',
                                'gamebtn'  : 'Play Now!',
                            }
                            ]
                    },
                    {
                        'id'    : '5725a620b3249760ea31de61',
                        'subcategorietitle'  : 'assets/img/subcateg2.png',
                        'subcategorie':[
                            {
                                'id'    : '5725a680b3249760ea31de61',
                                'gameimg'  : 'assets/img/gami1.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de62',
                                'gameimg'  : 'assets/img/gami2.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de63',
                                'gameimg'  : 'assets/img/gami3.jpg',
                                'gamebtn'  : 'Play Now!',
                            }]
                    },
                ]
            },
            {
                'id'    : '5725a680b3949760ea31de63',
                'tagName': 'All Games',
                'searchinput': 'Search Game Here...',
                'onclick':"GameCallApi",
                'categorie':[
                    {
                        'id'    : '5735a680b3949760ea31de63',
                        'subcategorietitle'  : 'assets/img/subcateg1.png',
                        'subcategorie':[
                            {
                                'id'    : '5725a680b3249760ea31de64',
                                'gameimg'  : 'assets/img/gami1.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de65',
                                'gameimg'  : 'assets/img/gami2.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de66',
                                'gameimg'  : 'assets/img/gami3.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de67',
                                'gameimg'  : 'assets/img/gami4.jpg',
                                'gamebtn'  : 'Play Now!',
                            }]
                    },
                    {
                        'id'    : '57252680b3249760ea21de52',
                        'subcategorietitle'  : 'assets/img/subcateg2.png',
                        'subcategorie':[
                            {
                                'id'    : '5725a680b3249760ea31de68',
                                'gameimg'  : 'assets/img/gami1.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de69',
                                'gameimg'  : 'assets/img/gami2.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de70',
                                'gameimg'  : 'assets/img/gami3.jpg',
                                'gamebtn'  : 'Play Now!',
                            }]
                    },
                ]
            },
            {
                'id'    : '5725a680b3249760ea31de71',
                'tagName': 'Latest Games',
                'searchinput': 'Search Game Here...',
                'categorie':[
                    {
                        'id'    : '5725a680b3249760ea31de72',
                        'subcategorietitle'  : 'assets/img/subcateg1.png',
                        'subcategorie':[
                            {
                                'id'    : '5725a680b3249760ea31de73',
                                'gameimg'  : 'assets/img/gami1.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de74',
                                'gameimg'  : 'assets/img/gami2.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de75',
                                'gameimg'  : 'assets/img/gami3.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de76',
                                'gameimg'  : 'assets/img/gami4.jpg',
                                'gamebtn'  : 'Play Now!',
                            }]
                    },
                    {
                        'id'    : '5725a680b3249760ea31de77',
                        'subcategorietitle'  : 'assets/img/subcateg2.png',
                        'subcategorie':[
                            {
                                'id'    : '5725a680b3249760ea31de78',
                                'gameimg'  : 'assets/img/gami1.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de79',
                                'gameimg'  : 'assets/img/gami1.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de80',
                                'gameimg'  : 'assets/img/gami1.jpg',
                                'gamebtn'  : 'Play Now!',
                            }]
                    },
                    {
                        'id'    : '5725a680b3249760ea31de81',
                        'subcategorietitle'  : 'assets/img/subcateg3.png',
                        'subcategorie':[
                            {
                                'id'    : '5725a680b3249760ea31de82',
                                'gameimg'  : 'assets/img/gami1.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de83',
                                'gameimg'  : 'assets/img/gami2.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de84',
                                'gameimg'  : 'assets/img/gami3.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de85',
                                'gameimg'  : 'assets/img/gami4.jpg',
                                'gamebtn'  : 'Play Now!',
                            },
                            {
                                'id'    : '5725a680b3249760ea31de86',
                                'gameimg'  : 'assets/img/gami1.jpg',
                                'gamebtn'  : 'Play Now!',
                            }]
                    },
                ]
            }

    ],
    tm: [
        {
            'id':'5725a680b3249760ea12de99',
            'tagName': 'சமீபத்தில் விளையாடியது',
            'searchinput': 'விளையாட்டு இங்கே தேடுங்கள் ...',
            'categorie':[
                {
                    'id':'5725a680b3249760ea13de99',
                    'subcategorietitle'  : 'assets/img/subcateg1.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de51',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de52',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de53',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de54',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de55',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de56',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de57',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de58',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de59',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de60',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        }
                        ]
                },
                {
                    'id'    : '5725a620b3249760ea31de61',
                    'subcategorietitle'  : 'assets/img/subcateg2.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de61',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de62',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de63',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        }]
                },
            ]
        },
        {
            'id'    : '5725a680b3949760ea31de63',
            'tagName': 'அனைத்து விளையாட்டுகள்',
            'searchinput': 'விளையாட்டு இங்கே தேடுங்கள் ...',
            'onclick':"GameCallApi",
            'categorie':[
                {
                    'id'    : '5735a680b3949760ea31de63',
                    'subcategorietitle'  : 'assets/img/subcateg1.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de64',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de65',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de66',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de67',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        }]
                },
                {
                    'id'    : '57252680b3249760ea21de52',
                    'subcategorietitle'  : 'assets/img/subcateg2.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de68',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de69',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de70',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        }]
                },
            ]
        },
        {
            'id'    : '5725a680b3249760ea31de71',
            'tagName': 'சமீபத்திய விளையாட்டுகள்',
            'searchinput': 'விளையாட்டு இங்கே தேடுங்கள் ...',
            'categorie':[
                {
                    'id'    : '5725a680b3249760ea31de72',
                    'subcategorietitle'  : 'assets/img/subcateg1.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de73',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de74',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de75',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de76',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        }]
                },
                {
                    'id'    : '5725a680b3249760ea31de77',
                    'subcategorietitle'  : 'assets/img/subcateg2.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de78',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de79',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de80',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        }]
                },
                {
                    'id'    : '5725a680b3249760ea31de81',
                    'subcategorietitle'  : 'assets/img/subcateg3.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de82',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de83',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de84',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de85',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de86',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'இப்பொழுதே விளையாடு!',
                        }]
                },
            ]
        }

    ],
    kn: [
        {
            'id':'5725a680b3249760ea12de99',
            'tagName': 'ಇತ್ತೀಚಿನ ಆಡಲಾಗಿದೆ',
            'searchinput': 'ಆಟವನ್ನು ಇಲ್ಲಿ ಹುಡುಕಿ ...',
            'categorie':[
                {
                    'id':'5725a680b3249760ea13de99',
                    'subcategorietitle'  : 'assets/img/subcateg1.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de51',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de52',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de53',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de54',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de55',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de56',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de57',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de58',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de59',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de60',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        }
                        ]
                },
                {
                    'id'    : '5725a620b3249760ea31de61',
                    'subcategorietitle'  : 'assets/img/subcateg2.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de61',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de62',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de63',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        }]
                },
            ]
        },
        {
            'id'    : '5725a680b3949760ea31de63',
            'tagName': 'ಎಲ್ಲಾ ಆಟಗಳು',
            'searchinput': 'ಆಟವನ್ನು ಇಲ್ಲಿ ಹುಡುಕಿ ...',
            'onclick':"GameCallApi",
            'categorie':[
                {
                    'id'    : '5735a680b3949760ea31de63',
                    'subcategorietitle'  : 'assets/img/subcateg1.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de64',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de65',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de66',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de67',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        }]
                },
                {
                    'id'    : '57252680b3249760ea21de52',
                    'subcategorietitle'  : 'assets/img/subcateg2.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de68',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de69',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de70',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        }]
                },
            ]
        },
        {
            'id'    : '5725a680b3249760ea31de71',
            'tagName': 'ಇತ್ತೀಚಿನ ಆಟಗಳು',
            'searchinput': 'ಆಟವನ್ನು ಇಲ್ಲಿ ಹುಡುಕಿ ...',
            'categorie':[
                {
                    'id'    : '5725a680b3249760ea31de72',
                    'subcategorietitle'  : 'assets/img/subcateg1.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de73',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de74',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de75',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de76',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        }]
                },
                {
                    'id'    : '5725a680b3249760ea31de77',
                    'subcategorietitle'  : 'assets/img/subcateg2.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de78',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de79',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de80',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        }]
                },
                {
                    'id'    : '5725a680b3249760ea31de81',
                    'subcategorietitle'  : 'assets/img/subcateg3.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de82',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de83',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de84',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de85',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de86',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ಈಗ ಆಡು!',
                        }]
                },
            ]
        }

    ],
    ne: [
        {
            'id':'5725a680b3249760ea12de99',
            'tagName': 'भर्खरै खेलेको',
            'searchinput': 'खेल यहाँ खोज्नुहोस् ...',
            'categorie':[
                {
                    'id':'5725a680b3249760ea13de99',
                    'subcategorietitle'  : 'assets/img/subcateg1.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de51',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de52',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de53',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de54',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de55',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de56',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de57',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de58',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de59',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de60',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'खेल अब!',
                        }
                        ]
                },
                {
                    'id'    : '5725a620b3249760ea31de61',
                    'subcategorietitle'  : 'assets/img/subcateg2.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de61',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de62',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de63',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'खेल अब!',
                        }]
                },
            ]
        },
        {
            'id'    : '5725a680b3949760ea31de63',
            'tagName': 'सबै खेलहरू',
            'searchinput': 'खेल यहाँ खोज्नुहोस् ...',
            'onclick':"GameCallApi",
            'categorie':[
                {
                    'id'    : '5735a680b3949760ea31de63',
                    'subcategorietitle'  : 'assets/img/subcateg1.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de64',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de65',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de66',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de67',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'खेल अब!',
                        }]
                },
                {
                    'id'    : '57252680b3249760ea21de52',
                    'subcategorietitle'  : 'assets/img/subcateg2.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de68',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de69',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de70',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'खेल अब!',
                        }]
                },
            ]
        },
        {
            'id'    : '5725a680b3249760ea31de71',
            'tagName': 'भर्खरका खेलहरू',
            'searchinput': 'खेल यहाँ खोज्नुहोस् ...',
            'categorie':[
                {
                    'id'    : '5725a680b3249760ea31de72',
                    'subcategorietitle'  : 'assets/img/subcateg1.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de73',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de74',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de75',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de76',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'खेल अब!',
                        }]
                },
                {
                    'id'    : '5725a680b3249760ea31de77',
                    'subcategorietitle'  : 'assets/img/subcateg2.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de78',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de79',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de80',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'खेल अब!',
                        }]
                },
                {
                    'id'    : '5725a680b3249760ea31de81',
                    'subcategorietitle'  : 'assets/img/subcateg3.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de82',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de83',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de84',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de85',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'खेल अब!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de86',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'खेल अब!',
                        }]
                },
            ]
        }

    ],
    tl: [
        {
            'id':'5725a680b3249760ea12de99',
            'tagName': 'ఇటీవల ఆడారు',
            'searchinput': 'ఆట ఇక్కడ శోధించండి ...',
            'categorie':[
                {
                    'id':'5725a680b3249760ea13de99',
                    'subcategorietitle'  : 'assets/img/subcateg1.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de51',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de52',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de53',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de54',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de55',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de56',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de57',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de58',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de59',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de60',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        }
                        ]
                },
                {
                    'id'    : '5725a620b3249760ea31de61',
                    'subcategorietitle'  : 'assets/img/subcateg2.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de61',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de62',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de63',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        }]
                },
            ]
        },
        {
            'id'    : '5725a680b3949760ea31de63',
            'tagName': 'అన్ని ఆటలు',
            'searchinput': 'ఆట ఇక్కడ శోధించండి ...',
            'onclick':"GameCallApi",
            'categorie':[
                {
                    'id'    : '5735a680b3949760ea31de63',
                    'subcategorietitle'  : 'assets/img/subcateg1.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de64',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de65',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de66',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de67',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        }]
                },
                {
                    'id'    : '57252680b3249760ea21de52',
                    'subcategorietitle'  : 'assets/img/subcateg2.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de68',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de69',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de70',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        }]
                },
            ]
        },
        {
            'id'    : '5725a680b3249760ea31de71',
            'tagName': 'తాజా ఆటలు',
            'searchinput': 'ఆట ఇక్కడ శోధించండి ...',
            'categorie':[
                {
                    'id'    : '5725a680b3249760ea31de72',
                    'subcategorietitle'  : 'assets/img/subcateg1.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de73',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de74',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de75',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de76',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        }]
                },
                {
                    'id'    : '5725a680b3249760ea31de77',
                    'subcategorietitle'  : 'assets/img/subcateg2.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de78',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de79',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de80',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        }]
                },
                {
                    'id'    : '5725a680b3249760ea31de81',
                    'subcategorietitle'  : 'assets/img/subcateg3.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de82',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de83',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de84',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de85',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de86',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'ఇప్పుడు ఆడు!',
                        }]
                },
            ]
        }

    ],
    hi: [
        {
            'id'    : '5725a680b3249760ea31de87',
            'tagName': 'हाल ही में खेला गया',
            'searchinput': 'यहां देखें गेम ...',
            'categorie':[
                {
                    'id'    : '5725a680b3249760ea31de88',
                    'subcategorietitle'  : 'assets/img/subcateg1.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249760ea31de89',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de90',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de91',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de92',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de93',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de94',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de95',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de96',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de97',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249760ea31de98',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        }
                        ]
                },
                {
                    'id'    : '5725a680b3249760ea31de99',
                    'subcategorietitle'  : 'assets/img/subcateg2.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249770ea31de51',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249770ea31de52',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249770ea31de53',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        }]
                },
            ]
        },
        {
            'id'    : '5725a680b3249770ea31de54',
            'tagName': 'सभी खेल',
            'searchinput': 'यहां देखें गेम ...',
            'categorie':[
                {
                    'id'    : '5725a680b3249770ea31de55',
                    'subcategorietitle'  : 'assets/img/subcateg1.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249770ea31de56',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249770ea31de57',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249770ea31de58',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249770ea31de59',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        }]
                },
                {
                    'id'    : '5725a680b3249770ea31de60',
                    'subcategorietitle'  : 'assets/img/subcateg2.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249770ea31de61',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249770ea31de62',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249770ea31de63',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        }]
                },
            ]
        },
        {
            'id'    : '5725a680b3249770ea31de64',
            'tagName': 'नवीनतम खेल',
            'searchinput': 'यहां देखें गेम ...',
            'categorie':[
                {
                    'id'    : '5725a680b3249770ea31de65',
                    'subcategorietitle'  : 'assets/img/subcateg1.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249770ea31de66',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249770ea31de67',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249770ea31de68',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249770ea31de69',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        }]
                },
                {
                    'id'    : '5725a680b3249770ea31de70',
                    'subcategorietitle'  : 'assets/img/subcateg2.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249770ea31de71',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249770ea31de72',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249770ea31de73',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        }]
                },
                {
                    'id'    : '5725a680b3249770ea31de74',
                    'subcategorietitle'  : 'assets/img/subcateg3.png',
                    'subcategorie':[
                        {
                            'id'    : '5725a680b3249770ea31de75',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249770ea31de76',
                            'gameimg'  : 'assets/img/gami2.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249770ea31de77',
                            'gameimg'  : 'assets/img/gami3.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249770ea31de78',
                            'gameimg'  : 'assets/img/gami4.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        },
                        {
                            'id'    : '5725a680b3249770ea31de79',
                            'gameimg'  : 'assets/img/gami1.jpg',
                            'gamebtn'  : 'अभी खेलें!',
                        }]
                },
            ]
        }

    ]
};

export default dashboardgameDb;
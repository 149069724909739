// import * as UserActions from './user.actions';
//import * as Actions from '../../../src/store/actions';
import jwtService from '../../../services/jwtService';

export const FUNDREQUEST_SUCCESS = 'FUNDREQUEST_SUCCESS';
export const FUNDREQUEST_ERROR = 'FUNDREQUEST_ERROR';


export function depositRequest({select_pay_by, parent_banker_id, sender_banker_id, deposit_mode, amount, transation_id, comment}, receipt_url){
    return (dispatch) => { 
        if(select_pay_by == "1"){
            jwtService.depositBtBRequest({
                token: window.localStorage.getItem('jwt_access_token'),
                payby: select_pay_by,
                parent_banker_id,
                sender_banker_id,
                deposit_mode,
                amount,
                transation_id,
                receipt_url,
                comment
            })
            .then((user) => { 
                
                return dispatch({
                        type: FUNDREQUEST_SUCCESS,
                        payload: user
                    });
                }
            )
            .catch(error => { 
                return dispatch({
                    type   : FUNDREQUEST_ERROR,
                    payload: error
                });
            });
        }else if(select_pay_by == "2"){
            jwtService.depositBtCRequest({
                token: window.localStorage.getItem('jwt_access_token'),
                payby: select_pay_by,
                parent_banker_id,
                amount,
                receipt_url,
                transation_id,
                comment
            })
            .then((user) => { 
                    return dispatch({
                        type: FUNDREQUEST_SUCCESS,
                        payload: user
                    });
                }
            )
            .catch(error => { 
                return dispatch({
                    type   : FUNDREQUEST_ERROR,
                    payload: error
                });
            });
        }
    }
}
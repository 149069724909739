 

const seccontactDb = {
    categories: [
        {
            'id'    : '5725a680b3249760ea21de52',
            'subtitle'    : 'CONTACT US',
            'title'    : 'GET IN TOUCH',
            'description'    : 'For more information please contact on the following numbers Deposits & Withdrawals & Support: +91 1234567890 Whatsapp : +94 1234567890', 
            'heading'    : 'Send Us a Message',
            'name'    : 'Enter Your Full Name',
            'email'    : 'Enter Your Email Address',
            'textarea'    : 'Message',
            'checkb'    : 'I have read and accept the general terms and conditions',
            'submitbtn'    : 'SUBMIT NOW',
            'submenus'    : [
                {
                    'id': 0,
                    'options':"Please select a topic",
                },
                {
                    'id': 1,
                    'options':"Registration",
                },
                {
                    'id': 2,
                    'options':"My Account",
                },
                {
                    'id': 3,
                    'options':"Login",
                },
                {
                    'id': 4,
                    'options':"Bonus Promotions",
                },
                {
                    'id': 5,
                    'options':"Deposit",
                },
                {
                    'id': 6,
                    'options':"Withdrawal",
                },
                {
                    'id': 7,
                    'options':"Technical Issues",
                },
                {
                    'id': 8,
                    'options':"Others",
                },
            ]
        }, 
    ],
    categoriesHi: [
        {
            'id'    : '5725a680b3249760ea21de52',
            'subtitle'    : 'संपर्क करें',
            'title'    : 'संपर्क में रहो',
            'description'    : 'अधिक जानकारी के लिए निम्नलिखित नंबरों पर संपर्क करें डिपॉजिट एंड विथड्रॉल एंड सपोर्ट: +91 1234567890 व्हाट्सएप: +94 1234567890', 
            'heading'    : 'हमें एक संदेश भेजें',
            'name'    : 'अपना पूरा नाम लिखे',
            'email'    : 'अपना ईमेल पता दर्ज करें',
            'textarea'    : 'संदेश',
            'checkb'    : 'मैंने सामान्य नियमों और शर्तों को पढ़ा और स्वीकार किया है',
            'submitbtn'    : 'अभी जमा करे',
            'submenus'    : [
                {
                    'options':"कृपया एक विषय चुनें",
                },
                {
                    'options':"पंजीकरण",
                },
                {
                    'options':"मेरा खाता",
                },
                {
                    'options':"लॉग इन करें",
                },
                {
                    'options':"बोनस प्रचार",
                },
                {
                    'options':"जमा",
                },
                {
                    'options':"निकासी",
                },
                {
                    'options':"तकनीकी दिक्कतें",
                },
                {
                    'options':"अन्य",
                },
            ]
        },  
    ],
    

};

export default seccontactDb;


 import Background from '../../img/loginbg.jpg';
// import Background4 from '../../img/smbg.jpg';

const landingPageStyle = {
  about:{

  },
  
     
   
  otpwrap:{
    float:"right", 
    borderRadius: "20px",
    width:"30%",
    position:"absolute",
    top:"15%",
    display:"none",
    right:"7%",
    "& fieldset":{
      border:"0px",
     
    },
    "& form":{
      margin:"0px",     
    },
    "& p":{
      margin: "-10px 0px 10px",
      fontSize: "14px",
      fontFamily: "ubuntu",
    },
    "& input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button ":{
      opacity: "0",  
    },
    "& h4":{
      fontFamily:"ubuntu",
      color:"#fff",
      fontSize:"24px",
      textAlign:"center",
      fontWeight: "bold",
      marginBottom: "0",
    },
    "& label":{
      border:"0px",
      color:"#fff",
      fontSize:"16px",
      transform: "translate(0px, 10px) scale(1)",
      fontFamily:"ubuntu",
      ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key 
        paddingTop: "15px",
      }, 
    },
    "& label.MuiFormLabel-root.Mui-focused":{
      color:"#fff !important"
    },
    "& input":{
      fontFamily:"ubuntu",
      color:"#fff",
      fontSize:"14px",
      padding:"20px 0px 10px 0px",
      marginBottom:"20px",
      borderBottom:"1px solid #f1f1f1",
      ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key 
        marginBottom:"10px", 
        fontSize:"14px",
        paddingTop: "30px",
      },
    },
    "& div":{
      width:"100%", 
      marginTop:"0px",
      marginRight: "0px",
      float: "right",
      ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key 
        margin:"0%", 
      },
    },
    "& div div":{ 
      marginTop:"0px"
    },
    ['@media (max-width:1200px)']: { // eslint-disable-line no-useless-computed-key 
      width:"40%", 
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      background:"rgba(0,0,0,0.6)",
      width:"100%",
      height:"100%",
      padding:"5% 10%",
      margin:"0px auto",
      float:"none",
      position: "fixed", 
      overflow:"auto",
      top:"0%", 
      right:"auto",
    },
  },
  orrow:{
    display:"block",
    margin:"0px",
    marginBottom: "-30px !important",
    marginTop: "10px  !important",
  },
  forgotwrap:{
    borderRadius: "10px",
    width:"40%",
    margin:"10px auto",  
    background:"#fff",
    boxShadow: "0px 0px 5px #ccc",
    display:"none",
    padding:"10px",
    "& fieldset":{
      border:"0px",
     
    },
    "& form":{
      margin:"0px",     
    },
    "& p":{
      margin: "-10px 0px 10px",
      fontSize: "14px",
      fontFamily: "ubuntu",
    },
    "& input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button ":{
      opacity: "0",  
    },
    "& h4":{
      fontFamily:"ubuntu",
      color:"#000",
      fontSize:"24px",
      textAlign:"center",
      fontWeight: "bold",
      marginBottom: "0",
    },
    "& label":{
      border:"0px",
      color:"#3c3c3c",
      fontSize:"16px",
      transform: "translate(0px, 10px) scale(1)",
      fontFamily:"ubuntu",
      ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key 
        paddingTop: "15px",
      }, 
    },
    "& label.MuiFormLabel-root.Mui-focused":{
      color:"#3c3c3c !important"
    },
    "& input":{
      fontFamily:"ubuntu",
      color:"#3c3c3c",
      fontSize:"14px",
      padding:"20px 0px 10px 0px",
      marginBottom:"20px",
      borderBottom:"1px solid #3c3c3c",
      ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key 
        marginBottom:"10px", 
        fontSize:"14px",
        paddingTop: "30px",
      },
    },
    "& div":{
      width:"100%", 
      marginTop:"0px",
      marginRight: "0px",
      float: "right",
      ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key 
        margin:"0%", 
      },
    },
    "& div div":{ 
      marginTop:"0px"
    },
    ['@media (max-width:1200px)']: { // eslint-disable-line no-useless-computed-key 
      width:"80%", 
      left:"10%",
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      width:"96%", 
      margin:"0px auto",
      float:"none", 
    },
  },
  mnumber:{
    "& input[type=number]::-webkit-outer-spin-button,input[type=number]::-webkit-inner-spin-button": {
      appearance: "none",
    }, 
  },
  girlimg:{ 
    borderRadius: "20px", 
    position:"fixed",
    bottom: "0%",
    left: "16%",
    width: "23%",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      width: "auto",
      height: "96%",
      left: "5%",
    },
  },
  dropi:{
    color:"#000 !important",
    "& svg":{
      top: "40%",
    },
    "& div div div":{
      color:"#000 !important",
    },
    "& input":{
      color:" #000 !important",
      padding: "15px 0px 15px 30px !important",
      marginBottom: "10px !important",
    },
    "& img":{
      margin:"0px !important"
    }
  },
  flgselett:{
    "& div":{
      width:"100% !important"
    },
    "& div div:before":{
      right: "10px !important"
    },
    "& div div":{
      color: "#fff !important",
      fontSize:"16px"
    },
    "& img":{
      height: "30px !important",
      width: "auto !important",
      marginTop: "18px",
    }
  },
  loginwrap:{
    float:"right", 
    borderRadius: "20px", 
    width:"30%",
    position:"absolute",
    top:"15%",
    right:"7%",
    "& fieldset":{
      border:"0px",
     
    },
    "& form":{
      margin:"0px",     
    },
    "& p":{
      margin: "-10px 0px 10px",
      fontSize: "14px",
      fontFamily: "ubuntu",
    },
    "& input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button ":{
      opacity: "0",  
    },
    "& h4":{
      fontFamily:"ubuntu",
      color:"#fff",
      fontSize:"24px",
      textAlign:"center"
    },
    "& label":{
      border:"0px",
      color:"#fff",
      fontSize:"16px",
      transform: "translate(0px, 10px) scale(1)",
      fontFamily:"ubuntu",
      ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key 
        paddingTop: "15px",
      }, 
    },
    "& label.MuiFormLabel-root.Mui-focused":{
      color:"#fff !important"
    },
    "& input":{
      fontFamily:"ubuntu",
      color:"#fff",
      fontSize:"14px",
      padding:"20px 0px 10px 0px",
      marginBottom:"20px",
      borderBottom:"1px solid #f1f1f1",
      ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key 
        marginBottom:"10px", 
        fontSize:"14px",
        paddingTop: "30px",
      },
    },
    "& div":{
      width:"100%", 
      marginTop:"0px",
      marginRight: "0px",
      float: "right",
      ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key 
        margin:"0%", 
      },
    },
    "& div div":{ 
      marginTop:"0px"
    },
    ['@media (max-width:1200px)']: { // eslint-disable-line no-useless-computed-key 
      width:"40%", 
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      background:"rgba(0,0,0,0.6)",
      width:"100%",
      height:"100%",
      padding:"5% 10%",
      margin:"0px auto",
      float:"none",
      position: "fixed", 
      overflow:"auto",
      top:"0%", 
      right:"auto",
    },
  },
  signupwrap:{ 
    float:"right", 
    borderRadius: "20px",
    width:"40%",
    position:"absolute",
    top:"7%",
    right:"7%",
    "& fieldset":{
      border:"0px",
     
    }, 
    "& p":{
      margin: "-10px 0px 10px",
      fontSize: "14px",
      fontFamily: "ubuntu",
    },
    "& input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button ":{
      opacity: "0",  
    },
    "& h4":{
      fontFamily:"ubuntu",
      color:"#fff",
      fontSize:"34px",
      textAlign:"center"
    },
    "& label":{
      border:"0px",
      color:"#fff",
      fontSize:"16px",
      transform: "translate(0px, 10px) scale(1)",
      fontFamily:"ubuntu",
      ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key 
        paddingTop: "15px",
      }, 
    },
    "& label.MuiFormLabel-root.Mui-focused":{
      color:"#fff !important"
    },
    "& input":{
      fontFamily:"ubuntu",
      color:"#ffffff",
      fontSize:"14px",
      padding:"20px 0px 10px 0px",
      marginBottom:"20px",
      borderBottom:"1px solid #f1f1f1",
      ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key 
        marginBottom:"10px", 
        fontSize:"14px",
        paddingTop: "30px",
      },
    },
    "& div":{
      width:"100%", 
      marginTop:"0px",
      marginRight: "0px",
      float: "right",   
      ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key 
        margin:"0%", 
      },
    },
    "& div div":{ 
      marginTop:"0px"
    },
    ['@media (max-width:1200px)']: { // eslint-disable-line no-useless-computed-key 
      width:"40%", 
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      background:"rgba(0,0,0,0.6)",
      width:"100%",
      height:"100%",
      padding:"5% 10%",
      margin:"0px auto",
      float:"none",
      position: "fixed", 
      overflow:"auto",
      top:"0%", 
      right:"auto",
    },
  },
  receptadiv:{
    float:"left",
    marginBottom:"10px",
    "& div":{
      float:"left"
    }
  },
  otpbtn:{
    display:"block",
    textAlign:"center",
    color:"#fff",
    margin: "0px auto",
    fontWeight: "600",
    fontSize: "14px",
    textTransform:"capitalize",
    "&:hover":{
      background:"none"
    }
  },
  signinmodalbtn:{
    color: "#000 !important",
    background: "#ffb80c",
    margin: "0px auto",
    borderRadius: "5px",
    padding: "10px 30px",
    textShadow: "0px 0px 0px",
    fontFamily: "ubuntu",
    fontWeight: "600",
    fontSize: "14px",
    display: "inherit",
    marginTop: "30px",
    "&:hover":{
      background: "#ffb80c",
    }
  },
  logoimg:{
    textAlign:"center",
    margin:"0px auto",
    display:"block",
    height:"80px"
  },  
  clerfix:{
    clear:"both",
  },
  fullpagelogin:{
   // overflow: "auto",
   backgroundImage:"url(" + Background + ")", 
   overflow: "auto", 
    width:"100%",
    height:"100%", 
    position: "fixed",
    backgroundPosition: "center",
    backgroundSize: "cover", 
    backgroundRepeat: "no-repeat",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      padding: "20px 0px 20px",
    },
 
  }, 
  errormsg:{
    color: "#fff",
    padding: "0px 10px",
    background: "#8c0b0b",
    marginBottom: "20px",
    borderRadius: "5px",
    fontFamily: "ubuntu",
    fontSize: "14px",
    lineHeight: "28px", 
  },
  sucessmsg:{
    color: "#fff",
    padding: "0px 10px",
    background: "#0b8c21",
    marginBottom: "20px",
    borderRadius: "5px",
    fontFamily: "ubuntu",
    fontSize: "14px",
    lineHeight: "28px", 
  },
  selectboxx:{ 
    border:"0px",
    width:"100%",
    fontSize: "16px",
    fontFamily: "ubuntu",
    padding: "11px 0 6px",
    background: "none !important",
    marginBottom:"20px",
    borderBottom:"1px solid #fff",
    color:"#3c3c3c",
    "& option":{
      color:"#000",
    },
    "& div div":{
      color: "#fff !important",
      fontSize: "14px",
      fontFamily: "ubuntu",
      padding:"0px",
    },
    "& div div:focus":{
      background: "none !important", 
    },
    ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
      marginBottom:"20px !important",
    },
  },
  flagselect:{
    borderBottom:"1px solid #fff",
    color:"#fff",  
    padding: "21px 0px 15px",
    fontFamily: "ubuntu",
    "& div.selected--flag--option":{
      padding:"0px !important",
    },
    "& input":{
      padding:"5px 10px",
      color:"#000",
      border:"1px solid #ccc",
    },
    "& span.country-label":{
      paddingLeft:"0px !important"
    },
    "& span.country-flag span.country-label":{
      paddingLeft:"10px !important",
      color:"#333",
    }
  },



 


};

export default landingPageStyle;

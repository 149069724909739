// import * as UserActions from './user.actions';
//import * as Actions from '../../../src/store/actions';
import jwtService from '../../../services/jwtService';


export const AFUNDREQUEST_SUCCESS = 'AFUNDREQUEST_SUCCESS';
export const AFUNDREQUEST_ERROR = 'AFUNDREQUEST_ERROR';




export function angadiyadeposit({amount, transation_id, comment}){
    return (dispatch) => {
        jwtService.depositAngadiyaRequest({
            token: window.localStorage.getItem('jwt_access_token'),
            amount,
            transation_id,
            comment
        })
        .then((user) => { 
            return dispatch({
                    type: AFUNDREQUEST_SUCCESS,
                    payload: user
                });
            }
        )
        .catch(error => { 
            return dispatch({
                type   : AFUNDREQUEST_ERROR,
                payload: error
            });
        });
    }
}

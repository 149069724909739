

const flagDb = {
    en: [
        {
            'id':'5725a680b3240760ea21de79',
            'flag_img':"assets/img/english.jpg", 
        } 
    ],
    tm: [
        {
            'id':'5725a680b3240760ea21de79',
            'flag_img':"assets/img/tamil.jpg", 
        } 
    ],
    kn: [
        {
            'id':'5725a680b3240760ea21de79',
            'flag_img':"assets/img/kannada.jpg", 
        } 
    ],
    ne: [
        {
            'id':'5725a680b3240760ea21de79',
            'flag_img':"assets/img/napali.jpg", 
        } 
    ],
    hi: [
        {
            'id':'5725a680b3240760ea21de80',
            'flag_img':"assets/img/hindi.jpg", 
        } 
    ],
    tl: [
        {
            'id':'5725a680b3240760ea21de80',
            'flag_img':"assets/img/telugu.jpg", 
        } 
    ]
}
export default flagDb;



const withdrawpageDb = {
    en: [ 
        {
            'id'    : '5725a680b3249760eb21de01',
            'top_text'  : 'Your account is demo purpose only. Not authorize to do payment withdrawal/deposit', 
            'one_heading_text'  : 'Available Balance', 
            'one_box'  : 'Total Available Balance', 
            'two_box'  : 'Total Non Withdrawable Balance', 
            'three_box'  : 'Last Deposit Amount', 
            'four_box'  : 'Wager on Last Deposit', 
            'five_box'  : 'Today Withdrawable Balance', 
            'box_bottom_text_one'  : 'Withdrawal amount must be greater then', 
            'box_bottom_text_two'  : 'and maximum', 
            'two_heading_text'  : 'Withdrawal Request',
            'selectbankaccount'  : 'Select Bank Account',
            'amount'  : 'Amount',
            'confirm_amount'  : 'Confirm Amount',
            'comment'  : 'Comment', 
            'withdraw_btn'  : 'Withdraw',
            'period'  : 'Period',
            'start_date'  : 'Start Date',
            'end_date'  : 'End Date',
        },
          
    ],
    tl: [
        {
            'id' : '5725a680b3249770ea31de79',
            'top_text'  : 'మీ ఖాతా డెమో ప్రయోజనం మాత్రమే. చెల్లింపు ఉపసంహరణ / డిపాజిట్ చేయడానికి అధికారం లేదు',
            'one_heading_text'  : 'అందుబాటులో ఉన్న బ్యాలెన్స్', 
            'one_box'  : 'మొత్తం అందుబాటులో ఉన్న బ్యాలెన్స్', 
            'two_box'  : 'మొత్తం ఉపసంహరించలేని బ్యాలెన్స్', 
            'three_box'  : 'చివరి డిపాజిట్ మొత్తం', 
            'four_box'  : 'చివరి డిపాజిట్పై పందెం', 
            'five_box'  : 'ఈ రోజు ఉపసంహరించుకోగల బ్యాలెన్స్', 
            'box_bottom_text_one'  : 'ఉపసంహరణ మొత్తం అప్పుడు ఎక్కువగా ఉండాలి', 
            'box_bottom_text_two'  : 'మరియు గరిష్టంగా', 
            'two_heading_text'  : 'ఉపసంహరణ అభ్యర్థన',
            'selectbankaccount'  : 'బ్యాంక్ ఖాతాను ఎంచుకోండి',
            'amount'  : 'మొత్తం',
            'confirm_amount'  : 'మొత్తాన్ని నిర్ధారించండి',
            'comment'  : 'వ్యాఖ్య', 
            'withdraw_btn'  : 'ఉపసంహరించుకోండి',
            'period'  : 'కాలం',
            'start_date'  : 'ప్రారంబపు తేది',
            'end_date'  : 'చివరి తేది', 
        },
        
    ],
    hi: [
        {
            'id'    : '5725a680b3249760eb21de01',
            'top_text'  : 'आपका खाता केवल डेमो उद्देश्य है। भुगतान वापसी / जमा करने के लिए अधिकृत नहीं', 
            'one_heading_text'  : 'उपलब्ध शेष राशि', 
            'one_box'  : 'कुल उपलब्ध शेष', 
            'two_box'  : 'कुल गैर निकासी योग्य शेष राशि', 
            'three_box'  : 'अंतिम जमा राशि', 
            'four_box'  : 'अंतिम जमा पर दांव', 
            'five_box'  : 'आज शेष राशि की निकासी', 
            'box_bottom_text_one'  : 'निकासी की राशि ',
            'box_bottom_text_two'  : 'से अधिक होनी चाहिए और अधिकतम', 
            'two_heading_text'  : 'वापसी का निवेदन',
            'selectbankaccount'  : 'बैंक खाता चुनें',
            'amount'  : 'रकम',
            'confirm_amount'  : 'राशि की पुष्टि करें',
            'comment'  : 'टिप्पणी',
            'withdraw_btn'  : 'निकालना',
            'period'  : 'अवधि',
            'start_date'  : 'आरंभ करने की तिथि',
            'end_date'  : 'अंतिम तिथि',
        }  
    ],

};

export default withdrawpageDb;
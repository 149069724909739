import * as Actions from '../actions';

const initialState = {
    success: false,
    data: null,
    error  : {
        otp: null,
    }
};

const wlOtp = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.WLOTP_SUCCESS:
        {
            
            return {
                success: true,
                data:action.payload,
                error  : {
                    otp: null,
                }
            };
        }
        case Actions.WLOTP_ERROR:
        {
             
            return {
                success: false,
                error  : action.payload
            };
        }
        default:
        {
            return state
        }
    }
};

export default wlOtp;
import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar"; 
import Button from "@material-ui/core/Button"; 
import styles from "../assets/jss/components/headerStyle.js";
import GridContainer from "../elements/Grid/GridContainer";
import GridItem from "../elements/Grid/GridItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CustomDropdown from "../elements/CustomDropdown/CustomDropdown"; 
import stylesr from "../assets/jss/components/headerLinkstopStyle";
import PageVisibility from 'react-page-visibility';
import SectionPopup from "./SectionPopup";
import sectionpop from "./SectionPopup";
import Games from "../assets/img/games.png";
import Language from "../assets/img/language.png";
import Balance from "../assets/img/coinbalace.png";
import Gamesactive from "../assets/img/gamesactive.png";
import Offer from "../assets/img/offer.png";
import Offeractive from "../assets/img/offeractive.png";
import Menuico from "../assets/img/menuico.png";
import Menuicoactive from "../assets/img/menuicoactive.png";
import Languageactive from "../assets/img/languageactive.png";
import Balanceactive from "../assets/img/balanceactive.png";
import Offer2 from "../assets/img/offr.jpg";
import Lodi from "../assets/img/lodi.gif";
import Slide from "@material-ui/core/Slide";
import LanguageSelector from './LanguageSelector'
import usermenuDb from "../data/usermenu";
import menusidebartopDb from "../data/menusidebartop";
import jwt from 'jsonwebtoken'; 
import jwtDecode from 'jwt-decode';
import jwtService from '../../../services/jwtService'
import axios from 'axios';
import * as globalurl from '../../../global/Global.js'; 
import Tooltip from "@material-ui/core/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"; 
import SectionLoginboxMobile from "./SectionLoginBoxMobile";
import SectionLoginbox from "./SectionLoginBox";
import SectionAllprofile from "./SectionAllprofile";
import SectionOtpbox from "./SectionOtpBox";    
import SectionOtpboxMobile from "./SectionOtpBoxMobile"; 
import SectionSignupboxMobile from "./SectionSignupBoxMobile";
import SectionSignupbox from "./SectionSignupBox";
import SectionForgotbox from "./SectionForgotBox";
import Offers from "../pages/offers";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';


var isExistApiCall = false;
 var isExistApiCalls = false;
var sideMenuEvent = 0;
var isAlreadyOpen = false;
var isBalanceLoop = false;
export default function SectionHeaderLogin(props) {
  const [userName, setUserName] = React.useState("Menus");
  const [topMenus, setTopMenus] = React.useState(0);
  const [sideMenus, setSideMenus] = React.useState(0);
  const [myBalance, setMyBalance] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1000);
  const [isMobilebal, setIsMobilebal] = React.useState(window.innerWidth < 1000);
  const { ...sectionPopup} = sectionpop;
  // const onFocus = (event) => {
  //   if(event === true){
  //     getBalanceNow();
  //   }
  // };

  if(props.themeColors != null){
    styles.balanceboxwrapmobile.background = props.themeColors.bgColors.secondary_dark_bgcolor; 
    
    styles.headersigninfix.borderColor = props.themeColors.bgColors.primary_inner_bgcolor; 
    styles.headersigninfix.background = props.themeColors.bgColors.secondary_bgcolor; 
    
    styles.topheaderrow.background = props.themeColors.bgColors.secondary_dark_bgcolor;
    stylesr.headbackground.backgroundColor = props.themeColors.bgColors.primary_bgcolor;
     styles.navigationitem.color = props.themeColors.bgColors.text_primary_color;
    styles.toolbarafter["&::before"].backgroundColor = props.themeColors.bgColors.primary_bgcolor;
    styles.toolbarafter["&::after"].borderBottomColor = props.themeColors.bgColors.primary_bgcolor;
    stylesr.dropdownLink.color = props.themeColors.bgColors.text_secondary_dark_color;
    stylesr.navLink.color = props.themeColors.bgColors.text_secondary_color; 
    styles.logoutbtnt.color = props.themeColors.bgColors.text_secondary_color; 
    styles.coinbalance.color = props.themeColors.bgColors.main_color
    styles.plusbalance.color = props.themeColors.bgColors.text_secondary_color
    styles.balancerefresh.color = props.themeColors.bgColors.main_color
    styles.menusignin.color = props.themeColors.bgColors.text_primary_color
    styles.menusignin["&:hover"].backgroundColor = props.themeColors.bgColors.secondary_dark_bgcolor
    styles.usernotibtn.background = props.themeColors.bgColors.main_color
    styles.usernotibtn.color = props.themeColors.bgColors.text_primary_dark_color 
    styles.balanceamount.color = props.themeColors.bgColors.text_primary_color
    styles.balanceamount["&:hover"].color = props.themeColors.bgColors.text_secondary_color
    styles.usersigninanker.color = props.themeColors.bgColors.text_primary_color
    styles.usersigninanker["&:hover"].background = props.themeColors.bgColors.main_color
    styles.usersigninanker["&:hover"].backgroundColor = props.themeColors.bgColors.main_color
    styles.usersigninanker["&:hover"].color = props.themeColors.bgColors.text_primary_dark_color
    styles.notificationmsgbox["& h5"].color = props.themeColors.bgColors.text_secondary_color
    styles.notificationmsgbox["&:hover"].background = props.themeColors.bgColors.main_color
    styles.notificationmsgbox["&:hover h5"].color = props.themeColors.bgColors.text_primary_dark_color  
    styles.rightsidebarbtn["&:hover"].color = props.themeColors.bgColors.text_primary_color;
    styles.rightsidebarbtn.color = props.themeColors.bgColors.text_secondary_color
    styles.listItemsignimg.background = props.themeColors.bgColors.primary_bgcolor;
    styles.navigationwrap.borderColor = props.themeColors.bgColors.border_color;
    styles.mainboxsearch.background = props.themeColors.bgColors.primary_bgcolor;
    // styles.mainboxsearch["& input"].borderColor = props.themeColors.bgColors.primary_bgcolor;
    styles.signinbtn.color = props.themeColors.bgColors.text_primary_color; 
     styles.listItemsignimg["& img"].filter = props.themeColors.bgColors.filter
    styles.listheadsidebartop.background = props.themeColors.bgColors.secondary_dark_bgcolor
    styles.listheadsidebartop["& h6"].color = props.themeColors.bgColors.text_secondary_dark_color
    styles.listheadsidebaranker.color = props.themeColors.bgColors.text_primary_color
    styles.listheadsidebaranker["&:hover"].background = props.themeColors.bgColors.main_color
    styles.listheadsidebaranker["&:hover"].color = props.themeColors.bgColors.text_primary_dark_color
    styles.usersigninbtn.color = props.themeColors.bgColors.text_primary_color
    styles.usersigninbtn.background = props.themeColors.bgColors.primary_bgcolor
    styles.logoname.color = props.themeColors.bgColors.text_primary_color
    
    styles.balnceiconbox.background = props.themeColors.bgColors.primary_bgcolor
    styles.balnceiconbox["& img"].filter = props.themeColors.bgColors.filter
    stylesr.dropdownLink.fontFamily = props.themeColors.bgColors.base_font; 
    styles.logoname.fontFamily = props.themeColors.bgColors.logo_font; 
    styles.menusignin.fontFamily = props.themeColors.bgColors.base_font; 
    styles.signinbtn.fontFamily = props.themeColors.bgColors.base_font;  
    styles.listheadsidebaranker.fontFamily = props.themeColors.bgColors.base_font;   
    styles.usersigninanker.fontFamily = props.themeColors.bgColors.base_font;    
    styles.usersigninanker["& span"].fontFamily = props.themeColors.bgColors.base_font;
    styles.usersigninbtn.fontFamily = props.themeColors.bgColors.base_font; 
    styles.logoutbtnt.fontFamily = props.themeColors.bgColors.base_font;   
    styles.listheadsidebartop["& h5"].fontFamily = props.themeColors.bgColors.base_font;
    
    function myFunction(x) {
      if (x.matches) { // If media query matches 
        // styles.usersigninbtn.background = props.themeColors.bgColors.main_color
        // styles.headsigninbg.background = props.themeColors.bgColors.main_color
      } else {
      }
    } 
    var x = window.matchMedia("(max-width: 1000px)")
    myFunction(x) // Call listener function at run time
    x.addListener(myFunction)
  }
  
  // const pageName = "dashboard";
  var parentId = 0;
  if(window.localStorage.getItem("header_access_token")){
    const decodedData = jwtDecode(window.localStorage.getItem("header_access_token"));
    if(decodedData.TopMenu != null){
      decodedData.TopMenu.forEach((myMenuData) => {
        if(myMenuData.path === "dashboard"){
          parentId = myMenuData.id;
        }
      });
    }
  }
  async function getBalanceFromServer(){
    if(isExistApiCall === false){
      isExistApiCall = true;
      let pro = new Promise((resolve,reject) => { 
        const request = axios.post(globalurl.GETBALANCE , {
            token: window.localStorage.getItem('jwt_access_token')
        });
        request.then((response) => {
          isExistApiCall = false; 
            if(response.data !== null && response.data.status !== null  && response.data.status === "OK"){
                var data = {
                    "status":true,
                    "data":response.data
                }
                resolve(data);
            }else{
                if(response.data !== null && response.data.status !== null && response.data.status !== undefined && response.data.status === "INVALID_AUTH"){
                  setTimeout(function() { 
                      sectionpop.openPopupReload("popup", "Hello " + (loginInfo.username), " Your session expired. Please login again.",true);
                      
                  }, 1000);
                }else if(response.data !== null && response.data.status !== null && response.data.status !== undefined && response.data.status === "ANOTHER_AUTH"){
                  setTimeout(function() { 
                      sectionpop.openPopupReload("popup", "Hello " + (loginInfo.username), " Another login attempts with this user. If it's not logged in by you then please change your password immediately.",true);
                  }, 1000);
                }
                resolve({"status":false, "message":'No record found'});
            }
        });
        request.catch((error) => {
          isExistApiCall = false;
            reject(error);
        });
      }); 
      const val = await pro;
      return val;
    }
  }
  async function getBalanceNow(from){
    //alert(from);
    if(window.localStorage.getItem('jwt_access_token') != null){
      var MyGetBalance = await getBalanceFromServer();
      if(MyGetBalance != undefined && MyGetBalance != null && MyGetBalance.status != null && MyGetBalance.status === true && MyGetBalance.data != null){
        if(MyGetBalance.data.balance != null){
          setMyBalance(MyGetBalance.data.balance);
        }else{
          setMyBalance(0);
        }
      }else{
        if(MyGetBalance != null && MyGetBalance !== undefined && MyGetBalance.status != null && MyGetBalance.status === "INVALID_PARAMETER"){
          setTimeout(function() { 
              sectionpop.openPopup("popup", "Hello " + (loginInfo.username), " Your session expired. Please login again.");
              setTimeout(function() { 
                window.location.reload();
              }, 2000);
          }, 1000);
        }
        if(MyGetBalance !== undefined){
          setMyBalance(0);
        }
      }
    }
  }
  if(myBalance === false){
    setMyBalance("Loading...")
    if (jwtService.isAuthenticationLogin() === true){
      getBalanceNow(1);
    }
  }
  var refreshBalance = function(){
    setMyBalance("Loading...")
    if (jwtService.isAuthenticationLogin() === true){
      getBalanceNow(2);
    }
  }
  

  async function getDataFromServer(menutype, myParent, myPageName){
    if(isExistApiCalls === false){
      isExistApiCalls = true;
    let pro = new Promise((resolve,reject) => {
       
        const request = axios.post(globalurl.GETMENUS , {
            token: window.localStorage.getItem('jwt_access_token'),
            menutypes: menutype,
            parent_id: myParent,
            pageslug: myPageName
        });
        request.then((response) => {
           isExistApiCalls = false;
            if(response.data.data != null) {
                var data = {
                    "status":true,
                    "data":response.data.data
                }
                resolve(data);
            }else{
                resolve({"status":false, "message":'No record found'});
            }
        });
        request.catch((error) => { 
           isExistApiCalls = false;
            reject(error);
        });
    
    }); 
    const val = await pro;
    return val;
    }
  }

  function setHeaderData(data, access_token_name){
      var jwtConfig = {
          "secret"   : "324934dsfj786ykjhgiyyi4439wri",
          "expiresIn": "1 hours" // A numeric value is interpreted as a seconds count. If you use a string be sure you provide the time units (days, hours, etc)
      };
      const access_token = jwt.sign(data, jwtConfig.secret, {expiresIn: jwtConfig.expiresIn});
      window.localStorage.setItem(access_token_name, access_token);
  }

  var dataOfMenu = [];
  async function getDataNow(){
   //  dataOfMenu = await getDataFromServer(6, parentId, pageName);    24-jun-2020
    if(parentId > 0){
      dataOfMenu = await getDataFromServer(8, parentId, "dashboard");
      
      if(dataOfMenu != null && dataOfMenu.data != null){
          setHeaderData({TopMenu: dataOfMenu.data}, "dashboard_access_token");
          setTopMenus(dataOfMenu.data);
          
        }
    }
  }
  if(topMenus === 0){
      let headerData = false; 
      if(window.localStorage.getItem("dashboard_access_token") != null){
          const decodedData = jwtDecode(window.localStorage.getItem("dashboard_access_token"));
          const currentTime = Date.now() / 1000;
          let tokenExpired = false;
          if ( decodedData.exp < currentTime ){
              tokenExpired = true;
          }
          if(tokenExpired === true && navigator != null && navigator.onLine != null && navigator.onLine !== true){
              tokenExpired = false;
          }
          if (tokenExpired === false){
              if(decodedData.TopMenu != null){
                  setTopMenus(decodedData.TopMenu);
                  headerData = true;
              }  
          }
      }
      if(headerData === false){
          getDataNow();
      }
  }else{
      if(Array.isArray(topMenus)){
        dataOfMenu = topMenus;
      }else{
        dataOfMenu[0] = topMenus;
      }
      
  } 
 
  function setHeaderSidebarData(data, access_token_name){
    var jwtConfig = {
        "secret"   : "324934dsfj786ykjhgiyyi4439wri",
        "expiresIn": "1 hours" // A numeric value is interpreted as a seconds count. If you use a string be sure you provide the time units (days, hours, etc)
    };
    const access_token = jwt.sign(data, jwtConfig.secret, {expiresIn: jwtConfig.expiresIn});
    window.localStorage.setItem(access_token_name, access_token);
  }
 
  var dataSidebar = [];
  async function getSidebarData(){
    dataSidebar = await getDataFromServer(6, 0, 0); 
  //  alert(JSON.stringify(dataSidebar.data));
      if(dataSidebar != null && dataSidebar.data !== null){
          setHeaderSidebarData({TopMenu: dataSidebar.data}, "sidebar_access_token");
          setSideMenus(dataSidebar.data);
      }
  }
  if(sideMenus === 0){
      let headerData = false; 
      if(window.localStorage.getItem("sidebar_access_token") != null){
          const decodedData = jwtDecode(window.localStorage.getItem("sidebar_access_token"));
          const currentTime = Date.now() / 1000;
          let tokenExpired = false;
          if ( decodedData.exp < currentTime ){
              tokenExpired = true;
          }
          if(tokenExpired === true && navigator != null && navigator.onLine != null && navigator.onLine !== true){
              tokenExpired = false;
          }
          if (tokenExpired === false){
              if(decodedData.TopMenu != null){
                  setSideMenus(decodedData.TopMenu);
                  headerData = true;
              }  
          }
      }
      if(headerData === false){
        getSidebarData();
      }
  }else{
    dataSidebar = sideMenus;
  } 

  const useStyles = makeStyles(styles);
  const useStylesr = makeStyles(stylesr);
  const classes = useStyles();
  const classesr = useStylesr();  
  const currentLocation = props.location.pathname.split("/");
  React.useEffect(() => {
    if (props.changeColorOnScroll && (currentLocation[1] === "" || currentLocation[1] === "about" || currentLocation[1] === "vipprograms" || currentLocation[1] === "faq" || currentLocation[1] === "contact")) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll && (currentLocation[1] === "" || currentLocation[1] === "about" || currentLocation[1] === "vipprograms" || currentLocation[1] === "faq" || currentLocation[1] === "contact")) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  }); 
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);  
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]); 
    }
  };


  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  Transition.displayName = "Transition"; 
  const { color,  fixed, absolute } = props;

  
  
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });  

  var checkToggle = 0;
  // function signinnotifi() {
  //   if(checkToggle === 0){
  //     checkToggle = 1;
  //     document.body
  //         .getElementsByClassName("signinnotificationbox")[0]
  //         .setAttribute("style", "display:block;");
  //   }else{
  //     checkToggle = 0;
  //     document.body
  //         .getElementsByClassName("signinnotificationbox")[0]
  //         .setAttribute("style", "display:none;");
  //   }
  // }
 
  function gamemenuall() {
    if(checkToggle === 0){
      checkToggle = 1;
      document.body
          .getElementsByClassName("headsigninbg")[0]
          .setAttribute("style", "display:block;");
    }else{
      checkToggle = 0;
      document.body
          .getElementsByClassName("headsigninbg")[0]
          .setAttribute("style", "display:none;");
    }
  }
  function mobilebalanceshow() {
    if(checkToggle === 0){
      checkToggle = 1;
      document.body
          .getElementsByClassName("banlance")[0]
          .setAttribute("style", "display:block;");
    }else{
      checkToggle = 0;
      document.body
          .getElementsByClassName("banlance")[0]
          .setAttribute("style", "display:none;");
    }
  }
   
  function signinsidebar() {  
      document.body
          .getElementsByClassName("signinsidebarbox")[0]
          .setAttribute("style", "display:block;");
      document.body
          .getElementsByClassName("headsigninbg")[0]
          .setAttribute("style", "display:none;");   
      document.body
          .getElementsByClassName("offersbg")[0]
          .setAttribute("style", "display:none;"); 
      document.body
          .getElementsByClassName("signinsidebar")[0].classList.add("active");    
      document.body
          .getElementsByClassName("offersidebar")[0].classList.remove("active");
      document.body
          .getElementsByClassName("gamesidebar")[0].classList.remove("active");
     
  }
  function gamesidebar() {  
      document.body
          .getElementsByClassName("headsigninbg")[0]
          .setAttribute("style", "display:block;");
      document.body
          .getElementsByClassName("signinsidebarbox")[0]
          .setAttribute("style", "display:none;"); 
      document.body
          .getElementsByClassName("offersbg")[0]
          .setAttribute("style", "display:none;"); 
      document.body
          .getElementsByClassName("gamesidebar")[0].classList.add("active");
      document.body
          .getElementsByClassName("offersidebar")[0].classList.remove("active");
      document.body
          .getElementsByClassName("signinsidebar")[0].classList.remove("active");
    
  }
  function offersidebar() {  
      document.body
          .getElementsByClassName("headsigninbg")[0]
          .setAttribute("style", "display:none;");
      document.body
          .getElementsByClassName("offersbg")[0]
          .setAttribute("style", "display:block;");
      document.body
          .getElementsByClassName("signinsidebarbox")[0]
          .setAttribute("style", "display:none;");  
      document.body
          .getElementsByClassName("gamesidebar")[0].classList.remove("active");
      document.body
          .getElementsByClassName("offersidebar")[0].classList.add("active");
      document.body
          .getElementsByClassName("signinsidebar")[0].classList.remove("active");
    
  }
  function parseJwt(token) {
      if (!token) { return; }
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
  }
  
  function getUserName(){
    if(window.localStorage.getItem('jwt_access_token') != null && userName === "Menus"){
        let userData = parseJwt(window.localStorage.getItem('jwt_access_token'));
        if(userData.username != null){
          setUserName(userData.username);
        }
    }
  }
  
  function createMarkup(text) { return {__html: text}; };

  function getCurrentThemeInfo(){
    if(window.localStorage.getItem('jwt_theme_token') != null){
        let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
        return themeData;
    }
  } 
  
  var supportNumber = null;
  var supportEmail = null;
   var icon = null;
  var fbLink = null;
  var brandName = null;
  var twitterLink = null; 
  var registration_type ;
  var themeInfo = getCurrentThemeInfo(); 

  function getCurrentLoginInfo(){
    if(window.localStorage.getItem('jwt_access_token') != null){
        let themeData = parseJwt(window.localStorage.getItem('jwt_access_token'));
        return themeData;
    }
  } 
  var loginInfo = getCurrentLoginInfo();
  if((sessionStorage.getItem('welcome_message') === null || sessionStorage.getItem('welcome_message') === undefined || sessionStorage.getItem('welcome_message') === false)  && jwtService.isAuthenticationLogin() === true && loginInfo && loginInfo.username != null && loginInfo.username !== undefined && themeInfo && themeInfo.brand_name != null && themeInfo.brand_name !== undefined){
    if(sectionpop && isAlreadyOpen === false){
      refreshBalance();
      isAlreadyOpen = true;
      setTimeout(function() { 
          getUserName();
          //sectionpop.openPopupWithButton("popup", "Hey " + (loginInfo.username), "Welcome to " + (themeInfo.brand_name).toUpperCase());
          sessionStorage.setItem('welcome_message', true);
      }, 2000);
    }
  }else{
    setTimeout(function() { 
      getUserName();
    }, 1000);
  }
  
  if (jwtService.isAuthenticationLogin() === true && isBalanceLoop === false){
    isBalanceLoop = true;
    fetchBalanceinterval();
  }
  function fetchBalanceinterval(){
    getBalanceNow(3);
    setTimeout(function() { 
      if (jwtService.isAuthenticationLogin() === true){
        fetchBalanceinterval();
      }else{
          setTimeout(function() { 
              sectionpop.openPopup("popup", "Hello " + (loginInfo.username), " Your session expired. Please login again.");
              setTimeout(function() { 
                window.location.reload();
              }, 2000);
          }, 1000);
      }
    }, 20000);
  }

  //alert(JSON.stringify(themeInfo))
  if(themeInfo != null && themeInfo.id != null){
      if(themeInfo.support_number != null && themeInfo.is_mobile != 0 && themeInfo.support_number.length > 0){
          supportNumber = themeInfo.support_number;
      }
      if(themeInfo.support_mail != null && themeInfo.is_email != 0 && themeInfo.support_mail.length > 0){
          supportEmail = themeInfo.support_mail;
      }
      if(themeInfo.facebook_url != null && themeInfo.facebook_url.length > 0){
        fbLink = themeInfo.facebook_url;
      }
      if(themeInfo.twitter_url != null && themeInfo.twitter_url.length > 0){
        twitterLink = themeInfo.twitter_url;
      }
      if(themeInfo.brand_name != null && themeInfo.brand_name.length > 0){
        brandName = themeInfo.brand_name;
      }
      if(themeInfo.icon != null && themeInfo.icon.length > 0){
        icon = themeInfo.icon;
      } 
      if(themeInfo.registration_type != null && themeInfo.registration_type > 0){ 
        registration_type = themeInfo.registration_type;
      }  
  } 
  var loginclass = ""; 
  var mobilmenuset = ""; 
  if(jwtService.isAuthenticationLogin() === true){   
    loginclass = 'topheaderrowddd'; 
    mobilmenuset = 'mobilmenu';       
  }else{          
    loginclass = 'topheaderrow';
    mobilmenuset = 'mobilmenuset';
  }   

  
  React.useEffect(() => {
      window.addEventListener("resize", () => {
          const ismobile = window.innerWidth < 1000;
          if (ismobile !== isMobile) setIsMobile(ismobile);
      }, false);
  }, [isMobile]);
  React.useEffect(() => {
    window.addEventListener("resize", () => {
        const ismobilebal = window.innerWidth < 1000;
        if (ismobilebal !== isMobilebal) setIsMobile(ismobilebal);
    }, false);
  }, [isMobilebal]); 

  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", hhiddeheader);
    }
    return function cleanup() {
      if (props.changeColorOnScroll ) {
        window.removeEventListener("scroll", hhiddeheader);
      }
    };
  });  

  const hhiddeheader = () => {
    const {  changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
   
      if(document.body.getElementsByClassName("fixheadermobilehide")[0] !== undefined ){
      document.body
      .getElementsByClassName("fixheadermobilehide")[0]
      .setAttribute("style", "display:none;");  
      }
      if(document.body.getElementsByClassName("fixheadermobile1")[0] !== undefined ){
      document.body
      .getElementsByClassName("fixheadermobile1")[0]
      .setAttribute("style", "display:block ;"); 
      }
      if(document.body.getElementsByClassName("mobilebal-class")[0] !== undefined ){
        document.body
        .getElementsByClassName("mobilebal-class")[0]  
        .setAttribute("style", "display:none ;"); 
      }
      
    }else {
      if(document.body.getElementsByClassName("fixheadermobilehide")[0] !== undefined){
        document.body
        .getElementsByClassName("fixheadermobilehide")[0]
        .setAttribute("style", "display:block;"); 
      } 
      if(document.body.getElementsByClassName("fixheadermobile1")[0] !== undefined){
        document.body
        .getElementsByClassName("fixheadermobile1")[0]
        .setAttribute("style", "display:none;");   
      }
      if(document.body.getElementsByClassName("mobilebal-class")[0] !== undefined){
        document.body
        .getElementsByClassName("mobilebal-class")[0]
        .setAttribute("style", "display:block ;"); 
      }
    }
  }  

 function openSectionSignup(){
    setTimeout(function() { 
      if(registration_type === 1){
        SectionSignupboxMobile.openPopup("signup");  
      }else{ 
        SectionSignupbox.openPopup("signup");
      }
    }, 500)
 }

 function toggle_plus(id) {
  sideMenuEvent = 1;
  var f = document.getElementById(id);
  if (f.classList.contains("showplus")) {
          f.classList.remove("showplus");
          f.classList.add("showminus");
      } else {
          f.classList.remove("showminus");
          f.classList.add("showplus");
      }
} 
function toggle_visibility(id) {
 var e = document.getElementById(id);
 
 if(e.style.display === 'block') {
    e.style.display = 'none';
 } else {
    e.style.display = 'block';
 }
}

  return ( 
    <PageVisibility>

      <div className={classes.headersignin} id="top">

      <div className="loaderboxfix" style={{display:"none"}}>
        <img src={Lodi} alt=""/>
        <h4>Please Wait...</h4>
      </div>

        <div className="fixheadermobilehide">
          <AppBar className={appBarClasses +" "+ classes.headersigninfix }> 
              <section className={classesr.topheader + " topheadercl " + classes.topheaderside}> 
                <GridContainer className={"bottomheaderbar"}> 
                  <GridItem xs={12} sm={12} md={12}>
                  {(() => {
                    // alert(JSON.stringify(themeInfo))
                      if(themeInfo.icon !== null && (themeInfo.icon !== undefined && themeInfo.icon !== "undefined" && themeInfo.icon.length > 0)) { 
                              return (
                                <a href="/" className={classes.brandlogowrap}>
                                  <img src={icon} height={"50px"} alt={""} className={classes.imglogo2} />
                                  <i className={"fa fa-home  " +  classes.himeicon}></i> 
                                </a>
                      ) }else{
                        return (
                            <a href="/" className={classes.brandlogowrap}>
                              <h4 className={classes.logoname}>{brandName}</h4>
                              <i className={"fa fa-home  " +  classes.himeicon}></i> 
                            </a>
                          )
                        }
                        })()} 
                  <div className={classes.laguagetrans}>  
                     <LanguageSelector themeColors={props.themeColors}  {...props} />
                  </div>
                  <div style={{clear:"both"}}></div>
                    <div className={classes.rightcontent2 + " rightcontent2"}> 
                        
                      {(() =>  {
                        if(jwtService.isAuthenticationLogin() === true){
                          return ( 
                            <span></span>
                        )
                      }else{
                        
                        if(registration_type === 1){ 
                          return (
                            <div className={classes.logininlinebtn}> 
                            {usermenuDb[sessionStorage.getItem('lang')].map((jsondata) => (
                              <Button
                                  // block
                                  className={classes.signinbtn}
                                  onClick={() => {SectionLoginboxMobile.openPopup("login");}}
                              > 
                                {jsondata.loginsignupbtn}
                              </Button>
                              ))}  
                              <SectionLoginboxMobile
                                  SignupBox={SectionSignupboxMobile}
                                  OtpBox={SectionOtpboxMobile}
                                  ForgotBox={SectionForgotbox}
                                  {...props} />
                              <SectionForgotbox 
                                  ForgotBox={SectionForgotbox}
                                  LoginBox={SectionLoginboxMobile}
                                  {...props} />
                              <SectionOtpboxMobile
                                  LoginBox={SectionLoginboxMobile}
                                  SignupBox={SectionSignupboxMobile}
                                  {...props} />
                              <SectionSignupboxMobile
                                  OtpBox={SectionOtpboxMobile}
                                  LoginBox={SectionLoginboxMobile}
                                  {...props} />
                                                            
                            </div>
                            ) 
                        }else{ 
                          return (
                            <div className={classes.logininlinebtn}> 
                            {usermenuDb[sessionStorage.getItem('lang')].map((jsondata) => (
                            <Button
                                // block
                                className={classes.signinbtn + " btnnx"}
                                onClick={() => {SectionLoginbox.openPopup("login");}}
                            > 
                              {jsondata.loginbtn}
                            </Button>
                             ))} 
                             {usermenuDb[sessionStorage.getItem('lang')].map((jsondata) => (
                            <Button
                                // block
                                style={{marginLeft:"1%", marginRight:"0%"}}
                                className={classes.signinbtn + " btnnx"}
                                onClick={openSectionSignup}
                            > 
                              {jsondata.ragisterbtn}
                            </Button>
                             ))}  
                              <SectionLoginbox
                                  SignupBox={SectionSignupbox}
                                  ForgotBox={SectionForgotbox}
                                  {...props} />
                              <SectionForgotbox 
                                  ForgotBox={SectionForgotbox}
                                  LoginBox={SectionLoginbox}
                                  {...props} />
                              <SectionOtpbox
                                  LoginBox={SectionLoginbox}
                                  {...props} />
                              <SectionSignupbox
                                  OtpBox={SectionOtpbox}
                                  LoginBox={SectionLoginbox}
                                  {...props} />
                                                            
                            </div>
                            )
                            
                        }
                      }
                    })()} 
 
 
                    </div>

                 
                  </GridItem>
                </GridContainer>
                <SectionPopup {...props} />
              </section> 
 
            <section>
            {(() =>  {
                        if(jwtService.isAuthenticationLogin() === true){
                          return ( 
                      <div className={classes.userdetaildiv}> 
           
                        {usermenuDb[sessionStorage.getItem('lang')].map((jsondata) => (
                         
                      <CustomDropdown
                              noLiPadding
                              themeColors={props.themeColors}
                              key={jsondata.id}
                              buttonText={[<i className={classes.loginusericon + " fa fa-user  " + classes.userdetailicon} key={"ik1"}></i>, <p className={classes.userdetailname} key={"ik2"}>{userName}</p>,<i className={"fa fa-angle-down " + classes.dropdownarrow}></i>  ]}
                              buttonProps={{
                                className: classes.navLink + " " + classes.usersigninbtn,
                                color: "transparent"
                              }}
                              dropdownList={jsondata.submenu.map((jsondata) => {
                                if(jsondata.is_popup === null || jsondata.is_popup === undefined || jsondata.is_popup === true){
                                  return( 
                                      <Button   
                                          onClick={() => {SectionAllprofile.openPopup("login",jsondata.tagname,jsondata.subtagname);}}
                                          className={classes.usersigninanker} 
                                          key={jsondata.id} 
                                      >
                                        <i className={jsondata.submenu_class}></i>&nbsp;  {jsondata.sub_menu_name}
                                      
                                      </Button> 
                                  );    
                                } else{
                                  return(
                                  <Button   
                                      className={classes.usersigninanker} 
                                      href={jsondata.submenu_path}
                                      key={jsondata.id} 
                                  >
                                    <i className={jsondata.submenu_class}></i>&nbsp;  {jsondata.sub_menu_name}
                                  </Button> 
                                )}
                              })
                              
                              }
                          />
                        ))}
                      </div>
                          )
                      }else{
                        return (
                          <span></span>
                          )
                        }
                      })()} 
            </section>

            <section> 
                        {(() =>  {
                          if(jwtService.isAuthenticationLogin() === true){
                            return (
                              <div className={classes.balanceboxi + " balanceboxi"}>
                                  <div className={classes.balancespike}> 
                                    <Button 
                                        onClick={() => {SectionAllprofile.openPopup("login");}}
                                        color="inherit" 
                                        className={classes.balanceamount}
                                    >
                                      <i className={classes.coinbalance + "  fa fa-credit-card "} />
                                      {(isNaN(myBalance)) ? myBalance : parseFloat(myBalance).toFixed(2)} 
                                    </Button> 
                                    <Button
                                        href=""
                                        color="inherit"
                                        onClick={refreshBalance}
                                        className={classes.balancerefresh}
                                    >
                                      <i className={"fa fa-retweet  "} />
                                    </Button></div>
                                   
                              </div>
                              )
                            }else {
                              return(
                                <span></span>
                              )
                            }
                        })()}  
            </section> 

            {(() => {
            if(supportNumber != null || supportEmail != null) {     
              return(       
            <section className={classes.mainboxsearch}>  
                  <List className={classesr.list}>
                    {(() => {
                      if(supportNumber != null) {
                        return(
                          <ListItem className={classesr.listItem} key={"3"}> 
                            <Button 
                                href=""
                                target="_blank"
                                className={classesr.dropdownLink}
                            >
                              <i className={"fa fa-phone-square"}></i>&nbsp;
                            +{supportNumber} 
                            </Button>
                          </ListItem>
                        )}
                      })()}
                    {(() => {
                      if(supportEmail != null) {
                        return(
                      <ListItem className={classesr.listItem} key={"4"}>
                        {menusidebartopDb[sessionStorage.getItem('lang')].map((jsondata) => (  
                        <Button
                            href=""
                            key={jsondata.id}
                            target="_blank"
                            className={classesr.dropdownLink + " suppotremail"}
                            
                        >
                          <i className={"fa fa-envelope"}></i>&nbsp; 
                          <span style={{ overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: 'inline-block',
                              "-webkit-line-clamp": '1',
                              "-webkit-box-orient": 'vertical',
                              width:'280px',
                              paddingLeft: "7px",
                              textAlign: 'left',
                              }}>{supportEmail}</span>
                        </Button>
                      ))}
                      </ListItem>
                      )}
                      })()}
                    </List>    
            </section>
              )
            }else{
              return(
              <span></span>
              )
            }
            })()}

            {usermenuDb[sessionStorage.getItem('lang')].map((jsondata) => (
            <section className={classes.navigationwrap}>
              
              <div className={classes.navigationitem + " offersidebar active"} onClick={offersidebar}> 
                <img src={Offer} className={"offer"} alt=""/>
                <img src={Offeractive} className={"offeractive"} style={{display:"none"}} alt=""/>
                {jsondata.offers}    
              </div>

              <div className={classes.navigationitem + " gamesidebar"}  onClick={gamesidebar}>
                <img src={Games} className={"games"} alt=""/>
                <img src={Gamesactive} className={"gamesactive"} style={{display:"none"}} alt=""/>
               {jsondata.game}     
              </div>
              
              <div className={classes.navigationitem + " signinsidebar"}  onClick={signinsidebar}>  
                    <img src={Menuico} className={"menuico"} alt=""/>
                    <img src={Menuicoactive} className={"menuicoactive"} style={{display:"none"}} alt=""/>
                    {jsondata.sitemenu}
              </div> 
              
            </section>
            ))}
            
             <SectionAllprofile {...sectionPopup} {...props} />
              <div className={classes.offersbg +  " offersbg "}>  
                 {/* <img src={Offer2} className={classes.sss} />  */}
                 <Offers {...sectionPopup} {...props} themeColors={props.themeColors} SectionLoginboxMobile={SectionLoginboxMobile} SectionSignupboxMobile={SectionSignupboxMobile} SectionLoginbox={SectionLoginbox} SectionSignupbox={SectionSignupbox} />
              </div>
              <div className={classes.headsigninbg +  " headsigninbg " + mobilmenuset}>  

                <List className={classes.listsign}>
                <Accordion>
                

            {(() => {
              if(Array.isArray(dataOfMenu) ) { 
                return dataOfMenu.map((jsondata) => (
            <div className="sad">
                   
                      <AccordionItem className={classes.accordianiitm + " " + classes.listItemsign}>
                          <AccordionItemHeading className={classes.accordianbtn}>
                            <AccordionItemButton className={classes.navmenu + " accordion__button"}>
                            
                            {(() => {
                            if(jsondata.submenu === null) { 
                              return (
                                <Button
                                   href={jsondata.path}
                                  color="inherit" 
                                  className={classes.menusignin}
                              >
                               
                                <span className={classes.listItemsignimg} ><img src={jsondata.icon}   alt=""/></span> {jsondata.menu_name}  
                              </Button>
                                ) 
                              }else{
                                return (
                                <Button
                                    // href={jsondata.path}
                                    color="inherit" 
                                    className={classes.menusignin}
                                >
                                  
                                  <span className={classes.listItemsignimg} ><img src={jsondata.icon} alt=""/></span> {jsondata.menu_name}  
                                </Button>)
                              }
                              })()
                            }
                              




                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className={classes.accordianp}>
                          {(() =>  {
                        
                        if (jsondata.submenu) {
                          let submenu = JSON.parse("["+jsondata.submenu+"]");
                        return (
                          <div>
                          {submenu.map((jsondataIn) => (
                        
                              <Button
                                  href={jsondataIn.path}
                                  color="inherit" 
                                  className={classes.menusignin}
                              >
                                <img src={jsondataIn.icon} style={{height: "24px", marginRight: "5px", background: "rgba(0,0,0,0.5)", borderRadius: "40px", padding: "4px", verticalAlign: "bottom"}} alt=""/> {jsondataIn.menu_name}
                              </Button>
                             ))}
                      </div>
                             ) 
                            } 
                            })()} 
                            
                          </AccordionItemPanel>
                      </AccordionItem>
              
                 </div>
                  )
                  )
                }
                })()
              }
                </Accordion>
                </List>
              </div>


              <div onClick={signinsidebar} className={classes.signinsidebarbox + " signinsidebarbox"}> 
                <div className={classes.signinheadersidebar}> 
                  <div className={classes.sportosidemenu + " sportosidemenu"}>
                    <List className={classes.listheadsidebar}> 


                    {(() =>  {
                if(jwtService.isAuthenticationLogin() === true){
                  return ( 
                    <div className="assdas"> 
                    
                    
                    <div   className={classes.othermenu + " "}>
                      {dataSidebar.map((jsondata) => ( 
                        <ListItem className={classes.listheadsidebaritem} key={jsondata.id}>
                          <Button
                              href={jsondata.path}  
                              color="inherit" 
                              className={classes.listheadsidebaranker}
                          > 
                            <span dangerouslySetInnerHTML={createMarkup(jsondata.menu_name)} />
                          </Button>
                        </ListItem>
                      ))}
                      </div>
                    </div>
                    )
                  }else{
                    return (
                      <div>  
                      {dataSidebar.map((jsondata) => ( 
                        <ListItem className={classes.listheadsidebaritem} key={jsondata.id}>
                          <Button
                              href={jsondata.path}  
                              color="inherit" 
                              className={classes.listheadsidebaranker}
                          > 
                            <span dangerouslySetInnerHTML={createMarkup(jsondata.menu_name)} />
                          </Button>
                        </ListItem>
                      ))} 
                      </div>
                      )
                    }
                  })()}  

                    </List>
                  </div>
                </div>
              </div>
            


          </AppBar>
        </div>
        
        {/* <Button  
          href="#top"   
          color="default"
          key="222"
          className={`${isMobile ? "mobile-class fixheadermobile1" : "non-mobile-class fixheadermobile1"}`}
        >
            <i className={"fa fa-arrow-up"}></i> Top
        </Button>           */}
      
      </div>   
    </PageVisibility>
  );
}

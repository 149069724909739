import React from 'react';
import GLogin from 'react-google-login';

function GoogleLogin(props)
{
   return (
        <GLogin
            clientId={props.googleClientId}
            buttonText={props.buttonValue}
            onSuccess={props.googleOnResponse}
            onFailure={props.googleOnResponse}
            scode={props.googleScope}
            cookiePolicy={'single_host_origin'}
        />
    )
}

export default React.memo(GoogleLogin);
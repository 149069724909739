import React from "react"; 
import {makeStyles} from "@material-ui/core/styles";   
import 'react-accessible-accordion/dist/fancy-example.css'; 

import styles from "../assets/jss/components/tabsDepositstyle.js"; 
import Slide from "@material-ui/core/Slide";  

import Background4 from '../assets/img/uploaddw.png';
const useStyles = makeStyles(styles); 
const Transition = React.forwardRef(function Transition(props, ref) {
    
    return <Slide direction="down" ref={ref} {...props}  />;
    
}); 
Transition.displayName = "Transition";


  


    const ImgUpload =({
        onChange,
        src,
      })=>{
        const classes = useStyles();  
        return(
          <label for="photo-upload" className={classes.custom_file_upload + " " + classes.fas}>
            <div className={classes.img_wrap + " " + classes.img_upload} >
              <img for="photo-upload" className={classes.custom_fileimg} alt="" src={src}/>
            </div>
            <input id="photo-upload" type="file" filetype="jpg, png, gif" className={classes.custom_fileu} onChange={onChange}/> 
          </label>
        );
      } 
       
        
      
      class CardProfile extends  React.Component {
        onClickEvent = null;  
        constructor(props) {
          super(props);
          this.onClickEvent = props.onClick;
           this.state = {
             file: '',
             imagePreviewUrl: (props.imagePreviewUrl != null && props.imagePreviewUrl.length > 0) ? props.imagePreviewUrl :Background4,
             name:'',
             status:'',
             active: 'edit'
          };
        }
        
        photoUpload (e) {
          e.preventDefault();
          const reader = new FileReader();
          const file = e.target.files[0];
         
          reader.onloadend = () => {
            this.onClickEvent(1,file);
            this.setState({
              file: file,
              imagePreviewUrl: reader.result
            });
          }
          reader.readAsDataURL(file);
        }  
        
        render() {
          //alert(JSON.stringify(this.props.onReset))
          if(this.props.onReset != null && this.props.onReset === "YES"){
            this.setState({
              file: '',
              imagePreviewUrl: (Background4)
            });
            this.props.resetBack("NO");
          }
          const {imagePreviewUrl} = this.state;
          return (
            <div>
              { 
                
                    <ImgUpload onChange={(e)=>this.photoUpload(e)} src={imagePreviewUrl}/>
                      
                }
              
            </div>
          )
        }
      }
      
    export default CardProfile;
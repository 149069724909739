import * as Actions from '../actions';

const initialState = {
    success: false,
    data: null,
    error  : {
        username: null,
        password: null
    }
};

const resendotp = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.WLRESENDOTP_SUCCESS:
        {
            return {
                success: true,
                data: action.payload
            };
        }
        case Actions.WLRESENDOTP_ERROR:
        {
            return {
                success: false,
                error  : action.payload
            };
        }
        default:
        {
            return state
        }
    }
};

export default resendotp;
import React, {useState} from "react";
// @material-ui/core components
import 'react-accessible-accordion/dist/fancy-example.css';

import axios from 'axios';
import * as globalurl from '../../../global/Global.js'; 

import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js";
    
import styles from "../assets/jss/components/tabsStatementstyle.js";
import Button from "../elements/CustomButtons/Button";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import statementDb from "../data/statement.js"; 
// import * as Datetime from 'react-datetime'; 
//database selection 
import localDb from "../data/statement";
    import Norecord from "../assets/img/no-record.png";
const useStyles = makeStyles(styles);

// async function getDataFromServer(){
//     let pro = new Promise((resolve,reject) => {
//         const request = axios.post(globalurl.GETSTATEMENT , {
//             token: window.localStorage.getItem('jwt_access_token')
//         });
//         request.then((response) => {
//             //alert(JSON.stringify(response));
//                 if(response.data.data != null){
//                     var data = {
//                         "status":true,
//                         "data":response.data.data
//                     }
//                     resolve(data);
//                 }else{
//                     resolve({"status":false, "message":'No record found'});
//                 }
//         });
//     }); 
//     const val = await pro;
//     return val;
// }


export default function SectionStatementList(props) {  
    
    const [paymentType, setPaymentType] = useState("NO");
    const [dateConfig, setDateConfig] = React.useState("NO");
    const [limitStartFrom, setLimitStartFrom] = React.useState(0);
    const limitMaxRange = 5;
    const [loadMore, setLoadMore] = React.useState(false);
    const [isChange, setIsChange] = React.useState(false);
    const [statementData, setStatementData] = React.useState([{status: false}]);
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    
    if(props.themeColors != null){ 
        styles.listItem["&:nth-child(even)"].background = props.themeColors.bgColors.primary_dark_bgcolor;
        styles.listItem.color = props.themeColors.bgColors.text_primary_color; 
        styles.tableheading.background = props.themeColors.bgColors.primary_bgcolor; 
        styles.depositbtn.background = props.themeColors.bgColors.main_color;  
        styles.messagebox.background = props.themeColors.bgColors.secondary_dark_bgcolor; 

        styles.historytablebox["& table tr th"].background = props.themeColors.bgColors.secondary_dark_bgcolor;
        styles.historytablebox["& table tr td"].background = props.themeColors.bgColors.primary_dark_bgcolor; 
    }
    const classes = useStyles();
    const isObject = function(a) {
        return (!!a) && (a.constructor === Object);
    };
    if(paymentType !== props.paymentType){
        setLimitStartFrom(0);
        setIsChange(true)
        setLoadMore(true);
        setPaymentType(props.paymentType);
    }
    if(dateConfig === "NO"){
        setDateConfig({start: props.startDate, end: props.endDate})
    }
    if(dateConfig.start != null && (props.startDate !== dateConfig.start || props.endDate !== dateConfig.end)){
        setLimitStartFrom(0);
        setDateConfig({start: props.startDate, end: props.endDate});
        setIsChange(true);
        setLoadMore(true);
    }
    if((statementData[0].status === false && statementData[0].checked == null && props.statement != null) || (loadMore === true && props.statement != null)){
        var payment_type = "";
        var payArr = props.paymentType.split(",");
        var trans_type = "";
        if(payArr.length >= 3){
            payment_type = props.paymentType;
        }else if(payArr.length > 1){
            payment_type = payArr[0];
            trans_type = payArr[1];
        }else if(payArr.length > 0){
            payment_type = payArr[0];
        }
        
        const request = axios.post(globalurl.GETSTATEMENT , {
            token: window.localStorage.getItem('jwt_access_token'),
            payment_type:((payment_type !== "NO" && payment_type.length > 0) ? payment_type : null),
            start_date: dateConfig.start,
            end_date: dateConfig.end,
            LSTART: limitStartFrom,
            trans_type:((trans_type.length > 0)? trans_type : null),
            LEND: limitMaxRange
        });

        request.then((response) => {
        
            setLoadMore(false);
            var statementDb = "";
            if(response.data.data != null){
                statementDb = [{
                    "status":true,
                    "data":response.data.data
                }]
                if(statementData[0].status != null && statementData[0].status === true && isChange === false){
                    if(Array.isArray(statementDb[0].data)){
                        statementDb[0].data = [...statementData[0].data, ...statementDb[0].data];
                    }else if(statementDb[0].data != null){
                        statementDb[0].data = [...statementData[0].data, statementDb[0].data];
                    }else{
                        statementDb[0].data = statementData[0].data;
                    }
                    setStatementData(statementDb);
                }else{
                    setStatementData(statementDb);
                    setIsChange(false);
                }
            }else{
                statementDb = [{
                    "status":false,
                    "checked":false
                }]   
                if(statementData[0].status != null && statementData[0].status === true){
                    if(isChange === true){
                        setStatementData(statementDb);
                    }else{
                        setLimitStartFrom((limitStartFrom - limitMaxRange));
                    }
                }else{
                    setStatementData(statementDb);
                }
            }
        });
        request.catch((error) => {
            setLoadMore(false);
            var statementDb = [{
                "status":false
            }]   
            setStatementData(statementDb);
        });
    }
    var LoadMoreRecord = function(){
        setLimitStartFrom((limitStartFrom + limitMaxRange));
        setLoadMore(true);
    }
    return(
        <div>
        {(() => {
            if(statementData[0].status != null && statementData[0].status === true){
                return (<div id="nav-tabs" >
                {statementData.map((jsondata) => (
                        <div  className={classes.historytablebox} key={":myunique_1"}> 
                            <div className={classes.tablewrapresposnive}>
                            <table> 
                                <tr>
                                    {localDb[sessionStorage.getItem('lang')][0].tableheader.map((headData) => ( 
                                    <th key={headData.id}>{headData.field_name}</th> 
                                    ))} 
                                </tr>
                                {(() => { 
                                if(Array.isArray(jsondata.data)){ 
                                return (jsondata.data.map((bodyData) => (
                                    <tr key={bodyData.id}> 
                                        <td>{bodyData.txnno}</td> 
                                        <td>
                                            {(() => {
                                                if(bodyData.maintype === 8 && bodyData.game_type_id === 10){
                                                    var gameDataInfo = JSON.parse(bodyData.game_info);
                                                    if(gameDataInfo.Purchases != null && gameDataInfo.Purchases[0] != null && gameDataInfo.Purchases[0].Purchase != null && gameDataInfo.Purchases[0].Purchase[0] != null && gameDataInfo.Purchases[0].Purchase[0].Selections != null && gameDataInfo.Purchases[0].Purchase[0].Selections[0].Selection != null  && gameDataInfo.Purchases[0].Purchase[0].Selections[0].Selection[0].$.EventName != null){
                                                        var EventName = gameDataInfo.Purchases[0].Purchase[0].Selections[0].Selection[0].$.EventName;
                                                        return(<span className={classes.promotioncont}>{EventName}</span>)
                                                    }else{
                                                        return(<span className={classes.promotioncont}>{(JSON.parse(bodyData.game_info)).EventName}</span>)
                                                    }
                                                }else if(bodyData.maintype === 32 && bodyData.type === "DR"){
                                                    var gameDataInfo = JSON.parse(bodyData.game_info);
                                                    
                                                    if(bodyData.maintype === 8 && gameDataInfo.Purchases != null && gameDataInfo.Purchases[0] != null ){
                                                        var selectionType = "";
                                                        
                                                    }else if(gameDataInfo.$ === null){
                                                        return(<span className={classes.promotioncont}>{gameDataInfo}</span>)
                                                    }else if(gameDataInfo.$ != null && gameDataInfo.$.BetTypeID != null && gameDataInfo.$.BetTypeID === "1"){
                                                        return(<span className={classes.promotioncont}>{gameDataInfo.$.EventName}</span>)
                                                    }else{
                                                        var nameOfEvent = "";
                                                        if(gameDataInfo.Lines != null && Array.isArray(gameDataInfo.Lines)){
                                                            var startLine = 1;
                                                            gameDataInfo.Lines.map((innerGInfo) => {
                                                                if(nameOfEvent === ""){
                                                                    nameOfEvent = startLine + ":" + innerGInfo.$.EventName;
                                                                }else{
                                                                    nameOfEvent = nameOfEvent + "\n" + startLine + ":" + innerGInfo.$.EventName;
                                                                }
                                                                startLine++;
                                                            })
                                                        }else{
                                                            if(gameDataInfo.$ != null && gameDataInfo.$.BetTypeName != null){
                                                                nameOfEvent = gameDataInfo.$.BetTypeName;
                                                            }else{
                                                                nameOfEvent = "";
                                                            }
                                                            
                                                        }
                                                        
                                                        return(<span className={classes.promotioncont}>{nameOfEvent}</span>)
                                                    }
                                                    
                                                }else{
                                                    return(
                                                        <span className={classes.promotioncont}>{(JSON.parse(bodyData.game_info)).type}</span>
                                                    )
                                                }
                                            })()}
                                        </td> 
                                        <td>{(new Date(bodyData.created)).toLocaleDateString('en-US', DATE_OPTIONS)}</td> 
                                        <td>
                                            {(() => {
                                                if(bodyData.type === "CR"){
                                                    return (
                                                        <span>{bodyData.amount}</span>
                                                    );
                                                }else{
                                                    return (
                                                        <span>0</span>
                                                    );
                                                }
                                            })()}
                                        </td> 
                                        <td>
                                            {(() => {
                                                if(bodyData.type === "DR"){
                                                    return (
                                                        <span>{bodyData.amount}</span>
                                                    );
                                                }else{
                                                    return (
                                                        <span>0</span>
                                                    );
                                                }
                                            })()}
                                        </td> 
                                        <td>{(() => {if(parseFloat(bodyData.balance_avail).toFixed(2) === "0.00"){return ("0.00")}else{return(parseFloat(bodyData.balance_avail).toFixed(2))}})()}{((bodyData.maintype === 32 && bodyData.type === "DR") ? " Reserved" : "")}</td> 
                                        <td>{bodyData.status}</td>  
                                    </tr>
                                )))}else if(isObject(jsondata.data)){return(
                                    <tr> 
                                        <td>{jsondata.data.txnno}</td> 
                                        <td>
                                            {(() => {
                                                if(jsondata.data.maintype === 8 && jsondata.data.game_type_id === 10){
                                                    return(<span className={classes.promotioncont}>{(JSON.parse(jsondata.data.game_info)).EventName}</span>)
                                                }else if(jsondata.data.maintype === 32 && jsondata.data.type === "DR"){
                                                    var gameDataInfo = JSON.parse(jsondata.data.game_info);
                                                    
                                                    if(gameDataInfo.$ === null){
                                                        return(<span className={classes.promotioncont}>{gameDataInfo}</span>)
                                                    }else if(gameDataInfo.$ != null && gameDataInfo.$.BetTypeID != null && gameDataInfo.$.BetTypeID === "1"){
                                                        return(<span className={classes.promotioncont}>{gameDataInfo.$.EventName}</span>)
                                                    }else{
                                                        var nameOfEvent = "";
                                                        if(gameDataInfo.Lines != null && Array.isArray(gameDataInfo.Lines)){
                                                            var startLine = 1;
                                                            gameDataInfo.Lines.map((innerGInfo) => {
                                                                if(nameOfEvent === ""){
                                                                    nameOfEvent = startLine + ":" + innerGInfo.$.EventName;
                                                                }else{
                                                                    nameOfEvent = nameOfEvent + "\n" + startLine + ":" + innerGInfo.$.EventName;
                                                                }
                                                                startLine++;
                                                            })
                                                        }else{
                                                            if(gameDataInfo.$ != null && gameDataInfo.$.BetTypeName != null){
                                                                nameOfEvent = gameDataInfo.$.BetTypeName;
                                                            }else{
                                                                nameOfEvent = "";
                                                            }
                                                        }
                                                        
                                                        return(<span className={classes.promotioncont}>{nameOfEvent}</span>)
                                                    }
                                                    
                                                }else{
                                                    return(
                                                        <span className={classes.promotioncont}>{(JSON.parse(jsondata.data.game_info)).type}</span>
                                                    )
                                                }
                                            })()}
                                        </td> 
                                        <td>{(new Date(jsondata.data.created)).toLocaleDateString('en-US', DATE_OPTIONS)}</td> 
                                        <td>
                                            {(() => {
                                                if(jsondata.data.type === "CR"){
                                                    return (
                                                        <span>{jsondata.data.amount}</span>
                                                    );
                                                }else{
                                                    return (
                                                        <span>0</span>
                                                    );
                                                }
                                            })()}    
                                        </td> 
                                        <td>
                                            {(() => {
                                                if(jsondata.data.type === "DR"){
                                                    return (
                                                        <span>{jsondata.data.amount}</span>
                                                    );
                                                }else{
                                                    return (
                                                        <span>0</span>
                                                    );
                                                }
                                            })()}    
                                        </td> 
                                        <td>{(() => {if(parseFloat(jsondata.data.balance_avail).toFixed(2) === "0.00"){return ("0.00")}else{return(parseFloat(jsondata.data.balance_avail).toFixed(2))}})()}{((jsondata.data.maintype === 32 && jsondata.data.type === "DR") ? " Reserved" : "")}</td> 
                                        <td>{jsondata.data.status}</td> 
                                    </tr>
                                )}else{
                                    return(
                                    <tr key={"statement_nodata"}> 
                                        <td><p className={classes.promotioncont}><img src={Norecord} className={classes.norecordimg + " norecordimg"} style={{display:"block", margin:"0px auto"}} alt=""/></p></td> 
                                    </tr>
                                )
                                }})()}
                            </table>  
                            </div>

                        

                              {statementDb[sessionStorage.getItem('lang')].map((jsondata) => (
                                <Button
                                    href=""
                                    color="transparent"
                                    className={classes.depositbtn}
                                    onClick={LoadMoreRecord}
                                >
                               {jsondata.loadmore}
                                </Button>
                                ))}
                             <div className={classes.clearboth}></div>
                        </div>
                    ))}
            </div>);
            }else{
                return (
                    <ListItem className={classes.listItem} key={"statement_nodata"}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <p className={classes.promotioncont}><img src={Norecord} className={classes.norecordimg + " norecordimg"} style={{display:"block", margin:"0px auto"}} alt=""/></p>
                            </GridItem>
                        </GridContainer>
                    </ListItem>
                    );
            }
        })()}
        </div>
    );
}
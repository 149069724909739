

const usermenuDb = {
    en: [ 
        {
            'id'    : '5725a680b3249760eb21de01',
            'submenu_dropdown_name'  : 'Username', 
            'logoutbtn':'Logout',
            'loginbtn':'Login',
            'ragisterbtn':'Register',
            'loginsignupbtn':'Sign In / Sign Up',
            'game':'Game',
            'language':'Language',
            'offers':'Offers',
            'sitemenu':'Site Menu',
            'submenu': [
                {
                    'id'    : '5725a680b3249760eb21de02',
                    'sub_menu_name'  : 'Deposit',
                    'submenu_path' : '/deposit',
                    'tagname':  'tab2',
                    'subtagname' : "tab1",
                    'submenu_class' : 'fa fa-database',
                },
                {
                    'id'    : '5725a680b3249760eb21de03',
                    'sub_menu_name'  : 'Withdraw Request',
                    'submenu_path' : '/withdraw',
                    'tagname':  'tab2',
                    'subtagname' : "tab2",
                    'submenu_class' : 'fa fa-leaf',
                },
                {
                    'id'    : '5725a680b3249760eb21de04',
                    'sub_menu_name'  : 'Account',
                    'submenu_path' : '/account',
                    'tagname':  'tab1',
                    'subtagname' : "tab1",
                    'submenu_class' : 'fa fa-user',
                },
                {
                    'id'    : '5725a680b3249760eb21de05',
                    'sub_menu_name'  : 'Statement',
                    'submenu_path' : '/statement',
                    'tagname':  'tab2',
                    'subtagname' : "tab3",
                    'submenu_class' : 'fa fa-list',
                }, 
                {
                    'id'    : '5725a680b3249760eb21de09',
                    'sub_menu_name'  : 'Login History',
                    'submenu_path' : '/loginhistory',
                    'tagname':  'tab1',
                    'subtagname' : "tab5",
                    'submenu_class' : 'fa fa-user',
                },
                {
                    'id'    : '5725a680b3249760eb21de10',
                    'sub_menu_name'  : 'My Bonus',
                    'submenu_path' : '/mybonus',
                    'tagname':  'tab4',
                    'subtagname' : "bonus",
                    'submenu_class' : 'fa fa-gift',
                },
                {
                    'id'    : '5725a680b3249760eb21de11',
                    'sub_menu_name'  : 'My Bets',
                    'submenu_path' : '/mybets', 
                    'tagname':  'tab3',
                    'subtagname' : "bets",
                    'submenu_class' : 'fa fa-trophy',
                },
                {
                    'id'    : '5725a680b3249760edb21de11',
                    'sub_menu_name'  : 'Logout',
                    'is_popup'      : false,
                    'is_login'   : true,
                    'submenu_path' : '/logout', 
                    'submenu_class' : 'fa fa-power-off',
                },  
            ]
        },
          
    ],
    tm: [ 
        {
            'id'    : '5725a680b3249760eb21de01',
            'submenu_dropdown_name'  : 'பயனர்பெயர்', 
            'logoutbtn':'Logout',
            'loginbtn':'உள்நுழைய',
            'ragisterbtn':'பதிவு',
            'game':'விளையாட்டு',
            'loginsignupbtn':'உள்நுழைவு / உள்நுழைவு',
            'language':'மொழி',
            'offers':'சலுகைகள்',
            'sitemenu':'தள மெனு',
            'submenu': [
                {
                    'id'    : '5725a680b3249760eb21de02',
                    'sub_menu_name'  : 'வைப்பு',
                    'submenu_path' : '/deposit',
                    'tagname':  'tab2',
                    'subtagname' : "tab1",
                    'submenu_class' : 'fa fa-database',
                },
                {
                    'id'    : '5725a680b3249760eb21de03',
                    'sub_menu_name'  : 'கோரிக்கையைத் திரும்பப் பெறுங்கள்',
                    'submenu_path' : '/withdraw',
                    'tagname':  'tab2',
                    'subtagname' : "tab2",
                    'submenu_class' : 'fa fa-leaf',
                },
                {
                    'id'    : '5725a680b3249760eb21de04',
                    'sub_menu_name'  : 'கணக்கு',
                    'submenu_path' : '/account',
                    'tagname':  'tab1',
                    'subtagname' : "tab1",
                    'submenu_class' : 'fa fa-user',
                },
                {
                    'id'    : '5725a680b3249760eb21de05',
                    'sub_menu_name'  : 'அறிக்கை',
                    'submenu_path' : '/statement',
                    'tagname':  'tab2',
                    'subtagname' : "tab3",
                    'submenu_class' : 'fa fa-list',
                }, 
                {
                    'id'    : '5725a680b3249760eb21de09',
                    'sub_menu_name'  : 'உள்நுழைவு வரலாறு',
                    'submenu_path' : '/loginhistory',
                    'tagname':  'tab1',
                    'subtagname' : "tab5",
                    'submenu_class' : 'fa fa-user',
                },
                {
                    'id'    : '5725a680b3249760eb21de10',
                    'sub_menu_name'  : 'எனது போனஸ்',
                    'submenu_path' : '/mybonus',
                    'tagname':  'tab4',
                    'subtagname' : "bonus",
                    'submenu_class' : 'fa fa-gift',
                },
                {
                    'id'    : '5725a680b3249760eb21de11',
                    'sub_menu_name'  : 'என் பெட்ஸ்',
                    'submenu_path' : '/mybets', 
                    'tagname':  'tab3',
                    'subtagname' : "bets",
                    'submenu_class' : 'fa fa-trophy',
                },
                {
                    'id'    : '5725a680b3249760edb21de11',
                    'sub_menu_name'  : 'வெளியேறு',
                    'is_popup'      : false,
                    'is_login'   : true,
                    'submenu_path' : '/logout', 
                    'submenu_class' : 'fa fa-power-off',
                },  
            ]
        },
          
    ],
    kn: [ 
        {
            'id'    : '5725a680b3249760eb21de01',
            'submenu_dropdown_name'  : 'ಬಳಕೆದಾರ ಹೆಸರು', 
            'logoutbtn':'Logout',
            'loginbtn':'ಲಾಗಿನ್',
            'ragisterbtn':'ನೋಂದಣಿ',
            'game':'ಆಟ',
            'loginsignupbtn':'ಸೈನ್ ಇನ್ / ಸೈನ್ ಅಪ್',
            'language':'ಭಾಷೆ',
            'offers':'ಕೊಡುಗೆಗಳು',
            'sitemenu':'ಸೈಟ್ ಮೆನು',
            'submenu': [
                {
                    'id'    : '5725a680b3249760eb21de02',
                    'sub_menu_name'  : 'ಠೇವಣಿ',
                    'submenu_path' : '/deposit',
                    'tagname':  'tab2',
                    'subtagname' : "tab1",
                    'submenu_class' : 'fa fa-database',
                },
                {
                    'id'    : '5725a680b3249760eb21de03',
                    'sub_menu_name'  : 'ವಿನಂತಿಯನ್ನು ಹಿಂತೆಗೆದುಕೊಳ್ಳಿ',
                    'submenu_path' : '/withdraw',
                    'tagname':  'tab2',
                    'subtagname' : "tab2",
                    'submenu_class' : 'fa fa-leaf',
                },
                {
                    'id'    : '5725a680b3249760eb21de04',
                    'sub_menu_name'  : 'ಖಾತೆ',
                    'submenu_path' : '/account',
                    'tagname':  'tab1',
                    'subtagname' : "tab1",
                    'submenu_class' : 'fa fa-user',
                },
                {
                    'id'    : '5725a680b3249760eb21de05',
                    'sub_menu_name'  : 'ಹೇಳಿಕೆ',
                    'submenu_path' : '/statement',
                    'tagname':  'tab2',
                    'subtagname' : "tab3",
                    'submenu_class' : 'fa fa-list',
                }, 
                {
                    'id'    : '5725a680b3249760eb21de09',
                    'sub_menu_name'  : 'ಲಾಗಿನ್ ಇತಿಹಾಸ',
                    'submenu_path' : '/loginhistory',
                    'tagname':  'tab1',
                    'subtagname' : "tab5",
                    'submenu_class' : 'fa fa-user',
                },
                {
                    'id'    : '5725a680b3249760eb21de10',
                    'sub_menu_name'  : 'ನನ್ನ ಬೋನಸ್',
                    'submenu_path' : '/mybonus',
                    'tagname':  'tab4',
                    'subtagname' : "bonus",
                    'submenu_class' : 'fa fa-gift',
                },
                {
                    'id'    : '5725a680b3249760eb21de11',
                    'sub_menu_name'  : 'ನನ್ನ ಬೆಟ್ಸ್',
                    'submenu_path' : '/mybets', 
                    'tagname':  'tab3',
                    'subtagname' : "bets",
                    'submenu_class' : 'fa fa-trophy',
                },
                {
                    'id'    : '5725a680b3249760edb21de11',
                    'sub_menu_name'  : 'ಲಾಗ್ ಔಟ್',
                    'is_popup'      : false,
                    'is_login'   : true,
                    'submenu_path' : '/logout', 
                    'submenu_class' : 'fa fa-power-off',
                },  
            ],
        },
          
    ],
    ne: [ 
        {
            'id'    : '5725a680b3249760eb21de01',
            'submenu_dropdown_name'  : 'प्रयोगकर्ता नाम', 
            'logoutbtn':'Logout',
            'loginbtn':'लग - इन',
            'ragisterbtn':'रेजिष्टर गर्नुहोस्',
            'game':'खेल',
            'loginsignupbtn':'साइन इन / साइनअप',
            'language':'भाषा',
            'offers':'प्रस्तावहरू',
            'sitemenu':'साइट मेनू',
            'submenu': [
                {
                    'id'    : '5725a680b3249760eb21de02',
                    'sub_menu_name'  : 'निक्षेप',
                    'submenu_path' : '/deposit',
                    'tagname':  'tab2',
                    'subtagname' : "tab1",
                    'submenu_class' : 'fa fa-database',
                },
                {
                    'id'    : '5725a680b3249760eb21de03',
                    'sub_menu_name'  : 'अनुरोध फिर्ता लिनुहोस्',
                    'submenu_path' : '/withdraw',
                    'tagname':  'tab2',
                    'subtagname' : "tab2",
                    'submenu_class' : 'fa fa-leaf',
                },
                {
                    'id'    : '5725a680b3249760eb21de04',
                    'sub_menu_name'  : 'खाता',
                    'submenu_path' : '/account',
                    'tagname':  'tab1',
                    'subtagname' : "tab1",
                    'submenu_class' : 'fa fa-user',
                },
                {
                    'id'    : '5725a680b3249760eb21de05',
                    'sub_menu_name'  : 'वक्तव्य',
                    'submenu_path' : '/statement',
                    'tagname':  'tab2',
                    'subtagname' : "tab3",
                    'submenu_class' : 'fa fa-list',
                }, 
                {
                    'id'    : '5725a680b3249760eb21de09',
                    'sub_menu_name'  : 'लग ईन ईतिहास',
                    'submenu_path' : '/loginhistory',
                    'tagname':  'tab1',
                    'subtagname' : "tab5",
                    'submenu_class' : 'fa fa-user',
                },
                {
                    'id'    : '5725a680b3249760eb21de10',
                    'sub_menu_name'  : 'मेरो बोनस',
                    'submenu_path' : '/mybonus',
                    'tagname':  'tab4',
                    'subtagname' : "bonus",
                    'submenu_class' : 'fa fa-gift',
                },
                {
                    'id'    : '5725a680b3249760eb21de11',
                    'sub_menu_name'  : 'मेरो शर्त',
                    'submenu_path' : '/mybets', 
                    'tagname':  'tab3',
                    'subtagname' : "bets",
                    'submenu_class' : 'fa fa-trophy',
                },
                {
                    'id'    : '5725a680b3249760edb21de11',
                    'sub_menu_name'  : 'बाहिर निस्कनु',
                    'is_popup'      : false,
                    'is_login'   : true,
                    'submenu_path' : '/logout', 
                    'submenu_class' : 'fa fa-power-off',
                },  
            ], 
        },
          
    ],
    tl: [ 
        {
            'id'    : '5725a680b3249760eb21de01',
            'submenu_dropdown_name'  : 'వినియోగదారు పేరు', 
            'logoutbtn':'లాగ్ అవుట్',
            'loginbtn':'ప్రవేశించండి',
            'ragisterbtn':'నమోదు చేయండి',
            'loginsignupbtn':'సైన్ ఇన్ / సైన్అప్',
            'game':'గేమ్',
            'language':'భాష',
            'offers':'ఆఫర్లు',
            'sitemenu':'సైట్ మెనూ',
            'submenu': [
                {
                    'id'    : '5725a680b3249760eb21de02',
                    'sub_menu_name'  : 'డిపాజిట్',
                    'submenu_path' : '/deposit',
                    'tagname':  'tab2',
                    'subtagname' : "tab1",
                    'submenu_class' : 'fa fa-database',
                },
                {
                    'id'    : '5725a680b3249760eb21de03',
                    'sub_menu_name'  : 'అభ్యర్థనను ఉపసంహరించుకోండి',
                    'submenu_path' : '/withdraw',
                    'tagname':  'tab2',
                    'subtagname' : "tab2",
                    'submenu_class' : 'fa fa-leaf',
                },
                {
                    'id'    : '5725a680b3249760eb21de04',
                    'sub_menu_name'  : 'ఖాతా',
                    'submenu_path' : '/account',
                    'tagname':  'tab1',
                    'subtagname' : "tab1",
                    'submenu_class' : 'fa fa-user',
                },
                {
                    'id'    : '5725a680b3249760eb21de05',
                    'sub_menu_name'  : 'ప్రకటన',
                    'submenu_path' : '/statement',
                    'tagname':  'tab2',
                    'subtagname' : "tab3",
                    'submenu_class' : 'fa fa-list',
                }, 
                {
                    'id'    : '5725a680b3249760eb21de09',
                    'sub_menu_name'  : 'లాగిన్ చరిత్ర',
                    'submenu_path' : '/loginhistory',
                    'tagname':  'tab1',
                    'subtagname' : "tab5",
                    'submenu_class' : 'fa fa-user',
                },
                {
                    'id'    : '5725a680b3249760eb21de10',
                    'sub_menu_name'  : 'నా బోనస్',
                    'submenu_path' : '/mybonus',
                    'tagname':  'tab4',
                    'subtagname' : "bonus",
                    'submenu_class' : 'fa fa-gift',
                },
               {
                    'id'    : '5725a680b3249760eb21de11',
                    'sub_menu_name'  : 'నా బెట్స్',
                    'submenu_path' : '/mybets', 
                    'tagname':  'tab3',
                    'subtagname' : "bets",
                    'submenu_class' : 'fa fa-trophy',
                },   
                {
                    'id'    : '5725a680b3249760edb21de11',
                    'sub_menu_name'  : 'లాగ్ అవుట్',
                    'is_popup'      : false,
                    'is_login'   : true,
                    'submenu_path' : '/logout', 
                    'submenu_class' : 'fa fa-power-off',
                },  
            ], 
        },
          
    ],
    hi: [
        {
            'id'    : '5725a680b3249760eb21de01',
            'submenu_dropdown_name'  : 'उपयोगकर्ता नाम', 
            'logoutbtn':'प्रस्थान करें',
            'loginbtn':'प्लॉग इन',
            'ragisterbtn':'रजिस्टर करें',
            'loginsignupbtn':'प्रवेश या साइन अप',
            'game':'खेल',
            'language':'भाषा',
            'offers':'ऑफर',
            'sitemenu':'साइट मेनू',
            'submenu': [
                {
                    'id'    : '5725a680b3249760eb21de02',
                    'sub_menu_name'  : 'जमा',
                    'submenu_path' : '/deposit',
                    'tagname':  'tab2',
                    'subtagname' : "tab1",
                    'submenu_class' : 'fa fa-database',
                },
                {
                    'id'    : '5725a680b3249760eb21de03',
                    'sub_menu_name'  : 'निकालना',
                    'submenu_path' : '/withdraw',
                    'tagname':  'tab2',
                    'subtagname' : "tab2",
                    'submenu_class' : 'fa fa-leaf',
                },
                {
                    'id'    : '5725a680b3249760eb21de04',
                    'sub_menu_name'  : 'खाता',
                    'submenu_path' : '/account',
                    'tagname':  'tab1',
                    'subtagname' : "tab1",
                    'submenu_class' : 'fa fa-user',
                },
                {
                    'id'    : '5725a680b3249760eb21de05',
                    'sub_menu_name'  : 'विवरण',
                    'submenu_path' : '/statement',
                    'tagname':  'tab2',
                    'subtagname' : "tab3",
                    'submenu_class' : 'fa fa-list',
                }, 
                {
                    'id'    : '5725a680b3249760eb21de09',
                    'sub_menu_name'  : 'लॉगिन इतिहास',
                    'submenu_path' : '/loginhistory',
                    'tagname':  'tab1',
                    'subtagname' : "tab5",
                    'submenu_class' : 'fa fa-user',
                },
                {
                    'id'    : '5725a680b3249760eb21de10',
                    'sub_menu_name'  : 'मेरा बोनस',
                    'submenu_path' : '/mybonus',
                    'tagname':  'tab4',
                    'subtagname' : "bonus",
                    'submenu_class' : 'fa fa-gift',
                },
                {
                    'id'    : '5725a680b3249760eb21de11',
                    'sub_menu_name'  : 'मेरा बेट्स',
                    'submenu_path' : '/mybets',
                    'tagname':  'tab3',
                    'subtagname' : "bets",
                    'submenu_class' : 'fa fa-trophy',
                },  
                {
                    'id'    : '5725a680b3249760edb21de11',
                    'sub_menu_name'  : 'लॉग आउट',
                    'is_popup'      : false,
                    'is_login'   : true,
                    'submenu_path' : '/logout', 
                    'submenu_class' : 'fa fa-power-off',
                },  
            ], 
        }  
    ],

};

export default usermenuDb;
// import history from '../../../@history';
import jwtService from '../../../services/jwtService';

export const KYC_SUCCESS = 'KYC_SUCCESS';
export const KYC_ERROR = 'KYC_ERROR';

export function uploadKYC(model, props) {
    return (dispatch) =>
    jwtService.uploadKYC(model)
    .then((response) => {
        return dispatch({
            type: KYC_SUCCESS,
            payload: response
        });
    }
    )
    .catch(error => {
        return dispatch({
            type: KYC_ERROR,
            payload: error
        });
    });
}
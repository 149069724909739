import history from '../../../@history';
//import * as UserActions from './user.actions';
import jwtService from '../../../services/jwtService';
import {setUserData} from './user.actions';
//import * as Actions from '../../../store/actions';
 
export const OTPNEW_ERROR = 'OTPNEW_ERROR';
export const OTPNEW_SUCCESS = 'OTPNEW_SUCCESS';
 
  

export function submitOtpNew({mobile, country_id, otp},props)
{ 
    return (dispatch) =>
        jwtService.signInOtpNew(mobile, country_id, otp)
            .then((user) => {  
                    if(user != null && user.PROFILETOKEN != null && user.PROFILETOKEN != "0" && user.PROFILESID != null && user.PROFILESID != "0"){
                         
                        user = {
                            role: ['player'],
                            data: {
                                displayName: user.username,
                                photoURL   : user.username,
                                email      : user.username,
                                settings   : {},
                                shortcuts  : []
                            }
                        };
                        dispatch(setUserData(user));
                    }
                    return dispatch({
                        type: OTPNEW_SUCCESS,
                        payload: user 
                    });
                }
            )
            .catch(error => { 
                return dispatch({
                    type   : OTPNEW_ERROR,
                    payload: error
                });
            });
}
 

const footertopDb = {
    en: [
        {
            'id'    : '5725a680b3249760ea21ee52',
            'subtitle'  : 'subscribe to iSoft Gaming',
            'title'  : "To Get Exclusive Benefits",
            'placeholder' : 'Your Email Address',
            'copyrighttext' : 'Copyright © 2020.All Rights Reserved By',
            'copyrightname' : 'iSoftGaming',
            'btntext':"Subscribe",
            'submenu':[
                {
                    'id'    : '5725a680b3249760ea21ee51',
                    'menu_link':"/",
                    'menu_name':"Terms Of Use",
                },
                {
                    'id'    : '5725a680b3249760ea21ee52',
                    'menu_link':"/",
                    'menu_name':"Privacy Policy",
                },
                {
                    'id'    : '5725a680b3249760ea21ee53',
                    'menu_link':"/",
                    'menu_name':"Other Policies",
                },
                {
                    'id'    : '5725a680b3249760ea21ee54',
                    'menu_link':"/",
                    'menu_name':"Faq",
                },
                {
                    'id'    : '5725a680b3249760ea21ee55',
                    'menu_link':"/",
                    'menu_name':"Help Center",
                }, 
            ]
        }, 
    ],
    tl: [
        {
            'id'    : '5725a680b3249760ea21ee52',
            'subtitle'  : 'ఐసోఫ్ట్ గేమింగ్‌కు సభ్యత్వాన్ని పొందండి',
            'title'  : "ప్రత్యేకమైన ప్రయోజనాలను పొందడానికి",
            'placeholder' : 'మీ ఇమెయిల్ చిరునామా',
            'copyrighttext' : 'కాపీరైట్ © 2020. అన్ని హక్కులూ ప్రత్యేకించుకోవడమైనది',
            'copyrightname' : 'ఐసోఫ్ట్ గేమింగ్', 
            'btntext':"సభ్యత్వాన్ని పొందండి",
            'submenu':[
                {
                    'id'    : '5725a680b3249760ea21ee51',
                    'menu_link':"/",
                    'menu_name':"Terms Of Use",
                },
                {
                    'id'    : '5725a680b3249760ea21ee52',
                    'menu_link':"/",
                    'menu_name':"Privacy Policy",
                },
                {
                    'id'    : '5725a680b3249760ea21ee53',
                    'menu_link':"/",
                    'menu_name':"Other Policies",
                },
                {
                    'id'    : '5725a680b3249760ea21ee54',
                    'menu_link':"/",
                    'menu_name':"Faq",
                },
                {
                    'id'    : '5725a680b3249760ea21ee55',
                    'menu_link':"/",
                    'menu_name':"Help Center",
                }, 
            ]
        }, 
    ],
    hi: [
        {
            'id'    : '5725a680b3249760ea22de52',
            'subtitle'  : 'आइसॉफ्ट गेमिंग के लिए सदस्यता लें',
            'title'  : "विशेष लाभ प्राप्त करने के लिए",
            'placeholder' : 'आपका ईमेल पता',
            'btntext':"सदस्यता लें",
            'copyrighttext' : 'कॉपीराइट © 2020. सर्वाधिकार सुरक्षित',
            'copyrightname' : 'आइसॉफ्ट गेमिंग',
            'submenu':[
                {
                    'id'    : '5725a680b3249760ea21ee56',
                    'menu_link':"/",
                    'menu_name':"उपयोग की शर्तें",
                },
                {
                    'id'    : '5725a680b3249760ea21ee57',
                    'menu_link':"/",
                    'menu_name':"गोपनीयता नीति",
                },
                {
                    'id'    : '5725a680b3249760ea21ee58',
                    'menu_link':"/",
                    'menu_name':"अन्य नीतियाँ",
                },
                {
                    'id'    : '5725a680b3249760ea21ee59',
                    'menu_link':"/",
                    'menu_name':"सामान्य प्रश्न",
                },
                {
                    'id'    : '5725a680b3249760ea21ee60',
                    'menu_link':"/",
                    'menu_name':"सहायता केंद्र",
                }, 
            ]
        },   
    ],
    

};

export default footertopDb;
import { container } from "../../casino-kit-react.js";

const carouselStyle = {
  sectionhomepageslider: {
    padding: "138px 0 0"
  },
  fullimgwidth:{
     
  },
  slidertext:{
    position: "absolute",
    right: "130px",
    zIndex: "19",
    width: "330px",
    background: "#ed2024",
    bottom: "0px",
    borderRadius: "0 0 60px 60px", 
    boxShadow: "0 0 5px 5px rgb(0 0 0 / 30%)",
    textAlign:"center",
    color:"#fff",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      right: "20px",
    },
    ['@media (max-width:992px)']: { // eslint-disable-line no-useless-computed-key
      right: "100px",
    },
    ['@media (max-width:720px)']: { // eslint-disable-line no-useless-computed-key
      width: "250px",
    },
    ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
      display: "none",
    },
    "& h3":{
      textTransform: "uppercase",
      fontSize: "34px",
      textShadow: "2px 2px 3px #580b0d",
      fontWeight: "bold",
      marginBottom: "0",
      ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
        fontSize: "24px",
        margin: "0",
      },
    },
    "& h5":{
      textShadow: "2px 2px 3px #580b0d",
      fontWeight: "bold",
      fontSize: "22px",
      margin: "0",
      borderBottom: "1px solid #fff",
      paddingBottom: "8px",
      ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
        fontSize: "16px", 
      },
    },
    "& p":{
      fontSize: "13px",
      fontWeight: "bold",
      lineHeight: "18px",
      padding: "10px",
      margin: "0",
      ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
        fontSize: "11px", 
        lineHeight: "14px",
      },
    },
    "& p:last-child":{
      fontSize: "10px",
      fontWeight: "normal",
      lineHeight: "12px",
      padding: "0 15%",
      margin: "0 0 10px 0", 
      ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
        fontSize: "8px",  
      },
      ['@media (max-width:720px)']: { // eslint-disable-line no-useless-computed-key
        display: "none",  
      },
    }
  },
  sliderjoinbtn:{
    background:"#fff",
    color:"#000",
    fontSize:"18px",
    borderRadius:"10px",
    textAlign:"center",
    padding:"5px 40px",
    margin:"0 0 10px 0",
    textTransform: "capitalize",
    ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: "12px",  
    },
  },
  carouselpromofirst:{
    padding: "40px 40px 10px",
    "& button.slick-arrow.slick-prev":{
      width: "auto",
      left: "-22px",
    },
    "& button.slick-arrow.slick-next":{
      width: "auto",
      right: "-22px",
    },
  },
  pageheadinginner:{
    color: "#ffb80c !important",
    padding: "0px 10px 20px",
    fontSize: "22px",
    fontFamily: "ubuntu",
  },
  sllidwrap:{
    background:"#2d2d2d !important",
  },
  slideImg:{
    margin:"0px 2%",
    borderRadius:"5px",
    maxWidth:"96%"
  },
  slidemainn:{
    padding:"0px 10px",
  },
  slideDes:{
    position:"inherit !important",
    marginTop:"-10px",
    paddingTop:"20px",
    borderRadius:"5px",
    paddingBottom:"10px !important",
    background:"#3c3d3e !important",
    "& h4":{
      fontSize:"18px",
      fontFamily: "ubuntu",
    },
    "& p":{
      fontFamily: "ubuntu",
      fontSize:"14px"
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      display: "block !important",
    },
  },
  sllidbox:{
    margin:"0px 0px 40px !important",
  },
  sllid:{
    margin:"0px",
    borderRadius:"0px",
  },
  container,
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important"
  }
};

export default carouselStyle;

// import * as UserActions from './user.actions';
//import * as Actions from '../../../src/store/actions';
import jwtService from '../../../services/jwtService';
import {setUserData} from './user.actions';
export const REGISTERMOBILE_ERROR = 'REGISTERMOBILE_ERROR';
export const REGISTERMOBILE_SUCCESS = 'REGISTERMOBILE_SUCCESS';
 

export function submitRegisterMobile({username, name, email, mobile, country_id, otp},SectionSignupBoxMobile)
{
    
    return (dispatch) =>
        jwtService.createUserMobile({
            username,
            email,
            country_id,
            name: name,
            mobile: mobile,
            otp,
        })
            .then((user) => { 
                
                    if(user != null && user.TOKEN != null && user.TOKEN != "0" && user.SIDTOKEN != null && user.SIDTOKEN != "0"){
                         
                        user = {
                            role: ['player'],
                            data: {
                                displayName: user.username,
                                photoURL   : user.username,
                                email      : user.username,
                                settings   : {},
                                shortcuts  : []
                            }
                        };
                        dispatch(setUserData(user));
                    }
                    //dispatch(UserActions.setUserData(user)); 
                    //SectionSignupbox("stop"); 
                    return dispatch({
                        type: REGISTERMOBILE_SUCCESS,
                        payload: user 
                    });
                }
            )
            .catch(error => { 
                return dispatch({
                    type   : REGISTERMOBILE_ERROR,
                    payload: error
                });
            });
}

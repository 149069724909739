// import {authRoles} from '../auth';

const navigationConfig = [

	 {
        'id'   : 'dashboard',
        'title': 'Dashboard',
        'type' : 'item',
        'icon' : 'dashboard',
        'url'  : '/dashboard'
    },
    {
        'type': 'divider',
        'id'  : 'divider-1'
    }, 
];

export default navigationConfig;

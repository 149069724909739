

const balanceDb = {
    en: [
        {
            'id':'3923923894',
            'title':"Deposit",
            'tagName': 'tab1', 
        },
        {
            'id':'3923923894',
            'title':"Withdraw",
            'tagName': 'tab2', 
        },
        {
            'id':'3923923894',
            'title':"History",
            'tagName': 'tab3', 
        },
        {
            'id':'3923923894',
            'title':"Bank Account",
            'tagName': 'tab4', 
        },
        
    ],
    tm: [
        {
            'id':'3923923894',
            'title':"வைப்பு",
            'tagName': 'tab1', 
        },
        {
            'id':'3923923894',
            'title':"திரும்பப் பெறுங்கள்",
            'tagName': 'tab2', 
        },
        {
            'id':'3923923894',
            'title':"வரலாறு",
            'tagName': 'tab3', 
        },
        
    ],
    kn: [
        {
            'id':'3923923894',
            'title':"ಠೇವಣಿ",
            'tagName': 'tab1', 
        },
        {
            'id':'3923923894',
            'title':"ಹಿಂತೆಗೆದುಕೊಳ್ಳಿ",
            'tagName': 'tab2', 
        },
        {
            'id':'3923923894',
            'title':"ಇತಿಹಾಸ",
            'tagName': 'tab3', 
        },
        
    ],
    ne: [
        {
            'id':'3923923894',
            'title':"निक्षेप",
            'tagName': 'tab1', 
        },
        {
            'id':'3923923894',
            'title':"निकाल्नु",
            'tagName': 'tab2', 
        },
        {
            'id':'3923923894',
            'title':"ईतिहास",
            'tagName': 'tab3', 
        },
        
    ],
    tl: [
        {
            'id':'3923923894',
            'title':"డిపాజిట్",
            'tagName': 'tab1', 
        },
        {
            'id':'3923923894',
            'title':"ఉపసంహరించుకోండి",
            'tagName': 'tab2', 
        },
        {
            'id':'3923923894',
            'title':"చరిత్ర",
            'tagName': 'tab3', 
        },
        
    ],
    hi: [
        {
            'id':'3923923894',
            'title':"जमा",
            'tagName': 'tab1', 
        },
        {
            'id':'3923923894',
            'title':"निकालना",
            'tagName': 'tab2', 
        },
        {
            'id':'3923923894',
            'title':"इतिहास",
            'tagName': 'tab3', 
        }, 
    ]
}
export default balanceDb;

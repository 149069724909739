

const tabnotificationeditDb = {
    en: [
        { 
            'id': '2342424424',   
            'noti_name1': 'Internal Messages', 
            'noti_name2': 'Push Notifications',       
            'noti_name3': 'Phone Call ',      
            'noti_name4': 'Email ',      
            'noti_name5': 'SMS',       
            'noti_yes': 'Yes',         
            'noti_no': 'No',   
            'editnotification':'Edit Notifications',
            'submitbtn':'Submit',
            'cancelbtn':'Cancel',  
        },
    ],
    tl: [
        { 
            'id': '2342424424',   
            'noti_name1': 'అంతర్గత సందేశాలు', 
            'noti_name2': 'పుష్ నోటిఫికేషన్‌లు',       
            'noti_name3': 'ఫోన్ కాల్ ',      
            'noti_name4': 'ఇమెయిల్',      
            'noti_name5': 'SMS',       
            'noti_yes': 'అవును',         
            'noti_no': 'లేదు',   
            'editnotification':'నోటిఫికేషన్‌లను సవరించండి',
            'submitbtn':'సమర్పించండి',
            'cancelbtn':'రద్దు చేయండి',  
        },
    ],
    hi: [
        { 
            'id': '2342424424', 
            'noti_name1': 'आंतरिक संदेश',     
            'noti_name2': 'सूचनाएं भेजना',      
            'noti_name3': 'फ़ोन कॉल ',     
            'noti_name4': 'ईमेल',       
            'noti_name5': 'एसएमएस',       
            'noti_yes': 'हाँ',     
            'noti_no': 'नहीं',   
            'editnotification':'सूचनाएं संपादित करें',
            'submitbtn':'प्रस्तुत',
            'cancelbtn':'रद्द करना',   
        },
    ]
}
export default tabnotificationeditDb;

import { container } from "../../casino-kit-react.js";

const carouselStyle = {
  sectionhomepageslider: {
    padding: "138px 0 0"
  },
  fullimgwidth:{
    padding:"0px 7px",
    borderRadius:"20px",
    ['@media (max-width:500px)']: { // eslint-disable-line no-useless-computed-key
      padding:"0px 3px",
      borderRadius:"10px",
    },
  },
  carouselpromofirst:{
    padding: "40px 40px 10px",
    "& button.slick-arrow.slick-prev":{
      width: "auto",
      left: "-22px",
    },
    "& button.slick-arrow.slick-next":{
      width: "auto",
      right: "-22px",
    },
  },
  pageheadinginner:{
    color: "#ffb80c !important",
    padding: "0px 10px 20px",
    fontSize: "22px",
    fontFamily: "ubuntu",
  },
  sllidwrap:{
    background:"#2d2d2d !important",
  },
  slideImg:{
    margin:"0px 2%",
    borderRadius:"5px",
    maxWidth:"96%"
  },
  slidemainn:{
    padding:"0px 10px",
  },
  slideDes:{
    position:"inherit !important",
    marginTop:"-10px",
    paddingTop:"20px",
    borderRadius:"5px",
    paddingBottom:"10px !important",
    background:"#3c3d3e !important",
    "& h4":{
      fontSize:"18px",
      fontFamily: "ubuntu",
    },
    "& p":{
      fontFamily: "ubuntu",
      fontSize:"14px"
    },
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      display: "block !important",
    },
  },
  sllidbox:{
    margin:"0px 0px 40px !important",
  },
  sllid:{
    margin:"0px",
    borderRadius:"0px",
    background:"none",
    boxShadow:"0 0 0",
    "& button.slick-arrow.slick-prev":{
      width:"20%",
      marginRight:"7px",
      background:"linear-gradient(270deg, #00000000, #000) !important",
      opacity:"1"
    },
    "& button.slick-arrow.slick-next":{
      width:"20%",
      marginLeft:"7px",
      background:"linear-gradient(90deg, #00000000, #000 ) !important",
      opacity:"1"
    },
  },
  container,
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important"
  }
};

export default carouselStyle;

import React from "react"; 
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import 'react-accessible-accordion/dist/fancy-example.css'; 
import GridContainer from "../elements/Grid/GridContainer.js";
import GridItem from "../elements/Grid/GridItem.js"; 
import Button from "@material-ui/core/Button";
import styles from "../assets/jss/components/tabsPromotionstyle.js";
import axios from 'axios'; 
import queryString from 'query-string';
import Slide from "@material-ui/core/Slide"; 
import * as globalurl from '../../../global/Global.js';  

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);

Transition.displayName = "Transition";
var webServiceRunning = false;
export default function Verifyemail(props) {
    const classes = useStyles();
    let params = queryString.parse(props.location.search);
    styles.messagebox.background = props.themeColors.bgColors.messagebox;
    styles.listItem["&:nth-child(even)"].background = props.themeColors.bgColors.messageboxlist;
    styles.listItem.color = props.themeColors.bgColors.messageboxlistcolor;

    const [messageTitle, setMessageTitle] = React.useState("Code is expired or account is not registered.");
    const [userName, setUserName] = React.useState("User");
    const [color, setColor] = React.useState({color:'red', paddingTop:'20px'});
    const [messageContent, setMessageContentTitle] = React.useState("Your mobile has not been verified. Please re verify your mobile or may be token is expired");
    
    if(params != null && params.code && params.number && webServiceRunning === false){
        verifyMobile();
    }
    function verifyMobile(){
      webServiceRunning = true;
      const request = axios.post(globalurl.VERIFYMOBILEDIRECT, {
        token: params.code,
        mobile: params.number
      });
        request.then((response) => {
            if(response != null && response.data != null && response.data.status === true){
              setColor({color:'green', paddingTop:'20px'});
              setMessageTitle("MOBILE NUMBER VERIFIED");
              setMessageContentTitle("Thank you, Your mobile (" + response.data.mobile_number + ") has been verified. Your account is now active.");
              setUserName(response.data.name)
            }
            webServiceRunning = false;
        });
        request.catch((error) => {
          webServiceRunning = false;
        });
    }

    function parseJwt(token) {
        if (!token) { return; }
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }

    function getCurrentThemeInfo(){
        if(window.localStorage.getItem('jwt_theme_token') != null){
            let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
            return themeData;
        }
      } 

    var icon = null;
    var brandName = null;
    var themeInfo = getCurrentThemeInfo(); 
    //alert(JSON.stringify(themeInfo))
    if(themeInfo != null && themeInfo.id != null){
        if(themeInfo.brand_name != null && themeInfo.brand_name.length > 0){
          brandName = themeInfo.brand_name;
        }
        if(themeInfo.icon != null && themeInfo.icon.length > 0){
          icon = themeInfo.icon;
        } 
    } 
    
    return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{textAlign:"center", color:"#fff"}}>
                {(() => {
                    // alert(JSON.stringify(themeInfo))
                      if(themeInfo.icon !== null && (themeInfo.icon !== undefined && themeInfo.icon !== "undefined" && themeInfo.icon.length > 0)) { 
                              return (
                                <a href="/">
                                  <img src={icon} height={"50px"} alt={""} className={classes.imglogo2} />
                               </a>
                      ) }else{
                        return (
                            <a href="/">
                              <h4 className={classes.logoname}>{brandName}</h4>
                            </a>
                          )
                        }
                        })()}

                <div className={classes.successwrap}>
                    <h4 style={color}>{messageTitle}</h4>
                    <div style={{textAlign:'center'  }}>
                    <i style={{fontSize:'50px',   }} className="fa fa-mobile"></i>
                    <h5 style={{fontWeight:'bold', color:'green', }}>Hello {userName},</h5>
                    <p>{messageContent}</p>
                </div>
                             
                </div>
                </GridItem>
            </GridContainer>
  
    );
  }
  
import { container } from "../../casino-kit-react.js";

const tabsStyle = {
  section: {
    background: "#EEEEEE",
    padding: "70px 0"
  },
  spikerecentitem:{
    paddingLeft:"6px",
    paddingRight:"6px"
  },
  sllid:{
    borderRadius:"0",
    "& .slick-arrow":{
      display:"none !important"
    }
  },
  spikerecentwinn:{
    marginLeft:"-6px",
    marginRight:"-6px",
    "& .singlewiner":{
      borderColor:"#292929",
      padding: "10px 15px 10px",
      minWidth: "100%",
      maxWidth: "100%",
      boxShadow:"0 0"
    },
    "& .singlewiner .leftbt":{
      color: "#ff3341",
      fontSize: "21px",
    },
    "& .singlewiner .name": { 
      fontSize: "22px",
      fontWeight: "lighter",
      marginBottom: "0px",
    },
    "& .singlewiner .date": { 
      fontSize: "14px",
      lineHeight: "20px",
      margin: "0 0 15px",
    },
    "& .singlewiner .id": { 
      background: "#0d0d0d",  
      marginTop: "-12px",
    }
  },
  accordianiitm:{
    background: "#1c294e !important",
    border: "none",
    boxShadow: "0px 0px 10px 0px rgba(51, 55, 69, 0.2)",
    borderRadius: "30px",
    marginTop: "15px",
  },
  roundCardHeader:{
    border:"0px",
  },
  recentwinn:{
    padding:"0px 12px 0",
  },
  recentwinnhead:{
    fontFamily: "'Ubuntu'",
    marginTop: "0",
  },
  accordianbtn: {
    "& div":{
      padding: "10px 10px",
      display: "flex",
      alignItems: "center",
      textAlign: "left",
      color: "#fff",
      fontSize: "18px",
      fontWeight: "600",
      outline: "none",
      textTransform: "uppercase",
      background: "#1c294e",
      fontFamily: "Ubuntu",
      boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.4)",
      position: "relative",
      borderRadius: "50px",
      width: "100%",
      ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
        fontSize: "16px",
        padding: "10px 17% 10px 10px",
        width: "100%",
      },
    },
    "& div::before":{
      position: "absolute",
      right: "20px",
    },
    "& div i":{
      width: "40px",
      height: "40px",
      fontSize: "24px",
      background: "#31b3f6",
      boxShadow: "-0.707px 0.707px 9.5px 0.5px rgba(6, 97, 144, 0.3)",
      borderColor: "1px solid rgba(198, 198, 198, 0.5)",
      color: "#ffffff",
      textAlign: "center",
      lineHeight: "40px",
      marginRight: "20px",
      borderRadius: "50%",
      ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
        marginRight: "10px",
        width: "46px",
      },
    },
    "& div:hover":{
      background: "#ffb80c",
    },
  },
  accordianp:{
    marginTop: "10px",
    textAlign:"left",
    padding: "20px 50px",
    fontFamily: "Open Sans",
    fontSize: "18px",
    color: "#a1aed4",
    ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
      padding: "10px 20px 20px 20px",
      fontSize: "16px",
    },
  },
  table:{
    boxShadow:" 0px 10px 40px 0px rgba(0, 0, 0, 0.4)",
    position: "relative",
    width: "100%",
    fontFamily: 'Open Sans',
    textAlign: "left",
    marginBottom: "1rem",
    color: "#212529",
    '& tr:hover': {
      background:"#192246",
    },
    '& td': {
      borderTop:"1px solid rgba(255, 255, 255, 0.09)",
      border:"0px",
      verticalAlign:"middle",
      padding:"20px 15px",
      fontWeight:"600",
      fontSize:"18px",
      textTransform:"uppercase",
      color:"#a1aed4",
    },
    '& th': {
      borderTop:"1px solid rgba(255, 255, 255, 0.09)",
      border:"0px",
      fontSize:"18px",
      fontWeight:"600",
      textTransform:"uppercase",
      borderBottom:"0px",
      padding:"20px 15px",
      background:"#0b122e",
      color:"#fff",
    },
    '& img': {
      width:"40px",
      height:"40px",
      borderRadius:"50%",
      verticalAlign:"middle",
      display:"inline-block"
    },
    '& td:nth-child(1) img': {
      marginRight:"5px",
    },
    '& td:nth-child(3) img': {
      marginRight:"5px",
    },
    '& td:nth-child(6) img': {
      marginRight:"5px",
    },
    '& td:nth-child(1)': {
      color:"#fff",
    },
    '& td:nth-child(3)': {
       color:"#045cf0",
    },
    '& td:nth-child(5)': {
      color:"#f0ad4e",
    },
    '& td:nth-child(6)': {
      color:"#f0ad4e",
    },
    '& td:last-child': {
      color:"#f0ad4e",
    },
  },
  singlegame:{
    height:"350px",
    display:"block",
    lineHeight:"300px",
    textAlign:"center",
    marginBottom:"40px",
    border:"1px solid #273b71",
    borderRadius:"10px",
    boxShadow:"0px 10px 15px 0px rgba(0, 0, 0, 0.4)",
    position:"relative",
    transition:"all 0.3s ease-in",
    '& img': {
      marginTop:"-25px",
      verticalAlign: "middle",
      animation:"opacityAnimation 2s linear infinite",
    },
    '&:hover': {
      borderColor:"#681f40",
      transform:"translateY(-10px)",
    },
  },
  mybtn2:{
    position:"absolute",
    display:"block",
    height:"50px",
    bottom:"-25px",
    left:"50%",
    fontFamily: 'Open Sans',
    transform:"translateX(-50%)",
    boxShadow:"0px 10px 15px 0px rgba(0, 0, 0, 0.4)",
    fontSize: "16px",
    textTransform: "uppercase",
    fontWeight: "600",
    lineHeight: "28px",
    width: "190px",
    color: "#fff",
    border: "0px",
    borderRadius: "50px",
    cursor: "pointer",
    transition: "all 0.3s ease-in",
    background: "linear-gradient(to left, #1c294e, #1c294e)",
    '&:hover': {
      background: "linear-gradient(to right, #ff344d, #d2273c)",
      color: "#fff",
    },
  },
  container,
  textCenter: {
    textAlign: "center"
  }
};

export default tabsStyle;

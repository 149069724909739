/*eslint-disable*/
import React, {useEffect, useRef, useState} from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton"; 
import People from "@material-ui/icons/People";
import Email from "@material-ui/icons/Email"; 
// react components for routing our app without refresh
import { Link, withRouter } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// @material-ui/core components
import {Button, Divider, Typography, InputAdornment, Icon} from '@material-ui/core';
import CustomInput from "../elements/CustomInput/CustomInput.js";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// core components
import CustomDropdown from "../elements/CustomDropdown/CustomDropdown.js";
import CustomButton from "../elements/CustomButtons/Button.js";
//Third Party Authentication
import {FacebookLogin, GoogleLogin} from "../../../@casino"

import Formsy from 'formsy-react';
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import menuDb from "../data/menu.js";
import logo from "../assets/img/logo.png";
import Hometab from "../pages/hometab";
import GridItem from "../elements/Grid/GridItem";
import GridContainer from "../elements/Grid/GridContainer";
import styles from "../assets/jss/components/headerLinksStyle.js";
import stylesr from "../assets/jss/components/headerLinkstopStyle"; 

import * as authActions from '../../../auth/casino/actions';
import {useDispatch, useSelector} from 'react-redux';
import {TextFieldFormsy, CheckboxFormsy} from '../../../@casino';
import secloginboxDb from "../data/secloginbox.js";
//import { useTranslation } from 'react-i18next'
const history = createBrowserHistory()
let classicModal = null;
let setClassicModal = null;
let bodyData = null;
let setBodyData = null;
var manualClose = false;
let bodyDataOutPut = null
let setBodyDataOutPut = null;
function SectionTransactionDetail(props) {
    [classicModal, setClassicModal] = React.useState(false);
    [bodyData, setBodyData] = React.useState(false);
    [bodyDataOutPut, setBodyDataOutPut] = React.useState(false);
    const dispatch = useDispatch();
     
    if(props.themeColors != null){
      styles.signinbtn.backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinbtn.color = props.themeColors.bgColors.text_primary_dark_color;
      styles.modalHeader.background = props.themeColors.bgColors.primary_dark_bgcolor;
      styles.modalBody.background = props.themeColors.bgColors.primary_dark_bgcolor;
      styles.modalFooter.background = props.themeColors.bgColors.primary_dark_bgcolor;
      styles.modalSubtitle.color = props.themeColors.bgColors.text_primary_color;
      styles.signupbixp.color = props.themeColors.bgColors.text_primary_color; 
      styles.modalCloseButton.color = props.themeColors.bgColors.text_primary_color;
      styles.signinmodalbtn.backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn["&:hover"].backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn.color = props.themeColors.bgColors.text_primary_dark_color;
      styles.modalBodyprofile.background = props.themeColors.bgColors.primary_dark_bgcolor;

      styles.tableheading.background = props.themeColors.bgColors.primary_bgcolor; 
      styles.tableheading.color = props.themeColors.bgColors.text_primary_color; 
      styles.listItemss.color = props.themeColors.bgColors.text_primary_color; 
    }
    var setManualClose = function(state){
      manualClose = state;
    }
  //  const { t } = useTranslation()
    let currentStyle = styles;
    const useStyles = makeStyles(styles);

    const classes = useStyles();
    var totalStack = 0.00;
    var totalWinLost = 0.00;
    if((bodyDataOutPut === null || bodyDataOutPut === false) && bodyData != null && bodyData != false){
        setBodyDataOutPut(fetchDataValues());
    }
    function fetchDataValues(){
        return (
            <div className={classes.wraptable}>
                <h3 className={classes.accountsheads}>
                {(() => {
                        if(bodyData != null && bodyData != false){
                            if(((bodyData.maintype === 8 && bodyData.game_type_id === 10) || bodyData.maintype === 33 || bodyData.maintype === 31 || bodyData.maintype === 32)){
                                var gameDataInfoTwo = JSON.parse(bodyData.game_info);
                                if(bodyData.maintype === 32 && bodyData.type === "CR" && gameDataInfoTwo.info != null){
                                    gameDataInfoTwo = gameDataInfoTwo.info;
                                }
                                if(bodyData.maintype === 8 && gameDataInfoTwo.Purchases != null && gameDataInfoTwo.Purchases[0] != null ){
                                    var selectionType = "";
                                    if(gameDataInfoTwo.Purchases != null && gameDataInfoTwo.Purchases[0] != null && gameDataInfoTwo.Purchases[0].Purchase != null && gameDataInfoTwo.Purchases[0].Purchase[0] != null && gameDataInfoTwo.Purchases[0].Purchase[0].Selections != null && gameDataInfoTwo.Purchases[0].Purchase[0].Selections[0].Selection != null  && gameDataInfoTwo.Purchases[0].Purchase[0].Selections[0].Selection[0].$.EventName != null){
                                        var EventName = gameDataInfoTwo.Purchases[0].Purchase[0].Selections[0].Selection[0].$.EventName;
                                        return(<p className={classes.promotioncont}>{EventName}</p>)
                                    }else{
                                        return(<p className={classes.promotioncont}>{gameDataInfoTwo}</p>)
                                    }
                                }else  if(gameDataInfoTwo.$ === null){
                                    return(<p className={classes.promotioncont}>{gameDataInfoTwo}</p>)
                                }else if(gameDataInfoTwo.$ != null && gameDataInfoTwo.$.BetTypeID != null && gameDataInfoTwo.$.BetTypeID === "1"){
                                    return(<p className={classes.promotioncont}>{gameDataInfoTwo.$.EventName}</p>)
                                }else{
                                    var nameOfEvent = "";
                                    if(gameDataInfoTwo.Lines != null && Array.isArray(gameDataInfoTwo.Lines)){
                                        var startLine = 1;
                                        gameDataInfoTwo.Lines.map((innerGInfo) => {
                                            if(nameOfEvent === ""){
                                                nameOfEvent = startLine + ":" + innerGInfo.$.EventName;
                                            }else{
                                                nameOfEvent = nameOfEvent + "\n" + startLine + ":" + innerGInfo.$.EventName;
                                            }
                                            startLine++;
                                        })
                                    }else{
                                        if(gameDataInfoTwo.$ != null && gameDataInfoTwo.$.BetTypeName != null){
                                            nameOfEvent = gameDataInfoTwo.$.BetTypeName;
                                        }else{
                                            nameOfEvent = "";
                                        }
                                    }
                                    
                                    return(<p className={classes.promotioncont}>{nameOfEvent}</p>)
                                }
                            }
                        }
                    })()}
                </h3>
                <div className={classes.tableresponsive}>
                <List className={classes.list}>
                    <ListItem className={classes.listItemss + " " + classes.tableheading}>
                        <GridContainer>
                            <GridItem xs={2} sm={2} md={2}>
                                <p className={classes.promotionhead}>Bet ID</p>
                            </GridItem>
                            <GridItem xs={2} sm={2} md={2}>
                                <p className={classes.promotionhead}>Selection</p>
                            </GridItem>
                            <GridItem xs={1} sm={1} md={1}>
                                <p className={classes.promotionhead}>Odds</p>
                            </GridItem>
                            <GridItem xs={2} sm={2} md={2}>
                                <p className={classes.promotionhead}>Stake</p>
                            </GridItem> 
                            <GridItem xs={1} sm={1} md={1}>
                                <p className={classes.promotionhead}>Branch</p>
                            </GridItem>
                            <GridItem xs={2} sm={2} md={2}>
                                <p className={classes.promotionhead + " " + classes.alignright}>Placed</p>
                            </GridItem> 
                            <GridItem xs={2} sm={2} md={2}>
                                <p className={classes.promotionhead + " " + classes.alignright}>Profit/Loss</p>
                            </GridItem> 
                        </GridContainer>
                    </ListItem>
                    {(() => {
                        
                        if(bodyData != null && bodyData != false){
                            if(((bodyData.maintype === 8 && bodyData.game_type_id === 10) || bodyData.maintype === 33 || bodyData.maintype === 31 || bodyData.maintype === 32)){
                                var gameDataInfo = JSON.parse(bodyData.game_info);
                                if(bodyData.maintype === 32 && bodyData.type === "CR" && gameDataInfo.info != null){
                                    gameDataInfo = gameDataInfo.info;
                                }
                                if(bodyData.maintype === 8 && gameDataInfo.Purchases != null && gameDataInfo.Purchases[0] != null ){
                                    var selectionType = "";
                                    if(gameDataInfo.Purchases != null && gameDataInfo.Purchases[0] != null && gameDataInfo.Purchases[0].Purchase != null && gameDataInfo.Purchases[0].Purchase[0] != null && gameDataInfo.Purchases[0].Purchase[0].Selections != null && gameDataInfo.Purchases[0].Purchase[0].Selections[0].Selection != null  && gameDataInfo.Purchases[0].Purchase[0].Selections[0].Selection[0].Changes != null  && gameDataInfo.Purchases[0].Purchase[0].Selections[0].Selection[0].Changes[0].Change != null && gameDataInfo.Purchases[0].Purchase[0].Selections[0].Selection[0].$.YourBet != null){
                                        selectionType = gameDataInfo.Purchases[0].Purchase[0].Selections[0].Selection[0].$.YourBet;
                                        var branchIName = gameDataInfo.Purchases[0].Purchase[0].Selections[0].Selection[0].$.BranchName;
                                        gameDataInfo.Purchases[0].Purchase[0].Selections[0].Selection[0].Changes[0].Change[0].Bets[0].Bet.map((innerGInfo) => {
                                            totalWinLost = ((innerGInfo.$.NewStatus == "Lost") ? "Lost " + innerGInfo.$.TaxedStake : ((innerGInfo.$.NewStatus == "Win") ? "Win " + innerGInfo.$.Amount : innerGInfo.$.NewStatus + " " + innerGInfo.$.Amount));
                                            totalStack = Number(totalStack) + Number(innerGInfo.$.TaxedStake);
                                        });
                                        var BetInfo = gameDataInfo.Purchases[0].Purchase[0].Selections[0].Selection[0].Changes[0].Change[0].Bets[0].Bet.map((innerGInfo) => (
                                            <ListItem className={classes.listItemss + " gamecheckfour"} key={innerGInfo.$.ID}>
                                                    <GridContainer>
                                                            <GridItem xs={2} sm={2} md={2}>
                                                                <p>{innerGInfo.$.ID}</p>
                                                            </GridItem>  
                                                            <GridItem xs={2} sm={2} md={2}>
                                                                <p>{selectionType}</p>
                                                            </GridItem>  
                                                            <GridItem xs={1} sm={1} md={1}>
                                                                <p>{innerGInfo.$.Odds}</p>
                                                            </GridItem>  
                                                            <GridItem xs={2} sm={2} md={2}>
                                                                <p>{innerGInfo.$.TaxedStake}</p>
                                                            </GridItem>  
                                                            <GridItem xs={1} sm={1} md={1}>
                                                                <p className={classes.redtext}>{branchIName}</p>
                                                            </GridItem>  
                                                            <GridItem xs={2} sm={2} md={2}>
                                                                <p className={classes.alignright}>{innerGInfo.$.BetSettledDate}</p>
                                                            </GridItem>
                                                            <GridItem xs={2} sm={2} md={2}>
                                                                <p className={classes.alignright}>{((innerGInfo.$.NewStatus == "Lost") ? "Lost " + innerGInfo.$.TaxedStake : ((innerGInfo.$.NewStatus == "Win") ? "Win " + innerGInfo.$.Amount : innerGInfo.$.NewStatus + " " + innerGInfo.$.Amount))}</p>
                                                            </GridItem>  
                                                        </GridContainer>
                                                </ListItem>
                                        ));
                                        return BetInfo;
                                    }else{
                                        return(<p className={classes.promotioncont}></p>)
                                    }
                                    
                                }else if(bodyData.maintype === 8 && gameDataInfo != null && gameDataInfo.LineID != null){
                                    return(
                                        <ListItem className={classes.listItemss + " gamecheckfive"} key={"23213"}>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>Really Sorry, Old data doesn't have reports</GridItem>
                                            </GridContainer>
                                        </ListItem>
                                    )
                                }else if(gameDataInfo.$ === null){
                                    return(
                                        <ListItem className={classes.listItemss + " gamechecksix"} key={"23214"}>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>Really Sorry, Old data doesn't have reports</GridItem>
                                            </GridContainer>
                                        </ListItem>
                                    )
                                }else if(gameDataInfo.$ != null && gameDataInfo.$.BetTypeID != null && gameDataInfo.$.BetTypeID === "1"){
                                    
                                    return(
                                            <ListItem className={classes.listItemss + " gamechecktwo"} key={gameDataInfo.$.BetID}>
                                                <GridContainer>
                                                    <GridItem xs={2} sm={2} md={2}>
                                                        <p>{gameDataInfo.$.BetID}</p>
                                                    </GridItem>  
                                                    <GridItem xs={2} sm={2} md={2}>
                                                        <p>{gameDataInfo.$.YourBet}</p>
                                                    </GridItem>  
                                                    <GridItem xs={1} sm={1} md={1}>
                                                        <p>{gameDataInfo.$.Odds}</p>
                                                    </GridItem>  
                                                    <GridItem xs={2} sm={2} md={2}>
                                                        <p>{gameDataInfo.$.Stake}</p>
                                                    </GridItem>  
                                                    <GridItem xs={1} sm={1} md={1}>
                                                        <p className={classes.redtext}>{gameDataInfo.$.BranchName}</p>
                                                    </GridItem>  
                                                    <GridItem xs={2} sm={2} md={2}>
                                                        <p className={classes.alignright}>{gameDataInfo.$.CreationDate}</p>
                                                    </GridItem>
                                                    <GridItem xs={2} sm={2} md={2}>
                                                        <p className={classes.alignright}>{gameDataInfo.$.OrgStake}</p>
                                                    </GridItem>  
                                                </GridContainer>
                                        </ListItem>
                                    )
                                }else{
                                    var nameOfEvent = "";
                                    if(gameDataInfo.Lines != null && Array.isArray(gameDataInfo.Lines)){
                                        var startLine = 1;
                                        var multiRow = (gameDataInfo.Lines.map((innerGInfo) => 
                                            (
                                                    <ListItem className={classes.listItemss + " gamecheckthree"} key={innerGInfo.$.BetID}>
                                                        <GridContainer>
                                                            <GridItem xs={2} sm={2} md={2}>
                                                                <p>{innerGInfo.$.BetID}</p>
                                                            </GridItem>  
                                                            <GridItem xs={2} sm={2} md={2}>
                                                                <p>{innerGInfo.$.YourBet}</p>
                                                            </GridItem>  
                                                            <GridItem xs={1} sm={1} md={1}>
                                                                <p>{innerGInfo.$.Odds}</p>
                                                            </GridItem>  
                                                            <GridItem xs={2} sm={2} md={2}>
                                                                <p>{innerGInfo.$.Stake}</p>
                                                            </GridItem>  
                                                            <GridItem xs={1} sm={1} md={1}>
                                                                <p className={classes.redtext}>{innerGInfo.$.BranchName}</p>
                                                            </GridItem>  
                                                            <GridItem xs={2} sm={2} md={2}>
                                                                <p className={classes.alignright}>{innerGInfo.$.CreationDate}</p>
                                                            </GridItem>
                                                            <GridItem xs={2} sm={2} md={2}>
                                                                <p className={classes.alignright}>{innerGInfo.$.BetTypeName}</p>
                                                            </GridItem>  
                                                        </GridContainer>
                                                </ListItem>
                                            )
                                        ));
                                        return multiRow;
                                    }
                                }
                                
                            }else{
                                return(
                                    <p className={classes.promotioncont}></p>
                                )
                            }
                        }
                    })()}
                    {(() => {
                        if(bodyData != null && bodyData != false){
                            if(((bodyData.maintype === 8 && bodyData.game_type_id === 10) || bodyData.maintype === 33 || bodyData.maintype === 31 || bodyData.maintype === 32)){
                                var gameDataInfoThree = JSON.parse(bodyData.game_info);
                                if(bodyData.maintype === 32 && bodyData.type === "CR" && gameDataInfoThree.info != null){
                                    gameDataInfoThree = gameDataInfoThree.info;
                                }
                                if(bodyData.maintype === 8 && gameDataInfoThree.Purchases != null && gameDataInfoThree.Purchases[0] != null ){
                                    if(gameDataInfoThree.Purchases != null && gameDataInfoThree.Purchases[0] != null && gameDataInfoThree.Purchases[0].Purchase != null && gameDataInfoThree.Purchases[0].Purchase[0] != null && gameDataInfoThree.Purchases[0].Purchase[0].Selections != null && gameDataInfoThree.Purchases[0].Purchase[0].Selections[0].Selection != null  && gameDataInfoThree.Purchases[0].Purchase[0].Selections[0].Selection[0].Changes != null  && gameDataInfoThree.Purchases[0].Purchase[0].Selections[0].Selection[0].Changes[0].Change != null && gameDataInfoThree.Purchases[0].Purchase[0].Selections[0].Selection[0].$.YourBet != null){
                                        return(
                                            <ListItem className={classes.listItemss + " listItemfooter"} key="1">
                                                <GridContainer className={classes.transactionsubtotal}>
                                                    <GridItem xs={10} sm={10} md={10}>
                                                        <p>Total Stakes</p>
                                                    </GridItem>   
                                                    <GridItem xs={2} sm={2} md={2}>
                                                        <p>{totalStack}</p>
                                                    </GridItem>  
                                                </GridContainer>
                                                <GridContainer>
                                                    <GridItem xs={10} sm={10} md={10}>
                                                        <p>Win/Lost</p>
                                                    </GridItem>   
                                                    <GridItem xs={2} sm={2} md={2}>
                                                        <p>{totalWinLost}</p>
                                                    </GridItem>  
                                                </GridContainer>
                                            </ListItem>
                                        )
                                    }else{
                                        return(<p className={classes.promotioncont}></p>)
                                    }
                                }else if(gameDataInfoThree.$ === null){
                                    return(<p className={classes.promotioncont}></p>)
                                }else if(gameDataInfoThree.$ != null && gameDataInfoThree.$.BetTypeID != null){
                                    return(
                                        <ListItem className={classes.listItemss + " listItemfooter"} key="1">
                                            <GridContainer className={classes.transactionsubtotal}>
                                                <GridItem xs={10} sm={10} md={10}>
                                                    <p>Total Stakes</p>
                                                </GridItem>   
                                                <GridItem xs={2} sm={2} md={2}>
                                                    <p>{gameDataInfoThree.$.Stake}</p>
                                                </GridItem>  
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={10} sm={10} md={10}>
                                                    <p>Gain</p>
                                                </GridItem>   
                                                <GridItem xs={2} sm={2} md={2}>
                                                    <p>{gameDataInfoThree.$.Gain}</p>
                                                </GridItem>  
                                            </GridContainer>
                                            {(() => {
                                                if(bodyData.maintype === 32 && bodyData.type === "CR"){
                                                    return (
                                                        <GridContainer>
                                                            <GridItem xs={10} sm={10} md={10}>
                                                                <p>Status</p>
                                                            </GridItem>   
                                                            <GridItem xs={2} sm={2} md={2}>
                                                                <p>Cancel Reserved</p>
                                                            </GridItem>  
                                                        </GridContainer>
                                                    ) 
                                                }
                                            
                                            })()}
                                        </ListItem>
                                        )
                                }
                            }
                        }
                    })()}
                    
                </List> 
                </div>
                </div>
  
        )
    }
  return ( <div>
   {secloginboxDb[sessionStorage.getItem('lang')].map((jsondata) => (   
      <div key={jsondata.id}>
    <Dialog
    classes={{
      root: classes.center + " " + classes.topfiix,
      paper: classes.modal + " " + classes.responsivemodellAllpro
      
    }}
    key={jsondata.id}
    open={classicModal}
     
    keepMounted
    onClose={() => { manualClose = true;setClassicModal(false)}}
    aria-labelledby="classic-modal-slide-title"
    aria-describedby="classic-modal-slide-description"
>

  <DialogTitle
      id="classic-modal-slide-title"
      disableTypography
      className={classes.modalHeader + " " + classes.modalHeaderprofile}
  >
    <IconButton
        className={classes.modalCloseButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => { manualClose = true;setClassicModal(false)}}
    >
      <Close className={classes.modalClose} />
    </IconButton>
     
  </DialogTitle>
 
  <DialogContent
      id="classic-modal-slide-description"
      className={classes.modalBody + " gamedialog " + classes.modalBodyprofile}
  >  
   {bodyDataOutPut}
  </DialogContent>
   
</Dialog>
</div>
))}
</div>
  );
}
SectionTransactionDetail.propTypes = {
  openPopup: function(isState, transactionRefId){ 
      if(isState === "login"){
        bodyDataOutPut = null;
        setClassicModal(true);
        setBodyDataOutPut(false);
        setBodyData(transactionRefId);
      } 
    }
};

SectionTransactionDetail.openPopup = function(isState, transactionRefId){ 
    if(isState === "login"){
        // if(transactionRefId != bodyData){
        //     if(document.body.getElementsByClassName("gamecheck")[0] != null){
        //         document.body.getElementsByClassName("gamecheck")[0].innerHTML="";
        //     }
        //     if(document.body.getElementsByClassName("gamechecktwo")[0] != null){
        //         document.body.getElementsByClassName("gamechecktwo")[0].innerHTML="";
        //     }
        //     if(document.body.getElementsByClassName("gamecheckthree")[0] != null){
        //         document.body.getElementsByClassName("gamecheckthree")[0].innerHTML="";
        //     }
        //     if(document.body.getElementsByClassName("gamecheckfour")[0] != null){
        //         document.body.getElementsByClassName("gamecheckfour")[0].innerHTML="";
        //     }
        // }
        bodyDataOutPut = null;
        setClassicModal(true);
        setBodyDataOutPut(false);
        setBodyData(transactionRefId);
    } 
}

export default SectionTransactionDetail;
/*eslint-disable*/
import React, {useEffect, useRef, useState} from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import People from "@material-ui/icons/People";
import Email from "@material-ui/icons/Email";
import Icon from "@material-ui/core/Icon";
// react components for routing our app without refresh
import { Link, withRouter } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// @material-ui/core components
import {TextFieldFormsy} from '../../../@casino';
import CustomInput from "../elements/CustomInput/CustomInput.js";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
// @material-ui/icons
import Formsy from 'formsy-react';
import { Apps, CloudDownload } from "@material-ui/icons";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// core components
import CustomDropdown from "../elements/CustomDropdown/CustomDropdown.js";
import Button from "../elements/CustomButtons/Button.js";
import * as authActions from '../../../auth/casino/actions';
import * as globalurl from '../../../global/Global.js'; 
import axios from 'axios';
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import {useDispatch, useSelector} from 'react-redux'; 
import menuDb from "../data/menu.js";
import logo from "../assets/img/logo.png";
import GridItem from "../elements/Grid/GridItem";
import GridContainer from "../elements/Grid/GridContainer";
import styles from "../assets/jss/components/headerLinksStyle.js";
import stylesr from "../assets/jss/components/headerLinkstopStyle"; 
//import { useTranslation } from 'react-i18next'
import secotpboxDb from "../data/secotpbox.js";
const history = createBrowserHistory()

let classicModal = null;
let setClassicModal = null;

let test = "ok";
let classicModal3, setClassicModal3 = null;
let mobile, setMobile, countryId, setCountryId = null;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
let registerId, setRegisterId = null;
Transition.displayName = "Transition";
function SectionOtpboxMobile(props) {
  const dispatch = useDispatch();
  const [isFormValid, setIsFormValid] = useState(false);
  [registerId, setRegisterId] = useState(false);
  [mobile, setMobile] = useState(false);
  [countryId, setCountryId] = useState(false);
    const formRef = useRef(null);
    const [isPopupOpen, setIsPopupOpen] = useState("stop");
    const [message, setMessage] = React.useState("");
    const otpnew = useSelector(({auth}) => auth.otpnew);
    const [otp, setOtp] = React.useState("");
    const [classAdd, setClassAdd] = React.useState(false);
    [classicModal, setClassicModal] = React.useState(false);
  function otpChange(event){
    if(event.target != null && event.target.value != null){
      setOtp(event.target.value);
    }
  }
  function resendOtp(){
    var urlForOtp = globalurl.LOGINME_PATH;
    var sender = "country_id";
     
    const request = axios.post(urlForOtp, [sender]+"="+countryId+"&mobile="+mobile,{ header: { "Content-Type": "application/x-www-form-urlencoded" } });
    request.then((response) => {
        if (response.data.status != null && (response.data.status === true || response.data.status === 1 || response.data.status === "1") )
        {
          if(response.data.success != null){
            setMessage(response.data.success);
          }else{
            setMessage("OTP sent again! Please check.");
          }
          setClassAdd(classes.msg = " successmsg" );
        }else{
          setMessage("Failed to send otp again. Error:" + (response.data.error != null && response.data.error.error != null) ? response.data.error.error : "Issue in sending OTP");
          
          setClassAdd(classes.msg = " errormsg" );
        }
    });
    request.catch((error) => {
      setMessage("Failed to send otp again");
        setClassAdd(classes.msg = " errormsg" );
    });
  }
  function verifyMobileOtp(otpmodel){
    var urlForOtp = globalurl.verifymeotp;
    const request = axios.post(urlForOtp, {
      otp: otp,
      mobile: mobile,
      country_id: countryId
    });
    request.then((response) => {
        if (response.data.status != null && response.data.status === true )
        {
          if(sender === "token"){
            setMessage("You mobile number is verified now.");
          }else{
            setMessage("You account is verified now. Please login and play now!");
          }
          setClassAdd(classes.msg = " successmsg" );
          if(sender === "token"){
            setTimeout(function() {
              document.body
                    .getElementsByClassName("messageverify")[0]
                    .innerHTML=""; 
                    setClassicModal3(false);
              }, 3000); 
          }else{
            setTimeout(function() {
              document.body
                    .getElementsByClassName("messageverify")[0]
                    .innerHTML=""; 
                    setClassicModal3(false);
              }, 3000); 
              props.LoginBox.openPopup("login");
          }
        }else{
          if(sender === "token"){
            setMessage("Failed to verify with mobile.");
          }else{
            setMessage("Failed to verify with otp.");
          }
          
          setClassAdd(classes.msg = " errormsg" );
        }
    });
    request.catch((error) => {
        if(sender === "token"){
          setMessage("Failed to verify with mobile.");
        }else{
          setMessage("Failed to verify with otp.");
        }
        setClassAdd(classes.msg = " errormsg" );
    });
  }
  if(props.themeColors != null){
    styles.signinbtn.backgroundImage = props.themeColors.bgColors.main_color;
    styles.signinbtn.color = props.themeColors.bgColors.text_primary_dark_color;
    styles.modalHeader.background = props.themeColors.bgColors.primary_dark_bgcolor;
    styles.modalBody.background = props.themeColors.bgColors.primary_dark_bgcolor;
    styles.modalFooter.background = props.themeColors.bgColors.primary_dark_bgcolor;
    styles.modalSubtitle.color = props.themeColors.bgColors.text_primary_color;
    styles.modalCloseButton.color = props.themeColors.bgColors.text_primary_color;
    styles.modalTitle.color = props.themeColors.bgColors.text_secondary_dark_color; 
    styles.signupbixp.color = props.themeColors.bgColors.text_primary_color; 
 
    styles.signinmodalbtn.backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn.background = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn["&:hover"].backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn["&:hover"].background = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn.color = props.themeColors.bgColors.text_primary_dark_color;
      styles.resendbtn.color = props.themeColors.bgColors.text_secondary_color;
      styles.signinsignup.color = props.themeColors.bgColors.text_secondary_color;
      styles.logoname.color = props.themeColors.bgColors.text_primary_color;
    // styles.signinmodalbtn.color = props.themeColors.bgColors.goldenbtntext;

    styles.logoname.fontFamily = props.themeColors.bgColors.logo_font; 
      styles.modalTitle.fontFamily = props.themeColors.bgColors.base_font; 
      styles.modalSubtitle.fontFamily = props.themeColors.bgColors.base_font;  
      styles.form["& label"].fontFamily = props.themeColors.bgColors.base_font;   
      styles.form["& input"].fontFamily = props.themeColors.bgColors.base_font; 
      styles.signupbix.fontFamily = props.themeColors.bgColors.base_font;
      styles.signinmodalbtn.fontFamily = props.themeColors.bgColors.base_font; 
      styles.tnctext.fontFamily = props.themeColors.bgColors.base_font; 
  }
  if(isPopupOpen === false){
   
    setClassicModal(false);
  }    
  useEffect(() => {
     
    if ( otpnew != null && otpnew.success != null && otpnew.success == false && otpnew.error && (otpnew.error.mobile || otpnew.error.country_id || otpnew.error.otp || otpnew.error.message) )
    
    { 
      if(formRef != null && formRef.current != null){
          formRef.current.reset();
      }
        if(otpnew.error.mobile || otpnew.error.country_id ){ 
          
            if(otpnew.error.mobile){
                otpnew.error.mobile = otpnew.error.mobile;
                delete otpnew.error["mobile"];
            }
            if(otpnew.error.country_id){
                otpnew.error.country_id = otpnew.error.country_id;
                delete otpnew.error["country_id"];
            }
            delete otpnew.error["message"]; 
            formRef.current.updateInputsWithError({
                ...otpnew.error
            });
        }else if(otpnew.error.message != null){ 
            if(document.body.getElementsByClassName("message_otp_one")[0] != null){
                document.body.getElementsByClassName("message_otp_one")[0].innerHTML=otpnew.error.message;
            }
            setClassAdd(classes.msg = " errormsg" );
        }
        disableButton();  
    }else{
        if(otpnew != null && otpnew.success != null && otpnew.success == true && otpnew.data != null && otpnew.data.status != null && otpnew.data.status > 0){
          
            if(formRef != null && formRef.current != null){
                formRef.current.reset();
            }
            if(otpnew.data.LOGINOUTPUT !== null && otpnew.data.LOGINOUTPUT !== undefined){
             
              let loginOutput = JSON.parse(otpnew.data.LOGINOUTPUT); 
              if(loginOutput.status === "1"){ 
                  setTimeout(function() { 
                    if(document.body.getElementsByClassName("message_otp_one")[0] != null && loginOutput.message !== null){
                        document.body.getElementsByClassName("message_otp_one")[0].innerHTML=loginOutput.message;
                        setClassAdd(classes.msg = " successmsg" );
                    }
                    setClassicModal(false);
                    if(props.OtpBox != null){
                    
                    }
                  }, 1000);
              }else{
                if(document.body.getElementsByClassName("message_otp_one")[0] != null && loginOutput.message !== null){
                    document.body.getElementsByClassName("message_otp_one")[0].innerHTML=loginOutput.message;
              
                    setClassAdd(classes.msg = " errormsg" );
                  }
              }
              
            }else if(otpnew.data !== null && otpnew.data.status !== null && otpnew.data.status === true && otpnew.data.PROFILETOKEN !== null && otpnew.data.PROFILESID !== null && otpnew.data.PROFILETOKEN === "0" && otpnew.data.PROFILESID === "0"){
            
                setClassicModal3(false);
                if(props.SignupBox != null){
                  props.SignupBox.openPopup("signup", otpnew.data.country_id, otpnew.data.mobile, otpnew.data.otp);
                }
            }else{
                 
            }
  
            if(otpnew.data.message != null){
                setClassAdd(classes.msg = " successmsg" )
                if(document.body.getElementsByClassName("message_otp_one")[0] != null){
                    document.body.getElementsByClassName("message_otp_one")[0].innerHTML=otpnew.data.message;
                }
            }
              
        }
        
    }
  }, [otpnew]);
   
  
  

    function handleSubmit(model)
    {
        model = {"mobile": mobile, "country_id": countryId, "otp": model.otp, ...model} 
        dispatch(authActions.submitOtpNew(model,setIsPopupOpen,props));
        if(document.body.getElementsByClassName("message_otp_one")[0] !== null){
          document.body
          .getElementsByClassName("message_otp_one")[0]
          .innerHTML=" ";  
        }
    }


    function disableButton()
    {
        setIsFormValid(false);
        document.body
        .getElementsByClassName("signinbtn")[0]
          .setAttribute("style", "opacity:0.5;");
    }

    function enableButton()
    {
        setIsFormValid(true);
        document.body
        .getElementsByClassName("signinbtn")[0]
          .setAttribute("style", "opacity:1;");
    }
    
    //const { t } = useTranslation()
    let currentStyle = styles;
    const useStyles = makeStyles(styles);

    const classes = useStyles();
  
   [classicModal3, setClassicModal3] = React.useState(false);

   function parseJwt(token) {
    if (!token) { return; }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}
 function getCurrentThemeInfo(){
  if(window.localStorage.getItem('jwt_theme_token') != null){
      let themeData = parseJwt(window.localStorage.getItem('jwt_theme_token'));
      return themeData;
  }
}

   var icon = null; 
   var brandName = null;
   var themeInfo = getCurrentThemeInfo();
    
    if(themeInfo != null && themeInfo.id != null){ 
        if(themeInfo.icon != null && themeInfo.icon.length > 0){
          icon = themeInfo.icon;
        }
        if(themeInfo.brand_name != null && themeInfo.brand_name.length > 0){
          brandName = themeInfo.brand_name;
        }  
    }

  return (
    <div>
    {secotpboxDb[sessionStorage.getItem('lang')].map((jsondata) => (   
      <div key={jsondata.id}>
    <Dialog
    classes={{
      root: classes.center,
      paper: classes.modal + "   " + classes.responsivemodell
    }}
    open={classicModal3}
    TransitionComponent={Transition}
    keepMounted
    onClose={() => setClassicModal3(false)}
    aria-labelledby="classic-modal-slide-title"
    aria-describedby="classic-modal-slide-description"
>
  <DialogTitle
      id="classic-modal-slide-title"
      disableTypography
      className={classes.modalHeader + " " + classes.verifyheader}
  >
    <IconButton
        className={classes.modalCloseButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => setClassicModal3(false)}
    >
      <Close className={classes.modalClose} />
    </IconButton>
      

      
  </DialogTitle>
  <Formsy
        onValidSubmit={handleSubmit}
        onValid={enableButton}
        onInvalid={disableButton}
        ref={formRef}
        autoComplete={"off"}
        className={classes.form + " flex flex-col justify-center w-full " + classes.modalBody + " " + classes.verifybody}
    >
    <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody + " " + classes.verifybody}
    >

        <div style={{textAlign:"center"}}>
            {(() => {
                
                  if(themeInfo.icon != null && (themeInfo.icon != undefined && themeInfo.icon != "undefined" && themeInfo.icon.length > 0)) { 
                          return ( 
                            <img src={icon} height={"40px"} alt={""} className={classes.modellogo} />
                  ) }else{
                    return ( 
                            <h4 className={classes.logoname}>{brandName}</h4>  
                      )
            }
            })()}

          <h4 className={classes.modalTitle}>{jsondata.title} </h4> 
        </div>

        <div className={classes.msg + " messageverify message_otp_one " + classAdd} id="messageverify">{message}</div>
        <GridContainer style={{marginLeft:'0'}}>
            <GridItem xs={12} style={{padding:'0'}}>
            <TextFieldFormsy
              label="Enter OTP"
              name="otp"
              formControlProps={{
                  fullWidth: true
              }}
              validations={{
                  minLength: 4,
                  isNumeric: true
              }}
              validationErrors={{
                  minLength: 'Min character length is 4',
                  isNumeric: "Only number allowed"
              }}
              onChange={otpChange}
              variant="outlined"
              required
          /> 
         </GridItem>
          </GridContainer>
          <div style={{textAlign:"center"}}>
          <Button simple color="primary" className={classes.signinmodalbtn + " signinbtn"} style={{marginLeft: "0",marginRight: "0", width: "100%",marginTop: "10px"}}  type="submit" size="lg">
        {((props.otpVerifyBy != null && props.otpVerifyBy == "Mobile") ? "Verify Now" : jsondata.sbmitbtn)}
        </Button>
        <a onClick={() => resendOtp()} id="resendOTP" className={classes.resendbtn} style={{cursor:"pointer"}}>Resend OTP</a>
         
          </div>
  </DialogContent>
 
  </Formsy> 
</Dialog>             
</div>
))}
</div>

  );
}
SectionOtpboxMobile.propTypes = {
  openPopup: function(isState){
    
    if(isState === "otpbox"){
        setClassicModal3(true);
      }   
    }
};
SectionOtpboxMobile.openPopup = function(isState, country_id, mymobile){
  if(isState === "otpbox"){
    if(document.body.getElementsByClassName("message_otp_one")[0] != null){
        document.body.getElementsByClassName("message_otp_one")[0].innerHTML="";
    }
    if(country_id != null && Number(country_id) > 0 && mymobile != null && Number(mymobile) > 0){
      setCountryId(country_id);
      setMobile(mymobile);
    }
    setClassicModal3(true);
  }   
};

export default SectionOtpboxMobile;



const gameDb = {
        en: [
            {
                'id'    : '5725a680b3249760ea22de51',
                'tagName': 'All Games',
                'btnName'  : 'Play Now!',
                'games':[
                    {
                        'id'    : '5725a680b3249760ea22de52',
                        'gamelogo'  : 'assets/img/gami1.jpg',
                        'btnName'  : 'Play Now!',
                    },
                    {
                        'id'    : '5725a680b3249760ea22de53',
                        'gamelogo'  : 'assets/img/gami2.jpg',
                        'btnName'  : 'Play Now!',
                    },
                    {
                        'id'    : '5725a680b3249760ea22de54',
                        'gamelogo'  : 'assets/img/gami3.jpg',
                        'btnName'  : 'Play Now!',
                    },
                    {
                        'id'    : '5725a680b3249760ea22de55',
                        'gamelogo'  : 'assets/img/gami4.jpg',
                        'btnName'  : 'Play Now!',
                    },
                    {
                        'id'    : '5725a680b3249760ea22de56',
                        'gamelogo'  : 'assets/img/gami1.jpg',
                        'btnName'  : 'Play Now!',
                    },
                    {
                        'id'    : '5725a680b3249760ea22de57',
                        'gamelogo'  : 'assets/img/gami2.jpg',
                        'btnName'  : 'Play Now!',
                    }
                ]
            },
            // {
            //     'id'    : '5725a680b3249760ea22de59',
            //     'tagName': 'Casino Games',
            //     'games':[
            //         {
            //             'id'    : '5725a680b3249760ea22de58',
            //             'gamelogo'  : 'assets/img/gami1.jpg',
            //             'btnName'  : 'Play Now!',
            //         },
            //         {
            //             'id'    : '5725a680b3249760ea22de60',
            //             'gamelogo'  : 'assets/img/gami2.jpg',
            //             'btnName'  : 'Play Now!',
            //         },
            //         {
            //             'id'    : '5725a680b3249760ea22de61',
            //             'gamelogo'  : 'assets/img/gami3.jpg',
            //             'btnName'  : 'Play Now!',
            //         }
            //     ]
            // }

    ],
    tl: [
        {
            'id'    : '5725a680b3249760ea22de51',
            'tagName': 'అన్ని ఆటలు',
            'btnName'  : 'ఇప్పుడు ఆడు!',
            'games':[
                {
                    'id'    : '5725a680b3249760ea22de52',
                    'gamelogo'  : 'assets/img/gami1.jpg',
                    'btnName'  : 'ఇప్పుడు ఆడు!',
                },
                {
                    'id'    : '5725a680b3249760ea22de53',
                    'gamelogo'  : 'assets/img/gami2.jpg',
                    'btnName'  : 'ఇప్పుడు ఆడు!',
                },
                {
                    'id'    : '5725a680b3249760ea22de54',
                    'gamelogo'  : 'assets/img/gami3.jpg',
                    'btnName'  : 'ఇప్పుడు ఆడు!',
                },
                {
                    'id'    : '5725a680b3249760ea22de55',
                    'gamelogo'  : 'assets/img/gami4.jpg',
                    'btnName'  : 'ఇప్పుడు ఆడు!',
                },
                {
                    'id'    : '5725a680b3249760ea22de56',
                    'gamelogo'  : 'assets/img/gami1.jpg',
                    'btnName'  : 'ఇప్పుడు ఆడు!',
                },
                {
                    'id'    : '5725a680b3249760ea22de57',
                    'gamelogo'  : 'assets/img/gami2.jpg',
                    'btnName'  : 'ఇప్పుడు ఆడు!',
                }
            ]
        }, 

    ],
    hi: [
        {
            'id'    : '5725a680b3249760ea22de62',
            'tagName': 'सभी खेल',
            'btnName'  : 'अब खेलें!',
            'games':[
                {
                    'id'    : '5725a680b3249760ea22de63',
                    'gamelogo'  : 'assets/img/gami1.jpg',
                    'btnName'  : 'अब खेलें!',
                },
                {
                    'id'    : '5725a680b3249760ea22de64',
                    'gamelogo'  : 'assets/img/gami2.jpg',
                    'btnName'  : 'अब खेलें!',
                },
                {
                    'id'    : '5725a680b3249760ea22de65',
                    'gamelogo'  : 'assets/img/gami3.jpg',
                    'btnName'  : 'अब खेलें!',
                },
                {
                    'id'    : '5725a680b3249760ea22de66',
                    'gamelogo'  : 'assets/img/gami4.jpg',
                    'btnName'  : 'अब खेलें!',
                },
                {
                    'id'    : '5725a680b3249760ea22de67',
                    'gamelogo'  : 'assets/img/gami1.jpg',
                    'btnName'  : 'अब खेलें!',
                },
                {
                    'id'    : '5725a680b3249760ea22de68',
                    'gamelogo'  : 'assets/img/gami2.jpg',
                    'btnName'  : 'अब खेलें!',
                }
            ]
        },
        // {
        //     'id'    : '5725a680b3249760ea22de69',
        //     'tagName': 'कैसीनो के खेल',
        //     'games':[
        //         {
        //             'id'    : '5725a680b3249760ea22de70',
        //             'gamelogo'  : 'assets/img/gami1.jpg',
        //             'btnName'  : 'अब खेलें!',
        //         },
        //         {
        //             'id'    : '5725a680b3249760ea22de71',
        //             'gamelogo'  : 'assets/img/gami2.jpg',
        //             'btnName'  : 'अब खेलें!',
        //         },
        //         {
        //             'id'    : '5725a680b3249760ea22de72',
        //             'gamelogo'  : 'assets/img/gami3.jpg',
        //             'btnName'  : 'अब खेलें!',
        //         }
        //     ]
        // }

]
};

export default gameDb;
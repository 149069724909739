import React from "react";
// @material-ui/core components
// import {
//     Accordion,
//     AccordionItem,
//     AccordionItemHeading,
//     AccordionItemButton,
//     AccordionItemPanel,
// } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

// import { makeStyles } from "@material-ui/core/styles";
// import faqDb from "../data/faqs.js";
// import GridContainer from "../elements/Grid/GridContainer.js";
// import GridItem from "../elements/Grid/GridItem.js";
// import CustomTabs from "../elements/CustomTabs/CustomTabs.js";
import * as Gurl from "../../../global/Global.js"
import axios from 'axios';


import styles from "../assets/jss/components/tabsFaqstyle.js";



// const useStyles = makeStyles(styles);

export default function SectionTabs(props) {
  styles.accordianbtn["& div i"].background = props.themeColors.bgColors.main_color
  styles.accordianbtn["& div i"].color = props.themeColors.bgColors.text_primary_in_color
  styles.accordianbtn["& div"].background = props.themeColors.bgColors.primary_bgcolor
  styles.accordianbtn["& div:hover"].background = props.themeColors.bgColors.primary_bgcolor
  styles.accordianiitm.background = props.themeColors.bgColors.primary_bgcolor
  styles.accordianp.color = props.themeColors.bgColors.text_secondary_dark_color 
  
  // const classes = useStyles();
  const [faqData, setFaqData] = React.useState("NOVALUE");
  function createMarkup(text) { return {__html: text}; };
    function loadFaqData(){
        const request = axios.post(Gurl.FAQS_CONTENT_API, {
            token: window.localStorage.getItem('jwt_access_token'),
            "faqids" : props.faqIds,
        });
        request.catch((error) => { 
            setFaqData("NOVALUE");
        });
        request.then((response) => { 
            if(response.data.data != null){ 
                var myOutPut = "";
                if(Array.isArray(response.data.data)){ 
                    myOutPut = (
                        response.data.data.map((jsondata) => (
                          <div class="panel panel-default">
                            <div id={"heading_" + jsondata.id} class="panel-heading" role="tab">
                              <h4 class="panel-title"><a class="collapsed" role="button" href={"#collapse_" + jsondata.id} data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls={"collapse_" + jsondata.id}> {jsondata.question}</a></h4>
                            </div>
                            <div id={"collapse_" + jsondata.id} class="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading_" + jsondata.id}>
                              <div class="panel-body" dangerouslySetInnerHTML={createMarkup(jsondata.answer)}></div>
                            </div>
                          </div>
                        ))
                    );
                }else{
                    var jsondata = response.data.data;
                    myOutPut = (
                      <div class="panel panel-default">
                            <div id={"heading_" + jsondata.id} class="panel-heading" role="tab">
                              <h4 class="panel-title"><a class="collapsed" role="button" href={"#collapse_" + jsondata.id} data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls={"collapse_" + jsondata.id}> {jsondata.question}</a></h4>
                            </div>
                            <div id={"collapse_" + jsondata.id} class="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading_" + jsondata.id}>
                              <div class="panel-body" dangerouslySetInnerHTML={createMarkup(jsondata.answer)}></div>
                            </div>
                          </div>
                    );
                }
                setFaqData(myOutPut);
            }else{
                setFaqData("NO FAQ FOUND");
            }
        });  
    }
    if(faqData === "NOVALUE"){
      setFaqData("Loading...");
      loadFaqData();
  }
  return (
    <div id="accordion" class="panel-group" role="tablist" aria-multiselectable="true">
    {faqData}
    </div>
  );
}

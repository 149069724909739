/*eslint-disable*/
import React, {useEffect, useRef, useState} from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton"; 
import People from "@material-ui/icons/People";
import Email from "@material-ui/icons/Email"; 
// react components for routing our app without refresh
import { Link, withRouter } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// @material-ui/core components
import {Button, Divider, Typography, InputAdornment, Icon} from '@material-ui/core';
import CustomInput from "../elements/CustomInput/CustomInput.js";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// core components
import CustomDropdown from "../elements/CustomDropdown/CustomDropdown.js";
import CustomButton from "../elements/CustomButtons/Button.js";
//Third Party Authentication
import {FacebookLogin, GoogleLogin} from "../../../@casino"

import Formsy from 'formsy-react';
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import menuDb from "../data/menu.js";
import logo from "../assets/img/logo.png";
import Hometab from "../pages/hometab";
import GridItem from "../elements/Grid/GridItem";
import GridContainer from "../elements/Grid/GridContainer";
import styles from "../assets/jss/components/headerLinksStyle.js";
import stylesr from "../assets/jss/components/headerLinkstopStyle"; 

import * as authActions from '../../../auth/casino/actions';
import {useDispatch, useSelector} from 'react-redux';
import {TextFieldFormsy, CheckboxFormsy} from '../../../@casino';
import secloginboxDb from "../data/secloginbox.js";
//import { useTranslation } from 'react-i18next'
const history = createBrowserHistory()
let classicModal = null;
let setClassicModal = null;
let currentTab = "";
let currentSubTab = "";
var manualClose = false;
 
function SectionAllprofile(props) {

    const dispatch = useDispatch();
     
    if(props.themeColors != null){
      styles.signinbtn.backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinbtn.color = props.themeColors.bgColors.text_primary_dark_color;
      styles.modalHeader.background = props.themeColors.bgColors.primary_dark_bgcolor;
      styles.modalBody.background = props.themeColors.bgColors.primary_dark_bgcolor;
      styles.modalFooter.background = props.themeColors.bgColors.primary_dark_bgcolor;
      styles.modalSubtitle.color = props.themeColors.bgColors.text_primary_color;
      styles.signupbixp.color = props.themeColors.bgColors.text_primary_color; 
      styles.modalCloseButton.color = props.themeColors.bgColors.text_primary_color;
      styles.signinmodalbtn.backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn["&:hover"].backgroundImage = props.themeColors.bgColors.main_color;
      styles.signinmodalbtn.color = props.themeColors.bgColors.text_primary_dark_color;
      styles.modalBodyprofile.background = props.themeColors.bgColors.primary_dark_bgcolor;
    }
    var setManualClose = function(state){
      manualClose = state;
    }
  //  const { t } = useTranslation()
    let currentStyle = styles;
    const useStyles = makeStyles(styles);

    const classes = useStyles();
  [classicModal, setClassicModal] = React.useState(false);
  return ( <div>
   {secloginboxDb[sessionStorage.getItem('lang')].map((jsondata) => (   
      <div key={jsondata.id}>
    <Dialog
    classes={{
      root: classes.center + " " + classes.topfiix,
      paper: classes.modal + " " + classes.responsivemodellAllpro
      
    }}
    key={jsondata.id}
    open={classicModal}
     
    keepMounted
    onClose={() => { manualClose = true;setClassicModal(false)}}
    aria-labelledby="classic-modal-slide-title"
    aria-describedby="classic-modal-slide-description"
>

  <DialogTitle
      id="classic-modal-slide-title"
      disableTypography
      className={classes.modalHeader + " " + classes.modalHeaderprofile}
  >
    <IconButton
        className={classes.modalCloseButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => { manualClose = true;setClassicModal(false)}}
    >
      <Close className={classes.modalClose} />
    </IconButton>
     
  </DialogTitle>
 
  <DialogContent
      id="classic-modal-slide-description"
      className={classes.modalBody + " " + classes.modalBodyprofile}
  >  
        <Hometab themeColors={props.themeColors} selectedTab={currentTab} selectedSubTab={currentSubTab} manualClose={manualClose} setManualClose={setManualClose} {...props} />
  
  </DialogContent>
   
</Dialog>
</div>
))}
</div>
  );
}
SectionAllprofile.propTypes = {
  openPopup: function(isState, tabName, subTabName){ 
      if(isState === "login"){
          currentTab = tabName;
        currentSubTab = subTabName;
        setClassicModal(true);
      } 
    }
};

SectionAllprofile.openPopup = function(isState, tabName, subTabName){ 
    if(isState === "login"){
        currentTab = tabName;
        currentSubTab = subTabName;
        setClassicModal(true);
    } 
}

export default SectionAllprofile;